import Axios, { AxiosRequestConfig, AxiosResponse, Canceler } from 'axios';
import {setupCache} from 'axios-cache-interceptor';
import { getHeaders } from '../../setup';
import {
    ServiceTypes,
    RequestOptions,
    RequestCoreParams,
} from '../../types/main';

Axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
Axios.defaults.headers.common['Cache-control'] = 'no-store';
// eslint-disable-next-line import/no-named-as-default-member

const axios = setupCache(Axios, {

});

const defaultOptions: RequestOptions = {
    isSecure: false,
    type: ServiceTypes.DFS,
    isAutoCancel: false,
    withCancel: false,
    cache: false,
};

export const queue:Set<AbortController> = new Set();

/**
 * Service for handling all requests.
 */
class Service {
    static autoCancelQueue: Record<string, Canceler> = {};

    static autoCancel = (isAutoCancel: boolean, url: string, cancelToken: Canceler):void => {
        if (isAutoCancel) {
            if (typeof Service.autoCancelQueue[url] === 'function') {
                Service.autoCancelQueue[url]();
                delete Service.autoCancelQueue[url];
            }
            Service.autoCancelQueue[url] = cancelToken;
        }
    };

    /** @method
     * @name cancelAll
     *  @param {string=} method - Method for cancel all requests or cancel by get or post method.
     */
    static cancelAll(method: string):void {
        if (method) {
            queue.forEach((controller: AbortController) => {
                controller.abort();
            });
        } else {
            queue.forEach((controller:AbortController) => {
                controller.abort();
            });
        }
    }

    /** @method
     * @name isCanceled
     * Method for check error type.
     @param {Error} thrown - The title of the book.
     */
    static isCanceled = (thrown: Error):boolean => (
        Axios.isCancel(thrown)
    );

    /** @method
     * @name cancelRequest
     * Cancel request by cancel token
     *  @param {AbortController} controller - AbortController controller.
     *  @param {function} controller.abort - abort request.
     */
    static cancelRequest = (controller: AbortController):void => {
        controller.abort();
        queue.delete(controller);
    };

    /** @method
     * @name secureSetup
     */
    static secureSetup(isSecure:boolean, type: ServiceTypes, axiosSetup: AxiosRequestConfig = {}):AxiosRequestConfig {
        const axiosSetupHeaders = axiosSetup.headers || {};

        return {
            ...axiosSetup,
            headers: {
                ...getHeaders(type, isSecure),
                ...axiosSetupHeaders,
            },
        };
    }

    static addQueue = (controller: AbortController):void => {
        queue.add(controller);
    };

    static request <TPayload, TResponseData, R = AxiosResponse<TResponseData>>(
        {
            isSecure = false, type = ServiceTypes.DFS, axiosSetup, isAutoCancel = true, withCancel, cache,
        }:RequestOptions,
        { method, url, data }:RequestCoreParams<TPayload>,
    ):Promise<R>|[Promise<R>, () => void] {
        const controller = new AbortController();

        const axiosSetupComputed = Service.secureSetup(isSecure, type, {
            ...axiosSetup,
            signal: controller.signal,
        });

        let cacheParam = cache;

        if (cacheParam === true) {
            cacheParam = {};
        } else if (cacheParam === false) {
            cacheParam = false;
        }

        const axiosRequest:Promise<R> = axios.request({
            url,
            method,
            data,
            cache: cacheParam,
            ...axiosSetupComputed,
        });

        Service.autoCancel(isAutoCancel, url, controller.abort);
        Service.addQueue(controller);

        // eslint-disable-next-line no-async-promise-executor
        const axiosRequestWrapper:Promise<R> = new Promise((async (resolve, reject) => {
            try {
                resolve(await axiosRequest);
                delete Service.autoCancelQueue[url];
            } catch (e) {
                reject(e);
            } finally {
                Service.cancelRequest(controller);
            }
        }));

        if (withCancel) {
            return [
                axiosRequestWrapper,
                () => Service.cancelRequest(controller),
            ];
        }

        return axiosRequestWrapper;
    }

    static getRequest<TResponseData, R = AxiosResponse<TResponseData>>(url: string, {
        isSecure = false,
        type = ServiceTypes.DFS,
        axiosSetup,
        isAutoCancel = false,
        withCancel = false,
        cache = false,
    }:RequestOptions = defaultOptions):Promise<R>|[Promise<R>, () => void] {
        return Service.request(
            {
                isSecure, type, axiosSetup, isAutoCancel, withCancel, cache,
            },
            { url, method: 'get' },
        );
    }

    static postRequest<TPayload, TResponseData, R = AxiosResponse<TResponseData>>(url:string, data:TPayload, {
        isSecure = false,
        type = ServiceTypes.DFS,
        axiosSetup = {},
        isAutoCancel = false,
        withCancel = false,
        cache = false,
    }:RequestOptions = defaultOptions):Promise<R>|[Promise<R>, () => void] {
        return Service.request(
            {
                isSecure, type, axiosSetup, isAutoCancel, withCancel, cache,
            },
            { url, method: 'post', data },
        );
    }

    static putRequest<TPayload, TResponseData, R = AxiosResponse<TResponseData>>(
        url:string,
        data:TPayload,
        {
            isSecure = false,
            type = ServiceTypes.DFS,
            axiosSetup,
        }:RequestOptions = defaultOptions,
    ):Promise<R> {
        return axios.put(url, data, Service.secureSetup(isSecure, type, axiosSetup));
    }

    /** @method
     * @name patchRequest
     *  wrapper for axios patch request
     *  @param {string} url - url.
     *  @param {object} data - data.
     *  @param {object=} setup - setting for request.
     *  @param {boolean=} setup.isSecure - isSecure request.
     *  @param {string=} setup.type - type request.
     *  @param {object=} setup.axiosSetup - another axios settings.
     */
    static patchRequest<TPayload, TResponseData, R = AxiosResponse<TResponseData>>(
        url:string,
        data:TPayload,
        {
            isSecure = false,
            type = ServiceTypes.DFS,
            axiosSetup,
        }:RequestOptions = defaultOptions,
    ):Promise<R> {
        return axios.patch(url, data, Service.secureSetup(isSecure, type, axiosSetup));
    }

    /** @method
     * @name deleteRequest
     *  wrapper for axios delete request
     *  @param {string} url - url.
     *  @param {object=} setup - setting for request.
     *  @param {boolean=} setup.isSecure - isSecure request.
     *  @param {string=} setup.type - type request.
     *  @param {object=} setup.axiosSetup - another axios settings.
     */
    static deleteRequest<TResponseData, R = AxiosResponse<TResponseData>>(
        url:string,
        {
            isSecure = false,
            type = ServiceTypes.DFS,
            axiosSetup,
        }:RequestOptions = defaultOptions,
    ):Promise<R> {
        return axios.delete(url, Service.secureSetup(isSecure, type, axiosSetup));
    }
}

export default Service;
