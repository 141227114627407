/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';
import Icon, {ICON_INFO_OUTLINE} from 'ui-library/components/Icon';
import './StatusBox.css';

export enum Status {
    Info,
    Warning,
    Danger
}

type StatusBoxProps = {
    status: Status;
    title?: string;
    description: string;
    showIcon?: boolean;
    className?: string;
}

export const StatusBox = ({
    status,
    title,
    description,
    className,
    showIcon = false,
}: StatusBoxProps) => {
    const statusClass = {
        [Status.Info]: 'info',
        [Status.Warning]: 'warning',
        [Status.Danger]: 'danger',
    }[status];

    return (
        <div className={`StatusBox ${statusClass} ${className}`}>
            {/* TODO: add other icons when/if needed */}
            {showIcon
                && (
                    <div>
                        {/* @ts-ignore */}
                        <Icon type={ICON_INFO_OUTLINE} size={24}/>
                    </div>
                )}
            <div className="body">
                {title && <p className="boxTitle">{title}</p>}
                <p>{description}</p>
            </div>
        </div>
    );
};
