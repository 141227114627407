import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import DefaultContent from './DefaultContent';
import '../Footer.css';
import withTheme from '../../../hocs/withTheme';

/** Use the mini footer when required. */

function FooterMini({
    children, link, title, logo,
}) {
    return (
        <footer className="Footer Footer_mini">
            <div className="container Footer__bottom">
                {children}
            </div>
        </footer>
    );
}

FooterMini.propTypes = {
    /** @ignore */
    children: PropTypes.node,
    /** Logo link */
    link: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    /** Alternative text for logo image and title attribute for the link */
    title: PropTypes.string,
    /** Source for the Logo */
    logo: PropTypes.node,
};

FooterMini.defaultProps = {
    children: <DefaultContent />,
    link: '/',
    title: 'additiv AG',
    logo: undefined,
};

export default withTheme(FooterMini);
