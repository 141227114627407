import React from 'react';
import PropTypes from 'prop-types';

function CellWithCurrentNewValue(data, valueLabels) {
    return (
        <div className="table-cell_with-current-new">
            { data.currentValue === data.newValue
                ? data.currentValue
                : (
                    <>
                        <div>
                            <span>{valueLabels.current}</span>
                            <span>{data.currentValue}</span>
                        </div>
                        <div>
                            <span><b>{valueLabels.new}</b></span>
                            <span><b>{data.newValue}</b></span>
                        </div>
                    </>
                )
            }
        </div>
    );
}

export default CellWithCurrentNewValue;

CellWithCurrentNewValue.propTypes = {
    data: PropTypes.shape({
        newValue: PropTypes.string,
        currentValue: PropTypes.string,
    }).isRequired,
    valueLabels: PropTypes.shape({
        current: PropTypes.string,
        new: PropTypes.string,
    }),
};

CellWithCurrentNewValue.defaultProps = {
    valueLabels: {
        current: 'current',
        new: 'new',
    },
};
