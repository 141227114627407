import { useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { PortfolioVolatilityListResponse } from 'core/types/api/Api';
import ServerError from 'errors/ServerError';
import { useTranslation } from 'react-i18next';
import SM from 'services/ServiceManager';
import { notification } from 'ui-library';

export const useGetPortfolioVolatility = ({isEnabled}) => {
    const {t} = useTranslation();
    const getPortfolioVolatility = async () => {
        try {
            const response: AxiosResponse<PortfolioVolatilityListResponse> = await SM.customAdvisoryServices(
                'getPortfolioVolatility',
            );

            return response;
        } catch (error: any) {
            notification.open({content: `${t('contactGroups.somethingWentWrong')} ${error.message}`, type: 'error'});

            return null;
        }
    };
    const result = useQuery({
        queryKey: ['getPortfolioVolatility'],
        enabled: isEnabled,
        retry: false,
        queryFn: getPortfolioVolatility,
    });

    return {
        // ...result,
        data: result?.data?.data,
        statusCode: result?.data?.status,
        error: result.error ? new ServerError(result.error) : result.error,
    };
};
