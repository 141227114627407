import React from 'react';
import PropTypes from 'prop-types';
import HighchartsReact from 'highcharts-react-official';
import HighchartsMore from 'highcharts/highcharts-more';
import Highcharts from 'highcharts';
import { getScatterChartOptions } from './utils';
import Labels from './Labels';
import Legend from './Legend';
import withTheme from '../../../hocs/withTheme';
import './RiskReturnChart.css';

HighchartsMore(Highcharts);

function RiskReturnChart(props) {
    const {
        data,
        matrix,
        height,
        minX,
        maxX,
        minY,
        maxY,
        xLabels,
        yLabels,
        tooltipFormat,
    } = props;

    return (
        <div className="Risk-return-container">
            <div className="Risk-return-chart-container">
                <HighchartsReact
                    containerProps={{ className: 'Highcharts-risk-return' }}
                    options={getScatterChartOptions(
                        data,
                        matrix,
                        height,
                        minX,
                        maxX,
                        minY,
                        maxY,
                        xLabels,
                        yLabels,
                        tooltipFormat,
                    )}
                />
                <Labels xLabels={xLabels} yLabels={yLabels} />
            </div>
            <Legend data={data} matrix={matrix} />
        </div>
    );
}

RiskReturnChart.propTypes = {
    /** Data to be shown on chart */
    data: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
        data: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)).isRequired,
    })),
    /** External polygon data to be shown on chart */
    matrix: PropTypes.shape({
        x: PropTypes.arrayOf(PropTypes.number),
        y: PropTypes.arrayOf(PropTypes.number),
        name: PropTypes.string,
    }),
    /** Height of chart */
    height: PropTypes.number,
    /** The min value for x axis */
    minX: PropTypes.number,
    /** The max value for x axis */
    maxX: PropTypes.number,
    /** The min value for y axis */
    minY: PropTypes.number,
    /** The max value for y axis */
    maxY: PropTypes.number,
    /** The labels for x axis */
    xLabels: PropTypes.arrayOf(PropTypes.string),
    /** The labels for y axis */
    yLabels: PropTypes.arrayOf(PropTypes.string),
    /** Formatter for tooltip */
    tooltipFormat: PropTypes.func,
};

RiskReturnChart.defaultProps = {
    data: [],
    matrix: null,
    height: 400,
    minX: 0,
    maxX: 10,
    minY: 0,
    maxY: 20,
    xLabels: [],
    yLabels: [],
    tooltipFormat: (name, x, y) => `${name}: ${x}/${y}`,
};

export default withTheme(RiskReturnChart);
