import {
    SecurityExtended,
    Securities,
    SearchSecurities,
    PriceHistory,
    PerformanceHistory,
} from 'additiv-services/types/services/instruments';
import { getServicesUrl } from '../../setup';
import { APIVersion } from '../../types/main';
import Service from './service';
import { getQueryStringByObject } from '../../utils/queryUtils';

class Instruments extends Service {
    static getSecurity(securityId: number, language = 'en') {
        return Service.getRequest(`${getServicesUrl(APIVersion.V11)}/securities/${securityId}?language=${language}`, { isSecure: true });
    }

    static getSecurityExtended(securityId: number, params: SecurityExtended) {
        const queryString = getQueryStringByObject(params);

        return Service.getRequest(`${getServicesUrl(APIVersion.V11)}/securities/${securityId}?${queryString}`, { isSecure: true });
    }

    /*
     * Get Securities by Ids
     * @param data
     * { "SecurityIds": [4, 5],
         "Language": "en"
       }
    */
    static getSecurities(data: Securities) {
        return Service.postRequest(`${getServicesUrl()}/securities`, data, { isSecure: true });
    }

    static postSecurities(filter: Securities) {
        return Service.postRequest(`${getServicesUrl()}/securities`, filter, { isSecure: true });
    }

    static searchSecurities(productId: number, filter: SearchSecurities, params: any) {
        const queryString = getQueryStringByObject(params);

        return Service.postRequest(`${getServicesUrl(APIVersion.V11)}/products/${productId}/securities/search?${queryString}`, filter, { isSecure: true });
    }

    static postPriceHistory(securityId: number, data: PriceHistory) {
        return Service.postRequest(`${getServicesUrl(APIVersion.V11)}/securities/${securityId}/pricehistory`, data, { isSecure: true });
    }

    static postPerformanceHistory(securityId: number, data: PerformanceHistory) {
        return Service.postRequest(`${getServicesUrl()}/securities/${securityId}/performancehistory`, data, { isSecure: true });
    }

    static getSecComponents(securityId: number) {
        return Service.getRequest(`${getServicesUrl()}/securities/${securityId}/components`, { isSecure: true });
    }

    static getSecAllocations(securityId: number) {
        return Service.getRequest(`${getServicesUrl()}/securities/${securityId}/allocations`, { isSecure: true });
    }
}

export default Instruments;
