import moment from 'moment';
import {useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import ServiceManager from '../../../../../services/ServiceManager';
import handlerRequestCanceling from '../../../../../utils/handlerRequestCanceling';
import HandlerError from '../../../../../errors/HandlerError';

export const useClientActivationHistory = ({contactId, adapter}) => {
    const {t} = useTranslation();
    const [data, setData] = useState();
    const [isLastResend24hAgo, setLastResend24hAgo] = useState(false);
    const [isUserRegistered, setUserRegistered] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState();

    const getData = useCallback(async () => {
        try {
            setLoading(true);
            const response = await ServiceManager.contactActivation('getContactActivationHistory', [{contactId}]);

            const lastResendActivationCode = response.data.filter(
                (item) => ([1, 2].includes(item?.activationStatus)),
            )?.reverse()?.[0]?.modifiedDate;

            setUserRegistered(response.data.filter(
                (item) => (item?.activationStatus === 6),
            )?.length > 0);

            setLastResend24hAgo(
                lastResendActivationCode
                    ? moment.utc(lastResendActivationCode).add(24, 'h') < moment.utc()
                    : true,
            );

            setData(adapter({data: response.data, t}));
        } catch (err) {
            if (err?.response?.status === 404) {
                setData(undefined);
            } else {
                handlerRequestCanceling(
                    HandlerError({
                        setError,
                        setLoading,
                    }),
                )(err);
            }
        } finally {
            setLoading(false);
        }
    }, [contactId, t]);

    return {
        isLastResend24hAgo,
        isUserRegistered,
        data,
        getData,
        isLoading,
        error,
    };
};
