import React, {useCallback, useMemo, useState} from 'react';

import {date, object, ObjectSchema } from 'yup';
import {Controller, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import FileSaver from 'file-saver';
import moment from 'moment/moment';

import Card from '../../../components/Card/Card';
import ServiceManager from '../../../services/ServiceManager';

import {Button, DatePicker, Loader} from '../../../ui-library';
import {useYupValidationResolver} from '../../../hooks/useYupValidationResolver';
import {compareDates, dateFormatZurich, formatDateWithoutLocalConversion} from '../../../utils/datetime';

import { FromToDate, ReportFormProps } from './ReportForm.types';

import '../Reports.css';

const ReportForm:React.FC<ReportFormProps> = ({ title = '', APIName = null, savedFileName = 'Report' }) => {
    const {t} = useTranslation();
    const [inProgress, setInProgress] = useState<boolean>(false);

    const schema: ObjectSchema<FromToDate> = useMemo(() => object().shape({
        fromDate: date().required(t('validation.mandatoryField')).nullable(),
        toDate: date().required(t('validation.mandatoryField')).nullable(),
    }), [t]);

    const resolver = useYupValidationResolver(schema);

    const {
        handleSubmit, formState: {errors}, control, watch,
    } = useForm({
        resolver,
        defaultValues: {
            fromDate: null,
            toDate: null,
        },
    });

    const {toDate: toDateValue, fromDate: fromDateValue} = watch();

    const onReportClick = useCallback(handleSubmit(async (values) => {
        try {
            setInProgress(true);
            const payload: {from: string; to: string;} = {
                from: formatDateWithoutLocalConversion(values.fromDate, moment.defaultFormatUtc),
                to: formatDateWithoutLocalConversion(values.toDate, moment.defaultFormatUtc),
            };
            const {data, headers} = await ServiceManager.customInvestmentService(APIName, [payload]);

            FileSaver.saveAs(new Blob([data], {type: headers['content-type'] }), `${savedFileName}.xlsx`);
        } finally {
            setInProgress(false);
        }
    }), [APIName, savedFileName]);

    return (
        <Card title={title} className="daw">
            <Controller
                control={control}
                name="fromDate"
                render={
                    ({field}) => (
                        <DatePicker
                            {...field}
                            format={dateFormatZurich as string}
                            data-cy="fromDate"
                            error={errors?.fromDate?.message}
                            label={t('clientReports.fromDate.label')}
                            disabledDate={(current) => current && compareDates(current, toDateValue)}
                            required
                        />
                    )
                }
            />
            <Controller
                control={control}
                name="toDate"
                render={
                    ({field}) => (
                        <DatePicker
                            {...field}
                            format={dateFormatZurich}
                            data-cy="toDate"
                            error={errors?.toDate?.message}
                            label={t('clientReports.toDate.label')}
                            disabledDate={(current) => current && (compareDates(current, moment().subtract(1, 'day')) || compareDates(moment(fromDateValue), current))}
                            required
                        />
                    )
                }
            />
            <Button type="primary" onClick={onReportClick} disabled={inProgress}>{inProgress ? <Loader /> : t('reports.generateReport')}</Button>
        </Card>
    );
};

export default ReportForm;
