import React, {useEffect, useMemo} from 'react';
import {
    Navigate, Route, Routes, useLocation, useParams,
} from 'react-router-dom';
import useLeaveRoute from 'hooks/useLeaveRoute';
import {getClientId} from 'utils';
import {PRODUCTS} from 'constants/constants';
import {
    useAdvisorySetup,
} from 'pages/ClientOverview/pages/Portfolios/pages/CreateNewPortfolio/pages/AdvisoryDocument/hooks/useAdvisorySetup';
import {
    useAdvisoryDocument,
} from 'pages/ClientOverview/pages/Portfolios/pages/CreateNewPortfolio/pages/AdvisoryDocument/hooks/useAdvisoryDocument';
import {ConnectionsManager} from 'components/Connections/components';
import PersonalDetails
    from 'pages/ClientOverview/pages/Portfolios/pages/CreateNewPortfolio/pages/PersonalDetails/PersonalDetails';
import CreateNewPortfolio from './CreateNewPortfolio';
import {useNewPortfolioData} from './hooks/useNewPortfolioData';
import {QualityCheckWithProvider} from './pages/QualityCheck/with-provider';
import StrategySelectionPage from './pages/StrategySelection/StrategySelectionPage';
import AgentInformation from './pages/AgentInformation';
import {GoalAndPortfolioSetup} from './pages/GoalAndPortfolioSetup';
import {AdvisoryDocument} from './pages/AdvisoryDocument';
import Overview from './pages/Overview';
import {Finalization} from './pages/Finalization';
import {CreateNewPlanProvider} from './context';
import {PlanWizardLayoutProvider} from '../../components/PlanWizardLayout';
import {steps} from './constants';
import useCommonAllocationDataProvider from '../../context/useCommonAllocationDataProvider.';
import {AdvisoryDocumentSetup} from './pages/AdvisoryDocument/AdvisoryDocumentSetup';
import {ProductSelection} from './pages/ProductSelection/ProductSelection';
import WithdrawalPlan from './pages/WithdrawalPlan/WithdrawalPlan';
import KYCPage from './pages/KYC/KYCPage';
import FeesOverview from './pages/FeesOverview';
import KYCDocs from './pages/KYC/KYCDocs';
import use3bStepper from '../../hooks/use3bStepper';
import {InvestorProfiles} from './pages/InvestorProfile/InvestorProfiles';
import {useWithdrawalPlan} from './pages/WithdrawalPlan/hooks/useWithdrawalPlan';

import './useNewPortfolioLayout.css';

const Router = () => {
    const {state, pathname} = useLocation();
    const {dfsClientId} = useParams();
    const clientId = getClientId(dfsClientId);
    const url = useMemo(() => pathname.split('/').slice(0, 5).join('/'), [pathname]);

    const {clearNewPortfolio, clearModifiedPositions} = useNewPortfolioData();

    const {selectedProductType} = useCommonAllocationDataProvider();
    const {threeBSteps} = use3bStepper();

    useLeaveRoute(url, () => {
        clearNewPortfolio();
        clearModifiedPositions();
    });

    useEffect(() => () => {
        clearNewPortfolio();
        clearModifiedPositions();
    }, []);

    return (
        <ConnectionsManager clientId={clientId}>
            <PlanWizardLayoutProvider
                steps={selectedProductType === PRODUCTS.bbb ? threeBSteps : steps[selectedProductType] || []}
                withProductGroupName
            >
                <CreateNewPlanProvider {...{investmentApplicationId: state?.investmentApplicationId, clientId}}>
                    <Routes>
                        <Route exact path="" element={<CreateNewPortfolio/>}/>
                        <Route exact path="personal-details" element={<PersonalDetails/>}/>
                        <Route
                            exact
                            path="advisory-document-init"
                            element={<AdvisoryDocumentSetup useData={useAdvisorySetup}/>}
                        />
                        <Route
                            exact
                            path="advisory-document"
                            element={<AdvisoryDocument useData={useAdvisoryDocument}/>}
                        />
                        <Route exact path="product-selection" element={<ProductSelection/>}/>
                        <Route exact path="investor-profile" element={<InvestorProfiles/>}/>
                        <Route exact path="strategy-selection" element={<StrategySelectionPage/>}/>
                        <Route exact path="goal-setup" element={<GoalAndPortfolioSetup/>}/>
                        <Route exact path="agent-information" element={<AgentInformation/>}/>
                        <Route exact path="withdrawal-plan" element={<WithdrawalPlan useData={useWithdrawalPlan}/>}/>
                        <Route exact path="kyc" element={<KYCPage/>}/>
                        <Route exact path="fees-overview" element={<FeesOverview/>}/>
                        <Route exact path="overview" element={<Overview/>}/>
                        <Route exact path="kyc" element={<KYCPage/>}/>
                        <Route exact path="upload-id" element={<KYCDocs/>}/>
                        <Route exact path="quality-check" element={<QualityCheckWithProvider/>}/>
                        <Route exact path="opening" element={<Finalization/>}/>

                        <Route
                            path="*"
                            element={<Navigate to="/404" replace/>}
                        />
                    </Routes>
                </CreateNewPlanProvider>
            </PlanWizardLayoutProvider>
        </ConnectionsManager>
    );
};

Router.displayName = 'Router';

export default Router;
