import PropTypes from 'prop-types';
import React from 'react';
import { QualityCheckContext } from './context';
import { useFormData } from './hooks/useFormData';
import { useQuestionnaire } from './hooks/useQuestionnaire';

export const QualityCheckProvider = ({ options, children }) => {
    const { clientId, productId } = options;
    const questionnaire = useQuestionnaire({ productId, clientId });
    const formData = useFormData({ serverData: questionnaire?.data });
    const getValue = (callback) => callback({
        clientId,
        questionnaire,
        formData,
    });

    return (
        <QualityCheckContext.Provider
            value={{
                clientId, questionnaire, formData, getValue,
            }}
        >
            {children}
        </QualityCheckContext.Provider>
    );
};

QualityCheckProvider.propTypes = {
    options: PropTypes.shape({
        clientId: PropTypes.number.isRequired,
        productId: PropTypes.number.isRequired,
    }).isRequired,
};
