import React, {useEffect, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import moment from 'moment';
import SinglePortfolioContext from './context';
import {navigationPages} from '../components/SinglePortfolioLayout/constants';
import {portfolioSelector, usePortfolioSelector} from '../../../../../../../domain/Portfolio';
import {useProductsList} from '../../../../../../../hooks/useProducts';
import Preloader from '../../../../../../../components/Preloader';
import SinglePortfolioLayout from '../components/SinglePortfolioLayout/SinglePortfolioLayout';
import {useShadowAccounts} from '../../../../../../../hooks/useShadowAccounts';
import {useStrategy} from '../pages/Strategy/hooks/useStrategy';
import {NEW_3B_RETURN_DATE, NEW_RETURN_DATE} from '../constants';
import {dateFormatZurich} from '../../../../../../../utils/datetime';
import {isAnyOfThreeBProductTypes, useThreeBProductType} from '../../../../../../../hooks/isThreeBProduct';
import {useManagerProfile} from '../../../../../../../prodivers/managerProfile';

type TSinglePortfolioProvider = {
    dfsClientId: string,
    portfolioId: number,
    children: JSX.Element
}

const SinglePortfolioProvider = ({
    dfsClientId,
    portfolioId,
    children,
}: TSinglePortfolioProvider) => {
    const {t} = useTranslation();
    const { hasAccessPolicy } = useManagerProfile();

    const strategy = useStrategy({portfolioId, dfsClientId});

    const portfolioDetail = usePortfolioSelector(portfolioSelector);
    const {
        isLoadingProducts,
        products,
        getProducts,
    } = useProductsList();
    const {
        data, dataRaw, isLoading, error,
    } = portfolioDetail;

    const {getSaldo, saldo} = useShadowAccounts();

    useEffect(() => {
        getProducts();
    }, []);

    useEffect(() => {
        getSaldo(portfolioId);
    }, [portfolioId]);

    const selectedProduct = useMemo(() => products?.find(f => f.id === data?.productId), [products, data?.productId]);
    const selectedProductSettings = useMemo(() => selectedProduct?.settings, [selectedProduct?.settings]);

    const productType = useThreeBProductType({product: {externalId: selectedProduct?.externalId}, isJointAccount: dataRaw?.IsJointAccount});

    const returnDate = useMemo(() => (isAnyOfThreeBProductTypes(selectedProduct?.externalId) ? NEW_3B_RETURN_DATE : NEW_RETURN_DATE), [selectedProduct?.externalId]);
    const isMigratedPortfolio = useMemo(() => (moment(returnDate, dateFormatZurich).isAfter(moment(data?.customAttributes?.CreateDate, dateFormatZurich))), [data?.customAttributes?.CreateDate, returnDate]);
    const migrationDate = useMemo(() => {
        if (!isMigratedPortfolio) return null;

        return returnDate;
    }, [isMigratedPortfolio, returnDate]);

    const isPortfolioChangeable = useMemo(() => (
        strategy.isPortfolioChangeable && hasAccessPolicy(selectedProductSettings?.AccessPolicy)
    ), [strategy.isPortfolioChangeable, selectedProductSettings?.AccessPolicy, hasAccessPolicy]);
    const navigation = navigationPages({
        portfolioId,
        dfsClientId,
        t,
        showDeposits: selectedProductSettings?.showDeposits,
        showDetails: selectedProductSettings?.showDetails,
        showStrategy: selectedProductSettings?.showStrategy,
    });

    const state = {
        portfolioDetail: data,
        portfolioDetailRaw: dataRaw,
        navigation,
        portfolioName: data?.title ?? '',
        productName: data?.overview?.product,
        productNameRisk: data?.overview ? `${data.overview.product}- ${data.overview.strategy}` : '',
        selectedProductSettings: selectedProductSettings ?? {},
        saldo,
        isMigratedPortfolio,
        migrationDate,
        ...productType,
        ...strategy,
        isPortfolioChangeable,
    };

    return (
        <SinglePortfolioContext.Provider
            value={{ ...state }}
        >
            <Preloader isLoading={isLoading || isLoadingProducts} error={error}>
                <SinglePortfolioLayout portfolioId={portfolioId} navigation={navigation}>
                    {children}
                </SinglePortfolioLayout>
            </Preloader>
        </SinglePortfolioContext.Provider>
    );
};

SinglePortfolioProvider.propTypes = {

};

SinglePortfolioProvider.defaultProps = {};

export default SinglePortfolioProvider;
