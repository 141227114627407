import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Divider from 'ui-library/components/Divider';
import { Title } from 'ui-library';
import { applyThousandSeparator } from 'ui-library/components/NumberInput/utils';
import { AdvisorySectionProps } from './AdvisorySection.types';
import DocBox from '../DocBox';
import './AdvisorySection.css';
import { DocTypes } from 'datasource/useApplicationDetails/ApplicationDetailsManager.types';
import { useApplicationDetails } from 'datasource/useApplicationDetails';

const AdvisorySection: FC<AdvisorySectionProps> = ({
    data,
    isExpired,
    isAdvisoryCase,
    proofOfAssetDocuments,
    advisoryDocument,
    basicAgreementDocuments,
}) => {
    const { t } = useTranslation();
    const {
        advisoryNo,
        createdAt,
        validTill,
        investmentAmount,
    } = data;

    const {
        isJA,
        jaUsers,
    } = useApplicationDetails();

    return (
        <div className="advisory-section">
            {isJA && (
                <div className="ja-section">
                    <Title type="3">
                        {t('ApplicationDetails.JointAccount.Title')}
                    </Title>
                    <p className="ja-info-text">{`${jaUsers?.[0]?.fullName}, ${jaUsers?.[1]?.fullName}`}</p>
                </div>
            )}
            {isAdvisoryCase && (
                <Title type="3">
                    {t('ApplicationDetails.AdvisorySection.Title')}
                </Title>
            )}

            <div className="advisory-section--grid">
                {isAdvisoryCase && (
                    <>
                        <div key="text-data" className="advisory-section--grid-col">
                            <div className="application-info-grid">
                                <div className="application-info-grid-col">
                                    <Title type="5">
                                        {t('ApplicationDetails.AdvisorySection.AdvisoryNo')}
                                    </Title>
                                    {advisoryNo}
                                </div>
                                <Divider direction="vertical"/>
                                <div className="application-info-grid-col">
                                    <Title type="5">
                                        {t('ApplicationDetails.AdvisorySection.CreatedAt')}
                                    </Title>
                                    {createdAt}
                                </div>
                                <div className="application-info-grid-col">
                                    <Title type="5">
                                        {t('ApplicationDetails.AdvisorySection.ValidTill')}
                                    </Title>
                                    <span className={isExpired ? 'color-danger' : ''}>{validTill}</span>
                                </div>
                                <Divider direction="vertical"/>
                                <div className="application-info-grid-col">
                                    <Title type="5">
                                        {t('ApplicationDetails.AdvisorySection.InvestmentAmount')}
                                    </Title>
                                    CHF
                                    {' '}
                                    {applyThousandSeparator(investmentAmount.toString(), '’')}
                                </div>
                            </div>
                        </div>

                        <div key="doc" className="advisory-section--grid-col">
                            <DocBox
                                type={DocTypes.ADVISORY}
                                uploadStatus={advisoryDocument?.status}
                                documentName={advisoryDocument?.documentName}
                                documentId={advisoryDocument?.documentId}
                            />
                        </div>
                    </>
                )}
                <div className="flex-column general-docs-wrapper">
                    <Title type="3">
                        {t('ApplicationDetails.AdvisorySection.GeneralDocument')}
                    </Title>
                    <div className="advisory-section--grid">
                        {!!basicAgreementDocuments.length && (
                            <div className="advisory-section--grid-col">
                                {basicAgreementDocuments.map((doc) => (
                                    <DocBox
                                        type={DocTypes.BASIC_AGREEMENT}
                                        contactId={doc?.contactId}
                                        uploadStatus={doc?.status}
                                        documentName={doc?.documentName}
                                        documentId={doc?.documentId}
                                    />
                                ))}
                            </div>
                        )}
                        {!!proofOfAssetDocuments.length && (
                            <div className="advisory-section--grid-col">
                                {proofOfAssetDocuments?.map((doc) => (
                                    <DocBox
                                        type={DocTypes.PROOF_OF_ASSETS}
                                        contactId={doc?.contactId}
                                        uploadStatus={doc.status}
                                        documentId={doc?.documentId}
                                        documentName={doc.documentName}
                                        originOfAsset={doc?.originOfAsset}
                                    />
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdvisorySection;
