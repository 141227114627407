import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {iconsMap} from './types';
import {getClassNames} from '../../utils';

import './Icon.css';
import withTheme from '../../hocs/withTheme';

/** Semantic vector graphics. */

function Icon({
    size, type, color = null, ariaLabel = null, component=null, className: classNameProp = '', ...rest
}) {
    const Icon = iconsMap.get(type) || component;
    const iconProps = useMemo(() => ({
        fill: 'currentColor',
        width: '1em',
        height: '1em',
        focusable: false,
        'aria-hidden': true,
    }), []);
    const className = getClassNames(classNameProp, 'Icon', { [`Icon-${type}`]: type });

    if (!Icon) return null;

    return (
        <i
            {...rest}
            className={className}
            aria-label={ariaLabel}
            style={{ fontSize: `${size}px`, color }}
        >
            <Icon {...iconProps}></Icon>
        </i>
    );
}

Icon.propTypes = {
    /** Can be set to 'questionmark', 'verified', 'error',
     * 'infobox', 'link' etc. for custom theme.
     * See more on the link to the additional ant design props. */
    type: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
    ]),
    /** Icon size in pixels. Should be a number */
    size: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    /** Style the icon with color */
    color: PropTypes.string,
    /** The aria-label for icon */
    ariaLabel: PropTypes.string,
    /** Custom component that can be displayed as icon
     * (can be used optionally instead of 'type' property) */
    component: PropTypes.node,
    /** Custom class name */
    className: PropTypes.string,
};

Icon.defaultProps = {
    type: null,
    size: '1em',
    color: null,
    ariaLabel: null,
    component: null,
    className: null,
};

export default withTheme(Icon);
