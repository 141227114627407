import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../Icon';
import {
    ICON_TYPE_ERROR, ICON_TYPE_TOOLTIP, ICON_TYPE_VERIFIED, ICON_TYPE_SEARCH,
} from '../Icon/constants';

function TextInputIcon({
    errorIcon, questionmark, errorInTooltip, verified, error, search, ...rest
}) {
    let iconName;

    if (!error) {
        if (questionmark) {
            iconName = ICON_TYPE_TOOLTIP;
        }
        if (verified) {
            iconName = ICON_TYPE_VERIFIED;
        }
        if (search) {
            iconName = ICON_TYPE_SEARCH;
        }
    } else if (errorIcon || errorInTooltip) {
        iconName = ICON_TYPE_ERROR;
    }

    return iconName ? (
        <Icon {...rest} type={iconName} />
    ) : <div />;
}

TextInputIcon.propTypes = {
    errorIcon: PropTypes.bool,
    questionmark: PropTypes.bool,
    errorInTooltip: PropTypes.bool,
    verified: PropTypes.bool,
    search: PropTypes.bool,
    error: PropTypes.string,
};

TextInputIcon.defaultProps = {
    errorIcon: false,
    questionmark: false,
    errorInTooltip: false,
    verified: false,
    search: false,
    error: null,
};

export default TextInputIcon;
