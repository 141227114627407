import React, {
    useState, useRef, useEffect, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { Calendar as CalendarBig } from 'react-big-calendar';
import DateCell from './DateCell';
import TimeEvent from './TimeEvent';
import WeekHeader from './WeekHeader';
import HeaderPanel from './HeaderPanel';
import EventPopover from './EventPopover';
import EventContainer from './EventContainerWrapper';
import TimeSlotWrapper from './TimeSlotWrapper';
import { COLORS } from './constants';
import { formatEvents, dateFormat } from './utils';
import { datePickerLocalePropTypes } from '../DatePicker/propTypes';
import withTheme from '../../hocs/withTheme';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './Calendar.css';

// TODO: when rewriting components,
//       unify locale and formats to one object
//       for Calendar and DatePicker components
function Calendar({
    data,
    locale,
    datePickerLabels,
    dayFormat,
    fullDayFormat,
    firstDayFormat,
    weekdaysFormat,
    fullMonthFormat,
    weekdaysShortFormat,
    views,
    localizer,
    onDateChange,
    onRangeChange,
    ...labels
}) {
    const calendar = useRef({});
    const [view, setView] = useState(views[0]);
    const events = formatEvents(data);
    const [activeDate, setActiveDate] = useState(new Date());

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        if (typeof onDateChange === 'function') onDateChange(activeDate);
    }, [activeDate]);

    const onView = (viewChanged) => setView(viewChanged);
    const showMore = (total) => `${total} ${labels.more}...`;
    const eventPropGetter = (event) => (event.color ? { className: `event-${event.color}` } : {});
    /* eslint-disable react-hooks/exhaustive-deps */
    const Header = useMemo(() => (props) => HeaderPanel({
        ...props,
        view,
        views,
        labels,
        locale,
        onChange: setView,
        setActiveDate,
        fullDayFormat,
        fullMonthFormat,
        weekdaysShortFormat,
        datePickerLabels,
    }), [locale]);

    const TimeSlot = (props) => TimeSlotWrapper({ ...props, locale });

    return (
        <div className="calendar-wrapper" ref={calendar}>
            <CalendarBig
                className={`calendar calendar-${view}`}
                step={30}
                view={view}
                views={views}
                onView={onView}
                events={events}
                formats={{
                    dateFormat: dateFormat(dayFormat, firstDayFormat),
                    weekdayFormat: weekdaysShortFormat,
                    dayFormat: weekdaysFormat,
                    dayHeaderFormat: weekdaysFormat,
                }}
                messages={{ showMore }}
                localizer={localizer}
                eventPropGetter={eventPropGetter}
                onRangeChange={(values) => { if (typeof onRangeChange === 'function') onRangeChange(values); }}
                components={{
                    toolbar: Header,
                    eventWrapper: (props) => EventPopover({ ...props, view }),
                    dateCellWrapper: DateCell,
                    timeSlotWrapper: TimeSlot,
                    timeGutterHeader: () => labels.allDay,
                    eventContainerWrapper: (props) => EventContainer({ ...props, locale }),
                    week: {
                        event: (props) => TimeEvent({ ...props, locale }),
                        header: (props) => WeekHeader({ dayFormat, activeDate, ...props }),
                    },
                    day: {
                        event: (props) => TimeEvent({ ...props, locale }),
                    },
                }}
            />
        </div>
    );
}

Calendar.propTypes = {
    /** The Calendar data */
    data: PropTypes.arrayOf(PropTypes.shape({
        color: PropTypes.oneOf(COLORS),
        title: PropTypes.string.isRequired,
        startDate: PropTypes.string,
        endDate: PropTypes.string,
        allDay: PropTypes.bool,
        fewDays: PropTypes.bool,
        contacts: PropTypes.arrayOf(PropTypes.shape({
            name: PropTypes.string,
            link: PropTypes.string,
        })),
    })),
    /** Default calendar views */
    views: PropTypes.arrayOf(PropTypes.oneOf(['month', 'week', 'day'])),
    /** Locale text for 'more' text */
    more: PropTypes.string,
    /** Locale text for 'day' text */
    day: PropTypes.string,
    /** Locale text for 'week' text */
    week: PropTypes.string,
    /** Locale text for 'month' text */
    month: PropTypes.string,
    /** Locale text for 'today' text */
    today: PropTypes.string,
    /** Locale language for 'All day' naming */
    allDay: PropTypes.string,
    /** Locale language for months naming */
    locale: PropTypes.string,
    datePickerLabels: datePickerLocalePropTypes,
    /** Object for all possible formats */
    dayFormat: PropTypes.string,
    localizer: PropTypes.shape({
        format: PropTypes.func,
        startOfWeek: PropTypes.func,
    }),
    fullDayFormat: PropTypes.string,
    firstDayFormat: PropTypes.string,
    fullMonthFormat: PropTypes.string,
    weekdaysFormat: PropTypes.string,
    weekdaysShortFormat: PropTypes.string,
    onDateChange: PropTypes.func,
    onRangeChange: PropTypes.func,
};

Calendar.defaultProps = {
    data: null,
    views: ['month', 'week', 'day'],
    locale: 'en',
    more: 'more',
    day: 'Day',
    week: 'Week',
    month: 'Month',
    today: 'Today',
    allDay: 'All day',
    datePickerLabels: undefined,
    dayFormat: 'D',
    localizer: {
        startOfWeek: () => {},
        format: () => {},
    },
    fullDayFormat: 'MMMM Do',
    firstDayFormat: 'MMMM Do',
    fullMonthFormat: 'MMMM',
    weekdaysFormat: 'ddd D',
    weekdaysShortFormat: 'dddd',
    onDateChange: () => {},
    onRangeChange: () => {},
};

export default withTheme(Calendar);
