export const tooltipPositioner = ({
    labelWidth, labelHeight, point, chart, yTitle,
}) => {
    const arrow = 11;
    const margin = 10;
    const xDiff = chart.plotLeft - 0.5 * labelWidth;
    const yDiff = -1 * labelHeight - arrow + (yTitle !== undefined && yTitle !== '' ? 30 : 0);

    const isTooltipOnTop = point.plotY - labelHeight < 0;
    const isTooltipOnRight = point.plotX + labelWidth / 2 > chart.plotSizeX;
    const isTooltipOnLeft = point.plotX - labelWidth / 2 < 0;

    let xCorrection = 0;
    let yCorrection = 0;

    if (isTooltipOnLeft) {
        xCorrection = labelWidth / 2 + arrow + margin;
        yCorrection = labelHeight / 2 + arrow / 2 + margin * 1.5;
    }

    if (isTooltipOnRight) {
        xCorrection = -1 * labelWidth / 2 - arrow - margin;
        yCorrection = labelHeight / 2 + arrow / 2 + margin * 1.5;
    }

    if (isTooltipOnTop) {
        if (!isTooltipOnLeft && !isTooltipOnRight) {
            yCorrection = labelHeight + 30 + margin;
        }
    }

    return {
        x: point.plotX + xDiff + xCorrection,
        y: point.plotY + yDiff + yCorrection - margin,
    };
};
