import React from 'react';
import Icon from '../../Icon';

function CellWithIcon(value) {
    return (
        <div className="table-cell_icon">
            {value && <Icon type={value} />}
        </div>
    );
}

export default CellWithIcon;
