/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import {useTranslation} from 'react-i18next';
import {Controller, useForm} from 'react-hook-form';
import {
    number, object, string,
} from 'yup';

import { ICON_TYPE_PLUS } from 'ui-library/components/Icon';
import {
    Button, Column, FormGroup, Icon, Infobox, Loader, Modal, Row, Select, TextInput,
} from 'ui-library';
import { useYupValidationResolver } from 'hooks/useYupValidationResolver';
import { useOptionList } from 'hooks/useOptionList';
import Preloader from 'components/Preloader/Preloader';
import { FIELD_LENGTH } from 'pages/ClientOverview/constants';

import './NewAddressModal.css';
import {OPTION_LIST} from '../../../../../../constants/constants';

export const TARGET_FIELDS = [
    'mailRecipientDateOfBirth',
    'mailRecipientAddressLine1',
    'mailRecipientLanguage',
    'mailRecipientAddressLine5',
    'mailRecipientAddressLine2',
    'mailRecipientAddressLine3',
    'mailRecipientAddressLine4',
    'mailRecipientAddressCountry',
];

type NewAddressModalProps = {
    onSubmit: (data: any) => void;
    customRenderComponent?: React.ReactNode;
    outsideClickHappened?: boolean;
    setEditAddressModalOpen?: (value: boolean) => void;
    initData?: any;
}

type FormData = {
    mailRecipientLanguage: string | number;
    mailRecipientAddressLine1: string;
    mailRecipientAddressLine2: string;
    mailRecipientAddressLine3: string;
    mailRecipientAddressLine4: string;
    mailRecipientAddressLine5: string;
    mailRecipientAddressCountry: string | number;
}

function NewAddressModal({
    onSubmit, customRenderComponent, outsideClickHappened, setEditAddressModalOpen, initData,
}: NewAddressModalProps) {
    const { t } = useTranslation();
    const [isVisible, setVisible] = useState(false);
    const [isDataSending, setDataSending] = useState(false);
    const [generalError, setGeneralError] = useState<string | null>(null);

    const onAdd = useCallback(() => {
        setVisible(true);
    }, []);

    const schema = useMemo(() => object().shape({
        mailRecipientAddressCountry: number().typeError(t('validation.mandatoryField')).required(t('validation.mandatoryField')),
        mailRecipientLanguage: number().typeError(t('validation.mandatoryField')).required(t('validation.mandatoryField')),
        mailRecipientAddressLine5: string().required(t('validation.mandatoryField')).max(FIELD_LENGTH.city, t('onboardingFlow.personalPage.validateInputLength', {length: FIELD_LENGTH.city})),
        mailRecipientAddressLine1: string().max(FIELD_LENGTH.street, t('onboardingFlow.personalPage.validateInputLength', {length: FIELD_LENGTH.street})),
        mailRecipientAddressLine2: string(),
        mailRecipientAddressLine3: string().required(t('validation.mandatoryField')),
        mailRecipientAddressLine4: string().matches(/^[0-9e]+$/, t('validation.invalidNumber')).required(t('validation.mandatoryField')),
    }), [t]);

    const resolver = useYupValidationResolver(schema);

    const {
        control, handleSubmit, formState: { errors }, watch, register, reset, setError,
    } = useForm<FormData>({resolver});

    useEffect(() => {
        if (outsideClickHappened) {
            setVisible(true);
        } else {
            setVisible(false);
        }
    }, [outsideClickHappened]);

    const {
        lists: [optionCountry, optionLanguage], isLoading: isOptionsLoading,
    } = useOptionList([OPTION_LIST.country, OPTION_LIST.language]);

    const optionCountryAdapted = useMemo(() => (
        optionCountry?.map(({id, label}) => ({value: id, label})) ?? []
    ), [optionCountry]);

    const optionLanguageAdapted = useMemo(() => (
        optionLanguage?.map(({id, label}) => ({value: id, label})) ?? []
    ), [optionLanguage]);

    const initCountrySelected = useMemo(() => {
        if (initData?.mailRecipientAddressCountry) {
            return optionCountryAdapted.find(({value}) => value === initData?.mailRecipientAddressCountry);
        }

        return null;
    }, [initData]);

    const initLanguageSelected = useMemo(() => {
        if (initData?.mailRecipientLanguage) {
            const initialLanguage = optionLanguageAdapted.find(({value}) => value === initData?.mailRecipientLanguage);

            return initialLanguage;
        }

        return null;
    }, [initData]);

    const data = watch();

    const onOk = useCallback(async () => {
        try {
            setDataSending(true);

            setGeneralError(null);

            await onSubmit(data);

            setVisible(false);
            if (setEditAddressModalOpen) {
                setEditAddressModalOpen(false);
            }

            reset(data);
        } catch (e) {
            setGeneralError(t('advisoryDashboard.error'));
        } finally {
            setDataSending(false);
        }
    }, [data, t, setError]);

    const onCancel = useCallback(() => {
        setGeneralError(null);
        setVisible(false);
        if (setEditAddressModalOpen) {
            setEditAddressModalOpen(false);
        }
    }, []);

    useEffect(() => {
        if (initData && initCountrySelected && initLanguageSelected) {
            reset({
                ...initData,
                mailRecipientAddressCountry: initCountrySelected.value,
                mailRecipientLanguage: initLanguageSelected.value,
            });
        }
    }, [initCountrySelected, initLanguageSelected, initData]);

    return (
        <div className="MailingAddress">
            <FormGroup underline={false}>
                <>
                    {customRenderComponent || (
                        <legend>
                            <Button type="secondary" size="small" onClick={onAdd} className="action-button">
                                {/* @ts-ignore */}
                                <Icon type={ICON_TYPE_PLUS} size={24}/>
                                <span className="NewAddressModalLabel">{t('newAddressModal.button.label')}</span>
                            </Button>
                        </legend>
                    )}
                </>
                {isVisible && (
                    <Modal
                        className="MailingAddress__MailingAddressDialog"
                        title={t('dynamicCRM.mailRecipientFieldset')}
                        okText={t('confirmation.confirm')}
                        cancelText={t('confirmation.cancel')}
                        footer={(
                            <span>
                                <Button type="primary" disabled={isDataSending || isOptionsLoading} onClick={handleSubmit(onOk)}>
                                    {isDataSending ? <Loader /> : t('clientDashboard.portfolioEdit.submit')}
                                </Button>
                                <Button type="secondary" onClick={onCancel}>
                                    {t('clientDashboard.details.cancel')}
                                </Button>
                            </span>
                        )}
                        width="80%"
                        visible={isVisible}
                        onOk={onOk}
                        onCancel={onCancel}
                    >
                        <Preloader isLoading={isOptionsLoading}>
                            {/* @ts-ignore */}
                            <Row>
                                {/* @ts-ignore */}
                                <Column size="12">
                                    <Controller
                                        data-cy="mailRecipientLanguage"
                                        name="mailRecipientLanguage"
                                        control={control}
                                        render={({field}) => (
                                            <Select
                                                {...field}
                                                placeholder={t('position.pleaseSelect')}
                                                label={t('dynamicCRM.communicationMethods.mailRecipient.mailRecipientLanguage')}
                                                options={optionLanguageAdapted}
                                                error={errors?.mailRecipientLanguage?.message}
                                                value={initLanguageSelected}
                                                required
                                            />
                                        )}
                                    />
                                </Column>
                            </Row>
                            {/* @ts-ignore */}
                            <Row>
                                {/* @ts-ignore */}
                                <Column size="6">
                                    <TextInput
                                        {...register('mailRecipientAddressLine1')}
                                        data-cy="mailRecipientAddressLine1"
                                        name="mailRecipientAddressLine1"
                                        label={t('dynamicCRM.communicationMethods.mailRecipient.mailRecipientAddressLine1')}
                                        error={errors?.mailRecipientAddressLine1?.message}
                                    />
                                </Column>
                                {/* @ts-ignore */}
                                <Column size="6">
                                    <TextInput
                                        {...register('mailRecipientAddressLine2')}
                                        data-cy="mailRecipientAddressLine2"
                                        name="mailRecipientAddressLine2"
                                        label={t('dynamicCRM.communicationMethods.mailRecipient.mailRecipientAddressLine2')}
                                        error={errors?.mailRecipientAddressLine2?.message}
                                    />
                                </Column>
                            </Row>
                            {/* @ts-ignore */}
                            <Row>
                                {/* @ts-ignore */}
                                <Column size="6">
                                    <TextInput
                                        {...register('mailRecipientAddressLine3')}
                                        data-cy="mailRecipientAddressLine3"
                                        name="mailRecipientAddressLine3"
                                        label={t('dynamicCRM.communicationMethods.mailRecipient.mailRecipientAddressLine3')}
                                        error={errors?.mailRecipientAddressLine3?.message}
                                        required
                                    />
                                </Column>
                                {/* @ts-ignore */}
                                <Column size="6">
                                    <TextInput
                                        {...register('mailRecipientAddressLine4')}
                                        data-cy="mailRecipientAddressLine4"
                                        name="mailRecipientAddressLine4"
                                        label={t('dynamicCRM.communicationMethods.mailRecipient.mailRecipientAddressLine4')}
                                        error={errors?.mailRecipientAddressLine4?.message}
                                        required
                                    />
                                </Column>
                            </Row>
                            {/* @ts-ignore */}
                            <Row>
                                {/* @ts-ignore */}
                                <Column size="6">
                                    <TextInput
                                        {...register('mailRecipientAddressLine5')}
                                        data-cy="mailRecipientAddressLine5"
                                        name="mailRecipientAddressLine5"
                                        label={t('dynamicCRM.communicationMethods.mailRecipient.mailRecipientAddressLine5')}
                                        error={errors?.mailRecipientAddressLine5?.message}
                                        required
                                    />
                                </Column>
                                {/* @ts-ignore */}
                                <Column size="6">
                                    <Controller
                                        name="mailRecipientAddressCountry"
                                        control={control}
                                        render={({field}) => (
                                            <Select
                                                {...field}
                                                data-cy="mailRecipientAddressCountry"
                                                placeholder={t('position.pleaseSelect')}
                                                label={t('dynamicCRM.communicationMethods.mailRecipient.mailRecipientAddressCountry')}
                                                options={optionCountryAdapted}
                                                error={errors?.mailRecipientAddressCountry?.message}
                                                value={initCountrySelected}
                                                required
                                            />
                                        )}
                                    />
                                </Column>
                            </Row>
                            {generalError && <Infobox error>{generalError}</Infobox>}
                        </Preloader>
                    </Modal>
                )}
            </FormGroup>
        </div>
    );
}

export default NewAddressModal;
