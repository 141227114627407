import React from 'react';
import PropTypes from 'prop-types';
import CellLinked from './CellLinked';
import RadioButton from '../../RadioButton';

function CellWithRadioLinked({
    value, id, link, name, maxLength, onChange,
}) {
    return (
        <div className="table-cell_radio" id={id}>
            <RadioButton label="" name={name} onChange={onChange} value={id} />
            <CellLinked value={value} link={link} maxLength={maxLength} />
        </div>
    );
}

CellWithRadioLinked.propTypes = {
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    /* Unique radio group name */
    name: PropTypes.string.isRequired,
    id: PropTypes.number,
    link: PropTypes.string,
    maxLength: PropTypes.number,
    onChange: PropTypes.func,
};

CellWithRadioLinked.defaultProps = {
    value: null,
    id: null,
    link: null,
    maxLength: 20,
    onChange: () => {},
};

export default CellWithRadioLinked;
