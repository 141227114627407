const height = 49;
const margin = 16;
const padding = 12;
const textOffset = 10;
const legendPadd = 10;
const legendOffset = 20;
const legendHeight = 30;
const rangeStyles = '14px Roboto';
const legendStyles = '16px Times New Roman bold';

const getWidth = (txt, font) => {
    const element = document.createElement('canvas');
    const context = element.getContext('2d', 0, 0);

    context.font = font;

    return context.measureText(txt).width;
};

const buildTriangle = (x, y, reverse, isLegend) => {
    const xA = isLegend ? 7 : 6;
    const yA = isLegend ? 9 : 9;
    const yAS = isLegend ? 0 : 1.5;

    return (reverse
        ? `${x - xA} ${y - yA} ${x + xA} ${y - yA} ${x} ${y - yAS}`
        : `${x - xA} ${y + yA} ${x + xA} ${y + yA} ${x} ${y + yAS}`);
};

export const getHeight = (data, hasMoreData) => ({
    height: data.length * (height + margin) + margin + (hasMoreData ? legendHeight : 0),
    chartHeight: data.length * (height + margin) + margin,
});

export const getXAxis = (x, y) => `M ${x - 1}.5 0 L ${x - 1}.5 ${y}`;

export const getLabelWidth = (labelWidth, width) => {
    if (typeof labelWidth === 'number') return labelWidth;

    if (labelWidth.indexOf('%') !== -1) {
        return width * Number(labelWidth.replace('%', '')) / 100;
    }

    return 150;
};

export const checkData = (data) => data
    .some((item) => item.data.curr !== undefined && item.data.new !== undefined);

export const getLabels = (data, labelFormat) => data
    .map(({ name, data }) => labelFormat(name, data));

export const buildSingleDataArrays = (
    data, width, labels, labelWidth, labelFormat, numberFormat,
) => data.map((item, i) => {
    if (width === 0) return { triangles: [] };

    const range = item.range.max - item.range.min;
    const plotY = i * (height) + (i + 1) * margin + padding;
    const plotYEnd = i * (height) + (i + 1) * margin + padding + 25;
    const plotWidth = width - labelWidth;
    const rangeText = `${numberFormat(item.range.min)}-${numberFormat(item.range.max)}%`;
    const rangeTextWidth = getWidth(rangeText, rangeStyles) + textOffset;
    const legendX = labelWidth + plotWidth * item.range.max / 100;

    return ({
        x: 0,
        y: plotY,
        barX: labelWidth + plotWidth * item.range.min / 100,
        barY: plotY,
        barWidth: plotWidth * range / 100,
        plotX: labelWidth,
        plotY,
        plotWidth,
        labelX: 0,
        labelY: plotY,
        legendX: legendX + rangeTextWidth > width
            ? width - rangeTextWidth
            : legendX + textOffset,
        legendY: plotY + 18,
        name: item.name,
        range: rangeText,
        width: `${item.range.max - item.range.min}%`,
        label: labels[i],
        labelWidth,
        triangles: item.data.new !== undefined
            ? [

                buildTriangle(labelWidth + plotWidth * item.data.new / 100, plotY, true),
                buildTriangle(labelWidth + plotWidth * item.data.curr / 100, plotYEnd, false),
            ]
            : [
                buildTriangle(labelWidth + plotWidth * item.data.curr / 100, plotY, true),
            ],
    });
});

export const getLegendCoordinates = (width, height, labelNew, labelCurrent) => {
    const y = height + legendPadd;
    const widthNew = getWidth(labelNew, legendStyles) + legendOffset;
    const widthCurrent = getWidth(labelCurrent, legendStyles) + legendOffset;
    const widthLegend = widthNew + widthCurrent + legendPadd * 2 + legendOffset + 28;
    const startCurr = width / 2 - widthLegend / 2 + 7;
    const startNew = startCurr + 14 + legendPadd + widthCurrent + legendOffset;

    return {
        currTriangle: buildTriangle(startCurr, y, false, true),
        currX: startCurr + 7 + legendPadd,
        currY: y + 9,
        newTriangle: buildTriangle(startNew, y + 9, true, true),
        newX: startNew + 7 + legendPadd,
        newY: y + 9,
    };
};
