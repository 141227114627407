import PropTypes from 'prop-types';
import React from 'react';
import {Button} from 'ui-library';
import {getClassNames} from 'utils';

import './ButtonWithIcon.css';

const ButtonWithIcon = ({
    children,
    className,
    ...rest
}) => (
    <Button {...rest} className={getClassNames('button-with-icon', className)}>
        {children}
    </Button>
);

ButtonWithIcon.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
};

ButtonWithIcon.defaultProps = {
    children: null,
    className: '',
};

export default ButtonWithIcon;
