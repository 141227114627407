import React, { useMemo, forwardRef } from 'react';
import PropTypes from 'prop-types';
import FormGroup from '../FormGroup';
import FormControlMessage from '../FormControlMessage';
import TextInputIcon from './TextInputIcon';
import Tooltip from '../Tooltip';
import Label from '../Label';
import Icon, { ICON_TYPE_SEARCH } from '../Icon';
import { getClassNames } from '../../utils';
import uid from '../../utils/uid';
import CoreInput from './CoreInput';
import './TextInput.css';
import withTheme from '../../hocs/withTheme';

/** A basic widget for getting the user input is a text field.
 * Keyboard and mouse can be used for providing or changing data.
 *
 * DO Always use labels on text inputs and don’t depend on the placeholder
 * text. If there is more information for the text input needed make
 * use of a paragraph on top or use an info box. */


const TextInput = (props: any) => {
    const {
        disabled, error, errorIcon, errorInTooltip, label, isError,
        labelInfobox, helpText, required, questionmark, verified, search,
        children, onChange, onKeyUp, onFocus, searchDelay, searchCharsNumber, prefix,
        id, innerRef: ref,
        className, textRight, ...rest
    } = props;
    const iconProps = {
        errorInTooltip, error, errorIcon, questionmark, verified,
    };
    const hasSuffix = useMemo(() => error || questionmark || verified,
        [error, questionmark, verified]);
    const prefixProp = useMemo(() => (search || prefix
        ? { prefix: search ? (
            <>
                {/* @ts-ignore */}
                <Icon type={ICON_TYPE_SEARCH} className="search-icon" />
            </>
            ): prefix }
        : {}), [search, prefix]);

    let searchTimer;
    const onSearch = (e) => {
        const { value } = e.target;

        clearTimeout(searchTimer);
        if (value.length === 0 || value.length >= searchCharsNumber) {
            searchTimer = setTimeout(() => onChange(value), searchDelay);
        } else {
            e.preventDefault();
        }
    };
    const classNames = getClassNames(
        { TextInput_invalid: error || isError },
        { 'TextInput_with-search': search },
        { 'text-right': textRight },
        className,
    );
    const inputId = id || uid('text-input');


    return (
        <FormGroup disabled={disabled} error={error}>
            {label && (
                <Label
                    htmlFor={inputId}
                    label={label}
                    helpText={helpText}
                    labelInfobox={labelInfobox}
                    required={required}
                />
            )}

            <CoreInput
                {...rest}
                {...prefixProp}
                id={inputId}
                className={classNames}
                suffix={hasSuffix && (
                    <Tooltip title={errorInTooltip ? error : null}>
                        {/* @ts-ignore */}
                        <TextInputIcon {...iconProps} />
                    </Tooltip>
                )}
                disabled={disabled}
                onChange={search ? onSearch : onChange}
                onKeyUp={onKeyUp}
                onFocus={onFocus}
                ref={ref}
                search={search}
            />

            {error && !errorInTooltip && (
                <FormControlMessage>{error}</FormControlMessage>
            )}

            {children && <p>{children}</p>}
        </FormGroup>
    );
};

TextInput.propTypes = {
    /** The label text */
    label: PropTypes.string,
    /** The text to be displayed in the label tooltip */
    helpText: PropTypes.string,
    /** The text displayed if the label tooltip exist */
    labelInfobox: PropTypes.bool,
    /** The placeholder text */
    placeholder: PropTypes.string,
    /** @ignore */
    defaultValue: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    /** @ignore */
    disabled: PropTypes.bool,
    /** If required field* */
    required: PropTypes.bool,
    /** The error box displayed if true */
    isError: PropTypes.bool,
    /** The error message displayed if the input is errored */
    error: PropTypes.string,
    /** If the space does not allow show error message below the input
     *  make use of the red error indicator with tooltip. */
    errorIcon: PropTypes.bool,
    /** The error message displayed is tooltip */
    errorInTooltip: PropTypes.bool,
    /** Whether the input field has questionmark icon */
    questionmark: PropTypes.bool,
    /** Whether the input field has verified icon */
    verified: PropTypes.bool,
    /** Change action for the input field */
    onChange: PropTypes.func,
    /** Keyup action for the input field */
    onKeyUp: PropTypes.func,
    /** Focus action for the input field */
    onFocus: PropTypes.func,
    /** Whether the input is search field */
    search: PropTypes.bool,
    /** The delay before search field will change */
    searchDelay: PropTypes.number,
    /** The number of characters before search field will change */
    searchCharsNumber: PropTypes.number,
    /** The prefix icon for the input. */
    prefix: PropTypes.node,
    /** @ignore */
    innerRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({
            current: PropTypes.object,
        }),
    ]),
    /** @ignore */
    addonAfter: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
    ]),
    /** @ignore */
    id: PropTypes.string,
    /** @ignore */
    className: PropTypes.string,
    /** @ignore */
    children: PropTypes.node,
    /** @ignore */
};

TextInput.defaultProps = {
    label: null,
    helpText: null,
    labelInfobox: false,
    placeholder: null,
    defaultValue: undefined,
    disabled: false,
    required: false,
    error: null,
    isError: false,
    errorIcon: false,
    errorInTooltip: false,
    questionmark: false,
    verified: false,
    onChange: () => {
    },
    onKeyUp: () => {
    },
    onFocus: () => {
    },
    search: false,
    searchDelay: 300,
    searchCharsNumber: 3,
    prefix: null,
    id: null,
    className: '',
    innerRef: null,
    addonAfter: null,
    children: null,
};

export default forwardRef((props: any, ref) => withTheme(TextInput)({ ...props, innerRef: ref }));
