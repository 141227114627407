import React, {useCallback} from 'react';
import {Link, useLocation} from 'react-router-dom';

import './Navigation.css';

export const Navigation = (props) => {
    const {pathname} = useLocation();
    const {navigation} = props;

    const isActiveNavItem = useCallback((pathnameKey) => pathname.includes(pathnameKey), [pathname]);

    return (
        <nav className="single-portfolio-nav" aria-label="Portfolio navigation">
            {navigation.map(navItem => {
                const isActiveItem = isActiveNavItem(navItem.pathnameKey);
                const isHidden = navItem.hidden ? navItem.hidden() : false;

                if (isHidden) {
                    return null;
                }

                return <Link data-cy={navItem.pathnameKey.replace('/', '')} to={navItem.path} key={navItem.title} aria-current={isActiveItem ? 'page' : undefined}>{navItem.title}</Link>;
            })}
        </nav>
    );
};
