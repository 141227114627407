import React, {FC} from 'react';
import withTheme from '../../hocs/withTheme';
import { DividerProps } from './Divider.types';
import './Divider.css';

const Divider:FC<DividerProps> = ({ direction = 'horizontal', style }) => (
    <div className={`Divider__${direction}`} style={style} />
);

export default withTheme(Divider);
