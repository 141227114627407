import React, { useMemo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { getTimeTopPosition } from './utils';

const EventContainerWrapper = ({ children }) => {
    const [now, setNow] = useState(new Date());
    const top = useMemo(() => getTimeTopPosition(now), [now]);
    const changeNow = (value) => {
        setNow(value.detail);
    };

    useEffect(() => {
        document.addEventListener('time-change', changeNow);

        return () => { document.removeEventListener('time-change', changeNow); };
    }, []);

    return (
        <>
            <span className="current-time-dot" style={{ top }} />
            {children}
        </>
    );
};

EventContainerWrapper.propTypes = {
    children: PropTypes.node.isRequired,
};

export default EventContainerWrapper;
