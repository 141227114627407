import {
    validateDateOfBirth,
    validateEmailAdress,
    validateInputLength,
    validateInputNumber,
    validateInputString,
    validatePhoneNumber,
    validateRadio,
} from 'validation/validation';
import {FIELD_LENGTH} from '../../../../../../../constants';

export const errorModel = (data) => ({
    firstName: validateInputString(data?.firstName),
    lastName: validateInputString(data?.lastName),
    dateOfBirth: validateDateOfBirth(data?.dateOfBirth),
    gender: validateRadio(data?.gender),
    street: validateInputString(data?.street) || validateInputLength(data?.street, FIELD_LENGTH.street),
    PLZ: validateInputNumber(data?.PLZ),
    ORT: validateInputString(data?.ORT) || validateInputLength(data?.ORT, FIELD_LENGTH.city),
    countryOfNationality: validateInputNumber(data?.countryOfNationality),
    countryOfResidence: validateInputNumber(data?.countryOfResidence),
    countryOfBirth: validateInputNumber(data?.countryOfBirth),
    primaryEmail: validateEmailAdress(data?.primaryEmail),
    primaryPhoneNumber: validatePhoneNumber(data?.primaryPhoneNumber, false),
});
