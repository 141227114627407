import React from 'react';
import { Button, Icon, Title } from 'ui-library';
import { ICON_TYPE_EDIT } from 'ui-library/components/Icon';

function CustodyFee({
    setShowFeeModal, defaultCustodyFee, custodyFee, t, disableEdit = false,
}) {
    return (
        <>
            <div className="FeeOverview__CustodyWrapper">
                <p>{t('custodyFee.labels.custody')}</p>
            </div>
            <div className="FeeOverview__CustodyWrapper">
                <b>
                    {custodyFee ?? defaultCustodyFee}
                    %

                </b>
            </div>
            <div className="FeeOverview__CustodyWrapper">
                {!disableEdit && (
                    <Button type="link" onClick={() => setShowFeeModal(true)} className="action-button">
                        <Icon type={ICON_TYPE_EDIT}/>
                        {t('interaction.edit')}
                    </Button>
                )}
            </div>
        </>
    );
}

export default CustodyFee;
