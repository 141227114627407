import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {Table} from '../../../ui-library';
import ClientsPagination from './ClientsPagination';

const ClientsTable = ({
    columns,
    data,
    isLoading,
    paging = {},
    sort = {},
    onChangeSort,
    onChangeFilter,
    onChangePagination,
    tab,
    selection,
    handleRowClick,
}) => {
    const {t} = useTranslation();
    const navigate = useNavigate();

    const onRowClick = (record) => ({
        onClick: () => {
            if (isLoading) {
                return;
            }

            if (handleRowClick) {
                handleRowClick(record);
            } else {
                navigate(`/client/${record.internalId.label}/dashboard`, {
                    fromTab: tab || 'clients',
                    clientName: record.name,
                });
            }
        },
    });

    const rowSelection = selection ? {type: selection} : undefined;

    return (
        <>
            <Table
                noDataText={t('clients.noClients')}
                onChangeSort={onChangeSort}
                onChangeFilter={onChangeFilter}
                columns={columns}
                paging={{
                    pageSize: paging.pageSize,
                    hidePaging: true,
                }}
                data={data}
                sorting={sort}
                onRow={onRowClick}
                headerControls={{
                    selectControlsLabel: {
                        selectAll: t('headerControls.selectAll'),
                        reset: t('headerControls.reset'),
                    },
                }}
                rowSelection={rowSelection}
            />
            <ClientsPagination
                total={paging.totalCount}
                currentPage={paging.currentPage}
                pageSize={paging.pageSize}
                onChange={onChangePagination}
            />
        </>
    );
};

ClientsTable.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.shape({})),
    data: PropTypes.arrayOf(PropTypes.shape({})),
    isLoading: PropTypes.bool,
    paging: PropTypes.shape({
        pageSize: PropTypes.number,
        totalCount: PropTypes.number,
        currentPage: PropTypes.number,
    }),
    sort: PropTypes.shape({
        key: PropTypes.string,
        direction: PropTypes.string,
    }),
    tab: PropTypes.string,
    onChangeSort: PropTypes.func,
    onChangeFilter: PropTypes.func,
    onChangePagination: PropTypes.func,
    selection: PropTypes.oneOf(['radio', 'checkbox']),
    handleRowClick: PropTypes.func,
};
ClientsTable.defaultProps = {
    columns: [],
    data: [],
    isLoading: false,
    paging: {
        pageSize: 0,
        totalCount: 0,
        currentPage: 0,
    },
    sort: {
        key: '',
        direction: 'asc',
    },
    tab: '',
    onChangeSort: () => {
    },
    onChangeFilter: () => {
    },
    onChangePagination: () => {
    },
    selection: null,
    handleRowClick: null,
};

export default ClientsTable;
