import React from 'react';
import PropTypes from 'prop-types';
import Cell from '../Body/Cell';
import { getClassNames } from '../../../utils';

function FooterCell(props) {
    const {
        column: {
            hidden, render, className, sortable, filterable, sorter,
        },
        data,
        item,
    } = props;

    if (hidden) return null;

    const classNameCell = getClassNames(className,
        { 'cell-sortable': sortable || sorter },
        { 'cell-filterable': filterable });

    return (
        <th role="rowheader" className={classNameCell}>
            {render ? render(item, data, 0) : Cell(item)}
        </th>
    );
}

FooterCell.propTypes = {
    row: PropTypes.shape({
        [PropTypes.string]: PropTypes.any,
        children: PropTypes.arrayOf(PropTypes.shape({
            [PropTypes.string]: PropTypes.any,
        })),
    }),
    data: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
            [PropTypes.string]: PropTypes.any,
        }),
    ]),
    column: PropTypes.shape({
        hidden: PropTypes.bool,
        render: PropTypes.func,
        sortable: PropTypes.bool,
        className: PropTypes.string,
        filterable: PropTypes.bool,
        sorter: PropTypes.func,
    }),
    item: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.shape]),
};

FooterCell.defaultProps = {
    row: {
        children: undefined,
    },
    data: null,
    column: {
        hidden: false,
        render: undefined,
        className: undefined,
        sorter: undefined,
    },
    item: null,
};

export default FooterCell;
