import React, {
    useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import update from 'lodash/update';
import {useTranslation} from 'react-i18next';
import {contactSelector, schemaSelector, useClientServiceSelector} from 'domain/ClientService';
import Preloader from 'components/Preloader';
import ButtonsBlockRow from 'components/ButtonsBlockRow';
import DetailsFormIo from 'components/FormIo';
import {createUniqueKey, getClientId} from 'utils';
import {getBearerAccessToken} from 'additiv-services/setup';
import i18n from 'i18next';
import {cloneDeep} from 'lodash/fp';
import {useParams} from 'react-router-dom';
import {getMatches} from './utils';

import {Infobox, Modal, notification} from '../../../../ui-library';
import Card from '../../../../components/Card/Card';
import {CUSTOM_SERVICE_LAYER_SERVICES, getCustomServiceLayerBaseUrl} from '../../../../services/constants';
import {useClientProfile} from '../../../../prodivers/clientProfile';
import UploadPersonalDocumentsModal from './components/UploadPersonalDocumentsModal';
import {checkIfEmailIsInUse} from '../../../utils';
import {useManagerProfile} from '../../../../prodivers/managerProfile';
import {ACCESS_POLICY, EMAIL_VALIDATION_REGEX} from '../../../../constants/constants';
import {MailingAddress} from '../../../../components/MailingAddress';
import ServiceManager from '../../../../services/ServiceManager';
import {validateMultipleInputsLength} from '../../../../validation/validation';
import {FIELD_LENGTH} from '../../constants';

import './Details.css';

const Details = () => {
    const { dfsClientId } = useParams();
    const { i18n: { language }, t } = useTranslation();
    const form = useRef();
    const [submissionData, setSubmissionData] = useState(null);
    const [isValid, setIsValid] = useState(true);
    const [cachedPersonalInfo, setCachedPersonalInfo] = useState(null);
    const [showUploadPersonalDocumentsModal, setShowUploadPersonalDocumentsModal] = useState(false);
    const clientId = getClientId(dfsClientId);
    const {hasAccessPolicy} = useManagerProfile();

    // ClientService Domain
    const {
        data,
        error,
        isLoading,
        getSchema,
        errorLists,
        isLoadingLists,
        getSchemasLists,
    } = useClientServiceSelector(schemaSelector);
    const {
        data: contact,
        error: errorContact,
        isLoading: isLoadingContact,
        getContact,
        errorUpdateContact,
        isLoadingUpdateContact,
        updateContact,
    } = useClientServiceSelector(contactSelector);

    const hasAccessToEdit = useMemo(() => hasAccessPolicy(ACCESS_POLICY.contactEdit), [hasAccessPolicy]);

    const oldBaseData = useMemo(() => [
        cachedPersonalInfo?.firstName,
        cachedPersonalInfo?.lastName,
        cachedPersonalInfo?.gender,
        cachedPersonalInfo?.dateOfBirth,
        cachedPersonalInfo?.identification?.[0]?.countryOfNationality].join(), [cachedPersonalInfo]);

    const {updateClientInfo} = useClientProfile();

    const isBaseDataChanged = useCallback(() => {
        const newData = [submissionData.personalInformation.firstName,
            submissionData?.personalInformation?.lastName,
            submissionData?.personalInformation?.gender,
            submissionData?.personalInformation?.dateOfBirth,
            submissionData?.personalInformation?.identification?.[0]?.countryOfNationality].join();

        if (oldBaseData.includes(newData)) {
            return false;
        }

        return true;
    }, [oldBaseData, submissionData]);

    const saveData = useCallback(async () => {
        try {
        // Delete properties which are unable to save
            delete submissionData?.personalInformation?.displayName;
            delete submissionData?.personalInformation?.displayName1;
            delete submissionData?.personalInformation?.isProspect;
            delete submissionData?.relationshipInformation?.contactType;
            delete submissionData?.relationshipInformation?.contactCreateDate;
            delete submissionData?.relationshipInformation?.isActivated;
            delete submissionData?.relationshipInformation?.lastClientLoginDate;
            delete submissionData?.relationshipInformation?.lastClientContactDate;
            delete submissionData?.investmentInformation?.calculatedRiskCategory;
            delete submissionData?.investmentInformation?.chosenRiskCategory;
            delete submissionData?.investmentInformation?.reportingCurrency;
            delete submissionData?.investmentInformation?.performanceSinceInception;
            delete submissionData?.investmentInformation?.performanceYTD;
            delete submissionData?.investmentInformation?.performance1Year;
            delete submissionData?.investmentInformation?.performance3Year;
            delete submissionData?.investmentInformation?.performance5Year;
            delete submissionData?.investmentInformation?.totalValueOfPortfolios;
            delete submissionData?.investmentInformation?.cashComponentOfTotalValue;
            delete submissionData?.investmentInformation?.securitiesComponentOfTotalValue;
            delete submissionData?.investmentInformation?.totalNumberOfPortfolios;
            delete submissionData?.investmentInformation?.activeProducts;

            await updateContact({params: {details: submissionData}});
            setCachedPersonalInfo(cloneDeep(submissionData?.personalInformation));
            notification.open({content: t('clientDashboard.details.successMessage'), type: 'success'});
            updateClientInfo();
        } catch (err) {
            if (err?.error?.response?.status === 409) {
                Modal.error({
                    title: t('clientDashboard.details.generatedDocumentsErrorTitle'),
                    content: t('clientDashboard.details.generatedDocumentsErrorText'),
                    okText: t('Ok'),
                });
            } else {
                notification.open({content: err?.message, type: 'error'});
            }
        }
    }, [submissionData, updateContact, t]);

    const updateContactDetails = useCallback(async () => {
        if (!hasAccessToEdit) {
            return;
        }

        const isLengthValid = validateMultipleInputsLength(FIELD_LENGTH.firstAndLastNameCombined, 'clientValidation.firstAndLastNameLengthCombined', submissionData?.personalInformation?.firstName, submissionData?.personalInformation?.lastName);

        if (isLengthValid) {
            notification.open({content: t(isLengthValid, {max: FIELD_LENGTH.firstAndLastNameCombined}), type: 'error'});

            return;
        }

        if (!EMAIL_VALIDATION_REGEX.test(submissionData?.communicationMethods?.primaryEmail)) {
            notification.open({content: t('onboardingFlow.personalPage.errorEmailIsSpecialCharacters'), type: 'error'});

            return;
        }

        if (await checkIfEmailIsInUse(clientId, submissionData?.communicationMethods?.primaryEmail)) {
            notification.open({content: t('onboardingFlow.personalPage.errorEmailIsRegister'), type: 'error'});

            return;
        }

        if (isBaseDataChanged()) {
            setShowUploadPersonalDocumentsModal(true);

            return;
        }
        await saveData();
    }, [saveData, isBaseDataChanged, hasAccessToEdit]);

    const onChange = useCallback(({isValid: isValidForm}, {changes}) => {
        (changes || []).forEach((change) => {
            if (!change?.component?.disabled && !change?.flags?.noValidate) {
                setSubmissionData((obj) => update(obj, change.instance.path, () => change.value));
            }
        });
        setIsValid(isValidForm);
    }, []);

    const i18nUpdater = (component, fields) => {
        let { key } = component;

        fields.forEach((field) => {
            const splittedFieldKey = field.key.split('.');
            const fieldKeySuffix = splittedFieldKey[splittedFieldKey.length - 1];

            if (key === fieldKeySuffix) {
                key = field.key;
            }
        });
        if (component.label) component.label = t(`dynamicCRM.${key}`);
        if (component.title) component.title = t(`dynamicCRM.${key}`);
        if (component.legend) component.legend = t(`dynamicCRM.${key}`);
        component.components?.forEach((cmp) => i18nUpdater(cmp, fields));
        component.columns?.forEach((cmp) => i18nUpdater(cmp, fields));
    };

    // Data
    const formIoSchema = useMemo(() => {
        let dataReturn = null;

        try {
            dataReturn = JSON.parse(data?.schema);

            // replace component for occupation
            const occupationScheme = getMatches((obj) => obj?.properties?.customEnum === 'occupation')(dataReturn);

            occupationScheme.type = 'lazyselect';
            occupationScheme.searchEnabled = true;
            occupationScheme.searchField = 'name';
            occupationScheme.dataSrc = 'url';
            occupationScheme.data = {
                url: `${global.location.origin}${getCustomServiceLayerBaseUrl(CUSTOM_SERVICE_LAYER_SERVICES.DEFAULT)}/lookup/occupations?language=${i18n.language}`,
                headers: [{ key: 'Authorization', value: getBearerAccessToken() }],
            };
            occupationScheme.limitProperty = 'count';
            occupationScheme.template = '{{ item.name }}';
            occupationScheme.limit = 20;
            occupationScheme.searchResultLimit = 20;
            occupationScheme.idPath = 'id';
            occupationScheme.valueProperty = 'id';
            occupationScheme.authenticate = true;
            occupationScheme.conditional = {
                when: '',
                eq: '',
            };

            dataReturn?.components?.forEach(
                (component) => i18nUpdater(component, dataReturn?.metadata?.fields),
            );
        } catch (e) {
            /* Do not throw error */
        }

        return dataReturn;
    }, [data?.schema, language, getBearerAccessToken]);

    const submission = useMemo(() => ({data: contact?.details}), [contact]);

    // Effects
    useEffect(() => {
        getSchema({key: 'editCustomer'});
    }, [getSchema]);

    useEffect(() => {
        getContact();
    }, [getContact]);

    useEffect(() => {
        getSchemasLists();
    }, [getSchemasLists]);

    useEffect(() => {
        setSubmissionData(contact?.details);
        setCachedPersonalInfo(cloneDeep(contact?.details?.personalInformation));
    }, [contact]);

    const schema = {
        formIo: formIoSchema, custom: null,
    };

    const onFormReady = () => {

    };

    const errorValidationProperties = errorUpdateContact?.error?.response?.data?.properties?.ValidationErrors;
    const errorValidationsMessages = Array.isArray(errorValidationProperties)
        ? errorValidationProperties.map(fieldError => fieldError.message) : [];

    const handleSubmitMailingAddress = useCallback(async (mailingData, action) => {
        await ServiceManager.customClientService('patchMailingAddress', [{
            contactId: clientId,
            data: mailingData,
            action,
        }]);

        global.location.reload();
    }, [clientId]);

    return (
        <div className="container Details">
            <Card>
                <Preloader
                    isLoading={isLoading || isLoadingLists || isLoadingContact}
                    error={error || errorLists || errorContact}
                >
                    <div className="details-form-io">
                        <DetailsFormIo
                            ref={form}
                            form={schema.formIo}
                            submission={submission}
                            formReady={onFormReady}
                            options={{
                                readOnly: false, noAlerts: false, renderMode: 'HWM',
                            }}
                            onChange={onChange}
                            onSubmit={updateContactDetails}
                        />
                    </div>
                    {!isLoadingContact && (
                        <div className="mailing-container">
                            <MailingAddress
                                onSubmit={handleSubmitMailingAddress}
                                initData={{...contact?.details?.communicationMethods?.mailRecipient}}
                            />
                        </div>
                    )}

                    <ButtonsBlockRow
                        primaryButton={hasAccessToEdit ? {
                            text: t('clientDetails.save'),
                            loading: isLoadingUpdateContact,
                            disabled: !isValid,
                            onClick: updateContactDetails,
                        } : null}
                    >
                        {!!errorValidationsMessages.length && (
                            <Infobox error >
                                {errorValidationsMessages.map((err, idx) => <div key={createUniqueKey(idx, err)}>{err}</div>)}
                            </Infobox>
                        )}
                    </ButtonsBlockRow>
                </Preloader>
            </Card>
            {showUploadPersonalDocumentsModal && (
                <UploadPersonalDocumentsModal
                    onClose={() => setShowUploadPersonalDocumentsModal(false)}
                    onUploadSuccess={saveData}
                    clientId={clientId}
                />
            )}
        </div>
    );
};

export default Details;
