import { AccountCreate, AccountSearch } from 'additiv-services/types/services/accounts';
import { getServicesUrl } from '../../setup';
import Service from './service';
import { getQueryStringByObject } from '../../utils/queryUtils';
import { APIVersion } from '../../types/main';

class Accounts extends Service {
    /**
     * Create a new Account
     * @param {contactId} number.
     * @param {data} json
     * data json:
     * {
     *   AccountNumber: 002500078712000099999,
     *   Iban: 'AB 002500078712000099999',
     *   Name: 'Cash Account',
     *   BranchId: 1,
     *   CurrencyId: 3,
     *   ContactGroupId: 1,
     *   AccountType: 1,
     *   IsActive: true,
     *   Product: 1,
     *   Status: 1,
     *   OpeningDate: 01.05.2020,
     * }
     */
    static createAccount(contactId: number, data: AccountCreate) {
        return Service.postRequest(
            `${getServicesUrl()}/contacts/${contactId}/accounts/create`, data, { isSecure: true },
        );
    }

    static getAccount(contactId: number, accountId: number, params: any) {
        const queryString = getQueryStringByObject(params);

        return Service.getRequest(`${getServicesUrl(APIVersion.V11)}/contacts/${contactId}/accounts/${accountId}?${queryString}`, { isSecure: true });
    }

    static postAccountsSearch(contactId: number, language: string, data: AccountSearch) {
        return Service.postRequest(`${getServicesUrl(APIVersion.V11)}/contacts/${contactId}/accounts/search?language=${language}`, data, { isSecure: true });
    }
}

export default Accounts;
