import Service from 'additiv-services/services/core/service';
import {CUSTOM_SERVICE_LAYER_SERVICES, getCustomServiceLayerBaseUrl} from '../constants';

class ContactActivation extends Service {
    static #baseServiceUrl = getCustomServiceLayerBaseUrl(CUSTOM_SERVICE_LAYER_SERVICES.CONTACT_ACTIVATION);

    static getContactActivationHistory({
        contactId,
    }) {
        return Service.getRequest(`${this.#baseServiceUrl}/${contactId}/history`, {isSecure: true});
    }
}

export default ContactActivation;
