import { getValidationMessage } from 'utils/validation';
import {
    object, string, number, mixed,
} from 'yup';

import moment from 'moment';
import { TFunction } from 'react-i18next';
import {
    ZIFD_MIN_WITHDRAWAL_AMOUNT,
    ZIV_MIN_WITHDRAWAL_AMOUNT,
} from 'pages/ClientOverview/constants';
import { nameValidationRegExp, dateValidationRegex } from './schemaValidationHelpers';

export const getWithdrawerPlanSchema = (
    t: TFunction<'translation', undefined>,
    productSettings: SettingsType | undefined,
    isAzp: boolean,
    isZiv: boolean,
    isZifd: boolean,
    isPk: boolean,
    nextSixMonthsInvestment: string,
) => {
    const {
        mandatoryError, nameError, invalidDateFormatError, numberError,
        notBirthDateError, birthDateError,
    } = getValidationMessage();

    const nameValidation = string()
        .required(mandatoryError)
        .test('is-valid', nameError, (val: string) => val !== 'N.A')
        .matches(nameValidationRegExp, {
            message: nameError,
            excludeEmptyString: true,
        });

    const inputNumberValidation = number()
        .typeError(numberError)
        .nullable(false, mandatoryError)
        .required(mandatoryError);

    const stringValueValidation = string()
        .required(mandatoryError)
        .test('is-valid', mandatoryError, (val: string) => val !== 'N/A');

    type mixTypeSelect = string | number | null;

    const selectValidation = mixed<mixTypeSelect>()
        .required(mandatoryError)
        .test(
            'is-valid',
            mandatoryError,
            (val: mixTypeSelect) => val !== 'N/A',
        );

    const commonSchemaModel = {
        frequency: selectValidation,
        iban: string().required(mandatoryError).nullable(false, mandatoryError),
        firstName: nameValidation,
        lastName: nameValidation,
        bankName: stringValueValidation,
        zipCode: string().matches(/^[0-9]+$/, numberError).nullable(false, mandatoryError).required(mandatoryError),
        city: stringValueValidation,
    };

    const schemaModelPK = {
        amount: inputNumberValidation,
        frequency: selectValidation,
        targetPortfolio: selectValidation,
        startDate: string()
            .required(mandatoryError)
            .matches(dateValidationRegex, notBirthDateError)
            .test(
                'not-empty',
                mandatoryError,
                (val: string) => val !== 'Invalid date',
            )
            .test(
                'is-valid',
                birthDateError,
                (val: string) => {
                    const splitDate = val.split('/');

                    return +splitDate[1] >= 1900;
                },
            ),
    };

    const schemaModelForZifdandZiv = {
        ...commonSchemaModel,
        amount: inputNumberValidation
            .min(
                isZiv ? ZIV_MIN_WITHDRAWAL_AMOUNT : ZIFD_MIN_WITHDRAWAL_AMOUNT,
                t('3b.withdrawalPlan.error.minWithdrawalAmount', {
                    amount: isZiv
                        ? ZIV_MIN_WITHDRAWAL_AMOUNT
                        : ZIFD_MIN_WITHDRAWAL_AMOUNT,
                }),
            )
            .max(
                parseFloat(nextSixMonthsInvestment) * 0.2, // 20% of the planned investment in next 6 months,
                t('3b.withdrawalPlan.error.maxWithdrawalAmount'),
            ),
        startDate: string()
            .required(mandatoryError)
            .matches(dateValidationRegex, notBirthDateError)
            .test(
                'not-empty',
                mandatoryError,
                (val: string) => val !== 'Invalid date',
            )
            .test(
                'is-valid',
                birthDateError,
                (val: string) => {
                    const splitDate = val.split('/');

                    return +splitDate[1] >= 1900;
                },
            ),
    };

    const schemaModelForAzp = {
        ...commonSchemaModel,
        amount: inputNumberValidation
            .min(
                productSettings?.minInvestmentAmount,
                t('3b.withdrawalPlan.error.minInvestmentAmount', {
                    amount: productSettings?.minInvestmentAmount,
                }),
            )
            .max(
                productSettings?.maxIvnestmentAmount,
                t('3b.withdrawalPlan.error.maxInvestmentAmount', {
                    amount: productSettings?.maxIvnestmentAmount,
                }),
            ),
        duration: inputNumberValidation,
        // duration_legacy: inputNumberValidation
        //     .min(
        //         productSettings?.minDuration,
        //         t('3b.withdrawalPlan.error.minDuration', {
        //             duration: productSettings?.minDuration,
        //         }),
        //     )
        //     .max(
        //         productSettings?.maxDuration,
        //         t('3b.withdrawalPlan.error.maxDuration', {
        //             duration: productSettings?.maxDuration,
        //         }),
        //     ),
        withdrawalsStartDate: string()
            .nullable(false, mandatoryError)
            .required(mandatoryError)
            .test(
                'is-valid',
                mandatoryError,
                (val: string | undefined) => val !== 'Invalid date' && val !== undefined,
            )
            .test(
                'is-valid-format',
                invalidDateFormatError,
                (val: boolean | moment.MomentInput) => !val
                    || (val && val !== moment(val as moment.MomentInput).isValid()),
            ),
    };

    if (isAzp) {
        return object(schemaModelForAzp);
    }
    if (isZiv || isZifd) {
        return object(schemaModelForZifdandZiv);
    }
    if (isPk) {
        return object(schemaModelPK);
    }

    return null;
};
