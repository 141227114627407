import Service from 'additiv-services';
import {getQueryStringByObject} from 'additiv-services/utils/queryUtils';
import {AdvisoryForContactGroupRequest} from 'core/types/api/Api';
import {CUSTOM_SERVICE_LAYER_SERVICES, getCustomServiceLayerBaseUrl} from '../constants';

class CustomAdvisoryServices extends Service {
    static #baseServiceUrl = getCustomServiceLayerBaseUrl(CUSTOM_SERVICE_LAYER_SERVICES.ADVISORY);

    // GET requests
    static getAdvisory({id}) {
        return Service.getRequest(`${this.#baseServiceUrl}/${id}`, {isSecure: true});
    }

    static getStrategyAllocations() {
        return Service.getRequest(`${this.#baseServiceUrl}/other-assets/allocations`, {isSecure: true});
    }

    static getSecurityAllocations({advisoryId, includeInvestmentApplications = false}) {
        return Service.getRequest(`${this.#baseServiceUrl}/${advisoryId}/security-allocations?includeInvestmentApplications=${includeInvestmentApplications}`, {isSecure: true});
    }

    static getPortfolioVolatility() {
        return Service.getRequest(`${this.#baseServiceUrl}/portfolio-volatility`, {isSecure: true});
    }

    static getPerformanceHistory() {
        return Service.getRequest(`${this.#baseServiceUrl}/performance-history`, {isSecure: true});
    }

    static getRiskReturnData() {
        return Service.getRequest(`${this.#baseServiceUrl}/risk-return`, {isSecure: true});
    }

    static getExistingPortfolios({advisoryId, lang}) {
        const queryString = getQueryStringByObject({language: lang || 'de'});

        return Service.getRequest(`${this.#baseServiceUrl}/${advisoryId}/portfolios?${queryString}`, {isSecure: true});
    }

    // PUT requests
    static updateAdvisoryProcessData({advisoryId, key, payload}) {
        return Service.putRequest(`${this.#baseServiceUrl}/${advisoryId}/${key}`, payload, {isSecure: true});
    }

    static generateAdvisoryEntity({payload}: {payload: AdvisoryForContactGroupRequest}) {
        return Service.postRequest(`${this.#baseServiceUrl}`, payload, {isSecure: true});
    }

    static calculateStrategyRiskBandwidth({advisoryId}: {advisoryId: number}) {
        return Service.postRequest(`${this.#baseServiceUrl}/${advisoryId}/calculate-risk`, null, {isSecure: true});
    }
}

export default CustomAdvisoryServices;
