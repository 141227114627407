export const BUTTON_TYPE_PRIMARY = 'primary';
export const BUTTON_TYPE_SECONDARY = 'secondary';
export const BUTTON_TYPE_DANGER = 'danger';
export const BUTTON_TYPE_DANGER_OUTLINE = 'danger-outline';
export const BUTTON_TYPE_LINK = 'link';

export const BUTTON_TYPES = [
    BUTTON_TYPE_PRIMARY,
    BUTTON_TYPE_SECONDARY,
    BUTTON_TYPE_DANGER,
    BUTTON_TYPE_DANGER_OUTLINE,
    BUTTON_TYPE_LINK,
];

export const BUTTON_SIZE_SMALL = 'small';

export const BUTTON_SIZES = [BUTTON_SIZE_SMALL];
