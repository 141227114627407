import { useEffect, useState } from 'react';
import SM from 'services/ServiceManager';

import { portfolioSelector, usePortfolioSelector } from 'domain/Portfolio';

export const useAllPortfolios = (clientId, language) => {
    const [isPortfoliosLoading, setIsPortfoliosLoading] = useState(true);
    const [isPortfoliosError, setIsPortfoliosError] = useState(null);
    const [filteredPortfoliosData, setFilteredPortfoliosData] = useState([]);
    const [totalCategoryValue, setTotalCategoryValue] = useState(0);
    const [totalValue, setTotalValue] = useState(0);
    const [portfolioTitle, setPortfolioTitle] = useState('');


    const { data, isLoading, error } = usePortfolioSelector(portfolioSelector);

    useEffect(() => {
        if (!isLoading && !error) {
            const getPortfolio = async () => {
                try {
                    const {
                        data: responseData,
                    } = await SM.portfolioManagement('getPortfolios', [
                        clientId,
                        { language },
                    ]);

                    const { productId, title } = data;

                    const filteredPortfolios = responseData.filter(
                        portfolio => portfolio.Product.Id === productId,
                    );

                    const totalCategory = filteredPortfolios.reduce((acc, portfolio) => {
                        const result = acc + portfolio.CurrentValue;

                        return result;
                    }, 0);

                    // const params = {
                    //     SecurityType: 'Instruments',
                    //     // TransactionType: optionsParams?.TransactionType,
                    //     Page: 1,
                    //     PageSize: 100,
                    // };

                    // const response = await SM.portfolioManagement('getPortfolioTrnsactions', [clientId, 2, params]);

                    // TODO should be calculated from response above
                    const total = totalCategory * 1.35;

                    //setTotalCategoryValue(totalCategory);
                    setFilteredPortfoliosData(filteredPortfolios);
                    setTotalValue(total);
                    setPortfolioTitle(title);
                } catch (err) {
                    setIsPortfoliosError(err);
                } finally {
                    setIsPortfoliosLoading(false);
                }
            };

            getPortfolio();
        }

        if (!isLoading && error) {
            setIsPortfoliosError(error);
            setIsPortfoliosLoading(false);
        }
    }, [isLoading, error, language, clientId, data?.title, data?.productId]);

    return {
        isLoading: isPortfoliosLoading,
        error: isPortfoliosError,
        data: filteredPortfoliosData,
        totalCategoryValue,
        totalValue,
        portfolioTitle,
    };
};
