import { sortBy } from 'lodash';
import {
    useCallback,
    useEffect, useMemo, useRef, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import HandlerError from 'errors/HandlerError';
import ServiceManager from 'services/ServiceManager';
import handlerRequestCanceling from 'utils/handlerRequestCanceling';
import {RISK_INDICATOR_CONFIG} from '../../../../../constants/constants';
import type {
    ModelPortfolioDetailsType, IndividualPortfolioDataType, ModelPortfolioAllocationType, AssetClassTreeType,
} from '../../../../../ViewModels/ModelPortfolioDetails';
import {BodyItemType, FooterItemType} from '../../../../../ViewModels/ModelPortfolioDetails';

type AllocationType = {
    name: string,
    value: number,
    color: string,
    ExternalId: string
}

type ProductAllocationModelType = {
    allocations: Array<AllocationType>,
    modelPortfolioDetails: ModelPortfolioDetailsType,
    factsheetUrl: string,
    isLoading: boolean
    error: any,
    riskScore: number,
}

type UseProductAllocationPayloadType = {
    productId?: number,
    individualPortfolioData: IndividualPortfolioDataType<Partial<BodyItemType>, Partial<FooterItemType>>
}

export const useProductAllocation = ({ productId, individualPortfolioData }: UseProductAllocationPayloadType) : ProductAllocationModelType => {
    const [error, setError] = useState(null);
    const [isLoading, setLoading] = useState(true);
    const [allocations, setAllocations] = useState<Array<AllocationType>>([]);
    const [factsheetUrl, setFactsheetUrl] = useState('');
    const [assetClassTree, setAssetClassTree] = useState<Array<AssetClassTreeType>>([]);
    const [modelPortfolioDetails, setModelPortfolioDetails] = useState<ModelPortfolioDetailsType>(<ModelPortfolioDetailsType>{});
    const cancelRequest = useRef<() => void>();

    const { i18n: { language } } = useTranslation();

    const riskScore = useMemo(() => {
        const predefinedRiskScore = JSON.parse(modelPortfolioDetails.Attributes || '{}')?.InvestmentRisk;

        if (predefinedRiskScore) {
            return predefinedRiskScore;
        }

        const sumOfAllEquityAssets = allocations.filter(f => [5, 6].includes(parseInt(f.ExternalId, 10))).reduce((acc, curr) => acc + curr.value, 0) * 100;

        const closestUpperID = RISK_INDICATOR_CONFIG.filter(obj => obj.limit >= parseFloat(sumOfAllEquityAssets.toFixed(2)))
            .reduce((prev, curr) => (prev.limit < curr.limit ? prev : curr))
            .value;

        return closestUpperID;
    }, [allocations, modelPortfolioDetails]);

    const updateAllocations = useCallback(async (data: Array<ModelPortfolioAllocationType>) => {
        const allocationsValues = data?.find(f => f.category === 'AssetClass')?.breakdowns.map(i => ({id: i.id, value: i.allocation}));

        const orderedInstruments = sortBy(assetClassTree, (item) => parseInt(item.Data?.ExternalId, 10));
        const instrumentAllocations = orderedInstruments?.map(item => ({
            name: item?.Data?.Name,
            value: allocationsValues?.find(i => item.Data?.Id === i.id)?.value,
            color: item?.Data?.Color,
            ExternalId: item?.Data?.ExternalId,
        }));

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        setAllocations(instrumentAllocations?.filter(f => !!f.value));
    }, [assetClassTree]);

    const calculateNewAllocations = useCallback(async () => {
        const payload = individualPortfolioData?.body?.filter(f => !f?.allocation?.disabled).map(i => ({securityId: i.id, allocation: (i?.allocation?.value ?? 0) / 100}));
        const { data } = await ServiceManager.customInvestmentService('calculateModelPortfolioAllocations', [{modelPortfolioId: modelPortfolioDetails.Id, data: payload}]);

        await updateAllocations(data?.modelPortfolioAllocations);
    }, [individualPortfolioData?.body, updateAllocations, modelPortfolioDetails.Id]);

    useEffect(() => {
        if (individualPortfolioData?.footer?.allocation === 100 && modelPortfolioDetails.Id) {
            calculateNewAllocations();
        }
    }, [individualPortfolioData, calculateNewAllocations, modelPortfolioDetails.Id]);

    useEffect(() => {
        (async () => {
            const [request] = await ServiceManager.portfolioManagementLocal('getAssetClassTree', [language]);

            const {data } = await request;

            setAssetClassTree(data);
        })();
    }, [language]);

    useEffect(() => {
        if (!productId) {
            setAllocations([]);
            setLoading(false);
            setModelPortfolioDetails(<ModelPortfolioDetailsType>{});

            return;
        }

        (async () => {
            try {
                if (cancelRequest.current) cancelRequest.current();
                setLoading(true);
                const [getModelPortfolioAllocationsPromise, cancel] = await ServiceManager.portfolioManagementLocal(
                    'getModelPortfolioAllocations',
                    [productId, { language }],
                );

                cancelRequest.current = cancel;
                const response = await getModelPortfolioAllocationsPromise;

                await updateAllocations(response?.data?.ModelPortfolioAllocations?.map(m => ({category: m.Category, breakdowns: m?.Breakdowns.map(i => ({id: i.Id, allocation: i.Allocation}))})));

                const [portfolioDetailsPromise, portfolioDetailsCancel] = await ServiceManager.portfolioManagementLocal('getModelPortfolioDetails', [productId, { language }]);

                cancelRequest.current = portfolioDetailsCancel;
                const portfolioDetailsResponse = await portfolioDetailsPromise;

                setFactsheetUrl(portfolioDetailsResponse.data?.Factsheet?.replace('url:', ''));
                setModelPortfolioDetails(portfolioDetailsResponse.data);
                setLoading(false);
            } catch (e) {
                handlerRequestCanceling(
                    HandlerError({ setError, setLoading }),
                )(e);
            }
        })();
    }, [productId, language, updateAllocations]);

    return {
        allocations, modelPortfolioDetails, factsheetUrl, isLoading, error, riskScore,
    };
};
