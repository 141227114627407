const initState = {
    documents: [],
};

export default function reducer(state = initState, action = {}) {
    switch (action.type) {
        case 'DOCUMENT_START_UPLOAD_PROCESS':
            return {
                ...state,
                documents: [...state.documents, {
                    fileName: action.payload,
                    isUploaded: false,
                    isLoading: true,
                    isDeleted: false,
                }],
            };
        case 'DOCUMENT_UPLOAD_SUCCESS':
            return {
                ...state,
                fileName: action.payload,
                isUploaded: true,
                isLoading: false,
                isDeleted: false,
            };
        case 'DELETE_DOCUMET':
            return {
                ...state,
                fileName: action.payload,
                isUploaded: true,
                isLoading: true,
                isDeleted: true,
            };
        case 'CLEAR_DOCUMETS':
            return {
                documents: [],
            };

        default: return state;
    }
}
