import { DEFAULT_PROJECTION_YEARS } from 'pages/ClientOverview/pages/Portfolios/pages/CreateNewPortfolio/constants';
import ServiceManager from 'services/ServiceManager';

const getInvestmentProjection = (data) => {
    const queryString = {
        modelPortfolioId: data.PortfolioId ?? data.modelPortfolioId,
        projectionYears: (data.projectionYears ?? 0) <= 0 ? DEFAULT_PROJECTION_YEARS : +data.projectionYears,
        initialInvestmentAmount: data.InitialInvestmentAmount ?? data.initialInvestment,
        yearlyContributionAmount: data.YearlyContributionAmount ?? data.yearlyPayments,
    };

    if (data.individualPortfolio) {
        queryString.individualPortfolio = data.individualPortfolio;
    }

    return ServiceManager.customInvestmentService('getData', [JSON.stringify(queryString)]);
};

export default getInvestmentProjection;
