import React, { ReactNode } from 'react';
import { useGetInvestmentApplicationOverview } from 'hooks/useGetInvestmentApplicationOverview';
import ClientApplicationOverviewContext, { ClientApplicationOverviewContextType } from './context';
import { useClientLayout } from '../../../hooks/useClientLayout';
import { useClientProfile } from '../../../../../prodivers/clientProfile';
import { getClientId } from '../../../../../utils';
import { investmentApplicationsAdapter} from '../investmentApplicationsAdapter';
import Preloader from '../../../../../components/Preloader';

interface ClientApplicationOverviewProviderProps {
    dfsClientId?: string;
    containerId?: string;
    applicationId?: string;
    children?: ReactNode;
}

const ClientApplicationOverviewProvider: React.FC<ClientApplicationOverviewProviderProps> = ({
    dfsClientId, containerId, children, applicationId,
}) => {
    useClientLayout();

    // Client Provider
    const {
        isDataAccessible,
        isOverviewLoading,
    } = useClientProfile();
    const clientId = getClientId(dfsClientId);

    const {
        isLoading: isAppsLoading, error: appsError, data: appsData, getInvestmentApplications,
    } = useGetInvestmentApplicationOverview(investmentApplicationsAdapter, isDataAccessible, containerId, applicationId);

    const state: ClientApplicationOverviewContextType = {
        clientId,
        dfsClientId,
        appsData,
        isAppsLoading,
        appsError,
        getInvestmentApplications,
    };

    return (
        <ClientApplicationOverviewContext.Provider
            value={{ ...state }}
        >
            <Preloader isLoading={!!isOverviewLoading || isAppsLoading} error={appsError}>
                {children}
            </Preloader>
        </ClientApplicationOverviewContext.Provider>
    );
};

ClientApplicationOverviewProvider.defaultProps = {
    children: null,
    dfsClientId: undefined,
    containerId: undefined,
    applicationId: undefined,
};

export default ClientApplicationOverviewProvider;
