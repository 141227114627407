import Service, { APIVersion } from 'additiv-services';
import { CUSTOM_SERVICE_LAYER_SERVICES, getCustomServiceLayerBaseUrl } from '../constants';
import { getQueryStringByObject } from '../../additiv-services/utils/queryUtils';
import { InvestmentApplicationInfo } from '../../core/types/api/Api_legacy';

interface CalculateModelPortfolioAllocationsPayload {
    modelPortfolioId: number,
    data: [{
        securityId: number,
        allocation: number
    }]
}

interface PortfolioAllocationsResponseData {

}

interface ResponseData<T> {
    data: T
}

class CustomInvestmentService extends Service {
    static #baseServiceUrl = getCustomServiceLayerBaseUrl(CUSTOM_SERVICE_LAYER_SERVICES.INVESTMENT);

    static calculateModelPortfolioAllocations({ modelPortfolioId, data }: CalculateModelPortfolioAllocationsPayload):Promise<ResponseData<PortfolioAllocationsResponseData>>|[Promise<ResponseData<PortfolioAllocationsResponseData>>, () => void] {
        return Service.postRequest(`${this.#baseServiceUrl}/${modelPortfolioId}/allocations`, data, { isSecure: true });
    }

    static checkIsFzAndFzpEnabled(contactId) {
        return Service.getRequest(`${this.#baseServiceUrl}/${contactId}/fz`, { isSecure: true });
    }

    static getInvestmentAppsByContainerId({ containerId, language }): Promise<ResponseData<InvestmentApplicationInfo[]>>|[Promise<ResponseData<InvestmentApplicationInfo[]>>, () => void] {
        const queryString = getQueryStringByObject({ language });

        return Service.getRequest(`${this.#baseServiceUrl}/${containerId}/container-application-info?${queryString}`, { isSecure: true });
    }

    static getSubstituteExclusionOptions({ preferenceKey, ...rest }) {
        const queryString = getQueryStringByObject({ ...rest });

        return Service.getRequest(`${this.#baseServiceUrl}/${preferenceKey}/getSubstituteExclusion?${queryString}`, { isSecure: true });
    }

    static getApplicationList({ contactId, memberId, ...rest }) {
        const queryString = getQueryStringByObject({ ...rest });

        return Service.getRequest(`${this.#baseServiceUrl}/${contactId}/${memberId}/applications?${queryString}`, { isSecure: true });
    }

    static updateFees({ investmentApplicationId, payload }) {
        return Service.postRequest(`${this.#baseServiceUrl}/${investmentApplicationId}/update-fees`, payload, { isSecure: true });
    }

    static deleteFee({ investmentApplicationId, feeType }) {
        return Service.deleteRequest(`${this.#baseServiceUrl}/${investmentApplicationId}/fees?feeType=${feeType}`, { isSecure: true });
    }

    static getData(params) {
        return Service.getRequest(`${this.#baseServiceUrl}/projection?data=${params}`, { isSecure: true });
    }

    static postData(id, data) {
        return Service.postRequest(`${this.#baseServiceUrl}/projection?${id}`, data, { isSecure: true });
    }

    static cancelApplication({ applicationId }) {
        return Service.postRequest(`${this.#baseServiceUrl}/${applicationId}/cancel`, null, { isSecure: true });
    }

    static cancelApplicationsByContainerId({ containerId }) {
        return Service.deleteRequest(`${this.#baseServiceUrl}/${containerId}/cancel-application`, { isSecure: true });
    }

    static createNewInvestment({
        contactId, language, data,
    }) {
        const queryString = getQueryStringByObject({ contactId, language });

        return Service.postRequest(`${this.#baseServiceUrl}/complete?${queryString}`, data, { isSecure: true });
    }

    static getInvestmentApplications({
        memberId, pageNumber, language, pageSize,
    }) {
        const queryString = getQueryStringByObject({
            pageNumber, language, pageSize,
        });

        return Service.getRequest(`${this.#baseServiceUrl}/${memberId}/applications?${queryString}`, { isSecure: true });
    }

    static reGenerateInvestmentApplicationDocuments({ investmentApplicationId }) {
        return Service.postRequest(`${this.#baseServiceUrl}/${investmentApplicationId}/regenerate`, null, { isSecure: true });
    }

    static getGeneralAgencies() {
        return Service.getRequest(`${this.#baseServiceUrl}/GeneralAgencies`, { isSecure: true });
    }

    static getDawReport({ from, to }) {
        return Service.getRequest(`${this.#baseServiceUrl}/eSign-report?dateFrom=${from}&dateTo=${to}`, { isSecure: true, axiosSetup: { responseType: 'blob' } });
    }

    static getLastApplicationByPortfolio({
        portfolioId, language,
    }) {
        const queryString = getQueryStringByObject({
            language,
        });

        return Service.getRequest(`${this.#baseServiceUrl}/${portfolioId}/last-application-by-portfolio?${queryString}`, { isSecure: true });
    }

    static getClientInvestmentApplications({
        pageNumber, language, pageSize, clientId, memberId,
    }) {
        const queryString = getQueryStringByObject({ pageNumber, language, pageSize });

        return Service.getRequest(`${this.#baseServiceUrl}/${memberId}/applications/${clientId}?${queryString}`, { isSecure: true });
    }

    static getClientInvestmentContainersByContactId({
        pageNumber, language, pageSize, clientId, memberId,
    }) {
        const queryString = getQueryStringByObject({ pageNumber, language, pageSize });

        return Service.getRequest(`${this.#baseServiceUrl}/${clientId}/${memberId}/getContainersByContactId?${queryString}`, { isSecure: true });
    }

    static getInvestmentContainersForMember({
        pageNumber, language, pageSize, memberId,
    }) {
        const queryString = getQueryStringByObject({ pageNumber, language, pageSize });

        return Service.getRequest(`${this.#baseServiceUrl}/${memberId}/getContainersByMemberId?${queryString}`, { isSecure: true });
    }

    static getInvestmentApplicationOverview({
        language, containerOrApplicationId, isContainer = false,
    }) {
        const queryString = getQueryStringByObject({ language });

        return Service.getRequest(`${this.#baseServiceUrl}/${containerOrApplicationId}/${isContainer ? 'container-application-info' : 'application-info'}?${queryString}`, { isSecure: true });
    }

    static cancelInvestmentApplications({ investmentApplicationId }) {
        return Service.postRequest(`${this.#baseServiceUrl}/${investmentApplicationId}/cancel`, {}, { isSecure: true });
    }

    static saveProductDetails(payload) {
        return Service.postRequest(`${this.#baseServiceUrl}/save`, payload, { isSecure: true });
    }

    static savePersonalDetails({ investmentApplicationId, ...rest }) {
        return Service.patchRequest(`${this.#baseServiceUrl}/${investmentApplicationId}/personal`, rest, { isSecure: true });
    }

    static saveStrategyData({ investmentApplicationId, ...rest }) {
        return Service.patchRequest(`${this.#baseServiceUrl}/${investmentApplicationId}/strategy`, rest, { isSecure: true });
    }

    static saveKYCDone({ investmentApplicationId, ...rest }) {
        return Service.patchRequest(`${this.#baseServiceUrl}/${investmentApplicationId}/kyc-done`, rest?.kycDone, { isSecure: true });
    }

    static saveKYCDoneByContainer({ id, ...rest }) {
        return Service.putRequest(`${this.#baseServiceUrl}/container/${id}/kyc-done`, rest?.kycDone, { isSecure: true });
    }

    static saveIdUploadDone({ investmentApplicationId, ...rest }) {
        return Service.patchRequest(`${this.#baseServiceUrl}/${investmentApplicationId}/set-id-provided`, rest?.idProvided, { isSecure: true });
    }

    static saveProductData({ investmentApplicationId, ...rest }) {
        return Service.patchRequest(`${this.#baseServiceUrl}/${investmentApplicationId}/product`, rest, { isSecure: true });
    }

    static saveAgentData({ investmentApplicationId, ...rest }) {
        return Service.patchRequest(`${this.#baseServiceUrl}/${investmentApplicationId}/agent`, rest, { isSecure: true });
    }

    static saveCustodyFee({ investmentApplicationId, ...rest }) {
        return Service.patchRequest(`${this.#baseServiceUrl}/${investmentApplicationId}/fees`, rest, { isSecure: true });
    }

    static saveWithdrawalData({ investmentApplicationId, ...rest }) {
        return Service.patchRequest(`${this.#baseServiceUrl}/${investmentApplicationId}/withdrawal`, rest, { isSecure: true });
    }

    static calculateWithdrawalPlanData({ investmentApplicationId, ...rest }) {
        const queryString = getQueryStringByObject({ ...rest });

        return Service.getRequest(`${this.#baseServiceUrl}/${investmentApplicationId}/withdrawalPlan?${queryString}`, { isSecure: true });
    }

    static finalizeNewPortfolio({ investmentApplicationId, ...rest }) {
        return Service.patchRequest(`${this.#baseServiceUrl}/${investmentApplicationId}/finalize`, rest, { isSecure: true });
    }

    static finalizeNewPortfoliosByContainerId({ containerId, ...rest }) {
        return Service.putRequest(`${this.#baseServiceUrl}/container/${containerId}/finalize`, rest, { isSecure: true });
    }

    static renderGeneralDocuments({ containerId }) {
        return Service.postRequest(`${this.#baseServiceUrl}/container/${containerId}/render-general-documents`, {}, { isSecure: true });
    }

    static generateDocuments({ investmentApplicationId }) {
        return Service.postRequest(`${this.#baseServiceUrl}/${investmentApplicationId}/documents`, {}, { isSecure: true });
    }

    static generateDocumentsNew({ investmentApplicationId }) {
        return Service.postRequest(`${this.#baseServiceUrl}/${investmentApplicationId}/documents/generate`, {}, { isSecure: true });
    }

    static getQuestionnaireAnswers({ id }) {
        return Service.getRequest(`${this.#baseServiceUrl}/${id}/questionnaire`, { isSecure: true });
    }

    static getQuestionnaireAnswersByContainer({ id }) {
        return Service.getRequest(`${this.#baseServiceUrl}/container/${id}/questionnaire`, { isSecure: true });
    }

    static postQuestionnaireAnswers({ data, id }) {
        return Service.postRequest(`${this.#baseServiceUrl}/${id}/questionnaire`, data, { isSecure: true });
    }

    static postQuestionnaireAnswersByContainer({ data, id }) {
        return Service.postRequest(`${this.#baseServiceUrl}/container/${id}/questionnaire`, data, { isSecure: true });
    }

    static signDocuments({ investmentApplicationId, isNewFlow = false }) {
        return Service.postRequest(`${this.#baseServiceUrl}/multi-doc?investmentApplicationId=${investmentApplicationId}&isNewFlow=${isNewFlow}`, {}, { isSecure: true });
    }

    static changePlanApplication({ portfolioId, ...rest }) {
        return Service.postRequest(`${this.#baseServiceUrl}/${portfolioId}/changePlanApplication`, rest, { isSecure: true });
    }

    static changePlan({ investmentApplicationId, isNewFlow = false }) {
        return Service.postRequest(`${this.#baseServiceUrl}/${investmentApplicationId}/changePlan?isNewFlow=${isNewFlow}`, {}, { isSecure: true });
    }

    static getInvestmentApplication({ investmentApplicationId }) {
        return Service.getRequest(`${this.#baseServiceUrl}/${investmentApplicationId}`, { isSecure: true });
    }

    static getKudi({ portfolioId }) {
        return Service.getRequest(`${this.#baseServiceUrl}/kudi/${portfolioId}`, { isSecure: true });
    }

    static getCaseManagementDetails({ portfolioId }) {
        return Service.getRequest(`${this.#baseServiceUrl}/${portfolioId}/caseManagementDetails`, { isSecure: true });
    }

    static makeIbanReservation({ investmentApplicationId }) {
        const queryString = getQueryStringByObject({ investmentApplicationId });

        return Service.postRequest(`${this.#baseServiceUrl}/makeIbanReservation?${queryString}`, {}, { isSecure: true });
    }

    static getKudiSaldo({ portfolioId }) {
        return Service.getRequest(`${this.#baseServiceUrl}/${portfolioId}/kudiSaldo`, { isSecure: true });
    }

    static saveAdvisoryDocumentCompletedFlag({ investmentApplicationId, data }) {
        return Service.patchRequest(`${getCustomServiceLayerBaseUrl(CUSTOM_SERVICE_LAYER_SERVICES.INVESTMENT, APIVersion.V11)}/${investmentApplicationId}/advisory-completed`, data, { isSecure: true });
    }

    static generateContainerId({ investmentApplicationId }) {
        return Service.patchRequest(`${this.#baseServiceUrl}/${investmentApplicationId}/add-container`, null, { isSecure: true });
    }

    static getInvestmentDataReport({ from, to }) {
        return Service.getRequest(`${this.#baseServiceUrl}/get-investment-data-report?startDate=${from}&endDate=${to}`, { isSecure: true, axiosSetup: { responseType: 'blob' } });
    }

    // rest -> {advisoryDocumentId, containerId, modelPortfolioId}
    static postChangeExisting3bStrategy({ portfolioId, ...rest }) {
        return Service.postRequest(`${this.#baseServiceUrl}/${portfolioId}/change-strategy`, rest, { isSecure: true });
    }

    // rest -> {advisoryDocumentId, containerId, modelPortfolioId}
    static putChangeExisting3bStrategy({ applicationId, ...rest }) {
        return Service.putRequest(`${this.#baseServiceUrl}/${applicationId}/change-strategy`, rest, { isSecure: true });
    }

    // rest -> {advisoryDocumentId, containerId, isSell, amount}
    static postChangeExisting3bInvestmentAmount({ portfolioId, ...rest }) {
        return Service.postRequest(`${this.#baseServiceUrl}/${portfolioId}/change-amount`, rest, { isSecure: true });
    }

    // rest -> {advisoryDocumentId, containerId, isSell, amount}
    static putChangeExisting3bInvestmentAmount({ applicationId, ...rest }) {
        return Service.putRequest(`${this.#baseServiceUrl}/${applicationId}/change-amount`, rest, { isSecure: true });
    }

    static deleteInvestmentApplication({ investmentApplicationId }) {
        return Service.deleteRequest(`${this.#baseServiceUrl}/${investmentApplicationId}/`, { isSecure: true });
    }
}

export default CustomInvestmentService;
