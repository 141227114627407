import React, {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import {useTranslation} from 'react-i18next';
import {withMainLayout} from 'hocs';
import Preloader from 'components/Preloader';
import {isEmpty} from 'lodash';

import {genarateDFSClientId} from 'utils';
import {useNavigate, useParams} from 'react-router-dom';

import {IsAllDataNull} from 'validation/validation';
import {useOptionList} from 'hooks/useOptionList';

import 'ui-library/components/Button/Button.css';
import 'ui-library/components/Label/Label.css';
import 'ui-library/components/TextInput/TextInput.css';
import 'ui-library/components/DatePicker/DatePicker.css';
import '../../../../../../../../widgets/PersonalDetails/PersonalDetails.css';

import './PersonalDetails.css';
import SM from 'services/ServiceManager';
import {adaptCountry} from 'widgets/PersonalDetails/utils/adapters';
import {Icon, Title} from 'ui-library';
import {NewAddressModal} from 'pages/ClientOverview/pages/ClientConnections/components/NewConnections';
import {ICON_EDIT_OUTLINE} from 'ui-library/components/Icon';
import {useUpdateConnectionMailingAddress} from 'components/Connections/hooks/useUpdateConnectionMailingAddress';
import {merge} from 'pages/utils';
import {useChangePlan} from '../../context';
import {OPTION_LIST} from '../../../../../../../../constants/constants';
import {errorModel} from './models/errorModel';
import {EditablePersonalData} from '../../../../components/EditablePersonalData';

const PersonalDetails = () => {
    const navigate = useNavigate();
    const {t, i18n: {language}} = useTranslation();

    const [isLoadingPersonalData, setLoadingPersonalData] = useState(false);

    const [occupancyList, setOccupancyList] = useState({});
    const [editAddressModalOpen, setEditAddressModalOpen] = useState(false);
    const [mainOwnerErrors, setMainOwnerErrors] = useState(null);
    const [jointOwnerErrors, setJointOwnerErrors] = useState(null);

    const {updateContactGroupAddress} = useUpdateConnectionMailingAddress();

    const {
        mainOwnerDetails,
        layout: {setData: setLayoutData},
        isThreeBProduct,
        isJointAccount,
        portfolioOwners,
        contactGroupAddress,
        contactGroupId,
        getContactGroupDetails,
        jointAccountDetails,
    } = useChangePlan();

    const {portfolioId, dfsClientId} = useParams();

    const baseUrl = useMemo(() => `/client/${dfsClientId}/portfolios/${portfolioId}/change-strategy`, [dfsClientId, portfolioId]);

    const {lists: [optionGender, optionCountry]} = useOptionList([OPTION_LIST.gender, OPTION_LIST.country]);
    const jointAccountClientId = useMemo(() => portfolioOwners.find(f => f.id !== mainOwnerDetails?.clientId)?.id, [mainOwnerDetails?.clientId, portfolioOwners]);

    const editPersonalData = (clientId) => {
        navigate(`/client/${genarateDFSClientId(clientId)}/details`);
    };

    const mailingAddress = useMemo(() => {
        let result = '';

        if (contactGroupAddress?.street) {
            result += `${contactGroupAddress?.street}, `;
        }
        if (contactGroupAddress?.zipCity || contactGroupAddress?.city) {
            result += `${contactGroupAddress?.zipCity} ${contactGroupAddress?.city}, `;
        }
        if (contactGroupAddress?.country && !isEmpty(optionCountry)) {
            result += adaptCountry(optionCountry, contactGroupAddress?.country).label;
        }

        return result || '-';
    }, [contactGroupAddress, optionCountry]);

    const getOccupationList = useCallback(async (gender, occupation) => {
        try {
            if ((!isEmpty(occupancyList[gender]) && occupancyList[gender]?.some(s => s.id === parseInt(occupation, 10))) || !gender || !occupation) {
                return;
            }

            const requestData = {
                language,
                gender,
                selectedId: occupation,
            };
            const {data: occupations} = await SM.customService('getOccupationsLookup', [requestData]);

            const optionItems = occupations?.map(({id, name, disabled}) => ({id, label: name, disabled}));

            setOccupancyList(prev => ({
                ...prev,
                [gender]: merge(prev[gender] ?? [], optionItems, (a, b) => a.id === b.id),
            }));
        } catch (err) {
            console.error(err);
        }
    }, [language, occupancyList]);

    const onContinue = useCallback(async () => {
        const errors = errorModel(mainOwnerDetails);
        const jaErrors = errorModel(jointAccountDetails);

        if (!IsAllDataNull(errors) && !IsAllDataNull(jaErrors)) {
            setMainOwnerErrors(errors);
            setJointOwnerErrors(jaErrors);

            return;
        }

        navigate(`${baseUrl}/investor-profile`);
    }, [mainOwnerDetails]);

    const onBack = useCallback(() => {
        navigate(-1);
    }, []);

    useEffect(() => {
        setLoadingPersonalData(true);
        if (optionCountry !== undefined && optionGender !== undefined && mainOwnerDetails?.clientId && (isJointAccount ? jointAccountDetails?.clientId : true)) {
            setLoadingPersonalData(false);
        }
    }, [optionGender, optionCountry, isJointAccount, mainOwnerDetails?.clientId, jointAccountDetails?.clientId]);

    useEffect(() => {
        setLayoutData({
            stepNavBarActive: isThreeBProduct ? 2 : 1,
        });
    }, [isThreeBProduct]);

    useEffect(() => {
        setLayoutData({
            onContinue,
            onBack,
            disabled: isLoadingPersonalData,
        });
    }, [onContinue, onBack, isLoadingPersonalData]);

    useEffect(() => {
        (async () => getOccupationList(mainOwnerDetails?.gender, mainOwnerDetails?.occupation))();
    }, [getOccupationList, mainOwnerDetails?.gender, mainOwnerDetails?.occupation]);

    useEffect(() => {
        (async () => getOccupationList(jointAccountDetails?.gender, jointAccountDetails?.occupation))();
    }, [getOccupationList, jointAccountDetails?.gender, jointAccountDetails?.occupation]);

    return (
        <div className={`client-details ${isJointAccount ? 'joint-details' : ''}`}>
            <Preloader isLoading={isLoadingPersonalData}>
                <div>
                    <EditablePersonalData
                        data={mainOwnerDetails}
                        editPersonalData={() => editPersonalData(mainOwnerDetails?.clientId)}
                        t={t}
                        errorData={mainOwnerErrors}
                        optionCountry={optionCountry}
                        optionGender={optionGender}
                        optionOccupancy={occupancyList[mainOwnerDetails?.gender]}
                        isChangePlan
                    />
                </div>
                {isJointAccount && (
                    <div>
                        <EditablePersonalData
                            data={jointAccountDetails}
                            editPersonalData={() => editPersonalData(jointAccountClientId)}
                            t={t}
                            errorData={jointOwnerErrors}
                            optionCountry={optionCountry}
                            optionGender={optionGender}
                            optionOccupancy={occupancyList[jointAccountDetails?.gender]}
                            isChangePlan
                        />
                    </div>
                )}

                {isJointAccount && (
                    <NewAddressModal
                        onSubmit={async (data) => {
                            setEditAddressModalOpen(false);

                            if (contactGroupId) {
                                await updateContactGroupAddress(contactGroupId, {
                                    language: data.mailRecipientLanguage,
                                    addLine1: data.mailRecipientAddressLine1,
                                    addLine2: data.mailRecipientAddressLine2,
                                    street: data.mailRecipientAddressLine3,
                                    zipCity: data.mailRecipientAddressLine4,
                                    city: data.mailRecipientAddressLine5,
                                    country: data.mailRecipientAddressCountry,
                                }, getContactGroupDetails);
                            }
                        }}
                        outsideClickHappened={editAddressModalOpen}
                        setEditAddressModalOpen={setEditAddressModalOpen}
                        initData={{
                            mailRecipientLanguage: contactGroupAddress?.language,
                            mailRecipientAddressLine1: contactGroupAddress?.addLine1,
                            mailRecipientAddressLine2: contactGroupAddress?.addLine2,
                            mailRecipientAddressLine3: contactGroupAddress?.street,
                            mailRecipientAddressLine4: contactGroupAddress?.zipCity,
                            mailRecipientAddressLine5: contactGroupAddress?.city,
                            mailRecipientAddressCountry: contactGroupAddress?.country,
                        }}
                        customRenderComponent={(
                            <div className="DataList__Item--hspan">
                                <div className="LinkedHeader">
                                    <Title type={2} data-cy="full_name">
                                        {t('dynamicCRM.mailRecipientFieldset')}
                                    </Title>
                                    <button
                                        className="button-link"
                                        type="button"
                                        data-cy="edit_personal_details"
                                        onClick={() => setEditAddressModalOpen(true)}
                                    >
                                        <Icon size={24} type={ICON_EDIT_OUTLINE}/>
                                        {t('interaction.edit')}
                                    </button>

                                </div>
                                <dd data-cy="city_zip">{mailingAddress}</dd>
                            </div>
                        )}
                    />

                )}
            </Preloader>
        </div>
    );
};

export default withMainLayout(PersonalDetails);
