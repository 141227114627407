/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, {
    useCallback, useEffect, useMemo, useState,
} from 'react';

import { getClientId } from 'utils';
import {
    Infobox, Loader,
} from 'ui-library';
import {useTranslation} from 'react-i18next';
import {adaptAnswers} from '../../../../../../../../adaptors/adaptAnswers';
import {InvestorProfileWithProvider} from './InvestorProfileWithProvider';

import './InvestorProfile.css';

export function InvestorProfilesContainer(props) {
    const { t, i18n: { language } } = useTranslation();
    const { dfsClientId, dataSource } = props;

    const {
        isPk, setLayoutData, setPageErrors, isJointAccount, navigateForward,
        isUsersLoading, saveAdditionalData,
        productDescription,
        clientAge,
        childClientName,
        targetClientName,
        targetDFSClientId,
    } = dataSource({dfsClientId});

    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isSending, setSending] = useState(false);
    const [profiles, setProfiles] = useState({});

    // Handlers/Callbacks

    const onQuestionnaireUpdate = useCallback((key, value) => {
        setProfiles({
            ...profiles,
            [key]: value,
        });
    }, [profiles]);

    // Handlers/Callbacks
    const onContinue = useCallback(async () => {
        try {
            setIsSubmitted(true);
            const allQuestionsFilled = Object.keys(profiles).map(key => profiles?.[key]?.allQuestionsFilled);

            if (!allQuestionsFilled?.some(a => a === false)) {
                const clientRiskToAccept = Math.max(...Object.keys(profiles).map(dfsId => getClientId(dfsId)));

                setSending(true);
                await Promise.all(Object.keys(profiles).map(async (key) => {
                    const {
                        clientId: mClientId,
                        data,
                        formData,
                        groupId,
                        isDataChanged,
                        postQuestionnaire,
                        calculateRisk,
                        setChosenRisk,
                    } = profiles?.[key];

                    const answers = adaptAnswers({data, values: formData, groupId});
                    const isQChanged = isDataChanged();

                    if (isQChanged) {
                        await postQuestionnaire({answers});
                    }

                    const response = await calculateRisk();

                    if (isQChanged) {
                        await setChosenRisk({id: response?.RiskCategory?.Id});
                    }

                    if (saveAdditionalData) {
                        await saveAdditionalData({clientId: mClientId, clientRiskToAccept, response});
                    }
                }));
                setSending(false);
                setIsSubmitted(false);

                navigateForward();
            }
        } catch (err) {
            console.error(err);
        }
    }, [profiles, isPk, language, navigateForward, saveAdditionalData]);

    const isAnyInvestorProfileLoading = useMemo(() => Object.keys(profiles).map(key => profiles?.[key]?.isLoading)?.some((a) => (a === true)), [profiles]);

    useEffect(() => {
        setLayoutData({
            onContinue,
            disabled: isAnyInvestorProfileLoading || isSending,
        });
    }, [onContinue, profiles, isSending]);

    useEffect(() => {
        setLayoutData({ saveInProgress: isSending});
    }, [isSending]);

    useEffect(() => {
        setPageErrors(Object.keys(profiles).find(key => profiles?.[key]?.pageError));
    }, [profiles]);

    if (isUsersLoading) {
        return <Loader />;
    }

    return (
        <>
            <InvestorProfileWithProvider
                clientName={targetClientName}
                clientAge={clientAge}
                title={productDescription}
                sessionStorageKey={`create-portfolio/investor-profile/${targetDFSClientId}/data`}
                dfsClientId={targetDFSClientId}
                onQuestionnaireUpdate={onQuestionnaireUpdate}
                isSubmitted={isSubmitted}
                isSending={isSending}
            />
            {isJointAccount && (
                <Infobox className="ja-message">
                    {t('portfolio.createNewPortfolio.investorProfile.jaInfoMessage', {clientFullName: childClientName, clientOwnerFullName: targetClientName})}
                </Infobox>
            )}
        </>
    );
}
