import React from 'react';
import {PortfolioDetailHeader} from './PortfolioDetail/PortfolioDetailHeader';
import {Navigation} from './Navigation/Navigation';
import './SinglePortfolioLayout.css';

const SinglePortfolioLayout = (props) => (
    <div className="spl">
        <div className="spl__nav">
            <Navigation navigation={props.navigation} />
        </div>
        <div className="spl__content">
            <PortfolioDetailHeader portfolioId={props.portfolioId}/>
            {props.children}
        </div>
    </div>
);

export default SinglePortfolioLayout;
