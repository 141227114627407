import FileSaver from 'file-saver';

const file = (data) => ({
    stringFile: atob(data.File || data.file),
    fileType: data.MimeType || data.mimeType,
    fileName: data.OriginalFilename || data.originalFilename,
});

export const downloadDmsDocument = (data, { name, saveFile }) => {
    const byteString = file(data).stringFile;
    const buffer = new ArrayBuffer(byteString.length);
    const dataBuffer = new Uint8Array(buffer);

    for (let i = 0; i < byteString.length; i += 1) {
        dataBuffer[i] = byteString.charCodeAt(i);
    }
    const newBlob = new Blob([buffer],
        {
            type: file(data).fileType,
        });

    if (saveFile) FileSaver.saveAs(newBlob, name || file(data).fileName);

    return { newBlob };
};
