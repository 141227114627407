import {useQuery} from '@tanstack/react-query';
import ServerError from '../../errors/ServerError';
import SM from '../../services/ServiceManager';

export const useGetContactGroup = ({
    connectionGroupId,
}) => {
    const result = useQuery({
        queryKey: ['getContactGroupDetails', connectionGroupId],
        enabled: !!connectionGroupId,
        retry: false,
        gcTime: 30, // seconds
        queryFn: async () => {
            const {data} = await SM.customClientService('getContactGroupDetails', [{groupId: connectionGroupId}]);

            return data;
        },
    });

    return {
        ...result,
        error: result.error ? new ServerError(result.error) : result.error,
    };
};
