import i18n from 'i18next';

export const getValidationMessage = (params) => ({
    mandatoryError: i18n.t('validation.mandatoryField'),
    nameError: i18n.t('onboardingFlow.personalPage.validationOfName'),
    notBirthDateError: i18n.t('onboardingFlow.personalPage.validationBirthday'),
    birthDateError: i18n.t('onboardingFlow.personalPage.validationBirthdayRange'),
    numberError: i18n.t('onboardingFlow.personalPage.validationNumber'),
    emailError: i18n.t('validation.invalidEmail'),
    emailInUse: i18n.t('onboardingFlow.personalPage.errorEmailIsRegister'),
    phoneError: i18n.t('onboardingFlow.personalPage.validationPhoneNumber'),
    alphanumericError: i18n.t('onboardingFlow.personalPage.validationAlphanumeric'),
    lengthError: i18n.t('onboardingFlow.personalPage.validateInputLength', {length: params?.length}),
});
