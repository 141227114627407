/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import RowCell from './RowCell';
import { getClassNames } from '../../../utils';

const Row = ({
    data, index, hidden, columns, level, scrollable, expandedRows, onRow, expandOnClick, rowClassName
}) => {
    const [expanded, setExpanded] = useState(expandedRows.includes(data.key));
    const rowProps = onRow(data, index) || { className: '' };
    const className = getClassNames(rowClassName, rowProps.className, `row-level-${level}`);

    useEffect(() => { setExpanded(expandedRows.includes(data.key)); },
        [expandedRows, data.key]);

    return (
        <>
            <tr role="row" hidden={hidden} {...rowProps} className={className}>
                {columns.map((column, index) => (
                    <RowCell
                        row={data}
                        data={data[column.key]}
                        index={index}
                        level={level}
                        column={column}
                        expanded={expanded}
                        scrollable={scrollable}
                        onExpand={() => expandOnClick(data.key)}
                        key={`row-cell-${column.key}`}
                    />
                ))}
            </tr>
            {(data.children || []).map((item, ind) => (
                <Row
                    data={item}
                    index={ind}
                    level={level + 1}
                    hidden={!expanded}
                    columns={columns}
                    scrollable={scrollable}
                    key={`row-child-${item.key}`}
                />
            ))}
        </>
    );
};

Row.propTypes = {
    data: PropTypes.shape({
        key: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]),
        [PropTypes.string]: PropTypes.any,
        children: PropTypes.arrayOf(PropTypes.shape({
            [PropTypes.string]: PropTypes.any,
        })),
    }),
    index: PropTypes.number,
    level: PropTypes.number,
    hidden: PropTypes.bool,
    scrollable: PropTypes.bool,
    expandedRows: PropTypes.arrayOf(PropTypes.string),
    columns: PropTypes.arrayOf(PropTypes.shape({
        [PropTypes.string]: PropTypes.any,
    })),
    onRow: PropTypes.func,
    expandOnClick: PropTypes.func,
};

Row.defaultProps = {
    data: {
        children: undefined,
    },
    index: 0,
    level: 0,
    hidden: false,
    scrollable: false,
    expandedRows: [],
    columns: [],
    onRow: () => ({}),
    expandOnClick: () => {},
};

export default Row;
