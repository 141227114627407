import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Note from 'components/common/Note/Note';
import { UploadAdvisoryDocumentModal } from 'components/UploadAdvisoryDocumentModal/UploadAdvisoryDocumentModal';
import { DocumentType } from 'components/UploadAdvisoryDocumentModal/hooks/useAdvisoryDocumentBase';
import { Button } from '../../ui-library';

import './AdvisoryTypeSelection.css';

export const AdvisoryTypeSelection = (props) => {
    const { t } = useTranslation();
    const [showModal, setShowModal] = useState<boolean>(false);

    const {
        contactGroupIdSelector,
        setUploadedAdvisoryDocumentAction,
        stepComplete,
        startNewAdvisoryProcess,
        isAdvisoryDataLoading,
    } = props;

    const onModalClose = useCallback(async (advisoryDocument: DocumentType | null) => {
        if (advisoryDocument) {
            setUploadedAdvisoryDocumentAction(advisoryDocument);
            stepComplete();
        } else {
            setShowModal(false);
        }
    }, [setUploadedAdvisoryDocumentAction, stepComplete]);

    const onNewProcessClick = useCallback(async () => {
        await startNewAdvisoryProcess();
    }, [stepComplete]);

    return (
        <section className="advisory-type-selection">
            <Button
                type="primary"
                className="start-new-process"
                onClick={onNewProcessClick}
                loading={isAdvisoryDataLoading}
            >
                {t('advisoryTypeSelection.startNewAdvisory')}
            </Button>
            <p>{t('advisoryTypeSelection.or')}</p>
            <Button
                className="upload-existing"
                type="outline"
                onClick={() => setShowModal(true)}
            >
                {t('advisoryTypeSelection.uploadExisting')}
            </Button>
            <Note text="advisoryTypeSelection.note"/>
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore */}
            <UploadAdvisoryDocumentModal
                show={showModal}
                onClose={onModalClose}
                inAdvisoryFlow
                contactGroupId={contactGroupIdSelector}
            />
        </section>
    );
};
