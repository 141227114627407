/* eslint-disable dot-notation */
import moment from 'moment';
import {dateTimeStandardFormat, formatDate} from 'utils/datetime';

type SendModelType = {
    data: any,
    isAzp?: boolean,
    amountAccount?: number,
    amountDeposit?: number,
    deferralPhaseDuration?: number,
    plannedEndDate?: string,
    withdrawalAmount?: string | number,
}

export const sendModel = ({
    data,
    isAzp,
    amountAccount,
    amountDeposit,
    deferralPhaseDuration,
    plannedEndDate,
    withdrawalAmount,
}: SendModelType) => {
    const withdrawalRequest = {
        withdrawalAmount: isAzp ? +parseFloat(`${withdrawalAmount}`)?.toFixed(2) : parseInt(data?.amount, 10),
        periodicity: parseInt(data?.frequency, 10),
        startDate: moment(data?.startDate, 'MM/YYYY').format(moment.defaultFormatUtc),
        ...(isAzp ? {
            investmentAmount: parseInt(data?.amount, 10),
            amountAccount,
            amountDeposit,
            deferralPhaseDuration,
            plannedEndDate,
        } : {}),
    };

    if (data?.duration && data?.withdrawalsStartDate) {
        withdrawalRequest['duration'] = parseInt(data?.duration, 10);
        withdrawalRequest.startDate = data?.withdrawalsStartDate;
    }

    if (data?.targetPortfolio) {
        withdrawalRequest['targetPortfolio'] = parseInt(data?.targetPortfolio, 10);
    }

    const payload = {
        withdrawalRequest,
        paymentInstructionRequest: {
            iban: data?.iban,
            firstName: data?.firstName,
            LastName: data?.lastName,
            bankName: data?.bankName,
            zipCity: data?.zipCode,
            city: data?.city,
        },
    };

    return payload;
};

export const sendWithdrawalPlanModel = (data) => {
    const payload = {
        InvestmentAmount: parseInt(data?.amount, 10),
        StartOfWithdrawal: formatDate(data?.withdrawalsStartDate, dateTimeStandardFormat),
        Periodicity: parseInt(data?.frequency, 10),
        Duration: parseInt(data?.duration, 10),
    };

    return payload;
};
