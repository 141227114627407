import React from 'react';
import PropTypes from 'prop-types';
import { getClassNames } from '../../../utils';

function CellWithMessageTitleClient({ title, subtitle, isRead }) {
    const className = getClassNames(
        'table-cell_with-subtext', 'table-cell_with_message',
        { 'table-cell_with_message-new': !isRead },
    );

    return (
        <div className={className}>
            <b>{title}</b>
            <p data-isread={isRead}>{subtitle}</p>
        </div>
    );
}

CellWithMessageTitleClient.propTypes = {
    isRead: PropTypes.bool,
    title: PropTypes.string,
    subtitle: PropTypes.string,
};

CellWithMessageTitleClient.defaultProps = {
    isRead: false,
    title: null,
    subtitle: null,
};

export default CellWithMessageTitleClient;
