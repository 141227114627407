import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import withTheme from '../../hocs/withTheme';
import './BreadCrumbs.css';
import { createUniqueKey } from '../../utils';

const BreadCrumbs = ({ items, current, children }) => {
    const breadcrumbs = items.length > 0 ? items : React.Children.toArray(children);

    return (
        <div className="BreadCrumbs">
            <ul>
                {(breadcrumbs || []).map((item, index) => (
                    <li
                        key={createUniqueKey(index, item?.label)}
                        className="BreadCrumbs__item"
                    >
                        {item.to ? <Link key={item.label} to={item.to}>{item.label}</Link> : item}
                    </li>
                ))}
                <li className="BreadCrumbs__item BreadCrumbs__item_current">{current}</li>
            </ul>
        </div>
    );
};

BreadCrumbs.propTypes = {
    /** Items to be shown (can be also defined with children) */
    items: PropTypes.arrayOf(
        PropTypes.shape({
            to: PropTypes.string,
            label: PropTypes.string,
        }),
    ),
    /** Define a current position label */
    current: PropTypes.string,
    /** @ignore */
    children: PropTypes.node,
};

BreadCrumbs.defaultProps = {
    items: [],
    current: null,
    children: null,
};


export default withTheme(BreadCrumbs);
