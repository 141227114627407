import React, {
    useEffect, useMemo, useRef, useState, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import OutsideClickHandler from 'react-outside-click-handler';
import Input from '../TextInput';
import OptionList from '../SelectInput/Select/OptionList';
import {getClassNames, transformOptions} from '../../utils';
import uid from '../../utils/uid';

import './AutoComplete.css';
import withTheme from '../../hocs/withTheme';

function AutoComplete(props) {
    const {
        id,
        value: valueProp,
        dataSource,
        placeholder,
        children,
        className: classNameProp,
        dropdownClassName: dropdownClassNameProp,
        onSearch,
        onSelect,
        isDropdownOpen,
        externalSearch,
    } = props;
    const listRef = useRef(null);
    const uId = useMemo(() => id || uid('results'), [id]);
    const [data, setData] = useState(transformOptions(dataSource));
    const [value, setValue] = useState(valueProp || '');
    const [activeIndex, setActiveIndex] = useState(-1);
    const [opened, setOpened] = useState(isDropdownOpen);
    const [options, setOptions] = useState([]);
    const updateOptions = useCallback((val = value) => {
        if (!externalSearch) {
            const filtered = data.filter((item) => item.value
                .toLocaleLowerCase().includes(val.toLocaleLowerCase()));

            setOptions(filtered);
        } else {
            setOptions(data);
        }
    }, [data, value]);

    useEffect(() => {
        setData(transformOptions(dataSource));
        if (externalSearch && isDropdownOpen) {
            setOpened(true);
        }
    }, [dataSource, externalSearch]);
    useEffect(() => {
        setValue(valueProp || '');
    }, [valueProp]);
    useEffect(() => {
        if (listRef.current) {
            const {scrollTop, offsetHeight: height} = listRef.current;
            const scrollBottom = scrollTop + height;
            const item = listRef.current.children[activeIndex];
            const heightItem = item && item.offsetHeight;
            const topItem = item && item.offsetTop;

            if (topItem > scrollBottom - heightItem) {
                listRef.current.scrollTo({top: topItem - scrollBottom + scrollTop + heightItem});
            }
            if (topItem < scrollTop) {
                listRef.current.scrollTo({top: topItem});
            }
        }
    }, [activeIndex]);

    useEffect(() => {
        setOpened(isDropdownOpen);
    }, [isDropdownOpen]);

    useEffect(() => {
        updateOptions();
    }, [updateOptions]);

    const closeDropdown = () => {
        setOpened(false);
        setActiveIndex(-1);
    };
    const onChange = (e) => {
        setValue(e.target.value);
        onSearch(e.target.value);
        setOpened(true);
        updateOptions(e.target.value);
    };

    const onFocus = () => {
        setOpened(true);
        updateOptions();
    };

    const selectItem = (value) => {
        const selected = typeof value === 'string' ? value : value && value.value;

        setValue(valueProp !== undefined ? valueProp : selected);
        onSelect(selected);
        closeDropdown();
    };

    const onKeyUp = (e) => {
        if (typeof children?.props?.onKeyUp === 'function') {
            // eslint-disable-next-line no-unused-expressions
            children?.props?.onKeyUp(e);
        }

        switch (e.key) {
            case 'ArrowUp':
            case 'ArrowDown':
            case 'Escape':
            case 'Enter':
                e.preventDefault();

                return;
            default:
                updateOptions();
        }
    };
    const onKeyDown = (e) => {
        if (typeof children?.props?.onKeyDown === 'function') {
            // eslint-disable-next-line no-unused-expressions
            children?.props?.onKeyDown(e);
        }
        if (e.key === 'Escape') {
            closeDropdown();
            setValue('');

            return;
        }

        if (options.length < 1) return;

        switch (e.key) {
            case 'ArrowUp':
                if (activeIndex > 0) {
                    setActiveIndex(activeIndex - 1);
                }
                break;
            case 'ArrowDown':
                if (activeIndex < options.length - 1) {
                    setActiveIndex(activeIndex + 1);
                }
                break;
            case 'Enter':
                selectItem(options[activeIndex]);

                return;
            case 'Tab':
                if (activeIndex >= 0) {
                    selectItem(options[activeIndex]);
                }
                closeDropdown();

                return;
            default:
                return;
        }

        e.preventDefault();
    };

    const selected = useMemo(() => options[activeIndex] && options[activeIndex].value,
        [options, activeIndex]);
    const inputProps = useMemo(() => ({
        value,
        placeholder,
        role: 'combobox',
        autoComplete: 'off',
        'aria-owns': uId,
        'aria-controls': uId,
        'aria-expanded': opened,
        'aria-describedby': 'initInstr',
        'aria-autocomplete': 'both',
        'aria-activedescendant': selected,
        onFocus,
        onKeyUp,
        onKeyDown,
        onChange,
    }), [uId, placeholder, value, opened, selected, onChange, onFocus, onKeyUp, onKeyDown]);

    const child = useMemo(() => children && React.cloneElement(children, inputProps),
        [children, inputProps]);

    const className = getClassNames(
        classNameProp,
        'AutoComplete',
        {AutoComplete__opened: opened && options.length > 0},
    );
    const dropdownClassName = getClassNames(
        dropdownClassNameProp,
        'AutoComplete-dropdown',
        {'AutoComplete-dropdown__opened': opened && options.length > 0},
    );

    return (
        <OutsideClickHandler onOutsideClick={closeDropdown}>
            <div className={className}>
                {child || <Input {...inputProps} />}
                <div id={uId} className={dropdownClassName} role="listbox" ref={listRef}>
                    <OptionList
                        name={`${uId}-option`}
                        options={options}
                        selected={selected}
                        clicked={selectItem}
                    />
                </div>
            </div>
        </OutsideClickHandler>
    );
}

AutoComplete.propTypes = {
    /** Id for the component */
    id: PropTypes.string,
    /** The default value */
    value: PropTypes.string,
    /** list of options to show in dropdown list */
    dataSource: PropTypes.arrayOf(PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({value: PropTypes.string, label: PropTypes.string}),
    ])),
    /** Placeholder text */
    placeholder: PropTypes.string,
    /** Callback on selected value from the search list function(value) */
    onSelect: PropTypes.func,
    /** Callback on change function(value) */
    onSearch: PropTypes.func,
    /** Custom class */
    className: PropTypes.string,
    /** The dropdown custom class */
    dropdownClassName: PropTypes.string,
    /** Trigger open/close dropdown list */
    isDropdownOpen: PropTypes.bool,
    /** the custom input component */
    children: PropTypes.node,
    externalSearch: PropTypes.bool,
};

AutoComplete.defaultProps = {
    id: null,
    value: undefined,
    dataSource: [],
    placeholder: 'Start typing...',
    onSelect: () => {
    },
    onSearch: () => {
    },
    className: undefined,
    dropdownClassName: undefined,
    isDropdownOpen: false,
    children: null,
    externalSearch: false,
};

export default withTheme(AutoComplete);
