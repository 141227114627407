import {AccessPolicy, Address, JADocument} from 'components/Connections/types';

export const CONNECTIONS_SESSION_KEY = 'newConnections';

export type NewConnectionType = 'ja' | 'poa' | 'representative';

export type MailingAddress = {
    language: number;
    addLine1: string;
    addLine2: string;
    street: string;
    zipCity: string;
    city: string;
    country: number;
}

export type NewConnectionsSessionManagerAPI = {
    contactId?: number;
    relationship?: string;
    connectionType?: NewConnectionType;
    ownerName?: string;
    ownerExternalId?: string;
    accessPolicy?: AccessPolicy;
    clients?: SelectedConnection[];
    mailingAddress?: MailingAddress;
    contactGroupId?: number;
    includeCCDoc?: boolean;
    newlyCreatedAddresses?: Address[];
    returnUrl?: string;
    documents?: JADocument[];
}

export type SelectedConnection = {
    id: number;
    name: string;
    externalId: string;
};

export enum ContactGroupDocumentTypes {
    generalDoc = 15,
    form = 18,
    ccApproval = 19
}
