/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import withTheme from '../../hocs/withTheme';
import { getClassNames, capitalize } from '../../utils';

import './RangeSlider.css';


const ORIENTATION_CONSTS = {
    dimension: 'width',
    direction: 'left',
    coordinate: 'x',
};

function RangeSlider({
    className,
    min,
    max,
    value,
    dotHidden,
    marks,
    step,
    onChange,
}) {
    const { dimension, direction, coordinate } = ORIENTATION_CONSTS;

    const sliderRef = useRef(null);

    const [state, setState] = useState({
        active: false,
        limit: 0,
    });

    const update = () => {
        if (sliderRef?.current) {
            const capDimension = capitalize(dimension);
            const sliderPos = sliderRef.current[`offset${capDimension}`];

            setState((prev) => ({ ...prev, limit: sliderPos }));
        }
    };

    useEffect(() => {
        update();

        window.addEventListener('resize', update);

        return () => window.removeEventListener('resize', update);
    }, []);

    const getPositionFromValue = (value) => {
        const diffMaxMin = max - min;
        const diffValMin = value - min;
        const percentage = diffValMin / diffMaxMin;

        return Math.round(percentage * state.limit);
    };

    const getValueFromPosition = (pos) => {
        const { limit } = state;
        const percentage = Math.min(Math.max(pos, 0), limit) / (limit || 1);
        const baseVal = step * Math.round(percentage * (max - min) / step);

        return Math.min(Math.max(baseVal + min, min), max);
    };

    const getCalculatedPosition = (e) => {
        const clientCoordinateStyle = `client${capitalize(coordinate)}`;
        const calcCoordinate = !e.touches
            ? e[clientCoordinateStyle]
            : e.touches[0][clientCoordinateStyle];
        const calcDirection = sliderRef.current.getBoundingClientRect()[direction];
        const pos = calcCoordinate - calcDirection;

        return getValueFromPosition(pos);
    };

    const getCoordinates = (pos) => {
        const value = getValueFromPosition(pos);
        const position = getPositionFromValue(value);

        return { fill: position, handle: position, label: position };
    };

    const handleKeyDown = (e) => {
        e.preventDefault();

        const { keyCode } = e;
        let sliderValue;

        switch (keyCode) {
            case 38:
            case 39:
                sliderValue = value + step > max ? max : value + step;
                onChange(sliderValue);

                break;
            case 37:
            case 40:
                sliderValue = value - step < min ? min : value - step;
                onChange(sliderValue);

                break;
            default:
        }
    };

    const handleDrag = (e) => {
        e.stopPropagation();

        const { target: { classList, dataset } } = e;
        let value = getCalculatedPosition(e);

        if (classList && classList.contains('RangeSlider__marks-item') && dataset.value) {
            value = parseFloat(dataset.value);
        }

        onChange(value);
    };

    const handleStart = () => {
        document.addEventListener('mousemove', handleDrag);
        document.addEventListener('mouseup', handleEnd);

        setState((prev) => ({ ...prev, active: true }));
    };

    const handleEnd = () => {
        setState((prev) => ({ ...prev, active: false }));

        document.removeEventListener('mousemove', handleDrag);
        document.removeEventListener('mouseup', handleEnd);
    };

    const renderMarks = (forDot = false) => {
        const marksKeys = Object.keys(marks).sort((a, b) => (b + a));

        return marksKeys.map((key) => {
            const labelPosition = getPositionFromValue(key);
            const labelCoords = getCoordinates(labelPosition);
            // Rude hack, just to align line to circle handle element
            // we need it because circle handle width is even number and line below too
            const position = forDot ? labelCoords.label - 1.5 : labelCoords.label;
            const style = { [direction]: `${position}px` };

            return (
                <span
                    key={key}
                    className={forDot ? 'RangeSlider__dots-item' : 'RangeSlider__marks-item'}
                    onMouseDown={handleDrag}
                    onTouchStart={handleStart}
                    onTouchEnd={handleEnd}
                    data-value={key}
                    style={style}
                >
                    {!forDot && marks[key]}
                </span>
            );
        });
    };

    const position = getPositionFromValue(value);
    const coords = getCoordinates(position);
    const fillStyle = { [dimension]: `${coords.fill}px` };
    const handleStyle = { [direction]: `${coords.handle}px` };

    return (
        <div
            ref={sliderRef}
            className={getClassNames('RangeSlider', className)}
            aria-valuemin={min}
            aria-valuemax={max}
            aria-valuenow={value}
            onMouseDown={handleDrag}
            onMouseUp={handleEnd}
            onTouchStart={handleStart}
            onTouchEnd={handleEnd}
        >
            <div className={getClassNames('RangeSlider__fill', { RangeSlider__fill_hidden: dotHidden })} style={fillStyle} />
            <div className="RangeSlider__dots">
                {renderMarks(true)}
            </div>
            <div
                className={getClassNames('RangeSlider__handle', { RangeSlider__handle_hidden: dotHidden && value === undefined })}
                onKeyDown={handleKeyDown}
                style={handleStyle}
                tabIndex={0}
                onMouseDown={handleStart}
                onTouchMove={handleDrag}
                onTouchEnd={handleEnd}
            />
            <div className="RangeSlider__marks">
                {renderMarks()}
            </div>
        </div>
    );
}

RangeSlider.propTypes = {
    /** Tick mark of Slider, type of key must be number,
     * and must in closed interval [min, max], each mark can declare its own style */
    marks: PropTypes.shape({
        [PropTypes.number]: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.node,
            PropTypes.shape({
                style: PropTypes.any,
                label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
            }),
        ]),
    }).isRequired,
    /** @ignored */
    className: PropTypes.string,
    /** The default value of slider */
    value: PropTypes.number,
    /** @ignored */
    step: PropTypes.number,
    /** The minimum value the slider can slide to */
    min: PropTypes.number,
    /** The maximum value the slider can slide to */
    max: PropTypes.number,
    /** If the initial slider has visible dot handle */
    dotHidden: PropTypes.bool,
    /** Callback function that is fired when the user changes the slider's value */
    onChange: PropTypes.func,
};

RangeSlider.defaultProps = {
    className: '',
    value: undefined,
    min: 0,
    step: 1,
    max: 100,
    dotHidden: false,
    onChange: () => { },
};

export default withTheme(RangeSlider);
