import { transformOptions } from '../../utils';

export const getSelectedOptionsTitles = (
    allOptions, selectedOptions,
) => allOptions && transformOptions(allOptions)
    .filter(({ value }) => (
        selectedOptions && selectedOptions.indexOf && selectedOptions.indexOf(value) !== -1
    )).map(({ label }) => label);

export const filterOptions = (options, searchStr) => transformOptions(options)
    .filter(
        ({ label }) => label?.toLowerCase()?.search(searchStr?.toLowerCase()) !== -1,
    );

export const getSelectedValue = (value) => (value !== null && typeof value === 'object'
    ? value.value : value);

export const getValueByIndex = (values, index) => (values[index]?.value !== undefined
    ? values[index]?.value : values[index]);


export const getOptionsWithoutSelected = (
    options, selectedOption,
) => {
    if (!options) {
        return [];
    }

    const transformedOptions = transformOptions(options);

    const filteredTransformedOptions = transformedOptions
        .filter(({ value }) => selectedOption !== value);

    if (options.every((option) => typeof option === 'string')) {
        return filteredTransformedOptions.map((transformedOption) => transformedOption.value);
    }

    return filteredTransformedOptions;
};
