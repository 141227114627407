import React, { useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Link, useNavigate, useParams} from 'react-router-dom';
import { Modal, Select, Icon } from 'ui-library';

import { useConnections } from 'components/Connections/components';
// eslint-disable-next-line import/no-cycle
import { ConnectionType } from 'components/Connections';
import {AccessPolicy, ContactGroupEnum} from 'components/Connections/types';
import {
    CONNECTIONS_SESSION_KEY,
    NewConnectionsSessionManagerAPI,
} from '../NewConnections/constants';
import {ICON_TYPE_OPEN} from '../../../../../../ui-library/components/Icon';

import './NewConnectionModal.css';

type Options = {
    label: string;
    value: ConnectionType;
}

type NewConnectionModalProps = {
    hasJAOpened?: boolean;
    allowProxyAddition?: boolean;
}

const NewConnectionModal = ({hasJAOpened, allowProxyAddition}: NewConnectionModalProps) => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const { dfsClientId, groupId } = useParams<any>();
    const { isAddConnectionModalOpen, closeAddConnectionModal, contactGroupDetails } = useConnections();

    const options: Options[] = useMemo(() => {
        const items: Options[] = [
            ...((groupId || hasJAOpened) ? [] : [{
                label: `${t('connections.addConnection.jointAccount')}`,
                value: 'JointAccount' as ConnectionType,
            }]),
            ...(allowProxyAddition ? [{
                label: `${t('connections.addConnection.powerOfAttorney')}`,
                value: 'PowerOfAttorney' as ConnectionType,
            }, {
                label: `${t('connections.addConnection.representative')}`,
                value: 'Representative' as ConnectionType,
            }] : []),

        ];

        return items;
    }, [groupId, hasJAOpened, allowProxyAddition]);
    const [connectionType, setConnectionType] = useState<ConnectionType | undefined>();

    const onClick = () => {
        const url = `/client/${dfsClientId}/connections/new-connection/customers`;

        const newConnection: string | NewConnectionsSessionManagerAPI | null = {};

        switch (connectionType) {
            case 'JointAccount':
                newConnection.connectionType = 'ja';
                break;
            case 'PowerOfAttorney':
                newConnection.connectionType = 'poa';
                newConnection.accessPolicy = AccessPolicy.PowerOfAttorney;
                break;
            case 'Representative':
                newConnection.connectionType = 'representative';
                newConnection.accessPolicy = AccessPolicy.Representative;

                break;
            default:
                console.error('Unknown connection type');
                closeAddConnectionModal();
                break;
        }

        if (groupId) {
            newConnection.returnUrl = global.window.location.pathname;
            newConnection.contactGroupId = +groupId;
            if (contactGroupDetails?.groupName === ContactGroupEnum.ja) {
                newConnection.ownerName = Object.values(contactGroupDetails?.owners ?? {}).join(', ');
                newConnection.ownerExternalId = contactGroupDetails?.externalId;
            }
        }

        global.sessionStorage.setItem(CONNECTIONS_SESSION_KEY, JSON.stringify(newConnection));

        closeAddConnectionModal();

        navigate(url);
    };

    return (
        <Modal
            title={t('connections.modal.title')}
            cancelText={t('connections.modal.cancel')}
            onOk={onClick}
            okText={t('connections.modal.next')}
            onCancel={closeAddConnectionModal}
            width={540}
            visible={isAddConnectionModalOpen}
            okButtonProps={{disabled: !connectionType}}
            className="new_connection_modal"
        >
            <div className="note">
                <span>{t('connections.addConnection.note')}</span>
                <Link to="/onboardingflow/newPersonalInformation">
                    {t('connections.addConnection.newPersonLink')}
                    {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                    {/* @ts-ignore */}
                    <Icon type={ICON_TYPE_OPEN} size={16}/>
                </Link>
            </div>
            <Select placeholder={t('connections.addConnection.placeholder')} options={options} onChange={(value: ConnectionType) => setConnectionType(value)} />
        </Modal>
    );
};

export default NewConnectionModal;

NewConnectionModal.defaultProps = {
    hasJAOpened: false,
    allowProxyAddition: false,
};
