import React from 'react';
import { useParams } from 'react-router-dom';
import { ClientApplicationOverviewProvider } from './context';
import InvestmentApplicationProducts from './components/InvestmentApplicationProducts/InvestmentApplicationProducts';
import InvestmentApplicationHeader from './components/InvestmentApplicationHeader/InvestmentApplicationHeader';

function ClientApplicationOverview(props) {
    const {dfsClientId, containerId, applicationId } = useParams();

    return (
        <ClientApplicationOverviewProvider {...{dfsClientId, containerId, applicationId}}>
            <div className="container">
                <div className="fi-verticals">
                    {containerId && <InvestmentApplicationHeader containerId={containerId} /* applicationId={applicationId}  backLink={`/client/${dfsClientId}/dashboard`} */ />}
                    <InvestmentApplicationProducts/>

                </div>
            </div>
        </ClientApplicationOverviewProvider>
    );
}

export default ClientApplicationOverview;
