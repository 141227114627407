export const adaptQualityCheckQuestionnaire = (data) => {
    if (!data?.Groups?.[1]?.Steps?.[0]?.Questions) return data;

    data.Groups[1].Steps[0].Questions = data.Groups[1].Steps[0].Questions.map((question) => {
        if (question?.Type === 'SingleAnswer') {
            question.Disabled = true;
        }

        return question;
    });

    return data;
};
