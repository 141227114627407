import React, {
    useCallback, useMemo,
} from 'react';
import './PortfolioDetailHeader.css';
import {useNavigate} from 'react-router-dom';
import {Select} from '../../../../../../../../../ui-library';

import {
    BLOCKED,
    NOT_IMPLEMENTED,
} from '../../../../../../../../../constants/portfolioStatuses';
import { useClientProfile } from '../../../../../../../../../prodivers/clientProfile';
import { genarateDFSClientId } from '../../../../../../../../../utils';

export const PortfolioDetailHeader = (props) => {
    const navigate = useNavigate();
    const { clientId } = useClientProfile();
    const dfsClientId = genarateDFSClientId(clientId);

    const {portfolios, sectionedPortfolios} = useClientProfile();
    const allPortfolios = useMemo(() => [...(sectionedPortfolios?.jointRegularPortfolios ?? []), ...(sectionedPortfolios?.poaPortfolios ?? []), ...(sectionedPortfolios?.parentalPortfolios ?? [])], [sectionedPortfolios]);

    const portfoliosSelectOptions = useMemo(() => [...(portfolios ?? []), ...allPortfolios]?.reduce((accu, item) => {
        if (item.Status?.value !== NOT_IMPLEMENTED && item.Status?.value !== BLOCKED && !accu.some(s => s.id === item.Id)) {
            accu.push({
                id: item?.Id,
                label: item?.Name?.label,
                value: item?.Id,
                product: item?.ProductDesc,
            });
        }

        return accu;
    }, []), [portfolios]);

    const selected = useMemo(() => {
        if (portfoliosSelectOptions?.length) {
            return portfoliosSelectOptions.find((item) => (item.id === parseInt(props?.portfolioId, 10)));
        }

        return undefined;
    }, [portfoliosSelectOptions, props?.portfolioId]);

    const onChange = useCallback((item) => {
        const parsedPathname = location.pathname.split('/');
        const tabName = parsedPathname[parsedPathname.length - 1];

        navigate(`/client/${dfsClientId}/portfolios/${item}/tab/${tabName}`);
    }, []);

    return (
        <div className="PortfolioDetailHeader">
            <div className="PortfolioDetailHeader__Title">
                {
                    !!selected && <h2>{selected?.product}</h2>
                }
                {
                    !!portfoliosSelectOptions?.length && <Select value={selected} options={portfoliosSelectOptions} onChange={onChange} />
                }
            </div>
        </div>
    );
};
