import PropTypes from 'prop-types';
import React from 'react';
import { getClassNames } from 'utils';
import './PageVariantWrapper.css';

function PageVariantWrapper({
    className,
    children,
}) {
    return (
        <div className={getClassNames('PageVariant main', className)}>
            {children}
        </div>
    );
}

PageVariantWrapper.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
};

PageVariantWrapper.defaultProps = {
    className: undefined,
};

export default PageVariantWrapper;
