/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable react/no-array-index-key */
import React, {
    FC, useCallback, useMemo, useState,
} from 'react';
import {
    Button, Checkbox, Icon, Loader, Modal, notification, Title,
} from 'ui-library';
import {
    ICON_ALERT_CIRCLE_SOLID,
    ICON_CHECK_CIRCLE_SOLID,
    ICON_DOCUMENT_LOADING,
    ICON_DOCUMENT_OUTLINE,
    ICON_DOCUMENT_RE_UPLOAD,
    ICON_DOWNLOAD_OUTLINE,
    ICON_PEN_LEFT,
    ICON_TRIANGLE_OUTLINE,
    ICON_TRY_AGAIN,
    ICON_TYPE_CLOSE,
} from 'ui-library/components/Icon';
import UploadDocuments from 'components/UploadDocuments/UploadDocuments';
import Error from 'components/AtomicStructure/atoms/Error';
import { BUTTON_TYPE_DANGER, BUTTON_TYPE_SECONDARY } from 'ui-library/components/Button/constants';
import {
    FILE_TYPES,
    FILE_TYPES_ALLOWED,
} from 'pages/ClientOnboardingFlow/pages/NewsPlan/UploadDocumentsPage/validation/const';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { DocTypes } from 'datasource/useApplicationDetails/ApplicationDetailsManager.types';
import { DocBoxProps, DocStatus } from './DocBox.types';
import './DocBox.css';
import SM from '../../../services/ServiceManager';
import { downloadDmsDocument } from '../../../utils/downloadDmsDocument';
import { useApplicationDetails } from '../../../datasource/useApplicationDetails';

const DocBox: FC<DocBoxProps> = ({
    uploadStatus = DocStatus.ReadyToGenerate,
    documentName,
    className,
    documentId = 0,
    contactId,
    originOfAsset,
    applicationId,
    type,
    isSent,
    isSentCase = false,
}) => {
    const {
        t,
        i18n: { language },
    } = useTranslation();
    const docName = documentName || t('ApplicationDetails.AdvisorySection.GenerateDocument');
    const [showUploadModal, setShowUploadModal] = useState(false);
    const [isSentCheckValue, setSentCheckValue] = useState(isSent);

    const [files, setFiles] = useState([]);
    const [error, setError] = useState<string>('');

    const kycProofOfAssetsDocNames = useMemo(() => ({
        798: t('ApplicationDetails.AdvisorySection.POA.PensionFunds'),
        799: t('ApplicationDetails.AdvisorySection.POA.LifeInsurance'),
        800: t('ApplicationDetails.AdvisorySection.POA.Inheritance'),
        801: t('ApplicationDetails.AdvisorySection.POA.Donation'),
        802: t('ApplicationDetails.AdvisorySection.POA.Savings'),
        803: t('ApplicationDetails.AdvisorySection.POA.RealEstateIncome'),
        804: t('ApplicationDetails.AdvisorySection.POA.RealEstateSale'),
        805: t('ApplicationDetails.AdvisorySection.POA.OtherOrigin'),
    }), [language]);

    const {
        onManualSign,
        onESign,
        onDocGeneration,
        setRetryState,
        acceptDocumentSend,
    } = useApplicationDetails();

    const [isDownloadInProgress, setDownloadInProgress] = useState<boolean>(false);
    const [isUploadInProgress, setUploadInProgress] = useState<boolean>(false);
    const [isESignInInProgress, setESignInProgress] = useState<boolean>(false);

    const classNames = classnames(className, 'doc-box', {
        'doc-box-error': uploadStatus === DocStatus.Error,
        'doc-box-initial': [DocStatus.ReadyToGenerate, DocStatus.Disabled].includes(uploadStatus),
    });

    const handleGenerateDocument = useCallback(async () => {
        try {
            if (applicationId) await onDocGeneration({ applicationId });
        } catch (err: any) {
            if (applicationId) setRetryState({ applicationId });
        }
    }, [applicationId]);

    const handleDocumentUpload = useCallback(async () => {
        if (!files.length) {
            setError(t('contactGroupDocuments.uploadDocumentsModal.noFilesSelectedError'));

            return;
        }

        setUploadInProgress(true);
        await onManualSign({
            file: files?.[0],
            contactId,
            originOfAsset,
            documentId,
            type,
        });
        setUploadInProgress(false);
        setShowUploadModal(false);
    }, [documentId, files, originOfAsset, contactId, type, onManualSign]);

    const uploadStatusIconType = useMemo(() => {
        switch (uploadStatus) {
            case 'ready-to-submit':
                return ICON_CHECK_CIRCLE_SOLID;
            case DocStatus.ReadyToUpload:
            case 'ready-to-sign':
                return ICON_ALERT_CIRCLE_SOLID;
            case 'error':
                return ICON_TRIANGLE_OUTLINE;
            case 'loading':
                return ICON_DOCUMENT_LOADING;

            default:
                return '';
        }
    }, [uploadStatus]);

    const allowedFileTypes = useMemo(() => {
        switch (type) {
            case 'PROOF_OF_ASSETS':
                return FILE_TYPES_ALLOWED;

            default:
                return FILE_TYPES.pdf;
        }
    }, [type]);

    const handleDocumentSign = useCallback(async () => {
        setESignInProgress(true);
        await onESign({ documentId });
        setESignInProgress(false);
    }, [documentId]);

    const handleDownload = useCallback(async () => {
        try {
            setDownloadInProgress(true);
            const response = await SM.documents('getDocumentById', [documentId]);

            if (response?.data) {
                const { retrieveFileAsName } = response.data;

                const { newBlob } = downloadDmsDocument(
                    response.data,
                    {
                        name: retrieveFileAsName,
                        saveFile: true,
                    },
                );
                const url = URL.createObjectURL(newBlob);

                global.open(url, '_blank');
            }
        } catch (err: any) {
            setError(err);
            notification.open({
                content: `${t('contactGroups.somethingWentWrong')} ${err?.message}`,
                type: 'error',
            });
        } finally {
            setDownloadInProgress(false);
        }
    }, [documentId, language]);

    const handleReUpload = useCallback(() => {
        setShowUploadModal(true);
    }, []);

    const onCancelDocumentSend = useCallback(() => {
        setSentCheckValue(false);
    }, []);

    const onAcceptDocumentSend = useCallback(async () => {
        await acceptDocumentSend({ documentId });
        setSentCheckValue(true);
    }, [documentId]);

    const tryToAcceptDocSend = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();

        if (isSent) return;

        Modal.confirm({
            title: t('documentSign.modal.documentIsNotSent.title'),
            content: t('documentSign.modal.documentIsNotSent.description'),
            okText: t('documentSign.modal.documentIsNotSent.ok'),
            cancelText: t('documentSign.modal.documentIsNotSent.cancel'),
            onCancel: onCancelDocumentSend,
            onOk: onAcceptDocumentSend,
        });
    }, [onCancelDocumentSend, onAcceptDocumentSend, isSent, isSentCheckValue]);

    return (
        <>
            <div className="doc-wrapper">
                <div className={classNames}>
                    <div className="doc-box-inner">
                        {uploadStatus !== 'ready-to-generate'
                            && (
                                <Icon
                                    className={uploadStatus === 'loading' ? 'loading-spinner' : ''}
                                    type={uploadStatusIconType}
                                    size={28}
                                />
                            )}
                        {!['loading', 'error']?.includes(uploadStatus)
                            && <Icon type={ICON_DOCUMENT_OUTLINE} size={28}/>}
                        {uploadStatus !== 'ready-to-generate' && (uploadStatus === 'error' ? t('ApplicationDetails.AdvisorySection.GenerateDocument.failed') : docName)}
                    </div>

                    <div className="doc-box-inner doc-box-actions">
                        {uploadStatus === 'ready-to-sign' && (
                            <span tabIndex={0} aria-hidden="true" role="button" onClick={handleDocumentSign}>
                                {' '}
                                {isESignInInProgress ? <Loader/> : <Icon type={ICON_PEN_LEFT} size={28}/>}
                            </span>
                        )}
                        {
                            !['loading', 'error', 'ready-to-generate', DocStatus.ReadyToUpload, DocStatus.Disabled]?.includes(uploadStatus)
                            && (isDownloadInProgress ? <Loader/>
                                : (
                                    <span tabIndex={-1} aria-hidden="true" role="button" onClick={handleDownload}>
                                        <Icon type={ICON_DOWNLOAD_OUTLINE} size={28}/>
                                    </span>
                                )
                            )
                        }
                        {[DocStatus.ReadyToUpload, DocStatus.ReadyToSign]?.includes(uploadStatus) && (typeof isSent !== 'boolean')
                            && (
                                <span tabIndex={-2} aria-hidden="true" role="button" onClick={handleReUpload}>
                                    <Icon
                                        type={ICON_DOCUMENT_RE_UPLOAD}
                                        size={28}
                                    />
                                </span>
                            )}
                        {uploadStatus === 'error' && (
                            <Button type={BUTTON_TYPE_DANGER} onClick={handleGenerateDocument}>
                                <Icon type={ICON_TRY_AGAIN} size={24}/>
                                {t('ApplicationDetails.AdvisorySection.GenerateDocument.TryAgain')}
                            </Button>
                        )}
                        {uploadStatus === 'ready-to-generate' && (
                            <Button type={BUTTON_TYPE_SECONDARY} onClick={handleGenerateDocument}>
                                {t('ApplicationDetails.AdvisorySection.GenerateDocument.Generate')}
                            </Button>
                        )}
                    </div>
                </div>
                {isSentCase && (
                    <div
                        className="single-application--confirm-document-submission-checkbox"
                        onClick={tryToAcceptDocSend}
                    >
                        {typeof isSent === 'boolean' && (
                            <Checkbox checked={isSentCheckValue} label={t('checkbox.TranfserFormSent')}/>
                        )}
                    </div>
                )}

            </div>
            {showUploadModal && (
                <Modal
                    title=""
                    visible
                    okText={t('ApplicationDetails.AdvisorySection.UploadDocument')}
                    cancelText={t('documentSign.modal.documentIsNotSent.cancel')}
                    onOk={handleDocumentUpload}
                    okButtonProps={{
                        disabled: isUploadInProgress,
                        loading: isUploadInProgress,
                    }}
                    cancelButtonProps={{ disabled: isUploadInProgress }}
                    onCancel={() => setShowUploadModal(false)}
                    width={610}
                    className="upload-document-modal"
                    borderRadius={24}
                    closable
                    closeIcon={<Icon type={ICON_TYPE_CLOSE} size={16}/>}
                >
                    <Title type={2} className="font-normal mb-32">
                        {t('ApplicationDetails.AdvisorySection.UploadDocument')}
                        {' '}
                        {docName}
                    </Title>
                    {type === DocTypes.PROOF_OF_ASSETS ? (
                        <>
                            <Title type={3}>
                                {t('ApplicationDetails.AdvisorySection.RequiredDocuments')}
                            </Title>
                            <ul className="mt-12">
                                <li>{originOfAsset ? kycProofOfAssetsDocNames?.[originOfAsset] : ''}</li>
                            </ul>
                        </>
                    ) : ''}
                    <UploadDocuments
                        onSuccess={setFiles}
                        onError={setError}
                        numberOfFiles={1}
                        maxMbFileSize={5}
                        fileTypesAllowed={allowedFileTypes}
                    />

                    {error && <Error>{error}</Error>}
                </Modal>
            )}
        </>
    );
};

export default DocBox;
