import {useQuery} from '@tanstack/react-query';
import ServerError from '../../errors/ServerError';
import SM from '../../services/ServiceManager';

export const useGetContact = ({
    contactId,
}) => {
    const result = useQuery({
        queryKey: ['getContactDetails', contactId],
        enabled: !!contactId,
        retry: false,
        gcTime: 10,
        queryFn: async () => {
            const {data} = await SM.clientService('getContact', [contactId]);

            return data;
        },
    });

    return {
        ...result,
        error: result.error ? new ServerError(result.error) : result.error,
    };
};
