import React, {
    cloneElement, useCallback, useEffect, useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';
import uid from '../../utils/uid';
import { getClassNames } from '../../utils';
import TabBar from './TabBar';
import withTheme from '../../hocs/withTheme';

import './Tabs.css';

const Tabs = (props) => {
    const {
        children,
        defaultActiveKey,
        activeKey: activeKeyProp,
        className: classNameProp,
        tabBarExtraContent,
        onChange,
        onTabClick,
    } = props;
    const className = getClassNames('Tabs', classNameProp);
    const [activeKey, setActiveKey] = useState(activeKeyProp || defaultActiveKey);

    useEffect(() => {
        setActiveKey(activeKeyProp);
    }, [activeKeyProp]);

    const onClickItem = (key) => () => {
        if (!activeKeyProp) {
            setActiveKey(key);
        }
        onChange(key);
        onTabClick(key);
    };
    const getTabProps = useCallback((child) => {
        if (!child || typeof child.type === 'string') return {};

        const { tab, disabled } = child.props;
        const tabKey = child.key || uid('tab');

        return {
            tab,
            tabKey,
            disabled,
            isActive: tabKey === activeKey,
            onClick: disabled ? null : onClickItem(tabKey),
        };
    }, [activeKey, onClickItem]);
    const tabsTitles = useMemo(() => (children || []).reduce((acc, item) => {
        if (item) return [...acc, getTabProps(item)];

        return acc;
    }, []), [children, getTabProps]);

    useEffect(() => {
        const defActiveKey = tabsTitles.some(({ tabKey }) => tabKey === defaultActiveKey)
            && defaultActiveKey;

        setActiveKey(activeKeyProp || defActiveKey || (tabsTitles[0] && tabsTitles[0].tabKey));
    }, []);

    const getNewChild = useCallback((child) => {
        if (!child) return null;
        if (typeof child.type === 'string') {
            return child;
        }

        const props = getTabProps(child);

        return cloneElement(child, props);
    }, [getTabProps]);
    const childrenWithProps = React.Children.map(children, getNewChild);

    return (
        <div className={className}>
            <TabBar
                tabs={tabsTitles}
                activeKey={activeKey}
                tabBarExtraContent={tabBarExtraContent}
            />
            <div className="Tabs-content">
                {childrenWithProps}
            </div>
        </div>
    );
};

Tabs.propTypes = {
    activeKey: PropTypes.string,
    defaultActiveKey: PropTypes.string,
    tabBarExtraContent: PropTypes.node,
    children: PropTypes.node,
    className: PropTypes.string,
    onChange: PropTypes.func,
    onTabClick: PropTypes.func,
    /** @ignore */
    animated: PropTypes.shape({
        inkBar: PropTypes.bool,
        tabPane: PropTypes.bool,
    }),
};

Tabs.defaultProps = {
    activeKey: null,
    defaultActiveKey: null,
    tabBarExtraContent: null,
    children: null,
    className: null,
    onChange: () => { },
    onTabClick: () => { },
    animated: { inkBar: true, tabPane: false },
};

export default withTheme(Tabs);
