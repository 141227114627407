import React from 'react';
import PropTypes from 'prop-types';
import AvatarComp from '../Avatar';
import Notifications from './Notifications';
import Dropdown from '../Dropdown';
import Button from '../Button';
import Icon, {ICON_TYPE_BELL_FILLED} from '../Icon';
import './ProfileMenu.css';
import withTheme from '../../hocs/withTheme';
import {createUniqueKey} from '../../utils';

/** The label is always used on text inputs (textareas) and don’t depend on the placeholder text. */

function ProfileMenu({
                         image,
                         name,
                         email,
                         editText,
                         onEdit,
                         logoutText,
                         onLogout,
                         withEdit,
                         withAlert,
                         notifications,
                         additionalInfo,
                         footer,
                         withAdditionalButtons,
                         ...rest
                     }) {
    const isNotificationsEmpty = !!notifications.errorMessage
        || (Array.isArray(notifications.data) && notifications.data.length === 0);


    const Avatar = image
        ? <AvatarComp src={image} className="avatar" role="button" tabIndex={0}/>
        : <AvatarComp className="avatar" role="button" tabIndex={0} name={name}/>;

    return (
        <div className="profile-menu">
            {withAlert && (
                <Dropdown
                    placement="bottomRight"
                    overlayClassName="notifications-menu__Popover"
                    title={(
                        <Icon
                            className={`notifications-menubutton ${isNotificationsEmpty ? 'notifications-menubutton--empty' : ''}`}
                            type={ICON_TYPE_BELL_FILLED}
                            role="button"
                            tabIndex={0}
                        />
                    )}
                >
                    <div className="profile-menu__notification">
                        <Notifications {...notifications} />
                    </div>
                </Dropdown>
            )}
            <Dropdown
                placement="bottomRight"
                title={Avatar}
                {...rest}
                overlayClassName="profile-menu__Popover"
            >
                <li className="ProfileMenuWrapper" aria-label={"Options"}>
                    <ul className={"ProfileMenu"}>
                        <li className={"ProfileMenu__Item"}>
                            <div className={"ProfileMenu__TextWrapper"}>
                                <span className={"ProfileMenu__Text"}>{name}</span>
                                {/*
                                {Avatar}
                                {email}
                                {additionalInfo}
                                */}
                            </div>
                        </li>

                        {withEdit && (
                            <li className={"ProfileMenu__Item"}>
                                <button className={"ProfileMenu__Button"} type={"button"} onClick={onEdit}>
                                    {editText}
                                </button>
                            </li>
                        )}

                        {withAdditionalButtons?.length > 0 && <li role={"separator"}/>}

                        {withAdditionalButtons?.length > 0
                            && withAdditionalButtons.map((button, index) => (
                                <li className="ProfileMenu__Item" key={createUniqueKey(index, button?.text)}>
                                    <button className={"ProfileMenu__Button"} type={"button"} id={button?.id} onClick={button?.onClick}>
                                        {button?.text}
                                    </button>
                                </li>
                            ))}

                        <li role={"separator"}/>

                        <li className={"ProfileMenu__Item"}>
                            <button className={"ProfileMenu__Button"} type="button" onClick={onLogout}>
                                {logoutText}
                            </button>
                        </li>
                    </ul>
                </li>
            </Dropdown>
        </div>
    );
}

ProfileMenu.propTypes = {
    /** The avatar image */
    image: PropTypes.string,
    /** The person's name */
    name: PropTypes.string,
    /** The person's email */
    email: PropTypes.string,
    /** Additional text */
    additionalInfo: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    /** Content on bottom  */
    footer: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    /** Button text (default set to 'Edit profile') */
    editText: PropTypes.string,
    /** Link text (default set to 'Logout') */
    logoutText: PropTypes.string,
    /** Whether edit will be shown */
    withEdit: PropTypes.bool,
    /** Whether alert will be shown */
    withAlert: PropTypes.bool,
    /** The callback function that is triggered when the edit button is clicked */
    onEdit: PropTypes.func,
    /** The callback function that is triggered when the logout button is clicked */
    onLogout: PropTypes.func,
    notifications: PropTypes.shape({
        data: PropTypes.arrayOf(PropTypes.shape({
            subject: PropTypes.string,
            date: PropTypes.string,
            text: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.object,
            ]),
        })),
        onReadNotification: PropTypes.func,
        errorMessage: PropTypes.string,
        emptyMessage: PropTypes.string,
    }),
    /** array of additional buttons */
    withAdditionalButtons: PropTypes.arrayOf(PropTypes.shape({
        onClick: PropTypes.func,
        text: PropTypes.string,
    })),
};

ProfileMenu.defaultProps = {
    image: '',
    name: undefined,
    email: undefined,
    additionalInfo: null,
    footer: null,
    editText: 'Edit profile',
    logoutText: 'Logout',
    withEdit: false,
    withAlert: false,
    onEdit: () => {
    },
    onLogout: () => {
    },
    notifications: {
        data: [],
        onReadNotificationL: () => {
        },
        errorMessage: null,
        emptyMessage: null,
    },
    withAdditionalButtons: [],
};
export default withTheme(ProfileMenu);
