import React, {
    createContext, useCallback, useMemo, useState,
} from 'react';
import LoadingOverlay from 'react-loading-overlay-nextgen';
import PropTypes from 'prop-types';
import { useHeader } from './header/useHeader';
import {Loader} from '../../ui-library';

LoadingOverlay.propTypes = undefined;

const LayoutContext = createContext({});
const LayoutProvider = (props) => {
    const { children } = props;
    const [isLoadingOverlayActive, setLoadingOverlayActive] = useState(false);
    const { headerState, initHeaderState, changeHeaderState } = useHeader();

    const value = useMemo(() => ({
        headerState,
        initHeaderState,
        changeHeaderState,
        setLoadingOverlayActive,
    }), [headerState, initHeaderState, changeHeaderState]);

    return (
        <LayoutContext.Provider value={value}>
            <LoadingOverlay
                active={isLoadingOverlayActive}
                spinner={<Loader large />}
            >
                {children}
            </LoadingOverlay>
        </LayoutContext.Provider>
    );
};

LayoutProvider.propTypes = {
    children: PropTypes.node,
};

LayoutProvider.defaultProps = {
    children: null,
};

export {
    LayoutContext,
    LayoutProvider,
};
