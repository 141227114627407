import PropTypes from 'prop-types';
import React from 'react';
import {getClassNames} from 'utils';
import './OverviewPanel.css';

function OverviewPanel({
    className,
    children
}) {
    return (
        <dl className={getClassNames('overview-panel', className)}>
            {children}
        </dl>
    );
}

OverviewPanel.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
};

OverviewPanel.defaultProps = {
    className: null,
    children: null,
};

export default OverviewPanel;
