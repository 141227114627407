import React from 'react';
import PropTypes from 'prop-types';
import { usePortfolioDetails } from '../hooks/usePortfolioDetails';
import { useModelPortfolio } from '../hooks/useModelPortfolio';
import PortfolioContext from './context';
import {useGeneralAgency} from '../hooks/useGeneralAgency';
import {useLastApplication} from '../hooks/useLastApplication';
import {useApplication} from '../hooks/useApplication';
import {useProposal} from '../hooks/useProposal';

const PortfolioOverviewProvider = (options) => {
    const {
        options: {
            id,
            clientId,
            portfolio: portfolioOptions,
            modelPortfolio: modelPortfolioOptions,
            lastApplication: lastApplicationOptions,
            application: applicationOptions,
            proposal: proposalOptions,
        },
        children,
    } = options;
    const modelPortfolio = useModelPortfolio(modelPortfolioOptions);
    const portfolio = usePortfolioDetails(
        { ...portfolioOptions, clientId, portfolioId: id }, modelPortfolio,
    );
    const lastApplication = useLastApplication(lastApplicationOptions);
    const application = useApplication(applicationOptions);
    const proposal = useProposal(proposalOptions);
    const generalAgency = useGeneralAgency({portfolioId: id, loadInitially: true});
    const state = {
        id,
        clientId,
        portfolio,
        modelPortfolio,
        lastApplication,
        application,
        proposal,
        generalAgency,
    };
    const getValue = (callback) => callback(state);

    return (
        <PortfolioContext.Provider
            value={{ ...state, getValue }}
        >
            {children}
        </PortfolioContext.Provider>
    );
};

PortfolioOverviewProvider.propTypes = {
    options: PropTypes.shape({
        id: PropTypes.number,
    }).isRequired,
};

PortfolioOverviewProvider.defaultProps = {};

export default PortfolioOverviewProvider;
