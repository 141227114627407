import React from 'react';
import { Icon, Title } from 'ui-library';
import { ICON_EDIT_OUTLINE } from 'ui-library/components/Icon';
import { useTranslation } from 'react-i18next';
import { adaptCountry, adaptGender } from 'widgets/PersonalDetails/utils/adapters';

type EditablePersonalDataObject = {
    firstName: string
    lastName: string
    email: string
    phoneNumber: string
    dateOfBirth: string
    gender: string
    countryOfNationality: string
    countryOfBirth: string
    avhNumber: number
    primaryEmail: string
    secondaryEmail: string
    telephoneNumber: string
    primaryPhoneNumber: string
    occupation: string
    taxDomicile: string
    riskDomicile: string
}

type EditablePersonalDataProps = {
    data: EditablePersonalDataObject,
    editPersonalData: () => void,
    errorData,
    optionCountry,
    optionGender,
}

export function NotEditablePersonalData({
    data,
    editPersonalData,
    errorData,
    optionCountry,
    optionGender,
}: EditablePersonalDataProps) {
    const { t } = useTranslation();

    return (
        <>
            <div className="LinkedHeader">
                <Title type={2} data-cy="full_name">
                    {data?.firstName}
                    {' '}
                    {data?.lastName}
                </Title>

                <button
                    className="button-link"
                    type="button"
                    data-cy="edit_personal_details"
                    onClick={() => {
                        editPersonalData();
                    }}
                >
                    <Icon size={24} type={ICON_EDIT_OUTLINE}/>
                    {t('interaction.edit')}
                </button>
            </div>

            <div className="personalDetailsTable data-summary-wrapper">

                <dl className="DataList">
                    <div className={errorData?.dateOfBirth ? 'DataList__Item--error' : undefined}>
                        <dt>
                            {t('onboardingFlow.personalPage.dateOfBirth')}
                        </dt>
                        <dd data-cy="dob">
                            {data?.dateOfBirth}
                        </dd>
                    </div>
                    <div className={errorData?.gender ? 'DataList__Item--error' : undefined}>
                        <dt>
                            {t('onboardingFlow.personalPage.gender')}
                        </dt>
                        <dd data-cy="gender">
                            {optionGender && adaptGender(optionGender, data?.gender)?.label}
                        </dd>
                    </div>
                    <div className={errorData?.countryOfNationality ? 'DataList__Item--error' : undefined}>
                        <dt>
                            {t('onboardingFlow.personalPage.countryOfNationality')}
                        </dt>
                        <dd data-cy="country_of_nationality">
                            {optionCountry && adaptCountry(optionCountry, data?.countryOfNationality)?.label}
                        </dd>
                    </div>
                    <div className={errorData?.countryOfBirth ? 'DataList__Item--error' : undefined}>
                        <dt>
                            {t('onboardingFlow.personalPage.countryOfBirth')}
                        </dt>
                        <dd data-cy="country_of_nationality">
                            {optionCountry && adaptCountry(optionCountry, data?.countryOfBirth)?.label}
                        </dd>
                    </div>
                    <div className={errorData?.avhNumber ? 'DataList__Item--error' : undefined}>
                        <dt>
                            {t('onboardingFlow.personalPage.ahvnumber')}
                        </dt>
                        <dd data-cy="avh_number">
                            {data?.avhNumber}
                        </dd>
                    </div>
                    <div
                        className={`DataList__Item--hspan ${errorData?.primaryEmail ? 'DataList__Item--error' : null}`}
                    >
                        <dt>
                            {t('onboardingFlow.personalPage.email')}
                        </dt>
                        <dd data-cy="primary_email">
                            {data?.primaryEmail}
                        </dd>
                    </div>
                    <div className={errorData?.secondaryPhoneNumber ? 'DataList__Item--error' : undefined}>
                        <dt>
                            {t('dynamicCRM.communicationMethods.secondaryPhoneNumber')}
                        </dt>
                        <dd data-cy="telephone">
                            {data?.telephoneNumber}
                        </dd>
                    </div>
                    <div className={errorData?.primaryPhoneNumber ? 'DataList__Item--error' : undefined}>
                        <dt>
                            {t('dynamicCRM.communicationMethods.primaryPhoneNumber')}
                        </dt>
                        <dd data-cy="phone">
                            {data?.primaryPhoneNumber}
                        </dd>
                    </div>
                </dl>
            </div>
        </>
    );
}
