import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {withMainLayout} from 'hocs';
import Preloader from 'components/Preloader';
import ServiceManager from 'services/ServiceManager';
import {
    Modal, Button, Title,
} from 'ui-library';
import {useParams} from 'react-router-dom';
import {CustomForm} from './components/CustomForm';
import {SwitchForm} from './components/SwitchForm';
import {IndividualForm} from './components/IndividualForm';
import {getModel} from './models/IndividualForm/getModel';

import './DynamicPage.css';

const DynamicPage = () => {
    const {i18n, t} = useTranslation();
    const { dfsClientId, portfolioId } = useParams();

    const tempStartData = {
        Fields: [],
    };

    const [templateData, setTemplate] = useState(tempStartData);
    const [caseLists, setCaseLists] = useState([]);
    const [caseCurrentID, setcaseCurrentID] = useState();
    const [isLoading, setLoading] = useState(true);
    const [TitlePage, setTitlePage] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [selectedOption, setSelectedOption] = useState([]);
    const [modalMsg, setModalMsg] = useState('');
    const [individualList, setIndividualList] = useState([]); // 1 level
    const [brokerList, setBrokerList] = useState([]);
    const [loadedCaseManagementData, setLoadedCaseManagementData] = useState({});
    const [brokerId, setBrokeId] = useState('');
    const [orderType, setOrderType] = useState('');
    const [individualCategoryList, setIndividualCategoryList] = useState([]); // 2 level
    const [individualDocumentCategoryList, setIndividualDocumentCategoryList] = useState([]); // 3 level
    const [renderIndividualForm, setRenderIndividualForm] = useState([]);

    useEffect(() => {
        (async () => {
            try {
                const {data: response} = await ServiceManager.customInvestmentService('getCaseManagementDetails', [{portfolioId}]);
                const responseData = getModel(response);

                setLoadedCaseManagementData(responseData);
            } catch (err) {
                // TODO: make better error handling, maybe introduce toast messages or something similar
                console.log(err);
            }
        })();
    }, [portfolioId]);

    const handleAdd = (data) => {
        const Fields = data;

        setTemplate({...templateData, Fields});
    };

    const updatePage = (dataForm) => {
        const entries = Object.entries(dataForm);

        entries.forEach(([key, value]) => {
            const objIndex = templateData.Fields.findIndex((obj => obj.propertyName === key));
            const newObject = {...templateData};

            newObject.Fields[objIndex].value = value;
            setTemplate(newObject);
        });

        setModalMsg('');

        ServiceManager.caseManagementService('sendDataCase', [{
            data: templateData.Fields, caseId: caseCurrentID, language: i18n.language,
        }]).then(response => {
            if (typeof response.data === 'number') {
                setModalMsg(`${t('caseManagment.saveSuccess')} ${response.data}`);
            } else {
                setModalMsg(response.data);
            }
        }).catch(error => {
            setModalMsg(t('caseManagment.errorOccured'));
        });
        setShowModal(true);
    };

    const setTitle = (data) => {
        setTitlePage(data);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    const handleChange = async (value) => {
        setIndividualList([]); // 1 level
        setIndividualCategoryList([]); // 2 level
        setIndividualDocumentCategoryList([]); // 3 level
        setBrokerList([]); // Broker List

        setRenderIndividualForm(false);
        setcaseCurrentID(value);
        setTitle(caseLists.find(x => x.id === value)?.label);

        if (value === 'individual') {
            const individualOrderTypesResponse = await ServiceManager.caseManagementService('getIndividualOrderTypes');

            setIndividualList(individualOrderTypesResponse?.data);

            const brokerResponse = await ServiceManager.caseManagementService('getBrokers');

            setBrokerList(brokerResponse?.data);
        } else {
            const response = await ServiceManager.caseManagementService('getDataCase', [value, i18n.language]);

            await handleAdd(response?.data);
        }
    };

    const individualListChange = async (OrderType) => {
        // 1 level
        setOrderType(OrderType);
        setIndividualCategoryList([]); // 2 level
        setIndividualDocumentCategoryList([]); // 3 level
        setRenderIndividualForm(false);
        if (brokerId) {
            const response = await ServiceManager.caseManagementService('getIndividualCategories', [OrderType, brokerId]);

            setIndividualCategoryList(response?.data);
        }
    };

    const brokerListChange = async (value) => {
        setBrokeId(value);

        if (orderType) {
            const response = await ServiceManager.caseManagementService('getIndividualCategories', [orderType, value]);

            setIndividualCategoryList(response?.data);
        }
    };

    const individualCategoryChange = async (categoryType) => {
        // 2 level
        setRenderIndividualForm(false);

        const response = await ServiceManager.caseManagementService('getIndividualDocumentCategories', [categoryType]);

        setIndividualDocumentCategoryList(response?.data);
        setRenderIndividualForm(true);
    };

    const individualDocumentCategoryChange = () => {
        // 3 level
        // setRenderIndividualForm(true);
    };

    useEffect(() => {
        console.log('render Case managment');
        setLoading(true);

        (async function () {
            const caseListResponse = await ServiceManager.caseManagementService('getCaseLists', [i18n.language]);

            const listsCases = caseListResponse?.data;

            setCaseLists(listsCases);
            for (const listsCase of listsCases) {
                if (listsCase.id === selectedOption) {
                    const response = await ServiceManager.caseManagementService('getDataCase', [listsCase.id, i18n.language]);
                    const contents = response?.data?.contents;

                    if (contents === '') {
                        setTitlePage('');
                    }
                    setcaseCurrentID(listsCase.id);
                    setTitle(listsCase.label);
                    await handleAdd(contents);
                }
            }
            setLoading(false);
        }());
    }, []);

    return (
        <div className="container dynamic-page">
            <Title>{t('dashboard.agentCockpit.createNewCase')}</Title>
            <div className="newStyle fi-verticals">
                <Preloader isLoading={isLoading} error={null}>
                    {modalMsg != null && modalMsg !== ''
                        ? (
                            <Modal
                                title={t('clients.columns.status')}
                                visible={showModal}
                                footer={(
                                    <div>
                                        <Button
                                            type="primary"
                                            onClick={closeModal}
                                        >
                                            {t('caseManagment.close')}
                                        </Button>
                                    </div>
                                )}
                            >
                                {modalMsg}
                            </Modal>
                        ) : null}

                    <div className="form">
                        <SwitchForm
                            listsData={caseLists || null}
                            currentList={caseCurrentID}
                            onChange={handleChange}
                        />

                        {individualList.length > 0
                            ? (
                                <IndividualForm
                                    dfsClientId={dfsClientId}
                                    portfolioId={portfolioId}
                                    individualList={individualList}
                                    onChange={individualListChange}
                                    individualCategoryList={individualCategoryList}
                                    onChangeBrokerList={brokerListChange}
                                    brokerList={brokerList}
                                    onChangeCategoryList={individualCategoryChange}
                                    individualDocumentCategoryList={individualDocumentCategoryList}
                                    onChangeDocumentCategoryList={individualDocumentCategoryChange}
                                    documentCategoryList={individualDocumentCategoryList}
                                    renderIndividualForm={renderIndividualForm}
                                    loadedData={loadedCaseManagementData}
                                />
                            )
                            : (
                                <CustomForm
                                    title={TitlePage}
                                    templateData={templateData}
                                    sendData={updatePage}
                                />
                            )}
                    </div>
                </Preloader>
            </div>
        </div>
    );
};

export default withMainLayout(DynamicPage);
