import { useQuery } from '@tanstack/react-query';
import ServerError from '../../errors/ServerError';
import SM from '../../services/ServiceManager';

export const useGetDocumentsByInvestmentApplicationId = ({
    investmentApplicationId, contactId, isEnabled = true,
}) => {
    const result = useQuery({
        queryKey: ['getDocumentsByInvestmentApplicationId', investmentApplicationId, contactId],
        enabled: !!contactId && !!investmentApplicationId && isEnabled,
        retry: false,
        gcTime: 30, // seconds
        queryFn: async () => {
            const { data } = await SM.customDocumentsService(
                'getDocumentsByInvestmentApplicationId',
                [{
                    investmentApplicationId, contactId,
                }],
            );

            return data;
        },
    });

    return {
        ...result,
        error: result.error ? new ServerError(result.error) : result.error,
    };
};
