
// export const maxLength = 15;
/**
 * limit decimal numbers to given scale
 * Not used .fixedTo because that will break with big numbers
 */
export function limitToPrecision(numStr, precision) {
    let str = '';

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i <= precision - 1; i++) {
        str += numStr[i] || '';
    }

    return str;
}

export function splitDecimal(numStr) {
    const hasNagation = numStr[0] === '-';

    // eslint-disable-next-line no-param-reassign
    numStr = numStr.replace('-', '');

    const parts = numStr.split('.');
    const beforeDecimal = parts[0];
    const afterDecimal = parts[1] || '';

    return {
        beforeDecimal,
        afterDecimal,
        hasNagation,
        addNegation: hasNagation,
    };
}

export function getThousandsGroupRegex(thousandsGroupStyle) {
    switch (thousandsGroupStyle) {
        case 'lakh':
            return /(\d+?)(?=(\d\d)+(\d)(?!\d))(\.\d+)?/g;
        case 'wan':
            return /(\d)(?=(\d{4})+(?!\d))/g;
        case 'thousand':
        default:
            return /(\d)(?=(\d{3})+(?!\d))/g;
    }
}

export function applyThousandSeparator(str, thousandSeparator, thousandsGroupStyle) {
    const thousandsGroupRegex = getThousandsGroupRegex(thousandsGroupStyle);
    let index = str.search(/[1-9]/);

    index = index === -1 ? str.length : index;

    return str.substring(0, index) + str.substring(index, str.length).replace(thousandsGroupRegex, `$1${thousandSeparator}`);
}


export function roundToPrecision(numStr, precision = 2) {
    if (['', '-'].indexOf(numStr) !== -1) return numStr;

    const shoudHaveDecimalSeparator = numStr.indexOf('.') !== -1 && precision;
    const { beforeDecimal, afterDecimal, hasNagation } = splitDecimal(numStr);
    const roundedDecimalParts = parseFloat(`0.${afterDecimal || '0'}`).toFixed(precision).split('.');
    const intPart = beforeDecimal.split('').reverse().reduce((roundedStr, current, idx) => {
        if (roundedStr.length > idx) {
            return (Number(roundedStr[0]) + Number(current))
                .toString() + roundedStr.substring(1, roundedStr.length);
        }

        return current + roundedStr;
    }, roundedDecimalParts[0]);

    const decimalPart = limitToPrecision(roundedDecimalParts[1] || '', Math.min(precision, afterDecimal.length));
    const negation = hasNagation ? '-' : '';
    const decimalSeparator = shoudHaveDecimalSeparator ? '.' : '';

    return `${negation}${intPart}${decimalSeparator}${decimalPart}`;
}

export function findChangedIndex(prevValue, newValue) {
    let i = 0;
    let j = 0;

    const prevLength = prevValue.length;
    const newLength = newValue.length;

    // eslint-disable-next-line no-plusplus
    while (prevValue[i] === newValue[i] && i < prevLength) i++;

    while (
        prevValue[prevLength - 1 - j] === newValue[newLength - 1 - j]
        && newLength - j > i
        && prevLength - j > i
    ) {
        // eslint-disable-next-line no-plusplus
        j++;
    }

    return { start: i, end: prevLength - j };
}

export function getCurrentCaretPosition(el) {
    return Math.max(el.selectionStart, el.selectionEnd);
}

export function charIsNumber(char) {
    return !!(char || '').match(/\d/);
}


/*
  Returns a number whose value is limited to the given range
*/
export function clamp(num, min, max) {
    return Math.min(Math.max(num, min), max);
}
