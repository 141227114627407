/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {CheckboxGroup, RadioButtonGroup, Textarea, Title,} from 'ui-library';

import {useOptionList} from 'hooks/useOptionList';
import Input from 'components/AtomicStructure/atoms/Input';
import SelectElement from 'components/AtomicStructure/atoms/SelectElement';
import {KYC, OPTION_LIST} from 'constants/constants';
import Preloader from 'components/Preloader';
import {
    GWG_PROCESS_INDUSTRIES
} from '../../pages/ClientOverview/pages/Portfolios/pages/CreateNewPortfolio/pages/KYC/constants';
import {useCreatePortfolio} from '../../pages/ClientOverview/pages/Portfolios/pages/CreateNewPortfolio/context';

import {
    Status,
    StatusBox
} from '../../pages/ClientOverview/pages/Portfolios/pages/CreateNewPortfolio/pages/KYC/components/StatusBox/StatusBox';
import {validateCheckboxGroup, validateInputString, validateRadio, validateSelect,} from './validation/validation';
import './KYCForm.css';
import {getClassNames} from '../../utils';

type KYCFormProps = {
    data: any;
    personalDetails: any;
    setData: (data: KYCDataPayload) => void;
    errorData: any;
    setErrorData: (data: any) => void;
    className: string
}

const KYCForm = ({
    data,
    personalDetails,
    setData,
    errorData,
    setErrorData,
    className = '',
}: KYCFormProps) => {
    const {t} = useTranslation();
    const {checkIfNationalityUnderFatfGrayList} = useCreatePortfolio();

    const isTopTierPlanInvestment = useMemo(() => false, []);
    const isSwissNationality = useMemo(() => {
            if (personalDetails?.details?.personalInformation?.identification?.length > 0) {
                return personalDetails?.details?.personalInformation?.identification[0]?.countryOfNationality === KYC.swissNationality;
            }

            return false;
        },
        [personalDetails?.details?.personalInformation?.identification?.[0]?.countryOfNationality]);

    const {
        lists: [
            optionDegreeOfEducation,
            optionPepStatus,
            optionInSwitzerlandSince,
            optionPlannedLengthOfStay,
            optionProfessionalSituation,
            optionOriginOfAssets,
            optionIndustry2,
            optionBeneficialOwner,
        ],
        isLoading,
    } = useOptionList([
        OPTION_LIST.degreeOfEducation,
        OPTION_LIST.pepStatus,
        OPTION_LIST.inSwitzerlandSince,
        OPTION_LIST.plannedLengthOfStay,
        OPTION_LIST.professionalSituation,
        OPTION_LIST.originOfAssets,
        OPTION_LIST.industry2,
        OPTION_LIST.beneficialOwner,
    ]);

    const nationality = useMemo(() => personalDetails?.details?.personalInformation?.identification?.[0]?.countryOfNationality, [personalDetails?.details?.personalInformation?.identification?.[0]?.countryOfNationality]);

    const showWarningMessage = useMemo(() => (checkIfNationalityUnderFatfGrayList ? checkIfNationalityUnderFatfGrayList(nationality) || data.pepStatus || GWG_PROCESS_INDUSTRIES.includes(data.industry) : data.pepStatus || GWG_PROCESS_INDUSTRIES.includes(data.industry)), [data, nationality, checkIfNationalityUnderFatfGrayList]);
    const shouldProcessStop = useMemo(() => data?.beneficialOwner === KYC.thirdParty, [data?.beneficialOwner]);
    const classNames = getClassNames('kyc form', className);

    return (
        <div className={classNames}>
            <Preloader isLoading={isLoading}>
                {!isSwissNationality && (
                    <fieldset className="info-section">
                        <Title tagName="legend">
                            {t('3b.kyc.clientExpat')}
                        </Title>
                        <div className="info-group">
                            <div className="form">
                                <SelectElement
                                    data-cy="inSwissSince"
                                    placeholder={t('3b.kyc.pleaseSelect')}
                                    label={t('3b.kyc.insSwissSince')}
                                    value={data?.inSwitzerlandSince && String(data?.inSwitzerlandSince)}
                                    onChange={(value: string) => setData({
                                        ...data,
                                        inSwitzerlandSince: parseInt(value, 10)
                                    })}
                                    options={optionInSwitzerlandSince}
                                />
                            </div>
                            <div className="form">
                                <SelectElement
                                    data-cy="lengthOfStay"
                                    placeholder={t('3b.kyc.pleaseSelect')}
                                    label={t('3b.kyc.lengthOfStay')}
                                    value={data?.plannedLengthOfStay && String(data?.plannedLengthOfStay)}
                                    onChange={(value: string) => setData({
                                        ...data,
                                        plannedLengthOfStay: parseInt(value, 10)
                                    })}
                                    options={optionPlannedLengthOfStay}
                                />
                            </div>
                        </div>
                    </fieldset>
                )}
                {!isSwissNationality ? (
                    <fieldset className="info-section">
                        <Title tagName="legend">
                            {t('3b.kyc.professionalSituation')}
                        </Title>
                        <div className="info-group">
                            <div className="form">
                                <SelectElement
                                    data-cy="professionalSituation"
                                    placeholder={t('3b.kyc.pleaseSelect')}
                                    label={t('3b.kyc.professionalSituation')}
                                    value={data?.professionalSituation && String(data?.professionalSituation)}
                                    onChange={(value: string) => {
                                        setData({
                                            ...data,
                                            professionalSituation: parseInt(value, 10)
                                        });
                                        setErrorData({
                                            ...errorData,
                                            professionalSituation: validateSelect(value),
                                        });
                                    }}
                                    options={optionProfessionalSituation}
                                    error={errorData?.professionalSituation}
                                    required
                                />
                                <SelectElement
                                    data-cy="industry"
                                    placeholder={t('3b.kyc.pleaseSelect')}
                                    label={t('3b.kyc.industry')}
                                    value={data?.industry && String(data?.industry)}
                                    onChange={(value: string) => {
                                        setData({
                                            ...data,
                                            industry: parseInt(value, 10)
                                        });
                                        setErrorData({
                                            ...errorData,
                                            industry: validateSelect(value),
                                        });
                                    }}
                                    options={optionIndustry2}
                                    error={errorData?.industry}
                                    required
                                />
                            </div>
                            <div className="form">
                                <SelectElement
                                    data-cy="educationDegree"
                                    placeholder={t('3b.kyc.pleaseSelect')}
                                    label={t('3b.kyc.educationDegree')}
                                    value={data?.degreeOfEducation && String(data?.degreeOfEducation)}
                                    onChange={(value: string) => {
                                        setData({
                                            ...data,
                                            degreeOfEducation: parseInt(value, 10)
                                        });
                                        setErrorData({
                                            ...errorData,
                                            degreeOfEducation: validateSelect(value),
                                        });
                                    }}
                                    options={optionDegreeOfEducation}
                                    error={errorData?.degreeOfEducation}
                                    required
                                />
                                <Input
                                    data-cy="employer"
                                    label={t('3b.kyc.employer')}
                                    value={data?.employer ?? ''}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        setData({
                                            ...data,
                                            employer: e?.target?.value
                                        });
                                        setErrorData({
                                            ...errorData,
                                            employer: validateInputString(e?.target?.value),
                                        });
                                    }}
                                    error={errorData?.employer}
                                    required
                                />
                            </div>
                        </div>
                    </fieldset>
                ) : (
                    <fieldset className="info-section">
                        <Title tagName="legend">
                            {t('3b.kyc.professionalSituation')}
                        </Title>
                        <div className="info-group">
                            <div className="form">

                                <SelectElement
                                    data-cy="professionalSituation"
                                    placeholder={t('3b.kyc.pleaseSelect')}
                                    label={t('3b.kyc.professionalSituation')}
                                    value={data?.professionalSituation && String(data?.professionalSituation)}
                                    onChange={(value: string) => {
                                        setData({
                                            ...data,
                                            professionalSituation: parseInt(value, 10)
                                        });
                                        setErrorData({
                                            ...errorData,
                                            professionalSituation: validateSelect(value),
                                        });
                                    }}
                                    options={optionProfessionalSituation}
                                    error={errorData?.professionalSituation}
                                    required
                                />
                                <Input
                                    data-cy="employer"
                                    label={t('3b.kyc.employer')}
                                    value={data?.employer ?? ''}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        setData({
                                            ...data,
                                            employer: e?.target?.value
                                        });
                                        setErrorData({
                                            ...errorData,
                                            employer: validateInputString(e?.target?.value),
                                        });
                                    }}
                                    error={errorData?.employer}
                                    required
                                />
                            </div>
                            <div className="form">

                                <SelectElement
                                    data-cy="industry"
                                    placeholder={t('3b.kyc.pleaseSelect')}
                                    label={t('3b.kyc.industry')}
                                    value={data?.industry && String(data?.industry)}
                                    onChange={(value: string) => {
                                        setData({
                                            ...data,
                                            industry: parseInt(value, 10)
                                        });
                                        setErrorData({
                                            ...errorData,
                                            industry: validateSelect(value),
                                        });
                                    }}
                                    options={optionIndustry2}
                                    error={errorData?.industry}
                                    required
                                />

                                <SelectElement
                                    data-cy="educationDegree"
                                    placeholder={t('3b.kyc.pleaseSelect')}
                                    label={t('3b.kyc.educationDegree')}
                                    value={data?.degreeOfEducation && String(data?.degreeOfEducation)}
                                    onChange={(value: string) => {
                                        setData({
                                            ...data,
                                            degreeOfEducation: parseInt(value, 10)
                                        });
                                        setErrorData({
                                            ...errorData,
                                            degreeOfEducation: validateSelect(value),
                                        });
                                    }}
                                    options={optionDegreeOfEducation}
                                    error={errorData?.degreeOfEducation}
                                    required
                                />
                            </div>
                        </div>
                    </fieldset>
                )}
                <fieldset className="info-section">
                    <Title tagName="legend">
                        {t('3b.kyc.assetsOrigin')}
                    </Title>
                    <div className="info-group">
                        <div className="form">
                            <div>

                                <span className="custom-checkbox-label">{`${t('3b.kyc.selectAll')} *`}</span>
                                <CheckboxGroup
                                    /* @ts-ignore */
                                    options={optionOriginOfAssets || []}
                                    value={data?.originOfAssets?.map((i) => String(i)) || []}
                                    onChange={(value: [string]) => {
                                        setData({
                                            ...data,
                                            originOfAssets: value
                                        });
                                        setErrorData({
                                            ...errorData,
                                            originOfAssets: validateCheckboxGroup(value),
                                        });
                                    }}
                                    size="big"
                                    error={errorData?.originOfAssets}
                                    required
                                    isInQuestionnaire
                                />
                            </div>
                        </div>
                        <div className="form additional-info">
                            <Textarea
                                /* @ts-ignore */
                                value={data?.additionalInformation ?? ''}
                                label={t('3b.kyc.additionalInformation')}
                                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setData({
                                    ...data,
                                    additionalInformation: e?.target?.value
                                })}
                            />
                            {isTopTierPlanInvestment &&
                                <StatusBox status={Status.Warning} description={t('3b.kyc.highInvestmentMessage')}/>}
                        </div>
                    </div>
                </fieldset>
                <fieldset className="info-section">
                    <Title tagName="legend">
                        {t('3b.kyc.personOfInterest')}
                    </Title>
                    <div className="form">
                        <StatusBox status={Status.Info} title={t('3b.kyc.poiTitle')}
                                   description={t('3b.kyc.poiDescription')} showIcon/>
                        <div className="pep">
                            <p className="pep-question">{t('3b.kyc.pepQuestion')}</p>
                            <RadioButtonGroup
                                value={String(data?.pepStatus)}
                                options={[
                                    {
                                        label: t('3b.kyc.radioNo'),
                                        value: 'false'
                                    },
                                    {
                                        label: t('3b.kyc.radioYes'),
                                        value: 'true'
                                    },
                                ]}
                                onChange={(e: any) => {
                                    setData({
                                        ...data,
                                        pepStatus: JSON.parse(e.target.value)
                                    });
                                    setErrorData({
                                        ...errorData,
                                        pepStatus: validateRadio(JSON.parse(e.target.value)),
                                    });
                                }}
                                error={errorData?.pepStatus}
                            />

                            {data?.pepStatus && (
                                <div className="pep-description">
                                    <SelectElement
                                        data-cy="pepDescription"
                                        placeholder={t('3b.kyc.pleaseSelect')}
                                        label={t('3b.kyc.pepDescription')}
                                        value={data?.pepDescription && String(data?.pepDescription)}
                                        onChange={(value: string) => {
                                            setData({
                                                ...data,
                                                pepDescription: parseInt(value, 10)
                                            });
                                            setErrorData({
                                                ...errorData,
                                                pepDescription: validateSelect(value),
                                            });
                                        }}
                                        options={optionPepStatus}
                                        error={errorData?.pepDescription}
                                        required
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </fieldset>
                <fieldset className="info-section">
                    <Title tagName="legend">
                        {t('3b.kyc.beneficialOwner')}
                    </Title>
                    <div className="form bo-form">
                        <p className="beneficial-owner">{t('3b.kyc.boLabel')}</p>
                        <div className="bo">
                            <p className="bo-question">{t('3b.kyc.boQuestion')}</p>
                            <RadioButtonGroup
                                value={String(data?.beneficialOwner)}
                                className="bo-radio"
                                options={optionBeneficialOwner}
                                error={errorData?.beneficialOwner}
                                onChange={(e: any) => {
                                    setData({
                                        ...data,
                                        beneficialOwner: parseInt(e.target.value, 10)
                                    });
                                    setErrorData({
                                        ...errorData,
                                        beneficialOwner: validateRadio(JSON.parse(e.target.value)),
                                    });
                                }}
                            />
                            <div className="bo-info">
                                {shouldProcessStop && <StatusBox className="bo-parties" status={Status.Danger}
                                                                 description={t('3b.kyc.boPartiesRightsMessage')}/>}
                                <span className="bo-info-item">{t('3b.kyc.boInfoZiagRights')}</span>
                                <span className="bo-info-item">{t('3b.kyc.boInfoCriminal')}</span>
                            </div>
                        </div>
                        {showWarningMessage &&
                            <StatusBox status={Status.Warning} description={t('3b.kyc.boCustomerRightsMessage')}/>}
                    </div>
                </fieldset>
            </Preloader>
        </div>
    );
};

export default KYCForm;
