import React, {useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Modal} from 'ui-library';
import UploadDocuments from '../../../../../../components/UploadDocuments/UploadDocuments';
import Error from '../../../../../../components/AtomicStructure/atoms/Error';
import {useConnections} from '../../../../../../components/Connections/components';
import ServiceManager from '../../../../../../services/ServiceManager';
import {getBase64FromFile} from '../../../../../../utils/file';
import {ContactGroupDocumentTypes} from '../NewConnections/constants';

type UploadContactGroupDocumentModalProps = {
    show: boolean;
    onClose: () => void
}

export const UploadContactGroupDocumentModal = ({show, onClose}: UploadContactGroupDocumentModalProps) => {
    const {t} = useTranslation();
    const [error, setError] = useState<string>('');
    const [files, setFiles] = useState([]);
    const [inProgress, setInProgress] = useState<boolean>(false);
    const {contactGroupDetails, getContactGroupDocuments} = useConnections();

    const onUploadDocuments = useCallback(async () => {
        try {
            if (!files.length) {
                setError(t('contactGroupDocuments.uploadDocumentsModal.noFilesSelectedError'));

                return;
            }

            setInProgress(true);
            const payload = {
                ownerId: contactGroupDetails?.groupId,
                documents: await Promise.all(files.map(async (file: any) => {
                    const base64File = await getBase64FromFile(file);
                    const splitted = base64File.split(',');

                    return {
                        filename: file.name,
                        file: splitted[1],
                        type: ContactGroupDocumentTypes.generalDoc,
                    };
                })),
            };

            await ServiceManager.customDocumentsService('uploadContactGroupDocuments', [{payload}]);
            await getContactGroupDocuments(contactGroupDetails?.groupId);
            onClose();
        } catch (err) {
            console.error(err);
        } finally {
            setInProgress(false);
        }
    }, [files, t, contactGroupDetails, getContactGroupDocuments]);

    return (
        <Modal width={500} okButtonProps={{loading: inProgress}} visible={show} onCancel={onClose} onOk={onUploadDocuments} title={t('contactGroupDocuments.uploadDocumentsModal.title')}>
            <UploadDocuments onError={setError} onSuccess={setFiles} />
            {error && <Error>{error}</Error>}
        </Modal>
    );
};
