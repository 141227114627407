import React, {useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import {Button, Infobox} from 'ui-library';
import {PRODUCTS} from 'constants/constants';
import {
    BUTTON_TYPE_PRIMARY,
} from 'ui-library/components/Button/constants';

import './ChangeVBorVBPPlanModalContent.css';

export const ChangeVBorVBPPlanModalContent = ({
    path,
    isVB,
    isVBP,
    navigate,
    onNavigate,
}) => {
    const {t} = useTranslation();
    const onChange = useCallback((productKey: string) => {
        navigate(path, {state: {productKey}});
        onNavigate();
    }, [path]);

    return (
        <>
            <div className="ChangeVBorVBPPlanModalContent_desc">{t('clientDashboard.changeVBPlan.desc')}</div>
            <div className="ChangeVBorVBPPlanModalContent_buttonGroup">
                <Button type={BUTTON_TYPE_PRIMARY} disabled={!isVB} onClick={() => onChange(PRODUCTS.fz)}>{t('clientDashboard.changeVBPlan.vb.button')}</Button>
                <Button type={BUTTON_TYPE_PRIMARY} disabled={!isVBP} onClick={() => onChange(PRODUCTS.fzp)}>{t('clientDashboard.changeVBPlan.vbp.button')}</Button>
            </div>
            <Infobox className="ChangeVBorVBPPlanModalContent_infoNote">{t('clientDashboard.changeVBPlan.note')}</Infobox>
        </>
    );
};

ChangeVBorVBPPlanModalContent.propTypes = {
    path: PropTypes.string.isRequired,
    isVB: PropTypes.bool,
    isVBP: PropTypes.bool,
    onNavigate: PropTypes.func,
};

ChangeVBorVBPPlanModalContent.defaultProps = {
    isVB: false,
    isVBP: false,
    onNavigate: () => {},
};
