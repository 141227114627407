export const defaultLineChartOptions = {
    chart: {
        zoomType: 'xy',
        marginRight: 0,
    },
    title: false,
    xAxis: {
        type: 'datetime',
        minPadding: 0,
    },
    yAxis: {
        rotation: 0,
        offset: 15,
    },
    plotOptions: {
        series: {
            threshold: -Infinity,
            marker: { symbol: 'circle', states: { hover: { radius: 5 } } },
        }
    },
    legend: {
        useHTML: true,
        symbolHeight: 0,
        symbolWidth: 0,
        symbolRadius: 0,
    },
    tooltip: { useHTML: true },
    credits: false,
};

export const defaultGoalOptions = {
    type: 'line',
    marker: {
        enabled: true,
        radius: 8,
        symbol: 'goal',
        states: { hover: { radius: 8 } },
    },
    states: { hover: { halo: { size: 14 } } },
    showInLegend: false,
    className: 'highcharts-goal',
};

export const defaultDurationOptions = {
    type: 'line',
    marker: {
        enabled: true,
        radius: 4,
        symbol: 'circle',
        states: { hover: { enabled: false } },
    },
    showInLegend: false,
    className: 'highcharts-duration',
};

export const defaultLabels = {
    months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    shortMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
};
