import React from 'react';
import {Checkbox} from 'ui-library';

const CheckboxRenderer = (props) => (
    <div className="CheckboxRenderer-Cell">
        <Checkbox
            data-cy="AllocationName"
            {...props}
        />
    </div>
);

export default CheckboxRenderer;
