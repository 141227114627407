import React, { useContext } from 'react';

import { NotificationContext } from '../contextProviders/NotificationProvider';

export const useNotificationConext = () => {
    const context = useContext(NotificationContext);

    if (context === undefined) {
        throw new Error('useLayout must be used within a LayoutProvider');
    }

    return context;
};
