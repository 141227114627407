import { useContext } from 'react';
import { ManagerProfileContext } from './ManagerProfileContext';

export default () => {
    const context = useContext(ManagerProfileContext);

    if (context === undefined) throw new Error('useManagerProfile must be used within a ManagerProfileProvider');

    return context;
};
