/* eslint-disable react/forbid-prop-types */
import React, { Fragment, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import Icon, { ICON_TYPE_MINUS, ICON_TYPE_PLUS } from '../../Icon';
import { getClassNames } from '../../../utils';

function RowCell(props) {
    const {
        row,
        data,
        index,
        level,
        column: {
            hidden,
            render,
            sorter,
            sortable,
            className,
            selection,
            filterable,
            titleType,
            onCell,
        },
        expanded,
        onExpand,
    } = props;

    if (hidden) return null;

    const cellPops = typeof onCell === 'function' ? onCell(row, index) : {};
    const selectComponent = useMemo(() => (typeof selection === 'function' ? selection(data, row) : null),
        [selection, data, row]);
    const expandIcon = useMemo(() => index === 0 && row.children && row.children.length > 0,
        [row, index]);
    const expandRender = useCallback(
        (render) => (level > 0 && index === 0 ? <div className="expand-cell">{render}</div> : render),
        [level, index],
    );
    const classNameCell = getClassNames(className,
        { 'cell-sortable': sortable || sorter },
        { 'cell-filterable': filterable },
        { 'cell-expandable': expandIcon },
        { 'cell-fixed': titleType === 'columns' });
    const InnerComponent = useMemo(() => (selectComponent ? 'div' : Fragment),
        [selectComponent]);
    const innerComponentProps = useMemo(() => (selectComponent ? { className: 'cell-row-selection' } : {}),
        [selectComponent]);

    return (
        <td
            role="cell"
            className={classNameCell}
            {...cellPops}
        >
            <InnerComponent {...innerComponentProps}>
                {selectComponent}
                {expandIcon && (
                    <span className="expand-icon">
                        <Icon
                            onClick={() => (cellPops.onClick === undefined ? onExpand() : {})}
                            type={expanded ? ICON_TYPE_MINUS : ICON_TYPE_PLUS}
                        />
                    </span>
                )}
                {expandRender(render
                    ? render(data, row, index)
                    : (<span className="table-cell">{data}</span>))}
            </InnerComponent>
        </td>
    );
}

RowCell.propTypes = {
    row: PropTypes.shape({
        [PropTypes.string]: PropTypes.any,
        children: PropTypes.arrayOf(PropTypes.shape({
            [PropTypes.string]: PropTypes.any,
        })),
    }),
    data: PropTypes.oneOfType([PropTypes.string, PropTypes.any]),
    level: PropTypes.number.isRequired,
    index: PropTypes.number.isRequired,
    column: PropTypes.shape({
        hidden: PropTypes.bool,
        render: PropTypes.func,
        sortable: PropTypes.bool,
        className: PropTypes.string,
        sorter: PropTypes.func,
        selection: PropTypes.func,
        filterable: PropTypes.bool,
        titleType: PropTypes.string,
        onCell: PropTypes.func,
    }),
    expanded: PropTypes.bool,
    scrollable: PropTypes.bool,
    onExpand: PropTypes.func,
};

RowCell.defaultProps = {
    row: {
        children: undefined,
    },
    data: null,
    column: {
        hidden: false,
        render: undefined,
        sortable: false,
        className: undefined,
        filterable: false,
        sorter: undefined,
        titleType: undefined,
        selection: () => { },
        onCell: () => ({}),
    },
    expanded: false,
    scrollable: false,
    onExpand: () => { },
};

export default RowCell;
