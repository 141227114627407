import React from 'react';
import {useParams} from 'react-router-dom';
import { getClientId } from 'utils';
import QualityCheck from './QualityCheck';
import { QualityCheckProvider } from './Provider';
import './QualityCheck.css';
import {useCreatePortfolio} from '../../context';

export function QualityCheckWithProvider() {
    const { dfsClientId } = useParams();
    const clientId = getClientId(dfsClientId);
    const { newPortfolio } = useCreatePortfolio();

    return (
        <QualityCheckProvider options={{ clientId, productId: newPortfolio?.applicationData?.investmentDescription?.productId }}>
            <QualityCheck />
        </QualityCheckProvider>
    );
}
