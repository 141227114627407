import React, {
    createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useReducer,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { StepperAPI, StepperState, StepsType } from './Stepper.types';
import { flattenSteps } from '../../utils/stepDefintion';

const LOCAL_STORAGE_KEY = 'Stepper';

const StepperContext = createContext<StepperAPI>(null as any);

const initialState = (): StepperState => {
    const localStorageData = sessionStorage.getItem(LOCAL_STORAGE_KEY);

    return localStorageData ? JSON.parse(localStorageData) : {};
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'stepComplete':
            return { ...state, isStepCompleted: action.payload };
        case 'setStep':
            return { ...state, currentStepKey: action.payload, isStepCompleted: false };
        case 'readyToNavigate':
            return { ...state, readyToNavigate: action.payload };
        default:
            return state;
    }
};

type StepperProviderProps = {
    startStep: string,
    items: StepsType;
    children: ReactNode;
    withCache: boolean;
    completeURL?: string;
    backURL?: string
}

export const StepperManager = ({
    items, children, withCache, startStep, backURL = '', completeURL = '',
}: StepperProviderProps) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const [state, dispatch] = useReducer(reducer, { currentStepKey: startStep, ...(initialState()) });

    const navigate = useNavigate();

    const flatSteps = useMemo(() => flattenSteps(items), [items]);
    const currentStepSelector = useMemo(() => (flatSteps[state.currentStepKey]), [state.currentStepKey, flatSteps]);
    const currentProcessSelector = useMemo(() => (flatSteps?.[state?.currentStepKey]?.process), [state?.currentStepKey, flatSteps]);

    const getStepBySetup = useCallback((navigationSubject) => {
        if (Array.isArray(navigationSubject)) return navigationSubject?.find((item) => (item.condition()))?.target ?? state.currentStepKey;

        return navigationSubject;
    }, [state.currentStepKey]);

    useEffect(() => {
        const stepToGo = flatSteps[state.currentStepKey];

        if (state.readyToNavigate && stepToGo?.url) navigate(stepToGo?.url);
    }, [state.currentStepKey, flatSteps, state.readyToNavigate]);

    useEffect(() => {
        if (state.isStepCompleted && state.readyToNavigate) {
            if (currentStepSelector?.isEnd) {
                navigate(completeURL);

                return;
            }
            const nextStepKey = getStepBySetup(currentStepSelector?.next);

            dispatch({
                type: 'setStep',
                payload: nextStepKey,
            });
        }
    }, [state.isStepCompleted, currentStepSelector, completeURL, getStepBySetup, state.readyToNavigate]);

    const stepBack = useCallback(() => {
        if (currentStepSelector.isBegin) {
            navigate(backURL);

            return;
        }
        const prevStepKey = getStepBySetup(currentStepSelector.prev);

        dispatch({ type: 'setStep', payload: prevStepKey });
    }, [currentStepSelector, backURL, getStepBySetup]);

    const readyToNavigateAction = useCallback((isReady: boolean) => {
        dispatch({ type: 'readyToNavigate', payload: isReady });
    }, []);

    const stepComplete = useCallback((isComplete: boolean = true) => {
        dispatch({ type: 'stepComplete', payload: isComplete });
    }, []);

    const setStep = useCallback(({ stepKey }) => {
        dispatch({
            type: 'setStep',
            payload: stepKey,
        });
    }, []);

    useEffect(() => {
        if (withCache) {
            sessionStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(state));
        } else {
            sessionStorage.removeItem(LOCAL_STORAGE_KEY);
        }
    }, [state]);

    return (
        <StepperContext.Provider value={{
            state,
            actions: {
                stepComplete,
                stepBack,
                readyToNavigateAction,
                setStep,
            },
            selectors: {
                currentStepSelector,
                currentProcessSelector,
            },
        }}
        >
            {children}
        </StepperContext.Provider>
    );
};

StepperManager.defaultProps = {
    completeURL: '/',
    backURL: '/',
};

StepperManager.LOCAL_STORAGE_KEY = LOCAL_STORAGE_KEY;

export const useStepper = (): StepperAPI => useContext(StepperContext);
