import React, {
    useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import SM from 'services/ServiceManager';
import { Modal } from 'ui-library';
import { useNavigate } from 'react-router-dom';
import {
    boolean, object, ObjectSchema, string,
} from 'yup';
import { getValidationMessage } from 'validation/messages';
import { getUpperAgeLimitPerProduct } from 'pages/ClientOverview/pages/Portfolios/pages/CreateNewPortfolio/utils';
import 'widgets/PersonalDetails/PersonalDetails.css';
import { DEFAULT_NUMBER_OF_LOADED_ITEMS } from 'widgets/PersonalDetails/constants/const';
import { useCreatePortfolioActions, useCreatePortfolioSelectors } from 'datasource/CreatePortfolio';
import { useTranslation } from 'react-i18next';
import { IsAllDataNull } from 'validation/validation';
import { useForm } from 'react-hook-form';
import {
    errorModel, getModel, PersonalDetailGetModelType, sendModel,
} from '../models';
import { useFormatting } from '../../../locale';
import { PersonalDetailsFormType } from '../../../widgets/PersonalDetails/PersonalDetailsForm.types';
import { useYupValidationResolver } from '../../../hooks/useYupValidationResolver';
import { genarateDFSClientId } from '../../../utils';
import { getAge } from '../../../utils/personalDetails';
import { IPersonalDetails } from '../../../core/types/api/mix';

function usePersonalDetails({
    personalDetails,
} : {personalDetails?: IPersonalDetails}) {
    const { t, i18n: { language } } = useTranslation();
    const { getFormattedDate } = useFormatting();
    const navigate = useNavigate();
    const retiredRef = useRef<HTMLInputElement|null>(null);
    const [data, setData] = useState<PersonalDetailGetModelType>();

    const [occupationListOptions, setOccupationListOptions] = useState<Options>([]);
    const [errorData, setErrorData] = useState({});
    const [searchInProgress, setSearchInProgress] = useState(false);
    const [hasMoreOccupations, setHasMoreOccupations] = useState(false);
    const clientId = personalDetails?.id;
    const dfsClientId = useMemo(() => genarateDFSClientId(clientId), [clientId]);
    const clientAge = useMemo(() => getAge(personalDetails?.details?.personalInformation?.dateOfBirth ?? ''), [personalDetails?.details?.personalInformation?.dateOfBirth]);

    const {
        selectedProductTypeSelector,
        isDependantOnAffiliatedWithPensionFundSelector,
        isPageLoadingSelector,
    } = useCreatePortfolioSelectors();

    const {
        clientDataRefetchAction,
    } = useCreatePortfolioActions();

    const showEmploymentContinuationCheck = useMemo(() => clientAge && clientAge >= getUpperAgeLimitPerProduct(data?.gender, selectedProductTypeSelector),
        [clientAge, data?.gender, selectedProductTypeSelector]);

    const getOccupations = useCallback(async (params, appendResult) => {
        if (data?.gender && language) {
            const requestData = {
                language,
                gender: data?.gender,
                ...params,
            };
            const { data: occupations } = await SM.customService('getOccupationsLookup', [requestData]);

            const optionItems = occupations?.map(({ id, name, disabled }) => ({ id, label: name, disabled }));

            if (appendResult) {
                setOccupationListOptions(prev => [...prev, ...optionItems]);
            } else {
                setOccupationListOptions(optionItems);
            }
            setHasMoreOccupations(occupations.length >= DEFAULT_NUMBER_OF_LOADED_ITEMS);
        }
    }, [language, data?.gender]);

    const handleSearchOccupations = useCallback(async (value:string) => {
        try {
            setSearchInProgress(true);
            const payloadData = {
                skip: 0,
                name: value,
            };

            await getOccupations(payloadData, false);
        } finally {
            setSearchInProgress(false);
        }
    }, [getOccupations]);

    const loadMoreItems = useCallback(async (page: number, searchValue: string) => {
        if (hasMoreOccupations && !searchInProgress) {
            const payloadData:{ skip: number; name?: string } = {
                skip: page * DEFAULT_NUMBER_OF_LOADED_ITEMS,
            };

            if (searchValue) {
                payloadData.name = searchValue;
            }

            await getOccupations(payloadData, true);
        }
    }, [getOccupations, hasMoreOccupations, searchInProgress]);

    useEffect(() => {
        const params: {page: number; selectedId?: string} = { page: 0 };

        if (data?.occupation) {
            params.selectedId = data?.occupation;
        }
        if (data) (async () => getOccupations(params, false))();
    }, [getOccupations, data?.occupation]);

    useEffect(() => {
        clientDataRefetchAction();
    }, []);

    const editPersonalData = useCallback(() => {
        navigate(`/client/${dfsClientId}/details`);
    }, [dfsClientId]);

    const modalConfirmationContent = useMemo(() => (
        <div className="modal-content">
            <div>{t('onboardingFlow.personalPage.retiredButStillEmployedConfirmationContent1')}</div>
            <div>{t('onboardingFlow.personalPage.retiredButStillEmployedConfirmationContent2')}</div>
            <div>{t('onboardingFlow.personalPage.retiredButStillEmployedConfirmationContent3')}</div>
        </div>
    ), [t]);

    const onRetiredButStillEmployedChange = useCallback((e) => {
        if (e.target?.checked) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            Modal.confirm({
                triggerCancelOnClose: true,
                title: t('onboardingFlow.personalPage.retiredButStillEmployedConfirmationTitle'),
                content: modalConfirmationContent,
                okText: t('onboardingFlow.personalPage.retiredButStillEmployedConfirmationOk'),
                width: 450,
                className: 'employment-continuation-confirmation-modal',
                onOk: () => {
                    setData({
                        ...data,
                        employmentContinuation: true,
                        employmentContinuationDate: new Date(),
                    });
                    setErrorData({
                        ...errorData,
                        employmentContinuation: null,
                    });
                },
                onCancel: () => {
                    retiredRef.current?.click();
                },
                cancelText: t('onboardingFlow.personalPage.retiredButStillEmployedConfirmationCancel'),
            });
        } else {
            setData({
                ...data,
                employmentContinuation: false,
                employmentContinuationDate: new Date(),
            });
            setErrorData({
                ...errorData,
                employmentContinuation: getValidationMessage().mandatoryError,
            });
        }
    }, [data]);

    const schema: ObjectSchema<PersonalDetailsFormType> = useMemo(() => object().shape({
        ...(isDependantOnAffiliatedWithPensionFundSelector ? {
            affiliatedPensionFund: string().required(t('validation.mandatoryField')),
        } : {}),
        employmentContinuation: showEmploymentContinuationCheck ? boolean().required().oneOf([true], t('validation.mandatoryField')) : boolean().nullable(),
        occupation: string().required(t('validation.mandatoryField')),
        taxDomicile: string().required(t('validation.mandatoryField')),
        riskDomicile: string().required(t('validation.mandatoryField')),
        member: boolean(),
        destinedLife: boolean(),
        zurichMA: boolean(),
    }), [showEmploymentContinuationCheck, isDependantOnAffiliatedWithPensionFundSelector]);

    const resolver = useYupValidationResolver(schema);

    const {
        control, formState: { errors }, setValue, handleSubmit, reset,
    } = useForm({
        resolver,
    });

    useEffect(() => {
        if (personalDetails && Object.keys(personalDetails).length) {
            const adaptedData = getModel(personalDetails, getFormattedDate);

            setData(adaptedData);
            reset({
                affiliatedPensionFund: adaptedData?.affiliatedPensionFund,
                employmentContinuation: adaptedData?.employmentContinuation,
                occupation: adaptedData?.occupation,
                taxDomicile: adaptedData?.taxDomicile,
                riskDomicile: adaptedData?.riskDomicile,
                member: adaptedData?.member,
                destinedLife: adaptedData?.destinedLife,
                zurichMA: adaptedData?.zurichMA,
            });
        }
    }, [personalDetails]);

    const onContinue = useCallback(async (): Promise<boolean> => new Promise((resolve, reject) => {
        handleSubmit(async (formData: any) => {
            try {
                let fullData = { ...data, ...formData };

                fullData = {
                    ...fullData,
                    occupation: parseInt(fullData?.occupation ?? '', 10),
                };

                const missingPersonalData = errorModel({
                    data: fullData,
                    isDependantOnAffiliatedWithPensionFund: isDependantOnAffiliatedWithPensionFundSelector,
                    showEmploymentContinuationCheck,
                });

                setErrorData(missingPersonalData);
                if (IsAllDataNull(missingPersonalData) === false) {
                    resolve(false);

                    return;
                }
                await SM.clientService('patchContact', [clientId, sendModel(fullData)]);
                resolve(fullData);
            } catch (e) {
                reject(e);
            }
        }, () => {
            resolve(false);
        })();
    }), [
        data,
        isDependantOnAffiliatedWithPensionFundSelector,
        clientId,
    ]);

    return {
        onContinue,
        isDependantOnAffiliatedWithPensionFund: isDependantOnAffiliatedWithPensionFundSelector,
        onRetiredButStillEmployedChange,
        data,
        showEmploymentContinuationCheck,
        onEditPersonalData: editPersonalData,
        missingData: errorData,
        retiredRef,
        isPageLoading: isPageLoadingSelector || !data,
        occupation: {
            occupationListOptions,
            handleSearchOccupations,
            searchInProgress,
            loadMoreItems,
            hasMoreOccupations,
        },
        form: {
            setValue,
            control,
            errors,
        },

    };
}

export default usePersonalDetails;
