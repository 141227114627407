import mail from 'clientRequirements/images/footerNavigation/contact_icon.svg';
import documents from 'clientRequirements/images/footerNavigation/documents_icon.svg';
import home from 'clientRequirements/images/footerNavigation/house_icon.svg';
import padlock from 'clientRequirements/images/footerNavigation/padlock_icon.svg';
import portfolio from 'clientRequirements/images/footerNavigation/portfolio_icon.svg';
import React from 'react';
import {Link} from 'react-router-dom';

import './styles/FooterNavigation.css';

const FooterNavigation = (props) => (
    <div className="footerNavigation  justify-content-between">
        <div className="footerLink">
            <Link to="/">
                <img src={home} alt=""/>
            </Link>
            <div>Übersicht</div>
        </div>
        <div className="footerLink">
            <Link to="/">
                <img src={mail} alt=""/>
            </Link>
            <div>Nachrichten</div>
        </div>
        <div className="footerLink">
            <Link to="/">
                <img src={portfolio} alt=""/>
            </Link>
            <div>Dokumente</div>
        </div>
        <div className="footerLink">
            <Link to="/">
                <img src={documents} alt=""/>
            </Link>
            <div>Profil</div>
        </div>
        <div className="footerLink">
            <Link to="/">
                <img src={padlock} alt=""/>
            </Link>
            <div>Logout</div>
        </div>
    </div>
);

export default FooterNavigation;
