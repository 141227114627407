import {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import ServiceManager from 'services/ServiceManager';
import AdapterError from 'errors/AdapterError';
import {adaptProducts} from 'adaptors/adaptProducts';
import handlerRequestCanceling from 'utils/handlerRequestCanceling';
import HandlerError from 'errors/HandlerError';
import {useFormatting, useLocale} from 'locale';
import {PRODUCTS} from '../constants/constants';

export const threeBProductGroup = {
    id: 999999,
    name: '3b',
    description: 'Zurich Invest 3b',
    isEnabled: true,
    settings: {productKey: '3b'},
};
export const useProducts = (step) => {
    const [products, setProducts] = useState({ products: [] });
    const [errorProducts, setErrorProducts] = useState(null);
    const [isLoadingProducts, setLoadingProducts] = useState(false);
    const { t, i18n } = useTranslation();

    const { getFormattedNumber } = useFormatting();
    const { locale } = useLocale();

    useEffect(() => {
        if (step === undefined || step === 3) {
            setErrorProducts(null);
            setLoadingProducts(true);

            ServiceManager.commonService('getGeneralSettings`')
                .then(({ data: globalSettings }) => {
                    ServiceManager.commonService('getCampaignsCurrentConfiguration', [i18n.language])
                        .then((response) => {
                            try {
                                setProducts(adaptProducts(response.data,
                                    globalSettings, t, getFormattedNumber));
                                setLoadingProducts(false);
                            } catch (err) {
                                throw new AdapterError(err);
                            }
                        })
                        .catch(handlerRequestCanceling(
                            HandlerError({
                                setLoading: setLoadingProducts,
                                setError: setErrorProducts,
                            }),
                        ));
                })
                .catch(handlerRequestCanceling(
                    HandlerError({
                        setLoading: setLoadingProducts,
                        setError: setErrorProducts,
                    }),
                ));
        }
    }, [step, i18n.language, locale]);

    return {
        products, isLoadingProducts, errorProducts,
    };
};

export const useProductsList = (contactId?:number) => {
    const [products, setProducts] = useState<ProductType[]>([]);
    const [errorProducts, setErrorProducts] = useState(null);
    const [isLoadingProducts, setLoadingProducts] = useState(false);
    const [groupedProducts, setGroupedProducts] = useState<ProductType[]>([]);
    const { i18n: {language} } = useTranslation();

    const { locale } = useLocale();

    const getProducts = useCallback(async () => {
        setErrorProducts(null);
        setLoadingProducts(true);

        try {
            const response = await ServiceManager.customCommonService('getProductsConfiguration', [{language, contactId}]);

            try {
                let prods = [];
                let groupedProds = [];

                if (response.data.productOffers != null && response.data.productOffers.length) {
                    prods = response.data.productOffers[0].products; // Get first active ProductOffer
                    groupedProds = response.data.productOffers[0].products?.filter(f => [PRODUCTS.aaa, PRODUCTS.fz, PRODUCTS.fzp].includes(f.settings?.productKey?.toLowerCase()));
                }

                if (response.data.productOffers[0].products.some(s => s.settings.productKey === PRODUCTS.bbb && s.isEnabled)) {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    groupedProds.push(threeBProductGroup as ProductType);
                }

                setProducts(prods);
                setGroupedProducts(groupedProds);
                setLoadingProducts(false);

                return prods;
            } catch (err) {
                throw new AdapterError(err);
            }
        } catch (err) {
            throw handlerRequestCanceling(
                HandlerError({
                    setLoading: setLoadingProducts,
                    setError: setErrorProducts,
                }),
            )(err);
        }
    }, [language, locale]);

    return {
        products,
        groupedProducts,
        getProducts,
        isLoadingProducts,
        errorProducts,
    };
};
