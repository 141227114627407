import { useEffect, useMemo, useState } from 'react';
import Selection from '../renderers/Selection';

export const useTableSelection = (rowSelection) => {
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const hasSelection = useMemo(() => (rowSelection !== null), [rowSelection]);

    useEffect(() => {
        if (rowSelection && rowSelection.selectedRowKeys) {
            setSelectedRowKeys(rowSelection.selectedRowKeys);
        }
    }, [rowSelection ? rowSelection.selectedRowKeys : null]);

    const onChangeSelection = (key) => {
        let newState = [key];

        if (rowSelection.type === 'checkbox') {
            if (selectedRowKeys.includes(key)) {
                newState = selectedRowKeys.filter((item) => item !== key);
            } else {
                newState = [...selectedRowKeys, key];
            }
        }
        setSelectedRowKeys(newState);
        if (typeof rowSelection.onChange === 'function') rowSelection.onChange(newState);
    };
    const selectionType = useMemo(() => (hasSelection ? rowSelection.type || 'radio' : null),
        [hasSelection, rowSelection ? rowSelection.type : null]);
    const selectionConfig = useMemo(() => (selectionType
        ? ({
            selectedRowKeys,
                        render: Selection({
                type: selectionType,
                selectedRowKeys,
                onChange: onChangeSelection,
            }),
        })
        : {}), [selectionType, selectedRowKeys, onChangeSelection]);

    return {
        selectionConfig,
    };
};
