import React from 'react';
import PropTypes from 'prop-types';
import HighchartsReact from 'highcharts-react-official';
import { getColumnChartOptions } from './utils';
import { getClassNames } from '../../../utils';
import './ColumnChart.css';
import withTheme from '../../../hocs/withTheme';

function ColumnChart(props) {
    const {
        data, labels, isSelected, labelFormat, min, max, className, showPercentage,
    } = props;

    return (
        <HighchartsReact
            containerProps={{
                className: getClassNames('Highcharts-column-chart', className),
            }}
            options={getColumnChartOptions(
                data,
                labels,
                isSelected,
                labelFormat,
                min,
                max,
                showPercentage,
            )}
        />
    );
}

ColumnChart.propTypes = {
    /** Data to be shown on chart */
    data: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        data: PropTypes.arrayOf(PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.shape({
                [PropTypes.string]: PropTypes.number,
            }),
            PropTypes.arrayOf(PropTypes.number),
        ])).isRequired,
    })),
    /** Labels to be shown on X axis */
    labels: PropTypes.arrayOf(PropTypes.string),
    /** Index of value with shown labels */
    isSelected: PropTypes.number,
    /** Formats of X axis label */
    labelFormat: PropTypes.func,
    /** Max value of Y axis */
    max: PropTypes.number,
    /** Min value of Y axis */
    min: PropTypes.number,
    /** Whether to show values by each column */
    showPercentage: PropTypes.bool,
    className: PropTypes.string,
};

ColumnChart.defaultProps = {
    min: undefined,
    max: undefined,
    data: [],
    labels: [],
    isSelected: undefined,
    showPercentage: false,
    labelFormat: (label) => label,
    className: '',
};

export default withTheme(ColumnChart);
