import {useWithMemberProfiles} from '../../../../../../../EditProfile/hooks/useWithMemberProfiles';
import {useManagerProfile} from '../../../../../../../../prodivers/managerProfile';

export const navigationPages = ({
    portfolioId, dfsClientId, t, showDeposits, showDetails, showStrategy,
}) => {
    const {hasAccessPolicy} = useManagerProfile();
    const pages = [
        {
            title: t('clients.portfolioNav.overview'),
            path: `/client/${dfsClientId}/portfolios/${portfolioId}/tab/overview`,
            pathnameKey: '/overview',
        },
        {
            title: t('clients.portfolioNav.strategy'),
            path: `/client/${dfsClientId}/portfolios/${portfolioId}/tab/strategy`,
            pathnameKey: '/strategy',
            hidden: () => !showStrategy,
        },
        {
            title: t('clients.portfolioNav.inventory'),
            path: `/client/${dfsClientId}/portfolios/${portfolioId}/tab/stock`,
            pathnameKey: '/stock',
        },
        {
            title: t('clients.portfolioNav.payIn'),
            path: `/client/${dfsClientId}/portfolios/${portfolioId}/tab/deposit`,
            pathnameKey: '/deposit',
        },
        {
            title: t('navigation.topNav.kudi'),
            path: `/client/${dfsClientId}/portfolios/${portfolioId}/tab/details`,
            pathnameKey: '/details',
            hidden: () => !(hasAccessPolicy('ShadowAccounts') && showDetails),
        },
    ];

    if (!showDeposits) {
        pages.splice(3, 1);
    }

    return pages;
};
