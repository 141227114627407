import React, { useMemo } from 'react';
import {string, shape} from 'prop-types';

import {useTranslation} from 'react-i18next';
import {getClientId} from 'utils';
import Preloader from 'components/Preloader';
import EmptyContent from 'components/EmptyContent';

import {useParams} from 'react-router-dom';
import {Qr} from './components/Qr';
import {Details} from './components/Details';
import {useAllPortfolios} from './useAllPortfolios';
import { useSinglePortfolio } from '../../context';

const Deposit = () => {
    const {i18n: {language}} = useTranslation();

    const { dfsClientId, portfolioId } = useParams();

    const clientId = useMemo(() => (getClientId(dfsClientId)), [dfsClientId]);

    const {
        isLoading,
        error,
        data,
        portfolioTitle,
    } = useAllPortfolios(clientId, language);

    const { portfolioDetail } = useSinglePortfolio();

    return (
        <Preloader isLoading={isLoading} error={error}>
            <EmptyContent data={data} text="Not Found">
                {!!data.length && (
                    <div className="container">
                        <div className="row">
                            <div className="col-xs-12 col-lg-6">
                                <Details clientId={clientId} portfolioId={portfolioId} currency={portfolioDetail?.currency ?? 'CHF'}/>
                            </div>
                            <div className="col-xs-12 col-lg-6">
                                <Qr
                                    portfolioTitle={portfolioTitle}
                                    portfolioId={portfolioId}
                                />
                            </div>
                        </div>
                    </div>
                )}
            </EmptyContent>
        </Preloader>
    );
};

export default Deposit;
