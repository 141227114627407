import { getEngagementContentUrl } from '../../setup';
import Service from './service';

class EngagementSchema extends Service {
    static postAll(data) {
        return this.postRequest(`${getEngagementContentUrl()}/schema/all`, data, { isSecure: true });
    }
}

export default EngagementSchema;
