import React from 'react';
import PropTypes from 'prop-types';
import { createUniqueKey } from '../../../utils';

function ChartLabels({ labels }) {
    return (
        <div className="chart-axis-labels">
            {labels.map((item, index) => (
                <div key={createUniqueKey(index, item)} className="chart-label">
                    <div>{item}</div>
                </div>
            ))}
        </div>
    );
}

ChartLabels.propTypes = {
    labels: PropTypes.arrayOf(PropTypes.oneOfType([
        PropTypes.string, PropTypes.node])),
};

ChartLabels.defaultProps = {
    labels: [],
};

export default ChartLabels;
