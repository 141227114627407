import React from 'react';
import PropTypes from 'prop-types';
import FileItem from './FileItem';
import './FilesList.css';


const FilesList = ({
    files = [], className, onRemove,
}) => {
    const handleDelete = (removedFile) => {
        const newFileList = files.filter((file) => file.uid !== removedFile.uid);

        onRemove({ file: removedFile, fileList: newFileList });
    };


    return (
        <div className={`FilesList ${className}`}>
            {files.length > 0 && (
                <div className="upload-list">
                    {files.map((file) => (
                        <FileItem key={file.uid} onDelete={() => handleDelete(file)}>
                            {file.name}
                        </FileItem>
                    ))}
                </div>
            )}
        </div>
    );
};

FilesList.propTypes = {
    files: PropTypes.arrayOf(PropTypes.shape({
        uid: PropTypes.oneOfType(
            [PropTypes.number, PropTypes.string],
        ),
        name: PropTypes.string,
    })),
    className: PropTypes.string,
    onRemove: PropTypes.func,
};
FilesList.defaultProps = {
    files: [],
    className: '',
    onRemove: () => {
    },
};
export default FilesList;
