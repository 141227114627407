import Service from 'additiv-services';
import { getQueryStringByObject } from 'additiv-services/utils/queryUtils';
import {CUSTOM_SERVICE_LAYER_SERVICES, getCustomServiceLayerBaseUrl} from '../constants';

class CustomService extends Service {
    static #baseServiceUrl = getCustomServiceLayerBaseUrl(CUSTOM_SERVICE_LAYER_SERVICES.DEFAULT);

    static getClientNotes({contactId, pageSize, pageNumber}) {
        return Service.getRequest(`${this.#baseServiceUrl}contactNote/GetContactNotes?contactId=${contactId}&pageSize=${pageSize}&pageNumber=${pageNumber}`, {isSecure: true});
    }

    static saveClientNote(payload) {
        return Service.postRequest(`${this.#baseServiceUrl}contactNote/InsertContactNote`, payload, {isSecure: true});
    }

    static searchPbNumbers({filter}) {
        return Service.getRequest(`${this.#baseServiceUrl}lookup/pbnumbers?filter=${filter}`, {isSecure: true});
    }

    static getOccupationsLookup(params) {
        const queryString = getQueryStringByObject(params);

        return Service.getRequest(
            `${this.#baseServiceUrl}lookup/occupations?${queryString}`,
            {isSecure: true, cache: true},
        );
    }

    static heartBeat({identifier}) {
        return Service.getRequest(`${this.#baseServiceUrl}heart-beat?guidIdentifier=${identifier}`, {isSecure: true});
    }

    static validateIban({iban}) {
        return Service.getRequest(`${this.#baseServiceUrl}/validateIban?iban=${iban}`, {isSecure: true});
    }
}
export default CustomService;
