import React from 'react';
import PropTypes from 'prop-types';
import { NavLink as RouterLink } from 'react-router-dom';
import { getClassNames } from '../../utils';
import withTheme from '../../hocs/withTheme';
import './Navigation.css';

function Link({
    children,
    hasChanges,
    homeLink,
    externalURL,
    ...rest
}) {
    const selectNavigationClassName = getClassNames(
        'Navigation__link',
        { Navigation__link_changed: hasChanges },
        { Navigation__link_home: homeLink },
    );

    return externalURL ?
        (
            <a href={rest.to.pathname} target={rest.target}
               className={selectNavigationClassName}>{children}</a>
        ):(
            <RouterLink
                {...rest}
                activeClassName="Navigation__link-active"
                className={selectNavigationClassName}
            >
                {children}
            </RouterLink>
        );
}

Link.propTypes = {
    /** The path to page */
    to: PropTypes.string,
    /** Indicate link */
    hasChanges: PropTypes.bool,
    /** @ignore */
    children: PropTypes.node,
    /** Marks that is the home link */
    homeLink: PropTypes.bool,
    /** Marks that is External URL link */
    externalURL: PropTypes.bool,
};

Link.defaultProps = {
    to: '/',
    hasChanges: false,
    children: null,
    homeLink: false,
    externalURL: false,
};

export default withTheme(Link);
