import {formatDate} from '../../../../../../../../utils/datetime';

export const getModel = (data) => ({
    brokerId: data.productExternalId,
    categoryId: data.categoryId,
    message: data.message,
    planNumber: data.lpzPlanId,
    firstName: data.firstName,
    lastName: data.lastName,
    customerNumber: data.lpzClientId,
    language: String(data.language),
    dateOfBirth: formatDate(data.dateOfBirth, 'DD.MM.YYYY'),
    street: data.primaryAddressLine3,
    additionalAddress1: data.additionalAddress1,
    additionalAddress2: data.additionalAddress2,
    postalCode: data.primaryAddressLine4,
    city: data.primaryAddressLine5,
    countryOfNationality: String(data.countryOfResidence),
    mobile: data.mobileNumber,
    email: data.email,
});
