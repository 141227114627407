import React from 'react';
import { ProductOfferSelection } from '../product-offer-selection';
import { PRODUCTS } from '../../constants/constants';

import './product-offers-selection.css';

type ProductOffersSelectionType = {
    productOffers: Array<ProductType>,
    onSelectedProductOfferChange: (value: number) => void,
    selectProductOfferId: number,
    isFzAndFzpAlreadyApplied: boolean
}

export function ProductOffersSelection({
    productOffers,
    onSelectedProductOfferChange,
    selectProductOfferId,
    isFzAndFzpAlreadyApplied,
}: ProductOffersSelectionType) {
    return (
        <div className="fi-products-selection">
            {productOffers.map(productOffer => (
                <ProductOfferSelection
                    key={productOffer.id}
                    disabled={!productOffer.isEnabled || (isFzAndFzpAlreadyApplied && [PRODUCTS.fzp, PRODUCTS.fz].includes(productOffer?.settings?.productKey?.toLowerCase()))}
                    productOffer={productOffer}
                    isSelected={selectProductOfferId === productOffer.id}
                    onSelectionChange={onSelectedProductOfferChange}
                />
            ))}
        </div>
    );
}
