import PropTypes from 'prop-types';
import React, {useMemo} from 'react';
import {Loader} from 'ui-library';
import './LoadingOverlap.css';

export const LoadingOverlap = ({
    isLoading,
    children
}) => {
    const className = useMemo(() => (isLoading ? 'loading-overlap' : null), [isLoading]);

    return (
        <div className={className}>
            {children}
            {isLoading && <div className="overlap-loader"><Loader/></div>}
        </div>
    );
};

LoadingOverlap.propTypes = {
    isLoading: PropTypes.bool,
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.array,
    ]).isRequired,
};

LoadingOverlap.defaultProps = {
    isLoading: false,
};
