import {formatDate} from '../../../../../../utils/datetime';

export const getModelByZKP = (outputData) => {
    let streetAndNumber = '';

    if (outputData?.street) {
        streetAndNumber = outputData?.street;
        if (outputData?.streetNumber) {
            streetAndNumber = `${outputData?.street} ${outputData?.streetNumber}`;
        }
    }

    return {
        gender: outputData?.gender,
        // titleInput: null,
        firstName: outputData?.firstName,
        lastName: outputData?.lastName,
        dateOfBirth: outputData?.birthDate ? formatDate(outputData?.birthDate, 'DD.MM.YYYY') : null,
        countryOfResidence: outputData?.countryOfResidence ? String(outputData?.countryOfResidence) : null,
        street: streetAndNumber,
        number: outputData?.streetNumber,
        PLZ: outputData?.postCode,
        ORT: outputData?.city,
        maritalStatus: String(outputData?.civilStatus),
        countryOfNationality: outputData?.countryOfNationality ? String(outputData?.countryOfNationality) : null,
        language: outputData?.language ? String(outputData?.language) : null,
        showSecondaryAdress: undefined,
        land: undefined,
        primaryPhoneNumber: outputData?.mobileNumber,
        primaryEmail: outputData?.email,
        // countryOfResidence: String(outputData?.countryOfResidence),
        isUsPerson: undefined,
        taxInAnotherCountry: undefined,
        usCitizen: undefined,
        hideDatePickerValue: false,
    };
};
