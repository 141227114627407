import { getFIdentityUrl } from '../../setup';
import Service from './service';
import { ServiceTypes } from '../../types/main';

class FIdentity extends Service {
    static authentication(data) {
        return this.postRequest(`${getFIdentityUrl()}/authentication`, data, { type: ServiceTypes.FIDENTITY });
    }

    static postFidentity(data) {
        return this.postRequest(`${getFIdentityUrl()}/fidentity`, data, { type: ServiceTypes.FIDENTITY });
    }

    static getFidentityProcess(id) {
        return this.getRequest(`${getFIdentityUrl()}/fidentity/${id}`, { type: ServiceTypes.FIDENTITY });
    }
}

export default FIdentity;
