import Service from 'additiv-services';
import { getQueryStringByObject } from 'additiv-services/utils/queryUtils';
import {CUSTOM_SERVICE_LAYER_SERVICES, getCustomServiceLayerBaseUrl} from '../constants';

class CaseManagementService extends Service {
    static #baseServiceUrl = getCustomServiceLayerBaseUrl(CUSTOM_SERVICE_LAYER_SERVICES.CASE_MANAGEMENT);

    static getCaseLists(language) {
        const queryString = getQueryStringByObject({language: language || 'de'});

        return Service.getRequest(`${this.#baseServiceUrl}/cases?${queryString}`, { isSecure: true });
    }

    static getDataCase(CaseId, language) {
        const queryString = getQueryStringByObject({language: language || 'de', caseId: CaseId});

        return Service.getRequest(`${this.#baseServiceUrl}/case-details?${queryString}`, { isSecure: true });
    }

    static sendDataCase = ({data, caseId, language}) => Service.postRequest(`${this.#baseServiceUrl}/Save/${caseId}/${language || 'de'}`, data, { isSecure: true })

    static getIndividualOrderTypes() {
        return Service.getRequest(`${this.#baseServiceUrl}/individualOrder/OrderTypes`, { isSecure: true});
    }

    static getIndividualCategories(OrderType, brokerId) {
        const queryString = getQueryStringByObject({brokerId, OrderType});

        return Service.getRequest(`${this.#baseServiceUrl}/individualOrder/categories?${queryString}`, { isSecure: true});
    }

    static getIndividualDocumentCategories(categoryType) {
        const queryString = getQueryStringByObject({categoryType: String(categoryType)});

        return Service.getRequest(`${this.#baseServiceUrl}/individualorder/documentCategories?${queryString}`, { isSecure: true});
    }

    static getBrokers = () => Service.getRequest(`${this.#baseServiceUrl}/brokers`, {isSecure: true})

    static postIndividualCreate = (data) => Service.postRequest(`${this.#baseServiceUrl}/individualorder/create`, data, {isSecure: true})

    static postIndividualDocumentUpload = (data) => Service.postRequest(
        `${this.#baseServiceUrl}/individualorder/document-upload`,
        data,
        { isSecure: true},
    )

    static postSubmitOrder = (data) => Service.postRequest(`${this.#baseServiceUrl}/individualorder/Submit`, data, {isSecure: true})

    static postIndividualDocumentDownload = (data) => Service.postRequest(`${this.#baseServiceUrl}/individualorder/document-download`, data, {isSecure: true})

    static getOrders = (params) => {
        const queryString = getQueryStringByObject({...params});

        return Service.getRequest(`${this.#baseServiceUrl}/orders?${queryString}`, {isSecure: true});
    }
}
export default CaseManagementService;
