import React from 'react';
import PropTypes from 'prop-types';
import '../Footer.css';
import withTheme from '../../../hocs/withTheme';

function FooterNav({ children }) {
    return (
        <div className="Footer__nav">
            {children}
        </div>
    );
}

FooterNav.propTypes = {
    /** @ignore */
    children: PropTypes.node,
};

FooterNav.defaultProps = {
    children: null,
};

export default withTheme(FooterNav);
