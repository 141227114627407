import { useCallback, useState } from 'react';
import ServiceManager from 'services/ServiceManager';
import HandlerError from 'errors/HandlerError';
import ServerError from 'errors/ServerError';
import handlerRequestCanceling from 'utils/handlerRequestCanceling';

export const useChangeProfilePicture = () => {
    const [error, setError] = useState(null);
    const [isLoading, setLoading] = useState(false);

    const changeMemberProfilePicture = useCallback(async (memberId, payload) => {
        try {
            setError(null);
            setLoading(true);

            const { data } = await ServiceManager.customMembersService('updateMemberProfilePicture', [{memberId, payload}]);

            setLoading(false);

            return data;
        } catch (err) {
            handlerRequestCanceling(
                HandlerError({ setError, setLoading }),
            )(err);
        }
    }, []);

    const deleteMemberProfilePicture = useCallback(async (memberId) => {
        try {
            setError(null);
            setLoading(true);

            const { data } = await ServiceManager.memberManagement('deleteMemberPicture', [memberId]);

            setLoading(false);

            return data;
        } catch (e) {
            const err = e.type !== undefined ? e : new ServerError(e);

            if (err?.error?.response?.status === 404) {
                setLoading(false);
            } else {
                handlerRequestCanceling(
                    HandlerError({ setError, setLoading }),
                )(e);
            }

            return err;
        }
    }, []);

    return {
        error,
        isLoading,
        changeMemberProfilePicture,
        deleteMemberProfilePicture,
    };
};
