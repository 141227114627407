import React from 'react';
import PropTypes from 'prop-types';
import './FormControlMessage.css';
import withTheme from '../../hocs/withTheme';

function FormControlMessage({ hidden, children }) {
    return (
        <p
            className="form-control-message"
            hidden={hidden}
        >
            {children}
        </p>
    );
}

FormControlMessage.propTypes = {
    hidden: PropTypes.bool,
    children: PropTypes.node,
};

FormControlMessage.defaultProps = {
    hidden: false,
    children: null,
};

export default withTheme(FormControlMessage);
