import * as React from 'react';
import classNames from 'classnames';
import RCPicker from 'rc-picker';
import enUS from '../locale/en_US';
import { getPlaceholder } from '../util';
import devWarning from '../_util/devWarning';
import Icon, { ICON_TYPE_CALENDAR, ICON_TYPE_CLOSE } from '../../../Icon';
import PickerButton from '../PickerButton';

const Components = { button: PickerButton };

function toArray(list) {
    if (!list) {
        return [];
    }

    return Array.isArray(list) ? list : [list];
}

function getTimeProps(props) {
    const {
        format, picker, showHour, showMinute, showSecond, use12Hours,
    } = props;
    const firstFormat = toArray(format)[0];
    const showTimeObj = Object.assign({}, props);

    if (firstFormat) {
        if (!firstFormat.includes('s') && showSecond === undefined) {
            showTimeObj.showSecond = false;
        }
        if (!firstFormat.includes('m') && showMinute === undefined) {
            showTimeObj.showMinute = false;
        }
        if (!firstFormat.includes('H') && !firstFormat.includes('h') && showHour === undefined) {
            showTimeObj.showHour = false;
        }
        if ((firstFormat.includes('a') || firstFormat.includes('A')) && use12Hours === undefined) {
            showTimeObj.use12Hours = true;
        }
    }
    if (picker === 'time') {
        return showTimeObj;
    }


    return {
        showTime: showTimeObj,
    };
}

export default function generatePicker(generateConfig) {
    function getPicker(picker, displayName) {
        class Picker extends React.Component {
            constructor(props) {
                super(props);
                this.pickerRef = React.createRef();
                this.focus = () => {
                    if (this.pickerRef.current) {
                        this.pickerRef.current.focus();
                    }
                };
                this.blur = () => {
                    if (this.pickerRef.current) {
                        this.pickerRef.current.blur();
                    }
                };
                this.getDefaultLocale = () => {
                    const { locale } = this.props;
                    const result = {
                        ...enUS,
                        ...locale,
                    };

                    result.lang = {
                        ...result.lang,
                        ...(locale || {}).lang,
                    };

                    return result;
                };
                this.renderPicker = (locale) => {
                    const getPrefixCls = (suffixCls) => (suffixCls ? `additiv-${suffixCls}` : 'additiv');
                    const { direction, getPopupContainer } = this.context;
                    const {
                        prefixCls: customizePrefixCls,
                        getPopupContainer: customizeGetPopupContainer,
                        className,
                        size: customizeSize,
                        bordered = true,
                        placeholder,
                        ...restProps
                    } = this.props;
                    const { format, showTime } = this.props;
                    const prefixCls = getPrefixCls('picker');
                    const additionalProps = {
                        showToday: true,
                    };
                    let additionalOverrideProps = {};

                    if (picker) {
                        additionalOverrideProps.picker = picker;
                    }
                    const mergedPicker = picker || this.props.picker;

                    additionalOverrideProps = {
                        ...additionalOverrideProps,
                        ...(showTime
                            ? getTimeProps(
                                { format, picker: mergedPicker, ...showTime },
                            )
                            : {}
                        ),
                        ...(mergedPicker === 'time'
                            ? getTimeProps({ format, ...this.props, picker: mergedPicker })
                            : {}),
                    };

                    const mergedSize = customizeSize;

                    return (
                        <RCPicker
                            ref={this.pickerRef}
                            placeholder={getPlaceholder(mergedPicker, locale, placeholder)}
                            suffixIcon={<Icon type={ICON_TYPE_CALENDAR} size={10} />}
                            allowClear={{clearIcon: <Icon type={ICON_TYPE_CLOSE} size={10} />}}
                            transitionName="slide-up"
                            {...additionalProps}
                            {...restProps}
                            {...additionalOverrideProps}
                            locale={locale.lang}
                            className={classNames(className, {
                                [`${prefixCls}-${mergedSize}`]: mergedSize,
                                [`${prefixCls}-borderless`]: !bordered,
                            })}
                            prefixCls={prefixCls}
                            getPopupContainer={customizeGetPopupContainer || getPopupContainer}
                            generateConfig={generateConfig}
                            prevIcon={<span className={`${prefixCls}-prev-icon`} />}
                            nextIcon={<span className={`${prefixCls}-next-icon`} />}
                            superPrevIcon={<span className={`${prefixCls}-super-prev-icon`} />}
                            superNextIcon={<span className={`${prefixCls}-super-next-icon`} />}
                            components={Components}
                            direction={direction}
                        />
                    );
                };
                devWarning(picker !== 'quarter', displayName, `DatePicker.${displayName} is legacy usage. Please use DatePicker[picker='${picker}'] directly.`);
            }

            render() {
                const locale = this.getDefaultLocale();

                return this.renderPicker(locale);
            }
        }
        if (displayName) {
            Picker.displayName = displayName;
        }


        return Picker;
    }
    const DatePicker = getPicker();
    const WeekPicker = getPicker('week', 'WeekPicker');
    const MonthPicker = getPicker('month', 'MonthPicker');
    const YearPicker = getPicker('year', 'YearPicker');
    const QuarterPicker = getPicker('quarter', 'QuarterPicker');


    return {
        DatePicker, WeekPicker, MonthPicker, YearPicker, QuarterPicker,
    };
}
