import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import ChangeStrategyRouter from './pages/ChangeStrategy/Router';
import { usePortfolioActionsLayout } from './hooks/usePortfolioActionsLayout';
import DynamicPage from './pages/DynamicPage/DynamicPage';
import { Success } from './pages/DynamicPage/page/Success';

const PortfolioActionsRouter = () => {
    usePortfolioActionsLayout();

    return (
        <Routes>
            <Route path="change-strategy/*" element={<ChangeStrategyRouter/>} />
            <Route path="case-management/" element={<DynamicPage/>} />
            <Route path="case-management/success" element={<Success/>} />
            <Route path="case-management/:caseId" element={<DynamicPage/>} />
            <Route
                path=""
                element={<Navigate to="/404" replace />}
            />
        </Routes>
    );
};

export default PortfolioActionsRouter;
