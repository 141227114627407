import React from 'react';
import PropTypes from 'prop-types';
import ChartItem from './ChartItem';
import {
    adaptDataPieChart,
    buildSingleDataArrays, getSum,
} from './utils';
import { createUniqueKey } from '../../../utils';
import withTheme from '../../../hocs/withTheme';

import './PieChart.css';

function PieChartSA({ data, numberFormat, percentFormat }) {
    const sum = getSum(data.length >= 6 ? adaptDataPieChart(data) : data);

    return (
        <div className="PieChart">
            {buildSingleDataArrays(data.length >= 6
                ? adaptDataPieChart(data) : data, sum)
                .map((item, index) => (
                    <ChartItem
                        key={createUniqueKey(index, item.label)}
                        index={index}
                        data={item}
                        sum={sum}
                        numberFormat={numberFormat}
                        percentFormat={percentFormat}
                        singleAllocation
                    />
                ))}
        </div>
    );
}

PieChartSA.propTypes = {
    /** Data to be shown on chart */
    data: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.number.isRequired,
    })),
    numberFormat: PropTypes.func,
    percentFormat: PropTypes.func,
};

PieChartSA.defaultProps = {
    data: [],
    numberFormat: (num) => (num.toLocaleString('en-US', { minimumFractionDigits: 2 })),
    percentFormat: (num) => `${(num.toLocaleString('en-US', { maximumFractionDigits: 2 }))}`,
};

export default withTheme(PieChartSA);
