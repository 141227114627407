import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import AutoComplete from '../AutoComplete';
import Input from '../TextInput';
import Icon from '../Icon';
import Tags from '../Tags';
import {ICON_TYPE_SEARCH} from '../Icon/constants';
import {getClassNames} from '../../utils';
import withTheme from '../../hocs/withTheme';

const SearchBar = (props) => {
    const {
        data,
        selected: selectedProp,
        options: optionSource,
        placeholder,
        disableFilters,
        className: classNameProp,
        dropdownClassName,
        filtersText,
        onSearch,
        onSelect,
        isMobile,
        labelRemove,
        isDropdownOpen,
        labelReset,
    } = props;
    const [options, setOptions] = useState(optionSource || data || []);
    const [selected, setSelected] = useState(selectedProp || []);
    const [value, setValue] = useState('');

    useEffect(() => {
        setOptions(optionSource);
    }, [optionSource]);
    useEffect(() => {
        setOptions(data);
    }, [data]);
    useEffect(() => {
        setSelected(selectedProp);
    }, [selectedProp]);

    const onSearchAutoComplete = (val) => {
        setOptions(data);
        setValue(val);
        onSearch(val);
    };
    const onSelectAutoComplete = (val) => {
        if (selected.includes(val)) {
            setValue('');
        } else {
            setValue('');
            setSelected((oldValue) => [...oldValue, val]);
            onSelect([...selected, val]);
        }
    };
    const handleTags = (val) => {
        setSelected(val);
        onSelect(val);
    };
    const onInputKeyup = (e) => {
        if (e.key === 'Enter' || e.keyCode === 13) {
            if (value.length > 0) onSelectAutoComplete(value);
        }
    };
    const className = getClassNames('SearchBar', classNameProp);

    return (
        <div className={className}>
            <AutoComplete
                dropdownClassName={getClassNames('search-dropdown', dropdownClassName)}
                dataSource={options}
                value={value}
                placeholder={placeholder}
                filterOption
                onSearch={onSearchAutoComplete}
                onSelect={onSelectAutoComplete}
                isDropdownOpen={isDropdownOpen}
            >
                <Input
                    prefix={<Icon type={ICON_TYPE_SEARCH} className="search-icon"/>}
                    onKeyUp={onInputKeyup}
                />
            </AutoComplete>

            {(selected.length > 0 && !disableFilters) && (
                <Tags
                    data={selected}
                    title={`${filtersText}:`}
                    onChange={handleTags}
                    isMobile={isMobile}
                    labelRemove={labelRemove}
                    labelReset={labelReset}
                />
            )}
        </div>
    );
};

SearchBar.propTypes = {
    /** Filter tags list */
    data: PropTypes.arrayOf(PropTypes.string),
    /** Custom class to search */
    className: PropTypes.string,
    /** Placeholder text */
    placeholder: PropTypes.string,
    /** list of options to show in dropdown list */
    options: PropTypes.arrayOf(PropTypes.string),
    /** trigger on selected value from the search list function(value) */
    onSelect: PropTypes.func,
    /** triger on change function(value) */
    onSearch: PropTypes.func,
    /** whether the filters are hidden */
    disableFilters: PropTypes.bool,
    /** the dropdown custom class */
    dropdownClassName: PropTypes.string,
    /** trigger dropdown list */
    isDropdownOpen: PropTypes.bool,
    /** the translation for filters */
    filtersText: PropTypes.string,
    /** check mobile version */
    isMobile: PropTypes.bool,
    /** selected values */
    selected: PropTypes.arrayOf(PropTypes.string),
    /** label for tags remove */
    labelRemove: PropTypes.string,
    labelReset: PropTypes.string,
};

SearchBar.defaultProps = {
    data: [],
    options: null,
    className: null,
    placeholder: 'Start typing...',
    onSelect: () => {
    },
    onSearch: () => {
    },
    disableFilters: false,
    dropdownClassName: undefined,
    isDropdownOpen: false,
    filtersText: 'Filters',
    labelReset: 'Reset filters',
    isMobile: false,
    selected: [],
    labelRemove: null,
};

export default withTheme(SearchBar);
