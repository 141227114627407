import React, {
    forwardRef, useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import OutsideClickHandler from 'react-outside-click-handler';
import debounce from 'lodash/debounce';
import FormControlMessage from '../../FormControlMessage';
import FormGroup from '../../FormGroup';
import Label from '../../Label';
import OptionList from './OptionList';
import SelectButton from '../SelectButton';
import {optionsList, optionType} from '../../../types';
import {
    filterOptions,
    getOptionsWithoutSelected,
    getSelectedOptionsTitles,
    getSelectedValue,
    getValueByIndex,
} from '../utils';
import withTheme from '../../../hocs/withTheme';
import uid from '../../../utils/uid';
import {getDataOrAriaProps} from '../../../../utils';
import Loader from '../../Loader';

/** If the space allows it use an error message below the select
 * input else make use of the red error indicator with tooltip. */

let buffer: Array<any> = [];
let lastKeyTime = Date.now();

const Select = (props) => {
    const {
        label,
        options,
        width,
        disabled,
        required,
        error,
        withInfobox,
        placeholder,
        errorInTooltip,
        children,
        hasSearch,
        helpText,
        onChange,
        value,
        asyncSearch,
        searchInProgress,
        hasMoreItems,
        loadMoreItems,
        innerRef: ref,
        className,
        ...rest
    } = props;

    const [focused, setFocused] = useState(false);
    const [opened, setOpened] = useState(false);
    const [selectedOption, setSelectedOption] = useState(getSelectedValue(value));
    const [filteredOptions, setFilteredOptions] = useState(options);
    const [inputValue, setInputValue] = useState(getSelectedValue(value));
    const [searchValue, setSearchValue] = useState('');
    const [currentPage, setCurrentPage] = useState(0);
    const loader = useRef(null);

    const [activeIndex, setActiveIndex] = useState(-1);

    const uId = useMemo(() => uid('results'), []);

    useEffect(() => {
        if (!isEqual(value, inputValue)) {
            setInputValue(getSelectedValue(value));
            setSelectedOption(getSelectedValue(value));
        }
    }, [value, inputValue]);

    useEffect(() => {
        setFilteredOptions(options);
    }, [options]);

    const listRef = useRef<any>(null);

    useEffect(() => {
        if (listRef.current) {
            const {scrollTop, offsetHeight: height} = listRef.current;
            const scrollBottom = scrollTop + height;
            const item = listRef.current.children[activeIndex];
            const heightItem = item && item.offsetHeight;
            const topItem = item && item.offsetTop;

            if (topItem > scrollBottom - heightItem) {
                listRef.current.scrollTo({top: topItem - scrollBottom + scrollTop + heightItem});
            }
            if (topItem < scrollTop) {
                listRef.current.scrollTo({top: topItem});
            }
        }
    }, [activeIndex]);

    const handleAsyncSearch = (value) => {
        setCurrentPage(0);
        asyncSearch(value);
    };

    const handleAsyncSearchDebounced = useCallback(debounce(handleAsyncSearch, 500), [asyncSearch]);

    const handleObserver = useCallback((entries) => {
        const target = entries[0];

        if (target.isIntersecting) {
            setCurrentPage((prev) => prev + 1);
        }
    }, []);

    useEffect(() => {
        if (loadMoreItems) {
            loadMoreItems(currentPage, searchValue);
        }
    }, [currentPage]);

    useEffect(() => {
        const option = {
            root: null,
            threshold: 0.1,
        };
        const observer = new IntersectionObserver(handleObserver, option);

        if (loader.current) {
            observer.observe(loader.current);
        }
    }, [handleObserver]);

    const handleClickOutside = () => {
        setOpened(false);
        setActiveIndex(-1);
        setSearchValue('');
        setFilteredOptions(options);
    };

    const handleFocus = useCallback(() => setFocused(!focused), [focused]);

    const handleDropdown = useCallback((e) => {
        if (e?.detail !== 0 || e === 'toggleItem') {
            setOpened(!opened);
            setActiveIndex(-1);
        }
    }, [opened]);

    const selectItem = (option) => {
        onChange(option);
        setSelectedOption(option);
    };
    const toggleItem = (option) => {
        selectItem(option);
        handleDropdown('toggleItem');
        setSearchValue('');
        setFilteredOptions(options);
    };
    const onFilterOptions = useCallback(({target: {value}}) => {
        setSearchValue(value);

        if (asyncSearch) {
            handleAsyncSearchDebounced(value);
        } else if (!value) {
            setFilteredOptions(options);
            setActiveIndex(-1);
            selectItem(null);
        } else {
            const filtered = filterOptions(options, value);

            setFilteredOptions(filtered);
            setActiveIndex(0);
            selectItem(getValueByIndex(filtered, 0));
        }
    }, [filteredOptions, options, selectItem, asyncSearch]);

    const findOption = useCallback((key) => {
        if (!hasSearch) {
            const currentTime = Date.now();
            const bufferSameLetter = key === buffer[0];

            if (bufferSameLetter || currentTime - lastKeyTime > 1000) {
                buffer = [];
            }

            buffer.push(key);

            lastKeyTime = currentTime;

            const value = buffer?.join('')?.toLocaleLowerCase();
            const filteredFromBuffer = filteredOptions
                ?.map((i, idx) => ({label: i?.label || i, idx}))
                ?.filter((i) => i?.label?.toLocaleLowerCase().startsWith(value));
            const activeFromBuffer = filteredFromBuffer
                ?.findIndex((i) => i?.idx === activeIndex);
            const newActiveOptionIdx = activeFromBuffer + 1 === filteredFromBuffer.length
                ? 0 : activeFromBuffer + 1;
            const active = filteredFromBuffer[newActiveOptionIdx]?.idx;
            const option = getValueByIndex(filteredOptions, active);

            if (option !== undefined) {
                setActiveIndex(active);
                selectItem(option);
            }
        }
    }, [filteredOptions, setActiveIndex, selectItem, hasSearch, activeIndex]);

    const openAndSelectCurrent = useCallback(() => {
        if (!disabled) {
            setOpened(true);
            if (selectedOption) {
                setActiveIndex(
                    filteredOptions.findIndex((i) => ((i?.value
                        ? i?.value : i) === selectedOption)),
                );
            }
        }
    }, [selectedOption, filteredOptions, disabled]);

    const onKeyDown = useCallback((e) => {
        if (typeof children?.props?.onKeyDown === 'function') {
            // eslint-disable-next-line no-unused-expressions
            children?.props?.onKeyDown(e);
        }

        if (e.key === 'Escape') {
            e.preventDefault();
            selectItem(null);
            setOpened(false);
            setActiveIndex(-1);

            return;
        }

        if (filteredOptions.length < 1) return;

        /* Space bar */
        if (e.keyCode === 32) {
            if (!opened) {
                openAndSelectCurrent();
            }
        }

        let option;

        const preventDefault = (e) => {
            e.preventDefault();
        };

        switch (e.key) {
            case 'ArrowUp':
                if (!opened) {
                    openAndSelectCurrent();
                } else if (activeIndex > 0) {
                    setActiveIndex(activeIndex - 1);
                    selectItem(getValueByIndex(filteredOptions, activeIndex - 1));
                }
                break;
            case 'ArrowDown':
                if (!opened) {
                    openAndSelectCurrent();
                } else if (activeIndex < filteredOptions.length - 1) {
                    setActiveIndex(activeIndex + 1);
                    selectItem(getValueByIndex(filteredOptions, activeIndex + 1));
                }
                break;
            case 'Enter':
                if (!opened) {
                    openAndSelectCurrent();
                } else {
                    option = getValueByIndex(filteredOptions, activeIndex);

                    if (option) toggleItem(option);
                }

                break;
            case 'Tab':
                if (activeIndex >= 0) {
                    selectItem(getValueByIndex(filteredOptions, activeIndex));
                }
                setOpened(false);
                setActiveIndex(-1);

                return;
            case 'Home':
                selectItem(getValueByIndex(filteredOptions, 0));
                setActiveIndex(0);
                preventDefault(e);

                // eslint-disable-next-line consistent-return
                return false;
            case 'End':
                selectItem(getValueByIndex(filteredOptions, filteredOptions.length - 1));
                setActiveIndex(filteredOptions.length - 1);
                preventDefault(e);

                // eslint-disable-next-line consistent-return
                return false;
            default:
                if (!hasSearch) {
                    findOption(e.key);

                    return;
                }

                return;
        }
        e.preventDefault();
    }, [filteredOptions, hasSearch, activeIndex, setActiveIndex, setSelectedOption, findOption]);

    const selected = useMemo(() => getValueByIndex(filteredOptions, activeIndex),
        [filteredOptions, activeIndex]);

    const inputEl = useRef<any>(null);

    useEffect(() => {
        if (opened) {
            if (inputEl.current) {
                inputEl.current.focus();
            }
        }
    }, [opened]);

    const optionsListItems = useMemo(
        () => getOptionsWithoutSelected(filteredOptions, selectedOption),
        [filteredOptions, selectedOption],
    );

    return (
        <FormGroup
            focused={focused}
            opened={opened}
            disabled={disabled}
            error={!!error}
            className={className}
        >
            {label
                && <Label htmlFor="select-dropdown-options" label={label} labelInfobox={withInfobox} required={required} helpText={helpText}/>}
            <div
                className="select"
                style={{width}}
                ref={ref}
            >
                <OutsideClickHandler
                    onOutsideClick={handleClickOutside}
                >
                    {/*@ts-ignore*/}
                    <SelectButton
                        ariaControlName="multiselectFilter-dropdown"
                        role="button"
                        id="select-dropdown"
                        className="select-dropdown-button"
                        ariaHaspopup="listbox"
                        isSingleSelect
                        selectedOptions={
                            selectedOption !== null
                                ? getSelectedOptionsTitles(options, [selectedOption])
                                : placeholder
                        }
                        opened={opened}
                        error={error}
                        errorInTooltip={errorInTooltip}
                        onFocus={handleFocus}
                        onBlur={handleFocus}
                        onClick={handleDropdown}
                        onKeyDown={onKeyDown}
                        {...getDataOrAriaProps(rest)}

                    />
                    <div className="select__dropdown__wrapper">
                        <div className="select__dropdown" id="select-dropdown">
                            {hasSearch && (
                                <input
                                    className="select__search"
                                    type="search"
                                    aria-label="Type to filter available options"
                                    value={searchValue}
                                    onChange={onFilterOptions}
                                    ref={inputEl}
                                    onKeyDown={onKeyDown}
                                />
                            )}
                            <div id="select-dropdown-options" className="select__dropdown-options">
                                <div id={uId} role="listbox" ref={listRef} tabIndex={-1}>
                                    <OptionList
                                        name={`${uId}-option`}
                                        options={optionsListItems}
                                        selected={selected}
                                        clicked={(option) => toggleItem(option)}
                                        searchInProgress={searchInProgress}
                                    />

                                    <div ref={loader} style={{display: hasMoreItems && !searchInProgress ? 'initial' : 'none'}}>
                                        <Loader />
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </OutsideClickHandler>
            </div>
            {error && !errorInTooltip && (
                <FormControlMessage>{error}</FormControlMessage>
            )}
            {children && <p>{children}</p>}
        </FormGroup>
    );
};


Select.propTypes = {
    /** The label text */
    label: PropTypes.string,
    /** If label infobox is visible */
    withInfobox: PropTypes.bool,
    /** If select has search field */
    hasSearch: PropTypes.bool,
    /** Placeholder text */
    placeholder: PropTypes.string,
    /** Option list  */
    options: optionsList,
    /** Custom width size in percent or pixels  */
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /** The error message displayed if the input is errored */
    error: PropTypes.string,
    /** If the error message should be displayed in tooltip */
    errorInTooltip: PropTypes.bool,
    /** @ignore */
    disabled: PropTypes.bool,
    /** If required field* */
    required: PropTypes.bool,
    /** @ignore */
    children: PropTypes.node,
    /** Initial option for select */
    value: PropTypes.oneOfType([PropTypes.string, optionType]),
    /** Callback function */
    onChange: PropTypes.func,
    /** The text displayed if the label tooltip exist */
    helpText: PropTypes.bool,

    /** @ignore */
    innerRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({
            // @ts-ignore
            current: PropTypes.objectOf,
        }),
    ]),
    searchInProgress: PropTypes.bool,
    asyncSearch: PropTypes.func,
};

Select.defaultProps = {
    label: null,
    withInfobox: true,
    hasSearch: false,
    placeholder: ' ',
    options: null,
    width: null,
    error: null,
    errorInTooltip: false,
    disabled: false,
    required: false,
    children: null,
    value: null,
    onChange: () => {
    },
    helpText: null,
    innerRef: null,
    searchInProgress: false,
    asyncSearch: null,
};

export default forwardRef((props: {[p: string]: any}, ref) => withTheme(Select)({...props, innerRef: ref}));
