export const adaptAbisory = (data, t) => ([
    { label: t('portfolio.createNewPortfolio.agentInformation.advisor.firstName'), value: data?.FirstName },
    { label: t('portfolio.createNewPortfolio.agentInformation.advisor.lastName'), value: data?.LastName },
    { label: t('portfolio.createNewPortfolio.agentInformation.advisor.street'), value: '' },
    { label: t('portfolio.createNewPortfolio.agentInformation.advisor.postOfficeBox'), value: '' },
    { label: t('portfolio.createNewPortfolio.agentInformation.advisor.location'), value: '' },
    { label: t('portfolio.createNewPortfolio.agentInformation.advisor.phone'), value: data?.Phone },
    { label: t('portfolio.createNewPortfolio.agentInformation.advisor.email'), value: data?.Email },
    { label: t('portfolio.createNewPortfolio.agentInformation.advisor.vstNumber'), value: '' },
]);
