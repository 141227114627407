import React, { useMemo } from 'react';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import {Button} from 'ui-library';
import ButtonsBlockRow from 'components/ButtonsBlockRow';
import {getClassNames} from 'utils';
import { InfoErrorList } from 'components/InfoErrorList/InfoErrorList';

import './PlanWizardLayout.css';

export const PlanWizardLayout = ({
    stepNavBarActive, children, onBack, onContinue,
    continueLabel, disabled, steps, withRisk, withProductGroupName,
    clientName, clientAge, risk, pageErrors, backLabel, onAdditionalComponentClick,
    backDisabled, inProgress, jaClientName, jaClientAge, productOfferGroupName,
    additionalComponentLabel,
}) => {
    const { t } = useTranslation();
    const userName = useMemo(() => (stepNavBarActive ? (clientName || '') : ''), [stepNavBarActive, clientName]);
    const userAge = useMemo(() => (stepNavBarActive ? (clientAge || '') : ''), [stepNavBarActive, clientAge]);
    const jaUserName = useMemo(() => (stepNavBarActive ? (jaClientName || '') : ''), [stepNavBarActive, jaClientName]);
    const jaUserAge = useMemo(() => (stepNavBarActive ? (jaClientAge || '') : ''), [stepNavBarActive, jaClientAge]);

    const Item = ({ number, name }) => (
        <li
            className={getClassNames(
                number === stepNavBarActive ? 'active' : '',
                (number === stepNavBarActive - 1 || number === stepNavBarActive + 1) ? 'with-active' : '',
            )}
        >
            {number === stepNavBarActive ? <span className="dot"/> : null}
            <span>{number}</span>
            <span>{t(name)}</span>
        </li>
    );

    return (
        <div className="fi-layout">
            <div className="fi-steps">
                <div className="container">
                    <ul className="fi-nav">
                        {steps.map((value) => (
                            <Item key={value.name} number={value.number} name={value.name}/>
                        ))}
                    </ul>
                </div>
            </div>
            <div className="fi-body">
                <div className="container">
                    {children}
                </div>
            </div>

            <div className="fi-footer">
                <InfoErrorList errors={pageErrors} />
                <div className="container">
                    <div className="fi-footer-data">
                        <div className="fi-footer-client-details">
                            <div className="fi-footer-client-details__block fi-footer-client-details__block--name">
                                <div className="fi-footer-client-details__label">
                                    {t(
                                        'portfolio.createNewPortfolio.clientName',
                                    )}
                                </div>
                                <div className="fi-footer-client-details__value" data-cy="layout_username">{userName}</div>
                                { jaUserName && (
                                    <div
                                        className="fi-footer-client-details__value"
                                        data-cy="layout_username"
                                    >
                                        {jaUserName}
                                    </div>
                                )}
                            </div>
                            <div className="fi-footer-client-details__block fi-footer-client-details__block--age">
                                <div className="fi-footer-client-details_label">{t('portfolio.createNewPortfolio.clientAge')}</div>
                                <div className="fi-footer-client-details__value" data-cy="layout_user_age">{userAge}</div>
                                {jaUserAge && (
                                    <div
                                        className="fi-footer-client-details__value"
                                        data-cy="layout_user_age"
                                    >
                                        {jaUserAge}
                                    </div>
                                )}
                            </div>
                            {productOfferGroupName && withProductGroupName && (
                                <div className="fi-footer-client-details__block fi-footer-client-details__block--age">
                                    <div className="fi-footer-client-details_label">{t('portfolio.createNewPortfolio.productOffer')}</div>
                                    <div className="fi-footer-client-details__value" data-cy="layout_product_offer_group">{productOfferGroupName}</div>
                                </div>
                            )}
                            {risk && withRisk && (
                                <div className="fi-footer-client-details__block fi-footer-client-details__block--age">
                                    <div className="fi-footer-client-details_label">{t('portfolio.changeStrategy.InvestmentStrategy')}</div>
                                    <div className="fi-footer-client-details__value" data-cy="layout_risk">{risk}</div>
                                </div>
                            )}
                        </div>
                        <ButtonsBlockRow
                            additionalButton={{
                                text: backLabel || t('portfolio.back'),
                                onClick: onBack,
                                disabled: backDisabled,
                            }}
                            additionalComponent={onAdditionalComponentClick && <Button type="secondary" disabled={disabled} onClick={onAdditionalComponentClick}>{additionalComponentLabel}</Button>}
                            primaryButton={{
                                text: continueLabel || t('portfolio.continue'),
                                onClick: onContinue,
                                disabled,
                                loading: inProgress,
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

PlanWizardLayout.propTypes = {
    pageErrors: PropTypes.arrayOf(
        PropTypes.string || PropTypes.element,
    ),
    risk: PropTypes.string,
    withRisk: PropTypes.bool,
    backDisabled: PropTypes.bool,
    productOfferGroupName: PropTypes.string,
};

PlanWizardLayout.defaultProps = {
    pageErrors: [],
    risk: undefined,
    withRisk: false,
    backDisabled: false,
    productOfferGroupName: undefined,
};
