import React from 'react';
import PropTypes from 'prop-types';
import { getClassNames } from '../../utils';
import './FormGroup.css';
import withTheme from '../../hocs/withTheme';

function FormGroup({
    focused, opened, disabled, error, children, className,
}) {
    const formGroupClassName = getClassNames(
        'form-group',
        className,
        { 'form-group_focused': focused },
        { 'form-group_opened': opened },
        { 'form-group_disabled': disabled },
        { 'form-group_invalid': error },
    );

    return (
        <div className={formGroupClassName}>
            {children}
        </div>
    );
}

FormGroup.propTypes = {
    disabled: PropTypes.bool,
    focused: PropTypes.bool,
    opened: PropTypes.bool,
    error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    children: PropTypes.node,
    className: PropTypes.string,
};

FormGroup.defaultProps = {
    disabled: false,
    focused: false,
    opened: false,
    error: false,
    children: null,
    className: undefined,
};

export default withTheme(FormGroup);
