import React from 'react';
import './TableFilter.css';

function TableFilter(props) {
    return (
        <div className="TableFilter">
            {props.children}
        </div>
    );
}

export default TableFilter;
