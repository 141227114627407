const NAMESPACE_DEFAULT = 'redux_sessionstorage';
const NAMESPACE_SEPARATOR_DEFAULT = '_';

export const load = ({ states }) => {
    const loadedState = {};

    states.forEach((value) => {
        loadedState[value] = {
            ...JSON.parse(
                sessionStorage.getItem(NAMESPACE_DEFAULT + NAMESPACE_SEPARATOR_DEFAULT + value),
            ),
        };
    });

    return loadedState;
};

export const save = ({ states }) => (store) => (next) => (action) => {
    const result = next(action);

    states.forEach((value) => {
        sessionStorage.setItem(
            NAMESPACE_DEFAULT + NAMESPACE_SEPARATOR_DEFAULT + value,
            JSON.stringify(store.getState()[value]),
        );
    });

    return result;
};
