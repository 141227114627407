import PortfoliosTable from 'components/Portfolios';
import { APPLICATION_CONTAINER_ID, QUALITY_CHECK_DATA } from 'constants/constants';
import { CreatePortfolioManager } from 'datasource/CreatePortfolio';
import { PortfolioProcessLayoutManager } from 'datasource/PortfolioProcessLayout';
import { CREATE_APP_CLIENT_ID } from 'pages/ClientOverview/constants';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getClientId } from 'utils';
import { StepperManager } from '@/core/stepper/StepperManager';
import { useClientProfile } from '../../../../prodivers/clientProfile';
import { useClientLayout } from '../../hooks/useClientLayout';
import { useLayout } from './hooks/useLayout';
import { useNewPortfolioData } from './pages/CreateNewPortfolio/hooks/useNewPortfolioData';
import './Portfolios.css';

function Portfolios({ dfsClientId }) {
    const navigate = useNavigate();
    const { clearNewPortfolio, clearModifiedPositions } = useNewPortfolioData();
    const { clearLayoutCache } = useLayout();

    useClientLayout();

    const {
        sectionedPortfolios, isOverviewLoading, setReFetchPortfolios,
    } = useClientProfile();

    useEffect(() => {
        setReFetchPortfolios(true);
    }, []);

    const onNewClick = () => navigate(`/client/${dfsClientId}/portfolios/risk-profile`);

    const onOpenNewPortfolio = () => {
        clearNewPortfolio();
        clearModifiedPositions();
        clearLayoutCache();
        sessionStorage.setItem(CREATE_APP_CLIENT_ID, getClientId(dfsClientId));
        navigate(`/client/${dfsClientId}/portfolios/new-portfolio`);
    };

    const onOpenNewPortfolio2 = () => {
        sessionStorage.removeItem(StepperManager.LOCAL_STORAGE_KEY);
        sessionStorage.removeItem(CreatePortfolioManager.LOCAL_STORAGE_KEY);
        sessionStorage.removeItem(PortfolioProcessLayoutManager.LOCAL_STORAGE_KEY);
        sessionStorage.removeItem(APPLICATION_CONTAINER_ID);
        sessionStorage.removeItem(QUALITY_CHECK_DATA);
        navigate(`/client/${dfsClientId}/portfolios/new-portfolio2`);
    };

    return (
        <PortfoliosTable
            isLoading={isOverviewLoading}
            data={sectionedPortfolios}
            onNewClick={onNewClick}
            onOpenNewPortfolio={onOpenNewPortfolio}
            onOpenNewPortfolio2={onOpenNewPortfolio2}
        />
    );
}

export default Portfolios;
