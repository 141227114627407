export const createUniqueKey = (index, label) => index + encodeURIComponent(label);
export const createObjUniqueKey = (data) => Object
    .values(data)
    .map((i) => (typeof i === 'object' ? Object.values(i).join() : i))
    .join();

export const getClassNames = (...args) => args
    .map((arg) => ((arg && typeof arg === 'object') ? Object.keys(arg).filter((item) => arg[item]) : arg))
    .reduce((acc, val) => acc.concat(val), [])
    .filter((item) => item).join(' ');

export const transformOptions = (options) => (
    options.map((option) => (
        (typeof option === 'string') ? { label: option, value: option } : option
    ))
);

export const formatNumber = (n) => n.replace(/\D/g, '');

const matches = (element, selector) => {
    if (element.matches) return element.matches(selector);

    return element.msMatchesSelector(selector);
};

export const closest = (el, selector, rootNode) => {
    let element = el;

    while (element) {
        const isRoot = element === rootNode || element === document.body;

        if (isRoot || (element.nodeType === 1 && matches(element, selector))) {
            if (isRoot) {
                element = null;
            }
            break;
        }
        element = element.parentNode;
    }


    return element;
};

export const getElements = (index, selector, rootNode) => rootNode
    .querySelectorAll(`${selector}:nth-child(${index + 1})`);

export const getDomIndex = (el) => Array.from(el.parentNode.children).indexOf(el);

export const capitalize = (str) => str.charAt(0).toUpperCase() + str.substr(1);
