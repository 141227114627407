import React, {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {getDataOrAriaProps} from '../../../utils';
import Error from './Error';

import './styles/ButtonRadioElement.css';

const ButtonRadioElement = (props) => {
    const {t} = useTranslation();

    if (props.items === undefined || props.items === null || props.items[0]?.id === undefined) {
        return null;
    }

    const [state, setState] = useState(props.value);
    const [list, setList] = useState(props.items);

    useEffect(() => {
        setList(props.items);
    }, [props.items]);

    useEffect(() => {
        setState(props.value);
    }, [props.value]);

    const handleChange = (id) => {
        props.passData(id, props.nameData);

        setState(id);
    };

    const isItemDeactivated = (id) => {
        if (id === state) {
            return 'buttonRadioElement buttonRadioElementNotSelected';
        }

        return 'buttonRadioElement';
    };

    const renderUndertext = useCallback((id) => {
        if (props.showUndertext) {
            if (id === props.recommendedId) {
                return <span>{props.undertext}</span>;
            }
            if (String(props.value) === String(id)) {
                return <span>{t('selected')}</span>;
            }
        }

        return null;
    }, [props.showUndertext, props.undertext, props.recommendedId, props.value]);

    return (
        <>
            <fieldset
                className={`form-group ${props.error ? 'errorButton' : ''}`}
                {...getDataOrAriaProps(props)}
            >
                {props.label && (
                    <>
                        <legend className="Form-control-label">
                            {props.label}
                            {' '}
                            {props.required && <span> *</span>}
                        </legend>
                        {props.description && <p style={{marginBottom: '10px'}}>{props.description}</p>}
                    </>
                )}
                <div className="form-group-controls" data-cy={props?.dataAttrCy}>
                    {list?.map((item) => (
                        <div key={item?.id}>
                            <button
                                key={props?.label + item?.id}
                                className={isItemDeactivated(item?.id)}
                                name={item?.label}
                                value={item?.id}
                                data-recommended={props.recommendedId === item?.id}
                                type="button"
                                onClick={() => handleChange(item?.id)}
                                disabled={props.disabled}
                            >
                                {item?.label || 'N/A'}
                            </button>
                            {renderUndertext(item?.id)}
                        </div>
                    ))}
                </div>
                {props.error && <Error>{props.error}</Error>}
            </fieldset>
        </>
    );
};

export default ButtonRadioElement;
