import {
    useCallback, useEffect, useRef,
} from 'react';

export const useInterval = (callback, delay) => {
    const savedCallback = useRef(null);
    const intervalId = useRef(null);

    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    const clearCustomInterval = useCallback(() => {
        clearInterval(intervalId.current);
    }, []);

    const tick = useCallback(() => {
        savedCallback.current(clearCustomInterval);
    }, [callback, clearCustomInterval]);

    useEffect(() => {
        if (delay !== null) {
            const id = setInterval(tick, delay);

            intervalId.current = id;

            return () => {
                clearInterval(id);
            };
        }
    }, [tick, delay]);
};
