import React from 'react';
import Icon, { ICON_TYPE_CLOSE } from '../../Icon';

const CloseIcon = () => (
    <Icon
        type={ICON_TYPE_CLOSE}
        className="Notification__close_icon"
    />
);

export default CloseIcon;
