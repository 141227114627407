export const getLegendTooltip = (key, t) => {
    switch (key) {
        case 'realEstate':

            return t('RiskBandwidthComparison.securityAllocationChart.legendTooltip.realState');

        case 'liquidity':

            return t('RiskBandwidthComparison.securityAllocationChart.legendTooltip.liquidity');

        default:
            return '';
    }
};
