export const getValFromHTML = (str, i18n) => {
    const en = str.match(/<en>(.*?)<\/en>/g)?.map((val) => val.replace(/<\/?en>/g, ''));
    const fr = str.match(/<fr>(.*?)<\/fr>/g)?.map((val) => val.replace(/<\/?fr>/g, ''));
    const it = str.match(/<it>(.*?)<\/it>/g)?.map((val) => val.replace(/<\/?it>/g, ''));
    const de = str.match(/<de>(.*?)<\/de>/g)?.map((val) => val.replace(/<\/?de>/g, ''));

    if (i18n.language === 'en') {
        return en || de || fr || it;
    } if (i18n.language === 'fr') {
        return fr || en || de || it;
    } if (i18n.language === 'it') {
        return it || en || de || fr;
    } if (i18n.language === 'de') {
        return de || en || fr || it;
    }

    return undefined;
};
