import {useOptionList} from 'hooks/useOptionList';
import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {OPTION_LIST} from 'constants/constants';
import PersonalDetailsForm from './PersonalDetailsForm';
import Preloader from '../../components/Preloader';
import {PersonalDetailsDSType} from './PersonalDetails.types';
import {ConnectionAddressWidget} from './ConnectionAddressWidget';

import './PersonalDetails.css';

type TPersonalDetailsFormProps = {
    datasource: () => PersonalDetailsDSType
}

const PersonalDetails = ({datasource}: TPersonalDetailsFormProps) => {
    const {t, i18n: {language}} = useTranslation();

    const option = useMemo(() => ([
        {label: t('clientDashboard.yes'), id: true},
        {label: t('clientDashboard.no'), id: false},
    ]), [language]);

    const radioButtonGroupValues = useMemo(() => ([
        {
            label: t('onboardingFlow.personalPage.pensionScheme'),
            value: 'true',
            disabled: false,
            error: false,
        }, {
            label: t('onboardingFlow.personalPage.accountHolder'),
            value: 'false',
            disabled: false,
            error: false,
        },
    ]), [language]);

    const {
        lists: [optionGender, optionCountry],
    } = useOptionList([OPTION_LIST.gender, OPTION_LIST.country]);

    const {
        client,
        jointClient,
        isJA,
        isPageLoading,
        contactGroupId,
    } = datasource();

    return (
        <Preloader isLoading={isPageLoading}>
            <div className="new-portfolio2 personal-details newStyle">
                <div className="row">
                    <div className={isJA ? 'col-lg-6' : 'col-lg-12'}>
                        <PersonalDetailsForm
                            optionCountry={optionCountry}
                            optionGender={optionGender}
                            radioButtonGroupValues={radioButtonGroupValues}
                            option={option}
                            isJA={isJA}
                            {...client}
                        />
                    </div>
                    {
                        isJA && (
                            <div className="col-lg-6">
                                <PersonalDetailsForm
                                    optionCountry={optionCountry}
                                    optionGender={optionGender}
                                    radioButtonGroupValues={radioButtonGroupValues}
                                    option={option}
                                    isJA={isJA}
                                    {...jointClient}
                                />
                            </div>
                        )
                    }
                    {isJA && contactGroupId && (
                        <div className="col-lg-12">
                            <ConnectionAddressWidget
                                contactGroupId={contactGroupId}
                            />
                        </div>
                    )}
                </div>
            </div>

        </Preloader>
    );
};

export default PersonalDetails;
