import React, {
    createContext, useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { useSelector } from 'react-redux';
import { useGetClientInfo } from './hooks/useGetClientInfo';
import ServiceManager from '../../services/ServiceManager';
import { memberIdSelector } from '../../redux-store/auth/authSelectors';
import { useWithInvestmentOverview } from '../../pages/ClientOverview/pages/Dashboard/hooks/useWithInvestmentOverview';
import { genarateDFSClientId } from '../../utils';
import { ACTIVE } from '../../constants/portfolioStatuses';

export const CLIENT_NAME = 'CLIENT_NAME';
const CLIENT_IS_PROSPECT = 'CLIENT_IS_PROSPECT';

export const clearClient = () => {
    sessionStorage.removeItem(CLIENT_NAME);
    sessionStorage.removeItem(CLIENT_IS_PROSPECT);
};

type TClientProfileContext = {
    portfolios?: Array<any>
    sectionedPortfolios?: any,
    updateClientInfo?: () => void
    isDataAccessible?: boolean,
    hasActivePortfolio?: boolean,
    overviewData?: any,
    portfolioNumber?: number
    isOverviewLoading?: boolean,
    getInvestmentOverview?: () => void
    clientId?: number
    dfsClientId?: string
    clientName?: string,
    isProspect?: boolean
    setReFetchPortfolios?: (value) => void
}

type TClientProfileProvider = {
    clientId: number,
    clientName: string,
    isProspect: boolean,
    withAdditionalInfo: boolean,
    children: JSX.Element
}

const ClientProfileContext = createContext<TClientProfileContext>({});
const ClientProfileProvider = (props: TClientProfileProvider) => {
    const {
        clientId,
        children,
        clientName: clientNameProp,
        isProspect: isProspectProp,
        withAdditionalInfo,
    } = props;
    const clientInfo = useGetClientInfo({ clientId, withAdditionalInfo });
    const { current: clientNameState } = useRef(clientNameProp);
    const memberId = useSelector(memberIdSelector);
    const [isDataAccessible, setIsDataAccessible] = useState(false);
    const [hasActivePortfolio, setHasActivePortfolio] = useState(false);
    const [reFetchPortfolios, setReFetchPortfolios] = useState<boolean | null>(null);

    const dfsClientId = genarateDFSClientId(clientId);

    const {
        data: overviewData, isLoading: isOverviewLoading, error: overviewError, getInvestmentOverview,
    } = useWithInvestmentOverview({
        clientId, dfsClientId, isDataAccessible, reFetchPortfolios,
    });

    useEffect(() => {
        if (overviewData?.portfolios) {
            setHasActivePortfolio(
                overviewData?.portfolios?.find((item) => (
                    item?.Status?.value === ACTIVE
                )) !== undefined,
            );
        } else {
            setHasActivePortfolio(false);
        }
        setReFetchPortfolios(false);
    }, [overviewData?.portfolios]);

    const clientNameCached = useMemo(() => {
        if (sessionStorage.getItem(CLIENT_NAME) !== clientInfo.data.Name) {
            clearClient();
            sessionStorage.setItem(CLIENT_NAME, clientInfo.data.Name);
        }

        return sessionStorage.getItem(CLIENT_NAME);
    }, [clientInfo.data.Name]);

    const clientName = useMemo(() => (
        clientNameCached
            || clientNameState
            || clientInfo.data.Name
            || ''),
    [clientNameCached, clientNameState, clientInfo.data.Name]);

    useEffect(() => {
        if (clientName?.length) sessionStorage.setItem(CLIENT_NAME, clientName);
    }, [clientName]);

    const { current: isProspectState } = useRef(isProspectProp);
    const isProspectCached = sessionStorage.getItem(CLIENT_IS_PROSPECT) === 'true';
    const isProspect = useMemo(() => isProspectCached || isProspectState,
        [isProspectCached, isProspectState]);

    useEffect(() => {
        if (typeof isProspect === 'boolean') sessionStorage.setItem(CLIENT_IS_PROSPECT, String(isProspect));
    }, [isProspect]);

    useEffect(() => () => clearClient(), []);

    useEffect(() => {
        (async () => {
            try {
                const { data } = await ServiceManager.customMembersService('checkMemberAccessOnClientData', [{
                    contactId: clientId,
                    memberId,
                }]);

                setIsDataAccessible(data);
            } catch (err) {
                console.error(err);
            }
        })();
    }, [clientId]);

    const updateClientInfo = useCallback(async () => {
        if (typeof clientInfo.refetch === 'function') {
            await clientInfo.refetch();
        }
    }, [clientInfo?.refetch]);

    return (
        <ClientProfileContext.Provider
            value={{
                clientId,
                dfsClientId,
                clientName,
                isProspect,
                ...clientInfo,
                updateClientInfo,
                isDataAccessible,
                overviewData: overviewData.overview,
                portfolioNumber: overviewData?.portfolios?.length,
                isOverviewLoading,
                hasActivePortfolio,
                portfolios: overviewData.portfolios,
                sectionedPortfolios: overviewData.sectionedPortfolios,
                getInvestmentOverview,
                setReFetchPortfolios,
            }}
        >
            {children}
        </ClientProfileContext.Provider>
    );
};

export {
    ClientProfileContext,
    ClientProfileProvider,
};
