import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {
    generalAgencySelector, portfolioSelector, usePortfolioSelector,
} from 'domain/Portfolio';
import { PRODUCTS } from 'constants/constants';
import EmptyContent from 'components/EmptyContent';
import Preloader from 'components/Preloader';
import {getClientId} from 'utils';
import {useBenchmarks} from 'hooks/useBenchmarks';
import Card from 'components/Card/Card';
import {isEmpty} from 'lodash';
import {useParams} from 'react-router-dom';
import moment from 'moment';
import RiskScale from '../../../../components/RiskScale/RiskScale';
import OverviewChart from './components/OverviewChart/OverviewChart';
import SumBarChart from './components/SumBarChart/SumBarChart';
import { useSinglePortfolio } from '../../context';
import { usePositionsWithIndividualIndicator } from '../../hooks/usePositionsWithIndividualIndicator';
import FzDepotInformationKpi from '../Details/components/DepotInformation/FzDepotInformationKpi';
import {usePerformanceAndRiskCompare} from '../../../../../../../../hooks/usePerformanceAndRiskCompare';
import {Icon} from '../../../../../../../../ui-library';
import {useClientProfile} from '../../../../../../../../prodivers/clientProfile';
import {joinWithSeparator} from '../Details/utils';
import type {TOverviewData} from './components/OverviewChart/OverviewChart';

import './Overview.css';

const Overview = () => {
    const {t} = useTranslation();

    const { dfsClientId, portfolioId } = useParams();

    const {
        dataRaw, data, isLoading, error,
    } = usePortfolioSelector(
        portfolioSelector,
    );

    const {data: generalAgencyData} = usePortfolioSelector(generalAgencySelector);

    const {portfolios} = useClientProfile();

    const {
        strategyName,
        riskCategories,
        getSelectStrategy,
        selectedProductSettings,
        migrationDate,
        isPk,
    } = useSinglePortfolio();
    const selectedPortfolio = useMemo(() => portfolios?.find(f => f.Id === parseInt(portfolioId!, 10)), [portfolios, portfolioId]);

    const clientId = useMemo(() => (getClientId(dfsClientId)), [dfsClientId]);

    const {
        isLoadingBenchmark,
    } = useBenchmarks(data, isLoading, true);

    const {
        performanceAndRisk, performanceSelected, onPerformanceChange, displayOptionSelected, onDisplayOptionChange,
    } = usePerformanceAndRiskCompare({
        clientId,
        portfolio: data,
        strategy: undefined,
        currencyId: data.currencyId,
        benchmark: undefined,
        isLoading: isLoadingBenchmark,
        additionalOptions: {
            ...{usePortfolioPerformance: true},
            isEdit: false,
        },
    });
    const analysisData = useMemo(() => (
        {...performanceAndRisk}
    ), [performanceAndRisk]);

    const isIndividualPortfolio = useMemo(() => (!!dataRaw?.PortfolioProposalId), [dataRaw]);

    const {
        positions,
    } = usePositionsWithIndividualIndicator({
        positionsRaw: dataRaw?.Positions,
    });

    return (
        <Preloader isLoading={isLoading || isLoadingBenchmark} error={error}>
            <EmptyContent data={data} text="Not Found">
                {data && (
                    <div className="container">
                        <div className="OverviewGrid">
                            <div className="OverviewGrid__Col">
                                {!isPk && (
                                    <OverviewChart
                                        data={analysisData as TOverviewData}
                                        onFilterChange={onPerformanceChange}
                                        performanceSelected={performanceSelected}
                                        displayOptionSelected={displayOptionSelected}
                                        onDisplayOptionChange={onDisplayOptionChange}
                                        isLoading={isLoading}
                                        error={error}
                                    />
                                )}

                                <Card title={t('shadowAccount.depot.title')}>
                                    <FzDepotInformationKpi portfolioId={portfolioId} />
                                </Card>
                                <Card title={t('portfolioDetail.overview.contact')}>
                                    <div className="contact-details">
                                        <div className="label">{t('portfolioDetail.overview.advisor')}</div>
                                        <div>
                                            {!isEmpty(generalAgencyData) ? (
                                                <>
                                                    <div>{joinWithSeparator(' ', generalAgencyData.agentInformation?.firstName, generalAgencyData?.agentInformation?.lastName)}</div>
                                                    <div>{generalAgencyData?.agentInformation?.address || ''}</div>
                                                    <div>{joinWithSeparator(' ', generalAgencyData?.agentInformation?.postalCode, generalAgencyData?.agentInformation?.city)}</div>
                                                    <div className="contact">
                                                        <div>
                                                            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                                                            {/* @ts-ignore */}
                                                            <Icon type="phone-call" />
                                                            {generalAgencyData?.agentInformation?.phone || '-'}
                                                        </div>
                                                        <div>
                                                            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                                                            {/* @ts-ignore */}
                                                            <Icon type="envelope" />
                                                            {generalAgencyData?.agentInformation?.email || '-'}
                                                        </div>
                                                    </div>
                                                </>
                                            ) : '-'}
                                        </div>
                                        <div className="label">{t('portfolioDetail.overview.agency')}</div>
                                        <div>
                                            <div>{generalAgencyData?.agencyInformation?.name}</div>
                                            <div>{generalAgencyData?.agencyInformation?.address}</div>
                                            <div>{joinWithSeparator(' ', generalAgencyData?.agencyInformation?.postalCode, generalAgencyData?.agencyInformation?.city)}</div>
                                            <div className="contact">
                                                {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                                                {/* @ts-ignore */}
                                                <Icon type="phone-call" />
                                                {generalAgencyData?.agencyInformation?.phone}
                                            </div>
                                        </div>
                                    </div>
                                </Card>
                            </div>
                            <div className="OverviewGrid__Col">
                                <Card title={t('portfolioDetail.overview.keyIndices')}>
                                    <dl className="Overview__Summary">
                                        <div>
                                            <dt className="Overview__Summary__Key">
                                                {t('portfolioDetail.overview.saldo')}
                                                <span className="Overview__Summary__KeyInfo">{` ${t('portfolioDetail.overview.inCHF')}`}</span>
                                            </dt>
                                            <dd className="Overview__Summary__Value">
                                                <div className="text-right">
                                                    {selectedPortfolio?.ValueWithoutCurrency}
                                                </div>
                                            </dd>
                                        </div>
                                        {!isPk && (
                                            <>
                                                <div>
                                                    <dt className="Overview__Summary__Key">
                                                        {t('portfolioDetail.overview.rendite')}
                                                        <span className="Overview__Summary__KeyInfo">{` ${t('portfolioDetail.overview.renditeSince', {category: '%', date: data?.customAttributes?.ReturnStartDate})}`}</span>
                                                    </dt>
                                                    <dd className="Overview__Summary__Value">
                                                        <div className="text-right">{`${(parseFloat(data?.customAttributes?.PerformanceTWR || 0) * 100).toFixed(2)}%`}</div>
                                                    </dd>
                                                </div>
                                                {migrationDate && (
                                                    <div>
                                                        <dt className="Overview__Summary__Key">
                                                            {t('portfolioDetail.overview.rendite')}
                                                            <span className="Overview__Summary__KeyInfo">{` ${t('portfolioDetail.overview.renditeSince', {category: 'CHF', date: migrationDate})}`}</span>
                                                        </dt>
                                                        <dd className="Overview__Summary__Value">
                                                            <div className="text-right">{data?.customAttributes?.AbsoluteReturnPostGoLive}</div>
                                                        </dd>
                                                    </div>
                                                )}
                                                <div>
                                                    <dt className="Overview__Summary__Key">
                                                        {t('portfolioDetail.overview.rendite')}
                                                        <span className="Overview__Summary__KeyInfo">
                                                            {` ${t('portfolioDetail.overview.renditeSince', {category: 'CHF', date: data?.customAttributes?.CreateDate})}`}
                                                        </span>
                                                    </dt>
                                                    <dd className="Overview__Summary__Value">
                                                        <div className="text-right">{data?.customAttributes?.AbsoluteReturn}</div>
                                                    </dd>
                                                </div>
                                            </>
                                        )}
                                    </dl>
                                </Card>

                                {!isPk && (
                                    <>
                                        <Card title={t('portfolioDetail.overview.strategy')}>
                                            <dl className="Overview__Summary Overview__Summary--half">
                                                <div>
                                                    <dt className="Overview__Summary__Key">{isIndividualPortfolio ? t('strategy.individual') : strategyName }</dt>
                                                    {!isIndividualPortfolio && (
                                                        <dd className="Overview__Summary__Value">
                                                            <div>
                                                                <RiskScale scales={riskCategories} activeIndex={getSelectStrategy()}/>
                                                            </div>
                                                        </dd>
                                                    )}
                                                </div>
                                            </dl>

                                            <h3 className="Card__SectionTitle">{t('portfolio.details.strategy.positionTitle')}</h3>
                                            <dl className="Overview__Summary Overview__Summary--3cols">
                                                {
                                                    positions.map(({name, marketValue, allocation}) => (
                                                        <div>
                                                            <dt className="Overview__Summary__Key">{name}</dt>
                                                            <dd className="Overview__Summary__Value Overview__Summary__Value--small">
                                                                <div className="text-right">{marketValue}</div>
                                                            </dd>
                                                            <dd className="Overview__Summary__Value">
                                                                <div className="text-right">{allocation}</div>
                                                            </dd>
                                                        </div>
                                                    ))
                                                }
                                            </dl>
                                        </Card>
                                        {
                                            selectedProductSettings?.showDeposits && (
                                                <Card title={t('portfolio.details.deposits')} titleLink="deposit">
                                                    <SumBarChart
                                                        currency={dataRaw?.Currency?.CurrencyCode ?? 'CHF'}
                                                        portfolioId={portfolioId}
                                                        clientId={clientId}
                                                    />
                                                </Card>
                                            )
                                        }
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </EmptyContent>
        </Preloader>
    );
};

export default Overview;
