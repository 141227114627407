import {
    CellLink, CellWithMoreActions, CellPerformance,
} from 'components/renderers';

export const COUNT_OF_PAGE = 10;
export const selectType = 'Select';
export const selectMultipleType = 'SelectMultiple';
export const fieldTypes = [selectType, selectMultipleType];
export const tableColumnsClient = ({ t }) => [
    {
        dataPath: 'id',
        hidden: true,
        sortable: false,
        key: 'internalId',
        render: CellLink,
    },
    {
        dataPath: 'relationshipInformation.externalId',
        hidden: false,
        sortable: false,
        key: 'externalId',
    },
    {
        dataPath: 'personalInformation.displayName',
        hidden: false,
        sortable: false,
        filterable: false,
        key: 'name',
    },
    {
        dataPath: 'personalInformation.language',
        hidden: true,
        sortable: false,
        filterable: false,
        key: 'language',
    },
    {
        dataPath: 'personalInformation.ahvNo',
        hidden: true,
        sortable: false,
        key: 'ahvNo',
    },
    {
        dataPath: 'personalInformation.identification.countryOfNationality',
        key: 'nationality',
        sortable: false,
        filterable: false,
        hidden: true,
    },
    {
        dataPath: 'communicationMethods.primaryAddress.primaryAddressLine3',
        hidden: false,
        sortable: false,
        key: 'addressLineThree',
    },
    {
        dataPath: 'communicationMethods.primaryAddress.primaryAddressLine4',
        hidden: false,
        sortable: false,
        key: 'postcode',
    },
    {
        dataPath: 'communicationMethods.primaryAddress.primaryAddressLine5',
        hidden: false,
        sortable: false,
        key: 'location',
    },
    {
        dataPath: 'communicationMethods.primaryAddressCountry',
        key: 'country',
        filterable: false,
        sortable: false,
        hidden: false,
    },
    {
        dataPath: 'personalInformation.dateOfBirth',
        filterable: false,
        hidden: false,
        sortable: false,
        key: 'dateOfBirth',
    },
    {
        dataPath: 'communicationMethods.primaryEmail',
        hidden: false,
        sortable: false,
        key: 'primaryEmail',
    },
    {
        dataPath: 'communicationMethods.primaryPhoneNumber',
        hidden: false,
        sortable: false,
        key: 'primaryPhoneNumber',
    },
    {
        dataPath: 'personalInformation.gender',
        hidden: true,
        sortable: false,
        key: 'gender',
    },
    {
        dataPath: [
            'additionalCommunicationMethods.additionalCommunicationChannelToUse.portal',
            'additionalCommunicationMethods.additionalCommunicationChannelToUse.email',
            'additionalCommunicationMethods.additionalCommunicationChannelToUse.paper',
        ],
        title: t('additionalCommunicationMethods.additionalCommunicationChannelToUse'),
        hidden: true,
        key: 'communication',
    },

    {
        key: 'actions',
        title: '',
        titleType: 'columns',
        width: 40,
        minWidth: 40,
        fixed: 'right',
        render: CellWithMoreActions,
    },
];

export const tableColumnsNewConnection = ({ t }) => [
    {
        key: 'select',
        width: 40,
        minWidth: 40,
        fixed: 'left',
        Icon: 'action',
        headerRenderer: () => null,
    },
    {
        dataPath: 'id',
        hidden: true,
        sortable: false,
        key: 'internalId',
        render: CellLink,
    },
    {
        dataPath: 'relationshipInformation.externalId',
        hidden: false,
        sortable: false,
        key: 'externalId',
    },
    {
        dataPath: 'personalInformation.displayName',
        hidden: false,
        sortable: false,
        filterable: false,
        key: 'name',
    },
    {
        dataPath: 'personalInformation.language',
        hidden: true,
        sortable: false,
        filterable: false,
        key: 'language',
    },
    {
        dataPath: 'personalInformation.ahvNo',
        hidden: true,
        sortable: false,
        key: 'ahvNo',
    },
    {
        dataPath: 'personalInformation.identification.countryOfNationality',
        key: 'nationality',
        sortable: false,
        filterable: false,
        hidden: true,
    },
    {
        dataPath: 'communicationMethods.primaryAddress.primaryAddressLine3',
        hidden: false,
        sortable: false,
        key: 'addressLineThree',
    },
    {
        dataPath: 'communicationMethods.primaryAddress.primaryAddressLine4',
        hidden: false,
        sortable: false,
        key: 'postcode',
    },
    {
        dataPath: 'communicationMethods.primaryAddress.primaryAddressLine5',
        hidden: false,
        sortable: false,
        key: 'location',
    },
    {
        dataPath: 'communicationMethods.primaryAddressCountry',
        key: 'country',
        filterable: false,
        sortable: false,
        hidden: false,
    },
    {
        dataPath: 'personalInformation.dateOfBirth',
        filterable: false,
        hidden: false,
        sortable: false,
        key: 'dateOfBirth',
    },
    {
        dataPath: 'communicationMethods.primaryEmail',
        hidden: false,
        sortable: false,
        key: 'primaryEmail',
    },
    {
        dataPath: 'communicationMethods.primaryPhoneNumber',
        hidden: false,
        sortable: false,
        key: 'primaryPhoneNumber',
    },
    {
        dataPath: 'personalInformation.gender',
        hidden: true,
        sortable: false,
        key: 'gender',
    },
    {
        dataPath: [
            'additionalCommunicationMethods.additionalCommunicationChannelToUse.portal',
            'additionalCommunicationMethods.additionalCommunicationChannelToUse.email',
            'additionalCommunicationMethods.additionalCommunicationChannelToUse.paper',
        ],
        title: t('additionalCommunicationMethods.additionalCommunicationChannelToUse'),
        hidden: true,
        key: 'communication',
    },
    {
        key: 'actions',
        title: '',
        titleType: 'columns',
        width: 40,
        minWidth: 40,
        fixed: 'right',
        render: CellWithMoreActions,
    },
];

export const tableColumnsProspect = () => [
    {
        dataPath: 'id',
        hidden: false,
        key: 'internalId',
        render: CellLink,
    },
    {
        dataPath: 'personalInformation.displayName',
        hidden: false,
        key: 'name',
    },
    {
        dataPath: 'relationshipInformation.contactType',
        hidden: true,
        key: 'type',
    },
    {
        dataPath: 'relationshipInformation.isProspect',
        key: 'status',
        hidden: false,
    },
    {
        dataPath: 'relationshipInformation.onboardingStage',
        key: 'onboardingStage',
        hidden: false,
    },
    {
        dataPath: 'personalInformation.identification.countryOfNationality',
        key: 'nationality',
        hidden: true,
    },
    {
        dataPath: 'communicationMethods.primaryAddressCountry',
        key: 'country',
        hidden: false,
    },
    {
        dataPath: 'taxSituation.isUsPerson',
        hidden: true,
        key: 'isUsPerson',
    },
    {
        dataPath: 'politicalExposure.isPoliticallyExposedPerson',
        hidden: false,
        key: 'pep',
    },
    {
        key: 'actions',
        title: '',
        titleType: 'columns',
        width: 40,
        minWidth: 40,
        fixed: 'right',
        render: CellWithMoreActions,
    },
];

export const data = [
    {
        internalID: 'DFS0000000001',
        externalID: 'CBS12345678',
        name: 'Conor Estrada',
        type: 'Private',
        product: 'Execution only',
        country: 'Switzerland',
        rm: 'Carlos Jakob',
        icon: 'action',
    },
    {
        internalID: 'DFS0000000001',
        externalID: 'CBS12345678',
        name: 'Fiona Figueroa',
        type: 'Private',
        product: 'Execution only',
        country: 'Swiss',
        rm: 'Sandra Weiler',
        icon: 'action',
    },
    {
        internalID: 'DFS0000000001',
        externalID: 'CBS12345678',
        name: 'Sandra Weller',
        type: 'Private',
        product: 'Execution only',
        country: 'Switzerland',
        rm: 'Carlo Del Sol',
        icon: 'action',
    },
    {
        internalID: 'DFS0000000001',
        externalID: 'CBS12345678',
        name: 'Adalyn Donovan',
        type: 'Private',
        product: 'Execution only',
        country: 'Democratic Republic of the Congo',
        rm: 'Bruce Banner',
        icon: 'action',
    },
    {
        internalID: 'DFS0000000001',
        externalID: 'CBS12345678',
        name: 'Presley Riley',
        type: 'Private',
        product: 'Execution only',
        country: 'Switzerland',
        rm: 'Carl Jackson',
        icon: 'action',
    },
    {
        internalID: 'DFS0000000001',
        externalID: 'CBS12345678',
        name: 'Easton Hodges',
        type: 'Private',
        product: 'Execution only',
        country: 'Russia',
        rm: 'Natasha Romanoff',
        icon: 'action',
    },
    {
        internalID: 'DFS0000000001',
        externalID: 'CBS12345678',
        name: 'Jamie Caldwell',
        type: 'Private',
        product: 'Execution only',
        country: 'Luxembourg',
        rm: 'Peter Parker',
        icon: 'action',
    },
    {
        internalID: 'DFS0000000001',
        externalID: 'CBS12345678',
        name: 'Gwendolyn Thomas',
        type: 'Private',
        product: 'Execution only',
        country: 'Denmark',
        rm: 'Peter Quill',
        icon: 'action',
    },
];

export const filters = data.map((item) => (
    Object.values(item)
))
    .reduce((a, b) => (
        a.concat(b)
    ), [])
    .filter((val, i, arr) => (
        arr.indexOf(val) === i && val !== ''
    ));
