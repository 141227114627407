import React, { useState } from 'react';
import './InvestmentApplicationItem.css';
import { Icon } from 'ui-library';
import {
    ICON_TYPE_DROPDOWN,
} from 'ui-library/components/Icon';
import { CustomTable } from 'components/CustomTable';
import { useTranslation } from 'react-i18next';
import Card from 'components/Card/Card';
import { columns } from './const';

interface InvestmentApplicationItemProps {
    productData: {
        product: string;
        strategy: string;
        collapseData: React.FC;
        createNewPlanData: React.FC;
        isJointAccount: boolean;
        owners: Record<string, string>;
    };
}

const InvestmentApplicationItem: React.FC<InvestmentApplicationItemProps> = ({ productData }) => {
    const { t } = useTranslation();
    const tableColumns = columns(t);

    const [isShowCollapse, setIsShowCollapse] = useState(false);

    const CollapseComponent = productData?.collapseData;
    const ActionsComponent = productData?.createNewPlanData;

    return (
        <Card style={{ marginTop: '20px' }}>
            <div className="InvestmentApplicationItem__container">
                <div className="InvestmentApplicationItem__header">
                    <p className="InvestmentApplicationItem__title">
                        <b>
                            {productData?.product}
                        </b>
                        {productData?.strategy && ` - ${productData?.strategy}`}
                    </p>
                </div>
                <p >
                    { productData?.isJointAccount && `${t('connections.groupName.ja')} | ${Object.values(productData?.owners).join(', ')}`}
                </p>
                <CustomTable
                    columns={tableColumns}
                    data={[productData] || []}
                    onChange={() => {}}
                />
                <div>
                    <button
                        type="button"
                        onClick={() => setIsShowCollapse(!isShowCollapse)}
                        className="InvestmentApplicationItem__documentsButton"
                    >
                        {/* eslint-disable-next-line */}
                        {/* @ts-ignore */}
                        <Icon type={ICON_TYPE_DROPDOWN} className={!isShowCollapse ? 'InvestmentApplicationItem__arrowIcon' : ''} />
                        {t('dashboard.overviewClientApplications.applicationDocuments')}
                        <span className="sr-only">{isShowCollapse ? 'collapse' : 'expand'}</span>
                    </button>
                </div>
                {isShowCollapse && (
                    <table className="InvestmentApplicationItem__documents">
                        <CollapseComponent />
                    </table>
                )}
                <div className="InvestmentApplicationItem__actions">
                    <ActionsComponent />
                </div>
            </div>
        </Card>
    );
};

export default InvestmentApplicationItem;
