import React, {
    forwardRef, useEffect, useMemo, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import AntDatePicker from './origin';
import { moment, momentWithCeilMinutes } from '../../utils/moment';
import FormGroup from '../FormGroup';
import FormControlMessage from '../FormControlMessage';
import Label from '../Label';
import Icon from '../Icon';
import { getClassNames } from '../../utils';
import { defaultDatePickerLocale } from './constants';
import { datePickerLocalePropTypes } from './propTypes';
import withTheme from '../../hocs/withTheme';
import './DatePicker.css';

/** A basic widget to work with date and time.
 *
 * DO Always use labels on text inputs and don’t depend on the placeholder
 * text. If there is more information for the text input needed make
 * use of a paragraph on top or use an info box. */

function DatePicker(props) {
    const {
        disabled, error, label, dropdownClassName,
        labelInfobox, required, use12Hours,
        children, format, defaultValue, value,
        onChange, labels, helpText, showTime, innerRef,
        labelInside, ...rest
    } = props;
    const initEmptyValue = () => (showTime === true ? momentWithCeilMinutes() : moment());
    const [valueInput, setValueInput] = useState(
        defaultValue
            ? moment(moment(defaultValue).format(format), format)
            : null,
    );
    const defaultValueInput = useMemo(() => (defaultValue
        ? moment(moment(defaultValue).format(format), format) : initEmptyValue()),
    [defaultValue, format]);
    const inputRef = useRef(null);

    const onChangeDate = (date, dateString) => {
        setValueInput(date);
        onChange(date, dateString);
    };

    useEffect(() => {
        if (value === null) setValueInput(null);
        else if (value && (typeof value === 'string')) {
            setValueInput(moment(value, format));
        }
    }, [value]);

    const dropdownClass = getClassNames(dropdownClassName, 'datepicker');

    const formGroupClass = getClassNames('datepicker-form-group', { 'with-label-inside': labelInside, 'with-label': label });

    return (
        <FormGroup disabled={disabled} error={error} className={formGroupClass}>
            {label && (
                <Label
                    htmlFor={label}
                    label={label}
                    labelInfobox={labelInfobox}
                    required={required}
                    helpText={helpText}
                />
            )}
            <AntDatePicker
                {...rest}
                showTime={showTime}
                dropdownAlign={{ offset: [0, 13] }}
                use12Hours={use12Hours}
                locale={labels}
                allowClear={false}
                id={label}
                ref={innerRef || inputRef}
                value={valueInput}
                format={format}
                className={error ? 'ant-input_invalid' : null}
                dropdownClassName={dropdownClass}
                suffixIcon={
                    <div><Icon type="calendar" /></div>
                }
                disabled={disabled}
                required={required}
                onChange={onChangeDate}
                defaultValue={defaultValueInput}
            />
            {labelInside && <span className="label-inside">{labelInside}</span>}
            {error && (
                <FormControlMessage>{error}</FormControlMessage>
            )}

            {children && <p>{children}</p>}
        </FormGroup>
    );
}

DatePicker.propTypes = {
    /** Define time format between 12h and 24h */
    use12Hours: PropTypes.bool,
    /** The label text */
    label: PropTypes.string,
    /** The text displayed if the label tooltip exist */
    labelInfobox: PropTypes.bool,
    /** The placeholder text */
    placeholder: PropTypes.string,
    /** @ignore */
    defaultValue: PropTypes.string,
    /** @ignore */
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.instanceOf(moment),
        PropTypes.instanceOf(Date),
    ]),
    /** @ignore */
    disabled: PropTypes.bool,
    /** If required field* */
    required: PropTypes.bool,
    /** The error message displayed if the input is errored */
    error: PropTypes.string,
    /** localization object */
    labels: datePickerLocalePropTypes,
    /** localization object */
    minuteStep: PropTypes.number,
    /** @ignore */
    children: PropTypes.node,
    /** @ignore */
    format: PropTypes.string,
    /** @ignore */
    onChange: PropTypes.func,
    /** @ignore */
    dropdownClassName: PropTypes.string,
    /** The text displayed if the label tooltip exist */
    helpText: PropTypes.bool,
    /** Define minutes metrics for time mode. Like: 5 minutes time step */
    showTime: PropTypes.bool,
    /** @ignore */
    innerRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({
            current: PropTypes.objectOf,
        }),
    ]),
    /** The label inside text */
    labelInside: PropTypes.string,
};

DatePicker.defaultProps = {
    use12Hours: false,
    label: null,
    labelInfobox: false,
    placeholder: null,
    defaultValue: undefined,
    value: null,
    disabled: false,
    required: false,
    error: null,
    minuteStep: 5,
    children: null,
    format: 'DD-MM-YYYY',
    onChange: () => {
    },
    dropdownClassName: undefined,
    labels: defaultDatePickerLocale,
    helpText: null,
    showTime: false,
    innerRef: null,
    labelInside: null,
};

export default forwardRef((props, ref) => withTheme(DatePicker)({ ...props, innerRef: ref }));
