import {
    useEffect, useMemo, useState, useCallback,
} from 'react';
import SM from 'services/ServiceManager';
import { useTranslation } from 'react-i18next';
import handlerRequestCanceling from 'utils/handlerRequestCanceling';
import HandlerError from 'errors/HandlerError';
import { useFormatting } from 'locale';
import { downloadDmsDocument } from 'utils/downloadDmsDocument';
import {uniq} from 'lodash';
import {adaptDocuments, adaptUnifiedDocuments, getNames} from '../adapters/adaptDocuments';
import { usePortfolios } from './usePortfolios';
import {genarateDFSClientId} from '../../../../../utils';
import {DOCUMENT_SOURCE} from '../constants';
import {DEFAULT_PAGE_SIZE} from '../../../../DocLibraryPage/constants/const';
import {getMimeTypeFromBase64File} from '../utils';

export const useDocuments = ({
    clientId, currentPagination, tab, businessUnitFilter, categoryFilter,
}) => {
    // TODO: do we really need different state objects for general and personal docs?
    const [error, setError] = useState(null);
    const [isLoading, setLoading] = useState(true);
    const [isGeneralLoading, setGeneralLoading] = useState(true);
    const [data, setData] = useState([]);
    const [cachedData, setCachedData] = useState([]);
    const [generalData, setGeneralData] = useState([]);
    const [names, setNames] = useState([]);
    const [generalNames, setGeneralNames] = useState([]);
    const { t, i18n } = useTranslation();
    const { getFormattedDate } = useFormatting();
    const { getPortfolios } = usePortfolios({ clientId });
    const [personalPaging, setPersonalPaging] = useState({});
    const [generalPaging, setGeneralPaging] = useState({});
    const [categories, setCategories] = useState(null);
    const [businessUnits, setBusinessUnits] = useState(null);

    const generalParams = useMemo(() => ({
        isActive: true,
        sortBy: 'CreateDate',
        sortOrder: 'Descending',
        page: currentPagination,
        pageSize: DEFAULT_PAGE_SIZE,
        searchBy: 'IncludeShared',
        documentTypeId: 2,
    }), [currentPagination]);

    const handleDownloadFile = useCallback((documentData) => {
        const {filename} = documentData;

        const {newBlob} = downloadDmsDocument(
            documentData,
            {
                name: filename,
                saveFile: true,
            },
        );
        const url = URL.createObjectURL(newBlob);

        global.open(url, '_blank');
    }, []);

    const downloadDocument = useCallback(async (documentId, source, customerId, filename, productId) => {
        try {
            setError(null);
            if (!source || source === DOCUMENT_SOURCE.dmsDocument) {
                const {data: documentData} = await SM.documents('getDocumentById', [documentId]);

                handleDownloadFile(documentData);
            } else if (source === DOCUMENT_SOURCE.lpzDocument) {
                const {data: documentData} = await SM.customDocumentsService('getDocumentPdf', [{DocumentId: documentId, CustomerId: customerId, ProductId: productId }]);

                handleDownloadFile({
                    file: documentData.pdf, mimeType: getMimeTypeFromBase64File(documentData.pdf), originalFilename: filename, filename,
                });
            }
        } catch (err) {
            handlerRequestCanceling(HandlerError({setError, setLoading}))(err);
        }
    }, []);

    const handleDocumentsData = useCallback(({
        setDocs, setDocNames, setPaging, docsData, page, totalCount,
    }) => {
        setDocs(docsData);
        setDocNames(getNames(docsData));
        setPaging({
            Page: page, PageSize: DEFAULT_PAGE_SIZE, TotalCount: totalCount,
        });
    }, []);

    const setFilterOptions = useCallback((docsData) => {
        setCategories(uniq(docsData.map(m => m.category)));
        setBusinessUnits(uniq(docsData.map(m => m.businessUnit)));
        setCachedData(docsData);
    }, []);

    const getDocuments = useCallback(async () => {
        try {
            setGeneralLoading(true);
            const {data: documentsData} = await SM.customDocumentsService('getUnifiedDocuments', [generalParams]);

            const userPortfolios = await getPortfolios({
                documents: documentsData,
            });

            const adaptedData = adaptDocuments(
                documentsData,
                downloadDocument,
                t,
                i18n,
                getFormattedDate,
                userPortfolios,
                tab,
            );

            handleDocumentsData({
                setDocs: setGeneralData,
                setDocNames: setGeneralNames,
                setPaging: setGeneralPaging,
                docsData: adaptedData,
                page: documentsData?.page,
                totalCount: documentsData?.totalCount,
            });
        } catch (err) {
            handlerRequestCanceling(HandlerError({ setError, setGeneralLoading }))(err);
        } finally {
            setGeneralLoading(false);
        }
    }, [generalParams, downloadDocument, t, i18n, tab]);

    const getUnifiedDocuments = useCallback(async () => {
        try {
            setLoading(true);
            const {data: response} = await SM.customDocumentsService('getUnifiedDocuments', [{
                contactId: clientId,
                language: i18n.language,
            }]);

            const adaptedData = adaptUnifiedDocuments({data: response, clientId: genarateDFSClientId(clientId), downloadDocument});

            setFilterOptions(adaptedData);

            handleDocumentsData({
                setDocs: setData,
                setDocNames: setNames,
                setPaging: setPersonalPaging,
                docsData: adaptedData,
                page: 1,
                totalCount: adaptedData?.length,
            });
        } catch (err) {
            handlerRequestCanceling(
                HandlerError({ setError, setLoading }),
            )(err);
        } finally {
            setLoading(false);
        }
    }, [clientId, i18n.language]);

    useEffect(() => {
        const filteredDocs = cachedData.filter(f => (!businessUnitFilter || f.businessUnit === businessUnitFilter)).filter(f => (!categoryFilter || f.category === categoryFilter));

        handleDocumentsData({
            setDocs: setData,
            setDocNames: setNames,
            setPaging: setPersonalPaging,
            docsData: filteredDocs,
            page: 1,
            totalCount: filteredDocs?.length,
        });
    }, [businessUnitFilter, categoryFilter, cachedData]);

    useEffect(() => {
        // General Docs
        if (tab === 'general') {
            (async () => getDocuments())();
        }
    }, [getDocuments, tab]);

    useEffect(() => {
        // Personal Docs
        if (tab === 'personal') {
            (async () => getUnifiedDocuments())();
        }
    }, [getUnifiedDocuments, tab]);

    return {
        data,
        generalData,
        names: names.concat(generalNames),
        isLoading,
        isGeneralLoading,
        pageSize: DEFAULT_PAGE_SIZE,
        personalPaging,
        generalPaging,
        error,
        categories,
        businessUnits,
        getUnifiedDocuments,
    };
};
