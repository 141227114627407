import PropTypes from 'prop-types';
import React from 'react';
import {getClassNames} from 'utils';

import './styles/Paragraph.css';
import './styles/Error.css';

const Error = (props) => (
    <div
        role="alert"
        className={getClassNames(
            'paragraph paragraph-error',
            props.className,
        )}
    >
        {props.children}
    </div>
);

Error.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node.isRequired,

};
Error.defaultProps = {
    className: '',
};

export default Error;
