import {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import SM from 'services/ServiceManager';
import moment from 'moment';
import {dateFormatZurich} from 'utils/datetime';
import { useCreatePortfolio } from '../../../context';
import { FEE_TYPES } from '../../../constants';
import distinguishClientType from './distinguishClientType';

export const useCustodyFee = ({
    dfsClientId, isZivAzp, isZic, isAzp,
}) => {
    const {
        newPortfolio, saveNewPortfolio, layout: { setData: setPageErrors },
    } = useCreatePortfolio();

    const investmentApplicationId = useMemo(() => newPortfolio?.applicationData?.investmentApplicationId, [newPortfolio?.applicationData?.investmentApplicationId]);
    const modelPortfolioId = useMemo(() => newPortfolio?.applicationData?.investmentDescription?.selectStrategy?.modelPortfolioId, [newPortfolio?.applicationData?.investmentDescription?.selectStrategy?.modelPortfolioId]);
    const investedAmount = useMemo(() => (
        isAzp
            ? newPortfolio?.applicationData?.investmentDescription?.withdrawal?.investmentAmount
            : newPortfolio?.applicationData?.investmentDescription?.productConfiguration?.initialInvestment),
    [
        newPortfolio?.applicationData?.investmentDescription?.productConfiguration?.initialInvestment,
        isAzp,
        newPortfolio?.applicationData?.investmentDescription?.withdrawal?.investmentAmount,
    ]);
    const clientType = useMemo(() => distinguishClientType(newPortfolio?.applicationData?.investmentDescription?.personalDetails?.zurichEmployee, newPortfolio?.applicationData?.investmentDescription?.personalDetails?.destinataryVita),
        [

            newPortfolio?.applicationData?.investmentDescription?.personalDetails?.zurichEmployee,
            newPortfolio?.applicationData?.investmentDescription?.personalDetails?.destinataryVita,
        ]);

    const [fees, setFees] = useState([]);

    const defaultCustodyFee = useMemo(() => JSON.parse(newPortfolio?.modelPortfolio?.Attributes || '{}')?.custodyFee, [newPortfolio?.modelPortfolio?.Attributes]);
    const defaultEntryFee = useMemo(() => JSON.parse(newPortfolio?.modelPortfolio?.Attributes || '{}')?.entryCost, [newPortfolio?.modelPortfolio?.Attributes]);
    const [defaultAssetFee, setDefaultAssetFee] = useState(null);
    const [defaultAssetDiscount, setDefaultAssetDiscount] = useState(0); // used for vita and zurich customers

    useEffect(() => {
        const fetchAssetData = async () => {
            const payload = {modelPortfolioId, investedAmount, clientType};

            if (isZivAzp) {
                try {
                    const {data} = await SM.planService('getAssetManagement', [payload]);

                    setDefaultAssetFee(data?.standardFee);
                    setDefaultAssetDiscount(data?.discount || 0);
                } catch (error) {
                    // Handle error here
                    console.error(error);
                }
            }
        };

        fetchAssetData();
    }, [isZivAzp, modelPortfolioId, investedAmount, clientType]);

    const defaultFees = useMemo(() => {
        const tempData = [];

        tempData.push({fee: defaultCustodyFee, feeType: FEE_TYPES.custody, startDate: moment()});
        if (isZivAzp) {
            const tempFee = defaultAssetFee * (1 - defaultAssetDiscount / 100);

            tempData.push({
                fee: parseFloat(tempFee.toFixed(2).replace(/\.?0*$/, '')),
                discountValue: defaultAssetDiscount,
                feeType: FEE_TYPES.asset,
                startDate: moment(),
            });
        }

        if (isZic) {
            tempData.push({fee: defaultEntryFee, feeType: FEE_TYPES.entry});
        }

        return tempData;
    }, [isZivAzp, isZic, defaultCustodyFee, defaultAssetFee, defaultEntryFee, defaultAssetDiscount]);

    const saveFeeLocally = (feeType, feeData) => {
        setFees((oldData) => {
            const tempData = [...oldData].filter((item) => item.feeType !== feeType);

            tempData.push(feeData);

            return tempData;
        });
    };

    useEffect(() => {
        setFees(newPortfolio?.applicationData?.investmentDescription?.fees || defaultFees);
    }, [newPortfolio?.applicationData?.investmentDescription?.fees, defaultFees]);

    const onSave = useCallback(async () => {
        try {
            const payload = {
                fees,
                investmentApplicationId,
            };

            if (payload.investmentApplicationId) {
                const result = await saveNewPortfolio({
                    data: payload,
                    method: 'saveCustodyFee',
                });

                return fees;
            }
        } catch (err) {
            setPageErrors(err.message);
        }

        return undefined;
    }, [
        fees, dfsClientId, investmentApplicationId, saveNewPortfolio, defaultCustodyFee,
    ]);

    return {
        feeData: fees,
        onSave,
        defaultCustodyFee,
        defaultAssetFee,
        saveFeeLocally,
        defaultEntryFee,
    };
};
