import React from 'react';

import Connections from 'components/Connections/Connections';
import { connectClientsSchema } from 'pages/Clients/provider/ClientsSchemaProvider';
import {useClientLayout} from '../../hooks/useClientLayout';
import './ClientConnections.css';

const ClientConnections = () => {
    useClientLayout();

    return (
        <div className="container">
            <div className="fi-verticals">
                <Connections />
            </div>
        </div>
    );
};

export default connectClientsSchema(ClientConnections);
