import React from 'react';
import PropTypes from 'prop-types';
import { getColumnClassNames } from './utils';
import { getClassNames } from '../../utils';
import withTheme from '../../hocs/withTheme';
import './Grid.css';

/** Column is supposed to be a part of grid and wrapped into row. */

function Column({
    size, children, className, ...props
}) {
    return (
        <div
            {...props}
            className={getClassNames(className, getColumnClassNames(size))}
        >
            {children}
        </div>
    );
}

Column.propTypes = {
    /** Container size or list of sizes for different screen width. */
    /** Names like 'lg-1','md-2, 'sm-3' etc. or numbers 1-12 are allowed */
    size: PropTypes.oneOfType([
        PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
    ]),
    /** @ignore */
    children: PropTypes.node,
    /** @ignore */
    className: PropTypes.string,
};

Column.defaultProps = {
    size: 1,
    children: null,
    className: null,
};

export default withTheme(Column);
