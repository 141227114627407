import React, {useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {useFormatting} from '../../../locale';
import {useClientsSchema} from '../provider/ClientsSchemaProvider';
import {useClientList} from '../../../hooks/useClientList';
import {ClientSchemaManager} from '../utils/ClientSchemaManager';
import {adaptClientList} from '../adapters/adaptClientList';
import Preloader from '../../../components/Preloader';
import {COUNT_OF_PAGE} from '../constants';
import {useOptionList} from '../../../hooks/useOptionList';
import {OPTION_LIST} from '../../../constants/constants';

const withClientTable = (Component, isProspects, sourceColumns) => ({
    search = '',
    selection,
    externalFilters,
    ...props
}) => {
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(1);
    const [filters, setFilters] = useState([]);
    const [sort, setSort] = useState({
        key: '',
        direction: 'asc',
    });
    const {
        getFormattedDate,
        getFormattedNumber,
        getFormattedCurrency,
    } = useFormatting();
    const {
        schemasColumns: {
            data: schemasData,
            isLoading: schemasLoading,
        },
        schemasLists,
    } = useClientsSchema();
    const {t} = useTranslation();
    const {
        isLoading: isOptionListLoading,
        lists: [optionGender, optionLanguage, optionCountry],
    } = useOptionList([OPTION_LIST.gender, OPTION_LIST.language, OPTION_LIST.country]);

    const columns = useMemo(
        () => (
            ClientSchemaManager.generateColumns({
                dataList: schemasLists?.data,
                columns: sourceColumns({t}),
                fields: schemasData?.fields,
            })
        ),
        [schemasLists, schemasData, sourceColumns],
    );

    const onChangeSort = ({
        key,
        direction,
    }) => {
        setSort(key
            ? {
                key,
                direction,
            }
            : {});
    };

    const order = useMemo(() => (
        ClientSchemaManager.generateSortValue({
            key: sort.key,
            direction: sort?.direction,
            columns,
        })
    ), [sort, columns]);

    const vars = useMemo(() => ({
        includeTotalCount: true,
        pageNumber: currentPage,
        pageSize: COUNT_OF_PAGE,
        search,
        order,
    }),
    [COUNT_OF_PAGE, currentPage, search, order]);

    const {
        data = [],
        isLoading,
        totalCount,
        error,
    } = useClientList(
        vars,
        `${ClientSchemaManager.generateContactFragment(columns)}`,
        externalFilters,
    );

    useEffect(() => {
        if (search) {
            setCurrentPage(1);
        }
    }, [search]);

    const onFilter = (filter) => {
        const uniqFilters = filters.filter(
            ({key}) => key !== filter.key,
        );

        if (filter.value.length > 0) {
            setFilters([...uniqFilters, filter]);
        } else {
            setFilters(uniqFilters);
        }
    };

    const adaptedData = adaptClientList({
        navigate,
        t,
        data,
        optionGender,
        optionLanguage,
        optionCountry,
        columns,
        isLoading: isLoading || isOptionListLoading,
        getFormattedDate,
        getFormattedNumber,
        getFormattedCurrency,
        pageSize: COUNT_OF_PAGE,
    });

    return (
        <Preloader isLoading={false} error={error}>
            <Component
                data={adaptedData}
                columns={columns}
                sort={sort}
                isLoading={isLoading || isOptionListLoading}
                paging={{
                    totalCount,
                    currentPage,
                    pageSize: COUNT_OF_PAGE,
                }}
                onChangePagination={setCurrentPage}
                onChangeFilter={onFilter}
                onChangeSort={onChangeSort}
                selection={selection}
                {...props}
            />
        </Preloader>
    );
};

export default withClientTable;
