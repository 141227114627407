import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ClientLanding } from './pages';

const Router = (props) => (
    <Routes {...props}>
        <Route index path=":externalClientId" element={<ClientLanding/>} />
        <Route
            path=""
            element={<Navigate to=":externalClientId" replace />}
        />
        <Route
            path="*"
            element={<Navigate to="/404" replace />}
        />
    </Routes>
);

export default Router;
