import {
    useMemo,
} from 'react';

import { useTranslation } from 'react-i18next';
import { useNotificationConext } from 'libs/additiv-react-signalr';

export const useNotifications = () => {
    const { t, i18n } = useTranslation();
    const errorMessage = useMemo(() => t('profileMenu.notifications.error'), [t, i18n.language]);
    const {
        notifications: allNotifications,
        readNotification: onReadNotification,
        error,
    } = useNotificationConext();

    return {
        onReadNotification,
        allNotifications,
        errorNotifications: error && errorMessage,
    };
};
