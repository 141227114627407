import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSecureUser } from 'libs/additiv-react-oidc-client';
import {useNavigate} from 'react-router-dom';
import { Loader } from '../ui-library';
import { INIT_LOCATION } from '../constants/sessionStorageKeys';

const initLocation = sessionStorage.getItem(INIT_LOCATION);

const withInitLocation = (
    WrappedComponent,
) => {
    const Component = (props) => {
        const secureUser = useSecureUser();
        const [isWithInitLocation, setWithInitLocation] = useState(!!initLocation);
        const navigate = useNavigate();

        useEffect(() => {
            if (secureUser && initLocation) {
                global.sessionStorage.removeItem(INIT_LOCATION);
                setWithInitLocation(false);
                navigate(initLocation);
            }
        }, [secureUser]);

        return (
            isWithInitLocation ? <Loader /> : <WrappedComponent {...props} />
        );
    };

    return Component;
};

export default withInitLocation;
