import React, {useMemo} from 'react';
import {
    Route, Routes, Navigate, useParams, useLocation,
} from 'react-router-dom';
import { getClientId } from 'utils';
import {
    AdvisoryDocumentSetup,
} from 'pages/ClientOverview/pages/Portfolios/pages/CreateNewPortfolio/pages/AdvisoryDocument/AdvisoryDocumentSetup';
import {
    useAdvisorySetupChangePlan,
} from 'pages/ClientOverview/pages/Portfolios/pages/CreateNewPortfolio/pages/AdvisoryDocument/hooks/useAdvisorySetupChangePlan';
import useCommonAllocationDataProvider
    from 'pages/ClientOverview/pages/Portfolios/context/useCommonAllocationDataProvider.';
import {PRODUCTS} from 'constants/constants';
import {AdvisoryDocument} from 'pages/ClientOverview/pages/Portfolios/pages/CreateNewPortfolio/pages/AdvisoryDocument';
import {
    useAdvisoryDocumentChangePlan,
} from 'pages/ClientOverview/pages/Portfolios/pages/CreateNewPortfolio/pages/AdvisoryDocument/hooks/useAdvisoryDocumentChangePlan';
import {
    InvestorProfilesContainer,
} from 'pages/ClientOverview/pages/Portfolios/pages/CreateNewPortfolio/pages/InvestorProfile/InvestorProfilesContainer';
import {
    useInvestorProfileChangePlan,
} from 'pages/ClientOverview/pages/Portfolios/pages/CreateNewPortfolio/pages/InvestorProfile/hooks/useInvestorProfileChangePlan';
import PersonalDetails from './pages/PersonalDetails/PersonalDetails';
import StrategySelectionPage from './pages/StrategySelection/StrategySelectionPage';
import ChangeOverview from './pages/ChangeOverview/ChangeOverview';
import { ChangeStrategyProvider } from './context';
import { getSteps } from './constants';
import { PlanWizardLayoutProvider } from '../../components/PlanWizardLayout';
import WithdrawalPlan from '../CreateNewPortfolio/pages/WithdrawalPlan/WithdrawalPlan';
import { useWithdrawalPlanChangePlan } from '../CreateNewPortfolio/pages/WithdrawalPlan/hooks/useWithdrawalPlanChangePlan';

const Router = () => {
    const {portfolioId, dfsClientId} = useParams();
    const {state} = useLocation();
    const clientId = getClientId(dfsClientId);
    const productKey = useMemo(() => state?.productKey, []);
    const {selectedProductType} = useCommonAllocationDataProvider();

    return (
        <PlanWizardLayoutProvider steps={getSteps(selectedProductType === PRODUCTS.bbb)} withRisk>
            <ChangeStrategyProvider clientId={clientId} portfolioId={portfolioId} productKey={productKey}>
                <Routes>
                    <Route path="advisory-setup" element={<AdvisoryDocumentSetup useData={useAdvisorySetupChangePlan}/>} />
                    <Route path="advisory-document" element={<AdvisoryDocument useData={useAdvisoryDocumentChangePlan}/>} />
                    <Route path="personal-details" element={<PersonalDetails/>} />
                    <Route path="investor-profile" element={<InvestorProfilesContainer dfsClientId={dfsClientId} dataSource={useInvestorProfileChangePlan}/>} />
                    <Route path="strategy-selection" element={<StrategySelectionPage/>} />
                    <Route path="withdrawal-plan" element={<WithdrawalPlan useData={useWithdrawalPlanChangePlan}/>} />
                    <Route path="change-overview" element={<ChangeOverview/>} />

                    <Route
                        path=""
                        element={<Navigate to="personal-details" replace/>}
                    />
                </Routes>
            </ChangeStrategyProvider>
        </PlanWizardLayoutProvider>
    );
};

Router.displayName = 'Router';

export default Router;
