/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';
import {Icon} from 'ui-library';
import {ICON_INFO_SLIM} from 'ui-library/components/Icon';
import './IconWithTooltip.css';

const IconWithTooltip = ({
    description,
    iconSize = 20
}: { description: string; iconSize?: number }) => (
    <div className="icon-with-desc">
        {/* @ts-ignore */}
        <Icon type={ICON_INFO_SLIM} size={iconSize}/>
        <div className="info-tooltip">{description}</div>
    </div>
);

export default IconWithTooltip;

IconWithTooltip.defaultProps = {
    iconSize: 20,
};
