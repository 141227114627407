import { getServicesUrl } from '../../setup';
import Service from './service';

class Data extends Service {
    static getFlatMemberData(memberId) {
        return Service.getRequest(`${getServicesUrl()}/data/flat/members/${memberId}`, { isSecure: true });
    }

    static getFlatPortfolioData(contactId) {
        return Service.getRequest(`${getServicesUrl()}/data/flat/contacts/${contactId}/portfolio-data`, { isSecure: true });
    }
}

export default Data;
