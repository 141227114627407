import { CellLink, CellPDFViewIcon } from 'components/renderers';
import { sorterByDate, sorterString } from 'utils/sorting';
import {PRODUCTS} from '../../../../constants/constants';

export const getColumns = (t, isTabletSize, getFormattedDate) => [
    {
        key: 'document',
        title: t('clientDashboard.documents.document'),
    },
    {
        key: 'businessUnit',
        title: t('clientDashboard.documents.businessUnit'),
    },
    {
        key: 'portfolio',
        title: t('clientDashboard.documents.depot'),
        render: CellLink,
        sortable: true,
        className: 'table-cell_account',
    },
    {
        key: 'createDate',
        title: t('clientDashboard.documents.createDate'),
        sorter: sorterByDate('createDate'),
        render: item => getFormattedDate(item),
        sortable: true,
        minWidth: 60,
    },
    {
        key: 'category',
        title: t('clientDashboard.documents.category'),
        sorter: sorterString('type'),
        sortable: true,
        hidden: isTabletSize,
    },
    {
        key: 'nameCol',
        title: '',
        render: CellPDFViewIcon,
    },
];

export const DOCUMENT_SOURCE = {
    dmsDocument: 1,
    lpzDocument: 2,
};

export const UPLOAD_DOCUMENT_CONFIG = {
    personal: {
        type: 'personal',
        numberOfDocuments: 2,
        translation: 'clientDashboard.documents.idDocument',
    },
    clientData: {
        type: 'clientData',
        numberOfDocuments: 1,
        translation: 'clientDashboard.documents.personalDocument',
    },
};
