import {useQuery} from '@tanstack/react-query';
import ServiceManager from 'services/ServiceManager';
import ServerError from '../../errors/ServerError';

export const useApplicationList = ({
    contactId, memberId, language, pageSize, page,
}) => {
    const result = useQuery({
        queryKey: ['getApplicationList', contactId, memberId, page, pageSize, language],
        enabled: !!contactId && !!memberId && !!page && !!pageSize && !!language,
        retry: false,
        queryFn: async () => {
            const {data} = await ServiceManager.customInvestmentService('getApplicationList', [{
                contactId, memberId, language, pageNumber: page, pageSize,
            }]);

            return data;
        },
    });

    return {
        ...result,
        error: result.error ? new ServerError(result.error) : result.error,
    };
};
