import React from 'react';
import PropTypes from 'prop-types';
import { datesEqual, localizeFormat } from './utils';

const WeekHeader = ({
    date, dayFormat, localizer, activeDate,
}) => (
    <div className={datesEqual(date, activeDate) ? 'rbc-today' : ''}>
        {localizeFormat(localizer, date, localizer.formats.weekdayFormat)}
        {' '}
        <span>{localizeFormat(localizer, date, dayFormat)}</span>
    </div>
);

WeekHeader.propTypes = {
    date: PropTypes.string.isRequired,
    dayFormat: PropTypes.string.isRequired,
    activeDate: PropTypes.string.isRequired,
    localizer: PropTypes.shape({
        format: PropTypes.func,
        formats: PropTypes.shape({
            weekdayFormat: PropTypes.string,
        }),
    }).isRequired,
};

export default WeekHeader;
