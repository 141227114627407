import {useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {
    MailingAddress
} from '../../../pages/ClientOverview/pages/ClientConnections/components/NewConnections/constants';
import SM from '../../../services/ServiceManager';
import {notification} from '../../../ui-library';

export const useUpdateConnectionMailingAddress = () => {
    const {t} = useTranslation();
    const [inProgress, setInProgress] = useState<boolean>(false);

    const updateContactGroupAddress = useCallback(async (groupId: string, address: MailingAddress, callback?: (...args) => Promise<void>): Promise<void> => {
        try {
            setInProgress(true);
            await SM.customClientService('updateContactGroupAddress', [groupId, {
                mailingAddress: address,
            }]);

            if (callback) {
                await callback(parseInt(groupId, 10));
            }

            notification.open({
                content: t('contactGroups.addressUpdatedSuccessfully'),
                type: 'success'
            });
        } catch (err: any) {
            notification.open({
                content: `${t('contactGroups.somethingWentWrong')} ${err.message}`,
                type: 'error'
            });
        } finally {
            setInProgress(false);
        }
    }, []);

    return {
        inProgress,
        updateContactGroupAddress
    };
};
