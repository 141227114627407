import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import './LogoWithClientName.css';

const LogoWithClientName = (props) => {
    const navigate = useNavigate();
    const {
        clientName,
        dfsClientId,
        isLogoClickable,
        onClientNameClick,
    } = props;
    const { t } = useTranslation();
    const onClientClick = () => {
        if (typeof onClientNameClick === 'function') {
            onClientNameClick();
        } else if (dfsClientId) navigate(`/client/${dfsClientId}/dashboard`);
    };
    const logoInner = (
        <>
            Zurich
            <strong>Insurance</strong>
        </>
    );

    return (
        <div className="LogoWithClientName">
            {isLogoClickable
                ? (<Link to="/" className="logo-title">{logoInner}</Link>)
                : (<div className="logo-title">{logoInner}</div>)}
            {clientName && (
                <button type="button" className="client-name" onClick={onClientClick}>
                    {clientName}
                </button>
            )}
        </div>
    );
};

LogoWithClientName.propTypes = {
    clientName: PropTypes.string,
    dfsClientId: PropTypes.string,
    isLogoClickable: PropTypes.bool,
    onClientNameClick: PropTypes.func,
};

LogoWithClientName.defaultProps = {
    clientName: undefined,
    dfsClientId: undefined,
    isLogoClickable: false,
    onClientNameClick: undefined,
};

export default LogoWithClientName;
