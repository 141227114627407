import React from 'react';
import {useParams} from 'react-router-dom';
import {
    useInvestorProfile,
} from 'pages/ClientOverview/pages/Portfolios/pages/CreateNewPortfolio/pages/InvestorProfile/hooks/useInvestorProfile';
import {InvestorProfilesContainer} from './InvestorProfilesContainer';

export const InvestorProfiles = () => {
    const { dfsClientId } = useParams();

    return (<InvestorProfilesContainer dfsClientId={dfsClientId} dataSource={useInvestorProfile}/>);
};
