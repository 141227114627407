import config from 'configs/idenityServerConfig';

export const onboardingLink = '/onboardingflow/';


export const newPersonalInformationOnboardingLink = `${onboardingLink}newPersonalInformation`;
export const uploadDocumentsPageLink = `${onboardingLink}uploadDocuments`;
export const questionPageLink = `${onboardingLink}question`;
export const confirmationPageLink = `${onboardingLink}confirm`;

export const LOGIN_URL = `${config.authority}/Account/Login`;
