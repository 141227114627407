import PageVariantWrapper from 'components/PageVariantWrapper';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useLocation, useNavigate} from 'react-router-dom';
import {PageVariant} from 'ui-library';

function ConfirmationPage() {
    const navigate = useNavigate();
    const {state} = useLocation();
    const {t} = useTranslation();

    return (
        <PageVariantWrapper>
            <PageVariant
                type="success"
                title={state ? state.title : t('confirm.title')}
                message={state ? state.message : ''}
                actionButtonLabel={state ? state.confirmButton : t('confirm.button')}
                onActionButtonClick={
                    () => {
                        navigate(state.redirectURL);
                    }
                }
            />
        </PageVariantWrapper>
    );
}

export default ConfirmationPage;
