import React from 'react'
import { User, UserManager, UserManagerEvents } from 'oidc-client'

export type oidcContext = {
  oidcUser: User | null;
  login: () => Promise<void>;
  logout: () => Promise<void>;
  signinSilent: () => Promise<void>;
  events?: UserManagerEvents;
  isLoading: boolean;
  isLoggingOut: boolean;
  userManager: UserManager;
  error: unknown;
};

export const AuthenticationContext = React.createContext<oidcContext | null>(null)
