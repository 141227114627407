import React from 'react';
import PropTypes from 'prop-types';
import HighchartsReact from 'highcharts-react-official';
import { buildAdditionalCss, getBarChartOptions } from './utils';
import { getClassNames } from '../../../utils';
import './BarChart.css';
import withTheme from '../../../hocs/withTheme';

function BarChart(props) {
    const {
        max, data, labels, height, labelFormat, className, barHeight,
    } = props;
    const css = buildAdditionalCss(data);

    return (
        <HighchartsReact
            containerProps={{
                style: css,
                className: getClassNames('Highcharts-bar-chart', className),
            }}
            options={getBarChartOptions(
                max,
                data,
                labels,
                height,
                labelFormat,
                barHeight,
            )}
        />
    );
}

BarChart.propTypes = {
    /** Data to be shown on chart */
    data: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        data: PropTypes.arrayOf(PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.shape({
                [PropTypes.string]: PropTypes.number,
            }),
            PropTypes.arrayOf(PropTypes.number),
        ])).isRequired,
    })),
    /** Labels to be shown on X axis */
    labels: PropTypes.arrayOf(PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
            [PropTypes.string]: PropTypes.string,
        }),
    ])),
    /** Height of chart */
    height: PropTypes.number,
    /** Formats of X axis label */
    labelFormat: PropTypes.func,
    /** Max value of Y axis */
    max: PropTypes.number,
    /** Height value for each bar */
    barHeight: PropTypes.number,
    className: PropTypes.string,
};

BarChart.defaultProps = {
    max: undefined,
    data: [],
    labels: [],
    height: undefined,
    labelFormat: (label) => label,
    barHeight: undefined,
    className: '',
};

export default withTheme(BarChart);
