import {useNavigate, useParams, useSearchParams} from 'react-router-dom';
import {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import {isNil} from 'lodash/fp';
import {useCreatePortfolio} from '../../../context';
import {useAdvisoryDocumentBase} from './useAdvisoryDocumentBase';
import {getQueryStringByObject} from '../../../../../../../../../additiv-services/utils/queryUtils';

export const useAdvisorySetup = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [shouldInitiateAdvisoryDocument, setShouldInitiateAdvisoryDocument] = useState<number>();
    const { dfsClientId } = useParams();
    const baseUrl = useMemo(() => `/client/${dfsClientId}/portfolios/new-portfolio`, [dfsClientId]);

    const {saveNewPortfolio, newPortfolio, layout: { setData: setLayoutData }} = useCreatePortfolio();
    const {document} = useAdvisoryDocumentBase();

    const onContinue = useCallback(async () => {
        try {
            const ifYes = shouldInitiateAdvisoryDocument === 1;

            const result = await saveNewPortfolio({
                data: {
                    advisoryDocumentId: document?.documentId,
                    investmentApplicationId: newPortfolio?.applicationData?.investmentApplicationId,
                },
                additionalData: {
                    isAdvisoryCompleted: ifYes,
                },
            });

            if (!result) return;

            navigate(ifYes ? `${baseUrl}/advisory-document?${getQueryStringByObject(Object.fromEntries([...searchParams]))}` : `${baseUrl}/product-selection`);
        } catch (err) {
            console.error(err);
            throw err;
        }
    }, [document, shouldInitiateAdvisoryDocument]);

    const onBack = useCallback(() => {
        navigate(baseUrl);
    }, []);

    useEffect(() => {
        setLayoutData({ inProgress: false });
    }, [setLayoutData]);

    useEffect(() => {
        if (!isNil(newPortfolio?.isAdvisoryCompleted)) {
            setShouldInitiateAdvisoryDocument(newPortfolio?.isAdvisoryCompleted ? 1 : 2);
        }
    }, [newPortfolio?.isAdvisoryCompleted]);

    useEffect(() => {
        setLayoutData({
            stepNavBarActive: 2,
            onBack,
            onContinue,
            disabled: !shouldInitiateAdvisoryDocument,
        });
    }, [onBack, onContinue, shouldInitiateAdvisoryDocument]);

    return {shouldInitiateAdvisoryDocument, setShouldInitiateAdvisoryDocument};
};
