import React from 'react';
import PropTypes from 'prop-types';
import ModalCore from './ModalCore';
import ActionButton from './ActionButton';
import { prefixCls } from '../constants';

const ConfirmModal = (props) => {
    const {
        close,
        okButtonProps,
        cancelButtonProps,
        okText,
        cancelText,
        visible,
        onCancel,
        afterClose,
        onOk,
        width,
        title,
        content,
        className = '',
        triggerCancelOnClose = false,
    } = props;

    const okType = props.okType || 'primary';

    const okCancel = 'okCancel' in props ? props.okCancel : true;

    const cancelButton = (okCancel && !!cancelText) && (
        <ActionButton
            actionFn={onCancel}
            closeModal={close}
            {...cancelButtonProps}

        >
            {cancelText}
        </ActionButton>
    );

    return (
        <ModalCore
            visible={visible}
            prefixCls={prefixCls}
            className={`${className} ${prefixCls}-confirm`}
            onCancel={() => close({ triggerCancel: true })}
            afterClose={afterClose}
            width={width}
            transitionName="zoom"
        >
            <div className={`${prefixCls}-confirm-body-wrapper`}>
                <div className={`${prefixCls}-confirm-body`}>
                    <div className="ModalHeader">
                        {title === undefined ? null : (
                            <h1 className={`${prefixCls}-confirm-title`}>{title}</h1>
                        )}
                        <button
                            type="button"
                            className="ModalHeader__ModalClose"
                            aria-label="Close"
                            onClick={() => (close({ triggerCancel: triggerCancelOnClose }))}
                        >
                            <span className="ModalHeader__ModalClose__Icon" />
                        </button>
                    </div>
                    <div className={`${prefixCls}-confirm-content`}>{content}</div>
                </div>
                <div className={`${prefixCls}-confirm-btns`}>
                    <ActionButton
                        type={okType}
                        actionFn={onOk}
                        closeModal={close}
                        buttonProps={okButtonProps}
                    >
                        {okText}
                    </ActionButton>
                    {cancelButton}
                </div>
            </div>
        </ModalCore>
    );
};

ConfirmModal.propTypes = {
    /** current dialog's visible status */
    visible: PropTypes.node,
    /** additional className for dialog wrap */
    wrapClassName: PropTypes.node,
    /** The cancel button props */
    cancelButtonProps: PropTypes.shape({}),
    /** The ok button props */
    okButtonProps: PropTypes.shape({}),
    /** Text of the OK button */
    okText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    /** Text of the Cancel button */
    cancelText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    /** Text of the Cancel button */
    okType: PropTypes.string,
    /** Whether to apply loading visual effect for OK button or not */
    confirmLoading: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
    ]),
    /** Specify a function that will be called when a user clicks mask,
     *  close button on top right or Cancel button */
    onCancel: PropTypes.func,
    /** Specify a function that will be called when a user clicks the OK button */
    onOk: PropTypes.func,
    /** The modal dialog's title */
    title: PropTypes.node,
    /** Content */
    content: PropTypes.node,
    /** @ignore */
    children: PropTypes.node,
    /** @ignore */
    className: PropTypes.string,
    /** @ignore */
    afterClose: PropTypes.func,
    /** @ignore */
    close: PropTypes.func,
    /** @ignore */
    okCancel: PropTypes.bool,
    /** Width of modal */
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

ConfirmModal.defaultProps = {
    visible: false,
    wrapClassName: '',
    cancelButtonProps: {},
    okButtonProps: {},
    okText: 'OK',
    cancelText: 'Cancel',
    okType: 'primary',
    confirmLoading: false,
    children: null,
    afterClose: () => {
    },
    close: () => {
    },
    onCancel: undefined,
    onOk: undefined,
    title: null,
    className: '',
    content: null,
    okCancel: undefined,
    width: 380,
};
export default ConfirmModal;
