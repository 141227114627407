import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Icon, { ICON_TYPE_ARROW_UP_DOWN, ICON_TYPE_ARROW_DOWN } from '../../Icon';
import { getClassNames } from '../../../utils';

function SortIcon({
    isSorted, direction, handleSort,
}) {
    const className = getClassNames(
        'table-cell__sorter-icon',
        isSorted && `table-cell__sorter-${direction}`,
    );
    const iconType = useMemo(() => (isSorted ? ICON_TYPE_ARROW_DOWN : ICON_TYPE_ARROW_UP_DOWN),
        [isSorted]);

    return (
        <button type="button" className="table-cell__sorter" onClick={handleSort}>
            <Icon className={className} type={iconType} />
        </button>
    );
}

SortIcon.propTypes = {
    isSorted: PropTypes.bool,
    direction: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    handleSort: PropTypes.func,
};

SortIcon.defaultProps = {
    isSorted: false,
    direction: false,
    handleSort: () => {},
};

export default SortIcon;
