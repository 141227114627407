import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {TextInput} from '../../../ui-library';

export const ClientSearchBar = ({onSearch}) => {
    const {t} = useTranslation();

    return (
        <>
            {/*<div>dropdown</div>*/}
            <TextInput
                onChange={onSearch}
                search
            />
            {/*<div>Extended search</div>*/}
        </>
    );
};

ClientSearchBar.propTypes = {
    onSearch: PropTypes.func.isRequired,
};

ClientSearchBar.defaultProps = {};
