import {useCallback, useMemo, useState} from 'react';
import { SELECETED_PRODUCT_TYPE } from 'pages/ClientOverview/constants';
import { useThreeBProductType } from 'hooks/isThreeBProduct';
import useCommonAllocationDataProvider from '../context/useCommonAllocationDataProvider.';

const steps = {
    productOffer: 'portfolio.createNewPortfolio.productOffer',
    advisoryDocument: 'portfolio.createNewPortfolio.advisoryDocument',
    productSelection: 'portfolio.createNewPortfolio.productSelection',
    personalDetails: 'portfolio.createNewPortfolio.personalDetails',
    investorProfile: 'portfolio.createNewPortfolio.investorProfile',
    strategy: 'portfolio.createNewPortfolio.strategy',
    withdrawalPlan: 'portfolio.createNewPortfolio.withdrawalPlan',
    productConfiguration: 'portfolio.createNewPortfolio.productConfiguration',
    agentInformation: 'portfolio.createNewPortfolio.agentInformation',
    kyc: 'portfolio.createNewPortfolio.kyc',
    overview: 'portfolio.createNewPortfolio.overview',
    opening: 'portfolio.createNewPortfolio.opening',
};

function use3bStepper() {
    const {setupWithdrawalPlan: isWithdrawal} = useCommonAllocationDataProvider();
    const {isAzp, isPk} = useThreeBProductType({product: {externalId: global.sessionStorage.getItem(SELECETED_PRODUCT_TYPE)}});

    const [agentInfoStepNumber, setAgentInfoStepNumber] = useState();
    const [kycStepNumber, setKycStepNumber] = useState();
    const [overviewStepNumber, setOverviewStepNumber] = useState();
    const [openingStepNumber, setOpeningStepNumber] = useState();
    const [withdrawalStepNumber, setWithdrawalStepNumber] = useState();
    const [productConfigStepNumber, setProductConfigStepNumber] = useState();

    const addStep = useCallback((stepAkku, stepName) => {
        const step = { number: stepAkku.length + 1, name: stepName };

        stepAkku.push(step);

        return step;
    }, []);

    const steps3b = useMemo(() => {
        const stepAkku = [];

        addStep(stepAkku, steps.productOffer);
        addStep(stepAkku, steps.advisoryDocument);
        addStep(stepAkku, steps.productSelection);
        addStep(stepAkku, steps.personalDetails);
        addStep(stepAkku, steps.investorProfile);
        if (!isPk) {
            addStep(stepAkku, steps.strategy);

            if (isAzp) {
                const {number: withdrawalPlanNumber} = addStep(stepAkku, steps.withdrawalPlan);
                const {number: productConfigurationNumber} = addStep(stepAkku, steps.productConfiguration);

                setWithdrawalStepNumber(withdrawalPlanNumber);
                setProductConfigStepNumber(productConfigurationNumber);
            } else if (isWithdrawal) {
                const {number: productConfigurationNumber} = addStep(stepAkku, steps.productConfiguration);
                const {number: withdrawalPlanNumber} = addStep(stepAkku, steps.withdrawalPlan);

                setWithdrawalStepNumber(withdrawalPlanNumber);
                setProductConfigStepNumber(productConfigurationNumber);
            } else {
                const {number: productConfigurationNumber} = addStep(stepAkku, steps.productConfiguration);

                setProductConfigStepNumber(productConfigurationNumber);
            }
        }
        const {number: agentInformationNumber} = addStep(stepAkku, steps.agentInformation);

        setAgentInfoStepNumber(agentInformationNumber);
        const {number: overviewNumber} = addStep(stepAkku, steps.overview);

        setOverviewStepNumber(overviewNumber);
        if (!isPk) {
            const {number: kycNumber} = addStep(stepAkku, steps.kyc);

            setKycStepNumber(kycNumber);
        }
        const {number: openingNumber} = addStep(stepAkku, steps.opening);

        setOpeningStepNumber(openingNumber);

        return stepAkku;
    }, [isWithdrawal, isAzp, isPk]);

    return {
        isWithdrawal,
        threeBSteps: steps3b,
        agentInfoStepNumber,
        kycStepNumber,
        overviewStepNumber,
        openingStepNumber,
        withdrawalStepNumber,
        productConfigStepNumber,
    };
}

export default use3bStepper;
