import Preloader from 'components/Preloader';
import {THREEBTYPES} from 'constants/constants';
import React from 'react';
import {LineChart, ProjectionChart} from 'ui-library';
import {useGoalAndPerformance} from 'widgets/GoalAndPortfolioSetup/hooks/useGoalAndPortfolio';

const ProjectionChartComp = ({
    product,
    continueDisabled,
    t
}) => {
    const {
        projectionOptions,
        currency,
        chartData,
        isLoading: chartDataInProgress,
        azpXAxisFormat,
        azpYAxisFormat,
    } = useGoalAndPerformance({
        modelPortfolioId: product?.modelPortfolioId,
        continueDisabledFromProps: continueDisabled,
    });

    return (
        <div>
            <Preloader isLoading={chartDataInProgress}>
                {
                    product?.product === THREEBTYPES.azpNeuSB || product?.product === THREEBTYPES.azpNeu
                        ? (<LineChart yAxisFormat={azpYAxisFormat} customXAxisFormat={azpXAxisFormat} data={chartData}
                                      yTitle={currency} legendEnabled projection/>)
                        : (
                            <ProjectionChart
                                data={chartData}
                                {...projectionOptions}
                                yTitle={currency}
                                yearsText={t('charts.years')}
                                showLegend
                            />
                        )
                }
            </Preloader>

        </div>
    );
};

export default React.memo(ProjectionChartComp);
