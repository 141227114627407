import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {ProductOfferSelection} from '../../../../../../../../components/product-offer-selection';
import {Title} from '../../../../../../../../ui-library';

import './AdvisoryDocument.css';

export const AdvisoryDocumentSetup = ({useData}) => {
    const {t} = useTranslation();
    const items = useMemo(() => [{id: 2, name: t('advisoryDocumentInit.step.no')}, {id: 1, name: t('advisoryDocumentInit.step.yes')}], [t]);

    const {shouldInitiateAdvisoryDocument, setShouldInitiateAdvisoryDocument} = useData();

    return (
        <div className="advisory-document-init">
            <Title type={1}>
                {t('advisoryDocumentInit.step.title')}
            </Title>
            <p className="question">{t('advisoryDocumentInit.step.question')}</p>
            <p className="desc">{t('advisoryDocumentInit.step.desc')}</p>
            <div className="fi-products-selection">
                {items.map(item => (
                    <ProductOfferSelection
                        disabled={false}
                        isSelected={shouldInitiateAdvisoryDocument === item.id}
                        key={item.id}
                        productOffer={item}
                        onSelectionChange={setShouldInitiateAdvisoryDocument}
                    />
                ))}
            </div>
        </div>
    );
};
