import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Icon from '../Icon';
import { ICON_TYPE_CLOSE } from '../Icon/constants';
import './Tags.css';
import withTheme from '../../hocs/withTheme';
import { getClassNames } from '../../utils';

class Tags extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: [],
        };
    }

    static getDerivedStateFromProps(nextProps) {
        return { selected: nextProps.data || [] };
    }

    clearSelected = (item) => {
        this.setState(({ selected }) => (
            { selected: selected.filter((selectable) => selectable !== item) }
        ));
        this.props.onChange(this.state.selected.filter((selectable) => selectable !== item));
    };

    clearSelectedTags = () => {
        this.setState({ selected: [] });
        this.props.onChange([]);
    };

    className = () => getClassNames('Tags', { 'Tags-mobile': this.props.isMobile });

    render() {
        const {
            props: {
                title,
                isMobile,
                labelRemove,
                labelReset,
            },
            state: { selected },
        } = this;

        return (
            <div className={this.className()}>
                <div className="tags-list">
                    {!isMobile && title && <b>{title}</b>}
                    {selected.map((item) => (
                        <span className="tag-item" key={item}>
                            <span>{item}</span>
                            <Icon
                                role="button"
                                tabIndex={0}
                                ariaLabel={`${labelRemove} ${item}`}
                                type={ICON_TYPE_CLOSE}
                                onClick={() => this.clearSelected(item)}
                            />
                        </span>
                    ))}
                </div>
                <button type="button" onClick={this.clearSelectedTags}>{labelReset}</button>
            </div>
        );
    }
}

Tags.propTypes = {
    /** Title for the list of tags */
    title: PropTypes.string,
    /** function(value) */
    onChange: PropTypes.func,
    /** check mobile version */
    isMobile: PropTypes.bool,
    /** label for remove */
    labelRemove: PropTypes.string,
    labelReset: PropTypes.string,
};

Tags.defaultProps = {
    title: null,
    onChange: () => {
    },
    isMobile: false,
    labelRemove: 'Remove',
    labelReset: 'Reset filters',
};

export default withTheme(Tags);
