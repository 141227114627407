import React from 'react';
import PropTypes from 'prop-types';
import { TOOLTIP_THEMES } from './constants';
import Popover from '../Popover';
import './Tooltip.css';
import withTheme from '../../hocs/withTheme';

/** A basic widget for getting the user input is a text field.
 * Keyboard and mouse can be used for providing or changing data. */

function Tooltip(props) {
    const {
        title, theme, children, overlayClassName, ...rest
    } = props;

    return title ? (
        <Popover
            {...rest}
            title={title}
            className="rc-tooltiped-text"
            overlayClassName={`Tooltip ${theme ? `rc-tooltip-${theme}` : ''} ${overlayClassName}`}
        >
            {children}
        </Popover>
    ) : children;
}

Tooltip.propTypes = {
    /** The text shown in the tooltip */
    title: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
    ]),
    /** Can be set to 'dark' or omitted (meaning default) */
    theme: PropTypes.oneOf(TOOLTIP_THEMES),
    /** @ignored */
    overlayClassName: PropTypes.string,
    /** @ignored */
    children: PropTypes.node,
};

Tooltip.defaultProps = {
    title: null,
    theme: null,
    overlayClassName: '',
    children: null,
};

export default withTheme(Tooltip);
