import ErrorsManager from './ErrorsManager';
import { API_ERROR } from './Error';

class ServerError extends Error {
    constructor(error, messageCustom) {
        super(error);
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, ServerError);
        }

        return ErrorsManager.createError({
            type: API_ERROR,
            error,
            messageCustom,
        });
    }
}

export default ServerError;
