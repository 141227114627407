export const columns = (t) => [
    {
        key: 'createDate',
        title: t('clientDashboard.clientPortal.createDate'),
    },
    {
        key: 'initiatedBy',
        title: t('clientDashboard.clientPortal.InitiatedBy'),
    },
    {
        key: 'action',
        title: t('clientDashboard.clientPortal.action'),
    },
    {
        key: 'expiration',
        title: t('clientDashboard.clientPortal.expiration'),
    },
];
export const actionMessageKeyByStatusId = (activationStatusId) => {
    switch (activationStatusId) {
        case 1:
        case 2:
            return 'clientDashboard.clientPortal.actions.ActivationCodeGenerated';
        case 3:
            return 'clientDashboard.clientPortal.actions.StartedRegistration';
        case 4:
            return 'clientDashboard.clientPortal.actions.StartedEmailVerified';
        case 5:
            return 'clientDashboard.clientPortal.actions.StartedMobileVerified';
        case 6:
            return 'clientDashboard.clientPortal.actions.CompletedAndVerified';
        case 7:
            return 'clientDashboard.clientPortal.actions.ActivationCodeDeactivated';
        case 8:
            return 'clientDashboard.clientPortal.actions.ActivationCodeExpired';
        default:
            return 'clientDashboard.clientPortal.actions.notDefined';
    }
};
