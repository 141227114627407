import { useState, useEffect } from 'react';
import { MOBILE_VIEWPORT_MIN_WIDTH, TABLET_VIEWPORT_MIN_WIDTH } from 'constants/constants';

const detectIsMobile = () => document.body.clientWidth <= MOBILE_VIEWPORT_MIN_WIDTH;
const detectIsTablet = () => document.body.clientWidth <= TABLET_VIEWPORT_MIN_WIDTH;
const detectIsLandscape = () => (global.screen.availWidth > global.screen.availHeight)
    && global.screen.availHeight < TABLET_VIEWPORT_MIN_WIDTH;

export const useDetectedMobileDevice = () => {
    const [isMobileSize, setMobileSize] = useState(detectIsMobile());
    const [isTabletSize, setTabletSize] = useState(detectIsTablet());
    const [isLandscape, setIsLandscape] = useState(detectIsLandscape());

    useEffect(() => {
        const handleResize = () => {
            setMobileSize(detectIsMobile());
            setTabletSize(detectIsTablet());
            setIsLandscape(detectIsLandscape());
        };

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return { isMobileSize, isTabletSize, isLandscape };
};
