import React from 'react';
import PropTypes from 'prop-types';
import Row from './Body/Row';
import Footer from './Footer/Footer';
import ScrollableColumns from './Header/ScrollableColumns';
import Pagination from '../Pagination';
import { pagingState, sortState } from './utils/types';
import './Table.css';
import { onRowFunction } from './utils/utils';
import HeaderMini from './Header/HeaderMini';
import NoDataCell from './NoDataCell';
import { createUniqueKey } from '../../utils';

const TableMini = (props) => {
    const {
        table,
        columns,
        data,
        footer,
        mini,
        paging,
        sorting,
        scrollable,
        tableBodyStyle,
        expandedRows,
        pagingTotal,
        noDataText,
        headerControls,
        classNameTable,
        classNameTableWrapper,
        onRow,
        onMouseDown,
        changeSorting,
        expandOnClick,
        changePagination,
        noHeader,
    } = props;

    return (
        <div
            ref={table}
            role="presentation"
            className={classNameTableWrapper}
            onMouseDown={onMouseDown}
            onPointerDown={onMouseDown}
        >
            {!noHeader && (
                <HeaderMini
                    columns={columns}
                    sorting={sorting}
                    changeSorting={changeSorting}
                    headerControls={headerControls}
                />
            )}
            <div className="Table-content" style={tableBodyStyle}>
                <table className={classNameTable}>
                    <ScrollableColumns scrollable={scrollable} columns={columns} mini={mini} />
                    <tbody>
                        {data.length
                            ? data.map((item, index) => (
                                <Row
                                    data={item}
                                    index={index}
                                    columns={columns}
                                    scrollable={scrollable}
                                    expandedRows={expandedRows}
                                    onRow={onRowFunction(onRow)}
                                    expandOnClick={expandOnClick}
                                    key={createUniqueKey(index, item.key)}
                                />
                            ))
                            : <NoDataCell colSpan={columns.length} noDataText={noDataText} />
                        }
                    </tbody>
                    {(!scrollable && footer) && <Footer columns={columns} footer={footer} />}
                </table>
            </div>
            {pagingTotal > data.length && (
                <Pagination {...paging} total={pagingTotal} onChange={changePagination} />
            )}
        </div>
    );
};

TableMini.propTypes = {
    table: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({ current: PropTypes.instanceOf(TableMini) }),
    ]),
    columns: PropTypes.arrayOf(PropTypes.shape({
        [PropTypes.string]: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
            PropTypes.shape,
            PropTypes.bool,
            PropTypes.func,
        ]),
    })),
    data: PropTypes.arrayOf(PropTypes.shape({
        [PropTypes.string]: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
            PropTypes.shape,
            PropTypes.func,
        ]),
    })),
    footer: PropTypes.shape({
        [PropTypes.string]: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
            PropTypes.func,
        ]),
    }),
    mini: PropTypes.bool,
    paging: pagingState,
    sorting: sortState,
    scrollable: PropTypes.bool,
    tableBodyStyle: PropTypes.shape({
        [PropTypes.string]: PropTypes.string,
    }),
    expandedRows: PropTypes.arrayOf(PropTypes.string),
    pagingTotal: PropTypes.number.isRequired,
    noDataText: PropTypes.string,
    headerControls: PropTypes.shape({
        selectControlsLabel: PropTypes.shape({
            selectAll: PropTypes.string,
            reset: PropTypes.string,
        }),
        sortByLabel: PropTypes.string,
    }).isRequired,
    classNameTable: PropTypes.string,
    classNameTableWrapper: PropTypes.string,
    onRow: PropTypes.func,
    onMouseDown: PropTypes.func,
    changeSorting: PropTypes.func,
    expandOnClick: PropTypes.func,
    changePagination: PropTypes.func,
    noHeader: PropTypes.bool,
};

TableMini.defaultProps = {
    table: { current: null },
    columns: [],
    data: [],
    footer: null,
    mini: true,
    paging: null,
    sorting: {},
    scrollable: false,
    tableBodyStyle: {},
    expandedRows: [],
    noDataText: 'No data',
    classNameTable: '',
    classNameTableWrapper: '',
    onRow: null,
    onMouseDown: () => { },
    changeSorting: () => { },
    expandOnClick: () => { },
    changePagination: () => { },
    noHeader: false,
};

export default TableMini;
