import Service from './service';
import { getDmsUrl } from '../../setup';
import { APIVersion, ServiceTypes } from '../../types/main';

class Documents extends Service {
    static postDocumentsList(data) {
        return Service.postRequest(`${getDmsUrl(APIVersion.V20)}/documents/search`, data, {
            isSecure: true,
            type: ServiceTypes.DMS
        });
    }

    static getDocumentById(documentId) {
        return Service.getRequest(`${getDmsUrl(APIVersion.V21)}/documents/${documentId}`, {
            isSecure: true,
            type: ServiceTypes.DMS
        });
    }

    static postDocument(data) {
        return Service.postRequest(`${getDmsUrl(APIVersion.V22)}/documents`, data, {
            isSecure: true,
            type: ServiceTypes.DMS
        });
    }
    static patchDocument(documentId, data) {
        return Service.patchRequest(`${getDmsUrl(APIVersion.V21)}/documents/${documentId}`, data, {
            isSecure: true,
            type: ServiceTypes.DMS
        });
    }

    static getMetadataByReferenceId(metadataReferenceId) {
        return Service.getRequest(`${getDmsUrl(APIVersion.V20)}/documents/metadata/reference/${metadataReferenceId}`, {
            isSecure: true,
            type: ServiceTypes.DMS
        });
    }
}

export default Documents;
