import withClientTable from '../hocs/withClientTable';
import ClientsTable from '../components/ClientsTable';
import { tableColumnsClient, tableColumnsNewConnection } from '../constants';

export const ClientList = withClientTable(
    ClientsTable, false, tableColumnsClient,
);

export const ClientListForNewConnection = withClientTable(
    ClientsTable, false, tableColumnsNewConnection,
);
