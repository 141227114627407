import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { AdvisoryTypeSelection } from 'widgets/AdvisoryTypeSelection';
import { steps } from 'datasource/useAdvisoryProcess';
import { ProductOfferSelection } from '../../components/product-offer-selection';

import './AdvisoryProcess.css';

export const AdvisoryProcess:FC<{datasource: () => any}> = ({ datasource }) => {
    const { t } = useTranslation();
    const {
        initiateAdvisoryProcess,
        shouldInitiateAdvisoryProcess,
        setShouldInitiateAdvisoryProcess,
        items,
        contactGroupIdSelector,
        setUploadedAdvisoryDocumentAction,
        stepComplete,
        startNewAdvisoryProcess,
        isAdvisoryDataLoading,
        step,
    } = datasource();

    if (shouldInitiateAdvisoryProcess === true && step === steps.AdvisoryProvider) {
        return (
            <AdvisoryTypeSelection
                contactGroupIdSelector={contactGroupIdSelector}
                setUploadedAdvisoryDocumentAction={setUploadedAdvisoryDocumentAction}
                stepComplete={stepComplete}
                startNewAdvisoryProcess={startNewAdvisoryProcess}
                isAdvisoryDataLoading={isAdvisoryDataLoading}
            />
        );
    }

    return (
        <div className="advisory-process">
            <p className="question">{t('advisoryDocumentInit.step.question')}</p>
            <p className="desc">{t('advisoryDocumentInit.step.desc')}</p>
            <div className="fi-products-selection">
                {items.map(item => (
                    <div>
                        <ProductOfferSelection
                            disabled={item.disabled}
                            isSelected={initiateAdvisoryProcess === item.id}
                            key={item.id}
                            productOffer={item}
                            onSelectionChange={setShouldInitiateAdvisoryProcess}
                        />
                        <p className="desc">{item.disabled && t('advisoryDocumentInit.step.roleNotSupported')}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};
