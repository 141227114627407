import React, { FC } from 'react';
import { Title } from 'ui-library';
import { useTranslation } from 'react-i18next';
import { ProductApplicationsProps } from './Applications.types';
import SingleApplicationRow from './SingleApplicationRow';
import './Applications.css';

const Applications: FC<ProductApplicationsProps> = ({
    applications,
    isAdvisoryExpired,
}) => {
    const { t } = useTranslation();

    return (
        <div className="product-applications">
            <Title type={2}>
                {t('ApplicationDetails.AdvisorySection.ProductApplications')}
            </Title>

            {applications?.map((application, index) => (
                <SingleApplicationRow
                    key={application?.applicationId || index}
                    data={application}
                    isAdvisoryExpired={isAdvisoryExpired}
                />
            ))}
        </div>
    );
};

export default Applications;
