import React, {
    forwardRef, useCallback, useEffect, useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';
import Checkbox from './Checkbox';
import { CHECKBOX_SIZE_DEFAULT, CHECKBOX_SIZES } from './constants';
import { optionsList } from '../../types';
import { createUniqueKey, transformOptions, getClassNames } from '../../utils';
import withTheme from '../../hocs/withTheme';
import FormControlMessage from '../FormControlMessage/FormControlMessage';
import FormGroup from '../FormGroup/FormGroup';

export const CheckboxGroup = (props) => {
    const {
        options, horizontal, className, defaultValue, innerRef: ref,
        onChange, value, size, error, isConditional, isInQuestionnaire, ...groupProps
    } = props;

    const [children, setChildren] = useState(props.children);
    const [values, setValues] = useState([]);

    const ids = useMemo(()=> {
        let items = [];
        if(values) {
            items = values.map((checkboxProps) => (`${checkboxProps.id || checkboxProps.value}:${Math.ceil(Math.random()*1000000)}`  ));
        }
        return items;
    }, [values.length]);

    const optionsWithChecked = useMemo(() => transformOptions(options).map((option) => ({
        ...option,
        checked: value.find((item) => item === option.value) !== undefined,
    })), [options, value]);

    useEffect(() => {
        setValues(optionsWithChecked);
    }, [optionsWithChecked]);

    const handleValues = (selectedValue, position, checked) => {
        if (!isConditional) {
            return values.map((item) => (item.value === selectedValue ? { ...item, checked } : item));
        }

        return values.map(item => {
            if (position === 1 && checked && item.position !== position) {
                return {...item, checked: false};
            }

            if (position !== 1 && checked && item.position === 1) {
                return {...item, checked: false};
            }

            return item.value === selectedValue ? {...item, checked} : item;
        });
    };

    const onChangeHandler = useCallback((selectedValue, position, checked) => {
        const mappedValue = handleValues(selectedValue, position, checked);

        setValues(mappedValue);
        onChange(mappedValue.filter((item) => item.checked).map((item) => item.value));
    }, [onChange, values]);

    useEffect(() => {
        if (values) {
            const items = values.map((checkboxProps, index) => (
                <Checkbox
                    key={createUniqueKey(index, checkboxProps.label)}
                    ref={ref}
                    size={size}
                    {...checkboxProps}
                    id={ids[index]}
                    error={error}
                    isInQuestionnaire={isInQuestionnaire}
                    defaultChecked={defaultValue?.find((item) => item === checkboxProps.value)}
                    onChange={(event) => onChangeHandler(checkboxProps.value, checkboxProps.position, event.target.checked)}
                />
            ));

            setChildren(items);
        }
    }, [values, onChangeHandler, defaultValue, error]);

    return (
        <FormGroup error={error}>
            <div
                {...groupProps}
                role="group"
                className={getClassNames(className, 'checkbox-group', { 'checkbox-group-horizontal': horizontal })}
            >
                {children}
                {error && (
                    <FormControlMessage>{error}</FormControlMessage>
                )}
            </div>
        </FormGroup>
    );
};

CheckboxGroup.propTypes = {
    /** Default selected value */
    defaultValue: PropTypes.arrayOf(PropTypes.string),
    /** Disable all checkboxes */
    disabled: PropTypes.bool,
    /** The name property of all input[type="checkbox"] children */
    name: PropTypes.string,
    /** Specifies options */
    options: optionsList,
    /** The size of checkboxes */
    size: PropTypes.oneOf(CHECKBOX_SIZES),
    /** Enable horizontal view */
    horizontal: PropTypes.bool,
    /** The callback function that is triggered when the state changes */
    onChange: PropTypes.func,
    /** @ignore */
    children: PropTypes.node,
    /** @ignore */
    className: PropTypes.string,
    /** Used for setting the currently selected value. */
    value: PropTypes.arrayOf(PropTypes.string),
    /** @ignore */
    innerRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({
            current: PropTypes.objectOf,
        }),
    ]),
    /** The error message displayed if the checkbox group is errored */
    error: PropTypes.string,
    isConditional: PropTypes.bool,
    isInQuestionnaire: PropTypes.bool
};

CheckboxGroup.defaultProps = {
    defaultValue: [],
    disabled: false,
    name: null,
    options: [],
    size: CHECKBOX_SIZE_DEFAULT,
    horizontal: false,
    onChange: () => {},
    children: null,
    className: null,
    value: [],
    innerRef: null,
    error: null,
    isConditional: false,
    isInQuestionnaire: false,
};

export default forwardRef((props, ref) => withTheme(CheckboxGroup)({ ...props, innerRef: ref }));
