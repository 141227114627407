import React, {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import {useTranslation} from 'react-i18next';
import {FEE_TYPES} from 'pages/ClientOverview/pages/Portfolios/pages/CreateNewPortfolio/constants';
import {Modal, Table} from '../../../../../../../../../../ui-library';
import {
    CHANGE_HISTORY_TYPE, getCommissionChangeHistoryColumns, getGeneralAgencyChangeHistoryColumns,
    getPbNumberChangeHistoryColumns,
} from '../../constants';
import ServiceManager from '../../../../../../../../../../services/ServiceManager';
import Preloader from '../../../../../../../../../../components/Preloader';
import {
    DEFAULT_PAGE_SIZE,
} from '../../../../../../../../../AdvisoryDashboard/components/BusinessTransactionsTable/constants/const';
import useFormatting from '../../../../../../../../../../locale/useFormatting';

const ChangeHistoryModal = ({
    type, onClose, portfolioId, selectedFeeType, investmentApplicationId,
}) => {
    const [data, setData] = useState();
    const [totalRows, setTotalRows] = useState();
    const [inProgress, setInProgress] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const {t} = useTranslation();
    const {getFormattedDate} = useFormatting();

    const tableColumns = useMemo(() => {
        switch (type) {
            case CHANGE_HISTORY_TYPE.pbNumber:
                return getPbNumberChangeHistoryColumns(t, getFormattedDate);
            case CHANGE_HISTORY_TYPE.generalAgency:
                return getGeneralAgencyChangeHistoryColumns(t, getFormattedDate);
            case CHANGE_HISTORY_TYPE.commission:
                return getCommissionChangeHistoryColumns(t, getFormattedDate, selectedFeeType !== FEE_TYPES.issueCommission);
            default:
                return [];
        }
    }, [getFormattedDate, t, type, selectedFeeType]);

    const getPbNumberChangeHistory = useCallback(async () => {
        try {
            setInProgress(true);
            const {data: responseData} = await ServiceManager.planService('getPbNumberChangeHistory', [{portfolioId, pageNumber: currentPage, pageSize: DEFAULT_PAGE_SIZE}]);

            setData(responseData?.pbNumberHistory);
            setTotalRows(responseData?.total);
        } finally {
            setInProgress(false);
        }
    }, [portfolioId, currentPage]);

    const getGeneralAgencyChangeHistory = useCallback(async () => {
        try {
            setInProgress(true);
            const {data: responseData} = await ServiceManager.planService('getGeneralAgencyChangeHistory', [{portfolioId, pageNumber: currentPage, pageSize: DEFAULT_PAGE_SIZE }]);

            setData(responseData.generalAgencyHistory);
            setTotalRows(responseData?.totalNumber);
        } finally {
            setInProgress(false);
        }
    }, [portfolioId, currentPage]);

    const getFeesChangeHistory = useCallback(async () => {
        try {
            setInProgress(true);
            const {data: responseData} = await ServiceManager.planService('getFeesChangeHistory', [{
                investmentApplicationId, pageNumber: currentPage, pageSize: DEFAULT_PAGE_SIZE, feeType: selectedFeeType,
            }]);

            setData(responseData.items);
            setTotalRows(responseData?.total);
        } finally {
            setInProgress(false);
        }
    }, [investmentApplicationId, currentPage, selectedFeeType]);

    const onChangePage = useCallback((paging) => {
        setCurrentPage(paging?.currentPage);
    }, []);

    useEffect(() => {
        switch (type) {
            case CHANGE_HISTORY_TYPE.pbNumber:
                (async () => getPbNumberChangeHistory())();
                break;
            case CHANGE_HISTORY_TYPE.generalAgency:
                (async () => getGeneralAgencyChangeHistory())();
                break;
            case CHANGE_HISTORY_TYPE.commission:
                (async () => getFeesChangeHistory())();
                break;
            default:
                break;
        }
    }, [type, getPbNumberChangeHistory, getGeneralAgencyChangeHistory, getFeesChangeHistory]);

    return (
        <Modal
            visible={!!type}
            title={t('planDetails.changeHistory.modalTitle')}
            onOk={onClose}
            cancelText={null}
            width={700}
            okButtonProps={{style: {minWidth: '100px', flex: 0}}}
            className="change-history-modal"
        >
            <Preloader isLoading={inProgress}>
                <Table
                    columns={tableColumns}
                    data={data}
                    onChange={onChangePage}
                    paging={{
                        total: totalRows,
                        pageSize: DEFAULT_PAGE_SIZE,
                        currentPage,
                    }}
                />
            </Preloader>
        </Modal>
    );
};

export default ChangeHistoryModal;
