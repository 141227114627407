import {setUserRegisterId, deleteUserRegisterId} from 'pages/ClientOnboardingFlow/helpers/userRegisterId';
import {useParams} from 'react-router-dom';

export const getUserId = () => {
    const {
        getParametrs,
    } = useParams();

    const urlParam = new URLSearchParams(getParametrs);
    const userRegisterId = urlParam?.get('id');

    if(urlParam) {
        setUserRegisterId(userRegisterId);
    } else {
        deleteUserRegisterId();
    }

    return userRegisterId;
};
