import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../../Icon';
import { ICON_TYPE_TOOLTIP } from '../../Icon/constants';
import Tooltip from '../../Tooltip';
import { getClassNames } from '../../../utils';

function CellTooltiped(props) {
    const value = typeof props === 'object' ? props.value : props;
    const showTooltip = value.length > props.maxLength;
    const className = getClassNames(
        'table-cell_tooltipped',
        { 'table-cell_tooltipped-show': showTooltip },
    );

    const trimmedValue = value.length > props.maxLength
        ? `${value.substring(0, props.maxLength)}...`
        : value;

    return (
        <span className={className}>
            <span>{trimmedValue}</span>
            {showTooltip && (
                <Tooltip
                    title={value}
                    className="table-tooltip"
                    trigger="click"
                >
                    <Icon className="icon__tooltipped" size={10} type={ICON_TYPE_TOOLTIP} />
                </Tooltip>
            )}
        </span>
    );
}

CellTooltiped.propTypes = {
    value: PropTypes.string,
    maxLength: PropTypes.number,
};

CellTooltiped.defaultProps = {
    value: null,
    maxLength: 20,
};

export default CellTooltiped;
