import { useCallback, useState } from 'react';
import ServiceManager from '../../../../../services/ServiceManager';
import HandlerError from '../../../../../errors/HandlerError';

export const useResendActivationLatter = ({ contactId }) => {
    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState();

    const sendData = useCallback(async () => {
        try {
            setLoading(true);

            await ServiceManager.customClientService('resendActivationLetter', [{ contactId }]);
        } catch (err) {
            HandlerError({
                setError,
                setLoading,
            });
            throw err;
        } finally {
            setLoading(false);
        }
    }, [contactId]);

    return {
        sendData,
        isLoading,
        error,
    };
};
