import {MAX_FILE_SIZE} from 'constants/constants';
import {getValidationMessage} from './messages';

export const validateFile = (data, additionalOptions = {}) => {
    const fileData = data;
    const {
        maxMbFileSize,
        sizeError,
        fileFormatError,
        fileTypesAllowed,
    } = additionalOptions;
    const maxFileSize = maxMbFileSize || MAX_FILE_SIZE;

    if (fileData === undefined || fileData === null || !fileData?.length) {
        return getValidationMessage().mandatoryError;
    }

    const results = Array.from(fileData)
        .map((file) => {
            if (fileTypesAllowed && !fileTypesAllowed.includes(file.type)) {
                return fileFormatError || getValidationMessage().format;
            }

            const fileSize = file.size / 1024 / 1024;

            if (fileSize > maxFileSize) {
                return sizeError || getValidationMessage().size;
            }

            return null;
        })
        .filter(f => !!f);

    return !results.length ? null : results;
};

export const validateUploadCount = (invalid, numberOfFiles) => {
    if (invalid) {
        return getValidationMessage(numberOfFiles).maxUploadCount;
    }

    return null;
};
