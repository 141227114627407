/* eslint-disable react/no-array-index-key */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, {
    useCallback, useEffect, useRef, useState,
} from 'react';
import { cloneDeep } from 'lodash/fp';
import { useTranslation } from 'react-i18next';
import { AxiosResponse } from 'axios';
import { Button, Title } from 'ui-library';
import PageSection from 'components/PageSection/PageSection';
import { withMainLayout } from 'hocs';
import Preloader from 'components/Preloader';
import Error from 'components/AtomicStructure/atoms/Error';
import { ContactGroupDetails } from 'components/Connections/types';

import { dateFormatZurich, formatDateWithoutLocalConversion } from 'utils/datetime';
import SM from 'services/ServiceManager';
import { getClientId } from 'utils';
import { DocItem } from './components/DocItem';

import './KYCPage.css';
import { UploadDocumentsModal } from './components/UploadDocumentsModal/UploadDocumentsModal';
import { getBase64FromFile } from '../../utils/file';
import { JaUserDoc } from './types';
import {
    useAdvisoryDocumentBase,
} from '../../pages/ClientOverview/pages/Portfolios/pages/CreateNewPortfolio/pages/AdvisoryDocument/hooks/useAdvisoryDocumentBase';
import { usePortfolioProcessNavigation } from '../../hooks/helpers/usePortfolioProcessNavigation';
import {
    useCreatePortfolioActions,
    useCreatePortfolioSelectors,
    useCreatePortfolioState,
} from '../../datasource/CreatePortfolio';

const KYCDocs = ({ dfsClientId, onPrevStep }) => {
    const [pageErrors, setPageErrors] = useState();

    const { t } = useTranslation();
    const contactId = getClientId(dfsClientId);

    // for JA
    const userDocsLoaded = useRef<boolean>(false);
    const usersLoaded = useRef<boolean>(false);

    const {
        isJointAccountSelector: isJA,
        contactGroupIdSelector,
    } = useCreatePortfolioSelectors();
    const {
        currentInvestmentApplicationId,
    } = useCreatePortfolioState();
    const {
        saveInvestmentApplicationDataAction,
    } = useCreatePortfolioActions();

    const [showModal, setShowModal] = useState<boolean>(false);
    const [showJaModal, setShowJaModal] = useState<boolean>(false);
    const [selectedUser, setSelectedUser] = useState<JaUserDoc | null>(null);

    const [oldDocuments, setOldDocuments] = useState<any>([]);
    const [data, setData] = useState<any>([]);
    const [error, setError] = useState<any>(null);

    const [preloading, setPreloading] = useState<boolean>(false);
    const [isLoading, setLoading] = useState(false);
    const [isUsersLoading, setIsUsersLoading] = useState(false);

    const { downloadDocument } = useAdvisoryDocumentBase(); // used for download of any (not only advisory) docs

    const [jaUsers, setJaUsers] = useState<JaUserDoc[]>([]);

    const fetchJaUsers = useCallback(async (connectionGroupId) => {
        setIsUsersLoading(true);
        const response: AxiosResponse<ContactGroupDetails> = await SM.customClientService('getContactGroupDetails', [{ groupId: connectionGroupId }]);

        const users: JaUserDoc[] = [];

        Object.entries(response?.data?.owners || {}).sort((a, b) => +a - +b).forEach(([key, value]) => users.push({
            id: key,
            name: value,
            oldDocs: [],
            docs: [],
        }));

        usersLoaded.current = true;
        setJaUsers(users);
        setIsUsersLoading(false);
    }, []);

    useEffect(() => {
        if (isJA && !usersLoaded.current) {
            fetchJaUsers(contactGroupIdSelector);
        }
    }, [isJA, contactGroupIdSelector, usersLoaded.current]);

    const fetchDocuments = useCallback(async () => {
        try {
            setPreloading(true);
            const { data: doc } = await SM.customDocumentsService(
                'getDocumentsByContactId',
                [{ contactId }],
            );

            setPreloading(false);
            setOldDocuments(doc?.id && doc?.id !== 0 ? [{ name: doc?.name, date: doc?.createDate, id: doc?.id }] : []);
            // You can handle the 'docs' data here if needed
        } catch (e: any) {
            // Handle errors here
        } finally {
            setPreloading(false);
        }
    }, [contactId]);

    const fetchJaDocuments = useCallback(async () => {
        try {
            setPreloading(true);

            const responses: Array<any> = await Promise.all(jaUsers.map(user => SM.customDocumentsService(
                'getDocumentsByContactId',
                [{ contactId: user.id }],
            ).then((res: AxiosResponse) => (res.data ? ({ ...res.data, userId: user.id }) : {}))));

            setJaUsers(prev => prev.map(usr => {
                const response = responses.find(f => f.userId === usr.id);

                if (response) {
                    return { ...usr, oldDocs: [{ name: response?.name, date: response?.createDate, id: response?.id }] };
                }

                return usr;
            }));

            userDocsLoaded.current = true;
        } catch (e: any) {
            // Handle errors here
        } finally {
            setPreloading(false);
        }
    }, [jaUsers]);

    useEffect(() => {
        if (isJA) return;

        fetchDocuments();
    }, [isJA]);

    useEffect(() => {
        if (isJA && jaUsers.length && !isUsersLoading && !userDocsLoaded.current) {
            fetchJaDocuments();
        }
    }, [isJA, jaUsers, isUsersLoading, userDocsLoaded.current]);

    const onModalSave = (files) => {
        setData(files);
        setShowModal(false);
        setError(null);
    };

    const onJaUserModalSave = (files) => {
        const usersCopy = cloneDeep(jaUsers);

        const user = usersCopy.find((u) => u.id === selectedUser?.id) as JaUserDoc;

        user.docs = files;
        setJaUsers(usersCopy);

        setShowJaModal(false);
        setSelectedUser(null);
        setError(prev => ({ ...prev, [user.id]: null }));
    };

    const onJaContinue = useCallback(async () => {
        if (!jaUsers.length) return;

        const noDocumentsUser = jaUsers.filter((user) => !user.oldDocs.length && !user.docs.length);

        if (noDocumentsUser.length) {
            setError(prev => {
                const newErrors = { ...prev };

                noDocumentsUser.forEach(user => {
                    newErrors[user.id] = t('3b.kyc.atLeastOneDoc');
                });

                return newErrors;
            });

            return;
        }

        setLoading(true);
        let allWentGood = true;

        for (const user of jaUsers) {
            if (user.docs.length) {
                const payload = {
                    ownerId: user.id,
                    documents: await Promise.all(user.docs.map(async (file) => ({
                        filename: file.name,
                        file: (await getBase64FromFile(file)).split(',')[1],
                    }))),
                };

                try {
                    const { data: apiData } = await SM.customDocumentsService('uploadIdDocument', [payload]);

                    if (!apiData) allWentGood = false;
                } catch (err: any) {
                    allWentGood = false;
                    setPageErrors(err?.message);
                }
            }
        }

        stepComplete();
        setLoading(false);
    }, [jaUsers]);

    const onOldDocumentDownload = async (id) => {
        await downloadDocument(id);
    };

    const onContinue = useCallback(async () => {
        if (isJA) {
            onJaContinue();

            return;
        }
        // implement data check

        if (data?.length > 0) {
            setLoading(true);

            try {
                const payload = {
                    ownerId: contactId,
                    documents: await Promise.all(data.map(async (file) => ({
                        filename: file.name,
                        file: (await getBase64FromFile(file)).split(',')[1],
                    }))),
                };

                const { data: apiData } = await SM.customDocumentsService('uploadIdDocument', [payload]);

                setLoading(false);
                if (!apiData) return;
                stepComplete();
            } catch (err: any) {
                setPageErrors(err?.message);
            }
        } else if (oldDocuments.length > 0) {
            stepComplete();
        }
        setError(t('3b.kyc.atLeastOneDoc'));
    }, [
        dfsClientId, data, contactId, oldDocuments, jaUsers,
    ]);

    const { stepComplete } = usePortfolioProcessNavigation({
        saveOnContinue: onContinue,
        saveOnBack: onPrevStep,
        enableAutoStepComplete: false,
        enableAutoStepBack: false,
        continueDisabled: preloading || isLoading || isUsersLoading,
        continueInProgress: isLoading || isUsersLoading,
        pageErrors,
    });

    return (

        <PageSection className="newStyle kyc">
            <div className="newStyleContent">
                <Preloader isLoading={preloading || isLoading || isUsersLoading} >
                    <div className="container">
                        <Title type={1}>
                            {t('3b.kyc.docTitle')}
                        </Title>

                        {!isJA ? (
                            <div className="kyc-docs">
                                <p>{t('3b.kyc.idOrPassport')}</p>
                                <div className="docs-container">
                                    { data.length
                                        ? data?.map((f) => (
                                            <DocItem
                                                name={f?.name}
                                                date={formatDateWithoutLocalConversion(new Date(f?.lastModifiedDate), dateFormatZurich).toString()}
                                                // @ts-ignore
                                                onDownload={() => global.open(URL.createObjectURL(f), '_blank')}
                                            />
                                        ))
                                        : oldDocuments?.map((f) => (
                                            <DocItem
                                                name={f?.name}
                                                date={formatDateWithoutLocalConversion(new Date(f?.date), dateFormatZurich).toString()}
                                                onDownload={onOldDocumentDownload}
                                                id={f?.id}
                                            />
                                        ))}
                                </div>
                                <Button
                                    type="secondary"
                                    size="small"
                                    onClick={() => setShowModal(true)}
                                    className="header_button"
                                >
                                    <span>{t('3b.kyc.uploadNewDoc')}</span>
                                </Button>
                                {error && <Error>{error}</Error>}
                            </div>
                        ) : (
                            <div className="kyc-docs kyc-multiple-docs">
                                {jaUsers.map((user) => (
                                    <div key={user.id}>
                                        <p>{`${t('3b.kyc.idOrPassport')} ${user.name}`}</p>
                                        <div className="docs-container">
                                            { user.docs.length
                                                ? user.docs?.map((f) => (
                                                    <DocItem
                                                        key={`${f?.name}${user.id}`}
                                                        name={f?.name}
                                                        date={formatDateWithoutLocalConversion(new Date(f?.lastModifiedDate), dateFormatZurich).toString()}
                                                        // @ts-ignore
                                                        onDownload={() => global.open(URL.createObjectURL(f), '_blank')}
                                                    />
                                                ))
                                                : user.oldDocs?.map((f) => (
                                                    <DocItem
                                                        key={`${f?.name}${user.id}`}
                                                        name={f?.name}
                                                        date={formatDateWithoutLocalConversion(new Date(f?.date), dateFormatZurich).toString()}
                                                        onDownload={onOldDocumentDownload}
                                                        id={f?.id}
                                                    />
                                                ))}
                                        </div>
                                        <Button
                                            type="secondary"
                                            size="small"
                                            onClick={() => {
                                                setSelectedUser(user);
                                                setShowJaModal(true);
                                            }}
                                            className="header_button"
                                        >
                                            <span>{t('3b.kyc.uploadNewDoc')}</span>
                                        </Button>
                                        {error?.[user?.id] && <Error>{error?.[user?.id]}</Error>}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </Preloader>
            </div>
            {showModal && <UploadDocumentsModal show={showModal} onClose={() => setShowModal(false)} onOk={onModalSave} />}
            {showJaModal && (
                <UploadDocumentsModal
                    show={showJaModal}
                    onClose={() => {
                        setShowJaModal(false);
                        setSelectedUser(null);
                    }}
                    onOk={onJaUserModalSave}
                />
            )}

        </PageSection>

    );
};

export default withMainLayout(KYCDocs);
