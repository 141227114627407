export type SortableItemType = {
    id: number|string,
    label: string,
    listKey: string,
    disabled?: boolean
    value?: string | number
}

type ObjectType = {
    [propName: string|number]: string|number|boolean
}

export const sortList = (list:Array<SortableItemType>, modifyList:Array<SortableItemType>): Array<SortableItemType>|null => {
    if (list === undefined || list === null) return null;

    modifyList.forEach((value) => {
        const index = list.findIndex((el) => el?.id === value?.id);
        const newLabel = list[index]?.label;

        list.splice(index, 1);
        list.unshift({ id: value?.id, label: newLabel, listKey: value?.listKey });
    });

    return list;
};

export const sortByAlphabet = (list: Array<SortableItemType>): Array<SortableItemType>|null => {
    if (list === undefined || list === null) return null;

    list.sort((a, b) => {
        if (a?.label < b?.label) { return -1; }
        if (a?.label > b?.label) { return 1; }

        return 0;
    });

    return list;
};

export const toLowerKeys = (obj:Array<ObjectType>) => obj.map(x => Object.fromEntries(Object.entries(x).map(
    ([key, value]) => [key, typeof value === 'string' ? value.toLowerCase() : value],
)));
