import { adaptAnswers } from 'adaptors/adaptAnswers';
import EmptyContent from 'components/EmptyContent';
import Preloader from 'components/Preloader';
import QuestionnaireGroup from 'components/QuestionnaireGroup';
import { useCreatePortfolioSelectors, useCreatePortfolioState } from 'datasource/CreatePortfolio';
import HandlerError from 'errors/HandlerError';
import { usePortfolioProcessNavigation } from 'hooks/helpers/usePortfolioProcessNavigation';
import { useClientProfile } from 'prodivers/clientProfile';
import React, {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { getClientId } from 'utils';
import handlerRequestCanceling from 'utils/handlerRequestCanceling';
import { adaptQualityCheckQuestionnaire } from 'widgets/QualityCheck/adapters/adaptQuestionnaire';
import { formDataSelector, questionnaireSelector } from './selectors';
import { useSelector } from './useSelector';

function QualityCheck() {
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isSending, setSending] = useState(false);
    const { dfsClientId } = useParams();
    const clientId = getClientId(dfsClientId);

    const { t } = useTranslation();

    // Client Provider
    const { ...information } = useClientProfile();
    const {
        currentInvestmentApplicationId,
    } = useCreatePortfolioState();
    const {
        isAdvisoryProcessSelector,
        is3bProductGroupSelector,
        containerIdSelector,
    } = useCreatePortfolioSelectors();
    const [pageErrors, setPageErrors] = useState();
    const {
        data, isLoading, getQuestionnaire, validateQuestionnaire,
        isLoadingPost, postQuestionnaire, error: postuseQuestionnaireError,
    } = useSelector(questionnaireSelector);
    const qualityCheckProfileGroup = data?.Groups?.[1];
    const groupId = qualityCheckProfileGroup?.Id;
    const {
        data: formData, initializeData, saveData, isInitDone,
    } = useSelector(formDataSelector);

    const financialServiceQuestion = useMemo(
        () => qualityCheckProfileGroup?.Steps?.[0].Questions?.find(
            ({ Type }) => (Type === 'SingleAnswer'),
        ),
        [qualityCheckProfileGroup?.Steps?.[0].Questions],
    );
    const {
        isValid: allQuestionsFilled, errors,
    } = useMemo(
        () => validateQuestionnaire({ groupId, answers: formData }),
        [formData, groupId, validateQuestionnaire],
    );

    useEffect(() => {
        if (containerIdSelector) {
            getQuestionnaire({
                id: is3bProductGroupSelector ? containerIdSelector : currentInvestmentApplicationId,
                isContainer: is3bProductGroupSelector,
                ...{ adapter: adaptQualityCheckQuestionnaire },
            });
        }
    }, [getQuestionnaire, currentInvestmentApplicationId, containerIdSelector, is3bProductGroupSelector]);
    useEffect(() => {
        if (!formData && data?.Groups?.length) {
            const groupIds = data.Groups.map(({ Id }) => Id);

            initializeData({ groupIds });
        }
    }, [data, formData, initializeData]);
    useEffect(() => {
        if (isInitDone) {
            const questions = [];

            if (!financialServiceQuestion?.Value?.Ids?.length) {
                const answerIdToSelect = isAdvisoryProcessSelector ? 1 : 0;

                questions.push({ questionId: financialServiceQuestion?.Id, value: financialServiceQuestion?.Answers[answerIdToSelect].Id });
            }

            if (questions?.length) {
                saveData({ groupId, questions });
            }
        }
    }, [
        isInitDone,
        groupId,
        isAdvisoryProcessSelector,
    ]);

    // Handlers/Callbacks
    const onContinue = useCallback(async () => {
        setIsSubmitted(true);

        if (allQuestionsFilled) {
            setSending(true);
            try {
                const answers = adaptAnswers({ data, values: formData });

                await postQuestionnaire({
                    answers,
                    id: is3bProductGroupSelector ? containerIdSelector : currentInvestmentApplicationId,
                    isContainer: is3bProductGroupSelector,
                });
                stepComplete();
            } catch (err) {
                handlerRequestCanceling(
                    HandlerError({
                        setError: setPageErrors,
                        setLoading: setSending,
                    }),
                )(err);
                setPageErrors();
            } finally {
                setSending(false);
                setIsSubmitted(false);
            }
        }
    }, [
        allQuestionsFilled, clientId, data,
        formData, postQuestionnaire, is3bProductGroupSelector,
        containerIdSelector, currentInvestmentApplicationId,
    ]);

    const onChange = useCallback(({ questionId, value }) => {
        saveData({ groupId, questions: [{ questionId, value }] });
    }, [groupId, saveData]);

    useEffect(() => {
        setPageErrors(postuseQuestionnaireError);
    }, [postuseQuestionnaireError]);

    const { stepComplete } = usePortfolioProcessNavigation({
        saveOnContinue: onContinue,
        enableAutoStepComplete: false,
        navigationDisabled: information?.isLoading || isLoading || isLoadingPost || isSending,
        pageErrors,
    });

    return (
        <Preloader
            isLoading={information?.isLoading || isLoading || isLoadingPost || isSending}
        >
            <div className="new-portfolio-content">
                <EmptyContent data={data} text={t('advisoryDashboard.noData')}>
                    <QuestionnaireGroup
                        data={data?.Groups?.[1]}
                        title={data?.Groups?.[1]?.Name}
                        value={formData?.[groupId] || {}}
                        errors={isSubmitted ? errors : {}}
                        onChange={onChange}
                    />
                </EmptyContent>
            </div>
        </Preloader>
    );
}

export default QualityCheck;
