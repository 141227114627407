import React, {Dispatch} from 'react';
import {User, UserManager} from 'oidc-client';

export interface OidcState {
    oidcUser: User | null;
    userManager: UserManager;
    isLoading: boolean;
    error: unknown;
    isLoggingOut: boolean;
}

export interface UseAuthenticationContextStateType {
    onError: (error: unknown) => void;
    onLoadUser: (user: User | null) => void;
    onLoading: () => void;
    onUnloadUser: () => void;
    onLogout: () => void;
    oidcState: OidcState;
}

const ON_LOADING = 'ON_LOADING';
const ON_ERROR = 'ON_ERROR';
const ON_LOAD_USER = 'ON_LOAD_USER';
const ON_UNLOAD_USER = 'ON_UNLOAD_USER';
const ON_LOGOUT = 'ON_LOGOUT';

type OidcAction =
    | { type: typeof ON_LOADING }
    | { type: typeof ON_ERROR; message: unknown }
    | { type: typeof ON_LOAD_USER, user: User | null }
    | { type: typeof ON_UNLOAD_USER }
    | { type: typeof ON_LOGOUT };

const oidcReducer = (oidcState: OidcState, action: OidcAction): OidcState => {
    switch (action.type) {
        case ON_ERROR:
            return {...oidcState, error: action?.message, isLoading: false};
        case ON_LOADING:
            return {...oidcState, isLoading: true};
        case ON_LOAD_USER:
            return {...oidcState, oidcUser: action.user, isLoading: false};
        case ON_UNLOAD_USER:
            return {...oidcState, oidcUser: null, isLoading: false};
        case ON_LOGOUT:
            return {...oidcState, isLoggingOut: true};
        default:
            return oidcState;
    }
};

const getDefaultState = (userManagerInt: UserManager): OidcState => ({
    oidcUser: null,
    userManager: userManagerInt,
    isLoading: true,
    error: '',
    isLoggingOut: false,
});
const onError = (dispatch: Dispatch<OidcAction>) => (message: unknown) => (
    dispatch({type: ON_ERROR, message})
);
const onLoadUser = (dispatch: Dispatch<OidcAction>) => (user: User | null) => (
    dispatch({type: ON_LOAD_USER, user})
);
const onLoading = (dispatch: Dispatch<OidcAction>) => () => dispatch({type: ON_LOADING});
const onUnloadUser = (dispatch: Dispatch<OidcAction>) => () => dispatch({type: ON_UNLOAD_USER});
const onLogout = (dispatch: Dispatch<OidcAction>) => () => dispatch({type: ON_LOGOUT});

export const useAuthenticationState = (
    userManagerInt: UserManager,
): UseAuthenticationContextStateType => {
    const defaultState = getDefaultState(userManagerInt);
    const [oidcState, dispatch] = React.useReducer(oidcReducer, defaultState);

    return {
        onError: React.useCallback((error) => onError(dispatch)(error), []),
        onLoadUser: React.useCallback((user) => onLoadUser(dispatch)(user), []),
        onLoading: React.useCallback(() => onLoading(dispatch)(), []),
        onUnloadUser: React.useCallback(() => onUnloadUser(dispatch)(), []),
        onLogout: React.useCallback(() => onLogout(dispatch)(), []),
        oidcState,
    };
};
