import { compose, applyMiddleware } from 'redux';
import { save as saveSession } from 'middleware/redux-sessionstorage';
import thunk from 'redux-thunk';
import { getStore, setStore } from './redux-store/generateStore';
import rootReducer from './rootReducer';

const composeEnhancer = global.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

setStore(rootReducer, composeEnhancer(applyMiddleware(
    thunk,
    saveSession({ states: ['auth'] }),
)));

export default getStore();
