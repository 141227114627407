import {
    ICON_TYPE_MENU, ICON_TYPE_LOGIN, ICON_TYPE_EXIT,
    ICON_TYPE_CLOSE, ICON_TYPE_ARROW, ICON_TYPE_DELAY,
    ICON_TYPE_LOGIN_CLIENT,
} from '../Icon/constants';

export const HEADER_ICONS_TYPE = [
    ICON_TYPE_MENU,
    ICON_TYPE_LOGIN,
    ICON_TYPE_CLOSE,
    ICON_TYPE_ARROW,
    ICON_TYPE_DELAY,
    ICON_TYPE_EXIT,
    ICON_TYPE_LOGIN_CLIENT,
];

export const HEADER_STACKED_TYPE = 'stacked';
export const HEADER_CENTERED_TYPE = 'centered';
export const HEADER_STANDARD_TYPE = 'standard';
export const HEADER_RIGHT_BAR_TYPE = 'rightBar';
export const HEADER_SIDE_BAR_TYPE = 'sideBar';

export const HEADER_TYPES = [
    HEADER_STACKED_TYPE,
    HEADER_CENTERED_TYPE,
    HEADER_STANDARD_TYPE,
    HEADER_RIGHT_BAR_TYPE,
    HEADER_SIDE_BAR_TYPE,
];
