import {
    getClientServiceHeader,
    getServicesUrl,
} from '../../setup';
import { APIVersion } from '../../types/main';
import Service from './service';

class ContactManagement extends Service {
    static getClientProfile(contactId, language = 'en-US') {
        return Service.getRequest(
            `${getServicesUrl(APIVersion.V22)}/contacts/${contactId}/profile/${language}`,
            { isSecure: true },
        );
    }

    static getClientPicture(contactId) {
        return Service.getRequest(
            `${getServicesUrl()}/contacts/${contactId}/profile-picture`,
            { isSecure: true },
        );
    }

    static getClientMemberPicture(contactId, memberId) {
        return Service.getRequest(
            `${getServicesUrl()}/contacts/${contactId}/member/${memberId}/profilepicture`,
            { isSecure: true },
        );
    }

    static getMembersProfile(internalUserId) {
        return Service.getRequest(
            `${getServicesUrl()}/members/${internalUserId}/profile/`,
            { isSecure: true },
        );
    }

    static getContactMembers(contactId) {
        return Service.getRequest(
            `${getServicesUrl()}/contacts/${contactId}/member-profiles`,
            { isSecure: true, axiosSetup: { headers: getClientServiceHeader() } },
        );
    }

    static getOnBoardingData(contactId, language) {
        return Service.getRequest(`${getServicesUrl(APIVersion.V12)}/contacts/${contactId}/private/${language}`, { isSecure: true });
    }

    static getOnBoardingLanguageData() {
        return Service.getRequest(`${getServicesUrl()}/countries/10`, { isSecure: true });
    }

    static postOnBoardingData(data, contactId) {
        return Service.putRequest(`${getServicesUrl(APIVersion.V11)}/contacts/${contactId}/private`, {
            Gender: data.Gender,
            FirstName: data.FirstName,
            LastName: data.LastName,
            AddressLine1: data.Street,
            AddressLine3: data.StreetNumber,
            AddressLine4: data.PostalCode,
            AddressLine5: data.City,
            AddressLine6: data.State,
            Country: data.Country,
            NationalityId: data.NationalityId,
            PreferredLanguageCultureCode: data.PreferredLanguageCultureCode,
            BirthDate: data.BirthDate,
            MobileNumber: data.MobileNumber,
            PhoneNumber: data.PhoneNumber,
        }, { isSecure: true });
    }

    static getContactsByFilter(memberId, filter) {
        return Service.postRequest(`${getServicesUrl(APIVersion.V11)}/members/${memberId}/hierarchy/contacts/filter`, {
            ...filter,
        }, { isSecure: true });
    }

    static patchProspect(contactId, data) {
        return Service.patchRequest(
            `${getServicesUrl()}/contacts/${contactId}/prospect`, data, { isSecure: true },
        );
    }

    static putPrivate(data, contactId) {
        return Service.putRequest(`${getServicesUrl(APIVersion.V11)}/contacts/${contactId}/private`, data, { isSecure: true });
    }

    static postContactsGroups(contactId, data) {
        return Service.postRequest(`${getServicesUrl(APIVersion.V20)}/contacts/${contactId}/contactgroups`, data, { isSecure: true });
    }

    static getContactsGroups(contactId, contactGroupId) {
        return Service.getRequest(`${getServicesUrl(APIVersion.V20)}/contacts/${contactId}/contactgroups/${contactGroupId}`, { isSecure: true });
    }
}

export default ContactManagement;
