import React from 'react';
import ApplicationDetailsHeader from 'components/ApplicationDetails/ApplicationDetailsHeader';
import Applications from 'components/ApplicationDetails/Applications';
import AdvisorySection from 'components/ApplicationDetails/AdvisorySection';
import { Infobox } from 'ui-library';
import { ICON_INFO_OUTLINE } from 'ui-library/components/Icon';
import './ApplicationDetails.css';
import { useTranslation } from 'react-i18next';
import { useApplicationDetails } from 'datasource/useApplicationDetails';
import Preloader from '../../components/Preloader';

const ApplicationDetails = () => {
    const {
        advisoryData, applicationsData, isAdvisoryExpired,
        proofOfAssetDocuments, advisoryDocument, basicAgreementDocuments, isAdvisoryCase, isApplicationsLoading,
    } = useApplicationDetails();
    const { t } = useTranslation();

    return (
        <Preloader isLoading={isApplicationsLoading}>
            <div className="application-details">
                <ApplicationDetailsHeader/>
                {advisoryData && (
                    <AdvisorySection
                        isAdvisoryCase={isAdvisoryCase}
                        advisoryDocument={advisoryDocument}
                        proofOfAssetDocuments={proofOfAssetDocuments}
                        basicAgreementDocuments={basicAgreementDocuments}
                        data={advisoryData}
                        isExpired={isAdvisoryExpired}
                        isRenderGeneralDocumentsInProgress={false}
                    />
                )}
                {isAdvisoryExpired && (
                    <Infobox error icon={ICON_INFO_OUTLINE} className="application-detail--advisory-error">
                        {t('ApplicationDetails.AdvisorySection.AdvisoryError')}
                    </Infobox>
                )}
                <Applications applications={applicationsData} isAdvisoryExpired={isAdvisoryExpired} />
            </div>
        </Preloader>
    );
};

export default ApplicationDetails;
