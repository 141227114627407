import Service from 'additiv-services/services/core/service';
import { getServicesUrl } from 'additiv-services/setup';

export class ContactIdentityServices extends Service {
    static postActivationKey(contactId, data) {
        return Service.postRequest(`${getServicesUrl('v1.1')}/contacts/${contactId}/activationkey/send`, data, {
            isSecure: true,
        });
    }
}
