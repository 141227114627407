import { useCallback, useMemo, useState } from 'react';
import { usePortfolioProcessNavigation } from 'hooks/helpers/usePortfolioProcessNavigation';
import { notification } from 'ui-library';
import { useTranslation } from 'react-i18next';
import { ProductPersonalDetailsRequest } from 'core/types/api/Api';
import usePersonalDetails from './hooks/usePersonalDetails';
import { useCreatePortfolioActions, useCreatePortfolioSelectors } from '../CreatePortfolio';

export const useMultiplePersonalDetails = () => {
    const { t } = useTranslation();
    const [continueInProgress, setContinueInProgress] = useState(false);
    const {
        saveInvestmentApplicationDataAction,
    } = useCreatePortfolioActions();
    const {
        isDependantOnAffiliatedWithPensionFundSelector,
        currentClientPersonalDetailsDataSelector,
        jointClientPersonalDetailsDataSelector,
        isJointAccountSelector,
        contactGroupIdSelector,
        is3bProductGroupSelector,
    } = useCreatePortfolioSelectors();

    const clientPersonalDetails = usePersonalDetails({
        personalDetails: currentClientPersonalDetailsDataSelector,
    });
    const jointPersonalDetails = usePersonalDetails({
        personalDetails: jointClientPersonalDetailsDataSelector,
    });

    const onContinue = useCallback(async () => {
        try {
            setContinueInProgress(true);
            let isAnyFormErrors = false;
            let dataToSave;

            if (clientPersonalDetails?.onContinue) {
                if (!isAnyFormErrors) {
                    dataToSave = await clientPersonalDetails?.onContinue();
                }

                isAnyFormErrors = isAnyFormErrors || !dataToSave;
            }
            if (isJointAccountSelector && jointPersonalDetails?.onContinue) {
                isAnyFormErrors = isAnyFormErrors || !(await jointPersonalDetails?.onContinue());
            }

            if (isAnyFormErrors) {
                return;
            }

            const payload:ProductPersonalDetailsRequest = {
                occupation: parseInt(dataToSave?.occupation ?? '', 10),
                memberBevSwitzerland: !!dataToSave?.member,
                destinataryVita: !!dataToSave?.destinedLife,
                zurichEmployee: !!dataToSave?.zurichMA,
            };

            if (isDependantOnAffiliatedWithPensionFundSelector) {
                payload.affiliatedToPensionFund = dataToSave?.affiliatedPensionFund === 'true';
            }
            const response = await saveInvestmentApplicationDataAction({
                method: 'savePersonalDetails',
                payload,
            });

            if (response?.status === 200) {
                stepComplete();
            }
        } catch (error: any) {
            notification.open({ content: `${t('contactGroups.somethingWentWrong')} ${error?.message}`, type: 'error' });
        } finally {
            setContinueInProgress(false);
        }
    }, [
        clientPersonalDetails?.onContinue,
        clientPersonalDetails?.data,
        jointPersonalDetails?.onContinue,
        jointPersonalDetails?.data,
        isJointAccountSelector,
    ]);

    const { stepComplete } = usePortfolioProcessNavigation({
        saveOnContinue: onContinue,
        enableAutoStepComplete: false,
        continueInProgress,
        backDisabled: !is3bProductGroupSelector,
    });

    return {
        client: clientPersonalDetails,
        jointClient: jointPersonalDetails,
        isJA: isJointAccountSelector,
        contactGroupId: contactGroupIdSelector,
        isPageLoading: useMemo(() => (isJointAccountSelector
            ? clientPersonalDetails?.isPageLoading || jointPersonalDetails?.isPageLoading : clientPersonalDetails?.isPageLoading
        ), [clientPersonalDetails?.isPageLoading, jointPersonalDetails?.isPageLoading, isJointAccountSelector]),
    };
};
