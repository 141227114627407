import ContactManagement from 'additiv-services/services/core/contactManagement';
import AdapterError from 'errors/AdapterError';
import ServerError from 'errors/ServerError';

export const SET_USER = 'client-dashboard/auth/SET_USER';

export function setUser(payload) {
    return { type: SET_USER, payload };
}

export function getUser({ profileId }) {
    return (dispatch) => (
        new Promise((resolve, reject) => {
            ContactManagement.getMembersProfile(profileId)
                .then((memberProfileResponse) => {
                    try {
                        global.localStorage.setItem('memberId', profileId);
                        const data = {
                            memberProfile: memberProfileResponse.data,
                        };

                        dispatch(setUser(data));
                        resolve(data);
                    } catch (err) {
                        throw new AdapterError(err);
                    }
                })
                .catch((err) => {
                    reject(err.type !== undefined ? err : new ServerError(err));
                });
        })
    );
}
