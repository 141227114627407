import React from 'react';
import PropTypes from 'prop-types';
import RcSteps from 'rc-steps';
import { ICON_TYPE_VERIFIED, ICON_TYPE_RADIO } from '../Icon/constants';
import {
    STEP_STATUS_TYPES,
    STEP_STATUS_FINISH,
    STEP_STATUS_PROCESS,
} from './constants';
import Icon from '../Icon';
import withTheme from '../../hocs/withTheme';

const { Step: RcStep } = RcSteps;

const iconTypesMap = new Map([
    [STEP_STATUS_FINISH, ICON_TYPE_VERIFIED],
    [STEP_STATUS_PROCESS, ICON_TYPE_RADIO],
]);

function Step(props) {
    const icon = <Icon type={iconTypesMap.get(props.status)} />;

    return (
        <RcStep icon={icon} {...props} />
    );
}

Step.propTypes = {
    /** The status of current step */
    status: PropTypes.oneOf(STEP_STATUS_TYPES),
    /** Title shown below the step icon */
    title: PropTypes.string,
};

Step.defaultProps = {
    status: null,
    title: null,
};

export default withTheme(Step);
