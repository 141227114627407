import { createContext } from 'react';

const initialContext = {
    clientId: undefined,
    portfolioId: undefined,
    personalDetails: {},
    layout: {},
    riskProfile: {},
    strategy: {},
};

const ChangeStrategyContext = createContext(initialContext);

export default ChangeStrategyContext;
