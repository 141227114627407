import React from 'react';

export const columns = ({
    t, currency,
}) => [
    {
        key: 'name',
        title: t('portfolio.details.positionTable.column.name'),
    },
    {
        key: 'currency',
        title: t('portfolio.details.positionTable.column.currency'),
    },
    {
        key: 'units',
        title: t('portfolio.details.positionTable.column.valueDate'),
        className: 'text-right',
    },
    {
        key: 'buyPrice',
        title: (
            <>
                {`${t('portfolio.details.positionTable.column.buyPrice')}*`}
                <span>
                    {` ${t('portfolio.details.column.in')} ${currency}`}
                </span>
            </>
        ),
        className: 'text-right',
    },
    {
        key: 'valuation',
        title: (
            <>
                {t('portfolio.details.positionTable.column.valuation')}
                <span>
                    {` ${t('portfolio.details.column.in')} ${currency}`}
                </span>
            </>
        ),
        className: 'text-right',
    },
    {
        key: 'rateDate',
        title: t('portfolio.details.positionTable.column.rateDate'),
    },
    {
        key: 'performancePtge',
        title: (
            <>
                {t('portfolio.details.positionTable.column.performanceCCY')}
                <span>
                    {` ${t('portfolio.details.column.sinceDebutInPercentage')} `}
                </span>
            </>
        ),
        className: 'text-right',
    },
    {
        key: 'allocation',
        title: (
            <>
                {t('portfolio.details.positionTable.column.allocation')}
                <span>
                    {` ${t('portfolio.details.column.inPercentage')} `}
                </span>
            </>
        ),
        className: 'text-right',
    },
    {
        key: 'marketValue',
        title: (
            <>
                {t('portfolio.details.positionTable.column.marketValue')}
                <span>
                    {` ${t('portfolio.details.column.in')} ${currency}`}
                </span>
            </>
        ),
        className: 'text-right',
    },
];
