import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import CellWithIcon from './CellWithIcon';

function CellWithHyperlinkIcon({
    type, href, size, className,
}) {
    const cellWithIcon = CellWithIcon(type, size);

    return (
        <Link to={{ pathname: href }} target="_parent" className={className}>
            {cellWithIcon}
        </Link>
    );
}

CellWithHyperlinkIcon.propTypes = {
    type: PropTypes.string.isRequired,
    size: PropTypes.number,
    href: PropTypes.string.isRequired,
    className: PropTypes.string,
};

CellWithHyperlinkIcon.defaultProps = {
    size: 20,
    className: undefined,
};

export default CellWithHyperlinkIcon;
