import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {NoteProps} from './Note.types';
import {Icon} from '../../../ui-library';
import {ICON_INFO_SLIM} from '../../../ui-library/components/Icon';

import './Note.css';

const Note: FC<NoteProps> = ({
    text,
    showIcon = true,
}) => {
    const {t} = useTranslation();

    return (
        <section className="note-container">
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore */}
            {showIcon && <Icon type={ICON_INFO_SLIM} size={24}/>}
            <p>{t(text)}</p>
        </section>
    );
};

export default Note;
