import {
    SET_USER,
} from './authActions';

const initState = {
    memberProfile: undefined,
    is: undefined,
    isAuthenticated: false,
    isLoading: false,
};

export default function reducer(state = initState, action = {}) {
    let nextState = {};

    switch (action.type) {
        case SET_USER:
            nextState = {
                ...state,
                isAuthenticated: true,
                isLoading: false,
                memberProfile: action.payload.memberProfile,
                is: action.payload.identityServer,
            };

            return nextState;

        default:
            return state;
    }
}
