import { DefaultError } from '@tanstack/react-query';
import SM from 'services/ServiceManager';
import { useErrorWrappedAPIMutation } from 'hooks/mutations/useErrorWrappedAPIMutation';

export const useManualDocumentSign = () => useErrorWrappedAPIMutation<any, DefaultError, {
        file: string,
        documentId: number,
    }>({
        mutationFn: async (payload) => {
            const { data } = await SM.customDocumentsService(
                'manualDocumentSign',
                [payload],
            );

            return data;
        },
    });
