import { formatDate } from 'utils/datetime';
import { changeMonthWithDay } from '../helpers/convertDate';
import {checkNullData} from '../../../../../../utils';
import {TARGET_FIELDS} from '../../../../../../components/MailingAddress/MailingAddress';

export const sendModel = (data) => {
    const payload = {
        personalInformation: {
            firstName: data?.firstName?.trim(),
            lastName:
    data?.lastName?.trim(),
            dateOfBirth:
    data?.dateOfBirth && formatDate(changeMonthWithDay(data.dateOfBirth), 'YYYY-MM-DD'),
            gender:
    +data?.gender,
            maritalStatus:
    +data?.maritalStatus,
            language:
    +data?.language,
            countryOfBirth:
    +data?.countryOfBirth,
            taxDomicile:
    +data?.countryOfResidence,
            identification:
    [{
        countryOfNationality: +data?.countryOfNationality,
    },
    ],
        },
        communicationMethods: {
            primaryEmail: data?.primaryEmail,
            primaryPhoneNumber:
        data?.primaryPhoneNumber,
            primaryAddressCountry:
        +data?.countryOfResidence,
            primaryAddress:
        {
            primaryAddressLine3: `${data.street}`,
            primaryAddressLine4:
            data?.PLZ,
            primaryAddressLine5:
            data?.ORT,
        },
            mailRecipient: {
                mailRecipientGender:
            data?.mailRecipientGender,
                mailRecipientLanguage:
            data?.mailRecipientLanguage,
                mailRecipientAddressCountry:
            data?.mailRecipientAddressCountry ? +data?.mailRecipientAddressCountry : undefined,
                mailRecipientAddressLine1:
            data?.mailRecipientAddressLine1,
                mailRecipientAddressLine2:
            data?.mailRecipientAddressLine2,
                mailRecipientAddressLine3:
            data?.mailRecipientAddressLine3,
                mailRecipientAddressLine4:
            data?.mailRecipientAddressLine4,
                mailRecipientAddressLine5:
            data?.mailRecipientAddressLine5,
                mailRecipientFirstName:
            data?.mailRecipientFirstName,
                mailRecipientLastName:
            data?.mailRecipientLastName,
            }
            ,
        },
        relationshipInformation: {
            contactStatus: 326, // In Progress
            isProspect:
        false,
        },
        taxSituation: {
            isUsPerson: data?.isUsPerson,
            isUsCitizenOrSubjectToUsTax:
        data?.usCitizen,
            hasUsResidence: data?.hasUsResidence,
            classedUsPerson: data?.classedUsPerson[0],
        }
        ,
    };

    if (!TARGET_FIELDS.find((fieldName) => !checkNullData(data?.[fieldName]))) {
        delete payload?.communicationMethods?.mailRecipient;
    }

    return payload;
};
