import {
    useEffect, useMemo, useState,
} from 'react';
import { useSinglePortfolio } from '../context';
import {portfolioSelector, usePortfolioSelector, useTransactions} from '../../../../../../../domain/Portfolio';
import {contactSelector, useClientServiceSelector} from '../../../../../../../domain/ClientService';

export const useWithSumBar = ({portfolioId, clientId}) => {
    const {selectedProductSettings} = useSinglePortfolio();
    const [totalValue, setTotalValue] = useState(0);

    const {
        isLoading: isTransactionsLoading, getTransactions, error: transactionsError,
    } = useTransactions({
        portfolioId,
        clientId,
    });

    const {
        dataRaw: dataRawPortfolio, isLoading: isPortfolioLoading, error: portfolioError,
    } = usePortfolioSelector(
        portfolioSelector,
    );

    const {
        data: contactDetails,
        getContact,
        isLoading: isLoadingContact,
    } = useClientServiceSelector(contactSelector);

    useEffect(() => {
        getContact();
    }, [getContact]);

    useEffect(() => {
        setTotalValue(contactDetails?.details?.personalInformation?.affiliatedPensionFund
            ? selectedProductSettings?.maximumYearlyContributionWithPensionFund?.isDependantOnAffiliatedWithPensionFundTrue
            : selectedProductSettings?.maximumYearlyContributionWithPensionFund?.isDependantOnAffiliatedWithPensionFundFalse);
    }, [contactDetails, selectedProductSettings]);

    useEffect(() => {
        getTransactions({
            optionsParams: {
                SecurityType: 'Liquidity',
                PageSize: 100000,
                From: (new Date(new Date().getFullYear(), 0, 1)).toISOString(),
                To: (new Date()).toISOString(),
            },
        });
    }, []);

    const currentValue = useMemo(() => {
        if (dataRawPortfolio?.CustomAttributes?.length) {
            return parseFloat(JSON.parse(dataRawPortfolio?.CustomAttributes ?? '{}')?.CurrentYearProgress ?? 0, 10);
        }

        return 0;
    }, [dataRawPortfolio]);

    const restValue = useMemo(() => (totalValue - currentValue), [totalValue, currentValue]);

    return {
        currentValue,
        totalValue,
        restValue,
        isLoading: isTransactionsLoading || isPortfolioLoading || isLoadingContact,
        error: transactionsError || portfolioError,
    };
};
