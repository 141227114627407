import React, { useReducer, useContext, createContext } from 'react';
import PropTypes from 'prop-types';

export const ADD_VARS = 'theme/addVars';

const initialState = {};
const reducer = (state, action) => {
    switch (action.type) {
        case ADD_VARS:
            return {
                ...state,
                [action.payload.component]: action.payload.vars,
            };
        default:
            return state;
    }
};

export const StoreProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    return (
        <StoreContext.Provider value={{ state, dispatch }}>
            {children}
        </StoreContext.Provider>
    );
};

StoreProvider.propTypes = {
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};

StoreProvider.defaultProps = {
    children: null,
};

const StoreContext = createContext();

export const useStore = () => useContext(StoreContext);
