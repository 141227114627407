import {useQuery} from '@tanstack/react-query';
import ServiceManager from 'services/ServiceManager';
import ServerError from '../../../../../errors/ServerError';

export const useReSendActivationLetterEnabled = ({
    contactId,
}) => {
    const result = useQuery({
        queryKey: ['resendActivationLetterEnabled', contactId],
        enabled: !!contactId,
        retry: false,
        queryFn: async () => {
            const {data} = await ServiceManager.customClientService('resendActivationLetterEnabled', [{
                contactId,
            }]);

            return data;
        },
    });

    return {
        ...result,
        error: result.error ? new ServerError(result.error) : result.error,
    };
};
