import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import AssetComp from 'components/AssetComp';
import { PieChart } from 'ui-library';
import { PieChartWIthLegendProps } from './PieChartWithLegend.types';
import './PieChartWithLegend.css';

const PieChartWithLegend: FC<PieChartWIthLegendProps> = ({
    chartData,
    className = '',
}) => {
    const { t } = useTranslation();

    const chartDataTranslated = useMemo(() => [...chartData]?.map(data => ({
        ...data,
        name: t(`allocationChart.legends.${data?.name}`),
    })), [chartData, t]);

    return (
        <div className={`pie-chart-with-legend ${className}`}>
            <div className="pie-chart-with-legend--pieChart-wrapper">
                <PieChart data={chartDataTranslated} disableLegend tooltip/>
            </div>
            <div className="pie-chart-with-legend--assets-container">
                <div className="pie-chart-with-legend--column">
                    <div className="pie-chart-with-legend--assets-group">
                        {chartDataTranslated?.map(data => (
                            <AssetComp
                                key={data?.name}
                                colorClass={data?.className || ''}
                                title={data?.name}
                                amount={`CHF ${data?.value}`}
                                percentage={`${data?.percent}%`}
                                description={data?.description || ''}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PieChartWithLegend;
