import React, {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import '../../../../styles/Table-ext.css';
import {useTranslation} from 'react-i18next';
import ServiceManager from '../../../../../../../../../../services/ServiceManager';
import {getKudiNotesColumns} from '../../constants';
import {useFormatting} from '../../../../../../../../../../locale';
import {Table} from '../../../../../../../../../../ui-library';
import {DEFAULT_PAGE_SIZE} from '../../../../../../../Dashboard/compnents/OverviewApplications/constants/const';
import Preloader from '../../../../../../../../../../components/Preloader';
import '../../styles/styles.css';

const ShadowAccountsTable = ({portfolioId, productKey, currency}) => {
    const {t} = useTranslation();
    const { getFormattedDate, getFormattedNumber } = useFormatting();
    const [notes, setNotes] = useState([]);
    const [notesCount, setNotesCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [inProgress, setInProgress] = useState(false);
    const [error, setError] = useState(null);
    const getKudiNotes = useCallback(async () => {
        try {
            setInProgress(true);
            const {data} = await ServiceManager.planService('getKudiNote', [{
                portfolioId,
                params: {pageNumber: currentPage, pageSize: DEFAULT_PAGE_SIZE},
            }]);

            setNotes(data.notes);
            setNotesCount(data.total);
        } catch (err) {
            setError(err);
        } finally {
            setInProgress(false);
        }
    }, [portfolioId, currentPage]);

    useEffect(() => {
        (async () => getKudiNotes())();
    }, [getKudiNotes]);

    const columns = useMemo(() => getKudiNotesColumns({
        productKey, t, getFormattedDate, getFormattedNumber, currency,
    }), [productKey, t, getFormattedDate]);

    return (
        <div className="fz-shadow-account-table">
            <Preloader isLoading={inProgress} error={error}>
                <Table
                    columns={columns}
                    data={notes}
                    paging={{
                        total: notesCount || 0,
                        pageSize: DEFAULT_PAGE_SIZE,
                        currentPage,
                    }}
                    onChange={(paging) => setCurrentPage(paging?.currentPage)}
                />
            </Preloader>
        </div>
    );
};

export default ShadowAccountsTable;
