import React from 'react';
import PropTypes from 'prop-types';
import { createUniqueKey } from '../../../utils';

function ChartItem({ data, index }) {
    return (
        <g
            x={data.x}
            y={data.y}
            height={49}
            className={`chart-series-group chart-color-${index}`}
        >
            <rect
                x={data.plotX}
                y={data.plotY}
                width={data.plotWidth}
                height="25"
                className="chart-plot"
            />
            <rect
                x={data.barX}
                y={data.barY}
                width={data.barWidth}
                height="25"
                className={`chart-bar chart-color-${index}`}
            />
            <text
                x={data.legendX}
                y={data.legendY}
                fontSize="14px"
                className="chart-bar-legend"
            >
                {data.range}
            </text>
            {data.triangles.map((item, i) => (
                <polygon
                    key={createUniqueKey(i, item)}
                    points={item}
                    className={`chart-triangle chart-color-${i}`}
                />
            ))}
        </g>
    );
}

ChartItem.propTypes = {
    data: PropTypes.shape({
        x: PropTypes.number,
        y: PropTypes.number,
        plotX: PropTypes.number,
        plotY: PropTypes.number,
        plotWidth: PropTypes.number,
        barX: PropTypes.number,
        barY: PropTypes.number,
        barWidth: PropTypes.number,
        legendX: PropTypes.number,
        legendY: PropTypes.number,
        range: PropTypes.string,
        triangles: PropTypes.arrayOf(PropTypes.string).isRequired,
    }),
    index: PropTypes.number,
};

ChartItem.defaultProps = {
    data: {
        triangles: [],
    },
    index: undefined,
};

export default ChartItem;
