const defaultLogger = console.warn;

type Logger = (error?: Error | unknown) => void

export class AuthenticationLoggerService {
    private readonly logger: Logger

    private readonly withDebug: boolean

    constructor(logger: Logger = defaultLogger, withDebug = true) {
        this.logger = logger;
        this.withDebug = withDebug;
    }

    log(error: Error | unknown): void {
        if (this.withDebug) {
            this.logger(error);
        }
    }
}
