import React, { useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Modal} from 'ui-library';

import Error from 'components/AtomicStructure/atoms/Error';
import UploadDocuments from 'components/UploadDocuments/UploadDocuments';

import './UploadDocumentsModal.css';

type UploadDocumentsModalProps = {
    show: boolean;
    onClose: () => void
    onOk: (files) => void
}

export const UploadDocumentsModal = ({show, onClose, onOk}: UploadDocumentsModalProps) => {
    const {t} = useTranslation();
    const [error, setError] = useState<string>('');
    const [files, setFiles] = useState([]);
    const [inProgress, setInProgress] = useState<boolean>(false);

    return (
        <Modal width={500} okButtonProps={{loading: inProgress}} visible={show} onCancel={onClose} onOk={() => { onOk(files); }} title={t('3b.kyc.docsModalTitle')}>
            <div className="kyc-docs-upload">
                <p>{t('3b.kyc.allowedFormats')}</p>
                <UploadDocuments onError={setError} onSuccess={setFiles} />
                {error && <Error>{error}</Error>}
            </div>
        </Modal>
    );
};
