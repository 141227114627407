import React, {useMemo} from 'react';
import Preloader from 'components/Preloader';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';
import {useSinglePortfolio} from '../../context';
import {PRODUCTS} from '../../../../../../../../constants/constants';
import Card from '../../../../../../../../components/Card/Card';
import ShadowAccountForm from './components/ShadowAccountForm/ShadowAccountForm';
import ShadowAccountsTable from './components/ShadowAccountsTable/ShadowAccountsTable';
import DepotInformationTable from './components/DepotInformation/DepotInformationTable';

const Details = () => {
    const { dfsClientId, portfolioId } = useParams();
    const {t} = useTranslation();
    const { selectedProductSettings, portfolioDetail: {currency} } = useSinglePortfolio();

    const productKey = useMemo(() => (
        selectedProductSettings?.productKey?.toLowerCase()
    ), [selectedProductSettings?.productKey]);

    const dataPerType = useMemo(() => {
        if (productKey === PRODUCTS.aaa) {
            return {
                // data for 3a
                shadowAccountForm: {
                    title: t('shadowAccount.saldo.title3a'),
                },
                shadowAccountTable: {
                    title: t('shadowAccount.notes.title3a'),
                },
            };
        } if (productKey === PRODUCTS.fz) {
            return {
                // data for FZ/FZP
                shadowAccountForm: {
                    title: t('shadowAccount.saldo.titleFz'),
                },
                shadowAccountTable: {
                    title: t('shadowAccount.notes.titleFz'),
                },
            };
        } if (productKey === PRODUCTS.fzp) {
            return {
                // data for FZ/FZP
                shadowAccountForm: {
                    title: t('shadowAccount.saldo.titleFzp'),
                },
                shadowAccountTable: {
                    title: t('shadowAccount.notes.titleFzp'),
                },
            };
        }

        return undefined;
    }, [t]);

    return (
        <Preloader isLoading={false} error={undefined}>
            <div className="container" style={{position: 'relative'}}>
                <div className="fi-verticals">
                    <Card title={dataPerType?.shadowAccountForm?.title}>
                        <ShadowAccountForm productKey={productKey} portfolioId={portfolioId}/>
                    </Card>
                    <Card title={dataPerType?.shadowAccountTable?.title}>
                        <ShadowAccountsTable portfolioId={portfolioId} productKey={productKey} currency={currency} />
                    </Card>
                    <Card title={t('shadowAccount.pledge.title')}>
                        <DepotInformationTable portfolioId={portfolioId}/>
                    </Card>
                </div>
            </div>
        </Preloader>
    );
};

export default Details;
