import React, { forwardRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import OutsideClickHandler from 'react-outside-click-handler';
import FormControlMessage from '../../FormControlMessage';
import FormGroup from '../../FormGroup';
import Label from '../../Label';
import SelectButton from '../SelectButton';
import { optionsList } from '../../../types';
import { getSelectedOptionsTitles } from '../utils';
import withTheme from '../../../hocs/withTheme';
import Loader from '../../Loader';
import MultiSelectMiniForm from '../MultiSelectMiniForm';

const MultiSelect = (props) => {
    const {
        value, onChange, onSearch, onReset,
        label, width, withInfobox, withSelectTool, placeholder, required, isSearching,
        disabled, error, errorInTooltip, children, options, selectControlsLabel, helpText,
        innerRef: ref,
    } = props;
    const [opened, setOpened] = useState(false);
    const [focused, setFocused] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState(value);

    useEffect(() => {
        setSelectedOptions(value);
    }, [value]);

    const handleClickOutside = () => {
        setOpened(false);
    };
    const handleFocus = () => {
        setFocused((val) => !val);
    };
    const handleDropdown = () => {
        setOpened((val) => !val);
    };
    const onSelect = (val) => {
        setSelectedOptions(val);
        onChange(val);
    };

    return (
        <OutsideClickHandler
            onOutsideClick={handleClickOutside}
        >
            <FormGroup
                focused={focused}
                opened={opened}
                disabled={disabled}
                error={!!error}
            >
                {label && <Label htmlFor="select-dropdown-options" label={label} labelInfobox={withInfobox} required={required} helpText={helpText} />}
                <div className="select select__multi" style={{ width }} ref={ref}>
                    <SelectButton
                        ariaControlName="multiselectFilter-dropdown"
                        opened={opened}
                        error={error}
                        className={selectedOptions.length === 0 ? 'SelectButton_with_placeholder' : ''}
                        errorInTooltip={errorInTooltip}
                        selectedOptions={
                            selectedOptions.length > 0
                                ? getSelectedOptionsTitles(options, selectedOptions)
                                : placeholder
                        }
                        onFocus={handleFocus}
                        onBlur={handleFocus}
                        onClick={handleDropdown}
                    />
                    <div className="select__dropdown__wrapper">
                        <MultiSelectMiniForm
                            value={selectedOptions}
                            opened={opened}
                            options={options}
                            headerControls={{ selectControlsLabel }}
                            withSelectTool={withSelectTool}
                            onSearch={onSearch}
                            onSelect={onSelect}
                            onReset={onReset}
                        >
                            {isSearching && <Loader />}
                        </MultiSelectMiniForm>
                    </div>
                </div>

                {error && !errorInTooltip && (
                    <FormControlMessage>{error}</FormControlMessage>
                )}

                {children && <p>{children}</p>}
            </FormGroup>
        </OutsideClickHandler>
    );
};

MultiSelect.propTypes = {
    /** The label text */
    label: PropTypes.string,
    /** Placeholder text */
    placeholder: PropTypes.string,
    /** If label infobox is visible */
    withInfobox: PropTypes.bool,
    /** If 'Select all' and 'Reset' are visible */
    withSelectTool: PropTypes.bool,
    /** If required field* */
    required: PropTypes.bool,
    /** Option list  */
    options: optionsList,
    /** Initial option for select */
    value: PropTypes.arrayOf(PropTypes.string),
    /** Callback reset function */
    onReset: PropTypes.func,
    /** Callback function */
    onChange: PropTypes.func,
    /** Callback function by searching */
    onSearch: PropTypes.func,
    /** Custom width size in percent or pixels  */
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /** The error message displayed if the input is errored */
    error: PropTypes.string,
    /** If the error message should be displayed in tooltip */
    errorInTooltip: PropTypes.bool,
    /** @ignore */
    disabled: PropTypes.bool,
    /** @ignore */
    children: PropTypes.node,
    selectControlsLabel: PropTypes.shape({
        selectAll: PropTypes.string,
        reset: PropTypes.string,
    }),
    /** The text displayed if the label tooltip exist */
    helpText: PropTypes.bool,
    /** Whether the searching process is on */
    isSearching: PropTypes.bool,
    /** @ignore */
    innerRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({
            current: PropTypes.objectOf,
        }),
    ]),
};

MultiSelect.defaultProps = {
    label: null,
    placeholder: ' ',
    withInfobox: true,
    withSelectTool: false,
    required: false,
    options: null,
    value: [],
    onReset: () => { },
    onChange: () => { },
    onSearch: () => { },
    width: null,
    error: null,
    errorInTooltip: false,
    disabled: false,
    children: null,
    selectControlsLabel: {
        selectAll: 'Select all',
        reset: 'Reset',
    },
    helpText: null,
    isSearching: false,
    innerRef: null,
};

export default forwardRef((props, ref) => withTheme(MultiSelect)({ ...props, innerRef: ref }));
