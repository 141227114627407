import {validateInputNumberWithZero, validateInputString} from 'validation/validation';

export const errorModel = ({
    data, showYearlyPayments, isThreeB, isZic, isAzp, isZifd,
}) => ({
    nameOfPensionScheme: !isThreeB ? validateInputString(data.nameOfPensionScheme) : null,
    yearlyPayment: showYearlyPayments ? validateInputNumberWithZero(data.yearlyPayment) : null,
    transferValue: (showYearlyPayments || isThreeB) ? null : validateInputNumberWithZero(data.transferValue),
    //   rubrik: isThreeB && !isZic && !isAzp && !isZifd ? validateInputString(data.rubrik) : null,
});
