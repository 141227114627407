import Service from './service';
import { getServicesUrl } from '../../setup';
import { APIVersion } from '../../types/main';
import Security from './security';

class DeviceEnrollment extends Service {
    static enrollStatusSource = Service.source;

    static enroll(contactId) {
        return Service.postRequest(`${getServicesUrl(APIVersion.V11)}/contacts/${contactId}/enrollment/enroll`, null, { isSecure: true });
    }

    static async enrollByUserAndPassword({ username, password }) {
        await Security.generateBearerApplicationAccessTokenIfNeeded();

        return Service.postRequest(`${getServicesUrl(APIVersion.V11)}/enrollment/enroll`, {
            UserName: username,
            Password: password
        });
    }

    static unenroll(contactId, data) {
        return Service.postRequest(`${getServicesUrl(APIVersion.V11)}/contacts/${contactId}/enrollment/unenroll`, data, { isSecure: true });
    }

    static async checkEnroll(email, enrollmentSessionId) {
        await Security.generateBearerApplicationAccessTokenIfNeeded();

        return Service.postRequest(`${getServicesUrl(APIVersion.V11)}/enrollment/status`, {
            UserName: email,
            EnrollmentSessionId: enrollmentSessionId
        });
    }
}

export default DeviceEnrollment;
