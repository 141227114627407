import React, {
    useEffect, useState, forwardRef,
    useRef, useMemo, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import RcTooltip from 'rc-tooltip';
import { validatePlacement } from './utils/placements';

import './Popover.css';
import withTheme from '../../hocs/withTheme';
import getDistanceByPlacement from './utils/placementOffset';


const DEFAULT_PLACEMENT = 'top';
const Popover = (props) => {
    const {
        children, visible, defaultVisible, onVisibleChange,
        overlayClassName, title, content, placement, trigger,
        innerRef: ref,
    } = props;
    const childElement = useRef(null);
    const [localVisible, setLocalVisible] = useState(!!visible || !!defaultVisible);

    useEffect(() => {
        if ('visible' in props) {
            setLocalVisible(visible);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visible]);
    const isNoTitle = () => !title && !content && title !== 0;

    const handleVisibleChange = (vis) => {
        if (!('visible' in props)) {
            setLocalVisible(isNoTitle() ? false : vis);
        }
        if (onVisibleChange && !isNoTitle()) {
            onVisibleChange(vis);
        }
    };

    const observerCallback = useCallback((domNode) => {
        if (domNode) {
            const arrowDistance = parseFloat(global.getComputedStyle(domNode).getPropertyValue('--popover-arrow-distance'));
            const arrowEl = domNode.querySelector('.rc-tooltip-arrow');
            const innerEl = domNode.querySelector('.rc-tooltip-inner');
            const childElementRect = childElement.current.getBoundingClientRect();
            const arrowElRect = arrowEl.getBoundingClientRect();

            const distance = getDistanceByPlacement({
                placement, arrowEl, innerEl, arrowDistance,
            });

            const offset = Math.ceil(childElementRect.left + (childElementRect.width / 2)
                - arrowElRect.left - arrowElRect.width / 2);

            if (placement.indexOf('top') >= 0 || placement.indexOf('bottom') >= 0) {
                if (Math.abs(offset) <= childElementRect.width * 2
                    && Math.abs(offset) < parseFloat(domNode.style.left)) {
                    // eslint-disable-next-line no-param-reassign
                    domNode.style.left = `${parseFloat(domNode.style.left) + offset}px`;
                }
            }

            if (distance?.style && distance?.offset) {
                innerEl.style[distance.style] = `${distance.offset}px`;
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const observerPopover = useMemo(() => new global.MutationObserver((event) => {
        try {
            const domNode = Array.isArray(event) && event[0] && event[0].target;

            observerCallback(domNode);
        } catch (e) {
            console.warn(e);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }), []);


    const onPopupAlign = (domNode) => {
        observerCallback(domNode);
        observerPopover.observe(domNode, { attributes: true, attributeFilter: ['style'] });
    };


    const renderOverlay = () => {
        if (title === 0) {
            return title;
        }


        return content || title || '';
    };

    const getTooltipContainer = (domNode) => {
        childElement.current = domNode;

        return document.body;
    };
    const getOverlay = () => (
        <>
            {title && <div className="rc-tooltip-title">{renderOverlay()}</div>}
            {content && <div className="rc-tooltip-inner-content">{renderOverlay()}</div>}
        </>
    );
    const getVisible = (vis) => {
        if (!('visible' in props) && isNoTitle()) {
            return false;
        }

        return vis;
    };

    useEffect(() => {
        if (localVisible === false) {
            observerPopover.disconnect();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [localVisible]);

    const childrenRender = typeof children === 'string'
        ? <span>{children}</span> : children;

    return (
        <RcTooltip
            {...props}
            overlayClassName={`Popover ${overlayClassName}`}
            placement={validatePlacement(placement, DEFAULT_PLACEMENT)}
            trigger={trigger}
            ref={ref}
            visible={getVisible(localVisible)}
            arrowContent={<span className="rc-tooltip-arrow-content" />}
            onPopupAlign={onPopupAlign}
            onVisibleChange={handleVisibleChange}
            getTooltipContainer={getTooltipContainer}
            overlay={getOverlay()}
            destroyPopupOnHide
        >
            {childrenRender}
        </RcTooltip>
    );
};

Popover.propTypes = {
    /** Title of the card */
    title: PropTypes.oneOfType([
        PropTypes.string, PropTypes.node,
    ]),
    /** Content of the card */
    content: PropTypes.oneOfType([
        PropTypes.string, PropTypes.node,
    ]),
    /** Whether the floating tooltip card is visible by default */
    defaultVisible: PropTypes.bool,
    /** Whether the floating tooltip card is visible by default */
    // eslint-disable-next-line react/require-default-props
    visible: PropTypes.bool,
    /** Callback executed when visibility of the tooltip card is changed */
    onVisibleChange: PropTypes.func,
    /** The position of the tooltip relative to the target,
     *  which can be one of top left right bottom topLeft
     *  topRight bottomLeft bottomRight leftTop leftBottom
     *  rightTop rightBottom */
    placement: PropTypes.string,
    /** Popover trigger mode. Could be multiple by passing an array
     * which actions cause tooltip shown. enum of 'hover','click','focus' */
    trigger: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.string),
        PropTypes.string,
    ]),
    /** Whether the arrow is pointed at the center of target */
    arrowPointAtCenter: PropTypes.bool,
    /** Whether to adjust popup placement automatically when popup is off screen */
    autoAdjustOverflow: PropTypes.bool,
    /** @ignored */
    overlayClassName: PropTypes.string,
    /** @ignored */
    children: PropTypes.node,
    /** @ignore */
    innerRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({ current: PropTypes.instanceOf(Popover) }),
    ]),
};

Popover.defaultProps = {
    title: null,
    content: null,
    defaultVisible: false,
    onVisibleChange: null,
    placement: 'top',
    trigger: ['click'],
    arrowPointAtCenter: false,
    autoAdjustOverflow: true,
    overlayClassName: '',
    children: null,
    innerRef: null,
};

export default forwardRef((props, ref) => withTheme(Popover)({ ...props, innerRef: ref }));
