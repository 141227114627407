import { getfromDataObject, validateStringData } from 'utils/formatting';
import { portfolioTypeIds } from 'constants/portfolioTypeIds';
import {
    BLOCKED,
    CLOSED,
    getPortfolioStatus,
    IN_EXECUTION,
    NOT_IMPLEMENTED,
    PENDING_ORDERS,
    portfolioStatuses,
    portfolioStatusesColors,
    portfolioStatusesIcons,
} from 'constants/portfolioStatuses';
import { STARS } from '../compnents/constants';

export const adaptInvestmentOverview = ({
    data, dfsId,
    portfolioActions, t,
    getFormattedCurrency,
    products, hasAccessPolicy,
    isDataAccessible,
    navigatem,
}) => {
    let positions;
    const currency = data?.BaseCurrency?.CurrencyCode;

    if (data && data.Positions) {
        positions = data.Positions;
    } else if (data && data.ConsolidatedPositions) {
        positions = data.ConsolidatedPositions;
    }
    const portfolios = data?.Portfolios !== undefined
        ? data.Portfolios
            .filter((item) => {
                const selectedProduct = products.find(product => product.id === item.Product.Id);

                return hasAccessPolicy(selectedProduct?.settings?.AccessPolicy);
            })
            .filter((item) => item.PortfolioTypeId === portfolioTypeIds.MAIN)
            .map((item) => {
                const portfolioStatus = getPortfolioStatus({
                    portfolioStatusId: item.PortfolioStatusId,
                });

                return {
                    Id: item.Id,
                    Name: {
                        label: item.Name || `${t('clientDashboard.portfolios.plan')} ${item.Id}`,
                        link: isDataAccessible ? `/client/${dfsId}/portfolios/${item.Id}/tab/overview` : null,
                        disabled: !isDataAccessible || portfolioStatus === NOT_IMPLEMENTED,
                    },
                    ProductId: item?.Product.Id,
                    Product: getfromDataObject(item, 'Product.Name'),
                    ProductDesc: getfromDataObject(item, 'Product.Description'),
                    Status: {
                        text: portfolioStatuses(t)[portfolioStatus] || '-',
                        icon: portfolioStatusesIcons[portfolioStatus] || '',
                        color: portfolioStatusesColors[portfolioStatus] || '',
                        value: portfolioStatus,
                    },
                    Profile: validateStringData(item.ModelPortfolioName || item?.RiskCategory?.Name),
                    Value: isDataAccessible ? getFormattedCurrency(
                        item.CurrentValue, { currency: item.Currency.CurrencyCode },
                    ) : `${item.Currency.CurrencyCode} ${STARS.multi}`,
                    ValueWithoutCurrency: getFormattedCurrency(
                        item.CurrentValue, {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2,
                        },
                    ),
                    rawValue: item.CurrentValue,
                    Performance: item.CurrentPerformance
                        ? item.CurrentPerformance * 100 : undefined,
                    Actions: {
                        value: 'action',
                        actions: portfolioActions({
                            navigatem,
                            dfsId,
                            id: item.Id,
                            productName: item.Product.Name,
                            t,
                            showEditButton: portfolioStatus !== NOT_IMPLEMENTED,
                            withEditActions:
                                portfolioStatus !== PENDING_ORDERS && portfolioStatus !== BLOCKED,
                            withPendingOrders: portfolioStatus === IN_EXECUTION,
                            withFundingInformation: portfolioStatus === NOT_IMPLEMENTED,
                            status: portfolioStatus,
                        }),
                        disabled: portfolioStatus === CLOSED,
                    },
                };
            })
        : [];

    const adaptSectionedPortfolios = (sectionedData) => sectionedData
        ?.filter((item) => item.portfolioTypeId === portfolioTypeIds.MAIN)
        .map((item) => {
            const portfolioStatus = getPortfolioStatus({
                portfolioStatusId: item.portfolioStatusId,
            });

            return {
                Id: item.id,
                Name: {
                    label: item.name || `${t('clientDashboard.portfolios.plan')} ${item.id}`,
                    link: isDataAccessible ? `/client/${dfsId}/portfolios/${item.id}/tab/overview` : null,
                    disabled: !isDataAccessible || portfolioStatus === NOT_IMPLEMENTED,
                },
                ProductId: item?.product.id,
                Product: getfromDataObject(item, 'product.name'),
                ProductDesc: getfromDataObject(item, 'product.description'),
                AccountHolder: item.holderNames,
                Status: {
                    text: portfolioStatuses(t)[portfolioStatus] || '-',
                    icon: portfolioStatusesIcons[portfolioStatus] || '',
                    color: portfolioStatusesColors[portfolioStatus] || '',
                    value: portfolioStatus,
                },
                Profile: validateStringData(item.modelPortfolioName || item?.riskCategoryName),
                Value: isDataAccessible ? getFormattedCurrency(
                    item.currentValue, { currency: item.currencyCode },
                ) : `${item.currencyCode} ${STARS.multi}`,
                rawValue: item?.currentValue,
                Actions: {
                    value: 'action',
                    actions: portfolioActions({
                        dfsId,
                        id: item.id,
                        productName: item.product.name,
                        t,
                        showEditButton: portfolioStatus !== NOT_IMPLEMENTED,
                        withEditActions:
                         portfolioStatus !== PENDING_ORDERS && portfolioStatus !== BLOCKED,
                        withPendingOrders: portfolioStatus === IN_EXECUTION,
                        withFundingInformation: portfolioStatus === NOT_IMPLEMENTED,
                        status: portfolioStatus,
                    }),
                    disabled: portfolioStatus === CLOSED,
                },
            };
        });

    const jointRegularPortfolios = data?.JointPortfolios !== undefined
        ? adaptSectionedPortfolios(data?.JointPortfolios)
        : [];

    const poaPortfolios = data?.POAPortfolios !== undefined
        ? adaptSectionedPortfolios(data?.POAPortfolios)
        : [];
    const parentalPortfolios = data?.PAPortfolios !== undefined
        ? adaptSectionedPortfolios(data?.PAPortfolios)
        : [];

    return {
        overview: {
            currency,
            securityValue: data.SecuritiesValue,
            totalValue: data.CurrentValue,
            cashAmount: data.CashAmount,
            performanceValue: data.CurrentPerformance * 100,
            performanceAmount: data.CurrentPerformanceAmount,
        },
        positions,
        portfolios,
        sectionedPortfolios: { jointRegularPortfolios, poaPortfolios, parentalPortfolios },
        productIds: portfolios.map(({ ProductId }) => ProductId),
    };
};
