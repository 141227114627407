import React, {
    useCallback, useEffect, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import { onElementHeightChange } from '../../utils';
import { getClassNames } from '../../../../utils';

function AccordionPanelContent(props) {
    const { uId, isActive, children } = props;
    const [isOpened, setOpened] = useState(isActive);
    const classNameContent = getClassNames(
        'AccordionPanel-content',
        { 'AccordionPanel-content__open': isOpened },
        { 'AccordionPanel-content__active': isActive },
    );
    const [isActivated, setActivated] = useState(isActive);

    useEffect(() => {
        if (!isActivated && isActive) setActivated(isActive);
    }, [isActive]);

    const content = useRef(null);
    const [maxHeight, setMaxHeight] = useState(0);
    const setHeight = useCallback((height) => {
        setMaxHeight(isActive ? height : 0);
    }, [isActive]);

    useEffect(() => {
        if (isActive) setTimeout(() => setOpened(true), 300);
        else setOpened(false);
    }, [isActive]);

    useEffect(() => {
        if (content.current) {
            setHeight(content.current.scrollHeight);
            onElementHeightChange(content.current.children[0], setMaxHeight);
        }
    }, [isActive, isActivated, children]);

    return isActivated && (
        <div
            id={uId}
            ref={content}
            style={{ maxHeight }}
            className={classNameContent}
            aria-hidden={!isActive}
        >
            <div className="AccordionPanel-content-box">
                {children}
            </div>
        </div>
    );
}

AccordionPanelContent.propTypes = {
    uId: PropTypes.string.isRequired,
    isActive: PropTypes.bool,
    children: PropTypes.node,
};

AccordionPanelContent.defaultProps = {
    isActive: false,
    children: null,
};

export default AccordionPanelContent;
