import Highcharts from 'highcharts';

function WrappedHighcharts(H) {
    H.wrap(H.Tooltip.prototype, 'move', function move(proceed, x, y, anchorX, anchorY) {
        const tooltip = this;
        const animate = tooltip.options.animation !== false && !tooltip.isHidden
            // When we get close to the target position,
            // abort animation and land on the right place (#3056)
            && (Math.abs(x - tooltip.now.x) > 1 || Math.abs(y - tooltip.now.y) > 1);

        // Get intermediate values for animation
        H.extend(tooltip.now, {
            x: animate ? (2 * tooltip.now.x + x) / 3 : x,
            y: animate ? (tooltip.now.y + y) / 2 : y,
            anchorX: animate ? (2 * tooltip.now.anchorX + anchorX) / 3 : anchorX,
            anchorY: animate ? (tooltip.now.anchorY + anchorY) / 2 : anchorY,
        });

        // Move to the intermediate value
        tooltip.getLabel().attr(tooltip.now);

        // Run on next tick of the mouse tracker
        if (animate) {
            // Never allow two timeouts
            clearTimeout(this.tooltipTimeout);

            // Set the fixed interval ticking for the smooth tooltip
            this.tooltipTimeout = setTimeout(() => {
                // The interval function may still be running during destroy,
                // so check that the chart is really there before calling.
                if (tooltip) {
                    tooltip.move(x, y, anchorX, anchorY);
                }
            }, 32);
        }
    });

    return H;
}

export default WrappedHighcharts(Highcharts);
