import {useMemo} from 'react';
import {PRODUCTS, THREEBTYPES} from '../constants/constants';

export const isThreeBProduct = (portfolio) => {
    const isThreeB = useMemo(() => portfolio?.product?.settings?.productKey?.toLowerCase() === PRODUCTS.bbb, [portfolio]);

    return isThreeB;
};

export const isAnyOfThreeBProductTypes = (productName) => Object.values(THREEBTYPES).includes(productName);

export const useThreeBProductType = (portfolio) => {
    const productType = useMemo(() => portfolio?.product?.externalId, [portfolio?.product?.externalId]);

    const isZiv = productType === THREEBTYPES.zivv;
    const isZifd = productType === THREEBTYPES.zifd;
    const isZic = productType === THREEBTYPES.zic;
    const isAzpD = productType === THREEBTYPES.azpNeu;
    const isAzpI = productType === THREEBTYPES.azpNeuSB;
    const isAzpAlt = productType === THREEBTYPES.azpAlt;
    const isPk = productType === THREEBTYPES.pk;

    const isAzp = isAzpD || isAzpI;
    const isZivZicAzp = isZiv || isZic || isAzp;
    const isZicAzp = isAzp || isZic;
    const isZivAzp = isZiv || isAzp;
    const isZivZifd = isZiv || isZifd;

    const isJA = portfolio?.isJointAccount === true;

    return {
        productType, isZivZicAzp, isZic, isAzp, isZiv, isZifd, isZicAzp, isPk, isZivZifd, isZivAzp, isJA, isAzpI, isAzpD, isAzpAlt,
    };
};
