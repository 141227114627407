import {
    useMemo, useCallback, useReducer, Reducer,
} from 'react';
import {
    Header, HeaderAction, HeaderActionType, defaultHeaderState,
} from './types';

const headerReducer: Reducer<Header, HeaderAction> = (
    state: Header, { type, payload }: HeaderAction,
): Header => {
    switch (type) {
        case HeaderActionType.init: return payload;
        case HeaderActionType.change: return ({
            ...state,
            ...payload,
        });
        case HeaderActionType.clear: return defaultHeaderState;
        default: return state;
    }
};

export const useHeader = () => {
    const [headerState, dispatchHeader] = useReducer<Reducer<Header, HeaderAction>>(
        headerReducer, defaultHeaderState,
    );

    const initHeaderState = useCallback((payload: Header) => {
        dispatchHeader({ type: HeaderActionType.init, payload });
    }, [dispatchHeader]);

    const changeHeaderState = useCallback((payload: Header) => {
        dispatchHeader({ type: HeaderActionType.change, payload });
    }, [dispatchHeader]);

    return useMemo(() => ({
        headerState, initHeaderState, changeHeaderState,
    }), [headerState, initHeaderState, changeHeaderState]);
};
