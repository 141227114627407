import React, {forwardRef, useCallback, useEffect, useState,} from 'react';
import PropTypes from 'prop-types';
import RadioButton from './RadioButton';
import {optionsList} from '../../types';
import {createUniqueKey, getClassNames, transformOptions} from '../../utils';

import './RadioButton.css';
import withTheme from '../../hocs/withTheme';
import FormGroup from '../FormGroup/FormGroup';
import FormControlMessage from '../FormControlMessage/FormControlMessage';
import {getDataOrAriaProps} from '../../../utils';

export const RadioButtonGroup = (props: any) => {
    const {
        options, horizontal, className, value, onChange, innerRef: ref,
        disabled, defaultValue, error, ...groupProps
    } = props;
    let { children } = props;

    const [checked, setChecked] = useState(value || defaultValue);

    useEffect(() => {
        setChecked(value);
    }, [value]);

    const onChangeValue = useCallback((e) => {
        setChecked(e.target.value);
        onChange(e);
    }, [onChange, setChecked]);

    if (options) {
        children = transformOptions(options).map((radioButtonProps, index) => (
            <RadioButton
                ref={ref}
                key={createUniqueKey(index, radioButtonProps.label)}
                {...radioButtonProps}
                id={`${radioButtonProps.value}:${Math.ceil(Math.random()*1000000)}`}
                error={error}
                checked={checked === radioButtonProps.value}
                onChange={onChangeValue}
            />
        ));
    }

    return (
        <FormGroup disabled={disabled} error={error}>
            <div
                {...groupProps}
                {...getDataOrAriaProps(props)}
                className={getClassNames(className, 'radio-group', 'radio-group-outline', { 'radio-group-horizontal': horizontal })}
            >
                {children}
            </div>
            {error && (
                <FormControlMessage>{error}</FormControlMessage>
            )}
        </FormGroup>
    );
};

RadioButtonGroup.propTypes = {
    /** Default selected value */
    defaultValue: PropTypes.string,
    /** Disable all radio buttons */
    disabled: PropTypes.bool,
    /** The name property of all input[type="radio"] children */
    name: PropTypes.string,
    /** Specifies options */
    options: optionsList,
    /** Enable horizontal view */
    horizontal: PropTypes.bool,
    /** The callback function that is triggered when the state changes */
    onChange: PropTypes.func,
    /** @ignore */
    children: PropTypes.node,
    /** @ignore */
    className: PropTypes.string,
    /** Used for setting the currently selected value. */
    value: PropTypes.string,
    /** @ignore */
    innerRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({
            current: PropTypes.instanceOf(Element),
        }),
    ]),
    /** The error message displayed if the input is errored */
    error: PropTypes.string,
};

RadioButtonGroup.defaultProps = {
    defaultValue: null,
    disabled: false,
    name: null,
    options: [],
    horizontal: false,
    onChange: () => {},
    children: null,
    className: null,
    value: null,
    innerRef: null,
    error: null,
};

export default forwardRef((props: any, ref) => withTheme(RadioButtonGroup)({ ...props, innerRef: ref }));
