import { useEffect, useCallback } from 'react';
import { useSecure } from 'libs/additiv-react-oidc-client';

export const useSessionExpiring = (fn: () => void, deps: unknown[] = []) => {
    const { events } = useSecure();
    const callback = useCallback(fn, deps);

    useEffect(() => {
        events?.addAccessTokenExpiring(callback);

        return () => {
            events?.removeAccessTokenExpiring(callback);
        };
    }, [callback]);
};
