import React, {
    createContext, useCallback, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import ServiceManager from 'services/ServiceManager';
import Service from 'additiv-services';
import {memberIdSelector} from 'redux-store/auth/authSelectors';
import {getImageMime} from 'utils';
import defaultImg from 'assets/images/avatar/AdvisorMale.jpg';
import handlerRequestCanceling from '../../utils/handlerRequestCanceling';
import HandlerError from '../../errors/HandlerError';
import AdapterError from '../../errors/AdapterError';
import {parseXml} from '../../utils/xmlParser';
import {useGetInvestmentApplications} from '../../hooks/useGetInvestmentApplications';
import {
    investmentApplicationsAdapter,
} from '../../pages/AdvisoryDashboard/components/BusinessCase/investmentApplicationsAdapter';

const ManagerProfileContext = createContext<any>({hasAccessPolicy: (policy: string) => {}});

const { Provider } = ManagerProfileContext;

const ManagerProfileProvider = (options) => {
    const [error, setError] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const [isPolicyLoading, setPolicyLoading] = useState(true);
    const [data, setData] = useState<any>({ email: '', name: '', image: '' });
    const [memberInfo, setMemberInfo] = useState(null);
    const memberId = useSelector(memberIdSelector);
    const [accessPolicies, setAccessPolicies] = useState<string[]>([]);
    const { i18n: { language } } = useTranslation();
    const [investmentAppPageNumber, setInvestmentAppPageNumber] = useState(1);
    const [investmentContainerPageNumber, setInvestmentContainerPageNumber] = useState(1);
    const [dashboardInfo, setDashboardInfo] = useState(null);

    const {
        isLoading: isInvestmentApplicationsLoading, error: investmentApplicationsError, totalPages, data: applications,
    } = useGetInvestmentApplications(investmentAppPageNumber, investmentApplicationsAdapter);

    const {
        options: { pathname },
        children,
    } = options;

    const getMemberInfo = useCallback(async () => {
        try {
            const {data: info} = await ServiceManager.customMembersService('getMemberInfo', [{memberId}]);

            setMemberInfo(info);
        } catch (err) {
            //
        }
    }, [memberId]);

    const getDashboardInfo = useCallback(async () => {
        try {
            const {data: dashboard} = await ServiceManager.customMembersService('getDashboardInfo', []);

            setDashboardInfo(dashboard);
        } catch (err) {
            //
        }
    }, []);

    const getMemberProfile = useCallback(async () => {
        if (memberId) {
            setLoading(true);
            Promise.all([
                ServiceManager.contactManagement('getMembersProfile', [memberId]),
                ServiceManager.memberManagement('getMemberPicture', [memberId]),
            ])
                .then((response) => response.map((item) => item.data))
                .then((response) => {
                    try {
                        setData({
                            image: response[1]?.ImageContentBase64 || data.image ? `data:${getImageMime(response[1]?.ImageContentBase64 || data.image)};base64,${response[1]?.ImageContentBase64 || data.image}` : defaultImg,
                            name: `${response[0]?.FirstName} ${response[0]?.LastName}`,
                            firstName: response[0]?.FirstName,
                            lastName: response[0]?.LastName,
                            phone: response[0]?.Phone,
                            mobile: response[0]?.Mobile,
                            bankBranch: parseXml(response[0]?.Branch?.Name)?.root?.[language]?.['#text'],
                            internalId: response[0]?.InternalUserId,
                            superiorIds: response[0]?.SuperiorIds,
                            hasProfilePicture: response[0]?.HasProfilePicture,
                        });
                        setLoading(false);
                    } catch (err) {
                        throw new AdapterError(err);
                    }
                })
                .catch((err) => {
                    handlerRequestCanceling(
                        HandlerError({ setError, setLoading }),
                    )(err);

                    if (Service.isCanceled(err)) {
                        setLoading(false);
                    }
                });
        }
    }, [memberId, language]);

    const getAccessPolicies = useCallback(async () => {
        try {
            setPolicyLoading(true);
            const {data: policies} = await ServiceManager.customMembersService('getBackofficeAccessPolicies', []);

            setAccessPolicies(policies?.accessPolicies);
        } catch (err) {
            //
        } finally {
            setPolicyLoading(false);
        }
    }, []);

    const hasAccessPolicy = useCallback((policy): boolean => accessPolicies?.includes(policy), [accessPolicies]);

    useEffect(() => {
        (async () => {
            await getMemberProfile();
            await getAccessPolicies();
        })();
    }, [getMemberProfile]);

    useEffect(() => {
        (async () => getDashboardInfo())();
    }, [getDashboardInfo]);

    return (
        <Provider value={{
            data,
            error,
            isPolicyLoading,
            isLoading,
            getMemberProfile,
            accessPolicies,
            hasAccessPolicy,
            getMemberInfo,
            memberInfo,
            totalPages,
            applications,
            dashboardInfo,
            setInvestmentAppPageNumber,
            investmentAppPageNumber,
            isInvestmentApplicationsLoading,
            investmentApplicationsError,
            isAdvisorDashboardLoading: isInvestmentApplicationsLoading,
            advisorDashboardError: investmentApplicationsError,
            investmentContainerPageNumber,
            setInvestmentContainerPageNumber,
        }}
        >
            {children}
        </Provider>
    );
};

ManagerProfileProvider.propTypes = {
    options: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
        children: PropTypes.node,
    }).isRequired,
};

ManagerProfileProvider.defaultProps = {};

export {
    ManagerProfileProvider,
    ManagerProfileContext,
};
