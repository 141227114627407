import React from 'react';
import RcSteps from 'rc-steps';
import PropTypes from 'prop-types';

import {
    PREFIX_CLS,
    PROGRESS_BAR_SIZES,
    PROGRESS_BAR_TYPES,
    PROGRESS_BAR_TYPE_DOTS,
    PROGRESS_BAR_TYPE_BARS,
    PROGRESS_BAR_SIZE_SMALL,
    PROGRESS_BAR_SIZE_DEFAULT,
} from './constants';

import './ProgressBar.css';
import withTheme from '../../hocs/withTheme';

const ProgressBar = ({
    size, type, current, children,
}) => {
    const hasBarsType = type === PROGRESS_BAR_TYPE_BARS;

    const isSmall = size === PROGRESS_BAR_SIZE_SMALL;
    const { title } = React.Children.toArray(children)[current].props;

    return (
        <div className="Steps-container">
            {isSmall && title && (<span>{title}</span>)}

            <RcSteps
                size={size}
                current={current}
                prefixCls={PREFIX_CLS}
                labelPlacement="vertical"
                className={hasBarsType ? `${PREFIX_CLS}-type-bars` : null}
            >
                {children}
            </RcSteps>
        </div>
    );
};

ProgressBar.propTypes = {
    /** The index of current step in progress bar */
    current: PropTypes.number,
    /** @ignore */
    children: PropTypes.node,
    /** The size progress bar, by default is big */
    size: PropTypes.oneOf(PROGRESS_BAR_SIZES),
    /** The type progress bar, by default is 'dots' */
    type: PropTypes.oneOf(PROGRESS_BAR_TYPES),
};

ProgressBar.defaultProps = {
    current: null,
    children: null,
    type: PROGRESS_BAR_TYPE_DOTS,
    size: PROGRESS_BAR_SIZE_DEFAULT,
};

export default withTheme(ProgressBar);
