import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {Table} from '../../../../../../../../../../ui-library';
import {getShadowAccountPledgeColumns} from '../../constants';
import useFormatting from '../../../../../../../../../../locale/useFormatting';
import {useSinglePortfolio} from '../../../../context';

import '../../styles/styles.css';

const DepotInformationTable = ({portfolioId}) => {
    const {t} = useTranslation();
    const {getFormattedDate, getFormattedNumber} = useFormatting();
    const {saldo} = useSinglePortfolio({portfolioId});

    const data = useMemo(() => {
        const item = {
            pledgeName: saldo?.pledgeName,
            pledgeWef1: saldo?.pledgeWef1,
            pledgeWef2: saldo?.pledgeWef2,
            pledgeWef3: saldo?.pledgeWef3,
            pledgeWef4: saldo?.pledgeWef4,
            pledgeWefAmount: saldo?.pledgeWefAmount,
            pledgeSetupDate: saldo?.pledgeSetupDate,
        };

        if (Object.values(item).some(f => !!f)) {
            return [item];
        }

        return [];
    }, [saldo]);

    const columns = useMemo(() => getShadowAccountPledgeColumns({getFormattedDate, getFormattedNumber, t}), [t, getFormattedDate, getFormattedNumber]);

    return (
        <Table
            className="pledge-table"
            columns={columns}
            data={data}
        />
    );
};

export default DepotInformationTable;
