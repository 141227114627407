export const defaultColumnChartOptions = {
    chart: {
        type: 'column',
        marginLeft: 0,
        marginRight: 0,
        marginTop: 30,
        marginBottom: 30,
    },
    title: false,
    xAxis: {
        title: false,
        labels: { enabled: false },
    },
    yAxis: {
        title: false,
        labels: { enabled: false },
        stackLabels: {
            enabled: true,
            useHTML: true,
        },
        endOnTick: false,
        startOnTick: false,
        gridLineWidth: 0,
        tickLength: 0,
        plotLines: [{
            width: 1,
            value: 0,
            zIndex: 99,
        }],
    },
    plotOptions: {
        series: {
            enableMouseTracking: false,
        },
        column: {
            stacking: 'normal',
            pointWidth: 53,
            dataLabels: { enabled: false },
            enableMouseTracking: false,
        },
    },
    legend: { enabled: false },
    tooltip: { enabled: false },
    credits: false,
};
