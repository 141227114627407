import {
    ICON_TYPE_ERROR,
    ICON_TYPE_TOOLTIP,
    ICON_TYPE_VERIFIED,
    ICON_TYPE_INFOBOX,
    ICON_TYPE_WARNING,
    ICON_TYPE_ARROW,
    ICON_TYPE_DROPDOWN,
    ICON_TYPE_RADIO,
    ICON_TYPE_TWITTER,
    ICON_TYPE_FACEBOOK,
    ICON_TYPE_LINKEDIN,
    ICON_TYPE_CLOSE,
    ICON_TYPE_MENU,
    ICON_TYPE_LOGIN,
    ICON_TYPE_LOGIN_CLIENT,
    ICON_TYPE_DELAY,
    ICON_TYPE_CHART_LINE,
    ICON_TYPE_CHART_ARROW,
    ICON_TYPE_CHART_PIE,
    ICON_TYPE_CHART_BAR_HORIZONTAL,
    ICON_TYPE_CHART_BAR_VERTICAL,
    ICON_TYPE_MINUS,
    ICON_TYPE_PLUS,
    ICON_TYPE_LETTER,
    ICON_TYPE_LIST,
    ICON_TYPE_BELL,
    ICON_TYPE_BELL_FILLED,
    ICON_TYPE_ENVOLOPE,
    ICON_TYPE_ENVELOPE,
    ICON_TYPE_SKYPE,
    ICON_TYPE_PHONE_CALL,
    ICON_TYPE_EXIT,
    ICON_TYPE_ACTION,
    ICON_TYPE_COLUMNS,
    ICON_TYPE_SEARCH,
    ICON_TYPE_LOCKED,
    ICON_TYPE_UNLOCKED,
    ICON_TYPE_SUCCESS,
    ICON_TYPE_PREV,
    ICON_TYPE_NEXT,
    ICON_TYPE_ARROW_DOWN,
    ICON_TYPE_ARROW_UP,
    ICON_TYPE_CLIENTS,
    ICON_TYPE_CALENDAR,
    ICON_TYPE_PROSPECTS,
    ICON_TYPE_TOTAL_AUM,
    ICON_TYPE_DOCUMENT,
    ICON_TYPE_ARROW_LEFT,
    ICON_TYPE_ARROW_RIGHT,
    ICON_TYPE_MINUS_BUTTON,
    ICON_TYPE_PLUS_BUTTON,
    ICON_TYPE_PLUS_SLIM,
    ICON_TYPE_CHECK_FILLED,
    ICON_TYPE_ARROW_UP_DOWN,
    ICON_TYPE_ELLIPSIS,
    ICON_TYPE_EDIT,
    ICON_TYPE_FORM,
    ICON_TYPE_CLOSE_BUTTON,
    ICON_TYPE_REPLY,
    ICON_TYPE_PLUS_MENU,
    ICON_TYPE_SAVE,
    ICON_TYPE_DELETE,
    ICON_TYPE_HISTORY,
    ICON_TYPE_SYNC,
    ICON_TYPE_NEW_DOCUMENT,
    ICON_TYPE_REMOVE,
    ICON_PROXY_CONNECTION,
    ICON_JA_CONNECTION,
    ICON_INFO_OUTLINE,
    ICON_DOCS_OUTLINE,
    ICON_DOWNLOAD,
    ICON_LONG_ARROW_RIGHT,
    ICON_EDIT_OUTLINE,
    ICON_TYPE_OPEN,
    ICON_TYPE_BIN,
    ICON_TYPE_CLOSE_SLIM,
    ICON_INFO_SLIM,
    ICON_EDIT_SLIM,
    ICON_EDIT_MINIMAL,
    ICON_CHECK_SLIM_OUTLINE,
    ICON_FULLSCREEN_SLIM,
    ICON_DOCUMENT_OUTLINE,
    ICON_ALERT_CIRCLE_SOLID,
    ICON_TRIANGLE_OUTLINE,
    ICON_TRIANGLE_OUTLINE_GRAY,
    ICON_CHECK_CIRCLE_SOLID,
    ICON_DOCUMENT_LOADING,
    ICON_DOCUMENT_RE_UPLOAD,
    ICON_DOWNLOAD_OUTLINE,
    ICON_PEN_LEFT,
    ICON_TRY_AGAIN,
    ICON_ARROW_RIGHT_TRENDY
} from './constants';
import IconTooltipSvg from '../../assets/icons/questionmark.svg?react';
import IconVerifiedSvg from '../../assets/icons/verified.svg?react';
import IconErrorSvg from '../../assets/icons/error.svg?react';
import IconWarningSvg from '../../assets/icons/warning.svg?react';
import IconInfoboxSvg from '../../assets/icons/infobox.svg?react';
import IconArrowSvg from '../../assets/icons/arrow.svg?react';
import IconDropdownSvg from '../../assets/icons/dropdown.svg?react';
import IconRadioSvg from '../../assets/icons/radio.svg?react';
import IconTwitterSvg from '../../assets/icons/twitter.svg?react';
import IconFacebookSvg from '../../assets/icons/facebook.svg?react';
import IconLinkedinSvg from '../../assets/icons/linkedin.svg?react';
import IconCloseSvg from '../../assets/icons/close.svg?react';
import IconCloseSlimSvg from '../../assets/icons/icon-close-slim.svg?react';
import IconCloseButtomSvg from '../../assets/icons/close-button.svg?react';
import IconMenuSvg from '../../assets/icons/menu.svg?react';
import IconLoginSvg from '../../assets/icons/login.svg?react';
import IconLoginClientSvg from '../../assets/icons/login-client.svg?react';
import IconDelaySvg from '../../assets/icons/delay.svg?react';
import IconMinusSvg from '../../assets/icons/minus.svg?react';
import IconMinusButtonSvg from '../../assets/icons/minus-button.svg?react';
import IconPlusSvg from '../../assets/icons/plus.svg?react';
import IconPlusMenuSvg from '../../assets/icons/plus-menu.svg?react';
import IconPlusButtonSvg from '../../assets/icons/plus-button.svg?react';
import IconPlusSlimSvg from '../../assets/icons/plus-button-slim.svg?react';
import IconLetterSvg from '../../assets/icons/letter.svg?react';
import IconChartLineSvg from '../../assets/icons/chart-line.svg?react';
import IconChartArrowSvg from '../../assets/icons/icon-chart-arrow.svg?react';
import IconChartPieSvg from '../../assets/icons/chart-pie.svg?react';
import IconChartBarHorizontalSvg from '../../assets/icons/chart-bar-horizontal.svg?react';
import IconChartBarVerticalSvg from '../../assets/icons/chart-bar-vertical.svg?react';
import IconListSvg from '../../assets/icons/list.svg?react';
import IconBellSvg from '../../assets/icons/bell.svg?react';
import IconBellFilledSvg from '../../assets/icons/bell-filled.svg?react';
import IconEnvolopeSvg from '../../assets/icons/envolope.svg?react';
import IconSkypeSvg from '../../assets/icons/skype.svg?react';
import IconPhoneCallSvg from '../../assets/icons/phone-call.svg?react';
import IconExitSvg from '../../assets/icons/exit.svg?react';
import IconActionSvg from '../../assets/icons/action.svg?react';
import IconColumnsSvg from '../../assets/icons/columns.svg?react';
import IconSearchSvg from '../../assets/icons/search.svg?react';
import IconLockedSvg from '../../assets/icons/locked.svg?react';
import IconUnlockedSvg from '../../assets/icons/unlocked.svg?react';
import IconSuccessSvg from '../../assets/icons/success.svg?react';
import IconPrevSvg from '../../assets/icons/prev.svg?react';
import IconNextSvg from '../../assets/icons/next.svg?react';
import IconArrowDownSvg from '../../assets/icons/arrow-down.svg?react';
import IconArrowUpSvg from '../../assets/icons/arrow-up.svg?react';
import IconArrowLeftSvg from '../../assets/icons/arrow-left.svg?react';
import IconArrowRightSvg from '../../assets/icons/arrow-right.svg?react';
import IconArrowUpDownSvg from '../../assets/icons/arrow-down-up.svg?react';
import IconClientsSvg from '../../assets/icons/clients.svg?react';
import IconCalendarSvg from '../../assets/icons/calendar.svg?react';
import IconProspectsSvg from '../../assets/icons/prospects.svg?react';
import IconTotalAumSvg from '../../assets/icons/total-aum.svg?react';
import IconDocumentSvg from '../../assets/icons/document.svg?react';
import IconNewDocumentSvg from '../../assets/icons/document-new.svg?react';
import IconCheckFilledSvg from '../../assets/icons/check-filled.svg?react';
import IconEllipsis from '../../assets/icons/ellipsis.svg?react';
import IconEdit from '../../assets/icons/edit.svg?react';
import IconForm from '../../assets/icons/form.svg?react';
import IconReply from '../../assets/icons/reply.svg?react';
import IconSave from '../../assets/icons/save.svg?react';
import IconDelete from '../../assets/icons/delete.svg?react';
import IconHistory from '../../assets/icons/history.svg?react';
import IconSync from '../../assets/icons/sync.svg?react';
import IconRemove from '../../assets/icons/remove.svg?react';
import IconJaConnection from '../../assets/icons/ja-connection.svg?react';
import IconProxyConnection from '../../assets/icons/proxy-connection.svg?react';
import IconInfoOutline from '../../assets/icons/info-outline.svg?react';
import IconInfoSlim from '../../assets/icons/info-slim.svg?react';
import IconDocsOutline from '../../assets/icons/docs-outline.svg?react';
import IconDownload from '../../assets/icons/download.svg?react';
import IconLongArrowRight from '../../assets/icons/long-arrow-right.svg?react';
import IconEditOutline from '../../assets/icons/edit-outline.svg?react';
import IconOpen from '../../assets/icons/open.svg?react';
import IconBin from '../../assets/icons/icon-bin.svg?react';
import IconEditSlim from '../../assets/icons/edit-slim.svg?react';
import IconEditMinimal from '../../assets/icons/edit-minimal.svg?react';
import IconCheckSlimOutline from '../../assets/icons/check-slim-outline.svg?react';
import IconFullscreenSlim from '../../assets/icons/fullscreen-slim.svg?react';
import IconDocumentsOutline from '../../assets/icons/icon-documents-outline.svg?react';
import IconAlertCircleSolid from '../../assets/icons/alert-circle-solid.svg?react';
import IconAlertTriangleOutline from '../../assets/icons/alert-triangle-outline.svg?react';
import IconAlertTriangleOutlineGray from '../../assets/icons/alert-triangle-outline-gray.svg?react';
import IconCheckCircleSolid from '../../assets/icons/check-circle-solid.svg?react';
import IconDocumentLoading from '../../assets/icons/icon-document-loading.svg?react';
import IconDocumentReUpload from '../../assets/icons/icon-document-re-upload.svg?react';
import IconDownloadOutline from '../../assets/icons/icon-download-outline.svg?react';
import IconPenLeft from '../../assets/icons/icon-pen-left.svg?react';
import IconTryAgain from '../../assets/icons/icon-try-again.svg?react';
import IconArrowRightTrendy from '../../assets/icons/arrow-right-trendy.svg?react';

export const iconsMap = new Map([
    [ICON_TYPE_TOOLTIP, IconTooltipSvg],
    [ICON_TYPE_VERIFIED, IconVerifiedSvg],
    [ICON_TYPE_ERROR, IconErrorSvg],
    [ICON_TYPE_WARNING, IconWarningSvg],
    [ICON_TYPE_INFOBOX, IconInfoboxSvg],
    [ICON_TYPE_ARROW, IconArrowSvg],
    [ICON_TYPE_DROPDOWN, IconDropdownSvg],
    [ICON_TYPE_RADIO, IconRadioSvg],
    [ICON_TYPE_TWITTER, IconTwitterSvg],
    [ICON_TYPE_FACEBOOK, IconFacebookSvg],
    [ICON_TYPE_LINKEDIN, IconLinkedinSvg],
    [ICON_TYPE_CLOSE, IconCloseSvg],
    [ICON_TYPE_CLOSE_SLIM, IconCloseSlimSvg],
    [ICON_TYPE_CLOSE_BUTTON, IconCloseButtomSvg],
    [ICON_TYPE_MENU, IconMenuSvg],
    [ICON_TYPE_LOGIN, IconLoginSvg],
    [ICON_TYPE_LOGIN_CLIENT, IconLoginClientSvg],
    [ICON_TYPE_DELAY, IconDelaySvg],
    [ICON_TYPE_MINUS, IconMinusSvg],
    [ICON_TYPE_MINUS_BUTTON, IconMinusButtonSvg],
    [ICON_TYPE_PLUS_BUTTON, IconPlusButtonSvg],
    [ICON_TYPE_PLUS_SLIM, IconPlusSlimSvg],
    [ICON_TYPE_LETTER, IconLetterSvg],
    [ICON_TYPE_PLUS, IconPlusSvg],
    [ICON_TYPE_PLUS_MENU, IconPlusMenuSvg],
    [ICON_TYPE_CHART_LINE, IconChartLineSvg],
    [ICON_TYPE_CHART_ARROW, IconChartArrowSvg],
    [ICON_TYPE_CHART_PIE, IconChartPieSvg],
    [ICON_TYPE_CHART_BAR_HORIZONTAL, IconChartBarHorizontalSvg],
    [ICON_TYPE_CHART_BAR_VERTICAL, IconChartBarVerticalSvg],
    [ICON_TYPE_LIST, IconListSvg],
    [ICON_TYPE_BELL, IconBellSvg],
    [ICON_TYPE_BELL_FILLED, IconBellFilledSvg],
    [ICON_TYPE_ENVOLOPE, IconEnvolopeSvg],
    [ICON_TYPE_ENVELOPE, IconEnvolopeSvg],
    [ICON_TYPE_SKYPE, IconSkypeSvg],
    [ICON_TYPE_PHONE_CALL, IconPhoneCallSvg],
    [ICON_TYPE_EXIT, IconExitSvg],
    [ICON_TYPE_ACTION, IconActionSvg],
    [ICON_TYPE_COLUMNS, IconColumnsSvg],
    [ICON_TYPE_SEARCH, IconSearchSvg],
    [ICON_TYPE_LOCKED, IconLockedSvg],
    [ICON_TYPE_UNLOCKED, IconUnlockedSvg],
    [ICON_TYPE_SUCCESS, IconSuccessSvg],
    [ICON_TYPE_PREV, IconPrevSvg],
    [ICON_TYPE_NEXT, IconNextSvg],
    [ICON_TYPE_ARROW_DOWN, IconArrowDownSvg],
    [ICON_TYPE_ARROW_UP, IconArrowUpSvg],
    [ICON_TYPE_ARROW_LEFT, IconArrowLeftSvg],
    [ICON_TYPE_ARROW_RIGHT, IconArrowRightSvg],
    [ICON_TYPE_ARROW_UP_DOWN, IconArrowUpDownSvg],
    [ICON_TYPE_CLIENTS, IconClientsSvg],
    [ICON_TYPE_CALENDAR, IconCalendarSvg],
    [ICON_TYPE_PROSPECTS, IconProspectsSvg],
    [ICON_TYPE_TOTAL_AUM, IconTotalAumSvg],
    [ICON_TYPE_DOCUMENT, IconDocumentSvg],
    [ICON_TYPE_NEW_DOCUMENT, IconNewDocumentSvg],
    [ICON_TYPE_CHECK_FILLED, IconCheckFilledSvg],
    [ICON_TYPE_ELLIPSIS, IconEllipsis],
    [ICON_TYPE_EDIT, IconEdit],
    [ICON_TYPE_FORM, IconForm],
    [ICON_TYPE_REPLY, IconReply],
    [ICON_TYPE_SAVE, IconSave],
    [ICON_TYPE_DELETE, IconDelete],
    [ICON_TYPE_HISTORY, IconHistory],
    [ICON_TYPE_SYNC, IconSync],
    [ICON_TYPE_REMOVE, IconRemove],
    [ICON_JA_CONNECTION, IconJaConnection],
    [ICON_PROXY_CONNECTION, IconProxyConnection],
    [ICON_INFO_OUTLINE, IconInfoOutline],
    [ICON_INFO_SLIM, IconInfoSlim],
    [ICON_EDIT_SLIM, IconEditSlim],
    [ICON_DOCS_OUTLINE, IconDocsOutline],
    [ICON_DOWNLOAD, IconDownload],
    [ICON_LONG_ARROW_RIGHT, IconLongArrowRight],
    [ICON_EDIT_OUTLINE, IconEditOutline],
    [ICON_EDIT_MINIMAL, IconEditMinimal],
    [ICON_TYPE_OPEN, IconOpen],
    [ICON_TYPE_BIN, IconBin],
    [ICON_CHECK_SLIM_OUTLINE, IconCheckSlimOutline],
    [ICON_FULLSCREEN_SLIM, IconFullscreenSlim],
    [ICON_DOCUMENT_OUTLINE, IconDocumentsOutline],
    [ICON_ALERT_CIRCLE_SOLID, IconAlertCircleSolid],
    [ICON_TRIANGLE_OUTLINE, IconAlertTriangleOutline],
    [ICON_TRIANGLE_OUTLINE_GRAY, IconAlertTriangleOutlineGray],
    [ICON_CHECK_CIRCLE_SOLID, IconCheckCircleSolid],
    [ICON_DOCUMENT_LOADING, IconDocumentLoading],
    [ICON_DOCUMENT_RE_UPLOAD, IconDocumentReUpload],
    [ICON_DOWNLOAD_OUTLINE, IconDownloadOutline],
    [ICON_PEN_LEFT, IconPenLeft],
    [ICON_TRY_AGAIN, IconTryAgain],
    [ICON_ARROW_RIGHT_TRENDY, IconArrowRightTrendy],
]);
