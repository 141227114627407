export const getPropertyValueMinusMortgage = (val) => {
    if (val && Array.isArray(val) && val?.length) {
        const value = val?.reduce(
            (accumulator, currentValue) => accumulator + +currentValue.value || 0,
            0,
        );
        const mortgage = val?.reduce(
            (accumulator, currentValue) => accumulator + +currentValue.mortgage || 0,
            0,
        );

        return value - mortgage;
    }

    return null;
};
