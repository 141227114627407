import React from 'react';
import PropTypes from 'prop-types';
import { createUniqueKey } from '../../../utils';

function Labels(props) {
    const { xLabels, yLabels } = props;

    return (
        <>
            <div className="Risk-return-labels Risk-return-xaxis-labels">
                {xLabels.map((item, index) => (
                    <span key={createUniqueKey(index, item)}>{item}</span>))}
            </div>
            <div className="Risk-return-labels Risk-return-yaxis-labels">
                {yLabels.map((item, index) => (
                    <span key={createUniqueKey(index, item)}>{item}</span>))}
            </div>
        </>
    );
}

Labels.propTypes = {
    xLabels: PropTypes.arrayOf(PropTypes.string),
    yLabels: PropTypes.arrayOf(PropTypes.string),
};

Labels.defaultProps = {
    xLabels: [],
    yLabels: [],
};

export default Labels;
