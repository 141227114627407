import { useState, useEffect } from 'react';
import { MAX_HANDHELD_VIEWPORT_SIZE } from 'constants/constants';

const detectIsHandheld = () => document.body.clientWidth <= MAX_HANDHELD_VIEWPORT_SIZE;

export const useDetectedHandheldDevice = () => {
    const [isHandheldSize, setHandheldSize] = useState(detectIsHandheld());

    useEffect(() => {
        const handleResize = () => setHandheldSize(detectIsHandheld());

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return { isHandheldSize };
};
