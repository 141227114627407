import React from 'react';
import { useTranslation } from 'react-i18next';
import ReportForm from '../ReportForm';

const DawReport:React.FC = () => {
    const {t} = useTranslation();

    return <ReportForm title={t('reports.daw.title')} APIName="getDawReport" savedFileName="DAW Report" />;
};

export default DawReport;
