export const defaultDatePickerLocale = {
    lang: {
        locale: 'en_GB',
        placeholder: 'Select date',
        rangePlaceholder: ['Start date', 'End date'],
        today: 'Today',
        now: 'Now',
        backToToday: 'Back to today',
        ok: 'Set Date',
        cancel: 'Reset',
        clear: 'Clear',
        month: 'Month',
        year: 'Year',
        timeSelect: 'Select time',
        dateSelect: 'Select date',
        monthSelect: 'Choose a month',
        yearSelect: 'Choose a year',
        decadeSelect: 'Choose a decade',
        yearFormat: 'YYYY',
        dateFormat: 'M/D/YYYY',
        dayFormat: 'D',
        dateTimeFormat: 'M/D/YYYY hh:mm A',
        monthFormat: 'MMM',
        monthBeforeYear: true,
        previousMonth: 'Previous month (PageUp)',
        nextMonth: 'Next month (PageDown)',
        previousYear: 'Last year (Control + left)',
        nextYear: 'Next year (Control + right)',
        previousDecade: 'Last decade',
        nextDecade: 'Next decade',
        previousCentury: 'Last century',
        nextCentury: 'Next century',
    },
    timePickerLocale: {
        placeholder: 'Select time',
    },
    dateFormat: 'YYYY-MM-DD',
    dateTimeFormat: 'YYYY-MM-DD hh:mm A',
    weekFormat: 'YYYY-wo',
    monthFormat: 'YYYY-MM',
};
