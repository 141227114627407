import React from 'react';
import PropTypes from 'prop-types';
import CellLinked from './CellLinked';
import Checkbox from '../../Checkbox';

function CellWithCheckboxLinked({
    isChecked, value, id, link, maxLength, onCheck,
}) {
    return (
        <div className="table-cell_checkboxed" id={id}>
            <Checkbox label="" checked={isChecked} onChange={onCheck} />
            <CellLinked value={value} link={link} maxLength={maxLength} />
        </div>
    );
}

CellWithCheckboxLinked.propTypes = {
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    isChecked: PropTypes.bool,
    id: PropTypes.number,
    link: PropTypes.string,
    maxLength: PropTypes.number,
    onCheck: PropTypes.func,
};

CellWithCheckboxLinked.defaultProps = {
    value: null,
    isChecked: false,
    id: null,
    link: null,
    maxLength: 20,
    onCheck: () => {},
};

export default CellWithCheckboxLinked;
