import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../Icon/Icon';
import Tooltip from '../Tooltip';
import { ICON_TYPE_TOOLTIP } from '../Icon/constants';
import './Label.css';
import withTheme from '../../hocs/withTheme';

/** The label is always used on text inputs (textareas) and don’t depend on the placeholder text. */

function Label({
    htmlFor,
    label,
    helpText,
    labelInfobox,
    required,
}) {
    return (
        <label className={required ? 'Form-control-label required' : 'Form-control-label'} htmlFor={htmlFor}>
            <span>{label}</span>
            {(labelInfobox && helpText) && (
                <Tooltip title={helpText}><span role="button" tabIndex={0}><Icon type={ICON_TYPE_TOOLTIP} /></span></Tooltip>
            )}
        </label>
    );
}

Label.propTypes = {
    /** @ignore */
    htmlFor: PropTypes.string,
    /** The label text */
    label: PropTypes.string,
    /** The text displayed if the label tooltip exist */
    helpText: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.string,
    ]),
    /** If there is more information for the text input needed make use an info box */
    labelInfobox: PropTypes.bool,
    /** If required field* */
    required: PropTypes.bool,
};

Label.defaultProps = {
    htmlFor: null,
    label: null,
    helpText: null,
    labelInfobox: false,
    required: false,
};

export default withTheme(Label);
