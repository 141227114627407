import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import notification from "ui-library/components/Notification";
import { Infobox } from 'ui-library';
import React from "react";

export const useFileExist = (MAX_FILE_SIZE: number) => {
    const { t } = useTranslation();
    const [fileSizeError, setFileSizeError] = useState(false);

    const checkIfFileSizeExceed = useCallback((fileSize: number) => {
        if (fileSize > MAX_FILE_SIZE) {
            setFileSizeError(true);
            notification.open({ content: t('application.filesize.error.message'), type: 'error' });
            return true
        } else {
            setFileSizeError(false); 
        }
    }, [MAX_FILE_SIZE, t]);

    const FileSizeErrorComponent = () => (
        fileSizeError ? <Infobox error>{t('application.filesize.error.message')}</Infobox> : null
    );

    return { checkIfFileSizeExceed, FileSizeErrorComponent };
};
