export const supportLinkActive = (
    global.AppConfig.REACT_APP_UNBLU_URL?.length
    || global.AppConfig.REACT_APP_SURFLY_URL?.length
) !== 0;

export const isUnbluSupport = (global.AppConfig.REACT_APP_UNBLU_URL?.length ?? 0) !== 0;
export const isSurflySupport = !isUnbluSupport
    ? (global.AppConfig.REACT_APP_SURFLY_WIDGET_KEY?.length ?? 0) !== 0
    : false;

export const getCurrentEnv = () => {
    const suffix = global.AppConfig.DMS_URL_SUFFIX;

    if (suffix.toLowerCase().includes('dev') && global.window.location.hostname === 'localhost') { return 'localhost'; }
    if (suffix.toLowerCase().includes('dev')) { return 'dev'; }
    if (suffix.toLowerCase().includes('sandbox')) return 'sit';
    if (suffix.toLowerCase().includes('uat')) return 'uat';

    return 'production';
};
