import React, {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import {useTranslation} from 'react-i18next';
import {object, string} from 'yup';
import {Controller, useForm} from 'react-hook-form';
import {generalAgencySelector, usePortfolioSelector} from 'domain/Portfolio';
import {FEE_TYPES} from 'pages/ClientOverview/pages/Portfolios/pages/CreateNewPortfolio/constants';
import AssetFeeModal
    from 'pages/ClientOverview/pages/Portfolios/components/FeesOverview/components/AssetFeeModal/AssetFeeModal';
import CustodyFeeModal
    from 'pages/ClientOverview/pages/Portfolios/components/FeesOverview/components/CustodyFeeModal/CustodyFeeModal';
import SelectElement from 'components/AtomicStructure/atoms/SelectElement';
import SM from 'services/ServiceManager';
import {
    Button, Icon, Loader, Modal, Select,
} from '../../../../../../../../../../ui-library';
import {useYupValidationResolver} from '../../../../../../../../../../hooks/useYupValidationResolver';
import ServiceManager from '../../../../../../../../../../services/ServiceManager';
import useFormatting from '../../../../../../../../../../locale/useFormatting';
import Preloader from '../../../../../../../../../../components/Preloader';
import notification from '../../../../../../../../../../ui-library/components/Notification';
import PbNumber from '../../../../../../components/PbNumber/PbNumber';
import {useManagerProfile} from '../../../../../../../../../../prodivers/managerProfile';
import {getAgentInfo, joinWithSeparator} from '../../utils';
import {validateInputAlphanumeric} from '../../../../../../../../../../validation/validation';
import {useBaseAgentInformation} from '../../../../../../components/AgentInformation/hooks/useBaseAgentInformation';
import {CHANGE_HISTORY_TYPE, DEPOSIT_MODAL_TYPE} from '../../constants';
import ChangeHistoryModal from './ChangeHistoryModal';

import '../../styles/styles.css';
import {useSinglePortfolio} from '../../../../context';
import distinguishClientType
    from 'pages/ClientOverview/pages/Portfolios/pages/CreateNewPortfolio/pages/FeesOverview/hooks/distinguishClientType';

const FEES_ACCESS_POLICIES = {
    [FEE_TYPES.custody]: 'EnableEditCustodyFee',
    [FEE_TYPES.asset]: 'EnableEditAssetFee',
};

const FzDepotInformationKpi = ({portfolioId}) => {
    const {t} = useTranslation();
    const {getFormattedDate, getFormattedNumber} = useFormatting();
    const {
        modelPortfolio: dataModelPortfolio,
        portfolioDetailRaw,
        fees: allFees,
        setFees,
        investmentAppPersonalDetails,
        withdrawal,
        isPortfolioOnReadOnly,
        investmentAppId,
        isPk,
        isZivAzp,
        isAzpAlt,
        isZic,
        isAzp,
    } = useSinglePortfolio();
    const {getAgentAndGeneralAgencyData} = usePortfolioSelector(generalAgencySelector);

    const [modalVersion, setModalVersion] = useState(null);
    const [planDetails, setPlanDetails] = useState(null);
    const [saveInProgress, setSaveInProgress] = useState(false);
    const [detailsLoading, setDetailsLoading] = useState(false);
    const [detailsError, setDetailsError] = useState(null);
    const [changeHistoryType, setChangeHistoryType] = useState(null);
    const [selectedFeeType, setSelectedFeeType] = useState(null);
    const [agentInfo, setAgentInfo] = useState('');
    const [vstNumber, setVstNumber] = useState('');
    const [vstNumberError, setVstNumberError] = useState(null);
    const [searchAgentsInProgress, setSearchAgentsInProgress] = useState(false);
    const [defaultAssetFee, setDefaultAssetFee] = useState();
    const [agentsList, setAgentsList] = useState([]);
    const [feeModal, setFeeModal] = useState(null);
    const {hasAccessPolicy} = useManagerProfile();
    const {generalAgencies} = useBaseAgentInformation({});
    const modelPortfolioSettings = useMemo(() => (JSON.parse(dataModelPortfolio?.Attributes ?? '{}')), [dataModelPortfolio?.Attributes]);
    const issueCommissionOptions = useMemo(() => (
        (modelPortfolioSettings?.issueComission ?? []).map((item) => ({
            label: getFormattedNumber(item?.label, { maximumFractionDigits: 1, minimumFractionDigits: 1 }),
            id: String(item.value),
        }))
    ), [modelPortfolioSettings?.issueComission]);

    const fees = useMemo(() => allFees?.filter(f => f.feeType !== FEE_TYPES.entry), [allFees]);

    const custodyAmFee = useMemo(() => {
        if (isPk) {
            return [];
        }

        const feesOptions = [{type: FEE_TYPES.custody, isEditable: !isZic}];

        if (isZivAzp || isAzpAlt) {
            feesOptions.push({type: FEE_TYPES.asset, isEditable: true});
        }

        return feesOptions;
    }, [isZivAzp, isPk, isZic]);

    const agentsListOptions = useMemo(() => [{value: '', label: t('position.pleaseSelect')}, ...agentsList.map(i => ({value: String(i.id), label: `${i.firstName} ${i.lastName} (${i.email})`}))], [agentsList]);

    const investedAmount = useMemo(() => (isAzp ? withdrawal?.investmentAmount : portfolioDetailRaw?.InvestedAmount), [
        withdrawal?.initialInvestment,
        isAzp,
        portfolioDetailRaw?.InvestedAmount,
    ]);

    const clientType = useMemo(() => distinguishClientType(investmentAppPersonalDetails?.zurichEmployee, investmentAppPersonalDetails?.destinataryVita),
        [
            investmentAppPersonalDetails?.zurichEmployee,
            investmentAppPersonalDetails?.destinataryVita,
        ]);

    useEffect(() => {
        (async () => getAgentInformation(''))();
    }, []);

    const getPlanDetails = useCallback(async () => {
        try {
            setDetailsLoading(true);
            const {data} = await ServiceManager.planService('getPlanDetails', [{portfolioId}]);

            setPlanDetails(data);
        } catch (err) {
            setDetailsError(err);
        } finally {
            setDetailsLoading(false);
        }
    }, [portfolioId]);

    const updatePbNumber = useCallback(async (values) => {
        try {
            setSaveInProgress(true);
            await ServiceManager.planService('updatePbNumber', [{
                portfolioId, pbNumber: values.newlySelectedValue, agentId: values.agentId, vstNumber,
            }]);
            getPlanDetails();
            getAgentAndGeneralAgencyData(portfolioId);
            notification.open({content: t('shadowAccount.depot.pbNumberSuccessfullyChanged'), type: 'success'});
            setModalVersion(null);
        } catch (err) {
            notification.open({content: err?.message, type: 'error'});
        } finally {
            setSaveInProgress(false);
        }
    }, [portfolioId, getPlanDetails, vstNumber]);

    const updateGeneralAgency = useCallback(async (generalAgency) => {
        try {
            setSaveInProgress(true);
            await ServiceManager.planService('updateGeneralAgency', [{portfolioId, generalAgency}]);
            getPlanDetails();
            getAgentAndGeneralAgencyData(portfolioId);
            notification.open({content: t('shadowAccount.depot.generalAgencySuccessfullyChanged'), type: 'success'});
            setModalVersion(null);
        } catch (err) {
            notification.open({content: err?.message, type: 'error'});
        } finally {
            setSaveInProgress(false);
        }
    }, [portfolioId, getPlanDetails]);

    const updateFeesLocally = useCallback((payload) => {
        // update fees in the app's state
        setFees(prev => {
            const index = prev?.findIndex(f => f.feeType === payload.feeType);

            prev[index] = payload;

            return [...prev];
        });
    }, []);

    const updateFees = useCallback(async (feeType, payload) => {
        try {
            await ServiceManager.customInvestmentService('updateFees', [{ investmentApplicationId: investmentAppId, payload }]);
            updateFeesLocally(payload);
            notification.open({ content: t('shadowAccount.depot.feeUpdatedSuccessfully'), type: 'success' });
        } catch (err) {
            notification.open({ content: t('general.somethingWentWrong'), type: 'error' });
            throw err;
        }
    }, [investmentAppId, getPlanDetails, updateFeesLocally]);

    const getDefaultAssetFee = useCallback(async () => {
        const payload = {modelPortfolioId: dataModelPortfolio.Id, investedAmount, clientType};

        if (isZivAzp) {
            try {
                const {data} = await SM.planService('getAssetManagement', [payload]);

                setDefaultAssetFee(data?.standardFee);
            } catch (error) {
                // Handle error here
                console.error(error);
            }
        }
    }, [isZivAzp, dataModelPortfolio, clientType, investedAmount]);

    const updateIssueCommission = useCallback(async (issueCommission) => {
        try {
            setSaveInProgress(true);
            await ServiceManager.planService('updateIssueCommission', [{portfolioId, issueCommission}]);
            getPlanDetails();
            notification.open({content: t('shadowAccount.depot.issueCommissionChanged'), type: 'success'});
            setModalVersion(null);
        } catch (err) {
            notification.open({content: err?.message, type: 'error'});
        } finally {
            setSaveInProgress(false);
        }
    }, [portfolioId, getPlanDetails]);

    const getAgentInformation = useCallback(async (filter) => {
        try {
            setSearchAgentsInProgress(true);

            const {data} = await ServiceManager.customMembersService('getMembers', [{filter}]);

            setAgentsList(data);
        } finally {
            setSearchAgentsInProgress(false);
        }
    }, []);

    useEffect(() => {
        (async () => getPlanDetails())();
    }, [getPlanDetails]);

    const schema = useMemo(() => object().shape({
        newlySelectedValue: string().max(9, t('shadowAccount.depot.pbNumberMaxLength')).required(t('validation.mandatoryField')),
        agentId: string().when([], {is: () => modalVersion === DEPOSIT_MODAL_TYPE.pbNumber, then: string().required(t('validation.mandatoryField'))}).nullable(),
    }), [t, modalVersion]);

    const resolver = useYupValidationResolver(schema);

    const {
        control, handleSubmit, formState: { errors}, setValue, watch,
    } = useForm({
        resolver,
        defaultValues: {
            newlySelectedValue: '',
            agentId: '',
        },
    });

    const {newlySelectedValue, agentId} = watch();

    const handleSaveData = handleSubmit(async (values) => {
        if (modalVersion === DEPOSIT_MODAL_TYPE.commission) {
            await updateIssueCommission(values.newlySelectedValue);
        } else if (modalVersion === DEPOSIT_MODAL_TYPE.pbNumber) {
            const hasError = validateInputAlphanumeric(vstNumber, true);

            if (hasError) {
                setVstNumberError(hasError);

                return;
            }
            setVstNumberError(null);
            await updatePbNumber(values);
        } else {
            await updateGeneralAgency(values.newlySelectedValue);
        }
    });

    const showFeesHistoryModal = useCallback((feeType) => {
        setSelectedFeeType(feeType);
        setChangeHistoryType(CHANGE_HISTORY_TYPE.commission);
    }, []);

    const generalAgenciesOptions = useMemo(() => [{label: t('position.pleaseSelect'), id: ''}, ...(generalAgencies?.map(i => ({label: i.name, id: i.id})) || [])], [generalAgencies]);

    const pbNumberForm = useMemo(() => (
        <>

            <Controller
                name="newlySelectedValue"
                value={newlySelectedValue}
                control={control}
                render={({field}) => (
                    <PbNumber
                        label={t('shadowAccount.depot.changePbNumber')}
                        required
                        {...field}
                        error={errors?.newlySelectedValue?.message}
                        setAgentInfo={setAgentInfo}
                        onSelect={(val) => setValue('newlySelectedValue', val, {shouldValidate: true})}
                        includeVstNumber
                        vstNumberValue={vstNumber}
                        onVstNumberChange={val => setVstNumber(val)}
                        vstNumberError={vstNumberError}
                    />
                )}
            />

            <Controller
                name="agentId"
                control={control}
                value={agentId}
                render={({field}) => (
                    <Select
                        {...field}
                        className="agent-info"
                        options={agentsListOptions}
                        innerRef={field.ref}
                        label={t('shadowAccount.depot.changeAdvisor')}
                        required
                        error={errors?.agentId?.message}
                        hasSearch
                        asyncSearch={getAgentInformation}
                        searchInProgress={searchAgentsInProgress}
                    />
                )}
            />
        </>
    ),
    [planDetails, errors, setAgentInfo, setValue, vstNumber, setVstNumber, vstNumberError, agentInfo, newlySelectedValue, control, agentsListOptions, searchAgentsInProgress]);

    const generalAgencyForm = useMemo(() => (
        <>
            <div className="row-data">
                <span>{t('shadowAccount.depot.oldGeneralAgency')}</span>
                <span>{`${planDetails?.generalAgency?.vstNumber} - ${planDetails?.generalAgency?.name}`}</span>
            </div>
            <Controller
                name="newlySelectedValue"
                value={newlySelectedValue}
                control={control}
                render={(field) => (
                    <SelectElement
                        {...field}
                        onChange={(val) => setValue('newlySelectedValue', val, {shouldValidate: true})}
                        value={newlySelectedValue}
                        options={generalAgenciesOptions}
                        hasSearch
                        error={errors?.newlySelectedValue?.message}
                    />
                )}
            />
            <div />
        </>
    ), [planDetails, newlySelectedValue, control, generalAgenciesOptions, errors]);

    const commissionOptions = useMemo(() => [{label: t('position.pleaseSelect'), id: ''}, ...(issueCommissionOptions?.map(i => ({label: i.label, id: i.id})) || [])], [issueCommissionOptions]);
    const issueCommissionForm = useMemo(() => (
        <>
            <div className="row-data">
                <span>{t('shadowAccount.depot.oldIssueCommission')}</span>
                <span>{`${planDetails?.issueCommission}%`}</span>
            </div>
            <Controller
                name="newlySelectedValue"
                value={newlySelectedValue}
                control={control}
                render={(field) => (
                    <SelectElement
                        {...field}
                        onChange={(val) => setValue('newlySelectedValue', val, {shouldValidate: true})}
                        value={newlySelectedValue}
                        options={commissionOptions}
                        hasSearch
                        error={errors?.newlySelectedValue?.message}
                    />
                )}
            />
            <div />
        </>
    ), [planDetails, newlySelectedValue, control, commissionOptions, errors]);

    const modalContentByType = useMemo(() => ({
        [DEPOSIT_MODAL_TYPE.pbNumber]: pbNumberForm,
        [DEPOSIT_MODAL_TYPE.generalAgency]: generalAgencyForm,
        [DEPOSIT_MODAL_TYPE.commission]: issueCommissionForm,
    }), [pbNumberForm, generalAgencyForm, issueCommissionForm]);

    const getFeeLabel = useCallback((feeType) => {
        if (feeType === FEE_TYPES.custody) {
            return t('shadowAccount.depot.custody');
        }
        if (feeType === FEE_TYPES.asset) {
            return t('shadowAccount.depot.asset');
        }

        return t('shadowAccount.depot.entry');
    }, [t]);

    useEffect(() => {
        (async () => getDefaultAssetFee())();
    }, [getDefaultAssetFee]);

    return (
        <Preloader isLoading={detailsLoading} error={detailsError}>
            <dl className="DataList DataList--inline depot-info">
                <div>
                    <dt>{t('shadowAccount.depot.refNumber')}</dt>
                    <dd>{planDetails?.lpzPlanId}</dd>
                </div>
                <div>
                    <dt>IBAN</dt>
                    <dd>{planDetails?.accountIban?.replace(/.{1,4}/g, '$& ')}</dd>
                </div>
                <div>
                    <dt>{t('shadowAccount.depot.openDate')}</dt>
                    <dd>{getFormattedDate(planDetails?.openingDate)}</dd>
                </div>
                <div>
                    <dt>{t('shadowAccount.depot.closeDate')}</dt>
                    <dd>{getFormattedDate(planDetails?.closingDate) || '-'}</dd>
                </div>
                {
                    custodyAmFee.map(item => {
                        const feeValue = fees?.find(fee => fee.feeType === item.type)?.fee;

                        return (
                            <div>
                                <dt>{getFeeLabel(item.type)}</dt>
                                <dd>
                                    {feeValue ? `${feeValue}%` : '-'}
                                </dd>
                                {!isZic && (
                                    <dt>
                                        {(item.isEditable && hasAccessPolicy(FEES_ACCESS_POLICIES[item.type])) && isPortfolioOnReadOnly && (
                                            <>
                                                <Button type="link" onClick={() => setFeeModal(item.type)}>
                                                    <Icon type="edit"/>
                                                    {t('interaction.edit')}
                                                </Button>
                                                <span className="splitter">|</span>
                                            </>
                                        )}

                                        <Icon type="history" onClick={() => showFeesHistoryModal(item.type)}/>
                                    </dt>
                                )}
                            </div>
                        );
                    })
                }
                {!isPk && (
                    <div>
                        <dt>{t('shadowAccount.depot.commission')}</dt>
                        <dd>
                            {`${planDetails?.issueCommission}%`}
                        </dd>
                        {!isZic && (
                            <dt>
                                {hasAccessPolicy('ChangePBNumber') && isPortfolioOnReadOnly && (
                                    <>
                                        <Button type="link" onClick={() => setModalVersion(DEPOSIT_MODAL_TYPE.commission)}>
                                            <Icon type="edit" />
                                            {t('interaction.edit')}
                                        </Button>
                                        <span className="splitter">|</span>
                                    </>
                                )}
                                <Icon type="history" onClick={() => showFeesHistoryModal(FEE_TYPES.issueCommission)} />
                            </dt>
                        )}
                    </div>
                )}
                <div>
                    <dt>
                        {t('shadowAccount.depot.pbNumber')}
                    </dt>
                    <dd>
                        {`${planDetails?.pbNumber} - ${getAgentInfo(t, planDetails?.memberFullName, planDetails?.provisionReceiver)}`}
                    </dd>
                    <dt>
                        {hasAccessPolicy('ChangePBNumber') && isPortfolioOnReadOnly && (
                            <>
                                <Button type="link" onClick={() => setModalVersion(DEPOSIT_MODAL_TYPE.pbNumber)}>
                                    <Icon type="edit" />
                                    {t('interaction.edit')}
                                </Button>
                                <span className="splitter">|</span>
                            </>
                        )}

                        <Icon type="history" onClick={() => setChangeHistoryType(CHANGE_HISTORY_TYPE.pbNumber)} />
                    </dt>
                </div>
                <div>
                    <dt>{t('portfolio.createNewPortfolio.agentInformation.generalAgency')}</dt>
                    <dd>
                        {joinWithSeparator(' - ', planDetails?.generalAgency?.vstNumber, planDetails?.generalAgency?.name)}
                    </dd>
                    <dt>
                        {hasAccessPolicy('ChangePBNumber') && isPortfolioOnReadOnly && (
                            <>
                                <Button type="link" onClick={() => setModalVersion(DEPOSIT_MODAL_TYPE.generalAgency)}>
                                    <Icon type="edit" />
                                    {t('interaction.edit')}
                                </Button>
                                <span className="splitter">|</span>
                            </>
                        )}

                        <Icon type="history" onClick={() => setChangeHistoryType(CHANGE_HISTORY_TYPE.generalAgency)} />
                    </dt>
                </div>
            </dl>
            {!!modalVersion && (
                <Modal
                    visible
                    title={t(`shadowAccount.depot.change${modalVersion}Title`)}
                    onCancel={() => setModalVersion(null)}
                    onOk={handleSaveData}
                    width={550}
                    okText={saveInProgress ? <Loader /> : t(`shadowAccount.depot.save${modalVersion}Button`)}
                    className="change-pb-number"
                    okButtonProps={{disabled: saveInProgress}}
                >
                    {modalContentByType[modalVersion]}
                </Modal>
            )}
            {changeHistoryType && <ChangeHistoryModal type={changeHistoryType} onClose={() => setChangeHistoryType(null)} portfolioId={portfolioId} selectedFeeType={selectedFeeType} investmentApplicationId={investmentAppId}/>}

            <CustodyFeeModal showModal={feeModal === FEE_TYPES.custody} handleClose={() => setFeeModal(null)} feeData={fees?.find(f => f.feeType === feeModal)} onSave={updateFees} defaultCustodyFee={modelPortfolioSettings?.custodyFee} showDeleteButton onDeleteCb={updateFeesLocally}/>
            <AssetFeeModal showModal={feeModal === FEE_TYPES.asset} handleClose={() => setFeeModal(null)} feeData={fees?.find(f => f.feeType === feeModal)} onSave={updateFees} defaultAssetFee={defaultAssetFee} showDeleteButton onDeleteCb={updateFeesLocally}/>

        </Preloader>
    );
};

export default FzDepotInformationKpi;
