import React from 'react';
import PropTypes from 'prop-types';
import './Menu.css';
import withTheme from '../../../hocs/withTheme';

function Menu({
    children, className, ...rest
}) {
    return (
        <ul
            {...rest}
            className={`Menu ${className}`}
            role="menu"
        >
            {children}
        </ul>
    );
}

Menu.propTypes = {
    /** @ignore */
    children: PropTypes.node,
    /** @ignore */
    className: PropTypes.string,
};

Menu.defaultProps = {
    children: null,
    className: '',
};

export default withTheme(Menu);
