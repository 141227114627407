import {useCallback, useRef} from 'react';
import ServiceManager from '../services/ServiceManager';

const getCacheKey = (params) => (
    params && params.length ? JSON.stringify(params) : undefined
);

export const useCachedAPI = () => {
    const cacheKey = useRef({});

    const requestWithCache = useCallback(async ({service, apiName, params}) => {
        const cacheCode = getCacheKey(params);

        if (cacheKey.current?.[apiName] && cacheKey.current[apiName]?.cacheCode === cacheCode) {
            return cacheKey.current?.[apiName].data;
        }

        const response = await service(apiName, params);

        cacheKey.current = {
            ...cacheKey.current,
            [apiName]: { cacheCode, data: response?.data},
        };

        return response?.data;
    }, []);

    const request = useCallback(async ({
        service, apiName, params, options = {withCache: false},
    }) => {
        if (options.withCache) {
            return requestWithCache({service, apiName, params});
        }
        const response = await service(apiName, params);

        return response?.data;
    }, []);

    const getPortfolioDetails = (params, options?) => request({
        service: ServiceManager.portfolioManagement, apiName: 'getPortfolioDetails', params, options,
    });
    const getLastApplicationByPortfolio = (params, options?) => request({
        service: ServiceManager.customInvestmentService, apiName: 'getLastApplicationByPortfolio', params, options,
    });

    const getInvestmentApplication = (params, options?) => request({
        service: ServiceManager.customInvestmentService, apiName: 'getInvestmentApplication', params, options,
    });

    const getModelPortfolio = (params, options?) => request({
        service: ServiceManager.portfolioManagement, apiName: 'getModelPortfolio', params, options,
    });

    const getPortfolioProposal = (params, options?) => request({
        service: ServiceManager.portfolioManagement, apiName: 'getPortfolioProposal', params, options,
    });

    const getWithdrawalData = (params, options?) => request({
        service: ServiceManager.planService, apiName: 'getWithdrawalData', params, options,
    });

    return {
        requestWithCache,
        getPortfolioDetails,
        getLastApplicationByPortfolio,
        getInvestmentApplication,
        getModelPortfolio,
        getPortfolioProposal,
        getWithdrawalData,
    };
};
