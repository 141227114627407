import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Security from 'additiv-services/services/core/security';
import { setup } from 'additiv-services';
import { Loader } from 'ui-library';
import OIDCAuthenticationProvider, { withSecure } from 'libs/additiv-react-oidc-client';
import {useNavigate} from 'react-router-dom';
import { isAuthenticatedSelector } from '../../redux-store/auth/authSelectors';
import { getUser } from '../../redux-store/auth/authActions';
import config from '../../configs/idenityServerConfig';
import { INIT_LOCATION } from '../../constants/sessionStorageKeys';

const SecureLoader = withSecure(Loader);

const AuthenticationProvider = ({ children }) => {
    const navigate = useNavigate();
    const isAuthenticated = useSelector(isAuthenticatedSelector);

    const [isUserLoading, setUserLoading] = useState(!isAuthenticated);
    const dispatch = useDispatch();

    useEffect(() => {
        const initLocation = global.sessionStorage.getItem(INIT_LOCATION);

        if (!isAuthenticated && !initLocation && !global.location.pathname.startsWith('/auth')) {
            global.sessionStorage.setItem(INIT_LOCATION, global.location.pathname);
        }
    }, []);

    const getUserData = async (user) => {
        if (isAuthenticated) return;

        setUserLoading(true);
        await dispatch(getUser({
            profileId: user?.profile?.mid,
        }));
        const initLocation = global.sessionStorage.getItem(INIT_LOCATION);

        setUserLoading(false);

        navigate(initLocation || '/dashboard');
    };

    const updateToken = (user) => {
        setup({
            bearerAccessToken: `Bearer ${user?.access_token}`,
        });
    };

    const onLogin = (user) => {
        updateToken(user);
        getUserData(user);
    };

    const onLogout = async () => {
        global.sessionStorage.removeItem('redux_sessionstorage_auth');
        try {
            await Security.invalidateAllAccessTokens();
        } catch (e) {
            console.log('Member access token has already expired', e);
        }
    };

    return (
        <OIDCAuthenticationProvider
            configuration={config}
            loadingComponent={<Loader />}
            onUserLoaded={onLogin}
            onLogout={onLogout}
        >
            {isUserLoading ? <SecureLoader /> : children}
        </OIDCAuthenticationProvider>
    );
};

export default AuthenticationProvider;
