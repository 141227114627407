import React from 'react';
import PropTypes from 'prop-types';
import Icon, { ICON_TYPE_MINUS, ICON_TYPE_PLUS } from '../../../Icon';

function ExpandIcon({ isActive }) {
    return <Icon type={isActive ? ICON_TYPE_MINUS : ICON_TYPE_PLUS} />;
}

ExpandIcon.propTypes = {
    isActive: PropTypes.bool,
};

ExpandIcon.defaultProps = {
    isActive: false,
};

export default ExpandIcon;
