import React, {useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {
    Label as LabelUI, LineChart, Select,
} from 'ui-library';

import LoadingOverlap from 'components/LoadingOverlap';
import Preloader from 'components/Preloader';
import EmptyContent from 'components/EmptyContent';
import CustomRangeSelect from 'components/CustomRangeSelect';
import {formatBigNumber} from 'utils/formatting';
import {
    investorPerformanceSelectValues,
    investorProposedPerformanceSelectValues,
} from 'filters/investementPerformanceFilters';
import {
    LINE, lineChartLabels,
} from 'constants/constants';
import './OverviewChart.css';

import {useFormatting} from 'locale';
import Card from '../../../../../../../../../../components/Card/Card';
import {CHART_DISPLAY_OPTION} from '../../../../constants';

type TOverviewChartData = {
    name: string,
    data: Array<number>
}
type TOverviewDataLine = {
    data: Array<TOverviewChartData>,
    error: any
    isLoading: boolean,
    title?: string,
    optional: {
        tooltipFormat: (number: number, date: Date) => string,
        yAxisFormat: (ref) => string
        matrix: {
            name: string,
            x: Array<number>
        },
        maxX: number,
        maxY: number,
        minX: number,
        minY: number,
        xLabels: Array<string>,
        yLabels: Array<string>,
    }
}

export type TOverviewData = {
    line: TOverviewDataLine,
    riskReturn: TOverviewDataLine
}

type TOverview = {
    data: TOverviewData
    performanceSelected: string
    isLoading: boolean,
    error: boolean | string,
    isPortfolioProposed?: boolean,
    onFilterChange: (value) => void,
    displayOptionSelected: number,
    onDisplayOptionChange: (value) => void
}

const Overview = ({
    data,
    performanceSelected,
    onFilterChange,
    isLoading,
    error,
    isPortfolioProposed,
    displayOptionSelected,
    onDisplayOptionChange,
}: TOverview) => {
    const {t} = useTranslation();
    const {getFormattedXAxisFormat} = useFormatting();
    const investorPerformanceOptions = [
        ...(isPortfolioProposed
            ? investorProposedPerformanceSelectValues : investorPerformanceSelectValues)
            .map((item) => ({
                ...item,
                label: t(`clientDashboard.assetDevelopment.filters.${item.value}`),
            })),
    ];
    const displayOptions = useMemo(() => [{label: t('clientDashboard.assetDevelopment.percentage'), value: CHART_DISPLAY_OPTION.percentage}, {label: t('clientDashboard.assetDevelopment.amount'), value: CHART_DISPLAY_OPTION.amount}], [t]);

    // This one is not being used by chart, I do not see the point of having it here
    const labelFormat = useCallback((label, values = {}) => {
        const valuesFormatted = values.new !== undefined
            ? `${formatBigNumber(values.new)}% (${t('clientDashboard.portfolio.toDate')} ${formatBigNumber(values.curr)}%)`
            : `${formatBigNumber(values.curr)}%`;

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return [<LabelUI label={label} labelInfobox/>, valuesFormatted];
    }, [t]);

    const isCalcError = useCallback((errObj) => {
        const errorObj = errObj?.error?.response?.data;

        return !!(errorObj?.Code === 'ValidationFailed' && errorObj.Properties?.ValidationErrors?.find(
            (item) => item?.Message?.includes('The monthly returns cannot be calculated'),
        ));
    }, [error]);

    return (
        <Card
            title={t('clientDashboard.overview.performance')}
            headerActions={(
                <div className="chart-filters">
                    <Select
                        onChange={onDisplayOptionChange}
                        options={displayOptions}
                        value={displayOptionSelected}
                        width={200}
                    />
                    <CustomRangeSelect
                        onChange={onFilterChange}
                        options={investorPerformanceOptions}
                        value={performanceSelected}
                        width={200}
                    />

                </div>
            )}
        >
            {isCalcError(data[LINE]?.error) ? (
                <p className="widget__empty">{t('position.performanceNoHistory')}</p>
            ) : (
                <Preloader isLoading={isLoading} error={error || data[LINE].error}>
                    <LoadingOverlap isLoading={data[LINE].isLoading}>
                        <EmptyContent
                            data={data[LINE].isLoading ? {} : data[LINE].data?.[0]?.data}
                            text={t('advisoryDashboard.noData')}
                        >
                            <LineChart
                                xAxisFormat={getFormattedXAxisFormat}
                                labels={lineChartLabels(t)}
                                data={data[LINE].data}
                                chartView={LINE}
                                labelFormat={labelFormat}
                                {...data[LINE].optional}
                                legendEnabled
                            />
                        </EmptyContent>
                    </LoadingOverlap>
                </Preloader>
            )}
        </Card>
    );
};

Overview.defaultProps = {
    isPortfolioProposed: false,
};

const areEqual = (prevProps, nextProps) => JSON.stringify(prevProps) === JSON.stringify(nextProps);
const OverviewWithMemo = React.memo(Overview, areEqual);

export default OverviewWithMemo;
