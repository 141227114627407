import React, {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import {KYCForm} from 'components/KYCForm';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';
import { KYC as KYCConstants } from 'constants/constants';
import {IsAllDataNull} from 'utils/validation';
import {errorModel, getModel, sendModel} from 'components/KYCForm/models';
import {getClientId} from '../../../../utils';
import Preloader from '../../../../components/Preloader';
import {useGetKYC} from '../../../../hooks/rest/useGetKYC';
import {useGetContact} from '../../../../hooks/rest/useGetContact';
import Card from '../../../../components/Card/Card';
import ButtonsBlockRow from '../../../../components/ButtonsBlockRow';
import {Loader, notification} from '../../../../ui-library';
import {useClientLayout} from '../../hooks/useClientLayout';
import ServiceManager from '../../../../services/ServiceManager';

import './ClientKYC.css';

const ClientKYC = () => {
    const {t} = useTranslation();
    const {dfsClientId} = useParams();
    const contactId = getClientId(dfsClientId);
    const [data, setData] = useState<KYCDataPayload>({});
    const [errorData, setErrorData] = useState<KYCErrorData | null>(null);
    const [saveInProgress, setSaveInProgress] = useState(false);
    const [isDataPosted, setDataPosted] = useState(false);

    useClientLayout();

    const isBeneficialOwner = useMemo(() => data?.beneficialOwner === KYCConstants.thirdParty, [data?.beneficialOwner]);

    const {
        data: kycData,
        isLoading: isKYCLoading,
        error: kycError,
        statusCode: kycStatusCode,
    } = useGetKYC({
        contactId,
    });

    const {
        data: dataContact,
        isLoading: isContactLoading,
        error: contactError,
    } = useGetContact({
        contactId,
    });

    const isSwissNationality = useMemo(() => {
        if (dataContact?.details?.personalInformation?.identification?.length > 0) {
            return dataContact?.details?.personalInformation?.identification[0]?.countryOfNationality === KYCConstants.swissNationality;
        }

        return false;
    },
    [dataContact?.details?.personalInformation]);

    useEffect(() => {
        setData(getModel(kycData));
    }, [kycData]);

    const handleSubmit = useCallback(async () => {
        const errors = await errorModel(data, isSwissNationality);

        setErrorData(errors);

        if (IsAllDataNull(errors) !== false) {
            const payload = sendModel({data, contactId});

            const isDataExists = kycStatusCode === 200 || isDataPosted;

            try {
                setSaveInProgress(true);
                const response = await ServiceManager.customClientService(isDataExists ? 'putKYCData' : 'postKYCData', [contactId, payload]);

                setData(getModel(response.data));
                setDataPosted(true);
                notification.open({content: t('general.saveSuccessMessage'), type: 'success'});
            } catch (err) {
                notification.open({content: t('general.somethingWentWrong'), type: 'error'});
            } finally {
                setSaveInProgress(false);
            }
        }
    }, [data, kycStatusCode]);

    return (
        <div className="container newStyle ClientKYC">
            <Card
                title={t('clientDashboard.kyc.title')}
                titleTagName="h1"
            >
                <Preloader
                    isLoading={isKYCLoading || isContactLoading}
                    error={kycError || contactError}
                >
                    <div className="modification-container">
                        <div className="modification-item">
                            <dt>{t('clientDashboard.kyc.lastModifiedOn')}</dt>
                            <dd>{data.modifiedDate}</dd>
                        </div>
                        <div className="modification-item">
                            <dt>{t('clientDashboard.kyc.lastModifiedBy')}</dt>
                            <dd>{data.modifiedBy}</dd>
                        </div>
                    </div>
                    <KYCForm
                        data={data}
                        personalDetails={dataContact}
                        setData={setData}
                        errorData={errorData}
                        setErrorData={setErrorData}
                    />
                    <ButtonsBlockRow
                        primaryButton={{
                            disabled: isBeneficialOwner,
                            text: saveInProgress ? <Loader/> : t('clientDashboard.kyc.saveBtn.label'),
                            onClick: handleSubmit,
                        }}
                    />
                </Preloader>
            </Card>
        </div>
    );
};

export default ClientKYC;
