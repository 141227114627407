import {
    validateDateOfBirth,
    validateEmailAdress,
    validateInputLength,
    validateInputNumber,
    validateInputString,
    validatePhoneNumber,
    validateRadio,
} from 'validation/validation';
import { FIELD_LENGTH } from 'pages/ClientOverview/constants';
import { getValidationMessage } from 'validation/messages';

export const errorModel = ({ data, isDependantOnAffiliatedWithPensionFund, showEmploymentContinuationCheck }) => ({
    firstName: validateInputString(data.firstName),
    lastName: validateInputString(data.lastName),
    dateOfBirth: validateDateOfBirth(data.dateOfBirth),
    gender: validateRadio(data.gender),
    street: validateInputString(data.street) || validateInputLength(data.street, FIELD_LENGTH.street),
    PLZ: validateInputNumber(data.PLZ),
    ORT: validateInputString(data.ORT) || validateInputLength(data.ORT, FIELD_LENGTH.city),
    countryOfNationality: validateInputNumber(data.countryOfNationality),
    countryOfBirth: validateInputNumber(data.countryOfBirth),
    countryOfResidence: validateInputNumber(data.countryOfResidence),
    primaryEmail: validateEmailAdress(data.primaryEmail),
    primaryPhoneNumber: validatePhoneNumber(data.primaryPhoneNumber, false),
    affiliatedPensionFund: isDependantOnAffiliatedWithPensionFund ? validateInputString(data.affiliatedPensionFund) : null,
    occupation: validateInputString(data.occupation),
    member: validateRadio(data.member),
    destinedLife: validateRadio(data.destinedLife),
    zurichMA: validateRadio(data.zurichMA),
    employmentContinuation: showEmploymentContinuationCheck && !data?.employmentContinuation ? getValidationMessage().mandatoryError : null,
});
