import {isNil} from 'lodash/fp';
import {isEmpty} from 'lodash';

import {switzLandCountryId} from 'constants/constants';

export const getModel = (outputData, personalDetails, getFormattedDate) => {
    const personalInformation = outputData?.details?.personalInformation;
    const communicationMethods = outputData?.details?.communicationMethods;
    const loadedOccupation = personalInformation?.occupation;
    let affiliatedPensionFund;

    if (personalDetails) {
        affiliatedPensionFund = !isNil(personalDetails?.affiliatedToPensionFund) ? String(personalDetails?.affiliatedToPensionFund) : undefined;
    } else {
        affiliatedPensionFund = !isNil(outputData?.details?.personalInformation?.affiliatedPensionFund) ? String(outputData?.details?.personalInformation?.affiliatedPensionFund) : undefined;
    }
    const occupationValue = () => {
        if (!isEmpty(personalDetails)) {
            return (personalDetails?.occupation);
        }

        return loadedOccupation ? String(loadedOccupation) : undefined;
    };

    return {
        firstName: personalInformation?.firstName,
        lastName: personalInformation?.lastName,
        dateOfBirth: personalInformation?.dateOfBirth && typeof getFormattedDate === 'function' && getFormattedDate(personalInformation?.dateOfBirth),
        gender: personalInformation?.gender,
        street: communicationMethods?.primaryAddress?.primaryAddressLine3,
        PLZ: communicationMethods?.primaryAddress?.primaryAddressLine4,
        ORT: communicationMethods?.primaryAddress?.primaryAddressLine5,
        countryOfNationality: String(personalInformation?.identification?.[0]?.countryOfNationality),
        countryOfResidence: String(communicationMethods?.primaryAddressCountry),
        countryOfBirth: personalInformation?.countryOfBirth ? String(personalInformation?.countryOfBirth) : null,
        primaryEmail: communicationMethods?.primaryEmail,
        primaryPhoneNumber: communicationMethods?.primaryPhoneNumber,
        telephoneNumber: communicationMethods?.secondaryPhoneNumber,
        avhNumber: personalInformation?.ahvNo,
        clientId: outputData?.id,
        affiliatedPensionFund,
        occupation: occupationValue(),
        member: personalDetails?.memberBevSwitzerland || personalInformation?.member || false,
        destinedLife: personalDetails?.destinataryVita || personalInformation?.destinedLife || false,
        zurichMA: personalDetails?.zurichEmployee || personalInformation?.zurichMA || false,
        employmentContinuation: personalInformation?.employmentContinuation,
        employmentContinuationDate: personalInformation?.employmentContinuationDate,
        taxDomicile: personalInformation?.taxDomicile ?? switzLandCountryId,
        riskDomicile: personalInformation?.riskDomicile ?? switzLandCountryId,
        mailingAddress: {
            country: communicationMethods?.mailRecipient?.mailRecipientAddressCountry,
            street: communicationMethods?.mailRecipient?.mailRecipientAddressLine3,
            zip: communicationMethods?.mailRecipient?.mailRecipientAddressLine4,
            city: communicationMethods?.mailRecipient?.mailRecipientAddressLine5,
        },
    };
};
