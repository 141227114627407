import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ServiceManager from 'services/ServiceManager';

import { collapseTableAdapter } from './collapseTableAdapter';

export const useGetData = (investmentApplicationId, contactId, applicationStatus, updateData) => {
    const [isLoading, setIsLoading] = useState(true);
    const [tableData, setTableData] = useState([]);
    const [error, setError] = useState(null);

    const { t } = useTranslation();

    const getInvestmentApplications = useCallback(async () => {
        setIsLoading(true);
        try {
            const { data } = await ServiceManager.customDocumentsService(
                'getDocumentsByInvestmentApplicationId',
                [{
                    investmentApplicationId,
                    contactId,
                }],
            );

            const resultTableData = !!data?.length && collapseTableAdapter(data, t, contactId, getInvestmentApplications, applicationStatus, updateData);

            setTableData(resultTableData || []);
        } catch (err) {
            setError(err);
        } finally {
            setIsLoading(false);
        }
    }, [investmentApplicationId, contactId]);

    return {
        isLoading,
        error,
        data: tableData,
        getInvestmentApplications,
    };
};
