import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Title from '../Title';
import Paragraph from '../Paragraph';

const EventDetails = ({
    title, dateString, contact, contactLink, contacts, subject,
}) => (
    <div className="event-details">
        <Title type={4}>{title}</Title>
        <Paragraph type="secondary" dangerouslySetInnerHTML={{ __html: dateString }} />
        <div className="contact-info">
            {contacts.length > 0 ? (contacts.map(({ name, link }, idx) => (
                <>
                    {link ? <Link to={link} className="text-underlined">{name}</Link> : name}
                    {idx !== contacts.length - 1 && ', '}
                </>
            ))) : (
                <>
                    {contactLink ? <Link to={contactLink} className="text-underlined">{contact}</Link> : contact}
                </>
            )}
        </div>
        {subject && <Paragraph>{subject}</Paragraph>}
    </div>
);

EventDetails.propTypes = {
    title: PropTypes.string.isRequired,
    dateString: PropTypes.string.isRequired,
    contacts: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        link: PropTypes.string,
    })),
    contact: PropTypes.string,
    contactLink: PropTypes.string,
    subject: PropTypes.string,
};

EventDetails.defaultProps = {
    contacts: [],
    contact: '',
    contactLink: '',
    subject: '',
};

export default EventDetails;
