export const getBubbleChartOptions = (options: any) => {
    return {
        chart: {
            type: 'bubble',
            plotBorderWidth: 1,
            // @ts-ignore
            zooming: {
                type: 'xy'
            },
            height: options?.height || 300,
        },
    
        legend: {
            enabled: false
        },
    
        title: {
            text: '',
        },
    
        subtitle: {
            text: ''
        },
    
        accessibility: {
            // @ts-ignore
            point: {
                valueDescriptionFormat: '{index}. {point.name}, fat: {point.x}g, ' +
                    'sugar: {point.y}g, obesity: {point.z}%.'
            }
        },
        // @ts-ignore
        xAxis: {
            gridLineWidth: 1,
            title: {
                text: options?.xAxisTitle || 'Risk'
            },
            labels: {
                format: '{value} %'
            },
            plotLines: [{
                color: 'black',
                dashStyle: 'dot',
                width: 2,
                value: 65,
                label: {
                    rotation: 0,
                    y: 15,
                    style: {
                        fontStyle: 'italic'
                    },
                    text: 'Safe fat intake 65g/day'
                },
                zIndex: 3
            }],
            accessibility: {
                rangeDescription: 'Range: 60 to 100 grams.'
            }
        },
        // @ts-ignore
        yAxis: {
            startOnTick: false,
            endOnTick: false,
            title: {
                text: options?.yAxisTitle || 'Expected return'
            },
            labels: {
                format: '{value} %'
            },
            maxPadding: 0.2,
            plotLines: [{
                color: 'black',
                dashStyle: '',
                width: 2,
                value: 50,
                label: {
                    align: 'right',
                    style: {
                        fontStyle: 'italic'
                    },
                    text: 'Safe sugar intake 50g/day',
                    x: -10
                },
                zIndex: 3
            }],
            accessibility: {
                rangeDescription: 'Range: 0 to 160 grams.'
            }
        },
    
        tooltip: {
            enabled: false
        },
    
        plotOptions: {
            series: {
                dataLabels: {
                    enabled: true,
                    format: '{point.name}'
                },
                animation: false,
            }
        },
    
        series: [{
            data: [
                { x: 8, y: 3, z: 2, name: 'BE'},
                { x: 12, y: 3, z: 2, name: 'CE'},
                { x: 15, y: 8, z: 2, name: 'DE'},
                { x: 21, y: 15, z: 2, name: 'XE'},
            ],
        }],
        ...options,
    }
}