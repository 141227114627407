export const avatarTypes = {
    square: 'avatar-square',
    circle: 'avatar-circle',
};

export const avatarSizeTypes = {
    large: { className: 'avatar-lg', px: 40 },
    small: { className: 'avatar-sm', px: 24 },
    default: { className: '', px: 32 },
};
