/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Badge, BubbleChart, Button, Icon, LineChart, Loader, Modal, Paragraph, Title,
} from 'ui-library';
import { ICON_FULLSCREEN_SLIM, ICON_TYPE_CLOSE_SLIM } from 'ui-library/components/Icon';
import { PortfolioVolatilityResponse } from 'core/types/api/Api';
import QuestionnaireQuestion from '../../components/QuestionnaireQuestion';
import useStrategy from '../../datasource/useStrategy';
import ButtonRadioElement from '../../components/AtomicStructure/atoms/ButtonRadioElement';
import './Strategy.css';

const Strategy = ({ datasource }: { datasource: typeof useStrategy }) => {
    const { t } = useTranslation();

    const {
        riskScores,
        riskAnalysisData,
        historicalPerformanceData,
        investmentHorizon,
        selectedHorizon,
        investmentHorizonError,
        validationErrorMessage,
        riskProfile,
        onRiskScoreChange,
        recommendedStrategy,
        selectedRiskProfile,
        chartModal,
        portfolioVolatilities,
        setChartModal,
        handleOpenChartInFullScreen,
        handleInvestmentHorizon,
        riskReturnChartLoading,
        historicalPerformanceChartLoading,
    } = datasource();

    const riskReturnChartOptions = useMemo(() => ({
        series: [{
            data: riskAnalysisData,
            colorByPoint: true,
        }],
    }), [riskAnalysisData]);

    const riskScroreArray = useMemo(() => riskScores?.reduce((acc, risk) => {
        acc.push(t(risk?.label));

        return acc;
    }, []), [riskScores]);

    const getLegendNumberStyle = useCallback((category) => {
        const style:any = {};

        if (category?.riskCategoryId === riskProfile) {
            style.backgroundColor = 'rgba(33, 103, 174, 1)';
            style.color = '#fff';
        }

        if (category?.riskCategoryId === recommendedStrategy?.Id) {
            style.backgroundColor = 'rgba(66, 131, 81, 1)';
            style.color = '#fff';
        }

        return style;
    }, [riskProfile, recommendedStrategy?.Id]);

    const colorMap = useMemo(() => {
        if (riskAnalysisData) {
            return riskAnalysisData.reduce((acc, obj) => {
                acc[obj.name] = obj.color || '';

                return acc;
            }, {});
        }

        return {};
    }, [riskAnalysisData, riskProfile]);

    const getMappedColors = (id:string | number) => {
        const staticColors = {
            6: '#91C8F2',
            7: '#DAD2BD',
        };

        if ([6, 7].includes(id as number)) {
            return staticColors[id];
        }

        return colorMap[id] || '';
    };
    const updatedHistoricalPerformanceData = useMemo(() => {
        if (historicalPerformanceData) {
            return historicalPerformanceData
                .map((item) => ({
                    ...item,
                    color: getMappedColors(item.id),
                })).filter(item => item.id === 6
                    || item.id === 7
                    || item.color === 'recommended'
                    || item.color === 'selected');
        }

        return [];
    }, [historicalPerformanceData, colorMap]);

    const lineChartLegendOptions = {
        useHTML: true,
        symbolHeight: 8,
        symbolWidth: 8,
        symbolRadius: 5,
        layout: 'horizontal',
        verticalAlign: 'top',
        align: 'left',
        y: -18,
    };

    const recommendedStrategyLabel = useMemo(() => riskScores.find(f => f.id === recommendedStrategy?.Id)?.label, [riskScores, recommendedStrategy?.Id, t]);

    return (
        <>
            <section className="strategy">
                <p className="desc">{t('advisoryFlow.Strategy.desc')}</p>
                <QuestionnaireQuestion
                    data-cy="investmentHorizon"
                    data={{
                        Layout: 'ButtonRadio',
                        Text: t('advisoryFlow.Strategy.yourHorizon'),
                        Answers: investmentHorizon,
                        Id: 'test_id_1',
                    }}
                    value={`${selectedHorizon}`}
                    error={investmentHorizonError ? validationErrorMessage : null}
                    onChange={handleInvestmentHorizon}
                />
                {selectedHorizon !== null && (
                    <>
                        <ButtonRadioElement
                            label={t('advisoryFlow.Strategy.riskProfile')}
                            items={riskScores?.filter(score => (score.id < 6))}
                            value={riskProfile}
                            passData={onRiskScoreChange}
                            recommendedId={recommendedStrategy?.Id}
                            showUndertext
                            undertext={t('advisoryFlow.Strategy.recommended')}
                        />

                        <Title type={2}>{t('advisoryFlow.Strategy.analysis')}</Title>
                        <div className="analysis">
                            <div>
                                <p>{t('advisoryFlow.Strategy.recommendedRiskProfile')}</p>
                                <Badge title={recommendedStrategyLabel} color="recommended"/>
                            </div>
                            {selectedRiskProfile?.id === recommendedStrategy?.Id ? null : (
                                <div>
                                    <p>{t('advisoryFlow.Strategy.selectedRiskProfile')}</p>
                                    <Badge title={selectedRiskProfile?.label} color="selected"/>
                                </div>
                            )}
                        </div>
                        <div className="chart-container">
                            <p className="title flex justify-content-between align-center">
                                {t('advisoryFlow.Strategy.riskReturn')}
                                <span className="chart-open-icon cursor-pointer" role="button" onClick={handleOpenChartInFullScreen('risk-return')}>
                                    {/* @ts-ignore */}
                                    <Icon type={ICON_FULLSCREEN_SLIM} size={18} />
                                </span>
                            </p>
                            {riskReturnChartLoading ? <Loader /> : (
                                <div className="risk-return-chart-wrapper">
                                    <BubbleChart options={{
                                        ...riskReturnChartOptions,
                                        height: 300,
                                        xAxisTitle: t('advisoryFlow.Strategy.riskReturnChart.XAxisLabel'),
                                        yAxisTitle: t('advisoryFlow.Strategy.riskReturnChart.YAxisLabel'),
                                    }}
                                    />
                                    <ul className="risk-return-chart-legends">
                                        {portfolioVolatilities?.map((category:PortfolioVolatilityResponse) => (
                                            <li key={category.riskCategoryId}>
                                                <span style={getLegendNumberStyle(category)}>{category.riskCategoryId}</span>
                                                <div>
                                                    <strong>{riskScroreArray[category.riskCategoryId as number - 1]}</strong>
                                                    {t('advisoryFlow.Strategy.riskReturnChartLegends.Volatility')}
                                                    :
                                                    {' '}
                                                    {(category?.minRiskValue as number * 100)?.toFixed(0)}
                                                    {'% '}
                                                    -
                                                    {' '}
                                                    {(category?.maxRiskValue as number * 100)?.toFixed(0)}
                                                    %
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                            <Paragraph type="secondary" className="mt-30">
                                {t('advisoryFlow.Strategy.riskReturn.description')}
                            </Paragraph>
                        </div>
                        <div className="chart-container">
                            <p className="title flex justify-content-between .align-center">
                                {t('advisoryFlow.Strategy.historicalPerformance')}
                                <span role="button" className="chart-open-icon cursor-pointer" onClick={handleOpenChartInFullScreen('historical')}>
                                    {/* @ts-ignore */}
                                    <Icon type={ICON_FULLSCREEN_SLIM} size={18} />
                                </span>
                            </p>
                            {historicalPerformanceChartLoading ? <Loader /> : (
                                <LineChart
                                    data={updatedHistoricalPerformanceData}
                                    valueFormat={{
                                        symbol: '',
                                        precision: 0,
                                        thousand: ',',
                                        format: '%v%s',
                                    }}
                                    isCustomClassRequired={false}
                                    // goal={[1672531200000, 40]}
                                    projection
                                    xTitle={t('advisoryFlow.Strategy.PerformanceChart.xAxisLabel')}
                                    yTitle={t('advisoryFlow.Strategy.PerformanceChart.yAxisLabel')}
                                    lineChartLegendOptions={lineChartLegendOptions}
                                    lineChartTooltipOptions={{
                                        enabled: false,
                                    }}
                                    legendEnabled
                                />
                            )}
                            <Paragraph type="secondary" className="mt-30 historical-performance-chart-info text-center">
                                {t('advisoryFlow.Strategy.riskReturn.info')}
                            </Paragraph>
                            <Paragraph type="secondary" className="mt-30">
                                {t('advisoryFlow.Strategy.performanceHistory.description')}
                            </Paragraph>
                        </div>
                        <p className="next-step-desc">{t('advisoryFlow.Strategy.nextStepDesc')}</p>
                    </>
                )}
            </section>
            <Modal
                title={chartModal === 'risk-return' ? t('advisoryFlow.Strategy.riskReturn') : t('advisoryFlow.Strategy.historicalPerformance')}
                visible={!!chartModal}
                width="90%"
                borderRadius={24}
                onCancel={() => setChartModal(null)}
                closable
                closeIcon={(
                    <Icon
                        type={ICON_TYPE_CLOSE_SLIM}
                        size={20}
                        color={undefined}
                        ariaLabel={undefined}
                        component={undefined}
                        className={undefined}
                    />
                )}
                className="strategy"
                footer={(
                    <div className="text-center">
                        <Button className="strategy-chart-modal-footer-btn" type="secondary" onClick={() => setChartModal(null)}>
                            { t('advisoryFlow.Strategy.modal.footerButton.close') }
                        </Button>
                    </div>
                )}
            >

                {chartModal === 'risk-return' ? (
                    <div className="risk-return-chart-wrapper">
                        <BubbleChart options={{
                            ...riskReturnChartOptions,
                            height: 300,
                            xAxisTitle: t('advisoryFlow.Strategy.riskReturnChart.XAxisLabel'),
                            yAxisTitle: t('advisoryFlow.Strategy.riskReturnChart.YAxisLabel'),
                        }}
                        />
                        <ul className="risk-return-chart-legends">
                            {portfolioVolatilities?.map((category:PortfolioVolatilityResponse) => (
                                <li key={category.riskCategoryId}>
                                    <span style={getLegendNumberStyle(category)}>{category.riskCategoryId}</span>
                                    <div>
                                        <strong>{riskScroreArray[category.riskCategoryId as number - 1]}</strong>
                                        {t('advisoryFlow.Strategy.riskReturnChartLegends.Volatility')}
                                        :
                                        {' '}
                                        {(category?.minRiskValue as number * 100)?.toFixed(0)}
                                        {'% '}
                                        -
                                        {' '}
                                        {(category?.maxRiskValue as number * 100)?.toFixed(0)}
                                        %
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                ) : (
                    <>
                        <LineChart
                            data={updatedHistoricalPerformanceData}
                            valueFormat={{
                                symbol: '',
                                precision: 0,
                                thousand: ',',
                                format: '%v%s',
                            }}
                            isCustomClassRequired={false}
                            // goal={[1672531200000, 40]}
                            projection
                            xTitle={t('advisoryFlow.Strategy.PerformanceChart.xAxisLabel')}
                            yTitle={t('advisoryFlow.Strategy.PerformanceChart.yAxisLabel')}
                            lineChartLegendOptions={lineChartLegendOptions}
                            legendEnabled
                        />
                        <Paragraph type="secondary" className="mt-30 historical-performance-chart-info">
                            {t('advisoryFlow.Strategy.riskReturn.info')}
                        </Paragraph>
                    </>
                )}
            </Modal>
        </>
    );
};

export default Strategy;
