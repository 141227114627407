import { useContext } from 'react';
import { InvestorProfileContext } from './context';

export const useSelector = (callback) => {
    const { getValue } = useContext(InvestorProfileContext);

    if (getValue === undefined) {
        throw new Error('useSelector can only be used inside InvestorProfileProvider');
    }

    return getValue(callback);
};
