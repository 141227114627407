import React, {
    useCallback, useEffect, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import { flatMap } from 'lodash/fp';
import isEqual from 'lodash/isEqual';
import FormGroup from '../../FormGroup';
import { CheckboxGroup } from '../../Checkbox';
import SelectControls from './SelectControls';
import { optionsList } from '../../../types';
import { filterOptions } from '../utils';
import withTheme from '../../../hocs/withTheme';

/** DO If used in a table header for filtering, idle will show on table header column hover.
 *
 *
 * DONT Idle boxed is only used rarely and is not used in table headers for filtering. */

const MultiSelectMiniForm = (props) => {
    const {
        value,
        opened,
        options,
        searchHidden,
        headerControls,
        withSelectTool,
        children,
        onReset,
        onSelect,
        onSearch,
    } = props;
    const inputRef = useRef();
    const [filterInput, setFilterInput] = useState('');
    const [selectedOptions, setSelectedOptions] = useState(value);
    const [filteredOptions, setFilteredOptions] = useState(options);
    const onFilterOptions = useCallback(({ target: { value: v } }) => {
        setFilterInput(v);
        setFilteredOptions(filterOptions(options, v));
        if (typeof onSearch === 'function') onSearch(v);
    }, [options, onSearch]);
    const toggleItem = useCallback((item) => {
        const filteredOptionsFlat = filteredOptions.map((item) => item?.value || item);
        const valuesWithoutCurrent = selectedOptions
            .filter((item) => !filteredOptionsFlat.includes(item));
        const newVal = [...new Set([...item, ...valuesWithoutCurrent])];

        setSelectedOptions(newVal);
        onSelect(newVal);
    }, [filteredOptions, selectedOptions, onSelect]);
    const handleCheckAllChange = useCallback(() => {
        const newOptions = flatMap(
            (item) => ([undefined, null].includes(item?.value) ? item : item?.value),
            options,
        );

        setSelectedOptions(newOptions);
        onSelect(newOptions);
    }, [options, onSelect]);
    const handleUnCheckAllChange = useCallback(() => {
        if (typeof onReset === 'function') {
            const values = onReset() || [];

            setSelectedOptions(values);
            onSelect(values);
        } else {
            setSelectedOptions([]);
            onSelect([]);
        }
    }, [onReset, onSelect]);

    useEffect(() => {
        if (opened && inputRef.current) {
            setTimeout(() => {
                inputRef.current.focus();
            }, 100);
        }
    }, [opened]);
    useEffect(() => {
        if (!isEqual(value, selectedOptions)) setSelectedOptions(value);
    }, [value]);
    useEffect(() => {
        setFilteredOptions(filterOptions(options, filterInput));
    }, [options]);

    return (
        <FormGroup opened={opened}>
            <div className="select__dropdown" id="multiselectFilter-dropdown">
                <input
                    className="select__search"
                    type="search"
                    aria-label="Type to filter available options"
                    value={filterInput}
                    onChange={onFilterOptions}
                    hidden={searchHidden}
                    ref={inputRef}
                />
                <fieldset className="multiselectFilter-dropdown-options">
                    <legend hidden>Options</legend>
                    <CheckboxGroup
                        className="checkbox-group_select"
                        value={selectedOptions}
                        options={filteredOptions}
                        onChange={toggleItem}
                    />
                    {children}
                </fieldset>
                {withSelectTool && (
                    <SelectControls
                        check={handleCheckAllChange}
                        uncheck={handleUnCheckAllChange}
                        selectControlsLabel={headerControls.selectControlsLabel}
                    />
                )}
            </div>
        </FormGroup>
    );
};

MultiSelectMiniForm.propTypes = {
    opened: PropTypes.bool,
    /** Option list  */
    options: optionsList,
    /** Initial option for select */
    value: PropTypes.arrayOf(PropTypes.string),
    /** Wether the select button has border around */
    onSelect: PropTypes.func,
    /** Wether the search input is hidden */
    searchHidden: PropTypes.bool,
    /** If 'Select all' and 'Reset' are visible */
    withSelectTool: PropTypes.bool,
    headerControls: PropTypes.shape({
        selectControlsLabel: PropTypes.shape({
            selectAll: PropTypes.string,
            reset: PropTypes.string,
        }),
    }),
    /** Callback for search options */
    onSearch: PropTypes.func,
    /** Callback for reset options */
    onReset: PropTypes.func,
    children: PropTypes.node,
};

MultiSelectMiniForm.defaultProps = {
    opened: true,
    options: null,
    value: [],
    withSelectTool: true,
    onSelect: () => {},
    searchHidden: false,
    headerControls: {},
    onReset: null,
    onSearch: null,
    children: null,
};

export default withTheme(MultiSelectMiniForm);
