import React from 'react';
import PropTypes from 'prop-types';
import { getClassNames } from '../../utils';
import withTheme from '../../hocs/withTheme';
import './Grid.css';

/** Row can be a part of grid if it is wrapped into container. */

function Row({ children, className, ...props }) {
    return (
        <div {...props} className={getClassNames(className, 'row')}>
            {children}
        </div>
    );
}

Row.propTypes = {
    /** @ignore */
    children: PropTypes.node,
    /** @ignore */
    className: PropTypes.string,
};

Row.defaultProps = {
    children: null,
    className: null,
};

export default withTheme(Row);
