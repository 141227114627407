import React, {
    useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {getParamsFromUrl} from 'utils';
import Preloader from 'components/Preloader';
import {useCancelAllRequests} from 'hooks/useCancelAllRequests';
import {newPersonalInformationOnboardingLink} from 'pages/ClientOnboardingFlow/helpers/links';
import {useNavigate} from 'react-router-dom';
import {ClientSearchBar} from './components/ClientSearchBar';
import {connectClientsSchema} from './provider/ClientsSchemaProvider';
import {ClientList} from './containers/TablesContainer';
import './Clients.css';
import Card from '../../components/Card/Card';
import SearchBar from '../../components/SearchBar/SearchBar';

function Clients(props) {
    useCancelAllRequests();
    const {t} = useTranslation();
    const status = getParamsFromUrl('status');
    const {location} = props;
    const [tab, setTab] = useState(location?.state?.fromTab || null);
    const [search, setSearch] = useState('');
    const navigate = useNavigate();

    const onNewProspect = () => {
        navigate(newPersonalInformationOnboardingLink);
    };

    const clientTitle = useMemo(() => {
        if (status) {
            if (tab === 'clients') {
                return t('advisoryDashboard.overview.clients');
            }
            if (status === t('advisoryDashboard.overview.clients')) {
                setTab('clients');

                return t('advisoryDashboard.overview.clients');
            }
        }

        return t('clients.title');
    }, [t, status, tab]);

    const onSearch = (value) => {
        setSearch(value);
    };

    return (
        <div className="container">
            <div className="fi-verticals">
                <SearchBar>
                    <ClientSearchBar
                        onSearch={onSearch}
                    />
                </SearchBar>
                <Card
                    title={clientTitle}
                    headerActions={(
                        <button
                            data-cy="newCustomer"
                            type="button"
                            className="button-link"
                            onClick={onNewProspect}
                        >
                            {t('clients.newClient')}
                        </button>
                    )}
                >
                    <Preloader isLoading={false} error={null}>
                        <ClientList search={search} tab={tab}/>
                    </Preloader>
                </Card>
            </div>
        </div>
    );
}

Clients.propTypes = {
    location: PropTypes.shape({
        state: PropTypes.shape({
            fromTab: PropTypes.string,
        }),
    }),
};

Clients.defaultProps = {
    location: {
        state: {},
    },
};
export default connectClientsSchema(Clients);
