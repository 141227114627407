import React, {forwardRef} from 'react';
import PropTypes from 'prop-types';
import {getClassNames} from '../../utils';

const CoreInput = forwardRef((props, ref) => {
    const {
        suffix, addonAfter, search, className, prefix,
        addonAfterBorder, ...restProps
    } = props;

    const classNameAddonAfter = getClassNames(
        'TextInput-group-addon',
        {'addon-after-border': addonAfterBorder},
    );

    return (
        <span className={`${className} TextInput-group-wrapper`}>
            <span className="TextInput-wrapper TextInput-group">
                <span className="TextInput-affix-wrapper">
                    {prefix && (<span className="TextInput-prefix">{prefix}</span>)}
                    <input {...restProps}
                        className={getClassNames(
                            'TextInput',
                            prefix ? 'TextInput--prefix' : '',
                            suffix ? 'TextInput--suffix' : '',
                        )}
                        ref={ref}
                    />
                    {suffix && (<span className="TextInput-suffix">{suffix}</span>)}
                </span>
                {addonAfter && (
                    <span className={classNameAddonAfter}>
                        {addonAfter}
                    </span>
                )}
            </span>
        </span>
    );
});

CoreInput.propTypes = {
    /** @ignore */
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    /** @ignore */
    defaultValue: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    /** The prefix icon for the input. */
    prefix: PropTypes.node,
    /** The suffix icon for the input. */
    suffix: PropTypes.node,
    /** @ignore */
    addonAfter: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
    ]),
    /** Whether the input is search field */
    search: PropTypes.bool,
    /** @ignore */
    className: PropTypes.string,
    // ** Show suffix border */
    addonAfterBorder: PropTypes.bool,

};
CoreInput.defaultProps = {
    value: undefined,
    defaultValue: undefined,
    prefix: null,
    suffix: null,
    addonAfter: null,
    search: false,
    className: false,
    addonAfterBorder: true,

};
export default CoreInput;
