import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import CellWithIcon from './CellWithIcon';

function CellWithLinkIcon({
    type, href, size, className,
}) {
    const cellWithIcon = CellWithIcon(type, size);

    if (href !== undefined) {
        return (
            <Link to={href} className={className}>
                {cellWithIcon}
            </Link>
        );
    }

    return cellWithIcon;
}

CellWithLinkIcon.propTypes = {
    type: PropTypes.string.isRequired,
    size: PropTypes.number,
    href: PropTypes.string,
    className: PropTypes.string,
};

CellWithLinkIcon.defaultProps = {
    href: undefined,
    size: 20,
    className: undefined,
};

export default CellWithLinkIcon;
