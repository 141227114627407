import React, { useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import TabHeader from './TabHeader';
import Icon, { ICON_TYPE_ARROW_LEFT, ICON_TYPE_ARROW_RIGHT } from '../../Icon';
import { useDetectFlexWrap } from '../hooks/useDetectFlexWrap';
import { useUnderlineSlider } from '../hooks/useUnderlineSlider';
import { useHorizontalScroll } from '../hooks/useHorizontalScroll';
import { getClassNames } from '../../../utils';

import './TabBar.css';

const TabBar = (props) => {
    const { tabs, activeKey, tabBarExtraContent } = props;
    const tabActiveIdx = useMemo(() => tabs.findIndex((tab) => tab.tabKey === activeKey),
        [tabs, activeKey]);
    const tabRef = useRef(null);
    const navRef = useRef(null);
    const tabRefs = tabs.map(() => useRef(null));
    const { styles, changeSizes } = useUnderlineSlider(tabRefs, tabActiveIdx);
    const { wrapped } = useDetectFlexWrap(tabRef);
    const {
        scrollable, scrollOffset, onLeftIconClick, onRightIconClick,
    } = useHorizontalScroll(navRef, tabRefs, changeSizes);
    const tabBarExtra = useMemo(() => (tabBarExtraContent
        ? <div className="Tabs-bar__extra">{tabBarExtraContent}</div> : null), [tabBarExtraContent]);
    const classNameBar = getClassNames('Tabs-bar', { 'Tabs-bar__wrapped': wrapped });
    const classNameNav = getClassNames(
        'Tabs-nav',
        { 'Tabs-nav__scrollable': scrollable },
        { 'Tabs-nav__icon-left-hidden': scrollable && scrollOffset.left < 20 },
        { 'Tabs-nav__icon-right-hidden': scrollable && scrollOffset.right < 20 },
    );

    return (
        <div className={classNameBar} ref={tabRef} tabIndex={0} role="tablist">
            <div className={classNameNav}>
                <Icon type={ICON_TYPE_ARROW_LEFT} className="Tabs-nav-icon__left" onClick={onLeftIconClick} />
                <div className="Tabs-nav_inner" ref={navRef}>
                    {tabs.map((tab, i) => <TabHeader {...tab} key={tab.tabKey} ref={tabRefs[i]} />)}
                    <div className="Tab-slider" style={styles} />
                </div>
                <Icon type={ICON_TYPE_ARROW_RIGHT} className="Tabs-nav-icon__right" onClick={onRightIconClick} />
            </div>
            {tabBarExtra}
        </div>
    );
};

TabBar.propTypes = {
    activeKey: PropTypes.string.isRequired,
    tabs: PropTypes.arrayOf(PropTypes.shape({
        tab: PropTypes.string,
        tabKey: PropTypes.string,
        disabled: PropTypes.bool,
        isActive: PropTypes.bool,
        onClick: PropTypes.func,
    })),
    tabBarExtraContent: PropTypes.node,
};

TabBar.defaultProps = {
    tabs: [],
    tabBarExtraContent: null,
};

export default TabBar;
