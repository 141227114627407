import React, { useMemo } from 'react';
import {
    getPortfolioStatus,
    IN_EXECUTION, NOT_IMPLEMENTED,
} from 'constants/portfolioStatuses';
import { usePortfolio } from 'domain/Portfolio';
import Preloader from 'components/Preloader';
import PropTypes from 'prop-types';
import PageNotFoundContent from 'components/PageNotFound/PageNotFoundContent';
import {useLocation, useNavigate, useParams} from 'react-router-dom';

const withPortfolioAccess = (Component) => {
    const Portfolio = (props) => {
        const navigate = useNavigate();
        const { state } = useLocation();
        const { dfsClientId } = useParams();
        const { portfolio: { data, isLoading, error } } = usePortfolio();

        const status = getPortfolioStatus({
            portfolioStatusId: data?.portfolioStatusId,
            isBreached: data?.isBreached,
            isOrderBookBlocked: data?.hasPendingOrders,
        });

        const pageNotFound = useMemo(() => [].includes(status), [status]);

        if (status === NOT_IMPLEMENTED) {
            navigate(`/client/${dfsClientId}/portfolios/${data.id}/funding-information`, state);
        }

        if (status === IN_EXECUTION) {
            navigate(`/client/${dfsClientId}/portfolios/${data.id}/pending-orders`, state);
        }

        if (data?.portfolioStatusId && pageNotFound) {
            return (
                <Preloader isLoading={isLoading} error={error}>
                    <PageNotFoundContent />
                </Preloader>
            );
        }

        Portfolio.propTypes = {
            match: PropTypes.shape({
                params: PropTypes.shape({
                    dfsClientId: PropTypes.string,
                }),
            }).isRequired,
            location: PropTypes.shape({
                state: {
                    fromReview: PropTypes.bool,
                },
            }).isRequired,
        };

        return <Component status={status} {...props} />;
    };

    return Portfolio;
};

export default withPortfolioAccess;
