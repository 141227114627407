import { defaultColumnChartOptions } from './constants';

export const getColumnChartOptions = (
    data, labels, isSelected, labelFormat, min, max, showPercentage,
) => {
    const allData = data.map((item) => [...item.data]).flat();

    return {
        ...defaultColumnChartOptions,
        chart: {
            ...defaultColumnChartOptions.chart,
            marginTop: showPercentage || isSelected ? 40 : 0,
        },
        xAxis: {
            ...defaultColumnChartOptions.xAxis,
            categories: labels,
        },
        yAxis: {
            ...defaultColumnChartOptions.yAxis,
            max: max || Math.max(...allData),
            min: min || Math.min(...allData),
            stackLabels: {
                ...defaultColumnChartOptions.yAxis.stackLabels,
                useHTML: true,
                formatter() {
                    const value = this.x === isSelected || showPercentage
                        ? labelFormat(this.total)
                        : `<span class="${this.alignOptions.verticalAlign}"></span>`;

                    return `<span class="${this.alignOptions.verticalAlign}">${value}</span>`;
                },
            },
        },
        series: [...data],
    };
};
