import {useCallback, useEffect, useState} from 'react';
import {getPaymentPeriodValue} from '../../../utils';
import {isAnyOfThreeBProductTypes} from '../../../../../../../../../hooks/isThreeBProduct';

type InvestmentContributionsData = {
    paymentPeriod: string,
    oneTimeInvestment: number | string,
    periodicPayment: number | string
}

export const useInvestmentContributions = (productType: string, defaultGoalSettings: any, data?: InvestmentContributionsData) => {
    const [periodicPayment, setPeriodicPayment] = useState<any>(0);
    const [oneTimeInvestment, setOneTimeInvestment] = useState<any>(0);
    const [paymentPeriod, setPaymentPeriod] = useState<any>(0);

    const getYearlyContribution = useCallback(() => periodicPayment * getPaymentPeriodValue(paymentPeriod), [getPaymentPeriodValue, paymentPeriod, periodicPayment]);
    const
        getInvestmentForNextSixMonths = useCallback(() => (getYearlyContribution() / 2) + oneTimeInvestment, [getYearlyContribution, oneTimeInvestment]);

    useEffect(() => {
        if (isAnyOfThreeBProductTypes(productType)) {
            setOneTimeInvestment(data?.oneTimeInvestment ?? defaultGoalSettings?.defaultOneTimeInvestment);
            setPaymentPeriod(data?.paymentPeriod ?? defaultGoalSettings?.defaultPaymentFrequency);
            setPeriodicPayment(data?.periodicPayment ?? defaultGoalSettings?.defaultPeriodicPayment);
        }
    }, [data, productType, defaultGoalSettings]);

    return {
        getYearlyContribution,
        getInvestmentForNextSixMonths,
    };
};
