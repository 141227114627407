import { CellAction } from '../CellAction';

export const columns = (t) => [
    {
        key: 'presentation',
    },
    {
        key: 'name',
        title: t('allocationColumns.name'),
    },
    {
        key: 'status',
        title: t('dashboard.agentCockpit.status'),
    },
    {
        key: 'action',
        title: '',
        render: CellAction,
    },
];
