import React from 'react';

import InvestmentApplicationNavigationButton from 'components/InvestmentApplicationsActionButton/InvestmentApplicationNavigationButton';
import { APPLICATION_STATUS} from '../../../../components/InvestmentApplicationsActionButton/InvestmentApplicationsActionButton';
import {dateTimeFormats} from '../../../../locale';

export const investmentApplicationsAdapter = ({
    data, t, getFormattedDate,
}) => {
    const tableData = data.map(
        ({
            investmentApplicationId,
            contactName,
            productName,
            strategy,
            agent,
            submitedDate,
            owners,
            forceCollapse,
            status: contractStatus,
        }) => {
            const clientId = Math.min(...Object.keys(owners).map(key => Number(key)));

            return {
                applicationId: investmentApplicationId,
                zipId: clientId,
                name: Object.values(owners).join(', '),
                product: productName,
                agent,
                strategy,
                forceCollapse,
                submitted: getFormattedDate(submitedDate, {
                    hour: dateTimeFormats.TWO_DIGIT,
                    minute: dateTimeFormats.TWO_DIGIT,
                }),
                status: APPLICATION_STATUS(t)?.[contractStatus],
                createNewPlan: () => InvestmentApplicationNavigationButton({destination: 'application', destinationId: investmentApplicationId, clientId}),

            };
        },
    );

    return tableData;
};
