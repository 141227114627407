import React from 'react';
import {
    Route, Routes, useParams, Navigate,
} from 'react-router-dom';
import AdvisoryDashboard from './AdvisoryDashboard';

const Router = () => (
    <Routes>
        <Route path="" element={<AdvisoryDashboard/>} />
        <Route
            path="*"
            element={<Navigate to="/404" replace />}
        />
    </Routes>
);

Router.displayName = 'Router';

export default Router;
