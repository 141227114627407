import {useCallback, useState} from 'react';
import ServiceManager from '../services/ServiceManager';

export const useShadowAccounts = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [saldo, setSaldo] = useState(null);

    const getSaldo = useCallback(async (portfolioId) => {
        try {
            setIsLoading(true);
            const {data} = await ServiceManager.customInvestmentService('getKudiSaldo', [{portfolioId}]);

            setSaldo(data);
        } catch (err) {
            setError(err);
        } finally {
            setIsLoading(false);
        }
    }, []);

    return {
        isLoading, error, getSaldo, saldo,
    };
};
