import {
    getfromDataObject,
    validateStringData,
} from 'utils/formatting';
import { portfolioTypeIds } from 'constants/portfolioTypeIds';
import { uniq } from 'lodash';
import { adaptProposalsByPortfolioId } from 'adaptors/adaptProposals';
import {
    PENDING_ORDERS,
    portfolioStatuses,
    portfolioStatusesColors,
    sortStatuses,
    IN_EXECUTION, NOT_IMPLEMENTED,
    BLOCKED, CLOSED, getPortfolioStatus,
} from 'constants/portfolioStatuses';

const filterPortfolios = (portfolios) => portfolios.filter((item) => (
    item.PortfolioTypeId === portfolioTypeIds.MAIN
));

export const adaptPortfolios = ({
    data, dfsId, portfolioActions, t, getFormattedCurrency, portfoliosOverview,
}) => {
    if (!data.Portfolios) return [];

    const pendingOrders = portfoliosOverview?.reduce((acc, item) => ({
        ...acc,
        [item.Id]: item?.HasPendingOrders,
    }), {}) || {};

    const portfolios = filterPortfolios(data.Portfolios);

    const sortedPortfolios = [...portfolios].sort((a, b) => new Date(b.CreateDate) - new Date(a.CreateDate));

    return sortedPortfolios.map((item) => {
        const portfolioStatus = getPortfolioStatus({
            portfolioStatusId: item.PortfolioStatusId,
            isBreached: item?.IsBreached,
            isOrderBookBlocked: pendingOrders?.[item.Id],
        });

        const link = () => {
            if (portfolioStatus === NOT_IMPLEMENTED) {
                return `/client/${dfsId}/portfolios/${item.Id}/funding-information`;
            }

            return `/client/${dfsId}/portfolios/${item.Id}/tab/overview`;
        };

        return ({
            UId: item.UId,
            Name: {
                label: item.Name || `${t('clientDashboard.portfolios.plan')} ${item.Id}`,
                link: link(),
                disabled: portfolioStatus === NOT_IMPLEMENTED,
            },
            Product: getfromDataObject(item, 'Product.Name'),
            Profile: validateStringData(item.RiskCategory.Name),
            Status: {
                text: portfolioStatuses(t)[portfolioStatus] || '-',
                value: portfolioStatus,
                color: portfolioStatusesColors[portfolioStatus] || '',
            },
            ExternalId: item.ExternalId,
            Value:
                getFormattedCurrency(item.CurrentValue, { currency: item.Currency.CurrencyCode }),
            Performance: item.CurrentPerformance ? item.CurrentPerformance * 100 : undefined,
            Actions: {
                value: 'action',
                actions: portfolioActions({
                    dfsId,
                    id: item.Id,
                    productName: getfromDataObject(item, 'Product.Name'),
                    t,
                    showEditButton: portfolioStatus !== NOT_IMPLEMENTED,
                    withEditActions:
                        portfolioStatus !== PENDING_ORDERS && portfolioStatus !== BLOCKED,
                    withPendingOrders: portfolioStatus === IN_EXECUTION,
                    withFundingInformation: portfolioStatus === NOT_IMPLEMENTED,
                }),
                disabled: portfolioStatus === CLOSED,
            },
        });
    });
};
