import React, {createContext, useCallback, useContext, useEffect, useMemo, useState,} from 'react';

import {AxiosResponse} from 'axios';
import {useTranslation} from 'react-i18next';
import {
    NewConnectionsSessionManagerAPI
} from 'pages/ClientOverview/pages/ClientConnections/components/NewConnections/constants';
import SM from 'services/ServiceManager';
import handlerRequestCanceling from 'utils/handlerRequestCanceling';
import HandlerError from 'errors/HandlerError';
import {
    AccessPolicy,
    Address,
    ConnectionsAPI,
    ContactGroup,
    ContactGroupDetails,
    ContactGroupDocuments,
    ContactGroupEnum,
    ContactGroupHistory,
    NewJAConnectionPayload,
    NewProxyConnectionPayload,
    ProxyDetails,
} from '../types';
import {notification} from '../../../ui-library';
import {downloadDmsDocument} from '../../../utils/downloadDmsDocument';
import {useUpdateConnectionMailingAddress} from '../hooks/useUpdateConnectionMailingAddress';

const ConnectionsContext = createContext<ConnectionsAPI>(null as any);

type ConnectionsManagerProps = {
    // eslint-disable-next-line react/require-default-props
    clientId?: number;
    children: React.ReactNode;
}

export const ConnectionsManager = ({
    clientId,
    children
}: ConnectionsManagerProps) => {
    const {t} = useTranslation();
    const [error, setError] = useState<string | null>(null);
    const [isLoading, setLoading] = useState<boolean>(true);
    const [isAddressesLoading, setAddressLoading] = useState<boolean>(true);
    const [addressError, setAddressError] = useState<string | null>(null);
    const [data, setData] = useState<ContactGroup[]>([]);
    const [rawData, setRawData] = useState<ContactGroup[]>([]);
    const [contactGroupDocumentsInProgress, setContactGroupDocumentsInProgress] = useState<boolean>(false);
    const [contactGroupDocumentsError, setContactGroupDocumentsError] = useState<string | null>(null);
    const [selectedProxyConnection, setSelectedProxyConnection] = useState<ProxyDetails | null>(null);
    const [isAddConnectionModalOpen, setIsAddConnectionModalOpen] = useState<boolean>(false);
    const [addresses, setAddress] = useState<Address[]>([]);
    const [contactGroupDetails, setContactGroupDetails] = useState<ContactGroupDetails | null>(null);
    const [contactGroupDocuments, setContactGroupDocuments] = useState<Array<ContactGroupDocuments> | null>(null);
    const [contactGroupHistoryLoading, setContactGroupHistoryLoading] = useState(false);
    const [contactGroupHistoryError, setContactGroupHistoryError] = useState<string | null>(null);
    const [contactGroupHistory, setContactGroupHistory] = useState<ContactGroupHistory | null>(null);
    const [downloadDocumentInProgress, setDownloadDocumentInProgress] = useState<{
        id: number,
        inProgress: boolean
    } | null>(null);
    const [allowProxyAddition, setAllowProxyAddition] = useState<boolean>(false);

    const onAddConnection = (): void => {
        setIsAddConnectionModalOpen(true);
    };

    const onGeneratePOAform = (): void => {
        // TODO: missing implementation
    };

    const {
        inProgress,
        updateContactGroupAddress
    } = useUpdateConnectionMailingAddress();

    useEffect(() => {
        setContactGroupDocuments(prev => prev?.map(item => ({
            ...item,
            nameCol: {
                ...item.nameCol,
                inProgress: item.id === downloadDocumentInProgress?.id ? downloadDocumentInProgress.inProgress : false
            }
        })) || null);
    }, [downloadDocumentInProgress]);

    const getContactGroupHistory = useCallback(async (groupId?: number) => {
        try {
            if (!groupId) {
                return;
            }
            setContactGroupHistoryLoading(true);

            const response: AxiosResponse<ContactGroupHistory> = await SM.customClientService('getContactGroupHistory', [groupId]);

            setContactGroupHistory(response.data);
        } catch (err) {
            handlerRequestCanceling(HandlerError({
                setError: setContactGroupHistoryError,
                setLoading: setContactGroupHistoryLoading
            }))(err);
        } finally {
            setContactGroupHistoryLoading(false);
        }
    }, []);

    const onDeleteProxyConnection = (): void => {
        // done for caching purposes, not to re-fetch connections again
        setContactGroupDetails(prev => (prev ? ({
            ...prev,
            proxies: prev.proxies.filter((proxy: ProxyDetails) => proxy.proxyId !== selectedProxyConnection?.proxyId),
        }) : null));
        setSelectedProxyConnection(null);
    };

    const onProxyConnectionDelete = useCallback(async (): Promise<void> => {
        if (!selectedProxyConnection || ![AccessPolicy.Representative, AccessPolicy.PowerOfAttorney].includes(selectedProxyConnection.accessPolicy)) {
            return;
        }

        try {
            setLoading(true);
            setError(null);
            await SM.customClientService('deleteProxyConnection', [{
                contactGroupId: contactGroupDetails?.groupId,
                proxyId: selectedProxyConnection.proxyId,
                accessPolicy: selectedProxyConnection.accessPolicy,
            }]);
            onDeleteProxyConnection();
            getContactGroupHistory(contactGroupDetails?.groupId);
            notification.open({
                content: t('contactGroups.proxyDeletedSuccessfully'),
                type: 'success'
            });
        } catch (err: any) {
            notification.open({
                content: `${t('contactGroups.somethingWentWrong')} ${err.message}`,
                type: 'error'
            });
        } finally {
            setLoading(false);
        }
    }, [selectedProxyConnection, contactGroupDetails, getContactGroupHistory]);

    const deleteContactGroup = useCallback(async (contactGroupId: number) => {
        try {
            await SM.customClientService('deleteContactGroup', [{contactGroupId}]);
            setData(prev => prev.filter(f => f.groupId !== contactGroupId));
            setRawData(prev => prev.filter(f => f.groupId !== contactGroupId));

            notification.open({
                content: t('contactGroups.contactGroupDeletedSuccessfully'),
                type: 'success'
            });
        } catch (err: any) {
            notification.open({
                content: err?.response?.status === 409
                    ? t('contactGroups.deleteError.threeBExists')
                    : `${t('contactGroups.somethingWentWrong')} ${err?.response?.message}`,
                type: 'error',
            });
        }
    }, [t]);

    const downloadDocument = useCallback(async (documentId: number) => {
        try {
            setDownloadDocumentInProgress({
                id: documentId,
                inProgress: true
            });
            const {data: documentData} = await SM.documents('getDocumentById', [documentId]);

            const {newBlob} = downloadDmsDocument(documentData, {
                saveFile: true,
                name: null
            });

            const url = URL.createObjectURL(newBlob);

            global.open(url, '_blank');
        } catch (err: any) {
            notification.open({
                content: `${t('contactGroups.somethingWentWrong')} ${err.message}`,
                type: 'error'
            });
        } finally {
            setDownloadDocumentInProgress({
                id: documentId,
                inProgress: false
            });
        }
    }, []);

    const getContactGroupDocuments = useCallback(async (contactGroupId?: number) => {
        try {
            if (!contactGroupId) {
                return;
            }
            setContactGroupDocumentsInProgress(true);
            const response: AxiosResponse<Array<ContactGroupDocuments>> = await SM.customDocumentsService('getContactGroupDocuments', [{contactGroupId}]);

            const documents = response.data.map(item => ({
                ...item,
                nameCol: {
                    link: '',
                    value: item.id,
                    onDownload: () => downloadDocument(item.id),
                },
            }));

            setContactGroupDocuments(documents);
        } catch (err) {
            handlerRequestCanceling(HandlerError({
                setError: setContactGroupDocumentsError,
                setLoading: setContactGroupDocumentsInProgress
            }))(err);
        } finally {
            setContactGroupDocumentsInProgress(false);
        }
    }, [downloadDocument]);

    const getContactGroupDetails = useCallback(async (groupId?: number) => {
        try {
            setError(null);

            if (!groupId) {
                return;
            }
            setLoading(true);

            const response: AxiosResponse<ContactGroupDetails> = await SM.customClientService('getContactGroupDetails', [{groupId}]);

            setContactGroupDetails(response.data);
        } catch (err) {
            handlerRequestCanceling(HandlerError({
                setError,
                setLoading
            }))(err);
        } finally {
            setLoading(false);
        }
    }, []);

    const getConnections = useCallback(async () => {
        try {
            setLoading(true);
            setError(null);
            const {data: connectionsData} = await SM.customClientService('getContactGroups', [{
                contactId: clientId,
            }]);

            setData(connectionsData.filter(f => !!f.proxies?.length || f.groupName !== ContactGroupEnum.threeB));
            setRawData(connectionsData);
        } catch (err) {
            handlerRequestCanceling(HandlerError({
                setError,
                setLoading
            }))(err);
        } finally {
            setLoading(false);
        }
    }, []);

    const createConnection = useCallback(async (context: NewConnectionsSessionManagerAPI): Promise<void> => {
        const {
            contactId,
            clients,
            accessPolicy,
            relationship,
            mailingAddress,
            documents,
            includeCCDoc,
        } = context;

        try {
            setLoading(true);
            setError(null);
            if (context.connectionType === 'poa' || context.connectionType === 'representative') {
                if (!clients?.length || !accessPolicy || !documents?.length) {
                    throw new Error(t('contactGroups.dataMissing'));
                }

                const payload: NewProxyConnectionPayload = {
                    proxyId: clients[0].id,
                    accessPolicy,
                    proxyRelationship: {
                        description: relationship || '',
                        ccApproval: includeCCDoc ?? false,
                    },
                    documents: documents.map(doc => ({
                        file: doc.file,
                        filename: doc.filename,
                        type: doc.type
                    })),
                };

                if (context.contactGroupId) {
                    await SM.customClientService('updateContactGroupProxy', [context.contactGroupId, payload]);
                } else {
                    await SM.customClientService('createProxyConnection', [contactId, payload]);
                }
                notification.open({
                    content: t('contactGroups.proxyAddedSuccessfully'),
                    type: 'success'
                });
            } else {
                if (!clients?.length || !mailingAddress || !contactId) {
                    throw new Error(t('contactGroups.dataMissing'));
                }

                const payload: NewJAConnectionPayload = {
                    contacts: [...clients.map((c) => (c.id)), contactId],
                    mailingAddress,
                };

                await SM.customClientService('createJAConnection', [contactId, payload]);
                notification.open({
                    content: t('contactGroups.jointAccountAddedSuccessfully'),
                    type: 'success'
                });
            }

            await getConnections();
        } catch (err: any) {
            notification.open({
                content: `${t('contactGroups.somethingWentWrong')} ${err.message}`,
                type: 'error'
            });
            throw err;
        } finally {
            setLoading(false);
        }
    }, [t]);

    // const updateContactGroupAddress = useCallback(async (groupId: string, address: MailingAddress): Promise<void> => {
    //     try {
    //         setAddressLoading(true);
    //         await SM.customClientService('updateContactGroupAddress', [groupId, {
    //             mailingAddress: address,
    //         }]);
    //         await getContactGroupDetails(parseInt(groupId, 10));
    //         notification.open({content: t('contactGroups.addressUpdatedSuccessfully'), type: 'success'});
    //     } catch (err: any) {
    //         notification.open({content: `${t('contactGroups.somethingWentWrong')} ${err.message}`, type: 'error'});
    //     } finally {
    //         setAddressLoading(false);
    //     }
    // }, []);

    const getProxyAdditionStatus = useCallback(async (contactGroupId?: string | null | undefined) => {
        try {
            if (!contactGroupId) {
                return;
            }
            const {data: proxyData} = await SM.customClientService('getProxyAdditionStatus', [parseInt(contactGroupId, 10)]);

            setAllowProxyAddition(proxyData?.canAddProxy);
        } catch (err) {
            setAllowProxyAddition(false);
        }
    }, []);

    const loadContactAddresses = useCallback(async (contacts: number[]): Promise<void> => {
        try {
            if (!contacts?.length) {
                return;
            }

            setAddressLoading(true);

            const {data: allAddresses} = await SM.customClientService('getContactGroupAddresses', [contacts]);

            setAddress(allAddresses);
        } catch (err) {
            handlerRequestCanceling(HandlerError({
                setError: setAddressError,
                setLoading: setAddressLoading
            }))(err);
        } finally {
            setAddressLoading(false);
        }
    }, []);

    useEffect(() => {
        (async () => getConnections())();
    }, [getConnections]);

    const exposedAPI = useMemo(() => ({
        data,
        isLoading,
        error,
        onAddConnection,
        onGeneratePOAform,
        selectedProxyConnection,
        discardConnectionRemove: () => setSelectedProxyConnection(null),
        isAddConnectionModalOpen,
        closeAddConnectionModal: () => setIsAddConnectionModalOpen(false),
        createConnection,
        getContactGroupDetails,
        contactGroupDetails,
        setSelectedProxyConnection,
        onProxyConnectionDelete,
        deleteContactGroup,
        getContactGroupDocuments,
        contactGroupDocumentsInProgress,
        contactGroupDocumentsError,
        contactGroupDocuments,
        addresses,
        isAddressesLoading: isAddressesLoading || inProgress,
        inProgress,
        loadContactAddresses,
        addressError,
        getContactGroupHistory,
        contactGroupHistoryLoading,
        contactGroupHistoryError,
        contactGroupHistory,
        setContactGroupDetails,
        updateContactGroupAddress,
        downloadDocument,
        getProxyAdditionStatus,
        allowProxyAddition,
        rawData,
    }), [
        data,
        isLoading,
        error,
        onAddConnection,
        onGeneratePOAform,
        selectedProxyConnection,
        isAddConnectionModalOpen,
        createConnection,
        getContactGroupDetails,
        contactGroupDetails,
        contactGroupDocuments,
        getContactGroupDocuments,
        contactGroupDocumentsInProgress,
        contactGroupDocumentsError,
        onProxyConnectionDelete,
        onProxyConnectionDelete,
        addresses,
        isAddressesLoading,
        inProgress,
        loadContactAddresses,
        addressError,
        getContactGroupHistory,
        contactGroupHistoryLoading,
        contactGroupHistoryError,
        contactGroupHistory,
        updateContactGroupAddress,
        downloadDocument,
        getProxyAdditionStatus,
        allowProxyAddition,
        rawData,
    ]);

    return (
        <ConnectionsContext.Provider value={exposedAPI}>
            {children}
        </ConnectionsContext.Provider>
    );
};

export const useConnections = (): ConnectionsAPI => useContext(ConnectionsContext);
