import React, {
    createContext, useState, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { getNumberSeparators } from './utils';

const defaultValue = {
    locale: 'de-CH',
    name: 'navigation',
};

const LocaleContext = createContext<{locale: string, name:string, numberSeparators?: { thousandSeparator: string, decimalSeparator: string } }>(defaultValue);

const { Provider, Consumer: LocaleConsumer } = LocaleContext;

const LocaleProvider = ({ children }) => {
    const [locale, setLocale] = useState(defaultValue);

    const numberSeparators = useMemo(() => getNumberSeparators(locale.locale), [locale]);

    const value:{locale: string, name:string, numberSeparators: { thousandSeparator: string, decimalSeparator: string } } = {
        ...locale,
        numberSeparators,
    };

    return (
        <Provider value={value}>
            {children}
        </Provider>
    );
};

LocaleProvider.propTypes = {
    children: PropTypes.node,
};

LocaleProvider.defaultProps = {
    children: null,
};

export {
    LocaleProvider,
    LocaleConsumer,
    LocaleContext,
};
