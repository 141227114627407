import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../Icon/Icon';
import Tooltip from '../Tooltip';
import { ICON_TYPE_DROPDOWN, ICON_TYPE_ERROR } from '../Icon/constants';
import { getClassNames } from '../../utils';

import './Select.css';
import {getDataOrAriaProps} from '../../../utils';

function SelectButton({
    ariaControlName, opened, errorInTooltip, error, checked, className,
    mini, boxed, selectedOptions, onFocus, onBlur, onClick, onKeyPress, ariaHaspopup,
    ariaLabelledby, onKeyDown, id, ...rest
}) {
    let selected;
    let count;

    if (selectedOptions) {
        if (typeof selectedOptions === 'string') {
            selected = selectedOptions;
        } else if (selectedOptions.length < 2) {
            [selected] = selectedOptions;
        } else {
            selected = selectedOptions.join(', ');
            count = selectedOptions.length;
        }
    } else selected = 'Select';

    const selectButtonClassName = getClassNames(
        'SelectButton',
        className,
        { SelectButton_tooltiped: errorInTooltip },
        { SelectButton_mini: mini },
        { SelectButton_boxed: boxed },
        { SelectButton_checked: checked },
    );

    const labelClassName = getClassNames(
        'selected__values',
        { selected__single: !count },
    );

    return (
        <button
            className={selectButtonClassName}
            type="button"
            id={id}
            aria-controls={ariaControlName}
            aria-expanded={opened}
            aria-haspopup={ariaHaspopup}
            aria-labelledby={ariaLabelledby}
            onFocus={onFocus}
            onBlur={onBlur}
            onClick={onClick}
            onKeyPress={onKeyPress}
            onKeyDown={onKeyDown}
            {...getDataOrAriaProps(rest)}
        >
            <span className={labelClassName}>{selected}</span>
            {count && <span className="selected__count">{count}</span>}

            {errorInTooltip && (
                <Tooltip title={error}>
                    <Icon type={ICON_TYPE_ERROR} className="anticon__error" />
                </Tooltip>
            )}

            <Icon type={ICON_TYPE_DROPDOWN} className="anticon__arrow" />
        </button>
    );
}

SelectButton.propTypes = {
    ariaControlName: PropTypes.string,
    className: PropTypes.string,
    error: PropTypes.string,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    onClick: PropTypes.func,
    onKeyPress: PropTypes.func,
    opened: PropTypes.bool,
    checked: PropTypes.bool,
    mini: PropTypes.bool,
    boxed: PropTypes.bool,
    errorInTooltip: PropTypes.bool,
    selectedOptions: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.string),
        PropTypes.string,
    ]),
    ariaHaspopup: PropTypes.string,
    ariaLabelledby: PropTypes.string,
    id: PropTypes.string,
    onKeyDown: PropTypes.func,
};

SelectButton.defaultProps = {
    ariaControlName: null,
    className: '',
    error: null,
    onFocus: () => { },
    onBlur: () => { },
    onClick: () => { },
    onKeyPress: () => { },
    opened: false,
    checked: false,
    mini: false,
    boxed: false,
    errorInTooltip: false,
    selectedOptions: null,
    ariaHaspopup: '',
    ariaLabelledby: undefined,
    onKeyDown: () => { },
    id: '',
};

export default SelectButton;
