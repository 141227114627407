import React, {useCallback, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {
    FileInput, Loader, Modal, notification,
} from '../../../../../ui-library';
import {MAXALLOWEDFILES} from '../../../../ClientOnboardingFlow/pages/NewsPlan/UploadDocumentsPage/validation/const';
import ServiceManager from '../../../../../services/ServiceManager';
import {getBase64FromFile} from '../../../../../utils/file';

const UploadPersonalDocumentsModal = (props) => {
    const {
        onClose, onUploadSuccess, clientId,
    } = props;
    const {t} = useTranslation();
    const [files, setFiles] = useState([]);
    const [uploadInProgress, setUploadInProgress] = useState(false);

    const onUpload = useCallback(async () => {
        try {
            setUploadInProgress(true);
            const payload = {
                ownerId: clientId,
                documents: await Promise.all(files.map(async (file) => ({
                    filename: file.name,
                    file: (await getBase64FromFile(file)).split(',')[1],
                }))),
            };

            await ServiceManager.customDocumentsService('uploadIdDocument', [payload]);

            onClose();
            onUploadSuccess();
        } catch (err) {
            notification.open({content: err?.message, type: 'error'});
        } finally {
            setUploadInProgress(false);
        }
    }, [onUploadSuccess, clientId, files]);

    const onFileUpload = useCallback((uploadedFiles) => {
        setFiles(uploadedFiles);
    }, []);

    const error = useMemo(() => {
        if (files.length > MAXALLOWEDFILES) {
            return t('validation.maxUploadCount').replace('{maxUploadCount}', MAXALLOWEDFILES);
        }

        return null;
    }, [files.length, t]);

    return (
        <Modal
            visible
            okText={uploadInProgress ? <Loader/> : t('clientOverview.personalDocuments.upload')}
            okButtonProps={{disabled: !files.length || uploadInProgress || files.length > MAXALLOWEDFILES}}
            onOk={onUpload}
            onCancel={onClose}
            title={t('clientOverview.personalDocuments.modalTitle')}
        >
            <FileInput
                multiple
                onChange={onFileUpload}
                error={error}
            />
        </Modal>
    );
};

UploadPersonalDocumentsModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    onUploadSuccess: PropTypes.func.isRequired,
    clientId: PropTypes.number.isRequired,
};

export default UploadPersonalDocumentsModal;
