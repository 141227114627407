import {convertDate} from '../helpers/convertDate';

export const getModel = (outputData) => ({
    gender: outputData?.details?.personalInformation?.gender,
    // titleInput: null,
    firstName: outputData?.details?.personalInformation?.firstName,
    lastName: outputData?.details?.personalInformation?.lastName,
    dateOfBirth: outputData?.details?.personalInformation?.dateOfBirth && convertDate(outputData?.details?.personalInformation?.dateOfBirth),
    countryOfResidence: String(outputData?.details?.communicationMethods?.primaryAddressCountry),
    countryOfNationality: outputData.details?.personalInformation?.identification && String(outputData.details?.personalInformation?.identification[0]?.countryOfNationality),
    street: outputData?.details?.communicationMethods?.primaryAddress?.primaryAddressLine3,
    PLZ: outputData?.details?.communicationMethods?.primaryAddress?.primaryAddressLine4,
    ORT: outputData?.details?.communicationMethods?.primaryAddress?.primaryAddressLine5,
    maritalStatus: String(outputData?.details?.personalInformation?.maritalStatus),
    language: String(outputData?.details?.personalInformation?.language),
    showSecondaryAdress: !!outputData?.details?.communicationMethods?.mailRecipient?.mailRecipientAddressCountry,
    land: String(outputData?.details?.communicationMethods?.mailRecipient?.mailRecipientAddressCountry) || null,
    secondaryStreet: outputData?.details?.communicationMethods?.mailRecipient?.mailRecipientAddressLine3 || null,
    secondaryPLZ: outputData?.details?.communicationMethods?.mailRecipient?.mailRecipientAddressLine4 || null,
    secondaryORT: outputData?.details?.communicationMethods?.mailRecipient?.mailRecipientAddressLine5 || null,
    primaryPhoneNumber: outputData?.details?.communicationMethods?.primaryPhoneNumber,
    primaryEmail: outputData?.details?.communicationMethods?.primaryEmail,
    countryOfBirth: String(outputData?.details?.personalInformation?.countryOfBirth),
    isUsPerson: outputData?.details?.taxSituation?.isUsPerson,
    hasUsResidence: outputData?.details?.taxSituation?.hasUsResidence,
    classedUsPerson: [outputData?.details?.taxSituation?.classedUsPerson],
    usCitizen: outputData?.details?.taxSituation?.isUsCitizenOrSubjectToUsTax,
    hideDatePickerValue: false,

    mailRecipientGender: outputData.details?.communicationMethods?.mailRecipient?.mailRecipientGender,
    mailRecipientLanguage: outputData.details?.communicationMethods?.mailRecipient?.mailRecipientLanguage,
    mailRecipientAddressCountry: outputData.details?.communicationMethods?.mailRecipient?.mailRecipientAddressCountry,
    mailRecipientAddressLine1: outputData.details?.communicationMethods?.mailRecipient?.mailRecipientAddressLine1,
    mailRecipientAddressLine2: outputData.details?.communicationMethods?.mailRecipient?.mailRecipientAddressLine2,
    mailRecipientAddressLine3: outputData.details?.communicationMethods?.mailRecipient?.mailRecipientAddressLine3,
    mailRecipientAddressLine4: outputData.details?.communicationMethods?.mailRecipient?.mailRecipientAddressLine4,
    mailRecipientAddressLine5: outputData.details?.communicationMethods?.mailRecipient?.mailRecipientAddressLine5,
    mailRecipientFirstName: outputData.details?.communicationMethods?.mailRecipient?.mailRecipientFirstName,
    mailRecipientLastName: outputData.details?.communicationMethods?.mailRecipient?.mailRecipientLastName,
    mailRecipientSalutation: outputData.details?.communicationMethods?.mailRecipient?.mailRecipientSalutation,

});
