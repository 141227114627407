import React from 'react';

import './styles/PageTitle.css';

const PageTitle = (props) => (
    <div className="titleBlock">
        {props.children && (
            <h1 className={`title ${props.className}`}>
                {props.children}
            </h1>
        )}
        {props.undertitle && <h2 className="undertitle">{props.undertitle}</h2>}
    </div>
);

PageTitle.defaultProps = {
    className: '',
    undertitle: '',
};

export default PageTitle;
