import {APIVersion} from 'additiv-services';

export const BASIC_API_URL = `${import.meta.env.VITE_APP_GATEWAY_API_URL}${global.AppConfig.REACT_APP_CSL_URL.replace('/csl-api/v1.0/', '/csl-api/')}`;

export const CUSTOM_SERVICE_LAYER_SERVICES = {
    CONTACT: 'Contact',
    CASE_MANAGEMENT: 'CaseManagement',
    PLAN: 'plan',
    MEMBERS: 'members',
    INVESTMENT: 'investment',
    CONTACT_ACTIVATION: 'ContactActivation',
    DOCUMENTS: 'Documents',
    COMMON: 'common',
    ADVISORY: 'Advisory',
    DEFAULT: '',
};
export const getCustomServiceLayerBaseUrl = (service, version = APIVersion.V10) => `${BASIC_API_URL}${version}/${service}`;
