import {
    getBearerAccessToken,
    getBearerApplicationAccessToken,
    getCustomUrl,
    getServicesUrl,
    getClientServiceMemberUrl,
    setup,
    withBearerApplicationAccessToken
} from '../../setup';
import {
    APIVersion,
    PayloadRequestParameters,
    ServiceTypes,
    URLRequestParameters,
    URLWithPayloadRequestParameters
} from '../../types/main';
import Service from './service';
import {AxiosError, AxiosResponse} from 'axios';
import {
    AccessTokenForExtensionPayload,
    AccessTokenPayload,
    AccessTokenResponseData,
    AuthenticatePayload,
    AuthenticateResponseData,
    AuthenticateStatusPayload,
    AuthenticateStatusResponseData,
    ChangePasswordByActivationKeyPayloadData,
    ChangePasswordByContactIdPayloadData,
    ForgotPasswordMTanPayload,
    ForgotPasswordMTanResponseData,
    ForgotPasswordPayload,
    ForgotPasswordResponseData,
    PasswordChangeResult,
    PreAuthPayload,
    PreAuthResponseData,
    PreAuthWithCaptchaPayload,
    PreAuthWithCaptchaResponseData,
    RegisterPrivateUserPayload,
    RegisterPrivateUserReturn,
    RenewTokenResponseData,
    ResultResponseData
} from "../../types/services/security";

class Security extends Service {

    /**
     * @deprecated
     */
    static async registerPrivateUser(data: RegisterPrivateUserPayload) {
        return Service.postRequest<RegisterPrivateUserPayload, RegisterPrivateUserReturn>(
            `${getServicesUrl(APIVersion.V11)}/contacts/register`,
            data,
            { isSecure: true }
        );
    }

    static async changePasswordByActivationCodeWithPolicies(
        {
            payload,
            config
        }: PayloadRequestParameters<ChangePasswordByActivationKeyPayloadData>
    ) {
        await Security.generateBearerApplicationAccessTokenIfNeeded();

        return Service.postRequest<ChangePasswordByActivationKeyPayloadData, PasswordChangeResult>(
            `${getServicesUrl(APIVersion.V11)}/authentication/reset-password`, payload,
            config
        );
    }

    /**
     * @deprecated
     */
    static async changePasswordByActivationCode(
        {
            payload,
            config
        }: PayloadRequestParameters<ChangePasswordByActivationKeyPayloadData>
    ) {
        await Security.generateBearerApplicationAccessTokenIfNeeded();

        return Service.postRequest<ChangePasswordByActivationKeyPayloadData, ResultResponseData>(`${getServicesUrl()}/authentication/reset-password`, payload,
            config
        );
    }

    static async changePasswordByContactIdWithPolicies(
        {
            urlParams,
            payload,
            config
        }: URLWithPayloadRequestParameters<{ contactId: number }, ChangePasswordByContactIdPayloadData>
    ) {
        return Service.postRequest<ChangePasswordByContactIdPayloadData, PasswordChangeResult>(
            `${getServicesUrl(APIVersion.V11)}/contacts/${urlParams.contactId}/authentication/reset-password`,
            payload,
            {
                isSecure: true,
                ...config
            }
        );
    }

    /**
     * @deprecated
     */
    static async changePasswordByContactId(
        {
            urlParams,
            payload,
            config
        }: URLWithPayloadRequestParameters<{ contactId: number }, ChangePasswordByContactIdPayloadData>
    ) {
        return Service.postRequest<ChangePasswordByContactIdPayloadData, ResultResponseData>(
            `${getServicesUrl()}/contacts/${urlParams.contactId}/authentication/reset-password`,
            payload,
            {
                isSecure: true,
                ...config
            }
        );
    }

    static async forgotPassword
    (
        {
            payload,
            config
        }: PayloadRequestParameters<ForgotPasswordPayload>
    ) {
        await Security.generateBearerApplicationAccessTokenIfNeeded();

        return Service.postRequest<ForgotPasswordPayload, ForgotPasswordResponseData>(
            `${getServicesUrl(APIVersion.V20)}/authentication/forgotten-password`,
            payload,
            config
        );
    }

    static async activateUser(
        { payload, config }: PayloadRequestParameters<{ ActivationKey: string }>
    ) {
        await Security.generateBearerApplicationAccessTokenIfNeeded();

        return Service.postRequest<
        {ActivationKey: string },
        {
            IsPasswordSet: boolean,
            UserName: string,
            ContactId: string,
        }
        >(
            `${getServicesUrl()}/contacts/activate/${payload.ActivationKey}`,
            payload,
            config
        );
    }

    static async forgotPasswordMtan(
        {
            payload,
            config
        }: PayloadRequestParameters<ForgotPasswordMTanPayload>
    ) {
        await Security.generateBearerApplicationAccessTokenIfNeeded();

        return Service.postRequest<ForgotPasswordMTanPayload, ForgotPasswordMTanResponseData>
        (
            `${getServicesUrl(APIVersion.V21)}/authentication/forgotten-password/mtan`,
            payload,
            config
        );
    }

    static async preAuth( {
        payload,
        config
    }: PayloadRequestParameters<PreAuthPayload>) {
        await Security.generateBearerApplicationAccessTokenIfNeeded();

        return Service.postRequest<PreAuthPayload, PreAuthResponseData>(
            `${getServicesUrl(APIVersion.V11)}/authentication/pre-authenticate`,
            payload,
            config
        );
    }

    static async preAuthWithCaptcha( {
        payload,
        config
    }: PayloadRequestParameters<PreAuthWithCaptchaPayload>) {
        await Security.generateBearerApplicationAccessTokenIfNeeded();

        return Service.postRequest<PreAuthWithCaptchaPayload, PreAuthWithCaptchaResponseData>(
            `${getCustomUrl()}/v1.1/authentication/pre-authenticate`,
            payload,
            config
        );
    }

    static logOff({
        urlParams,
        config
    }: URLRequestParameters<{ contactId: number }>) {
        return Service.postRequest<undefined, ResultResponseData>
        (
            `${getServicesUrl()}/contacts/${urlParams.contactId}/authentication/logoff`,
            undefined,
            { isSecure: true, ...config }
        );
    }

    static async authenticate({
        urlParams,
        payload,
        config
    }: URLWithPayloadRequestParameters<{ contactId: number }, AuthenticatePayload >) {
        await Security.generateBearerApplicationAccessTokenIfNeeded();

        return Service.postRequest<AuthenticatePayload, AuthenticateResponseData>
        (
            `${getServicesUrl(APIVersion.V11)}/contacts/${urlParams.contactId}/authentication/authenticate`,
            payload,
            config
        );
    }

    static async authenticateStatus({
        payload,
        config
    }: PayloadRequestParameters<AuthenticateStatusPayload >) {
        await Security.generateBearerApplicationAccessTokenIfNeeded();

        return Service.postRequest<AuthenticateStatusPayload, AuthenticateStatusResponseData>
        (
            `${getServicesUrl(APIVersion.V11)}/authentication/authenticate/status`,
            payload,
            { withCancel: true, ...config }
        );
    }

    /** @method
     * @name generateAccessToken
     * generate new access token based on type
     * @param {Object} data Request params.
     * Possible values:
     * - 1 - application identity
     * - 2 - notification hub identity
     * we should generate new access token type.
     * @returns {Object} data
     * @returns {Object} data.accessToken - new type of accessToken
     */
    static generateAccessToken(
        {
            payload,
            config
        }: PayloadRequestParameters <AccessTokenPayload>
    ) {
        return new Promise<AccessTokenResponseData>((resolve, reject) => (
            (Service.postRequest <AccessTokenPayload, AccessTokenResponseData> (
                `${getCustomUrl()}/connect/token`,
                payload,
                config
            ) as Promise<AxiosResponse<AccessTokenResponseData>>).then((response:AxiosResponse<AccessTokenResponseData>) => {
                if (payload.type === 1) {
                    if (response.data.isSuccessful) {
                        setup({ bearerApplicationAccessToken: `Bearer ${response.data.result.accessToken}` });
                    } else {
                        reject(response.data.apiError);
                    }
                }

                resolve(response.data);
            }).catch((e:AxiosError) => {
                reject(e);
            })
        ));
    }

    static generateBearerApplicationAccessTokenIfNeeded() {
        if (withBearerApplicationAccessToken()) {
            return Security.generateAccessToken({ payload: { type: 1 } });
        }
    }

    static async refreshConnectHubToken() {
        const { result: { accessToken } } = await Security.generateBearerApplicationAccessTokenIfNeeded() as AccessTokenResponseData;

        return Service.postRequest(`${getCustomUrl()}/connect/hub/token/refresh`, {
            accessToken,
        }, { isSecure: true });
    }

    static invalidateAllAccessTokens() {
        const accessTokensToInvalidate:Array<string|undefined> = [
            getBearerApplicationAccessToken(),
            getBearerAccessToken()
        ];
        const promises = accessTokensToInvalidate.reduce<Promise<AxiosResponse<undefined>>[]>(
            (previousValue, currentValue) => {
                if (currentValue === undefined) return previousValue;
                previousValue.push(
                    Service.postRequest<{ accessToken: string }, undefined >(
                        `${getCustomUrl()}/connect/hub/token/remove`,
                        {
                            accessToken: currentValue
                        }, { isSecure: true }
                    ) as Promise<AxiosResponse<undefined>>
                );

                return previousValue;
            }, [])

        return Promise.all(promises);
    }

    /** @method
     * @name getAccessTokenForExtension
     * get access token based on type of extension
     * @param {Object} payload Request params.
     * @param {number} payload.type requested type of access token.
     * Possible values:
     * - 'clarity' - application identity
     * - 'fidentity' - notification hub identity
     * @param {Object} config Request configuration object.
     * @returns {Object} data
     * @returns {Object} data.accessToken - new type of accessToken
     */
    static getAccessTokenForExtension({
        payload,
        config
    }: PayloadRequestParameters<AccessTokenForExtensionPayload>) {
        return Service.postRequest<AccessTokenForExtensionPayload, AccessTokenResponseData>(
            `${getCustomUrl()}/ext/connect/token`,
            payload,
            config
        );
    }

    static validateToken({
        payload,
        config
    }: PayloadRequestParameters<{ AccessToken:string }>
    ) {
        return Service.postRequest<{ AccessToken:string }, ResultResponseData>(
            `${getServicesUrl()}/authentication/tokens/validate`,
            payload, {
                withCancel: true,
                ...config
            });
    }

    static async renewToken(
        {
            urlParams,
            payload,
            config,
        }: URLWithPayloadRequestParameters<{contactId: number}, { AccessToken:string }>
    ) {
        await Security.generateBearerApplicationAccessTokenIfNeeded();

        return Service.putRequest<{ AccessToken:string },RenewTokenResponseData >(
            `${getServicesUrl()}/contacts/${urlParams.contactId}/authentication/tokens/renew`,
            payload,
            {
                type: ServiceTypes.APP,
                ...config
            }
        );
    }
}

export default Security;

export const security = new Security();
