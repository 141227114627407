import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';
import Card from '../../../../components/Card/Card';
import AddClientNote from './components/AddClientNote';
import EmptyContent from '../../../../components/EmptyContent';
import {Table} from '../../../../ui-library';
import Preloader from '../../../../components/Preloader';
import {getColumns} from './constants';
import {getClientId} from '../../../../utils';
import useClientNotesData from './useClientNotesData';
import {PAGE_SIZE} from '../../../../constants/constants';
import {useFormatting} from '../../../../locale';
import {useClientLayout} from '../../hooks/useClientLayout';

const ClientNotes = () => {
    const {dfsClientId} = useParams();
    const {t} = useTranslation();
    const clientId = getClientId(dfsClientId);
    const {getFormattedDate} = useFormatting();

    useClientLayout();

    const {
        getClientNotes, loading, error, data, totalNumber, saveClientNotes, setError, saveInProgress, currentPage, onPageChange,
    } = useClientNotesData(clientId);

    useEffect(() => {
        (async () => getClientNotes())();
    }, [getClientNotes]);

    return (
        <div className="container">
            <Card
                title={t('advisoryDashboard.clientNotes.title')}
                titleTagName="h1"
                headerActions={(
                    <AddClientNote
                        saveClientNotes={saveClientNotes}
                        getClientNotes={getClientNotes}
                        inProgress={saveInProgress}
                        error={error}
                        setError={setError}
                    />
                )}
            >
                <Preloader
                    isLoading={loading}
                    error={error}
                >
                    <EmptyContent data={data} text={t('advisoryDashboard.clientNotes.noNotes')}>
                        <Table
                            columns={getColumns(t, getFormattedDate)}
                            data={data}
                            paging={{
                                total: totalNumber || 0,
                                pageSize: PAGE_SIZE,
                                currentPage,
                            }}
                            onChange={onPageChange}
                        />
                    </EmptyContent>
                </Preloader>
            </Card>
        </div>
    );
};

export default ClientNotes;
