import { useContext } from 'react';

import ChangeStrategyContext from './context';

const useChangePlan = () => {
    const context = useContext(ChangeStrategyContext);

    if (context === undefined) {
        throw new Error('useChangePlan can only be used inside ChangeStrategyProvider');
    }

    return context;
};

export default useChangePlan;
