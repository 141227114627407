import { useQuery } from '@tanstack/react-query';
import ServerError from 'errors/ServerError';
import { useTranslation } from 'react-i18next';
import SM from 'services/ServiceManager';
import { notification } from 'ui-library';

export const useHistorialPerformanceData = ({isEnabled = false}) => {
    const {t} = useTranslation();
    const getPerformanceHistory = async () => {
        try {
            return await SM.customAdvisoryServices('getPerformanceHistory');
        } catch (error: any) {
            notification.open({content: `${t('contactGroups.somethingWentWrong')} ${error.message}`, type: 'error'});

            return null;
        }
    };

    const result = useQuery({
        queryKey: ['getPerformanceHistory'],
        enabled: isEnabled,
        retry: false,
        queryFn: () => getPerformanceHistory(),
    });

    return {
        data: result?.data?.data?.historicalPerformances || null,
        statusCode: result?.data?.status,
        error: result.error ? new ServerError(result.error) : result.error,
    };
};
