import React from 'react';
import PropTypes from 'prop-types';
import Popover from '../Popover';
import EventDetails from './EventDetails';

const EventPopover = ({ event, children, view }) => (
    <Popover
        overlayClassName="event-popover"
        content={<EventDetails {...event} />}
        trigger={['click']}
        placement={view === 'day' ? 'bottom' : 'right'}
    >
        {children}
    </Popover>
);

EventPopover.propTypes = {
    event: PropTypes.shape({
        color: PropTypes.string,
        title: PropTypes.string.isRequired,
        startDate: PropTypes.string,
        endDate: PropTypes.string,
        currentDay: PropTypes.instanceOf(Date).isRequired,
    }).isRequired,
    view: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default EventPopover;
