import uniq from 'lodash/uniq';

export const getDocumentsPortfolios = ({
    portfolios, documents,
}) => {
    const filteredPortfolios = [];

    (documents?.results || []).forEach(({ contractId }) => {
        const filter = contractId && portfolios.find(({ UId }) => UId === contractId);

        if (filter) {
            filteredPortfolios.push(filter);
        }
    });

    return { Portfolios: uniq(filteredPortfolios) };
};

export const getMimeTypeFromBase64File = (file) => {
    switch (file.charAt(0)) {
        case '/':
            return 'image/jpeg';
        case 'i':
            return 'image/png';
        case 'R':
            return 'image/gif';
        case 'U':
            return 'image/webp';
        case 'J':
            return 'application/pdf';
        default:
            return 'unknown';
    }
};
