import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { JAUsersType } from 'datasource/useApplicationDetails/ApplicationDetailsManager.types';
import { addUnits, dateFormatZurich, formatDate } from '../../../../../utils/datetime';
import { useGetGeneralDocuments } from '../../../../../hooks/rest/useGetGeneralDocuments';
import { useGetProofOfAssetsDocs } from '../../../../../hooks/rest/useGetProofOfAssetsDocs';
import { useGetAdvisory } from '../../../../../hooks/rest/useGetAdvisory';
import { useGetDocumentsByContainerId } from '../../../../../hooks/rest/useGetDocumentsByContainerId';

export const use3bApplicationDetails = ({
    investmentAppsData,
}) => {
    const { containerId } = useParams();
    const { i18n: { language } } = useTranslation();

    const isAdvisoryCase = useMemo(() => !!investmentAppsData?.[0]?.investmentDescription?.advisoryDocumentId, [investmentAppsData]);
    const {
        data: applicationsDocumentsData,
        isLoading: isApplicationDocsLoading,
    } = useGetDocumentsByContainerId({ containerId });
    const {
        data: generalDocumentsData,
        isLoading: isGetGeneralDocumentsLoading,
    } = useGetGeneralDocuments({ containerId, language });
    const contactGroupId = investmentAppsData?.[0]?.investmentDescription?.contactGroupId;
    const isJA = investmentAppsData?.[0]?.isJointAccount;
    const jaUsers:JAUsersType = useMemo(() => {
        const owners = investmentAppsData?.[0]?.owners;

        return Object.keys(investmentAppsData?.[0]?.owners).map((key) => ({
            id: parseInt(key, 10),
            fullName: owners[key],
        }));
    }, [isJA]);
    const {
        data: proofOfAssetsDocsData,
        isPending: isProofOfAssetsDocsDataLoading,
    } = useGetProofOfAssetsDocs({ contactGroupId, containerId, language });
    const advisoryDocId = investmentAppsData?.[0]?.investmentDescription?.advisoryDocumentId;
    const { data: advisoryDetailsData } = useGetAdvisory({ id: advisoryDocId });

    const advisoryData = useMemo(() => {
        const sumOfPlannedInvestmentAmounts = investmentAppsData.reduce(
            (akku, item) => {
                const changeInvestmentAmount = item?.investmentDescription?.changeInvestmentAmount;

                if (item?.changePlan) {
                    if (changeInvestmentAmount) {
                        return changeInvestmentAmount?.isSell ? (akku - changeInvestmentAmount?.value) : (akku + changeInvestmentAmount?.value);
                    }

                    return akku;
                }

                return akku + (item?.plannedInvestmentAmount ?? 0);
            },
            0,
        );

        return {
            advisoryNo: advisoryDetailsData?.advisoryNumber ?? '-',
            createdAt: advisoryDetailsData?.createdDate ? formatDate(advisoryDetailsData?.createdDate, dateFormatZurich) : '-',
            validTill: advisoryDetailsData?.createdDate ? addUnits(advisoryDetailsData?.createdDate, '1', 'y', dateFormatZurich) : '-',
            investmentAmount: sumOfPlannedInvestmentAmounts,
        };
    }, [
        investmentAppsData, advisoryDetailsData?.advisoryNumber,
        advisoryDetailsData?.createdDate,
    ]);

    return {
        isJA,
        jaUsers,
        isApplicationDocsLoading,
        isGetGeneralDocumentsLoading,
        isProofOfAssetsDocsDataLoading,
        isAdvisoryCase,
        generalDocumentsData,
        contactGroupId,
        proofOfAssetsDocsData,
        advisoryDocId,
        advisoryDetailsData,
        advisoryData,
        applicationsDocumentsData,
    };
};
