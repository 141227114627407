export const getCookie = (name) => {
    const cookie = {};

    const cookies = document.cookie.split(';');

    for (let i = 0; i < cookies.length; i++) {
        const [k, v] = cookies[i].split('=');

        cookie[k.trim()] = v;
    }

    return cookie[name];
};

export const selectLocale = (locales) => {
    return Object.keys(locales).reduce((a, b) => {
        return locales[a].priority < locales[b].priority ? a : b;
    });
};

export const priorityExists = (priority, locales) => {
    return Object.keys(locales).some((key) => locales[key].priority === priority);
};

export const getInitialLocales = (locales) => {
    const initial = {};

    Object.keys(locales).filter((i) => {
        if (locales[i].locale && locales[i].priority) {
            initial[i] = locales[i];
        }
    });

    return initial;
};

export const localesEmpty = (locales) => Object.entries(locales).length === 0;

export const validateNewLocale = (name, locale) => name && (locale.priority && locale.locale);
