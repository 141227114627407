import SM from 'services/ServiceManager';

export const nameValidationRegExp = /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u;
export const dateValidationRegex = /^\d{2}\/\d{4}$/;

export const testIbanValue = (async (iban: string) => {
    const finalIban = iban.replace(/\s/g, '');

    try {
        const { data } = await SM.customService('validateIban', [{iban: finalIban}]);

        if (!data) {
            return false;
        }

        return true;
    } catch (error) {
        return false;
    }
});
