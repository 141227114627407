import {dateTimeFormats} from '../../../../locale';

export const getColumns = (t, getFormattedDate) => [
    {
        key: 'createdDate',
        render: (date) => getFormattedDate(date, {
            year: dateTimeFormats.NUMERIC,
            month: dateTimeFormats.TWO_DIGIT,
            day: dateTimeFormats.TWO_DIGIT,
            hour: dateTimeFormats.TWO_DIGIT,
            minute: dateTimeFormats.TWO_DIGIT,
        }),
        title: t('clientDashboard.clientNotes.time'),
    },
    {
        key: 'message',
        title: t('clientDashboard.clientNotes.message'),
    },
    {
        key: 'createdByName',
        title: t('clientDashboard.clientNotes.createdBy'),
    },
];
