import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import withTheme from '../../hocs/withTheme';
import './TabbedNavigation.css';

function Link({ children, ...rest }) {
    return (
        <NavLink
            {...rest}
            className={({isActive}) => `Tabbed-navigation__link${isActive ? ' active' : ''}`}
        >
            {children}
        </NavLink>
    );
}

Link.propTypes = {
    /** @ignore */
    children: PropTypes.node,
};

Link.defaultProps = {
    children: null,
};

export default withTheme(Link);
