import React from 'react';
import PropTypes from 'prop-types';

function NoDataCell(props) {
    const { colSpan, noDataText } = props;

    return <tr><td className="no-data" colSpan={colSpan}>{noDataText}</td></tr>;
}

NoDataCell.propTypes = {
    colSpan: PropTypes.number.isRequired,
    noDataText: PropTypes.string.isRequired,
};

export default NoDataCell;
