import React, {
    Fragment, useMemo,
} from 'react';
import {useTranslation} from 'react-i18next';
import {TextInput} from '../../../../../../../../../../ui-library';
import {SHADOW_ACCOUNT_FORM_FIELDS} from '../../constants';
import {useFormatting} from '../../../../../../../../../../locale';
import {useSinglePortfolio} from '../../../../context';

import '../../styles/styles.css';

const ShadowAccountForm = ({productKey, portfolioId}) => {
    const {t} = useTranslation();
    const {getFormattedNumber} = useFormatting();

    const {saldo: data} = useSinglePortfolio({portfolioId});

    const fields = useMemo(() => SHADOW_ACCOUNT_FORM_FIELDS.map(i => {
        if (!i.type || productKey?.includes(i.type)) {
            return (
                <Fragment key={i.label}>
                    <div>
                        <span>{t(i.label)}</span>
                        <TextInput readonly value={getFormattedNumber(data?.[i.key], {minimumFractionDigits: 2, maximumFractionDigits: 2})}/>
                    </div>
                    {i.fullWidth && <div className="placeholder" />}
                </Fragment>
            );
        }

        return null;
    }), [data, getFormattedNumber]);

    return (
        <div className="fz-shadow-account-form container ">
            <div className="desktop-col">
                {fields}
            </div>
        </div>
    );
};

export default ShadowAccountForm;
