/* eslint-disable react/button-has-type */
import React from 'react';
import PropTypes from 'prop-types';
import { BUTTON_TYPES, BUTTON_SIZES } from './constants';
import Loader from '../Loader';
import { getClassNames } from '../../utils';
import './Button.css';
import withTheme from '../../hocs/withTheme';

/** To trigger an operation. */

function Button(props) {
    const {
        type, htmltype, size, pressed, rounded, children, loading, disabled, className, ...rest
    } = props;
    const classButton = getClassNames(
        'button',
        className,
        { [`button-${size}`]: size },
        { [`button-${type}`]: type },
        { 'button-loading': loading },
        { 'button-pressed': pressed },
        { 'button-rounded': rounded },
    );

    return (
        <button
            type={htmltype}
            disabled={loading ? true : disabled}
            className={classButton}
            {...rest}
        >
            {loading ? <Loader negative={type === 'danger'} /> : children}
        </button>
    );
}

Button.propTypes = {
    /** Additional CSS ui classes. Can be set to 'primary',
     * 'secondary', 'danger' or omitted (meaning default) */
    type: PropTypes.oneOf(BUTTON_TYPES),
    /** the button type */
    htmltype: PropTypes.oneOf(['submit', 'button', 'reset']),
    /** Additional CSS ui class. Can be set to 'small' or omitted (meaning default) */
    size: PropTypes.oneOf(BUTTON_SIZES),
    /** Whether the button is pressed */
    pressed: PropTypes.bool,
    /** Whether the button is loaded */
    loading: PropTypes.bool,
    /** Whether the button is rounded */
    rounded: PropTypes.bool,
    /** @ignore */
    disabled: PropTypes.bool,
    /** @ignore */
    children: PropTypes.node,
    /** @ignore */
    className: PropTypes.string,
};

Button.defaultProps = {
    type: null,
    htmltype: 'button',
    size: null,
    pressed: false,
    rounded: false,
    loading: false,
    disabled: false,
    children: null,
    className: '',
};

export default withTheme(Button);
