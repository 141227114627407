import Service from 'additiv-services/services/core/service';
import { getQueryStringByObject } from 'additiv-services/utils/queryUtils';
import {CUSTOM_SERVICE_LAYER_SERVICES, getCustomServiceLayerBaseUrl} from '../constants';

class CustomReports extends Service {
    static generateReport({
        data,
    }) {
        return Service.postRequest(`${getCustomServiceLayerBaseUrl(CUSTOM_SERVICE_LAYER_SERVICES.DEFAULT)}/portfolio-statement-report`, data, {isSecure: true});
    }

    static getReportsStartDate(params) {
        const queryString = getQueryStringByObject(params);

        return Service.getRequest(`${getCustomServiceLayerBaseUrl(CUSTOM_SERVICE_LAYER_SERVICES.DOCUMENTS)}/statement-min-start-date?${queryString}`, {isSecure: true});
    }
}

export default CustomReports;
