import {useQuery} from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { notification } from 'ui-library';
import SM from 'services/ServiceManager';
import { getUniqueListBy } from 'utils';

export const useGetJointPortfolios = ({clientId, proxyId}: {clientId: number | undefined, proxyId: number | undefined}) => {
    const { i18n: {language}, t } = useTranslation();

    const getJointPortfolios = async () => {
        const params = { language };

        const ids = [clientId, proxyId];

        try {
            return await Promise.allSettled(ids?.map(id => id && SM.portfolioManagement('getJointPortfolios', [id, params])));
        } catch (error: any) {
            notification.open({content: `${t('contactGroups.somethingWentWrong')} ${error.message}`, type: 'error'});

            return null;
        }
    };

    const portfolios:any = useQuery({
        queryKey: ['getJointPortfolios'],
        enabled: !!clientId,
        retry: false,
        queryFn: getJointPortfolios,
    });

    const jointAccountPortfolios = portfolios?.data?.map(portfolio => []?.concat(portfolio?.value?.data || [])).flat();
    const uniqueJointAccountPortfolios = getUniqueListBy(jointAccountPortfolios || [], 'id');

    return {data: uniqueJointAccountPortfolios || null};
};
