import React, {
    forwardRef, useCallback, useEffect, useState,
} from 'react';
import {useTranslation} from 'react-i18next';
import debounce from 'lodash/debounce';
import {
    AutoComplete, Icon, Loader, TextInput,
} from '../../../../../../ui-library';
import {ICON_TYPE_SEARCH} from '../../../../../../ui-library/components/Icon';
import ServiceManager from '../../../../../../services/ServiceManager';
import {getAgentInfo} from '../../pages/SinglePortfolioView/pages/Details/utils';

import './PbNumber.css';

const PbNumber = ({
    error, onSelect, value, setAgentInfo, label, required, includeVstNumber, vstNumberValue, onVstNumberChange, vstNumberError, innerRef, ...rest
}) => {
    const {t} = useTranslation();
    const [rawData, setRawData] = useState([]);
    const [filteredPbNumbers, setFilteredPbNumbers] = useState([]);
    const [searchInProgress, setSearchInProgress] = useState(false);
    const [pbNotListed, setPbNotListed] = useState(false);
    const [selectedPbNumber, setSelectedPbNumber] = useState(null);
    const [hasFocus, setHasFocus] = useState(false);

    const resetAgentInfo = () => {
        if (setAgentInfo) {
            setAgentInfo(null);
        }
    };

    useEffect(() => {
        if (value && !pbNotListed) {
            (async () => {
                const {data} = await ServiceManager.customService('searchPbNumbers', [{filter: value}]);

                if (!data.length) {
                    setPbNotListed(true);
                }

                setRawData(data);
            })();
        }
    }, [value]);

    useEffect(() => {
        const pbNumbersFiltered = rawData.map(i => {
            const agentInfo = getAgentInfo(t, i?.agentName, i?.provisionReceiver);

            return {
                label: `${i.pbNumber} - ${agentInfo}`, value: i.pbNumber, name: agentInfo, vstNumber: String(i.vstNumber),
            };
        });

        setFilteredPbNumbers(pbNumbersFiltered.concat({
            label: t('portfolio.createNewPortfolio.agentInformation.pbNotSet'), value: -1, name: null, vstNumber: null,
        }));
    }, [rawData]);

    useEffect(() => {
        if (pbNotListed) {
            return;
        }

        const pbNumber = filteredPbNumbers.find(f => f.value === value);

        if (onVstNumberChange) {
            onVstNumberChange(pbNumber ? pbNumber.vstNumber : vstNumberValue);
        }

        setAgentInfo(pbNumber?.name);
        setSelectedPbNumber(pbNumber);
    }, [filteredPbNumbers, value, pbNotListed]);

    const searchPbNumbers = useCallback(debounce(async (val) => {
        try {
            setSearchInProgress(true);
            const {data} = await ServiceManager.customService('searchPbNumbers', [{filter: val}]);

            if (!val) {
                onSelect('');
                resetAgentInfo();
            }

            setRawData(data);
        } finally {
            setSearchInProgress(false);
        }
    }, 200), [pbNotListed]);

    const onSelectItem = (val) => {
        if (val > -1) {
            setPbNotListed(false);
            onSelect(val);

            return;
        }

        setSelectedPbNumber(null);
        onSelect('');
        setPbNotListed(true);
        resetAgentInfo();
        if (onVstNumberChange) {
            onVstNumberChange('');
        }
    };

    const onCustomPbNumberChange = (e) => {
        onSelect(e.target.value);
    };

    return (
        <>
            <div>
                {/* {label && <label htmlFor="pbNumber" className={`Form-control-label${required ? ' required' : ''}`}><span>{label}</span></label>} */}
                <AutoComplete
                    {...rest}
                    value={!pbNotListed ? value : ''}
                    type="text"
                    onSelect={onSelectItem}
                    className="pb-number"
                    dropdownClassName="search-dropdown"
                    dataSource={filteredPbNumbers}
                    filterOption
                    onSearch={searchPbNumbers}
                    externalSearch
                    isDropdownOpen={hasFocus}
                    placeholder={t('shadowAccount.depot.searchForPbNumber')}
                >
                    <TextInput
                        ref={innerRef}
                        data-cy="pbNumber"
                        label={label}
                        required={required}
                        id="pbNumber"
                        onFocus={() => setHasFocus(true)}
                        onBlur={() => setHasFocus(false)}
                        error={!pbNotListed ? error : null}
                        prefix={searchInProgress ? <Loader/> : <Icon type={ICON_TYPE_SEARCH} className="search-icon"/>}
                    />
                </AutoComplete>
                {pbNotListed && <TextInput data-cy="pbNumberCustom" type="number" className="pb-number custom" value={value} error={error} onChange={onCustomPbNumberChange}/>}

            </div>
            {(includeVstNumber && pbNotListed) && (
                <>
                    <span className="agent-name">{selectedPbNumber?.name}</span>
                    <TextInput
                        data-cy="vstNumber"
                        onChange={(e) => onVstNumberChange(e.target.value)}
                        value={vstNumberValue}
                        error={vstNumberError}
                        label={t('portfolio.createNewPortfolio.agentInformation.VSTNumber')}
                        required
                    />
                </>
            )}
        </>
    );
};

export default forwardRef((props, ref) => PbNumber({ ...props, innerRef: ref }));
