/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';
import {useTranslation} from 'react-i18next';
import {Button, Icon, Loader, Modal,} from 'ui-library';
import {ICON_JA_CONNECTION, ICON_PROXY_CONNECTION} from 'ui-library/components/Icon';

import {AccessPolicy, ProxyDetails} from '../types';
import {useConnections} from './ConnectionManager';
import './RemoveConnectionModal.css';
import {getExternalId} from '../../../utils';

type ConnectionModalProps = {
    connection: ProxyDetails;
}

export const RemoveProxyConnectionModal = () => {
    const {selectedProxyConnection} = useConnections();

    switch (selectedProxyConnection?.accessPolicy) {
        case AccessPolicy.Owner:
            return <RemoveJointAccountModal connection={selectedProxyConnection}/>;
        case AccessPolicy.PowerOfAttorney:
            return <RemovePowerOfAttorneyModal connection={selectedProxyConnection}/>;
        case AccessPolicy.Representative:
            return <RemoveRepresentativeModal connection={selectedProxyConnection}/>;
        default:
            return null;
    }
};

const RemoveJointAccountModal = ({
    connection,
}: ConnectionModalProps) => {
    // const connections = connection.connection.split(',');
    const {
        isLoading,
        discardConnectionRemove
    } = useConnections();

    const {t} = useTranslation();

    return (
        <Modal
            title={t('connections.removeConnectionModal.jointAccountTitle')}
            visible={!!connection}
            width={550}
            footer={(
                <div>
                    <Button type="primary" disabled={isLoading} onClick={() => {
                    }}>
                        {isLoading ? <Loader/> : t('connections.removeConnection.okLabel')}
                    </Button>
                    <Button type="secondary" disabled={isLoading} onClick={discardConnectionRemove}>
                        {t('connections.removeConnection.cancelLabel')}
                    </Button>
                </div>
            )}
        >
            <div className="remove_connection_modal">
                <span>{t('connections.removeConnectionModal.jointAccAreYouSure')}</span>
                <div className="box">
                    {/* @ts-ignore */}
                    <Icon type={ICON_JA_CONNECTION} size={24}/>
                    <span className="client_id">{getExternalId(connection.proxyId)}</span>
                    <p className="ja_content">
                        {/* {connections.map((name) => (
                            <span key={name}>
                                {name}
                                <br/>
                            </span>
                        ))} */}
                    </p>
                    <span className="ja_label">Joint Account</span>
                </div>
                <span className="warning">{t('connections.removeConnectionModal.warning')}</span>
            </div>
        </Modal>
    );
};

const RemovePowerOfAttorneyModal = ({
    connection,
}: ConnectionModalProps) => {
    const {
        isLoading,
        discardConnectionRemove,
        contactGroupDetails,
        onProxyConnectionDelete,
    } = useConnections();
    const {t} = useTranslation();

    return (
        <Modal
            title={t('connections.removeConnectionModal.proxyTitle')}
            visible={!!connection}
            width={550}
            footer={(
                <div>
                    <Button type="primary" disabled={isLoading} onClick={onProxyConnectionDelete}>
                        {isLoading ? <Loader/> : t('connections.removeConnection.okLabel')}
                    </Button>
                    <Button type="secondary" disabled={isLoading} onClick={discardConnectionRemove}>
                        {t('connections.removeConnection.cancelLabel')}
                    </Button>
                </div>
            )}
        >
            <div className="remove_connection_modal">
                <span>{t('connections.removeConnectionModal.proxyAreYouSure')}</span>
                <div className="box">
                    {/* @ts-ignore */}
                    <Icon type={ICON_PROXY_CONNECTION} size={24}/>
                    <span className="client_id">{getExternalId(connection.proxyId)}</span>
                    <p className="ja_content">{connection.proxyName}</p>
                </div>
                <span className="type_label">{t('connections.removeConnection.powerAttorneyLabel')}</span>
                <div className="box">
                    {/* @ts-ignore */}
                    <Icon type={ICON_JA_CONNECTION} size={24}/>
                    <span className="client_id">{contactGroupDetails?.externalId}</span>
                    <p className="ja_content">{Object.values(contactGroupDetails?.owners ?? {})
                        .join(' ')}</p>
                </div>
                <span className="warning">{t('connections.removeConnectionModal.undoneActionWarning')}</span>
            </div>
        </Modal>
    );
};

const RemoveRepresentativeModal = ({
    connection,
}: ConnectionModalProps) => {
    const {
        isLoading,
        discardConnectionRemove,
        contactGroupDetails,
        onProxyConnectionDelete,
    } = useConnections();
    const {t} = useTranslation();

    return (
        <Modal
            title={t('connections.removeConnectionModal.proxyTitle')}
            visible={!!connection}
            width={550}
            footer={(
                <div>
                    <Button type="primary" disabled={isLoading} onClick={onProxyConnectionDelete}>
                        {isLoading ? <Loader/> : t('connections.removeConnection.okLabel')}
                    </Button>
                    <Button type="secondary" disabled={isLoading} onClick={discardConnectionRemove}>
                        {t('connections.removeConnection.cancelLabel')}
                    </Button>
                </div>
            )}
        >
            <div className="remove_connection_modal">
                <span>{t('connections.removeConnectionModal.proxyAreYouSure')}</span>
                <div className="box">
                    {/* @ts-ignore */}
                    <Icon type={ICON_PROXY_CONNECTION} size={24}/>
                    <span className="client_id">{getExternalId(connection.proxyId)}</span>
                    <p className="ja_content">{connection.proxyName}</p>
                </div>
                <span className="type_label">{t('connections.removeConnection.parentalAuthorityLabel')}</span>
                <div className="box">
                    {/* @ts-ignore */}
                    <Icon type={ICON_PROXY_CONNECTION} size={24}/>
                    <span className="client_id">{contactGroupDetails?.externalId}</span>
                    <p className="ja_content">{Object.values(contactGroupDetails?.owners ?? {})
                        .join(' ')}</p>
                </div>
                <span className="warning">{t('connections.removeConnectionModal.warning')}</span>
            </div>
        </Modal>
    );
};
