import accounting from 'accounting';
import {
    defaultLineChartOptions, defaultGoalOptions, defaultDurationOptions,
} from './constants';
import { tooltipPositioner } from '../../../utils/tooltip';

export const getLineChartOptions = (
    data,
    dateFormat,
    valueFormat,
    goal,
    projection,
    tooltipFormat,
    xAxisFormat,
    yAxisFormat,
    height,
    xMin,
    ySoftMin,
    xTitle,
    yTitle,
    legendEnabled,
    lineChartLegendOptions,
    lineChartTooltipOptions,
    customXAxisFormat,
    isCustomClassRequired
) => {
    function formatter() {
        return `${tooltipFormat(this.point.y, this.point.x)}`;
    }

    function xAxisFormatter() {
        if (!this) return null;

        return customXAxisFormat(this);
    }

    function positioner(labelWidth, labelHeight, point) {
        return tooltipPositioner({
            labelWidth, labelHeight, point, chart: this.chart, yTitle,
        });
    }

    const getDateTimeLabelFormats = () => xAxisFormat || dateFormat;

    function getValueFormat() {
        const {
            symbol,
            precision,
            thousand,
            decimal,
            format,
        } = valueFormat;

        return accounting.formatMoney(this.value, symbol, precision, thousand, decimal, format);
    }

    let series = data.map(({ name, color, data, type }) => ({
        type: type || projection ? 'line' : 'area',
        name,
        data,
        color: color==='recommended' ? '#428351' : (color==='selected'? '#2167AE': color)
    }));

    if (goal.length > 0) {
        const goalOptions = { ...defaultGoalOptions, data: [goal] };
        const durationOptions = {
            ...defaultDurationOptions,
            data: [[-Infinity, 0], [goal[0], 0]],
        };

        series = [...series, { ...goalOptions }, { ...durationOptions }];
    }

    return {
        ...defaultLineChartOptions,
        chart: {
            ...defaultLineChartOptions.chart,
            height,
            marginTop: yTitle !== undefined && yTitle !== '' ? 30 : 0,
            styledMode: isCustomClassRequired
        },
        colors: ['#91C8F2','#DAD2BD'],
        xAxis: {
            ...defaultLineChartOptions.xAxis,
            title: {text:xTitle},
            dateTimeLabelFormats: getDateTimeLabelFormats(),
            min: xMin,
            labels: {
                formatter: customXAxisFormat !== null && xAxisFormatter,
            },
        },
        yAxis: {
            ...defaultLineChartOptions.yAxis,
            title: {text:yTitle},
            labels: { formatter: yAxisFormat !== null ? yAxisFormat : getValueFormat },
            softMin: ySoftMin,
        },
        legend: {  ...(lineChartLegendOptions ? lineChartLegendOptions : defaultLineChartOptions.legend),enabled: legendEnabled },
        tooltip: { ...(lineChartTooltipOptions ? lineChartTooltipOptions : defaultLineChartOptions.tooltip), formatter, positioner },
        series: [...series],
    };
};
