import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from '../../Checkbox';

function CellWithCheckbox({ value, onChecked }) {
    return (
        <span className="table-cell_with-checkbox">
            <Checkbox label="" checked={value} onChange={onChecked} />
        </span>
    );
}

CellWithCheckbox.propTypes = {
    value: PropTypes.bool.isRequired,
    onChecked: PropTypes.func,
};

CellWithCheckbox.defaultProps = {
    onChecked: null,
};

export default CellWithCheckbox;
