import React, {
    useEffect,
} from 'react';
import { getClientId } from 'utils';
import Preloader from 'components/Preloader';
import PersonalDetailsForm from './PersonalDetailsForm';
import usePersonalDetails from './usePersonalDetails';
import { errorModel } from './models/errorModel';

import 'ui-library/components/Button/Button.css';
import 'ui-library/components/DatePicker/DatePicker.css';
import 'ui-library/components/Label/Label.css';
import 'ui-library/components/TextInput/TextInput.css';
import './PersonalDetails.css';

const JAPersonalDetails = ({
    newPortfolio, language, setPageErrors, t, getFormattedDate, optionGender, optionCountry, radioButtonGroupValues, option, setJAData, dfsClientId, continueClicked,
}) => {
    const {
        data,
        productSettings,
        showEmploymentContinuationCheck,
        setErrorData,
        isLoading,
        editPersonalData,
        errorData,
        setData,
        onDateClick,
        passData,
        changeMobileMask,
        retiredRef,
        onRetiredButStillEmployedChange,
        occupationListOptions,
        handleSearchOccupations,
        searchInProgress,
        loadMoreItems,
        hasMoreOccupations,

    } = usePersonalDetails({
        newPortfolio, dfsClientId, language, setPageErrors, t, getFormattedDate, isJA: true,
    });

    useEffect(() => {
        const errors = errorModel({data, isDependantOnAffiliatedWithPensionFund: productSettings?.isDependantOnAffiliatedWithPensionFund, showEmploymentContinuationCheck});

        setJAData({clientId: getClientId(dfsClientId), data, errors});

        if (continueClicked) { setErrorData(errors); }
    }, [dfsClientId, data, productSettings?.isDependantOnAffiliatedWithPensionFund, continueClicked]);

    return (
        <div className="col-lg-6" style={{height: '100%'}}>
            <Preloader isLoading={isLoading}>
                <PersonalDetailsForm
                    data={data}
                    editPersonalData={editPersonalData}
                    errorData={errorData}
                    t={t}
                    optionCountry={optionCountry}
                    optionGender={optionGender}
                    setData={setData}
                    onDateClick={onDateClick}
                    passData={passData}
                    setErrorData={setErrorData}
                    changeMobileMask={changeMobileMask}
                    productSettings={productSettings}
                    radioButtonGroupValues={radioButtonGroupValues}
                    showEmploymentContinuationCheck={showEmploymentContinuationCheck}
                    retiredRef={retiredRef}
                    onRetiredButStillEmployedChange={onRetiredButStillEmployedChange}
                    occupationListOptions={occupationListOptions}
                    handleSearchOccupations={handleSearchOccupations}
                    searchInProgress={searchInProgress}
                    loadMoreItems={loadMoreItems}
                    hasMoreOccupations={hasMoreOccupations}
                    option={option}
                    getFormattedDate={getFormattedDate}
                    isJA
                />
            </Preloader>
        </div>
    );
};

export default JAPersonalDetails;
