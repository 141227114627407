import { useContext } from 'react';

import ClientDashboardContext from './context';

const useClientDashboard = () => {
    const context = useContext(ClientDashboardContext);

    if (context === undefined) {
        throw new Error('useClientDashboard can only be used inside ClientDashboardProvider');
    }

    return context;
};

export default useClientDashboard;
