import React, {useCallback, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useForm} from 'react-hook-form';
import { object, string} from 'yup';
import {
    Infobox, Loader, Modal, Textarea,
} from '../../../../../ui-library';
import {useYupValidationResolver} from '../../../../../hooks/useYupValidationResolver';

const AddClientNote = (props) => {
    const {t} = useTranslation();
    const {
        saveClientNotes, inProgress, error, setError,
    } = props;
    const [showModal, setShowModal] = useState(false);

    const schema = useMemo(() => object().shape({
        note: string().required(t('advisoryDashboard.clientNotes.noteRequired')),
    }), [t]);

    const resolver = useYupValidationResolver(schema);

    const {
        register, handleSubmit, formState: { errors}, setValue, watch,
    } = useForm({resolver});

    const {note} = watch();

    const handleShowModalClick = useCallback(() => {
        setShowModal(true);
    }, []);

    const handleDiscardNewNote = useCallback(() => {
        setError(null);
        setShowModal(false);
    }, []);

    const handleSaveNewNote = handleSubmit(async () => {
        await saveClientNotes(note);
        handleDiscardNewNote();
    });

    return (
        <>
            <button type="button" data-cy="addClientNote" className="button-link" onClick={handleShowModalClick}>{t('advisoryDashboard.clientNotes.addNote')}</button>
            {showModal && (
                <Modal
                    cancelButtonProps={{disabled: inProgress}}
                    okButtonProps={{disabled: inProgress}}
                    title={t('advisoryDashboard.clientNotes.addClientNote')}
                    visible
                    okText={inProgress ? <Loader /> : t('advisoryDashboard.clientNotes.save')}
                    cancelText={t('advisoryDashboard.clientNotes.cancel')}
                    onOk={handleSaveNewNote}
                    onCancel={handleDiscardNewNote}
                >
                    <Textarea
                        {...register('note')}
                        name="note"
                        onChange={(e) => setValue('note', e.target?.value)}
                        label={t('advisoryDashboard.clientNotes.enterNote')}
                        labelInfobox
                        error={errors.note?.message}
                    />
                    {error && <Infobox error><span>{error.toString()}</span></Infobox> }
                </Modal>
            )}
        </>
    );
};

export default AddClientNote;
