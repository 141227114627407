import { useCallback, useState } from 'react';
import ServiceManager from 'services/ServiceManager';
import handlerRequestCanceling from 'utils/handlerRequestCanceling';
import HandlerError from 'errors/HandlerError';

export const useSetChosenRisk = ({ clientId, withAccept = false }) => {
    const [isSending, setSending] = useState(false);
    const [error, setError] = useState(null);

    const setChosenRisk = useCallback((risk) => new Promise((resolve, reject) => {
        setSending(true);
        setError(null);

        ServiceManager.compliance('setChosenRisk', [clientId, risk.id])
            .then(() => {
                if (!withAccept) {
                    setSending(false);
                    resolve();
                } else {
                    try {
                        ServiceManager.compliance('acceptRisk', [clientId])
                            .then(() => {
                                try {
                                    setSending(false);
                                    resolve();
                                } catch (errAcceptRisk) {
                                    handlerRequestCanceling(() => {
                                        HandlerError({setError, setLoading: setSending});
                                        reject();
                                    })(errAcceptRisk);
                                }
                            })
                            .catch(handlerRequestCanceling(() => {
                                HandlerError({setError, setLoading: setSending});
                                reject();
                            }));
                    } catch (err) {
                        handlerRequestCanceling(() => {
                            HandlerError({setError, setLoading: setSending});
                            reject();
                        })(err);
                    }
                }
            })
            .catch(handlerRequestCanceling(() => {
                HandlerError({ setError, setLoading: setSending });
                reject();
            }));
    }), [clientId]);

    return { setChosenRisk, isSending, error };
};
