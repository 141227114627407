export const PARAGRAPH_TYPE_PRIMARY = 'primary';
export const PARAGRAPH_TYPE_TITLE = 'title';
export const PARAGRAPH_TYPE_HIGHLIGHTS = 'highlights';
export const PARAGRAPH_TYPE_SECONDARY = 'secondary';

export const PARAGRAPH_TYPES = [
    PARAGRAPH_TYPE_PRIMARY,
    PARAGRAPH_TYPE_HIGHLIGHTS,
    PARAGRAPH_TYPE_TITLE,
    PARAGRAPH_TYPE_SECONDARY,
];
