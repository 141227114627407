import {useEffect, useMemo, useState} from 'react';
import moment from 'moment/moment';
import {useGetContactGroup} from '../rest/useGetContactGroup';
import {useContact} from '../../domain/ClientService';

export const useContactGroupOwners = ({connectionGroupId}:{connectionGroupId?: number}) => {
    const {data} = useGetContactGroup({connectionGroupId});
    const { getContact } = useContact({});
    const [ownersWithAge, setOwnersWithAge] = useState<{ age: string; id: number; name: any; }[]>();

    const owners = useMemo(() => (
        data
            ? Object.keys(data?.owners)?.map(key => ({
                id: parseInt(key, 10),
                name: data?.owners?.[key],
            }))
            : undefined),
    [data]);

    const sortedOwners = useMemo(() => owners?.sort((a, b) => (a.id - b.id)), [owners]);

    useEffect(() => {
        async function getData() {
            if (sortedOwners) {
                const res = await Promise.all(sortedOwners.map(async (item) => {
                    const clientData = await getContact({contactId: item.id});
                    const userClientAge = moment(moment()
                        .format('YYYY-MM-DD'))
                        .diff(moment(clientData?.details?.personalInformation?.dateOfBirth), 'years', true);

                    return {...item, age: String(Math.floor(userClientAge))};
                }));

                setOwnersWithAge(res);
            }
        }

        getData();
    }, [sortedOwners]);

    return {
        mainOwner: ownersWithAge?.[0],
        jaClient: ownersWithAge?.[1],
    };
};
