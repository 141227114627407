import React, { useCallback, useState } from 'react';
import { KYCPages } from './constants';
import KYCPage from './KYCPage';
import KYCDocs from './KYCDocs';

export const KYCMain = () => {
    const [currentStep, setCurrentStep] = useState(KYCPages.KYCForm);

    const onNextStep = useCallback(() => {
        setCurrentStep(KYCPages.KYCDocs);
    }, [currentStep]);

    const onPrevStep = useCallback(() => {
        setCurrentStep(KYCPages.KYCForm);
    }, []);

    switch (currentStep) {
        case KYCPages.KYCDocs:
            return (<KYCDocs onPrevStep={onPrevStep}/>);

        default:
            return (<KYCPage onNextStep={onNextStep}/>);
    }

    return (<KYCPage />);
};
