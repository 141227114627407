export const ICON_TYPE_TOOLTIP = 'questionmark';
export const ICON_TYPE_VERIFIED = 'verified';
export const ICON_TYPE_ERROR = 'error';
export const ICON_TYPE_WARNING = 'warning';
export const ICON_TYPE_INFOBOX = 'infobox';
export const ICON_TYPE_ARROW = 'arrow';
export const ICON_TYPE_RADIO = 'radio';
export const ICON_TYPE_DROPDOWN = 'dropdown';
export const ICON_TYPE_TWITTER = 'twitter';
export const ICON_TYPE_FACEBOOK = 'facebook';
export const ICON_TYPE_LINKEDIN = 'linkedin';
export const ICON_TYPE_CLOSE = 'close';
export const ICON_TYPE_CLOSE_SLIM = 'close-slim';
export const ICON_TYPE_MENU = 'menu';
export const ICON_TYPE_LOGIN = 'login';
export const ICON_TYPE_LOGIN_CLIENT = 'login-client';
export const ICON_TYPE_DELAY = 'delay';
export const ICON_TYPE_HISTORY = 'history';
export const ICON_TYPE_PLUS = 'plus';
export const ICON_TYPE_PLUS_MENU = 'plus-menu';
export const ICON_TYPE_PLUS_BUTTON = 'plus-button';
export const ICON_TYPE_PLUS_SLIM = 'plus-button-slim';
export const ICON_TYPE_LETTER = 'letter';
export const ICON_TYPE_MINUS = 'minus';
export const ICON_TYPE_MINUS_BUTTON = 'minus-button';
export const ICON_TYPE_CHART_LINE = 'chart-line';
export const ICON_TYPE_CHART_ARROW = 'chart-arrow';
export const ICON_TYPE_CHART_PIE = 'chart-pie';
export const ICON_TYPE_CALENDAR = 'calendar';
export const ICON_TYPE_CHART_BAR_HORIZONTAL = 'chart-bar-horizontal';
export const ICON_TYPE_CHART_BAR_VERTICAL = 'chart-bar-vertical';
export const ICON_TYPE_LIST = 'list';
export const ICON_TYPE_BELL = 'bell';
export const ICON_TYPE_BELL_FILLED = 'bell-filled';
export const ICON_TYPE_ENVOLOPE = 'envolope';
export const ICON_TYPE_ENVELOPE = 'envelope';
export const ICON_TYPE_SKYPE = 'skype';
export const ICON_TYPE_PHONE_CALL = 'phone-call';
export const ICON_TYPE_EXIT = 'exit';
export const ICON_TYPE_ACTION = 'action';
export const ICON_TYPE_COLUMNS = 'columns';
export const ICON_TYPE_SEARCH = 'search';
export const ICON_TYPE_PREV = 'prev';
export const ICON_TYPE_NEXT = 'next';
export const ICON_TYPE_LOCKED = 'locked';
export const ICON_TYPE_UNLOCKED = 'unlocked';
export const ICON_TYPE_SUCCESS = 'success';
export const ICON_TYPE_ARROW_DOWN = 'arrow-down';
export const ICON_TYPE_ARROW_UP = 'arrow-up';
export const ICON_TYPE_ARROW_LEFT = 'arrow-left';
export const ICON_TYPE_ARROW_RIGHT = 'arrow-right';
export const ICON_TYPE_ARROW_UP_DOWN = 'arrow-up-down';
export const ICON_TYPE_CLIENTS = 'clients';
export const ICON_TYPE_PROSPECTS = 'prospects';
export const ICON_TYPE_TOTAL_AUM = 'total-aum';
export const ICON_TYPE_DOCUMENT = 'document';
export const ICON_TYPE_NEW_DOCUMENT = 'new-document';
export const ICON_TYPE_CHECK_FILLED = 'check-filled';
export const ICON_TYPE_ELLIPSIS = 'ellipsis';
export const ICON_TYPE_EDIT = 'edit';
export const ICON_TYPE_FORM = 'form';
export const ICON_TYPE_CLOSE_BUTTON = 'close-button';
export const ICON_TYPE_REPLY = 'reply';
export const ICON_TYPE_SAVE = 'save';
export const ICON_TYPE_DELETE = 'delete';
export const ICON_TYPE_SYNC= 'sync';
export const ICON_TYPE_REMOVE = 'remove';
export const ICON_PROXY_CONNECTION = 'proxy-connection';
export const ICON_JA_CONNECTION = 'ja-connection';
export const ICON_INFO_OUTLINE = 'info-outline';
export const ICON_DOCS_OUTLINE = 'docs-outline';
export const ICON_DOWNLOAD = 'download';
export const ICON_LONG_ARROW_RIGHT = 'long-arrow-right';
export const ICON_EDIT_OUTLINE = 'edit-outline';
export const ICON_TYPE_OPEN = 'open';
export const ICON_TYPE_BIN = 'bin';
export const ICON_INFO_SLIM = 'info-slim';
export const ICON_EDIT_SLIM = 'edit-slim';
export const ICON_EDIT_MINIMAL = 'edit-minimal';
export const ICON_CHECK_SLIM_OUTLINE = 'check-slim-outline';
export const ICON_FULLSCREEN_SLIM = 'fullscreen-slim';
export const ICON_DOCUMENT_OUTLINE = 'icon-documents-outline';
export const ICON_ALERT_CIRCLE_SOLID = 'alert-circle-solid';
export const ICON_TRIANGLE_OUTLINE = 'alert-triangle-outline';
export const ICON_TRIANGLE_OUTLINE_GRAY = 'alert-triangle-outline-gray';
export const ICON_CHECK_CIRCLE_SOLID = 'check-circle-solid';
export const ICON_DOCUMENT_LOADING = 'icon-document-loading';
export const ICON_DOCUMENT_RE_UPLOAD = 'icon-document-re-upload';
export const ICON_DOWNLOAD_OUTLINE = 'icon-download-outline';
export const ICON_PEN_LEFT = 'icon-pen-left';
export const ICON_TRY_AGAIN = 'icon-try-again';
export const ICON_ARROW_RIGHT_TRENDY = 'arrow-right-trendy';

export const ICON_TYPES = [
    ICON_TYPE_TOOLTIP,
    ICON_TYPE_VERIFIED,
    ICON_TYPE_ERROR,
    ICON_TYPE_WARNING,
    ICON_TYPE_INFOBOX,
    ICON_TYPE_ARROW,
    ICON_TYPE_RADIO,
    ICON_TYPE_DROPDOWN,
    ICON_TYPE_TWITTER,
    ICON_TYPE_FACEBOOK,
    ICON_TYPE_LINKEDIN,
    ICON_TYPE_CLOSE,
    ICON_TYPE_CLOSE_SLIM,
    ICON_TYPE_MENU,
    ICON_TYPE_LOGIN,
    ICON_TYPE_LOGIN_CLIENT,
    ICON_TYPE_DELAY,
    ICON_TYPE_PLUS,
    ICON_TYPE_PLUS_MENU,
    ICON_TYPE_PLUS_BUTTON,
    ICON_TYPE_PLUS_SLIM,
    ICON_TYPE_LETTER,
    ICON_TYPE_MINUS,
    ICON_TYPE_MINUS_BUTTON,
    ICON_TYPE_CHART_LINE,
    ICON_TYPE_CHART_ARROW,
    ICON_TYPE_CHART_PIE,
    ICON_TYPE_CHART_BAR_HORIZONTAL,
    ICON_TYPE_CHART_BAR_VERTICAL,
    ICON_TYPE_LIST,
    ICON_TYPE_BELL,
    ICON_TYPE_BELL_FILLED,
    ICON_TYPE_ENVOLOPE,
    ICON_TYPE_ENVELOPE,
    ICON_TYPE_SKYPE,
    ICON_TYPE_PHONE_CALL,
    ICON_TYPE_EXIT,
    ICON_TYPE_ACTION,
    ICON_TYPE_COLUMNS,
    ICON_TYPE_SEARCH,
    ICON_TYPE_PREV,
    ICON_TYPE_NEXT,
    ICON_TYPE_LOCKED,
    ICON_TYPE_UNLOCKED,
    ICON_TYPE_SUCCESS,
    ICON_TYPE_ARROW_DOWN,
    ICON_TYPE_ARROW_UP,
    ICON_TYPE_ARROW_LEFT,
    ICON_TYPE_ARROW_RIGHT,
    ICON_TYPE_CLIENTS,
    ICON_TYPE_PROSPECTS,
    ICON_TYPE_TOTAL_AUM,
    ICON_TYPE_DOCUMENT,
    ICON_TYPE_NEW_DOCUMENT,
    ICON_TYPE_CHECK_FILLED,
    ICON_TYPE_ELLIPSIS,
    ICON_TYPE_EDIT,
    ICON_TYPE_FORM,
    ICON_TYPE_CLOSE_BUTTON,
    ICON_TYPE_REPLY,
    ICON_TYPE_SAVE,
    ICON_TYPE_DELETE,
    ICON_TYPE_HISTORY,
    ICON_TYPE_SYNC,
    ICON_TYPE_REMOVE,
    ICON_PROXY_CONNECTION,
    ICON_JA_CONNECTION,
    ICON_INFO_OUTLINE,
    ICON_DOCS_OUTLINE,
    ICON_DOWNLOAD,
    ICON_LONG_ARROW_RIGHT,
    ICON_EDIT_OUTLINE,
    ICON_TYPE_OPEN,
    ICON_TYPE_BIN,
    ICON_INFO_SLIM,
    ICON_EDIT_SLIM,
    ICON_EDIT_MINIMAL,
    ICON_CHECK_SLIM_OUTLINE,
    ICON_FULLSCREEN_SLIM,
    ICON_DOCUMENT_OUTLINE,
    ICON_ALERT_CIRCLE_SOLID,
    ICON_TRIANGLE_OUTLINE,
    ICON_TRIANGLE_OUTLINE_GRAY,
    ICON_CHECK_CIRCLE_SOLID,
    ICON_DOCUMENT_LOADING,
    ICON_DOCUMENT_RE_UPLOAD,
    ICON_DOWNLOAD_OUTLINE,
    ICON_PEN_LEFT,
    ICON_TRY_AGAIN,
    ICON_ARROW_RIGHT_TRENDY
];
