import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {useParams} from 'react-router-dom';
import {useSinglePortfolio} from 'pages/ClientOverview/pages/Portfolios/pages/SinglePortfolioView/context';
import InventoryTable from './components/InventoryTable/InventoryTable';
import Card from '../../../../../../../../components/Card/Card';
import { getClientId } from '../../../../../../../../utils';
import TransactionsTable from '../Details/components/TransactionsTable/TransactionsTable';

import '../../styles/Table-ext.css';

const Inventory = () => {
    const { dfsClientId, portfolioId } = useParams();
    const clientId = useMemo(() => (getClientId(dfsClientId)), [dfsClientId]);
    const {t} = useTranslation();
    const { migrationDate} = useSinglePortfolio();

    return (
        <div className="container">
            <div className="fi-verticals">
                <Card title={t('portfolio.details.positionTitle')}>
                    <InventoryTable clientId={clientId} portfolioId={portfolioId}/>
                    {migrationDate && <span className="note">{`*${t('portfolio.positionDetails.priceNote', {date: migrationDate})}`}</span>}
                </Card>

                <Card title={t('shadowAccount.transactions.title')}>
                    <TransactionsTable portfolioId={portfolioId} clientId={clientId}/>
                </Card>
            </div>
        </div>
    );
};

export default Inventory;
