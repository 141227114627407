import ErrorsManager from './ErrorsManager';
import { ADAPTOR_ERROR } from './Error';

class AdapterError extends Error {
    constructor(error, messageCustom) {
        super(error);
        Error.captureStackTrace(this, AdapterError);

        return ErrorsManager.createError({
            type: ADAPTOR_ERROR,
            error,
            messageCustom,
        });
    }
}

export default AdapterError;
