import React, {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { getImageMime } from 'utils';
import ButtonsBlockRow from 'components/ButtonsBlockRow';
import {object, string} from 'yup';
import {useForm} from 'react-hook-form';
import {useNavigate} from 'react-router-dom';
import Infobox from '../../ui-library/components/Infobox';
import {
    Column, ContentBox, Title, Row, Modal, FileInput, Button, TextInput, notification,
} from '../../ui-library';
import { useManagerProfile } from '../../prodivers/managerProfile';
import PageSection from '../../components/PageSection';
import Preloader from '../../components/Preloader';
import { adaptPictureFile } from './adaptors/adaptPictureFile';
import { memberIdSelector } from '../../redux-store/auth/authSelectors';
import { useChangeProfilePicture } from './hooks/useChangeProfilePicture';
import './EditProfile.css';
import Avatar from '../../ui-library/components/Avatar';
import {useYupValidationResolver} from '../../hooks/useYupValidationResolver';
import FieldControl from '../../components/FieldControl/FieldControl';
import ServiceManager from '../../services/ServiceManager';

const EditProfile = () => {
    const {
        data, isLoading, error, getMemberInfo, memberInfo,
    } = useManagerProfile();
    const { t } = useTranslation();
    const memberId = useSelector(memberIdSelector);
    const navigate = useNavigate();

    const [uploadParams, setUploadParams] = useState(null);
    const [uploadError, setUploadError] = useState(null);
    const [sizeError, setSizeError] = useState(false);
    const [extensionError, setExtensionError] = useState(false);

    const onPictureChange = useCallback(async (files) => {
        if (files.length === 0) {
            setUploadParams(null);

            return;
        }
        try {
            const fileParams = await adaptPictureFile(files);

            setUploadParams(fileParams);
            setSizeError(fileParams.size >= 2097152);
            setExtensionError(!['jpg', 'png', 'jpeg'].includes(fileParams.extension?.toLowerCase()));
        } catch (err) {
            setUploadError(err);
        }
    }, []);

    useEffect(() => {
        (async () => getMemberInfo())();
    }, [getMemberInfo]);

    const schema = useMemo(() => object().shape({
        phoneNumber: string().matches(/^[+][(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,7}$/, t('onboardingFlow.personalPage.validationPhoneNumber')).required(t('validation.mandatoryField')),
        address: string().required(t('validation.mandatoryField')),
        city: string().required(t('validation.mandatoryField')),
        zip: string().required(t('validation.mandatoryField')),
    }), [t]);

    const resolver = useYupValidationResolver(schema);

    const {
        register, handleSubmit, formState: {errors}, setValue, watch, reset,
    } = useForm({
        resolver,
        defaultValues: {
            phoneNumber: '',
            address: '',
            city: '',
            zip: '',
        },
    });

    useEffect(() => {
        reset({
            phoneNumber: memberInfo?.phoneNumber,
            address: memberInfo?.streetAddress,
            city: memberInfo?.city,
            zip: memberInfo?.postalCode,
        });
    }, [memberInfo]);

    const {
        phoneNumber, address, city, zip,
    } = watch();

    const profilePicture = useMemo(() => {
        if (uploadParams?.ImageContentBase64?.length === 0) {
            return null;
        }
        const currentImage = data?.hasProfilePicture ? data?.image : null;

        const newImage = uploadParams?.ImageContentBase64
            ? `data:${getImageMime(uploadParams?.ImageContentBase64)};base64,${uploadParams?.ImageContentBase64}` : null;

        return newImage || currentImage;
    },
    [data?.hasProfilePicture, data?.image, uploadParams?.ImageContentBase64, getImageMime]);

    const {
        changeMemberProfilePicture,
        error: errorChangeProfilePicture,
        isLoading: isLoadingChangeProfilePicture,
        deleteMemberProfilePicture,
    } = useChangeProfilePicture();

    const onSave = handleSubmit(async (formData) => {
        if (sizeError || extensionError) {
            return;
        }

        Modal.confirm({
            title: t('editProfile.saveChangesTitle'),
            content: t('editProfile.saveChangesTitleContent'),
            okText: t('editProfile.yes'),
            cancelText: t('editProfile.no'),
            onOk: async () => {
                await ServiceManager.customMembersService('saveMemberInfo', [{
                    memberId,
                    streetAddress: formData.address,
                    postalCode: formData.zip,
                    city: formData.city,
                    phoneNumber: formData.phoneNumber,
                }]);
                if (uploadParams?.ImageContentBase64 !== undefined) {
                    if (uploadParams?.ImageContentBase64?.length === 0) {
                        await deleteMemberProfilePicture(memberId);
                    } else {
                        await changeMemberProfilePicture(memberId,
                            {ImageContentBase64: uploadParams.ImageContentBase64});
                    }
                }
                notification.open({content: t('editProfile.successfullySaved'), type: 'success'});
            },
        });
    });

    const onPhoneNumberChange = useCallback((e) => {
        const phoneValue = e.target?.value;

        if (phoneValue && !phoneValue.startsWith('+')) {
            setValue('phoneNumber', `+${phoneValue}`);
        } else {
            setValue('phoneNumber', phoneValue);
        }
    }, []);

    return (
        <PageSection>
            <Preloader
                isLoading={isLoading}
                error={error || uploadError || errorChangeProfilePicture}
            >
                <ContentBox className="edit-profile">
                    <Title type={2} className="underlined with-mb">{t('editProfile.title')}</Title>
                    <Row>
                        <Column size={['lg-2', 'md-3', 'sm-12']}>
                            <Avatar
                                name={data?.firstName}
                                src={profilePicture}
                                size="large"
                            />
                            <div className="upload-buttons">
                                <FileInput
                                    accept=".jpg, .png, .jpeg"
                                    uploadText={t('editProfile.upload')}
                                    onChange={onPictureChange}
                                    buttonType="primary"
                                    showFileList={false}
                                />
                                <Button
                                    type="danger"
                                    size="small"
                                    onClick={() => setUploadParams({ ImageContentBase64: '' })}
                                >
                                    {t('editProfile.delete')}
                                </Button>
                            </div>
                        </Column>
                        <Column className="form-fields" size={['lg-5', 'md-6', 'sm-12']}>
                            <FieldControl
                                label={t('editProfile.fullName')}
                                value={data?.name}
                            />
                            <FieldControl
                                label={t('editProfile.email')}
                                value={memberInfo?.email}
                            />
                            <FieldControl
                                label={t('editProfile.role')}
                                value={memberInfo?.role}
                            />
                            <FieldControl
                                label={t('editProfile.personalNo')}
                                value={memberInfo?.description || '-'}
                            />
                            <TextInput
                                {...register('phoneNumber')}
                                name="phoneNumber"
                                label={t('editProfile.phone')}
                                type="tel"
                                value={phoneNumber}
                                onChange={onPhoneNumberChange}
                                error={errors.phoneNumber?.message}
                            />
                            <TextInput
                                {...register('address')}
                                name="address"
                                label={t('editProfile.address')}
                                value={address}
                                onChange={(e) => setValue('address', e.target?.value)}
                                error={errors.address?.message}
                            />
                            <TextInput
                                {...register('zip')}
                                name="zip"
                                label={t('editProfile.zip')}
                                value={zip}
                                onChange={(e) => setValue('zip', e.target?.value)}
                                error={errors.zip?.message}
                            />
                            <TextInput
                                {...register('city')}
                                name="city"
                                label={t('editProfile.city')}
                                value={city}
                                onChange={(e) => setValue('city', e.target?.value)}
                                error={errors.city?.message}
                            />
                        </Column>
                        <Column size={['sm-12']}>
                            {sizeError && <Infobox error>{t('editProfile.profilePicture.sizeError')}</Infobox>}
                            {extensionError && <Infobox error>{t('editProfile.profilePicture.extensionError')}</Infobox>}
                        </Column>
                    </Row>
                    <ButtonsBlockRow
                        additionalButton={{
                            text: t('clientDetails.cancel'),
                            onClick: () => { navigate(-1); },
                        }}
                        primaryButton={{
                            text: t('clientDetails.save'),
                            loading: isLoadingChangeProfilePicture,
                            onClick: onSave,
                        }}
                    />
                </ContentBox>
            </Preloader>
        </PageSection>
    );
};

EditProfile.propTypes = {
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
        hash: PropTypes.string,
    }).isRequired,
};

export default EditProfile;
