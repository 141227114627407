import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { createUniqueKey, getClassNames } from '../../../utils';
import {
    getHeight,
    getXAxis,
    checkData,
    getLabels,
    buildSingleDataArrays,
    getLabelWidth,
    getLegendCoordinates,
} from './utils';
import ChartItem from './ChartItem';
import ChartLabels from './ChartLabels';
import ChartLegend from './ChartLegend';
import withTheme from '../../../hocs/withTheme';
import './BandwidthChart.css';

function BandwidthChart(props) {
    const {
        data, labelFormat, numberFormat, labelWidth, className, labelNew, labelCurrent,
    } = props;
    const hasNew = checkData(data);
    const { height, chartHeight } = getHeight(data, hasNew);
    const [width, setWidth] = useState(0);
    const labels = getLabels(data, labelFormat);
    const labelSize = getLabelWidth(labelWidth, width);
    const dataArray = buildSingleDataArrays(
        data, width, labels, labelSize, labelFormat, numberFormat,
    );
    const xAxis = getXAxis(labelSize, chartHeight);
    const legendCoordinates = getLegendCoordinates(
        width, chartHeight, labelNew, labelCurrent,
    );
    const refCallback = (element) => {
        if (!width && element) {
            setWidth(element.getBoundingClientRect().width);
        }
    };

    return (
        <div className={getClassNames(className, 'BandwidthChart-container')} ref={refCallback}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={width}
                height={height}
                version="1.1"
                className="chart-root"
                viewBox={`0 0 ${width} ${height}`}
            >
                <path d={xAxis} className="chart-xaxis-line" />
                {dataArray.map((item, index) => (
                    <ChartItem
                        key={createUniqueKey(index, item.label)}
                        data={item}
                        index={index}
                    />
                ))}
                {hasNew && (
                    <ChartLegend
                        data={legendCoordinates}
                        labelNew={labelNew}
                        labelCurrent={labelCurrent}
                    />
                )}
            </svg>
            <ChartLabels labels={labels} />
        </div>
    );
}

BandwidthChart.propTypes = {
    /** Data to be shown on chart */
    data: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        data: PropTypes.shape({
            curr: PropTypes.number.isRequired,
            new: PropTypes.number,
        }).isRequired,
        range: PropTypes.shape({
            min: PropTypes.number.isRequired,
            max: PropTypes.number.isRequired,
        }).isRequired,
    })),
    /** Width for label of chart (default 150px) */
    labelWidth: PropTypes.number,
    /** Formats of labels */
    labelFormat: PropTypes.func,
    /** Formats of numbers (without percent) */
    numberFormat: PropTypes.func,
    /** Label for current value */
    labelCurrent: PropTypes.string,
    /** Label for new value */
    labelNew: PropTypes.string,
    className: PropTypes.string,
};

BandwidthChart.defaultProps = {
    data: [],
    labelNew: '',
    className: '',
    labelWidth: 150,
    labelCurrent: '',
    labelFormat: (name) => name,
    numberFormat: (number) => number,
};

export default withTheme(BandwidthChart);
