import React from 'react';
import PropTypes from 'prop-types';
import {createUniqueKey} from '../../../utils';

function Legend(props) {
    const {
        legend, singleAllocation, numberFormat, hideValues,
    } = props;

    return (
        <dl className={`legend ${singleAllocation ? 'legend-single-item' : ''}`}>
            {
                legend.map(
                    (item, index) => (
                        <div className="legend-item" key={createUniqueKey(index, item.label)}>
                            <dt className="legend-name">
                                {!singleAllocation && <div className="legend-point"/>}
                                {item.label}
                            </dt>
                            {!hideValues && (
                                <dd className="legend-value">
                                    {item.percent}
                                </dd>
                            )}
                        </div>
                    ),
                )
            }
        </dl>
    );
}

Legend.propTypes = {
    /** Data to be shown on legend */
    legend: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string.isRequired,
        percent: PropTypes.string,
        value: PropTypes.number.isRequired,
    })),
    /** Single allocation view */
    singleAllocation: PropTypes.bool,
    numberFormat: PropTypes.func,
    hideValues: PropTypes.bool,
};

Legend.defaultProps = {
    legend: [],
    singleAllocation: false,
    numberFormat: (num) => (num.toLocaleString('en-US', {minimumFractionDigits: 2})),
    hideValues: false,
};

export default Legend;
