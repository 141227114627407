import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import FormControlMessage from '../FormControlMessage';
import FormGroup from '../FormGroup';
import Label from '../Label';
import './Textarea.css';
import withTheme from '../../hocs/withTheme';

/** Always use labels on text areas and don’t depend on the placeholder text.
 * If there is more information for the text area needed make
 * use of a paragraph on top or use an info box. */

function Textarea(props) {
    const {
        label, labelInfobox, disabled, required, error, children, helpText, innerRef: ref, ...rest
    } = props;

    return (
        <FormGroup disabled={disabled} error={error}>
            {
                label && (
                    <Label
                        htmlFor={label}
                        label={label}
                        labelInfobox={labelInfobox}
                        required={required}
                        helpText={helpText}
                    />
                )
            }
            <textarea
                {...rest}
                id={label}
                ref={ref}
                className="Textarea"
                disabled={disabled}
                required={required}
            />

            {error && (
                <FormControlMessage>{error}</FormControlMessage>
            )}

            {children && <p>{children}</p>}
        </FormGroup>
    );
}

Textarea.propTypes = {
    /** The label text */
    label: PropTypes.string,
    /** The text displayed if the label tooltip exist */
    labelInfobox: PropTypes.bool,
    /** Whether the input is disabled */
    disabled: PropTypes.bool,
    /** If required field* */
    required: PropTypes.bool,
    /** The error message displayed if the input is errored */
    error: PropTypes.string,
    /** @ignore */
    defaultValue: PropTypes.string,
    /** @ignore */
    children: PropTypes.node,
    /** The text displayed if the label tooltip exist */
    helpText: PropTypes.bool,
    /** @ignore */
    innerRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({
            current: PropTypes.objectOf,
        }),
    ]),
};

Textarea.defaultProps = {
    label: null,
    labelInfobox: false,
    disabled: false,
    required: false,
    error: null,
    defaultValue: null,
    children: null,
    helpText: null,
    innerRef: null,
};

export default forwardRef((props, ref) => withTheme(Textarea)({ ...props, innerRef: ref }));
