import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Button } from 'ui-library';

export const ModalLogout = ({
    onContinue, onTimeOver, visible, dialogCountdown = 10,
}) => {
    const timer = useRef();
    const [remainSeconds, setRemainSeconds] = useState(dialogCountdown);
    const { t } = useTranslation();

    const stopTimer = () => {
        if (timer.current) clearInterval(timer.current);
    };
    const startTimer = () => {
        timer.current = setInterval(() => {
            setRemainSeconds((seconds) => {
                if (seconds <= 0) {
                    stopTimer();
                    onTimeOver();

                    return 0;
                }

                return seconds - 1;
            });
        }, 1000);
    };

    useEffect(() => {
        if (visible) {
            setRemainSeconds(dialogCountdown);
            startTimer();
        }

        return () => stopTimer();
    }, [visible]);

    return (
        <div>
            <Modal
                type="warning"
                className="auto-logout"
                keyboard={false}
                title={t('session.title')}
                visible={visible}
                footer={(
                    <div>
                        <Button type="primary" onClick={onContinue}>{t('session.button')}</Button>
                    </div>
                )}
            >

                {t('session.content')}
                <br />
                <br />
                {`${t('session.remainingTime')}: `}
                {`${remainSeconds} ${t('session.seconds')}`}

            </Modal>
        </div>
    );
};
