import Service from 'additiv-services/services/core/service';
import { getServicesUrl } from 'additiv-services/setup';
import { getQueryStringByObject } from 'additiv-services/utils/queryUtils';

export class PortfolioManagement extends Service {
    static getModelPortfolioAllocations(modelPortfolioId, params) {
        const queryString = getQueryStringByObject({
            ...params,
            includeLookThrough: 'true',
        });

        return Service.getRequest(
            `${getServicesUrl()}/model-portfolios/${modelPortfolioId}/allocations?${queryString}`,
            { isSecure: true, withCancel: true },
        );
    }

    static getAllocations(data) {
        return Service.postRequest(
            `${getServicesUrl()}/securities`,
            data,
            { isSecure: true },
        );
    }

    static getModelPortfolioDetails(modelPortfolioId, params) {
        const queryString = getQueryStringByObject(params);

        return Service.getRequest(
            `${getServicesUrl('v1.2')}/model-portfolios/${modelPortfolioId}?${queryString}`,
            { isSecure: true, withCancel: true },
        );
    }

    static getAssetClassTree(language) {
        return Service.getRequest(`${getServicesUrl()}/assetclasses/${language}`, {isSecure: true, withCancel: true});
    }
}
