import Service from '../../additiv-services';
import {CUSTOM_SERVICE_LAYER_SERVICES, getCustomServiceLayerBaseUrl} from '../constants';

class CustomCommonService extends Service {
    static #baseServiceUrl = getCustomServiceLayerBaseUrl(CUSTOM_SERVICE_LAYER_SERVICES.COMMON);

    static getProductsConfiguration({language, contactId}) {
        let queryString = `language=${language}`;

        if (contactId) {
            queryString += `&contactId=${contactId}`;
        }

        return Service.getRequest(
            `${this.#baseServiceUrl}/campaign/configuration?${queryString}`,
            { isSecure: true, cache: true },
        );
    }

    static infraTest({timeout}) {
        return Service.getRequest(`${this.#baseServiceUrl}/testDelay?seconds=${timeout}`, {isSecure: true});
    }
}

export default CustomCommonService;
