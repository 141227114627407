import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import Preloader from 'components/Preloader';
import {getClassNames} from 'utils';

import './ProductOffers.css';
import {ProductOffersSelection} from '../../components/product-offers-selection';
import {ThreeBGroupsModal} from '../../components/three-b-groups-modal/ThreeBGroupsModal';

export const ProductOffers = ({datasource}) => {
    const {
        clientId,
        isLoading,
        pageErrors,
        groupedProducts,
        isFzAndFzpAlreadyApplied,
        infoLabelData,
        selectedProductId,
        setSelectedProductId,
        contactGroups,
        onThreeBConnectionGroupSelected,
        showThreeBGroupsModal,
        setShowThreeBGroupsModal,
        onChangeInvestorProfileClient,
    } = datasource();

    const { t } = useTranslation();

    const infoLabel = useMemo(() => {
        if (infoLabelData) {
            return <div className={`info-label ${infoLabelData.isError ? 'error' : 'warning'}`}>{infoLabelData.message ? t(infoLabelData.message) : ''}</div>;
        }

        return null;
    }, [infoLabelData]);

    return (
        <div className="new-portfolio">
            <Preloader
                isLoading={isLoading}
                error={pageErrors}
            >
                <div>
                    <div className={getClassNames('new-portfolio-content')}>
                        {infoLabel}
                        <ProductOffersSelection
                            isFzAndFzpAlreadyApplied={isFzAndFzpAlreadyApplied}
                            productOffers={groupedProducts}
                            selectProductOfferId={selectedProductId}
                            onSelectedProductOfferChange={(id) => setSelectedProductId(id)}
                        />
                    </div>
                </div>
            </Preloader>
            {showThreeBGroupsModal && <ThreeBGroupsModal clientId={clientId} show={showThreeBGroupsModal} onClose={() => setShowThreeBGroupsModal(false)} groups={contactGroups || []} onSelect={onThreeBConnectionGroupSelected} onChangeInvestorProfileClient={onChangeInvestorProfileClient}/>}
        </div>
    );
};
