import React from 'react';
import PropTypes from 'prop-types';
import Button from '../Button';
import ModalCore from './core/ModalCore';
import { prefixCls } from './constants';
import './Modal.css';
import withTheme from '../../hocs/withTheme';
import classNames from 'classnames';

function Modal(props) {
    const {
        footer,
        visible,
        wrapClassName,
        width,
        cancelButtonProps,
        okButtonProps,
        okText,
        cancelText,
        closeIcon = null,
        closable = false,
        borderRadius = 0,
        className,
        buttonsStreched = true,
        ...restProps
    } = props;

    const classes = classNames(className, {
        [`has-radius`]: borderRadius
    })

    const handleCancel = (e) => {
        const { onCancel } = props;

        if (typeof onCancel === 'function') {
            onCancel(e);
        }
    };

    const handleOk = (e) => {
        const { onOk } = props;

        if (typeof onOk === 'function') {
            onOk(e);
        }
    };

    const renderFooter = () => {
        const { okType, confirmLoading } = props;

        return (
            <div className='modal-footer--button-group'>
                {cancelText && (
                    <Button onClick={handleCancel} {...cancelButtonProps}>
                        {cancelText}
                    </Button>
                )}
                <Button
                    type={okType}
                    loading={confirmLoading}
                    onClick={handleOk}
                    {...okButtonProps}
                >
                    {okText}
                </Button>
            </div>
        );
    };

    return (
        <ModalCore
            {...restProps}
            width={width}
            prefixCls={prefixCls}
            footer={footer === undefined ? renderFooter() : footer}
            visible={visible}
            onClose={handleCancel}
            transitionName="zoom"
            closable={closable}
            closeIcon={closeIcon}
            className={classes}
        />
    );
}

Modal.propTypes = {
    /** current dialog's visible status */
    visible: PropTypes.bool,
    /** additional className for dialog wrap */
    wrapClassName: PropTypes.node,
    /** The cancel button props */
    cancelButtonProps: PropTypes.shape({}),
    /** The ok button props */
    okButtonProps: PropTypes.shape({}),
    /** Text of the OK button */
    okText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    /** Text of the Cancel button */
    cancelText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    /** Text of the Cancel button */
    okType: PropTypes.string,
    /** Whether to apply loading visual effect for OK button or not */
    confirmLoading: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
    ]),
    /** Specify a function that will be called when a user clicks mask,
     *  close button on top right or Cancel button */
    onCancel: PropTypes.func,
    /** Specify a function that will be called when a user clicks the OK button */
    onOk: PropTypes.func,
    /** @ignore */
    children: PropTypes.node,
    /** @ignore */
    footer: PropTypes.node,
    /** Width of modal */
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

Modal.defaultProps = {
    visible: false,
    wrapClassName: '',
    cancelButtonProps: {},
    okButtonProps: {},
    okText: 'OK',
    cancelText: 'Cancel',
    okType: 'primary',
    confirmLoading: false,
    children: null,
    onCancel: undefined,
    onOk: undefined,
    footer: undefined,
    width: 380,
};

export default withTheme(Modal);
