import { DefinitionRequestPayload } from 'additiv-services/types/services/definition';
import Service from './service';
import { getServicesUrl } from '../../setup';

class Definitions extends Service {
    static postDefinitions(data: DefinitionRequestPayload) {
        return Service.postRequest(`${getServicesUrl()}/definitions`, data, {
            isSecure: true,
        });
    }
}

export default Definitions;
