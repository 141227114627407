import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ErrorBoundary from 'utils/ErrorBoundary';
import Documents from './Documents';
import { useClientLayout } from '../../hooks/useClientLayout';

const Router = () => {
    useClientLayout();
    const { t } = useTranslation();

    return (
        <ErrorBoundary t={t}>
            <Routes>
                <Route exact path="" element={<Documents/>} />
                <Route
                    path="*"
                    element={<Navigate to="/404" replace />}
                />
            </Routes>
        </ErrorBoundary>
    );
};

Router.displayName = 'Router';

export default Router;
