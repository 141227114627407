import React, {useMemo} from 'react';
import {useTheme} from 'theming';
import {Toggle} from 'ui-library';
import './ThemeToggle.css';

const ThemeToggle = () => {
    const {
        theme,
        setTheme
    } = useTheme();
    const defaultChecked = useMemo(() => theme === 'modern', [theme]);

    return (
        <div className="ThemeToggle">
            <h4>Modern theme</h4>
            <Toggle
                defaultChecked={defaultChecked}
                onChange={(value) => setTheme(value ? 'modern' : 'prestige')}
            />
        </div>
    );
};

ThemeToggle.propTypes = {};

ThemeToggle.defaultProps = {};

export default ThemeToggle;
