import React, {
    useState, useCallback, useEffect, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import Preloader from 'components/Preloader';
import EmptyContent from 'components/EmptyContent';
import {getClientId} from 'utils';
import {useCancelAllRequests} from 'hooks/useCancelAllRequests';
import {useDetectedMobileDevice} from 'hooks/useDetectedMobileSize';
import {useLocation, useParams} from 'react-router-dom';
import SelectElement from 'components/AtomicStructure/atoms/SelectElement';
import { Table} from '../../../../ui-library';
import {getColumns} from './constants';
import {useDocuments} from './hooks/useDocuments';
import {DocumentUpload} from './components/DocumentUpload';
import Card from '../../../../components/Card/Card';
import {useFormatting} from '../../../../locale';
import {TableFilter, TableFilterItem} from '../../../../components/TableFilter';
import './Documents.css';

function Documents() {
    useCancelAllRequests();

    const { dfsClientId } = useParams();
    const { state } = useLocation();
    const currentPage = state?.currentPage || 1;
    const tab = state?.currentTab || 'personal';

    const clientId = getClientId(dfsClientId);
    const {t} = useTranslation();
    const {isTabletSize} = useDetectedMobileDevice();
    const [currentPagination, setCurrentPagination] = useState(currentPage);
    const [docError, setDocError] = useState(null);
    const [businessUnitFilter, setBusinessUnitFilter] = useState(null);
    const [categoryFilter, setCategoryFilter] = useState(null);
    const [params] = useState({});
    const { getFormattedDate } = useFormatting();

    const {
        data: fullData,
        isLoading,
        error: useDocumentsError,
        pageSize,
        personalPaging,
        categories,
        businessUnits,
        getUnifiedDocuments,
    } = useDocuments({
        clientId,
        currentPagination,
        params,
        tab,
        businessUnitFilter,
        categoryFilter,
    });

    const typeFilterOptions = useMemo(() => [{label: t('clientDashboard.documents.allCategories'), id: ''}, ...(categories?.map(i => ({label: i, id: i})) || [])], [categories]);
    const productFilterOptions = useMemo(() => [{label: t('clientDashboard.documents.allBusinessUnits'), id: ''}, ...(businessUnits?.map(i => ({label: i, id: i})) || [])], [businessUnits]);

    useEffect(() => {
        setDocError(useDocumentsError);
        const errMsg = useDocumentsError?.error?.response?.data?.message?.toLowerCase();

        // if server could not find file, show custom message correctly
        if (errMsg?.includes('not found') || errMsg?.includes('not exist')) {
            setDocError({
                ...docError,
                message: t('clientDashboard.documents.noDocFound'),
            });
        }
    }, [useDocumentsError]);

    const onChangePage = useCallback((paging) => {
        setCurrentPagination(paging?.currentPage);
    }, [setCurrentPagination]);

    const onUploadSuccess = useCallback(async (_id) => {
        await getUnifiedDocuments();
    }, [getUnifiedDocuments]);

    return (
        <div className="container">
            <Card
                title={t('advisoryDashboard.documents.title')}
                titleTagName="h1"
                headerActions={<DocumentUpload contactId={clientId} onUpload={onUploadSuccess}/>}
            >
                <TableFilter>
                    <TableFilterItem>
                        <SelectElement
                            hasSearch
                            className="filters"
                            placeholder={t('clientDashboard.documents.category')}
                            options={typeFilterOptions}
                            value={categoryFilter}
                            onChange={val => setCategoryFilter(val)}
                        />
                    </TableFilterItem>
                    <TableFilterItem>
                        <SelectElement
                            className="filters"
                            placeholder={t('clientDashboard.documents.businessUnit')}
                            options={productFilterOptions}
                            value={businessUnitFilter}
                            onChange={val => setBusinessUnitFilter(val)}
                        />
                    </TableFilterItem>
                </TableFilter>
                <Preloader
                    isLoading={isLoading}
                    error={docError}
                    letChildrenToRenderOnError
                >
                    <EmptyContent data={fullData} text={t('advisoryDashboard.documents.noDocuments')}>
                        <Table
                            columns={getColumns(t, isTabletSize, getFormattedDate)}
                            data={fullData}
                            paging={{
                                total: personalPaging?.TotalCount || 0,
                                pageSize,
                                currentPage: currentPagination,
                            }}
                            headerControls={{
                                selectControlsLabel: {
                                    selectAll: t('headerControls.selectAll'),
                                    reset: t('headerControls.reset'),
                                },
                            }}
                            onChange={onChangePage}
                        />
                    </EmptyContent>
                </Preloader>
            </Card>
        </div>
    );
}

export default Documents;
