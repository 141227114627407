import React from 'react';
import {
    Route, Routes, Navigate, useParams,
} from 'react-router-dom';
import { Overview } from './pages/Overview';
import { Deposit } from './pages/Deposit';
import { Strategy } from './pages/Strategy';
import { Details } from './pages/Details';
import { Inventory } from './pages/Inventory';

import {SinglePortfolioProvider} from './context';
import {useClientLayout} from '../../../../hooks/useClientLayout';
import withPortfolioAccess from '../../hocs/withPortfolioAccess';
import {ClientServiceProvider} from '../../../../../../domain/ClientService';
import {getClientId} from '../../../../../../utils';

const OverviewWrapper = withPortfolioAccess(Overview);
const StrategyWrapper = withPortfolioAccess(Strategy);
const InventoryWrapper = withPortfolioAccess(Inventory);
const DepositWrapper = withPortfolioAccess(Deposit);
const DetailsWrapper = withPortfolioAccess(Details);

const SinglePortfolioRouter = () => {
    const { dfsClientId, portfolioId } = useParams();

    useClientLayout();

    return (
        <ClientServiceProvider options={{contactId: getClientId(dfsClientId)}}>
            <SinglePortfolioProvider {...{portfolioId, dfsClientId}}>
                <Routes>
                    <Route path="overview" element={<OverviewWrapper/>} />
                    <Route path="strategy" element={<StrategyWrapper/>} />
                    <Route path="stock" element={<InventoryWrapper/>} />
                    <Route path="deposit" element={<DepositWrapper/>} />
                    <Route path="details" element={<DetailsWrapper/>} />

                    <Route
                        path=""
                        element={<Navigate to="overview" replace />}
                    />
                    <Route
                        path="*"
                        element={<Navigate to="/404" replace />}
                    />
                </Routes>
            </SinglePortfolioProvider>
        </ClientServiceProvider>
    );
};

export default SinglePortfolioRouter;
