import { useMemo } from 'react';
import { LiquidityId } from '../../../../../../../constants/instrument';
import { useFormatting } from '../../../../../../../locale';

export const usePositionsWithIndividualIndicator = ({positionsRaw}) => {
    const { getFormattedNumber } = useFormatting();

    const positions = useMemo(() => {
        if (positionsRaw?.length) {
            return positionsRaw?.reduce((accu, item) => {
                if (!(item?.Allocation === 0 && item?.Security?.Type?.Id === LiquidityId)) {
                    accu.push({
                        id: item?.Security?.Type?.Id,
                        name: item?.Security?.Name,
                        marketValue: `${getFormattedNumber(item?.InvestmentValueSecurityCurrency, {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2,
                        })} ${item?.Security?.Currency?.CurrencyCode}`,
                        allocation: `${getFormattedNumber(item?.Allocation * 100, {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2,
                        })}%`,
                    });

                    return accu;
                }

                return accu;
            }, []);
        }

        return [];
    }, [positionsRaw]);

    return {
        positions,
    };
};
