import React from 'react';
import { useTranslation } from 'react-i18next';
import ReportForm from '../ReportForm';

const InvestmentDataReport:React.FC = () => {
    const {t} = useTranslation();

    return <ReportForm title={t('reports.investmentApplicationData.title')} APIName="getInvestmentDataReport" savedFileName="Investment Data Report" />;
};

export default InvestmentDataReport;
