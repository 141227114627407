import {validateInputString, validateSelect} from '../../../../../../../../validation/validation';

export const errorModel = (data) => ({
    message: validateInputString(data.message),
    planNumber: validateInputString(data.planNumber),
    firstName: validateInputString(data.firstName),
    lastName: validateInputString(data.lastName),
    customerNumber: validateInputString(data.customerNumber),
    language: validateSelect(data.language),
});
