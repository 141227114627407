import {AxiosResponse} from 'axios';
import {
    GetFundingResponseData,
    PatchFundingData,
    PatchFundingResponseData,
    SearchFundingData,
    SearchFundingResponseData,
} from 'additiv-services/types/services/fundingManagement';
import {getServicesUrl} from '../../setup';
import Service from './service';

class FundingManagement extends Service {
    static getFunding(contactId: number, fundingId: number) {
        return Service.getRequest(`${getServicesUrl()}/contacts/${contactId}/fundings/${fundingId}`, { isSecure: true }) as Promise<AxiosResponse<GetFundingResponseData>>;
    }

    static patchFunding(contactId: number, fundingId: number, data: PatchFundingData) {
        return Service.patchRequest(
            `${getServicesUrl()}/contacts/${contactId}/fundings/${fundingId}`, data, { isSecure: true },
        ) as Promise<AxiosResponse<PatchFundingResponseData>>;
    }

    static searchFunding(contactId: number, data: SearchFundingData) {
        return Service.postRequest(
            `${getServicesUrl()}/contacts/${contactId}/fundings/search`, data, { isSecure: true },
        ) as Promise<AxiosResponse<SearchFundingResponseData>>;
    }
}

export default FundingManagement;
