import React from 'react';
import PropTypes from 'prop-types';
import { createUniqueKey } from '../../../utils';

function Legend(props) {
    const { data, matrix } = props;

    return (
        <div className="Risk-return-legend">
            {
                data.map(
                    (item, index) => (
                        <div className={`Risk-return-legend-item legend-item-${index}`} key={createUniqueKey(index, item.name)}>
                            <div className="legend-name">
                                {item.name}
                            </div>
                        </div>
                    ),
                )
            }
            {matrix?.name && (
                <div className="Risk-return-legend-item legend-item-matrix" key={`matrix-${matrix.name}`}>
                    <div className="legend-name">
                        {matrix.name}
                    </div>
                </div>
            )}
        </div>
    );
}

Legend.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
    })),
    matrix: PropTypes.shape({
        name: PropTypes.string,
    }),
};

Legend.defaultProps = {
    data: [],
    matrix: undefined,
};

export default Legend;
