import React, { useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import PageSection from 'components/PageSection/PageSection';
import {withMainLayout} from 'hocs';
import PageTitle from 'components/AtomicStructure/atoms/PageTitle';
import NewWrapper from 'components/AtomicStructure/layouts/NewWrapper';
import Error from 'components/AtomicStructure/atoms/Error';
import {newPersonalInformationOnboardingLink, questionPageLink} from 'pages/ClientOnboardingFlow/helpers/links';
import {getUserRegisterId} from 'pages/ClientOnboardingFlow/helpers/userRegisterId';

import './UploadDocumentsPage.css';
import {getBase64FromFile} from 'utils/file';
import {useNavigate} from 'react-router-dom';
import ButtonsBlockRow from '../../../../../components/ButtonsBlockRow';
import {MAXALLOWEDFILES} from './validation/const';
import ServiceManager from '../../../../../services/ServiceManager';
import UploadDocuments from '../../../../../components/UploadDocuments/UploadDocuments';

const UploadDocumentsPage = () => {
    const navigate = useNavigate();
    const {t} = useTranslation();

    const userRegisterId = getUserRegisterId();

    const [files, setFiles] = useState([]);
    const [isSending, setSending] = useState(false);
    const [errorPage, setErrorPage] = useState('');

    const goBack = () => {
        navigate(`${newPersonalInformationOnboardingLink}/id=${userRegisterId}`);
    };

    const goToAction = useCallback(async () => {
        try {
            if (files?.length) {
                setSending(true);

                const payload = {
                    ownerId: userRegisterId,
                    documents: await Promise.all(files.map(async (file) => ({
                        filename: file.name,
                        file: (await getBase64FromFile(file)).split(',')[1],
                    }))),
                };

                await ServiceManager.customDocumentsService('uploadIdDocument', [payload]);
            }
            navigate(questionPageLink);
        } catch (err) {
            setErrorPage(err?.message);
        } finally {
            setSending(false);
        }
    }, [files, userRegisterId]);

    return (
        <NewWrapper
            stepNavBarActive={1}
            headTitle={t('onboardingFlow.customerOnboarding.title')}
        >
            <PageSection className="newStyle">
                <div className="newStyleContent">
                    <div className="container">
                        <PageTitle className="textCenter">
                            {t('onboardingFlow.documetsPage.pleaseFollow')}
                        </PageTitle>

                        <p className="text-center text-light text-large text-dark">
                            {t('onboardingFlow.documetsPage.description')
                                .replace('{maxUploadCount}', MAXALLOWEDFILES)}
                        </p>
                        <UploadDocuments onError={(val) => setErrorPage(val)} onSuccess={setFiles}/>

                    </div>

                    <div className="JourneyFooter">
                        <div className="container">
                            {errorPage && <Error>{errorPage}</Error>}

                            <ButtonsBlockRow
                                additionalButton={{
                                    text: t('onboardingFlow.documetsPage.back'),
                                    onClick: () => goBack(),
                                }}
                                primaryButton={{
                                    text: t('onboardingFlow.clientsDataPage.continue'),
                                    onClick: goToAction,
                                    loading: isSending,
                                }}
                            />
                        </div>
                    </div>
                </div>
            </PageSection>
        </NewWrapper>
    );
};

export default withMainLayout(UploadDocumentsPage);
