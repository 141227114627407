import React from 'react';
import PropTypes from 'prop-types';
import Icon, { ICON_TYPE_CLOSE } from '../../../Icon';
import './FileItem.css';

const FileItem = ({ onDelete, children }) => {
    const onPressEnter = (event) => {
        if (event.which === 13 || event.keyCode === 13) {
            event.preventDefault();
            onDelete(event);
        }
    };


    return (
        <div
            className="FileItem"
        >
            <span className="upload-list-item-content">{children}</span>
            <Icon
                onClick={onDelete}
                role="button"
                tabIndex={0}
                onKeyPress={onPressEnter}
                type={ICON_TYPE_CLOSE}
                className="action-delete"
            />
        </div>
    );
};

FileItem.propTypes = {
    children: PropTypes.node,
    onDelete: PropTypes.func,
};
FileItem.defaultProps = {
    children: null,
    onDelete: () => {
    },
};
export default FileItem;
