export const DEFAULT_PAGE_SIZE = 3;

export const START_PAGINATION_PAGE = 1;

export const columns = (t) => [
    {
        key: 'applicationId',
        title: t('dashboard.overviewClientApplications.applicationId'),
    },
    {
        key: 'zipId',
        title: t('dashboard.overviewClientApplications.zipId'),
    },
    {
        key: 'name',
        title: t('allocationColumns.name'),
    },
    {
        key: 'product',
        title: t('dashboard.agentCockpit.productWorld'),
    },
    {
        key: 'strategy',
        title: t('portfolio.createNewPortfolio.overview.strategy'),
    },
    {
        key: 'agent',
        title: t('dashboard.overviewClientApplications.agent'),
    },
    {
        key: 'submitted',
        title: t('dashboard.agentCockpit.submitted'),
    },
    {
        key: 'status',
        title: t('dashboard.agentCockpit.status'),
    },
    {
        key: 'createNewPlan',
        title: '',
    },
];
