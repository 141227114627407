import React from 'react';
import {number, string} from 'prop-types';
import {useTranslation} from 'react-i18next';

import 'ui-library/components/Table/Table.css';

import Card from '../../../../../../../../../../components/Card/Card';
import SumBarChart from '../../../Overview/components/SumBarChart/SumBarChart';

const Details = ({
    portfolioId, currency, clientId,
}) => {
    const { t } = useTranslation();

    return (
        <Card title={`${t('cashFlows.inflows')} ${new Date().getFullYear()}`}>
            <SumBarChart
                portfolioId={portfolioId}
                clientId={clientId}
                currency={currency}
            />
        </Card>
    );
};

Details.propTypes = {
    portfolioId: number.isRequired,
    clientId: number.isRequired,
    currency: string.isRequired,
};

export default Details;
