import React from 'react';
import PropTypes from 'prop-types';
import { createUniqueKey } from '../../../utils';

function ScrollableColumns({
    scrollable, columns, mini,
}) {
    if (!scrollable && !mini) return null;

    return (
        <colgroup>
            {columns.map(({ width, minWidth }, index) => (
                <col
                    key={createUniqueKey(index, width)}
                    style={{ width, minWidth: minWidth || width }}
                />
            ))}
        </colgroup>
    );
}

ScrollableColumns.propTypes = {
    mini: PropTypes.bool,
    scrollable: PropTypes.bool,
    columns: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.string,
        title: PropTypes.string,
        width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        minWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        hidden: PropTypes.bool,
        sortable: PropTypes.bool,
        className: PropTypes.string,
    })),
};

ScrollableColumns.defaultProps = {
    mini: false,
    scrollable: false,
    columns: [],
};

export default ScrollableColumns;
