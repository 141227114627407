import Service from './service';
import { getServicesUrl } from '../../setup';
import { getQueryStringByObject } from '../../utils/queryUtils';

class MemberManagement extends Service {
    static membersLookup(params) {
        const queryString = getQueryStringByObject(params);

        return Service.getRequest(`${getServicesUrl()}/members/lookup?${queryString}`, { isSecure: true });
    }

    static getMemberPicture(memberId) {
        return Service.getRequest(
            `${getServicesUrl()}/members/${memberId}/profilepicture`,
            { isSecure: true }
        );
    }

    static updateMemberPicture(memberId, data) {
        return Service.putRequest(
            `${getServicesUrl()}/members/${memberId}/profilepicture`, data,
            { isSecure: true }
        );
    }

    static deleteMemberPicture(memberId) {
        return Service.deleteRequest(
            `${getServicesUrl()}/members/${memberId}/profilepicture`,
            { isSecure: true }
        );
    }
}

export default MemberManagement;
