export const THEME = [
    {
        theme: 'Mint (Accent)',
        colors: [
            'accent-default',
            'accent-lighter',
            'accent-light',
            'accent-dark',
            'accent-darker',
        ],
    },
    {
        theme: 'Ash (Primary)',
        colors: [
            'primary-default',
            'primary-lighter',
            'primary-light',
            'primary-dark',
            'primary-darker',
        ],
    },
    {
        theme: 'Dust (Secondary)',
        colors: [
            'secondary-default',
            'secondary-lighter',
            'secondary-light',
            'secondary-dark',
            'secondary-darker',
        ],
    },
    {
        theme: 'Jade (Positive)',
        colors: [
            'positive-default',
            'positive-lighter',
            'positive-light',
            'positive-dark',
            'positive-darker',
        ],
    },
    {
        theme: 'Raspberry (Negative)',
        colors: [
            'negative-default',
            'negative-lighter',
            'negative-light',
            'negative-dark',
            'negative-darker',
        ],
    },
    {
        theme: 'Others',
        colors: [
            'white',
        ],
    },
];
