import {isNil} from 'lodash/fp';

import {switzLandCountryId} from 'constants/constants';
import {PersonalDetailGetModelType} from 'widgets/PersonalDetails/PersonalDetails.types';

export const getModel = (outputData, getFormattedDate): PersonalDetailGetModelType => {
    const personalInformation = outputData?.details?.personalInformation;
    const communicationMethods = outputData?.details?.communicationMethods;
    const loadedOccupation = personalInformation?.occupation;
    const affiliatedPensionFund = !isNil(outputData?.details?.personalInformation?.affiliatedPensionFund) ? String(outputData?.details?.personalInformation?.affiliatedPensionFund) : undefined;
    const occupationValue = () => (loadedOccupation ? String(loadedOccupation) : undefined);

    return {
        firstName: personalInformation?.firstName,
        lastName: personalInformation?.lastName,
        dateOfBirth: personalInformation?.dateOfBirth && typeof getFormattedDate === 'function' && getFormattedDate(personalInformation?.dateOfBirth),
        gender: personalInformation?.gender,
        street: communicationMethods?.primaryAddress?.primaryAddressLine3,
        PLZ: communicationMethods?.primaryAddress?.primaryAddressLine4,
        ORT: communicationMethods?.primaryAddress?.primaryAddressLine5,
        countryOfNationality: String(personalInformation?.identification?.[0]?.countryOfNationality),
        countryOfResidence: String(communicationMethods?.primaryAddressCountry),
        countryOfBirth: personalInformation?.countryOfBirth ? String(personalInformation?.countryOfBirth) : undefined,
        primaryEmail: communicationMethods?.primaryEmail,
        primaryPhoneNumber: communicationMethods?.primaryPhoneNumber,
        telephoneNumber: communicationMethods?.secondaryPhoneNumber,
        avhNumber: personalInformation?.ahvNo,
        clientId: outputData?.id,
        affiliatedPensionFund,
        occupation: occupationValue(),
        member: personalInformation?.member || false,
        destinedLife: personalInformation?.destinedLife || false,
        zurichMA: personalInformation?.zurichMA || false,
        employmentContinuation: personalInformation?.employmentContinuation,
        employmentContinuationDate: personalInformation?.employmentContinuationDate,
        taxDomicile: `${personalInformation?.taxDomicile ?? switzLandCountryId}`,
        riskDomicile: `${personalInformation?.riskDomicile ?? switzLandCountryId}`,
        mailingAddress: {
            country: communicationMethods?.mailRecipient?.mailRecipientAddressCountry,
            street: communicationMethods?.mailRecipient?.mailRecipientAddressLine3,
            zip: communicationMethods?.mailRecipient?.mailRecipientAddressLine4,
            city: communicationMethods?.mailRecipient?.mailRecipientAddressLine5,
        },
    };
};
