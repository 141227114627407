import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useStore } from '../hooks/useThemeStore';
import { getClassNames } from '../utils';

type TWithTheme = {
    className?: string,
    [p: string]: string | number | boolean | (() => void) | object | Array<any> | undefined | null
}

const updateStyle = (component, vars = {}) => {
    if (!vars || Object.keys(vars).length === 0) {
        return undefined;
    }

    const className = `${component}-cst`;
    const stylesInner = Object.keys(vars).reduce((acc, key) => `${acc}${key}: ${vars[key]};`, '');
    const styles = `.${className} {${stylesInner}}`;
    const styleExisting = document.querySelector(`style[data-component-class='${className}']`);

    if (styleExisting && styleExisting.innerHTML === styles) {
        return className;
    }

    const style = styleExisting || document.createElement('style');

    style["type"] = 'text/css';
    style["dataset"].componentClass = className;
    style.innerHTML = styles;
    document.getElementsByTagName('head')[0].appendChild(style);

    return className;
};

const withTheme = <T extends TWithTheme>(Component: React.JSXElementConstructor<T>) => {
    const Wrapped = (props: T) => {
        const store = useStore();
        const vars = store && store.state ? store.state[Component.name] : null;
        const classNameCustom = useMemo(() => updateStyle(Component.name, vars), [vars]);
        const className = useMemo(() => getClassNames(classNameCustom, props.className),
            [classNameCustom, props.className]);

        return <Component {...(props as T)} className={className} />;
    }

    return Wrapped;
};

export default withTheme;
