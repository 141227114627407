import CheckboxRenderer from 'pages/ClientOverview/pages/Portfolios/renderers/CheckboxRenderer';
import FactsheetRenderer from 'pages/ClientOverview/pages/Portfolios/renderers/FactsheetRenderer';
import SliderTableRenderer from 'pages/ClientOverview/pages/Portfolios/renderers/SliderTableRenderer';

export const columnDefs = (t) => [
    {
        key: 'name',
        title: t('portfolio.createNewPortfolio.productName'),
        render: CheckboxRenderer,
    },
    {
        key: 'allocation',
        title: t('portfolio.createNewPortfolio.instrumentAllocation'),
        render: SliderTableRenderer,
    },
    {
        key: 'factsheet',
        title: t('portfolio.createNewPortfolio.strategy.factsheetUrl'),
        render: FactsheetRenderer,
    },
];

export const footerColumnDefs = () => [
    {
        key: 'name',
    },
    {
        key: 'allocation',
        className: 'total-value',
    },
];
