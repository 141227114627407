import React, {useRef} from 'react';
import {createUniqueKey} from 'utils';

import Preloader from 'components/Preloader';
import {useDraggableWidgets} from './hooks/useDraggableWidgets';
import DashboardColumn from './components/DashboardColumn';
import WidgetsObject from './components/WidgetsObject';
import {withMainLayout} from '../../hocs';

import './AdvisoryDashboard.css';

const AdvisoryDashboard = () => {
    const dashboard = useRef(null);
    const widgetsObject = WidgetsObject();

    const {
        isLoading, error, columns, getWidgets,
    } = useDraggableWidgets(dashboard);

    return (
        <div className="container">
            <div className="AdvisoryDashboard fi-verticals">
                <Preloader isLoading={isLoading} error={error}>
                    {columns.map((column, index) => (
                        <DashboardColumn
                            key={createUniqueKey(index, column)}
                            widgets={getWidgets(column)}
                            widgetsObject={widgetsObject}
                        />
                    ))}
                </Preloader>
            </div>
        </div>
    );
};

export default withMainLayout(AdvisoryDashboard);
