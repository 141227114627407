import { getServicesUrl } from '../../setup';
import Service from './service';

class RelationshipManagement extends Service {
    static getTickets(memberId) {
        return Service.getRequest(`${getServicesUrl()}/persons/${memberId}/tickets`, {
            isSecure: true
        });
    }
}

export default RelationshipManagement;
