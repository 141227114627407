import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { createUniqueKey } from '../../utils';
import Dropdown, { MenuItem } from '../Dropdown';
import Icon, { ICON_TYPE_ACTION } from '../Icon';

function HeaderNavigationDropdown(props) {
    const {
        labelMore,
        navigation,
        renderLink,
        title,
    } = props;
    const [visible, setVisible] = useState(false);

    const titleItem = useMemo(() => (
        title
            ? <span role="button" tabIndex={0} className="hasSubmenu">{title}</span>
            : <Icon role="button" tabIndex={0} type={ICON_TYPE_ACTION} ariaLabel={labelMore} />
    ), [labelMore, title]);

    return (
        <Dropdown
            visible={visible}
            onVisibleChange={setVisible}
            placement="bottomRight"
            overlayClassName="Header-dropdown"
            title={titleItem}
        >
            {navigation.map((item, index) => (
                <MenuItem
                    key={createUniqueKey(index, item.title)}
                    onClick={() => setVisible(false)}
                >
                    {renderLink(item)}
                </MenuItem>
            ))}
        </Dropdown>
    );
}

HeaderNavigationDropdown.propTypes = {
    navigation: PropTypes.arrayOf(PropTypes.shape({
        link: PropTypes.string,
        title: PropTypes.string,
        active: PropTypes.bool,
        hasChanges: PropTypes.bool,
    })),
    labelMore: PropTypes.string.isRequired,
    renderLink: PropTypes.func.isRequired,
    title: PropTypes.string,
};

HeaderNavigationDropdown.defaultProps = {
    navigation: [],
    title: '',
};

export default HeaderNavigationDropdown;
