import React, {
    useEffect, useMemo, useRef, useState,
} from 'react';
import {useTranslation} from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';

import { connectClientsSchema } from 'pages/Clients/provider/ClientsSchemaProvider';
import Card from 'components/Card/Card';
import Preloader from 'components/Preloader/Preloader';
import NewWrapper from 'components/AtomicStructure/layouts/NewWrapper';
import ButtonsBlockRow from 'components/ButtonsBlockRow/ButtonsBlockRow';
import Error from 'components/AtomicStructure/atoms/Error';
import { useConnections } from 'components/Connections/components';

import {useClientLayout} from '../../../../hooks/useClientLayout';
import { ClientSummary } from '../ClientSummary';
import {
    CONNECTIONS_SESSION_KEY, MailingAddress, NewConnectionType, NewConnectionsSessionManagerAPI,
} from './constants';
import './NewConnections.css';

const NewConnectionsSummary = () => {
    useClientLayout();
    const {t} = useTranslation();
    const {
        createConnection, isLoading,
    } = useConnections();

    const connectionType = useRef<NewConnectionType | null>();
    const [ct, setCt] = useState<NewConnectionType>('ja');
    const navigate = useNavigate();
    const location = useLocation();

    const [errorPage, setErrorPage] = useState('');

    const [clients, setClients] = useState<{id: number, name: string, externalId: string}[]>([]);
    const [address, setAddress] = useState<MailingAddress | undefined>();

    const preservedState = useMemo(() => JSON.parse(global.sessionStorage.getItem(CONNECTIONS_SESSION_KEY) || '{}') as NewConnectionsSessionManagerAPI, []);

    useEffect(() => {
        if (!preservedState || !preservedState.contactId || !preservedState.clients?.length || !preservedState.ownerName) {
            navigate(location.pathname.replace('/new-connection/summary', ''));
        }

        const owner = {
            id: preservedState.contactId!,
            name: preservedState.ownerName!,
            externalId: preservedState.ownerExternalId! || '',
        };

        const connections = preservedState.clients?.map(c => ({id: c.id, name: c.name, externalId: c.externalId})) ?? [];

        connectionType.current = preservedState.connectionType;
        if (preservedState.connectionType) setCt(preservedState.connectionType);
        setClients([owner, ...connections]);
        setAddress(preservedState?.mailingAddress);
    }, [preservedState]);

    const goToAction = async () => {
        let newConnection: string | NewConnectionsSessionManagerAPI | null = global.sessionStorage.getItem(CONNECTIONS_SESSION_KEY);

        try {
            if (newConnection) {
                newConnection = JSON.parse(newConnection) as NewConnectionsSessionManagerAPI;
                await createConnection(newConnection);

                global.sessionStorage.removeItem(CONNECTIONS_SESSION_KEY);

                if (newConnection.returnUrl) {
                    navigate(newConnection.returnUrl);
                } else {
                    navigate(location.pathname.replace('/new-connection/summary', ''));
                }
            }
        } catch (e: any) {
            setErrorPage(e?.message);
        }
    };

    return (
        <div className="container PoaConnections">
            <div className="fi-verticals">
                <Card
                    title={{
                        ja: t('connections.createNewJa.title'),
                        poa: t('connections.createNewPoa.title'),
                        representative: t('connections.createNewPa.title'),
                    }[connectionType.current || 'ja']}
                >
                    <NewWrapper
                        HeaderNavbarTurnOff
                        stepNavBarActive={2}
                        dataKey={ct === 'ja' ? 'newJaConnections' : 'newConnections'}
                    >
                        <div className="poa_content">
                            <p className="text-center newAccountsLabel">
                                {t('connections.createNew.accounts')}
                            </p>
                            <div className="clients">
                                {clients.map(c => <ClientSummary key={c.id} id={c.externalId} name={c.name} isJa={c.externalId.startsWith('5')} />)}
                            </div>
                            {ct === 'ja' && (
                                <div className="summary">
                                    <p className="text-center">
                                        {t('connections.createNew.summary')}
                                    </p>
                                    <div className="newConnectionSummaryBox">
                                        <span>{`${address?.street} ${address?.addLine1} ${address?.addLine2}`}</span>
                                        <span>{`${address?.zipCity} ${address?.city}`}</span>
                                    </div>
                                </div>
                            )}
                            <Preloader isLoading={false}>
                                <div className="container">
                                    {errorPage && <Error>{errorPage}</Error>}

                                    <ButtonsBlockRow
                                        additionalButton={{
                                            text: t('onboardingFlow.documetsPage.back'),
                                            onClick: () => navigate(-1),
                                        }}
                                        primaryButton={{
                                            text: {
                                                ja: t('connections.createNewJa.finish'),
                                                poa: t('connections.createNewPoa.finish'),
                                                representative: t('connections.createNewPa.finish'),
                                            }[connectionType.current || 'ja'],
                                            onClick: () => goToAction(),
                                            disabled: isLoading,
                                            loading: isLoading,
                                        }}
                                    />
                                </div>
                            </Preloader>
                        </div>
                    </NewWrapper>
                </Card>
            </div>
        </div>
    );
};

export default connectClientsSchema(NewConnectionsSummary);
