import Service from 'additiv-services';
import i18n from 'i18next';
import {BASIC_DMS_API_URL_V20} from './const';

const getToken = () => {
    const token = {};

    Object.keys(sessionStorage).forEach((key) => {
        if (key.includes('oidc')) {
            token[key] = sessionStorage.getItem(key);
        }
    });

    return `Bearer ${Object.values(token).id_token}`;
};

class DocumentService extends Service {
    static getDocumentCategoriesList(params) {
        return Service.postRequest(`${BASIC_DMS_API_URL_V20}/categories/lookup`, {
            'Accept-Language': i18n.language,
            documentTypeId: params?.documentTypeId || 2,
            isActive: true,
        }, {
            isSecure: true,
            headers: [
                {authorization: getToken()},
            ],
        });
    }

    static getDocumentMetaDataList(params) {
        return Service.postRequest(`${BASIC_DMS_API_URL_V20}/metadata/lookup`, {
            'Accept-Language': i18n.language,
            documentTypeId: params?.documentTypeId || 2,
            documentCategoryId: params?.documentCategoryId || 2,
            isActive: true,
            metadataAccessPolicy: 'Read',
        }, {
            isSecure: true,
            headers: [
                {authorization: getToken()},
            ],
        });
    }
}
export default DocumentService;
