const CLIENT_TYPE = {
    Normal: 1,
    Vita: 2,
    Zurich: 3,
};

function distinguishClientType(zurichEmployee, destinataryVita) {
    if (zurichEmployee) {
        return CLIENT_TYPE.Zurich;
    } if (destinataryVita) {
        return CLIENT_TYPE.Vita;
    }

    return CLIENT_TYPE.Normal;
}

export default distinguishClientType;
