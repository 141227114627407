import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OPTION_LIST, STRATEGIES } from 'constants/constants';
import { Select, Title } from 'ui-library';
import './SelectPreferenceStrategy.css';
import { useOptionList } from 'hooks/useOptionList';
import ServiceManager from 'services/ServiceManager';

const SelectPreferenceStrategy = ({
    preferenceStock,
    onPreferenceStockChange,
    preferenceExclusion,
    onPreferenceExclusionChange,
    preferenceInclusion,
    onPreferenceInclusionChange,
    exclusionSubstitute,
    onExclusionSubstituteChange,
    selectedStrategyId,
}) => {
    const {
        i18n: { language },
        t,
    } = useTranslation();

    const isCapital = selectedStrategyId === STRATEGIES.capital;
    const {
        lists: [
            optionPreferenceStock,
            optionPreferenceExclusion,
            optionPreferenceInclusion,
        ],
    } = useOptionList([
        OPTION_LIST.preferenceStocks,
        OPTION_LIST.preferenceExclusion,
        OPTION_LIST.preferenceInclusion,
    ]);

    const [optionExclusionSubstitute, setOptionExclusionSusbtitute] = useState([]);

    const NO_EXCLUSION = optionPreferenceExclusion ? optionPreferenceExclusion[0]?.value : 0;

    useEffect(() => {
        if (isCapital) {
            if ((!preferenceStock || !preferenceInclusion)
                && optionPreferenceExclusion?.length > 0 && optionPreferenceStock?.length > 0) {
                if (!preferenceStock) {
                    onPreferenceStockChange(optionPreferenceStock[0]?.value);
                }
                onPreferenceInclusionChange(optionPreferenceInclusion[0]?.value);
            }
        } else if ((!preferenceStock || !preferenceExclusion)
            && optionPreferenceExclusion?.length > 0 && optionPreferenceStock?.length > 0) {
            onPreferenceStockChange(optionPreferenceStock[0]?.value);
            onPreferenceExclusionChange(optionPreferenceExclusion[0]?.value);
            onExclusionSubstituteChange(undefined);
        }
    }, [optionPreferenceStock,
        optionPreferenceExclusion,
        optionPreferenceInclusion,
        isCapital,
    ]);

    const getSubstituteExclusionOptions = async () => {
        try {
            const { data } = await ServiceManager.customInvestmentService('getSubstituteExclusionOptions', [{
                filter: optionPreferenceExclusion?.find((i) => i?.value === preferenceExclusion)?.label,
                preferenceKey: 'substituteExclusion',
                language,
            }]);

            if (data) {
                const tempSubstituteExclusionOptions = data?.items?.map((item) => ({
                    ...item,
                    value: `${item.id}`,
                }));

                setOptionExclusionSusbtitute(tempSubstituteExclusionOptions);
            }
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        if (preferenceExclusion && preferenceExclusion !== NO_EXCLUSION) {
            getSubstituteExclusionOptions();
        }
    }, [preferenceExclusion, language]);

    return (
        <div className="selectPreferenceStrategy">
            <Title type={2}>{t('portfolio.createNewPortfolio.productMark')}</Title>
            <Select
                data-cy="preference-stocks"
                value={preferenceStock}
                options={optionPreferenceStock || []}
                label={t('portfolio.createNewPortfolio.selectStrategy.preferenceStocks')}
                placeholder={t('portfolio.createNewPortfolio.agentInformation.selectPlaceholder')}
                onChange={onPreferenceStockChange}
            />
            {
                isCapital ? (
                    <Select
                        data-cy="preference-inclusion"
                        value={preferenceInclusion}
                        options={optionPreferenceInclusion || []}
                        label={t('portfolio.createNewPortfolio.selectStrategy.preferenceInclusion')}
                        placeholder={t('portfolio.createNewPortfolio.agentInformation.selectPlaceholder')}
                        onChange={onPreferenceInclusionChange}
                    />
                ) : (
                    <>
                        <Select
                            data-cy="preference-exclusion"
                            value={preferenceExclusion}
                            options={optionPreferenceExclusion || []}
                            label={t('portfolio.createNewPortfolio.selectStrategy.preferenceExclusion')}
                            placeholder={t('portfolio.createNewPortfolio.agentInformation.selectPlaceholder')}
                            onChange={(val) => {
                                onExclusionSubstituteChange(undefined);

                                onPreferenceExclusionChange(val);
                            }}
                        />
                        <Select
                            data-cy="exclusion-substitute"
                            value={exclusionSubstitute}
                            options={optionExclusionSubstitute || []}
                            label={t('portfolio.createNewPortfolio.selectStrategy.exclusionSubstitute')}
                            placeholder={t('portfolio.createNewPortfolio.agentInformation.selectPlaceholder')}
                            onChange={onExclusionSubstituteChange}
                            disabled={preferenceExclusion === NO_EXCLUSION}
                        />
                    </>
                )
            }
        </div>
    );
};

export default SelectPreferenceStrategy;
