import { useTranslation } from 'react-i18next';
import { notification } from 'ui-library';
import SM from 'services/ServiceManager';
import { DefaultError, useMutation } from '@tanstack/react-query';

export interface Payload<T> {
    service: string;
    API: string;
    payload: T;
}

export type requestType = 'post' | 'put' | 'delete';

export interface Response<T> {
    data: T;
}

export interface DefaultPayload<V> {
    [key: string]: V;
}

export const useGenericAPIMutation = <T, U, V>(
    defaultPayload: DefaultPayload<V> = {}) => {
    const { t } = useTranslation();

    return useMutation<Response<U>, DefaultError, Payload<T>>({
        mutationFn: async ({ service, API, payload }: Payload<T>) => {
            const response = await SM?.[service](API, [
                {
                    ...defaultPayload,
                    ...payload,
                },
            ]);

            return response;
        },
        onError: (error: any) => {
            notification.open({
                content: `${t('contactGroups.somethingWentWrong')} ${
                    error.message
                }`,
                type: 'error',
            });
            console.error('Error while uploadProofOfAssetsDocument.');

            return error;
        },
    });
};
