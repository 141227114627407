import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '../../Button';

const ActionButton = (props) => {
    const {
        buttonProps = {},
        type,
        children,
    } = props;
    const [loading, setLoading] = useState(false);


    const handlePromiseOnOk = (returnValueOfOnOk) => {
        const { closeModal } = props;

        if (!returnValueOfOnOk || !returnValueOfOnOk.then) {
            return;
        }
        setLoading(true);
        returnValueOfOnOk.then(
            (...args) => {
                closeModal(...args);
            },
            (e) => {
                console.error(e);
                setLoading(false);
            },
        );
    };
    const onClick = () => {
        const { actionFn, closeModal } = props;

        if (!actionFn) {
            closeModal();


            return;
        }
        let returnValueOfOnOk;

        if (actionFn.length) {
            returnValueOfOnOk = actionFn(closeModal);
        } else {
            returnValueOfOnOk = actionFn();
            if (!returnValueOfOnOk) {
                closeModal();


                return;
            }
        }
        handlePromiseOnOk(returnValueOfOnOk);
    };

    return (
        <Button
            {...buttonProps}
            type={type}
            onClick={onClick}
            loading={loading}
        >
            {children}
        </Button>
    );
};

ActionButton.propTypes = {
    closeModal: PropTypes.func,
    actionFn: PropTypes.func,
    buttonProps: PropTypes.shape({}),
    type: PropTypes.string,
    loading: PropTypes.bool,
    children: PropTypes.node,
};

ActionButton.defaultProps = {
    closeModal: null,
    actionFn: null,
    buttonProps: {},
    type: null,
    loading: false,
    children: null,
};


export default ActionButton;
