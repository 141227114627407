import React, {
    useCallback, memo,
} from 'react';

import Preloader from 'components/Preloader';
import EmptyContent from 'components/EmptyContent';

import { CustomTable } from 'components/CustomTable';
import Card from 'components/Card/Card';
import { useTranslation } from 'react-i18next';

import {
    DEFAULT_PAGE_SIZE,
    columns,
} from './constants/const';

import {useManagerProfile} from '../../../../prodivers/managerProfile';

const BusinessCase = () => {
    const { t } = useTranslation();

    const tableColumns = columns(t);

    const {
        applications, totalPages, investmentApplicationsError, investmentAppPageNumber, isInvestmentApplicationsLoading, setInvestmentAppPageNumber,
    } = useManagerProfile();

    const onChangePage = useCallback((paging) => {
        setInvestmentAppPageNumber(paging);
    }, [setInvestmentAppPageNumber]);

    return (
        <Card title={t('dashboard.agentCockpit.overviewClientApplications')}>
            <Preloader isLoading={isInvestmentApplicationsLoading} error={investmentApplicationsError}>
                <EmptyContent data={applications} text="Not Found">
                    <CustomTable
                        columns={tableColumns}
                        data={applications}
                        onChange={onChangePage}
                        paging={{
                            pageSize: DEFAULT_PAGE_SIZE,
                            pageNumber: investmentAppPageNumber,
                            totalPages,
                        }}
                    />
                </EmptyContent>
            </Preloader>
        </Card>
    );
};

export default memo(BusinessCase);
