/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { validateIban } from 'utils/validation';
import { portfolioSelector, usePortfolioSelector } from 'domain/Portfolio';
import { getClientId } from 'utils';
import { useProductsList } from 'hooks/useProducts';
import { useCachedAPI } from 'hooks/useCachedAPI';
import { useTranslation } from 'react-i18next';

import HandlerError from '../../../../../../../../../errors/HandlerError';
import handlerRequestCanceling from '../../../../../../../../../utils/handlerRequestCanceling';
import { useThreeBProductType } from '../../../../../../../../../hooks/isThreeBProduct';
import { getModel } from '../models/getModel';
import { sendModel } from '../models/sendModel';
import { useWithdrawalPlanBase } from './useWithdrawalPlanBase';
import { useChangePlan } from '../../../../ChangeStrategy/context';
import { UseWithdrawalPlanReturn } from '../types';
import usePortfolio from '../../../../../../../../../domain/Portfolio/context/usePortfolio';

export const useWithdrawalPlanChangePlan = (): UseWithdrawalPlanReturn => {
    const navigate = useNavigate();

    const { dfsClientId } = useParams();
    const contactId = getClientId(dfsClientId);
    const {lastApplication: {data: lastAppData, getLastApplication }} = usePortfolio();

    const [investmentId, setInvestmentId] = useState();
    const {
        i18n: { language },
    } = useTranslation();

    const {
        strategy: { data: strategyData, setData: saveWithdrawalData },

        layout: { setData: setLayoutData, setPageErrors },

        portfolioId,
    } = useChangePlan();

    const baseUrl = useMemo(
        () => `/client/${dfsClientId}/portfolios/${portfolioId}/change-strategy`,
        [dfsClientId, portfolioId],
    );

    const { getProducts, products } = useProductsList(contactId);

    useEffect(() => {
        (async () => getProducts())();
    }, [getProducts]);

    const { dataRaw: portfolio } = usePortfolioSelector(portfolioSelector);

    const selectedProduct = products?.find(
        ({ id }) => portfolio?.Product?.Id === id,
    );

    const productSettings = useMemo(
        () => selectedProduct?.settings,
        [selectedProduct],
    );

    const productExternalId = portfolio?.Product?.ExternalId;
    const {
        isAzp, isPk, isZiv, isZifd, isAzpI, isAzpD,
    } = useThreeBProductType(
        { product: { externalId: productExternalId } },
    );

    const { getLastApplicationByPortfolio } = useCachedAPI();

    const {
        data,
        setData,
        withdrawalPlanData,
        planCalculationLoading,
        errorData,
        trigger,
        setErrorData,
        isRegularTransferPlan,
        setIsRegularTransferPlan,
        memberId,
        option,
        isAtLeastOneProductOpened,
        setLoading,
        isLoading,
        optionWithdrawalPeriodicity,
        isOptionsLoading,
        calculateWithdrawalPlan,
        displayWithdrawalPlanCalculations,
        disableDateHandler,
        setPlanCalculationLoading,
        control,
        handleSubmit,
        register,
    } = useWithdrawalPlanBase({
        isAzp,
        isPk,
        isZiv,
        isZifd,
        productSettings,
        modelPortfolioId: strategyData?.newModelPortfolio?.Id,
        strategyId: strategyData?.strategyId,
        investmentApplicationId: investmentId,
        setPageErrors,
        productExternalId,
        productConfiguration: lastAppData?.investmentDescription?.productConfiguration,
    });

    useEffect(() => {
        const getInvestmentIdByPortfolioId = async () => {
            try {
                setPlanCalculationLoading(true);
                const lastApplicationByPortfolio = await getLastApplicationByPortfolio(
                    [{ portfolioId, language }],
                    { withCache: true },
                );

                setInvestmentId(
                    lastApplicationByPortfolio?.investmentApplicationId,
                );
            } catch (err) {
                handlerRequestCanceling(
                    HandlerError({
                        setError: setPageErrors,
                        setLoading: setPlanCalculationLoading,
                    }),
                )(err);
            }
        };

        if (portfolioId) {
            getInvestmentIdByPortfolioId();
        }
    }, [portfolioId, language]);

    useEffect(() => {
        const firstName = strategyData?.clientName?.split(' ')[0];
        const lastName = strategyData?.clientName?.split(' ')[1];

        const adaptedData = getModel(
            {
                withdrawal: strategyData?.newWithdrawalData?.withdrawalRequest,
                paymentInstruction:
                    strategyData?.newWithdrawalData?.paymentInstructionRequest,
            },
            { firstName, lastName, isAzp },
        );

        setData(adaptedData);
    }, [strategyData?.clientName, strategyData?.newWithdrawalData, isAzp]);

    const onContinue = useCallback(
        async (dta: any) => {
            try {
                const ibanError = await validateIban(dta?.iban);

                if (ibanError) {
                    setErrorData('iban', {
                        message: ibanError as string,
                    });

                    return;
                }

                saveWithdrawalData({
                    newWithdrawalData: sendModel({
                        data: dta,
                        isAzp,
                        ...(withdrawalPlanData || {}),
                    }),
                });
                navigate(`${baseUrl}/change-overview`);
            } catch (err) {
                handlerRequestCanceling(
                    HandlerError({
                        setError: setPageErrors,
                        setLoading,
                    }),
                )(err);
            }
        },
        [
            contactId,
            memberId,
            isAzp,
            withdrawalPlanData,
            isRegularTransferPlan,
        ],
    );

    const onBack = useCallback(() => {
        navigate(-1);
    }, []);

    useEffect(() => {
        setLayoutData({
            stepNavBarActive: 5,
            onBack,
            onContinue:
                handleSubmit && typeof handleSubmit === 'function'
                    ? handleSubmit(onContinue)
                    : onContinue,
            disabled: false,
        });
    }, [onBack, onContinue, handleSubmit]);

    useEffect(() => {
        getLastApplication({portfolioId});
    }, [getLastApplication]);

    return {
        data,
        withdrawalPlanData,
        planCalculationLoading,
        errorData,
        isRegularTransferPlan,
        optionWithdrawalPeriodicity,
        isLoading,
        isOptionsLoading,
        productExternalId,
        isAzp,
        isPk,
        isZiv,
        isZifd,
        isAzpI,
        isAzpD,
        calculateWithdrawalPlan,
        onContinue,
        onBack,
        displayWithdrawalPlanCalculations,
        disableDateHandler,
        setData,
        setErrorData,
        trigger,
        option,
        isAtLeastOneProductOpened,
        setIsRegularTransferPlan,
        setPlanCalculationLoading,
        control,
        register,
    };
};
