/* eslint-disable no-param-reassign */
import { closest, getDomIndex, getElements } from '../utils';

const transform = (x) => `translateX(${x}px)`;
const dragElement = document.createElement('div');

dragElement.classList.add('drag-element');

export const useDraggable = (
    dragEnd, ref, parentNode, overNode, dragNode, isDisabledTarget, draggableAlong,
) => {
    let toIndex = -1;
    let fromIndex = -1;
    let startNode = null;

    const getParentNode = (target) => closest(target, parentNode, ref);
    const getOverNode = (target) => {
        const overNodePass = overNode.find((node) => closest(target, node, ref));

        if (overNodePass) return closest(target, overNodePass, ref);

        return null;
    };
    const getDragNode = (target) => {
        const dragNodePass = dragNode.find((node) => closest(target, node, ref));

        if (dragNodePass) return closest(target, dragNodePass, ref);

        return null;
    };
    const moveElements = (node, elements, width, toInd) => {
        node.style.transform = transform(width);
        elements.forEach((item, i) => {
            elements[i].style.transform = transform(width);
        });

        setTimeout(() => {
            node.style.removeProperty('transform');
            startNode.style.removeProperty('transform');
            elements.forEach((item, i) => {
                elements[i].style.removeProperty('transform');
            });
            toIndex = toInd;
            dragEnd(fromIndex, toIndex);
        }, 100);
    };
    const onDragEnter = (e) => {
        const target = getOverNode(e.target);
        const parent = getParentNode(e.target);
        const toInd = getDomIndex(target);
        const disabled = isDisabledTarget(target);

        if (!disabled && fromIndex >= 0 && toIndex !== toInd) {
            if (draggableAlong && draggableAlong.length) {
                const elements = getElements(toInd, draggableAlong, parent);
                const startElements = getElements(fromIndex, draggableAlong, parent);

                if (fromIndex > toInd) {
                    moveElements(target, elements, startNode.offsetWidth, toInd);
                } else {
                    moveElements(startNode, startElements, target.offsetWidth, toInd);
                }
            }
        }
    };
    const onDragOver = (e) => {
        e.preventDefault();

        return true;
    };
    const onDragStart = (e) => {
        const target = getDragNode(e.target);
        const parent = getParentNode(target);
        const { childNodes } = target;
        const fromInd = getDomIndex(target);

        dragElement.innerHTML = childNodes[0].innerText;
        if (parent) parent.appendChild(dragElement);
        if (e.dataTransfer.setDragImage) {
            const { offsetWidth, offsetHeight } = dragElement;

            e.dataTransfer.setDragImage(dragElement, offsetWidth / 2, offsetHeight / 2);
        }
        if (parent) {
            parent.ondragenter = onDragEnter;
            parent.ondragover = onDragOver;
        }
        target.classList.add('draggable');
        toIndex = fromInd;
        startNode = target;
        fromIndex = fromInd;
    };
    const onDragEnd = (e) => {
        const target = getDragNode(e.target);
        const parent = getParentNode(target);

        dragElement.innerHTML = '';
        if (parent) parent.removeChild(dragElement);
        target.classList.remove('draggable');
        target.ondragstart = null;
        target.ondragend = null;
        if (parent) {
            parent.ondragenter = null;
            parent.ondragover = null;
        }
    };
    const onMouseDown = (e) => {
        const target = getDragNode(e.target);

        if (target && target.getAttribute('draggable')) {
            target.ondragstart = onDragStart;
            target.ondragend = onDragEnd;
        }
    };

    return onMouseDown;
};
