import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import OutsideClickHandler from 'react-outside-click-handler';
import { MultiSelectMiniForm } from '../../SelectInput';
import Icon, { ICON_TYPE_COLUMNS } from '../../Icon';
import Tooltip from '../../Tooltip';

const ColumnConfig = (props) => {
    const {
        columns, resetColumns, changeColumns, headerControls,
    } = props;
    const options = useMemo(
        () => columns.filter(({ titleType }) => titleType !== 'columns').map(({ key, title }) => ({ label: title || key, value: key })),
        [columns],
    );
    const selected = useMemo(
        () => columns.filter(({ hidden, titleType }) => !hidden && titleType !== 'columns').map(({ key }) => key),
        [columns],
    );
    const [opened, setOpened] = useState(false);
    const onChange = (value) => {
        setOpened(false);
        changeColumns(value);
    };
    const onOutsideClick = (e) => {
        if (!e.target.closest('.select-columns')) setOpened(false);
    };

    const content = (
        <OutsideClickHandler onOutsideClick={onOutsideClick} display="block">
            <MultiSelectMiniForm
                value={selected}
                options={options}
                onReset={resetColumns}
                onSelect={onChange}
                headerControls={headerControls}
                searchHidden
            />
        </OutsideClickHandler>
    );

    return (
        <div className="ColumnFilter ColumnConfig select select__multi-mini">
            <Tooltip
                title={content}
                overlayClassName="Table-filters"
                placement="bottomRight"
                trigger="click"
                visible={opened}
            >
                <Icon
                    type={ICON_TYPE_COLUMNS}
                    onClick={() => setOpened(!opened)}
                />
            </Tooltip>
        </div>
    );
};

ColumnConfig.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.string,
        title: PropTypes.string,
        width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        minWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        hidden: PropTypes.bool,
        sortable: PropTypes.bool,
        className: PropTypes.string,
    })),
    resetColumns: PropTypes.func,
    changeColumns: PropTypes.func,
    headerControls: PropTypes.shape({
        selectControlsLabel: PropTypes.shape({
            selectAll: PropTypes.string,
            reset: PropTypes.string,
        }),
        sortByLabel: PropTypes.string,
    }).isRequired,
};

ColumnConfig.defaultProps = {
    columns: [],
    resetColumns: null,
    changeColumns: () => {},
};

export default ColumnConfig;
