import React, { Suspense } from 'react';
import { Loader } from 'ui-library';
import {
    Route, Routes, Router, useNavigate,
} from 'react-router-dom';
import AutoLogout from 'domain/Auth/AutoLogout';
import withSignalR from 'hocs/withSignalR';
import { Router as RootRouter } from 'pages';
import ScrollToTop from 'components/ScrollToTop';
import ErrorBoundary from 'components/ErrorBoundary';
import { ApolloClientProvider } from 'services/ApolloClient';
import {getCurrentEnv} from './utils/support';

import './App.css';

const App = () => {
    const navigate = useNavigate();

    return (
        <Suspense fallback={<Loader/>}>
            <ApolloClientProvider>

                <ErrorBoundary navigate={navigate}>
                    <AutoLogout/>
                    <div className={`env-bar ${getCurrentEnv()} `}>
                        <strong>{getCurrentEnv().toUpperCase()}</strong>
                    </div>

                    <ScrollToTop/>
                    <Routes>
                        <Route
                            path="/*"
                            element={<RootRouter/>}
                        />
                    </Routes>

                </ErrorBoundary>

            </ApolloClientProvider>
        </Suspense>
    );
};

export default withSignalR(App);
