import React from 'react';
import {useTranslation} from 'react-i18next';
import {TabbedNavigation, TabbedNavigationLink as Link,} from 'ui-library';
import {useManagerProfile} from '../../prodivers/managerProfile';
import {ACCESS_POLICY} from '../../constants/constants';

type TopNavigationProps = {
    clientId: string;
    isDataAccessible: boolean;
}

export default function TopNavigation({
    clientId,
    isDataAccessible
}: TopNavigationProps) {
    const {t} = useTranslation();
    const {hasAccessPolicy} = useManagerProfile();

    return (
        <TabbedNavigation>
            <Link data-cy="overview" to={`/client/${clientId}/dashboard`}>{t('navigation.topNav.overView')}</Link>
            {isDataAccessible && <Link data-cy="documents"
                                       to={`/client/${clientId}/documents`}>{t('navigation.topNav.documents')}</Link>}
            <Link data-cy="details" to={`/client/${clientId}/details`}>{t('navigation.topNav.details')}</Link>
            {hasAccessPolicy(ACCESS_POLICY.EnableEditKyc) &&
                <Link data-cy="kyc" to={`/client/${clientId}/kyc`}>{t('navigation.topNav.kyc')}</Link>}
            {hasAccessPolicy('CustomerNotes') && <Link data-cy="customerNotes"
                                                       to={`/client/${clientId}/client-notes`}>{t('navigation.topNav.clientNotes')}</Link>}
            {(isDataAccessible || hasAccessPolicy('CustomerNotes')) && <Link data-cy="clientReports"
                                                                             to={`/client/${clientId}/reports`}>{t('navigation.topNav.clientReports')}</Link>}
            {hasAccessPolicy('ConnectionsTab') && <Link data-cy="connections"
                                                        to={`/client/${clientId}/connections`}>{t('navigation.topNav.connectionHistory')}</Link>}
            {hasAccessPolicy('ClientPortalTab') && <Link data-cy="activation"
                                                         to={`/client/${clientId}/client-portal`}>{t('navigation.topNav.clientPortal')}</Link>}
        </TabbedNavigation>
    );
}
