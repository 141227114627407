export const DEFAULT_PAGE_SIZE = 3;

export const START_PAGINATION_PAGE = 1;

export const CONTAINER_STATUS = {
    inProgress: 0,
    completed: 1,
};

export const CONTAINER_STATUS_TEXT = {
    [CONTAINER_STATUS.inProgress]: 'containerStatus.inProgress',
    [CONTAINER_STATUS.completed]: 'containerStatus.completed',
};

export const columns = (t) => [
    {
        key: 'applicationId',
        title: t('dashboard.overviewClientApplications.applicationId'),
    },
    {
        key: 'product',
        title: t('dashboard.agentCockpit.productWorld'),
    },
    {
        key: 'strategy',
        title: t('portfolio.createNewPortfolio.overview.strategy'),
    },
    {
        key: 'createdDate',
        title: t('dashboard.agentCockpit.createdDate'),
    },
    // {
    //     key: 'validTo',
    //     title: t('dashboard.agentCockpit.validTo'),
    // },
    {
        key: 'agent',
        title: t('dashboard.overviewClientApplications.agent'),
    },
    {
        key: 'submitted',
        title: t('dashboard.agentCockpit.submitted'),
    },
    {
        key: 'status',
        title: t('dashboard.agentCockpit.status'),
    },
    {
        key: 'createNewPlan',
        title: '',
    },
];

export const containersColumns = (t) => [
    {
        key: 'createdDate',
        title: t('dashboard.agentCockpit.createdDate'),
    },
    {
        key: 'advisorName',
        title: t('dashboard.overviewClientApplications.advisor'),
    },
    {
        key: 'contacts',
        title: t('allocationColumns.name'),
    },
    {
        key: 'product',
        title: t('dashboard.agentCockpit.productWorld'),
    },
    {
        key: 'status',
        title: t('dashboard.agentCockpit.status'),
    },
    {
        key: 'createNewPlan',
        title: '',
    },
];

export const applicationListColumns = (t) => [
    {
        key: 'applicationId',
        title: t('dashboard.overviewClientApplications.applicationId'),
    },
    {
        key: 'advisory',
        title: t('dashboard.overviewClientApplications.advisory'),
    },
    {
        key: 'products',
        title: t('dashboard.agentCockpit.productWorld'),
    },
    {
        key: 'strategy',
        title: t('portfolio.createNewPortfolio.overview.strategy'),
    },
    {
        key: 'createdDate',
        title: t('dashboard.agentCockpit.createdDate'),
    },
    {
        key: 'advisorName',
        title: t('dashboard.overviewClientApplications.advisor'),
    },
    {
        key: 'status',
        title: t('dashboard.agentCockpit.status'),
    },
    {
        key: 'createNewPlan',
        title: '',
    },
    ...globalThis?.AppConfig?.ADVISORY_PROCESS_FEATURE_FLAG === 'true' ? [({
        key: 'applicationDetails',
        title: '',
    })] : [],
];
