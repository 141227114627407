import { getDateFormat } from 'libs/additiv-intl-formatting';
import { formatTime, dateTimeStandardFormat } from 'utils/datetime';
import { parseXml } from 'utils/xmlParser';
import { dateTimeFormats } from './formatTypes';

export function getUTCDate(date) {
    const d1 = formatTime(date, dateTimeStandardFormat);
    const a = `${d1}Z`.split(/[^0-9]/);

    return new Date(a[0], a[1] - 1, a[2], a[3], a[4], a[5]);
}

export function getCETDate(date, locale) {
    return new Date(date).toLocaleString(locale, { timeZone: 'CET' });
}

export function getNumberSeparators(locale) {
    const formattedNum = new Intl.NumberFormat(locale).format(1111.11);

    const [thousandSeparator, decimalSeparator] = [formattedNum[1], formattedNum[5]];

    return {
        thousandSeparator,
        decimalSeparator,
    };
}

export function getDateTimeSeparators(locale) {
    const formattedDate = new Intl.DateTimeFormat(locale).format(new Date());
    const formattedTime = getDateFormat({
        locale,
        date: getUTCDate(new Date()),
        options: {
            hour: dateTimeFormats.TWO_DIGIT,
            minute: dateTimeFormats.TWO_DIGIT,
        },
    });

    const [dateSeparator] = formattedDate.match(/[^\w\s]/g);
    const [timeSeparator] = formattedTime.match(/[^\w\s]/g);

    return {
        dateSeparator,
        timeSeparator,
    };
}

export function getDateLocalFormat(locale) {
    const formatObj = new Intl.DateTimeFormat(locale).formatToParts(new Date());
    const dateFormat = (formatObj || []).map((element) => {
        switch (element?.type) {
            case 'day':
                return 'DD';
            case 'month':
                return 'MM';
            case 'year':
                return 'YY';
            default:
                return element?.value;
        }
    });

    const dateLocaleFormat = {
        formatString: dateFormat.join(''),
        firstElement: dateFormat[0][0].toLocaleLowerCase(),
        secondElement: dateFormat[2][0].toLocaleLowerCase(),
    };

    return dateLocaleFormat;
}

export const parseLocaleNumber = (stringNumber = '', locale) => {
    const thousandSeparator = Intl.NumberFormat(locale).format(11111).replace(/\p{Number}/gu, '');
    const decimalSeparator = Intl.NumberFormat(locale).format(1.1).replace(/\p{Number}/gu, '');

    return parseFloat(stringNumber
        .replace(new RegExp(`\\${thousandSeparator}`, 'g'), '')
        .replace(new RegExp(`\\${decimalSeparator}`), '.'));
};


export const getLocaleString = (string, language) => {
    const parsedString = parseXml(string);
    const { root } = parsedString;

    if (root) {
        const localeString = root[language] || root.en;

        return localeString['#text'];
    }

    return string;
};
