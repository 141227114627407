import {arrayOf, func, shape, string,} from 'prop-types';
import React from 'react';

import {getClassNames} from '../../../utils';

import 'ui-library/components/Label/Label.css';
import 'components/AtomicStructure/atoms/styles/ButtonRadioElement.css';
import './ActionButtons.css';

const ActionButtons = ({actions}) => (
    <div className="ActionButtonGroups">
        {actions.map(action => (
            <fieldset className="ActionButtonGroup" key={action.title}>
                <legend className="Form-control-label">{action.title}</legend>
                {action.buttons && (
                    <div className="form-group-controls">
                        {action.buttons.map(button => (
                            <button
                                key={button.title}
                                onClick={button.onClick}
                                type="button"
                                className={getClassNames(
                                    'buttonRadioElement',
                                    action.activeAction === button.title
                                        ? 'buttonRadioElementNotSelected'
                                        : '',
                                )}
                            >
                                {button.title}
                            </button>
                        ))}
                    </div>
                )}
            </fieldset>
        ))}
    </div>
);

export const actionsPropTypes = arrayOf(
    shape({
        title: string,
        buttons: arrayOf(
            shape({
                title: string,
                onclick: func,
            }),
        ),
        activeAction: string,
    }),
);

ActionButtons.propTypes = {
    actions: actionsPropTypes.isRequired,
};

export default ActionButtons;
