import {useQuery} from '@tanstack/react-query';
import ServiceManager from '../../services/ServiceManager';
import ServerError from '../../errors/ServerError';

export const useGetCheckIfParentalAuthority = ({clientId, isEnabled = true}) => {
    const result = useQuery({
        queryKey: ['getCheckIfParentalAuthority', clientId],
        enabled: !!clientId && isEnabled,
        retry: false,
        gcTime: 10,
        queryFn: async () => {
            const {data} = await ServiceManager.customClientService('isParentalAuthorityAvailable', [clientId]);

            return data;
        },
    });

    return {
        ...result,
        error: result.error ? new ServerError(result.error) : result.error,
    };
};
