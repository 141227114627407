import React, {useMemo} from 'react';
import {
    Route, Routes, useLocation, useParams, useMatch, Navigate,
} from 'react-router-dom';
import ConfirmationPage from 'components/ConfirmationPage';
import { ClientProfileProvider } from 'prodivers/clientProfile';
import {ClientKYC} from 'pages/ClientOverview/pages/KYC';
import {ACCESS_POLICY} from 'constants/constants';
import PortfoliosRouter from './pages/Portfolios/Router';
import Details from './pages/Details';
import Documents from './pages/Documents/Router';
import Dashboard from './pages/Dashboard/Dashboard';
import Confirmation from './pages/Confirmation/Confirmation';
import { getClientId } from '../../utils';
import CloseClient from './components/CloseClient';
import KuDiCockpit from './pages/Portfolios/pages/SinglePortfolioView/pages/KuDiCockpit/KuDiCockpit';
import ClientNotes from './pages/ClientNotes/ClientNotes';
import {ClientPortal} from './pages/ClientPortal';

import {ClientReports} from './pages/ClientReports';
import { ClientConnectionsRouter } from './pages/ClientConnections';
import {useManagerProfile} from '../../prodivers/managerProfile';
import Preloader from '../../components/Preloader';
import ApplicationOverviewRouter from './pages/ClientApplicationOverview/Router';

import './ClientOverview.css';

const Router = () => {
    const {dfsClientId} = useParams();
    const { state, pathname } = useLocation();
    const url = useMemo(() => pathname.split('/').slice(0, 3).join('/'), [pathname]);

    const clientId = getClientId(dfsClientId);
    const {hasAccessPolicy, isPolicyLoading} = useManagerProfile();
    const withPolicyCheck = (policy, Component) => (defProps) => (
        <Preloader isLoading={isPolicyLoading}>
            {hasAccessPolicy(policy)
                ? <Component {...defProps} />
                : <Navigate to={`${defProps?.match?.params?.dfsClientId}/dashboard`} replace />}
        </Preloader>
    );

    const ClientPortalWrapped = useMemo(() => withPolicyCheck('ClientPortalTab', ClientPortal), [withPolicyCheck]);
    const ClientConnectionsRouterWrapped = useMemo(() => withPolicyCheck('ConnectionsTab', ClientConnectionsRouter), [withPolicyCheck]);
    const ClientKYCRouterWrapped = useMemo(() => withPolicyCheck(ACCESS_POLICY.EnableEditKyc, ClientKYC), [withPolicyCheck]);

    return (
        <ClientProfileProvider
            clientId={clientId}
            clientName={state?.clientName}
            isProspect={state?.isProspect}
            withAdditionalInfo
        >
            <CloseClient url={url} state={state} />
            <Routes>
                <Route path="dashboard" element={<Dashboard/>} />
                <Route path="confirm" element={<Confirmation/>} />
                <Route path="success-page" element={<ConfirmationPage/>} />
                <Route path="client-portal" element={<ClientPortalWrapped/>} />
                <Route path="kyc" element={<ClientKYCRouterWrapped/>} />
                <Route path="details" element={<Details/>} />
                <Route path="portfolios/*" element={<PortfoliosRouter/>} />
                <Route path="connections/*" element={<ClientConnectionsRouterWrapped/>} />
                <Route path="/*" element={<ApplicationOverviewRouter/>} />
                <Route path="documents/*" element={<Documents/>} />
                <Route path="client-notes" element={<ClientNotes/>} />
                <Route path="reports" element={<ClientReports/>} />
                <Route path="kudi-cockpit/*" element={<KuDiCockpit/>} />
                <Route
                    path=""
                    element={<Navigate to="dashboard" replace />}
                />
                <Route
                    path="*"
                    element={<Navigate to="/404" replace />}
                />
            </Routes>
        </ClientProfileProvider>
    );
};

export default Router;
