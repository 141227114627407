import LogoEmblem from 'assets/images/logo/main_logo-emblem.svg';

// zurich
import LogoLarge from 'assets/images/logo/main_logo.svg';
import LightLogoSvg from 'assets/images/logo/MobileAdvisor-light.svg';
import DarkLogoSvg from 'assets/images/logo/MobileAdvisor.svg';
import './Logo.css';
import {useDetectedMobileDevice} from 'hooks/useDetectedMobileSize';
import React from 'react';
import {useTranslation} from 'react-i18next';
import useTheme from 'theming/useTheme';

export const Logo = () => {
    const {t} = useTranslation();
    const {theme} = useTheme();

    const {isMobileSize} = useDetectedMobileDevice();

    const themeLogo = theme?.includes('prestige') ? DarkLogoSvg : LightLogoSvg;

    return (
        <div className="">
            {/* <img src={isMobileSize ? MobileAdvisorLogoSvg : themeLogo} alt={t('login.title')} className="logo-icon" /> */}
            <img className="mainLogo" src={LogoLarge} alt={t('login.title')}/>
            <img className="mainLogo-sm" src={LogoEmblem} alt={t('login.title')}/>
        </div>
    );
};
