import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {useApplicationList} from 'hooks/rest/useApplicationList';
import {applicationsAdapter} from '../applicationListAdapter';
import {DEFAULT_PAGE_SIZE} from '../constants/const';
import {useFormatting} from '../../../../../../../locale';
import {memberIdSelector} from '../../../../../../../redux-store/auth/authSelectors';
import {getClientId} from '../../../../../../../utils';

export const useApplicationTable = () => {
    const memberId = useSelector(memberIdSelector);
    const { dfsClientId } = useParams();
    const contactId = getClientId(dfsClientId);
    const [list, setList] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const {t, i18n: {language}} = useTranslation();
    const { getFormattedDate } = useFormatting();
    const {
        data,
        isLoading,
        error,
    } = useApplicationList({
        contactId,
        memberId,
        language,
        pageSize: DEFAULT_PAGE_SIZE,
        page,
    });

    useEffect(() => {
        const { applications, totalPages: total } = applicationsAdapter({data, t, getFormattedDate });

        setList(applications);
        setTotalPages(total);
    }, [data]);

    return {
        list,
        totalPages,
        page,
        setPage,
        isLoading,
        error,
    };
};
