import ServiceManager from '../services/ServiceManager';

export const checkIfEmailIsInUse = async (contactId, email) => {
    try {
        const {data: isEmailExist} = await ServiceManager.customClientService('checkIfEmailIsInUse', [{contactId, email }]);

        return isEmailExist;
    } catch (err) {
        return false;
    }
};

export const checkIfEmailIsAvailable = async (email) => {
    try {
        const {data: isEmailInUse} = await ServiceManager.customClientService('checkIfEmailIsAvailable', [{email}]);

        return !isEmailInUse;
    } catch (err) {
        return true;
    }
};

export const merge = (a, b, predicate = (aItem, bItem) => aItem === bItem) => {
    const c = [...a]; // copy to avoid side effects

    // add all items from B to copy C if they're not already present
    b.forEach((bItem) => (c.some((cItem) => predicate(bItem, cItem)) ? null : c.push(bItem)));

    return c;
};
