import { createContext } from 'react';

const initialContext = {
};

export interface ClientApplicationOverviewContextType {
    clientId?: number;
    dfsClientId?: string;
    appsData: any[] | null; // Adjust the type based on your actual data structure
    isAppsLoading: boolean;
    appsError: any; // Adjust the type based on your actual error structure
    getInvestmentApplications: () => void;
}

const ClientApplicationOverviewContext = createContext<ClientApplicationOverviewContextType | Record<string, never>>(initialContext);

export default ClientApplicationOverviewContext;
