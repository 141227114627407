import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import CellTooltiped from './CellTooltiped';
import Cell from '../Body/Cell';
import { cellValue } from '../utils/types';

function CellLinked(props) {
    const value = typeof props === 'object' ? props.value : props;
    const isLink = !!props.value && !!props.link;

    if (!isLink) return Cell(value);

    const trimmedValue = useMemo(() => (
        value.length > props.maxLength
            ? `${value.substring(0, props.maxLength)}...`
            : value
    ),
    [value, props.maxLength]);

    return (
        props.link
            ? (
                <Link to={props.link} className="table-cell_linked">
                    <CellTooltiped {...props} />
                </Link>
            )
            : (
                <span className="table-cell">{trimmedValue}</span>
            )
    );
}

CellLinked.propTypes = {
    value: cellValue,
    link: PropTypes.string.isRequired,
};

CellLinked.defaultProps = {
    value: null,
};

export default CellLinked;
