import React from 'react';
import BusinessContainers from 'pages/AdvisoryDashboard/components/BusniessContainers/BusinessContainers';
import Overview from './Overview';
// import BusinessTransactionsTable from './BusinessTransactionsTable/BusinessTransactionsTable';
import BusinessCase from './BusinessCase/BusinessCase';

const WidgetsObject = () => {
    const Widgets = (props) => ({
        Overview: <Overview {...props} />,
        // BusinessTransactionsTable: <BusinessTransactionsTable {...businessTransactionsTable} {...props} />,
        BusinessCase: <BusinessCase {...props} />,
        Containers: <BusinessContainers {...props} />,
    });

    return Widgets;
};

export default WidgetsObject;
