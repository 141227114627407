import React, {
    useEffect, useState, useRef, useMemo, useCallback,
} from 'react';
import PropTypes, {func, number, oneOfType, shape, string} from 'prop-types';
import Row from './Body/Row';
import Header from './Header/Header';
import Footer from './Footer/Footer';
import ScrollableColumns from './Header/ScrollableColumns';
import Pagination from '../Pagination';
import { pagingState, sortState, filteringState } from './utils/types';
import { getClassNames } from '../../utils';
import './Table.css';
import { useTableConfig } from './hooks/useTableConfig';
import { setDataKeys, getUpdatedSnapshot, onRowFunction } from './utils/utils';
import NoDataCell from './NoDataCell';
import TableMini from './TableMini';
import withTheme from '../../hocs/withTheme';
import {rowPropType} from "../../../components/CustomTable/TableRow";

const Table = (props) => {
    const {
        data: dataInit,
        mini,
        footer,
        scroll,
        noHeader,
        className,
        noDataText,
        orderColumns,
        onRow,
        headerControls,
        onChange,
        wrapperClass = ''
    } = props;
    const table = useRef(null);
    const dataSource = useMemo(() => setDataKeys(dataInit), [dataInit]);
    const [data, setData] = useState(dataSource);
    const {
        columns,
        footerColumns,
        fixedLastColumn,
        paging,
        pagingTotal,
        sorting,
        filtering,
        expandedRows,
        expandOnClick,
        changeColumns,
        resetColumns,
        changeSorting,
        changeFiltering,
        changePagination,
        onMouseDown,
    } = useTableConfig({ ...props, table, dataSource });
    const scrollable = useMemo(() => !!scroll, [scroll]);
    const classNameTable = useMemo(() => getClassNames(
        'Table',
        'table',
        className,
        { scrollable },
        { 'Table-mini': mini },
        { 'Table-no-header': noHeader },
        { 'Table-draggable': orderColumns },
        { 'Table-with-clickable-row': typeof (onRow) === 'function' },
        { hasPagination: paging?.currentPage && data?.length > 0 && pagingTotal > data?.length },
    ),
    [
        className,
        scrollable,
        mini,
        noHeader,
        orderColumns,
        onRow,
        paging?.currentPage,
        data?.length,
        pagingTotal,
    ]);
    const classNameTableWrapper = getClassNames('Table-wrapper', 'table-wrapper', wrapperClass, { scrollable }, { 'fixed-last-column': fixedLastColumn });
    const classNameTableContent = getClassNames('Table-content', { 'fixed-last-column': fixedLastColumn });
    const tableBodyStyle = useMemo(() => (scroll ? { maxHeight: scroll.y } : {}), [scroll]);

    const changeSortingHandler = useCallback((e) => {
        changeSorting(e);
        if (typeof props.onChangeSort === 'function') props.onChangeSort(e);
    }, [props.onChangeSort, changeSorting]);

    const changeFilteringHandler = useCallback((e) => {
        changeFiltering(e);
        if (typeof props.onChangeFilter === 'function') props.onChangeFilter(e);
    }, [props.onChangeFilter, changeFiltering]);

    useEffect(() => {
        const {
            data: dataUpdated,
            total,
        } = getUpdatedSnapshot({
            data: dataSource,
            columns,
            sorting,
            filtering,
            paging,
        });

        setData(dataUpdated);
        if (typeof onChange === 'function') {
            onChange(
                paging,
                filtering,
                sorting,
                { currentDataSource: dataSource, updatedData: dataUpdated, updatedTotal: total },
            );
        }
    }, [dataSource, sorting, filtering, paging]);

    if (mini) {
        return (
            <TableMini
                table={table}
                columns={columns}
                data={data}
                footer={footer}
                mini={mini}
                paging={paging}
                sorting={sorting}
                scrollable={scrollable}
                tableBodyStyle={tableBodyStyle}
                expandedRows={expandedRows}
                pagingTotal={pagingTotal}
                noDataText={noDataText}
                headerControls={headerControls}
                classNameTable={classNameTable}
                classNameTableWrapper={classNameTableWrapper}
                onRow={onRow}
                onMouseDown={onMouseDown}
                changeSorting={changeSortingHandler}
                expandOnClick={expandOnClick}
                changePagination={changePagination}
                noHeader={noHeader}
            />
        );
    }

    return (
        <div
            ref={table}
            className={classNameTableWrapper}
            onMouseDown={onMouseDown}
            onPointerDown={onMouseDown}
        >
            {scrollable && (
                <table className={classNameTable}>
                    <ScrollableColumns scrollable={scrollable} columns={columns} />
                    <Header
                        columns={columns}
                        sorting={sorting}
                        filtering={filtering}
                        resetColumns={resetColumns}
                        changeColumns={changeColumns}
                        changeSorting={changeSortingHandler}
                        headerControls={headerControls}
                        changeFiltering={changeFilteringHandler}
                    />
                </table>
            )}

            <div className={classNameTableContent} style={tableBodyStyle}>
                <table className={classNameTable}>
                    <ScrollableColumns scrollable={scrollable} columns={columns} />
                    {!scrollable && (
                        <Header
                            columns={columns}
                            sorting={sorting}
                            filtering={filtering}
                            resetColumns={resetColumns}
                            changeColumns={changeColumns}
                            changeSorting={changeSortingHandler}
                            headerControls={headerControls}
                            changeFiltering={changeFilteringHandler}
                        />
                    )}
                    <tbody>
                        {data.length
                            ? data.map((item, index) => (
                                <Row
                                    data={item}
                                    index={index}
                                    columns={columns}
                                    scrollable={scrollable}
                                    expandedRows={expandedRows}
                                    onRow={onRowFunction(onRow)}
                                    expandOnClick={expandOnClick}
                                    key={`row-${item.key}`}
                                />
                            ))
                            : <NoDataCell colSpan={columns.length} noDataText={noDataText} />
                        }
                    </tbody>
                    {!scrollable && <Footer columns={footerColumns} footer={footer} />}
                </table>
            </div>
            {scrollable && (
                <table className={classNameTable}>
                    <ScrollableColumns scrollable={scrollable} columns={columns} />
                    <Footer columns={footerColumns} footer={footer} />
                </table>
            )}
            {(!paging.hidePaging && paging.currentPage && data.length > 0 && pagingTotal > data.length) && (
                <Pagination {...paging} total={pagingTotal} onChange={changePagination} />
            )}
        </div>
    );
};

const columnType = PropTypes.arrayOf(PropTypes.shape({
    [PropTypes.string]: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.shape,
        PropTypes.bool,
        PropTypes.func,
    ]),
}));

Table.propTypes = {
    /** Columns of table */
    columns: columnType,
    footerColumns: columnType,
    /** Data record array to be displayed */
    data: PropTypes.arrayOf(PropTypes.shape({
        [PropTypes.string]: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
            PropTypes.shape,
            PropTypes.func,
        ]),
    })),
    /** Setting the default sorting for the column use
    * an object {key: string, direction: string } to define the
    * column key and 'asc' or 'desc' sort order */
    sorting: sortState,
    filtering: filteringState,
    noDataText: PropTypes.string,
    noHeader: PropTypes.bool,
    scroll: PropTypes.shape({
        y: PropTypes.number,
    }),
    footer: PropTypes.shape({
        [PropTypes.string]: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
            PropTypes.func,
        ]),
    }),
    mini: PropTypes.bool,
    className: PropTypes.string,
    paging: pagingState,
    orderColumns: PropTypes.bool,
    expandedColumn: PropTypes.string,
    expandedRowKeys: PropTypes.arrayOf(PropTypes.string),
    defaultExpandAllRows: PropTypes.bool,
    rowSelection: PropTypes.shape({
        type: PropTypes.oneOf(['radio', 'checkbox']),
        selectedRowKeys: PropTypes.arrayOf(PropTypes.string),
        onChange: PropTypes.func,
    }),
    onRow: PropTypes.func,
    onChange: PropTypes.func,
    onChangeFilter: PropTypes.func,
    onChangeSort: PropTypes.func,
    handleSort: PropTypes.func,
    headerControls: PropTypes.shape({
        selectControlsLabel: PropTypes.shape({
            selectAll: PropTypes.string,
            reset: PropTypes.string,
        }),
        sortByLabel: PropTypes.string,
    }),
    fixedLastColumn: PropTypes.bool,

};

Table.defaultProps = {
    columns: [],
    footerColumns: [],
    data: [],
    sorting: null,
    filtering: null,
    noDataText: 'No data',
    noHeader: false,
    scroll: null,
    footer: null,
    mini: false,
    className: '',
    paging: null,
    onRow: null,
    orderColumns: false,
    expandedColumn: null,
    expandedRowKeys: [],
    defaultExpandAllRows: false,
    rowSelection: null,
    onChange: () => {
    },
    onChangeFilter: () => {
    },
    onChangeSort: () => {
    },
    handleSort: () => {
    },
    headerControls: {
        selectControlsLabel: {
            selectAll: 'Select all',
            reset: 'Reset',
        },
        sortByLabel: 'By:',
    },
    fixedLastColumn: false,
};

export default withTheme(Table);
