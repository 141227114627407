export const columnDefs = (t) => [
    {
        key: 'name',
        title: t('portfolio.createNewPortfolio.productName'),
    },
    {
        key: 'allocation',
        title: t('portfolio.createNewPortfolio.instrumentAllocation'),
    },
];

export const nextReallocationColumnDefs = (t, formatDate) => [
    {
        key: 'date',
        title: t('shadowAccount.transactions.date'),
        render: (val) => formatDate(val),
    },
    {
        key: 'strategyName',
        title: t('clientDashboard.portfolio.strategy'),
    },
];
