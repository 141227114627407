import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useGetDocumentsByInvestmentApplicationId } from 'hooks/rest/useGetDocumentsByInvestmentApplicationId';
import { getClientId } from '../../../../../utils';

export const use3aVBApplicationDetails = ({
    investmentAppsData,
}) => {
    const { dfsClientId } = useParams();
    const contactId = useMemo(() => (getClientId(dfsClientId)), [dfsClientId]);

    const {
        data: applicationDocument,
        isLoading: isApplicationDocsLoading,
    } = useGetDocumentsByInvestmentApplicationId({
        investmentApplicationId: investmentAppsData?.[0]?.investmentApplicationId,
        contactId,
    });

    return {
        isJA: false,
        jaUsers: undefined,
        isGetGeneralDocumentsLoading: false,
        isApplicationDocsLoading,
        isAdvisoryCase: false,
        generalDocumentsData: undefined,
        contactGroupId: undefined,
        proofOfAssetsDocsData: undefined,
        advisoryDocId: undefined,
        advisoryDetailsData: undefined,
        advisoryData: undefined,
        applicationsDocumentsData: applicationDocument,
    };
};
