import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { TITLE_TYPES } from './constants';
import Icon from '../Icon';
import { ICON_TYPE_ARROW } from '../Icon/constants';
import { getClassNames } from '../../utils';
import './Title.css';
import withTheme from '../../hocs/withTheme';

/** Pages and blocks' headers. */
function Title(props) {
    const {
        type, link, children, className, tagName, ...rest
    } = props;

    const Heading = !tagName ? `h${type}` : false;

    const classNames = getClassNames(
        'Title',
        className,
    );

    const TagName = tagName || Heading;

    const InnerTitle = ({ link, text }) => {
        const externalLink = /^https?:\/\//.test(link);

        if (externalLink) {
            return (
                <a href={link} target="_blank" rel="noopener noreferrer">
                    {text}
                    <Icon type={ICON_TYPE_ARROW} />
                </a>
            );
        } if (link) {
            return (
                <Link to={link}>
                    {text}
                    <Icon type={ICON_TYPE_ARROW} />
                </Link>
            );
        }

        return text;
    };

    return (
        <TagName className={classNames} {...rest}><InnerTitle link={link} text={children} /></TagName>
    );
}

Title.propTypes = {
    /** Additional class to h1-h4 */
    className: PropTypes.string,
    tagName: PropTypes.string,
    /** Can be set to '1', '2', '3', '4' */
    type: PropTypes.oneOf(TITLE_TYPES),
    /** Whether the title has link. It should be marked with icon */
    link: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    /** @ignored */
    children: PropTypes.node,
};

Title.defaultProps = {
    className: null,
    type: 1,
    link: null,
    children: null,
    tagName: '',
};

export default withTheme(Title);
