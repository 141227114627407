import { tickLength, defaultScatterChartOptions, pointRadiusHover as pointRadius } from './constants';

const getTooltipPosition = (tooltip, points, width) => {
    const arrow = 7;
    const isTooltipOut = (areaPointTL) => {
        const isPointOnTop = areaPointTL.y < 0;
        const isPointOnRight = areaPointTL.x + tooltip.width > width;
        const isPointOnLeft = areaPointTL.x < 0;

        return isPointOnTop || isPointOnRight || isPointOnLeft;
    };
    const isPointInArea = (areaPointTL, point) => point.x > areaPointTL.x
        && point.x + pointRadius < areaPointTL.x + tooltip.width
        && point.y > areaPointTL.y
        && point.y < areaPointTL.y + tooltip.height;
    const calculatePointsInArea = (areaPointTL) => {
        const pointInArea = points.filter((point) => isPointInArea(areaPointTL, point));

        return pointInArea.length;
    };

    // top
    const top = { x: tooltip.x - tooltip.width / 2, y: tooltip.y - tooltip.height - pointRadius };
    const topPoints = calculatePointsInArea(top) + isTooltipOut(top);

    if (topPoints === 0) return top;
    // right
    // eslint-disable-next-line max-len
    const right = { x: tooltip.x + arrow + pointRadius * 2, y: tooltip.y - tooltip.height / 2 + arrow };
    const rightPoints = calculatePointsInArea(right) + isTooltipOut(right);

    if (rightPoints === 0) return right;
    // topRight
    const topRight = { x: tooltip.x + pointRadius * 2, y: tooltip.y - tooltip.height - arrow };
    const topRightPoints = calculatePointsInArea(topRight) + isTooltipOut(topRight);

    if (topRightPoints === 0) return topRight;
    // bottomRight
    const bottomRight = { x: tooltip.x + pointRadius * 2, y: tooltip.y + pointRadius * 2 };
    const bottomRightPoints = calculatePointsInArea(bottomRight) + isTooltipOut(bottomRight);

    if (bottomRightPoints === 0) return bottomRight;
    // bottom
    const bottom = { x: tooltip.x - tooltip.width / 2, y: tooltip.y + arrow + pointRadius * 2 };
    const bottomPoints = calculatePointsInArea(bottom) + isTooltipOut(bottom);

    if (bottomPoints === 0) return bottom;
    // left
    const left = { x: tooltip.x - tooltip.width - pointRadius, y: tooltip.y - tooltip.height / 2 };
    const leftPoints = calculatePointsInArea(left) + isTooltipOut(left);

    if (leftPoints === 0) return left;
    // topLeft
    const topLeft = { x: tooltip.x - tooltip.width, y: tooltip.y - tooltip.height };
    const topLeftPoints = calculatePointsInArea(topLeft) + isTooltipOut(topLeft);

    if (topLeftPoints === 0) return topLeft;
    // bottomLeft
    const bottomLeft = { x: tooltip.x - tooltip.width, y: tooltip.y + pointRadius * 2 };
    const bottomLeftPoints = calculatePointsInArea(bottomLeft) + isTooltipOut(bottomLeft);

    if (bottomLeftPoints === 0) return bottomLeft;

    // Find less covered points in position
    const positions = {
        top,
        right,
        topRight,
        bottomRight,
        bottom,
        left,
        topLeft,
        bottomLeft,
    };
    const pointsQnt = {
        top: topPoints,
        right: rightPoints,
        topRight: topRightPoints,
        bottomRight: bottomRightPoints,
        bottom: bottomPoints,
        left: leftPoints,
        topLeft: topLeftPoints,
        bottomLeft: bottomLeftPoints,
    };
    const sortable = Object.keys(pointsQnt).sort((a, b) => pointsQnt[a] - pointsQnt[b]);

    return positions[sortable[0]];
};

export const getScatterChartOptions = (
    data,
    matrix,
    height,
    minX,
    maxX,
    minY,
    maxY,
    xLabels,
    yLabels,
    tooltipFormat,
) => {
    function tooltipFormatter() {
        return `${tooltipFormat(this.series.name, this.x, this.y)}`;
    }
    function tickPositionerX() {
        const diff = maxX - minX;

        return [...Array(tickLength).keys()].map((k) => diff / (tickLength - 1) * k + minX);
    }
    function tickPositionerY() {
        const diff = maxY - minY;

        return [...Array(tickLength).keys()].map((k) => diff / (tickLength - 1) * k + minY);
    }
    function tooltipPositioner(widthT, heightT, point) {
        const points = this.chart.series
            .map(({ data }) => ({ x: data[0].plotX, y: data[0].plotY }));
        const tooltip = {
            x: point.plotX,
            y: point.plotY,
            width: widthT,
            height: heightT,
        };

        return getTooltipPosition(tooltip, points, this.chart.plotSizeX);
    }
    const dataPolygon = matrix ? {
        type: 'polygon',
        colorIndex: 99,
        data: [
            { id: '1', x: matrix.x?.[0] || minX, y: matrix.y?.[0] || minY },
            { id: '2', x: matrix.x?.[1] || maxX, y: matrix.y?.[0] || minY },
            { id: '3', x: matrix.x?.[1] || maxX, y: matrix.y?.[1] || maxY },
            { id: '4', x: matrix.x?.[0] || minX, y: matrix.y?.[1] || maxY },
        ],
    } : undefined;
    const series = dataPolygon ? [dataPolygon, ...data] : [...data];

    return {
        ...defaultScatterChartOptions,
        chart: {
            ...defaultScatterChartOptions.chart,
            height,
            width: height,
        },
        xAxis: {
            ...defaultScatterChartOptions.xAxis,
            min: minX,
            max: maxX,
            tickPositioner: tickPositionerX,
        },
        yAxis: {
            ...defaultScatterChartOptions.yAxis,
            min: minY,
            max: maxY,
            tickPositioner: tickPositionerY,
        },
        tooltip: {
            ...defaultScatterChartOptions.tooltip,
            formatter: tooltipFormatter,
            positioner: tooltipPositioner,
        },
        series,
    };
};
