import { getAdaptedDate } from 'utils/formatting';
import { dateTimeFormats } from 'locale';
import { getLocaleString } from 'locale/utils';
import { removeExtension } from 'utils/file';

export const getNames = (data) => {
    const listOfNames = new Set();

    data.forEach((val) => (listOfNames.add(val.name)));

    return [...listOfNames];
};

const getProduct = ({ contractId, portfolios }) => {
    const notAvailable = 'N/A';

    if (!contractId || !portfolios?.length) return notAvailable;

    const findPortfolio = portfolios.find(({ UId }) => UId === contractId);

    const { Name } = findPortfolio;

    return Name;
};

const getPortfolio = ({
    contractId, portfolios, currentPage, currentTab,
}) => {
    const notAvailable = { label: 'N/A', link: '', disabled: true };

    if (!contractId || !portfolios) return notAvailable;

    const findPortfolio = portfolios.find(({ UId }) => UId === contractId);

    if (!findPortfolio) return notAvailable;

    const { Name } = findPortfolio;

    return {
        ...Name,
        state: {
            fromDocs: 'documents',
            currentPage,
            currentTab,
        },
    };
};

const getUnifiedDocumentPortfolio = ({
    portfolioName, currentPage, currentTab, portfolioId, clientId,
}) => {
    if (!portfolioName) {
        return { label: 'N/A', link: '', disabled: true };
    }

    return {
        label: portfolioName,
        link: `client/${clientId}/portfolios/${portfolioId}/tab/overview`,
        disabled: false,
        state: {
            fromDocs: 'documents',
            currentPage,
            currentTab,
        },
    };
};

export const adaptDocuments = (
    data,
    downloadDocument,
    t,
    i18n,
    getFormattedDate,
    portfolios,
    tab,
) => (data?.results || [])
    .map((item) => {
        const {
            id,
            contractId,
            validTill,
            createDate,
            externalId,
            filename,
            originalFilename,
            documentMetadata: {
                name,
                isActive,
                documentCategory: { name: documentTypeName },
            },
        } = item;
        const { language } = i18n;

        return {
            id,
            name: removeExtension(filename),
            nameCol: {
                link: `/documents/${id}/owner/${contractId}`,
                value: filename,
                onDownload: downloadDocument,
            },
            valid: getFormattedDate(validTill, {
                hour: dateTimeFormats.TWO_DIGIT,
                minute: dateTimeFormats.TWO_DIGIT,
                second: dateTimeFormats.TWO_DIGIT,
            }),
            isActive: t(`advisoryDashboard.documents.${isActive ? 'yes' : 'no'}`),
            filename: originalFilename || '',
            category: getLocaleString(name, language) || '',
            type: getLocaleString(documentTypeName, language) || '',
            product: getProduct({contractId, portfolios}),
            contactId: getPortfolio({
                contractId,
                portfolios,
                currentPage: data?.page,
                currentTab: tab,
            }),
            externalId,
            fullDate: createDate,
            createDate: getFormattedDate(getAdaptedDate(createDate, t)),
            icon: '',
        };
    });

export const adaptUnifiedDocuments = ({data, dfsClientId, downloadDocument}) => {
    if (!data) {
        return [];
    }

    return data?.map(item => ({
        id: item.id,
        name: item.name,
        type: item.type,
        category: item.category,
        document: item.document,
        businessUnit: item.businessUnit,
        product: item.productName || 'N/A',
        portfolio: getUnifiedDocumentPortfolio({
            portfolioName: item.portfolioName,
            currentPage: data?.page,
            currentTab: 'personal',
            clientId: dfsClientId,
            portfolioId: item.portfolioId,
        }),
        createDate: item.createDate,
        nameCol: {
            link: '',
            value: item.document,
            onDownload: () => downloadDocument(item.id, item.documentSource, item.lpzCustomerId, item.name, item.productId),
        },
    }));
};
