import PropTypes from 'prop-types';
import { SORT_DIRECTIONS } from './constants';

export const columnData = PropTypes.shape({
    key: PropTypes.string.isRequired,
    title: PropTypes.string,
    filterable: PropTypes.bool,
    sortable: PropTypes.bool,
    width: PropTypes.number,
    headerRenderer: PropTypes.func,
    renderer: PropTypes.func,
    className: PropTypes.string,
    headerClassName: PropTypes.string,
    style: PropTypes.object,
    headerStyle: PropTypes.string,
});

export const cellValue = PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
    PropTypes.bool,
    PropTypes.object,
    PropTypes.node,
    PropTypes.func,
]);

export const sortState = PropTypes.shape({
    key: PropTypes.string,
    direction: PropTypes.oneOf(SORT_DIRECTIONS),
});

export const filteringState = PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.arrayOf(PropTypes.any),
}));

export const filterItem = PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.arrayOf(PropTypes.string),
});

export const filterState = PropTypes.arrayOf(filterItem);

export const pagingState = PropTypes.shape({
    pageSize: PropTypes.number,
    currentPage: PropTypes.number,
});
