import { createContext } from 'react';

type SinglePortfolioType = {
    navigation,
    portfolioName: string,
    productName: any,
    selectedProductSettings: any,
    productNameRisk?: any,
    portfolioDetail?: any,
    portfolioDetailRaw?: any,
    saldo?,
    t?,
    isPortfolioChangeable?,
    portfolioProducts?,
    isIndividualPortfolio?,
    clientId?,
    strategyName?: string,
    modelPortfolio?,
    riskCategories?,
    getSelectStrategy?,
    getFormattedXAxisFormat?,
    allocations?,
    factsheetUrl?,
    rebalancingOptions?: any,
    handleRebalanceChange?,
    rebalancing?,
    error?,
    isLoading?: boolean,
    portfolioData?: any,
    ongoingPlan?,
    migrationDate?: string | null
    isMigratedPortfolio?: boolean
    isPk?: boolean
};

const initialContext = {
    navigation: [],
    portfolioName: '',
    productName: '',
    productNameRisk: '',
    selectedProductSettings: {},
};

const SinglePortfolioContext = createContext<SinglePortfolioType>(initialContext);

export default SinglePortfolioContext;
