import moment from 'moment';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import './configs/setupAdditivServices';
import { Provider } from 'react-redux';
import { NotificationProvider } from 'libs/additiv-react-signalr';
import AuthenticationProvider from 'domain/Auth/AuthProvider';
import {createRoot} from 'react-dom/client';
import {BrowserRouter} from 'react-router-dom';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import * as serviceWorker from './serviceWorker';
import store from './store';
import App from './App';
import { LocaleProvider } from './locale';
import { LayoutProvider } from './prodivers/layout';

import ErrorHandler from './errors';
import SM from './services/ServiceManager';
import 'styles';
import 'clientRequirements/styles/cutomStyles.css';
import 'formiojs/dist/formio.full.min.css';

import 'ui-library/components/TabbedNavigation/TabbedNavigation.css';
import './translations/i18n';

moment();
const queryClient = new QueryClient();

const errorHandlerLog = (errorModel) => {
    const styles = {
        title: 'color: brown; font-size: 16px; font-weight: bold;',
        type: 'color: white; background-color: brown; font-size: 14px; font-weight: bold; border-radius: 3px;',
        stack: 'color: brown',
    };

    console.error(`%c⚠️ New Error with type: %c ${errorModel.type} \n%c${errorModel.stack}`, styles.title, styles.type, styles.stack);
};

ErrorHandler.configure({
    log: errorHandlerLog,
    DFSLoggerConfig: {
        enabled: true,
        endpointFunc: (data) => SM.middleware('log', [data]),
    },
});

const root = createRoot(document.getElementById('root'));

root.render(
    <QueryClientProvider client={queryClient}>
        <Provider store={store}>
            <LocaleProvider>
                <LayoutProvider>
                    <BrowserRouter>
                        <AuthenticationProvider>
                            <NotificationProvider>
                                <App />
                            </NotificationProvider>
                        </AuthenticationProvider>
                    </BrowserRouter>
                </LayoutProvider>
            </LocaleProvider>
        </Provider>
    </QueryClientProvider>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
