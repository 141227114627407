import React from 'react';
import PropTypes from 'prop-types';

const TimeEvent = ({ locale, event, title }) => {
    const difference = Math.abs(event.end - event.start) / 60000;
    const showStart = difference > 59 && difference < 24 * 60;

    return (
        <>
            {(showStart && !event.allDay) && (
                <div className="event-start">
                    {event.start.toLocaleString(locale, { hour: 'numeric', minute: 'numeric', hour12: true })}
                </div>
            )}
            {title}
        </>
    );
};

TimeEvent.propTypes = {
    title: PropTypes.string,
    event: PropTypes.shape({
        end: PropTypes.instanceOf(Date),
        start: PropTypes.instanceOf(Date),
        allDay: PropTypes.bool,
    }),
    locale: PropTypes.string.isRequired,
};

TimeEvent.defaultProps = {
    title: '',
    event: {},
};

export default TimeEvent;
