import {
    AdvisoryData,
    ApplicationDocumentType,
    Documents,
} from '../../components/ApplicationDetails/AdvisorySection/AdvisorySection.types';
import { SingleApplicationData } from '../../components/ApplicationDetails/Applications/Applications.types';

export enum ApplicationDetailsActionTypes {
    UPDATE_GENERATE_APPLICATION_DOC = 'UPDATE_GENERATE_APPLICATION_DOC',
    SET_APPLICATIONS = 'SET_APPLICATIONS',
    UPDATE_APPLICATION_STATUS = 'UPDATE_APPLICATION_STATUS',
    ADD_GENERATED_DOCS_FOR_APPLICATION = 'ADD_GENERATED_DOCS_FOR_APPLICATION',
    UPDATE_APPLICATION_DOC = 'UPDATE_APPLICATION_DOC',
    UPDATE_APPLICATION_DOC_WITH_TRANSFER = 'UPDATE_APPLICATION_DOC_WITH_TRANSFER',
    UPDATE_PROOF_OF_ASSETS_DOC = 'UPDATE_PROOF_OF_ASSETS_DOC',
    SET_PROOF_OF_ASSETS_DOCS = 'SET_PROOF_OF_ASSETS_DOCS',
    SET_BASIC_AGREEMENT_DOCS = 'SET_BASIC_AGREEMENT_DOCS',
    UPDATE_BASIC_AGREEMENT_DOC = 'UPDATE_BASIC_AGREEMENT_DOC',
    SUBMIT_APPLICATION = 'SUBMIT_APPLICATION',
}

export enum DocTypes {
    PROOF_OF_ASSETS = 'PROOF_OF_ASSETS',
    BASIC_AGREEMENT = 'BASIC_AGREEMENT',
    APPLICATION = 'APPLICATION',
    ADVISORY = 'SET_BASIC_DOCS',
    GENERATE = 'GENERATE',
    NOT_AVAILABLE = 'NOT_AVAILABLE',
}

export enum ApplicationStatus {
    inProgress = 0,
    completed = 1,
    canceled = 2,
    readyForSign = 3,
    submitted = 4,
    readyForSubmit = 5,
    closed = 6,
    readyForIbanReservation = 7,
}

export type JAUsersType = {
    id: number;
    fullName: string;
} [];

export type TApplicationDetailsManagerState = {
    proofOfAssetDocuments: Documents;
    basicAgreementDocuments: Documents;
    applicationsData: SingleApplicationData[]
}

export type TApplicationDetailsManager = TApplicationDetailsManagerState & {
    isJA: boolean,
    jaUsers?: JAUsersType,
    advisoryData: AdvisoryData,
    advisoryDocument: ApplicationDocumentType,
    isAdvisoryExpired: boolean,
    isAdvisoryCase: boolean,
    isAdvisoryDocReadyForSubmission: boolean,
    isShoppingCardCancellable: boolean,
    isApplicationsLoading: boolean,
    cancelAllApplications: () => Promise<() => void>,
    cancelApplication: (payload: {applicationId: number}) => Promise<void>,
    submitApplication: (payload: {applicationId: number, planType: string}) => Promise<void> | null,
    acceptDocumentSend: (payload: {documentId: number}) => Promise<void>,
    onManualSign: (d:TOnSignFileData) => Promise<void>;
    onESign: (d: { documentId: number }) => Promise<void>;
    onDocGeneration: (d: { applicationId: number }) => Promise<void>;
    setRetryState: (d: { applicationId: number }) => void
}

export type TOnSignFileData = {
    file: File;
    contactId?: number;
    originOfAsset?: number;
    documentId: number;
    type: DocTypes
}

export enum POLLING_STATUS {
    notFound = 'NotFound',
    inProgress = 'InProgress',
    error = 'Error',
    success = 'Success',
}
