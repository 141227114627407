import React, {
    useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate, useLocation, useParams} from 'react-router-dom';

import { useLayout } from 'prodivers/layout';
import { connectClientsSchema } from 'pages/Clients/provider/ClientsSchemaProvider';
import { ClientSearchBar } from 'pages/Clients/components/ClientSearchBar';
import Card from 'components/Card/Card';
import Preloader from 'components/Preloader/Preloader';
import { ClientListForNewConnection } from 'pages/Clients/containers/TablesContainer';
import SearchBar from 'components/SearchBar/SearchBar';
import NewWrapper from 'components/AtomicStructure/layouts/NewWrapper';
import { CLIENT_NAME } from 'prodivers/clientProfile/ClientProfileProvider';
import ButtonsBlockRow from 'components/ButtonsBlockRow/ButtonsBlockRow';

import {useClientLayout} from '../../../../hooks/useClientLayout';
import {getClientId, getExternalId} from '../../../../../../utils';
import {
    CONNECTIONS_SESSION_KEY, NewConnectionType, NewConnectionsSessionManagerAPI, SelectedConnection,
} from './constants';
import './NewConnections.css';
import {useConnections} from '../../../../../../components/Connections/components';
import {ContactGroupEnum} from '../../../../../../components/Connections/types';

const NewConnectionsCustomer = () => {
    useClientLayout();
    const { dfsClientId, groupId } = useParams();
    const {t} = useTranslation();
    // used to prevent double click on row
    const rowClickTracker = useRef<number>(0);
    const connectionType = useRef<NewConnectionType | null>();
    const [ct, setCt] = useState<NewConnectionType>('ja');

    const navigate = useNavigate();
    const location = useLocation();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { headerState: { clientName } } = useLayout();

    const [search, setSearch] = useState('');
    const [selectedClients, setSelectedClients] = useState<SelectedConnection[]>([]);

    const clientId = getClientId(dfsClientId);

    const storage = JSON.parse(global.sessionStorage.getItem(CONNECTIONS_SESSION_KEY) || '{}') as NewConnectionsSessionManagerAPI;
    const {data} = useConnections();

    const selectedGroup = useMemo(() => data?.find(f => f.groupId === storage.contactGroupId || (Object.keys(f.owners).includes(String(clientId)) && f.groupName === ContactGroupEnum.threeB && !groupId)), [data, groupId]);

    const groupProxies = useMemo(() => selectedGroup?.proxies?.map(proxy => proxy.proxyId) || [],
        [selectedGroup]);

    const groupOwners = useMemo(() => Object.keys(selectedGroup?.owners || {}).map(m => parseInt(m, 10)), [selectedGroup]);

    if (!storage || !storage.connectionType) {
        // prevent user from accessing this page directly if there is no previous connection type
        navigate(location.pathname.replace('/new-connection/customers', ''));
    }

    const onSearch = (value) => {
        setSearch(value);
    };

    const goToAction = () => {
        if (connectionType.current === 'ja') {
            navigate(location.pathname.replace('/customers', '/address'));
        } else {
            navigate(location.pathname.replace('/customers', '/relationship'));
        }
    };

    useEffect(() => {
        let newConnection: string | NewConnectionsSessionManagerAPI | null = global.sessionStorage.getItem(CONNECTIONS_SESSION_KEY);

        if (newConnection) {
            newConnection = JSON.parse(newConnection) as NewConnectionsSessionManagerAPI;

            connectionType.current = newConnection.connectionType;
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            setCt(newConnection.connectionType!);
            newConnection.clients = [];
            newConnection.documents = [];
            global.sessionStorage.setItem(CONNECTIONS_SESSION_KEY, JSON.stringify(newConnection));
        }
    }, []);

    const handleRowClick = (record) => {
        if (!record) {
            return;
        }

        rowClickTracker.current += 1;

        if (rowClickTracker.current % 2 !== 0) {
            return;
        }

        const preservedState = JSON.parse(global.sessionStorage.getItem(CONNECTIONS_SESSION_KEY) || '{}') as NewConnectionsSessionManagerAPI;
        // const clientName = global.sessionStorage.getItem(CLIENT_NAME);

        const selected: SelectedConnection = {
            id: record.id,
            name: record.name,
            externalId: record.externalId,
        };

        setSelectedClients([selected]);

        const newConnection: NewConnectionsSessionManagerAPI = {
            contactId: clientId,
            clients: [selected],
            ownerName: preservedState.ownerName || clientName || '',
            ownerExternalId: preservedState.ownerExternalId || getExternalId(clientId),
        };

        global.sessionStorage.setItem(CONNECTIONS_SESSION_KEY, JSON.stringify({
            ...preservedState,
            ...newConnection,
        }));
    };

    const allUsedClients = useMemo(() => (ct === 'ja' ? [clientId] : [...groupProxies, ...groupOwners, clientId]), [ct, groupProxies, groupOwners, clientId]);

    const filterClientsForConnectionsProcess = useCallback((clients) => ({
        ...clients,
        contactClient: clients?.contactClient?.filter(({ id }) => !allUsedClients.includes(parseInt(id, 10))),
    }), [allUsedClients]);

    return (
        <div className="container PoaConnections">
            <div className="fi-verticals">
                <Card
                    title={{
                        ja: t('connections.createNewJa.title'),
                        poa: t('connections.createNewPoa.title'),
                        representative: t('connections.createNewPa.title'),
                    }[connectionType.current || 'ja']}
                >
                    <NewWrapper
                        HeaderNavbarTurnOff
                        stepNavBarActive={0}
                        dataKey={ct === 'ja' ? 'newJaConnections' : 'newConnections'}
                    >
                        <div className="poa_content">
                            <SearchBar>
                                <ClientSearchBar
                                    onSearch={onSearch}
                                />
                            </SearchBar>
                            <Preloader isLoading={false} error={null}>
                                <ClientListForNewConnection search={search} selection="radio" handleRowClick={handleRowClick} externalFilters={filterClientsForConnectionsProcess} />
                            </Preloader>
                            <Preloader isLoading={false}>
                                <div className="container">
                                    <ButtonsBlockRow
                                        additionalButton={{
                                            text: t('onboardingFlow.documetsPage.back'),
                                            onClick: () => navigate(-1),
                                        }}
                                        primaryButton={{
                                            text: t('onboardingFlow.clientsDataPage.continue'),
                                            onClick: () => goToAction(),
                                            disabled: !selectedClients.length,
                                        }}
                                    />
                                </div>
                            </Preloader>
                        </div>
                    </NewWrapper>
                </Card>
            </div>
        </div>
    );
};

export default connectClientsSchema(NewConnectionsCustomer);
