import {useCallback, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import {
    object, number, string, date, array,
} from 'yup';
import ServiceManager from '../../../../services/ServiceManager';
import {memberProfileSelector} from '../../../../redux-store/auth/authSelectors';
import {CURRENT_PAGE, PAGE_SIZE} from '../../../../constants/constants';
import handlerRequestCanceling from '../../../../utils/handlerRequestCanceling';
import HandlerError from '../../../../errors/HandlerError';

const useClientNotesData = (contactId) => {
    const [loading, setLoading] = useState(false);
    const [saveInProgress, setSaveInProgress] = useState(false);
    const [error, setError] = useState(null);
    const [data, setData] = useState(null);
    const [totalNumber, setTotalNumber] = useState(null);
    const profile = useSelector(memberProfileSelector);
    const [currentPage, setCurrentPage] = useState(CURRENT_PAGE);

    const schema = useMemo(() => object({
        notes: array(object({
            createdById: number().integer(),
            createdByName: string().required(),
            createdDate: date().required(),
            message: string().required(),
        })),
        total: number().required().integer(),
    }), []);

    const onPageChange = useCallback(async (paging) => {
        setCurrentPage(paging?.currentPage);
    }, []);

    const getClientNotes = useCallback(async () => {
        try {
            setLoading(true);
            const response = await ServiceManager.customService('getClientNotes', [{contactId, pageSize: PAGE_SIZE, pageNumber: currentPage}]);
            const {notes, total} = await schema.validate(response.data);

            setData(notes);
            setTotalNumber(total);
        } catch (err) {
            handlerRequestCanceling(
                HandlerError({
                    setError,
                    setLoading,
                }),
            )(err);
        } finally {
            setLoading(false);
        }
    }, [contactId, currentPage]);

    const saveClientNotes = useCallback(async (note) => {
        try {
            setSaveInProgress(true);
            const payload = {contactId, message: note, createdBy: profile.Id};

            await ServiceManager.customService('saveClientNote', [payload]);

            setData([{
                ...payload,
                createdByName: `${profile.FirstName} ${profile.LastName}`,
                createdDate: new Date(),
            }, ...data]);
        } catch (err) {
            handlerRequestCanceling(
                HandlerError({
                    setError,
                    setLoading: setSaveInProgress,
                }),
            )(err);
            throw err;
        } finally {
            setSaveInProgress(false);
        }
    }, [contactId, data]);

    return {
        getClientNotes,
        saveClientNotes,
        loading,
        saveInProgress,
        error,
        data,
        setError,
        totalNumber,
        onPageChange,
        currentPage,
    };
};

export default useClientNotesData;
