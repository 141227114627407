import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../Icon';
import Title from '../Title';
import ContentBox from '../ContentBox';
import Button from '../Button';
import './PageVariant.css';
import withTheme from '../../hocs/withTheme';
import { getClassNames } from '../../utils';

const PageVariantBase = ({
    title,
    type,
    message,
    actionButtonLabel,
    primaryButtonLabel,
    onActionButtonClick,
    onPrimaryButtonClick,
    primaryButtonProps,
    children,
    horizontal,
    className,
}) => (
    <div className="PageVariant wrapper">
        <div className="main">
            <div className="justify-content-center">
                <ContentBox underline={false} className={type}>
                    <Icon type={type} size={32} />
                    <Title type={2}>{title}</Title>
                    <p>{message}</p>
                    <div className={getClassNames(className, 'PageVariant-buttons', { 'PageVariant-buttons-horizontal': horizontal })}>
                        {primaryButtonLabel && (
                            <Button
                                type="primary"
                                text={primaryButtonLabel}
                                onClick={onPrimaryButtonClick}
                                {...primaryButtonProps}
                            >
                                {primaryButtonLabel}
                            </Button>
                        )}
                        {children}
                        {actionButtonLabel && (
                            <Button
                                type="secondary"
                                text={actionButtonLabel}
                                onClick={onActionButtonClick}
                            >
                                {actionButtonLabel}
                            </Button>
                        )}
                    </div>
                </ContentBox>
            </div>
        </div>
    </div>
);

PageVariantBase.propTypes = {
    /** Title of Error */
    title: PropTypes.string.isRequired,
    /** Type of page */
    type: PropTypes.string.isRequired,
    /** Message error */
    message: PropTypes.string.isRequired,
    /** The content below buttons */
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    /** Label for Action button */
    actionButtonLabel: PropTypes.string.isRequired,
    /** Label for Action button */
    onActionButtonClick: PropTypes.func.isRequired,
    /** Label for Primary button */
    primaryButtonLabel: PropTypes.string,
    /** Additional props for Primary button */
    primaryButtonProps: PropTypes.shape({
        [PropTypes.string]: PropTypes.any,
    }),
    /** Click action for Primary button */
    onPrimaryButtonClick: PropTypes.func,
    /** @ignore */
    horizontal: PropTypes.bool,
    /** @ignore */
    className: PropTypes.string,
};

PageVariantBase.defaultProps = {
    children: null,
    primaryButtonLabel: null,
    primaryButtonProps: {},
    onPrimaryButtonClick: () => {},
    className: null,
    horizontal: false,
};

export const PageVariant = withTheme(PageVariantBase);
