import moment from 'moment';

const changeLocale = (locale) => {
    document.documentElement.setAttribute('lang', locale);
    moment.locale(locale);
};

const changeTimezone = (timezone) => {
    if (moment.tz) moment.tz.setDefault(timezone);
};

const momentWithCeilMinutes = (ceilValue = 5) => {
    const start = moment();
    const remainder = ceilValue - (start.minute() % ceilValue);

    return moment(start).add(remainder, 'minutes');
};

const setValueToDate = (date, obj = {}) => moment(date).set(obj);

export {
    moment,
    changeLocale,
    changeTimezone,
    momentWithCeilMinutes,
    setValueToDate,
};
