import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { THEME } from './constants';
import { createUniqueKey, getClassNames } from '../../utils';
import withTheme from '../../hocs/withTheme';
import './ThemeColors.css';

/** Mint should only be used for the most important
 * elements or actions such as the primary button,
 * current active navigation or current progress step. */

function ThemeColors({ colors, fullSize }) {
    const themeColors = colors ? [{ theme: '', colors }] : THEME;
    const className = getClassNames('Colors', { Colors_mini: !fullSize });

    return (
        <div className={className}>
            {themeColors.map(
                (colors, index) => (
                    <Fragment key={createUniqueKey(index, colors.theme)}>
                        <h3>{colors.theme}</h3>
                        <ul>
                            {colors.colors.map(
                                (color, i) => (
                                    <li key={createUniqueKey(i, colors)} className={color}>
                                        <span>{color}</span>
                                    </li>
                                ),
                            )}
                        </ul>
                    </Fragment>
                ),
            )}
        </div>
    );
}

ThemeColors.propTypes = {
    /** Array of color names */
    colors: PropTypes.arrayOf(PropTypes.string),
    /** @ignore */
    fullSize: PropTypes.bool,
};

ThemeColors.defaultProps = {
    colors: null,
    fullSize: false,
};

export default withTheme(ThemeColors);
