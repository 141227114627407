import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import ExpandIcon from './ExpandIcon';
import AccordionExtraCounter from './ExtraCounter/AccordionExtraCounter';
import { getClassNames } from '../../../../utils';

function AccordionHeader(props) {
    const {
        uId,
        role,
        count,
        isActive,
        disabled,
        children,
        headerClass,
        onClick,
    } = props;

    const classNameHeader = getClassNames('AccordionPanel-header', headerClass);
    const handleKeyPress = (e) => {
        if (e.key === 'Enter' || e.keyCode === 13 || e.keyCode === 32) {
            onClick();
        }
        if (isActive && (e.key === 'Escape' || e.keyCode === 27)) {
            onClick();
        }
        if (isActive && (e.key === 'ArrowUp' || e.keyCode === 38)) {
            onClick();
        }
        if (!isActive && (e.key === 'ArrowDown' || e.keyCode === 40)) {
            onClick();
        }
    };
    const extra = useMemo(() => count && (<AccordionExtraCounter count={count} />), [count]);

    return (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <div
            role={role}
            tabIndex={disabled ? -1 : 0}
            className={classNameHeader}
            aria-expanded={isActive}
            aria-controls={uId}
            onClick={onClick}
            onKeyDown={handleKeyPress}
        >
            <ExpandIcon isActive={isActive} />
            {children}
            {extra && (<div className="AccordionPanel-extra">{extra}</div>)}
        </div>
    );
}

AccordionHeader.propTypes = {
    uId: PropTypes.string.isRequired,
    role: PropTypes.string,
    count: PropTypes.number,
    isActive: PropTypes.bool,
    disabled: PropTypes.bool,
    children: PropTypes.node,
    headerClass: PropTypes.number,
    onClick: PropTypes.func,
};

AccordionHeader.defaultProps = {
    role: 'button',
    count: null,
    isActive: false,
    disabled: false,
    children: null,
    headerClass: null,
    onClick: () => {},
};

export default AccordionHeader;
