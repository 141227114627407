import React from 'react';
import ChartType from 'components/ChartType/ChartType';
import { PIE } from 'constants/constants';
import { allocationPercentFormat } from 'utils/formatting';

export const InvestmentStrategyAllocations = ({
    allocations,
    getFormattedXAxisFormat,
}) => (
    <ChartType
        data={allocations}
        chartView={PIE}
        percentFormat={allocationPercentFormat}
        xAxisFormat={getFormattedXAxisFormat}
    />

);
