import React, {
    createContext, useCallback, useState, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import DetectorSystemTheme from './DetectorSystemTheme';
import {
    getFromLS, saveToLS, setThemeVariables,
} from './utils';

const ThemingContext = createContext({});

const { Provider, Consumer: ThemingConsumer } = ThemingContext;

const ThemingProvider = (props) => {
    const {
        children,
        setVariables,
        defaultTheme,
        themesVariables,
        updateComponents,
        saveToLocalStorage,
    } = props;
    const [theme, changeTheme] = useState(defaultTheme);
    const setTheme = useCallback((newTheme) => {
        setVariables({ theme: newTheme, vars: themesVariables[newTheme], updateComponents });
        console.log('newTheme', newTheme);
        console.log('themesVariables[newTheme]', themesVariables[newTheme]);
        changeTheme(newTheme);
    }, [setVariables, themesVariables, updateComponents]);
    const detectorTheme = useCallback((e) => {
        const localTheme = getFromLS();

        if (!localTheme) setTheme(e.matches ? 'dark' : 'light');
    }, [setTheme]);

    // Set default theme
    useEffect(() => {
        const userTheme = DetectorSystemTheme.getSystemTheme();
        const localTheme = getFromLS();

        if (localTheme && themesVariables[localTheme]) setTheme(localTheme);
        else if (userTheme && themesVariables[userTheme]) setTheme(userTheme);
        else if (!defaultTheme) setTheme(Object.keys(themesVariables)?.[0]);
    }, [defaultTheme, setTheme, themesVariables]);

    // Local saving if true
    useEffect(() => {
        if (saveToLocalStorage && theme) saveToLS(theme);
    }, [detectorTheme, saveToLocalStorage, theme]);

    // DetectorSystem subscription
    useEffect(() => {
        DetectorSystemTheme.subscribe(detectorTheme);

        return () => {
            DetectorSystemTheme.unsubscribe(detectorTheme);
        };
    }, [detectorTheme]);

    return <Provider value={{ theme, setTheme }}>{children}</Provider>;
};

ThemingProvider.propTypes = {
    themesVariables: PropTypes.shape({
        [PropTypes.string]: PropTypes.shape({
            [PropTypes.string]: PropTypes.string,
        }),
    }),
    defaultTheme: PropTypes.string,
    saveToLocalStorage: PropTypes.bool,
    setVariables: PropTypes.func,
    updateComponents: PropTypes.func,
    children: PropTypes.node,
};

ThemingProvider.defaultProps = {
    themesVariables: {},
    defaultTheme: undefined,
    saveToLocalStorage: false,
    setVariables: setThemeVariables,
    updateComponents: () => {},
    children: null,
};

export { ThemingProvider, ThemingConsumer, ThemingContext };
