import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { avatarSizeTypes, avatarTypes } from './const';
import { getFontSize, getInitialsByName } from './utils';
import Icon from '../Icon';
import './Avatar.css';
import withTheme from '../../hocs/withTheme';


const Avatar = ({
    shape,
    src,
    iconType,
    name,
    gap,
    withSecondName,
    alt,
    size,
    className,
    onError,
    ...props
}) => {
    const avatarSrc = src && 'avatar-image';
    const avatarType = avatarTypes[shape] || avatarTypes.circle;
    const avatarNodeRef = useRef();
    const avatarChildrenRef = useRef();


    const calcAvatarFontSize = (size) => {
        const defaultFontSize = getFontSize(avatarNodeRef.current);

        if (size < defaultFontSize + (gap * 2)) {
            avatarNodeRef.current.style.fontSize = `${(defaultFontSize) - (gap * 2)}px`;
        }
    };

    const calculateSizeClass = () => {
        if (size in avatarSizeTypes) {
            return avatarSizeTypes[size].className;
        }


        return '';
    };

    const calculateSizeStyles = () => {
        if (size in avatarSizeTypes) {
            calcAvatarFontSize(avatarSizeTypes[size].px);
        } else if (typeof size === 'number' && size >= 0) {
            calcAvatarFontSize(size);

            avatarNodeRef.current.style.width = `${size}px`;
            avatarNodeRef.current.style.height = `${size}px`;
            avatarNodeRef.current.style.lineHeight = `${size}px`;
            avatarChildrenRef.current.style.lineHeight = `${size}px`;
        }
    };


    const renderContent = () => {
        if (src) {
            return <img src={src} alt={alt} onError={onError} />;
        }
        if (iconType) {
            return <Icon type={iconType} />;
        }
        if (name) {
            return getInitialsByName(name, withSecondName);
        }

        return null;
    };

    useEffect(() => {
        calculateSizeStyles();
    }, []);

    return (
        <span
            ref={avatarNodeRef}
            className={`Avatar ${className} ${avatarType} ${avatarSrc} ${calculateSizeClass()}`}
            {...props}
        >
            <span ref={avatarChildrenRef} className="avatar-content">
                {renderContent()}
            </span>
        </span>
    );
};

Avatar.propTypes = {
    /** The shape of avatar circle | square */
    shape: PropTypes.oneOf(['circle', 'square']),
    /** Icon type for an icon avatar */
    iconType: PropTypes.string,
    /** The size of the avatar number or 'large', 'small', 'default' */
    size: PropTypes.oneOfType([
        PropTypes.number, PropTypes.oneOf(['large', 'small', 'default']),
    ]),
    /** the address of the image for an image avatar */
    src: PropTypes.string,
    /** This attribute defines the alternative text describing the image */
    alt: PropTypes.string,
    /** Link text (default set to 'Logout') */
    gap: PropTypes.number,
    /** handler when img load error, return false to prevent default fallback behavior */
    onError: PropTypes.func,
    /** initial with SecondName */
    withSecondName: PropTypes.bool,
    /** name for initial  */
    name: PropTypes.string,
    /** @ignore */
    className: PropTypes.string,
};

Avatar.defaultProps = {
    shape: 'circle',
    iconType: null,
    size: 'default',
    src: '',
    alt: '',
    gap: 4,
    withSecondName: false,
    name: '',
    className: '',
    onError: () => {
    },
};
export default withTheme(Avatar);
