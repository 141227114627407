import { useCallback, useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import uniq from 'lodash/uniq';
import SM from 'services/ServiceManager';
import AdapterError from 'errors/AdapterError';
import ServerError from 'errors/ServerError';
import HandlerError from 'errors/HandlerError';
import { CURRENT_PAGE, PAGE_SIZE } from 'constants/constants';
import { useFormatting } from 'locale';
import handlerRequestCanceling from 'utils/handlerRequestCanceling';
import { adaptTransactions } from '../adapters/adaptTransactions';

const initialState = {
    data: [],
    dataRaw: {},
    error: null,
    isLoading: false,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'setData':
            return { ...state, data: action.payload };
        case 'setDataRaw':
            return { ...state, dataRaw: action.payload };
        case 'setError':
            return { ...state, error: action.payload };
        case 'setIsLoading':
            return { ...state, isLoading: action.payload };
        default:
            return state;
    }
};

export const useTransactions = (options = {}) => {
    const {
        clientId,
        portfolioId,
        adaptData = adaptTransactions,
        loadInitially = false,
    } = options;
    const [state, dispatch] = useReducer(reducer, initialState);

    const { t, i18n: { language } } = useTranslation();
    const { getFormattedDate, getFormattedNumber, getFormattedCurrency } = useFormatting();

    // Callbacks
    const getTransactions = useCallback(async ({ optionsParams, adaptOptions } = {}) => {
        dispatch({ type: 'setError', payload: null });
        dispatch({ type: 'setIsLoading', payload: true });

        try {
            const params = {
                SecurityType: optionsParams?.SecurityType || 'Instruments',
                TransactionType: optionsParams?.TransactionType,
                Page: optionsParams?.Page || CURRENT_PAGE,
                PageSize: optionsParams?.PageSize || PAGE_SIZE,
                ...{
                    From: optionsParams?.From,
                    To: optionsParams?.To,
                },
            };
            const response = await SM.customClientService('postTransactions', [clientId, portfolioId, params]);
            let securityIds = [];

            try {
                securityIds = (response?.data?.Transactions || []).map((item) => item.SecurityId);
            } catch (err) {
                throw new AdapterError(err);
            }

            let responseSecurities = [];

            if (securityIds?.length) {
                const paramsSecurities = {
                    SecurityIds: uniq(securityIds),
                    Language: language,
                };

                responseSecurities = await SM.instrumentsService('postSecurities', [paramsSecurities]);
            }

            try {
                const adapted = adaptData(response.data, {
                    securities: responseSecurities.data,
                    t,
                    getFormattedDate,
                    getFormattedNumber,
                    getFormattedCurrency,
                    currency: adaptOptions?.currency,
                    baseUrl: adaptOptions?.baseUrl,
                });

                dispatch({ type: 'setData', payload: adapted });
                dispatch({ type: 'setDataRaw', payload: response.data });
                dispatch({ type: 'setIsLoading', payload: false });

                return adapted;
            } catch (err) {
                throw new AdapterError(err);
            }
        } catch (err) {
            handlerRequestCanceling(
                HandlerError({
                    setError: (val) => dispatch({ type: 'setError', payload: val }),
                    setLoading: (val) => dispatch({ type: 'setIsLoading', payload: val }),
                }),
            )(err);

            return err.type !== undefined ? err : new ServerError(err);
        }
    }, [
        clientId,
        portfolioId,
        language,
        adaptData,
        t,
        getFormattedDate,
        getFormattedNumber,
        getFormattedCurrency,
    ]);

    // Effects
    useEffect(() => {
        dispatch({ type: 'setIsLoading', payload: loadInitially });
    }, [loadInitially]);

    useEffect(() => {
        if (loadInitially) getTransactions();
    }, [getTransactions, loadInitially]);

    return {
        data: state.data,
        dataRaw: state.dataRaw,
        error: state.error,
        isLoading: state.isLoading,
        getTransactions,
    };
};
