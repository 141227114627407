import React from 'react';
import {
    Navigate, Route, Routes, useParams,
} from 'react-router-dom';
import {PortfolioOverviewProvider} from 'domain/Portfolio';
import {getClientId} from 'utils';
import PortfolioActionsRouter from './PortfolioActionsRouter';
import withActionAccess from './hocs/withActionAccess';
import {
    adaptCashFlowsWithInflowsAndOutflows,
} from '../../../../domain/Portfolio/adapters/adaptCashFlowsWithInflowsAndOutflows';
import {CommonAllocationProvider} from './context/CommonAllocationDataProvider';

import SinglePortfolioRouter from './pages/SinglePortfolioView/SinglePortfolioRouter';

const CachedPortfolioActionsRouter = withActionAccess(PortfolioActionsRouter);

const PortfolioRouter = () => {
    const { dfsClientId, portfolioId } = useParams();
    const clientId = getClientId(dfsClientId);

    return (
        <PortfolioOverviewProvider
            options={{
                id: Number(portfolioId),
                clientId: Number(clientId),
                portfolio: { loadInitially: true },
                cashFlows: {
                    adaptData: adaptCashFlowsWithInflowsAndOutflows,
                },
            }}
        >
            <CommonAllocationProvider>
                <Routes>
                    <Route path="tab/*" element={<SinglePortfolioRouter/>} />
                    <Route path="*" element={<CachedPortfolioActionsRouter/>} />
                    <Route
                        path=""
                        element={<Navigate to="tab" replace />}
                    />
                    <Route
                        path="*"
                        element={<Navigate to="/404" replace />}
                    />
                </Routes>
            </CommonAllocationProvider>
        </PortfolioOverviewProvider>
    );
};

PortfolioRouter.displayName = 'PortfolioRouter';

export default PortfolioRouter;
