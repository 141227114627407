import {useQuery} from '@tanstack/react-query';
import ServerError from '../../errors/ServerError';
import SM from '../../services/ServiceManager';

export const useGetGeneralDocuments = ({
    containerId, language, isEnabled = true,
}) => {
    const result = useQuery({
        queryKey: ['getGeneralDocumentsAdvisoryWay', containerId, language],
        enabled: !!containerId && !!language && isEnabled,
        retry: false,
        gcTime: 30, // seconds
        queryFn: async () => {
            const {data} = await SM.customDocumentsService(
                'getGeneralDocumentsAdvisoryWay',
                [{
                    containerId, language,
                }],
            );

            return data;
        },
    });

    return {
        ...result,
        error: result.error ? new ServerError(result.error) : result.error,
    };
};
