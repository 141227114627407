import CellDocLibraryLinkDownload from './cells/CellDocLibraryLinkDownload';

export const getColumns = (t) => [
    {
        key: 'documentMetadataName',
        title: t('clientDashboard.documents.name'),
    },
    {
        key: 'productName',
        title: t('advisoryDashboard.documents.product'),
    },
    {
        key: 'linkDownload',
        title: `${t('research.downloadDocument')}/${t('clientDashboard.delete')}`,
        render: CellDocLibraryLinkDownload,
        width: 250,
    },
];
