import {
    BodyItemType,
    FooterItemType,
    IndividualPortfolioDataType,
} from '../../../../../../../../../ViewModels/ModelPortfolioDetails';

export const adaptAllocations = (positions) => {
    const adaptedAllocations: IndividualPortfolioDataType<Partial<BodyItemType>, Partial<FooterItemType>> = {body: [], footer: {}};

    if (positions?.length) {
        adaptedAllocations.body = positions.map((item) => ({
            id: item?.Security?.Id,
            name: {
                name: item?.Security?.Name,
            },
            allocation: {
                value: item?.Allocation * 100,
            },
        }));

        adaptedAllocations.footer = {
            allocation: 100 * positions.reduce((accu, item) => (item?.Allocation + accu), 0),
        };
    }

    return adaptedAllocations;
};
