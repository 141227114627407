import React, {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import uniqBy from 'lodash/uniqBy';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { date, number, object } from 'yup';
import moment from 'moment';
import format from 'date-fns/format';
import {
    INITIAL_START_DATE_FOR_CLIENT_REPORT_DATEPICKER, languages,
    migrationYearOf3b,
    PRODUCT_VALUES_FOR_3B,
} from 'constants/constants';
import { useParams } from 'react-router-dom';

import {
    DatePicker,
    Infobox,
    Loader,
    notification,
    Select,
} from 'ui-library';
import Card from '../../../../components/Card/Card';
import { getClientId } from '../../../../utils';
import { useClientLayout } from '../../hooks/useClientLayout';
import ButtonsBlockRow from '../../../../components/ButtonsBlockRow';
import {
    compareDates,
    dateFormatZurich,
    formatDateWithoutLocalConversion,
} from '../../../../utils/datetime';
import { useYupValidationResolver } from '../../../../hooks/useYupValidationResolver';
import ServiceManager from '../../../../services/ServiceManager';

import { useClientProfile } from '../../../../prodivers/clientProfile';
import { ACTIVE } from '../../../../constants/portfolioStatuses';
import './ClientReports.css';

const currentYear = format(new Date(), 'yyyy');

const ClientReports = () => {
    const {
        t,
        i18n: { language },
    } = useTranslation();
    const { dfsClientId } = useParams();
    const clientId = getClientId(dfsClientId);
    const [isDataSending, setDataSending] = useState(false);
    const [isStartDateLoading, setStartDateLoading] = useState(false);
    const [generalError, setGeneralError] = useState(false);

    const { sectionedPortfolios, isOverviewLoading } = useClientProfile();

    const allPortfolios = useMemo(
        () => [
            ...(sectionedPortfolios?.jointRegularPortfolios ?? []),
            ...(sectionedPortfolios?.poaPortfolios ?? []),
            ...(sectionedPortfolios?.parentalPortfolios ?? []),
        ],
        [sectionedPortfolios],
    );

    const productOptions = useMemo(
        () => uniqBy(
            allPortfolios?.filter((f) => f.Status?.value === ACTIVE),
            (u) => u.ProductId,
        )?.map((i) => ({ label: i.Product, value: i.ProductId })),
        [allPortfolios],
    );

    // const yesterdayDate = moment.utc().subtract(1, 'day').startOf('day');
    const today = moment.utc().startOf('day');

    const [startDate, setStartDate] = useState(INITIAL_START_DATE_FOR_CLIENT_REPORT_DATEPICKER);
    const [is3BProduct, setIs3BProduct] = useState(false);
    const [isMigrated, setIsMigrated] = useState(true);
    const [isInvestementAvailable, setIsInvestementAvailable] = useState(true);

    const isFromDateDisabled = useMemo(() => isStartDateLoading || (is3BProduct && isMigrated && currentYear === migrationYearOf3b) || (!isMigrated && !isInvestementAvailable),
        [isStartDateLoading, is3BProduct, isMigrated, currentYear, migrationYearOf3b]);

    const isToDateDisabled = useMemo(() => isStartDateLoading || (!isMigrated && !isInvestementAvailable), [isStartDateLoading, isMigrated, isInvestementAvailable]);

    const schema = useMemo(
        () => object().shape({
            fromDate: date().required(t('validation.mandatoryField')),
            toDate: date().required(t('validation.mandatoryField')),
            product: number()
                .required(t('validation.mandatoryField'))
                .nullable(),
        }),
        [t],
    );

    const resolver = useYupValidationResolver(schema);

    const handleSetIs3BProduct = useCallback((value) => {
        const is3BProductBool = [...PRODUCT_VALUES_FOR_3B]?.includes(value);

        setIs3BProduct(is3BProductBool);
        setValue('fromDate', startDate);
    }, []);

    const {
        handleSubmit,
        formState: { errors },
        control,
        watch,
        setValue,
    } = useForm({
        resolver,
        defaultValues: {
            fromDate: moment.utc().startOf('year'),
            toDate: today,
            product: null,
        },
    });

    const data = watch();

    useClientLayout();

    const fetchStartDate = useCallback(async () => {
        try {
            setStartDateLoading(true);
            const {data: {minimumStartDate, isMigrated: migrated, isInvestementAvailable: investmentAvailable }} = await ServiceManager.customReportsService(
                'getReportsStartDate',
                [{ contactId: clientId, productId: data?.product }],
            );

            if (compareDates(minimumStartDate, data?.fromDate)) {
                setValue('fromDate', minimumStartDate);
            }
            if (compareDates(minimumStartDate, data?.toDate)) {
                setValue('toDate', today);
            }

            setStartDate(minimumStartDate);
            setIsMigrated(migrated);
            setIsInvestementAvailable(investmentAvailable);
        } catch (err) {
            notification.open({content: `${err.message}!`, type: 'error'});
        } finally {
            setStartDateLoading(false);
        }
    }, [clientId, data]);

    useEffect(() => {
        if (clientId && data?.product) {
            fetchStartDate();
        }
    }, [clientId, data?.product]);

    const generateReport = useCallback(async () => {
        try {
            setDataSending(true);
            await ServiceManager.customReportsService('generateReport', [
                {
                    data: {
                        fromDate: formatDateWithoutLocalConversion(
                            data?.fromDate,
                            moment.defaultFormatUtc,
                        ),
                        toDate: formatDateWithoutLocalConversion(
                            data?.toDate,
                            moment.defaultFormatUtc,
                        ),
                        language: languages?.[language] ?? 0,
                        contactId: clientId,
                        productId: data.product,
                    },
                },
            ]);
            notification.open({
                content: t('clientReports.generate.successful'),
                type: 'success',
            });
        } catch (e) {
            setGeneralError(t('advisoryDashboard.error'));
        } finally {
            setDataSending(false);
        }
    }, [data, language, clientId]);

    return (
        <div className="container ClientReports">
            <Card
                title={t('advisoryDashboard.clientReports.title')}
                titleTagName="h1"
            >
                <form className="form">
                    <Controller
                        control={control}
                        name="product"
                        render={({ field: {onChange, ...field} }) => (
                            <Select
                                {...field}
                                className="report-products"
                                label={t('clientReports.products.label')}
                                options={productOptions}
                                placeholder={t('position.pleaseSelect')}
                                onChange={(value) => {
                                    onChange(value);
                                    handleSetIs3BProduct(value);
                                }}
                                error={errors?.product?.message}
                                required
                                disabled={isOverviewLoading}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name="fromDate"
                        render={({ field }) => (
                            <DatePicker
                                {...field}
                                value={
                                    (data?.fromDate
                                        && formatDateWithoutLocalConversion(
                                            data?.fromDate,
                                            dateFormatZurich,
                                        ))
                                }
                                format={dateFormatZurich}
                                data-cy="fromDate"
                                errors={errors?.fromDate}
                                label={t('clientReports.fromDate.label')}
                                disabledDate={
                                    (current) => current
                                        && (compareDates(current, data.toDate)
                                            || compareDates(
                                                moment(startDate),
                                                current,
                                            ))
                                }
                                required
                                disabled={isFromDateDisabled}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name="toDate"
                        render={({ field }) => (
                            <DatePicker
                                {...field}
                                value={
                                    data?.toDate
                                    && formatDateWithoutLocalConversion(
                                        data?.toDate,
                                        dateFormatZurich,
                                    )
                                }
                                format={dateFormatZurich}
                                data-cy="toDate"
                                errors={errors?.toDate}
                                label={t('clientReports.toDate.label')}
                                disabledDate={(current) => current
                                    && (compareDates(
                                        current,
                                        // moment().subtract(1, 'day'),
                                        moment(),
                                    )
                                        || compareDates(
                                            moment(data.fromDate),
                                            current,
                                        ))}
                                required
                                disabled={isToDateDisabled}
                            />
                        )}
                    />
                    <Infobox accent>
                        {t(
                            'clientReports.createReport.reportGenerationDurationInfoMessage',
                        )}
                    </Infobox>
                    {generalError && <Infobox error>{generalError}</Infobox>}
                    <ButtonsBlockRow
                        primaryButton={{
                            text: isDataSending ? (
                                <Loader />
                            ) : (
                                t('clientReports.createReportButton.label')
                            ),
                            onClick: handleSubmit(generateReport),
                        }}
                    />
                </form>
            </Card>
        </div>
    );
};

export default ClientReports;
