import i18n from 'i18next';
import moment from 'moment';
import SM from 'services/ServiceManager';

export const getValidationMessage = (params?: any) => ({
    mandatoryError: i18n.t('validation.mandatoryField'),
    phoneError: i18n.t('validation.invalidPhoneNumber'),
    alphanumericError: i18n.t('validation.invalidAlphanumeric'),
    lengthError: i18n.t('validation.invalidLength', {length: params?.length}),
    notBirthDateError: i18n.t('validation.invalidDateFormat'),
    birthDateError: i18n.t('validation.invalidDate'),
    nameError: i18n.t('validation.invalidName'),
    numberError: i18n.t('validation.invalidNumber'),
    emailError: i18n.t('validation.invalidEmail'),
    dateNotWithinSixMonthsError: i18n.t('validation.dateNotWithinSixMonths'),
    ibanError: i18n.t('validation.invalidIban'),
    invalidDateFormatError: i18n.t('validation.invalidDateFormat'),
});

export const validateInputAlphanumeric = (inputValue?: string | null, isRequired = false) => {
    if (isRequired) {
        const isInvalidString = validateInputString(inputValue);

        if (isInvalidString) {
            return isInvalidString;
        }
    }
    if (!inputValue || inputValue === '') {
        return null;
    }

    const reqExp = new RegExp(/^\w+$/);
    const result = inputValue.toString().match(reqExp);

    return result ? null : getValidationMessage().alphanumericError;
};

export const validateIban = async (iban?: string | null) => {
    if (!iban) {
        return getValidationMessage().mandatoryError;
    }

    const finalIban = iban.replace(/\s/g, '');

    try {
        const { data } = await SM.customService('validateIban', [{iban: finalIban}]);

        if (!data) {
            return getValidationMessage().ibanError;
        }

        return null;
    } catch (error) {
        return getValidationMessage().ibanError;
    }
};

export const validateInputString = (inputValue?: string | null): string | null => {
    if (inputValue === null || inputValue === undefined || inputValue === 'N.A' || inputValue === '') {
        return getValidationMessage().mandatoryError;
    }

    return null;
};

export const validateSelect = (selectValue?: string | number | null): string | null => {
    if (selectValue === null || selectValue === undefined || selectValue === 'N/A') {
        return getValidationMessage().mandatoryError;
    }

    return null;
};

export const validateRadio = (radioValue?: string | null) => {
    if (radioValue === null || radioValue === undefined) {
        return getValidationMessage().mandatoryError;
    }

    return null;
};

export const validateInputLength = (value?: string, maxAllowed = 40) => {
    if (!value) {
        return getValidationMessage().mandatoryError;
    }

    if (value?.length > maxAllowed) {
        return getValidationMessage({length: maxAllowed}).lengthError;
    }

    return null;
};

export const validateMultipleInputsLength = (maxAllowed: number, message: string, ...args: string[]) => {
    let totalLength = 0;

    args.forEach(f => { totalLength += f?.length || 0; });

    if (totalLength > maxAllowed) {
        return message;
    }

    return null;
};

export const validateDate = (date?: string | null) => {
    if (date === '' || date === null || date === undefined || date === 'Invalid date') {
        return getValidationMessage().mandatoryError;
    }

    const regex = /^\d{2}\/\d{4}$/;

    const splitDateOfBirthday = date.split('/');

    if (!regex.test(date)) {
        return getValidationMessage().notBirthDateError;
    }

    if (+splitDateOfBirthday[1] < 1900) {
        return getValidationMessage().birthDateError;
    }

    return null;
};

export const validateDateWithinSixMonths = (date?: string | null) => {
    if (date === '' || date === null || date === undefined || date === 'Invalid date') {
        return getValidationMessage().mandatoryError;
    }

    const formattedDate = moment(date);
    const currentDate = moment();

    const monthsDiff = formattedDate.diff(currentDate, 'months');

    if (monthsDiff >= 6) {
        return getValidationMessage().dateNotWithinSixMonthsError;
    }

    return null;
};

export const validateDateOfBirth = (dateOfBirthdayValue?: string | null) => {
    if (dateOfBirthdayValue === '' || dateOfBirthdayValue === null || dateOfBirthdayValue === undefined || dateOfBirthdayValue === 'Invalid date') {
        return getValidationMessage().mandatoryError;
    }

    const regex = /^\d{2}.\d{2}.\d{4}$/;

    const splitDateOfBirthday = dateOfBirthdayValue.split('/');

    if (!regex.test(dateOfBirthdayValue)) {
        return getValidationMessage().notBirthDateError;
    }

    if (+splitDateOfBirthday[2] < 1900) {
        return getValidationMessage().birthDateError;
    }

    return null;
};

export const validateName = (inputValue?: string | null) => {
    if (inputValue === null || inputValue === undefined || inputValue === 'N.A' || inputValue === '') {
        return getValidationMessage().mandatoryError;
    }

    const nameValidationRegExp = /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u;

    if (!nameValidationRegExp.test(inputValue)) {
        return getValidationMessage().nameError;
    }

    return null;
};

export const validateInputNumber = (inputNumberValue?: number| string | null) => {
    if (inputNumberValue === null || inputNumberValue === undefined || inputNumberValue === '') {
        return getValidationMessage().mandatoryError;
    }

    const regex = new RegExp('^[0-9]+$');

    if (!regex.test(inputNumberValue.toString())) {
        return getValidationMessage().numberError;
    }

    return null;
};

export const validateInputNumberMinMax = ({
    inputNumberValue,
    minValue,
    maxValue,
    minValueErrorMessage,
    maxValueErrorMessage,
}: {
    inputNumberValue?: number | string | null;
    minValue?: number;
    maxValue?: number;
    minValueErrorMessage?: string;
    maxValueErrorMessage?: string;
  }) => {
    if (inputNumberValue === null || inputNumberValue === undefined) {
        return getValidationMessage().mandatoryError;
    }

    const regex = new RegExp('^[0-9]+$');

    if (!regex.test(inputNumberValue.toString())) {
        return getValidationMessage().numberError;
    }

    if (minValue && +inputNumberValue < minValue) {
        return minValueErrorMessage || '';
    }

    if (maxValue && +inputNumberValue > maxValue) {
        return maxValueErrorMessage || '';
    }

    return null;
};

export const validateInputNumberWithZero = (inputNumberValue?: number | null) => {
    if (inputNumberValue === 0) {
        return getValidationMessage().mandatoryError;
    }

    return validateInputNumber(inputNumberValue);
};

export const validateEmailAdress = (emailAdressValue) => {
    if (emailAdressValue === null || emailAdressValue === undefined || emailAdressValue === 'N.A') {
        return getValidationMessage().mandatoryError;
    }

    const regex = /\b[\w.-]+@[\w.-]+\.\w{2,4}\b/;

    if (!regex.test(emailAdressValue)) {
        return getValidationMessage().emailError;
    }

    return null;
};

export const validatePhoneNumber = (phoneNumberValue?: string | null, required = true) => {
    if (required && (phoneNumberValue === null || phoneNumberValue === undefined)) {
        return getValidationMessage().mandatoryError;
    }

    const regex = new RegExp('^[+][(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,7}$');

    if (phoneNumberValue && !regex.test(phoneNumberValue)) {
        return getValidationMessage().phoneError;
    }

    return null;
};

export const IsAllDataNull = (obj: Record<string, unknown>) => Object.values(obj).every((value) => value === null);

export const validateFullDate = (date?: string | null) => {
    if (date === '' || date === null || date === undefined || date === 'Invalid date') {
        return getValidationMessage().mandatoryError;
    }

    const isValidDate = moment(date).isValid();

    if (!isValidDate) {
        return getValidationMessage().invalidDateFormatError;
    }

    return null;
};
