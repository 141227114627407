import PropTypes from 'prop-types';
import React from 'react';

import './product-offer-selection.css';

export function ProductOfferSelection({
    productOffer,
    onSelectionChange,
    isSelected,
    disabled,
}) {
    return (
        <div className={`fi-product-offer${disabled ? ' product-disabled' : ''}`}>
            <button
                disabled={disabled}
                type="button"
                className={`fi-product-offer-selection ${isSelected ? 'fi-product-offer-selection--selected' : ''}`}
                onClick={() => onSelectionChange(productOffer.id)}
            >
                <span className="fi-product-offer-selection__name">{productOffer.name}</span>
            </button>
            <p className="fi-product-offer-selection__description">
                {productOffer.description && productOffer.description.split('\n')
                    .map((item, index) => (
                        <span key={index}>
                            {item}
                        </span>
                    ))}
            </p>
        </div>
    );
}

ProductOfferSelection.propTypes = {
    productOffer: PropTypes.object.isRequired,
    isSelected: PropTypes.bool.isRequired,
    onSelectionChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
};

ProductOfferSelection.defaultProps = {
    disabled: false,
};
