import React from 'react';
import './TableFilterItem.css';

function TableFilterItem(props) {
    return (
        <div>
            {props.children}
        </div>
    );
}

export default TableFilterItem;
