import { SORT_DIRECTION_ASC, SORT_DIRECTION_DESC } from './constants';
import { getClassNames } from '../../../utils';

export const createRowUniqueKey = (data, index) => `row-${index}:${Object
    .values(data).filter((i) => typeof i !== 'object' && i !== '').join()}`;

export const setDataKeys = (data) => data
    .map((item, i) => {
        const key = createRowUniqueKey(item, i);

        return item.children
            ? { key, ...item, children: setDataKeys(item.children) }
            : { key, ...item };
    });

export const getFilteringColumns = (columns) => columns
    .filter(({ titleType }) => titleType !== 'columns')
    .map(({ key, title }) => ({ label: title || key, value: key }));

const onFilter = (key, value, record, columns) => {
    const filterFunction = columns.find((item) => key === item.key)?.onFilter;

    if (filterFunction === false) return true;

    const hasValue = typeof filterFunction === 'function'
        ? filterFunction(value, record)
        : record[key].toString().indexOf(value.toString()) === 0;

    if (!hasValue && record.children) {
        return record.children.some((item) => onFilter(key, value, item, columns));
    }

    return hasValue;
};

const processFiltering = (data, filtering, columns) => (
    data.filter((row) => filtering
        .map(({ key, value }) => (
            value.some((filterValue) => onFilter(key, filterValue, row, columns))
            || !value.length
        ))
        .every((result) => result))
);

const defaultSorting = (data, { key, direction }) => data.sort((previous, current) => {
    let previousValue = previous[key];
    let currentValue = current[key];

    if (typeof previousValue === 'string') {
        previousValue = previousValue.toLowerCase();
    }

    if (typeof currentValue === 'string') {
        currentValue = currentValue.toLowerCase();
    }

    if (previousValue === undefined || currentValue === undefined) {
        return 0;
    }

    if (previousValue > currentValue) {
        return direction === SORT_DIRECTION_DESC ? -1 : 1;
    }

    if (previousValue < currentValue) {
        return direction === SORT_DIRECTION_ASC ? -1 : 1;
    }

    return 0;
});

const processSorting = (data, { key, direction, sorter }) => {
    if (sorter === false) return data;
    const sorterFunc = sorter !== undefined ? sorter : defaultSorting;
    const dataWithSortedChildren = data.map((item) => (!item.children ? item : ({
        ...item,
        children: sorterFunc([...item.children], { key, direction, sorter: sorterFunc }),
    })));

    return sorterFunc([...dataWithSortedChildren], { key, direction, sorter: sorterFunc });
};

export const processPaging = (data, { pageSize, currentPage: targetPage }) => data
    .slice((targetPage - 1) * pageSize, targetPage * pageSize);

export const getUpdatedSnapshot = ({
    data, columns, sorting, filtering, paging,
}) => {
    let nextSnapshot = data;

    if (filtering && filtering.length) {
        nextSnapshot = processFiltering(nextSnapshot, filtering, columns);
    }
    if (sorting && sorting.key) {
        let sortingConfig = sorting;

        if (sorting.sorter === undefined) {
            const column = columns.find(({ key }) => key === sorting.key);

            sortingConfig = { sorter: column.sorter, ...sorting };
        }

        nextSnapshot = processSorting(nextSnapshot, sortingConfig);
    }
    const total = nextSnapshot.length;
    const currentTotal = (paging.currentPage - 1) * paging.pageSize || 0;

    if (paging && paging.currentPage && currentTotal < total) {
        nextSnapshot = processPaging(nextSnapshot, paging);
    }

    return { data: nextSnapshot, total };
};

export const getFilteringOptionsByKey = (data, column) => {
    const options = data.map((item) => ({
        label: item[column.key].toString(),
        value: item[column.key],
    }));

    return options.filter((obj, pos, arr) => arr
        .map((mapObj) => mapObj.value).indexOf(obj.value) === pos && obj.value !== '');
};

export const collectFilters = (columns, data) => {
    const filters = columns
        .filter((item) => item.filterable && (!item.filters || item.filters.length === 0))
        .reduce((acc, { key }) => ({ ...acc, [key]: [] }), {});

    if (Object.keys(filters).length) {
        data.forEach((item) => {
            Object.keys(filters).forEach((key) => {
                filters[key].push({
                    label: item[key].toString(),
                    value: item[key],
                });
            });
        });
        Object.keys(filters).forEach((key) => {
            filters[key] = filters[key].filter((obj, pos, arr) => arr
                .map((mapObj) => mapObj.value).indexOf(obj.value) === pos && obj.value !== '');
        });
    }

    return filters;
};

export const collectExpandedRows = (data) => data
    .filter(({ children }) => children && children.length)
    .map(({ key }) => key);

export const collectColumns = (
    columns, data, expandedColumn, expandOnClick, orderColumns, selectionConfig,
) => {
    const filters = collectFilters(columns, data);

    return columns.map((item, ind) => {
        const filterOptions = item.filterable && {
            filters: item.filters || filters[item.key],
        };
        const sortOptions = item.sortable && {
            sorter: item.sorter ?? defaultSorting,
        };
        const expandedOptions = item.key === expandedColumn ? {
            className: `${item.className || ''} cell-clickable`,
            onCell: (record, index) => ({
                ...item.onCell,
                onClick: () => {
                    if (item.onCell && item.onCell.onClick) {
                        item.onCell.onClick(record, index);
                    }
                    if (record.children) expandOnClick(record.key);
                },
            }),
        } : {};
        const selectionColumn = ind === 0 && selectionConfig.selectedRowKeys
            ? { selection: selectionConfig.render }
            : {};

        return {
            ...item,
            ...selectionColumn,
            ...sortOptions,
            ...filterOptions,
            ...expandedOptions,
            onHeaderCell: ({ dragDisabled, ...column }) => {
                const props = typeof item.onHeaderCell === 'function' ? item.onHeaderCell(column) : {};

                if (orderColumns) props.draggable = dragDisabled ? !dragDisabled : true;

                return {
                    ...props,
                    className: `${props.className || ''}${item.titleType === 'columns' ? ' cell__select-columns' : ''}`,
                };
            },
        };
    });
};

export const getSortingFromColumns = (columns) => {
    const sortColumn = columns.find((item) => item.defaultSortOrder);

    return sortColumn
        ? { key: sortColumn.key, direction: sortColumn.defaultSortOrder, sorter: sortColumn.sorter }
        : {};
};

export const onRowFunction = (onRow) => (record, rowIndex) => {
    const classNameRow = (row) => getClassNames({ row__selected: row.isSelected, row__disabled: row.isDisabled,  });

    if (onRow) {
        const rowProps = onRow(record, rowIndex);

        return {
            ...rowProps,
            className: getClassNames(rowProps.className, classNameRow(record)),
        };
    }

    return { className: classNameRow(record) };
};

const matches = (element, selector) => {
    if (element.matches) return element.matches(selector);

    return element.msMatchesSelector(selector);
};

export const closest = (el, selector, rootNode) => {
    let element = el;

    while (element) {
        const isRoot = element === rootNode || element === document.body;

        if (isRoot || (element.nodeType === 1 && matches(element, selector))) {
            if (isRoot) {
                element = null;
            }
            break;
        }
        element = element.parentNode;
    }


    return element;
};

export const getElements = (index, selector, rootNode) => rootNode
    .querySelectorAll(`${selector}:nth-child(${index + 1})`);

export const getDomIndex = (el) => Array.from(el.parentNode.children).indexOf(el);
