import { useContext } from 'react';
import PortfolioContext from './context';

const usePortfolio = () => {
    const context = useContext(PortfolioContext);

    if (context.id === undefined) {
        throw new Error('usePortfolio can only be used inside PortfolioOverviewProvider');
    }

    return context;
};

export default usePortfolio;
