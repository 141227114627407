import React from 'react';
import {useTranslation} from 'react-i18next';

import DawReport from './items/DawReport';
import InvestmentDataReport from './items/InvestmentDataReport';
import {useManagerProfile} from '../../prodivers/managerProfile';

import './Reports.css';

const Reports:React.FC = () => {
    const {t} = useTranslation();
    const {hasAccessPolicy } = useManagerProfile();

    return (
        <>
            <div className="container reports">
                {hasAccessPolicy('EnableESignReports') && (
                    <>
                        <DawReport/>
                        <InvestmentDataReport />
                    </>
                )}
                <div className="no-reports">{t('generalReports.noReports')}</div>
            </div>
        </>
    );
};

export default Reports;
