import Service from './service';
import { getServicesUrl } from '../../setup';
import { APIVersion } from '../../types/main';
import Security from './security';

class Common extends Service {
    static async getGeneralSettings() {
        await Security.generateBearerApplicationAccessTokenIfNeeded();

        return Service.getRequest(`${getServicesUrl()}/settings?group=General`);
    }

    static async getSettings(group) {
        const queryString = group !== undefined ? `?group=${group}` : '';

        await Security.generateBearerApplicationAccessTokenIfNeeded();

        return Service.getRequest(`${getServicesUrl()}/settings${queryString}`);
    }

    static async getGlobalSettingByGroupAndKey(group, key) {
        await Security.generateBearerApplicationAccessTokenIfNeeded();

        return Service.getRequest(`${getServicesUrl()}/settings/${group}/${key}`);
    }

    static lengthOfMTan() {
        const group = 'MTan';
        const key = 'Length';

        return Common.getGlobalSettingByGroupAndKey(group, key);
    }

    static getNewsSettings() {
        return new Promise(((resolve) => {
            Common.getGeneralSettings().then((response) => {
                resolve(
                    response.data.find((item) => item.Key === 'NewsAssetTypeId'),
                );
            });
        }));
    }

    static getCampaignsCurrentConfiguration(language = 'en') {
        return Service.getRequest(`${getServicesUrl(APIVersion.V12)}/campaigns/current/configuration?language=${language}`, { isSecure: true });
    }

    static getProductsDetails(productId, language) {
        return Service.getRequest(
            `${getServicesUrl()}/products/${productId}?language=${language}`,
            { isSecure: true },
        );
    }

    static getAssetClasses(language) {
        return Service.getRequest(`${getServicesUrl()}/assetclasses/${language}`, { isSecure: true });
    }

    static getSectors(language) {
        return Service.getRequest(`${getServicesUrl()}/sectors/${language}`, { isSecure: true, cache: true });
    }

    static getSecurityTypes(language) {
        return Service.getRequest(`${getServicesUrl()}/securitytypes/${language}`, { isSecure: true, cache: true });
    }

    static async getCountries(language) {
        await Security.generateBearerApplicationAccessTokenIfNeeded();

        return Service.getRequest(`${getServicesUrl()}/countries/${language}`);
    }

    static async getLanguages(language) {
        await Security.generateBearerApplicationAccessTokenIfNeeded();

        return Service.getRequest(`${getServicesUrl()}/languages/${language}`);
    }

    static getSeverityLevels() {
        return Service.getRequest(`${getServicesUrl()}/severity-levels`, { isSecure: true });
    }

    static getRecurrenceTypes() {
        return Service.getRequest(`${getServicesUrl()}/recurrencetype`, { isSecure: true });
    }

    static getRiskCategory(id) {
        return Service.getRequest(`${getServicesUrl()}/riskcategories/${id}`, { isSecure: true });
    }

    static getRiskCategories(language) {
        return Service.getRequest(`${getServicesUrl()}/riskcategories?language=${language}`, { isSecure: true });
    }

    static getRiskCategoriesByProduct(productId, language) {
        return Service.getRequest(`${getServicesUrl(APIVersion.V11)}/products/${productId}/riskcategories?language=${language}`, { isSecure: true });
    }

    static async getSalutations(language) {
        // eslint-disable-next-line no-undef
        console.log('DEPRECATED use Definitions.postDefinitions instead');
        await Security.generateBearerApplicationAccessTokenIfNeeded();

        return Service.getRequest(`${getServicesUrl()}/salutations/${language}`);
    }

    static async getIdentificationDocumentTypes(language) {
        await Security.generateBearerApplicationAccessTokenIfNeeded();

        return Service.getRequest(`${getServicesUrl()}/identificationdocumenttypes/${language}`);
    }

    static getRelatedObjects(data, language = null) {
        const requestUrl = language
            ? `${getServicesUrl()}/relatedobjects/search?language=${language}`
            : `${getServicesUrl()}/relatedobjects/search`;

        return Service.postRequest(requestUrl, data, { isSecure: true });
    }

    static getEconomicalZones(language) {
        return Service.getRequest(`${getServicesUrl()}/economicalzones/${language}`, { isSecure: true });
    }

    static getRatingAgencies(language) {
        return Service.getRequest(`${getServicesUrl()}/ratingagencies?language=${language}`, { isSecure: true });
    }

    static getRatings(ratingAgencyId) {
        return Service.getRequest(`${getServicesUrl()}/ratingagencies/${ratingAgencyId}/ratings`, { isSecure: true });
    }

    static getEnumerations(data, language) {
        return Service.postRequest(`${getServicesUrl()}/enumerations?language=${language}`, data, { isSecure: true });
    }
}

export default Common;
