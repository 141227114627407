import PropTypes from 'prop-types';
import React from 'react';

function PageSection({
    children,
    className
}) {
    return (
        <div className={className}>
            {children}
        </div>
    );
}

PageSection.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
};

PageSection.defaultProps = {
    children: null,
    className: null,
};

export default PageSection;
