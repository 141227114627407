import { formatDate } from 'utils/datetime';
import {changeMonthWithDay} from
    'pages/ClientOnboardingFlow/pages/NewsPlan/NewPersonalInformationPage/helpers/convertDate';

export const saveModel = (data) => ({
    personalInformation: {
        firstName: data?.firstName,
        lastName: data?.lastName,
        dateOfBirth: data.dateOfBirth && formatDate(changeMonthWithDay(data.dateOfBirth), 'YYYY-MM-DD'),
        gender: +data?.gender,
        taxDomicile: +data?.countryOfResidence,
        identification: [{
            countryOfNationality: +data?.countryOfNationality,
        },
        ],
    },
    communicationMethods: {
        primaryEmail: data.primaryEmail,
        primaryPhoneNumber: data.primaryPhoneNumber || null,
        secondaryPhoneNumber: data.telephoneNumber || null,
        primaryAddress: {
            primaryAddressLine1: data.street,
            primaryAddressLine4: data.PLZ,
            primaryAddressLine5: data.ORT,
        }
    },
    affiliatedPensionFund: data?.affiliatedPensionFund,
    occupation: data?.occupation,
    member: data?.member,
    destinedLife: data?.destinedLife,
    zurichMA: data?.zurichMA,
});
