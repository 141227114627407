import { isEmpty, isObject } from 'lodash/fp';
import queryString from 'query-string';
import { DefaultContext } from '@apollo/client/core/types';

type TParams = { [key: string]: unknown }
type TDefaultContextI = DefaultContext & { queryParams?: TParams }
type TDefaultContextO = TDefaultContextI & { path: string }
export const adaptQueryParams = (params: TParams = {}) => {
    try {
        delete params.memberId;

        return !isObject(params) || isEmpty(params) ? '' : `&${queryString.stringify(params)}`;
    } catch {
        return '';
    }
};


export const createGQContext = (path: string) => (
    (context: TDefaultContextI = {}): TDefaultContextO => ({
        path,
        ...context,
    })
);
