import {useQuery} from '@tanstack/react-query';
import {AxiosResponse} from 'axios';
import ServerError from '../../errors/ServerError';
import SM from '../../services/ServiceManager';

export const useGetKYC = ({
    contactId,
}) => {
    const result = useQuery({
        queryKey: ['getContactGroupDetails', contactId],
        enabled: !!contactId,
        retry: false,
        gcTime: 10, // seconds
        queryFn: async () => {
            const response: AxiosResponse<any> = await SM.customClientService(
                'getKYCData',
                [contactId],
            );

            return response;
        },
    });

    return {
        ...result,
        data: result?.data?.data,
        statusCode: result?.data?.status,
        error: result.error ? new ServerError(result.error) : result.error,
    };
};
