export const adaptAllocations = ({
    data, onAllocationChange, onCheckBoxChange, t,
}) => {
    const total = 0;
    const tableData = { body: [], footer: {} };

    data?.Positions?.forEach((item) => {
        tableData.body.push({
            id: item?.Security?.Id,
            name: {
                label: item?.Security?.Name,
                checked: false,
                onChange: (e) => (onCheckBoxChange({ checked: e.target.checked, id: item?.Security?.Id })),
            },
            allocation: {
                id: item?.Security?.Id,
                value: 0,
                onChange: onAllocationChange,
                disabled: true,
            },
            factsheet: {
                securityId: item?.Security?.Id,
            },
        });

        tableData.footer = {
            name: t('portfolio.createNewPortfolio.allocations.total'),
            allocation: total,
        };
    }, []);

    return tableData;
};
