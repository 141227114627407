import { validateStringData, NA, validateData } from 'utils/formatting';
import { sorterByDateCommon } from 'utils/sorting';

export const adaptTransactions = (data, options = {}) => {
    const { securities, baseUrl } = (options || {});
    const getFormattedDate = options?.getFormattedDate || ((d) => d);
    const getFormattedNumber = options?.getFormattedNumber || ((d) => d);
    const getFormattedCurrency = options?.getFormattedCurrency || ((d) => d);

    const transactions = data?.Transactions || [];
    const currency = data?.Currency?.CurrencyCode;
    const securitiesCurrency = (securities || [])
        .reduce((acc, item) => ({ ...acc, [item.Id]: item }), {});
    let balance = 0;

    return transactions
        .sort((...rest) => sorterByDateCommon('Date')(...rest))
        .map((item) => {
            const multiplier = item?.Type?.Id === 1 ? 1 : -1;
            const validatePrice = validateStringData(item?.Price);
            const priceCurrencyCode = securitiesCurrency[item?.SecurityId]?.Currency?.CurrencyCode;
            const formattedNumber = item?.Quantity
                ? getFormattedNumber(item?.Quantity, {
                    maximumFractionDigits: 6,
                    minimumFractionDigits: 6,
                })
                : NA;

            balance += multiplier * item?.Value;

            return {
                Name: validateStringData(item?.CustomAttributes?.TrxCodeText),
                Number: formattedNumber,
                Date: getFormattedDate(item?.Date),
                Balance: getFormattedNumber(balance, {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                }),
                ExecutionDate: getFormattedDate(item?.ExecutionDate),
                BookingType: item?.BookingType,
                Price: getFormattedCurrency(validatePrice, { currency: priceCurrencyCode }),
                Text: item?.BookingText,
                Value: getFormattedNumber(multiplier * item?.Value, {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                }),
                Total: getFormattedCurrency(item?.Value, {
                    currency,
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                }),
            };
        });
};
