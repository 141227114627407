import PropTypes from 'prop-types';
import React from 'react';
import {formatCurrency, formatProjectionDate} from 'utils/formatting';

const ProjectionTooltip = ({
    point: {
        x,
        y
    },
    series: {name},
    currency,
    t,
    getFormattedCurrency,
}) => (
    <div>
        <div className="tooltip-row">
            {name}
            :
            <span className="value">
                {getFormattedCurrency
                    ? getFormattedCurrency(y, {currency})
                    : formatCurrency(y, currency)}
            </span>
        </div>
        <div className="tooltip-row">
            {formatProjectionDate(x, t)}
        </div>
    </div>
);

ProjectionTooltip.propTypes = {
    point: PropTypes.shape({
        x: PropTypes.number.isRequired,
        y: PropTypes.number.isRequired,
    }).isRequired,
    series: PropTypes.shape({
        name: PropTypes.string.isRequired,
    }).isRequired,
    currency: PropTypes.string,
    t: PropTypes.func,
    getFormattedCurrency: PropTypes.func,
};

ProjectionTooltip.defaultProps = {
    currency: '',
    t: (str) => str,
    getFormattedCurrency: () => {
    },
};

export default ProjectionTooltip;
