import ConfirmModal from '../core/ConfirmModal';

export function withWarn(props) {
    return {
        type: 'warning',
        okCancel: false,
        ...props,
    };
}

export function withInfo(props) {
    return {
        type: 'info',
        okCancel: false,
        ...props,
    };
}

export function withSuccess(props) {
    return {
        type: 'success',
        okCancel: false,
        ...props,
    };
}

export function withError(props) {
    return {
        type: 'error',
        okCancel: false,
        ...props,
    };
}

export function withConfirm(props) {
    return {
        type: 'confirm',
        okCancel: true,
        ...props,
    };
}

export function modalWarn(props) {
    return ConfirmModal(withWarn(props));
}
