import React from 'react';
import {useParams} from 'react-router-dom';
import {ApplicationList} from 'pages/ClientOverview/pages/Dashboard/compnents/OverviewApplications/ApplicationList';
import Overview from './compnents/Overview';
import {ClientDashboardProvider} from './context';
import Portfolios from '../Portfolios/Portfolios';
import './Dashboard.css';

function Dashboard() {
    const {dfsClientId} = useParams();

    return (
        <ClientDashboardProvider {...{dfsClientId}}>
            <div className="container">
                <div className="fi-verticals">
                    <Overview/>
                    <Portfolios dfsClientId={dfsClientId} />
                    <ApplicationList />
                </div>
            </div>
        </ClientDashboardProvider>
    );
}

export default Dashboard;
