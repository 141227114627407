import React from 'react';
import PropTypes from 'prop-types';
import { getClassNames } from '../../utils';
import './ContentBox.css';
import withTheme from '../../hocs/withTheme';

/** Content box with padding and underlined title */

function ContentBox({
    children, className, underline, style,
}) {
    const contentBoxClassName = getClassNames(
        'Content-box',
        { 'content-box_title': underline },
        className,
    );

    return (
        <div className={contentBoxClassName} style={style}>
            {children}
        </div>
    );
}

ContentBox.propTypes = {
    /** Additional class to block */
    className: PropTypes.string,
    /** @ignore */
    children: PropTypes.node,
    /** Underline after title */
    underline: PropTypes.bool,
    style: PropTypes.shape({
        [PropTypes.string]: PropTypes.string,
    }),
};

ContentBox.defaultProps = {
    className: null,
    children: null,
    underline: true,
    style: null,
};

export default withTheme(ContentBox);
