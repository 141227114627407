import React from 'react';
import PropTypes from 'prop-types';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from './WrappedHighcharts';
import { getLineChartOptions } from './utils';
import { defaultLabels } from './constants';
import withTheme from '../../../hocs/withTheme';
import 'highcharts/css/highcharts.css';
import './LineChart.css';

function LineChart(props) {
    const {
        isCustomClassRequired,
        data,
        dateFormat,
        valueFormat,
        goal,
        projection,
        tooltipFormat,
        xAxisFormat,
        yAxisFormat,
        height,
        xMin,
        ySoftMin,
        xTitle,
        yTitle,
        legendEnabled,
        lineChartLegendOptions,
        lineChartTooltipOptions,
        labels,
        customXAxisFormat,
    } = props;

    Highcharts.setOptions({ lang: labels });

    return (
        <HighchartsReact
            containerProps={{ className: isCustomClassRequired ? 'LineChart':'' }}
            highcharts={Highcharts}
            options={getLineChartOptions(
                data,
                dateFormat,
                valueFormat,
                goal,
                projection,
                tooltipFormat,
                xAxisFormat,
                yAxisFormat,
                height,
                xMin,
                ySoftMin,
                xTitle,
                yTitle,
                legendEnabled,
                lineChartLegendOptions,
                lineChartTooltipOptions,
                customXAxisFormat,
                isCustomClassRequired
            )}
        />
    );
}

LineChart.propTypes = {
    /** Data to be shown on chart */
    data: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        data: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)).isRequired,
    })),
    isCustomClassRequired : PropTypes.bool,
    /** Define min for x-axis */
    xMin: PropTypes.number,
    /** Define soft min for y-axis */
    ySoftMin: PropTypes.number,
    /** Enable chart legend */
    legendEnabled: PropTypes.bool,
    lineChartLegendOptions: PropTypes.object,
    lineChartTooltipOptions: PropTypes.object,
    /** Single allocation view */
    projection: PropTypes.bool,
    /** The goal value */
    goal: PropTypes.arrayOf(PropTypes.number),
    /** Formats of X axis labels<br/><br/>
     * Date format codes:
     * <ul>
     * <li>%a - Short weekday, like 'Mon'.</li>
     * <li>%A - Long weekday, like 'Monday'.</li>
     * <li>%d - Two digit day of the month, 01 to 31.</li>
     * <li>%e - Day of the month, 1 through 31.</li>
     * <li>%b - Short month, like 'Jan'.</li>
     * <li>%B - Long month, like 'January'.</li>
     * <li>%m - Two digit month number, 01 through 12.</li>
     * <li>%y - Two digits year, like 09 for 2009.</li>
     * <li>%Y - Four digits year, like 2009.</li>
     * <li>%H - Two digits hours in 24h format, 00 through 23.</li>
     * <li>%I - Two digits hours in 12h format, 00 through 11.</li>
     * <li>%l (Lower case L) - Hours in 12h format, 1 through 11.</li>
     * <li>%M - Two digits minutes, 00 through 59.</li>
     * <li>%p - Upper case AM or PM.</li>
     * <li>%P - Lower case AM or PM.</li>
     * <li>%S - Two digits seconds, 00 through 59.</li>
     * <ul>
    */
    dateFormat: PropTypes.shape({
        millisecond: PropTypes.string,
        second: PropTypes.string,
        minute: PropTypes.string,
        hour: PropTypes.string,
        day: PropTypes.string,
        week: PropTypes.string,
        month: PropTypes.string,
        year: PropTypes.string,
    }),
    /** Formats of Y axis label<br/><br/>
     * Value format codes:
     * <ul>
     * <li>%s - the symbol from the 'symbol' parameter</li>
     * <li>%v - the value</li>
     * <ul>
     */
    valueFormat: PropTypes.shape({
        symbol: PropTypes.string,
        precision: PropTypes.number,
        thousand: PropTypes.string,
        decimal: PropTypes.string,
        format: PropTypes.string,
    }),
    /** Title to be shown on Y axis */
    xTitle: PropTypes.string,
    yTitle: PropTypes.string,
    tooltipFormat: PropTypes.func,
    xAxisFormat: PropTypes.shape({
        millisecond: PropTypes.string,
        second: PropTypes.string,
        minute: PropTypes.string,
        hour: PropTypes.string,
        day: PropTypes.string,
        week: PropTypes.string,
        month: PropTypes.string,
        year: PropTypes.string,
    }),
    yAxisFormat: PropTypes.func,
    height: PropTypes.number,
    labels: PropTypes.shape({
        month: PropTypes.arrayOf(PropTypes.string),
        shortMonth: PropTypes.arrayOf(PropTypes.string),
    }),
    customXAxisFormat: PropTypes.func,
};

LineChart.defaultProps = {
    data: [],
    isCustomClassRequired: true,
    projection: false,
    legendEnabled: false,
    lineChartLegendOptions: undefined,
    lineChartTooltipOptions: undefined,
    goal: [],
    xMin: null,
    ySoftMin: undefined,
    dateFormat: {
        millisecond: '%H:%M:%S.%L',
        second: '%H:%M:%S',
        minute: '%H:%M',
        hour: '%H:%M',
        day: '%d-%m',
        week: '%d-%m',
        month: '%b %y',
        year: '%Y',
    },
    valueFormat: {
        symbol: '',
        precision: 2,
        thousand: ',',
        decimal: '.',
        format: '%s%v',
    },
    tooltipFormat: (num) => num,
    xAxisFormat: {
        millisecond: '%H:%M:%S.%L',
        second: '%H:%M:%S',
        minute: '%H:%M',
        hour: '%H:%M',
        day: '%d-%m',
        week: '%d-%m',
        month: '%b %y',
        year: '%Y',
    },
    yAxisFormat: null,
    height: 400,
    xTitle: undefined,
    yTitle: undefined,
    labels: defaultLabels,
    customXAxisFormat: null,
};

export default withTheme(LineChart);
