import React from 'react';
import { Icon, Tooltip } from 'ui-library';
import { ICON_TYPE_INFOBOX } from 'ui-library/components/Icon';

function EntryCostFee({
    defaultEntryFee, t,
}) {
    return (
        <>
            <div className="FeeOverview__CustodyWrapper">
                <p>{t('entryCostFee.labels.entryCost')}</p>
            </div>

            <div className="FeeOverview__CustodyWrapper">
                <b>
                    {defaultEntryFee}
                    %

                </b>
            </div>
            <div className="FeeOverview__CustodyWrapper">

                <Tooltip title={t('entryCostFee.tooltip.info')} trigger="hover" >
                    <Icon type={ICON_TYPE_INFOBOX} size={16} />

                </Tooltip>
            </div>

        </>
    );
}

export default EntryCostFee;
