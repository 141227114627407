export const tickLength = 6;
export const pointRadiusHover = 11;

export const defaultScatterChartOptions = {
    chart: {
        type: 'scatter',
        animation: {
            duration: 10,
        },
        styledMode: true,
    },
    title: {
        text: undefined,
    },
    subtitle: {
        text: undefined,
    },
    legend: {
        enabled: false,
    },
    tooltip: {
        useHTML: true,
    },
    credits: {
        enabled: false,
    },
    xAxis: {
        gridLineWidth: 1,
        crosshair: { width: 1.5 },
        title: { enabled: false },
        tickLength: 0,
        lineWidth: 1,
        labels: { enabled: false },
    },
    yAxis: {
        gridLineWidth: 1,
        crosshair: { width: 1.5 },
        title: { enabled: false },
        tickLength: 0,
        lineWidth: 1,
        labels: { enabled: false },
    },
    plotOptions: {
        scatter: {
            cursor: 'pointer',
            marker: {
                symbol: 'circle',
                radius: 9,
                states: {
                    hover: {
                        radius: pointRadiusHover,
                    },
                },
            },
        },
        polygon: {
            enableMouseTracking: false,
        },
    },
    series: [],
};
