import Service from 'additiv-services/services/core/service';
import { getServicesUrl } from 'additiv-services/setup';
import { getQueryStringByObject } from 'additiv-services/utils/queryUtils';

class Bank extends Service {
    static getBank(id) {
        return Service.getRequest(`${getServicesUrl()}/banks/${id}`, { isSecure: true });
    }

    static getBankBranch(id, params) {
        const queryString = getQueryStringByObject(params);

        return Service.getRequest(`${getServicesUrl()}/bankbranches/${id}?${queryString}`, { isSecure: true });
    }
}

export default Bank;
