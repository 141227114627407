import { DefaultError, MutationFunction, useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { notification } from 'ui-library';

export type TUseErrorWrappedAPIMutation <TData, TError, TVariables, TContext> = {
    onError?: (error: TError, variables: TVariables, context: TContext | undefined) => Promise<unknown> | unknown;
    mutationFn: MutationFunction<TData, TVariables>;
}

export const useErrorWrappedAPIMutation = <
    TData = unknown,
    TError = DefaultError,
    TVariables = void,
    TContext = unknown,
>(
        { mutationFn, onError } : TUseErrorWrappedAPIMutation<TData, TError, TVariables, TContext>,
    ) => {
    const { t } = useTranslation();

    return useMutation<TData, TError, TVariables, TContext>({
        mutationFn,
        onError: (error: TError, variables: TVariables, context: TContext | undefined) => {
            notification.open({ content: `${t('contactGroups.somethingWentWrong')}`, type: 'error' });
            console.error('Error during mutation', error);

            if (onError) onError(error, variables, context);
        },
    });
};
