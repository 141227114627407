import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    FormGroup, Label, Button, Title, TextInput, Select,
} from '../../../../../../../ui-library';

export const CustomForm = (props) => {
    const { t } = useTranslation();
    const { Fields } = props.templateData;

    const [formData, setFormData] = useState({});

    const onChange = ({ target, propertyName }) => {
        const {
            name, value, checked, type,
        } = target;
        const newVal = type === 'checkbox' ? checked : value;

        setFormData({
            ...formData,
            [name]: newVal,
        });
    };

    const onChangeSelect = (name, val) => {
        setFormData({
            ...formData,
            [name]: val,
        });
    };

    const handleClick = () => {
        props.sendData(formData);
    };

    const showContent = (data) => {
        if (Object.keys(data).length === 0) { return false; }

        return true;
    };

    const renderFields = (fields) => fields.map(field => {
        const {
            propertyType, propertyLabel, propertyName, propertyMandatory,
        } = field;

        switch (propertyType) {
            case 'text':
            case 'double':
                return (
                    <FormGroup>
                        <Label
                            htmlFor={propertyName}
                            label={propertyLabel}
                        >
                            <span style={propertyMandatory ? { color: 'red' } : {}}>*</span>
                        </Label>
                        <TextInput
                            type="text"
                            name={propertyName}
                            onChange={onChange}
                        />
                    </FormGroup>
                );
            case 'select':
                const { possibleValues } = field;

                return (
                    <FormGroup>
                        <Label>
                            <span style={propertyMandatory ? { color: 'red' } : {}}>*</span>
                        </Label>
                        <Select
                            placeholder={propertyName}
                            onChange={(val) => onChangeSelect(propertyName, val)}
                            label={propertyLabel}
                            options={possibleValues}
                        />
                    </FormGroup>
                );
            default: null;
        }
    });

    return (
        <>
            {/* {showContent(Fields) ? (
                <>
                    <Title type={3}>
                        {t('caseManagment.caseDetails')}
                        :
                    </Title>
                </>
            ) : null} */}

            {showContent(Fields)
                ? (
                    <fieldset className="form">
                        <legend className="Title">
                            {t('caseManagment.caseDetails')}
                            {': '}
                            {props.title}
                        </legend>
                        {renderFields(Fields)}
                        <div className="button-group">
                            <Button
                                className="dynamic-button"
                                type="secondary"
                                onClick={handleClick}
                                block
                            >
                                {t('messages.send')}
                            </Button>
                        </div>
                    </fieldset>
                ) : null}
        </>
    );
};
