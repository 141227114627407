import {
    allocationColumns,
    BANDWIDTH,
    BAR,
    LINE,
    lineChartLabels,
    LIST,
    PIE,
    PIE_SA,
    PROJECTION,
    RISKRETURN,
} from 'constants/constants';
import {useFormatting} from 'locale';
import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import React, {memo} from 'react';
import {useTranslation} from 'react-i18next';
import {BandwidthChart, BarChart, LineChart, ProjectionChart, RiskReturnChart, Table,} from 'ui-library';
import PieChart, {PieChartSA} from '../../ui-library/components/Charts/PieChart';
import './ChartType.css';

const isPropsEqual = ({data: prevPropsData}, {data: nextPropsData}) => (
    isEqual(prevPropsData, nextPropsData)
);

function ChartType({
    chartView,
    title,
    currency,
    ...props
}) {
    const {t} = useTranslation();
    const {getFormattedXAxisFormat} = useFormatting();
    if (chartView === PIE_SA) return <PieChartSA {...props} />;
    if (chartView === PIE) return <PieChart {...props} />;
    if (chartView === BAR) return <BarChart {...props} />;
    if (chartView === LINE) {
        return (
            <LineChart
                xAxisFormat={getFormattedXAxisFormat}
                labels={lineChartLabels(t)}
                {...props}
            />
        );
    }
    if (chartView === RISKRETURN) return <RiskReturnChart {...props} />;
    if (chartView === BANDWIDTH) return <BandwidthChart {...props} />;
    if (chartView === PROJECTION) {
        return (
            <ProjectionChart
                {...props}
                yearsText={t('charts.years')}
            />
        );
    }
    if (chartView === LIST) {
        return <Table {...props} columns={allocationColumns(t)}/>;
    }

    return null;
}

const MemoizedChartType = memo(ChartType, isPropsEqual);

ChartType.propTypes = {
    title: PropTypes.string,
    currency: PropTypes.string,
    chartView: PropTypes.string.isRequired,
};

ChartType.defaultProps = {
    title: '',
    currency: null,
};

export default MemoizedChartType;
