import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { localizeFormat } from './utils';
import { navigate } from './constants';
import Icon from '../Icon';
import Tabs, { TabPane, TabsExtraItem } from '../Tabs';
import Title from '../Title';
import Button from '../Button';
import Paragraph from '../Paragraph';
import DatePicker from '../DatePicker';
import { datePickerLocalePropTypes } from '../DatePicker/propTypes';

const HeaderPanel = (props) => {
    const {
        view,
        date,
        views,
        labels,
        locale,
        onChange,
        localizer,
        onNavigate,
        setActiveDate,
        fullDayFormat,
        fullMonthFormat,
        weekdaysShortFormat,
        datePickerLabels,
    } = props;

    const [activeTab, setActiveTab] = useState(`calendar-tab-${view}`);

    const title = useMemo(
        () => localizeFormat(localizer, date, view === 'day' ? fullDayFormat : fullMonthFormat),
        [date, view, fullDayFormat, fullMonthFormat],
    );
    const onTabClick = (key) => {
        setActiveTab(key);
        onChange(key.replace('calendar-tab-', ''));
    };
    const onPrevClick = () => {
        onNavigate(navigate.PREVIOUS);
    };
    const onNextClick = () => {
        onNavigate(navigate.NEXT);
    };
    const onTodayClick = () => {
        const now = new Date();

        date.setMonth(now.getMonth());
        date.setYear(now.getFullYear());
        setActiveDate(now);
        onNavigate(navigate.TODAY);
    };
    const onDateClick = (dateCalendar) => {
        date.setDate(dateCalendar.date());
        date.setMonth(dateCalendar.month());
        date.setYear(dateCalendar.year());
        setActiveDate(date);
        onNavigate(navigate.DATE, date);
    };

    const extraContent = (
        <TabsExtraItem>
            <DatePicker
                dropdownClassName="calendar-picker"
                onChange={onDateClick}
                locale={locale}
                labels={datePickerLabels}
            />
            <Button className="button_icon arrow_left" onClick={onPrevClick}>
                <Icon type="arrow-left" size={10} />
            </Button>
            <Button type="secondary" size="small" onClick={onTodayClick}>
                {labels.today}
            </Button>
            <Button className="button_icon arrow_right" onClick={onNextClick}>
                <Icon type="arrow-right" size={10} />
            </Button>
        </TabsExtraItem>
    );

    return (
        <div className="calendar-header-panel">
            <Tabs
                activeKey={activeTab}
                tabBarExtraContent={extraContent}
                onTabClick={onTabClick}
            >
                {views.map((view) => (
                    <TabPane tab={labels[view]} index={view} key={`calendar-tab-${view}`} />
                ))}
            </Tabs>
            <Title type={3}>
                {title}
                {view === 'day' ? ', ' : ' '}
                {date.getFullYear()}
            </Title>
            {view === 'day' && (
                <Paragraph>{localizeFormat(localizer, date, weekdaysShortFormat)}</Paragraph>
            )}
        </div>
    );
};

HeaderPanel.propTypes = {
    view: PropTypes.string.isRequired,
    date: PropTypes.instanceOf(Date).isRequired,
    views: PropTypes.arrayOf(PropTypes.oneOf(['month', 'week', 'day'])).isRequired,
    labels: PropTypes.shape({
        day: PropTypes.string,
        week: PropTypes.string,
        month: PropTypes.string,
        today: PropTypes.string,
    }).isRequired,
    locale: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    setActiveDate: PropTypes.func.isRequired,
    localizer: PropTypes.shape({
        format: PropTypes.func,
    }).isRequired,
    fullDayFormat: PropTypes.string.isRequired,
    fullMonthFormat: PropTypes.string.isRequired,
    datePickerLabels: datePickerLocalePropTypes,
    weekdaysShortFormat: PropTypes.string.isRequired,
    onNavigate: PropTypes.func.isRequired,
};

HeaderPanel.defaultProps = {
    datePickerLabels: undefined,
};

export default HeaderPanel;
