import React from 'react';
import './SumBarChart.css';
import { useTranslation } from 'react-i18next';
import { validateNumber } from '../../../../../../../../../../utils/formatting';
import { useFormatting } from '../../../../../../../../../../locale';
import { useWithSumBar } from '../../../../hooks/useWithSumBar';
import { LINE } from '../../../../../../../../../../constants/constants';
import Preloader from '../../../../../../../../../../components/Preloader';

const SumBarChart = ({
    currency,
    portfolioId,
    clientId,
}) => {
    const {t} = useTranslation();

    const { getFormattedNumber } = useFormatting();

    const {
        currentValue,
        restValue,
        totalValue,
        isLoading,
        error,
    } = useWithSumBar({
        portfolioId,
        clientId,
    });

    return (
        <Preloader isLoading={isLoading} error={error}>
            <div className="SumBarChart">
                <dl className="SumBarChart__Top">
                    <div>
                        <dt>{t('client.portfolios.deposit.current')}</dt>
                        <dd>
                            <span className="SumBarChart__Currency">{currency}</span>
                            {' '}
                            { getFormattedNumber(validateNumber(currentValue), {
                                maximumFractionDigits: 2,
                                minimumFractionDigits: 2,
                            })}
                        </dd>
                    </div>
                    <div>
                        <dt>{t('client.portfolios.deposit.maxAmount')}</dt>
                        <dd>
                            <span className="SumBarChart__Currency">{currency}</span>
                            {' '}
                            { getFormattedNumber(validateNumber(totalValue), {
                                maximumFractionDigits: 2,
                                minimumFractionDigits: 2,
                            })}
                        </dd>
                    </div>
                </dl>
                <div className="SumBarChart__Middle" aria-hidden="true">
                    <div className="SumBarChart__TotalValueBar">
                        <div className="SumBarChart__CurrentValueBar" style={{width: `${Math.round((currentValue / totalValue > 1 ? 1 : currentValue / totalValue) * 100)}%`}} />
                    </div>
                </div>
                <dl className="SumBarChart__Bottom">
                    <div>
                        <dt>{t('client.portfolios.deposit.rest')}</dt>
                        <dd>
                            <span className="SumBarChart__Currency">{currency}</span>
                            {' '}
                            {getFormattedNumber(validateNumber(restValue), {
                                maximumFractionDigits: 2,
                                minimumFractionDigits: 2,
                            })}
                        </dd>
                    </div>
                </dl>
            </div>
        </Preloader>
    );
};

export default SumBarChart;
