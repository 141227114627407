import Preloader from 'components/Preloader';
import QuestionnaireQuestion from 'components/QuestionnaireQuestion';
import SelectedStrategy from 'components/SelectedStrategy/SelectedStrategy';
import SelectPreferenceStrategy from 'components/SelectPreferenceStrategy/SelectPreferenceStrategy';
import SetupWithdrawalPlan from 'components/SetupWithdrawalPlan/SetupWithdrawalPlan';
import { INVESTMENT_HORIZON_OPTIONS } from 'pages/ClientOverview/constants';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { RadioButtonGroup, Title } from 'ui-library';
import AllocationChart from '../AllocationChart';
import IndividualPortfolioSecurities from '../IndividualPortfolioSecurities';
import StrategyList from '../StrategyList';

import 'widgets/StrategySelection/components/StrategySelection/StrategySelection.css';

function StrategySelection({
    productDescription,
    investmentHorizon,
    investmentHorizonError,
    selectedProduct,
    recommendedStrategy,
    selectedStrategyId,
    setSelectedStrategyId,
    products,
    isIndividualPortfolioSelected,
    isModalListLoading,
    isAllocationsLoading,
    individualPortfolioData,
    isRebalance,
    setRebalance,
    modelPortfolioDetails,
    setIndividualPortfolioData,
    setIndividualPortfolioValidationError,
    selectedProductMarkId,
    productMarksOptions,
    showChart,
    allocations,
    factsheetUrl,
    onInvestmentHorizonChange,
    onProductMarkClick,
    strategies,
    productMissingError,
    cachedSelectStrategy,
    productKey,
    onSetupWithdrawalPlanChange,
    setupWithdrawalPlan,
    is3bProduct,
    preferenceStock,
    onPreferenceStockChange,
    preferenceExclusion,
    onPreferenceExclusionChange,
    preferenceInclusion,
    onPreferenceInclusionChange,
    exclusionSubstitute,
    onExclusionSubstituteChange,
    isZivZicAzp,
    isZiv,
    isAzp,
    isZivZifd,
    isZifdExclInterestIncome,
    strategySelectedError,
    isZic,
}) {
    const { t } = useTranslation();

    return (
        <div className="new-portfolio SelectStrategy">
            {productDescription && (
                <Title type={1}>
                    {productDescription}
                </Title>
            )}
            <div className="fi-verticals">
                <div className="fi-product-mark-selection">
                    <QuestionnaireQuestion
                        data-cy="investmentHorizon"
                        data={{
                            Layout: 'ButtonRadio',
                            Text: t('portfolio.createNewPortfolio.strategy.investmentHorizon.label'),
                            Answers: INVESTMENT_HORIZON_OPTIONS(t, isAzp),
                            Id: 'test_id_1',
                        }}
                        value={investmentHorizon}
                        error={investmentHorizonError}
                        onChange={onInvestmentHorizonChange}
                    />
                </div>
                {recommendedStrategy && (
                    <div className="fi-strategy-layout">
                        <StrategyList
                            strategies={strategies}
                            selectedStrategyId={selectedStrategyId}
                            recommendedStrategy={recommendedStrategy}
                            setSelectedStrategyId={setSelectedStrategyId}
                            products={products}
                            cachedSelectStrategy={cachedSelectStrategy}
                            selectedProductKey={productKey}
                            disabled={isModalListLoading || isAllocationsLoading}
                        />
                        {
                            isIndividualPortfolioSelected ? (
                                <div>
                                    {isZivZifd && (
                                        <SetupWithdrawalPlan
                                            value={setupWithdrawalPlan}
                                            onChange={onSetupWithdrawalPlanChange}
                                            disableWithdrawalOption={isZifdExclInterestIncome}
                                        />
                                    )}
                                    <IndividualPortfolioSecurities
                                        isLoading={isModalListLoading || isAllocationsLoading}
                                        individualPortfolioData={individualPortfolioData}
                                        isRebalance={isRebalance}
                                        setRebalance={setRebalance}
                                        modelPortfolioDetails={modelPortfolioDetails}
                                        setIndividualPortfolioData={setIndividualPortfolioData}
                                        setValidationError={setIndividualPortfolioValidationError}
                                        cachedSelectStrategy={cachedSelectStrategy}
                                        setupWithdrawalPlan={setupWithdrawalPlan}
                                    />
                                </div>
                            ) : (
                                <div className="fi-product-mark-selection">

                                    <Preloader isLoading={!strategies?.length}>
                                        <div className="new-portfolio-content">
                                            {is3bProduct && (
                                                <>
                                                    { isZiv && (
                                                        <SelectPreferenceStrategy
                                                            preferenceStock={preferenceStock}
                                                            onPreferenceStockChange={onPreferenceStockChange}
                                                            preferenceExclusion={preferenceExclusion}
                                                            onPreferenceExclusionChange={onPreferenceExclusionChange}
                                                            exclusionSubstitute={exclusionSubstitute}
                                                            onExclusionSubstituteChange={onExclusionSubstituteChange}
                                                            onPreferenceInclusionChange={onPreferenceInclusionChange}
                                                            preferenceInclusion={preferenceInclusion}
                                                            selectedStrategyId={selectedStrategyId}
                                                        />
                                                    )}
                                                </>
                                            )}
                                            {isZivZicAzp ? (
                                                <SelectedStrategy
                                                    selectedProductStrategy={selectedProduct?.Name}
                                                    //  selectedProductStrategy={productMarksOptions?.find(i => i.value === selectedProductMarkId?.toString())?.label}
                                                    errorMessage={strategySelectedError?.message
                                                        && t(strategySelectedError?.message, { riskProfile: strategies.find(i => i?.Id === selectedStrategyId)?.Name })}
                                                    disableOnError={isAzp && strategySelectedError?.isError}
                                                    factsheetUrl={!isZiv && factsheetUrl}
                                                    isInfoMessage={!strategySelectedError?.isError}
                                                />
                                            ) : (
                                                <>
                                                    <Title type={2}>{t('portfolio.createNewPortfolio.productMark')}</Title>
                                                    <RadioButtonGroup
                                                        data-cy="available_products"
                                                        value={selectedProductMarkId.toString()}
                                                        options={productMarksOptions}
                                                        onChange={onProductMarkClick}
                                                        error={productMissingError}
                                                    />
                                                </>
                                            )}
                                            {isZivZifd && (
                                                <SetupWithdrawalPlan
                                                    value={setupWithdrawalPlan}
                                                    onChange={onSetupWithdrawalPlanChange}
                                                    disableWithdrawalOption={isZifdExclInterestIncome}
                                                />
                                            )}
                                        </div>

                                    </Preloader>
                                </div>
                            )
                        }
                        {(showChart && !isZic) && (
                            <AllocationChart
                                isLoading={isModalListLoading || isAllocationsLoading}
                                isHidden={isModalListLoading}
                                selectedProduct={selectedProduct}
                                allocations={allocations}
                                factsheetUrl={factsheetUrl}
                            />
                        )}
                    </div>
                )}
            </div>
        </div>

    );
}

export default StrategySelection;
