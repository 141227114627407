import { useEffect, useState } from 'react';
import { getFullWidth } from '../../../utils/dom';

const detectHorizontalScroll = (parent, widthChildren) => {
    const widthParent = parent.offsetWidth;

    return widthChildren > widthParent;
};

export const useHorizontalScroll = (
    parentRef, childrenRefs, scrollCallback,
) => {
    const [scrollable, setScrollable] = useState(false);
    const [scrollOffset, setScrollOffset] = useState({ left: 0, right: 0 });

    useEffect(() => {
        const widthChildren = childrenRefs
            .map(({ current }) => current)
            .reduce((acc, item) => acc + getFullWidth(item), 0);

        const calculateScrollOffset = () => {
            setScrollOffset({
                left: parentRef.current.scrollLeft,
                right: widthChildren - parentRef.current.offsetWidth - parentRef.current.scrollLeft,
            });
        };
        const handleResize = () => {
            setScrollable(detectHorizontalScroll(parentRef.current, widthChildren));
            calculateScrollOffset();
        };
        const handleScroll = () => {
            if (typeof scrollCallback === 'function') scrollCallback();

            calculateScrollOffset();
        };

        window.addEventListener('resize', handleResize);
        parentRef?.current?.addEventListener?.('scroll', handleScroll);
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
            parentRef?.current?.addEventListener?.('scroll', handleScroll);
        };
    }, []);

    const onLeftIconClick = () => {
        const scrollWidth = parentRef.current.offsetWidth * 0.75;
        const scrollPosition = Math.max(parentRef.current.scrollLeft - scrollWidth, 0);
        const iconsEl = parentRef.current.parentNode.querySelector('.Icon-arrow-left');
        const iconWidth = getFullWidth(iconsEl, true);

        parentRef.current.scrollTo({ left: scrollPosition < iconWidth ? 0 : scrollPosition, behavior: 'smooth' });
    };

    const onRightIconClick = () => {
        const widthChildren = childrenRefs
            .map(({ current }) => current)
            .reduce((acc, item) => acc + getFullWidth(item), 0);
        const scrollWidth = parentRef.current.offsetWidth * 0.75;
        const scrollPosition = Math.min(
            parentRef.current.scrollLeft + scrollWidth,
            widthChildren - parentRef.current.offsetWidth,
        );

        parentRef.current.scrollTo({ left: scrollPosition, behavior: 'smooth' });
    };

    return {
        scrollable,
        scrollOffset,
        onLeftIconClick,
        onRightIconClick,
    };
};
