import React, {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {number, object, string} from 'yup';
import {useForm, Controller} from 'react-hook-form';
import { FILE_TYPES_ALLOWED } from 'pages/ClientOnboardingFlow/pages/NewsPlan/UploadDocumentsPage/validation/const';
import {
    FileInput, Infobox, Loader, Modal, Select,
} from '../../../ui-library';
import {getValFromHTML} from '../utils/getvalfromhtml';
import {useLanguageSwitcher} from '../../../translations/hooks/useLanguageSwitcher';

import ServiceManager from '../../../services/ServiceManager';
import {memberIdSelector} from '../../../redux-store/auth/authSelectors';
import {useYupValidationResolver} from '../../../hooks/useYupValidationResolver';
import {validateFile} from '../../ClientOnboardingFlow/pages/NewsPlan/UploadDocumentsPage/validation/validation';

import './UploadDocumentModal.css';
import {getBase64FromFile} from '../../../utils/file';

const defaultValues = {
    documentCategoryId: null,
    documentTypeId: null,
    language: null,
    productId: null,
    file: '',
};

const UploadDocumentModal = (props) => {
    const {t, i18n} = useTranslation();
    const {
        onClose, documentCategories, productsList, getDocumentLists,
    } = props;

    const productListOptions = useMemo(() => productsList?.map(i => ({label: i.label, value: String(i.id)})), [productsList]);
    const documentCategoriesOptions = useMemo(() => documentCategories?.map(i => ({label: i.label, value: String(i.id)})), [documentCategories]);

    const memberId = useSelector(memberIdSelector);
    const [documentTypeOptions, setDocumentTypeOptions] = useState([]);
    const [inProgress, setInProgress] = useState(false);
    const [docTypesLoading, setDocTypesLoading] = useState(false);
    const [error, setError] = useState(null);

    const {languages} = useLanguageSwitcher();

    const schema = useMemo(() => object().shape({
        documentCategoryId: string().required(t('advisoryDashboard.docLibrary.requiredField')).nullable(),
        documentTypeId: string().required(t('advisoryDashboard.docLibrary.requiredField')).nullable(),
        language: string().required(t('advisoryDashboard.docLibrary.requiredField')).nullable(),
        productId: number().required(t('advisoryDashboard.docLibrary.requiredField')).nullable(),
        file: string().required(t('advisoryDashboard.docLibrary.requiredField')).nullable(),
    }), [t]);

    const resolver = useYupValidationResolver(schema);

    const {
        control, handleSubmit, formState: {errors}, setValue, watch, reset,
    } = useForm({
        resolver,
        defaultValues,
    });

    const {
        documentCategoryId, file,
    } = watch();

    const getMetadata = useCallback(async () => {
        try {
            setDocTypesLoading(true);
            setValue('documentTypeId', null);
            setValue('documentMetadataId', null);
            const {data} = await ServiceManager.documentService('getDocumentMetaDataList', [{documentCategoryId}]);

            setDocumentTypeOptions(data?.map(({id, name}) => ({label: getValFromHTML(name, i18n), value: String(id)})) ?? []);
        } catch (err) {
            setDocumentTypeOptions([]);
        } finally {
            setDocTypesLoading(false);
        }
    }, [documentCategoryId]);

    useEffect(() => {
        (async () => getMetadata())();
    }, [getMetadata]);

    useEffect(() => {
        setError(null);
    }, [file]);

    const onModalClose = () => {
        setError(null);
        reset();
        onClose();
    };

    const onDocumentUpload = handleSubmit(async (values) => {
        try {
            setInProgress(true);

            const hasErrors = validateFile(file, {
                fileTypesAllowed: FILE_TYPES_ALLOWED,
            });

            if (hasErrors) {
                setError(hasErrors);

                return;
            }

            const payload = {
                filename: file?.[0]?.name,
                file: (await getBase64FromFile(file[0]))?.split(',')?.[1],
                documentMetadataId: parseInt(values.documentTypeId, 10),
                productId: parseInt(values.productId, 10),
                language: values.language,
            };

            await ServiceManager.customDocumentsService('uploadGeneralDocument', [{payload, memberId}]);
            getDocumentLists();
            onModalClose();
        } catch (err) {
            setError(err.message);
        } finally {
            setInProgress(false);
        }
    });

    return (
        <Modal
            title={t('advisoryDashboard.documents.uploadDocument')}
            cancelText={t('advisoryDashboard.documents.cancel')}
            onOk={onDocumentUpload}
            okText={inProgress ? <Loader /> : t('advisoryDashboard.documents.upload')}
            onCancel={onModalClose}
            width={450}
            visible
            className="UploadDocumentModal"
            okButtonProps={{disabled: inProgress}}
        >
            <Controller
                name="documentCategoryId"
                control={control}
                render={({field}) => (
                    <Select
                        {...field}
                        options={documentCategoriesOptions}
                        placeholder={t('clientDashboard.documents.category')}
                        error={errors?.documentCategoryId?.message}
                    />
                )}
            />

            <Controller
                name="documentTypeId"
                control={control}
                render={({field}) => (
                    <Select
                        {...field}
                        disabled={docTypesLoading || !documentCategoryId || !documentTypeOptions?.length}
                        options={documentTypeOptions}
                        placeholder={t('clientDashboard.documents.type')}
                        error={errors?.documentTypeId?.message}
                    />
                )}
            />

            <Controller
                name="language"
                control={control}
                render={({field}) => (
                    <Select
                        {...field}
                        innerRef={field.ref}
                        options={languages}
                        placeholder={t('common.selectLanguage')}
                        error={errors?.language?.message}
                    />
                )}
            />
            <Controller
                name="productId"
                control={control}
                render={({field}) => (
                    <Select
                        {...field}
                        placeholder={t('advisoryDashboard.documents.product')}
                        error={errors?.productId?.message}
                        options={productListOptions}
                    />
                )}
            />

            <div className="uploadFile">
                <Controller
                    name="file"
                    control={control}
                    render={({field: {onChange, ref}}) => (
                        <FileInput
                            ref={ref}
                            onChange={onChange}
                            className="uploadFile"
                            accept=".pdf, .jpg, .jpeg, .png"
                            uploadText={t('advisoryDashboard.documents.selectFile')}
                            error={errors?.file?.message}
                        />
                    )}
                />
            </div>

            {!!error && <Infobox error>{error}</Infobox>}
        </Modal>
    );
};

UploadDocumentModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    documentCategories: PropTypes.arrayOf(PropTypes.shape({id: PropTypes.string, label: PropTypes.string})).isRequired,
    productsList: PropTypes.arrayOf(PropTypes.shape({id: PropTypes.string, label: PropTypes.string})).isRequired,
    getDocumentLists: PropTypes.func,
};

UploadDocumentModal.defaultProps = {
    getDocumentLists: () => {},
};

export default UploadDocumentModal;
