import {
    useCallback, useEffect, useReducer,
} from 'react';

const LOCAL_STORAGE_KEY = 'layout';
const initialState = {
    clientName: '', clientAge: undefined, risk: '', isCorrectFlow: false, disabled: false,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'setData':
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

/**
 *
 * @returns {{data: { clientName: string, clientAge: number, risk: string }, setData: (value: (((prevState: {}) => {}) | {})) => void, updateData: ((function(*): void)|*)}}
 */
export const useLayout = () => {
    const [state, dispatch] = useReducer(reducer, JSON.parse(sessionStorage.getItem(LOCAL_STORAGE_KEY)) || initialState);
    const setData = useCallback((layoutData) => {
        dispatch({ type: 'setData', payload: layoutData });
    }, []);

    const clearLayoutCache = useCallback(() => {
        sessionStorage.removeItem(LOCAL_STORAGE_KEY);
    }, []);

    const goto = useCallback((step) => {
        setData({stepNavBarActive: step});
    }, []);

    const setPageErrors = useCallback((errors) => {
        setData({pageErrors: errors});
    }, []);

    useEffect(() => {
        sessionStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(state));
    }, [state]);

    return {
        data: state,
        setData,
        goto,
        setPageErrors,
        clearLayoutCache,
    };
};
