import React, {
    createContext, FC, ReactNode, useCallback, useEffect, useMemo, useReducer,
} from 'react';
import { useParams } from 'react-router-dom';
import { DefaultError, useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import {
    ApplicationDetailsActionTypes,
    ApplicationStatus,
    DocTypes,
    POLLING_STATUS,
    TApplicationDetailsManager,
    TOnSignFileData,
} from 'datasource/useApplicationDetails/ApplicationDetailsManager.types';
import { useApplicationCancel } from 'hooks/mutations/useApplicationCancel';
import { Documents } from 'components/ApplicationDetails/AdvisorySection/AdvisorySection.types';
import { useDocumentSentFlag } from 'hooks/mutations/useDocumentSentFlag';
import { dateFormatZurich, formatDate, isDateExpired } from 'utils/datetime';
import { STATUS } from 'components/InvestmentApplicationsActionButton/InvestmentApplicationsActionButton';
import { DocStatus } from 'components/ApplicationDetails/DocBox/DocBox.types';
import { ApplicationTypesEnum } from 'components/ApplicationDetails/Applications/Applications.types';
import { getBase64FromFile } from 'utils/file';
import { useManualDocumentSign } from 'hooks/mutations/useManualDocumentSign';
import { useUploadProofOfAssetsDocument } from 'hooks/mutations/useUploadProofOfAssetsDocument';
import { useGetInvestmentApp } from 'hooks/rest/useGetInvestmentApp';
import ServiceManager from 'services/ServiceManager';
import { EXTERNAL_PRODUCTS } from 'constants/constants';
import { notification } from 'ui-library';
import { useLayout } from 'prodivers/layout';
import { useSelector } from 'react-redux';
import { memberProfileSelector } from 'redux-store/auth/authSelectors';
import {
    docLoadingEntity, getNoDocumentEntry, initialState, updateTypeToActionMap,
} from './constants';
import { reducer } from './ApplicationDetailsManager.reducer';

export const ApplicationDetailsContext = createContext<TApplicationDetailsManager>(null as any);

type TApplicationDetailsManagerProps = {
    children: ReactNode;
    useDataProvider: any;
}

let errorCounter = 0;

export const ApplicationDetailsManager: FC<TApplicationDetailsManagerProps> = ({
    children,
    useDataProvider,
}) => {
    const { i18n: { language }, t } = useTranslation();
    const [state, dispatch] = useReducer(reducer, initialState());
    const profile:any = useSelector(memberProfileSelector);
    const { setLoadingOverlayActive }: any = useLayout();
    const { containerId, applicationId: urlApplicationId, dfsClientId } = useParams();
    const {
        data: investmentAppsData,
        refetch: getInvestmentApplications,
        isLoading: isInvestmentApplicationsLoading,
    } = useGetInvestmentApp({
        containerOrApplicationId: containerId || urlApplicationId, language, isContainer: !!containerId,
    });

    const isShoppingCardCancellable = useMemo(() => !state.applicationsData?.some(app => (
        [ApplicationStatus.canceled, ApplicationStatus.submitted, ApplicationStatus.completed].includes(app.status)
    )),
    [state.applicationsData]);

    const {
        isJA,
        jaUsers,
        generalDocumentsData,
        proofOfAssetsDocsData,
        advisoryData,
        applicationsDocumentsData,
        isAdvisoryCase,
        isApplicationDocsLoading,
        isGetGeneralDocumentsLoading,
        isProofOfAssetsDocsDataLoading,
    } = useDataProvider({
        containerId,
        applicationId: urlApplicationId,
        investmentAppsData,
    });

    useEffect(() => {
        if (isProofOfAssetsDocsDataLoading || isGetGeneralDocumentsLoading) return;
        let proofOfAssetDocuments = [];

        if (generalDocumentsData && proofOfAssetsDocsData) {
            proofOfAssetDocuments = proofOfAssetsDocsData?.proofOfAssetsDocumentInfo?.reduce((docs, client) => {
                const generalContactData = generalDocumentsData?.contactDocuments?.find((clientItem) => (client.contactId === clientItem.contactId));

                client?.proofOfAssetDocuments?.forEach(({
                    documentName,
                    originOfAsset,
                }) => {
                    const generalDoc = generalContactData?.proofOfAssetDocuments?.find((docItem) => docItem.originOfAsset === originOfAsset);
                    let documentNameToSet = documentName;

                    if (isJA) {
                        const splittedDocumentName = documentName.split(':');

                        documentNameToSet = `${splittedDocumentName?.[0]} ${client?.firstName} ${client?.lastName}: ${splittedDocumentName?.[1]}`;
                    }

                    docs.push({
                        contactId: client?.contactId,
                        documentName: documentNameToSet,
                        status: generalDoc ? DocStatus.ReadyToSubmit : DocStatus.ReadyToUpload,
                        documentId: generalDoc ? generalDoc?.documentId : undefined,
                        originOfAsset,
                        type: DocTypes.PROOF_OF_ASSETS,
                    });
                });

                return docs;
            }, []);
        }

        dispatch({
            type: ApplicationDetailsActionTypes.SET_PROOF_OF_ASSETS_DOCS,
            payload: proofOfAssetDocuments?.length ? proofOfAssetDocuments : [getNoDocumentEntry({ documentName: t('ApplicationDetails.AdvisorySection.ProofOfAssets.notAvailableName') })],
        });
    }, [proofOfAssetsDocsData, generalDocumentsData, isJA, language]);

    useEffect(() => {
        if (isGetGeneralDocumentsLoading) return;
        let basicAgreementDocuments = [];

        if (generalDocumentsData?.contactDocuments) {
            basicAgreementDocuments = generalDocumentsData?.contactDocuments?.reduce((docs, client) => {
                if (!client?.basicAgreementDocument) return docs;
                let documentName = client?.basicAgreementDocument?.documentName;

                documentName = isJA ? `${documentName} ${client?.firstName} ${client?.lastName}` : documentName;

                docs.push({
                    documentName,
                    status: client?.basicAgreementDocument?.signStatus === STATUS.submitted ? DocStatus?.ReadyToSubmit : DocStatus?.ReadyToSign,
                    documentId: client?.basicAgreementDocument?.documentId,
                    contactId: client?.contactId,
                });

                return docs;
            }, []);
        }

        const noDocEntry = getNoDocumentEntry({ documentName: t('ApplicationDetails.AdvisorySection.BasicAgreement.notAvailableName') });
        const noDocs = isJA ? [noDocEntry, noDocEntry] : [noDocEntry];

        dispatch({
            type: ApplicationDetailsActionTypes.SET_BASIC_AGREEMENT_DOCS,
            payload: basicAgreementDocuments?.length
                ? basicAgreementDocuments
                : noDocs,
        });
    }, [generalDocumentsData?.contactDocuments, isJA]);

    const advisoryDocument = useMemo(() => (generalDocumentsData?.advisoryDocument !== undefined ? {
        documentName: generalDocumentsData?.advisoryDocument?.documentName,
        status: DocStatus?.ReadyToSubmit,
        documentId: generalDocumentsData?.advisoryDocument?.documentId,
        type: DocTypes.ADVISORY,
    } : docLoadingEntity), [generalDocumentsData?.advisoryDocument]);

    const cancelAllApplications = useCallback(async () => {
        await ServiceManager.customInvestmentService('cancelApplicationsByContainerId', [{ containerId }]);

        return getInvestmentApplications;
    }, [containerId]);

    const isAdvisoryExpired = isDateExpired(advisoryData?.validTill, 'DD.MM.YYYY');
    const applicationsQuantity = investmentAppsData?.length;

    useEffect(() => {
        const applications = investmentAppsData?.map((application) => {
            let docsPerApplicationId = applicationsDocumentsData?.filter((doc) => doc?.investmentApplicationId === application?.investmentApplicationId).map((doc) => ({
                status: doc?.signStatus === STATUS.submitted ? DocStatus.ReadyToSubmit : DocStatus.ReadyToSign,
                documentName: doc?.documentName,
                documentId: doc?.id,
                applicationId: application?.investmentApplicationId,
                type: DocTypes.APPLICATION,
                isSent: doc?.isSent,
            }));

            docsPerApplicationId = docsPerApplicationId?.length > 0 ? docsPerApplicationId : [{
                status: DocStatus.ReadyToGenerate,
                type: DocTypes.GENERATE,
                applicationId: application?.investmentApplicationId,
            }];

            if ([ApplicationStatus.canceled, ApplicationStatus.completed].includes(application?.status)) {
                docsPerApplicationId = docsPerApplicationId.reduce((akku: Documents, doc) => {
                    if (doc.type !== DocTypes.GENERATE
                        && doc.status !== DocStatus.ReadyToSign
                    ) akku.push(doc);

                    return akku;
                }, []);
            }

            let type = ApplicationTypesEnum.NEW;
            const changeInvestmentAmount = application?.investmentDescription?.changeInvestmentAmount;

            if (application?.changePlan) {
                type = ApplicationTypesEnum.CHANGE;
                if (changeInvestmentAmount) {
                    type = changeInvestmentAmount?.isSell ? ApplicationTypesEnum.SELL : ApplicationTypesEnum.BUY;
                }
            }

            return {
                id: application?.investmentApplicationId,
                typeKey: type,
                type: t(type),
                productExternalId: application?.investmentDescription?.productExternalId,
                depotNumber: application?.portfolioInfo?.name,
                currentStrategy: application?.portfolioInfo?.strategy,
                investedAmount: application?.plannedInvestmentAmount ?? '-',
                amountToBeSold: changeInvestmentAmount?.value,
                purchaseAmount: changeInvestmentAmount?.value,
                productName: application?.strategy ?? '-',
                productNameTranslated: application?.productName ?? '-',
                applicationId: application?.investmentApplicationId ?? '-',
                createdDate: application?.createdDate ? formatDate(application?.createdDate, dateFormatZurich) : '-',
                validTill: application?.validTo ? formatDate(application?.validTo, dateFormatZurich) : '-',
                advisor: application?.advisor ?? '-',
                createdBy: application?.createdBy ?? '-',
                investmentAmount: application?.plannedInvestmentAmount ?? '-',
                submitted: application?.submittedDate ? formatDate(application?.submittedDate, dateFormatZurich) : '-',
                submittedBy: application?.submittedBy,
                documents: docsPerApplicationId,
                isSentCase: docsPerApplicationId?.some((item) => (typeof item.isSent === 'boolean')),
                canBeDeleted: applicationsQuantity !== 1 && !isAdvisoryCase && ![ApplicationStatus.canceled, ApplicationStatus.submitted, ApplicationStatus.completed].includes(application?.status),
                status: application?.status,
                rubrik: application?.investmentDescription?.productConfiguration?.rubrik,
            };
        });

        const newApps = applications?.filter(({ typeKey }) => typeKey === ApplicationTypesEnum.NEW);
        const changeApps = applications?.filter(({ typeKey }) => typeKey === ApplicationTypesEnum.CHANGE);
        const sellApps = applications?.filter(({ typeKey }) => typeKey === ApplicationTypesEnum.SELL);
        const buyApps = applications?.filter(({ typeKey }) => typeKey === ApplicationTypesEnum.BUY);

        dispatch({
            type: ApplicationDetailsActionTypes.SET_APPLICATIONS,
            payload: newApps.concat(changeApps, sellApps, buyApps),
        });
    }, [investmentAppsData, applicationsDocumentsData, isAdvisoryCase, applicationsQuantity, t]);

    const {
        mutateAsync: manualDocumentSign,
    } = useManualDocumentSign();

    const {
        mutateAsync: uploadProofOfAssetsDocument,
    } = useUploadProofOfAssetsDocument();

    const onESign = useCallback(async ({ documentId }: { documentId: number}) => {
        const response = await ServiceManager.customDocumentsService('getESignUrl', [{
            documentId,
            returnUrl: `${window.location.origin}${window.location.pathname}`,
        }]);

        window.open(response.data?.signingUrl, '_self');
    }, [dfsClientId]);

    const onManualSign = useCallback(async ({
        file,
        contactId,
        originOfAsset,
        documentId,
        type,
    }: TOnSignFileData) => {
        const base64File = await getBase64FromFile(file);
        const splitted = base64File.split(',');

        if (type === DocTypes.PROOF_OF_ASSETS) {
            const payload = {
                containerId,
                contactId,
                originOfAsset,
                document: {
                    filename: file?.name,
                    file: splitted[1],
                    documentId,
                },
            };

            const data = await uploadProofOfAssetsDocument(payload);

            dispatch({
                type: ApplicationDetailsActionTypes.UPDATE_PROOF_OF_ASSETS_DOC,
                payload: {
                    documentId: data?.documentId,
                    originOfAsset,
                    contactId,
                },
            });
        } else if (type === DocTypes.BASIC_AGREEMENT || type === DocTypes.APPLICATION) {
            let is3bDoc = false;

            if (type === DocTypes.APPLICATION) {
                is3bDoc = state.applicationsData.some(
                    ({ productExternalId }) => (
                        ![EXTERNAL_PRODUCTS.aaa, EXTERNAL_PRODUCTS.fz, EXTERNAL_PRODUCTS.fzp].includes(productExternalId?.toLowerCase())
                    ),
                );
            }

            const payload = {
                file: splitted[1],
                documentId,
                ...{ isNewSigningFlow: is3bDoc ? true : undefined },
            };

            await manualDocumentSign(payload);

            dispatch({
                type: updateTypeToActionMap[type],
                payload: {
                    documentId,
                },
            });
        }
    }, [state.applicationsData]);

    const {
        mutateAsync: generateDocs,
    } = useMutation<any, DefaultError, {applicationId:number}>({
        mutationFn: async ({ applicationId }) => {
            const { data } = await ServiceManager.customInvestmentService(
                'generateDocumentsNew',
                [{
                    investmentApplicationId: applicationId,
                }],
            );

            return data;
        },
        onError: () => {
            console.error('Error while generating docs.');
        },
    });

    const {
        mutateAsync: cancelInvestmentApplication,
    } = useApplicationCancel();

    const submitApplication = useCallback(async (payload: { applicationId: number; planType: string }) => {
        let intervalId: NodeJS.Timeout;

        setLoadingOverlayActive(true);

        if (payload?.planType === 'CHANGE') {
            ServiceManager.customInvestmentService(
                'changePlan',
                [{ investmentApplicationId: payload.applicationId, isNewFlow: true }],
            ).then((res) => {
                if (res.status === 201) {
                    const agent = `${profile?.FirstName} ${profile?.LastName}`;

                    dispatch({
                        type: ApplicationDetailsActionTypes.SUBMIT_APPLICATION,
                        payload: {
                            applicationId: payload.applicationId,
                            status: ApplicationStatus.submitted,
                            submitted: formatDate(new Date(), dateFormatZurich),
                            submittedBy: agent,
                        },
                    });
                }
                setLoadingOverlayActive(false);
            }).catch((err: any) => {
                notification.open({ content: `${t('contactGroups.somethingWentWrong')} ${err.message}`, type: 'error' });
                setLoadingOverlayActive(false);
            });

            return null;
        }

        return new Promise<void>((resolve, reject) => {
            const getStatus = (identifier:string) => setTimeout(async () => {
                try {
                    const { data } = await ServiceManager.customService('heartBeat', [{ identifier }]);

                    if (data?.status === POLLING_STATUS.success) {
                        const agent = `${profile?.FirstName} ${profile?.LastName}`;

                        dispatch({
                            type: ApplicationDetailsActionTypes.SUBMIT_APPLICATION,
                            payload: {
                                applicationId: payload.applicationId,
                                status: ApplicationStatus.submitted,
                                submitted: formatDate(new Date(), dateFormatZurich),
                                submittedBy: agent,
                            },
                        });
                    } else if (data?.status === POLLING_STATUS.notFound) {
                        notification.open({ content: JSON.parse(data?.jsonResult)?.Error, type: 'error' });
                    } else if (data?.status === POLLING_STATUS.error) {
                        notification.open({ content: JSON.parse(data?.jsonResult)?.Error || t('clientDashboard.application.completionFailed'), type: 'error' });
                    }

                    if ([POLLING_STATUS.success, POLLING_STATUS.error, POLLING_STATUS.notFound].includes(data?.status)) {
                        clearIntervalData();
                        resolve();
                    } else {
                        intervalId = getStatus(identifier);
                    }
                } catch (err) {
                    errorCounter += 1;
                    if (errorCounter >= 5) {
                        clearIntervalData();
                        notification.open({ content: t('clientDashboard.application.completionFailed'), type: 'error' });
                        reject();
                    }
                    console.error(err);
                }
            }, 2500);
            const clearIntervalData = () => {
                clearTimeout(intervalId);
                errorCounter = 0;
                setLoadingOverlayActive(false);
            };

            ServiceManager.customInvestmentService(
                'signDocuments',
                [{ investmentApplicationId: payload.applicationId, isNewFlow: true }],
            ).then((response: {data: string}) => {
                intervalId = getStatus(response.data);
            });
        });
    }, [profile]);

    const cancelApplication = useCallback(async (payload: {applicationId: number}) => {
        await cancelInvestmentApplication(payload);
        dispatch({
            type: ApplicationDetailsActionTypes.UPDATE_APPLICATION_STATUS,
            payload: {
                applicationId: payload.applicationId,
                status: ApplicationStatus.canceled,
            },
        });
    }, []);

    const {
        mutateAsync: postDocumentSentFlag,
    } = useDocumentSentFlag();

    const acceptDocumentSend = useCallback(async (payload: {documentId: number}) => {
        await postDocumentSentFlag(payload);
        dispatch({
            type: ApplicationDetailsActionTypes.UPDATE_APPLICATION_DOC_WITH_TRANSFER,
            payload: {
                ...payload,
                status: DocStatus.ReadyToSubmit,
            },
        });
    }, []);

    const onDocGeneration = useCallback(async ({ applicationId }) => {
        dispatch({
            type: ApplicationDetailsActionTypes.UPDATE_GENERATE_APPLICATION_DOC,
            payload: {
                applicationId,
                status: DocStatus.Loading,
            },
        });

        const data = await generateDocs({
            applicationId,
        });

        dispatch({
            type: ApplicationDetailsActionTypes.ADD_GENERATED_DOCS_FOR_APPLICATION,
            payload: {
                applicationId,
                documents: data?.documents?.map((doc) => ({
                    applicationId,
                    documentId: doc?.id,
                    documentName: doc?.documentName,
                    status: doc?.signStatus === STATUS.submitted ? DocStatus.ReadyToSubmit : DocStatus.ReadyToSign,
                    type: DocTypes.APPLICATION,
                })),
            },
        });
    }, []);

    const setRetryState = useCallback(({ applicationId }) => {
        dispatch({
            type: ApplicationDetailsActionTypes.UPDATE_GENERATE_APPLICATION_DOC,
            payload: {
                applicationId,
                status: DocStatus.Error,
            },
        });
    }, []);

    const isAdvisoryDocReadyForSubmission = useMemo(() => (
        !advisoryData || !(
            state.basicAgreementDocuments.some((item) => item.status !== DocStatus.ReadyToSubmit && item.status !== DocStatus.Disabled)
            || state.proofOfAssetDocuments.some((item) => item.status !== DocStatus.ReadyToSubmit && item.status !== DocStatus.Disabled)
        )
    ), [state.basicAgreementDocuments, state.proofOfAssetDocuments, isAdvisoryCase]);

    const isApplicationsLoading = useMemo(() => isInvestmentApplicationsLoading || isApplicationDocsLoading, [isInvestmentApplicationsLoading, isApplicationDocsLoading]);

    const value = useMemo(() => ({
        isJA,
        jaUsers,
        advisoryData,
        advisoryDocument,
        isAdvisoryExpired,
        submitApplication,
        cancelApplication,
        cancelAllApplications,
        acceptDocumentSend,
        isAdvisoryCase,
        onManualSign,
        onESign,
        onDocGeneration,
        setRetryState,
        isAdvisoryDocReadyForSubmission,
        isShoppingCardCancellable,
        isApplicationsLoading,
        ...state,
        // applicationsData,
    }), [
        advisoryData, advisoryDocument, isAdvisoryExpired,
        cancelApplication, isAdvisoryCase, isApplicationsLoading,
        onManualSign, onESign, onDocGeneration, isShoppingCardCancellable,
        setRetryState, isAdvisoryDocReadyForSubmission, state,
    ]);

    return (
        <ApplicationDetailsContext.Provider value={value}>
            {children}
        </ApplicationDetailsContext.Provider>
    );
};
