import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '../Button';
import FormGroup from '../FormGroup';
import Label from '../Label';
import FormControlMessage from '../FormControlMessage';
import FilesList from './FilesList';
import Upload from './Upload';
import withTheme from '../../hocs/withTheme';

export const FileInput = ({
    label,
    labelInfobox,
    uploadText,
    disabled,
    required,
    error,
    multiple,
    children,
    onChange,
    defaultFileList,
    showFileList,
    buttonType,
    ...rest
}) => {
    const [files, setFiles] = useState(defaultFileList);
    const beforeUpload = (file) => {
        const newFilesList = !multiple ? [file] : [...files, file];

        setFiles(newFilesList);
        onChange(newFilesList);

        return false;
    };

    const handleChange = ({ file, fileList }) => {
        const changedFiles = multiple
            ? fileList
            : fileList.filter((fileItem) => fileItem.uid === file.uid);

        setFiles(changedFiles);
        onChange(changedFiles);
    };

    return (
        <FormGroup disabled={disabled} error={error}>
            {
                label && (
                    <Label
                        htmlFor={label}
                        label={label}
                        labelInfobox={labelInfobox}
                        required={required}
                    />
                )
            }
            {showFileList && (
                <FilesList
                    files={files}
                    onRemove={handleChange}
                />
            )}
            <Upload
                {...rest}
                files={files}
                onChange={handleChange}
                beforeUpload={beforeUpload}
                disabled={disabled}
                multiple={multiple}
            >
                <Button size="small" type={buttonType} disabled={disabled}>{uploadText}</Button>

            </Upload>
            {error && (
                <FormControlMessage>{error}</FormControlMessage>
            )}

            {children && <p>{children}</p>}
        </FormGroup>
    );
};

FileInput.propTypes = {
    /** Text for action button */
    uploadText: PropTypes.string,
    /** The label text */
    label: PropTypes.string,
    /** The text displayed if the label tooltip exist */
    labelInfobox: PropTypes.bool,
    /** Whether the input is disabled */
    disabled: PropTypes.bool,
    /** If required field* */
    required: PropTypes.bool,
    /** Whether to support selected multiple file.
     * IE10+ supported. You can select multiple files with CTRL holding
     * down while multiple is set to be true */
    multiple: PropTypes.bool,
    /** The error message displayed if the input is errored */
    error: PropTypes.string,
    /** File types that can be accepted. */
    accept: PropTypes.string,
    /** @ignore */
    children: PropTypes.node,
    /** The default list files to display */
    defaultFileList: PropTypes.arrayOf(PropTypes.shape({
        uid: PropTypes.number,
        name: PropTypes.string,
    })),
    /** The onChange event */
    onChange: PropTypes.func,
    /** Showing File List */
    showFileList: PropTypes.bool,
    /** Button Type */
    buttonType: PropTypes.string,
};

FileInput.defaultProps = {
    uploadText: 'Browse',
    label: null,
    labelInfobox: false,
    disabled: false,
    required: false,
    multiple: false,
    error: null,
    accept: '*',
    children: null,
    defaultFileList: [],
    onChange: () => {
    },
    showFileList: true,
    buttonType: 'secondary',
};

export default withTheme(FileInput);
