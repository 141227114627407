import React, { cloneElement, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './Accordion.css';
import { getClassNames } from '../../utils';
import uid from '../../utils/uid';
import withTheme from '../../hocs/withTheme';

function Accordion(props) {
    const {
        children,
        accordion,
        activeKeys: activeKeysProp,
        defaultActiveKey,
        className: classNameProp,
        onChange,
    } = props;
    const className = getClassNames('Accordion', classNameProp);

    const [activeKeys, setActiveKeys] = useState(activeKeysProp || defaultActiveKey);

    useEffect(() => {
        if (activeKeysProp) setActiveKeys(activeKeysProp);
    }, [activeKeysProp]);

    const changeActiveKeys = (keys) => {
        setActiveKeys(keys);
        if (typeof onChange === 'function') onChange(keys);
    };
    const onClickItem = (key) => {
        if (accordion) {
            changeActiveKeys(activeKeys[0] === key ? [] : [key]);

            return;
        }

        const activeKey = [...activeKeys];
        const index = activeKey.indexOf(key);
        const isActive = index > -1;

        if (isActive) {
            activeKey.splice(index, 1);
        } else {
            activeKey.push(key);
        }

        changeActiveKeys(activeKey);
    };

    const getNewChild = (child) => {
        if (!child) return null;

        const { disabled } = child.props;
        const key = child.key || uid('panel');
        const isActive = activeKeys.indexOf(key) > -1;

        const props = {
            panelKey: key,
            isActive,
            accordion,
            onItemClick: disabled ? null : onClickItem,
        };

        if (typeof child.type === 'string') {
            return child;
        }

        return cloneElement(child, props);
    };
    const childrenWithProps = React.Children.map(children, getNewChild);

    return (
        <div className={className}>
            {childrenWithProps}
        </div>
    );
}

Accordion.propTypes = {
    /** Whether the Collapse renders as Accordion */
    accordion: PropTypes.bool,
    /** Keys of the initial active panel */
    activeKeys: PropTypes.arrayOf(PropTypes.string),
    /** Default keys of the initial active panel */
    defaultActiveKey: PropTypes.arrayOf(PropTypes.string),
    /** @ignore */
    className: PropTypes.string,
    /** @ignore */
    children: PropTypes.node,
    /** Callback function executed when active panel is changed */
    onChange: PropTypes.func,
};

Accordion.defaultProps = {
    accordion: false,
    activeKeys: null,
    defaultActiveKey: [],
    className: null,
    children: null,
    onChange: () => {},
};

export default withTheme(Accordion);
