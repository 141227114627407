import React from 'react';
import PropTypes from 'prop-types';
import { getClassNames } from '../../utils';
import withTheme from '../../hocs/withTheme';
import './Grid.css';

/** Column is supposed to be a part of grid and wrapped into container. */

function Container({ children, className, ...props }) {
    return (
        <div {...props} className={getClassNames(className, 'container')}>
            {children}
        </div>
    );
}

Container.propTypes = {
    /** @ignore */
    children: PropTypes.node,
    /** @ignore */
    className: PropTypes.string,
};

Container.defaultProps = {
    children: null,
    className: null,
};

export default withTheme(Container);
