import {useQuery} from '@tanstack/react-query';
import ServerError from '../../errors/ServerError';
import ServiceManager from '../../services/ServiceManager';

export const useGetFzAndFzpEnabled = ({
    clientId,
}) => {
    const result = useQuery({
        queryKey: ['checkIsFzAndFzpEnabled', clientId],
        enabled: !!clientId,
        retry: false,
        gcTime: 10,
        queryFn: async () => {
            const {data} = await ServiceManager.customInvestmentService('checkIsFzAndFzpEnabled', [clientId]);

            return data;
        },
    });

    return {
        ...result,
        error: result.error ? new ServerError(result.error) : result.error,
    };
};
