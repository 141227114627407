import React, { Component } from 'react';
import PropTypes from 'prop-types';
import OutsideClickHandler from 'react-outside-click-handler';
import Icon from '../Icon/Icon';
import { ICON_TYPE_CLOSE } from '../Icon/constants';
import { getClassNames } from '../../utils';
import './Navigation.css';
import withTheme from '../../hocs/withTheme';


class Navigation extends Component {
    constructor(props) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
    }

    componentDidMount() {
        document.addEventListener('click', this.handleClick);
        document.addEventListener('keydown', this.handleKeyPress);
    }

    componentDidUpdate(prevProps) {
        if (this.props.isOpen) {
            const navigationLink = this.navigation.getElementsByClassName('Navigation__link')[0];

            if (navigationLink) navigationLink.focus();
        } else if (prevProps.isOpen === true && !this.props.isOpen) {
            const headerBtn = document.querySelectorAll('.header__button[name="menu"]')[0];

            if (headerBtn) headerBtn.focus();
        }
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClick);
        document.removeEventListener('keydown', this.handleKeyPress);
    }

    handleClickOutside = (event) => this.props.onClose(event);

    handleClick({ target: { classList } }) {
        if (classList.length && [...classList].includes('Navigation__link')) {
            this.props.onClose();
        }
    }

    handleKeyPress(event) {
        if (event.keyCode === 27) {
            this.props.onClose(event);
        }
        if (event.keyCode === 9 && this.props.isOpen) {
            if (![...this.navigation.children].includes(document.activeElement)) {
                this.navigation.children[0].focus();
            }
        }
    }

    render() {
        const {
            isOpen, children, onClose, closeAriaLabel,
        } = this.props;

        return (
            <OutsideClickHandler
                onOutsideClick={this.handleClickOutside}
            >
                <div
                    ref={(input) => { this.navigation = input; }}
                    className={getClassNames('Navigation', { Navigation_open: isOpen })}
                >
                    <button
                        type="button"
                        className="Navigation__button"
                        onClick={onClose}
                        aria-label={closeAriaLabel}
                    >
                        <Icon type={ICON_TYPE_CLOSE} />
                    </button>
                    {children}
                </div>
            </OutsideClickHandler>
        );
    }
}

Navigation.propTypes = {
    /** Whether can be opened */
    isOpen: PropTypes.bool,
    /** @ignore */
    children: PropTypes.node,
    /** The aria-label for close button */
    closeAriaLabel: PropTypes.string,
    /** The callback function that is triggered when the close button is clicked */
    onClose: PropTypes.func,
};

Navigation.defaultProps = {
    isOpen: false,
    children: null,
    closeAriaLabel: 'Close menu',
    onClose: () => {},
};

export default withTheme(Navigation);
