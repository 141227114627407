import { createContext } from 'react';

const initialContext = {
    id: undefined,
    clientId: undefined,
    // logic from usePortfolioDetails hook
    portfolio: {
        data: {
            investmentAllocation: { chart: [{ data: { pie: {}, bar: {}, list: {} } }] },
            positions: [],
        },
        dataRaw: {},
        error: null,
        isLoading: false,
        getPortfolioDetails: () => {},
    },
    // logic from useModelPortfolio hook
    modelPortfolio: {
        data: {},
        dataRaw: {},
        error: null,
        isLoading: false,
        getModelPortfolio: () => {},
    },
};

const PortfolioContext = createContext(initialContext);

export default PortfolioContext;
