import React from 'react';
import PropTypes from 'prop-types';

function SelectControls({ check, uncheck, selectControlsLabel }) {
    return (
        <div className="select__controls">
            <button type="button" onClick={check}>{selectControlsLabel.selectAll}</button>
            <button type="button" onClick={uncheck}>{selectControlsLabel.reset}</button>
        </div>
    );
}

SelectControls.propTypes = {
    check: PropTypes.func,
    uncheck: PropTypes.func,
    selectControlsLabel: PropTypes.shape({
        selectAll: PropTypes.string,
        reset: PropTypes.string,
    }),
};

SelectControls.defaultProps = {
    check: () => {},
    uncheck: () => {},
    selectControlsLabel: {
        selectAll: 'Select all',
        reset: 'Reset',
    },
};

export default SelectControls;
