import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Icon from '../Icon';
import { HEADER_ICONS_TYPE } from './constants';
import { getClassNames } from '../../utils';
import withTheme from '../../hocs/withTheme';

function HeaderButton({
    type, to, ariaLabel, children, onClick, className,
}) {
    return (
        <React.Fragment>
            {to ? (
                <Link to={to} className="Header__button">
                    {type && <Icon type={type} />}
                    {children && <span>{children}</span>}
                </Link>
            )
                : (
                    <button
                        type="button"
                        name={type}
                        className={getClassNames(className, 'Header__button')}
                        onClick={onClick}
                        aria-label={ariaLabel}
                    >
                        {type && <Icon type={type} />}
                        {children && <span>{children}</span>}
                    </button>
                )}
        </React.Fragment>
    );
}

HeaderButton.propTypes = {
    type: PropTypes.oneOf(HEADER_ICONS_TYPE),
    to: PropTypes.string,
    ariaLabel: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.node,
    onClick: PropTypes.func,
};

HeaderButton.defaultProps = {
    type: null,
    to: null,
    ariaLabel: null,
    className: undefined,
    children: null,
    onClick: () => { },
};

export default withTheme(HeaderButton);
