export const resourceAdapt = (resources) => {
    try {
        if (Array.isArray(resources) && resources.length > 0 && resources[0].Resources) {
            return resources[0].Resources.reduce((accumulator, currentValue) => {
                if (currentValue?.Name) {
                    accumulator[currentValue.Name] = currentValue?.ResourceValues?.[0]?.Value;
                }


                return accumulator;
            }, {});
        }

        return {};
    } catch {
        return {};
    }
};
