import {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import ServiceManager from '../services/ServiceManager';

export const useClientList = (variables = {}, ContactItemsFragment = '', additionalFilters = null) => {
    const [data, setData] = useState();
    const [error, setError] = useState();
    const [isLoading, setLoading] = useState(false);

    const searchClient = useCallback(async () => {
        try {
            setLoading(true);
            const response = await ServiceManager.customClientService('search', [{
                filter: variables?.search,
                pageNumber: variables?.pageNumber,
                pageSize: variables?.pageSize,
            }]);

            let responseData = response?.data;

            if (additionalFilters) {
                responseData = additionalFilters(response?.data);
            }

            setData(responseData);
        } catch (e) {
            setError(e?.message);
        } finally {
            setLoading(false);
        }
    }, [variables?.search, variables?.pageNumber, variables?.pageSize, additionalFilters]);

    useEffect(() => {
        searchClient();
    }, [variables?.search, variables?.pageNumber, variables?.pageSize, searchClient]);

    return {
        data: data?.contactClient,
        isLoading,
        error,
        totalCount: data?.total,
    };
};
