import React from 'react';
import { Title } from 'ui-library';
import './StaticField.css'

interface StaticFieldProps {
  label?: string; 
  value: string|number;
  secondaryValue?: string;
  Icon?: any
  width?: string
  className?: string
}

export const StaticField: React.FC<StaticFieldProps> = ({ label, value, secondaryValue, Icon, width, className = '' }) => (
    <div style={{'width':width}} className={`shopping-cart-row--static-field ${className}`}>
      <Title type={4}><strong>{label}</strong></Title>
      {(value ?? secondaryValue) && <div>
        <div className=''><span className='shopping-cart-row--static-field--value'>{value}</span><span>{Icon}</span></div>
        {secondaryValue && <div>{secondaryValue}</div>}
      </div>}
    </div>
  );