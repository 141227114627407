/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
import PropTypes from 'prop-types';
import React from 'react';
import {renderers} from 'ui-library';
import {getClassNames} from 'utils';
import './CellLink.css';

const {CellLinked} = renderers;

function CellLink({
    label,
    link,
    state,
    disabled,
}) {
    const className = getClassNames('table-cell_checkboxed', {
        'table-cell_disabled': disabled,
        'table-cell_active': !disabled,
    });

    return (
        <div onClick={(e) => e.stopPropagation()} className={className}>
            {CellLinked({
                value: label,
                link: {
                    pathname: link,
                    state
                },
            })}
        </div>
    );
}

CellLink.propTypes = {
    label: PropTypes.string,
    link: PropTypes.string,
    state: PropTypes.shape({}),
    disabled: PropTypes.bool,
};

CellLink.defaultProps = {
    label: '',
    link: '',
    state: {},
    disabled: false,
};

export default CellLink;
