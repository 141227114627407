import React from 'react';

import './styles/FooterNavbar.css';

const FooterNavbar = (props) => (
    <>
        <div className="footerNavBar container">
            {props.children}
        </div>
    </>
);

export default FooterNavbar;
