import React from 'react';
import PropTypes from 'prop-types';
import { getClassNames } from '../../../utils';
import Icon from '../../Icon';
import { ICON_TYPE_REPLY } from '../../Icon/constants';

function CellWithMessageDate({ date, isReplied }) {
    const className = getClassNames(
        'table-cell_with_message_date',
        { 'table-cell_with_message_date-replied': isReplied },
    );

    return (
        <div className={className}>
            { isReplied ? <Icon type={ICON_TYPE_REPLY} /> : <br /> }
            <span>{date}</span>
        </div>
    );
}

CellWithMessageDate.propTypes = {
    isReplied: PropTypes.bool,
    date: PropTypes.string,
};

CellWithMessageDate.defaultProps = {
    isReplied: true,
    date: null,
};

export default CellWithMessageDate;
