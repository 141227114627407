import { useMemo } from 'react';
import { usePortfolioFlowLayoutState } from 'datasource/PortfolioProcessLayout/usePortfolioFlowLayoutState';
import { useStepperSelectors, useStepperState } from '../../core/stepper';
import { PortfolioFlowLayoutContract } from '../../components/PortfolioProcessLayout/PortfolioProcessLayout.types';
import { useCreatePortfolioSelectors } from '../../datasource/CreatePortfolio';
import { useContactGroupOwners } from '../helpers/useContactGroupOwners';
import { stepsKeys, useCreatePortfolioFlow } from '../../widgets/CreatePortfolio/useCreatePortfolioFlow';
import { flattenSteps, flattenViewSteps, getStepsForLayout } from '../../utils/stepDefintion';

export const useCreatePortfolioLayoutProcess = (): PortfolioFlowLayoutContract => {
    const {
        onContinue, onBack, inProgress, continueDisabled, backDisabled, hideStepperButtons, pageErrors,
    } = usePortfolioFlowLayoutState();
    const { stepsBasedOnState } = useCreatePortfolioFlow();
    const { currentProcessSelector } = useStepperSelectors();

    const {
        currentClientSelector,
        selectedProductTypeSelector,
        contactGroupIdSelector,
    } = useCreatePortfolioSelectors();

    const { mainOwner, jaClient } = useContactGroupOwners({ connectionGroupId: contactGroupIdSelector });

    const viewSteps = useMemo(() => (
        getStepsForLayout(stepsBasedOnState)
    ), [stepsBasedOnState]);
    const flatSteps = useMemo(() => (
        flattenSteps(stepsBasedOnState)
    ), [stepsBasedOnState]);
    const currentProcessSteps = useMemo(() => (
        viewSteps[currentProcessSelector]
    ), [currentProcessSelector, viewSteps]);

    const currentProcessStepsFlat = useMemo(() => (flattenViewSteps(currentProcessSteps)), [currentProcessSteps]);
    const { currentStepKey } = useStepperState();
    const currentStepNumber = useMemo(() => (currentProcessStepsFlat.find((item) => (item.label === currentStepKey))?.position ?? 1), [currentStepKey, currentProcessStepsFlat]);

    const footerAdditionalInfo = useMemo(() => [
        {
            label: 'portfolio.createNewPortfolio.clientName',
            values: [mainOwner?.name ?? currentClientSelector?.name ?? '-', ...[jaClient?.name]],
        },
        {
            label: 'portfolio.createNewPortfolio.clientAge',
            values: [mainOwner?.age?.toString() ?? currentClientSelector?.age?.toString() ?? '-', ...[jaClient?.age?.toString()]],
        },
        {
            label: 'portfolio.createNewPortfolio.productOffer',
            values: [selectedProductTypeSelector ?? '-'],
        },
    ], [currentClientSelector, selectedProductTypeSelector, mainOwner, jaClient]);
    const title = useMemo(() => 'portfolio.createNewPortfolio.stepper.title', []);
    const contentTitle = useMemo(() => flatSteps[currentStepKey]?.contentTitle, [flatSteps[currentStepKey]?.contentTitle]);
    const contentClassName = useMemo(() => flatSteps[currentStepKey]?.contentClassName, [flatSteps[currentStepKey]?.contentClassName]);

    const isStepperEnabled = useMemo(() => currentStepKey !== stepsKeys.GeneralProductSelection, [currentStepKey]);

    const isFooterSimplified = useMemo(() => currentStepKey !== stepsKeys.GeneralProductSelection, [currentStepKey]);

    return {
        footerAdditionalInfo,
        stepper: {
            steps: currentProcessSteps,
            title,
            currentStepNumber,
        },
        contentTitle,
        contentClassName,
        isStepperEnabled,
        isFooterSimplified,
        onBack,
        onContinue,
        inProgress,
        continueDisabled,
        backDisabled,
        hideStepperButtons,
        pageErrors,
        productType: selectedProductTypeSelector,
    };
};
