const now = +(new Date());
let index = 0;


export default function uid(prefix = 'upload') {
    index += 1;


    return `${prefix}-${now}-${index}`;
}
