import React from 'react';
import PropTypes from 'prop-types';
import NotificationItem from './NotificationItem';

const Notifications = ({
    data, onReadNotification, errorMessage, emptyMessage,
}) => {
    if (errorMessage) {
        return <div className="NotificationsError">{errorMessage}</div>;
    }
    if (!Array.isArray(data) || data.length === 0) {
        return <div className="NotificationsError">{emptyMessage}</div>;
    }

    return (
        <div className="Notifications">
            {data.map(({
                id, date, subject, text,
            }) => (
                <NotificationItem
                    onClick={onReadNotification}
                    key={id}
                    id={id}
                    date={date}
                    subject={subject}
                    text={text}
                />
            ))}
        </div>
    );
};


Notifications.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.oneOfType(
                [PropTypes.string, PropTypes.number],
            ),
            subject: PropTypes.string,
            date: PropTypes.string,
            text: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.object,
            ]),
        }),
    ),
    onReadNotification: PropTypes.func,
    errorMessage: PropTypes.string,
    emptyMessage: PropTypes.string,
};

Notifications.defaultProps = {
    data: [],
    onReadNotification: () => {
    },
    errorMessage: null,
    emptyMessage: null,
};
export default Notifications;
