import React, {useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Button, Loader} from '../../../../../ui-library';
import ServiceManager from '../../../../../services/ServiceManager';

const FactsheetRenderer = ({securityId}) => {
    const {t, i18n: {language}} = useTranslation();
    const [inProgress, setInProgress] = useState(false);

    const downloadFile = useCallback(async () => {
        try {
            setInProgress(true);
            const {data } = await ServiceManager.instrumentsService('getSecurity', [securityId, language]);

            global.open(data?.Factsheet?.replace('url:', ''));
        } catch (err) {
            //
        } finally {
            setInProgress(false);
        }
    }, [securityId, language]);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return <Button disabled={inProgress} onClick={downloadFile}>{inProgress ? <Loader/> : t('portfolio.createNewPortfolio.strategy.factsheetUrl')}</Button>;
};

export default FactsheetRenderer;
