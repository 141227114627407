import {useTranslation} from 'react-i18next';
import React, {FC} from 'react';
import {Title} from 'ui-library';
import {applyThousandSeparator} from 'ui-library/components/NumberInput/utils';

const InvestmentAmountOptions: FC<{
    desiredInvestmentAmount: number;
    remainingInvestmentAmount: number
}> = ({
    desiredInvestmentAmount,
    remainingInvestmentAmount
}) => {
    const {t} = useTranslation();

    return (
        <div className="mb-32">
            <div className="investment-amount-options">
                <div className="investment-amount-option-item">
                    <Title type={3}>
                        {t('ShoppingCart.investmentAmount.desired')}
                    </Title>
                    <div
                        className={`investment-amount + ${remainingInvestmentAmount < 0 ? 'investment-amount-error' : ''}`}>
                        <strong>
                            CHF
                            {' '}
                            {applyThousandSeparator(`${desiredInvestmentAmount?.toFixed(2)}`, '\'')}
                        </strong>
                    </div>
                </div>
                <div
                    className={`investment-amount-option-item remaining-amount ${remainingInvestmentAmount < 0 ? 'negative-amount' : ''}`}>
                    <Title type={3}>
                        {t('ShoppingCart.investmentAmount.remaining')}
                    </Title>
                    <div className="investment-amount">
                        <strong>
                            {remainingInvestmentAmount < 0 && '- '}
                            CHF
                            {' '}
                            {applyThousandSeparator(`${Math.abs(+remainingInvestmentAmount.toFixed(2) as number)}`, '\'')}
                        </strong>
                    </div>
                </div>
            </div>

            {remainingInvestmentAmount < 0 && (
                <div className="negative-remaining-amount-error">
                    {t('ShoppingCart.remainingInvestmentAmountError')}
                </div>
            )}
        </div>

    );
};

export default InvestmentAmountOptions;
