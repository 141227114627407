import React from 'react';
import {getClientId} from 'utils';
import {InvestorProfileProvider} from 'components/InvestorProfileBase/Provider';
import {InvestorProfile} from './InvestorProfile';

import './InvestorProfile.css';

export function InvestorProfileWithProvider({
    dfsClientId, clientAge, clientName, title, isSubmitted, isSending, sessionStorageKey, onQuestionnaireUpdate,
}) {
    const clientId = getClientId(dfsClientId);

    return (
        <InvestorProfileProvider options={{
            clientId,
            sessionStorageKey,
        }}
        >
            <InvestorProfile
                dfsClientId={dfsClientId}
                clientAge={clientAge}
                clientName={clientName}
                title={title}
                isSubmitted={isSubmitted}
                isSending={isSending}
                onQuestionnaireUpdate={onQuestionnaireUpdate}
            />
        </InvestorProfileProvider>
    );
}
