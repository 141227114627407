import { getCustomUrl } from '../../setup';
import Service from './service';
import Security from './security';

class Middleware extends Service {
    static log(data) {
        return this.postRequest(`${getCustomUrl()}/log`, data, { isSecure: true });
    }

    static postCSSchemaEditCutomer(data) {
        return this.postRequest(`${getCustomUrl()}/clientservices/schemas/editcustomer`, data, { isSecure: true });
    }

    static postCSContactDetails(contactId, data) {
        return this.postRequest(`${getCustomUrl()}/clientservices/contacts/${contactId}/details`, data, { isSecure: true });
    }

    static putCSContactDetails(contactId, data) {
        return this.putRequest(`${getCustomUrl()}/clientservices/contacts/${contactId}/details`, data, { isSecure: true });
    }

    static async postVerifyCaptchaToken(data) {
        await Security.generateBearerApplicationAccessTokenIfNeeded();

        return Service.postRequest(`${getCustomUrl()}/google/recaptcha/verify`, data);
    }

    static async postVerifyCaptchaV2Token(data) {
        await Security.generateBearerApplicationAccessTokenIfNeeded();

        return Service.postRequest(`${getCustomUrl()}/google/v2.0/recaptcha/verify`, data);
    }

    static getGoogleAnalytics() {
        return Service.getRequest(`${getCustomUrl()}/google/analitics`);
    }
}

export default Middleware;
