import React, { FC } from 'react';
import {
    Icon, Loader, NumberInput, Select,
} from 'ui-library';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ICON_TYPE_BIN } from 'ui-library/components/Icon';

import EmptyComp from 'components/common/EmptyComp';
import AddButton from 'components/common/AddButton';
import InfoBox from 'components/common/InfoBox';
import { RealEstateProps } from './RealEstate.types';
import './RealEstate.css';

const RealEstate: FC<RealEstateProps> = ({ datasource = () => {} }) => {
    const {
        fields, control, errors, advisoryRealEstatePropertyTypes, handleAddProperty, handleRemoveProperty, getPropertyValueMinusMortgage,
    } = datasource() || {};

    const { t } = useTranslation();

    const propertyOptions = advisoryRealEstatePropertyTypes?.data || [];

    if (advisoryRealEstatePropertyTypes?.status === 'pending') {
        return <Loader />;
    }

    return (
        <div className="real-state-form-comp">
            {fields?.length ? fields?.map((item, index) => (
                <div key={item?.id || index} className="real-state-form flex">
                    <Controller
                        name={`realEstate.${index}.propertyListItemId`}
                        control={control}
                        render={({ field }) => (
                            <Select
                                {...field}
                                label={index === 0 ? t('RealEstateForm.label.property') : ''}
                                options={propertyOptions}
                                placeholder={t('position.pleaseSelect')}
                                error={errors?.[`realEstate[${index}].propertyListItemId`] ? t('validation.mandatoryField') : ''}
                            />
                        )}
                    />
                    <Controller
                        name={`realEstate.${index}.value`}
                        control={control}
                        render={({ field }) => (
                            <NumberInput
                                {...field}
                                placeholder=""
                                thousandSeparator="'"
                                decimalSeparator="."
                                label={index === 0 ? t('RealEstateForm.label.value') : ''}
                                data-cy={`realEstate.${index}.value`}
                                error={errors?.[`realEstate[${index}].value`] ? t('validation.mandatoryField') : ''}
                                prefix="CHF "
                                textRight
                            />
                        )}
                    />
                    <Controller
                        name={`realEstate.${index}.mortgage`}
                        control={control}
                        render={({ field }) => (
                            <NumberInput
                                {...field}
                                placeholder=""
                                thousandSeparator="'"
                                decimalSeparator="."
                                label={index === 0 ? t('RealEstateForm.label.mortgage') : ''}
                                data-cy={`realEstate.${index}.mortgage`}
                                error={errors?.[`realEstate[${index}].mortgage`] ? t('validation.mandatoryField') : ''}
                                prefix="CHF "
                                textRight
                            />
                        )}
                    />
                    <button
                        type="button"
                        className="remove-property-btn"
                        aria-label="remove-property-button"
                        onClick={() => handleRemoveProperty(index)}
                    >
                        <Icon
                            type={ICON_TYPE_BIN}
                            size={20}
                        />
                    </button>
                </div>
            )) : <EmptyComp text={t('RealEstateForm.noProperties')} className="mt-40"/>}

            <AddButton onClick={handleAddProperty} label={t('RealEstateForm.button.addProperty')}/>

            {getPropertyValueMinusMortgage() != null ? (
                <InfoBox
                    title={t('RealEstateForm.info.totalPropertyValueMinusMortgage')}
                    value={getPropertyValueMinusMortgage().toFixed(2)}
                />
            ) : ''}

        </div>
    );
};

export default RealEstate;
