import React from 'react';
import Dialog from 'rc-dialog';

const ModalCore = (props) => {
    return <Dialog
        {...props}
    />
};

export default ModalCore;
