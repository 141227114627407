import React, {useCallback} from 'react';
import {isEmpty} from 'lodash';

import Card from 'components/Card/Card';
import Preloader from 'components/Preloader';
import EmptyContent from 'components/EmptyContent';
import {useTranslation} from 'react-i18next';

import { CustomTable } from 'components/CustomTable';

import './OverviewApplications.css';

import {
    DEFAULT_PAGE_SIZE,
    containersColumns, applicationListColumns,
} from './constants/const';
import {useApplicationTable} from './hooks/useApplicationTable';

export const ApplicationList = () => {
    const { t } = useTranslation();

    const {
        list,
        totalPages,
        page,
        setPage,
        isLoading,
        error,
    } = useApplicationTable();

    const tableColumns = applicationListColumns(t);

    const onChangePage = useCallback((paging) => {
        setPage(paging);
    }, []);

    return (
        <Card title={t('dashboard.client.appsAndConsultations')}>
            <Preloader isLoading={isLoading} error={error}>
                <EmptyContent data={list} text="Not Found">
                    <CustomTable
                        columns={tableColumns}
                        data={list}
                        onChange={onChangePage}
                        paging={{
                            pageSize: DEFAULT_PAGE_SIZE,
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            pageNumber: page,
                            totalPages,
                        }}
                    />
                </EmptyContent>
            </Preloader>
        </Card>
    );
};
