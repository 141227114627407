// eslint-disable @typescript-eslint/ban-ts-comment
import React, {useCallback, useEffect, useMemo, useState,} from 'react';
import {useTranslation} from 'react-i18next';
import isNil from 'lodash/isNil';
import {useLocation} from 'react-router-dom';
import {StepperConfigType, StepperPropType} from './types/StepperPropType';
import StepperItem from './StepperItem';
import {Icon, Loader} from '../../ui-library';
import {ICON_TYPE_DROPDOWN} from '../../ui-library/components/Icon';
import {flattenArray} from '../../utils/formatting';

import './Stepper.css';

type AdaptedConfigType = StepperConfigType & {
    id?: string;
    completed?: boolean;
}

const Stepper = ({
    config,
    title,
    className,
    currentStepNumber = 2,
    productType = '',
}: StepperPropType) => {
    const {t} = useTranslation();
    const [completionPercentage, setCompletionPercentage] = useState(0);
    const [selectedStep, setSelectedStep] = useState<AdaptedConfigType>({} as AdaptedConfigType);
    const [adaptedConfig, setAdaptedConfig] = useState<AdaptedConfigType[]>();
    const [showSection, setShowSection] = useState<{ [key: string]: boolean }>({});

    const {pathname} = useLocation();

    const singleArrayItems = useMemo(() => flattenArray(adaptedConfig), [adaptedConfig]);
    const totalNumberOfSteps = useMemo(() => singleArrayItems?.length, [singleArrayItems?.length]);
    const totalStepsCompleted = useMemo(() => singleArrayItems?.filter(f => f.id < selectedStep.id! && !selectedStep.id!.startsWith(f.id))?.length, [selectedStep, singleArrayItems]);
    const selectedStepIndex = useMemo(() => singleArrayItems?.findIndex(f => f.id === selectedStep.id), [selectedStep]);

    const getAdaptedConfig = useCallback((configItems: AdaptedConfigType[], parentId: string = '') => configItems?.map((item, index) => {
        const newId = parentId ? `${parentId}.${index + 1}` : `${index + 1}`;

        return {
            ...item,
            id: newId,
            children: item.children?.length ? getAdaptedConfig(item.children, newId) : null,
        };
    }), [config]);

    const toggleSection = useCallback((key) => {
        setShowSection(prev => ({
            ...prev,
            [key]: !isNil(prev[key]) ? !prev[key] : false
        }));
    }, []);

    const getSelectedStep = useCallback((configItems?: AdaptedConfigType[]) => configItems?.forEach((item: StepperConfigType) => {
        if (item.path === pathname || item.position === currentStepNumber) {
            setSelectedStep(item);

            return;
        }
        if (item.children?.length) {
            getSelectedStep(item.children);
        }
    }), [pathname, currentStepNumber]);

    const renderItems = useCallback((configItems?: AdaptedConfigType[]) => configItems?.sort((a, b) => a.position - b.position)
        .map((item, index) => {
            if (item.children?.length) {
                const isVisible = isNil(showSection[item.id!]) || !!showSection[item.id!];

                return (
                    <section data-id={item.id} key={item?.id || index}>
                        <div role="button" tabIndex={0} className="collapsable" onClick={() => toggleSection(item.id)}
                             onKeyDown={() => {
                             }}>
                            <StepperItem name={item.name}
                                         isSelected={selectedStep?.id === item.id || (item.id! < selectedStep.id! && selectedStep.id!.startsWith(item.id!))}
                                         isCompleted={item.id! < selectedStep.id!}/>
                            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                            {/* @ts-ignore */}
                            <Icon type={ICON_TYPE_DROPDOWN} size={12} className={isVisible && 'reverse'}/>
                        </div>
                        {isVisible && <div className="children">{renderItems(item.children)}</div>}
                    </section>
                );
            }

            return <StepperItem key={item?.id || index} data-id={item.id} name={item.name}
                                isSelected={selectedStep?.id === item.id} isCompleted={item.id! < selectedStep.id!}/>;
        }), [selectedStep, showSection]);

    useEffect(() => {
        const percentage = ((totalStepsCompleted * 100) / totalNumberOfSteps);

        setCompletionPercentage(Math.trunc(percentage) || 0);
    }, [totalNumberOfSteps, totalStepsCompleted]);

    useEffect(() => {
        getSelectedStep(adaptedConfig);
    }, [getSelectedStep, adaptedConfig]);

    useEffect(() => {
        setAdaptedConfig(getAdaptedConfig(config));
    }, [getAdaptedConfig, config]);

    if (!config?.length) {
        return (
            <div className="stepper-loader-wrapper">
                <Loader/>
            </div>
        );
    }

    return (
        <section className={`stepper ${className || ''}`}>
            {title && (
                <h1>
                    {t(title)}
                    {' '}
                    <div>{productType}</div>
                </h1>
            )}
            <div className="percentage"
                 style={{background: `radial-gradient(closest-side, white 89%, transparent 90% 100%), conic-gradient(#2167ae ${completionPercentage}%, white 0)`}}>{`${completionPercentage}%`}</div>
            <p className="steps-title">{t('stepper.stepOfSteps', {
                currentStep: selectedStepIndex + 1,
                total: totalNumberOfSteps
            })}</p>
            {renderItems(adaptedConfig)}
        </section>
    );
};

export default Stepper;
