export const STEP_STATUS_FINISH = 'finish';
export const STEP_STATUS_PROCESS = 'process';
export const STEP_STATUS_WAITING = 'wait';

export const STEP_STATUS_TYPES = [
    STEP_STATUS_FINISH,
    STEP_STATUS_PROCESS,
    STEP_STATUS_WAITING,
];

export const PROGRESS_BAR_SIZE_SMALL = 'small';
export const PROGRESS_BAR_SIZE_DEFAULT = 'default';

export const PROGRESS_BAR_SIZES = [
    PROGRESS_BAR_SIZE_SMALL,
    PROGRESS_BAR_SIZE_DEFAULT,
];


export const PROGRESS_BAR_TYPE_DOTS = 'dots';
export const PROGRESS_BAR_TYPE_BARS = 'bars';

export const PROGRESS_BAR_TYPES = [
    PROGRESS_BAR_TYPE_DOTS,
    PROGRESS_BAR_TYPE_BARS,
];

export const PREFIX_CLS = 'Steps';
