export const localizeFormat = (localizer, date, format) => {
    if (localizer.format(date) === undefined) {
        return date.toLocaleString('default', format);
    }

    return localizer.format(date, format);
};

export const formatEvents = (data) => data.map((event) => ({
    ...event,
    end: new Date(event.endDate),
    start: new Date(event.startDate),
}));

export const dateFormat = (dayFormat, firstDayFormat) => (date, culture, localizer) => {
    const isToday = (new Date().toDateString() === date.toDateString());

    if (date.getDate() === 1 && isToday) return localizeFormat(localizer, date, dayFormat);

    return localizeFormat(localizer, date, date.getDate() === 1 ? firstDayFormat : dayFormat);
};

export const getTimeTopPosition = (date) => {
    const seconds = (date.getHours() * 60 + date.getMinutes()) * 60;

    return `${seconds / 86340 * 100}%`;
};

export const isLabelHidden = (now, date) => (now.getHours() === date.getHours()
    && now.getMinutes() < 31) || (now.getHours() + 1 === date.getHours() && now.getMinutes() > 30);


export const datesEqual = (date, dateCompare) => date.getFullYear() === dateCompare.getFullYear()
    && date.getMonth() === dateCompare.getMonth()
    && date.getDate() === dateCompare.getDate();
