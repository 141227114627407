export const sendModel = (data) => (
    {
        details: {
            personalInformation: {
                taxDomicile: data?.taxDomicile || +data?.countryOfResidence,
                riskDomicile: data?.riskDomicile,
                affiliatedPensionFund: data?.affiliatedPensionFund === 'true',
                zurichMA: data?.zurichMA,
                member: data?.member,
                destinedLife: data?.destinedLife,
                occupation: +data?.occupation,
            },
        },
    }
);
