import React, {useEffect} from 'react';
import {User} from 'oidc-client';
import {global} from '@apollo/client/utilities/globals';
import {authenticator} from '../authenticator';

type CallbackContainerProps = {
    children?: React.ReactChild | React.ReactChild[] | null;
    redirectPath?: string
}

export const onRedirectSuccess = (redirectPath = '/') => (user: User) => {
    const urlPath = user?.state?.url ?? redirectPath;

    window.dispatchEvent(new CustomEvent('popstate', {detail: {urlPath}}));
};

const CallbackContainer = ({children = null, redirectPath = '/'}: CallbackContainerProps) => {
    const userManager = authenticator.getUserManager();

    useEffect(() => {
        const onSuccess = onRedirectSuccess(redirectPath);

        userManager?.signinRedirectCallback().then(
            onSuccess,
            userManager?.signinRedirect,
        ).catch(() => {
            global.location.href = '/';
        });
    }, []);

    return (
        <>
            {children}
        </>
    );
};

export default CallbackContainer;
