import { DefaultError } from '@tanstack/react-query';
import SM from 'services/ServiceManager';
import { useErrorWrappedAPIMutation } from 'hooks/mutations/useErrorWrappedAPIMutation';

export const useUploadProofOfAssetsDocument = () => useErrorWrappedAPIMutation<any, DefaultError, {
        containerId?: string,
        contactId?: number,
        originOfAsset?: number,
        document: {
            filename: string,
            file: string,
            documentId: number,
        }
    }>({
        mutationFn: async (payload) => {
            const { data } = await SM.customDocumentsService(
                'uploadProofOfAssetsDocuments',
                [payload],
            );

            return data;
        },
    });
