import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLayout } from '../../../hooks/useLayout';
import PlanWizardLayoutContext from './context';
import { Loader } from '../../../../../../../ui-library';
import { PlanWizardLayout } from '../PlanWizardLayout';

const WizardLayoutProvider = ({
    children,
    steps,
    withRisk,
    withProductGroupName,
}) => {
    const layout = useLayout();

    const state = {
        ...layout,
    };

    return (
        <PlanWizardLayoutContext.Provider value={{ ...state }}>
            <PlanWizardLayout
                {...{
                    ...layout?.data,
                    disabled: layout?.data?.disabled || layout?.data?.saveInProgress,
                    backDisabled: layout?.data?.saveInProgress || layout?.data?.backDisabled,
                    continueLabel: layout?.data?.saveInProgress ? <Loader /> : layout?.data?.continueLabel,
                }}
                steps={steps}
                withRisk={withRisk}
                withProductGroupName={withProductGroupName}
            >
                {children}
            </PlanWizardLayout>
        </PlanWizardLayoutContext.Provider>
    );
};

WizardLayoutProvider.propTypes = {
    withRisk: PropTypes.bool,
    withProductGroupName: PropTypes.bool,
    children: PropTypes.element.isRequired,
    steps: PropTypes.arrayOf(
        PropTypes.shape({
            number: PropTypes.number,
            name: PropTypes.string,
        }),
    ).isRequired,
};

WizardLayoutProvider.defaultProps = {
    withRisk: false,
    withProductGroupName: false,
};

export default WizardLayoutProvider;
