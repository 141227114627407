import React from 'react'
import {
    WebStorageStateStore, User, UserManagerSettings
} from 'oidc-client'
import OidcRoutes from '../../../core/router/OidcRoutes'
import {authenticator} from '../../../core/authenticator'
import {
    AuthenticationLoggerService
} from '../../../core/service/AuthenticationLoggerService'
import {useAuthenticationState} from '../hooks/useAuthenticationState'
import {AuthenticationContext} from '../context/AuthenticationContext'

interface AuthenticationProviderProps {
    children: React.ReactChild | React.ReactChild[] | null,
    configuration: UserManagerSettings,
    onUserLoaded?: (user: User) => void,
    onLogout?: () => void,
    onRedirect?: (path: string) => void,
    loadingComponent?: React.ReactElement | string,
    callbackComponentOverride?: React.ReactElement | string,
    postLogoutComponentOverride?: React.ReactElement | string,
    silentComponentOverride?: React.ReactElement | string,
    clientStore?: Storage,
    prefixStore?: string,
    loggerConfig?: {
        withDebug: true
        logger?: (error?: Error | unknown) => void
    }
}

const AuthenticationProvider = ({
                                    children = null,
                                    configuration,
                                    onUserLoaded: onUserLoadedCallback,
                                    onLogout: onLogoutCallback,
                                    onRedirect,
                                    loadingComponent = 'loading...',
                                    callbackComponentOverride = loadingComponent,
                                    postLogoutComponentOverride = loadingComponent,
                                    silentComponentOverride = loadingComponent,
                                    clientStore = sessionStorage,
                                    prefixStore = 'auth',
                                    loggerConfig
                                }: AuthenticationProviderProps) => {
    const logger = React.useMemo(() => new AuthenticationLoggerService(
            loggerConfig?.logger, loggerConfig?.withDebug
        ),
        [loggerConfig])

    const userManager = React.useMemo(() => (
        authenticator
            .init({
                ...configuration,
                userStore: new WebStorageStateStore(
                    {prefix: prefixStore, store: clientStore}
                )
            }, logger.log)
            .getUserManager()
    ), [configuration])

    const {
        onLoadUser,
        onError,
        onLoading,
        onLogout,
        onUnloadUser,
        oidcState
    } = useAuthenticationState(userManager)


    const loadUser = React.useCallback(async () => {
        try {
            onLoading()
            const user = await userManager?.getUser()

            onLoadUser(user)

        } catch (error) {
            logger.log(error)
            onError(error)
        }
    }, [userManager])

    const login = React.useCallback(async () => {
        try {
            await authenticator.login()
        } catch (error) {
            logger.log(error)
            onError(error)
        }
    }, [authenticator])

    const signinSilent = React.useCallback(async () => {
        try {
            await authenticator.signinSilent()
        } catch (error) {
            logger.log(error)
            onError(error)
        }
    }, [authenticator])

    const logout = React.useCallback(async () => {
        try {
            onLogout()
            if (typeof onLogoutCallback === 'function') onLogoutCallback()
            await authenticator.logout()
        } catch (error) {
            logger.log(error)
            onError(error)
        }
    }, [authenticator])


    const autoRenewSession = React.useCallback(() => {
        if (configuration.automaticSilentRenew) {
            signinSilent()
        }

    }, [signinSilent, configuration.automaticSilentRenew])


    const onUserLoaded = React.useCallback(async () => {
        const user = oidcState.oidcUser || await userManager?.getUser()
        if (typeof onUserLoadedCallback === 'function' && user) onUserLoadedCallback(user)
    }, [oidcState.oidcUser, onUserLoadedCallback, userManager])

    React.useEffect(() => {
        onUserLoaded()
    }, [onUserLoaded])

    React.useEffect(() => {
            loadUser()
            userManager?.events.addUserLoaded(onLoadUser)
            userManager?.events.addAccessTokenExpiring(autoRenewSession)
            userManager?.events.addAccessTokenExpired(logout)
            userManager?.events.addUserUnloaded(onUnloadUser)
            userManager?.events.addSilentRenewError(logger.log)

            return () => {
                userManager?.events.removeUserLoaded(onLoadUser)
                userManager?.events.removeAccessTokenExpiring(autoRenewSession)
                userManager?.events.removeAccessTokenExpired(logout)
                userManager?.events.removeUserUnloaded(onUnloadUser)
                userManager?.events.addSilentRenewError(logger.log)
            }
        },
        [])


    const renderChildren = React.useMemo(() => {
        if (oidcState.isLoading || oidcState.isLoggingOut) {
            return (<>{loadingComponent}</>)
        }

        return (
            <>{children}</>
        )
    }, [children, oidcState.isLoading, oidcState.isLoggingOut, loadingComponent])

    return (
        <AuthenticationContext.Provider
            value={{
                userManager,
                oidcUser: oidcState.oidcUser,
                logout,
                login,
                signinSilent,
                isLoading: oidcState.isLoading,
                isLoggingOut: oidcState.isLoggingOut,
                error: oidcState.error,
                events: userManager?.events
            }}
        >
            <OidcRoutes
                onRedirect={onRedirect}
                routes={{
                    callbackPath: configuration.redirect_uri || `${global.location.origin}/auth/callback`,
                    silentPath: configuration.silent_redirect_uri || `${global.location.origin}/auth/postlogout`,
                    postLogoutPath: configuration.post_logout_redirect_uri || `${global.location.origin}/auth/postlogout`
                }}
                callbackComponentOverride={callbackComponentOverride}
                postLogoutComponentOverride={postLogoutComponentOverride}
                silentComponentOverride={silentComponentOverride}
            >
                {renderChildren}
            </OidcRoutes>
        </AuthenticationContext.Provider>
    )
}

export default AuthenticationProvider
