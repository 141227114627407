import {GENDER, PRODUCTS} from '../../../../../../constants/constants';

const otherSteps = [
    { number: 1, name: 'portfolio.createNewPortfolio.productOffer' },
    { number: 2, name: 'portfolio.createNewPortfolio.personalDetails' },
    { number: 3, name: 'portfolio.createNewPortfolio.investorProfile' },
    { number: 4, name: 'portfolio.createNewPortfolio.strategy' },
    { number: 5, name: 'portfolio.createNewPortfolio.productConfiguration' },
    { number: 6, name: 'portfolio.createNewPortfolio.agentInformation' },
    { number: 7, name: 'portfolio.createNewPortfolio.overview' },
    { number: 8, name: 'portfolio.createNewPortfolio.qualityCheck' },
    { number: 9, name: 'portfolio.createNewPortfolio.opening' },
];

const steps3b = [
    { number: 1, name: 'portfolio.createNewPortfolio.productOffer' },
    { number: 2, name: 'portfolio.createNewPortfolio.advisoryDocument' },
    { number: 3, name: 'portfolio.createNewPortfolio.productSelection' },
    { number: 4, name: 'portfolio.createNewPortfolio.personalDetails' },
    { number: 5, name: 'portfolio.createNewPortfolio.investorProfile' },
    { number: 6, name: 'portfolio.createNewPortfolio.strategy' },
    { number: 7, name: 'portfolio.createNewPortfolio.productConfiguration' },
    // check use3bStepper() component that also handles 3b Steps when Withdrawal Option is selected
    { number: 8, name: 'portfolio.createNewPortfolio.agentInformation' },
    { number: 9, name: 'portfolio.createNewPortfolio.kyc' },
    { number: 10, name: 'portfolio.createNewPortfolio.overview' },
    //    { number: 12, name: 'portfolio.createNewPortfolio.qualityCheck' },
    { number: 11, name: 'portfolio.createNewPortfolio.opening' },
];

export const steps = {
    [PRODUCTS.bbb]: steps3b,
    [PRODUCTS.aaa]: otherSteps,
    [PRODUCTS.fz]: otherSteps,
    [PRODUCTS.fzp]: otherSteps,
};

export const FEE_TYPES = {
    asset: 1,
    custody: 0,
    entry: 2,
    issueCommission: 3,
};

export const UPPER_AGE_LIMIT_PER_PRODUCT = {
    [PRODUCTS.aaa]: {
        [GENDER.male]: 65,
        [GENDER.female]: 64,
    },
    [PRODUCTS.fz]: {
        [GENDER.male]: 70,
        [GENDER.female]: 69,
    },
    [PRODUCTS.fzp]: {
        [GENDER.male]: 70,
        [GENDER.female]: 69,
    },
};

export const DEFAULT_PROJECTION_YEARS = 5;
export const MAX_INVESTMENT_WITHOUT_PROOF_OF_ASSETS = 250000;
