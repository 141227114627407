/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, {
    useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { Checkbox, Loader, Textarea } from 'ui-library';
import { connectClientsSchema } from 'pages/Clients/provider/ClientsSchemaProvider';
import Card from 'components/Card/Card';
import Preloader from 'components/Preloader/Preloader';
import NewWrapper from 'components/AtomicStructure/layouts/NewWrapper';
import ButtonsBlockRow from 'components/ButtonsBlockRow/ButtonsBlockRow';
import { isEmpty } from 'lodash';
import './NewConnections.css';
import { getBase64FromFile } from 'utils/file';
import ServiceManager from 'services/ServiceManager';
import useFormatting from 'locale/useFormatting';
import { useConnections } from 'components/Connections/components';
import { downloadDmsDocument } from 'utils/downloadDmsDocument';
import { getClientId } from 'utils';
import {
    CONNECTIONS_SESSION_KEY,
    ContactGroupDocumentTypes,
    NewConnectionsSessionManagerAPI,
    NewConnectionType,
    SelectedConnection,
} from './constants';
import { DocItem } from '../../../Portfolios/pages/CreateNewPortfolio/pages/KYC/components/DocItem/DocItem';
import { useClientLayout } from '../../../../hooks/useClientLayout';
import { DocumentUpload } from '../../../Documents/components/DocumentUpload';

const UPLOAD_PA_DOCUMENT_OPTIONS = {
    personal: {
        type: 'personal',
        numberOfDocuments: 2,
        translation: 'clientDashboard.documents.idDocument',
        fullLabel: 'clientDashboard.documents.pa.idDocumentFull',
    },
    familyCertificate: {
        type: 'familyCertificate',
        numberOfDocuments: 1,
        documentType: ContactGroupDocumentTypes.form,
        translation: 'clientDashboard.documents.familyCertificate',
        fullLabel: 'clientDashboard.documents.familyCertificate',
        size: 2,
    },
};

const UPLOAD_POA_DOCUMENT_OPTIONS = {
    personal: {
        type: 'personal',
        numberOfDocuments: 2,
        translation: 'clientDashboard.documents.idDocument',
        fullLabel: 'clientDashboard.documents.poa.idDocumentFull',
    },
    powerOfAttorneyForm: {
        type: 'powerOfAttorneyForm',
        numberOfDocuments: 1,
        documentType: ContactGroupDocumentTypes.form,
        translation: 'clientDashboard.documents.powerOfAttorneyForm',
        fullLabel: 'clientDashboard.documents.powerOfAttorneyFormFull',
        size: 2,
    },
    powerOfAttorneyFormForPc: {
        type: 'powerOfAttorneyFormForPc',
        numberOfDocuments: 1,
        documentType: ContactGroupDocumentTypes.ccApproval,
        translation: 'clientDashboard.documents.powerOfAttorneyFormForPc',
        fullLabel: 'clientDashboard.documents.powerOfAttorneyFormForPcFull',
        size: 2,
    },
};

const NewConnectionsRelationship = () => {
    const {
        dfsClientId,
    } = useParams();
    const clientId = getClientId(dfsClientId);
    const { getFormattedDate } = useFormatting();

    useClientLayout();
    const { t } = useTranslation();
    const connectionType = useRef<NewConnectionType | null>();
    const { downloadDocument } = useConnections();

    const navigate = useNavigate();
    const location = useLocation();

    const [files, setFiles] = useState<Array<File | any>>([]);
    const [errorPage, setErrorPage] = useState('');
    const [relationship, setRelationship] = useState<string>('');
    const [uploadedDocuments, setUploadedDocuments] = useState<Array<any>>([]);
    const [documentLoading, setDocumentsLoading] = useState(false);
    const [includeCCDoc, setIncludeCCDoc] = useState(false);
    const [clients, setClients] = useState<SelectedConnection[]>();
    const NUMBER_OF_FILES = includeCCDoc ? 3 : 2;

    const isPoa = useMemo(() => connectionType.current === 'poa', [connectionType.current]);
    const connectionClientId = clients?.[0]?.id;

    const goToAction = async () => {
        const preservedState = JSON.parse(global.sessionStorage.getItem(CONNECTIONS_SESSION_KEY) || '{}') as NewConnectionsSessionManagerAPI;

        const documents = await Promise.all(files.map(async (f) => {
            let dataUrl;

            if (!f.file) {
                dataUrl = await getBase64FromFile(f);
            }

            return {
                filename: f.file ? f.filename : f.name,
                file: f.file ? f.file : dataUrl.split(',')[1],
                // @ts-ignore
                documentType: f.documentType,
                type: documentTypes[f.documentType].documentType,
            };
        }));

        global.sessionStorage.setItem(CONNECTIONS_SESSION_KEY, JSON.stringify({
            ...preservedState,
            includeCCDoc,
            relationship,
            documents,
        }));

        navigate(location.pathname.replace('/relationship', '/summary'));
    };

    useEffect(() => {
        if (files?.length) {
            setUploadedDocuments(prev => {
                const newItems: any = [];

                files.forEach((f) => {
                    const selectedIndex = prev.findIndex((ix) => ix.type === f.documentType);

                    const newItem = {
                        name: t(`clientDashboard.documents.${f.documentType}`),
                        createDate: new Date(),
                        type: f.documentType,
                    };

                    if (selectedIndex > -1) {
                        prev[selectedIndex] = newItem;
                    } else {
                        newItems.push(newItem);
                    }
                });

                return [...prev, ...newItems];
            });
        }
    }, [files]);

    useEffect(() => {
        const newConnection: string | NewConnectionsSessionManagerAPI | null = global.sessionStorage.getItem(CONNECTIONS_SESSION_KEY);

        if (newConnection) {
            const newConnectionParsed = JSON.parse(newConnection) as NewConnectionsSessionManagerAPI;

            connectionType.current = newConnectionParsed.connectionType;

            setClients(newConnectionParsed.clients);
            if (newConnectionParsed.documents?.length) {
                // @ts-ignore
                setIncludeCCDoc(newConnectionParsed.includeCCDoc);
                setFiles(newConnectionParsed.documents);
                setUploadedDocuments(prev => [...prev]);

                if (newConnectionParsed.relationship) {
                    setRelationship(newConnectionParsed.relationship);
                }
            }
        }
    }, []);

    const getIdDocument = useCallback(async () => {
        if (!connectionClientId || !clientId) return;
        try {
            setDocumentsLoading(true);

            const { data } = await ServiceManager.customDocumentsService('getDocumentsByContactId', [{ contactId: connectionClientId }]);

            setUploadedDocuments(prev => {
                const selectedIndex = prev.findIndex(f => f.name === data.name);

                if (selectedIndex > -1) {
                    prev[selectedIndex] = data;

                    return prev;
                }
                if (data) {
                    return [...prev, data];
                }

                return prev;
            });
        } finally {
            setDocumentsLoading(false);
        }
    }, [connectionClientId, clientId]);

    useEffect(() => {
        (async () => getIdDocument())();
    }, [getIdDocument]);

    const handleDocumentDownload = useCallback(async (docId: number | string, item: any) => {
        try {
            if (docId) {
                await downloadDocument(Number(docId));

                return;
            }

            const tempFile = files.filter(i => i.documentType === item.type);

            // @ts-ignore
            if (tempFile[0].file) {
                // @ts-ignore
                downloadDmsDocument(tempFile[0], { saveFile: true, name: tempFile[0].filename });
            } else {
                // @ts-ignore
                global.open(URL.createObjectURL(tempFile[0]), '_blank');
            }
        } catch (err) {
            console.log(err);
        }
    }, [downloadDocument, files]);

    const handleDocumentUpload = useCallback((selectedFiles, onSuccess, documentType) => {
        const docs = selectedFiles.map((i) => {
            i.documentType = documentType;

            return i;
        });

        setFiles((prev) => {
            const filteredDocs = prev.filter(f => f.documentType !== documentType);

            if (!isEmpty(filteredDocs)) {
                return [...filteredDocs, ...docs];
            }

            return docs;
        });
        onSuccess();
    }, []);

    const documentTypes = useMemo(() => {
        const uploadDocsConfig = { ...(isPoa ? UPLOAD_POA_DOCUMENT_OPTIONS : UPLOAD_PA_DOCUMENT_OPTIONS) };

        if (!includeCCDoc && isPoa) {
            // @ts-ignore
            delete uploadDocsConfig?.powerOfAttorneyFormForPc;
        }

        return uploadDocsConfig;
    }, [t, includeCCDoc, isPoa]);

    return (
        <div className="container PoaConnections">
            <div className="fi-verticals">
                <Card
                    title={{
                        ja: t('connections.createNewJa.title'),
                        poa: t('connections.createNewPoa.title'),
                        representative: t('connections.createNewPa.title'),
                    }[connectionType.current || 'ja']}
                >
                    <NewWrapper
                        HeaderNavbarTurnOff
                        stepNavBarActive={1}
                        dataKey="newConnections"
                    >
                        <div className="poa_content">

                            {isPoa && (
                                <fieldset className="poa_client">
                                    <b >
                                        {t('connections.createNew.portalAccessPoa')}
                                    </b>

                                    <Checkbox
                                        // @ts-ignore
                                        label={t('connections.createNew.agreementPortal')}
                                        checked={includeCCDoc}
                                        onChange={(e) => setIncludeCCDoc(e.target.checked)}
                                    />

                                </fieldset>
                            )}
                            <fieldset className="poa_client">
                                <b
                                    className="inputLabel"
                                >
                                    {t('connections.createNew.requiredDocsList')}
                                </b>

                                <ul>
                                    {Object.keys(documentTypes)?.map(key => <li key={key}>{t(documentTypes[key].fullLabel)}</li>)}
                                </ul>
                            </fieldset>

                            <div className="poa_upload">
                                <div className="title-bar">
                                    <b>
                                        {t('connections.createNew.uploadDocLabel')}
                                    </b>
                                    {/* @ts-ignore */}
                                    <Preloader isLoading={!connectionClientId} >
                                        <DocumentUpload
                                            contactId={clientId!}
                                            connectionClientId={connectionClientId}
                                            onUpload={getIdDocument}
                                            handleUploadNonIdDocument={handleDocumentUpload}
                                            documentOptions={documentTypes}
                                        />
                                    </Preloader>
                                </div>
                                <div className="docs">
                                    {documentLoading ? <Loader /> : uploadedDocuments?.map(i => <DocItem key={i.id} id={i.id} name={i.name} date={getFormattedDate(i.createDate)} onDownload={(id) => handleDocumentDownload(id, i)} />)}
                                </div>
                            </div>
                            <div className="poa_inner">
                                <div className="poa_relationship">
                                    <Textarea
                                        /* @ts-ignore */
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            setRelationship(e.target.value);
                                        }}
                                        value={relationship}
                                        label={<b>{t('connections.createNew.describeRelationship')}</b>}
                                    />
                                </div>
                            </div>
                            <Preloader isLoading={false}>
                                <div className="container">
                                    <ButtonsBlockRow
                                        additionalButton={{
                                            text: t('onboardingFlow.documetsPage.back'),
                                            onClick: () => navigate(-1),
                                        }}
                                        primaryButton={{
                                            text: t('onboardingFlow.clientsDataPage.continue'),
                                            onClick: () => goToAction(),
                                            disabled: uploadedDocuments.length < NUMBER_OF_FILES,
                                        }}
                                    />
                                </div>
                            </Preloader>
                        </div>
                    </NewWrapper>
                </Card>
            </div>
        </div>
    );
};

export default connectClientsSchema(NewConnectionsRelationship);
