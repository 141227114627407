import i18n from 'i18next';
import {get} from 'lodash/fp';
import accountsService from 'additiv-services/services/core/accounts';
import contactManagement from 'additiv-services/services/core/contactManagement';
import instrumentsService from 'additiv-services/services/core/instruments';
import PortfolioManagement from 'additiv-services/services/core/portfolioManagement';
import performanceService from 'additiv-services/services/core/performance';
import Products from 'additiv-services/services/core/products';
import Security, {security} from 'additiv-services/services/core/security';
import commonService from 'additiv-services/services/core/common';
import commonContent from 'additiv-services/services/core/commonContent';
import currenciesService from 'additiv-services/services/core/currencies';
import tradingService from 'additiv-services/services/core/trading';
import complianceService from 'additiv-services/services/core/compliance';
import dataService from 'additiv-services/services/core/data';
import relationshipManagement from 'additiv-services/services/core/relationshipManagement';
import deviceEnrollment from 'additiv-services/services/core/deviceEnrollment';
import messageService from 'additiv-services/services/core/messages';
import goalsService from 'additiv-services/services/core/goals';
import optimizationService from 'additiv-services/services/core/optimization';
import reports from 'additiv-services/services/core/reports';
import contactInteraction from 'additiv-services/services/core/contactInteraction';
import rss from 'additiv-services/services/core/rss';
import documents from 'additiv-services/services/core/documents';
import memberManagement from 'additiv-services/services/core/memberManagement';
import clientService from 'additiv-services/services/core/clientService';
import Service from 'additiv-services/services/core/service';
import engagementSchema from 'additiv-services/services/core/engagementSchema';
import engagementContent from 'additiv-services/services/core/engagementContent';
import fIdentity from 'additiv-services/services/core/fIdentity';
import fundingManagement from 'additiv-services/services/core/fundingManagement';
import middleware from 'additiv-services/services/core/middleware';
import Definitions from 'additiv-services/services/core/definitions';
import {setup} from 'additiv-services';
import bank from './services/bank';
import member from './services/member';
import {PortfolioManagement as PortfolioManagementLocal} from './services/portfolio-management';
import {ContactIdentityServices} from './services/contactIdentityServices';
import customService from './custom/CustomService';
import CaseManagementService from './custom/CaseManagementService';
import documentService from './documents/DocumentService';
import {checkStatus} from './generalStatusCheck';
import CustomClientServices from './custom/CustomClientServices';
import customDocumentService from './custom/CustomDocumentService';
import PlanService from './custom/PlanService';
import CustomMembersService from './custom/CustomMembersService';
import CustomReportsService from './custom/CustomReports';
import CustomInvestmentService from './custom/CustomInvestmentService';
import ContactActivation from './custom/ContactActivation';
import CustomCommonService from './custom/CustomCommonService';
import CustomAdvisoryServices from './custom/CustomAdvisoryServices';

const serviceCall = (service, method, argsArray = []) => {
    const result = service[method](...argsArray);
    const promise = result instanceof Promise ? result : result[0];

    promise
        .then(() => {})
        .catch((reason) => {
            checkStatus(get('response.status', reason));
        });

    return result;
};

class ServiceManager {
    static Service(method, argsArray) {
        return serviceCall(Service, method, argsArray);
    }

    static tradingService(method, argsArray) {
        return serviceCall(tradingService, method, argsArray);
    }

    static compliance(method, argsArray) {
        return serviceCall(complianceService, method, argsArray);
    }

    static contactManagement(method, argsArray) {
        return serviceCall(contactManagement, method, argsArray);
    }

    static dataService(method, argsArray) {
        return serviceCall(dataService, method, argsArray);
    }

    static goalsService(method, argsArray) {
        return serviceCall(goalsService, method, argsArray);
    }

    static optimizationService(method, argsArray) {
        return serviceCall(optimizationService, method, argsArray);
    }

    static performanceService(method, argsArray) {
        return serviceCall(performanceService, method, argsArray);
    }

    static relationshipManagement(method, argsArray) {
        return serviceCall(relationshipManagement, method, argsArray);
    }

    static instrumentsService(method, argsArray) {
        return serviceCall(instrumentsService, method, argsArray);
    }

    static portfolioManagement(method, argsArray) {
        return serviceCall(PortfolioManagement, method, argsArray);
    }

    static portfolioManagementLocal(method, argsArray) {
        return serviceCall(PortfolioManagementLocal, method, argsArray);
    }

    static contactIdentityServices(method, argsArray) {
        return serviceCall(ContactIdentityServices, method, argsArray);
    }

    static products(method, argsArray) {
        return serviceCall(Products, method, argsArray);
    }

    static security(method, argsArray) {
        return serviceCall(security, method, argsArray);
    }

    static Security(method, argsArray) {
        return serviceCall(Security, method, argsArray);
    }

    static commonService(method, argsArray) {
        return serviceCall(commonService, method, argsArray);
    }

    static currenciesService(method, argsArray) {
        return serviceCall(currenciesService, method, argsArray);
    }

    static commonContent(method, argsArray) {
        return serviceCall(commonContent, method, argsArray);
    }

    static messageService(method, argsArray) {
        return serviceCall(messageService, method, argsArray);
    }

    static deviceEnrollment(method, argsArray) {
        return serviceCall(deviceEnrollment, method, argsArray);
    }

    static accountsService(method, argsArray) {
        return serviceCall(accountsService, method, argsArray);
    }

    static reportsService(method, argsArray) {
        return serviceCall(reports, method, argsArray);
    }

    static contactInteraction(method, argsArray) {
        if (method === 'postInteractions') {
            const params = argsArray[0] || {};

            params.FallbackLanguageId = params.FallbackLanguageId || 2;
        }
        if (method === 'patchInteractions') {
            const params = argsArray[1] || {};

            params.FallbackLanguageId = params.FallbackLanguageId || 2;
        }

        return serviceCall(contactInteraction, method, argsArray);
    }

    static bank(method, argsArray) {
        return serviceCall(bank, method, argsArray);
    }

    static member(method, argsArray) {
        return serviceCall(member, method, argsArray);
    }

    static memberManagement(method, argsArray) {
        return serviceCall(memberManagement, method, argsArray);
    }

    static rssService(method, argsArray) {
        return serviceCall(rss, method, argsArray);
    }

    static documents(method, argsArray) {
        return serviceCall(documents, method, argsArray);
    }

    static clientService(method, argsArray) {
        setup({
            CLIENT_SERVICE_HEADERS: { 'accept-language': i18n.language },
        });

        return serviceCall(clientService, method, argsArray);
    }

    static engagementSchema(method, argsArray) {
        return serviceCall(engagementSchema, method, argsArray);
    }

    static engagementContent(method, argsArray) {
        return serviceCall(engagementContent, method, argsArray);
    }

    static fIdentity(method, argsArray) {
        return serviceCall(fIdentity, method, argsArray, false);
    }

    static middleware(method, argsArray) {
        return serviceCall(middleware, method, argsArray);
    }

    static fundingManagement(method, argsArray) {
        return serviceCall(fundingManagement, method, argsArray);
    }

    static definitions(method, argsArray) {
        return serviceCall(Definitions, method, argsArray);
    }

    static customService(method, argsArray) {
        return serviceCall(customService, method, argsArray);
    }

    static customDocumentsService(method, argsArray) {
        return serviceCall(customDocumentService, method, argsArray);
    }

    static documentService(method, argsArray) {
        return serviceCall(documentService, method, argsArray);
    }

    static caseManagementService(method, argsArray) {
        return serviceCall(CaseManagementService, method, argsArray);
    }

    static customClientService(method, argsArray) {
        return serviceCall(CustomClientServices, method, argsArray);
    }

    static customMembersService(method, argsArray) {
        return serviceCall(CustomMembersService, method, argsArray);
    }

    static planService(method, argsArray) {
        return serviceCall(PlanService, method, argsArray);
    }

    static customReportsService(method, argsArray) {
        return serviceCall(CustomReportsService, method, argsArray);
    }

    static customInvestmentService(method, argsArray) {
        return serviceCall(CustomInvestmentService, method, argsArray);
    }

    static customCommonService(method, argsArray) {
        return serviceCall(CustomCommonService, method, argsArray);
    }

    static contactActivation(method, argsArray) {
        return serviceCall(ContactActivation, method, argsArray);
    }

    static customAdvisoryServices(method, argsArray) {
        return serviceCall(CustomAdvisoryServices, method, argsArray);
    }
}

export default ServiceManager;
