import React from 'react';
import {useTranslation} from 'react-i18next';

import Preloader from 'components/Preloader';
import {TYPE_CURRENCY, TYPE_NUMBER} from 'utils/formatting';
import {OverviewPanel, OverviewItem} from 'components/Overview/index';
import Card from '../../../components/Card/Card';
import {useManagerProfile} from '../../../prodivers/managerProfile';

function Overview() {
    const {t} = useTranslation();

    const {
        isLoading, totalsError, dashboardInfo,
    } = useManagerProfile();

    return (
        <Card>
            <OverviewPanel>
                <Preloader isLoading={isLoading} error={totalsError}>
                    <OverviewItem
                        title={t('advisoryDashboard.overview.totalAUM')}
                        type={TYPE_CURRENCY}
                        value={dashboardInfo?.totalAssets}
                        currency="CHF"
                        icon="total-aum"
                    />
                    <OverviewItem
                        title={t('advisoryDashboard.overview.depotsNumber')}
                        type={TYPE_NUMBER}
                        value={dashboardInfo?.numberOfDeposits}
                        icon="clients"
                    />
                    {/* <OverviewItem
                        title={t('advisoryDashboard.overview.performance')}
                        type={TYPE_PERCENT}
                        value={total.percent}
                        icon={total.percent < 0 ? 'arrow-down' : 'arrow-up'}
                        iconColored
                    /> */}
                    <OverviewItem
                        title={t('advisoryDashboard.overview.clients')}
                        itemLink="/clients"
                        type={TYPE_NUMBER}
                        value={dashboardInfo?.numberOfCustomers}
                        icon="clients"
                    />
                    {/* <OverviewItem
                        title={t('advisoryDashboard.overview.prospects')}
                        itemLink={`/clients?status=${t('advisoryDashboard.overview.prospects')}`}
                        type={TYPE_NUMBER}
                        value={prospects.value}
                        icon="prospects"
                    /> */}
                </Preloader>
            </OverviewPanel>
        </Card>
    );
}

Overview.propTypes = {
};

Overview.defaultProps = {
};

export default React.memo(Overview);
