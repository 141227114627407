import React, {useEffect} from 'react';
import {authenticator} from '../authenticator';

type SilentContainerProps = {
    children?: React.ReactChild | React.ReactChild[] | null;
}
const SilentContainer = ({children}: SilentContainerProps) => {
    const userManager = authenticator.getUserManager();

    useEffect(() => {
        userManager?.signinSilentCallback();
    }, []);

    return (
        <>
            {children}
        </>
    );
};

export default SilentContainer;
