import { getCustomUrl } from '../../setup';
import Service from './service';

class Reports extends Service {
    static reportInvestmentContract(contactId, data) {
        const axiosSetup = { responseType: 'blob' };

        return this.postRequest(
            `${getCustomUrl()}/reports/${contactId}/investment-contract`, data, { isSecure: true, axiosSetup }
        );
    }

    static reportAvisoryProtocol(contactId, data) {
        const axiosSetup = { responseType: 'blob' };

        return this.postRequest(
            `${getCustomUrl()}/reports/${contactId}/advisory-protocol`, data, { isSecure: true, axiosSetup }
        );
    }

    static reportInvestmentProposal(contactId, portfolioId, portfolioProposalId, language) {
        const axiosSetup = { responseType: 'blob' };

        return this.getRequest(
            `${getCustomUrl()}/reports/${contactId}/portfolio/${portfolioId}/investment-proposal/${portfolioProposalId}/${language}`,
            { isSecure: true, axiosSetup }
        );
    }
}

export default Reports;
