import { useCallback, useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import SM from 'services/ServiceManager';
import ServerError from 'errors/ServerError';
import HandlerError from 'errors/HandlerError';
import handlerRequestCanceling from 'utils/handlerRequestCanceling';

const initialState = {
    data: {},
    dataRaw: {},
    error: null,
    isLoading: false,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'setData':
            return { ...state, data: action.payload };
        case 'setDataRaw':
            return { ...state, dataRaw: action.payload };
        case 'setError':
            return { ...state, error: action.payload };
        case 'setIsLoading':
            return { ...state, isLoading: action.payload };
        default:
            return state;
    }
};

export const useGeneralAgency = (options = {}) => {
    const {
        portfolioId: portfolioIdOption,
        loadInitially = false,
    } = options;
    const [state, dispatch] = useReducer(reducer, initialState);

    const { t } = useTranslation();

    // Callbacks
    const getAgentAndGeneralAgencyData = useCallback(async (portfolioId = portfolioIdOption) => {
        try {
            dispatch({ type: 'setIsLoading', payload: true });
            dispatch({ type: 'setError', payload: null });

            if (!portfolioId) {
                return null;
            }

            const {data} = await SM.planService('getAgentAndAgencyInfo', [{portfolioId}]);

            dispatch({ type: 'setData', payload: data });

            return data;
        } catch (err) {
            handlerRequestCanceling(
                HandlerError({
                    setError: (val) => dispatch({ type: 'setError', payload: val }),
                    setLoading: (val) => dispatch({ type: 'setIsLoading', payload: val }),
                }),
            )(err);

            return err.type !== undefined ? err : new ServerError(err);
        } finally {
            dispatch({ type: 'setIsLoading', payload: false });
        }
    }, [t]);

    // Effects
    useEffect(() => {
        dispatch({ type: 'setIsLoading', payload: loadInitially });
    }, [loadInitially]);

    useEffect(() => {
        if (loadInitially) getAgentAndGeneralAgencyData();
    }, [getAgentAndGeneralAgencyData, loadInitially]);

    return {
        data: state.data,
        dataRaw: state.dataRaw,
        error: state.error,
        isLoading: state.isLoading,
        getAgentAndGeneralAgencyData,
    };
};
