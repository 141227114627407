import {strategyTypes} from '../../../../../constants/constants';

export const adaptModelType = (modelPortfolio) => JSON.parse(modelPortfolio?.Attributes ?? '{}')?.Type ?? strategyTypes.STANDARD;

export const adaptModels = models => models.reduce((acc, model) => {
    if (!model.IsActive) {
        return acc;
    }

    return [
        ...acc,
        {
            ...model,
            RiskScore: model.RiskCategory.Max,
            Type: adaptModelType(model),
        },
    ];
}, []);

export const adaptStrategies = models => models.reduce((acc, model) => {
    if (!model.IsActive) {
        return acc;
    }

    const index = acc.findIndex(strategy => strategy.Id === model.RiskCategory.Id);

    if (index === -1) {
        return [
            ...acc,
            {
                ...model.RiskCategory,
                Products: [model.Id],
                Type: adaptModelType(model),
            },
        ];
    }

    acc[index].Products.push(model.Id);

    return acc;
}, []).sort((a, b) => a.Max - b.Max);
