import PropTypes from 'prop-types';
import React from 'react';
import {Infobox} from 'ui-library';
import './WidgetError.css';

const WidgetError = ({
    message,
    ...props
}) => (
    <Infobox {...props}>
        <div>
            {typeof message === 'string' ? message
                : message.map((item) => (
                    item && (
                        <div>
                            {item}
                        </div>
                    )
                ))
            }
        </div>
    </Infobox>
);

WidgetError.propTypes = {
    message: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

WidgetError.defaultProps = {
    message: '',
};

export default WidgetError;
