import {
    useCallback, useReducer,
} from 'react';

const initialState = {
    clientName: '', clientAge: undefined, risk: '',
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'setData':
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

/**
 * @data: { clientName, clientAge, }
 * @returns {{data: {}, setData: (value: (((prevState: {}) => {}) | {})) => void, updateData: ((function(*): void)|*)}}
 */
export const useRiskProfile = () => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const setData = useCallback((lData) => {
        dispatch({ type: 'setData', payload: lData });
    }, []);

    return {
        data: state,
        setData,
    };
};
