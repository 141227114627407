import React from 'react';
import { PageVariant } from 'ui-library';
import PageVariantWrapper from 'components/PageVariantWrapper';
import { useTranslation } from 'react-i18next';
import {useNavigate, useParams} from 'react-router-dom';

export const Success = () => {
    const { dfsClientId } = useParams();
    const navigate = useNavigate();
    const {t} = useTranslation();

    return (
        <PageVariantWrapper>
            <PageVariant
                title={t('caseManagement.modal.successTitle')}
                type="success"
                message={t('caseManagement.modal.successMessage')}
                actionButtonLabel={t('caseManagement.modal.submitBtn.label')}
                onActionButtonClick={() => {
                    navigate(`/client/${dfsClientId}/dashboard`);
                }}
            />
        </PageVariantWrapper>
    );
};
