import { useContext } from 'react';

import {CommonAllocationContext} from './CommonAllocationDataProvider';

const useCommonAllocationDataProvider = () => {
    const context = useContext(CommonAllocationContext);

    if (context === undefined) {
        throw new Error('useCommonAllocationDataProvider can only be used inside CommonAllocationContext');
    }

    return context;
};

export default useCommonAllocationDataProvider;
