import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {applyThousandSeparator} from 'ui-library/components/NumberInput/utils';
import './InfoBox.css';
import {InfoBoxProps} from './InfoBox.types';

const InfoBox: FC<InfoBoxProps> = ({
    title,
    value
}: { title: string; value: number }) => {
    const {t} = useTranslation();

    return (
        <div className="property-value-info">
            {title}
            {value !== null ? (
                <span>
                    {(value as number < 0) && '- '}
                    CHF
                    {' '}
                    {applyThousandSeparator(`${Math.abs(value as number)
                        .toFixed(2)}`, '’')}
                </span>
            ) : (
                <span>
                    {t('PensionSituationForm.info.notSpecified')}
                </span>
            )}
        </div>
    );
};

export default InfoBox;
