import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import QuestionPage from './pages/NewsPlan/QuestionPage/QuestionPage';
import ConfirmationPage from './pages/NewsPlan/ConfirmationPage/ConfirmationPage';
import UploadDocumentsPage from './pages/NewsPlan/UploadDocumentsPage/UploadDocumentsPage';
import NewPersonalInformationPage from './pages/NewsPlan/NewPersonalInformationPage/NewPersonalInformationPage';

const Router = () => (
    <Routes>
        <Route exact path="" element={<NewPersonalInformationPage/>} />

        <Route exact path="newPersonalInformation" element={<NewPersonalInformationPage/>} />
        <Route exact path="newPersonalInformation/:getParametrs" element={<NewPersonalInformationPage/>} />
        <Route exact path="uploadDocuments" element={<UploadDocumentsPage/>} />
        <Route exact path="question" element={<QuestionPage/>} />
        <Route exact path="confirm" element={<ConfirmationPage/>} />

        <Route
            path="*"
            element={<Navigate to="/404" replace />}
        />
    </Routes>
);

Router.displayName = 'Router';

export default Router;
