import { DefaultError } from '@tanstack/react-query';
import SM from 'services/ServiceManager';
import { useErrorWrappedAPIMutation } from 'hooks/mutations/useErrorWrappedAPIMutation';

export const useDocumentSentFlag = () => useErrorWrappedAPIMutation<any, DefaultError, {
        documentId: number,
    }>({
        mutationFn: async (payload) => {
            const { data } = await SM.customDocumentsService(
                'postDocumentSentFlag',
                [payload],
            );

            return data;
        },
    });
