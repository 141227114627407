import React from 'react';
import PropTypes from 'prop-types';
import uid from '../../../../utils/uid';

const ImplicitFileInput = ({
    onChange,
    beforeUpload,
    accept,
    files,
    multiple,
    ...props
}) => {
    const handleChange = ({ file, fileList }) => {
        onChange({ file, fileList: [...files, ...fileList] });
    };
    const upload = (file, fileList) => {
        if (!beforeUpload) {
            // always async in case use react state to keep fileList
            setTimeout(() => onChange({ file, fileList }), 0);


            return;
        }

        const before = beforeUpload(file, fileList);

        if (before && before.then) {
            before.then((processedFile) => {
                const processedFileType = Object.prototype.toString.call(processedFile);

                if (processedFileType === '[object File]' || processedFileType === '[object Blob]') {
                    onChange({ file, fileList });


                    return;
                }
                handleChange({ file, fileList });
            }).catch((e) => {
                // eslint-disable-next-line no-console
                console.log(e);
            });
        } else if (before !== false) {
            setTimeout(
                () => handleChange({ file, fileList }),
                0,
            );
        } else {
            handleChange({ file, fileList });
        }
    };
    const uploadFiles = (e) => {
        const { files } = e.target;
        const fileList = Array.prototype.slice.call(files);

        fileList
            .map((file) => {
                // eslint-disable-next-line no-param-reassign
                file.uid = uid();


                return file;
            })
            .forEach((file) => {
                upload(file, fileList);
            });
    };
    const handleClick = (e) => {
        // FIX: allow the same file to be selected after it was removed from input
        e.target.value = null;
    };

    return (
        <input
            {...props}
            onChange={uploadFiles}
            onClick={handleClick}
            multiple={multiple}
            type="file"
            accept={accept}
        />
    );
};

ImplicitFileInput.propTypes = {
    onChange: PropTypes.func,
    beforeUpload: PropTypes.func,
    accept: PropTypes.string,
    files: PropTypes.arrayOf(PropTypes.shape({
        uid: PropTypes.string,
        name: PropTypes.string,
    })),
    multiple: PropTypes.bool,
};
ImplicitFileInput.defaultProps = {
    onChange: () => {
    },
    beforeUpload: () => {
    },
    accept: '*',
    files: [],
    multiple: false,
};
export default ImplicitFileInput;
