import {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';
import {isEmpty} from 'lodash';
import {useTranslation} from 'react-i18next';
import {useAdvisoryDocumentBase} from './useAdvisoryDocumentBase';
import {useCreatePortfolio} from '../../../context';

export const useAdvisoryDocument = () => {
    const {
        isLoading,
        document,
        getDocument,
        setDocument,
    } = useAdvisoryDocumentBase();
    const {t} = useTranslation();
    const navigate = useNavigate();
    const { dfsClientId } = useParams();
    const [searchParams] = useSearchParams();

    const [error, setError] = useState(null);

    const baseUrl = useMemo(() => `/client/${dfsClientId}/portfolios/new-portfolio`, [dfsClientId]);

    const {
        saveNewPortfolio, newPortfolio, layout: { setData: setLayoutData },
    } = useCreatePortfolio();

    const onContinue = useCallback(async () => {
        if (isEmpty(document)) {
            setError(t('advisoryDocument.step.noDocumentError'));

            return;
        }

        // if returnUrl is present, this means, user has been redirected here from an ongoing app,
        // therefor they should be returned to from where they came.
        const returnUrl = searchParams.get('returnUrl');

        await saveNewPortfolio({
            data: {
                data: {advisoryDocumentId: document.advisoryDocumentId},
                investmentApplicationId: newPortfolio.applicationData?.investmentApplicationId,
            },
            method: returnUrl ? 'saveAdvisoryDocumentCompletedFlag' : null, // no returnUrl also means no investmentApplicationId which is need to save advisoryCompleted flag
            additionalData: {advisoryDocument: document},
        });

        navigate(returnUrl || `${baseUrl}/product-selection`);
    }, [document, searchParams, newPortfolio]);

    const onBack = useCallback(() => {
        navigate(`${baseUrl}/advisory-document-init`);
    }, [baseUrl]);

    useEffect(() => {
        setLayoutData({
            stepNavBarActive: 2,
            onBack,
            onContinue,
            disabled: isLoading,
        });
    }, [onBack, onContinue, isLoading]);

    return {
        error, getDocument, setDocument, setError, isLoading, document,
    };
};
