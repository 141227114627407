import { useCreatePortfolioSelectors } from 'datasource/CreatePortfolio';
import React from 'react';
import { useParams } from 'react-router-dom';
import { getClientId } from 'utils';
import { QualityCheckProvider } from './Provider';
import QualityCheck from './QualityCheck';

import './QualityCheck.css';

export function QualityCheckWithProvider() {
    const { dfsClientId } = useParams();
    const clientId = getClientId(dfsClientId);
    const {
        is3bProductGroupSelector,
        firstNotPKNewProductSelector,
        selectedProductIdSelector,
    } = useCreatePortfolioSelectors();

    return (
        <QualityCheckProvider options={{
            clientId,
            productId: is3bProductGroupSelector ? firstNotPKNewProductSelector.id : selectedProductIdSelector,
        }}
        >
            <QualityCheck />
        </QualityCheckProvider>
    );
}
