export const defaultProjectionChartOptions = {
    chart: {
        type: 'area',
        marginTop: 30,
        styledMode: true,
    },
    title: {
        text: undefined,
    },
    subtitle: {
        text: undefined,
    },
    legend: {
        enabled: false,
        useHTML: true,
    },
    credits: {
        enabled: false,
    },
    tooltip: {
        useHTML: true,
        distance: 0,
    },
    xAxis: {
        crosshair: {
            width: 1,
            color: '#444',
            dashStyle: 'longdash',
            snap: false,
            zIndex: 3,
        },
        labels: {
            y: 40,
        },
    },
    yAxis: {
        title: {
            text: '',
            margin: 0,
            rotation: 0,
            align: 'high',
            textAlign: 'high',
            y: -20,
        },
        gridLineDashStyle: 'dash',
    },
    plotOptions: {
        area: {
            marker: {
                enabled: false,
                symbol: 'circle',
                radius: 4,
                states: {
                    hover: {
                        enabled: true,
                    },
                },
            },
        },
        scatter: {
            cursor: 'pointer',
            marker: {
                symbol: 'circle',
                radius: 9,
                states: {
                    hover: {
                        radius: 11,
                    },
                },
            },
        },
    },
    series: [],
};
