import React from 'react';
import { Button, Icon, Title } from 'ui-library';
import { ICON_TYPE_EDIT } from 'ui-library/components/Icon';

function AssetFee({
    setShowAssetModal, defaultAssetFee, assetFee, t, disableEdit = false,
}) {
    return (
        <>
            <div className="FeeOverview__CustodyWrapper">
                <p>{t('assetFee.labels.asset')}</p>
            </div>
            <div className="FeeOverview__CustodyWrapper">
                <b>
                    {assetFee ?? defaultAssetFee}
                    %

                </b>
            </div>
            <div className="FeeOverview__CustodyWrapper">
                {!disableEdit && (
                    <Button type="link" onClick={() => setShowAssetModal(true)} className="action-button">
                        <Icon type={ICON_TYPE_EDIT}/>
                        {t('interaction.edit')}
                    </Button>
                )}
            </div>
        </>
    );
}

export default AssetFee;
