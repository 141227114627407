import React from 'react';
import NavbarTab from '../moleculs/NavbarTab';
import FooterNavbar from './FooterNavbar';
import FooterNavigation from './FooterNavigation';
import HeaderNavbar from './HeaderNavbar';

const NewWrapper = (props) => (
    <>
        <style
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
                __html: `
                    .Header {
                        display: none;
                    }
                    `,
            }}
        />

        {props.HeaderNavbarTurnOff ? null : <HeaderNavbar headTitle={props.headTitle}/>}
        {props.NavbarTurnOff ? null : <NavbarTab stepNavBarActive={props.stepNavBarActive} dataKey={props.dataKey}/>}

        {props.children}

        {props.FooterNavigationTurnOn && <FooterNavigation/>}

        {props.footer ? (
                <FooterNavbar>
                    {props.footer}
                </FooterNavbar>
            )
            : null}
    </>
);

export default NewWrapper;
