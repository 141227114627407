import React from 'react';
import PropTypes from 'prop-types';
import './MenuItem.css';
import withTheme from '../../../hocs/withTheme';

function MenuItem({
    children, ...rest
}) {
    return (
        <li
            {...rest}
            className="menu-item"
            role="menuitem"
        >
            {children}
        </li>
    );
}

MenuItem.propTypes = {
    /** @ignore */
    children: PropTypes.node,
};

MenuItem.defaultProps = {
    children: null,
};

export default withTheme(MenuItem);
