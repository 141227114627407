import {useCallback, useEffect} from 'react';
import {useLayout} from 'prodivers/layout';
import {useTranslation} from 'react-i18next';
import LogoWithClientName from 'components/LogoWithClientName';
import {useClientProfile} from 'prodivers/clientProfile';
import {usePortfolio} from 'domain/Portfolio';

import {genarateDFSClientId} from 'utils';
import {CHANGE_MODEL, CHANGE_STRATEGY} from 'constants/constants';
import {useNavigate} from 'react-router-dom';
import {Modal} from '../../../../../ui-library';
import {MODIFY} from '../constants';

export const usePortfolioActionsLayout = () => {
    const navigate = useNavigate();
    const { initHeaderState } = useLayout();
    const { t } = useTranslation();
    // Client Provider
    const { clientId, clientName } = useClientProfile();
    const dfsClientId = genarateDFSClientId(clientId);
    // Portfolio Provider
    const { id: portfolioId } = usePortfolio();

    const confirmClosePortfolio = useCallback(() => {
        Modal.confirm({
            title: t('confirm.closeProcess'),
            content: t('confirm.closeModalText'),
            okText: t('clientDashboard.yes'),
            onOk: async () => {
                sessionStorage.removeItem(CHANGE_STRATEGY);
                sessionStorage.removeItem(CHANGE_MODEL);
                sessionStorage.removeItem(MODIFY);
                navigate(`/client/${dfsClientId}/dashboard`);
            },
            cancelText: t('clientDashboard.no'),
            className: 'close-client-modal',
        });
    }, [clientId, dfsClientId, portfolioId, t]);

    useEffect(() => {
        initHeaderState({
            link: null,
            clientName,
            dfsClientId,
            content: LogoWithClientName,
            contentProps: {
                clientName,
                dfsClientId,
                onClientNameClick: confirmClosePortfolio,
            },
            showClientTitle: false,
            showTopNavigation: false,
            leftButton: { type: 'close', label: t('confirm.closeProcess') },
            onLeftButtonClick: confirmClosePortfolio,
            rightButton: '',
            onRightButtonClick: null,
        });
    }, [clientName, dfsClientId, confirmClosePortfolio, initHeaderState, t]);
};
