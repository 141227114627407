import { Modal } from 'ui-library';
import { useClientProfile } from 'prodivers/clientProfile';
import useHistoryBlock from 'hooks/useHistoryBlock';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {useNavigate} from 'react-router-dom';

const CloseClient = (props) => {
    const {
        url, state,
    } = props;
    const navigate = useNavigate();
    const { t } = useTranslation();

    // Client Provider
    const { clientName } = useClientProfile();

    const confirmCloseClient = (pathProp, pathState, setOpenModal) => {
        // todo fix me[AT-638]
        const isNextOnBoarding = new RegExp('/onboarding').test(pathProp);
        const modalContent = isNextOnBoarding
            ? t('clientDashboard.startOnBoarding') : t('clientDashboard.closeModalText');
        const modalTitle = isNextOnBoarding
            ? t('clientDashboard.startOnBoarding.title') : t('clientDashboard.close');

        setOpenModal(true);

        Modal.confirm({
            title: `${modalTitle} ${clientName}`,
            content: modalContent,
            triggerCancelOnClose: true,
            okText: t('clientDashboard.confirm'),
            onOk: () => {
                // TODO: use new approach for window block
                // history.block(true);
                navigate(
                    state?.fromOrigin || pathProp,
                    {state: { ...state, ...pathState, fromTab: pathState?.fromTab }},
                );
                setOpenModal(false);
            },
            onCancel: async () => { await setOpenModal(false); },
            cancelText: t('clientDashboard.cancel'),
            className: 'close-client-modal',
        });
    };

    useHistoryBlock(url, confirmCloseClient);

    return null;
};

CloseClient.propTypes = {
    url: PropTypes.string.isRequired,
    state: PropTypes.shape({
        fromTab: PropTypes.string,
        fromOrigin: PropTypes.string,
    }),
};

CloseClient.defaultProps = {
    state: null,
};

export default CloseClient;
