import React from 'react';
import DocumentsCollapseTable from '../../../../components/DocumentsCollapseTable/DocumentsCollapseTable';
import InvestmentApplicationsActionButton, {APPLICATION_STATUS, STATUS}
    from '../../../../components/InvestmentApplicationsActionButton/InvestmentApplicationsActionButton';
import {dateTimeFormats} from '../../../../locale';

export const investmentApplicationsAdapter = ({
    data, t, updateData, getFormattedDate, getInvestmentOverview, isContainerCase,
}) => {
    const isCanceled = status => status === STATUS.canceled;

    const sortByStatusAndDate = (a, b) => {
        const dateA = new Date(a.createdDate).getTime();
        const dateB = new Date(b.createdDate).getTime();

        // If both are canceled or both are not canceled, sort by created date (newest first)
        if (isCanceled(a.status) === isCanceled(b.status)) {
            return dateB - dateA;
        } if (isCanceled(a.status)) {
            return 1; // Move canceled to the end
        }

        return -1; // Move canceled to the end
    };

    const tableData = data?.sort(sortByStatusAndDate)?.map(
        ({
            investmentApplicationId,
            createdDate,
            validTo,
            productName,
            strategy,
            submittedDate,
            agent,
            status: contractStatus,
            changePlan,
            productExternalId,
            advisor,
            createdBy,
            submittedBy,
            isJointAccount,
            owners,
            investmentDescription,
        }) => {
            const contactId = Math.min(...Object.keys(owners).map(i => Number(i)));

            return {
                applicationId: investmentApplicationId,
                investmentDescription,
                // zipId: contactId,
                // name: contactName,
                product: productName,
                strategy,
                createdDate: getFormattedDate(createdDate),
                validTo: getFormattedDate(validTo) || '-',
                submitted: getFormattedDate(submittedDate, {
                    hour: dateTimeFormats.TWO_DIGIT,
                    minute: dateTimeFormats.TWO_DIGIT,
                }) || '-',
                agent,
                status: APPLICATION_STATUS(t)?.[contractStatus],
                rawStatus: contractStatus,
                advisor: advisor || '-',
                createdBy: createdBy || '-',
                submittedBy: submittedBy || '-',
                isJointAccount,
                owners,
                createNewPlanData: () => (InvestmentApplicationsActionButton({
                    t,
                    status: contractStatus,
                    investmentApplicationId,
                    contactId,
                    changePlan,
                    updateData,
                    getInvestmentOverview,
                    productName,
                    productExternalId,
                    isContainerCase,
                })),
                collapseData: () => DocumentsCollapseTable({
                    investmentApplicationId, contactId, contractStatus, updateData,
                }),
            };
        },
    );

    return tableData;
};
