import React from 'react';
import PropTypes from 'prop-types';

function ChartLegend({ data, labelNew, labelCurrent }) {
    return (
        <>
            <g>
                <polygon points={data.currTriangle} className="chart-triangle chart-color-1" />
                <text
                    x={data.currX}
                    y={data.currY}
                    fontSize="14px"
                    className="chart-legend"
                >
                    {labelCurrent}
                </text>
            </g>
            <g>
                <polygon points={data.newTriangle} className="chart-triangle chart-color-0" />
                <text
                    x={data.newX}
                    y={data.newY}
                    fontSize="14px"
                    className="chart-legend"
                >
                    {labelNew}
                </text>
            </g>
        </>
    );
}

ChartLegend.propTypes = {
    data: PropTypes.shape({
        newX: PropTypes.number,
        newY: PropTypes.number,
        currX: PropTypes.number,
        currY: PropTypes.number,
        newTriangle: PropTypes.string,
        currTriangle: PropTypes.string,
    }),
    labelNew: PropTypes.string,
    labelCurrent: PropTypes.string,
};

ChartLegend.defaultProps = {
    data: {},
    labelNew: '',
    labelCurrent: '',
};

export default ChartLegend;
