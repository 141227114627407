import React, { useCallback, useState } from 'react';
import {
    Title,
} from 'ui-library';
import { useManagerProfile } from 'prodivers/managerProfile';
import AssetFee from './components/AssetFee/AssetFee';
import CustodyFee from './components/CustodyFee/CustodyFee';
import CustodyFeeModal from './components/CustodyFeeModal/CustodyFeeModal';
import './fees-overview-base.css';
import AssetFeeModal from './components/AssetFeeModal/AssetFeeModal';
import { FEE_TYPES } from '../../pages/CreateNewPortfolio/constants';
import EntryCostFee from './components/EntryCostFee/EntryCostFee';
import findSpecificFeeData from '../../pages/CreateNewPortfolio/pages/FeesOverview/hooks/findSpecificFeeData';

function FeesOverviewBase({
    t,
    productDesc,
    feeData,
    defaultCustodyFee,
    defaultAssetFee,
    defaultEntryFee,
    saveFeeLocally,
    isZivAzp,
    isZic,
}) {
    const [showFeeModal, setShowFeeModal] = useState(false);
    const [showAssetModal, setShowAssetModal] = useState(false);
    const {hasAccessPolicy, isPolicyLoading} = useManagerProfile();

    const disableEdit = useCallback((policy) => {
        // IF user does not have acces policy or its zic product

        if (isZic || isPolicyLoading || !hasAccessPolicy(policy)) {
            return true;
        }

        return false;
    }, [isZic, hasAccessPolicy, isPolicyLoading]);

    return (
        <div className="fi-verticals">
            <div>
                <Title type={1}>
                    {productDesc}
                </Title>
                <Title type={2}>{t('custodyFee.labels.title')}</Title>
                <div className="FeeOverview__GridWrapper">
                    <CustodyFee
                        t={t}
                        custodyFee={findSpecificFeeData(FEE_TYPES.custody, feeData)?.fee}
                        defaultCustodyFee={defaultCustodyFee}
                        setShowFeeModal={setShowFeeModal}
                        disableEdit={disableEdit('EnableEditCustodyFee')}
                    />
                    {isZivAzp && (
                        <AssetFee
                            t={t}
                            assetFee={findSpecificFeeData(FEE_TYPES.asset, feeData)?.fee}
                            defaultAssetFee={defaultAssetFee}
                            setShowAssetModal={setShowAssetModal}
                            disableEdit={disableEdit('EnableEditAssetFee')}
                        />
                    )}
                    {isZic && (
                        <EntryCostFee
                            defaultEntryFee={defaultEntryFee}
                            t={t}
                        />
                    )}
                </div>

                <CustodyFeeModal
                    showModal={showFeeModal}
                    handleClose={() => setShowFeeModal(false)}
                    onSave={saveFeeLocally}
                    feeData={findSpecificFeeData(FEE_TYPES.custody, feeData)}
                    defaultCustodyFee={defaultCustodyFee}
                />
                <AssetFeeModal
                    showModal={showAssetModal}
                    handleClose={() => setShowAssetModal(false)}
                    onSave={saveFeeLocally}
                    feeData={findSpecificFeeData(FEE_TYPES.asset, feeData)}
                    defaultAssetFee={defaultAssetFee}
                />
            </div>
        </div>
    );
}

export default FeesOverviewBase;
