import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useThreeBProductType } from 'hooks/isThreeBProduct';
import { AdditionalData, InvestorProfileHook } from 'widgets/InvestorProfile/types/InvestorProfileHook';
import { useCreatePortfolioActions, useCreatePortfolioSelectors, useCreatePortfolioState } from '../CreatePortfolio';

export const useInvestorProfile = (): InvestorProfileHook => {
    const { i18n: { language } } = useTranslation();

    const {
        jointClientSelector,
        currentClientSelector,
        targetInvestorProfileClientSelector,
        targetInvestorProfileOtherClientSelector,
        selectedProductSelector,
        targetInvestorProfileDFSClientIdSelector,
        isJointAccountSelector,
        isAdvisoryProcessSelector,
        advisoryProcessInProgressSelector,
    } = useCreatePortfolioSelectors();

    const {
        currentInvestmentApplicationId,
        uploadedAdvisoryDocument,
    } = useCreatePortfolioState();

    const {
        saveRecommendedStrategyAction,
        saveAdvisoryDataAction,
    } = useCreatePortfolioActions();

    const {
        isPk,
    } = useThreeBProductType({ product: selectedProductSelector });

    const saveAdditionalData = useCallback(async ({ clientId: mClientId, clientRiskToAccept, response }: AdditionalData) => {
        if (mClientId === clientRiskToAccept) {
            const additionalData: any = { recommendedStrategy: response };

            saveRecommendedStrategyAction({
                data: additionalData,
            });
        }
    }, [language, currentInvestmentApplicationId]);

    const isBackDisabled = useMemo(() => (!advisoryProcessInProgressSelector && !!uploadedAdvisoryDocument), [advisoryProcessInProgressSelector, uploadedAdvisoryDocument]);

    return {
        isJointAccount: isJointAccountSelector,
        isPk,
        saveAdditionalData,
        childClientName: targetInvestorProfileOtherClientSelector?.name,
        targetClientName: targetInvestorProfileClientSelector?.name,
        targetDFSClientId: targetInvestorProfileDFSClientIdSelector as string,
        clientAge: currentClientSelector?.age,
        isAdvisoryProcessSelector,
        saveAdvisoryDataAction,
        isBackDisabled,
    };
};
