import ChartType from 'components/ChartType/ChartType';
import Preloader from 'components/Preloader';
import { PIE } from 'constants/constants';
import { useFormatting } from 'locale';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Title } from 'ui-library';
import { allocationPercentFormat } from 'utils/formatting';

const AllocationChart = ({
    isLoading,
    isHidden,
    selectedProduct,
    allocations,
    factsheetUrl,
}) => {
    const { t } = useTranslation();
    const { getFormattedXAxisFormat } = useFormatting();

    return (
        <div
            className="fi-product-mark-selection-chart"
            hidden={isHidden}
        >
            <Preloader
                isLoading={isLoading}
            >
                <Title type={2}>{selectedProduct.Name}</Title>
                <div className="fi-strategy-layout fi-strategy-layout--pie-chart">
                    <div className="fi-pie-chart">
                        <ChartType
                            data={allocations}
                            chartView={PIE}
                            percentFormat={allocationPercentFormat}
                            xAxisFormat={getFormattedXAxisFormat}
                            chartSize={250}
                        />
                    </div>
                    <div className="fi-product-description">
                        {selectedProduct.Description && selectedProduct.Description.split('\n').map((item) => {
                            const trimItem = item.trim();

                            if (trimItem.length > 0) {
                                return (
                                    <p className="paragraph" key={trimItem}>
                                        {item.trim()}
                                    </p>
                                );
                            }

                            return null;
                        })}

                        {!!factsheetUrl && (
                            <a
                                href={factsheetUrl}
                                target="_blank"
                                className="button"
                                rel="noreferrer"
                            >
                                {t('portfolio.createNewPortfolio.strategy.factsheetUrl')}
                            </a>
                        )}
                    </div>
                </div>
            </Preloader>
        </div>
    );
};

AllocationChart.propTypes = {
    isLoading: PropTypes.bool,
    isHidden: PropTypes.bool,
    selectedProduct: PropTypes.shape({
        Name: PropTypes.string,
        Description: PropTypes.string,
    }),
    allocations: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.number,
        color: PropTypes.string,
        ExternalId: PropTypes.string || PropTypes.number,
    })),
    factsheetUrl: PropTypes.string,
};

AllocationChart.defaultProps = {
    isLoading: true,
    isHidden: true,
    selectedProduct: {},
    allocations: [],
    factsheetUrl: undefined,

};

export default AllocationChart;
