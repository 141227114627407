import { useCreatePortfolioSelectors, useCreatePortfolioState } from 'datasource/CreatePortfolio';
import { usePortfolioProcessNavigation } from 'hooks/helpers/usePortfolioProcessNavigation';
import { isThreeBProduct } from 'hooks/isThreeBProduct';
import { usePostRenderGeneralDocuments } from 'hooks/rest/usePostRenderGeneralDocuments';
import {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { memberIdSelector } from 'redux-store/auth/authSelectors';
import ServiceManager from 'services/ServiceManager';
import { notification } from 'ui-library';
import { getClientId } from 'utils';
import { IsAllDataNull, validateSelect } from 'validation/validation';
import { validate } from '../validation';

export const useFinalization = ({ dfsClientId }) => {
    const { i18n: { language }, t } = useTranslation();

    const { currentInvestmentApplicationId } = useCreatePortfolioState();

    const {
        selectedProductSelector,
        currentInvestmentApplicationSelector,
    } = useCreatePortfolioSelectors();

    const [pageErrors, setPageErrors] = useState();
    const isThreeB = isThreeBProduct({ product: selectedProductSelector });
    const [formData, setFormData] = useState({
        isConsultationConducted: false,
        transactionByExternalReferrer: false,
    });
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isSending, setSending] = useState(false);
    const clientId = getClientId(dfsClientId);
    const memberId = useSelector(memberIdSelector);
    const [error, setError] = useState(undefined);
    const [loadingAppsByContainer, setLoadingAppsByContainer] = useState(undefined);
    const [appsList, setAppsList] = useState([]);

    const containerId = useMemo(() => currentInvestmentApplicationSelector?.investmentDescription?.containerId, [currentInvestmentApplicationSelector?.investmentDescription?.containerId]);

    const getInvestmentAppsByContainerId = useCallback(async () => {
        try {
            if (!containerId) {
                return;
            }

            setLoadingAppsByContainer(true);

            const response = await ServiceManager.customInvestmentService('getInvestmentAppsByContainerId', [{ containerId, language }]);

            if (response?.data) {
                setAppsList(response?.data);
            }
        } catch (err) {
            console.error(err);
        } finally {
            setLoadingAppsByContainer(false);
        }
    }, [containerId, language]);

    useEffect(() => {
        (async () => getInvestmentAppsByContainerId())();
    }, [getInvestmentAppsByContainerId]);

    const {
        isLoading: isRenderGeneralDocumentsInProgress, refetch: renderGeneralDocuments,
    } = usePostRenderGeneralDocuments({
        containerId: currentInvestmentApplicationSelector?.investmentDescription?.containerId,
        isEnabled: false,
    });

    const onContinue = useCallback(async () => {
        setIsSubmitted(true);
        const errors = validate(formData);

        setError(errors);

        if (IsAllDataNull(errors) === false) {
            return;
        }

        try {
            setPageErrors();

            setSending(true);

            if (isThreeB) {
                await ServiceManager.customInvestmentService(
                    'finalizeNewPortfoliosByContainerId',
                    [{
                        containerId: currentInvestmentApplicationSelector?.investmentDescription?.containerId,
                        documentLanguage: formData.documentLanguage,
                    }],
                );
                const { error: renderGeneralDocumentsError } = await renderGeneralDocuments();

                /* if (renderGeneralDocumentsError) {
                    throw renderGeneralDocumentsError;
                } */
            } else {
                await ServiceManager.customInvestmentService(
                    'finalizeNewPortfolio',
                    [{
                        ...formData,
                        investmentApplicationId: currentInvestmentApplicationId,
                        documentLanguage: formData.documentLanguage,
                        isConsultationConducted: formData.isConsultationConducted,
                        transactionByExternalReferrer: formData.transactionByExternalReferrer,
                    }],
                );
                await ServiceManager.customInvestmentService('makeIbanReservation', [{ investmentApplicationId: currentInvestmentApplicationId }]);
                await ServiceManager.customInvestmentService('generateDocuments', [{ investmentApplicationId: currentInvestmentApplicationId }]);
            }

            stepComplete();
        } catch (err) {
            setPageErrors(err.message);
            notification.open({ content: t('general.somethingWentWrong'), type: 'error' });
            console.log('error with submit', err);
        } finally {
            setSending(false);
            setIsSubmitted(false);
        }
    }, [language, clientId, memberId, formData, isThreeB]);

    const yesNoOption = useMemo(() => ([
        { label: t('clientDashboard.yes'), id: true },
        { label: t('clientDashboard.no'), id: false },
    ]), []);

    const languagesOption = useMemo(() => ([
        { label: 'DE', id: 'de' },
        { label: 'FR', id: 'fr' },
        { label: 'IT', id: 'it' },
        { label: 'EN', id: 'en' },
    ]), []);

    const passData = useCallback((value, key) => {
        setError({
            ...error,
            [key]: validateSelect(value),
        });
        setFormData({
            ...formData,
            [key]: value,
        });
    }, [formData]);

    useEffect(() => {
        setFormData({
            ...formData,
            isConsultationConducted: false,
            transactionByExternalReferrer: false,
            documentLanguage: language.toLocaleLowerCase(),
        });
    }, [language]);

    const { stepComplete } = usePortfolioProcessNavigation({
        saveOnContinue: onContinue,
        enableAutoStepComplete: false,
        continueInProgress: isSending,
        pageErrors,
    });

    return {
        t,
        onContinue,
        yesNoOption,
        languagesOption,
        passData,
        isSubmitted,
        isSending,
        appsList,
        loadingAppsByContainer,
        data: formData,
        error,
    };
};
