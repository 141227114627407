export const getNames = (data) => {
    const listOfNames = new Set();

    data.forEach((val) => (
        listOfNames.add(val.name)
    ));

    return [...listOfNames];
};


export const generateObjFromDataPath = (dataPath = '', value = '') => {
    if (!dataPath) return {};


    return dataPath
        .split('.')
        .reduceRight(
            (acc, item, index, arr) => ({ [item]: index + 1 < arr.length ? acc : value }), {},
        );
};

export const convertObjToGraphQLSchema = (schemaObj) => (
    JSON.stringify(schemaObj)
        .replaceAll('"', ' ')
        .replaceAll(',', ' ')
        .replaceAll(':', ' ')
);
