import React from 'react';
import PropTypes from 'prop-types';
import { getClassNames } from '../../../utils';
import withTheme from '../../../hocs/withTheme';

import './TabPane.css';

const TabPane = (props) => {
    const {
        children,
        tabKey,
        isActive,
        disabled,
        className: classNameProp,
    } = props;

    const className = getClassNames(
        'TabPane',
        classNameProp,
        { 'TabPane-active': isActive },
        { 'TabPane-inactive': !isActive },
        { 'TabPane-disabled': disabled },
    );

    return (
        <div
            key={tabKey}
            role="tab"
            aria-selected={isActive}
            aria-disabled={disabled}
            className={className}
        >
            {children}
        </div>
    );
};

TabPane.propTypes = {
    children: PropTypes.node,
    tabKey: PropTypes.string.isRequired,
    isActive: PropTypes.bool,
    disabled: PropTypes.bool,
    className: PropTypes.string,
};

TabPane.defaultProps = {
    children: null,
    isActive: false,
    disabled: false,
    className: null,
};

export default withTheme(TabPane);
