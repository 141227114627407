import React, {
    forwardRef, useCallback, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import { getClassNames } from '../../utils';

import './RadioButton.css';
import uid from '../../utils/uid';
import { RADIO_SIZE_DEFAULT, RADIO_SIZES } from './constants';
import withTheme from '../../hocs/withTheme';

function RadioButton(props) {
    const {
        error,
        label,
        size,
        description,
        checked,
        defaultChecked,
        onChange,
        disabled,
        value,
        id,
        className,
        innerRef: ref,
        ...others
    } = props;

    const [inputValue, setInputValue] = useState(value);
    const [inputChecked, setInputChecked] = useState(checked || defaultChecked);

    useEffect(() => {
        setInputValue(value);
    }, [value]);

    useEffect(() => {
        setInputChecked(checked);
    }, [checked]);

    const classNameRadioButtonWrapper = getClassNames(
        'radio-wrapper',
        { [`radio-${size}`]: size },
        { 'radio-error': error },
        { 'radio-wrapper-disabled': disabled },
        className,
    );

    const classNameRadioButton = getClassNames(
        'radio',
        { 'radio-checked': inputChecked || defaultChecked },
    );

    const onChangeHandlar = useCallback((event) => {
        event.persist();
        setInputValue(event.target.value);
        if (typeof onChange === 'function') {
            onChange(event);
        }
        setInputChecked(event.target.value === inputValue);
    }, [onChange, setInputValue, inputValue, setInputChecked]);

    const uIdRadio = id || uid('radio');

    return (
        <div className={"radio-button"}>
            <input
                {...others}
                ref={ref}
                type="radio"
                className="radio-button-input"
                onChange={onChangeHandlar}
                value={inputValue}
                checked={inputChecked}
                disabled={disabled}
                id={uIdRadio}
            />
            <div className={"radio-button-inner"}>
                <label className={"radio-button-label"} htmlFor={uIdRadio}>
                    {label}
                </label>
                {description && <span className="radio-button-description">{description}</span>}
            </div>
        </div>
    );
}

RadioButton.propTypes = {
    /** Define a radio button label */
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    /** Define a radio button description */
    description: PropTypes.string,
    /** Get focus when component mounted */
    autoFocus: PropTypes.bool,
    /** Disable radio button */
    disabled: PropTypes.bool,
    /** Specifies the initial state: whether or not the radio button is selected */
    defaultChecked: PropTypes.bool,
    /** Enable error view */
    error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    /** The size of Radio */
    size: PropTypes.oneOf(RADIO_SIZES),
    /** The callback function that is triggered when the state changes */
    onChange: PropTypes.func,
    /** Specifies whether the radio is selected. */
    checked: PropTypes.bool,
    /** According to value for comparison, to determine whether the selected */
    value: PropTypes.string,
    /** @ignore */
    id: PropTypes.string,
    /** @ignore */
    className: PropTypes.string,
    /** @ignore */
    innerRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({
            current: PropTypes.objectOf,
        }),
    ]),
};

RadioButton.defaultProps = {
    description: null,
    autoFocus: false,
    disabled: false,
    defaultChecked: false,
    error: false,
    size: RADIO_SIZE_DEFAULT,
    onChange: () => {},
    checked: false,
    value: '',
    id: null,
    className: null,
    innerRef: null,
};

export default forwardRef((props, ref) => withTheme(RadioButton)({ ...props, innerRef: ref }));
