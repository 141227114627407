export const getAgentInfo = (t, agentName, provisionReceiver) => `${agentName || t('portfolio.createNewPortfolio.agentInformation.unknown')} / ${provisionReceiver || t('portfolio.createNewPortfolio.agentInformation.unknown')}`;

export const joinWithSeparator = (separator, ...args) => {
    const items = args?.filter(f => !!f);

    if (items?.length) {
        return items.join(separator);
    }

    return '-';
};
