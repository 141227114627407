import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { sortState } from '../utils/types';
import SortIcon from './SortIcon';
import { SORT_ORDER_MINI } from '../utils/constants';
import Dropdown from '../../Dropdown';
import MenuItem from '../../Dropdown/Menu/MenuItem';
import { ICON_TYPE_DROPDOWN } from '../../Icon';
import Icon from '../../Icon/Icon';
import { getClassNames, createUniqueKey } from '../../../utils';

const HeaderMini = ({
    columns, sorting, changeSorting, headerControls: { sortByLabel },
}) => {
    const hasSorting = columns.some((column) => column.sortable || column.sorter);
    const options = columns.filter(({ sortable }) => sortable)
        .map(({ key, title, sorter }) => (
            { value: key, label: title || key, sorter }
        ));
    const [selectedColumn, setColumn] = useState(sorting.key);
    const hasActiveSort = !!sorting.key;
    const sortDirection = hasActiveSort ? SORT_ORDER_MINI.indexOf(sorting.direction) : 0;
    const onSort = (columnKey) => {
        const column = options.find((option) => option.value === columnKey);
        const directionIndex = sortDirection === SORT_ORDER_MINI.length - 1 ? 0 : sortDirection + 1;

        changeSorting({
            key: columnKey,
            sorter: column.sorter,
            direction: SORT_ORDER_MINI[directionIndex],
        });
    };
    const onColumnSelect = (selectedColumn) => {
        const column = options.find((option) => option.value === selectedColumn);

        setColumn(selectedColumn);
        changeSorting({
            key: selectedColumn,
            sorter: column.sorter,
            direction: SORT_ORDER_MINI[sortDirection],
        });
    };

    const [visible, setVisible] = useState(false);

    const dropdownArrowClassName = useMemo(() => getClassNames(
        { 'dropdown-title__opened': visible },
    ), [visible]);

    const selectedColumnLabel = useMemo(() => {
        const column = options.find((option) => option.value === selectedColumn);

        return column?.label;
    }, [columns, selectedColumn]);

    if (!hasSorting) return null;

    return (
        <div className="Table-header-mini" role="row">
            <Dropdown
                title={(
                    <div className="dropdown-title">
                        <div className="dropdown-title-container">
                            <span>
                                {sortByLabel}
                                &nbsp;
                            </span>
                            <span>{selectedColumnLabel}</span>
                            <span className={dropdownArrowClassName}>
                                <Icon type={ICON_TYPE_DROPDOWN} className="dropdown-arrow" />
                            </span>
                        </div>
                    </div>
                )}
                visible={visible}
                onVisibleChange={setVisible}
                overlayClassName="Table-header__dropdown"
                placement="bottomRight"
            >
                {options?.map((item, index) => (
                    <MenuItem
                        key={createUniqueKey(index, item?.label)}
                        onClick={() => {
                            onColumnSelect(item?.value);
                            setVisible(false);
                        }}
                    >
                        {item?.label}
                    </MenuItem>
                ))}
            </Dropdown>
            {selectedColumn && options.length > 0 && (
                <SortIcon
                    isSorted={hasActiveSort}
                    direction={SORT_ORDER_MINI[sortDirection]}
                    handleSort={() => onSort(selectedColumn)}
                />
            )}
        </div>
    );
};

HeaderMini.propTypes = {
    sorting: sortState,
    /** Table header cells list */
    columns: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.string.isRequired,
        title: PropTypes.string,
        sortable: PropTypes.bool,
    })),
    headerControls: PropTypes.shape({
        sortByLabel: PropTypes.string,
    }).isRequired,
    changeSorting: PropTypes.func,
};

HeaderMini.defaultProps = {
    sorting: {},
    columns: [],
    changeSorting: () => { },
};

export default HeaderMini;
