import React from 'react';
import {useLocation} from 'react-router-dom';
import {CreatePortfolioManager} from '../../datasource/CreatePortfolio';
import {CreatePortfolio} from './CreatePortfolio';

export const CreatePortfolioMain = () => {
    const {
        state,
    } = useLocation();

    return (
        <CreatePortfolioManager investmentApplicationId={state?.investmentApplicationId}>
            <CreatePortfolio/>
        </CreatePortfolioManager>
    );
};
