import React from 'react';
import PropTypes from 'prop-types';

function CellWithSubtext({ title, subtitle }) {
    return (
        <div className="table-cell_with-subtext">
            <b>{title}</b>
            <span>{subtitle}</span>
        </div>
    );
}

CellWithSubtext.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
};

CellWithSubtext.defaultProps = {
    title: null,
    subtitle: null,
};

export default CellWithSubtext;
