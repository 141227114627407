import { AxiosRequestConfig, CancelTokenSource, Method } from 'axios';
import {CacheProperties} from 'axios-cache-interceptor/src/cache/cache';

export type BearerFunction = (a: boolean) => string|undefined;
export type ExtensionalAccessToken = 'clarity' | 'fidentity';
export type AuthStatus = 'Allow' | 'Auth';
export type ResultType =
| 'PasswordPolicyValidationFailed'
| 'Success'
| 'AccessDenied'
| 'AccessTokenInvalid'
| 'ActivationKeyInvalid'
| 'ApplicationNotEnrolled'
| 'ApplicationMultipleEnrolled'
| 'AuditContextIdInvalid'
| 'ConfirmPasswordInvalid'
| 'ConfirmPasswordNotMatched'
| 'ContactExternalInvalid'
| 'ContactIdInvalid'
| 'ContactLocked'
| 'ContactAlreadyUnlocked'
| 'ContactNotActivated'
| 'ContactNotPasswordSet'
| 'CredentialsInvalid'
| 'DeviceAlreadyEnrolled'
| 'DeviceMultipleEnrolled'
| 'DeviceNotEnrolled'
| 'EmailInvalid'
| 'EmailMultipleContacts'
| 'EmailNotSet'
| 'JwtTokenExpired'
| 'JwtTokenInvalid'
| 'MemberIdInvalid'
| 'MobileInvalid'
| 'MobileMultipleContacts'
| 'MTanInvalid'
| 'PasscodeInvalid'
| 'PasswordSetActive'
| 'PasswordInvalid'
| 'PasswordSameAlreadySet'
| 'SessionAlreadyActive'
| 'SessionExpired'
| 'SessionInvalid'
| 'TwoFactorDisabled'
| 'UsernameInvalid'
| 'UsernameAndEmailInvalid'
| 'Waiting';

export interface SetupData {
    withBearerApplicationAccessToken: boolean;
    bearerAccessToken?: string;
    bearerApplicationAccessToken?: string;
    bearerFIdentityAccessToken?: string;
    headers: Record<string, string>;
    isVersionIncluded: boolean;
    SERVICES_BASIC_AUTH?: string;
    DMS_BASIC_AUTH?: string;
    GATEWAY_API_URL: string;
    SERVICES_URL_SUFFIX: string;
    DMS_URL_SUFFIX: string;
    CUSTOM_URL_SUFFIX: string;
    DMS_URL?: string;
    SERVICES_URL?: string;
    CUSTOM_URL?: string;
    ENGAGEMENT_CONTENT_URL?: string;
    ENGAGEMENT_CONTENT_URL_SUFFIX: string;
    FIDENTITY_URL?: string;
    FIDENTITY_URL_SUFFIX: string;
    CLIENT_SERVICE_URL?: string;
    CLIENT_SERVICE_URL_SUFFIX: string;
    CLIENT_SERVICE_HEADERS: Record<string, string>;
    CLIENT_SERVICE_MEMBER_URL_SUFFIX: string;
}

export enum APIVersion {
    V1 = 'v1',
    V10 = 'v1.0',
    V11 = 'v1.1',
    V12 = 'v1.2',
    V20 = 'v2.0',
    V21 = 'v2.1',
    V22 = 'v2.2'
}

export type BearerType =
    'APPLICATION' |
    'AUTHORISATION';

export interface CancelTokenSourceWithMethod extends CancelTokenSource {
    method: string;
}

export enum ServiceTypes {
    DFS = 'DFS',
    DMS = 'DMS',
    FIDENTITY = 'FIDENTITY',
    APP = 'APP'
}

export interface RequestOptions {
    isSecure?: boolean;
    type?: ServiceTypes;
    axiosSetup?: AxiosRequestConfig;
    isAutoCancel?:boolean;
    withCancel?:boolean;
    bearerType?: BearerType;
    cache?: false | true | Partial<CacheProperties<any, any>>;
}

export interface RequestCoreParams<TData> {
    url: string;
    data?: TData;
    method: Method;
}

export interface SimpleRequestParameters {
    config?: RequestOptions;
}

export interface PayloadRequestParameters <Payload> extends SimpleRequestParameters {
    payload: Payload;
}

export interface URLRequestParameters <URLParameters> extends SimpleRequestParameters {
    urlParams: URLParameters;
}

export interface URLWithPayloadRequestParameters <URLParameters, Payload> extends SimpleRequestParameters {
    payload: Payload;
    urlParams: URLParameters;
}
