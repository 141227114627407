import { useCallback, useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import SM from 'services/ServiceManager';
import ServerError from 'errors/ServerError';
import HandlerError from 'errors/HandlerError';
import handlerRequestCanceling from 'utils/handlerRequestCanceling';

type LastApplicationPrams = {
    portfolioId?: string|number,
    loadInitially?: boolean
};

const initialState = {
    data: {},
    error: null,
    isLoading: false,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'setData':
            return { ...state, data: action.payload };
        case 'setError':
            return { ...state, error: action.payload };
        case 'setIsLoading':
            return { ...state, isLoading: action.payload };
        default:
            return state;
    }
};

export const useLastApplication = (options: LastApplicationPrams = {}) => {
    const {
        portfolioId: portfolioIdOption,
        loadInitially = false,
    } = options;
    const [state, dispatch] = useReducer(reducer, initialState);

    const { i18n: { language } } = useTranslation();

    // Callbacks
    const getLastApplication = useCallback(async ({ portfolioId = portfolioIdOption } = {}) => {
        dispatch({ type: 'setError', payload: null });
        dispatch({ type: 'setIsLoading', payload: true });

        if (!portfolioId) {
            return null;
        }

        try {
            const response = await SM.customInvestmentService('getLastApplicationByPortfolio', [{portfolioId, language}]);

            dispatch({ type: 'setData', payload: response?.data });

            return response?.data;
        } catch (err: any) {
            handlerRequestCanceling(
                HandlerError({
                    setError: (val) => dispatch({ type: 'setError', payload: val }),
                    setLoading: (val) => dispatch({ type: 'setIsLoading', payload: val }),
                }),
            )(err);

            return err.type !== undefined ? err : new ServerError(err);
        }
    }, [
        portfolioIdOption,
        language,
    ]);

    // Effects
    useEffect(() => {
        dispatch({ type: 'setIsLoading', payload: loadInitially });
    }, [loadInitially]);

    useEffect(() => {
        if (loadInitially) getLastApplication();
    }, [getLastApplication, loadInitially]);

    return {
        data: state.data,
        error: state.error,
        isLoading: state.isLoading,
        getLastApplication,
    };
};
