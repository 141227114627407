export const defaultBarChartOptions = {
    chart: {
        type: 'bar',
        styledMode: true,
        marginTop: 0,
        marginRight: 0,
        marginBottom: 0,
    },
    title: false,
    xAxis: {
        minPadding: 0,
        maxPadding: 0,
        title: false,
        labels: {
            align: 'left',
            reserveSpace: true,
            useHTML: true,
            allowOverlap: true,
            style: {
                wordBreak: 'break-all',
                textOverflow: 'allow',
            },
            format: '<div class="bar-chart__label">{value}</div>',
        },
    },
    yAxis: {
        minPadding: 0,
        maxPadding: 0,
        visible: false,
    },
    plotOptions: {
        bar: {
            pointWidth: 20,
            pointPadding: 0,
            colorByPoint: true,
        },
    },
    legend: { enabled: false },
    tooltip: { enabled: false },
    credits: false,
};
