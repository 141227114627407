import Service from 'additiv-services/services/core/service';
import { getQueryStringByObject } from 'additiv-services/utils/queryUtils';
import { APIVersion } from 'additiv-services';
import { CUSTOM_SERVICE_LAYER_SERVICES, getCustomServiceLayerBaseUrl } from '../constants';

class CustomDocumentService extends Service {
    static #baseServiceUrl = getCustomServiceLayerBaseUrl(CUSTOM_SERVICE_LAYER_SERVICES.DOCUMENTS);

    static #baseServiceUrlv11 = getCustomServiceLayerBaseUrl(CUSTOM_SERVICE_LAYER_SERVICES.DOCUMENTS, APIVersion.V11);

    static #baseServiceUrlv12 = getCustomServiceLayerBaseUrl(CUSTOM_SERVICE_LAYER_SERVICES.DOCUMENTS, APIVersion.V12);

    static getDocumentsByContainer({ containerId }) {
        return Service.getRequest(`${this.#baseServiceUrl}/investment/container/${containerId}`, {
            isSecure: true,
        });
    }

    static getDocumentsByInvestmentApplicationId({ investmentApplicationId, contactId }) {
        const queryString = getQueryStringByObject({ contactId });

        return Service.getRequest(`${this.#baseServiceUrl}/${investmentApplicationId}?${queryString}`, {
            isSecure: true,
        });
    }

    static getDocumentsByContactId({ contactId }) {
        return Service.getRequest(`${this.#baseServiceUrl}/${contactId}/id-document`, {
            isSecure: true,
        });
    }

    static getESignUrl({ documentId, returnUrl }) {
        return Service.getRequest(`${this.#baseServiceUrl}/${documentId}/esign?returnUrl=${returnUrl}`, { isSecure: true });
    }

    static manualDocumentSign({ documentId, ...rest }) {
        return Service.postRequest(`${this.#baseServiceUrl}/${documentId}/manual-sign`, rest, { isSecure: true });
    }

    static uploadProofOfAssetsDocuments(payload) {
        return Service.postRequest(`${this.#baseServiceUrl}/proof-of-asset`, payload, { isSecure: true });
    }

    static postDocumentSentFlag({ documentId }) {
        return Service.postRequest(`${this.#baseServiceUrl}/${documentId}/is-sent`, {}, { isSecure: true });
    }

    static getUnifiedDocuments(params) {
        const queryString = getQueryStringByObject(params);

        return Service.getRequest(`${this.#baseServiceUrl}/DocDB/GetUnifiedDocuments?${queryString}`, { isSecure: true });
    }

    static getDocumentPdf(params) {
        const queryString = getQueryStringByObject(params);

        return Service.getRequest(`${this.#baseServiceUrl}/DocDB/GetDocumentPdf?${queryString}`, { isSecure: true });
    }

    static uploadIdDocument(data) {
        return Service.postRequest(`${this.#baseServiceUrl}/iddocument`, data, { isSecure: true });
    }

    static getGeneralDocumentsAdvisoryWay({ language, containerId }) {
        const queryString = getQueryStringByObject({ language });

        return Service.getRequest(`${this.#baseServiceUrl}/${containerId}/general-documents?${queryString}`, { isSecure: true });
    }

    static getGeneralDocuments(params) {
        const queryString = getQueryStringByObject(params);

        return Service.getRequest(`${this.#baseServiceUrl}/general-documents?${queryString}`, { isSecure: true });
    }

    static uploadGeneralDocument({ payload, memberId }) {
        return Service.postRequest(`${this.#baseServiceUrl}/general-documents?memberId=${memberId}`, payload, { isSecure: true });
    }

    static getOpeningLetter({ portfolioId }) {
        return Service.getRequest(`${this.#baseServiceUrl}/portfolio/${portfolioId}/opening-letter`, { isSecure: true });
    }

    static getContactGroupDocuments({ contactGroupId }) {
        return Service.getRequest(`${this.#baseServiceUrl}/${contactGroupId}/contact-group`, { isSecure: true });
    }

    static uploadContactGroupDocuments({ payload }) {
        return Service.postRequest(`${this.#baseServiceUrl}/contact-group`, payload, { isSecure: true });
    }

    static getAdvisoryDocument({ advisoryDocumentId }) {
        return Service.getRequest(`${this.#baseServiceUrlv11}/advisory-document/${advisoryDocumentId}`, { isSecure: true });
    }

    static addAdvisoryDocument({ payload }) {
        return Service.postRequest(`${this.#baseServiceUrlv11}/advisory-document`, payload, { isSecure: true });
    }

    static uploadAdvisoryDocument({ payload }) {
        return Service.postRequest(`${this.#baseServiceUrlv12}/advisory-document`, payload, { isSecure: true });
    }

    static upload3bApplicationFormDocuments(payload) {
        return Service.postRequest(`${this.#baseServiceUrl}/application-form-document`, payload, { isSecure: true });
    }

    static deleteDocumentById(documentId) {
        return Service.deleteRequest(`${this.#baseServiceUrl}/general-documents?dmsDocumentId=${documentId}`, { isSecure: true });
    }
}

export default CustomDocumentService;
