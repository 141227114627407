import { getServicesUrl } from '../../setup';
import { APIVersion } from '../../types/main';
import Service from './service';
import { getQueryStringByObject } from '../../utils/queryUtils';

class Performance extends Service {
    static getAllocation(memberId, params) {
        const queryString = getQueryStringByObject({ 'aumRequest.memberId': memberId, ...params });

        return Service.getRequest(`${getServicesUrl()}/members/${memberId}/assetclasses/allocation?${queryString}`, { isSecure: true });
    }

    static getInvestmentPerformance(memberId, options) {
        return Service.postRequest(
            `${getServicesUrl(APIVersion.V21)}/members/${memberId}/accounts/history`, options, { isSecure: true }
        );
    }

    static calculateKPIPerformance(options, requestOptions) {
        return Service.postRequest(`${getServicesUrl()}/performance/kpi/calculate`, options, { ...(requestOptions || {}), isSecure: true });
    }

    static calculateProjectionPerformance(options) {
        return Service.postRequest(`${getServicesUrl()}/performance/projection/calculate`, options, { isSecure: true });
    }

    static calculateCorrelationMatrix(options) {
        return Service.postRequest(`${getServicesUrl()}/performance/correlation/calculate`, options, { isSecure: true });
    }

    static getModelPerformanceAttribution(options) {
        return Service.postRequest(`${getServicesUrl()}/performance/performance-attribution-cumulative`, options, { isSecure: true });
    }

    static postPerfomanceAllocationsCalculate(params,requestOptions) {
        const queryString = getQueryStringByObject(params);
        return Service.postRequest(`${getServicesUrl()}/performance/allocations/calculate?${queryString}`, requestOptions, { isSecure: true });
    }                                                 
}

export default Performance;
