import { createContext } from 'react';

const initialContext = {
    clientId: undefined,
    layout: {},
    personalDetails: {},
    riskProfile: {},
    strategy: {},
    newPortfolio: {},
};

const CreateNewPlanContext = createContext(initialContext);

export default CreateNewPlanContext;
