import { createSelector } from 'reselect';

export const idSelector = (state) => (state.id);
export const clientIdSelector = (state) => (state.clientId);

export const portfolioSelector = (state) => (state.portfolio);
export const lastApplicationSelector = (state) => (state.lastApplication);
export const applicationSelector = (state) => (state.application);
export const proposalSelector = (state) => (state.proposal);
export const generalAgencySelector = (state) => (state.generalAgency);
