import React from 'react';
import {useTranslation} from 'react-i18next';
import {OverviewPanel, OverviewItem} from 'components/Overview/index';
import PageSection from 'components/PageSection/PageSection';
import { Loader} from 'ui-library';
import Preloader from 'components/Preloader';
import {STARS, TYPE_CURRENCY} from './constants';
import Card from '../../../../../components/Card/Card';
import {useDownloadReport} from '../hooks/useDownloadReport';
import {PageNotFound} from '../../../../../components/PageNotFound';
import {useClientDashboard} from '../context';
import { TYPE_NUMBER } from '../../../../../utils/formatting';
import {useClientProfile} from '../../../../../prodivers/clientProfile';

function Overview() {
    const {t} = useTranslation();
    const {
        clientName,
        clientId,
    } = useClientDashboard();

    const {
        isDataAccessible, overviewData, portfolioNumber, isOverviewLoading,
    } = useClientProfile();

    const {
        downloadReport, isLoading: isReportLoading, fileName,
    } = useDownloadReport(clientId, clientName);

    const getValue = (value, defaultValue) => {
        if (!isDataAccessible) {
            return defaultValue;
        }

        return value;
    };

    if (clientId === undefined) {
        return (<PageNotFound/>);
    }

    return (
        <PageSection>

            <Card
                headerActions={(
                    <a
                        href={fileName}
                        className="button-link"
                        disabled={!clientName || isReportLoading}
                        onClick={downloadReport}
                        download
                    >
                        {isReportLoading ? <Loader/> : t('clientDashboard.overview.download')}
                    </a>
                )}
            >
                <Preloader isLoading={isOverviewLoading}>
                    <OverviewPanel>
                        <OverviewItem currency={overviewData?.currency} title={t('clientDashboard.overview.overallValue')} type={TYPE_CURRENCY} value={getValue(overviewData?.totalValue, STARS.multi)}/>
                        <OverviewItem currency={overviewData?.currency} title={t('clientDashboard.overview.cashAmount')} type={TYPE_CURRENCY} value={getValue(overviewData?.cashAmount, STARS.multi)}/>
                        <OverviewItem currency={overviewData?.currency} title={t('clientDashboard.overview.securityValue')} type={TYPE_CURRENCY} value={getValue(overviewData?.securityValue, STARS.multi)}/>
                        <OverviewItem
                            title={t('advisoryDashboard.overview.depotsNumber')}
                            type={TYPE_NUMBER}
                            value={getValue(portfolioNumber, STARS.single)}
                        />
                    </OverviewPanel>
                </Preloader>
            </Card>
        </PageSection>
    );
}

export default Overview;
