import {
    ApplicationDetailsActionTypes,
    DocTypes,
    TApplicationDetailsManagerState,
} from 'datasource/useApplicationDetails/ApplicationDetailsManager.types';
import { ApplicationDocumentType } from 'components/ApplicationDetails/AdvisorySection/AdvisorySection.types';
import { DocStatus } from '../../components/ApplicationDetails/DocBox/DocBox.types';

export const docLoadingEntity = {
    documentName: '',
    status: DocStatus.Loading,
    type: DocTypes.GENERATE,
};

export const getNoDocumentEntry = ({ documentName = '' }):ApplicationDocumentType => ({
    documentName,
    status: DocStatus.Disabled,
    type: DocTypes.NOT_AVAILABLE,
});

export const updateTypeToActionMap = {
    [DocTypes.APPLICATION]: ApplicationDetailsActionTypes.UPDATE_APPLICATION_DOC,
    [DocTypes.BASIC_AGREEMENT]: ApplicationDetailsActionTypes.UPDATE_BASIC_AGREEMENT_DOC,
};

export const initialState = (): TApplicationDetailsManagerState => ({
    proofOfAssetDocuments: [docLoadingEntity],
    basicAgreementDocuments: [docLoadingEntity],
    applicationsData: [],
});
