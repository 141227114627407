import {DocTypes} from 'datasource/useApplicationDetails/ApplicationDetailsManager.types';

export enum DocStatus {
    ReadyToGenerate = 'ready-to-generate',
    ReadyToSubmit = 'ready-to-submit',
    ReadyToSign = 'ready-to-sign',
    Canceled = 'canceled',
    Error = 'error',
    Loading = 'loading',
    Disabled = 'disabled',
    ReadyToUpload = 'ready-to-upload',
}

export interface DocBoxProps {
    type: DocTypes;
    uploadStatus?: DocStatus;
    documentName?: string;
    className?: string;
    documentId?: number;
    originOfAsset?: number;
    contactId?: number;
    applicationId?: number;
    isSent?: boolean;
    isSentCase?: boolean;
}
