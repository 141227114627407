import { getEngagementContentUrl } from '../../setup';
import { APIVersion } from '../../types/main';
import Service from './service';
import { getQueryStringByObject } from '../../utils/queryUtils';

class EngagementContent extends Service {
    static postSchema(data) {
        return this.postRequest(`${getEngagementContentUrl()}/content/schema`, data, { isSecure: true });
    }

    static postSchemaLatest(data) {
        return this.postRequest(`${getEngagementContentUrl()}/content/latest/schema`, data, { isSecure: true });
    }

    static contentSearch(data, language) {
        const languageGet = language && `?language=${language}`;

        return this.postRequest(`${getEngagementContentUrl(APIVersion.V11)}/content/search${languageGet}`, data, { isSecure: true });
    }

    static getContent(id, params = {}) {
        const queryString = params ? getQueryStringByObject(params) : '';

        return this.getRequest(`${getEngagementContentUrl()}/content/${id}?${queryString}`, { isSecure: true });
    }

    static getContentLatest(id, params = {}) {
        const queryString = params ? getQueryStringByObject(params) : '';

        return this.getRequest(`${getEngagementContentUrl()}/content/latest/${id}?${queryString}`, { isSecure: true });
    }

    static getContentImage(id, language) {
        return this.getRequest(`${getEngagementContentUrl(APIVersion.V20)}/content/${id}/image/${language}`, { isSecure: true });
    }

    static getContentLatestImage(id, language) {
        return this.getRequest(`${getEngagementContentUrl(APIVersion.V20)}/content/${id}/latest/image/${language}`, { isSecure: true });
    }

    static getContentDocument(id, language) {
        return this.getRequest(`${getEngagementContentUrl(APIVersion.V20)}/content/${id}/document/${language}`, { isSecure: true });
    }

    static getContentLatestDocument(id, language) {
        return this.getRequest(`${getEngagementContentUrl(APIVersion.V20)}/content/${id}/latest/document/${language}`, { isSecure: true });
    }
}

export default EngagementContent;
