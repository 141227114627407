import React, {FC} from 'react';
import './EmptyComp.css';

interface EmptyCompProps {
    text: string,
    className?: string
}

const EmptyComp: FC<EmptyCompProps> = ({
    text,
    className = ''
}) => (
    <div className={`empty-comp ${className}`}>{text}</div>
);

export default EmptyComp;

EmptyComp.defaultProps = {
    className: '',
};
