import React from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';

import ClientApplicationOverview from './ClientApplicationOverview';
import ApplicationDetailsPage from './ApplicationDetailsPage';

const ApplicationOverviewRouter = () => (
    <Routes >
        {/* for test */}
        {
            globalThis?.AppConfig?.ADVISORY_PROCESS_FEATURE_FLAG === 'true' && (
                <>
                    <Route path="application-2/:applicationId/" element={<ApplicationDetailsPage />} />
                    <Route path="container-2/:containerId/" element={<ApplicationDetailsPage />} />
                </>
            )
        }
        {/* for test */}

        <Route path="application/:applicationId/" element={<ClientApplicationOverview/>} />
        <Route path="container/:containerId/" element={<ClientApplicationOverview/>} />
        <Route
            path="*"
            element={<Navigate to="/404" replace />}
        />
    </Routes>
);

ApplicationOverviewRouter.displayName = 'Router';

export default ApplicationOverviewRouter;
