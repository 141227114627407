import React from 'react';
import {formatDate} from 'utils/datetime';
import {actionMessageKeyByStatusId} from '../const';

export const clientHistoryAdapter = ({data, t}) => {
    let adapted = [];

    if (data && data.length) {
        adapted = data.map((item) => ({
            expiration: formatDate(item?.expiryDate, 'DD.MM.YYYY'),
            createDate: formatDate(item?.modifiedDate, 'DD.MM.YYYY HH:mm'),
            initiatedBy: item?.modifiedBy === null ? t('clientDashboard.clientPortal.InitiatedBy.System') : item?.modifiedBy,
            action: t(actionMessageKeyByStatusId(item?.activationStatus)),
            isDisabled: !item?.isActive,
        })).reverse();
    }

    return adapted;
};
