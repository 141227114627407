import React from 'react';
import * as ReactDOM from 'react-dom';
import ConfirmModal from './ConfirmModal';

function confirm(config) {
    const div = document.createElement('div');

    document.body.appendChild(div);

    function destroy(...args) {
        const unmountResult = ReactDOM.unmountComponentAtNode(div);

        if (unmountResult && div.parentNode) {
            div.parentNode.removeChild(div);
        }
        const triggerCancel = args.some((param) => param && param.triggerCancel);

        if (config.onCancel && triggerCancel) {
            config.onCancel(...args);
        }
    }

    let currentConfig = { ...config, close, visible: true };

    function render({ okText, cancelText, ...props }) {
        /**
         * Sync render blocks React event. Let's make this async.
         */
        setTimeout(() => {
            ReactDOM.render(
                <ConfirmModal
                    {...props}
                    okText={okText}
                    cancelText={cancelText}
                />,
                div,
            );
        });
    }

    function close(...args) {
        currentConfig = {
            ...currentConfig,
            visible: false,
            afterClose: destroy.bind(this, ...args),
        };
        render(currentConfig);
    }

    function update(newConfig) {
        currentConfig = {
            ...currentConfig,
            ...newConfig,
        };
        render(currentConfig);
    }

    render(currentConfig);

    return {
        destroy: close,
        update,
    };
}

export default confirm;
