import React from 'react';
import PropTypes from 'prop-types';

function AccordionExtraCounter({ count }) {
    return (
        <div className="AccordionPanel-extra-counter">
            {count}
        </div>
    );
}

AccordionExtraCounter.propTypes = {
    /** Count */
    count: PropTypes.number,
};

AccordionExtraCounter.defaultProps = {
    count: null,
};

export default AccordionExtraCounter;
