import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import HighchartsReact from 'highcharts-react-official';
import {getBarChartOptions} from './utils';
import {getClassNames} from '../../../utils';
import Legend from './Legend';
import withTheme from '../../../hocs/withTheme';
import './ProjectionChart.css';

function ProjectionChart(props) {
    const {
        min,
        max,
        data,
        dataExternal,
        height,
        className,
        yAxisFormat,
        tooltipFormat,
        yTitle,
        yearsText,
        topLabel,
        bottomLabel,
        middleLabel,
        middleBottomLabel,
        middleTopLabel,
        legendLabels,
        showLegend,
        colorScheme,
    } = props;
    const containerClassName = getClassNames('ProjectionChart-container', {[`highcharts-${colorScheme}`]: colorScheme});
    const {i18n: {language}, t} = useTranslation();

    return (
        <div className={containerClassName}>
            <HighchartsReact
                containerProps={{
                    className: getClassNames('Highcharts-projection-chart', className),
                }}
                options={getBarChartOptions(
                    min,
                    max,
                    data,
                    dataExternal,
                    height,
                    yAxisFormat,
                    tooltipFormat,
                    {
                        yearsText,
                        yTitle,
                        topLabel,
                        bottomLabel,
                        middleLabel,
                        middleBottomLabel,
                        middleTopLabel,
                    },
                )}
            />
            <Legend dataExternal={dataExternal} legendLabels={legendLabels}/>
            <p className="projection-disclaimer">{t('clientDashboard.projectionDisclaimer')}</p>
        </div>
    );
}

ProjectionChart.propTypes = {
    /** Data (type 'area') to be shown on chart */
    data: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        data: PropTypes.arrayOf(PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.shape({
                [PropTypes.string]: PropTypes.number,
            }),
            PropTypes.arrayOf(PropTypes.number),
        ])).isRequired,
    })),
    /** External data with different types to be shown on chart */
    dataExternal: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        data: PropTypes.arrayOf(PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.shape({
                [PropTypes.string]: PropTypes.number,
            }),
            PropTypes.arrayOf(PropTypes.number),
        ])).isRequired,
    })),
    /** Height of chart */
    height: PropTypes.number,
    /** Formats of Y axis label */
    yAxisFormat: PropTypes.func,
    /** Formats of tooltip */
    tooltipFormat: PropTypes.func,
    /** Max value of Y axis */
    min: PropTypes.number,
    /** Max value of Y axis */
    max: PropTypes.number,
    /** Title to be shown on Y axis */
    yTitle: PropTypes.string,
    /** Name for top line */
    topLabel: PropTypes.string,
    /** Name for bottom line */
    bottomLabel: PropTypes.string,
    /** Name for middle line */
    middleLabel: PropTypes.string,
    /** Name for middle bottom line */
    middleBottomLabel: PropTypes.string,
    /** Name for middle top line */
    middleTopLabel: PropTypes.string,
    /** Label to be shown on X axis */
    yearsText: PropTypes.string,
    /** The custom color scheme (e.g. 'positiveAndNegative') */
    colorScheme: PropTypes.string,
    /** The legend labels to be shown */
    legendLabels: PropTypes.arrayOf(PropTypes.string),
    /** Whether the legend should be shown */
    showLegend: PropTypes.bool,
    className: PropTypes.string,
};

ProjectionChart.defaultProps = {
    min: undefined,
    max: undefined,
    data: [],
    dataExternal: [],
    yTitle: '',
    topLabel: '',
    bottomLabel: '',
    middleLabel: '',
    middleBottomLabel: '',
    middleTopLabel: '',
    height: undefined,
    yAxisFormat: (label) => label,
    tooltipFormat: ({
        point: {y},
        series: {name},
    }) => `${name}: ${(y.toLocaleString('en-US', {maximumFractionDigits: 2}))}`,
    colorScheme: null,
    legendLabels: [],
    showLegend: false,
    className: '',
    yearsText: 'Years',
};

export default withTheme(ProjectionChart);
