import { useContext } from 'react';

import CreateNewPlanContext from './context';

const useCreatePortfolio = () => {
    const context = useContext(CreateNewPlanContext);

    if (context === undefined) {
        throw new Error('useCreatePortfolio can only be used inside CreateNewPortfolioProvider');
    }

    return context;
};

export default useCreatePortfolio;
