import { useMemo } from 'react';
import { allocationColors } from 'constants/constants';
import { useCreatePortfolioSelectors } from 'datasource/CreatePortfolio';
import { useGetSecurityAllocations } from 'hooks/rest/useGetSecurityAllocations';
import { usePortfolioProcessNavigation } from 'hooks/helpers/usePortfolioProcessNavigation';
import { useCalculateStrategyRiskBandwidth } from 'hooks/rest/useCalculateStrategyRiskBandwidth';
import { useGetPortfolioVolatility } from 'hooks/rest/useGetPortfolioVolatility';
import { useTranslation } from 'react-i18next';
import { getLegendTooltip } from 'components/PieChartWIthLegend/helper';

export const useStrategyRiskComparison = () => {
    const { t, i18n: { language } } = useTranslation();
    const { advisoryIdSelector, advisoryDataSelector } = useCreatePortfolioSelectors();

    const advisoryMetaData = advisoryDataSelector?.metadata;
    const showSecurityAllocationChart = useMemo(() => (!!advisoryMetaData?.investments?.includedPortfolios?.length || !!advisoryMetaData?.otherInvestment?.investments?.length), [advisoryMetaData?.investments?.includedPortfolios, advisoryMetaData?.otherInvestment?.investments]);
    const { securityAllocations, securityAllocationsAfterOptimization } = useGetSecurityAllocations({
        advisoryId: advisoryIdSelector, afterOptimization: true, enabled: !!advisoryIdSelector, showSecurityAllocationChart,
    });

    const securityAllocationsData = useMemo(() => (securityAllocations?.data ? Object?.keys(securityAllocations?.data)?.map(key => ({
        name: key,
        percent: (securityAllocations?.data?.[key]?.percentage * 100)?.toFixed(2),
        color: allocationColors?.[key],
        value: securityAllocations?.data?.[key]?.value,
        className: key,
        description: getLegendTooltip(key, t),
    })) : []), [securityAllocations?.data, language]);

    const securityAllocationsDataAfterOptimization = useMemo(() => (securityAllocationsAfterOptimization?.data ? Object?.keys(securityAllocationsAfterOptimization?.data)?.map(key => ({
        name: key,
        percent: (securityAllocationsAfterOptimization?.data?.[key]?.percentage * 100)?.toFixed(2),
        color: allocationColors?.[key],
        value: securityAllocationsAfterOptimization?.data?.[key]?.value,
        className: key,
        description: getLegendTooltip(key, t),
    })) : []), [securityAllocationsAfterOptimization?.data, language]);

    const { data } = useCalculateStrategyRiskBandwidth({ advisoryId: advisoryIdSelector, isEnabled: !!advisoryIdSelector });
    const { data: portfolioVolatilities } = useGetPortfolioVolatility({ isEnabled: !!advisoryIdSelector });

    const riskCategoryId = useMemo(() => advisoryDataSelector?.metadata?.advisoryStrategy?.chosenRiskCategoryId, [advisoryDataSelector?.metadata?.advisoryStrategy?.chosenRiskCategoryId]);
    const portfolioVolatility = useMemo(() => portfolioVolatilities?.portfolioVolatililties?.find(v => v?.riskCategoryId === riskCategoryId), [portfolioVolatilities?.portfolioVolatililties, riskCategoryId]);

    const minMaxValue = {
        from: Math.round((portfolioVolatility?.minRiskValue || 0) * 100),
        to: Math.round((portfolioVolatility?.maxRiskValue || 0) * 100),
    };

    const calculatedRiskValue = useMemo(() => ((data?.metadata?.calculatedRiskValue || 0) * 100), [data?.metadata?.calculatedRiskValue]);

    const bandwidthStatus = useMemo(() => {
        if (+calculatedRiskValue > minMaxValue?.to) {
            return 'over-range';
        }

        if (+calculatedRiskValue < minMaxValue?.from) {
            return 'below-range';
        }

        return 'in-range';
    }, [calculatedRiskValue, minMaxValue]);

    const { setStep: setCNPStep } = usePortfolioProcessNavigation({ preventNavigationLock: true, continueDisabled: bandwidthStatus === 'over-range' });

    return {
        securityAllocations: {
            beforeOptimization: securityAllocationsData,
            afterOptimization: securityAllocationsDataAfterOptimization,
        },
        riskBandwidth: {
            portfolioVolatility: minMaxValue,
            calculatedRiskValue,
            bandwidthStatus,
        },
        setCNPStep,
    };
};
