import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ServiceManager from 'services/ServiceManager';
import AdapterError from 'errors/AdapterError';
import handlerRequestCanceling from 'utils/handlerRequestCanceling';
import HandlerError from 'errors/HandlerError';
import { useFormatting, useLocale } from 'locale';
import { APIVersion } from 'additiv-services';
import {useNavigate} from 'react-router-dom';
import { adaptInvestmentOverview } from '../adapters/adaptInvestmentOverview';
import { portfolioActions } from '../../../constants';
import {useProductsList} from '../../../../../hooks/useProducts';
import {useManagerProfile} from '../../../../../prodivers/managerProfile';

type TUseWithInvestmentOverview = {
    clientId: number,
    dfsClientId: string,
    isDataAccessible: boolean
    reFetchPortfolios?: boolean | null
}

type TRawData = {
    data: any,
    products: Array<any | never> | undefined,
    proposals?: any
}

type TData = {
    overview?: any,
    portfolios?: any,
    sectionedPortfolios?: any,
    investmentAllocation?: {
        chart: Array<any>
    }
}

type Nullable<T> = T | undefined | null

export const useWithInvestmentOverview = ({
    clientId, dfsClientId: dfsId, isDataAccessible, reFetchPortfolios = null,
}: TUseWithInvestmentOverview) => {
    const navigate = useNavigate();
    const [error, setError] = useState(null);
    const [isLoading, setLoading] = useState(true);
    const [data, setData] = useState<TData>({
        overview: {},
        investmentAllocation: { chart: [{ data: { pieSa: {}, bar: {}, list: {} } }] },
    });
    const [rawData, setRawData] = useState<Nullable<TRawData>>(null);
    const { i18n: {language}, t } = useTranslation();
    const { getFormattedCurrency } = useFormatting();
    const { locale } = useLocale();
    const {getProducts} = useProductsList(clientId);
    const {hasAccessPolicy} = useManagerProfile();

    // eslint-disable-next-line consistent-return
    const getInvestmentOverview = useCallback(async () => {
        // null means initial fetch
        if (reFetchPortfolios === false) {
            return;
        }

        setLoading(true);
        try {
            const params = { language };

            const [dataInvestmentResponse, allPortfoliosResponse, allJointPortfoliosResponse, allPOAPortfoliosResponse, allPAPortfoliosResponse, historicalPerfomanceResponse, products] = await Promise.all(
                [ServiceManager.portfolioManagement('getInvestmentOverview', [clientId, params, APIVersion.V11]),
                    ServiceManager.portfolioManagement('getAllPortfolios', [clientId, params]),
                    ServiceManager.portfolioManagement('getJointPortfolios', [clientId, params]),
                    ServiceManager.portfolioManagement('getPOAPortfolios', [clientId, params]),
                    ServiceManager.portfolioManagement('getPAPortfolios', [clientId, params]),
                    ServiceManager.portfolioManagement('getHistoricalPerformance', [clientId]),
                    getProducts()],
            );

            const investmentDataFromEndpoint = {
                ...dataInvestmentResponse?.data,
                Portfolios: allPortfoliosResponse?.data,
                JointPortfolios: allJointPortfoliosResponse?.data,
                POAPortfolios: allPOAPortfoliosResponse?.data,
                PAPortfolios: allPAPortfoliosResponse?.data,
                HistoricalPerformance: [
                    {
                        SeriesType: 'Portfolio',
                        Values: historicalPerfomanceResponse?.data?.Values,
                    },
                ],
            };

            try {
                setRawData({
                    data: investmentDataFromEndpoint,
                    products,
                });
            } catch (err) {
                throw new AdapterError(err);
            }
        } catch (err) {
            handlerRequestCanceling(
                HandlerError({
                    setError,
                    setLoading,
                }),
            )(err);
        } finally {
            setLoading(false);
        }
    }, [language, clientId, locale, reFetchPortfolios]);

    useEffect(() => {
        if (rawData) {
            setData(
                adaptInvestmentOverview(
                    {
                        data: rawData.data,
                        dfsId,
                        portfolioActions,
                        t,
                        getFormattedCurrency,
                        products: rawData.products,
                        hasAccessPolicy,
                        isDataAccessible,
                        navigatem: navigate,
                    },
                ),
            );
        }
    }, [rawData, hasAccessPolicy, dfsId, t, getFormattedCurrency, isDataAccessible]);

    useEffect(() => {
        getInvestmentOverview();
    }, [getInvestmentOverview]);

    return {
        data, isLoading, error, getInvestmentOverview,
    };
};
