import {validateInputAlphanumeric} from '../../../../../../validation/validation';

export const validate = ({
    commission, pbNumber, newPBNumber, vstNumber, generalAgency, agentInfo, isZic,
}, t) => ({
    commission: (() => {
        if (isZic) {
            return undefined;
        }
        if (commission === undefined || commission === null) {
            return t('validation.mandatoryField');
        }

        return undefined;
    })(),
    pbNumber: (() => {
        if (!pbNumber) {
            return t('validation.mandatoryField');
        }
        if (pbNumber?.length > 9) {
            return t('validation.fieldLength');
        }

        return undefined;
    })(),
    vstNumber: (() => validateInputAlphanumeric(vstNumber, true))(),
    generalAgency: (() => {
        if (!generalAgency) {
            return t('validation.mandatoryField');
        }

        return undefined;
    })(),
    agent: (() => {
        if (!agentInfo) {
            return t('validation.mandatoryField');
        }

        return undefined;
    })(),
});

export const isValid = (validation) => !Object.keys(validation).find((key) => (validation[key]));
