import CheckboxRenderer from '../../renderers/CheckboxRenderer';
import SliderTableRenderer from '../../renderers/SliderTableRenderer';
import FactsheetRenderer from '../../renderers/FactsheetRenderer';

export const columnDefs = (t) => [
    {
        key: 'name',
        title: t('portfolio.createNewPortfolio.productName'),
        render: CheckboxRenderer,
    },
    {
        key: 'allocation',
        title: t('portfolio.createNewPortfolio.instrumentAllocation'),
        render: SliderTableRenderer,
    },
    {
        key: 'factsheet',
        title: t('portfolio.createNewPortfolio.strategy.factsheetUrl'),
        render: FactsheetRenderer,
    },
];

export const footerColumnDefs = () => [
    {
        key: 'name',
    },
    {
        key: 'allocation',
        className: 'total-value',
    },
];
