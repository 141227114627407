import React from 'react';
import HighchartsReact from 'highcharts-react-official';
import HighchartsMore from 'highcharts/highcharts-more';
import Highcharts from 'highcharts';
import withTheme from '../../../hocs/withTheme';
import { getBubbleChartOptions } from './constants';
import './BubbleChart.css';

HighchartsMore(Highcharts);

function BubbleChart(props) {
    const {options} = props;

    return (
        <div className="Risk-return-container bubble-chart">
            <div className="Risk-return-chart-container">
                <HighchartsReact
                    highcharts={Highcharts}
                    containerProps={{ className: 'Highcharts-risk-return' }}
                    options={getBubbleChartOptions(options) as any}
                />
            </div>
        </div>
    );
}

export default withTheme(BubbleChart);
