import {User, UserManager, UserManagerSettings, WebStorageStateStore} from 'oidc-client';

type AuthenticationLogger = (error?: Error | unknown) => void

export class AuthenticationService {
    private userManager: UserManager | null;

    public logger: AuthenticationLogger = () => null;

    constructor(config?: null | UserManagerSettings, logger?: AuthenticationLogger) {
        if (config) {
            this.init(config, logger);
        }

        this.userManager = null;
    }

    private userManagerUninitialized = () => {
        this.logger(new Error('userManager is not initialized'));
    }

    init = (config: UserManagerSettings, logger?: AuthenticationLogger) => {
        this.userManager = new UserManager(config);
        if (typeof logger === 'function') {
            this.logger = logger;
        }

        return this;
    }

    getUserManager = () => {
        if (!this.userManager) {
            throw new Error('userManager is not initialized');
        }

        return this.userManager;
    }

    getUser = (): Promise<User | null> => {
        if (!this.userManager) {
            this.userManagerUninitialized();

            return Promise.resolve(null);
        }

        return this.userManager.getUser();
    }

    logout = async () => {
        if (!this.userManager) {
            this.userManagerUninitialized();

            return;
        }
        const user = await this.getUser();
        // @ts-ignore hot fix please refer to https://github.com/IdentityModel/oidc-client-js/issues/570#issuecomment-433557489
        await this.userManager.clearStaleState(new WebStorageStateStore({store: window.localStorage}))

        if (user) {
            await this.userManager.signoutRedirect();
        } else {
            await this.userManager.signinRedirect();
        }
    }

    login = async () => {
        if (!this.userManager) {
            this.userManagerUninitialized();

            return;
        }

        await this.userManager.signinRedirect();
    }

    signoutRedirect = async () => {
        if (!this.userManager) {
            this.userManagerUninitialized();

            return;
        }

        await this.userManager.signoutRedirect();
    }

    signinSilent = async () => {
        if (!this.userManager) {
            this.userManagerUninitialized();

            return;
        }

        await this.userManager.signinSilent();
    }
}
