import React from 'react';
import {Icon, Title} from 'ui-library';
import {ICON_EDIT_OUTLINE} from 'ui-library/components/Icon';
import {adaptCountry, adaptGender, adaptOccupancy,} from '../../../../../widgets/PersonalDetails/utils/adapters';

export function EditablePersonalData({
                                         data,
                                         editPersonalData,
                                         errorData,
                                         t,
                                         optionCountry,
                                         optionGender,
                                         optionOccupancy,
                                         isChangePlan,
                                     }) {
    return (
        <>
            <div className="LinkedHeader">
                <Title type={2} data-cy="full_name">
                    {data?.firstName}
                    {' '}
                    {data?.lastName}
                </Title>

                <button
                    className="button-link"
                    type="button"
                    data-cy="edit_personal_details"
                    onClick={() => {
                        editPersonalData();
                    }}
                >
                    <Icon size={24} type={ICON_EDIT_OUTLINE}/>
                    {t('interaction.edit')}
                </button>
            </div>

            <div className="personalDetailsTable data-summary-wrapper">

                <dl className="DataList">
                    <div className={errorData?.dateOfBirth ? 'DataList__Item--error' : null}>
                        <dt>
                            {t('onboardingFlow.personalPage.dateOfBirth')}
                        </dt>
                        <dd data-cy="dob">
                            {data?.dateOfBirth}
                        </dd>
                    </div>
                    <div className={errorData?.gender ? 'DataList__Item--error' : null}>
                        <dt>
                            {t('onboardingFlow.personalPage.gender')}
                        </dt>
                        <dd data-cy="gender">
                            {optionGender && adaptGender(optionGender, data?.gender)?.label}
                        </dd>
                    </div>
                    <div className={errorData?.countryOfNationality ? 'DataList__Item--error' : null}>
                        <dt>
                            {t('onboardingFlow.personalPage.countryOfNationality')}
                        </dt>
                        <dd data-cy="country_of_nationality">
                            {optionCountry && adaptCountry(optionCountry, data?.countryOfNationality)?.label}
                        </dd>
                    </div>
                    <div className={errorData?.countryOfBirth ? 'DataList__Item--error' : null}>
                        <dt>
                            {t('onboardingFlow.personalPage.countryOfBirth')}
                        </dt>
                        <dd data-cy="country_of_nationality">
                            {optionCountry && adaptCountry(optionCountry, data?.countryOfBirth)?.label}
                        </dd>
                    </div>
                    <div className={errorData?.avhNumber ? 'DataList__Item--error' : null}>
                        <dt>
                            {t('onboardingFlow.personalPage.ahvnumber')}
                        </dt>
                        <dd data-cy="avh_number">
                            {data?.avhNumber}
                        </dd>
                    </div>
                    <div
                        className={`DataList__Item--hspan ${errorData?.primaryEmail ? 'DataList__Item--error' : null}`}>
                        <dt>
                            {t('onboardingFlow.personalPage.email')}
                        </dt>
                        <dd data-cy="primary_email">
                            {data?.primaryEmail}
                        </dd>
                    </div>
                    <div className={errorData?.secondaryPhoneNumber ? 'DataList__Item--error' : null}>
                        <dt>
                            {t('dynamicCRM.communicationMethods.secondaryPhoneNumber')}
                        </dt>
                        <dd data-cy="telephone">
                            {data?.telephoneNumber}
                        </dd>
                    </div>
                    <div className={errorData?.primaryPhoneNumber ? 'DataList__Item--error' : null}>
                        <dt>
                            {t('dynamicCRM.communicationMethods.primaryPhoneNumber')}
                        </dt>
                        <dd data-cy="phone">
                            {data?.primaryPhoneNumber}
                        </dd>
                    </div>
                    {isChangePlan && (
                        <>
                            <div className="DataList__Item--hspan">
                                <dt>
                                    {t('dynamicCRM.personalInformation.occupation')}
                                </dt>
                                <dd data-cy="country_of_residence">
                                    {data?.occupation && adaptOccupancy(optionOccupancy, parseInt(data?.occupation, 10))?.label}
                                </dd>
                            </div>
                            <div className="DataList__Item--hspan">
                                <dt>
                                    {t('onboardingFlow.personalPage.domicileDeclaration')}
                                </dt>
                            </div>
                            <div>
                                <dt>
                                    {t('onboardingFlow.personalPage.taxDomicle')}
                                </dt>
                                <dd>{optionCountry && adaptCountry(optionCountry, data?.taxDomicile)?.label}</dd>
                            </div>
                            <div>
                                <dt>
                                    {t('dynamicCRM.personalInformation.riskDomicile')}
                                </dt>
                                <dd>{optionCountry && adaptCountry(optionCountry, data?.riskDomicile)?.label}</dd>
                            </div>
                        </>
                    )}
                </dl>
            </div>
        </>
    );
}
