import React from 'react';
import PropTypes from 'prop-types';
import { getClientId } from 'utils';
import { useCancelAllRequests } from 'hooks/useCancelAllRequests';
import ClientServiceProvider from 'domain/ClientService/context/Provider';
import {useParams} from 'react-router-dom';
import { useClientLayout } from '../../hooks/useClientLayout';
import Details from './Details';

const DetailsContainer = () => {
    useCancelAllRequests();
    useClientLayout();

    const { dfsClientId } = useParams();
    const contactId = getClientId(dfsClientId);

    return (
        <ClientServiceProvider options={{ contactId }}>
            <Details/>
        </ClientServiceProvider>
    );
};

export default DetailsContainer;
