import {getValidationMessage} from './messages';

export const validateInputAlphanumeric = (inputValue, isRequired = false) => {
    if (isRequired) {
        const isInvalidString = validateInputString(inputValue);

        if (isInvalidString) {
            return isInvalidString;
        }
    }
    if (!inputValue || inputValue === '') {
        return null;
    }

    const reqExp = new RegExp(/^\w+$/);
    const result = inputValue.toString()
        .match(reqExp);

    return result ? null : getValidationMessage().alphanumericError;
};

export const validateInputString = (inputValue?: string): string | null => {
    if (inputValue === null || inputValue === undefined || inputValue === 'N.A' || inputValue === '') {
        return getValidationMessage().mandatoryError;
    }

    return null;
};

export const validateSelect = (selectValue?: string | number): string | null => {
    if (selectValue === null || selectValue === undefined || selectValue === 'N/A') {
        return getValidationMessage().mandatoryError;
    }

    return null;
};

export const validateRadio = (radioValue) => {
    if (radioValue === null || radioValue === undefined) {
        return getValidationMessage().mandatoryError;
    }

    return null;
};

export const validateInputLength = (value: string, maxAllowed = 40) => {
    if (value?.length > maxAllowed) {
        return getValidationMessage({length: maxAllowed}).lengthError;
    }

    return null;
};

export const validateCheckboxGroup = (value?: Array<any>) => {
    if (!value || !value.length) {
        return getValidationMessage().mandatoryError;
    }

    return null;
};

export const validateMultipleInputsLength = (maxAllowed, message, ...args) => {
    let totalLength = 0;

    args.forEach(f => {
        totalLength += f?.length || 0;
    });

    if (totalLength > maxAllowed) {
        return message;
    }

    return null;
};

export const IsAnyOfDataNull = (obj) => Object.values(obj)
    .some((value) => value === null);
