import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import RiskComparisonComp from 'components/RiskComparisonComp';
import RiskBandWidthComparison from 'components/RiskBandWidthComparison';
import { StrategyRiskComparisonProps } from './StrategyRiskComparison.types';
import './StrategyRiskComparison.css';

const StrategyRiskComparison: FC<StrategyRiskComparisonProps> = ({ datasource }) => {
    const {
        securityAllocations,
        riskBandwidth: {
            portfolioVolatility,
            calculatedRiskValue,
            bandwidthStatus,
        },
        setCNPStep,
    } = datasource();

    const { t } = useTranslation();

    return (
        <section>
            <div className="strategy-risk-comparison-container">
                <RiskComparisonComp
                    chartData={securityAllocations?.beforeOptimization}
                    title={
                        t('StrategyRiskComparison.Title.BeforeOptimization')
                    }
                    subTitle={
                        t('StrategyRiskComparison.SubTitle.BeforeOptimization')
                    }
                />
                <RiskComparisonComp
                    chartData={securityAllocations?.afterOptimization}
                    title={
                        t('StrategyRiskComparison.Title.AfterOptimization')
                    }
                    subTitle={
                        t('StrategyRiskComparison.SubTitle.AfterOptimization')
                    }
                />
            </div>

            <div className="risk-bandwidth-comparison-container">
                <RiskBandWidthComparison
                    title={
                        t('StrategyRiskComparison.RiskBandWidth.AfterOptimization')
                    }
                    minMaxRange={portfolioVolatility}
                    value={calculatedRiskValue}
                    setCNPStep={setCNPStep}
                    bandwidthStatus={bandwidthStatus}
                />
            </div>
        </section>
    );
};

export default StrategyRiskComparison;
