import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import AccordionHeader from './Header';
import AccordionPanelContent from './Content';
import { getClassNames } from '../../../utils';
import uid from '../../../utils/uid';

function AccordionPanel(props) {
    const {
        id,
        count,
        header,
        panelKey,
        isActive,
        disabled,
        children,
        accordion,
        className: classNameProp,
        headerClass,
        onItemClick,
        dataTestId,
    } = props;

    const onClick = () => {
        onItemClick(panelKey);
    };

    const uId = useMemo(() => (id || uid('content')), [id]);
    const role = useMemo(() => (accordion ? 'tab' : 'button'), [accordion]);
    const tabIndex = useMemo(() => (disabled ? -1 : 0), [disabled]);
    const className = getClassNames(
        'AccordionPanel',
        classNameProp,
        { AccordionPanel__active: isActive },
    );

    return (
        <div className={className} data-testid={dataTestId}>
            <AccordionHeader
                uId={uId}
                role={role}
                count={count}
                tabIndex={tabIndex}
                isActive={isActive}
                headerClass={headerClass}
                onClick={onClick}
            >
                {header}
            </AccordionHeader>
            <AccordionPanelContent uId={uId} isActive={isActive}>
                {children}
            </AccordionPanelContent>
        </div>
    );
}

AccordionPanel.propTypes = {
    id: PropTypes.string,
    count: PropTypes.number,
    header: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    panelKey: PropTypes.string,
    isActive: PropTypes.bool,
    disabled: PropTypes.bool,
    children: PropTypes.node,
    accordion: PropTypes.bool,
    className: PropTypes.string,
    headerClass: PropTypes.number,
    onItemClick: PropTypes.func,
    dataTestId: PropTypes.string,
};

AccordionPanel.defaultProps = {
    id: null,
    count: null,
    header: null,
    panelKey: null,
    isActive: false,
    disabled: false,
    children: null,
    accordion: false,
    className: null,
    headerClass: null,
    onItemClick: () => {},
    dataTestId: undefined,
};

export default AccordionPanel;
