import React from 'react';
import PropTypes from 'prop-types';
import withTheme from '../../hocs/withTheme';
import './Badge.css';

const Badge = ({ title, color }) => (
    <div className={`Badge badge__${color}`}>
        {title}
    </div>
);


Badge.propTypes = {
    /** Text to be shown in the badge */
    title: PropTypes.string,
    /** Define a badge color (green, yellow, red) */
    color: PropTypes.string,
};

Badge.defaultProps = {
    title: null,
    color: 'default',
};


export default withTheme(Badge);
