import React from 'react';
import {useTranslation} from 'react-i18next';
import './KuDiCockpit.css';
import { Infobox, Title } from 'ui-library';
import Preloader from 'components/Preloader';
import {useParams} from 'react-router-dom';
import { useKudi } from './useKudi';
import EmptyContent from '../../../../../../../../components/EmptyContent';

const KuDiCockpit = () => {
    const {portfolioId, dfsClientId} = useParams();
    const {t} = useTranslation();
    const {
        isLoading,
        error,
        isEmpty,
        data: {
            id,
            lpzClientId,
            lpzPlanId,
            vestedBenefits,
            retirementAssetsBVG,
            vestedBenefitsAge50,
            vestedBenefitsUponMarriage,
            vestedBenefitsUponMarriageLPP,
            wefEarlyWithdrawal,
            wefEarlyWithdrawalLPP,
            repaymentWEFEarlyWithdrawal,
            repaymentWEFEarlyWithdrawalLPP,
            vestedBenefitsDivorce,
            vestedBenefitsDivorceLPP,
            purchaseIntoPensionFund3years,
        },
    } = useKudi({portfolioId});

    return (
        <div className="container">
            <Preloader isLoading={isLoading}>
                <EmptyContent data={isEmpty ? null : {}} text={t('advisoryDashboard.noData')}>
                    {
                        error ? (
                            <Infobox error>
                                {error?.message || error}
                            </Infobox>
                        ) : (
                            <div className="fi-verticals">
                                <div>
                                    <dl className="DataFilter">
                                        <div>
                                            <dt>{t('KuDi.client.clientNumber')}</dt>
                                            <dd>{id}</dd>
                                        </div>
                                        <div>
                                            <dt>{t('KuDi.client.LPZClientID')}</dt>
                                            <dd>{lpzClientId}</dd>
                                        </div>
                                        <div>
                                            <dt id="LPZPlanID">{t('KuDi.client.LPZPlanID')}</dt>
                                            <dd>{lpzPlanId}</dd>
                                        </div>
                                    </dl>
                                </div>
                                <div>
                                    <Title type={2}>{t('KuDi.client.KC03')}</Title>
                                    <dl className="DataList">
                                        <div>
                                            <dt>{t('KuDi.client.FZGH')}</dt>
                                            <dd>{vestedBenefits}</dd>
                                        </div>
                                        <div>
                                            <dt>{t('KuDi.client.BVG')}</dt>
                                            <dd>{retirementAssetsBVG}</dd>
                                        </div>
                                        <div>
                                            <dt>{t('KuDi.client.AGT50')}</dt>
                                            <dd>{vestedBenefitsAge50}</dd>
                                        </div>
                                        <div>
                                            <dt>{t('KuDi.client.AGBVG50')}</dt>
                                            <dd>{vestedBenefitsAge50}</dd>
                                        </div>
                                        <div>
                                            <dt>{t('KuDi.client.EHEKTO')}</dt>
                                            <dd>{vestedBenefitsUponMarriage}</dd>
                                        </div>
                                        <div>
                                            <dt>{t('KuDi.client.EHEKTOBVG')}</dt>
                                            <dd>{vestedBenefitsUponMarriageLPP}</dd>
                                        </div>
                                        <div>
                                            <dt>{t('KuDi.client.WEFAGT')}</dt>
                                            <dd>{wefEarlyWithdrawal}</dd>
                                        </div>
                                        <div>
                                            <dt>{t('KuDi.client.WEFAGBVG')}</dt>
                                            <dd>{wefEarlyWithdrawalLPP}</dd>
                                        </div>
                                        <div>
                                            <dt>{t('KuDi.client.WEF')}</dt>
                                            <dd>{repaymentWEFEarlyWithdrawal}</dd>
                                        </div>
                                        <div>
                                            <dt>{t('KuDi.client.WEFBVG')}</dt>
                                            <dd>{repaymentWEFEarlyWithdrawalLPP}</dd>
                                        </div>
                                        <div>
                                            <dt>{t('KuDi.client.SCHEID')}</dt>
                                            <dd>{vestedBenefitsDivorce}</dd>
                                        </div>
                                        <div>
                                            <dt>{t('KuDi.client.SCHEIDBVG')}</dt>
                                            <dd>{vestedBenefitsDivorceLPP}</dd>
                                        </div>
                                        <div>
                                            <dt>{t('KuDi.client.EINKAUFPVE')}</dt>
                                            <dd>{purchaseIntoPensionFund3years}</dd>
                                        </div>
                                    </dl>
                                </div>
                            </div>
                        )
                    }
                </EmptyContent>
            </Preloader>
        </div>
    );
};

export default KuDiCockpit;
