import React, {
    createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useReducer,
} from 'react';
import { Loader } from 'ui-library';
import {
    ClientSetup,
    PortfolioFlowLayoutManagerAPI,
    PortfolioFlowLayoutState,
    TPageError,
} from './PortfolioFlowLayoutManager.types';

const PortfolioFlowLayoutContext = createContext<PortfolioFlowLayoutManagerAPI>(null as any);

const LOCAL_STORAGE_KEY = 'PortfolioLayout';
const initialState = (): PortfolioFlowLayoutState => {
    const localStorageData = sessionStorage.getItem(LOCAL_STORAGE_KEY);

    return localStorageData ? JSON.parse(localStorageData) : {
        clientName: '',
        clientAge: undefined,
        risk: '',
        isCorrectFlow: false,
        disabled: false,
    };
};
const reducer = (state: PortfolioFlowLayoutState = initialState(), action) => {
    switch (action.type) {
        case 'setAdditionalComponentLabel':
            return { ...state, additionalComponentLabel: action.payload };
        case 'setOnAdditionalComponentClick':
            return { ...state, onAdditionalComponentClick: action.payload };
        case 'setOnContinueCallback':
            return { ...state, onContinue: action.payload };
        case 'setOnBackCallback':
            return { ...state, onBack: action.payload };
        case 'setPageError':
            return { ...state, pageErrors: action.payload };
        case 'continueLoading':
            return { ...state, inProgress: action.payload };
        case 'continueDisabled':
            return { ...state, continueDisabled: action.payload };
        case 'backDisabled':
            return { ...state, backDisabled: action.payload };
        case 'clientSetup':
            return {
                ...state,
                clientName: action.payload.clientName,
                clientAge: action.payload.clientAge,
                jaClientName: action.payload.jaClientName,
                jaClientAg: action.payload.jaClientAg,
            };
        case 'hideStepperButtons':
            return { ...state, hideStepperButtons: action.payload };
        default:
            return state;
    }
};

type PortfolioProcessLayoutManagerProps = {
    withRisk?: boolean;
    withProductGroupName?: boolean;
    children: ReactNode;
}

export const PortfolioProcessLayoutManager = ({
    children,
    withRisk,
    withProductGroupName,
}: PortfolioProcessLayoutManagerProps) => {
    const [state, dispatch] = useReducer(
        reducer,
        initialState(),
    );

    const setAdditionalComponentLabelAction = useCallback((label: string) => {
        dispatch({ type: 'setAdditionalComponentLabel', payload: label });
    }, []);

    const setOnAdditionalComponentLabelAction = useCallback((callback: (t: any) => any) => {
        dispatch({ type: 'setOnAdditionalComponentClick', payload: callback });
    }, []);

    const onPageErrorAction = useCallback((error?: TPageError) => {
        dispatch({ type: 'setPageError', payload: error });
    }, []);

    const onContinueAction = useCallback((callback: (t: any) => any) => {
        dispatch({ type: 'setOnContinueCallback', payload: callback });
    }, []);

    const onBackAction = useCallback((callback: (t: any) => any) => {
        dispatch({ type: 'setOnBackCallback', payload: callback });
    }, []);

    const continueLoadingAction = useCallback((flag: boolean) => {
        dispatch({ type: 'continueLoading', payload: flag });
    }, []);
    const continueDisabledAction = useCallback((flag: boolean) => {
        dispatch({ type: 'continueDisabled', payload: flag });
    }, []);
    const backDisabledAction = useCallback((flag: boolean) => {
        dispatch({ type: 'backDisabled', payload: flag });
    }, []);

    const clientSetupAction = useCallback((data: ClientSetup) => {
        dispatch({ type: 'clientSetup', payload: data });
    }, []);

    const hideStepperButtonsAction = useCallback((payload: boolean) => {
        dispatch({ type: 'hideStepperButtons', payload });
    }, []);

    const clearLayoutCache = useCallback(() => {
        sessionStorage.removeItem(LOCAL_STORAGE_KEY);
    }, []);

    useEffect(() => {
        sessionStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(state));
    }, [state]);

    const layoutData = useMemo(() => ({
        state: {
            ...state,
            withRisk,
            withProductGroupName,
            continueDisabled: state?.continueDisabled || state?.saveInProgress,
            backDisabled: state?.backDisabled || state?.saveInProgress,
            continueLabel: state?.saveInProgress ? <Loader/> : state?.continueLabel,
            hideStepperButtons: state?.hideStepperButtons,
        },
        actions: {
            onPageErrorAction,
            onContinueAction,
            onBackAction,
            clearLayoutCache,
            clientSetupAction,
            continueLoadingAction,
            continueDisabledAction,
            backDisabledAction,
            setAdditionalComponentLabelAction,
            setOnAdditionalComponentLabelAction,
            hideStepperButtonsAction,
        },
        selectors: {},
    }), [
        state,
    ]);

    return (
        <PortfolioFlowLayoutContext.Provider value={layoutData}>
            {children}
        </PortfolioFlowLayoutContext.Provider>
    );
};

export const usePortfolioFlowLayout = (): PortfolioFlowLayoutManagerAPI => useContext(PortfolioFlowLayoutContext);

PortfolioProcessLayoutManager.LOCAL_STORAGE_KEY = LOCAL_STORAGE_KEY;
PortfolioProcessLayoutManager.defaultProps = {
    withRisk: false,
    withProductGroupName: false,
};
