import React, {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import isNil from 'lodash/isNil';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import {
    Checkbox, Table, Title,
} from 'ui-library';
import Preloader from 'components/Preloader';
import {columnDefs, footerColumnDefs} from './columnDefs';
import {adaptAllocations} from './adaptAllocations';
import Icon from '../../../../../../ui-library/components/Icon/Icon';
import {ICON_TYPE_INFOBOX} from '../../../../../../ui-library/components/Icon';
import Popover from '../../../../../../ui-library/components/Popover';
import {Status, StatusBox} from '../../pages/CreateNewPortfolio/pages/KYC/components/StatusBox/StatusBox';

const IndividualPortfolioSecurities = ({
    isLoading,
    individualPortfolioData,
    isRebalance,
    modelPortfolioDetails,
    setIndividualPortfolioData,
    setRebalance,
    setValidationError,
    cachedSelectStrategy,
    setupWithdrawalPlan,
}) => {
    const [changesAllocationSliderValue, setChangesAllocationSliderValue] = useState();
    const [changedPositionListValue, setChangedPositionListValue] = useState();
    const {t} = useTranslation();
    const isWithdrawalEnabled = useMemo(() => JSON.parse(modelPortfolioDetails?.Attributes || '{}')?.WithdrawalOption, [modelPortfolioDetails]);

    const onAllocationChange = useCallback((value) => {
        setChangesAllocationSliderValue(value);
    }, []);

    const onCheckBoxChange = useCallback((value) => {
        setChangedPositionListValue(value);
    }, []);

    const onChangeRebalance = useCallback((e) => {
        setRebalance(e?.target?.checked);
    }, []);

    useEffect(() => () => {
        setValidationError(null);
    }, []);

    useEffect(() => {
        setValidationError(
            !isNil(individualPortfolioData?.footer?.allocation)
            && individualPortfolioData?.footer?.allocation !== 100
                ? t('portfolio.createNewPortfolio.strategy.individualPortfolio.error.OneHundred')
                : undefined,
        );
    }, [individualPortfolioData?.footer?.allocation]);

    const updateAllocationById = useCallback(({id, allocationUpdateObject = {}, nameUpdateObject = {}}) => {
        const tableData = individualPortfolioData.body.map((item) => {
            if (item?.id === id) {
                return {
                    ...item,
                    allocation: {
                        ...item?.allocation,
                        ...allocationUpdateObject,
                    },
                    name: {
                        ...item?.name,
                        ...nameUpdateObject,
                    },
                };
            }

            return item;
        });

        const footerTableData = {
            ...individualPortfolioData.footer,
            allocation: tableData.reduce((accu, item) => ((item?.name.checked ? item?.allocation?.value : 0) + accu), 0),
        };

        setIndividualPortfolioData({
            body: tableData,
            footer: footerTableData,
        });
    }, [individualPortfolioData?.body, individualPortfolioData?.footer]);

    useEffect(() => {
        if (changesAllocationSliderValue) {
            const {value, id} = changesAllocationSliderValue;

            updateAllocationById({
                id,
                allocationUpdateObject: value === 0 ? {value, disabled: true} : {value},
                nameUpdateObject: value === 0 ? {checked: false} : {},
            });
        }
    }, [changesAllocationSliderValue]);

    useEffect(() => {
        if (changedPositionListValue) {
            const {checked, id} = changedPositionListValue;

            updateAllocationById({
                id,
                allocationUpdateObject: {value: checked ? 10 : 0, disabled: !checked},
                nameUpdateObject: {checked},
            });
        }
    }, [changedPositionListValue]);

    useEffect(() => {
        const data = adaptAllocations({
            t, data: modelPortfolioDetails, onAllocationChange, onCheckBoxChange,
        });
        const cachedIndividualPortfolio = cachedSelectStrategy?.individualPortfolio;

        if (cachedIndividualPortfolio?.length) {
            data.body = data.body.map((item) => {
                const cachedAllocation = cachedIndividualPortfolio.find((cachedItem) => (item?.allocation?.id === cachedItem.securityId));

                if (cachedAllocation) {
                    return {
                        ...item,
                        name: {
                            ...item?.name,
                            checked: true,
                        },
                        allocation: {
                            ...item?.allocation,
                            value: cachedAllocation.allocation * 100,
                            disabled: false,
                        },
                    };
                }

                return {
                    ...item,
                    name: {
                        ...item?.name,
                        checked: false,
                    },
                    allocation: {
                        ...item?.allocation,
                        disabled: true,
                    },
                };
            });

            data.footer = {
                ...data.footer,
                allocation: Math.round(100 * cachedIndividualPortfolio.reduce((accu, item) => (item.allocation + accu), 0)),
            };
        }

        if (typeof cachedSelectStrategy?.rebalancing === 'boolean') {
            setRebalance(cachedSelectStrategy?.rebalancing);
        }

        setIndividualPortfolioData(data);
    }, [
        t,
        modelPortfolioDetails,
        cachedSelectStrategy?.modelPortfolioId,
    ]);

    useEffect(() => {
        if (!isWithdrawalEnabled && setupWithdrawalPlan) {
            setIndividualPortfolioData({});
        }
    }, [isWithdrawalEnabled, setupWithdrawalPlan]);

    return (
        <div className="fi-product-mark-selection">
            {!isWithdrawalEnabled && setupWithdrawalPlan ? <StatusBox status={Status.Warning} description={t('portfolioDetail.strategy.withdrawalPlanNotSupportedForIndividual')} />
                : (
                    <>
                        <Title type={2}>{t('portfolio.createNewPortfolio.allocations')}</Title>
                        <Preloader isLoading={isLoading}>
                            <div className="new-portfolio-content">
                                <Table
                                    data={individualPortfolioData.body}
                                    columns={columnDefs(t)}
                                    footerColumns={footerColumnDefs(t)}
                                    mini={false}
                                    footer={individualPortfolioData.footer}
                                />

                                <div className="rebalance-container">
                                    <Checkbox
                                        className="rebalance"
                                        label={t('portfolio.createNewPortfolio.allocations.rebalance')}
                                        onChange={onChangeRebalance}
                                        checked={isRebalance}
                                    />
                                    <Popover content={<div style={{padding: '10px', maxWidth: '300px'}}>{t('portfolioDetail.strategy.rebalancingTooltip')}</div>} trigger="hover">
                                        <Icon type={ICON_TYPE_INFOBOX} className="search-icon" size={16} />
                                    </Popover>
                                </div>
                            </div>
                        </Preloader>
                    </>
                )}
        </div>
    );
};

const keyValue = PropTypes.shape({
    [PropTypes.string]: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.shape,
        PropTypes.bool,
        PropTypes.func,
    ]),
});

const keyValueArray = PropTypes.arrayOf(keyValue);

IndividualPortfolioSecurities.propTypes = {
    individualPortfolioData: PropTypes.shape({ body: keyValueArray, footer: keyValue}),
    isLoading: PropTypes.bool,
    isRebalance: PropTypes.bool,
    setRebalance: PropTypes.func,
    modelPortfolioDetails: PropTypes.shape({
        Positions: PropTypes.arrayOf(PropTypes.shape({
            Allocation: PropTypes.number,
            Security: PropTypes.shape({
                Id: PropTypes.number,
                Name: PropTypes.string,
            }),
        })),
    }),
    setIndividualPortfolioData: PropTypes.func,
    setValidationError: PropTypes.func,
};

IndividualPortfolioSecurities.defaultProps = {
    individualPortfolioData: {body: [], footer: {}},
    isLoading: true,
    isRebalance: false,
    setRebalance: () => {},
    modelPortfolioDetails: {},
    setIndividualPortfolioData: () => {},
    setValidationError: () => {},
};

export default IndividualPortfolioSecurities;
