export const prefixCls = 'Modal';

export const defaultConfig = {
    width: 380,
};

export const confirmProps = {
    okButtonProps: { className: 'button button-primary' },
    cancelButtonProps: { className: 'button button-secondary' },
};
