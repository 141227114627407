import {
    useState, useEffect, useRef, useCallback,
} from 'react';
import debounce from 'lodash/debounce';
import { parseTimeToMs } from '../utils';

export const useIsUserActive = (inactiveTime = 10) => {
    const timer = useRef<ReturnType<typeof setTimeout>>();
    const [isActiveUser, setIsActiveUser] = useState(true);
    const events = [
        'mousemove',
        'click',
        'scroll',
        'keypress',
    ];

    const setUserActive = useCallback(
        debounce(() => {
            if (timer.current) clearTimeout(timer.current);
            setIsActiveUser(true);
            timer.current = setTimeout(() => {
                setIsActiveUser(false);
            }, parseTimeToMs(inactiveTime));
        }, 1000),
        [timer.current, inactiveTime],
    );

    useEffect(() => {
        setUserActive();
        events.forEach((event) => {
            global.window.addEventListener(event, setUserActive);
        });

        return () => {
            if (timer.current) clearTimeout(timer.current);
            events.forEach((event) => {
                global.window.removeEventListener(event, setUserActive);
            });
        };
    }, []);

    return isActiveUser;
};
