import PropTypes from 'prop-types';

export const optionType = PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    disabled: PropTypes.bool,
    error: PropTypes.bool,
    description: PropTypes.string,
});

export const optionsList = PropTypes.arrayOf(
    PropTypes.oneOfType([optionType, PropTypes.string]),
);
