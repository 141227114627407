import React from 'react';
import PropTypes from 'prop-types';
import Icon, { ICON_TYPE_CLOSE } from '../../../Icon';
import './NotificationItem.css';
import { getClassNames } from '../../../../utils';

const NotificationItem = ({
    id, subject, date, text, onClick,
}) => {
    const className = getClassNames('NotificationItem', { 'NotificationItem__without-date': !date });

    return (
        <div className={className}>
            <div className="notification__title">{subject}</div>
            {date && <div className="notification__date">{date}</div>}
            <div className="notification__description">
                {text}
            </div>
            <div
                className="notification__read"
                role="button"
                tabIndex={0}
                onClick={() => onClick(id, subject, date, text)}
                onKeyPress={onClick}
            >
                <Icon type={ICON_TYPE_CLOSE} />
            </div>
        </div>
    );
};

NotificationItem.propTypes = {
    /** The Notification id */
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /** The Notification subject */
    subject: PropTypes.string,
    /** The Notification date */
    date: PropTypes.string,
    /** The Notification text */
    text: PropTypes.string,
    /** The Notification onClick */
    onClick: PropTypes.func,
};

NotificationItem.defaultProps = {
    id: null,
    subject: null,
    date: null,
    text: null,
    onClick: () => {
    },
};
export default NotificationItem;
