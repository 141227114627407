export const additionalCountryList = [
    { id: 139, label: 'Liechtenstein', listKey: 'country' },
    { id: 99, label: 'France', listKey: 'country' },
    { id: 123, label: 'Italy', listKey: 'country' },
    { id: 49, label: 'Austria', listKey: 'country' },
    { id: 84, label: 'Germany', listKey: 'country' },
    { id: 73, label: 'Switzerland', listKey: 'country' },
];

export const additionalLanguageList = [
    { id: 235, label: 'English', listKey: 'language' },
    { id: 237, label: 'French', listKey: 'language' },
    { id: 236, label: 'Italian', listKey: 'language' },
    { id: 234, label: 'German', listKey: 'language' },
];

export const postCodeSwitzland = [
    {
        Ortschaftsname: 'Aeugst am Albis',
        PLZ: 8914,
        Zusatzziffer: 0,
        Gemeindename: 'Aeugst am Albis',
        'BFS-Nr': 1,
        Kantonskürzel: 'ZH',
        E: 8.48831328535326,
        N: 47.26700438726633,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Aeugstertal',
        PLZ: 8914,
        Zusatzziffer: 2,
        Gemeindename: 'Aeugst am Albis',
        'BFS-Nr': 1,
        Kantonskürzel: 'ZH',
        E: 8.49364170604486,
        N: 47.282760808853396,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Zwillikon',
        PLZ: 8909,
        Zusatzziffer: 0,
        Gemeindename: 'Affoltern am Albis',
        'BFS-Nr': 2,
        Kantonskürzel: 'ZH',
        E: 8.431458619350813,
        N: 47.287633089462105,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Affoltern am Albis',
        PLZ: 8910,
        Zusatzziffer: 0,
        Gemeindename: 'Affoltern am Albis',
        'BFS-Nr': 2,
        Kantonskürzel: 'ZH',
        E: 8.448945112880077,
        N: 47.27916857724247,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Bonstetten',
        PLZ: 8906,
        Zusatzziffer: 0,
        Gemeindename: 'Bonstetten',
        'BFS-Nr': 3,
        Kantonskürzel: 'ZH',
        E: 8.467611445312384,
        N: 47.31551041988308,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Hausen am Albis',
        PLZ: 8915,
        Zusatzziffer: 0,
        Gemeindename: 'Hausen am Albis',
        'BFS-Nr': 4,
        Kantonskürzel: 'ZH',
        E: 8.534860104026881,
        N: 47.24235959332327,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Ebertswil',
        PLZ: 8925,
        Zusatzziffer: 0,
        Gemeindename: 'Hausen am Albis',
        'BFS-Nr': 4,
        Kantonskürzel: 'ZH',
        E: 8.550009060935205,
        N: 47.22615737479009,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Hedingen',
        PLZ: 8908,
        Zusatzziffer: 0,
        Gemeindename: 'Hedingen',
        'BFS-Nr': 5,
        Kantonskürzel: 'ZH',
        E: 8.450346932518778,
        N: 47.29793768702259,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Kappel am Albis',
        PLZ: 8926,
        Zusatzziffer: 0,
        Gemeindename: 'Kappel am Albis',
        'BFS-Nr': 6,
        Kantonskürzel: 'ZH',
        E: 8.52484980001053,
        N: 47.22736695387242,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Hauptikon',
        PLZ: 8926,
        Zusatzziffer: 2,
        Gemeindename: 'Kappel am Albis',
        'BFS-Nr': 6,
        Kantonskürzel: 'ZH',
        E: 8.495354094622611,
        N: 47.23038738389161,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Uerzlikon',
        PLZ: 8926,
        Zusatzziffer: 3,
        Gemeindename: 'Kappel am Albis',
        'BFS-Nr': 6,
        Kantonskürzel: 'ZH',
        E: 8.49938180782218,
        N: 47.22203318856604,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Knonau',
        PLZ: 8934,
        Zusatzziffer: 0,
        Gemeindename: 'Knonau',
        'BFS-Nr': 7,
        Kantonskürzel: 'ZH',
        E: 8.460616615257766,
        N: 47.223794093961985,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Maschwanden',
        PLZ: 8933,
        Zusatzziffer: 0,
        Gemeindename: 'Maschwanden',
        'BFS-Nr': 8,
        Kantonskürzel: 'ZH',
        E: 8.426273936192054,
        N: 47.235240765224226,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Mettmenstetten',
        PLZ: 8932,
        Zusatzziffer: 0,
        Gemeindename: 'Mettmenstetten',
        'BFS-Nr': 9,
        Kantonskürzel: 'ZH',
        E: 8.463769783327361,
        N: 47.24279923596396,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Obfelden',
        PLZ: 8912,
        Zusatzziffer: 0,
        Gemeindename: 'Obfelden',
        'BFS-Nr': 10,
        Kantonskürzel: 'ZH',
        E: 8.42304503703087,
        N: 47.263626853678495,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Ottenbach',
        PLZ: 8913,
        Zusatzziffer: 0,
        Gemeindename: 'Ottenbach',
        'BFS-Nr': 11,
        Kantonskürzel: 'ZH',
        E: 8.404344633221083,
        N: 47.282771337374626,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Rifferswil',
        PLZ: 8911,
        Zusatzziffer: 0,
        Gemeindename: 'Rifferswil',
        'BFS-Nr': 12,
        Kantonskürzel: 'ZH',
        E: 8.496528927971678,
        N: 47.243477987063386,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Stallikon',
        PLZ: 8143,
        Zusatzziffer: 0,
        Gemeindename: 'Stallikon',
        'BFS-Nr': 13,
        Kantonskürzel: 'ZH',
        E: 8.490997118966119,
        N: 47.32586425336778,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Uetliberg',
        PLZ: 8143,
        Zusatzziffer: 2,
        Gemeindename: 'Stallikon',
        'BFS-Nr': 13,
        Kantonskürzel: 'ZH',
        E: 8.491344879313965,
        N: 47.3494790465516,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Wettswil',
        PLZ: 8907,
        Zusatzziffer: 0,
        Gemeindename: 'Wettswil am Albis',
        'BFS-Nr': 14,
        Kantonskürzel: 'ZH',
        E: 8.474962403404636,
        N: 47.33802011347025,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Adlikon b. Andelfingen',
        PLZ: 8452,
        Zusatzziffer: 0,
        Gemeindename: 'Adlikon',
        'BFS-Nr': 21,
        Kantonskürzel: 'ZH',
        E: 8.692311457125522,
        N: 47.5830351800184,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Benken ZH',
        PLZ: 8463,
        Zusatzziffer: 0,
        Gemeindename: 'Benken (ZH)',
        'BFS-Nr': 22,
        Kantonskürzel: 'ZH',
        E: 8.654019191577385,
        N: 47.65273896514521,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Berg am Irchel',
        PLZ: 8415,
        Zusatzziffer: 0,
        Gemeindename: 'Berg am Irchel',
        'BFS-Nr': 23,
        Kantonskürzel: 'ZH',
        E: 8.598157496002308,
        N: 47.57046219876355,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Gräslikon',
        PLZ: 8415,
        Zusatzziffer: 1,
        Gemeindename: 'Berg am Irchel',
        'BFS-Nr': 23,
        Kantonskürzel: 'ZH',
        E: 8.605294557065687,
        N: 47.557320374168285,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Buch am Irchel',
        PLZ: 8414,
        Zusatzziffer: 0,
        Gemeindename: 'Buch am Irchel',
        'BFS-Nr': 24,
        Kantonskürzel: 'ZH',
        E: 8.622589256001662,
        N: 47.54900405618059,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Dachsen',
        PLZ: 8447,
        Zusatzziffer: 0,
        Gemeindename: 'Dachsen',
        'BFS-Nr': 25,
        Kantonskürzel: 'ZH',
        E: 8.613862622551544,
        N: 47.666972343003195,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Dorf',
        PLZ: 8458,
        Zusatzziffer: 0,
        Gemeindename: 'Dorf',
        'BFS-Nr': 26,
        Kantonskürzel: 'ZH',
        E: 8.647981643666581,
        N: 47.571686192436104,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Feuerthalen',
        PLZ: 8245,
        Zusatzziffer: 0,
        Gemeindename: 'Feuerthalen',
        'BFS-Nr': 27,
        Kantonskürzel: 'ZH',
        E: 8.64104452857084,
        N: 47.69094830024171,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Langwiesen',
        PLZ: 8246,
        Zusatzziffer: 0,
        Gemeindename: 'Feuerthalen',
        'BFS-Nr': 27,
        Kantonskürzel: 'ZH',
        E: 8.6632601277367,
        N: 47.683983554881884,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Flaach',
        PLZ: 8416,
        Zusatzziffer: 0,
        Gemeindename: 'Flaach',
        'BFS-Nr': 28,
        Kantonskürzel: 'ZH',
        E: 8.60285262969122,
        N: 47.573334476210746,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Flurlingen',
        PLZ: 8247,
        Zusatzziffer: 0,
        Gemeindename: 'Flurlingen',
        'BFS-Nr': 29,
        Kantonskürzel: 'ZH',
        E: 8.62786801940825,
        N: 47.6841707878047,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Andelfingen',
        PLZ: 8450,
        Zusatzziffer: 0,
        Gemeindename: 'Andelfingen',
        'BFS-Nr': 30,
        Kantonskürzel: 'ZH',
        E: 8.67852026951591,
        N: 47.594882202224284,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Henggart',
        PLZ: 8444,
        Zusatzziffer: 0,
        Gemeindename: 'Henggart',
        'BFS-Nr': 31,
        Kantonskürzel: 'ZH',
        E: 8.683314132835044,
        N: 47.562703893397725,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Humlikon',
        PLZ: 8457,
        Zusatzziffer: 0,
        Gemeindename: 'Humlikon',
        'BFS-Nr': 32,
        Kantonskürzel: 'ZH',
        E: 8.6695746664396,
        N: 47.5756287355605,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Kleinandelfingen',
        PLZ: 8451,
        Zusatzziffer: 0,
        Gemeindename: 'Kleinandelfingen',
        'BFS-Nr': 33,
        Kantonskürzel: 'ZH',
        E: 8.682990859670031,
        N: 47.599528364393144,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Alten',
        PLZ: 8453,
        Zusatzziffer: 0,
        Gemeindename: 'Kleinandelfingen',
        'BFS-Nr': 33,
        Kantonskürzel: 'ZH',
        E: 8.647460097153353,
        N: 47.59942553542318,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Oerlingen',
        PLZ: 8461,
        Zusatzziffer: 0,
        Gemeindename: 'Kleinandelfingen',
        'BFS-Nr': 33,
        Kantonskürzel: 'ZH',
        E: 8.676249811872285,
        N: 47.62398412956237,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Nohl',
        PLZ: 8212,
        Zusatzziffer: 4,
        Gemeindename: 'Laufen-Uhwiesen',
        'BFS-Nr': 34,
        Kantonskürzel: 'ZH',
        E: 8.607844328824012,
        N: 47.66994437548598,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Uhwiesen',
        PLZ: 8248,
        Zusatzziffer: 0,
        Gemeindename: 'Laufen-Uhwiesen',
        'BFS-Nr': 34,
        Kantonskürzel: 'ZH',
        E: 8.634368664570138,
        N: 47.67107838986943,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Marthalen',
        PLZ: 8460,
        Zusatzziffer: 0,
        Gemeindename: 'Marthalen',
        'BFS-Nr': 35,
        Kantonskürzel: 'ZH',
        E: 8.64840876490265,
        N: 47.62520566237858,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Ellikon am Rhein',
        PLZ: 8464,
        Zusatzziffer: 0,
        Gemeindename: 'Marthalen',
        'BFS-Nr': 35,
        Kantonskürzel: 'ZH',
        E: 8.597405495833414,
        N: 47.605485574447805,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Ossingen',
        PLZ: 8475,
        Zusatzziffer: 0,
        Gemeindename: 'Ossingen',
        'BFS-Nr': 37,
        Kantonskürzel: 'ZH',
        E: 8.725764404647775,
        N: 47.610340091874704,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Rheinau',
        PLZ: 8462,
        Zusatzziffer: 0,
        Gemeindename: 'Rheinau',
        'BFS-Nr': 38,
        Kantonskürzel: 'ZH',
        E: 8.603384787478777,
        N: 47.64394415257786,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Thalheim an der Thur',
        PLZ: 8478,
        Zusatzziffer: 0,
        Gemeindename: 'Thalheim an der Thur',
        'BFS-Nr': 39,
        Kantonskürzel: 'ZH',
        E: 8.75433628100378,
        N: 47.57897255565752,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Rudolfingen',
        PLZ: 8465,
        Zusatzziffer: 0,
        Gemeindename: 'Trüllikon',
        'BFS-Nr': 40,
        Kantonskürzel: 'ZH',
        E: 8.673417184764507,
        N: 47.64036170030906,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Wildensbuch',
        PLZ: 8465,
        Zusatzziffer: 1,
        Gemeindename: 'Trüllikon',
        'BFS-Nr': 40,
        Kantonskürzel: 'ZH',
        E: 8.675901719829207,
        N: 47.65173147193734,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Trüllikon',
        PLZ: 8466,
        Zusatzziffer: 0,
        Gemeindename: 'Trüllikon',
        'BFS-Nr': 40,
        Kantonskürzel: 'ZH',
        E: 8.692247251999763,
        N: 47.6372693507532,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Truttikon',
        PLZ: 8467,
        Zusatzziffer: 0,
        Gemeindename: 'Truttikon',
        'BFS-Nr': 41,
        Kantonskürzel: 'ZH',
        E: 8.72651460572754,
        N: 47.6301286585794,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Volken',
        PLZ: 8459,
        Zusatzziffer: 0,
        Gemeindename: 'Volken',
        'BFS-Nr': 43,
        Kantonskürzel: 'ZH',
        E: 8.629002244065166,
        N: 47.57283883412277,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Bachenbülach',
        PLZ: 8184,
        Zusatzziffer: 0,
        Gemeindename: 'Bachenbülach',
        'BFS-Nr': 51,
        Kantonskürzel: 'ZH',
        E: 8.546430192606914,
        N: 47.50356920457575,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Bassersdorf',
        PLZ: 8303,
        Zusatzziffer: 0,
        Gemeindename: 'Bassersdorf',
        'BFS-Nr': 52,
        Kantonskürzel: 'ZH',
        E: 8.627838771811374,
        N: 47.44430845300167,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Bülach',
        PLZ: 8180,
        Zusatzziffer: 0,
        Gemeindename: 'Bülach',
        'BFS-Nr': 53,
        Kantonskürzel: 'ZH',
        E: 8.540863629475654,
        N: 47.51707100782142,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Dietlikon',
        PLZ: 8305,
        Zusatzziffer: 0,
        Gemeindename: 'Dietlikon',
        'BFS-Nr': 54,
        Kantonskürzel: 'ZH',
        E: 8.615781748254063,
        N: 47.42552862161528,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Eglisau',
        PLZ: 8193,
        Zusatzziffer: 0,
        Gemeindename: 'Eglisau',
        'BFS-Nr': 55,
        Kantonskürzel: 'ZH',
        E: 8.526037855083436,
        N: 47.57518002603192,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Embrach',
        PLZ: 8424,
        Zusatzziffer: 0,
        Gemeindename: 'Embrach',
        'BFS-Nr': 56,
        Kantonskürzel: 'ZH',
        E: 8.595217101828691,
        N: 47.5012284266776,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Freienstein',
        PLZ: 8427,
        Zusatzziffer: 2,
        Gemeindename: 'Freienstein-Teufen',
        'BFS-Nr': 57,
        Kantonskürzel: 'ZH',
        E: 8.590853915374247,
        N: 47.53261473528856,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Teufen ZH',
        PLZ: 8428,
        Zusatzziffer: 0,
        Gemeindename: 'Freienstein-Teufen',
        'BFS-Nr': 57,
        Kantonskürzel: 'ZH',
        E: 8.567384714588757,
        N: 47.5498909760702,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Glattfelden',
        PLZ: 8192,
        Zusatzziffer: 0,
        Gemeindename: 'Glattfelden',
        'BFS-Nr': 58,
        Kantonskürzel: 'ZH',
        E: 8.49894483007971,
        N: 47.55880970413219,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Zweidlen',
        PLZ: 8192,
        Zusatzziffer: 1,
        Gemeindename: 'Glattfelden',
        'BFS-Nr': 58,
        Kantonskürzel: 'ZH',
        E: 8.466662839321954,
        N: 47.56092348422757,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Hochfelden',
        PLZ: 8182,
        Zusatzziffer: 0,
        Gemeindename: 'Hochfelden',
        'BFS-Nr': 59,
        Kantonskürzel: 'ZH',
        E: 8.515470064458354,
        N: 47.52335843558774,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Höri',
        PLZ: 8181,
        Zusatzziffer: 0,
        Gemeindename: 'Höri',
        'BFS-Nr': 60,
        Kantonskürzel: 'ZH',
        E: 8.508440693226534,
        N: 47.50742392281879,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Hüntwangen',
        PLZ: 8194,
        Zusatzziffer: 0,
        Gemeindename: 'Hüntwangen',
        'BFS-Nr': 61,
        Kantonskürzel: 'ZH',
        E: 8.49291578573097,
        N: 47.59544946350886,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Kloten',
        PLZ: 8302,
        Zusatzziffer: 0,
        Gemeindename: 'Kloten',
        'BFS-Nr': 62,
        Kantonskürzel: 'ZH',
        E: 8.584370227219702,
        N: 47.452530526636714,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Lufingen',
        PLZ: 8426,
        Zusatzziffer: 0,
        Gemeindename: 'Lufingen',
        'BFS-Nr': 63,
        Kantonskürzel: 'ZH',
        E: 8.593667175905107,
        N: 47.490399981624726,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Nürensdorf',
        PLZ: 8309,
        Zusatzziffer: 0,
        Gemeindename: 'Nürensdorf',
        'BFS-Nr': 64,
        Kantonskürzel: 'ZH',
        E: 8.649206628955003,
        N: 47.447975906036774,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Oberembrach',
        PLZ: 8425,
        Zusatzziffer: 0,
        Gemeindename: 'Oberembrach',
        'BFS-Nr': 65,
        Kantonskürzel: 'ZH',
        E: 8.617427756375864,
        N: 47.487407227673486,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Glattbrugg',
        PLZ: 8152,
        Zusatzziffer: 0,
        Gemeindename: 'Opfikon',
        'BFS-Nr': 66,
        Kantonskürzel: 'ZH',
        E: 8.568188088895571,
        N: 47.433836275242115,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Opfikon',
        PLZ: 8152,
        Zusatzziffer: 1,
        Gemeindename: 'Opfikon',
        'BFS-Nr': 66,
        Kantonskürzel: 'ZH',
        E: 8.57742334234081,
        N: 47.43221267172676,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Glattpark (Opfikon)',
        PLZ: 8152,
        Zusatzziffer: 2,
        Gemeindename: 'Opfikon',
        'BFS-Nr': 66,
        Kantonskürzel: 'ZH',
        E: 8.561675338962793,
        N: 47.4232475038171,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Rafz',
        PLZ: 8197,
        Zusatzziffer: 0,
        Gemeindename: 'Rafz',
        'BFS-Nr': 67,
        Kantonskürzel: 'ZH',
        E: 8.534988509339827,
        N: 47.614389437993154,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Rorbas',
        PLZ: 8427,
        Zusatzziffer: 3,
        Gemeindename: 'Rorbas',
        'BFS-Nr': 68,
        Kantonskürzel: 'ZH',
        E: 8.578119172609911,
        N: 47.52897999594148,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Wallisellen',
        PLZ: 8304,
        Zusatzziffer: 0,
        Gemeindename: 'Wallisellen',
        'BFS-Nr': 69,
        Kantonskürzel: 'ZH',
        E: 8.591794513941402,
        N: 47.412676351994996,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Wasterkingen',
        PLZ: 8195,
        Zusatzziffer: 0,
        Gemeindename: 'Wasterkingen',
        'BFS-Nr': 70,
        Kantonskürzel: 'ZH',
        E: 8.472611941896382,
        N: 47.590126688509976,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Wil ZH',
        PLZ: 8196,
        Zusatzziffer: 0,
        Gemeindename: 'Wil (ZH)',
        'BFS-Nr': 71,
        Kantonskürzel: 'ZH',
        E: 8.503495532736599,
        N: 47.60268307307099,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Winkel',
        PLZ: 8185,
        Zusatzziffer: 0,
        Gemeindename: 'Winkel',
        'BFS-Nr': 72,
        Kantonskürzel: 'ZH',
        E: 8.554477337310221,
        N: 47.492500384616044,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Bachs',
        PLZ: 8164,
        Zusatzziffer: 0,
        Gemeindename: 'Bachs',
        'BFS-Nr': 81,
        Kantonskürzel: 'ZH',
        E: 8.439070029806587,
        N: 47.52505895659891,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Boppelsen',
        PLZ: 8113,
        Zusatzziffer: 0,
        Gemeindename: 'Boppelsen',
        'BFS-Nr': 82,
        Kantonskürzel: 'ZH',
        E: 8.402593613350334,
        N: 47.470534177598516,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Buchs ZH',
        PLZ: 8107,
        Zusatzziffer: 0,
        Gemeindename: 'Buchs (ZH)',
        'BFS-Nr': 83,
        Kantonskürzel: 'ZH',
        E: 8.43812539324188,
        N: 47.458811522221346,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Dällikon',
        PLZ: 8108,
        Zusatzziffer: 0,
        Gemeindename: 'Dällikon',
        'BFS-Nr': 84,
        Kantonskürzel: 'ZH',
        E: 8.439384093893743,
        N: 47.43993161052506,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Dänikon ZH',
        PLZ: 8114,
        Zusatzziffer: 0,
        Gemeindename: 'Dänikon',
        'BFS-Nr': 85,
        Kantonskürzel: 'ZH',
        E: 8.405405206690604,
        N: 47.44594314149815,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Dielsdorf',
        PLZ: 8157,
        Zusatzziffer: 0,
        Gemeindename: 'Dielsdorf',
        'BFS-Nr': 86,
        Kantonskürzel: 'ZH',
        E: 8.45355459043416,
        N: 47.48419186144986,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Hüttikon',
        PLZ: 8115,
        Zusatzziffer: 0,
        Gemeindename: 'Hüttikon',
        'BFS-Nr': 87,
        Kantonskürzel: 'ZH',
        E: 8.3871685039007,
        N: 47.44608841496912,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Neerach',
        PLZ: 8173,
        Zusatzziffer: 0,
        Gemeindename: 'Neerach',
        'BFS-Nr': 88,
        Kantonskürzel: 'ZH',
        E: 8.47452120272768,
        N: 47.513485772093894,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Niederglatt ZH',
        PLZ: 8172,
        Zusatzziffer: 0,
        Gemeindename: 'Niederglatt',
        'BFS-Nr': 89,
        Kantonskürzel: 'ZH',
        E: 8.503516038972542,
        N: 47.487463475564354,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Niederhasli',
        PLZ: 8155,
        Zusatzziffer: 0,
        Gemeindename: 'Niederhasli',
        'BFS-Nr': 90,
        Kantonskürzel: 'ZH',
        E: 8.483811515555885,
        N: 47.482324946178636,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Nassenwil',
        PLZ: 8155,
        Zusatzziffer: 1,
        Gemeindename: 'Niederhasli',
        'BFS-Nr': 90,
        Kantonskürzel: 'ZH',
        E: 8.471468627188537,
        N: 47.465762440146676,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Oberhasli',
        PLZ: 8156,
        Zusatzziffer: 0,
        Gemeindename: 'Niederhasli',
        'BFS-Nr': 90,
        Kantonskürzel: 'ZH',
        E: 8.49828249716288,
        N: 47.46541926244081,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Niederweningen',
        PLZ: 8166,
        Zusatzziffer: 0,
        Gemeindename: 'Niederweningen',
        'BFS-Nr': 91,
        Kantonskürzel: 'ZH',
        E: 8.368980277796924,
        N: 47.504141228597675,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Oberglatt ZH',
        PLZ: 8154,
        Zusatzziffer: 0,
        Gemeindename: 'Oberglatt',
        'BFS-Nr': 92,
        Kantonskürzel: 'ZH',
        E: 8.52072647965942,
        N: 47.47663140368921,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Oberweningen',
        PLZ: 8165,
        Zusatzziffer: 2,
        Gemeindename: 'Oberweningen',
        'BFS-Nr': 93,
        Kantonskürzel: 'ZH',
        E: 8.407361929364615,
        N: 47.503772608460736,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Otelfingen',
        PLZ: 8112,
        Zusatzziffer: 0,
        Gemeindename: 'Otelfingen',
        'BFS-Nr': 94,
        Kantonskürzel: 'ZH',
        E: 8.387127546944358,
        N: 47.46190585454648,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Regensberg',
        PLZ: 8158,
        Zusatzziffer: 0,
        Gemeindename: 'Regensberg',
        'BFS-Nr': 95,
        Kantonskürzel: 'ZH',
        E: 8.438811951768017,
        N: 47.48314969967727,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Regensdorf',
        PLZ: 8105,
        Zusatzziffer: 0,
        Gemeindename: 'Regensdorf',
        'BFS-Nr': 96,
        Kantonskürzel: 'ZH',
        E: 8.465587250433273,
        N: 47.4306851140246,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Watt',
        PLZ: 8105,
        Zusatzziffer: 2,
        Gemeindename: 'Regensdorf',
        'BFS-Nr': 96,
        Kantonskürzel: 'ZH',
        E: 8.481297209055368,
        N: 47.44281342534846,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Adlikon b. Regensdorf',
        PLZ: 8106,
        Zusatzziffer: 2,
        Gemeindename: 'Regensdorf',
        'BFS-Nr': 96,
        Kantonskürzel: 'ZH',
        E: 8.466498101820388,
        N: 47.4481389732845,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Rümlang',
        PLZ: 8153,
        Zusatzziffer: 0,
        Gemeindename: 'Rümlang',
        'BFS-Nr': 97,
        Kantonskürzel: 'ZH',
        E: 8.532700636182767,
        N: 47.45169256661303,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Schleinikon',
        PLZ: 8165,
        Zusatzziffer: 3,
        Gemeindename: 'Schleinikon',
        'BFS-Nr': 98,
        Kantonskürzel: 'ZH',
        E: 8.391506523940256,
        N: 47.49809258135476,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Schöfflisdorf',
        PLZ: 8165,
        Zusatzziffer: 0,
        Gemeindename: 'Schöfflisdorf',
        'BFS-Nr': 99,
        Kantonskürzel: 'ZH',
        E: 8.416881290226316,
        N: 47.50006328294066,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Stadel b. Niederglatt',
        PLZ: 8174,
        Zusatzziffer: 0,
        Gemeindename: 'Stadel',
        'BFS-Nr': 100,
        Kantonskürzel: 'ZH',
        E: 8.466077102463384,
        N: 47.52714340464087,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Windlach',
        PLZ: 8175,
        Zusatzziffer: 0,
        Gemeindename: 'Stadel',
        'BFS-Nr': 100,
        Kantonskürzel: 'ZH',
        E: 8.474969835465785,
        N: 47.54114992574625,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Steinmaur',
        PLZ: 8162,
        Zusatzziffer: 0,
        Gemeindename: 'Steinmaur',
        'BFS-Nr': 101,
        Kantonskürzel: 'ZH',
        E: 8.455669383904736,
        N: 47.499120934304756,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Sünikon',
        PLZ: 8162,
        Zusatzziffer: 1,
        Gemeindename: 'Steinmaur',
        'BFS-Nr': 101,
        Kantonskürzel: 'ZH',
        E: 8.435905014651176,
        N: 47.49070400012085,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Weiach',
        PLZ: 8187,
        Zusatzziffer: 0,
        Gemeindename: 'Weiach',
        'BFS-Nr': 102,
        Kantonskürzel: 'ZH',
        E: 8.437903123722482,
        N: 47.55762070831526,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Bäretswil',
        PLZ: 8344,
        Zusatzziffer: 0,
        Gemeindename: 'Bäretswil',
        'BFS-Nr': 111,
        Kantonskürzel: 'ZH',
        E: 8.85577846146996,
        N: 47.337535252949415,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Adetswil',
        PLZ: 8345,
        Zusatzziffer: 0,
        Gemeindename: 'Bäretswil',
        'BFS-Nr': 111,
        Kantonskürzel: 'ZH',
        E: 8.840409637136647,
        N: 47.340520553292485,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Bubikon',
        PLZ: 8608,
        Zusatzziffer: 0,
        Gemeindename: 'Bubikon',
        'BFS-Nr': 112,
        Kantonskürzel: 'ZH',
        E: 8.817225489727857,
        N: 47.26900932951344,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Wolfhausen',
        PLZ: 8633,
        Zusatzziffer: 0,
        Gemeindename: 'Bubikon',
        'BFS-Nr': 112,
        Kantonskürzel: 'ZH',
        E: 8.800087295613308,
        N: 47.25655807424269,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Tann',
        PLZ: 8632,
        Zusatzziffer: 0,
        Gemeindename: 'Dürnten',
        'BFS-Nr': 113,
        Kantonskürzel: 'ZH',
        E: 8.85155200860088,
        N: 47.263546593223005,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Dürnten',
        PLZ: 8635,
        Zusatzziffer: 0,
        Gemeindename: 'Dürnten',
        'BFS-Nr': 113,
        Kantonskürzel: 'ZH',
        E: 8.843185982524831,
        N: 47.278137372399314,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Steg im Tösstal',
        PLZ: 8496,
        Zusatzziffer: 0,
        Gemeindename: 'Fischenthal',
        'BFS-Nr': 114,
        Kantonskürzel: 'ZH',
        E: 8.932353997483498,
        N: 47.35391272673796,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Fischenthal',
        PLZ: 8497,
        Zusatzziffer: 0,
        Gemeindename: 'Fischenthal',
        'BFS-Nr': 114,
        Kantonskürzel: 'ZH',
        E: 8.919322585122904,
        N: 47.33147322605521,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Gibswil',
        PLZ: 8498,
        Zusatzziffer: 0,
        Gemeindename: 'Fischenthal',
        'BFS-Nr': 114,
        Kantonskürzel: 'ZH',
        E: 8.915288758150421,
        N: 47.313950095671444,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Bertschikon (Gossau ZH)',
        PLZ: 8614,
        Zusatzziffer: 0,
        Gemeindename: 'Gossau (ZH)',
        'BFS-Nr': 115,
        Kantonskürzel: 'ZH',
        E: 8.75728777491018,
        N: 47.3209529874555,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Grüt (Gossau ZH)',
        PLZ: 8624,
        Zusatzziffer: 0,
        Gemeindename: 'Gossau (ZH)',
        'BFS-Nr': 115,
        Kantonskürzel: 'ZH',
        E: 8.782010937840672,
        N: 47.311048338684344,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Gossau ZH',
        PLZ: 8625,
        Zusatzziffer: 0,
        Gemeindename: 'Gossau (ZH)',
        'BFS-Nr': 115,
        Kantonskürzel: 'ZH',
        E: 8.76270342930178,
        N: 47.30104484061906,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Ottikon (Gossau ZH)',
        PLZ: 8626,
        Zusatzziffer: 0,
        Gemeindename: 'Gossau (ZH)',
        'BFS-Nr': 115,
        Kantonskürzel: 'ZH',
        E: 8.779374208067315,
        N: 47.296489243055575,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Grüningen',
        PLZ: 8627,
        Zusatzziffer: 0,
        Gemeindename: 'Grüningen',
        'BFS-Nr': 116,
        Kantonskürzel: 'ZH',
        E: 8.761922878548353,
        N: 47.2847987797599,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Hinwil',
        PLZ: 8340,
        Zusatzziffer: 0,
        Gemeindename: 'Hinwil',
        'BFS-Nr': 117,
        Kantonskürzel: 'ZH',
        E: 8.847208610141404,
        N: 47.3011820716778,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Wernetshausen',
        PLZ: 8342,
        Zusatzziffer: 0,
        Gemeindename: 'Hinwil',
        'BFS-Nr': 117,
        Kantonskürzel: 'ZH',
        E: 8.866444416626077,
        N: 47.29897194749234,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Rüti ZH',
        PLZ: 8630,
        Zusatzziffer: 0,
        Gemeindename: 'Rüti (ZH)',
        'BFS-Nr': 118,
        Kantonskürzel: 'ZH',
        E: 8.849160286108347,
        N: 47.25910945865308,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Aathal-Seegräben',
        PLZ: 8607,
        Zusatzziffer: 0,
        Gemeindename: 'Seegräben',
        'BFS-Nr': 119,
        Kantonskürzel: 'ZH',
        E: 8.769625042807338,
        N: 47.33944687008346,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Wald ZH',
        PLZ: 8636,
        Zusatzziffer: 0,
        Gemeindename: 'Wald (ZH)',
        'BFS-Nr': 120,
        Kantonskürzel: 'ZH',
        E: 8.915950200012754,
        N: 47.27704723036556,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Laupen ZH',
        PLZ: 8637,
        Zusatzziffer: 0,
        Gemeindename: 'Wald (ZH)',
        'BFS-Nr': 120,
        Kantonskürzel: 'ZH',
        E: 8.928314296723697,
        N: 47.26510976912709,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Wetzikon ZH',
        PLZ: 8620,
        Zusatzziffer: 0,
        Gemeindename: 'Wetzikon (ZH)',
        'BFS-Nr': 121,
        Kantonskürzel: 'ZH',
        E: 8.806375156850532,
        N: 47.32238807191015,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Wetzikon ZH',
        PLZ: 8623,
        Zusatzziffer: 0,
        Gemeindename: 'Wetzikon (ZH)',
        'BFS-Nr': 121,
        Kantonskürzel: 'ZH',
        E: 8.821414806906569,
        N: 47.332170101253446,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Adliswil',
        PLZ: 8134,
        Zusatzziffer: 0,
        Gemeindename: 'Adliswil',
        'BFS-Nr': 131,
        Kantonskürzel: 'ZH',
        E: 8.522315303694374,
        N: 47.31284103738969,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Kilchberg ZH',
        PLZ: 8802,
        Zusatzziffer: 0,
        Gemeindename: 'Kilchberg (ZH)',
        'BFS-Nr': 135,
        Kantonskürzel: 'ZH',
        E: 8.542836827741464,
        N: 47.320417637200315,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Langnau am Albis',
        PLZ: 8135,
        Zusatzziffer: 0,
        Gemeindename: 'Langnau am Albis',
        'BFS-Nr': 136,
        Kantonskürzel: 'ZH',
        E: 8.534583285015563,
        N: 47.28824437116239,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Oberrieden',
        PLZ: 8942,
        Zusatzziffer: 0,
        Gemeindename: 'Oberrieden',
        'BFS-Nr': 137,
        Kantonskürzel: 'ZH',
        E: 8.576700382018855,
        N: 47.27701429822005,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Richterswil',
        PLZ: 8805,
        Zusatzziffer: 0,
        Gemeindename: 'Richterswil',
        'BFS-Nr': 138,
        Kantonskürzel: 'ZH',
        E: 8.700955877910815,
        N: 47.20826066969482,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Samstagern',
        PLZ: 8833,
        Zusatzziffer: 0,
        Gemeindename: 'Richterswil',
        'BFS-Nr': 138,
        Kantonskürzel: 'ZH',
        E: 8.678340155299011,
        N: 47.194584568231676,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Rüschlikon',
        PLZ: 8803,
        Zusatzziffer: 0,
        Gemeindename: 'Rüschlikon',
        'BFS-Nr': 139,
        Kantonskürzel: 'ZH',
        E: 8.554894204054305,
        N: 47.30932441735598,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Gattikon',
        PLZ: 8136,
        Zusatzziffer: 0,
        Gemeindename: 'Thalwil',
        'BFS-Nr': 141,
        Kantonskürzel: 'ZH',
        E: 8.550583931680448,
        N: 47.284620293043176,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Thalwil',
        PLZ: 8800,
        Zusatzziffer: 0,
        Gemeindename: 'Thalwil',
        'BFS-Nr': 141,
        Kantonskürzel: 'ZH',
        E: 8.569164155771151,
        N: 47.290223114366,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Erlenbach ZH',
        PLZ: 8703,
        Zusatzziffer: 0,
        Gemeindename: 'Erlenbach (ZH)',
        'BFS-Nr': 151,
        Kantonskürzel: 'ZH',
        E: 8.591594123388965,
        N: 47.30229682295557,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Herrliberg',
        PLZ: 8704,
        Zusatzziffer: 0,
        Gemeindename: 'Herrliberg',
        'BFS-Nr': 152,
        Kantonskürzel: 'ZH',
        E: 8.616883228825824,
        N: 47.285197157837146,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Hombrechtikon',
        PLZ: 8634,
        Zusatzziffer: 0,
        Gemeindename: 'Hombrechtikon',
        'BFS-Nr': 153,
        Kantonskürzel: 'ZH',
        E: 8.76387178360639,
        N: 47.25297519392692,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Feldbach',
        PLZ: 8714,
        Zusatzziffer: 0,
        Gemeindename: 'Hombrechtikon',
        'BFS-Nr': 153,
        Kantonskürzel: 'ZH',
        E: 8.782158936497565,
        N: 47.2389088992923,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Forch',
        PLZ: 8127,
        Zusatzziffer: 0,
        Gemeindename: 'Küsnacht (ZH)',
        'BFS-Nr': 154,
        Kantonskürzel: 'ZH',
        E: 8.64823740902385,
        N: 47.32539830775686,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Küsnacht ZH',
        PLZ: 8700,
        Zusatzziffer: 0,
        Gemeindename: 'Küsnacht (ZH)',
        'BFS-Nr': 154,
        Kantonskürzel: 'ZH',
        E: 8.583538342756214,
        N: 47.316872154349795,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Männedorf',
        PLZ: 8708,
        Zusatzziffer: 0,
        Gemeindename: 'Männedorf',
        'BFS-Nr': 155,
        Kantonskürzel: 'ZH',
        E: 8.69382754216855,
        N: 47.25469791517197,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Meilen',
        PLZ: 8706,
        Zusatzziffer: 0,
        Gemeindename: 'Meilen',
        'BFS-Nr': 156,
        Kantonskürzel: 'ZH',
        E: 8.639900367478418,
        N: 47.26809074802968,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Oetwil am See',
        PLZ: 8618,
        Zusatzziffer: 0,
        Gemeindename: 'Oetwil am See',
        'BFS-Nr': 157,
        Kantonskürzel: 'ZH',
        E: 8.723220837941811,
        N: 47.27018727627144,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Stäfa',
        PLZ: 8712,
        Zusatzziffer: 0,
        Gemeindename: 'Stäfa',
        'BFS-Nr': 158,
        Kantonskürzel: 'ZH',
        E: 8.731044344447753,
        N: 47.23895073268293,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Uerikon',
        PLZ: 8713,
        Zusatzziffer: 0,
        Gemeindename: 'Stäfa',
        'BFS-Nr': 158,
        Kantonskürzel: 'ZH',
        E: 8.756748551921905,
        N: 47.234569897388816,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Uetikon am See',
        PLZ: 8707,
        Zusatzziffer: 0,
        Gemeindename: 'Uetikon am See',
        'BFS-Nr': 159,
        Kantonskürzel: 'ZH',
        E: 8.676921463896058,
        N: 47.26182409806981,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Zumikon',
        PLZ: 8126,
        Zusatzziffer: 0,
        Gemeindename: 'Zumikon',
        'BFS-Nr': 160,
        Kantonskürzel: 'ZH',
        E: 8.623277907915842,
        N: 47.33140333459929,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Zollikerberg',
        PLZ: 8125,
        Zusatzziffer: 0,
        Gemeindename: 'Zollikon',
        'BFS-Nr': 161,
        Kantonskürzel: 'ZH',
        E: 8.602261647411273,
        N: 47.34800675033366,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Zollikon',
        PLZ: 8702,
        Zusatzziffer: 0,
        Gemeindename: 'Zollikon',
        'BFS-Nr': 161,
        Kantonskürzel: 'ZH',
        E: 8.574179370651436,
        N: 47.33827490362902,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Fehraltorf',
        PLZ: 8320,
        Zusatzziffer: 0,
        Gemeindename: 'Fehraltorf',
        'BFS-Nr': 172,
        Kantonskürzel: 'ZH',
        E: 8.75227696885112,
        N: 47.38712798665247,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Hittnau',
        PLZ: 8335,
        Zusatzziffer: 0,
        Gemeindename: 'Hittnau',
        'BFS-Nr': 173,
        Kantonskürzel: 'ZH',
        E: 8.824101584206876,
        N: 47.36092687982071,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Kemptthal',
        PLZ: 8310,
        Zusatzziffer: 0,
        Gemeindename: 'Lindau',
        'BFS-Nr': 176,
        Kantonskürzel: 'ZH',
        E: 8.705688049683063,
        N: 47.452811736020536,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Grafstal',
        PLZ: 8310,
        Zusatzziffer: 1,
        Gemeindename: 'Lindau',
        'BFS-Nr': 176,
        Kantonskürzel: 'ZH',
        E: 8.698216790994378,
        N: 47.443870293831154,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Winterberg ZH',
        PLZ: 8312,
        Zusatzziffer: 0,
        Gemeindename: 'Lindau',
        'BFS-Nr': 176,
        Kantonskürzel: 'ZH',
        E: 8.692087063972052,
        N: 47.45655292869648,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Lindau',
        PLZ: 8315,
        Zusatzziffer: 0,
        Gemeindename: 'Lindau',
        'BFS-Nr': 176,
        Kantonskürzel: 'ZH',
        E: 8.670647759883606,
        N: 47.441863068421256,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Tagelswangen',
        PLZ: 8317,
        Zusatzziffer: 0,
        Gemeindename: 'Lindau',
        'BFS-Nr': 176,
        Kantonskürzel: 'ZH',
        E: 8.671844628020898,
        N: 47.430415652277375,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Pfäffikon ZH',
        PLZ: 8330,
        Zusatzziffer: 0,
        Gemeindename: 'Pfäffikon',
        'BFS-Nr': 177,
        Kantonskürzel: 'ZH',
        E: 8.781101980256928,
        N: 47.36522615309005,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Auslikon',
        PLZ: 8331,
        Zusatzziffer: 0,
        Gemeindename: 'Pfäffikon',
        'BFS-Nr': 177,
        Kantonskürzel: 'ZH',
        E: 8.80690557183696,
        N: 47.34550487891338,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Madetswil',
        PLZ: 8322,
        Zusatzziffer: 0,
        Gemeindename: 'Russikon',
        'BFS-Nr': 178,
        Kantonskürzel: 'ZH',
        E: 8.79143965833676,
        N: 47.41016807960352,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Gündisau',
        PLZ: 8322,
        Zusatzziffer: 2,
        Gemeindename: 'Russikon',
        'BFS-Nr': 178,
        Kantonskürzel: 'ZH',
        E: 8.807446348083905,
        N: 47.398020426465074,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Russikon',
        PLZ: 8332,
        Zusatzziffer: 0,
        Gemeindename: 'Russikon',
        'BFS-Nr': 178,
        Kantonskürzel: 'ZH',
        E: 8.773790713651897,
        N: 47.39620841027907,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Rumlikon',
        PLZ: 8332,
        Zusatzziffer: 2,
        Gemeindename: 'Russikon',
        'BFS-Nr': 178,
        Kantonskürzel: 'ZH',
        E: 8.759724099039255,
        N: 47.40929689942004,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Weisslingen',
        PLZ: 8484,
        Zusatzziffer: 0,
        Gemeindename: 'Weisslingen',
        'BFS-Nr': 180,
        Kantonskürzel: 'ZH',
        E: 8.765125611641263,
        N: 47.4334349741883,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Neschwil',
        PLZ: 8484,
        Zusatzziffer: 2,
        Gemeindename: 'Weisslingen',
        'BFS-Nr': 180,
        Kantonskürzel: 'ZH',
        E: 8.788230611124362,
        N: 47.43020826173229,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Theilingen',
        PLZ: 8484,
        Zusatzziffer: 3,
        Gemeindename: 'Weisslingen',
        'BFS-Nr': 180,
        Kantonskürzel: 'ZH',
        E: 8.767776956877528,
        N: 47.421110681187756,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Wila',
        PLZ: 8492,
        Zusatzziffer: 0,
        Gemeindename: 'Wila',
        'BFS-Nr': 181,
        Kantonskürzel: 'ZH',
        E: 8.845832655779686,
        N: 47.41941565311436,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Wildberg',
        PLZ: 8489,
        Zusatzziffer: 0,
        Gemeindename: 'Wildberg',
        'BFS-Nr': 182,
        Kantonskürzel: 'ZH',
        E: 8.816681798366814,
        N: 47.42751412366287,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Schalchen',
        PLZ: 8489,
        Zusatzziffer: 1,
        Gemeindename: 'Wildberg',
        'BFS-Nr': 182,
        Kantonskürzel: 'ZH',
        E: 8.826216050019134,
        N: 47.40692316327332,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Ehrikon',
        PLZ: 8489,
        Zusatzziffer: 2,
        Gemeindename: 'Wildberg',
        'BFS-Nr': 182,
        Kantonskürzel: 'ZH',
        E: 8.809024888476626,
        N: 47.418948372006916,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Gockhausen',
        PLZ: 8044,
        Zusatzziffer: 1,
        Gemeindename: 'Dübendorf',
        'BFS-Nr': 191,
        Kantonskürzel: 'ZH',
        E: 8.602475950574982,
        N: 47.38333585442108,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Dübendorf',
        PLZ: 8600,
        Zusatzziffer: 0,
        Gemeindename: 'Dübendorf',
        'BFS-Nr': 191,
        Kantonskürzel: 'ZH',
        E: 8.618052062776723,
        N: 47.395080263298325,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Egg b. Zürich',
        PLZ: 8132,
        Zusatzziffer: 0,
        Gemeindename: 'Egg',
        'BFS-Nr': 192,
        Kantonskürzel: 'ZH',
        E: 8.691447150962007,
        N: 47.30065417811812,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Hinteregg',
        PLZ: 8132,
        Zusatzziffer: 2,
        Gemeindename: 'Egg',
        'BFS-Nr': 192,
        Kantonskürzel: 'ZH',
        E: 8.684243433749401,
        N: 47.30594153115987,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Esslingen',
        PLZ: 8133,
        Zusatzziffer: 0,
        Gemeindename: 'Egg',
        'BFS-Nr': 192,
        Kantonskürzel: 'ZH',
        E: 8.709645800528286,
        N: 47.28835517148579,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Fällanden',
        PLZ: 8117,
        Zusatzziffer: 0,
        Gemeindename: 'Fällanden',
        'BFS-Nr': 193,
        Kantonskürzel: 'ZH',
        E: 8.638358449481473,
        N: 47.370528890884025,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Pfaffhausen',
        PLZ: 8118,
        Zusatzziffer: 0,
        Gemeindename: 'Fällanden',
        'BFS-Nr': 193,
        Kantonskürzel: 'ZH',
        E: 8.623247129419298,
        N: 47.365736958432386,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Benglen',
        PLZ: 8121,
        Zusatzziffer: 0,
        Gemeindename: 'Fällanden',
        'BFS-Nr': 193,
        Kantonskürzel: 'ZH',
        E: 8.637323992136501,
        N: 47.36117322895199,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Greifensee',
        PLZ: 8606,
        Zusatzziffer: 2,
        Gemeindename: 'Greifensee',
        'BFS-Nr': 194,
        Kantonskürzel: 'ZH',
        E: 8.676980114106447,
        N: 47.364984868555304,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Binz',
        PLZ: 8122,
        Zusatzziffer: 0,
        Gemeindename: 'Maur',
        'BFS-Nr': 195,
        Kantonskürzel: 'ZH',
        E: 8.627067326055073,
        N: 47.3567446276112,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Ebmatingen',
        PLZ: 8123,
        Zusatzziffer: 0,
        Gemeindename: 'Maur',
        'BFS-Nr': 195,
        Kantonskürzel: 'ZH',
        E: 8.63997210016202,
        N: 47.35147588793316,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Maur',
        PLZ: 8124,
        Zusatzziffer: 0,
        Gemeindename: 'Maur',
        'BFS-Nr': 195,
        Kantonskürzel: 'ZH',
        E: 8.672844678428788,
        N: 47.337809961101,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Mönchaltorf',
        PLZ: 8617,
        Zusatzziffer: 0,
        Gemeindename: 'Mönchaltorf',
        'BFS-Nr': 196,
        Kantonskürzel: 'ZH',
        E: 8.72197517883632,
        N: 47.31015613385332,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Schwerzenbach',
        PLZ: 8603,
        Zusatzziffer: 0,
        Gemeindename: 'Schwerzenbach',
        'BFS-Nr': 197,
        Kantonskürzel: 'ZH',
        E: 8.652131602888902,
        N: 47.37979451346095,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Nänikon',
        PLZ: 8606,
        Zusatzziffer: 0,
        Gemeindename: 'Uster',
        'BFS-Nr': 198,
        Kantonskürzel: 'ZH',
        E: 8.693385382769616,
        N: 47.37018201150075,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Uster',
        PLZ: 8610,
        Zusatzziffer: 0,
        Gemeindename: 'Uster',
        'BFS-Nr': 198,
        Kantonskürzel: 'ZH',
        E: 8.71690082510045,
        N: 47.34585310298203,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Sulzbach',
        PLZ: 8614,
        Zusatzziffer: 2,
        Gemeindename: 'Uster',
        'BFS-Nr': 198,
        Kantonskürzel: 'ZH',
        E: 8.746715064556431,
        N: 47.32896105660855,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Wermatswil',
        PLZ: 8615,
        Zusatzziffer: 0,
        Gemeindename: 'Uster',
        'BFS-Nr': 198,
        Kantonskürzel: 'ZH',
        E: 8.74593371079058,
        N: 47.36210523941859,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Freudwil',
        PLZ: 8615,
        Zusatzziffer: 2,
        Gemeindename: 'Uster',
        'BFS-Nr': 198,
        Kantonskürzel: 'ZH',
        E: 8.734096629052276,
        N: 47.374737002123815,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Riedikon',
        PLZ: 8616,
        Zusatzziffer: 0,
        Gemeindename: 'Uster',
        'BFS-Nr': 198,
        Kantonskürzel: 'ZH',
        E: 8.711528149578221,
        N: 47.33252680574925,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Volketswil',
        PLZ: 8604,
        Zusatzziffer: 0,
        Gemeindename: 'Volketswil',
        'BFS-Nr': 199,
        Kantonskürzel: 'ZH',
        E: 8.69142247090143,
        N: 47.38905499772681,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Gutenswil',
        PLZ: 8605,
        Zusatzziffer: 0,
        Gemeindename: 'Volketswil',
        'BFS-Nr': 199,
        Kantonskürzel: 'ZH',
        E: 8.717984059779962,
        N: 47.385145593345484,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Brüttisellen',
        PLZ: 8306,
        Zusatzziffer: 0,
        Gemeindename: 'Wangen-Brüttisellen',
        'BFS-Nr': 200,
        Kantonskürzel: 'ZH',
        E: 8.629741540885098,
        N: 47.42322240129563,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Wangen b. Dübendorf',
        PLZ: 8602,
        Zusatzziffer: 0,
        Gemeindename: 'Wangen-Brüttisellen',
        'BFS-Nr': 200,
        Kantonskürzel: 'ZH',
        E: 8.646458325200049,
        N: 47.40946288435608,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Altikon',
        PLZ: 8479,
        Zusatzziffer: 0,
        Gemeindename: 'Altikon',
        'BFS-Nr': 211,
        Kantonskürzel: 'ZH',
        E: 8.78450488733637,
        N: 47.57524000380281,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Brütten',
        PLZ: 8311,
        Zusatzziffer: 0,
        Gemeindename: 'Brütten',
        'BFS-Nr': 213,
        Kantonskürzel: 'ZH',
        E: 8.674222243891652,
        N: 47.47408979183086,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Rutschwil (Dägerlen)',
        PLZ: 8471,
        Zusatzziffer: 0,
        Gemeindename: 'Dägerlen',
        'BFS-Nr': 214,
        Kantonskürzel: 'ZH',
        E: 8.730225104223566,
        N: 47.556532928914876,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Dägerlen',
        PLZ: 8471,
        Zusatzziffer: 2,
        Gemeindename: 'Dägerlen',
        'BFS-Nr': 214,
        Kantonskürzel: 'ZH',
        E: 8.722114711070226,
        N: 47.56089640269968,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Oberwil (Dägerlen)',
        PLZ: 8471,
        Zusatzziffer: 3,
        Gemeindename: 'Dägerlen',
        'BFS-Nr': 214,
        Kantonskürzel: 'ZH',
        E: 8.716621985023135,
        N: 47.57259007845758,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Berg (Dägerlen)',
        PLZ: 8471,
        Zusatzziffer: 4,
        Gemeindename: 'Dägerlen',
        'BFS-Nr': 214,
        Kantonskürzel: 'ZH',
        E: 8.733175041147192,
        N: 47.5637187541533,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Bänk (Dägerlen)',
        PLZ: 8471,
        Zusatzziffer: 5,
        Gemeindename: 'Dägerlen',
        'BFS-Nr': 214,
        Kantonskürzel: 'ZH',
        E: 8.739209536742981,
        N: 47.54837646134214,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Dättlikon',
        PLZ: 8421,
        Zusatzziffer: 0,
        Gemeindename: 'Dättlikon',
        'BFS-Nr': 215,
        Kantonskürzel: 'ZH',
        E: 8.62416872473886,
        N: 47.524882791078014,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Dinhard',
        PLZ: 8474,
        Zusatzziffer: 0,
        Gemeindename: 'Dinhard',
        'BFS-Nr': 216,
        Kantonskürzel: 'ZH',
        E: 8.768290555789141,
        N: 47.5552042607987,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Ellikon an der Thur',
        PLZ: 8548,
        Zusatzziffer: 0,
        Gemeindename: 'Ellikon an der Thur',
        'BFS-Nr': 218,
        Kantonskürzel: 'ZH',
        E: 8.825412166209187,
        N: 47.562016744581875,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Elsau',
        PLZ: 8352,
        Zusatzziffer: 0,
        Gemeindename: 'Elsau',
        'BFS-Nr': 219,
        Kantonskürzel: 'ZH',
        E: 8.802517903216344,
        N: 47.50615367134981,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Hagenbuch ZH',
        PLZ: 8523,
        Zusatzziffer: 0,
        Gemeindename: 'Hagenbuch',
        'BFS-Nr': 220,
        Kantonskürzel: 'ZH',
        E: 8.88783517467721,
        N: 47.52101673163975,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Hettlingen',
        PLZ: 8442,
        Zusatzziffer: 0,
        Gemeindename: 'Hettlingen',
        'BFS-Nr': 221,
        Kantonskürzel: 'ZH',
        E: 8.708118813703202,
        N: 47.54838177671516,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Aesch (Neftenbach)',
        PLZ: 8412,
        Zusatzziffer: 0,
        Gemeindename: 'Neftenbach',
        'BFS-Nr': 223,
        Kantonskürzel: 'ZH',
        E: 8.6793652943283,
        N: 47.54127515856092,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Riet (Neftenbach)',
        PLZ: 8412,
        Zusatzziffer: 1,
        Gemeindename: 'Neftenbach',
        'BFS-Nr': 223,
        Kantonskürzel: 'ZH',
        E: 8.686827029984409,
        N: 47.53646034883262,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Hünikon (Neftenbach)',
        PLZ: 8412,
        Zusatzziffer: 2,
        Gemeindename: 'Neftenbach',
        'BFS-Nr': 223,
        Kantonskürzel: 'ZH',
        E: 8.668214291144237,
        N: 47.552143104962866,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Neftenbach',
        PLZ: 8413,
        Zusatzziffer: 0,
        Gemeindename: 'Neftenbach',
        'BFS-Nr': 223,
        Kantonskürzel: 'ZH',
        E: 8.667824601889858,
        N: 47.52855893458482,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Pfungen',
        PLZ: 8422,
        Zusatzziffer: 0,
        Gemeindename: 'Pfungen',
        'BFS-Nr': 224,
        Kantonskürzel: 'ZH',
        E: 8.64653645848558,
        N: 47.51587827090937,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Rickenbach ZH',
        PLZ: 8545,
        Zusatzziffer: 0,
        Gemeindename: 'Rickenbach (ZH)',
        'BFS-Nr': 225,
        Kantonskürzel: 'ZH',
        E: 8.793893839390265,
        N: 47.551447169544076,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Rickenbach Sulz',
        PLZ: 8545,
        Zusatzziffer: 1,
        Gemeindename: 'Rickenbach (ZH)',
        'BFS-Nr': 225,
        Kantonskürzel: 'ZH',
        E: 8.787877754614444,
        N: 47.540696871189816,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Schlatt ZH',
        PLZ: 8418,
        Zusatzziffer: 0,
        Gemeindename: 'Schlatt (ZH)',
        'BFS-Nr': 226,
        Kantonskürzel: 'ZH',
        E: 8.825961740283878,
        N: 47.46795475268602,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Seuzach',
        PLZ: 8472,
        Zusatzziffer: 0,
        Gemeindename: 'Seuzach',
        'BFS-Nr': 227,
        Kantonskürzel: 'ZH',
        E: 8.73836762918562,
        N: 47.536234488745166,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Turbenthal',
        PLZ: 8488,
        Zusatzziffer: 0,
        Gemeindename: 'Turbenthal',
        'BFS-Nr': 228,
        Kantonskürzel: 'ZH',
        E: 8.847388690836128,
        N: 47.43516269158667,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Schmidrüti',
        PLZ: 8495,
        Zusatzziffer: 0,
        Gemeindename: 'Turbenthal',
        'BFS-Nr': 228,
        Kantonskürzel: 'ZH',
        E: 8.903416307294572,
        N: 47.41481312792848,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Ricketwil (Winterthur)',
        PLZ: 8352,
        Zusatzziffer: 2,
        Gemeindename: 'Winterthur',
        'BFS-Nr': 230,
        Kantonskürzel: 'ZH',
        E: 8.80093912350213,
        N: 47.485933090208604,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Winterthur',
        PLZ: 8400,
        Zusatzziffer: 0,
        Gemeindename: 'Winterthur',
        'BFS-Nr': 230,
        Kantonskürzel: 'ZH',
        E: 8.72954884269744,
        N: 47.491621873326025,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Winterthur',
        PLZ: 8404,
        Zusatzziffer: 0,
        Gemeindename: 'Winterthur',
        'BFS-Nr': 230,
        Kantonskürzel: 'ZH',
        E: 8.759223002794496,
        N: 47.507475123256896,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Reutlingen (Winterthur)',
        PLZ: 8404,
        Zusatzziffer: 2,
        Gemeindename: 'Winterthur',
        'BFS-Nr': 230,
        Kantonskürzel: 'ZH',
        E: 8.751333851221453,
        N: 47.527952542932496,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Stadel (Winterthur)',
        PLZ: 8404,
        Zusatzziffer: 3,
        Gemeindename: 'Winterthur',
        'BFS-Nr': 230,
        Kantonskürzel: 'ZH',
        E: 8.761468968858043,
        N: 47.53454503911586,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Winterthur',
        PLZ: 8405,
        Zusatzziffer: 0,
        Gemeindename: 'Winterthur',
        'BFS-Nr': 230,
        Kantonskürzel: 'ZH',
        E: 8.778269585505283,
        N: 47.47754949977949,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Winterthur',
        PLZ: 8406,
        Zusatzziffer: 0,
        Gemeindename: 'Winterthur',
        'BFS-Nr': 230,
        Kantonskürzel: 'ZH',
        E: 8.700625776013917,
        N: 47.48322456758198,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Winterthur',
        PLZ: 8408,
        Zusatzziffer: 0,
        Gemeindename: 'Winterthur',
        'BFS-Nr': 230,
        Kantonskürzel: 'ZH',
        E: 8.68334220971565,
        N: 47.50773949910317,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Winterthur',
        PLZ: 8409,
        Zusatzziffer: 0,
        Gemeindename: 'Winterthur',
        'BFS-Nr': 230,
        Kantonskürzel: 'ZH',
        E: 8.781455850549886,
        N: 47.509825107380685,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Sennhof (Winterthur)',
        PLZ: 8482,
        Zusatzziffer: 0,
        Gemeindename: 'Winterthur',
        'BFS-Nr': 230,
        Kantonskürzel: 'ZH',
        E: 8.758021929741268,
        N: 47.469166026715044,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Kollbrunn',
        PLZ: 8483,
        Zusatzziffer: 0,
        Gemeindename: 'Zell (ZH)',
        'BFS-Nr': 231,
        Kantonskürzel: 'ZH',
        E: 8.7766254729831,
        N: 47.457296669798275,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Rikon im Tösstal',
        PLZ: 8486,
        Zusatzziffer: 0,
        Gemeindename: 'Zell (ZH)',
        'BFS-Nr': 231,
        Kantonskürzel: 'ZH',
        E: 8.796773363512141,
        N: 47.44472450528816,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Zell ZH',
        PLZ: 8487,
        Zusatzziffer: 0,
        Gemeindename: 'Zell (ZH)',
        'BFS-Nr': 231,
        Kantonskürzel: 'ZH',
        E: 8.822697588686555,
        N: 47.44852473679616,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Rämismühle',
        PLZ: 8487,
        Zusatzziffer: 1,
        Gemeindename: 'Zell (ZH)',
        'BFS-Nr': 231,
        Kantonskürzel: 'ZH',
        E: 8.81908237236302,
        N: 47.441573025965724,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Aesch ZH',
        PLZ: 8904,
        Zusatzziffer: 0,
        Gemeindename: 'Aesch (ZH)',
        'BFS-Nr': 241,
        Kantonskürzel: 'ZH',
        E: 8.438240182749135,
        N: 47.33735726486113,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Birmensdorf ZH',
        PLZ: 8903,
        Zusatzziffer: 0,
        Gemeindename: 'Birmensdorf (ZH)',
        'BFS-Nr': 242,
        Kantonskürzel: 'ZH',
        E: 8.441176919517936,
        N: 47.351665237544545,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Dietikon',
        PLZ: 8953,
        Zusatzziffer: 0,
        Gemeindename: 'Dietikon',
        'BFS-Nr': 243,
        Kantonskürzel: 'ZH',
        E: 8.403729396195992,
        N: 47.40491419685633,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Fahrweid',
        PLZ: 8951,
        Zusatzziffer: 0,
        Gemeindename: 'Geroldswil',
        'BFS-Nr': 244,
        Kantonskürzel: 'ZH',
        E: 8.415493748599095,
        N: 47.41041842749119,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Geroldswil',
        PLZ: 8954,
        Zusatzziffer: 0,
        Gemeindename: 'Geroldswil',
        'BFS-Nr': 244,
        Kantonskürzel: 'ZH',
        E: 8.410376203627484,
        N: 47.42243509640037,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Oberengstringen',
        PLZ: 8102,
        Zusatzziffer: 0,
        Gemeindename: 'Oberengstringen',
        'BFS-Nr': 245,
        Kantonskürzel: 'ZH',
        E: 8.462359599999985,
        N: 47.40837400096172,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Oetwil an der Limmat',
        PLZ: 8955,
        Zusatzziffer: 0,
        Gemeindename: 'Oetwil an der Limmat',
        'BFS-Nr': 246,
        Kantonskürzel: 'ZH',
        E: 8.39499526978769,
        N: 47.42815598135042,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Schlieren',
        PLZ: 8952,
        Zusatzziffer: 0,
        Gemeindename: 'Schlieren',
        'BFS-Nr': 247,
        Kantonskürzel: 'ZH',
        E: 8.445788972494483,
        N: 47.3966049051377,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Uitikon Waldegg',
        PLZ: 8142,
        Zusatzziffer: 0,
        Gemeindename: 'Uitikon',
        'BFS-Nr': 248,
        Kantonskürzel: 'ZH',
        E: 8.453979400763368,
        N: 47.370851622742705,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Unterengstringen',
        PLZ: 8103,
        Zusatzziffer: 0,
        Gemeindename: 'Unterengstringen',
        'BFS-Nr': 249,
        Kantonskürzel: 'ZH',
        E: 8.449449188670052,
        N: 47.41332598368551,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Urdorf',
        PLZ: 8902,
        Zusatzziffer: 0,
        Gemeindename: 'Urdorf',
        'BFS-Nr': 250,
        Kantonskürzel: 'ZH',
        E: 8.423755265480839,
        N: 47.38266536996476,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Weiningen ZH',
        PLZ: 8104,
        Zusatzziffer: 0,
        Gemeindename: 'Weiningen (ZH)',
        'BFS-Nr': 251,
        Kantonskürzel: 'ZH',
        E: 8.4359841298133,
        N: 47.420631852634735,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Zürich',
        PLZ: 8001,
        Zusatzziffer: 0,
        Gemeindename: 'Zürich',
        'BFS-Nr': 261,
        Kantonskürzel: 'ZH',
        E: 8.541349296997435,
        N: 47.372048532664216,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Zürich',
        PLZ: 8002,
        Zusatzziffer: 0,
        Gemeindename: 'Zürich',
        'BFS-Nr': 261,
        Kantonskürzel: 'ZH',
        E: 8.532489659450409,
        N: 47.360584104497654,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Zürich',
        PLZ: 8003,
        Zusatzziffer: 0,
        Gemeindename: 'Zürich',
        'BFS-Nr': 261,
        Kantonskürzel: 'ZH',
        E: 8.516142107913552,
        N: 47.372578569421265,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Zürich',
        PLZ: 8004,
        Zusatzziffer: 0,
        Gemeindename: 'Zürich',
        'BFS-Nr': 261,
        Kantonskürzel: 'ZH',
        E: 8.52337017333728,
        N: 47.37800217265169,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Zürich',
        PLZ: 8005,
        Zusatzziffer: 0,
        Gemeindename: 'Zürich',
        'BFS-Nr': 261,
        Kantonskürzel: 'ZH',
        E: 8.520684105099669,
        N: 47.38676633563334,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Zürich',
        PLZ: 8006,
        Zusatzziffer: 0,
        Gemeindename: 'Zürich',
        'BFS-Nr': 261,
        Kantonskürzel: 'ZH',
        E: 8.543174739428517,
        N: 47.385765673187315,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Zürich',
        PLZ: 8008,
        Zusatzziffer: 0,
        Gemeindename: 'Zürich',
        'BFS-Nr': 261,
        Kantonskürzel: 'ZH',
        E: 8.561384559723898,
        N: 47.353640047881825,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Zürich',
        PLZ: 8032,
        Zusatzziffer: 0,
        Gemeindename: 'Zürich',
        'BFS-Nr': 261,
        Kantonskürzel: 'ZH',
        E: 8.56443872630102,
        N: 47.36668879694642,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Zürich',
        PLZ: 8037,
        Zusatzziffer: 0,
        Gemeindename: 'Zürich',
        'BFS-Nr': 261,
        Kantonskürzel: 'ZH',
        E: 8.524261806663304,
        N: 47.39849490685259,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Zürich',
        PLZ: 8038,
        Zusatzziffer: 0,
        Gemeindename: 'Zürich',
        'BFS-Nr': 261,
        Kantonskürzel: 'ZH',
        E: 8.536904755366834,
        N: 47.34157429220142,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Zürich',
        PLZ: 8041,
        Zusatzziffer: 0,
        Gemeindename: 'Zürich',
        'BFS-Nr': 261,
        Kantonskürzel: 'ZH',
        E: 8.51566433067803,
        N: 47.33605960507216,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Zürich',
        PLZ: 8044,
        Zusatzziffer: 0,
        Gemeindename: 'Zürich',
        'BFS-Nr': 261,
        Kantonskürzel: 'ZH',
        E: 8.57283034214897,
        N: 47.38041488093525,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Zürich',
        PLZ: 8045,
        Zusatzziffer: 0,
        Gemeindename: 'Zürich',
        'BFS-Nr': 261,
        Kantonskürzel: 'ZH',
        E: 8.50570809616007,
        N: 47.35161517520839,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Zürich',
        PLZ: 8046,
        Zusatzziffer: 0,
        Gemeindename: 'Zürich',
        'BFS-Nr': 261,
        Kantonskürzel: 'ZH',
        E: 8.508278676048434,
        N: 47.422177381382056,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Zürich',
        PLZ: 8047,
        Zusatzziffer: 0,
        Gemeindename: 'Zürich',
        'BFS-Nr': 261,
        Kantonskürzel: 'ZH',
        E: 8.487021836354158,
        N: 47.37389233190548,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Zürich',
        PLZ: 8048,
        Zusatzziffer: 0,
        Gemeindename: 'Zürich',
        'BFS-Nr': 261,
        Kantonskürzel: 'ZH',
        E: 8.483871092066265,
        N: 47.38674684601843,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Zürich',
        PLZ: 8049,
        Zusatzziffer: 0,
        Gemeindename: 'Zürich',
        'BFS-Nr': 261,
        Kantonskürzel: 'ZH',
        E: 8.500466748261303,
        N: 47.40869838981309,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Zürich',
        PLZ: 8050,
        Zusatzziffer: 0,
        Gemeindename: 'Zürich',
        'BFS-Nr': 261,
        Kantonskürzel: 'ZH',
        E: 8.550802019058356,
        N: 47.41193801124004,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Zürich',
        PLZ: 8051,
        Zusatzziffer: 0,
        Gemeindename: 'Zürich',
        'BFS-Nr': 261,
        Kantonskürzel: 'ZH',
        E: 8.57881930913423,
        N: 47.39965101487254,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Zürich',
        PLZ: 8052,
        Zusatzziffer: 0,
        Gemeindename: 'Zürich',
        'BFS-Nr': 261,
        Kantonskürzel: 'ZH',
        E: 8.540643194356203,
        N: 47.424464140513315,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Zürich',
        PLZ: 8053,
        Zusatzziffer: 0,
        Gemeindename: 'Zürich',
        'BFS-Nr': 261,
        Kantonskürzel: 'ZH',
        E: 8.597418711716397,
        N: 47.36128969682945,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Zürich',
        PLZ: 8055,
        Zusatzziffer: 0,
        Gemeindename: 'Zürich',
        'BFS-Nr': 261,
        Kantonskürzel: 'ZH',
        E: 8.49029204173726,
        N: 47.364188803441294,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Zürich',
        PLZ: 8057,
        Zusatzziffer: 0,
        Gemeindename: 'Zürich',
        'BFS-Nr': 261,
        Kantonskürzel: 'ZH',
        E: 8.54739260758107,
        N: 47.39992584088796,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Zürich',
        PLZ: 8064,
        Zusatzziffer: 0,
        Gemeindename: 'Zürich',
        'BFS-Nr': 261,
        Kantonskürzel: 'ZH',
        E: 8.485247272827678,
        N: 47.398657596898836,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Waltalingen',
        PLZ: 8468,
        Zusatzziffer: 0,
        Gemeindename: 'Stammheim',
        'BFS-Nr': 292,
        Kantonskürzel: 'ZH',
        E: 8.778917851502893,
        N: 47.622485408313096,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Guntalingen',
        PLZ: 8468,
        Zusatzziffer: 2,
        Gemeindename: 'Stammheim',
        'BFS-Nr': 292,
        Kantonskürzel: 'ZH',
        E: 8.769194154026968,
        N: 47.6346739575027,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Unterstammheim',
        PLZ: 8476,
        Zusatzziffer: 0,
        Gemeindename: 'Stammheim',
        'BFS-Nr': 292,
        Kantonskürzel: 'ZH',
        E: 8.792329552566267,
        N: 47.64083603225498,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Oberstammheim',
        PLZ: 8477,
        Zusatzziffer: 0,
        Gemeindename: 'Stammheim',
        'BFS-Nr': 292,
        Kantonskürzel: 'ZH',
        E: 8.801146885097534,
        N: 47.63401560250837,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Wilen b. Neunforn',
        PLZ: 8525,
        Zusatzziffer: 2,
        Gemeindename: 'Stammheim',
        'BFS-Nr': 292,
        Kantonskürzel: 'ZH',
        E: 8.79717024047177,
        N: 47.60257994078682,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Au ZH',
        PLZ: 8804,
        Zusatzziffer: 0,
        Gemeindename: 'Wädenswil',
        'BFS-Nr': 293,
        Kantonskürzel: 'ZH',
        E: 8.642975254725025,
        N: 47.24699034325969,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Wädenswil',
        PLZ: 8820,
        Zusatzziffer: 0,
        Gemeindename: 'Wädenswil',
        'BFS-Nr': 293,
        Kantonskürzel: 'ZH',
        E: 8.671744183343975,
        N: 47.22847229546469,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Schönenberg ZH',
        PLZ: 8824,
        Zusatzziffer: 0,
        Gemeindename: 'Wädenswil',
        'BFS-Nr': 293,
        Kantonskürzel: 'ZH',
        E: 8.64504004436453,
        N: 47.192904990318475,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Hütten',
        PLZ: 8825,
        Zusatzziffer: 0,
        Gemeindename: 'Wädenswil',
        'BFS-Nr': 293,
        Kantonskürzel: 'ZH',
        E: 8.669011315897812,
        N: 47.175651445318074,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Elgg',
        PLZ: 8353,
        Zusatzziffer: 0,
        Gemeindename: 'Elgg',
        'BFS-Nr': 294,
        Kantonskürzel: 'ZH',
        E: 8.867576656208632,
        N: 47.490181864605894,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Hofstetten ZH',
        PLZ: 8354,
        Zusatzziffer: 0,
        Gemeindename: 'Elgg',
        'BFS-Nr': 294,
        Kantonskürzel: 'ZH',
        E: 8.853718589115259,
        N: 47.47310439574053,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Dickbuch',
        PLZ: 8354,
        Zusatzziffer: 1,
        Gemeindename: 'Elgg',
        'BFS-Nr': 294,
        Kantonskürzel: 'ZH',
        E: 8.831650385714784,
        N: 47.496397409180354,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Sihlbrugg Station',
        PLZ: 8135,
        Zusatzziffer: 2,
        Gemeindename: 'Horgen',
        'BFS-Nr': 295,
        Kantonskürzel: 'ZH',
        E: 8.577206143066027,
        N: 47.23919453153994,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Sihlwald',
        PLZ: 8135,
        Zusatzziffer: 3,
        Gemeindename: 'Horgen',
        'BFS-Nr': 295,
        Kantonskürzel: 'ZH',
        E: 8.557518823751147,
        N: 47.26828466941764,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Horgen',
        PLZ: 8810,
        Zusatzziffer: 0,
        Gemeindename: 'Horgen',
        'BFS-Nr': 295,
        Kantonskürzel: 'ZH',
        E: 8.59738556918559,
        N: 47.259524117766894,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Horgenberg',
        PLZ: 8815,
        Zusatzziffer: 0,
        Gemeindename: 'Horgen',
        'BFS-Nr': 295,
        Kantonskürzel: 'ZH',
        E: 8.583894373012633,
        N: 47.24786418735395,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Hirzel',
        PLZ: 8816,
        Zusatzziffer: 0,
        Gemeindename: 'Horgen',
        'BFS-Nr': 295,
        Kantonskürzel: 'ZH',
        E: 8.606669485579227,
        N: 47.21702624332535,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Effretikon',
        PLZ: 8307,
        Zusatzziffer: 0,
        Gemeindename: 'Illnau-Effretikon',
        'BFS-Nr': 296,
        Kantonskürzel: 'ZH',
        E: 8.689483141141492,
        N: 47.42815279759913,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Ottikon b. Kemptthal',
        PLZ: 8307,
        Zusatzziffer: 2,
        Gemeindename: 'Illnau-Effretikon',
        'BFS-Nr': 296,
        Kantonskürzel: 'ZH',
        E: 8.716877259518736,
        N: 47.43468151836961,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Illnau',
        PLZ: 8308,
        Zusatzziffer: 0,
        Gemeindename: 'Illnau-Effretikon',
        'BFS-Nr': 296,
        Kantonskürzel: 'ZH',
        E: 8.72262445048944,
        N: 47.408959656986724,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Agasul',
        PLZ: 8308,
        Zusatzziffer: 2,
        Gemeindename: 'Illnau-Effretikon',
        'BFS-Nr': 296,
        Kantonskürzel: 'ZH',
        E: 8.743328236006231,
        N: 47.42518853033553,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Kyburg',
        PLZ: 8314,
        Zusatzziffer: 0,
        Gemeindename: 'Illnau-Effretikon',
        'BFS-Nr': 296,
        Kantonskürzel: 'ZH',
        E: 8.744690069810785,
        N: 47.457782435022935,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Saland',
        PLZ: 8493,
        Zusatzziffer: 0,
        Gemeindename: 'Bauma',
        'BFS-Nr': 297,
        Kantonskürzel: 'ZH',
        E: 8.853481324148392,
        N: 47.39111600036059,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Bauma',
        PLZ: 8494,
        Zusatzziffer: 0,
        Gemeindename: 'Bauma',
        'BFS-Nr': 297,
        Kantonskürzel: 'ZH',
        E: 8.87724382487757,
        N: 47.367288658018495,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Sternenberg',
        PLZ: 8499,
        Zusatzziffer: 0,
        Gemeindename: 'Bauma',
        'BFS-Nr': 297,
        Kantonskürzel: 'ZH',
        E: 8.911753654818924,
        N: 47.387302644297556,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Wiesendangen',
        PLZ: 8542,
        Zusatzziffer: 0,
        Gemeindename: 'Wiesendangen',
        'BFS-Nr': 298,
        Kantonskürzel: 'ZH',
        E: 8.789515621863183,
        N: 47.52126013157206,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Bertschikon',
        PLZ: 8543,
        Zusatzziffer: 0,
        Gemeindename: 'Wiesendangen',
        'BFS-Nr': 298,
        Kantonskürzel: 'ZH',
        E: 8.814508152556138,
        N: 47.52680557957406,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Gundetswil',
        PLZ: 8543,
        Zusatzziffer: 1,
        Gemeindename: 'Wiesendangen',
        'BFS-Nr': 298,
        Kantonskürzel: 'ZH',
        E: 8.822336975196517,
        N: 47.539847166749176,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Kefikon ZH',
        PLZ: 8543,
        Zusatzziffer: 2,
        Gemeindename: 'Wiesendangen',
        'BFS-Nr': 298,
        Kantonskürzel: 'ZH',
        E: 8.831016666064691,
        N: 47.54889031689169,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Attikon',
        PLZ: 8544,
        Zusatzziffer: 0,
        Gemeindename: 'Wiesendangen',
        'BFS-Nr': 298,
        Kantonskürzel: 'ZH',
        E: 8.797961991544103,
        N: 47.53587349327331,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Menzengrüt',
        PLZ: 8546,
        Zusatzziffer: 3,
        Gemeindename: 'Wiesendangen',
        'BFS-Nr': 298,
        Kantonskürzel: 'ZH',
        E: 8.821850967194077,
        N: 47.54964958001008,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Aarberg',
        PLZ: 3270,
        Zusatzziffer: 0,
        Gemeindename: 'Aarberg',
        'BFS-Nr': 301,
        Kantonskürzel: 'BE',
        E: 7.275297463028967,
        N: 47.04354553494627,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Bargen BE',
        PLZ: 3282,
        Zusatzziffer: 0,
        Gemeindename: 'Bargen (BE)',
        'BFS-Nr': 302,
        Kantonskürzel: 'BE',
        E: 7.25730112036087,
        N: 47.04015793295672,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Grossaffoltern',
        PLZ: 3257,
        Zusatzziffer: 0,
        Gemeindename: 'Grossaffoltern',
        'BFS-Nr': 303,
        Kantonskürzel: 'BE',
        E: 7.358832025046721,
        N: 47.0657350920021,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Ammerzwil BE',
        PLZ: 3257,
        Zusatzziffer: 1,
        Gemeindename: 'Grossaffoltern',
        'BFS-Nr': 303,
        Kantonskürzel: 'BE',
        E: 7.341477184799406,
        N: 47.07155701024176,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Suberg',
        PLZ: 3262,
        Zusatzziffer: 0,
        Gemeindename: 'Grossaffoltern',
        'BFS-Nr': 303,
        Kantonskürzel: 'BE',
        E: 7.3388615443467495,
        N: 47.059291563273185,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Golaten',
        PLZ: 3207,
        Zusatzziffer: 2,
        Gemeindename: 'Kallnach',
        'BFS-Nr': 304,
        Kantonskürzel: 'BE',
        E: 7.2422611783248785,
        N: 46.98570875027718,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Kallnach',
        PLZ: 3283,
        Zusatzziffer: 0,
        Gemeindename: 'Kallnach',
        'BFS-Nr': 304,
        Kantonskürzel: 'BE',
        E: 7.2299767447349454,
        N: 47.01780774247225,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Niederried b. Kallnach',
        PLZ: 3283,
        Zusatzziffer: 2,
        Gemeindename: 'Kallnach',
        'BFS-Nr': 304,
        Kantonskürzel: 'BE',
        E: 7.252380092896418,
        N: 47.01054182658397,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Kappelen',
        PLZ: 3273,
        Zusatzziffer: 0,
        Gemeindename: 'Kappelen',
        'BFS-Nr': 305,
        Kantonskürzel: 'BE',
        E: 7.267581044457035,
        N: 47.06019559031215,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Lyss',
        PLZ: 3250,
        Zusatzziffer: 0,
        Gemeindename: 'Lyss',
        'BFS-Nr': 306,
        Kantonskürzel: 'BE',
        E: 7.307507139316171,
        N: 47.07058059074075,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Busswil BE',
        PLZ: 3292,
        Zusatzziffer: 0,
        Gemeindename: 'Lyss',
        'BFS-Nr': 306,
        Kantonskürzel: 'BE',
        E: 7.32243278209025,
        N: 47.100596873083795,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Ortschwaben',
        PLZ: 3042,
        Zusatzziffer: 0,
        Gemeindename: 'Meikirch',
        'BFS-Nr': 307,
        Kantonskürzel: 'BE',
        E: 7.401894445223139,
        N: 46.99058769336097,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Meikirch',
        PLZ: 3045,
        Zusatzziffer: 0,
        Gemeindename: 'Meikirch',
        'BFS-Nr': 307,
        Kantonskürzel: 'BE',
        E: 7.362644782001176,
        N: 47.009346841890384,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Wahlendorf',
        PLZ: 3046,
        Zusatzziffer: 0,
        Gemeindename: 'Meikirch',
        'BFS-Nr': 307,
        Kantonskürzel: 'BE',
        E: 7.3378391826949665,
        N: 47.007717611906415,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Detligen',
        PLZ: 3036,
        Zusatzziffer: 0,
        Gemeindename: 'Radelfingen',
        'BFS-Nr': 309,
        Kantonskürzel: 'BE',
        E: 7.272789570869697,
        N: 47.00173950600875,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Radelfingen b. Aarberg',
        PLZ: 3271,
        Zusatzziffer: 0,
        Gemeindename: 'Radelfingen',
        'BFS-Nr': 309,
        Kantonskürzel: 'BE',
        E: 7.269056279045946,
        N: 47.021862139524636,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Lätti',
        PLZ: 3053,
        Zusatzziffer: 5,
        Gemeindename: 'Rapperswil (BE)',
        'BFS-Nr': 310,
        Kantonskürzel: 'BE',
        E: 7.430243079398754,
        N: 47.039479466609684,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Ruppoldsried',
        PLZ: 3251,
        Zusatzziffer: 2,
        Gemeindename: 'Rapperswil (BE)',
        'BFS-Nr': 310,
        Kantonskürzel: 'BE',
        E: 7.425466484456931,
        N: 47.08809346110944,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Rapperswil BE',
        PLZ: 3255,
        Zusatzziffer: 0,
        Gemeindename: 'Rapperswil (BE)',
        'BFS-Nr': 310,
        Kantonskürzel: 'BE',
        E: 7.409942374348781,
        N: 47.06313800328001,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Dieterswil',
        PLZ: 3256,
        Zusatzziffer: 0,
        Gemeindename: 'Rapperswil (BE)',
        'BFS-Nr': 310,
        Kantonskürzel: 'BE',
        E: 7.425028574567534,
        N: 47.05520949081962,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Bangerten b. Dieterswil',
        PLZ: 3256,
        Zusatzziffer: 2,
        Gemeindename: 'Rapperswil (BE)',
        'BFS-Nr': 310,
        Kantonskürzel: 'BE',
        E: 7.447652754524991,
        N: 47.0567308778031,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Seewil',
        PLZ: 3256,
        Zusatzziffer: 3,
        Gemeindename: 'Rapperswil (BE)',
        'BFS-Nr': 310,
        Kantonskürzel: 'BE',
        E: 7.408883709443168,
        N: 47.04806190169362,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Schüpfen',
        PLZ: 3054,
        Zusatzziffer: 0,
        Gemeindename: 'Schüpfen',
        'BFS-Nr': 311,
        Kantonskürzel: 'BE',
        E: 7.376796529308662,
        N: 47.03821298065186,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Frieswil',
        PLZ: 3035,
        Zusatzziffer: 0,
        Gemeindename: 'Seedorf (BE)',
        'BFS-Nr': 312,
        Kantonskürzel: 'BE',
        E: 7.285473818301022,
        N: 46.994931747361306,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Wiler b. Seedorf',
        PLZ: 3266,
        Zusatzziffer: 0,
        Gemeindename: 'Seedorf (BE)',
        'BFS-Nr': 312,
        Kantonskürzel: 'BE',
        E: 7.319965580521094,
        N: 47.04853793318066,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Seedorf BE',
        PLZ: 3267,
        Zusatzziffer: 0,
        Gemeindename: 'Seedorf (BE)',
        'BFS-Nr': 312,
        Kantonskürzel: 'BE',
        E: 7.311099073409035,
        N: 47.034220067125624,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Lobsigen',
        PLZ: 3268,
        Zusatzziffer: 0,
        Gemeindename: 'Seedorf (BE)',
        'BFS-Nr': 312,
        Kantonskürzel: 'BE',
        E: 7.293586219686586,
        N: 47.026803348324826,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Aarwangen',
        PLZ: 4912,
        Zusatzziffer: 0,
        Gemeindename: 'Aarwangen',
        'BFS-Nr': 321,
        Kantonskürzel: 'BE',
        E: 7.769617203492055,
        N: 47.240874092876474,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Auswil',
        PLZ: 4944,
        Zusatzziffer: 0,
        Gemeindename: 'Auswil',
        'BFS-Nr': 322,
        Kantonskürzel: 'BE',
        E: 7.832675668304817,
        N: 47.13466285163432,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Bannwil',
        PLZ: 4913,
        Zusatzziffer: 0,
        Gemeindename: 'Bannwil',
        'BFS-Nr': 323,
        Kantonskürzel: 'BE',
        E: 7.729947096268574,
        N: 47.237408741861984,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Bleienbach',
        PLZ: 3368,
        Zusatzziffer: 0,
        Gemeindename: 'Bleienbach',
        'BFS-Nr': 324,
        Kantonskürzel: 'BE',
        E: 7.757697858087769,
        N: 47.18456560409521,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Busswil b. Melchnau',
        PLZ: 4917,
        Zusatzziffer: 2,
        Gemeindename: 'Busswil bei Melchnau',
        'BFS-Nr': 325,
        Kantonskürzel: 'BE',
        E: 7.827969544043198,
        N: 47.18608786735911,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Gondiswil',
        PLZ: 4955,
        Zusatzziffer: 0,
        Gemeindename: 'Gondiswil',
        'BFS-Nr': 326,
        Kantonskürzel: 'BE',
        E: 7.871932474136463,
        N: 47.14623977474729,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Langenthal',
        PLZ: 4900,
        Zusatzziffer: 0,
        Gemeindename: 'Langenthal',
        'BFS-Nr': 329,
        Kantonskürzel: 'BE',
        E: 7.792487934277558,
        N: 47.21159141344169,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Untersteckholz',
        PLZ: 4916,
        Zusatzziffer: 0,
        Gemeindename: 'Langenthal',
        'BFS-Nr': 329,
        Kantonskürzel: 'BE',
        E: 7.8467294234252805,
        N: 47.204966770071735,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Obersteckholz',
        PLZ: 4924,
        Zusatzziffer: 0,
        Gemeindename: 'Langenthal',
        'BFS-Nr': 329,
        Kantonskürzel: 'BE',
        E: 7.8183500482522055,
        N: 47.19951338522244,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Lotzwil',
        PLZ: 4932,
        Zusatzziffer: 0,
        Gemeindename: 'Lotzwil',
        'BFS-Nr': 331,
        Kantonskürzel: 'BE',
        E: 7.7883218274796215,
        N: 47.18973590235411,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Gutenburg',
        PLZ: 4932,
        Zusatzziffer: 2,
        Gemeindename: 'Madiswil',
        'BFS-Nr': 332,
        Kantonskürzel: 'BE',
        E: 7.795515802757709,
        N: 47.18157958772209,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Madiswil',
        PLZ: 4934,
        Zusatzziffer: 0,
        Gemeindename: 'Madiswil',
        'BFS-Nr': 332,
        Kantonskürzel: 'BE',
        E: 7.799396161691377,
        N: 47.167371395654165,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Leimiswil',
        PLZ: 4935,
        Zusatzziffer: 0,
        Gemeindename: 'Madiswil',
        'BFS-Nr': 332,
        Kantonskürzel: 'BE',
        E: 7.761064527199425,
        N: 47.14892345591717,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Kleindietwil',
        PLZ: 4936,
        Zusatzziffer: 0,
        Gemeindename: 'Madiswil',
        'BFS-Nr': 332,
        Kantonskürzel: 'BE',
        E: 7.791107572605074,
        N: 47.14624337415411,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Melchnau',
        PLZ: 4917,
        Zusatzziffer: 0,
        Gemeindename: 'Melchnau',
        'BFS-Nr': 333,
        Kantonskürzel: 'BE',
        E: 7.85688845631213,
        N: 47.18324333468962,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Oeschenbach',
        PLZ: 4943,
        Zusatzziffer: 0,
        Gemeindename: 'Oeschenbach',
        'BFS-Nr': 335,
        Kantonskürzel: 'BE',
        E: 7.747163114344675,
        N: 47.101710974118376,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Reisiswil',
        PLZ: 4919,
        Zusatzziffer: 0,
        Gemeindename: 'Reisiswil',
        'BFS-Nr': 336,
        Kantonskürzel: 'BE',
        E: 7.846290885776035,
        N: 47.16512742718259,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Roggwil BE',
        PLZ: 4914,
        Zusatzziffer: 0,
        Gemeindename: 'Roggwil (BE)',
        'BFS-Nr': 337,
        Kantonskürzel: 'BE',
        E: 7.821681268643192,
        N: 47.239910457501495,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Rohrbach',
        PLZ: 4938,
        Zusatzziffer: 0,
        Gemeindename: 'Rohrbach',
        'BFS-Nr': 338,
        Kantonskürzel: 'BE',
        E: 7.812227681281746,
        N: 47.13288649907291,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Rohrbachgraben',
        PLZ: 4938,
        Zusatzziffer: 2,
        Gemeindename: 'Rohrbachgraben',
        'BFS-Nr': 339,
        Kantonskürzel: 'BE',
        E: 7.800495668996686,
        N: 47.12084443080664,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Rütschelen',
        PLZ: 4933,
        Zusatzziffer: 0,
        Gemeindename: 'Rütschelen',
        'BFS-Nr': 340,
        Kantonskürzel: 'BE',
        E: 7.771088532427965,
        N: 47.17322300990655,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Schwarzhäusern',
        PLZ: 4911,
        Zusatzziffer: 0,
        Gemeindename: 'Schwarzhäusern',
        'BFS-Nr': 341,
        Kantonskürzel: 'BE',
        E: 7.770033197513901,
        N: 47.2551142309996,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Bützberg',
        PLZ: 4922,
        Zusatzziffer: 0,
        Gemeindename: 'Thunstetten',
        'BFS-Nr': 342,
        Kantonskürzel: 'BE',
        E: 7.746565680800902,
        N: 47.21581147419685,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Thunstetten',
        PLZ: 4922,
        Zusatzziffer: 2,
        Gemeindename: 'Thunstetten',
        'BFS-Nr': 342,
        Kantonskürzel: 'BE',
        E: 7.753937898794297,
        N: 47.20384200688763,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Ursenbach',
        PLZ: 4937,
        Zusatzziffer: 0,
        Gemeindename: 'Ursenbach',
        'BFS-Nr': 344,
        Kantonskürzel: 'BE',
        E: 7.772910070652556,
        N: 47.13568467196431,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Wynau',
        PLZ: 4923,
        Zusatzziffer: 0,
        Gemeindename: 'Wynau',
        'BFS-Nr': 345,
        Kantonskürzel: 'BE',
        E: 7.813477965108926,
        N: 47.25776893020263,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Bern',
        PLZ: 3004,
        Zusatzziffer: 0,
        Gemeindename: 'Bern',
        'BFS-Nr': 351,
        Kantonskürzel: 'BE',
        E: 7.4520107074682755,
        N: 46.9804871926737,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Bern',
        PLZ: 3005,
        Zusatzziffer: 0,
        Gemeindename: 'Bern',
        'BFS-Nr': 351,
        Kantonskürzel: 'BE',
        E: 7.449931552415316,
        N: 46.93938995355346,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Bern',
        PLZ: 3006,
        Zusatzziffer: 0,
        Gemeindename: 'Bern',
        'BFS-Nr': 351,
        Kantonskürzel: 'BE',
        E: 7.471278342654534,
        N: 46.94581211618737,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Bern',
        PLZ: 3007,
        Zusatzziffer: 0,
        Gemeindename: 'Bern',
        'BFS-Nr': 351,
        Kantonskürzel: 'BE',
        E: 7.4345288888963585,
        N: 46.93919327733187,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Bern',
        PLZ: 3008,
        Zusatzziffer: 0,
        Gemeindename: 'Bern',
        'BFS-Nr': 351,
        Kantonskürzel: 'BE',
        E: 7.413896593247755,
        N: 46.945100555345846,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Bern',
        PLZ: 3010,
        Zusatzziffer: 0,
        Gemeindename: 'Bern',
        'BFS-Nr': 351,
        Kantonskürzel: 'BE',
        E: 7.4241472589155055,
        N: 46.946949745364236,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Bern',
        PLZ: 3011,
        Zusatzziffer: 0,
        Gemeindename: 'Bern',
        'BFS-Nr': 351,
        Kantonskürzel: 'BE',
        E: 7.447957834220311,
        N: 46.94723895051563,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Bern',
        PLZ: 3012,
        Zusatzziffer: 0,
        Gemeindename: 'Bern',
        'BFS-Nr': 351,
        Kantonskürzel: 'BE',
        E: 7.426024833698223,
        N: 46.96158403101272,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Bern',
        PLZ: 3013,
        Zusatzziffer: 0,
        Gemeindename: 'Bern',
        'BFS-Nr': 351,
        Kantonskürzel: 'BE',
        E: 7.446200709077208,
        N: 46.95620888748941,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Bern',
        PLZ: 3014,
        Zusatzziffer: 0,
        Gemeindename: 'Bern',
        'BFS-Nr': 351,
        Kantonskürzel: 'BE',
        E: 7.458409753622983,
        N: 46.962199665011134,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Bern',
        PLZ: 3015,
        Zusatzziffer: 0,
        Gemeindename: 'Bern',
        'BFS-Nr': 351,
        Kantonskürzel: 'BE',
        E: 7.484808363287374,
        N: 46.94020835960618,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Bern',
        PLZ: 3018,
        Zusatzziffer: 0,
        Gemeindename: 'Bern',
        'BFS-Nr': 351,
        Kantonskürzel: 'BE',
        E: 7.389112617746961,
        N: 46.93388884623948,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Bern',
        PLZ: 3019,
        Zusatzziffer: 0,
        Gemeindename: 'Bern',
        'BFS-Nr': 351,
        Kantonskürzel: 'BE',
        E: 7.3479518639380155,
        N: 46.93098262568504,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Bern',
        PLZ: 3020,
        Zusatzziffer: 0,
        Gemeindename: 'Bern',
        'BFS-Nr': 351,
        Kantonskürzel: 'BE',
        E: 7.320873278693392,
        N: 46.937079949146224,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Bern',
        PLZ: 3027,
        Zusatzziffer: 0,
        Gemeindename: 'Bern',
        'BFS-Nr': 351,
        Kantonskürzel: 'BE',
        E: 7.387022253665844,
        N: 46.95555250272264,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Bolligen',
        PLZ: 3065,
        Zusatzziffer: 0,
        Gemeindename: 'Bolligen',
        'BFS-Nr': 352,
        Kantonskürzel: 'BE',
        E: 7.496274643643378,
        N: 46.97503186167681,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Bremgarten b. Bern',
        PLZ: 3047,
        Zusatzziffer: 0,
        Gemeindename: 'Bremgarten bei Bern',
        'BFS-Nr': 353,
        Kantonskürzel: 'BE',
        E: 7.43434602914914,
        N: 46.978519392811656,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Herrenschwanden',
        PLZ: 3037,
        Zusatzziffer: 0,
        Gemeindename: 'Kirchlindach',
        'BFS-Nr': 354,
        Kantonskürzel: 'BE',
        E: 7.4182986486416995,
        N: 46.97822795495022,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Kirchlindach',
        PLZ: 3038,
        Zusatzziffer: 0,
        Gemeindename: 'Kirchlindach',
        'BFS-Nr': 354,
        Kantonskürzel: 'BE',
        E: 7.41429839450897,
        N: 46.999655913785475,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Wabern',
        PLZ: 3084,
        Zusatzziffer: 0,
        Gemeindename: 'Köniz',
        'BFS-Nr': 355,
        Kantonskürzel: 'BE',
        E: 7.449534817204611,
        N: 46.928587500902175,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Spiegel b. Bern',
        PLZ: 3095,
        Zusatzziffer: 0,
        Gemeindename: 'Köniz',
        'BFS-Nr': 355,
        Kantonskürzel: 'BE',
        E: 7.43293430753073,
        N: 46.92556695161132,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Liebefeld',
        PLZ: 3097,
        Zusatzziffer: 0,
        Gemeindename: 'Köniz',
        'BFS-Nr': 355,
        Kantonskürzel: 'BE',
        E: 7.415781571292609,
        N: 46.929726140639936,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Köniz',
        PLZ: 3098,
        Zusatzziffer: 0,
        Gemeindename: 'Köniz',
        'BFS-Nr': 355,
        Kantonskürzel: 'BE',
        E: 7.435272380867865,
        N: 46.93035927807075,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Köniz',
        PLZ: 3098,
        Zusatzziffer: 0,
        Gemeindename: 'Köniz',
        'BFS-Nr': 355,
        Kantonskürzel: 'BE',
        E: 7.413420499386784,
        N: 46.92111072253153,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Schliern b. Köniz',
        PLZ: 3098,
        Zusatzziffer: 1,
        Gemeindename: 'Köniz',
        'BFS-Nr': 355,
        Kantonskürzel: 'BE',
        E: 7.416125722973052,
        N: 46.90924238661325,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Gasel',
        PLZ: 3144,
        Zusatzziffer: 0,
        Gemeindename: 'Köniz',
        'BFS-Nr': 355,
        Kantonskürzel: 'BE',
        E: 7.400727583857717,
        N: 46.90087165575823,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Niederscherli',
        PLZ: 3145,
        Zusatzziffer: 0,
        Gemeindename: 'Köniz',
        'BFS-Nr': 355,
        Kantonskürzel: 'BE',
        E: 7.385512193538206,
        N: 46.88567018784211,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Mittelhäusern',
        PLZ: 3147,
        Zusatzziffer: 0,
        Gemeindename: 'Köniz',
        'BFS-Nr': 355,
        Kantonskürzel: 'BE',
        E: 7.368002076549886,
        N: 46.87647198995243,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Niederwangen b. Bern',
        PLZ: 3172,
        Zusatzziffer: 0,
        Gemeindename: 'Köniz',
        'BFS-Nr': 355,
        Kantonskürzel: 'BE',
        E: 7.376883685135721,
        N: 46.92586864250323,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Oberwangen b. Bern',
        PLZ: 3173,
        Zusatzziffer: 0,
        Gemeindename: 'Köniz',
        'BFS-Nr': 355,
        Kantonskürzel: 'BE',
        E: 7.361157807001169,
        N: 46.91585618738992,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Thörishaus',
        PLZ: 3174,
        Zusatzziffer: 0,
        Gemeindename: 'Köniz',
        'BFS-Nr': 355,
        Kantonskürzel: 'BE',
        E: 7.351795846252995,
        N: 46.89404983039866,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Gümligen',
        PLZ: 3073,
        Zusatzziffer: 0,
        Gemeindename: 'Muri bei Bern',
        'BFS-Nr': 356,
        Kantonskürzel: 'BE',
        E: 7.509268304033052,
        N: 46.93505098371397,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Muri b. Bern',
        PLZ: 3074,
        Zusatzziffer: 0,
        Gemeindename: 'Muri bei Bern',
        'BFS-Nr': 356,
        Kantonskürzel: 'BE',
        E: 7.488481690919198,
        N: 46.928369095380425,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Oberbalm',
        PLZ: 3096,
        Zusatzziffer: 0,
        Gemeindename: 'Oberbalm',
        'BFS-Nr': 357,
        Kantonskürzel: 'BE',
        E: 7.404624948512457,
        N: 46.87296727268848,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Stettlen',
        PLZ: 3066,
        Zusatzziffer: 0,
        Gemeindename: 'Stettlen',
        'BFS-Nr': 358,
        Kantonskürzel: 'BE',
        E: 7.522595985777529,
        N: 46.95913847895951,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Boll',
        PLZ: 3067,
        Zusatzziffer: 0,
        Gemeindename: 'Vechigen',
        'BFS-Nr': 359,
        Kantonskürzel: 'BE',
        E: 7.547609493046736,
        N: 46.95352738499335,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Utzigen',
        PLZ: 3068,
        Zusatzziffer: 0,
        Gemeindename: 'Vechigen',
        'BFS-Nr': 359,
        Kantonskürzel: 'BE',
        E: 7.564664507426845,
        N: 46.96081807179524,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Hinterkappelen',
        PLZ: 3032,
        Zusatzziffer: 0,
        Gemeindename: 'Wohlen bei Bern',
        'BFS-Nr': 360,
        Kantonskürzel: 'BE',
        E: 7.377457533372591,
        N: 46.967890570542714,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Wohlen b. Bern',
        PLZ: 3033,
        Zusatzziffer: 0,
        Gemeindename: 'Wohlen bei Bern',
        'BFS-Nr': 360,
        Kantonskürzel: 'BE',
        E: 7.3556712353509415,
        N: 46.97061613493138,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Murzelen',
        PLZ: 3034,
        Zusatzziffer: 0,
        Gemeindename: 'Wohlen bei Bern',
        'BFS-Nr': 360,
        Kantonskürzel: 'BE',
        E: 7.31483523575102,
        N: 46.98179044732681,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Uettligen',
        PLZ: 3043,
        Zusatzziffer: 0,
        Gemeindename: 'Wohlen bei Bern',
        'BFS-Nr': 360,
        Kantonskürzel: 'BE',
        E: 7.3856886176686745,
        N: 46.987283239898765,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Innerberg',
        PLZ: 3044,
        Zusatzziffer: 2,
        Gemeindename: 'Wohlen bei Bern',
        'BFS-Nr': 360,
        Kantonskürzel: 'BE',
        E: 7.309498303073987,
        N: 46.99531843802628,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Säriswil',
        PLZ: 3049,
        Zusatzziffer: 0,
        Gemeindename: 'Wohlen bei Bern',
        'BFS-Nr': 360,
        Kantonskürzel: 'BE',
        E: 7.3341691689328385,
        N: 46.991791206360496,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Zollikofen',
        PLZ: 3052,
        Zusatzziffer: 0,
        Gemeindename: 'Zollikofen',
        'BFS-Nr': 361,
        Kantonskürzel: 'BE',
        E: 7.461419686849904,
        N: 46.99290346352701,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Worblaufen',
        PLZ: 3048,
        Zusatzziffer: 0,
        Gemeindename: 'Ittigen',
        'BFS-Nr': 362,
        Kantonskürzel: 'BE',
        E: 7.457665419230947,
        N: 46.97879768105429,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Ittigen',
        PLZ: 3063,
        Zusatzziffer: 0,
        Gemeindename: 'Ittigen',
        'BFS-Nr': 362,
        Kantonskürzel: 'BE',
        E: 7.479871609207618,
        N: 46.97526372862298,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Ostermundigen',
        PLZ: 3072,
        Zusatzziffer: 0,
        Gemeindename: 'Ostermundigen',
        'BFS-Nr': 363,
        Kantonskürzel: 'BE',
        E: 7.487030424911038,
        N: 46.956628803711126,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Biel/Bienne',
        PLZ: 2502,
        Zusatzziffer: 0,
        Gemeindename: 'Biel/Bienne',
        'BFS-Nr': 371,
        Kantonskürzel: 'BE',
        E: 7.24572514928842,
        N: 47.1419838725423,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Biel/Bienne',
        PLZ: 2503,
        Zusatzziffer: 0,
        Gemeindename: 'Biel/Bienne',
        'BFS-Nr': 371,
        Kantonskürzel: 'BE',
        E: 7.25855392423261,
        N: 47.13168580313932,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Biel/Bienne',
        PLZ: 2504,
        Zusatzziffer: 0,
        Gemeindename: 'Biel/Bienne',
        'BFS-Nr': 371,
        Kantonskürzel: 'BE',
        E: 7.2826510509647635,
        N: 47.156328357426254,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Biel/Bienne',
        PLZ: 2505,
        Zusatzziffer: 0,
        Gemeindename: 'Biel/Bienne',
        'BFS-Nr': 371,
        Kantonskürzel: 'BE',
        E: 7.21798760362253,
        N: 47.13369948327448,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Magglingen/Macolin',
        PLZ: 2532,
        Zusatzziffer: 0,
        Gemeindename: 'Evilard',
        'BFS-Nr': 372,
        Kantonskürzel: 'BE',
        E: 7.216705383781767,
        N: 47.14205784741345,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Evilard',
        PLZ: 2533,
        Zusatzziffer: 0,
        Gemeindename: 'Evilard',
        'BFS-Nr': 372,
        Kantonskürzel: 'BE',
        E: 7.239625015125592,
        N: 47.14981495604012,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Arch',
        PLZ: 3296,
        Zusatzziffer: 0,
        Gemeindename: 'Arch',
        'BFS-Nr': 381,
        Kantonskürzel: 'BE',
        E: 7.427523003659042,
        N: 47.1638591104727,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Büetigen',
        PLZ: 3263,
        Zusatzziffer: 0,
        Gemeindename: 'Büetigen',
        'BFS-Nr': 382,
        Kantonskürzel: 'BE',
        E: 7.341190118655991,
        N: 47.106570115044306,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Büren an der Aare',
        PLZ: 3294,
        Zusatzziffer: 0,
        Gemeindename: 'Büren an der Aare',
        'BFS-Nr': 383,
        Kantonskürzel: 'BE',
        E: 7.37406099207307,
        N: 47.13989347551852,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Diessbach b. Büren',
        PLZ: 3264,
        Zusatzziffer: 0,
        Gemeindename: 'Diessbach bei Büren',
        'BFS-Nr': 385,
        Kantonskürzel: 'BE',
        E: 7.362001222662157,
        N: 47.108303439131284,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Dotzigen',
        PLZ: 3293,
        Zusatzziffer: 0,
        Gemeindename: 'Dotzigen',
        'BFS-Nr': 386,
        Kantonskürzel: 'BE',
        E: 7.3434229042494295,
        N: 47.12093972626486,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Lengnau BE',
        PLZ: 2543,
        Zusatzziffer: 0,
        Gemeindename: 'Lengnau (BE)',
        'BFS-Nr': 387,
        Kantonskürzel: 'BE',
        E: 7.369178285729735,
        N: 47.18237568499743,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Leuzigen',
        PLZ: 3297,
        Zusatzziffer: 0,
        Gemeindename: 'Leuzigen',
        'BFS-Nr': 388,
        Kantonskürzel: 'BE',
        E: 7.454367888487084,
        N: 47.173146715122364,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Meienried',
        PLZ: 3294,
        Zusatzziffer: 2,
        Gemeindename: 'Meienried',
        'BFS-Nr': 389,
        Kantonskürzel: 'BE',
        E: 7.3413715038233445,
        N: 47.13868003254057,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Meinisberg',
        PLZ: 2554,
        Zusatzziffer: 0,
        Gemeindename: 'Meinisberg',
        'BFS-Nr': 390,
        Kantonskürzel: 'BE',
        E: 7.34899590811337,
        N: 47.16174072191537,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Oberwil b. Büren',
        PLZ: 3298,
        Zusatzziffer: 0,
        Gemeindename: 'Oberwil bei Büren',
        'BFS-Nr': 391,
        Kantonskürzel: 'BE',
        E: 7.40931605575785,
        N: 47.12701225246925,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Pieterlen',
        PLZ: 2542,
        Zusatzziffer: 0,
        Gemeindename: 'Pieterlen',
        'BFS-Nr': 392,
        Kantonskürzel: 'BE',
        E: 7.328274599261831,
        N: 47.17412644428723,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Rüti b. Büren',
        PLZ: 3295,
        Zusatzziffer: 0,
        Gemeindename: 'Rüti bei Büren',
        'BFS-Nr': 393,
        Kantonskürzel: 'BE',
        E: 7.406937992540912,
        N: 47.154469291592015,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Wengi b. Büren',
        PLZ: 3251,
        Zusatzziffer: 0,
        Gemeindename: 'Wengi',
        'BFS-Nr': 394,
        Kantonskürzel: 'BE',
        E: 7.400101911654371,
        N: 47.08225371994076,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Aefligen',
        PLZ: 3426,
        Zusatzziffer: 0,
        Gemeindename: 'Aefligen',
        'BFS-Nr': 401,
        Kantonskürzel: 'BE',
        E: 7.551745253616114,
        N: 47.09571293161141,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Alchenstorf',
        PLZ: 3473,
        Zusatzziffer: 0,
        Gemeindename: 'Alchenstorf',
        'BFS-Nr': 402,
        Kantonskürzel: 'BE',
        E: 7.63932607179465,
        N: 47.1263300001015,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Bäriswil BE',
        PLZ: 3323,
        Zusatzziffer: 0,
        Gemeindename: 'Bäriswil',
        'BFS-Nr': 403,
        Kantonskürzel: 'BE',
        E: 7.533728548974185,
        N: 47.01749033059129,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Burgdorf',
        PLZ: 3400,
        Zusatzziffer: 0,
        Gemeindename: 'Burgdorf',
        'BFS-Nr': 404,
        Kantonskürzel: 'BE',
        E: 7.624661648027415,
        N: 47.05652532310535,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Ersigen',
        PLZ: 3423,
        Zusatzziffer: 0,
        Gemeindename: 'Ersigen',
        'BFS-Nr': 405,
        Kantonskürzel: 'BE',
        E: 7.592661946841793,
        N: 47.09557253398672,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Niederösch',
        PLZ: 3424,
        Zusatzziffer: 0,
        Gemeindename: 'Ersigen',
        'BFS-Nr': 405,
        Kantonskürzel: 'BE',
        E: 7.612334601760382,
        N: 47.11639778537987,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Oberösch',
        PLZ: 3424,
        Zusatzziffer: 2,
        Gemeindename: 'Ersigen',
        'BFS-Nr': 405,
        Kantonskürzel: 'BE',
        E: 7.608047810748163,
        N: 47.10955115351153,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Hasle b. Burgdorf',
        PLZ: 3415,
        Zusatzziffer: 1,
        Gemeindename: 'Hasle bei Burgdorf',
        'BFS-Nr': 406,
        Kantonskürzel: 'BE',
        E: 7.6465130104436465,
        N: 47.01643569452256,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Schafhausen im Emmental',
        PLZ: 3415,
        Zusatzziffer: 2,
        Gemeindename: 'Hasle bei Burgdorf',
        'BFS-Nr': 406,
        Kantonskürzel: 'BE',
        E: 7.6562977357730455,
        N: 46.99544678049945,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Biembach im Emmental',
        PLZ: 3419,
        Zusatzziffer: 0,
        Gemeindename: 'Hasle bei Burgdorf',
        'BFS-Nr': 406,
        Kantonskürzel: 'BE',
        E: 7.616170176479505,
        N: 46.993179537758415,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Heimiswil',
        PLZ: 3412,
        Zusatzziffer: 0,
        Gemeindename: 'Heimiswil',
        'BFS-Nr': 407,
        Kantonskürzel: 'BE',
        E: 7.659926832466068,
        N: 47.064802112882944,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Kaltacker',
        PLZ: 3413,
        Zusatzziffer: 0,
        Gemeindename: 'Heimiswil',
        'BFS-Nr': 407,
        Kantonskürzel: 'BE',
        E: 7.6756014909387815,
        N: 47.076772570902286,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Hellsau',
        PLZ: 3429,
        Zusatzziffer: 3,
        Gemeindename: 'Hellsau',
        'BFS-Nr': 408,
        Kantonskürzel: 'BE',
        E: 7.651748720512438,
        N: 47.1458251220991,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Hindelbank',
        PLZ: 3324,
        Zusatzziffer: 0,
        Gemeindename: 'Hindelbank',
        'BFS-Nr': 409,
        Kantonskürzel: 'BE',
        E: 7.540033928893843,
        N: 47.041769023357176,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Mötschwil',
        PLZ: 3324,
        Zusatzziffer: 2,
        Gemeindename: 'Hindelbank',
        'BFS-Nr': 409,
        Kantonskürzel: 'BE',
        E: 7.567400395789558,
        N: 47.04883024609718,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Höchstetten',
        PLZ: 3429,
        Zusatzziffer: 2,
        Gemeindename: 'Höchstetten',
        'BFS-Nr': 410,
        Kantonskürzel: 'BE',
        E: 7.63133897920043,
        N: 47.145054981070466,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Kernenried',
        PLZ: 3309,
        Zusatzziffer: 0,
        Gemeindename: 'Kernenried',
        'BFS-Nr': 411,
        Kantonskürzel: 'BE',
        E: 7.549871876894591,
        N: 47.06980689107937,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Kirchberg BE',
        PLZ: 3422,
        Zusatzziffer: 0,
        Gemeindename: 'Kirchberg (BE)',
        'BFS-Nr': 412,
        Kantonskürzel: 'BE',
        E: 7.585370116961041,
        N: 47.08378025548306,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Koppigen',
        PLZ: 3425,
        Zusatzziffer: 0,
        Gemeindename: 'Koppigen',
        'BFS-Nr': 413,
        Kantonskürzel: 'BE',
        E: 7.595544472525443,
        N: 47.13322187174832,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Hettiswil b. Hindelbank',
        PLZ: 3325,
        Zusatzziffer: 0,
        Gemeindename: 'Krauchthal',
        'BFS-Nr': 414,
        Kantonskürzel: 'BE',
        E: 7.554180608686295,
        N: 47.02900349472974,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Krauchthal',
        PLZ: 3326,
        Zusatzziffer: 0,
        Gemeindename: 'Krauchthal',
        'BFS-Nr': 414,
        Kantonskürzel: 'BE',
        E: 7.56851666739451,
        N: 47.008984907587454,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Lyssach',
        PLZ: 3421,
        Zusatzziffer: 0,
        Gemeindename: 'Lyssach',
        'BFS-Nr': 415,
        Kantonskürzel: 'BE',
        E: 7.580871683398337,
        N: 47.063296943083806,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Oberburg',
        PLZ: 3414,
        Zusatzziffer: 0,
        Gemeindename: 'Oberburg',
        'BFS-Nr': 418,
        Kantonskürzel: 'BE',
        E: 7.620478129067245,
        N: 47.03948013559786,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Lyssach',
        PLZ: 3421,
        Zusatzziffer: 0,
        Gemeindename: 'Oberburg',
        'BFS-Nr': 418,
        Kantonskürzel: 'BE',
        E: 7.5932973979156255,
        N: 47.04864679208871,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Rüdtligen',
        PLZ: 3422,
        Zusatzziffer: 1,
        Gemeindename: 'Rüdtligen-Alchenflüh',
        'BFS-Nr': 420,
        Kantonskürzel: 'BE',
        E: 7.569809662309439,
        N: 47.087315961529654,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Alchenflüh',
        PLZ: 3422,
        Zusatzziffer: 2,
        Gemeindename: 'Rüdtligen-Alchenflüh',
        'BFS-Nr': 420,
        Kantonskürzel: 'BE',
        E: 7.58082164353708,
        N: 47.08304844611828,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Rumendingen',
        PLZ: 3472,
        Zusatzziffer: 2,
        Gemeindename: 'Rumendingen',
        'BFS-Nr': 421,
        Kantonskürzel: 'BE',
        E: 7.6394110456287025,
        N: 47.10588386973153,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Rüti b. Lyssach',
        PLZ: 3421,
        Zusatzziffer: 2,
        Gemeindename: 'Rüti bei Lyssach',
        'BFS-Nr': 422,
        Kantonskürzel: 'BE',
        E: 7.576910861191669,
        N: 47.05664777452245,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Willadingen',
        PLZ: 3425,
        Zusatzziffer: 2,
        Gemeindename: 'Willadingen',
        'BFS-Nr': 423,
        Kantonskürzel: 'BE',
        E: 7.611115257324012,
        N: 47.146674985829954,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Wynigen',
        PLZ: 3472,
        Zusatzziffer: 0,
        Gemeindename: 'Wynigen',
        'BFS-Nr': 424,
        Kantonskürzel: 'BE',
        E: 7.6666807385485924,
        N: 47.106115321435354,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Rüedisbach',
        PLZ: 3474,
        Zusatzziffer: 0,
        Gemeindename: 'Wynigen',
        'BFS-Nr': 424,
        Kantonskürzel: 'BE',
        E: 7.702757615323031,
        N: 47.11968062250595,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Corgémont',
        PLZ: 2606,
        Zusatzziffer: 0,
        Gemeindename: 'Corgémont',
        'BFS-Nr': 431,
        Kantonskürzel: 'BE',
        E: 7.140839347701334,
        N: 47.19424543518053,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Mont-Crosin',
        PLZ: 2610,
        Zusatzziffer: 4,
        Gemeindename: 'Cormoret',
        'BFS-Nr': 432,
        Kantonskürzel: 'BE',
        E: 7.035399598527798,
        N: 47.18409808410651,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Cormoret',
        PLZ: 2612,
        Zusatzziffer: 0,
        Gemeindename: 'Cormoret',
        'BFS-Nr': 432,
        Kantonskürzel: 'BE',
        E: 7.054087709612769,
        N: 47.17289853720371,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Cortébert',
        PLZ: 2607,
        Zusatzziffer: 0,
        Gemeindename: 'Cortébert',
        'BFS-Nr': 433,
        Kantonskürzel: 'BE',
        E: 7.106665131075737,
        N: 47.19159026704515,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Montagne-de-Courtelary',
        PLZ: 2608,
        Zusatzziffer: 2,
        Gemeindename: 'Cortébert',
        'BFS-Nr': 433,
        Kantonskürzel: 'BE',
        E: 7.115632698780751,
        N: 47.170412014959076,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Courtelary',
        PLZ: 2608,
        Zusatzziffer: 0,
        Gemeindename: 'Courtelary',
        'BFS-Nr': 434,
        Kantonskürzel: 'BE',
        E: 7.069229173090898,
        N: 47.18020086160459,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'La Ferrière',
        PLZ: 2333,
        Zusatzziffer: 0,
        Gemeindename: 'La Ferrière',
        'BFS-Nr': 435,
        Kantonskürzel: 'BE',
        E: 6.894333694874212,
        N: 47.141042617242775,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Mont-Tramelan',
        PLZ: 2723,
        Zusatzziffer: 0,
        Gemeindename: 'Mont-Tramelan',
        'BFS-Nr': 437,
        Kantonskürzel: 'BE',
        E: 7.053377236712487,
        N: 47.20782281359643,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Orvin',
        PLZ: 2534,
        Zusatzziffer: 0,
        Gemeindename: 'Orvin',
        'BFS-Nr': 438,
        Kantonskürzel: 'BE',
        E: 7.216379298323659,
        N: 47.15914479975399,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Les Prés-d\'Orvin',
        PLZ: 2534,
        Zusatzziffer: 2,
        Gemeindename: 'Orvin',
        'BFS-Nr': 438,
        Kantonskürzel: 'BE',
        E: 7.173689381869929,
        N: 47.153560078085654,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Renan BE',
        PLZ: 2616,
        Zusatzziffer: 0,
        Gemeindename: 'Renan (BE)',
        'BFS-Nr': 441,
        Kantonskürzel: 'BE',
        E: 6.929078128309582,
        N: 47.12714844983761,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Romont BE',
        PLZ: 2538,
        Zusatzziffer: 0,
        Gemeindename: 'Romont (BE)',
        'BFS-Nr': 442,
        Kantonskürzel: 'BE',
        E: 7.340381182468996,
        N: 47.18886453935115,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'St-Imier',
        PLZ: 2610,
        Zusatzziffer: 0,
        Gemeindename: 'Saint-Imier',
        'BFS-Nr': 443,
        Kantonskürzel: 'BE',
        E: 6.996713279628029,
        N: 47.15259461873656,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Mont-Soleil',
        PLZ: 2610,
        Zusatzziffer: 2,
        Gemeindename: 'Saint-Imier',
        'BFS-Nr': 443,
        Kantonskürzel: 'BE',
        E: 6.988677500000179,
        N: 47.15851687170501,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Les Pontins',
        PLZ: 2610,
        Zusatzziffer: 3,
        Gemeindename: 'Saint-Imier',
        'BFS-Nr': 443,
        Kantonskürzel: 'BE',
        E: 6.996721405864668,
        N: 47.13152975031012,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Sonceboz-Sombeval',
        PLZ: 2605,
        Zusatzziffer: 0,
        Gemeindename: 'Sonceboz-Sombeval',
        'BFS-Nr': 444,
        Kantonskürzel: 'BE',
        E: 7.168574136299215,
        N: 47.19508285568544,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Sonvilier',
        PLZ: 2615,
        Zusatzziffer: 0,
        Gemeindename: 'Sonvilier',
        'BFS-Nr': 445,
        Kantonskürzel: 'BE',
        E: 6.964328626197475,
        N: 47.13998170419069,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Montagne-de-Sonvilier',
        PLZ: 2615,
        Zusatzziffer: 2,
        Gemeindename: 'Sonvilier',
        'BFS-Nr': 445,
        Kantonskürzel: 'BE',
        E: 6.946831256134526,
        N: 47.12093043846955,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Tramelan',
        PLZ: 2720,
        Zusatzziffer: 0,
        Gemeindename: 'Tramelan',
        'BFS-Nr': 446,
        Kantonskürzel: 'BE',
        E: 7.09717375062631,
        N: 47.22313196043226,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Les Reussilles',
        PLZ: 2722,
        Zusatzziffer: 0,
        Gemeindename: 'Tramelan',
        'BFS-Nr': 446,
        Kantonskürzel: 'BE',
        E: 7.08251951902742,
        N: 47.22430057278718,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Villeret',
        PLZ: 2613,
        Zusatzziffer: 0,
        Gemeindename: 'Villeret',
        'BFS-Nr': 448,
        Kantonskürzel: 'BE',
        E: 7.017234902310916,
        N: 47.15644633246698,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Frinvillier',
        PLZ: 2535,
        Zusatzziffer: 0,
        Gemeindename: 'Sauge',
        'BFS-Nr': 449,
        Kantonskürzel: 'BE',
        E: 7.25741009104355,
        N: 47.169531954236646,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Plagne',
        PLZ: 2536,
        Zusatzziffer: 0,
        Gemeindename: 'Sauge',
        'BFS-Nr': 449,
        Kantonskürzel: 'BE',
        E: 7.287394249182585,
        N: 47.18838882210955,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Vauffelin',
        PLZ: 2537,
        Zusatzziffer: 0,
        Gemeindename: 'Sauge',
        'BFS-Nr': 449,
        Kantonskürzel: 'BE',
        E: 7.303347843044944,
        N: 47.18701919449778,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Péry',
        PLZ: 2603,
        Zusatzziffer: 0,
        Gemeindename: 'Péry-La Heutte',
        'BFS-Nr': 450,
        Kantonskürzel: 'BE',
        E: 7.249542299753056,
        N: 47.19494893468344,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'La Heutte',
        PLZ: 2604,
        Zusatzziffer: 0,
        Gemeindename: 'Péry-La Heutte',
        'BFS-Nr': 450,
        Kantonskürzel: 'BE',
        E: 7.224691549223251,
        N: 47.18968562111596,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Brüttelen',
        PLZ: 3237,
        Zusatzziffer: 0,
        Gemeindename: 'Brüttelen',
        'BFS-Nr': 491,
        Kantonskürzel: 'BE',
        E: 7.147408635427688,
        N: 47.02232103404785,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Erlach',
        PLZ: 3235,
        Zusatzziffer: 0,
        Gemeindename: 'Erlach',
        'BFS-Nr': 492,
        Kantonskürzel: 'BE',
        E: 7.095379555301775,
        N: 47.041987435639584,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Finsterhennen',
        PLZ: 2577,
        Zusatzziffer: 2,
        Gemeindename: 'Finsterhennen',
        'BFS-Nr': 493,
        Kantonskürzel: 'BE',
        E: 7.178366123511715,
        N: 47.02601876632701,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Gals',
        PLZ: 3238,
        Zusatzziffer: 0,
        Gemeindename: 'Gals',
        'BFS-Nr': 494,
        Kantonskürzel: 'BE',
        E: 7.04964162742748,
        N: 47.027851573159325,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Gampelen',
        PLZ: 3236,
        Zusatzziffer: 0,
        Gemeindename: 'Gampelen',
        'BFS-Nr': 495,
        Kantonskürzel: 'BE',
        E: 7.0592346482919055,
        N: 47.014592053964975,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Ins',
        PLZ: 3232,
        Zusatzziffer: 0,
        Gemeindename: 'Ins',
        'BFS-Nr': 496,
        Kantonskürzel: 'BE',
        E: 7.101916542426133,
        N: 47.009096208437064,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Lüscherz',
        PLZ: 2576,
        Zusatzziffer: 0,
        Gemeindename: 'Lüscherz',
        'BFS-Nr': 497,
        Kantonskürzel: 'BE',
        E: 7.151551287281054,
        N: 47.04665183095972,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Müntschemier',
        PLZ: 3225,
        Zusatzziffer: 0,
        Gemeindename: 'Müntschemier',
        'BFS-Nr': 498,
        Kantonskürzel: 'BE',
        E: 7.142115970410717,
        N: 46.99337062851557,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Siselen BE',
        PLZ: 2577,
        Zusatzziffer: 0,
        Gemeindename: 'Siselen',
        'BFS-Nr': 499,
        Kantonskürzel: 'BE',
        E: 7.188282741579047,
        N: 47.03129034577671,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Treiten',
        PLZ: 3226,
        Zusatzziffer: 0,
        Gemeindename: 'Treiten',
        'BFS-Nr': 500,
        Kantonskürzel: 'BE',
        E: 7.160944814282907,
        N: 47.00907433554881,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Tschugg',
        PLZ: 3233,
        Zusatzziffer: 0,
        Gemeindename: 'Tschugg',
        'BFS-Nr': 501,
        Kantonskürzel: 'BE',
        E: 7.076034442099886,
        N: 47.02681609560448,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Vinelz',
        PLZ: 3234,
        Zusatzziffer: 0,
        Gemeindename: 'Vinelz',
        'BFS-Nr': 502,
        Kantonskürzel: 'BE',
        E: 7.111246429897441,
        N: 47.033788694904636,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Bätterkinden',
        PLZ: 3315,
        Zusatzziffer: 0,
        Gemeindename: 'Bätterkinden',
        'BFS-Nr': 533,
        Kantonskürzel: 'BE',
        E: 7.540340673059875,
        N: 47.12899379404853,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Kräiligen',
        PLZ: 3315,
        Zusatzziffer: 1,
        Gemeindename: 'Bätterkinden',
        'BFS-Nr': 533,
        Kantonskürzel: 'BE',
        E: 7.533259427568892,
        N: 47.14932136356666,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Deisswil b. Münchenbuchsee',
        PLZ: 3053,
        Zusatzziffer: 2,
        Gemeindename: 'Deisswil bei Münchenbuchsee',
        'BFS-Nr': 535,
        Kantonskürzel: 'BE',
        E: 7.453651678139562,
        N: 47.037341469933324,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Diemerswil',
        PLZ: 3053,
        Zusatzziffer: 3,
        Gemeindename: 'Diemerswil',
        'BFS-Nr': 536,
        Kantonskürzel: 'BE',
        E: 7.427920945398984,
        N: 47.019082092597486,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Etzelkofen',
        PLZ: 3306,
        Zusatzziffer: 0,
        Gemeindename: 'Fraubrunnen',
        'BFS-Nr': 538,
        Kantonskürzel: 'BE',
        E: 7.480644100019014,
        N: 47.084470429031434,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Grafenried',
        PLZ: 3308,
        Zusatzziffer: 0,
        Gemeindename: 'Fraubrunnen',
        'BFS-Nr': 538,
        Kantonskürzel: 'BE',
        E: 7.514712897204287,
        N: 47.07653840505172,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Zauggenried',
        PLZ: 3309,
        Zusatzziffer: 2,
        Gemeindename: 'Fraubrunnen',
        'BFS-Nr': 538,
        Kantonskürzel: 'BE',
        E: 7.540935742713866,
        N: 47.07318892873043,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Fraubrunnen',
        PLZ: 3312,
        Zusatzziffer: 0,
        Gemeindename: 'Fraubrunnen',
        'BFS-Nr': 538,
        Kantonskürzel: 'BE',
        E: 7.516891422529021,
        N: 47.08410782733077,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Büren zum Hof',
        PLZ: 3313,
        Zusatzziffer: 0,
        Gemeindename: 'Fraubrunnen',
        'BFS-Nr': 538,
        Kantonskürzel: 'BE',
        E: 7.5137349407349765,
        N: 47.0957799774994,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Schalunen',
        PLZ: 3314,
        Zusatzziffer: 0,
        Gemeindename: 'Fraubrunnen',
        'BFS-Nr': 538,
        Kantonskürzel: 'BE',
        E: 7.524439419975222,
        N: 47.11037602831214,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Limpach',
        PLZ: 3317,
        Zusatzziffer: 0,
        Gemeindename: 'Fraubrunnen',
        'BFS-Nr': 538,
        Kantonskürzel: 'BE',
        E: 7.497873013473113,
        N: 47.10768795620516,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Mülchi',
        PLZ: 3317,
        Zusatzziffer: 1,
        Gemeindename: 'Fraubrunnen',
        'BFS-Nr': 538,
        Kantonskürzel: 'BE',
        E: 7.4736407950672135,
        N: 47.101787214717845,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Jegenstorf',
        PLZ: 3303,
        Zusatzziffer: 0,
        Gemeindename: 'Jegenstorf',
        'BFS-Nr': 540,
        Kantonskürzel: 'BE',
        E: 7.507000259526521,
        N: 47.05023081669221,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Münchringen',
        PLZ: 3303,
        Zusatzziffer: 2,
        Gemeindename: 'Jegenstorf',
        'BFS-Nr': 540,
        Kantonskürzel: 'BE',
        E: 7.522191429956802,
        N: 47.04619665007175,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Ballmoos',
        PLZ: 3303,
        Zusatzziffer: 4,
        Gemeindename: 'Jegenstorf',
        'BFS-Nr': 540,
        Kantonskürzel: 'BE',
        E: 7.4758609351641505,
        N: 47.040488181566985,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Scheunen',
        PLZ: 3305,
        Zusatzziffer: 2,
        Gemeindename: 'Jegenstorf',
        'BFS-Nr': 540,
        Kantonskürzel: 'BE',
        E: 7.454681911094901,
        N: 47.070110035033274,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Iffwil',
        PLZ: 3305,
        Zusatzziffer: 0,
        Gemeindename: 'Iffwil',
        'BFS-Nr': 541,
        Kantonskürzel: 'BE',
        E: 7.478234149123487,
        N: 47.06401496885493,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Mattstetten',
        PLZ: 3322,
        Zusatzziffer: 1,
        Gemeindename: 'Mattstetten',
        'BFS-Nr': 543,
        Kantonskürzel: 'BE',
        E: 7.513761406507638,
        N: 47.02993395876909,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Moosseedorf',
        PLZ: 3302,
        Zusatzziffer: 0,
        Gemeindename: 'Moosseedorf',
        'BFS-Nr': 544,
        Kantonskürzel: 'BE',
        E: 7.482209551843116,
        N: 47.017519452710474,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Münchenbuchsee',
        PLZ: 3053,
        Zusatzziffer: 0,
        Gemeindename: 'Münchenbuchsee',
        'BFS-Nr': 546,
        Kantonskürzel: 'BE',
        E: 7.446015380748707,
        N: 47.02170403353136,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Urtenen-Schönbühl',
        PLZ: 3322,
        Zusatzziffer: 0,
        Gemeindename: 'Urtenen-Schönbühl',
        'BFS-Nr': 551,
        Kantonskürzel: 'BE',
        E: 7.499897886858259,
        N: 47.025566197319975,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Utzenstorf',
        PLZ: 3427,
        Zusatzziffer: 0,
        Gemeindename: 'Utzenstorf',
        'BFS-Nr': 552,
        Kantonskürzel: 'BE',
        E: 7.557280029942148,
        N: 47.12680972039498,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Wiggiswil',
        PLZ: 3053,
        Zusatzziffer: 4,
        Gemeindename: 'Wiggiswil',
        'BFS-Nr': 553,
        Kantonskürzel: 'BE',
        E: 7.470864515229721,
        N: 47.03131415719431,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Wiler b. Utzenstorf',
        PLZ: 3428,
        Zusatzziffer: 0,
        Gemeindename: 'Wiler bei Utzenstorf',
        'BFS-Nr': 554,
        Kantonskürzel: 'BE',
        E: 7.555844253350729,
        N: 47.15237161870326,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Zielebach',
        PLZ: 4564,
        Zusatzziffer: 1,
        Gemeindename: 'Zielebach',
        'BFS-Nr': 556,
        Kantonskürzel: 'BE',
        E: 7.57296011852044,
        N: 47.15958404403031,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Zuzwil BE',
        PLZ: 3303,
        Zusatzziffer: 3,
        Gemeindename: 'Zuzwil (BE)',
        'BFS-Nr': 557,
        Kantonskürzel: 'BE',
        E: 7.4715739174327584,
        N: 47.05133694533313,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Adelboden',
        PLZ: 3715,
        Zusatzziffer: 0,
        Gemeindename: 'Adelboden',
        'BFS-Nr': 561,
        Kantonskürzel: 'BE',
        E: 7.558821777117628,
        N: 46.49291136873533,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Aeschi b. Spiez',
        PLZ: 3703,
        Zusatzziffer: 0,
        Gemeindename: 'Aeschi bei Spiez',
        'BFS-Nr': 562,
        Kantonskürzel: 'BE',
        E: 7.694426444127123,
        N: 46.659914045982056,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Aeschiried',
        PLZ: 3703,
        Zusatzziffer: 41,
        Gemeindename: 'Aeschi bei Spiez',
        'BFS-Nr': 562,
        Kantonskürzel: 'BE',
        E: 7.725560600567881,
        N: 46.645246985275854,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Emdthal',
        PLZ: 3711,
        Zusatzziffer: 0,
        Gemeindename: 'Aeschi bei Spiez',
        'BFS-Nr': 562,
        Kantonskürzel: 'BE',
        E: 7.6836880677077675,
        N: 46.65493441248131,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Frutigen',
        PLZ: 3714,
        Zusatzziffer: 0,
        Gemeindename: 'Frutigen',
        'BFS-Nr': 563,
        Kantonskürzel: 'BE',
        E: 7.642719214392651,
        N: 46.58807980112951,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Ried (Frutigen)',
        PLZ: 3724,
        Zusatzziffer: 0,
        Gemeindename: 'Frutigen',
        'BFS-Nr': 563,
        Kantonskürzel: 'BE',
        E: 7.611857968783074,
        N: 46.56425400261272,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Achseten',
        PLZ: 3725,
        Zusatzziffer: 0,
        Gemeindename: 'Frutigen',
        'BFS-Nr': 563,
        Kantonskürzel: 'BE',
        E: 7.598418290300473,
        N: 46.52701595207362,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Kandergrund',
        PLZ: 3716,
        Zusatzziffer: 0,
        Gemeindename: 'Kandergrund',
        'BFS-Nr': 564,
        Kantonskürzel: 'BE',
        E: 7.662993835267664,
        N: 46.54563766632806,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Blausee-Mitholz',
        PLZ: 3717,
        Zusatzziffer: 0,
        Gemeindename: 'Kandergrund',
        'BFS-Nr': 564,
        Kantonskürzel: 'BE',
        E: 7.675388800829906,
        N: 46.52891450657587,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Kandersteg',
        PLZ: 3718,
        Zusatzziffer: 0,
        Gemeindename: 'Kandersteg',
        'BFS-Nr': 565,
        Kantonskürzel: 'BE',
        E: 7.674723670866165,
        N: 46.49466750991525,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Krattigen',
        PLZ: 3704,
        Zusatzziffer: 0,
        Gemeindename: 'Krattigen',
        'BFS-Nr': 566,
        Kantonskürzel: 'BE',
        E: 7.720748183953582,
        N: 46.65835206539378,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Mülenen',
        PLZ: 3711,
        Zusatzziffer: 2,
        Gemeindename: 'Reichenbach im Kandertal',
        'BFS-Nr': 567,
        Kantonskürzel: 'BE',
        E: 7.693016913577753,
        N: 46.63806006989572,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Reichenbach im Kandertal',
        PLZ: 3713,
        Zusatzziffer: 0,
        Gemeindename: 'Reichenbach im Kandertal',
        'BFS-Nr': 567,
        Kantonskürzel: 'BE',
        E: 7.6940472975115615,
        N: 46.626153304292416,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Wengi b. Frutigen',
        PLZ: 3714,
        Zusatzziffer: 3,
        Gemeindename: 'Reichenbach im Kandertal',
        'BFS-Nr': 567,
        Kantonskürzel: 'BE',
        E: 7.662843187863187,
        N: 46.60958009694798,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Scharnachtal',
        PLZ: 3722,
        Zusatzziffer: 0,
        Gemeindename: 'Reichenbach im Kandertal',
        'BFS-Nr': 567,
        Kantonskürzel: 'BE',
        E: 7.698120662722932,
        N: 46.61487338306163,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Kiental',
        PLZ: 3723,
        Zusatzziffer: 0,
        Gemeindename: 'Reichenbach im Kandertal',
        'BFS-Nr': 567,
        Kantonskürzel: 'BE',
        E: 7.724379026729816,
        N: 46.58940466545534,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Sundlauenen',
        PLZ: 3800,
        Zusatzziffer: 5,
        Gemeindename: 'Beatenberg',
        'BFS-Nr': 571,
        Kantonskürzel: 'BE',
        E: 7.795565884161337,
        N: 46.68748717266551,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Beatenberg',
        PLZ: 3803,
        Zusatzziffer: 0,
        Gemeindename: 'Beatenberg',
        'BFS-Nr': 571,
        Kantonskürzel: 'BE',
        E: 7.790785446698734,
        N: 46.69600678950565,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Bönigen b. Interlaken',
        PLZ: 3806,
        Zusatzziffer: 0,
        Gemeindename: 'Bönigen',
        'BFS-Nr': 572,
        Kantonskürzel: 'BE',
        E: 7.900487465494714,
        N: 46.684030038218204,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Brienz BE',
        PLZ: 3855,
        Zusatzziffer: 0,
        Gemeindename: 'Brienz (BE)',
        'BFS-Nr': 573,
        Kantonskürzel: 'BE',
        E: 8.02352880856716,
        N: 46.75622895584119,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Axalp',
        PLZ: 3855,
        Zusatzziffer: 1,
        Gemeindename: 'Brienz (BE)',
        'BFS-Nr': 573,
        Kantonskürzel: 'BE',
        E: 8.038306210407175,
        N: 46.721438646006014,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Brienzwiler',
        PLZ: 3856,
        Zusatzziffer: 0,
        Gemeindename: 'Brienzwiler',
        'BFS-Nr': 574,
        Kantonskürzel: 'BE',
        E: 8.098936829535555,
        N: 46.75016107279983,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Därligen',
        PLZ: 3707,
        Zusatzziffer: 0,
        Gemeindename: 'Därligen',
        'BFS-Nr': 575,
        Kantonskürzel: 'BE',
        E: 7.807943970674385,
        N: 46.66161552056425,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Burglauenen',
        PLZ: 3816,
        Zusatzziffer: 1,
        Gemeindename: 'Grindelwald',
        'BFS-Nr': 576,
        Kantonskürzel: 'BE',
        E: 7.978089139826497,
        N: 46.637928228898026,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Grindelwald',
        PLZ: 3818,
        Zusatzziffer: 0,
        Gemeindename: 'Grindelwald',
        'BFS-Nr': 576,
        Kantonskürzel: 'BE',
        E: 8.04584348646453,
        N: 46.62475728741999,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Gsteigwiler',
        PLZ: 3814,
        Zusatzziffer: 0,
        Gemeindename: 'Gsteigwiler',
        'BFS-Nr': 577,
        Kantonskürzel: 'BE',
        E: 7.872673212320063,
        N: 46.65484750907099,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Wilderswil',
        PLZ: 3812,
        Zusatzziffer: 0,
        Gemeindename: 'Gündlischwand',
        'BFS-Nr': 578,
        Kantonskürzel: 'BE',
        E: 7.911296368039866,
        N: 46.652414918088546,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Zweilütschinen',
        PLZ: 3815,
        Zusatzziffer: 0,
        Gemeindename: 'Gündlischwand',
        'BFS-Nr': 578,
        Kantonskürzel: 'BE',
        E: 7.89991247324517,
        N: 46.632935158564486,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Gündlischwand',
        PLZ: 3815,
        Zusatzziffer: 2,
        Gemeindename: 'Gündlischwand',
        'BFS-Nr': 578,
        Kantonskürzel: 'BE',
        E: 7.911784104358153,
        N: 46.63425200733556,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Habkern',
        PLZ: 3804,
        Zusatzziffer: 0,
        Gemeindename: 'Habkern',
        'BFS-Nr': 579,
        Kantonskürzel: 'BE',
        E: 7.862764760652455,
        N: 46.72512522982462,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Hofstetten b. Brienz',
        PLZ: 3858,
        Zusatzziffer: 0,
        Gemeindename: 'Hofstetten bei Brienz',
        'BFS-Nr': 580,
        Kantonskürzel: 'BE',
        E: 8.071364174155438,
        N: 46.75398148125773,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Interlaken',
        PLZ: 3800,
        Zusatzziffer: 0,
        Gemeindename: 'Interlaken',
        'BFS-Nr': 581,
        Kantonskürzel: 'BE',
        E: 7.862870322226102,
        N: 46.68689296718228,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Iseltwald',
        PLZ: 3807,
        Zusatzziffer: 0,
        Gemeindename: 'Iseltwald',
        'BFS-Nr': 582,
        Kantonskürzel: 'BE',
        E: 7.964747713626513,
        N: 46.709649244345016,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Lauterbrunnen',
        PLZ: 3822,
        Zusatzziffer: 0,
        Gemeindename: 'Lauterbrunnen',
        'BFS-Nr': 584,
        Kantonskürzel: 'BE',
        E: 7.908619283840012,
        N: 46.592749253201205,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Isenfluh',
        PLZ: 3822,
        Zusatzziffer: 2,
        Gemeindename: 'Lauterbrunnen',
        'BFS-Nr': 584,
        Kantonskürzel: 'BE',
        E: 7.895628243293009,
        N: 46.620247555566074,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Wengen',
        PLZ: 3823,
        Zusatzziffer: 0,
        Gemeindename: 'Lauterbrunnen',
        'BFS-Nr': 584,
        Kantonskürzel: 'BE',
        E: 7.919347169686746,
        N: 46.60722648402512,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Kleine Scheidegg',
        PLZ: 3823,
        Zusatzziffer: 1,
        Gemeindename: 'Lauterbrunnen',
        'BFS-Nr': 584,
        Kantonskürzel: 'BE',
        E: 7.960511769452786,
        N: 46.585095892941844,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Eigergletscher',
        PLZ: 3823,
        Zusatzziffer: 2,
        Gemeindename: 'Lauterbrunnen',
        'BFS-Nr': 584,
        Kantonskürzel: 'BE',
        E: 7.973830991975496,
        N: 46.57493020738182,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Stechelberg',
        PLZ: 3824,
        Zusatzziffer: 0,
        Gemeindename: 'Lauterbrunnen',
        'BFS-Nr': 584,
        Kantonskürzel: 'BE',
        E: 7.901592260541026,
        N: 46.548070179004824,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Mürren',
        PLZ: 3825,
        Zusatzziffer: 0,
        Gemeindename: 'Lauterbrunnen',
        'BFS-Nr': 584,
        Kantonskürzel: 'BE',
        E: 7.894530238421468,
        N: 46.560557854937905,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Gimmelwald',
        PLZ: 3826,
        Zusatzziffer: 0,
        Gemeindename: 'Lauterbrunnen',
        'BFS-Nr': 584,
        Kantonskürzel: 'BE',
        E: 7.8932987867012905,
        N: 46.54709548885897,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Leissigen',
        PLZ: 3706,
        Zusatzziffer: 0,
        Gemeindename: 'Leissigen',
        'BFS-Nr': 585,
        Kantonskürzel: 'BE',
        E: 7.772862877989464,
        N: 46.65497296794026,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Lütschental',
        PLZ: 3816,
        Zusatzziffer: 0,
        Gemeindename: 'Lütschental',
        'BFS-Nr': 586,
        Kantonskürzel: 'BE',
        E: 7.948795873240794,
        N: 46.63708882874521,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Matten b. Interlaken',
        PLZ: 3800,
        Zusatzziffer: 3,
        Gemeindename: 'Matten bei Interlaken',
        'BFS-Nr': 587,
        Kantonskürzel: 'BE',
        E: 7.869714885224609,
        N: 46.67727184286673,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Niederried b. Interlaken',
        PLZ: 3853,
        Zusatzziffer: 0,
        Gemeindename: 'Niederried bei Interlaken',
        'BFS-Nr': 588,
        Kantonskürzel: 'BE',
        E: 7.9293990369517,
        N: 46.716716758995936,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Oberried am Brienzersee',
        PLZ: 3854,
        Zusatzziffer: 0,
        Gemeindename: 'Oberried am Brienzersee',
        'BFS-Nr': 589,
        Kantonskürzel: 'BE',
        E: 7.956708826000279,
        N: 46.73796841469702,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Goldswil b. Interlaken',
        PLZ: 3805,
        Zusatzziffer: 0,
        Gemeindename: 'Ringgenberg (BE)',
        'BFS-Nr': 590,
        Kantonskürzel: 'BE',
        E: 7.88027320144703,
        N: 46.69658466666908,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Ringgenberg BE',
        PLZ: 3852,
        Zusatzziffer: 0,
        Gemeindename: 'Ringgenberg (BE)',
        'BFS-Nr': 590,
        Kantonskürzel: 'BE',
        E: 7.896498107019517,
        N: 46.700860554272026,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Saxeten',
        PLZ: 3813,
        Zusatzziffer: 0,
        Gemeindename: 'Saxeten',
        'BFS-Nr': 591,
        Kantonskürzel: 'BE',
        E: 7.830979929993655,
        N: 46.636529195705926,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Schwanden b. Brienz',
        PLZ: 3855,
        Zusatzziffer: 41,
        Gemeindename: 'Schwanden bei Brienz',
        'BFS-Nr': 592,
        Kantonskürzel: 'BE',
        E: 8.058321692967876,
        N: 46.75727590871033,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Unterseen',
        PLZ: 3800,
        Zusatzziffer: 2,
        Gemeindename: 'Unterseen',
        'BFS-Nr': 593,
        Kantonskürzel: 'BE',
        E: 7.84954014689376,
        N: 46.68762137783323,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Wilderswil',
        PLZ: 3812,
        Zusatzziffer: 0,
        Gemeindename: 'Wilderswil',
        'BFS-Nr': 594,
        Kantonskürzel: 'BE',
        E: 7.869480593649234,
        N: 46.6660860269379,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Arni BE',
        PLZ: 3508,
        Zusatzziffer: 0,
        Gemeindename: 'Arni (BE)',
        'BFS-Nr': 602,
        Kantonskürzel: 'BE',
        E: 7.668092794762981,
        N: 46.93611512463175,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Biglen',
        PLZ: 3507,
        Zusatzziffer: 0,
        Gemeindename: 'Biglen',
        'BFS-Nr': 603,
        Kantonskürzel: 'BE',
        E: 7.6288649135275985,
        N: 46.929976231776195,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Bowil',
        PLZ: 3533,
        Zusatzziffer: 0,
        Gemeindename: 'Bowil',
        'BFS-Nr': 605,
        Kantonskürzel: 'BE',
        E: 7.695002336025524,
        N: 46.89303663821882,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Brenzikofen',
        PLZ: 3671,
        Zusatzziffer: 0,
        Gemeindename: 'Brenzikofen',
        'BFS-Nr': 606,
        Kantonskürzel: 'BE',
        E: 7.613781511781634,
        N: 46.816138929880964,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Freimettigen',
        PLZ: 3510,
        Zusatzziffer: 8,
        Gemeindename: 'Freimettigen',
        'BFS-Nr': 607,
        Kantonskürzel: 'BE',
        E: 7.6273833271169424,
        N: 46.867948594387016,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Schlosswil',
        PLZ: 3082,
        Zusatzziffer: 0,
        Gemeindename: 'Grosshöchstetten',
        'BFS-Nr': 608,
        Kantonskürzel: 'BE',
        E: 7.611707743283216,
        N: 46.908693404301644,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Grosshöchstetten',
        PLZ: 3506,
        Zusatzziffer: 0,
        Gemeindename: 'Grosshöchstetten',
        'BFS-Nr': 608,
        Kantonskürzel: 'BE',
        E: 7.641654168702469,
        N: 46.907074935399685,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Häutligen',
        PLZ: 3510,
        Zusatzziffer: 9,
        Gemeindename: 'Häutligen',
        'BFS-Nr': 609,
        Kantonskürzel: 'BE',
        E: 7.606704117727015,
        N: 46.85759870208678,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Herbligen',
        PLZ: 3671,
        Zusatzziffer: 2,
        Gemeindename: 'Herbligen',
        'BFS-Nr': 610,
        Kantonskürzel: 'BE',
        E: 7.608183985485812,
        N: 46.827310975670954,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Kiesen',
        PLZ: 3629,
        Zusatzziffer: 0,
        Gemeindename: 'Kiesen',
        'BFS-Nr': 611,
        Kantonskürzel: 'BE',
        E: 7.575430544219443,
        N: 46.81945545300611,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Gysenstein',
        PLZ: 3503,
        Zusatzziffer: 0,
        Gemeindename: 'Konolfingen',
        'BFS-Nr': 612,
        Kantonskürzel: 'BE',
        E: 7.594782374172376,
        N: 46.88957279196664,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Konolfingen',
        PLZ: 3510,
        Zusatzziffer: 0,
        Gemeindename: 'Konolfingen',
        'BFS-Nr': 612,
        Kantonskürzel: 'BE',
        E: 7.619468712442099,
        N: 46.87816924513494,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Obergoldbach',
        PLZ: 3434,
        Zusatzziffer: 0,
        Gemeindename: 'Landiswil',
        'BFS-Nr': 613,
        Kantonskürzel: 'BE',
        E: 7.672820622749505,
        N: 46.960602268225394,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Landiswil',
        PLZ: 3434,
        Zusatzziffer: 1,
        Gemeindename: 'Landiswil',
        'BFS-Nr': 613,
        Kantonskürzel: 'BE',
        E: 7.6800127125666275,
        N: 46.95837669763752,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Linden',
        PLZ: 3673,
        Zusatzziffer: 0,
        Gemeindename: 'Linden',
        'BFS-Nr': 614,
        Kantonskürzel: 'BE',
        E: 7.677174908075054,
        N: 46.84723832642659,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Mirchel',
        PLZ: 3532,
        Zusatzziffer: 2,
        Gemeindename: 'Mirchel',
        'BFS-Nr': 615,
        Kantonskürzel: 'BE',
        E: 7.647224437014446,
        N: 46.895615995732356,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Trimstein',
        PLZ: 3083,
        Zusatzziffer: 0,
        Gemeindename: 'Münsingen',
        'BFS-Nr': 616,
        Kantonskürzel: 'BE',
        E: 7.580043333746495,
        N: 46.905718665263905,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Münsingen',
        PLZ: 3110,
        Zusatzziffer: 0,
        Gemeindename: 'Münsingen',
        'BFS-Nr': 616,
        Kantonskürzel: 'BE',
        E: 7.555372010889105,
        N: 46.876452532223304,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Tägertschi',
        PLZ: 3111,
        Zusatzziffer: 0,
        Gemeindename: 'Münsingen',
        'BFS-Nr': 616,
        Kantonskürzel: 'BE',
        E: 7.586572618633995,
        N: 46.8740008910727,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Niederhünigen',
        PLZ: 3504,
        Zusatzziffer: 0,
        Gemeindename: 'Niederhünigen',
        'BFS-Nr': 617,
        Kantonskürzel: 'BE',
        E: 7.636502702004574,
        N: 46.8778483438857,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Oberdiessbach',
        PLZ: 3672,
        Zusatzziffer: 0,
        Gemeindename: 'Oberdiessbach',
        'BFS-Nr': 619,
        Kantonskürzel: 'BE',
        E: 7.623760128342146,
        N: 46.8381540153482,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Aeschlen b. Oberdiessbach',
        PLZ: 3672,
        Zusatzziffer: 2,
        Gemeindename: 'Oberdiessbach',
        'BFS-Nr': 619,
        Kantonskürzel: 'BE',
        E: 7.646468933367525,
        N: 46.837543055283035,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Bleiken b. Oberdiessbach',
        PLZ: 3674,
        Zusatzziffer: 0,
        Gemeindename: 'Oberdiessbach',
        'BFS-Nr': 619,
        Kantonskürzel: 'BE',
        E: 7.6388768632332305,
        N: 46.81624656105524,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Oberthal',
        PLZ: 3531,
        Zusatzziffer: 0,
        Gemeindename: 'Oberthal',
        'BFS-Nr': 620,
        Kantonskürzel: 'BE',
        E: 7.674852918930047,
        N: 46.91663731822342,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Oppligen',
        PLZ: 3629,
        Zusatzziffer: 2,
        Gemeindename: 'Oppligen',
        'BFS-Nr': 622,
        Kantonskürzel: 'BE',
        E: 7.594840022633883,
        N: 46.82005720011902,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Rubigen',
        PLZ: 3113,
        Zusatzziffer: 0,
        Gemeindename: 'Rubigen',
        'BFS-Nr': 623,
        Kantonskürzel: 'BE',
        E: 7.549246218282001,
        N: 46.89791492991287,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Walkringen',
        PLZ: 3512,
        Zusatzziffer: 0,
        Gemeindename: 'Walkringen',
        'BFS-Nr': 626,
        Kantonskürzel: 'BE',
        E: 7.6148257082053075,
        N: 46.94803996362344,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Bigenthal',
        PLZ: 3513,
        Zusatzziffer: 0,
        Gemeindename: 'Walkringen',
        'BFS-Nr': 626,
        Kantonskürzel: 'BE',
        E: 7.62344396515029,
        N: 46.96566027971444,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Rüfenacht BE',
        PLZ: 3075,
        Zusatzziffer: 0,
        Gemeindename: 'Worb',
        'BFS-Nr': 627,
        Kantonskürzel: 'BE',
        E: 7.5385179368764845,
        N: 46.929707973118006,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Vielbringen b. Worb',
        PLZ: 3075,
        Zusatzziffer: 2,
        Gemeindename: 'Worb',
        'BFS-Nr': 627,
        Kantonskürzel: 'BE',
        E: 7.549083729481924,
        N: 46.91532479343101,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Worb',
        PLZ: 3076,
        Zusatzziffer: 0,
        Gemeindename: 'Worb',
        'BFS-Nr': 627,
        Kantonskürzel: 'BE',
        E: 7.564317368394192,
        N: 46.93014238271044,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Enggistein',
        PLZ: 3077,
        Zusatzziffer: 0,
        Gemeindename: 'Worb',
        'BFS-Nr': 627,
        Kantonskürzel: 'BE',
        E: 7.596896834257903,
        N: 46.932190938029294,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Richigen',
        PLZ: 3078,
        Zusatzziffer: 0,
        Gemeindename: 'Worb',
        'BFS-Nr': 627,
        Kantonskürzel: 'BE',
        E: 7.586909325760425,
        N: 46.91895042890622,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Zäziwil',
        PLZ: 3532,
        Zusatzziffer: 0,
        Gemeindename: 'Zäziwil',
        'BFS-Nr': 628,
        Kantonskürzel: 'BE',
        E: 7.661085276969708,
        N: 46.90244598344191,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Oberhünigen',
        PLZ: 3504,
        Zusatzziffer: 2,
        Gemeindename: 'Oberhünigen',
        'BFS-Nr': 629,
        Kantonskürzel: 'BE',
        E: 7.658559075606065,
        N: 46.879740144059,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Allmendingen b. Bern',
        PLZ: 3112,
        Zusatzziffer: 0,
        Gemeindename: 'Allmendingen',
        'BFS-Nr': 630,
        Kantonskürzel: 'BE',
        E: 7.524133983324439,
        N: 46.91477981768784,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Wichtrach',
        PLZ: 3114,
        Zusatzziffer: 0,
        Gemeindename: 'Wichtrach',
        'BFS-Nr': 632,
        Kantonskürzel: 'BE',
        E: 7.579436690202883,
        N: 46.845526626702046,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Rizenbach',
        PLZ: 3206,
        Zusatzziffer: 0,
        Gemeindename: 'Ferenbalm',
        'BFS-Nr': 662,
        Kantonskürzel: 'BE',
        E: 7.227087081067931,
        N: 46.94348634422656,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Ferenbalm',
        PLZ: 3206,
        Zusatzziffer: 1,
        Gemeindename: 'Ferenbalm',
        'BFS-Nr': 662,
        Kantonskürzel: 'BE',
        E: 7.211446768368123,
        N: 46.948342500282976,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Biberen',
        PLZ: 3206,
        Zusatzziffer: 3,
        Gemeindename: 'Ferenbalm',
        'BFS-Nr': 662,
        Kantonskürzel: 'BE',
        E: 7.212039549735892,
        N: 46.94006035095241,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Gammen',
        PLZ: 3206,
        Zusatzziffer: 4,
        Gemeindename: 'Ferenbalm',
        'BFS-Nr': 662,
        Kantonskürzel: 'BE',
        E: 7.227081827835487,
        N: 46.919604068435234,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Frauenkappelen',
        PLZ: 3202,
        Zusatzziffer: 0,
        Gemeindename: 'Frauenkappelen',
        'BFS-Nr': 663,
        Kantonskürzel: 'BE',
        E: 7.337651452300777,
        N: 46.955654591981,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Gurbrü',
        PLZ: 3208,
        Zusatzziffer: 0,
        Gemeindename: 'Gurbrü',
        'BFS-Nr': 665,
        Kantonskürzel: 'BE',
        E: 7.218054807709888,
        N: 46.9641008528358,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Kriechenwil',
        PLZ: 3179,
        Zusatzziffer: 0,
        Gemeindename: 'Kriechenwil',
        'BFS-Nr': 666,
        Kantonskürzel: 'BE',
        E: 7.226784497673145,
        N: 46.910780839976304,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Laupen BE',
        PLZ: 3177,
        Zusatzziffer: 0,
        Gemeindename: 'Laupen',
        'BFS-Nr': 667,
        Kantonskürzel: 'BE',
        E: 7.240793504282818,
        N: 46.90293598974355,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Mühleberg',
        PLZ: 3203,
        Zusatzziffer: 0,
        Gemeindename: 'Mühleberg',
        'BFS-Nr': 668,
        Kantonskürzel: 'BE',
        E: 7.26194141112139,
        N: 46.95380375061137,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Rosshäusern',
        PLZ: 3204,
        Zusatzziffer: 0,
        Gemeindename: 'Mühleberg',
        'BFS-Nr': 668,
        Kantonskürzel: 'BE',
        E: 7.275860775530531,
        N: 46.933536432571316,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Gümmenen',
        PLZ: 3205,
        Zusatzziffer: 0,
        Gemeindename: 'Mühleberg',
        'BFS-Nr': 668,
        Kantonskürzel: 'BE',
        E: 7.249370823368342,
        N: 46.94388259425456,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Münchenwiler',
        PLZ: 1797,
        Zusatzziffer: 0,
        Gemeindename: 'Münchenwiler',
        'BFS-Nr': 669,
        Kantonskürzel: 'BE',
        E: 7.126343629727982,
        N: 46.91173691659865,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Neuenegg',
        PLZ: 3176,
        Zusatzziffer: 0,
        Gemeindename: 'Neuenegg',
        'BFS-Nr': 670,
        Kantonskürzel: 'BE',
        E: 7.2984935852881545,
        N: 46.897113313705596,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Wileroltigen',
        PLZ: 3207,
        Zusatzziffer: 0,
        Gemeindename: 'Wileroltigen',
        'BFS-Nr': 671,
        Kantonskürzel: 'BE',
        E: 7.2388535727975185,
        N: 46.97080550195977,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Belprahon',
        PLZ: 2744,
        Zusatzziffer: 0,
        Gemeindename: 'Belprahon',
        'BFS-Nr': 681,
        Kantonskürzel: 'BE',
        E: 7.405885746377233,
        N: 47.28635327975539,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Champoz',
        PLZ: 2735,
        Zusatzziffer: 3,
        Gemeindename: 'Champoz',
        'BFS-Nr': 683,
        Kantonskürzel: 'BE',
        E: 7.2985575410581145,
        N: 47.25555024666685,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Corcelles BE',
        PLZ: 2747,
        Zusatzziffer: 0,
        Gemeindename: 'Corcelles (BE)',
        'BFS-Nr': 687,
        Kantonskürzel: 'BE',
        E: 7.455017560635988,
        N: 47.28545578382941,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Court',
        PLZ: 2738,
        Zusatzziffer: 0,
        Gemeindename: 'Court',
        'BFS-Nr': 690,
        Kantonskürzel: 'BE',
        E: 7.3322068051605225,
        N: 47.23996324877057,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Crémines',
        PLZ: 2746,
        Zusatzziffer: 0,
        Gemeindename: 'Crémines',
        'BFS-Nr': 691,
        Kantonskürzel: 'BE',
        E: 7.439085810903301,
        N: 47.28421553962509,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Eschert',
        PLZ: 2743,
        Zusatzziffer: 0,
        Gemeindename: 'Eschert',
        'BFS-Nr': 692,
        Kantonskürzel: 'BE',
        E: 7.398845728049994,
        N: 47.2755927232791,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Grandval',
        PLZ: 2745,
        Zusatzziffer: 0,
        Gemeindename: 'Grandval',
        'BFS-Nr': 694,
        Kantonskürzel: 'BE',
        E: 7.425224072953813,
        N: 47.28344509415524,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Loveresse',
        PLZ: 2732,
        Zusatzziffer: 4,
        Gemeindename: 'Loveresse',
        'BFS-Nr': 696,
        Kantonskürzel: 'BE',
        E: 7.236140948876453,
        N: 47.24214780562038,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Moutier',
        PLZ: 2740,
        Zusatzziffer: 0,
        Gemeindename: 'Moutier',
        'BFS-Nr': 700,
        Kantonskürzel: 'BE',
        E: 7.37215732860315,
        N: 47.28048944360926,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Perrefitte',
        PLZ: 2742,
        Zusatzziffer: 0,
        Gemeindename: 'Perrefitte',
        'BFS-Nr': 701,
        Kantonskürzel: 'BE',
        E: 7.341900022317364,
        N: 47.274740636125884,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Reconvilier',
        PLZ: 2732,
        Zusatzziffer: 0,
        Gemeindename: 'Reconvilier',
        'BFS-Nr': 703,
        Kantonskürzel: 'BE',
        E: 7.2272971197426195,
        N: 47.2352507323324,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Roches BE',
        PLZ: 2762,
        Zusatzziffer: 0,
        Gemeindename: 'Roches (BE)',
        'BFS-Nr': 704,
        Kantonskürzel: 'BE',
        E: 7.3825311399396805,
        N: 47.30205988125902,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Le Fuet',
        PLZ: 2712,
        Zusatzziffer: 0,
        Gemeindename: 'Saicourt',
        'BFS-Nr': 706,
        Kantonskürzel: 'BE',
        E: 7.182495234047515,
        N: 47.24317920908201,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Bellelay',
        PLZ: 2713,
        Zusatzziffer: 0,
        Gemeindename: 'Saicourt',
        'BFS-Nr': 706,
        Kantonskürzel: 'BE',
        E: 7.16831029109815,
        N: 47.26396592046159,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Saicourt',
        PLZ: 2732,
        Zusatzziffer: 2,
        Gemeindename: 'Saicourt',
        'BFS-Nr': 706,
        Kantonskürzel: 'BE',
        E: 7.207578816280032,
        N: 47.24298255685019,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Saules BE',
        PLZ: 2732,
        Zusatzziffer: 3,
        Gemeindename: 'Saules (BE)',
        'BFS-Nr': 707,
        Kantonskürzel: 'BE',
        E: 7.21889171176332,
        N: 47.2449247925739,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Schelten',
        PLZ: 2827,
        Zusatzziffer: 2,
        Gemeindename: 'Schelten',
        'BFS-Nr': 708,
        Kantonskürzel: 'BE',
        E: 7.551276321413564,
        N: 47.33199589609328,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Seehof',
        PLZ: 2747,
        Zusatzziffer: 2,
        Gemeindename: 'Seehof',
        'BFS-Nr': 709,
        Kantonskürzel: 'BE',
        E: 7.524964811014152,
        N: 47.304673143876705,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Sorvilier',
        PLZ: 2736,
        Zusatzziffer: 0,
        Gemeindename: 'Sorvilier',
        'BFS-Nr': 711,
        Kantonskürzel: 'BE',
        E: 7.305662346018428,
        N: 47.239416412375775,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Tavannes',
        PLZ: 2710,
        Zusatzziffer: 0,
        Gemeindename: 'Tavannes',
        'BFS-Nr': 713,
        Kantonskürzel: 'BE',
        E: 7.197395426855509,
        N: 47.22149972508877,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'La Tanne',
        PLZ: 2720,
        Zusatzziffer: 2,
        Gemeindename: 'Tavannes',
        'BFS-Nr': 713,
        Kantonskürzel: 'BE',
        E: 7.155464237788951,
        N: 47.22057788318872,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Rebévelier',
        PLZ: 2717,
        Zusatzziffer: 2,
        Gemeindename: 'Rebévelier',
        'BFS-Nr': 715,
        Kantonskürzel: 'BE',
        E: 7.1926049413443565,
        N: 47.29115789448774,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Châtelat',
        PLZ: 2715,
        Zusatzziffer: 1,
        Gemeindename: 'Petit-Val',
        'BFS-Nr': 716,
        Kantonskürzel: 'BE',
        E: 7.194422345217093,
        N: 47.272060095296176,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Monible',
        PLZ: 2715,
        Zusatzziffer: 2,
        Gemeindename: 'Petit-Val',
        'BFS-Nr': 716,
        Kantonskürzel: 'BE',
        E: 7.200256844649066,
        N: 47.27633442944272,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Sornetan',
        PLZ: 2716,
        Zusatzziffer: 0,
        Gemeindename: 'Petit-Val',
        'BFS-Nr': 716,
        Kantonskürzel: 'BE',
        E: 7.213956021489402,
        N: 47.274057346631146,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Fornet-Dessous',
        PLZ: 2717,
        Zusatzziffer: 0,
        Gemeindename: 'Petit-Val',
        'BFS-Nr': 716,
        Kantonskürzel: 'BE',
        E: 7.173107778002105,
        N: 47.28026772914625,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Souboz',
        PLZ: 2748,
        Zusatzziffer: 0,
        Gemeindename: 'Petit-Val',
        'BFS-Nr': 716,
        Kantonskürzel: 'BE',
        E: 7.243225452394408,
        N: 47.27413142062203,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Les Ecorcheresses',
        PLZ: 2748,
        Zusatzziffer: 2,
        Gemeindename: 'Petit-Val',
        'BFS-Nr': 716,
        Kantonskürzel: 'BE',
        E: 7.27963928804408,
        N: 47.27679759999367,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Pontenet',
        PLZ: 2733,
        Zusatzziffer: 0,
        Gemeindename: 'Valbirse',
        'BFS-Nr': 717,
        Kantonskürzel: 'BE',
        E: 7.25443204981649,
        N: 47.238923799792026,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Bévilard',
        PLZ: 2735,
        Zusatzziffer: 2,
        Gemeindename: 'Valbirse',
        'BFS-Nr': 717,
        Kantonskürzel: 'BE',
        E: 7.282412268310263,
        N: 47.23797620072621,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Malleray',
        PLZ: 2735,
        Zusatzziffer: 4,
        Gemeindename: 'Valbirse',
        'BFS-Nr': 717,
        Kantonskürzel: 'BE',
        E: 7.27489507576164,
        N: 47.238281771797595,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'La Neuveville',
        PLZ: 2520,
        Zusatzziffer: 0,
        Gemeindename: 'La Neuveville',
        'BFS-Nr': 723,
        Kantonskürzel: 'BE',
        E: 7.097054740549072,
        N: 47.06445424867817,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Nods',
        PLZ: 2518,
        Zusatzziffer: 0,
        Gemeindename: 'Nods',
        'BFS-Nr': 724,
        Kantonskürzel: 'BE',
        E: 7.080301113431255,
        N: 47.11230785015018,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Prêles',
        PLZ: 2515,
        Zusatzziffer: 0,
        Gemeindename: 'Plateau de Diesse',
        'BFS-Nr': 726,
        Kantonskürzel: 'BE',
        E: 7.129879178111397,
        N: 47.09932293207564,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Lamboing',
        PLZ: 2516,
        Zusatzziffer: 0,
        Gemeindename: 'Plateau de Diesse',
        'BFS-Nr': 726,
        Kantonskürzel: 'BE',
        E: 7.136356442884726,
        N: 47.11735685855093,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Diesse',
        PLZ: 2517,
        Zusatzziffer: 0,
        Gemeindename: 'Plateau de Diesse',
        'BFS-Nr': 726,
        Kantonskürzel: 'BE',
        E: 7.117423889833986,
        N: 47.11226080078306,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Aegerten',
        PLZ: 2558,
        Zusatzziffer: 0,
        Gemeindename: 'Aegerten',
        'BFS-Nr': 731,
        Kantonskürzel: 'BE',
        E: 7.291650838161605,
        N: 47.12114938935535,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Bellmund',
        PLZ: 2564,
        Zusatzziffer: 0,
        Gemeindename: 'Bellmund',
        'BFS-Nr': 732,
        Kantonskürzel: 'BE',
        E: 7.245389819388054,
        N: 47.106990755449345,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Brügg BE',
        PLZ: 2555,
        Zusatzziffer: 0,
        Gemeindename: 'Brügg',
        'BFS-Nr': 733,
        Kantonskürzel: 'BE',
        E: 7.278120935344228,
        N: 47.123540139879466,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Bühl b. Aarberg',
        PLZ: 3274,
        Zusatzziffer: 2,
        Gemeindename: 'Bühl',
        'BFS-Nr': 734,
        Kantonskürzel: 'BE',
        E: 7.246553738464111,
        N: 47.069791908892455,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Epsach',
        PLZ: 3272,
        Zusatzziffer: 2,
        Gemeindename: 'Epsach',
        'BFS-Nr': 735,
        Kantonskürzel: 'BE',
        E: 7.221727497283934,
        N: 47.070467115386364,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Hagneck',
        PLZ: 2575,
        Zusatzziffer: 2,
        Gemeindename: 'Hagneck',
        'BFS-Nr': 736,
        Kantonskürzel: 'BE',
        E: 7.187962326665396,
        N: 47.05813326120018,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Hermrigen',
        PLZ: 3274,
        Zusatzziffer: 0,
        Gemeindename: 'Hermrigen',
        'BFS-Nr': 737,
        Kantonskürzel: 'BE',
        E: 7.243961208098476,
        N: 47.08144667892807,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Jens',
        PLZ: 2565,
        Zusatzziffer: 0,
        Gemeindename: 'Jens',
        'BFS-Nr': 738,
        Kantonskürzel: 'BE',
        E: 7.263584306515894,
        N: 47.09632025939631,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Ipsach',
        PLZ: 2563,
        Zusatzziffer: 0,
        Gemeindename: 'Ipsach',
        'BFS-Nr': 739,
        Kantonskürzel: 'BE',
        E: 7.235222382419012,
        N: 47.117259980742595,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Ligerz',
        PLZ: 2514,
        Zusatzziffer: 0,
        Gemeindename: 'Ligerz',
        'BFS-Nr': 740,
        Kantonskürzel: 'BE',
        E: 7.13539234692458,
        N: 47.085850216827765,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Merzligen',
        PLZ: 3274,
        Zusatzziffer: 3,
        Gemeindename: 'Merzligen',
        'BFS-Nr': 741,
        Kantonskürzel: 'BE',
        E: 7.254345145117826,
        N: 47.08764246561612,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Mörigen',
        PLZ: 2572,
        Zusatzziffer: 2,
        Gemeindename: 'Mörigen',
        'BFS-Nr': 742,
        Kantonskürzel: 'BE',
        E: 7.211954219025885,
        N: 47.08432178064324,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Nidau',
        PLZ: 2560,
        Zusatzziffer: 0,
        Gemeindename: 'Nidau',
        'BFS-Nr': 743,
        Kantonskürzel: 'BE',
        E: 7.24055625717782,
        N: 47.12504013431118,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Orpund',
        PLZ: 2552,
        Zusatzziffer: 0,
        Gemeindename: 'Orpund',
        'BFS-Nr': 744,
        Kantonskürzel: 'BE',
        E: 7.312835709244282,
        N: 47.13724075284577,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Port',
        PLZ: 2562,
        Zusatzziffer: 0,
        Gemeindename: 'Port',
        'BFS-Nr': 745,
        Kantonskürzel: 'BE',
        E: 7.255418578892297,
        N: 47.11722310795502,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Safnern',
        PLZ: 2553,
        Zusatzziffer: 0,
        Gemeindename: 'Safnern',
        'BFS-Nr': 746,
        Kantonskürzel: 'BE',
        E: 7.327777616680972,
        N: 47.150348359890714,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Scheuren',
        PLZ: 2556,
        Zusatzziffer: 0,
        Gemeindename: 'Scheuren',
        'BFS-Nr': 747,
        Kantonskürzel: 'BE',
        E: 7.320895587334977,
        N: 47.134710674789176,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Schwadernau',
        PLZ: 2556,
        Zusatzziffer: 2,
        Gemeindename: 'Schwadernau',
        'BFS-Nr': 748,
        Kantonskürzel: 'BE',
        E: 7.307780860481038,
        N: 47.12779975220567,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Studen BE',
        PLZ: 2557,
        Zusatzziffer: 0,
        Gemeindename: 'Studen (BE)',
        'BFS-Nr': 749,
        Kantonskürzel: 'BE',
        E: 7.303238986837775,
        N: 47.11304835476629,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Sutz',
        PLZ: 2572,
        Zusatzziffer: 0,
        Gemeindename: 'Sutz-Lattrigen',
        'BFS-Nr': 750,
        Kantonskürzel: 'BE',
        E: 7.216905082994309,
        N: 47.10399365189827,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Täuffelen',
        PLZ: 2575,
        Zusatzziffer: 0,
        Gemeindename: 'Täuffelen',
        'BFS-Nr': 751,
        Kantonskürzel: 'BE',
        E: 7.1986834405362,
        N: 47.065086318511256,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Gerolfingen',
        PLZ: 2575,
        Zusatzziffer: 1,
        Gemeindename: 'Täuffelen',
        'BFS-Nr': 751,
        Kantonskürzel: 'BE',
        E: 7.201264806563552,
        N: 47.075008963867546,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Walperswil',
        PLZ: 3272,
        Zusatzziffer: 0,
        Gemeindename: 'Walperswil',
        'BFS-Nr': 754,
        Kantonskürzel: 'BE',
        E: 7.230081248891555,
        N: 47.05917004714972,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Worben',
        PLZ: 3252,
        Zusatzziffer: 0,
        Gemeindename: 'Worben',
        'BFS-Nr': 755,
        Kantonskürzel: 'BE',
        E: 7.29227482672257,
        N: 47.10013223810568,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Tüscherz-Alfermée',
        PLZ: 2512,
        Zusatzziffer: 0,
        Gemeindename: 'Twann-Tüscherz',
        'BFS-Nr': 756,
        Kantonskürzel: 'BE',
        E: 7.195776528669751,
        N: 47.115600400379904,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Twann',
        PLZ: 2513,
        Zusatzziffer: 0,
        Gemeindename: 'Twann-Tüscherz',
        'BFS-Nr': 756,
        Kantonskürzel: 'BE',
        E: 7.159885286511861,
        N: 47.095331391175435,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Därstetten',
        PLZ: 3763,
        Zusatzziffer: 0,
        Gemeindename: 'Därstetten',
        'BFS-Nr': 761,
        Kantonskürzel: 'BE',
        E: 7.495710542081859,
        N: 46.65879117178778,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Weissenburg',
        PLZ: 3764,
        Zusatzziffer: 0,
        Gemeindename: 'Därstetten',
        'BFS-Nr': 761,
        Kantonskürzel: 'BE',
        E: 7.4756001336723035,
        N: 46.658499686642976,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Oey',
        PLZ: 3753,
        Zusatzziffer: 0,
        Gemeindename: 'Diemtigen',
        'BFS-Nr': 762,
        Kantonskürzel: 'BE',
        E: 7.579411370946591,
        N: 46.659918109424396,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Diemtigen',
        PLZ: 3754,
        Zusatzziffer: 0,
        Gemeindename: 'Diemtigen',
        'BFS-Nr': 762,
        Kantonskürzel: 'BE',
        E: 7.566115711304058,
        N: 46.64938584283213,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Horboden',
        PLZ: 3755,
        Zusatzziffer: 0,
        Gemeindename: 'Diemtigen',
        'BFS-Nr': 762,
        Kantonskürzel: 'BE',
        E: 7.564187093252929,
        N: 46.630392724635406,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Zwischenflüh',
        PLZ: 3756,
        Zusatzziffer: 0,
        Gemeindename: 'Diemtigen',
        'BFS-Nr': 762,
        Kantonskürzel: 'BE',
        E: 7.515596063393855,
        N: 46.60744551336719,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Schwenden im Diemtigtal',
        PLZ: 3757,
        Zusatzziffer: 0,
        Gemeindename: 'Diemtigen',
        'BFS-Nr': 762,
        Kantonskürzel: 'BE',
        E: 7.4870959728714155,
        N: 46.57511706263711,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Latterbach',
        PLZ: 3758,
        Zusatzziffer: 0,
        Gemeindename: 'Erlenbach im Simmental',
        'BFS-Nr': 763,
        Kantonskürzel: 'BE',
        E: 7.578066907766267,
        N: 46.66407638762859,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Erlenbach im Simmental',
        PLZ: 3762,
        Zusatzziffer: 0,
        Gemeindename: 'Erlenbach im Simmental',
        'BFS-Nr': 763,
        Kantonskürzel: 'BE',
        E: 7.55208540301887,
        N: 46.66096676059531,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Oberwil im Simmental',
        PLZ: 3765,
        Zusatzziffer: 0,
        Gemeindename: 'Oberwil im Simmental',
        'BFS-Nr': 766,
        Kantonskürzel: 'BE',
        E: 7.432576710845918,
        N: 46.65735786274057,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Reutigen',
        PLZ: 3647,
        Zusatzziffer: 0,
        Gemeindename: 'Reutigen',
        'BFS-Nr': 767,
        Kantonskürzel: 'BE',
        E: 7.619495082836563,
        N: 46.6931818500118,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Einigen',
        PLZ: 3646,
        Zusatzziffer: 0,
        Gemeindename: 'Spiez',
        'BFS-Nr': 768,
        Kantonskürzel: 'BE',
        E: 7.64601805342903,
        N: 46.70943275546603,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Spiez',
        PLZ: 3700,
        Zusatzziffer: 0,
        Gemeindename: 'Spiez',
        'BFS-Nr': 768,
        Kantonskürzel: 'BE',
        E: 7.6771697089353585,
        N: 46.69072690372952,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Hondrich',
        PLZ: 3702,
        Zusatzziffer: 0,
        Gemeindename: 'Spiez',
        'BFS-Nr': 768,
        Kantonskürzel: 'BE',
        E: 7.680415409539426,
        N: 46.67237796833778,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Faulensee',
        PLZ: 3705,
        Zusatzziffer: 0,
        Gemeindename: 'Spiez',
        'BFS-Nr': 768,
        Kantonskürzel: 'BE',
        E: 7.702631079861316,
        N: 46.67614206945218,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Wimmis',
        PLZ: 3752,
        Zusatzziffer: 0,
        Gemeindename: 'Wimmis',
        'BFS-Nr': 769,
        Kantonskürzel: 'BE',
        E: 7.635291179645352,
        N: 46.67297348306544,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Höfen b. Thun',
        PLZ: 3631,
        Zusatzziffer: 0,
        Gemeindename: 'Stocken-Höfen',
        'BFS-Nr': 770,
        Kantonskürzel: 'BE',
        E: 7.563742976663338,
        N: 46.723184678900566,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Niederstocken',
        PLZ: 3632,
        Zusatzziffer: 2,
        Gemeindename: 'Stocken-Höfen',
        'BFS-Nr': 770,
        Kantonskürzel: 'BE',
        E: 7.57309034502133,
        N: 46.71054953722246,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Oberstocken',
        PLZ: 3632,
        Zusatzziffer: 3,
        Gemeindename: 'Stocken-Höfen',
        'BFS-Nr': 770,
        Kantonskürzel: 'BE',
        E: 7.5544033923402,
        N: 46.715788612224756,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Guttannen',
        PLZ: 3864,
        Zusatzziffer: 0,
        Gemeindename: 'Guttannen',
        'BFS-Nr': 782,
        Kantonskürzel: 'BE',
        E: 8.28973712978342,
        N: 46.65500418330469,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Hasliberg Hohfluh',
        PLZ: 6083,
        Zusatzziffer: 0,
        Gemeindename: 'Hasliberg',
        'BFS-Nr': 783,
        Kantonskürzel: 'BE',
        E: 8.17395629548176,
        N: 46.751597442571914,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Hasliberg Wasserwendi',
        PLZ: 6084,
        Zusatzziffer: 0,
        Gemeindename: 'Hasliberg',
        'BFS-Nr': 783,
        Kantonskürzel: 'BE',
        E: 8.197250748331482,
        N: 46.74665263773396,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Hasliberg Goldern',
        PLZ: 6085,
        Zusatzziffer: 0,
        Gemeindename: 'Hasliberg',
        'BFS-Nr': 783,
        Kantonskürzel: 'BE',
        E: 8.19858968743095,
        N: 46.739625297353705,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Hasliberg Reuti',
        PLZ: 6086,
        Zusatzziffer: 0,
        Gemeindename: 'Hasliberg',
        'BFS-Nr': 783,
        Kantonskürzel: 'BE',
        E: 8.209168880437149,
        N: 46.73275630591557,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Innertkirchen',
        PLZ: 3862,
        Zusatzziffer: 0,
        Gemeindename: 'Innertkirchen',
        'BFS-Nr': 784,
        Kantonskürzel: 'BE',
        E: 8.230780441274858,
        N: 46.70637152349941,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Gadmen',
        PLZ: 3863,
        Zusatzziffer: 0,
        Gemeindename: 'Innertkirchen',
        'BFS-Nr': 784,
        Kantonskürzel: 'BE',
        E: 8.352955401450401,
        N: 46.7383332578149,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Unterbach BE',
        PLZ: 3857,
        Zusatzziffer: 0,
        Gemeindename: 'Meiringen',
        'BFS-Nr': 785,
        Kantonskürzel: 'BE',
        E: 8.11214759228421,
        N: 46.73918522264444,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Meiringen',
        PLZ: 3860,
        Zusatzziffer: 0,
        Gemeindename: 'Meiringen',
        'BFS-Nr': 785,
        Kantonskürzel: 'BE',
        E: 8.189185833634088,
        N: 46.72944912777304,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Brünig',
        PLZ: 3860,
        Zusatzziffer: 4,
        Gemeindename: 'Meiringen',
        'BFS-Nr': 785,
        Kantonskürzel: 'BE',
        E: 8.13819550392973,
        N: 46.75757235716501,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Rosenlaui',
        PLZ: 3860,
        Zusatzziffer: 2,
        Gemeindename: 'Schattenhalb',
        'BFS-Nr': 786,
        Kantonskürzel: 'BE',
        E: 8.153901135243585,
        N: 46.67958082016252,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Schattenhalb',
        PLZ: 3860,
        Zusatzziffer: 3,
        Gemeindename: 'Schattenhalb',
        'BFS-Nr': 786,
        Kantonskürzel: 'BE',
        E: 8.190340358852279,
        N: 46.70700634028728,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Boltigen',
        PLZ: 3766,
        Zusatzziffer: 0,
        Gemeindename: 'Boltigen',
        'BFS-Nr': 791,
        Kantonskürzel: 'BE',
        E: 7.394266937755761,
        N: 46.63039839755104,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Lenk im Simmental',
        PLZ: 3775,
        Zusatzziffer: 0,
        Gemeindename: 'Lenk',
        'BFS-Nr': 792,
        Kantonskürzel: 'BE',
        E: 7.4408957465026875,
        N: 46.456462799987435,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'St. Stephan',
        PLZ: 3772,
        Zusatzziffer: 0,
        Gemeindename: 'St. Stephan',
        'BFS-Nr': 793,
        Kantonskürzel: 'BE',
        E: 7.400318092955496,
        N: 46.50551980569306,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Matten (St. Stephan)',
        PLZ: 3773,
        Zusatzziffer: 0,
        Gemeindename: 'St. Stephan',
        'BFS-Nr': 793,
        Kantonskürzel: 'BE',
        E: 7.421574532870478,
        N: 46.49375262661221,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Zweisimmen',
        PLZ: 3770,
        Zusatzziffer: 0,
        Gemeindename: 'Zweisimmen',
        'BFS-Nr': 794,
        Kantonskürzel: 'BE',
        E: 7.371831811184776,
        N: 46.55201672897794,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Blankenburg',
        PLZ: 3771,
        Zusatzziffer: 0,
        Gemeindename: 'Zweisimmen',
        'BFS-Nr': 794,
        Kantonskürzel: 'BE',
        E: 7.389479681159374,
        N: 46.541516307652046,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Oeschseite',
        PLZ: 3776,
        Zusatzziffer: 0,
        Gemeindename: 'Zweisimmen',
        'BFS-Nr': 794,
        Kantonskürzel: 'BE',
        E: 7.351517429532115,
        N: 46.53096235356296,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Feutersoey',
        PLZ: 3784,
        Zusatzziffer: 0,
        Gemeindename: 'Gsteig',
        'BFS-Nr': 841,
        Kantonskürzel: 'BE',
        E: 7.270797093911569,
        N: 46.41789250793217,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Gsteig b. Gstaad',
        PLZ: 3785,
        Zusatzziffer: 0,
        Gemeindename: 'Gsteig',
        'BFS-Nr': 841,
        Kantonskürzel: 'BE',
        E: 7.267212527327074,
        N: 46.384655783284686,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Lauenen b. Gstaad',
        PLZ: 3782,
        Zusatzziffer: 0,
        Gemeindename: 'Lauenen',
        'BFS-Nr': 842,
        Kantonskürzel: 'BE',
        E: 7.321070322690279,
        N: 46.42477983046758,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Abländschen',
        PLZ: 1657,
        Zusatzziffer: 0,
        Gemeindename: 'Saanen',
        'BFS-Nr': 843,
        Kantonskürzel: 'BE',
        E: 7.29280822987617,
        N: 46.577034648401906,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Saanenmöser',
        PLZ: 3777,
        Zusatzziffer: 0,
        Gemeindename: 'Saanen',
        'BFS-Nr': 843,
        Kantonskürzel: 'BE',
        E: 7.311731451556002,
        N: 46.517473854436375,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Schönried',
        PLZ: 3778,
        Zusatzziffer: 0,
        Gemeindename: 'Saanen',
        'BFS-Nr': 843,
        Kantonskürzel: 'BE',
        E: 7.291013145163885,
        N: 46.5041622963315,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Gstaad',
        PLZ: 3780,
        Zusatzziffer: 0,
        Gemeindename: 'Saanen',
        'BFS-Nr': 843,
        Kantonskürzel: 'BE',
        E: 7.286286858352668,
        N: 46.47496339409069,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Turbach',
        PLZ: 3781,
        Zusatzziffer: 0,
        Gemeindename: 'Saanen',
        'BFS-Nr': 843,
        Kantonskürzel: 'BE',
        E: 7.3356575191014795,
        N: 46.478962898221816,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Grund b. Gstaad',
        PLZ: 3783,
        Zusatzziffer: 0,
        Gemeindename: 'Saanen',
        'BFS-Nr': 843,
        Kantonskürzel: 'BE',
        E: 7.274335181476447,
        N: 46.44554256780215,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Saanen',
        PLZ: 3792,
        Zusatzziffer: 0,
        Gemeindename: 'Saanen',
        'BFS-Nr': 843,
        Kantonskürzel: 'BE',
        E: 7.258479405362266,
        N: 46.490336146344745,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Sangernboden',
        PLZ: 1738,
        Zusatzziffer: 0,
        Gemeindename: 'Guggisberg',
        'BFS-Nr': 852,
        Kantonskürzel: 'BE',
        E: 7.3533759150098215,
        N: 46.713082166095184,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Riffenmatt',
        PLZ: 3156,
        Zusatzziffer: 0,
        Gemeindename: 'Guggisberg',
        'BFS-Nr': 852,
        Kantonskürzel: 'BE',
        E: 7.352927231547025,
        N: 46.76560172600021,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Guggisberg',
        PLZ: 3158,
        Zusatzziffer: 0,
        Gemeindename: 'Guggisberg',
        'BFS-Nr': 852,
        Kantonskürzel: 'BE',
        E: 7.329234065713339,
        N: 46.76661953277998,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Riedstätt',
        PLZ: 3159,
        Zusatzziffer: 0,
        Gemeindename: 'Guggisberg',
        'BFS-Nr': 852,
        Kantonskürzel: 'BE',
        E: 7.3198153824304,
        N: 46.78380400006939,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Rüschegg Gambach',
        PLZ: 3153,
        Zusatzziffer: 0,
        Gemeindename: 'Rüschegg',
        'BFS-Nr': 853,
        Kantonskürzel: 'BE',
        E: 7.3740407117850735,
        N: 46.776218638711356,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Rüschegg Heubach',
        PLZ: 3154,
        Zusatzziffer: 0,
        Gemeindename: 'Rüschegg',
        'BFS-Nr': 853,
        Kantonskürzel: 'BE',
        E: 7.407255321147968,
        N: 46.78203361072426,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Lanzenhäusern',
        PLZ: 3148,
        Zusatzziffer: 0,
        Gemeindename: 'Schwarzenburg',
        'BFS-Nr': 855,
        Kantonskürzel: 'BE',
        E: 7.347214349772513,
        N: 46.84188670438746,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Schwarzenburg',
        PLZ: 3150,
        Zusatzziffer: 0,
        Gemeindename: 'Schwarzenburg',
        'BFS-Nr': 855,
        Kantonskürzel: 'BE',
        E: 7.341858607447384,
        N: 46.8169815477656,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Mamishaus',
        PLZ: 3152,
        Zusatzziffer: 0,
        Gemeindename: 'Schwarzenburg',
        'BFS-Nr': 855,
        Kantonskürzel: 'BE',
        E: 7.378596913756841,
        N: 46.80976896276145,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Milken',
        PLZ: 3157,
        Zusatzziffer: 0,
        Gemeindename: 'Schwarzenburg',
        'BFS-Nr': 855,
        Kantonskürzel: 'BE',
        E: 7.358698120458983,
        N: 46.792571006902456,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Albligen',
        PLZ: 3183,
        Zusatzziffer: 0,
        Gemeindename: 'Schwarzenburg',
        'BFS-Nr': 855,
        Kantonskürzel: 'BE',
        E: 7.319128163106373,
        N: 46.85175301948025,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Belp',
        PLZ: 3123,
        Zusatzziffer: 0,
        Gemeindename: 'Belp',
        'BFS-Nr': 861,
        Kantonskürzel: 'BE',
        E: 7.500502388411209,
        N: 46.89090972549944,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Belpberg',
        PLZ: 3124,
        Zusatzziffer: 0,
        Gemeindename: 'Belp',
        'BFS-Nr': 861,
        Kantonskürzel: 'BE',
        E: 7.52129780288839,
        N: 46.86731252122218,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Burgistein',
        PLZ: 3664,
        Zusatzziffer: 0,
        Gemeindename: 'Burgistein',
        'BFS-Nr': 863,
        Kantonskürzel: 'BE',
        E: 7.505175769493035,
        N: 46.79627544575291,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Gerzensee',
        PLZ: 3115,
        Zusatzziffer: 0,
        Gemeindename: 'Gerzensee',
        'BFS-Nr': 866,
        Kantonskürzel: 'BE',
        E: 7.547413796871272,
        N: 46.83830708939187,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Gurzelen',
        PLZ: 3663,
        Zusatzziffer: 0,
        Gemeindename: 'Gurzelen',
        'BFS-Nr': 867,
        Kantonskürzel: 'BE',
        E: 7.5333013545454826,
        N: 46.77972178413199,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Jaberg',
        PLZ: 3629,
        Zusatzziffer: 3,
        Gemeindename: 'Jaberg',
        'BFS-Nr': 868,
        Kantonskürzel: 'BE',
        E: 7.568439634196021,
        N: 46.81805511353207,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Kaufdorf',
        PLZ: 3126,
        Zusatzziffer: 0,
        Gemeindename: 'Kaufdorf',
        'BFS-Nr': 869,
        Kantonskürzel: 'BE',
        E: 7.498690246252994,
        N: 46.83803285916868,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Kehrsatz',
        PLZ: 3122,
        Zusatzziffer: 0,
        Gemeindename: 'Kehrsatz',
        'BFS-Nr': 870,
        Kantonskürzel: 'BE',
        E: 7.473546514439985,
        N: 46.912634532900434,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Kirchdorf BE',
        PLZ: 3116,
        Zusatzziffer: 0,
        Gemeindename: 'Kirchdorf (BE)',
        'BFS-Nr': 872,
        Kantonskürzel: 'BE',
        E: 7.550597945800512,
        N: 46.82049005878597,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Mühledorf BE',
        PLZ: 3116,
        Zusatzziffer: 2,
        Gemeindename: 'Kirchdorf (BE)',
        'BFS-Nr': 872,
        Kantonskürzel: 'BE',
        E: 7.529944415810914,
        N: 46.82798053715882,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Noflen BE',
        PLZ: 3116,
        Zusatzziffer: 3,
        Gemeindename: 'Kirchdorf (BE)',
        'BFS-Nr': 872,
        Kantonskürzel: 'BE',
        E: 7.543301457993588,
        N: 46.80559999034532,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Gelterfingen',
        PLZ: 3126,
        Zusatzziffer: 2,
        Gemeindename: 'Kirchdorf (BE)',
        'BFS-Nr': 872,
        Kantonskürzel: 'BE',
        E: 7.515639230243542,
        N: 46.844489838588544,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Niedermuhlern',
        PLZ: 3087,
        Zusatzziffer: 0,
        Gemeindename: 'Niedermuhlern',
        'BFS-Nr': 877,
        Kantonskürzel: 'BE',
        E: 7.4653305355476,
        N: 46.86137920879441,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Rüti b. Riggisberg',
        PLZ: 3099,
        Zusatzziffer: 0,
        Gemeindename: 'Riggisberg',
        'BFS-Nr': 879,
        Kantonskürzel: 'BE',
        E: 7.4475214310092985,
        N: 46.78280205325471,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Rümligen',
        PLZ: 3128,
        Zusatzziffer: 2,
        Gemeindename: 'Riggisberg',
        'BFS-Nr': 879,
        Kantonskürzel: 'BE',
        E: 7.49569327238644,
        N: 46.83102063280934,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Riggisberg',
        PLZ: 3132,
        Zusatzziffer: 0,
        Gemeindename: 'Riggisberg',
        'BFS-Nr': 879,
        Kantonskürzel: 'BE',
        E: 7.47667867704334,
        N: 46.811621346220626,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Rüeggisberg',
        PLZ: 3088,
        Zusatzziffer: 0,
        Gemeindename: 'Rüeggisberg',
        'BFS-Nr': 880,
        Kantonskürzel: 'BE',
        E: 7.43870060145399,
        N: 46.82125528972767,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Oberbütschel',
        PLZ: 3088,
        Zusatzziffer: 2,
        Gemeindename: 'Rüeggisberg',
        'BFS-Nr': 880,
        Kantonskürzel: 'BE',
        E: 7.463152927010457,
        N: 46.837665934662574,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Hinterfultigen',
        PLZ: 3089,
        Zusatzziffer: 0,
        Gemeindename: 'Rüeggisberg',
        'BFS-Nr': 880,
        Kantonskürzel: 'BE',
        E: 7.401155906088703,
        N: 46.83911316641459,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Helgisried-Rohrbach',
        PLZ: 3155,
        Zusatzziffer: 0,
        Gemeindename: 'Rüeggisberg',
        'BFS-Nr': 880,
        Kantonskürzel: 'BE',
        E: 7.423715846595291,
        N: 46.81194630691353,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Seftigen',
        PLZ: 3662,
        Zusatzziffer: 0,
        Gemeindename: 'Seftigen',
        'BFS-Nr': 883,
        Kantonskürzel: 'BE',
        E: 7.540671423708006,
        N: 46.78683109298828,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Toffen',
        PLZ: 3125,
        Zusatzziffer: 0,
        Gemeindename: 'Toffen',
        'BFS-Nr': 884,
        Kantonskürzel: 'BE',
        E: 7.492819757300539,
        N: 46.85851826197869,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Uttigen',
        PLZ: 3628,
        Zusatzziffer: 0,
        Gemeindename: 'Uttigen',
        'BFS-Nr': 885,
        Kantonskürzel: 'BE',
        E: 7.574351101800626,
        N: 46.79312814205203,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Wattenwil',
        PLZ: 3665,
        Zusatzziffer: 0,
        Gemeindename: 'Wattenwil',
        'BFS-Nr': 886,
        Kantonskürzel: 'BE',
        E: 7.504873153234969,
        N: 46.77053120682832,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Zimmerwald',
        PLZ: 3086,
        Zusatzziffer: 0,
        Gemeindename: 'Wald (BE)',
        'BFS-Nr': 888,
        Kantonskürzel: 'BE',
        E: 7.470174524818954,
        N: 46.87649098732257,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Englisberg',
        PLZ: 3086,
        Zusatzziffer: 2,
        Gemeindename: 'Wald (BE)',
        'BFS-Nr': 888,
        Kantonskürzel: 'BE',
        E: 7.4683970450522725,
        N: 46.89789795356609,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Mühlethurnen',
        PLZ: 3127,
        Zusatzziffer: 0,
        Gemeindename: 'Thurnen',
        'BFS-Nr': 889,
        Kantonskürzel: 'BE',
        E: 7.507613112636157,
        N: 46.81405487218582,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Lohnstorf',
        PLZ: 3127,
        Zusatzziffer: 2,
        Gemeindename: 'Thurnen',
        'BFS-Nr': 889,
        Kantonskürzel: 'BE',
        E: 7.510822651530153,
        N: 46.80676666570011,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Kirchenthurnen',
        PLZ: 3128,
        Zusatzziffer: 0,
        Gemeindename: 'Thurnen',
        'BFS-Nr': 889,
        Kantonskürzel: 'BE',
        E: 7.506315577734293,
        N: 46.82500159441789,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Aeschau',
        PLZ: 3536,
        Zusatzziffer: 0,
        Gemeindename: 'Eggiwil',
        'BFS-Nr': 901,
        Kantonskürzel: 'BE',
        E: 7.766453854044007,
        N: 46.908883141396586,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Eggiwil',
        PLZ: 3537,
        Zusatzziffer: 0,
        Gemeindename: 'Eggiwil',
        'BFS-Nr': 901,
        Kantonskürzel: 'BE',
        E: 7.795775950459093,
        N: 46.874426330822516,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Langnau im Emmental',
        PLZ: 3550,
        Zusatzziffer: 0,
        Gemeindename: 'Langnau im Emmental',
        'BFS-Nr': 902,
        Kantonskürzel: 'BE',
        E: 7.785107753847711,
        N: 46.94186216351227,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Oberfrittenbach',
        PLZ: 3551,
        Zusatzziffer: 0,
        Gemeindename: 'Langnau im Emmental',
        'BFS-Nr': 902,
        Kantonskürzel: 'BE',
        E: 7.8000319110587935,
        N: 46.97767876075739,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Bärau',
        PLZ: 3552,
        Zusatzziffer: 0,
        Gemeindename: 'Langnau im Emmental',
        'BFS-Nr': 902,
        Kantonskürzel: 'BE',
        E: 7.8110599284731626,
        N: 46.93299267614589,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Gohl',
        PLZ: 3553,
        Zusatzziffer: 0,
        Gemeindename: 'Langnau im Emmental',
        'BFS-Nr': 902,
        Kantonskürzel: 'BE',
        E: 7.808335943475956,
        N: 46.95596305616606,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Lauperswil',
        PLZ: 3438,
        Zusatzziffer: 0,
        Gemeindename: 'Lauperswil',
        'BFS-Nr': 903,
        Kantonskürzel: 'BE',
        E: 7.73789382824007,
        N: 46.96653915221448,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Emmenmatt',
        PLZ: 3543,
        Zusatzziffer: 0,
        Gemeindename: 'Lauperswil',
        'BFS-Nr': 903,
        Kantonskürzel: 'BE',
        E: 7.7476165574258875,
        N: 46.94803202869673,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Röthenbach im Emmental',
        PLZ: 3538,
        Zusatzziffer: 0,
        Gemeindename: 'Röthenbach im Emmental',
        'BFS-Nr': 904,
        Kantonskürzel: 'BE',
        E: 7.74144902578741,
        N: 46.854325898796056,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Schwanden im Emmental',
        PLZ: 3433,
        Zusatzziffer: 0,
        Gemeindename: 'Rüderswil',
        'BFS-Nr': 905,
        Kantonskürzel: 'BE',
        E: 7.694470757804733,
        N: 46.98559679301996,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Zollbrück',
        PLZ: 3436,
        Zusatzziffer: 0,
        Gemeindename: 'Rüderswil',
        'BFS-Nr': 905,
        Kantonskürzel: 'BE',
        E: 7.741319474978465,
        N: 46.97581055037813,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Rüderswil',
        PLZ: 3437,
        Zusatzziffer: 0,
        Gemeindename: 'Rüderswil',
        'BFS-Nr': 905,
        Kantonskürzel: 'BE',
        E: 7.719590363055355,
        N: 46.982765742928024,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Schangnau',
        PLZ: 6197,
        Zusatzziffer: 0,
        Gemeindename: 'Schangnau',
        'BFS-Nr': 906,
        Kantonskürzel: 'BE',
        E: 7.863773546917171,
        N: 46.82823361985927,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Signau',
        PLZ: 3534,
        Zusatzziffer: 0,
        Gemeindename: 'Signau',
        'BFS-Nr': 907,
        Kantonskürzel: 'BE',
        E: 7.724510044243205,
        N: 46.92057165006197,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Schüpbach',
        PLZ: 3535,
        Zusatzziffer: 0,
        Gemeindename: 'Signau',
        'BFS-Nr': 907,
        Kantonskürzel: 'BE',
        E: 7.734789806007488,
        N: 46.9286160548714,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Trub',
        PLZ: 3556,
        Zusatzziffer: 0,
        Gemeindename: 'Trub',
        'BFS-Nr': 908,
        Kantonskürzel: 'BE',
        E: 7.8774235899654395,
        N: 46.94314060455192,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Fankhaus (Trub)',
        PLZ: 3557,
        Zusatzziffer: 0,
        Gemeindename: 'Trub',
        'BFS-Nr': 908,
        Kantonskürzel: 'BE',
        E: 7.908634481503582,
        N: 46.967261272061435,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Trubschachen',
        PLZ: 3555,
        Zusatzziffer: 0,
        Gemeindename: 'Trubschachen',
        'BFS-Nr': 909,
        Kantonskürzel: 'BE',
        E: 7.845703574511871,
        N: 46.9229091781267,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Amsoldingen',
        PLZ: 3633,
        Zusatzziffer: 0,
        Gemeindename: 'Amsoldingen',
        'BFS-Nr': 921,
        Kantonskürzel: 'BE',
        E: 7.579183783690713,
        N: 46.727147358264965,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Blumenstein',
        PLZ: 3638,
        Zusatzziffer: 0,
        Gemeindename: 'Blumenstein',
        'BFS-Nr': 922,
        Kantonskürzel: 'BE',
        E: 7.52038436514855,
        N: 46.7411494029496,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Heimenschwand',
        PLZ: 3615,
        Zusatzziffer: 0,
        Gemeindename: 'Buchholterberg',
        'BFS-Nr': 923,
        Kantonskürzel: 'BE',
        E: 7.697295418925797,
        N: 46.82846100309827,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Eriz',
        PLZ: 3619,
        Zusatzziffer: 0,
        Gemeindename: 'Eriz',
        'BFS-Nr': 924,
        Kantonskürzel: 'BE',
        E: 7.777500137070619,
        N: 46.78826364158483,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Fahrni b. Thun',
        PLZ: 3617,
        Zusatzziffer: 0,
        Gemeindename: 'Fahrni',
        'BFS-Nr': 925,
        Kantonskürzel: 'BE',
        E: 7.658341030698468,
        N: 46.79806291361333,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Heiligenschwendi',
        PLZ: 3625,
        Zusatzziffer: 0,
        Gemeindename: 'Heiligenschwendi',
        'BFS-Nr': 927,
        Kantonskürzel: 'BE',
        E: 7.679271330419852,
        N: 46.75136977234438,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Heimberg',
        PLZ: 3627,
        Zusatzziffer: 0,
        Gemeindename: 'Heimberg',
        'BFS-Nr': 928,
        Kantonskürzel: 'BE',
        E: 7.6015527578903574,
        N: 46.789615046180174,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Hünibach',
        PLZ: 3626,
        Zusatzziffer: 0,
        Gemeindename: 'Hilterfingen',
        'BFS-Nr': 929,
        Kantonskürzel: 'BE',
        E: 7.651250010405704,
        N: 46.745241025991504,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Hilterfingen',
        PLZ: 3652,
        Zusatzziffer: 0,
        Gemeindename: 'Hilterfingen',
        'BFS-Nr': 929,
        Kantonskürzel: 'BE',
        E: 7.6622933472277985,
        N: 46.74068328960055,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Homberg b. Thun',
        PLZ: 3622,
        Zusatzziffer: 0,
        Gemeindename: 'Homberg',
        'BFS-Nr': 931,
        Kantonskürzel: 'BE',
        E: 7.683809773149117,
        N: 46.7768968947765,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Horrenbach',
        PLZ: 3623,
        Zusatzziffer: 2,
        Gemeindename: 'Horrenbach-Buchen',
        'BFS-Nr': 932,
        Kantonskürzel: 'BE',
        E: 7.752449493146565,
        N: 46.77787754081303,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Buchen BE',
        PLZ: 3623,
        Zusatzziffer: 3,
        Gemeindename: 'Horrenbach-Buchen',
        'BFS-Nr': 932,
        Kantonskürzel: 'BE',
        E: 7.709189663941494,
        N: 46.7825102225698,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Oberhofen am Thunersee',
        PLZ: 3653,
        Zusatzziffer: 0,
        Gemeindename: 'Oberhofen am Thunersee',
        'BFS-Nr': 934,
        Kantonskürzel: 'BE',
        E: 7.668508290192274,
        N: 46.73119687478233,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Pohlern',
        PLZ: 3638,
        Zusatzziffer: 1,
        Gemeindename: 'Pohlern',
        'BFS-Nr': 936,
        Kantonskürzel: 'BE',
        E: 7.535254702213442,
        N: 46.72515071335632,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Gunten',
        PLZ: 3654,
        Zusatzziffer: 0,
        Gemeindename: 'Sigriswil',
        'BFS-Nr': 938,
        Kantonskürzel: 'BE',
        E: 7.701540588101549,
        N: 46.71283105970267,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Sigriswil',
        PLZ: 3655,
        Zusatzziffer: 0,
        Gemeindename: 'Sigriswil',
        'BFS-Nr': 938,
        Kantonskürzel: 'BE',
        E: 7.711252120191758,
        N: 46.71693613789569,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Tschingel ob Gunten',
        PLZ: 3656,
        Zusatzziffer: 0,
        Gemeindename: 'Sigriswil',
        'BFS-Nr': 938,
        Kantonskürzel: 'BE',
        E: 7.70863472347316,
        N: 46.72388602006089,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Aeschlen ob Gunten',
        PLZ: 3656,
        Zusatzziffer: 1,
        Gemeindename: 'Sigriswil',
        'BFS-Nr': 938,
        Kantonskürzel: 'BE',
        E: 7.698898760469301,
        N: 46.71981507486961,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Ringoldswil',
        PLZ: 3656,
        Zusatzziffer: 2,
        Gemeindename: 'Sigriswil',
        'BFS-Nr': 938,
        Kantonskürzel: 'BE',
        E: 7.694541343500279,
        N: 46.73340609073297,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Schwanden (Sigriswil)',
        PLZ: 3657,
        Zusatzziffer: 0,
        Gemeindename: 'Sigriswil',
        'BFS-Nr': 938,
        Kantonskürzel: 'BE',
        E: 7.716303703564201,
        N: 46.735644269968546,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Merligen',
        PLZ: 3658,
        Zusatzziffer: 0,
        Gemeindename: 'Sigriswil',
        'BFS-Nr': 938,
        Kantonskürzel: 'BE',
        E: 7.737603394009352,
        N: 46.69893125195276,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Steffisburg',
        PLZ: 3612,
        Zusatzziffer: 0,
        Gemeindename: 'Steffisburg',
        'BFS-Nr': 939,
        Kantonskürzel: 'BE',
        E: 7.638250768614873,
        N: 46.782682727069314,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Steffisburg',
        PLZ: 3613,
        Zusatzziffer: 0,
        Gemeindename: 'Steffisburg',
        'BFS-Nr': 939,
        Kantonskürzel: 'BE',
        E: 7.614530432913175,
        N: 46.771039566980285,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Schwendibach',
        PLZ: 3624,
        Zusatzziffer: 2,
        Gemeindename: 'Steffisburg',
        'BFS-Nr': 939,
        Kantonskürzel: 'BE',
        E: 7.661917560879021,
        N: 46.76992927409472,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Teuffenthal b. Thun',
        PLZ: 3623,
        Zusatzziffer: 0,
        Gemeindename: 'Teuffenthal (BE)',
        'BFS-Nr': 940,
        Kantonskürzel: 'BE',
        E: 7.711100579671612,
        N: 46.76681165345761,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Thierachern',
        PLZ: 3634,
        Zusatzziffer: 0,
        Gemeindename: 'Thierachern',
        'BFS-Nr': 941,
        Kantonskürzel: 'BE',
        E: 7.573573472736505,
        N: 46.75410585082208,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Thun',
        PLZ: 3600,
        Zusatzziffer: 0,
        Gemeindename: 'Thun',
        'BFS-Nr': 942,
        Kantonskürzel: 'BE',
        E: 7.623636969722557,
        N: 46.75541428405191,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Thun',
        PLZ: 3603,
        Zusatzziffer: 0,
        Gemeindename: 'Thun',
        'BFS-Nr': 942,
        Kantonskürzel: 'BE',
        E: 7.600145125862506,
        N: 46.76204218119255,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Thun',
        PLZ: 3604,
        Zusatzziffer: 0,
        Gemeindename: 'Thun',
        'BFS-Nr': 942,
        Kantonskürzel: 'BE',
        E: 7.620698071687906,
        N: 46.7405093157627,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Thun',
        PLZ: 3608,
        Zusatzziffer: 0,
        Gemeindename: 'Thun',
        'BFS-Nr': 942,
        Kantonskürzel: 'BE',
        E: 7.598845359148188,
        N: 46.73993775582931,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Goldiwil (Thun)',
        PLZ: 3624,
        Zusatzziffer: 0,
        Gemeindename: 'Thun',
        'BFS-Nr': 942,
        Kantonskürzel: 'BE',
        E: 7.665210374926323,
        N: 46.7617974599869,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Gwatt (Thun)',
        PLZ: 3645,
        Zusatzziffer: 0,
        Gemeindename: 'Thun',
        'BFS-Nr': 942,
        Kantonskürzel: 'BE',
        E: 7.623122556980317,
        N: 46.72850511833907,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Uebeschi',
        PLZ: 3635,
        Zusatzziffer: 0,
        Gemeindename: 'Uebeschi',
        'BFS-Nr': 943,
        Kantonskürzel: 'BE',
        E: 7.5549146776499185,
        N: 46.738166988284675,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Uetendorf',
        PLZ: 3661,
        Zusatzziffer: 0,
        Gemeindename: 'Uetendorf',
        'BFS-Nr': 944,
        Kantonskürzel: 'BE',
        E: 7.573894651224135,
        N: 46.774755816441115,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Unterlangenegg',
        PLZ: 3614,
        Zusatzziffer: 0,
        Gemeindename: 'Unterlangenegg',
        'BFS-Nr': 945,
        Kantonskürzel: 'BE',
        E: 7.692786539904355,
        N: 46.798145581653394,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Schwarzenegg',
        PLZ: 3616,
        Zusatzziffer: 0,
        Gemeindename: 'Unterlangenegg',
        'BFS-Nr': 945,
        Kantonskürzel: 'BE',
        E: 7.714785926718691,
        N: 46.79678952279901,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Süderen',
        PLZ: 3618,
        Zusatzziffer: 0,
        Gemeindename: 'Wachseldorn',
        'BFS-Nr': 946,
        Kantonskürzel: 'BE',
        E: 7.7563896521537306,
        N: 46.81804329327336,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Zwieselberg',
        PLZ: 3645,
        Zusatzziffer: 2,
        Gemeindename: 'Zwieselberg',
        'BFS-Nr': 947,
        Kantonskürzel: 'BE',
        E: 7.617301000262844,
        N: 46.70848160706161,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Längenbühl',
        PLZ: 3636,
        Zusatzziffer: 0,
        Gemeindename: 'Forst-Längenbühl',
        'BFS-Nr': 948,
        Kantonskürzel: 'BE',
        E: 7.528942462415077,
        N: 46.756007281503656,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Forst b. Längenbühl',
        PLZ: 3636,
        Zusatzziffer: 2,
        Gemeindename: 'Forst-Längenbühl',
        'BFS-Nr': 948,
        Kantonskürzel: 'BE',
        E: 7.522372838559948,
        N: 46.76339362585183,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Affoltern im Emmental',
        PLZ: 3416,
        Zusatzziffer: 0,
        Gemeindename: 'Affoltern im Emmental',
        'BFS-Nr': 951,
        Kantonskürzel: 'BE',
        E: 7.734751217233985,
        N: 47.06450812358541,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Weier im Emmental',
        PLZ: 3462,
        Zusatzziffer: 0,
        Gemeindename: 'Affoltern im Emmental',
        'BFS-Nr': 951,
        Kantonskürzel: 'BE',
        E: 7.753373493234869,
        N: 47.06256895513344,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Häusernmoos im Emmental',
        PLZ: 3463,
        Zusatzziffer: 0,
        Gemeindename: 'Affoltern im Emmental',
        'BFS-Nr': 951,
        Kantonskürzel: 'BE',
        E: 7.749060566808205,
        N: 47.079433583527646,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Dürrenroth',
        PLZ: 3465,
        Zusatzziffer: 0,
        Gemeindename: 'Dürrenroth',
        'BFS-Nr': 952,
        Kantonskürzel: 'BE',
        E: 7.791371075873477,
        N: 47.09049319949659,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Eriswil',
        PLZ: 4952,
        Zusatzziffer: 0,
        Gemeindename: 'Eriswil',
        'BFS-Nr': 953,
        Kantonskürzel: 'BE',
        E: 7.85072608189866,
        N: 47.078117326387485,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Huttwil',
        PLZ: 4950,
        Zusatzziffer: 0,
        Gemeindename: 'Huttwil',
        'BFS-Nr': 954,
        Kantonskürzel: 'BE',
        E: 7.849953943966639,
        N: 47.11372038094467,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Schwarzenbach (Huttwil)',
        PLZ: 4953,
        Zusatzziffer: 0,
        Gemeindename: 'Huttwil',
        'BFS-Nr': 954,
        Kantonskürzel: 'BE',
        E: 7.832424015096589,
        N: 47.106369306447576,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Lützelflüh-Goldbach',
        PLZ: 3432,
        Zusatzziffer: 0,
        Gemeindename: 'Lützelflüh',
        'BFS-Nr': 955,
        Kantonskürzel: 'BE',
        E: 7.682327667894533,
        N: 47.005112768312195,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Ramsei',
        PLZ: 3435,
        Zusatzziffer: 0,
        Gemeindename: 'Lützelflüh',
        'BFS-Nr': 955,
        Kantonskürzel: 'BE',
        E: 7.708427516385098,
        N: 46.99845045636337,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Ranflüh',
        PLZ: 3439,
        Zusatzziffer: 0,
        Gemeindename: 'Lützelflüh',
        'BFS-Nr': 955,
        Kantonskürzel: 'BE',
        E: 7.737947242253612,
        N: 46.988559527045005,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Grünenmatt',
        PLZ: 3452,
        Zusatzziffer: 0,
        Gemeindename: 'Lützelflüh',
        'BFS-Nr': 955,
        Kantonskürzel: 'BE',
        E: 7.723995619801704,
        N: 47.008842809188955,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Rüegsauschachen',
        PLZ: 3415,
        Zusatzziffer: 3,
        Gemeindename: 'Rüegsau',
        'BFS-Nr': 956,
        Kantonskürzel: 'BE',
        E: 7.660688112484303,
        N: 47.01821194724288,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Rüegsau',
        PLZ: 3417,
        Zusatzziffer: 0,
        Gemeindename: 'Rüegsau',
        'BFS-Nr': 956,
        Kantonskürzel: 'BE',
        E: 7.674543290911989,
        N: 47.02477540829484,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Rüegsbach',
        PLZ: 3418,
        Zusatzziffer: 0,
        Gemeindename: 'Rüegsau',
        'BFS-Nr': 956,
        Kantonskürzel: 'BE',
        E: 7.691750512019501,
        N: 47.033929485357326,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Sumiswald',
        PLZ: 3454,
        Zusatzziffer: 0,
        Gemeindename: 'Sumiswald',
        'BFS-Nr': 957,
        Kantonskürzel: 'BE',
        E: 7.744992014523709,
        N: 47.02777136699576,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Grünen',
        PLZ: 3455,
        Zusatzziffer: 0,
        Gemeindename: 'Sumiswald',
        'BFS-Nr': 957,
        Kantonskürzel: 'BE',
        E: 7.741121547090813,
        N: 47.02493666395518,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Wasen im Emmental',
        PLZ: 3457,
        Zusatzziffer: 0,
        Gemeindename: 'Sumiswald',
        'BFS-Nr': 957,
        Kantonskürzel: 'BE',
        E: 7.797132554587479,
        N: 47.04307573648073,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Heimisbach',
        PLZ: 3453,
        Zusatzziffer: 0,
        Gemeindename: 'Trachselwald',
        'BFS-Nr': 958,
        Kantonskürzel: 'BE',
        E: 7.76098550187369,
        N: 47.012005419611974,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Trachselwald',
        PLZ: 3456,
        Zusatzziffer: 0,
        Gemeindename: 'Trachselwald',
        'BFS-Nr': 958,
        Kantonskürzel: 'BE',
        E: 7.737771722187725,
        N: 47.0165878247912,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Schmidigen-Mühleweg',
        PLZ: 3464,
        Zusatzziffer: 0,
        Gemeindename: 'Walterswil (BE)',
        'BFS-Nr': 959,
        Kantonskürzel: 'BE',
        E: 7.743642530139465,
        N: 47.09473672207892,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Walterswil BE',
        PLZ: 4942,
        Zusatzziffer: 0,
        Gemeindename: 'Walterswil (BE)',
        'BFS-Nr': 959,
        Kantonskürzel: 'BE',
        E: 7.776437558302435,
        N: 47.11283883785357,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Wyssachen',
        PLZ: 4954,
        Zusatzziffer: 0,
        Gemeindename: 'Wyssachen',
        'BFS-Nr': 960,
        Kantonskürzel: 'BE',
        E: 7.824445707927513,
        N: 47.0791664266981,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Attiswil',
        PLZ: 4536,
        Zusatzziffer: 0,
        Gemeindename: 'Attiswil',
        'BFS-Nr': 971,
        Kantonskürzel: 'BE',
        E: 7.614019170608872,
        N: 47.246526951772786,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Berken',
        PLZ: 3376,
        Zusatzziffer: 2,
        Gemeindename: 'Berken',
        'BFS-Nr': 972,
        Kantonskürzel: 'BE',
        E: 7.705945691391089,
        N: 47.22577489017535,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Bettenhausen',
        PLZ: 3366,
        Zusatzziffer: 0,
        Gemeindename: 'Bettenhausen',
        'BFS-Nr': 973,
        Kantonskürzel: 'BE',
        E: 7.718286781678835,
        N: 47.17331809702935,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Bollodingen',
        PLZ: 3366,
        Zusatzziffer: 2,
        Gemeindename: 'Bettenhausen',
        'BFS-Nr': 973,
        Kantonskürzel: 'BE',
        E: 7.705620316310311,
        N: 47.16618009967446,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Farnern',
        PLZ: 4539,
        Zusatzziffer: 2,
        Gemeindename: 'Farnern',
        'BFS-Nr': 975,
        Kantonskürzel: 'BE',
        E: 7.617789882321465,
        N: 47.2669293895526,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Graben',
        PLZ: 3376,
        Zusatzziffer: 0,
        Gemeindename: 'Graben',
        'BFS-Nr': 976,
        Kantonskürzel: 'BE',
        E: 7.717802410199845,
        N: 47.21527238828845,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Wanzwil',
        PLZ: 3372,
        Zusatzziffer: 0,
        Gemeindename: 'Heimenhausen',
        'BFS-Nr': 977,
        Kantonskürzel: 'BE',
        E: 7.691999405427046,
        N: 47.19890459109637,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Röthenbach Herzogenbuchsee',
        PLZ: 3373,
        Zusatzziffer: 0,
        Gemeindename: 'Heimenhausen',
        'BFS-Nr': 977,
        Kantonskürzel: 'BE',
        E: 7.683439749662232,
        N: 47.20663189120699,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Heimenhausen',
        PLZ: 3373,
        Zusatzziffer: 2,
        Gemeindename: 'Heimenhausen',
        'BFS-Nr': 977,
        Kantonskürzel: 'BE',
        E: 7.697933446623731,
        N: 47.21360131687935,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Herzogenbuchsee',
        PLZ: 3360,
        Zusatzziffer: 0,
        Gemeindename: 'Herzogenbuchsee',
        'BFS-Nr': 979,
        Kantonskürzel: 'BE',
        E: 7.714362194025975,
        N: 47.19453410036497,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Oberönz',
        PLZ: 3363,
        Zusatzziffer: 0,
        Gemeindename: 'Herzogenbuchsee',
        'BFS-Nr': 979,
        Kantonskürzel: 'BE',
        E: 7.694315146197812,
        N: 47.17943485640302,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Inkwil',
        PLZ: 3375,
        Zusatzziffer: 0,
        Gemeindename: 'Inkwil',
        'BFS-Nr': 980,
        Kantonskürzel: 'BE',
        E: 7.673676168370711,
        N: 47.20140889385853,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Niederbipp',
        PLZ: 4704,
        Zusatzziffer: 0,
        Gemeindename: 'Niederbipp',
        'BFS-Nr': 981,
        Kantonskürzel: 'BE',
        E: 7.701828054904519,
        N: 47.268390794142505,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Wolfisberg',
        PLZ: 4704,
        Zusatzziffer: 2,
        Gemeindename: 'Niederbipp',
        'BFS-Nr': 981,
        Kantonskürzel: 'BE',
        E: 7.659927589857082,
        N: 47.27485250233941,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Niederönz',
        PLZ: 3362,
        Zusatzziffer: 0,
        Gemeindename: 'Niederönz',
        'BFS-Nr': 982,
        Kantonskürzel: 'BE',
        E: 7.691470435308258,
        N: 47.18435637534735,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Oberbipp',
        PLZ: 4538,
        Zusatzziffer: 0,
        Gemeindename: 'Oberbipp',
        'BFS-Nr': 983,
        Kantonskürzel: 'BE',
        E: 7.6567514273407795,
        N: 47.26009250566314,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Ochlenberg',
        PLZ: 3367,
        Zusatzziffer: 2,
        Gemeindename: 'Ochlenberg',
        'BFS-Nr': 985,
        Kantonskürzel: 'BE',
        E: 7.736038004799576,
        N: 47.14995127361391,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Oschwand',
        PLZ: 3476,
        Zusatzziffer: 0,
        Gemeindename: 'Ochlenberg',
        'BFS-Nr': 985,
        Kantonskürzel: 'BE',
        E: 7.713036211695824,
        N: 47.14055057835774,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Rumisberg',
        PLZ: 4539,
        Zusatzziffer: 0,
        Gemeindename: 'Rumisberg',
        'BFS-Nr': 987,
        Kantonskürzel: 'BE',
        E: 7.639793524836978,
        N: 47.26560557306405,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Grasswil',
        PLZ: 3365,
        Zusatzziffer: 0,
        Gemeindename: 'Seeberg',
        'BFS-Nr': 988,
        Kantonskürzel: 'BE',
        E: 7.666812110163827,
        N: 47.145751125190614,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Seeberg',
        PLZ: 3365,
        Zusatzziffer: 2,
        Gemeindename: 'Seeberg',
        'BFS-Nr': 988,
        Kantonskürzel: 'BE',
        E: 7.664544112613465,
        N: 47.15494020101881,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Riedtwil',
        PLZ: 3475,
        Zusatzziffer: 0,
        Gemeindename: 'Seeberg',
        'BFS-Nr': 988,
        Kantonskürzel: 'BE',
        E: 7.697140781117976,
        N: 47.14240762569899,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Hermiswil',
        PLZ: 3475,
        Zusatzziffer: 1,
        Gemeindename: 'Seeberg',
        'BFS-Nr': 988,
        Kantonskürzel: 'BE',
        E: 7.700566903156392,
        N: 47.155929291892114,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Thörigen',
        PLZ: 3367,
        Zusatzziffer: 0,
        Gemeindename: 'Thörigen',
        'BFS-Nr': 989,
        Kantonskürzel: 'BE',
        E: 7.7297111726920225,
        N: 47.17495667485223,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Walliswil b. Niederbipp',
        PLZ: 3380,
        Zusatzziffer: 2,
        Gemeindename: 'Walliswil bei Niederbipp',
        'BFS-Nr': 990,
        Kantonskürzel: 'BE',
        E: 7.6917830808013035,
        N: 47.23736749093206,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Walliswil b. Wangen',
        PLZ: 3377,
        Zusatzziffer: 0,
        Gemeindename: 'Walliswil bei Wangen',
        'BFS-Nr': 991,
        Kantonskürzel: 'BE',
        E: 7.682899722420797,
        N: 47.22885573059148,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Wangen an der Aare',
        PLZ: 3380,
        Zusatzziffer: 0,
        Gemeindename: 'Wangen an der Aare',
        'BFS-Nr': 992,
        Kantonskürzel: 'BE',
        E: 7.656026423123099,
        N: 47.2359176240779,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Wangenried',
        PLZ: 3374,
        Zusatzziffer: 0,
        Gemeindename: 'Wangenried',
        'BFS-Nr': 993,
        Kantonskürzel: 'BE',
        E: 7.655651501195018,
        N: 47.21712844835686,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Wiedlisbach',
        PLZ: 4537,
        Zusatzziffer: 0,
        Gemeindename: 'Wiedlisbach',
        'BFS-Nr': 995,
        Kantonskürzel: 'BE',
        E: 7.647290603486621,
        N: 47.252621920486796,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Doppleschwand',
        PLZ: 6112,
        Zusatzziffer: 0,
        Gemeindename: 'Doppleschwand',
        'BFS-Nr': 1001,
        Kantonskürzel: 'LU',
        E: 8.055450010086613,
        N: 47.018924170998105,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Entlebuch',
        PLZ: 6162,
        Zusatzziffer: 0,
        Gemeindename: 'Entlebuch',
        'BFS-Nr': 1002,
        Kantonskürzel: 'LU',
        E: 8.06472889235839,
        N: 46.99222240010738,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Rengg',
        PLZ: 6162,
        Zusatzziffer: 2,
        Gemeindename: 'Entlebuch',
        'BFS-Nr': 1002,
        Kantonskürzel: 'LU',
        E: 8.0913058628689,
        N: 47.00346515621899,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Finsterwald b. Entlebuch',
        PLZ: 6162,
        Zusatzziffer: 3,
        Gemeindename: 'Entlebuch',
        'BFS-Nr': 1002,
        Kantonskürzel: 'LU',
        E: 8.10239221385985,
        N: 46.973482678069686,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Ebnet',
        PLZ: 6163,
        Zusatzziffer: 0,
        Gemeindename: 'Entlebuch',
        'BFS-Nr': 1002,
        Kantonskürzel: 'LU',
        E: 8.077154142393733,
        N: 47.02178538251775,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Flühli LU',
        PLZ: 6173,
        Zusatzziffer: 0,
        Gemeindename: 'Flühli',
        'BFS-Nr': 1004,
        Kantonskürzel: 'LU',
        E: 8.015381843686518,
        N: 46.884905360558676,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Sörenberg',
        PLZ: 6174,
        Zusatzziffer: 0,
        Gemeindename: 'Flühli',
        'BFS-Nr': 1004,
        Kantonskürzel: 'LU',
        E: 8.032298241001653,
        N: 46.82337914881031,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Hasle LU',
        PLZ: 6166,
        Zusatzziffer: 0,
        Gemeindename: 'Hasle (LU)',
        'BFS-Nr': 1005,
        Kantonskürzel: 'LU',
        E: 8.05138603702749,
        N: 46.98015549314039,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Fontannen b. Wolhusen',
        PLZ: 6110,
        Zusatzziffer: 2,
        Gemeindename: 'Romoos',
        'BFS-Nr': 1007,
        Kantonskürzel: 'LU',
        E: 7.985963885645713,
        N: 47.025957178308175,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Romoos',
        PLZ: 6113,
        Zusatzziffer: 0,
        Gemeindename: 'Romoos',
        'BFS-Nr': 1007,
        Kantonskürzel: 'LU',
        E: 8.024017757323788,
        N: 47.011001832493115,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Bramboden',
        PLZ: 6167,
        Zusatzziffer: 0,
        Gemeindename: 'Romoos',
        'BFS-Nr': 1007,
        Kantonskürzel: 'LU',
        E: 7.985526167683116,
        N: 46.979034372980266,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Schüpfheim',
        PLZ: 6170,
        Zusatzziffer: 0,
        Gemeindename: 'Schüpfheim',
        'BFS-Nr': 1008,
        Kantonskürzel: 'LU',
        E: 8.019775159318362,
        N: 46.95316622432971,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Schachen LU',
        PLZ: 6105,
        Zusatzziffer: 0,
        Gemeindename: 'Werthenstein',
        'BFS-Nr': 1009,
        Kantonskürzel: 'LU',
        E: 8.14281197829824,
        N: 47.03829045492216,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Werthenstein',
        PLZ: 6106,
        Zusatzziffer: 0,
        Gemeindename: 'Werthenstein',
        'BFS-Nr': 1009,
        Kantonskürzel: 'LU',
        E: 8.103452949849679,
        N: 47.05439260636268,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Escholzmatt',
        PLZ: 6182,
        Zusatzziffer: 0,
        Gemeindename: 'Escholzmatt-Marbach',
        'BFS-Nr': 1010,
        Kantonskürzel: 'LU',
        E: 7.9347387149870325,
        N: 46.913977324865705,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Wiggen',
        PLZ: 6192,
        Zusatzziffer: 0,
        Gemeindename: 'Escholzmatt-Marbach',
        'BFS-Nr': 1010,
        Kantonskürzel: 'LU',
        E: 7.909791585139591,
        N: 46.89547019714946,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Marbach LU',
        PLZ: 6196,
        Zusatzziffer: 0,
        Gemeindename: 'Escholzmatt-Marbach',
        'BFS-Nr': 1010,
        Kantonskürzel: 'LU',
        E: 7.900732559144851,
        N: 46.852910800813106,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Aesch LU',
        PLZ: 6287,
        Zusatzziffer: 0,
        Gemeindename: 'Aesch (LU)',
        'BFS-Nr': 1021,
        Kantonskürzel: 'LU',
        E: 8.239119923658713,
        N: 47.254709748997456,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Ballwil',
        PLZ: 6275,
        Zusatzziffer: 0,
        Gemeindename: 'Ballwil',
        'BFS-Nr': 1023,
        Kantonskürzel: 'LU',
        E: 8.320719192730362,
        N: 47.152289182070085,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Emmenbrücke',
        PLZ: 6020,
        Zusatzziffer: 0,
        Gemeindename: 'Emmen',
        'BFS-Nr': 1024,
        Kantonskürzel: 'LU',
        E: 8.273687163555472,
        N: 47.07730244766744,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Emmen',
        PLZ: 6032,
        Zusatzziffer: 0,
        Gemeindename: 'Emmen',
        'BFS-Nr': 1024,
        Kantonskürzel: 'LU',
        E: 8.302483974169057,
        N: 47.07901664764685,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Ermensee',
        PLZ: 6294,
        Zusatzziffer: 0,
        Gemeindename: 'Ermensee',
        'BFS-Nr': 1025,
        Kantonskürzel: 'LU',
        E: 8.24275630088384,
        N: 47.227900290538095,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Eschenbach LU',
        PLZ: 6274,
        Zusatzziffer: 0,
        Gemeindename: 'Eschenbach (LU)',
        'BFS-Nr': 1026,
        Kantonskürzel: 'LU',
        E: 8.320291928946752,
        N: 47.13277524760257,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Gelfingen',
        PLZ: 6284,
        Zusatzziffer: 0,
        Gemeindename: 'Hitzkirch',
        'BFS-Nr': 1030,
        Kantonskürzel: 'LU',
        E: 8.271534155916937,
        N: 47.214611339528076,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Sulz LU',
        PLZ: 6284,
        Zusatzziffer: 2,
        Gemeindename: 'Hitzkirch',
        'BFS-Nr': 1030,
        Kantonskürzel: 'LU',
        E: 8.288927851799354,
        N: 47.22011541741184,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Hitzkirch',
        PLZ: 6285,
        Zusatzziffer: 0,
        Gemeindename: 'Hitzkirch',
        'BFS-Nr': 1030,
        Kantonskürzel: 'LU',
        E: 8.264382548752486,
        N: 47.225227795472485,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Retschwil',
        PLZ: 6285,
        Zusatzziffer: 2,
        Gemeindename: 'Hitzkirch',
        'BFS-Nr': 1030,
        Kantonskürzel: 'LU',
        E: 8.253482284276659,
        N: 47.19290976069335,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Altwis',
        PLZ: 6286,
        Zusatzziffer: 0,
        Gemeindename: 'Hitzkirch',
        'BFS-Nr': 1030,
        Kantonskürzel: 'LU',
        E: 8.247821559432232,
        N: 47.23846182725806,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Müswangen',
        PLZ: 6289,
        Zusatzziffer: 0,
        Gemeindename: 'Hitzkirch',
        'BFS-Nr': 1030,
        Kantonskürzel: 'LU',
        E: 8.289171108206192,
        N: 47.23831015379594,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Hämikon',
        PLZ: 6289,
        Zusatzziffer: 2,
        Gemeindename: 'Hitzkirch',
        'BFS-Nr': 1030,
        Kantonskürzel: 'LU',
        E: 8.27188577341186,
        N: 47.23732824690194,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Hämikon',
        PLZ: 6289,
        Zusatzziffer: 2,
        Gemeindename: 'Hitzkirch',
        'BFS-Nr': 1030,
        Kantonskürzel: 'LU',
        E: 8.299981284108041,
        N: 47.243191852970256,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Mosen',
        PLZ: 6295,
        Zusatzziffer: 0,
        Gemeindename: 'Hitzkirch',
        'BFS-Nr': 1030,
        Kantonskürzel: 'LU',
        E: 8.227781363029864,
        N: 47.242831942300796,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Hochdorf',
        PLZ: 6280,
        Zusatzziffer: 0,
        Gemeindename: 'Hochdorf',
        'BFS-Nr': 1031,
        Kantonskürzel: 'LU',
        E: 8.293029699626281,
        N: 47.16689199084022,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Urswil',
        PLZ: 6280,
        Zusatzziffer: 2,
        Gemeindename: 'Hochdorf',
        'BFS-Nr': 1031,
        Kantonskürzel: 'LU',
        E: 8.293044783583102,
        N: 47.14955276324934,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Baldegg',
        PLZ: 6283,
        Zusatzziffer: 0,
        Gemeindename: 'Hochdorf',
        'BFS-Nr': 1031,
        Kantonskürzel: 'LU',
        E: 8.280476624561453,
        N: 47.18563284891842,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Hohenrain',
        PLZ: 6276,
        Zusatzziffer: 0,
        Gemeindename: 'Hohenrain',
        'BFS-Nr': 1032,
        Kantonskürzel: 'LU',
        E: 8.318349737837538,
        N: 47.179486510099686,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Kleinwangen',
        PLZ: 6277,
        Zusatzziffer: 0,
        Gemeindename: 'Hohenrain',
        'BFS-Nr': 1032,
        Kantonskürzel: 'LU',
        E: 8.294645319114647,
        N: 47.19492452468058,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Lieli LU',
        PLZ: 6277,
        Zusatzziffer: 2,
        Gemeindename: 'Hohenrain',
        'BFS-Nr': 1032,
        Kantonskürzel: 'LU',
        E: 8.29877585307485,
        N: 47.209713095538504,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Inwil',
        PLZ: 6034,
        Zusatzziffer: 0,
        Gemeindename: 'Inwil',
        'BFS-Nr': 1033,
        Kantonskürzel: 'LU',
        E: 8.35028079588642,
        N: 47.12219475683984,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Rain',
        PLZ: 6026,
        Zusatzziffer: 0,
        Gemeindename: 'Rain',
        'BFS-Nr': 1037,
        Kantonskürzel: 'LU',
        E: 8.263189012033356,
        N: 47.1297286336635,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Römerswil LU',
        PLZ: 6027,
        Zusatzziffer: 0,
        Gemeindename: 'Römerswil',
        'BFS-Nr': 1039,
        Kantonskürzel: 'LU',
        E: 8.24591599424094,
        N: 47.169712986895036,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Herlisberg',
        PLZ: 6028,
        Zusatzziffer: 0,
        Gemeindename: 'Römerswil',
        'BFS-Nr': 1039,
        Kantonskürzel: 'LU',
        E: 8.230108193292716,
        N: 47.19844005669513,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Rothenburg',
        PLZ: 6023,
        Zusatzziffer: 0,
        Gemeindename: 'Rothenburg',
        'BFS-Nr': 1040,
        Kantonskürzel: 'LU',
        E: 8.273056533997972,
        N: 47.09392638895981,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Schongau',
        PLZ: 6288,
        Zusatzziffer: 0,
        Gemeindename: 'Schongau',
        'BFS-Nr': 1041,
        Kantonskürzel: 'LU',
        E: 8.267460043734024,
        N: 47.269097748925965,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Adligenswil',
        PLZ: 6043,
        Zusatzziffer: 0,
        Gemeindename: 'Adligenswil',
        'BFS-Nr': 1051,
        Kantonskürzel: 'LU',
        E: 8.365095622611106,
        N: 47.070576874814634,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Buchrain',
        PLZ: 6033,
        Zusatzziffer: 0,
        Gemeindename: 'Buchrain',
        'BFS-Nr': 1052,
        Kantonskürzel: 'LU',
        E: 8.346013670169837,
        N: 47.094766672207015,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Perlen',
        PLZ: 6035,
        Zusatzziffer: 0,
        Gemeindename: 'Buchrain',
        'BFS-Nr': 1052,
        Kantonskürzel: 'LU',
        E: 8.359783142680365,
        N: 47.11063629516793,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Dierikon',
        PLZ: 6036,
        Zusatzziffer: 0,
        Gemeindename: 'Dierikon',
        'BFS-Nr': 1053,
        Kantonskürzel: 'LU',
        E: 8.37187899909396,
        N: 47.09734982285052,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Ebikon',
        PLZ: 6030,
        Zusatzziffer: 0,
        Gemeindename: 'Ebikon',
        'BFS-Nr': 1054,
        Kantonskürzel: 'LU',
        E: 8.340223080658925,
        N: 47.081791064545996,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Gisikon',
        PLZ: 6038,
        Zusatzziffer: 0,
        Gemeindename: 'Gisikon',
        'BFS-Nr': 1055,
        Kantonskürzel: 'LU',
        E: 8.399282324064968,
        N: 47.12712616408421,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Greppen',
        PLZ: 6404,
        Zusatzziffer: 0,
        Gemeindename: 'Greppen',
        'BFS-Nr': 1056,
        Kantonskürzel: 'LU',
        E: 8.430056872868333,
        N: 47.05550728696109,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Honau',
        PLZ: 6038,
        Zusatzziffer: 2,
        Gemeindename: 'Honau',
        'BFS-Nr': 1057,
        Kantonskürzel: 'LU',
        E: 8.406206456615939,
        N: 47.13286037421615,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'St. Niklausen LU',
        PLZ: 6005,
        Zusatzziffer: 2,
        Gemeindename: 'Horw',
        'BFS-Nr': 1058,
        Kantonskürzel: 'LU',
        E: 8.34039129811869,
        N: 47.01932611652858,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Kastanienbaum',
        PLZ: 6047,
        Zusatzziffer: 0,
        Gemeindename: 'Horw',
        'BFS-Nr': 1058,
        Kantonskürzel: 'LU',
        E: 8.333815486200097,
        N: 47.01343857273474,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Horw',
        PLZ: 6048,
        Zusatzziffer: 0,
        Gemeindename: 'Horw',
        'BFS-Nr': 1058,
        Kantonskürzel: 'LU',
        E: 8.312697569206277,
        N: 47.01919161084247,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Kriens',
        PLZ: 6010,
        Zusatzziffer: 0,
        Gemeindename: 'Kriens',
        'BFS-Nr': 1059,
        Kantonskürzel: 'LU',
        E: 8.279095240554934,
        N: 47.033003962707575,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Obernau',
        PLZ: 6012,
        Zusatzziffer: 0,
        Gemeindename: 'Kriens',
        'BFS-Nr': 1059,
        Kantonskürzel: 'LU',
        E: 8.253408051053777,
        N: 47.03215214032783,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Luzern',
        PLZ: 6003,
        Zusatzziffer: 0,
        Gemeindename: 'Luzern',
        'BFS-Nr': 1061,
        Kantonskürzel: 'LU',
        E: 8.297014296749866,
        N: 47.05006542243485,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Luzern',
        PLZ: 6004,
        Zusatzziffer: 0,
        Gemeindename: 'Luzern',
        'BFS-Nr': 1061,
        Kantonskürzel: 'LU',
        E: 8.298118004514452,
        N: 47.06017261139517,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Luzern',
        PLZ: 6005,
        Zusatzziffer: 0,
        Gemeindename: 'Luzern',
        'BFS-Nr': 1061,
        Kantonskürzel: 'LU',
        E: 8.315253464461577,
        N: 47.03930998869998,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Luzern',
        PLZ: 6006,
        Zusatzziffer: 0,
        Gemeindename: 'Luzern',
        'BFS-Nr': 1061,
        Kantonskürzel: 'LU',
        E: 8.333741419828135,
        N: 47.052260778961276,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Luzern',
        PLZ: 6014,
        Zusatzziffer: 0,
        Gemeindename: 'Luzern',
        'BFS-Nr': 1061,
        Kantonskürzel: 'LU',
        E: 8.24173296984837,
        N: 47.05758694143725,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Luzern',
        PLZ: 6015,
        Zusatzziffer: 0,
        Gemeindename: 'Luzern',
        'BFS-Nr': 1061,
        Kantonskürzel: 'LU',
        E: 8.277856714640482,
        N: 47.06370452585225,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Malters',
        PLZ: 6102,
        Zusatzziffer: 0,
        Gemeindename: 'Malters',
        'BFS-Nr': 1062,
        Kantonskürzel: 'LU',
        E: 8.183441946697377,
        N: 47.03594411660316,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Meggen',
        PLZ: 6045,
        Zusatzziffer: 0,
        Gemeindename: 'Meggen',
        'BFS-Nr': 1063,
        Kantonskürzel: 'LU',
        E: 8.37206236632325,
        N: 47.04648663485243,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Meierskappel',
        PLZ: 6344,
        Zusatzziffer: 0,
        Gemeindename: 'Meierskappel',
        'BFS-Nr': 1064,
        Kantonskürzel: 'LU',
        E: 8.442683194463292,
        N: 47.125286232550415,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Root',
        PLZ: 6037,
        Zusatzziffer: 0,
        Gemeindename: 'Root',
        'BFS-Nr': 1065,
        Kantonskürzel: 'LU',
        E: 8.391598702078447,
        N: 47.11422737335049,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Root D4',
        PLZ: 6039,
        Zusatzziffer: 0,
        Gemeindename: 'Root',
        'BFS-Nr': 1065,
        Kantonskürzel: 'LU',
        E: 8.373467402277159,
        N: 47.1044257517085,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Eigenthal',
        PLZ: 6013,
        Zusatzziffer: 0,
        Gemeindename: 'Schwarzenberg',
        'BFS-Nr': 1066,
        Kantonskürzel: 'LU',
        E: 8.21923790153906,
        N: 47.007053792398175,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Schwarzenberg LU',
        PLZ: 6103,
        Zusatzziffer: 0,
        Gemeindename: 'Schwarzenberg',
        'BFS-Nr': 1066,
        Kantonskürzel: 'LU',
        E: 8.17419633802736,
        N: 47.016084544592516,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Udligenswil',
        PLZ: 6044,
        Zusatzziffer: 0,
        Gemeindename: 'Udligenswil',
        'BFS-Nr': 1067,
        Kantonskürzel: 'LU',
        E: 8.399286230353512,
        N: 47.0911185265971,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Vitznau',
        PLZ: 6354,
        Zusatzziffer: 0,
        Gemeindename: 'Vitznau',
        'BFS-Nr': 1068,
        Kantonskürzel: 'LU',
        E: 8.483786092666932,
        N: 47.01037569383016,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Weggis',
        PLZ: 6353,
        Zusatzziffer: 0,
        Gemeindename: 'Weggis',
        'BFS-Nr': 1069,
        Kantonskürzel: 'LU',
        E: 8.43432196164318,
        N: 47.0326962889073,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Rigi Kaltbad',
        PLZ: 6356,
        Zusatzziffer: 0,
        Gemeindename: 'Weggis',
        'BFS-Nr': 1069,
        Kantonskürzel: 'LU',
        E: 8.470984852816969,
        N: 47.043952270720155,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Neudorf',
        PLZ: 6025,
        Zusatzziffer: 0,
        Gemeindename: 'Beromünster',
        'BFS-Nr': 1081,
        Kantonskürzel: 'LU',
        E: 8.209876141436043,
        N: 47.176609511723676,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Beromünster',
        PLZ: 6215,
        Zusatzziffer: 0,
        Gemeindename: 'Beromünster',
        'BFS-Nr': 1081,
        Kantonskürzel: 'LU',
        E: 8.193146251216115,
        N: 47.20702180932944,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Schwarzenbach LU',
        PLZ: 6215,
        Zusatzziffer: 2,
        Gemeindename: 'Beromünster',
        'BFS-Nr': 1081,
        Kantonskürzel: 'LU',
        E: 8.212548702225073,
        N: 47.233876739450416,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Gunzwil',
        PLZ: 6222,
        Zusatzziffer: 0,
        Gemeindename: 'Beromünster',
        'BFS-Nr': 1081,
        Kantonskürzel: 'LU',
        E: 8.179656481827903,
        N: 47.21123375248394,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Büron',
        PLZ: 6233,
        Zusatzziffer: 0,
        Gemeindename: 'Büron',
        'BFS-Nr': 1082,
        Kantonskürzel: 'LU',
        E: 8.090955117676414,
        N: 47.214738867624334,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Buttisholz',
        PLZ: 6018,
        Zusatzziffer: 0,
        Gemeindename: 'Buttisholz',
        'BFS-Nr': 1083,
        Kantonskürzel: 'LU',
        E: 8.094575452171306,
        N: 47.1148614855487,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Eich',
        PLZ: 6205,
        Zusatzziffer: 0,
        Gemeindename: 'Eich',
        'BFS-Nr': 1084,
        Kantonskürzel: 'LU',
        E: 8.169558706326251,
        N: 47.152997662374084,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Geuensee',
        PLZ: 6232,
        Zusatzziffer: 0,
        Gemeindename: 'Geuensee',
        'BFS-Nr': 1085,
        Kantonskürzel: 'LU',
        E: 8.110137961263213,
        N: 47.198443256368336,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Grosswangen',
        PLZ: 6022,
        Zusatzziffer: 0,
        Gemeindename: 'Grosswangen',
        'BFS-Nr': 1086,
        Kantonskürzel: 'LU',
        E: 8.050048288443413,
        N: 47.13329676669661,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Hildisrieden',
        PLZ: 6024,
        Zusatzziffer: 0,
        Gemeindename: 'Hildisrieden',
        'BFS-Nr': 1088,
        Kantonskürzel: 'LU',
        E: 8.233377381688403,
        N: 47.1501010661168,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'St. Erhard',
        PLZ: 6212,
        Zusatzziffer: 0,
        Gemeindename: 'Knutwil',
        'BFS-Nr': 1089,
        Kantonskürzel: 'LU',
        E: 8.074215912966304,
        N: 47.182681438538054,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Knutwil',
        PLZ: 6213,
        Zusatzziffer: 0,
        Gemeindename: 'Knutwil',
        'BFS-Nr': 1089,
        Kantonskürzel: 'LU',
        E: 8.07279188836567,
        N: 47.20161476186219,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Kaltbach',
        PLZ: 6212,
        Zusatzziffer: 2,
        Gemeindename: 'Mauensee',
        'BFS-Nr': 1091,
        Kantonskürzel: 'LU',
        E: 8.05882310644225,
        N: 47.183790518770685,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Mauensee',
        PLZ: 6216,
        Zusatzziffer: 0,
        Gemeindename: 'Mauensee',
        'BFS-Nr': 1091,
        Kantonskürzel: 'LU',
        E: 8.067031774250102,
        N: 47.167739878356144,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Hellbühl',
        PLZ: 6016,
        Zusatzziffer: 0,
        Gemeindename: 'Neuenkirch',
        'BFS-Nr': 1093,
        Kantonskürzel: 'LU',
        E: 8.196854816921798,
        N: 47.070139146903394,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Sempach Station',
        PLZ: 6203,
        Zusatzziffer: 0,
        Gemeindename: 'Neuenkirch',
        'BFS-Nr': 1093,
        Kantonskürzel: 'LU',
        E: 8.19302145081482,
        N: 47.11462809540647,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Neuenkirch',
        PLZ: 6206,
        Zusatzziffer: 0,
        Gemeindename: 'Neuenkirch',
        'BFS-Nr': 1093,
        Kantonskürzel: 'LU',
        E: 8.203581931883939,
        N: 47.09994938262153,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Nottwil',
        PLZ: 6207,
        Zusatzziffer: 0,
        Gemeindename: 'Nottwil',
        'BFS-Nr': 1094,
        Kantonskürzel: 'LU',
        E: 8.133839955617614,
        N: 47.13437117298262,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Oberkirch LU',
        PLZ: 6208,
        Zusatzziffer: 0,
        Gemeindename: 'Oberkirch',
        'BFS-Nr': 1095,
        Kantonskürzel: 'LU',
        E: 8.11531983634173,
        N: 47.158520012243564,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Pfeffikon LU',
        PLZ: 5735,
        Zusatzziffer: 0,
        Gemeindename: 'Rickenbach (LU)',
        'BFS-Nr': 1097,
        Kantonskürzel: 'LU',
        E: 8.177617971695085,
        N: 47.249777527870165,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Rickenbach LU',
        PLZ: 6221,
        Zusatzziffer: 0,
        Gemeindename: 'Rickenbach (LU)',
        'BFS-Nr': 1097,
        Kantonskürzel: 'LU',
        E: 8.152954262610207,
        N: 47.21984563867503,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Ruswil',
        PLZ: 6017,
        Zusatzziffer: 0,
        Gemeindename: 'Ruswil',
        'BFS-Nr': 1098,
        Kantonskürzel: 'LU',
        E: 8.126659026954066,
        N: 47.08511563851099,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Sigigen',
        PLZ: 6019,
        Zusatzziffer: 0,
        Gemeindename: 'Ruswil',
        'BFS-Nr': 1098,
        Kantonskürzel: 'LU',
        E: 8.135132202908224,
        N: 47.06334464620814,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Schenkon',
        PLZ: 6214,
        Zusatzziffer: 0,
        Gemeindename: 'Schenkon',
        'BFS-Nr': 1099,
        Kantonskürzel: 'LU',
        E: 8.135521302529812,
        N: 47.17325389702379,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Schlierbach',
        PLZ: 6231,
        Zusatzziffer: 0,
        Gemeindename: 'Schlierbach',
        'BFS-Nr': 1100,
        Kantonskürzel: 'LU',
        E: 8.109973294745453,
        N: 47.226005367280536,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Sempach',
        PLZ: 6204,
        Zusatzziffer: 0,
        Gemeindename: 'Sempach',
        'BFS-Nr': 1102,
        Kantonskürzel: 'LU',
        E: 8.199040255543311,
        N: 47.13301500521537,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Sursee',
        PLZ: 6210,
        Zusatzziffer: 0,
        Gemeindename: 'Sursee',
        'BFS-Nr': 1103,
        Kantonskürzel: 'LU',
        E: 8.109416461455258,
        N: 47.17332165623581,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Triengen',
        PLZ: 6234,
        Zusatzziffer: 0,
        Gemeindename: 'Triengen',
        'BFS-Nr': 1104,
        Kantonskürzel: 'LU',
        E: 8.077336561960633,
        N: 47.23407612784961,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Kulmerau',
        PLZ: 6234,
        Zusatzziffer: 1,
        Gemeindename: 'Triengen',
        'BFS-Nr': 1104,
        Kantonskürzel: 'LU',
        E: 8.087448832274884,
        N: 47.254972300538235,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Winikon',
        PLZ: 6235,
        Zusatzziffer: 0,
        Gemeindename: 'Triengen',
        'BFS-Nr': 1104,
        Kantonskürzel: 'LU',
        E: 8.049299444826515,
        N: 47.2372775609714,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Wilihof',
        PLZ: 6236,
        Zusatzziffer: 0,
        Gemeindename: 'Triengen',
        'BFS-Nr': 1104,
        Kantonskürzel: 'LU',
        E: 8.064859812999343,
        N: 47.22478219701255,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Wolhusen',
        PLZ: 6110,
        Zusatzziffer: 0,
        Gemeindename: 'Wolhusen',
        'BFS-Nr': 1107,
        Kantonskürzel: 'LU',
        E: 8.074791751149538,
        N: 47.05974030069346,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Steinhuserberg',
        PLZ: 6114,
        Zusatzziffer: 0,
        Gemeindename: 'Wolhusen',
        'BFS-Nr': 1107,
        Kantonskürzel: 'LU',
        E: 8.046410745735095,
        N: 47.042956740605746,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Alberswil',
        PLZ: 6248,
        Zusatzziffer: 0,
        Gemeindename: 'Alberswil',
        'BFS-Nr': 1121,
        Kantonskürzel: 'LU',
        E: 7.997224827658098,
        N: 47.14962729319282,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Altbüron',
        PLZ: 6147,
        Zusatzziffer: 0,
        Gemeindename: 'Altbüron',
        'BFS-Nr': 1122,
        Kantonskürzel: 'LU',
        E: 7.882414517276862,
        N: 47.17991505899691,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Ebersecken',
        PLZ: 6245,
        Zusatzziffer: 0,
        Gemeindename: 'Altishofen',
        'BFS-Nr': 1123,
        Kantonskürzel: 'LU',
        E: 7.932876546486874,
        N: 47.182623019071436,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Altishofen',
        PLZ: 6246,
        Zusatzziffer: 0,
        Gemeindename: 'Altishofen',
        'BFS-Nr': 1123,
        Kantonskürzel: 'LU',
        E: 7.96035745130045,
        N: 47.20158782075407,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Buchs LU',
        PLZ: 6211,
        Zusatzziffer: 1,
        Gemeindename: 'Dagmersellen',
        'BFS-Nr': 1125,
        Kantonskürzel: 'LU',
        E: 8.037126332788255,
        N: 47.20090200668863,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Dagmersellen',
        PLZ: 6252,
        Zusatzziffer: 0,
        Gemeindename: 'Dagmersellen',
        'BFS-Nr': 1125,
        Kantonskürzel: 'LU',
        E: 7.9903327382271145,
        N: 47.214183078022096,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Uffikon',
        PLZ: 6253,
        Zusatzziffer: 0,
        Gemeindename: 'Dagmersellen',
        'BFS-Nr': 1125,
        Kantonskürzel: 'LU',
        E: 8.018266988456094,
        N: 47.211356728287875,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Egolzwil',
        PLZ: 6243,
        Zusatzziffer: 0,
        Gemeindename: 'Egolzwil',
        'BFS-Nr': 1127,
        Kantonskürzel: 'LU',
        E: 8.006360743343619,
        N: 47.185464443188195,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Kottwil',
        PLZ: 6217,
        Zusatzziffer: 0,
        Gemeindename: 'Ettiswil',
        'BFS-Nr': 1128,
        Kantonskürzel: 'LU',
        E: 8.04510196662251,
        N: 47.162773137225194,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Ettiswil',
        PLZ: 6218,
        Zusatzziffer: 0,
        Gemeindename: 'Ettiswil',
        'BFS-Nr': 1128,
        Kantonskürzel: 'LU',
        E: 8.014499192453298,
        N: 47.148536133211216,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Fischbach LU',
        PLZ: 6145,
        Zusatzziffer: 0,
        Gemeindename: 'Fischbach',
        'BFS-Nr': 1129,
        Kantonskürzel: 'LU',
        E: 7.9078543246065855,
        N: 47.156094323014926,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Grossdietwil',
        PLZ: 6146,
        Zusatzziffer: 0,
        Gemeindename: 'Grossdietwil',
        'BFS-Nr': 1131,
        Kantonskürzel: 'LU',
        E: 7.888587391913414,
        N: 47.17153976508412,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Hergiswil b. Willisau',
        PLZ: 6133,
        Zusatzziffer: 0,
        Gemeindename: 'Hergiswil bei Willisau',
        'BFS-Nr': 1132,
        Kantonskürzel: 'LU',
        E: 7.959182345934445,
        N: 47.085897069395564,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Hofstatt',
        PLZ: 6154,
        Zusatzziffer: 0,
        Gemeindename: 'Luthern',
        'BFS-Nr': 1135,
        Kantonskürzel: 'LU',
        E: 7.915058737440647,
        N: 47.08474536159038,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Luthern',
        PLZ: 6156,
        Zusatzziffer: 0,
        Gemeindename: 'Luthern',
        'BFS-Nr': 1135,
        Kantonskürzel: 'LU',
        E: 7.916404410933564,
        N: 47.05911021417226,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Luthern Bad',
        PLZ: 6156,
        Zusatzziffer: 2,
        Gemeindename: 'Luthern',
        'BFS-Nr': 1135,
        Kantonskürzel: 'LU',
        E: 7.927142130988971,
        N: 47.02814856037989,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Menznau',
        PLZ: 6122,
        Zusatzziffer: 0,
        Gemeindename: 'Menznau',
        'BFS-Nr': 1136,
        Kantonskürzel: 'LU',
        E: 8.039328362260859,
        N: 47.08403269981633,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Geiss',
        PLZ: 6123,
        Zusatzziffer: 0,
        Gemeindename: 'Menznau',
        'BFS-Nr': 1136,
        Kantonskürzel: 'LU',
        E: 8.05600798716842,
        N: 47.09023045800154,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Menzberg',
        PLZ: 6125,
        Zusatzziffer: 0,
        Gemeindename: 'Menznau',
        'BFS-Nr': 1136,
        Kantonskürzel: 'LU',
        E: 7.998292097031851,
        N: 47.04119034920289,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Nebikon',
        PLZ: 6244,
        Zusatzziffer: 0,
        Gemeindename: 'Nebikon',
        'BFS-Nr': 1137,
        Kantonskürzel: 'LU',
        E: 7.974658823427288,
        N: 47.1912289696308,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'St. Urban',
        PLZ: 4915,
        Zusatzziffer: 0,
        Gemeindename: 'Pfaffnau',
        'BFS-Nr': 1139,
        Kantonskürzel: 'LU',
        E: 7.839722033120163,
        N: 47.23241342989016,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Pfaffnau',
        PLZ: 6264,
        Zusatzziffer: 0,
        Gemeindename: 'Pfaffnau',
        'BFS-Nr': 1139,
        Kantonskürzel: 'LU',
        E: 7.898301103727612,
        N: 47.22854055900866,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Reiden',
        PLZ: 6260,
        Zusatzziffer: 0,
        Gemeindename: 'Reiden',
        'BFS-Nr': 1140,
        Kantonskürzel: 'LU',
        E: 7.972170729587732,
        N: 47.243542771731896,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Reidermoos',
        PLZ: 6260,
        Zusatzziffer: 2,
        Gemeindename: 'Reiden',
        'BFS-Nr': 1140,
        Kantonskürzel: 'LU',
        E: 7.990077536570538,
        N: 47.25451984662484,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Mehlsecken',
        PLZ: 6260,
        Zusatzziffer: 4,
        Gemeindename: 'Reiden',
        'BFS-Nr': 1140,
        Kantonskürzel: 'LU',
        E: 7.9591227099059045,
        N: 47.24547708651597,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Langnau b. Reiden',
        PLZ: 6262,
        Zusatzziffer: 0,
        Gemeindename: 'Reiden',
        'BFS-Nr': 1140,
        Kantonskürzel: 'LU',
        E: 7.963207103011526,
        N: 47.23111299102361,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Richenthal',
        PLZ: 6263,
        Zusatzziffer: 0,
        Gemeindename: 'Reiden',
        'BFS-Nr': 1140,
        Kantonskürzel: 'LU',
        E: 7.946415434035658,
        N: 47.219381773136135,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Roggliswil',
        PLZ: 6265,
        Zusatzziffer: 0,
        Gemeindename: 'Roggliswil',
        'BFS-Nr': 1142,
        Kantonskürzel: 'LU',
        E: 7.8862764899628575,
        N: 47.21208781334097,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Ohmstal',
        PLZ: 6143,
        Zusatzziffer: 0,
        Gemeindename: 'Schötz',
        'BFS-Nr': 1143,
        Kantonskürzel: 'LU',
        E: 7.951183491885023,
        N: 47.16305858326168,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Schötz',
        PLZ: 6247,
        Zusatzziffer: 0,
        Gemeindename: 'Schötz',
        'BFS-Nr': 1143,
        Kantonskürzel: 'LU',
        E: 7.988979985120399,
        N: 47.169418531122346,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Ufhusen',
        PLZ: 6153,
        Zusatzziffer: 0,
        Gemeindename: 'Ufhusen',
        'BFS-Nr': 1145,
        Kantonskürzel: 'LU',
        E: 7.899981324432911,
        N: 47.11756593384872,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Wauwil',
        PLZ: 6242,
        Zusatzziffer: 0,
        Gemeindename: 'Wauwil',
        'BFS-Nr': 1146,
        Kantonskürzel: 'LU',
        E: 8.022582970589804,
        N: 47.18629358675157,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Wikon',
        PLZ: 4806,
        Zusatzziffer: 0,
        Gemeindename: 'Wikon',
        'BFS-Nr': 1147,
        Kantonskürzel: 'LU',
        E: 7.967194366576966,
        N: 47.26322002190174,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Hintermoos',
        PLZ: 6260,
        Zusatzziffer: 3,
        Gemeindename: 'Wikon',
        'BFS-Nr': 1147,
        Kantonskürzel: 'LU',
        E: 7.9984501609901395,
        N: 47.270130033277326,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Zell LU',
        PLZ: 6144,
        Zusatzziffer: 0,
        Gemeindename: 'Zell (LU)',
        'BFS-Nr': 1150,
        Kantonskürzel: 'LU',
        E: 7.922643782523075,
        N: 47.140010397776116,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Hüswil',
        PLZ: 6152,
        Zusatzziffer: 0,
        Gemeindename: 'Zell (LU)',
        'BFS-Nr': 1150,
        Kantonskürzel: 'LU',
        E: 7.9025482622154755,
        N: 47.12788212985593,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Daiwil',
        PLZ: 6126,
        Zusatzziffer: 0,
        Gemeindename: 'Willisau',
        'BFS-Nr': 1151,
        Kantonskürzel: 'LU',
        E: 8.01280225698455,
        N: 47.09709787757798,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Willisau',
        PLZ: 6130,
        Zusatzziffer: 0,
        Gemeindename: 'Willisau',
        'BFS-Nr': 1151,
        Kantonskürzel: 'LU',
        E: 7.989682769193232,
        N: 47.11993344813139,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Rohrmatt',
        PLZ: 6132,
        Zusatzziffer: 0,
        Gemeindename: 'Willisau',
        'BFS-Nr': 1151,
        Kantonskürzel: 'LU',
        E: 7.985047517216644,
        N: 47.08228938037672,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Gettnau',
        PLZ: 6142,
        Zusatzziffer: 0,
        Gemeindename: 'Willisau',
        'BFS-Nr': 1151,
        Kantonskürzel: 'LU',
        E: 7.970004140658078,
        N: 47.14216679281067,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Altdorf UR',
        PLZ: 6460,
        Zusatzziffer: 0,
        Gemeindename: 'Altdorf (UR)',
        'BFS-Nr': 1201,
        Kantonskürzel: 'UR',
        E: 8.642259004032088,
        N: 46.883324772887676,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Andermatt',
        PLZ: 6490,
        Zusatzziffer: 0,
        Gemeindename: 'Andermatt',
        'BFS-Nr': 1202,
        Kantonskürzel: 'UR',
        E: 8.597370858888015,
        N: 46.631837438063705,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Attinghausen',
        PLZ: 6468,
        Zusatzziffer: 0,
        Gemeindename: 'Attinghausen',
        'BFS-Nr': 1203,
        Kantonskürzel: 'UR',
        E: 8.623979831132061,
        N: 46.864970795279675,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Bürglen UR',
        PLZ: 6463,
        Zusatzziffer: 0,
        Gemeindename: 'Bürglen (UR)',
        'BFS-Nr': 1205,
        Kantonskürzel: 'UR',
        E: 8.662451959167868,
        N: 46.87520848211418,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Erstfeld',
        PLZ: 6472,
        Zusatzziffer: 0,
        Gemeindename: 'Erstfeld',
        'BFS-Nr': 1206,
        Kantonskürzel: 'UR',
        E: 8.65037223119659,
        N: 46.82031203982235,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Flüelen',
        PLZ: 6454,
        Zusatzziffer: 0,
        Gemeindename: 'Flüelen',
        'BFS-Nr': 1207,
        Kantonskürzel: 'UR',
        E: 8.628644801749704,
        N: 46.90537642213223,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Göschenen',
        PLZ: 6487,
        Zusatzziffer: 0,
        Gemeindename: 'Göschenen',
        'BFS-Nr': 1208,
        Kantonskürzel: 'UR',
        E: 8.583901839878418,
        N: 46.669602733961305,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Intschi',
        PLZ: 6476,
        Zusatzziffer: 0,
        Gemeindename: 'Gurtnellen',
        'BFS-Nr': 1209,
        Kantonskürzel: 'UR',
        E: 8.64972981574607,
        N: 46.7604616895297,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Gurtnellen',
        PLZ: 6482,
        Zusatzziffer: 0,
        Gemeindename: 'Gurtnellen',
        'BFS-Nr': 1209,
        Kantonskürzel: 'UR',
        E: 8.628466949462402,
        N: 46.73800246076228,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Hospental',
        PLZ: 6493,
        Zusatzziffer: 0,
        Gemeindename: 'Hospental',
        'BFS-Nr': 1210,
        Kantonskürzel: 'UR',
        E: 8.572735787019202,
        N: 46.61913512757893,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Isenthal',
        PLZ: 6461,
        Zusatzziffer: 0,
        Gemeindename: 'Isenthal',
        'BFS-Nr': 1211,
        Kantonskürzel: 'UR',
        E: 8.560996795616388,
        N: 46.91181366332064,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Realp',
        PLZ: 6491,
        Zusatzziffer: 0,
        Gemeindename: 'Realp',
        'BFS-Nr': 1212,
        Kantonskürzel: 'UR',
        E: 8.501881527377192,
        N: 46.59874808916048,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Schattdorf',
        PLZ: 6467,
        Zusatzziffer: 0,
        Gemeindename: 'Schattdorf',
        'BFS-Nr': 1213,
        Kantonskürzel: 'UR',
        E: 8.658895591197854,
        N: 46.86406547580471,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Haldi b. Schattdorf',
        PLZ: 6469,
        Zusatzziffer: 0,
        Gemeindename: 'Schattdorf',
        'BFS-Nr': 1213,
        Kantonskürzel: 'UR',
        E: 8.67503194717096,
        N: 46.861831943615115,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Seedorf UR',
        PLZ: 6462,
        Zusatzziffer: 0,
        Gemeindename: 'Seedorf (UR)',
        'BFS-Nr': 1214,
        Kantonskürzel: 'UR',
        E: 8.610606694021925,
        N: 46.88403311307999,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Bauen',
        PLZ: 6466,
        Zusatzziffer: 0,
        Gemeindename: 'Seedorf (UR)',
        'BFS-Nr': 1214,
        Kantonskürzel: 'UR',
        E: 8.57765641075076,
        N: 46.935142777483705,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Seelisberg',
        PLZ: 6377,
        Zusatzziffer: 0,
        Gemeindename: 'Seelisberg',
        'BFS-Nr': 1215,
        Kantonskürzel: 'UR',
        E: 8.585653911782325,
        N: 46.97365869790786,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Rütli',
        PLZ: 6441,
        Zusatzziffer: 2,
        Gemeindename: 'Seelisberg',
        'BFS-Nr': 1215,
        Kantonskürzel: 'UR',
        E: 8.59285587396208,
        N: 46.96866831525633,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Silenen',
        PLZ: 6473,
        Zusatzziffer: 0,
        Gemeindename: 'Silenen',
        'BFS-Nr': 1216,
        Kantonskürzel: 'UR',
        E: 8.672756323331607,
        N: 46.79186334281251,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Amsteg',
        PLZ: 6474,
        Zusatzziffer: 0,
        Gemeindename: 'Silenen',
        'BFS-Nr': 1216,
        Kantonskürzel: 'UR',
        E: 8.670717879341607,
        N: 46.770666989365445,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Bristen',
        PLZ: 6475,
        Zusatzziffer: 0,
        Gemeindename: 'Silenen',
        'BFS-Nr': 1216,
        Kantonskürzel: 'UR',
        E: 8.691042614460216,
        N: 46.76938554669943,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Sisikon',
        PLZ: 6452,
        Zusatzziffer: 0,
        Gemeindename: 'Sisikon',
        'BFS-Nr': 1217,
        Kantonskürzel: 'UR',
        E: 8.623585067069046,
        N: 46.94807566098254,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Spiringen',
        PLZ: 6464,
        Zusatzziffer: 0,
        Gemeindename: 'Spiringen',
        'BFS-Nr': 1218,
        Kantonskürzel: 'UR',
        E: 8.728407767145441,
        N: 46.87101287922513,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Urnerboden',
        PLZ: 8751,
        Zusatzziffer: 0,
        Gemeindename: 'Spiringen',
        'BFS-Nr': 1218,
        Kantonskürzel: 'UR',
        E: 8.902403687950075,
        N: 46.8889649784822,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Unterschächen',
        PLZ: 6465,
        Zusatzziffer: 0,
        Gemeindename: 'Unterschächen',
        'BFS-Nr': 1219,
        Kantonskürzel: 'UR',
        E: 8.76841641836738,
        N: 46.861211946063236,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Wassen UR',
        PLZ: 6484,
        Zusatzziffer: 0,
        Gemeindename: 'Wassen',
        'BFS-Nr': 1220,
        Kantonskürzel: 'UR',
        E: 8.595703646626934,
        N: 46.706363887423436,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Meien',
        PLZ: 6485,
        Zusatzziffer: 0,
        Gemeindename: 'Wassen',
        'BFS-Nr': 1220,
        Kantonskürzel: 'UR',
        E: 8.54941343704476,
        N: 46.72570871454017,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Bennau',
        PLZ: 8836,
        Zusatzziffer: 0,
        Gemeindename: 'Einsiedeln',
        'BFS-Nr': 1301,
        Kantonskürzel: 'SZ',
        E: 8.729904864114653,
        N: 47.14859463487049,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Einsiedeln',
        PLZ: 8840,
        Zusatzziffer: 0,
        Gemeindename: 'Einsiedeln',
        'BFS-Nr': 1301,
        Kantonskürzel: 'SZ',
        E: 8.74981185642266,
        N: 47.12836941504669,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Trachslau',
        PLZ: 8840,
        Zusatzziffer: 2,
        Gemeindename: 'Einsiedeln',
        'BFS-Nr': 1301,
        Kantonskürzel: 'SZ',
        E: 8.72541253849389,
        N: 47.097796398926924,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Gross',
        PLZ: 8841,
        Zusatzziffer: 0,
        Gemeindename: 'Einsiedeln',
        'BFS-Nr': 1301,
        Kantonskürzel: 'SZ',
        E: 8.776452319662361,
        N: 47.1158722898197,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Euthal',
        PLZ: 8844,
        Zusatzziffer: 0,
        Gemeindename: 'Einsiedeln',
        'BFS-Nr': 1301,
        Kantonskürzel: 'SZ',
        E: 8.816409905174293,
        N: 47.095743603053045,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Willerzell',
        PLZ: 8846,
        Zusatzziffer: 0,
        Gemeindename: 'Einsiedeln',
        'BFS-Nr': 1301,
        Kantonskürzel: 'SZ',
        E: 8.794107792181894,
        N: 47.12984631308502,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Egg SZ',
        PLZ: 8847,
        Zusatzziffer: 0,
        Gemeindename: 'Einsiedeln',
        'BFS-Nr': 1301,
        Kantonskürzel: 'SZ',
        E: 8.780634245533838,
        N: 47.161310417717424,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Rigi Scheidegg',
        PLZ: 6410,
        Zusatzziffer: 5,
        Gemeindename: 'Gersau',
        'BFS-Nr': 1311,
        Kantonskürzel: 'SZ',
        E: 8.52097041321704,
        N: 47.027283658518584,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Gersau',
        PLZ: 6442,
        Zusatzziffer: 0,
        Gemeindename: 'Gersau',
        'BFS-Nr': 1311,
        Kantonskürzel: 'SZ',
        E: 8.527020853408178,
        N: 46.992832726398994,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Schindellegi',
        PLZ: 8834,
        Zusatzziffer: 0,
        Gemeindename: 'Feusisberg',
        'BFS-Nr': 1321,
        Kantonskürzel: 'SZ',
        E: 8.712700977990258,
        N: 47.17437572191876,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Feusisberg',
        PLZ: 8835,
        Zusatzziffer: 0,
        Gemeindename: 'Feusisberg',
        'BFS-Nr': 1321,
        Kantonskürzel: 'SZ',
        E: 8.747752202621161,
        N: 47.18772261952735,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Hurden',
        PLZ: 8640,
        Zusatzziffer: 3,
        Gemeindename: 'Freienbach',
        'BFS-Nr': 1322,
        Kantonskürzel: 'SZ',
        E: 8.805847454948447,
        N: 47.214285017508224,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Bäch SZ',
        PLZ: 8806,
        Zusatzziffer: 0,
        Gemeindename: 'Freienbach',
        'BFS-Nr': 1322,
        Kantonskürzel: 'SZ',
        E: 8.732677963289495,
        N: 47.203912424806454,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Freienbach',
        PLZ: 8807,
        Zusatzziffer: 0,
        Gemeindename: 'Freienbach',
        'BFS-Nr': 1322,
        Kantonskürzel: 'SZ',
        E: 8.760536655644964,
        N: 47.205327351840914,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Pfäffikon SZ',
        PLZ: 8808,
        Zusatzziffer: 0,
        Gemeindename: 'Freienbach',
        'BFS-Nr': 1322,
        Kantonskürzel: 'SZ',
        E: 8.7779846938592,
        N: 47.20037884394819,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Wollerau',
        PLZ: 8832,
        Zusatzziffer: 0,
        Gemeindename: 'Freienbach',
        'BFS-Nr': 1322,
        Kantonskürzel: 'SZ',
        E: 8.752456996109776,
        N: 47.19624500808909,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Wilen b. Wollerau',
        PLZ: 8832,
        Zusatzziffer: 2,
        Gemeindename: 'Freienbach',
        'BFS-Nr': 1322,
        Kantonskürzel: 'SZ',
        E: 8.734616890418412,
        N: 47.197003537039755,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Wollerau',
        PLZ: 8832,
        Zusatzziffer: 0,
        Gemeindename: 'Wollerau',
        'BFS-Nr': 1323,
        Kantonskürzel: 'SZ',
        E: 8.718853429640285,
        N: 47.195965752038255,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Merlischachen',
        PLZ: 6402,
        Zusatzziffer: 0,
        Gemeindename: 'Küssnacht (SZ)',
        'BFS-Nr': 1331,
        Kantonskürzel: 'SZ',
        E: 8.408048462322515,
        N: 47.066377956430586,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Küssnacht am Rigi',
        PLZ: 6403,
        Zusatzziffer: 0,
        Gemeindename: 'Küssnacht (SZ)',
        'BFS-Nr': 1331,
        Kantonskürzel: 'SZ',
        E: 8.440080606716098,
        N: 47.08039502353354,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Immensee',
        PLZ: 6405,
        Zusatzziffer: 0,
        Gemeindename: 'Küssnacht (SZ)',
        'BFS-Nr': 1331,
        Kantonskürzel: 'SZ',
        E: 8.461457014344154,
        N: 47.095696328233316,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Altendorf',
        PLZ: 8852,
        Zusatzziffer: 0,
        Gemeindename: 'Altendorf',
        'BFS-Nr': 1341,
        Kantonskürzel: 'SZ',
        E: 8.83089014200731,
        N: 47.191955047083674,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Galgenen',
        PLZ: 8854,
        Zusatzziffer: 2,
        Gemeindename: 'Galgenen',
        'BFS-Nr': 1342,
        Kantonskürzel: 'SZ',
        E: 8.874186772458803,
        N: 47.18231280741017,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Innerthal',
        PLZ: 8858,
        Zusatzziffer: 0,
        Gemeindename: 'Innerthal',
        'BFS-Nr': 1343,
        Kantonskürzel: 'SZ',
        E: 8.919226853655152,
        N: 47.10621099533646,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Lachen SZ',
        PLZ: 8853,
        Zusatzziffer: 0,
        Gemeindename: 'Lachen',
        'BFS-Nr': 1344,
        Kantonskürzel: 'SZ',
        E: 8.857823145434901,
        N: 47.19745531023492,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Reichenburg',
        PLZ: 8864,
        Zusatzziffer: 0,
        Gemeindename: 'Reichenburg',
        'BFS-Nr': 1345,
        Kantonskürzel: 'SZ',
        E: 8.974202853134715,
        N: 47.17144478330685,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Siebnen',
        PLZ: 8854,
        Zusatzziffer: 0,
        Gemeindename: 'Schübelbach',
        'BFS-Nr': 1346,
        Kantonskürzel: 'SZ',
        E: 8.897142665758926,
        N: 47.1761568069731,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Schübelbach',
        PLZ: 8862,
        Zusatzziffer: 0,
        Gemeindename: 'Schübelbach',
        'BFS-Nr': 1346,
        Kantonskürzel: 'SZ',
        E: 8.928175315340766,
        N: 47.17324369130604,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Buttikon SZ',
        PLZ: 8863,
        Zusatzziffer: 0,
        Gemeindename: 'Schübelbach',
        'BFS-Nr': 1346,
        Kantonskürzel: 'SZ',
        E: 8.953337119235128,
        N: 47.17376212348901,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Tuggen',
        PLZ: 8856,
        Zusatzziffer: 0,
        Gemeindename: 'Tuggen',
        'BFS-Nr': 1347,
        Kantonskürzel: 'SZ',
        E: 8.940498218966773,
        N: 47.20347118981199,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Vorderthal',
        PLZ: 8857,
        Zusatzziffer: 0,
        Gemeindename: 'Vorderthal',
        'BFS-Nr': 1348,
        Kantonskürzel: 'SZ',
        E: 8.899537972261038,
        N: 47.12273616946196,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Wangen SZ',
        PLZ: 8855,
        Zusatzziffer: 0,
        Gemeindename: 'Wangen (SZ)',
        'BFS-Nr': 1349,
        Kantonskürzel: 'SZ',
        E: 8.895594576155046,
        N: 47.19273639901032,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Alpthal',
        PLZ: 8849,
        Zusatzziffer: 0,
        Gemeindename: 'Alpthal',
        'BFS-Nr': 1361,
        Kantonskürzel: 'SZ',
        E: 8.716168005568507,
        N: 47.070725849512364,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Goldau',
        PLZ: 6410,
        Zusatzziffer: 0,
        Gemeindename: 'Arth',
        'BFS-Nr': 1362,
        Kantonskürzel: 'SZ',
        E: 8.54489804229782,
        N: 47.046612301829846,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Rigi Klösterli',
        PLZ: 6410,
        Zusatzziffer: 2,
        Gemeindename: 'Arth',
        'BFS-Nr': 1362,
        Kantonskürzel: 'SZ',
        E: 8.485725007407838,
        N: 47.04350953759223,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Rigi Staffel',
        PLZ: 6410,
        Zusatzziffer: 3,
        Gemeindename: 'Arth',
        'BFS-Nr': 1362,
        Kantonskürzel: 'SZ',
        E: 8.475288414502137,
        N: 47.05369250579868,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Rigi Kulm',
        PLZ: 6410,
        Zusatzziffer: 4,
        Gemeindename: 'Arth',
        'BFS-Nr': 1362,
        Kantonskürzel: 'SZ',
        E: 8.4828822243589,
        N: 47.05518012643456,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Oberarth',
        PLZ: 6414,
        Zusatzziffer: 0,
        Gemeindename: 'Arth',
        'BFS-Nr': 1362,
        Kantonskürzel: 'SZ',
        E: 8.533625449923258,
        N: 47.05608939427683,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Arth',
        PLZ: 6415,
        Zusatzziffer: 0,
        Gemeindename: 'Arth',
        'BFS-Nr': 1362,
        Kantonskürzel: 'SZ',
        E: 8.52313917025415,
        N: 47.06359211187134,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Illgau',
        PLZ: 6434,
        Zusatzziffer: 0,
        Gemeindename: 'Illgau',
        'BFS-Nr': 1363,
        Kantonskürzel: 'SZ',
        E: 8.723323422892836,
        N: 46.987618046302394,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Brunnen',
        PLZ: 6440,
        Zusatzziffer: 0,
        Gemeindename: 'Ingenbohl',
        'BFS-Nr': 1364,
        Kantonskürzel: 'SZ',
        E: 8.605264100865304,
        N: 46.99440378038075,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Lauerz',
        PLZ: 6424,
        Zusatzziffer: 0,
        Gemeindename: 'Lauerz',
        'BFS-Nr': 1365,
        Kantonskürzel: 'SZ',
        E: 8.580071323579766,
        N: 47.03540115075,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Stoos SZ',
        PLZ: 6433,
        Zusatzziffer: 0,
        Gemeindename: 'Morschach',
        'BFS-Nr': 1366,
        Kantonskürzel: 'SZ',
        E: 8.664557162698015,
        N: 46.98045441910602,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Morschach',
        PLZ: 6443,
        Zusatzziffer: 0,
        Gemeindename: 'Morschach',
        'BFS-Nr': 1366,
        Kantonskürzel: 'SZ',
        E: 8.620376225645973,
        N: 46.9816329239711,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Muotathal',
        PLZ: 6436,
        Zusatzziffer: 0,
        Gemeindename: 'Muotathal',
        'BFS-Nr': 1367,
        Kantonskürzel: 'SZ',
        E: 8.759935806286776,
        N: 46.97753988559065,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Bisisthal',
        PLZ: 6436,
        Zusatzziffer: 2,
        Gemeindename: 'Muotathal',
        'BFS-Nr': 1367,
        Kantonskürzel: 'SZ',
        E: 8.831433707658608,
        N: 46.94392880391271,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Ried (Muotathal)',
        PLZ: 6436,
        Zusatzziffer: 3,
        Gemeindename: 'Muotathal',
        'BFS-Nr': 1367,
        Kantonskürzel: 'SZ',
        E: 8.713907552308894,
        N: 46.986373745475916,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Oberiberg',
        PLZ: 8843,
        Zusatzziffer: 0,
        Gemeindename: 'Oberiberg',
        'BFS-Nr': 1368,
        Kantonskürzel: 'SZ',
        E: 8.786479410839526,
        N: 47.0417017184755,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Riemenstalden',
        PLZ: 6452,
        Zusatzziffer: 2,
        Gemeindename: 'Riemenstalden',
        'BFS-Nr': 1369,
        Kantonskürzel: 'SZ',
        E: 8.666355942439083,
        N: 46.94699172307376,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Rothenthurm',
        PLZ: 6418,
        Zusatzziffer: 0,
        Gemeindename: 'Rothenthurm',
        'BFS-Nr': 1370,
        Kantonskürzel: 'SZ',
        E: 8.67513800852901,
        N: 47.10507040063632,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Sattel',
        PLZ: 6417,
        Zusatzziffer: 0,
        Gemeindename: 'Sattel',
        'BFS-Nr': 1371,
        Kantonskürzel: 'SZ',
        E: 8.637750660769395,
        N: 47.083746414808125,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Seewen SZ',
        PLZ: 6423,
        Zusatzziffer: 0,
        Gemeindename: 'Schwyz',
        'BFS-Nr': 1372,
        Kantonskürzel: 'SZ',
        E: 8.63089404741689,
        N: 47.02976149443008,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Schwyz',
        PLZ: 6430,
        Zusatzziffer: 0,
        Gemeindename: 'Schwyz',
        'BFS-Nr': 1372,
        Kantonskürzel: 'SZ',
        E: 8.653676072823743,
        N: 47.02135764425363,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Rickenbach b. Schwyz',
        PLZ: 6432,
        Zusatzziffer: 0,
        Gemeindename: 'Schwyz',
        'BFS-Nr': 1372,
        Kantonskürzel: 'SZ',
        E: 8.665841138088787,
        N: 47.0164033742265,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Ibach',
        PLZ: 6438,
        Zusatzziffer: 0,
        Gemeindename: 'Schwyz',
        'BFS-Nr': 1372,
        Kantonskürzel: 'SZ',
        E: 8.644887031248775,
        N: 47.012853553932736,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Steinen',
        PLZ: 6422,
        Zusatzziffer: 0,
        Gemeindename: 'Steinen',
        'BFS-Nr': 1373,
        Kantonskürzel: 'SZ',
        E: 8.61317898513281,
        N: 47.05007761808494,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Steinerberg',
        PLZ: 6416,
        Zusatzziffer: 0,
        Gemeindename: 'Steinerberg',
        'BFS-Nr': 1374,
        Kantonskürzel: 'SZ',
        E: 8.585308074953968,
        N: 47.05208230635589,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Unteriberg',
        PLZ: 8842,
        Zusatzziffer: 0,
        Gemeindename: 'Unteriberg',
        'BFS-Nr': 1375,
        Kantonskürzel: 'SZ',
        E: 8.80324954943286,
        N: 47.056371820448575,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Studen SZ',
        PLZ: 8845,
        Zusatzziffer: 0,
        Gemeindename: 'Unteriberg',
        'BFS-Nr': 1375,
        Kantonskürzel: 'SZ',
        E: 8.83372908458336,
        N: 47.073392371959706,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Pilatus Kulm',
        PLZ: 6010,
        Zusatzziffer: 2,
        Gemeindename: 'Alpnach',
        'BFS-Nr': 1401,
        Kantonskürzel: 'OW',
        E: 8.254704422042028,
        N: 46.979264449024846,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Alpnachstad',
        PLZ: 6053,
        Zusatzziffer: 0,
        Gemeindename: 'Alpnach',
        'BFS-Nr': 1401,
        Kantonskürzel: 'OW',
        E: 8.276409475587268,
        N: 46.9537255507979,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Alpnach Dorf',
        PLZ: 6055,
        Zusatzziffer: 0,
        Gemeindename: 'Alpnach',
        'BFS-Nr': 1401,
        Kantonskürzel: 'OW',
        E: 8.271825689533465,
        N: 46.94107230060818,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Grafenort',
        PLZ: 6388,
        Zusatzziffer: 0,
        Gemeindename: 'Engelberg',
        'BFS-Nr': 1402,
        Kantonskürzel: 'OW',
        E: 8.372401249810476,
        N: 46.8705073418544,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Engelberg',
        PLZ: 6390,
        Zusatzziffer: 0,
        Gemeindename: 'Engelberg',
        'BFS-Nr': 1402,
        Kantonskürzel: 'OW',
        E: 8.403713848897539,
        N: 46.816209872767125,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Giswil',
        PLZ: 6074,
        Zusatzziffer: 0,
        Gemeindename: 'Giswil',
        'BFS-Nr': 1403,
        Kantonskürzel: 'OW',
        E: 8.181304942337116,
        N: 46.836864053836734,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Kerns',
        PLZ: 6064,
        Zusatzziffer: 0,
        Gemeindename: 'Kerns',
        'BFS-Nr': 1404,
        Kantonskürzel: 'OW',
        E: 8.280241594903504,
        N: 46.90196596908926,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'St. Niklausen OW',
        PLZ: 6066,
        Zusatzziffer: 0,
        Gemeindename: 'Kerns',
        'BFS-Nr': 1404,
        Kantonskürzel: 'OW',
        E: 8.283291751256607,
        N: 46.87454848834189,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Melchtal',
        PLZ: 6067,
        Zusatzziffer: 0,
        Gemeindename: 'Kerns',
        'BFS-Nr': 1404,
        Kantonskürzel: 'OW',
        E: 8.292763760736717,
        N: 46.83388841908319,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Melchsee-Frutt',
        PLZ: 6068,
        Zusatzziffer: 0,
        Gemeindename: 'Kerns',
        'BFS-Nr': 1404,
        Kantonskürzel: 'OW',
        E: 8.269084824751816,
        N: 46.773564466000096,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Lungern',
        PLZ: 6078,
        Zusatzziffer: 0,
        Gemeindename: 'Lungern',
        'BFS-Nr': 1405,
        Kantonskürzel: 'OW',
        E: 8.158997767527946,
        N: 46.782539997550145,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Bürglen OW',
        PLZ: 6078,
        Zusatzziffer: 2,
        Gemeindename: 'Lungern',
        'BFS-Nr': 1405,
        Kantonskürzel: 'OW',
        E: 8.166072702551569,
        N: 46.81206504044235,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Sachseln',
        PLZ: 6072,
        Zusatzziffer: 0,
        Gemeindename: 'Sachseln',
        'BFS-Nr': 1406,
        Kantonskürzel: 'OW',
        E: 8.23303834253782,
        N: 46.864551558088046,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Flüeli-Ranft',
        PLZ: 6073,
        Zusatzziffer: 0,
        Gemeindename: 'Sachseln',
        'BFS-Nr': 1406,
        Kantonskürzel: 'OW',
        E: 8.263828591189329,
        N: 46.871960089781844,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Kägiswil',
        PLZ: 6056,
        Zusatzziffer: 0,
        Gemeindename: 'Sarnen',
        'BFS-Nr': 1407,
        Kantonskürzel: 'OW',
        E: 8.258578477051532,
        N: 46.91951269651394,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Sarnen',
        PLZ: 6060,
        Zusatzziffer: 0,
        Gemeindename: 'Sarnen',
        'BFS-Nr': 1407,
        Kantonskürzel: 'OW',
        E: 8.24955601837052,
        N: 46.89356798009211,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Ramersberg',
        PLZ: 6060,
        Zusatzziffer: 2,
        Gemeindename: 'Sarnen',
        'BFS-Nr': 1407,
        Kantonskürzel: 'OW',
        E: 8.231478281857761,
        N: 46.90008326205247,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Wilen (Sarnen)',
        PLZ: 6062,
        Zusatzziffer: 0,
        Gemeindename: 'Sarnen',
        'BFS-Nr': 1407,
        Kantonskürzel: 'OW',
        E: 8.224000506013349,
        N: 46.88180094014316,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Stalden (Sarnen)',
        PLZ: 6063,
        Zusatzziffer: 0,
        Gemeindename: 'Sarnen',
        'BFS-Nr': 1407,
        Kantonskürzel: 'OW',
        E: 8.208623576876821,
        N: 46.88738540565179,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Beckenried',
        PLZ: 6375,
        Zusatzziffer: 0,
        Gemeindename: 'Beckenried',
        'BFS-Nr': 1501,
        Kantonskürzel: 'NW',
        E: 8.468746764538258,
        N: 46.96508858588754,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Buochs',
        PLZ: 6374,
        Zusatzziffer: 0,
        Gemeindename: 'Buochs',
        'BFS-Nr': 1502,
        Kantonskürzel: 'NW',
        E: 8.420093585467608,
        N: 46.97139614111511,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Dallenwil',
        PLZ: 6383,
        Zusatzziffer: 0,
        Gemeindename: 'Dallenwil',
        'BFS-Nr': 1503,
        Kantonskürzel: 'NW',
        E: 8.390657837931299,
        N: 46.93276379401141,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Wiesenberg',
        PLZ: 6383,
        Zusatzziffer: 3,
        Gemeindename: 'Dallenwil',
        'BFS-Nr': 1503,
        Kantonskürzel: 'NW',
        E: 8.364122487633844,
        N: 46.925043143544144,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Wirzweli',
        PLZ: 6383,
        Zusatzziffer: 4,
        Gemeindename: 'Dallenwil',
        'BFS-Nr': 1503,
        Kantonskürzel: 'NW',
        E: 8.36514991709923,
        N: 46.91334534493639,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Emmetten',
        PLZ: 6376,
        Zusatzziffer: 0,
        Gemeindename: 'Emmetten',
        'BFS-Nr': 1504,
        Kantonskürzel: 'NW',
        E: 8.523124189777983,
        N: 46.95558040931583,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Bürgenstock',
        PLZ: 6363,
        Zusatzziffer: 2,
        Gemeindename: 'Ennetbürgen',
        'BFS-Nr': 1505,
        Kantonskürzel: 'NW',
        E: 8.382295323579017,
        N: 46.99722755018291,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Ennetbürgen',
        PLZ: 6373,
        Zusatzziffer: 0,
        Gemeindename: 'Ennetbürgen',
        'BFS-Nr': 1505,
        Kantonskürzel: 'NW',
        E: 8.414481579619247,
        N: 46.98487339917616,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Ennetmoos',
        PLZ: 6372,
        Zusatzziffer: 0,
        Gemeindename: 'Ennetmoos',
        'BFS-Nr': 1506,
        Kantonskürzel: 'NW',
        E: 8.338118937122339,
        N: 46.95669683484182,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Hergiswil NW',
        PLZ: 6052,
        Zusatzziffer: 0,
        Gemeindename: 'Hergiswil (NW)',
        'BFS-Nr': 1507,
        Kantonskürzel: 'NW',
        E: 8.304165028185333,
        N: 46.98714952380576,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Oberdorf NW',
        PLZ: 6370,
        Zusatzziffer: 41,
        Gemeindename: 'Oberdorf (NW)',
        'BFS-Nr': 1508,
        Kantonskürzel: 'NW',
        E: 8.386678204838237,
        N: 46.95856866282218,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Büren NW',
        PLZ: 6382,
        Zusatzziffer: 0,
        Gemeindename: 'Oberdorf (NW)',
        'BFS-Nr': 1508,
        Kantonskürzel: 'NW',
        E: 8.397975951231592,
        N: 46.94029294866814,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Niederrickenbach',
        PLZ: 6383,
        Zusatzziffer: 2,
        Gemeindename: 'Oberdorf (NW)',
        'BFS-Nr': 1508,
        Kantonskürzel: 'NW',
        E: 8.43686531459999,
        N: 46.92136808318073,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Stans',
        PLZ: 6370,
        Zusatzziffer: 0,
        Gemeindename: 'Stans',
        'BFS-Nr': 1509,
        Kantonskürzel: 'NW',
        E: 8.366368336591393,
        N: 46.95842604448835,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Stansstad',
        PLZ: 6362,
        Zusatzziffer: 0,
        Gemeindename: 'Stansstad',
        'BFS-Nr': 1510,
        Kantonskürzel: 'NW',
        E: 8.339574512579132,
        N: 46.97659455955361,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Obbürgen',
        PLZ: 6363,
        Zusatzziffer: 0,
        Gemeindename: 'Stansstad',
        'BFS-Nr': 1510,
        Kantonskürzel: 'NW',
        E: 8.360483482103874,
        N: 46.981580218048755,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Fürigen',
        PLZ: 6363,
        Zusatzziffer: 1,
        Gemeindename: 'Stansstad',
        'BFS-Nr': 1510,
        Kantonskürzel: 'NW',
        E: 8.351862986487356,
        N: 46.98189564712591,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Kehrsiten',
        PLZ: 6365,
        Zusatzziffer: 0,
        Gemeindename: 'Stansstad',
        'BFS-Nr': 1510,
        Kantonskürzel: 'NW',
        E: 8.369864149637252,
        N: 47.00105886097693,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Wolfenschiessen',
        PLZ: 6386,
        Zusatzziffer: 0,
        Gemeindename: 'Wolfenschiessen',
        'BFS-Nr': 1511,
        Kantonskürzel: 'NW',
        E: 8.396490721226481,
        N: 46.90881021919879,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Oberrickenbach',
        PLZ: 6387,
        Zusatzziffer: 0,
        Gemeindename: 'Wolfenschiessen',
        'BFS-Nr': 1511,
        Kantonskürzel: 'NW',
        E: 8.423985898578122,
        N: 46.888134095609736,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Näfels',
        PLZ: 8752,
        Zusatzziffer: 0,
        Gemeindename: 'Glarus Nord',
        'BFS-Nr': 1630,
        Kantonskürzel: 'GL',
        E: 9.057086288722168,
        N: 47.101118935764944,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Mollis',
        PLZ: 8753,
        Zusatzziffer: 0,
        Gemeindename: 'Glarus Nord',
        'BFS-Nr': 1630,
        Kantonskürzel: 'GL',
        E: 9.075755950325455,
        N: 47.09370121465777,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Filzbach',
        PLZ: 8757,
        Zusatzziffer: 0,
        Gemeindename: 'Glarus Nord',
        'BFS-Nr': 1630,
        Kantonskürzel: 'GL',
        E: 9.131208745646921,
        N: 47.120447959039765,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Obstalden',
        PLZ: 8758,
        Zusatzziffer: 0,
        Gemeindename: 'Glarus Nord',
        'BFS-Nr': 1630,
        Kantonskürzel: 'GL',
        E: 9.151113171791364,
        N: 47.11753262351983,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Bilten',
        PLZ: 8865,
        Zusatzziffer: 0,
        Gemeindename: 'Glarus Nord',
        'BFS-Nr': 1630,
        Kantonskürzel: 'GL',
        E: 9.024858959411374,
        N: 47.15013985215546,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Niederurnen',
        PLZ: 8867,
        Zusatzziffer: 0,
        Gemeindename: 'Glarus Nord',
        'BFS-Nr': 1630,
        Kantonskürzel: 'GL',
        E: 9.05293704360893,
        N: 47.12571176415749,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Oberurnen',
        PLZ: 8868,
        Zusatzziffer: 0,
        Gemeindename: 'Glarus Nord',
        'BFS-Nr': 1630,
        Kantonskürzel: 'GL',
        E: 9.058816007012775,
        N: 47.11461934488147,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Mühlehorn',
        PLZ: 8874,
        Zusatzziffer: 0,
        Gemeindename: 'Glarus Nord',
        'BFS-Nr': 1630,
        Kantonskürzel: 'GL',
        E: 9.172677572785775,
        N: 47.11734514333962,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Mitlödi',
        PLZ: 8756,
        Zusatzziffer: 0,
        Gemeindename: 'Glarus Süd',
        'BFS-Nr': 1631,
        Kantonskürzel: 'GL',
        E: 9.079020044311086,
        N: 47.01046280452582,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Schwanden GL',
        PLZ: 8762,
        Zusatzziffer: 0,
        Gemeindename: 'Glarus Süd',
        'BFS-Nr': 1631,
        Kantonskürzel: 'GL',
        E: 9.07477799159712,
        N: 46.99522162922389,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Schwändi b. Schwanden',
        PLZ: 8762,
        Zusatzziffer: 2,
        Gemeindename: 'Glarus Süd',
        'BFS-Nr': 1631,
        Kantonskürzel: 'GL',
        E: 9.066724795078267,
        N: 47.00570675138464,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Sool',
        PLZ: 8762,
        Zusatzziffer: 3,
        Gemeindename: 'Glarus Süd',
        'BFS-Nr': 1631,
        Kantonskürzel: 'GL',
        E: 9.08575553558878,
        N: 47.00073021736665,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Engi',
        PLZ: 8765,
        Zusatzziffer: 0,
        Gemeindename: 'Glarus Süd',
        'BFS-Nr': 1631,
        Kantonskürzel: 'GL',
        E: 9.148077409194805,
        N: 46.983255367261755,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Matt',
        PLZ: 8766,
        Zusatzziffer: 0,
        Gemeindename: 'Glarus Süd',
        'BFS-Nr': 1631,
        Kantonskürzel: 'GL',
        E: 9.170194880862205,
        N: 46.96323914549704,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Elm',
        PLZ: 8767,
        Zusatzziffer: 0,
        Gemeindename: 'Glarus Süd',
        'BFS-Nr': 1631,
        Kantonskürzel: 'GL',
        E: 9.172159286192429,
        N: 46.91859905132632,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Nidfurn',
        PLZ: 8772,
        Zusatzziffer: 0,
        Gemeindename: 'Glarus Süd',
        'BFS-Nr': 1631,
        Kantonskürzel: 'GL',
        E: 9.051349760628717,
        N: 46.9869167377797,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Haslen GL',
        PLZ: 8773,
        Zusatzziffer: 0,
        Gemeindename: 'Glarus Süd',
        'BFS-Nr': 1631,
        Kantonskürzel: 'GL',
        E: 9.060056869149708,
        N: 46.98069110820455,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Leuggelbach',
        PLZ: 8774,
        Zusatzziffer: 0,
        Gemeindename: 'Glarus Süd',
        'BFS-Nr': 1631,
        Kantonskürzel: 'GL',
        E: 9.041053369030077,
        N: 46.97672906783498,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Luchsingen',
        PLZ: 8775,
        Zusatzziffer: 1,
        Gemeindename: 'Glarus Süd',
        'BFS-Nr': 1631,
        Kantonskürzel: 'GL',
        E: 9.03573546089803,
        N: 46.96668367375881,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Hätzingen',
        PLZ: 8775,
        Zusatzziffer: 2,
        Gemeindename: 'Glarus Süd',
        'BFS-Nr': 1631,
        Kantonskürzel: 'GL',
        E: 9.040465772031322,
        N: 46.9614798488253,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Diesbach GL',
        PLZ: 8777,
        Zusatzziffer: 0,
        Gemeindename: 'Glarus Süd',
        'BFS-Nr': 1631,
        Kantonskürzel: 'GL',
        E: 9.035394884650735,
        N: 46.95192958691121,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Betschwanden',
        PLZ: 8777,
        Zusatzziffer: 2,
        Gemeindename: 'Glarus Süd',
        'BFS-Nr': 1631,
        Kantonskürzel: 'GL',
        E: 9.027380749625632,
        N: 46.94413506621851,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Rüti GL',
        PLZ: 8782,
        Zusatzziffer: 0,
        Gemeindename: 'Glarus Süd',
        'BFS-Nr': 1631,
        Kantonskürzel: 'GL',
        E: 9.012876246427325,
        N: 46.934607503689975,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Linthal',
        PLZ: 8783,
        Zusatzziffer: 0,
        Gemeindename: 'Glarus Süd',
        'BFS-Nr': 1631,
        Kantonskürzel: 'GL',
        E: 9.002353130432041,
        N: 46.924589668305835,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Braunwald',
        PLZ: 8784,
        Zusatzziffer: 0,
        Gemeindename: 'Glarus Süd',
        'BFS-Nr': 1631,
        Kantonskürzel: 'GL',
        E: 8.998127569376901,
        N: 46.940681904723135,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Glarus',
        PLZ: 8750,
        Zusatzziffer: 0,
        Gemeindename: 'Glarus',
        'BFS-Nr': 1632,
        Kantonskürzel: 'GL',
        E: 9.06566582573054,
        N: 47.040467670605736,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Riedern',
        PLZ: 8750,
        Zusatzziffer: 2,
        Gemeindename: 'Glarus',
        'BFS-Nr': 1632,
        Kantonskürzel: 'GL',
        E: 9.047233249310061,
        N: 47.04907209630389,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Klöntal',
        PLZ: 8750,
        Zusatzziffer: 3,
        Gemeindename: 'Glarus',
        'BFS-Nr': 1632,
        Kantonskürzel: 'GL',
        E: 8.942329661415227,
        N: 47.02555826809832,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Netstal',
        PLZ: 8754,
        Zusatzziffer: 0,
        Gemeindename: 'Glarus',
        'BFS-Nr': 1632,
        Kantonskürzel: 'GL',
        E: 9.050862325692757,
        N: 47.063636528428674,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Ennenda',
        PLZ: 8755,
        Zusatzziffer: 0,
        Gemeindename: 'Glarus',
        'BFS-Nr': 1632,
        Kantonskürzel: 'GL',
        E: 9.07901381966247,
        N: 47.03383200223124,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Allenwinden',
        PLZ: 6319,
        Zusatzziffer: 0,
        Gemeindename: 'Baar',
        'BFS-Nr': 1701,
        Kantonskürzel: 'ZG',
        E: 8.550978056950855,
        N: 47.16454533492718,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Baar',
        PLZ: 6340,
        Zusatzziffer: 0,
        Gemeindename: 'Baar',
        'BFS-Nr': 1701,
        Kantonskürzel: 'ZG',
        E: 8.522800423001938,
        N: 47.19325036558098,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Cham',
        PLZ: 6330,
        Zusatzziffer: 0,
        Gemeindename: 'Cham',
        'BFS-Nr': 1702,
        Kantonskürzel: 'ZG',
        E: 8.4596239998271,
        N: 47.179291850673195,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Hagendorn',
        PLZ: 6332,
        Zusatzziffer: 0,
        Gemeindename: 'Cham',
        'BFS-Nr': 1702,
        Kantonskürzel: 'ZG',
        E: 8.431923586916659,
        N: 47.20293661923734,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Hünenberg',
        PLZ: 6331,
        Zusatzziffer: 0,
        Gemeindename: 'Hünenberg',
        'BFS-Nr': 1703,
        Kantonskürzel: 'ZG',
        E: 8.42802256502576,
        N: 47.1747198488631,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Hünenberg See',
        PLZ: 6333,
        Zusatzziffer: 0,
        Gemeindename: 'Hünenberg',
        'BFS-Nr': 1703,
        Kantonskürzel: 'ZG',
        E: 8.45031724868118,
        N: 47.169258183079,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Menzingen',
        PLZ: 6313,
        Zusatzziffer: 0,
        Gemeindename: 'Menzingen',
        'BFS-Nr': 1704,
        Kantonskürzel: 'ZG',
        E: 8.590315250331496,
        N: 47.17878234303276,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Menzingen',
        PLZ: 6313,
        Zusatzziffer: 0,
        Gemeindename: 'Menzingen',
        'BFS-Nr': 1704,
        Kantonskürzel: 'ZG',
        E: 8.636676674809092,
        N: 47.170620796438136,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Edlibach',
        PLZ: 6313,
        Zusatzziffer: 2,
        Gemeindename: 'Menzingen',
        'BFS-Nr': 1704,
        Kantonskürzel: 'ZG',
        E: 8.571487954362382,
        N: 47.17974648293293,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Finstersee',
        PLZ: 6313,
        Zusatzziffer: 3,
        Gemeindename: 'Menzingen',
        'BFS-Nr': 1704,
        Kantonskürzel: 'ZG',
        E: 8.62976541027466,
        N: 47.16712008775879,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Sihlbrugg',
        PLZ: 6340,
        Zusatzziffer: 4,
        Gemeindename: 'Neuheim',
        'BFS-Nr': 1705,
        Kantonskürzel: 'ZG',
        E: 8.576983157150025,
        N: 47.21756397311705,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Neuheim',
        PLZ: 6345,
        Zusatzziffer: 0,
        Gemeindename: 'Neuheim',
        'BFS-Nr': 1705,
        Kantonskürzel: 'ZG',
        E: 8.575743613892822,
        N: 47.20553904846143,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Oberägeri',
        PLZ: 6315,
        Zusatzziffer: 0,
        Gemeindename: 'Oberägeri',
        'BFS-Nr': 1706,
        Kantonskürzel: 'ZG',
        E: 8.614365222449686,
        N: 47.13457929417888,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Alosen',
        PLZ: 6315,
        Zusatzziffer: 2,
        Gemeindename: 'Oberägeri',
        'BFS-Nr': 1706,
        Kantonskürzel: 'ZG',
        E: 8.634769996334722,
        N: 47.14075026280166,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Morgarten',
        PLZ: 6315,
        Zusatzziffer: 3,
        Gemeindename: 'Oberägeri',
        'BFS-Nr': 1706,
        Kantonskürzel: 'ZG',
        E: 8.640502172382854,
        N: 47.10427808004265,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Rotkreuz',
        PLZ: 6343,
        Zusatzziffer: 0,
        Gemeindename: 'Risch',
        'BFS-Nr': 1707,
        Kantonskürzel: 'ZG',
        E: 8.429562988107236,
        N: 47.13969131529635,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Buonas',
        PLZ: 6343,
        Zusatzziffer: 2,
        Gemeindename: 'Risch',
        'BFS-Nr': 1707,
        Kantonskürzel: 'ZG',
        E: 8.456828588748097,
        N: 47.14104138292429,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Risch',
        PLZ: 6343,
        Zusatzziffer: 3,
        Gemeindename: 'Risch',
        'BFS-Nr': 1707,
        Kantonskürzel: 'ZG',
        E: 8.46685000744634,
        N: 47.13395936856151,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Holzhäusern ZG',
        PLZ: 6343,
        Zusatzziffer: 4,
        Gemeindename: 'Risch',
        'BFS-Nr': 1707,
        Kantonskürzel: 'ZG',
        E: 8.440764621026029,
        N: 47.153987574778505,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Steinhausen',
        PLZ: 6312,
        Zusatzziffer: 0,
        Gemeindename: 'Steinhausen',
        'BFS-Nr': 1708,
        Kantonskürzel: 'ZG',
        E: 8.485180481939082,
        N: 47.19601553569031,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Unterägeri',
        PLZ: 6314,
        Zusatzziffer: 0,
        Gemeindename: 'Unterägeri',
        'BFS-Nr': 1709,
        Kantonskürzel: 'ZG',
        E: 8.582707151888815,
        N: 47.13839623010423,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Neuägeri',
        PLZ: 6314,
        Zusatzziffer: 1,
        Gemeindename: 'Unterägeri',
        'BFS-Nr': 1709,
        Kantonskürzel: 'ZG',
        E: 8.562794429437766,
        N: 47.152376379724934,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Walchwil',
        PLZ: 6318,
        Zusatzziffer: 0,
        Gemeindename: 'Walchwil',
        'BFS-Nr': 1710,
        Kantonskürzel: 'ZG',
        E: 8.514775843102981,
        N: 47.10025366993192,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Zug',
        PLZ: 6300,
        Zusatzziffer: 0,
        Gemeindename: 'Zug',
        'BFS-Nr': 1711,
        Kantonskürzel: 'ZG',
        E: 8.516015202206233,
        N: 47.16518203460534,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Zugerberg',
        PLZ: 6300,
        Zusatzziffer: 5,
        Gemeindename: 'Zug',
        'BFS-Nr': 1711,
        Kantonskürzel: 'ZG',
        E: 8.533432416047136,
        N: 47.143378251004854,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Oberwil b. Zug',
        PLZ: 6317,
        Zusatzziffer: 0,
        Gemeindename: 'Zug',
        'BFS-Nr': 1711,
        Kantonskürzel: 'ZG',
        E: 8.507117232422871,
        N: 47.147797031542765,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Châtillon FR',
        PLZ: 1473,
        Zusatzziffer: 2,
        Gemeindename: 'Châtillon (FR)',
        'BFS-Nr': 2008,
        Kantonskürzel: 'FR',
        E: 6.827678547960659,
        N: 46.833080258975656,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Cugy FR',
        PLZ: 1482,
        Zusatzziffer: 0,
        Gemeindename: 'Cugy (FR)',
        'BFS-Nr': 2011,
        Kantonskürzel: 'FR',
        E: 6.8930847668324775,
        N: 46.81487881991763,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Vesin',
        PLZ: 1483,
        Zusatzziffer: 3,
        Gemeindename: 'Cugy (FR)',
        'BFS-Nr': 2011,
        Kantonskürzel: 'FR',
        E: 6.872771693078458,
        N: 46.80765768611021,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Fétigny',
        PLZ: 1532,
        Zusatzziffer: 0,
        Gemeindename: 'Fétigny',
        'BFS-Nr': 2016,
        Kantonskürzel: 'FR',
        E: 6.915476854757149,
        N: 46.797094077654734,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Gletterens',
        PLZ: 1544,
        Zusatzziffer: 0,
        Gemeindename: 'Gletterens',
        'BFS-Nr': 2022,
        Kantonskürzel: 'FR',
        E: 6.937160160342521,
        N: 46.895229412493656,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Lully FR',
        PLZ: 1470,
        Zusatzziffer: 2,
        Gemeindename: 'Lully (FR)',
        'BFS-Nr': 2025,
        Kantonskürzel: 'FR',
        E: 6.8480561434049845,
        N: 46.83498533104719,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Seiry',
        PLZ: 1470,
        Zusatzziffer: 3,
        Gemeindename: 'Lully (FR)',
        'BFS-Nr': 2025,
        Kantonskürzel: 'FR',
        E: 6.832075593049882,
        N: 46.81208489988894,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Bollion',
        PLZ: 1470,
        Zusatzziffer: 4,
        Gemeindename: 'Lully (FR)',
        'BFS-Nr': 2025,
        Kantonskürzel: 'FR',
        E: 6.827201895009213,
        N: 46.81707901767078,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Ménières',
        PLZ: 1533,
        Zusatzziffer: 0,
        Gemeindename: 'Ménières',
        'BFS-Nr': 2027,
        Kantonskürzel: 'FR',
        E: 6.881446285753564,
        N: 46.784150582385095,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Cousset',
        PLZ: 1774,
        Zusatzziffer: 0,
        Gemeindename: 'Montagny (FR)',
        'BFS-Nr': 2029,
        Kantonskürzel: 'FR',
        E: 6.977969902946301,
        N: 46.81854330545062,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Cousset',
        PLZ: 1774,
        Zusatzziffer: 0,
        Gemeindename: 'Montagny (FR)',
        'BFS-Nr': 2029,
        Kantonskürzel: 'FR',
        E: 6.971310385323282,
        N: 46.82674089902113,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Montagny-les-Monts',
        PLZ: 1774,
        Zusatzziffer: 2,
        Gemeindename: 'Montagny (FR)',
        'BFS-Nr': 2029,
        Kantonskürzel: 'FR',
        E: 6.991999238565902,
        N: 46.81048745731625,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Mannens',
        PLZ: 1775,
        Zusatzziffer: 2,
        Gemeindename: 'Montagny (FR)',
        'BFS-Nr': 2029,
        Kantonskürzel: 'FR',
        E: 6.970143460654709,
        N: 46.792567191186556,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Grandsivaz',
        PLZ: 1775,
        Zusatzziffer: 3,
        Gemeindename: 'Montagny (FR)',
        'BFS-Nr': 2029,
        Kantonskürzel: 'FR',
        E: 6.980800480808882,
        N: 46.78537255936349,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Montagny-la-Ville',
        PLZ: 1776,
        Zusatzziffer: 0,
        Gemeindename: 'Montagny (FR)',
        'BFS-Nr': 2029,
        Kantonskürzel: 'FR',
        E: 6.995437259907552,
        N: 46.817501507282365,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Nuvilly',
        PLZ: 1485,
        Zusatzziffer: 0,
        Gemeindename: 'Nuvilly',
        'BFS-Nr': 2035,
        Kantonskürzel: 'FR',
        E: 6.830635293386879,
        N: 46.78405300345526,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Prévondavaux',
        PLZ: 1410,
        Zusatzziffer: 4,
        Gemeindename: 'Prévondavaux',
        'BFS-Nr': 2038,
        Kantonskürzel: 'FR',
        E: 6.793274545737169,
        N: 46.72736648493999,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'St-Aubin FR',
        PLZ: 1566,
        Zusatzziffer: 0,
        Gemeindename: 'Saint-Aubin (FR)',
        'BFS-Nr': 2041,
        Kantonskürzel: 'FR',
        E: 6.980355915675773,
        N: 46.89013338685743,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Les Friques',
        PLZ: 1566,
        Zusatzziffer: 2,
        Gemeindename: 'Saint-Aubin (FR)',
        'BFS-Nr': 2041,
        Kantonskürzel: 'FR',
        E: 6.98803439680489,
        N: 46.903331790554574,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Sévaz',
        PLZ: 1541,
        Zusatzziffer: 3,
        Gemeindename: 'Sévaz',
        'BFS-Nr': 2043,
        Kantonskürzel: 'FR',
        E: 6.87549917465978,
        N: 46.838993307792926,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Villeneuve FR',
        PLZ: 1527,
        Zusatzziffer: 0,
        Gemeindename: 'Surpierre',
        'BFS-Nr': 2044,
        Kantonskürzel: 'FR',
        E: 6.8685888555064185,
        N: 46.74595938430182,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Surpierre',
        PLZ: 1528,
        Zusatzziffer: 0,
        Gemeindename: 'Surpierre',
        'BFS-Nr': 2044,
        Kantonskürzel: 'FR',
        E: 6.861327067544251,
        N: 46.74498594315022,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Praratoud',
        PLZ: 1528,
        Zusatzziffer: 2,
        Gemeindename: 'Surpierre',
        'BFS-Nr': 2044,
        Kantonskürzel: 'FR',
        E: 6.847532671303208,
        N: 46.73460974180143,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Cheiry',
        PLZ: 1529,
        Zusatzziffer: 0,
        Gemeindename: 'Surpierre',
        'BFS-Nr': 2044,
        Kantonskürzel: 'FR',
        E: 6.83357183288445,
        N: 46.74932777817412,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Chapelle (Broye)',
        PLZ: 1534,
        Zusatzziffer: 2,
        Gemeindename: 'Surpierre',
        'BFS-Nr': 2044,
        Kantonskürzel: 'FR',
        E: 6.851573710524151,
        N: 46.77007141283169,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Vallon',
        PLZ: 1565,
        Zusatzziffer: 2,
        Gemeindename: 'Vallon',
        'BFS-Nr': 2045,
        Kantonskürzel: 'FR',
        E: 6.953352500780985,
        N: 46.884774934526234,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Montet (Broye)',
        PLZ: 1483,
        Zusatzziffer: 0,
        Gemeindename: 'Les Montets',
        'BFS-Nr': 2050,
        Kantonskürzel: 'FR',
        E: 6.868698907080176,
        N: 46.81602250355355,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Frasses',
        PLZ: 1483,
        Zusatzziffer: 2,
        Gemeindename: 'Les Montets',
        'BFS-Nr': 2050,
        Kantonskürzel: 'FR',
        E: 6.863561718555631,
        N: 46.826755260352265,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Aumont',
        PLZ: 1484,
        Zusatzziffer: 0,
        Gemeindename: 'Les Montets',
        'BFS-Nr': 2050,
        Kantonskürzel: 'FR',
        E: 6.848617506774422,
        N: 46.801185659359895,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Granges-de-Vesin',
        PLZ: 1484,
        Zusatzziffer: 2,
        Gemeindename: 'Les Montets',
        'BFS-Nr': 2050,
        Kantonskürzel: 'FR',
        E: 6.842653722454938,
        N: 46.812285207658945,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Delley',
        PLZ: 1567,
        Zusatzziffer: 0,
        Gemeindename: 'Delley-Portalban',
        'BFS-Nr': 2051,
        Kantonskürzel: 'FR',
        E: 6.967580020473803,
        N: 46.91314164096961,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Portalban',
        PLZ: 1568,
        Zusatzziffer: 0,
        Gemeindename: 'Delley-Portalban',
        'BFS-Nr': 2051,
        Kantonskürzel: 'FR',
        E: 6.955467334938451,
        N: 46.91794697886919,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Dompierre FR',
        PLZ: 1563,
        Zusatzziffer: 0,
        Gemeindename: 'Belmont-Broye',
        'BFS-Nr': 2053,
        Kantonskürzel: 'FR',
        E: 6.988572903479869,
        N: 46.85304478226283,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Domdidier',
        PLZ: 1564,
        Zusatzziffer: 0,
        Gemeindename: 'Belmont-Broye',
        'BFS-Nr': 2053,
        Kantonskürzel: 'FR',
        E: 7.0153536158649334,
        N: 46.86476353351189,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Léchelles',
        PLZ: 1773,
        Zusatzziffer: 0,
        Gemeindename: 'Belmont-Broye',
        'BFS-Nr': 2053,
        Kantonskürzel: 'FR',
        E: 7.015969856806609,
        N: 46.829032359545494,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Chandon',
        PLZ: 1773,
        Zusatzziffer: 2,
        Gemeindename: 'Belmont-Broye',
        'BFS-Nr': 2053,
        Kantonskürzel: 'FR',
        E: 7.048135273648606,
        N: 46.83905118922377,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Russy',
        PLZ: 1773,
        Zusatzziffer: 3,
        Gemeindename: 'Belmont-Broye',
        'BFS-Nr': 2053,
        Kantonskürzel: 'FR',
        E: 7.001459068848521,
        N: 46.84280282649668,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Estavayer-le-Lac',
        PLZ: 1470,
        Zusatzziffer: 0,
        Gemeindename: 'Estavayer',
        'BFS-Nr': 2054,
        Kantonskürzel: 'FR',
        E: 6.847202235029403,
        N: 46.84999406638053,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Font',
        PLZ: 1473,
        Zusatzziffer: 0,
        Gemeindename: 'Estavayer',
        'BFS-Nr': 2054,
        Kantonskürzel: 'FR',
        E: 6.837935415755718,
        N: 46.825392919028744,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Font',
        PLZ: 1473,
        Zusatzziffer: 0,
        Gemeindename: 'Estavayer',
        'BFS-Nr': 2054,
        Kantonskürzel: 'FR',
        E: 6.820039236723408,
        N: 46.8361348370991,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Autavaux',
        PLZ: 1475,
        Zusatzziffer: 0,
        Gemeindename: 'Estavayer',
        'BFS-Nr': 2054,
        Kantonskürzel: 'FR',
        E: 6.877477058569439,
        N: 46.86520029454107,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Forel FR',
        PLZ: 1475,
        Zusatzziffer: 2,
        Gemeindename: 'Estavayer',
        'BFS-Nr': 2054,
        Kantonskürzel: 'FR',
        E: 6.888105128571267,
        N: 46.87555267002113,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Montbrelloz',
        PLZ: 1475,
        Zusatzziffer: 3,
        Gemeindename: 'Estavayer',
        'BFS-Nr': 2054,
        Kantonskürzel: 'FR',
        E: 6.885822633164666,
        N: 46.85358606193964,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Vuissens',
        PLZ: 1486,
        Zusatzziffer: 0,
        Gemeindename: 'Estavayer',
        'BFS-Nr': 2054,
        Kantonskürzel: 'FR',
        E: 6.765779442291755,
        N: 46.73798107356057,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Murist',
        PLZ: 1489,
        Zusatzziffer: 0,
        Gemeindename: 'Estavayer',
        'BFS-Nr': 2054,
        Kantonskürzel: 'FR',
        E: 6.807318848051698,
        N: 46.790439755160484,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Bussy FR',
        PLZ: 1541,
        Zusatzziffer: 0,
        Gemeindename: 'Estavayer',
        'BFS-Nr': 2054,
        Kantonskürzel: 'FR',
        E: 6.889286137427905,
        N: 46.83487455210133,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Morens FR',
        PLZ: 1541,
        Zusatzziffer: 2,
        Gemeindename: 'Estavayer',
        'BFS-Nr': 2054,
        Kantonskürzel: 'FR',
        E: 6.907500119970423,
        N: 46.844449406167236,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Rueyres-les-Prés',
        PLZ: 1542,
        Zusatzziffer: 0,
        Gemeindename: 'Estavayer',
        'BFS-Nr': 2054,
        Kantonskürzel: 'FR',
        E: 6.91670869219848,
        N: 46.85963047388085,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Cheyres',
        PLZ: 1468,
        Zusatzziffer: 0,
        Gemeindename: 'Cheyres-Châbles',
        'BFS-Nr': 2055,
        Kantonskürzel: 'FR',
        E: 6.787127315449445,
        N: 46.8142463904499,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Châbles FR',
        PLZ: 1474,
        Zusatzziffer: 0,
        Gemeindename: 'Cheyres-Châbles',
        'BFS-Nr': 2055,
        Kantonskürzel: 'FR',
        E: 6.809435193807093,
        N: 46.82620487396004,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Auboranges',
        PLZ: 1673,
        Zusatzziffer: 3,
        Gemeindename: 'Auboranges',
        'BFS-Nr': 2061,
        Kantonskürzel: 'FR',
        E: 6.80570609359013,
        N: 46.5826947310904,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Billens',
        PLZ: 1681,
        Zusatzziffer: 0,
        Gemeindename: 'Billens-Hennens',
        'BFS-Nr': 2063,
        Kantonskürzel: 'FR',
        E: 6.900092751401324,
        N: 46.69091223887412,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Hennens',
        PLZ: 1681,
        Zusatzziffer: 2,
        Gemeindename: 'Billens-Hennens',
        'BFS-Nr': 2063,
        Kantonskürzel: 'FR',
        E: 6.884565207548906,
        N: 46.68314500670693,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Chapelle (Glâne)',
        PLZ: 1608,
        Zusatzziffer: 4,
        Gemeindename: 'Chapelle (Glâne)',
        'BFS-Nr': 2066,
        Kantonskürzel: 'FR',
        E: 6.834287131002601,
        N: 46.58782635981065,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Le Châtelard-près-Romont',
        PLZ: 1689,
        Zusatzziffer: 0,
        Gemeindename: 'Le Châtelard',
        'BFS-Nr': 2067,
        Kantonskürzel: 'FR',
        E: 6.9782842532172005,
        N: 46.677540169506116,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Châtonnaye',
        PLZ: 1553,
        Zusatzziffer: 0,
        Gemeindename: 'Châtonnaye',
        'BFS-Nr': 2068,
        Kantonskürzel: 'FR',
        E: 6.938259368902964,
        N: 46.754100936957734,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Ecublens FR',
        PLZ: 1673,
        Zusatzziffer: 4,
        Gemeindename: 'Ecublens (FR)',
        'BFS-Nr': 2072,
        Kantonskürzel: 'FR',
        E: 6.808471569818219,
        N: 46.608594308708355,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Grangettes-près-Romont',
        PLZ: 1686,
        Zusatzziffer: 0,
        Gemeindename: 'Grangettes',
        'BFS-Nr': 2079,
        Kantonskürzel: 'FR',
        E: 6.964700642938517,
        N: 46.67643316184452,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Massonnens',
        PLZ: 1692,
        Zusatzziffer: 0,
        Gemeindename: 'Massonnens',
        'BFS-Nr': 2086,
        Kantonskürzel: 'FR',
        E: 6.973414367378421,
        N: 46.700642725008606,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Berlens',
        PLZ: 1680,
        Zusatzziffer: 2,
        Gemeindename: 'Mézières (FR)',
        'BFS-Nr': 2087,
        Kantonskürzel: 'FR',
        E: 6.953270397636425,
        N: 46.69319586728001,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Mézières FR',
        PLZ: 1684,
        Zusatzziffer: 0,
        Gemeindename: 'Mézières (FR)',
        'BFS-Nr': 2087,
        Kantonskürzel: 'FR',
        E: 6.926342778252776,
        N: 46.67912420752719,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Montet (Glâne)',
        PLZ: 1674,
        Zusatzziffer: 2,
        Gemeindename: 'Montet (Glâne)',
        'BFS-Nr': 2089,
        Kantonskürzel: 'FR',
        E: 6.813973265527141,
        N: 46.644584944545954,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Romont FR',
        PLZ: 1680,
        Zusatzziffer: 0,
        Gemeindename: 'Romont (FR)',
        'BFS-Nr': 2096,
        Kantonskürzel: 'FR',
        E: 6.918222835036331,
        N: 46.69540239523799,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Promasens',
        PLZ: 1673,
        Zusatzziffer: 0,
        Gemeindename: 'Rue',
        'BFS-Nr': 2097,
        Kantonskürzel: 'FR',
        E: 6.823068014237434,
        N: 46.601410752964505,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Gillarens',
        PLZ: 1673,
        Zusatzziffer: 2,
        Gemeindename: 'Rue',
        'BFS-Nr': 2097,
        Kantonskürzel: 'FR',
        E: 6.826870646080041,
        N: 46.587487881060646,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Rue',
        PLZ: 1673,
        Zusatzziffer: 5,
        Gemeindename: 'Rue',
        'BFS-Nr': 2097,
        Kantonskürzel: 'FR',
        E: 6.822306019298082,
        N: 46.61962745669846,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Blessens',
        PLZ: 1675,
        Zusatzziffer: 2,
        Gemeindename: 'Rue',
        'BFS-Nr': 2097,
        Kantonskürzel: 'FR',
        E: 6.838109227634991,
        N: 46.610769532942335,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Chavannes-les-Forts',
        PLZ: 1676,
        Zusatzziffer: 0,
        Gemeindename: 'Siviriez',
        'BFS-Nr': 2099,
        Kantonskürzel: 'FR',
        E: 6.897687959619757,
        N: 46.651756229326104,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Prez-vers-Siviriez',
        PLZ: 1677,
        Zusatzziffer: 0,
        Gemeindename: 'Siviriez',
        'BFS-Nr': 2099,
        Kantonskürzel: 'FR',
        E: 6.874472578794484,
        N: 46.638112962346476,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Siviriez',
        PLZ: 1678,
        Zusatzziffer: 0,
        Gemeindename: 'Siviriez',
        'BFS-Nr': 2099,
        Kantonskürzel: 'FR',
        E: 6.880088849419016,
        N: 46.65785182314887,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Villaraboud',
        PLZ: 1679,
        Zusatzziffer: 0,
        Gemeindename: 'Siviriez',
        'BFS-Nr': 2099,
        Kantonskürzel: 'FR',
        E: 6.912872349262875,
        N: 46.65893739948573,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Ursy',
        PLZ: 1670,
        Zusatzziffer: 0,
        Gemeindename: 'Ursy',
        'BFS-Nr': 2102,
        Kantonskürzel: 'FR',
        E: 6.834187548369254,
        N: 46.63558637062394,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Bionnens',
        PLZ: 1670,
        Zusatzziffer: 2,
        Gemeindename: 'Ursy',
        'BFS-Nr': 2102,
        Kantonskürzel: 'FR',
        E: 6.855452222850443,
        N: 46.63505165402045,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Esmonts',
        PLZ: 1670,
        Zusatzziffer: 3,
        Gemeindename: 'Ursy',
        'BFS-Nr': 2102,
        Kantonskürzel: 'FR',
        E: 6.8442103252116935,
        N: 46.64206167575751,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Vuarmarens',
        PLZ: 1674,
        Zusatzziffer: 0,
        Gemeindename: 'Ursy',
        'BFS-Nr': 2102,
        Kantonskürzel: 'FR',
        E: 6.826926012689228,
        N: 46.64494450623839,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Morlens',
        PLZ: 1674,
        Zusatzziffer: 3,
        Gemeindename: 'Ursy',
        'BFS-Nr': 2102,
        Kantonskürzel: 'FR',
        E: 6.836674063920005,
        N: 46.65304200650842,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Vauderens',
        PLZ: 1675,
        Zusatzziffer: 0,
        Gemeindename: 'Ursy',
        'BFS-Nr': 2102,
        Kantonskürzel: 'FR',
        E: 6.8475443931719,
        N: 46.623252923543404,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Mossel',
        PLZ: 1675,
        Zusatzziffer: 3,
        Gemeindename: 'Ursy',
        'BFS-Nr': 2102,
        Kantonskürzel: 'FR',
        E: 6.8556285869925935,
        N: 46.61042941291315,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Villariaz',
        PLZ: 1685,
        Zusatzziffer: 0,
        Gemeindename: 'Vuisternens-devant-Romont',
        'BFS-Nr': 2113,
        Kantonskürzel: 'FR',
        E: 6.939938220079072,
        N: 46.66482624749624,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'La Neirigue',
        PLZ: 1686,
        Zusatzziffer: 2,
        Gemeindename: 'Vuisternens-devant-Romont',
        'BFS-Nr': 2113,
        Kantonskürzel: 'FR',
        E: 6.954699842186094,
        N: 46.683880374448044,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Vuisternens-devant-Romont',
        PLZ: 1687,
        Zusatzziffer: 0,
        Gemeindename: 'Vuisternens-devant-Romont',
        'BFS-Nr': 2113,
        Kantonskürzel: 'FR',
        E: 6.929732651597969,
        N: 46.65620407822902,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'La Magne',
        PLZ: 1687,
        Zusatzziffer: 2,
        Gemeindename: 'Vuisternens-devant-Romont',
        'BFS-Nr': 2113,
        Kantonskürzel: 'FR',
        E: 6.932433493396007,
        N: 46.6344523679787,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Estévenens',
        PLZ: 1687,
        Zusatzziffer: 3,
        Gemeindename: 'Vuisternens-devant-Romont',
        'BFS-Nr': 2113,
        Kantonskürzel: 'FR',
        E: 6.9565819092115,
        N: 46.66905566731449,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Sommentier',
        PLZ: 1688,
        Zusatzziffer: 0,
        Gemeindename: 'Vuisternens-devant-Romont',
        'BFS-Nr': 2113,
        Kantonskürzel: 'FR',
        E: 6.912210633896341,
        N: 46.63833380724093,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Lieffrens',
        PLZ: 1688,
        Zusatzziffer: 2,
        Gemeindename: 'Vuisternens-devant-Romont',
        'BFS-Nr': 2113,
        Kantonskürzel: 'FR',
        E: 6.900711859372619,
        N: 46.63669806745787,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'La Joux FR',
        PLZ: 1697,
        Zusatzziffer: 0,
        Gemeindename: 'Vuisternens-devant-Romont',
        'BFS-Nr': 2113,
        Kantonskürzel: 'FR',
        E: 6.939077019446706,
        N: 46.62913001606489,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Les Ecasseys',
        PLZ: 1697,
        Zusatzziffer: 2,
        Gemeindename: 'Vuisternens-devant-Romont',
        'BFS-Nr': 2113,
        Kantonskürzel: 'FR',
        E: 6.909286769399396,
        N: 46.62229236457578,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Villarsiviriaux',
        PLZ: 1694,
        Zusatzziffer: 0,
        Gemeindename: 'Villorsonnens',
        'BFS-Nr': 2114,
        Kantonskürzel: 'FR',
        E: 7.011757679710777,
        N: 46.70022353927019,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Villargiroud',
        PLZ: 1694,
        Zusatzziffer: 2,
        Gemeindename: 'Villorsonnens',
        'BFS-Nr': 2114,
        Kantonskürzel: 'FR',
        E: 7.001294099697326,
        N: 46.70424148428428,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Orsonnens',
        PLZ: 1694,
        Zusatzziffer: 3,
        Gemeindename: 'Villorsonnens',
        'BFS-Nr': 2114,
        Kantonskürzel: 'FR',
        E: 6.993913503681291,
        N: 46.71740532842841,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Chavannes-sous-Orsonnens',
        PLZ: 1694,
        Zusatzziffer: 4,
        Gemeindename: 'Villorsonnens',
        'BFS-Nr': 2114,
        Kantonskürzel: 'FR',
        E: 6.99200054846297,
        N: 46.72786361898152,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Torny-le-Grand',
        PLZ: 1748,
        Zusatzziffer: 0,
        Gemeindename: 'Torny',
        'BFS-Nr': 2115,
        Kantonskürzel: 'FR',
        E: 6.968545609974462,
        N: 46.77062744294636,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Middes',
        PLZ: 1749,
        Zusatzziffer: 0,
        Gemeindename: 'Torny',
        'BFS-Nr': 2115,
        Kantonskürzel: 'FR',
        E: 6.949353779734668,
        N: 46.76807374518535,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Villaz-St-Pierre',
        PLZ: 1690,
        Zusatzziffer: 0,
        Gemeindename: 'Villaz',
        'BFS-Nr': 2117,
        Kantonskürzel: 'FR',
        E: 6.956950658841963,
        N: 46.719996606694025,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Lussy FR',
        PLZ: 1690,
        Zusatzziffer: 2,
        Gemeindename: 'Villaz',
        'BFS-Nr': 2117,
        Kantonskürzel: 'FR',
        E: 6.944152477520775,
        N: 46.71503761334653,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Villarimboud',
        PLZ: 1691,
        Zusatzziffer: 0,
        Gemeindename: 'Villaz',
        'BFS-Nr': 2117,
        Kantonskürzel: 'FR',
        E: 6.962841422212812,
        N: 46.73948156294017,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Albeuve',
        PLZ: 1669,
        Zusatzziffer: 0,
        Gemeindename: 'Haut-Intyamon',
        'BFS-Nr': 2121,
        Kantonskürzel: 'FR',
        E: 7.055989796183403,
        N: 46.516905024418804,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Lessoc',
        PLZ: 1669,
        Zusatzziffer: 2,
        Gemeindename: 'Haut-Intyamon',
        'BFS-Nr': 2121,
        Kantonskürzel: 'FR',
        E: 7.062494209809571,
        N: 46.50566580443217,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Les Sciernes-d\'Albeuve',
        PLZ: 1669,
        Zusatzziffer: 3,
        Gemeindename: 'Haut-Intyamon',
        'BFS-Nr': 2121,
        Kantonskürzel: 'FR',
        E: 7.035085954281547,
        N: 46.49688349271792,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Neirivue',
        PLZ: 1669,
        Zusatzziffer: 4,
        Gemeindename: 'Haut-Intyamon',
        'BFS-Nr': 2121,
        Kantonskürzel: 'FR',
        E: 7.058021305289897,
        N: 46.5252590169651,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Montbovon',
        PLZ: 1669,
        Zusatzziffer: 5,
        Gemeindename: 'Haut-Intyamon',
        'BFS-Nr': 2121,
        Kantonskürzel: 'FR',
        E: 7.045717185713117,
        N: 46.48624352869882,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Gumefens',
        PLZ: 1643,
        Zusatzziffer: 0,
        Gemeindename: 'Pont-en-Ogoz',
        'BFS-Nr': 2122,
        Kantonskürzel: 'FR',
        E: 7.076831952809178,
        N: 46.67698971595168,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Avry-devant-Pont',
        PLZ: 1644,
        Zusatzziffer: 0,
        Gemeindename: 'Pont-en-Ogoz',
        'BFS-Nr': 2122,
        Kantonskürzel: 'FR',
        E: 7.086402113165081,
        N: 46.68720690656094,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Le Bry',
        PLZ: 1645,
        Zusatzziffer: 0,
        Gemeindename: 'Pont-en-Ogoz',
        'BFS-Nr': 2122,
        Kantonskürzel: 'FR',
        E: 7.083571170108608,
        N: 46.70390971030046,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Botterens',
        PLZ: 1652,
        Zusatzziffer: 0,
        Gemeindename: 'Botterens',
        'BFS-Nr': 2123,
        Kantonskürzel: 'FR',
        E: 7.112928284570675,
        N: 46.621290818673685,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Villarbeney',
        PLZ: 1652,
        Zusatzziffer: 2,
        Gemeindename: 'Botterens',
        'BFS-Nr': 2123,
        Kantonskürzel: 'FR',
        E: 7.1086728688369485,
        N: 46.629097282389836,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Broc',
        PLZ: 1636,
        Zusatzziffer: 0,
        Gemeindename: 'Broc',
        'BFS-Nr': 2124,
        Kantonskürzel: 'FR',
        E: 7.099473825100621,
        N: 46.604958708258934,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Bulle',
        PLZ: 1630,
        Zusatzziffer: 0,
        Gemeindename: 'Bulle',
        'BFS-Nr': 2125,
        Kantonskürzel: 'FR',
        E: 7.0584562303266205,
        N: 46.620272760729684,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'La Tour-de-Trême',
        PLZ: 1635,
        Zusatzziffer: 0,
        Gemeindename: 'Bulle',
        'BFS-Nr': 2125,
        Kantonskürzel: 'FR',
        E: 7.066224628588974,
        N: 46.609074949681606,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Châtel-sur-Montsalvens',
        PLZ: 1653,
        Zusatzziffer: 3,
        Gemeindename: 'Châtel-sur-Montsalvens',
        'BFS-Nr': 2128,
        Kantonskürzel: 'FR',
        E: 7.1264179763035385,
        N: 46.61360579916236,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Corbières',
        PLZ: 1647,
        Zusatzziffer: 0,
        Gemeindename: 'Corbières',
        'BFS-Nr': 2129,
        Kantonskürzel: 'FR',
        E: 7.101113760280961,
        N: 46.6613728701691,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Villarvolard',
        PLZ: 1651,
        Zusatzziffer: 0,
        Gemeindename: 'Corbières',
        'BFS-Nr': 2129,
        Kantonskürzel: 'FR',
        E: 7.107328854123744,
        N: 46.64525982215682,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Crésuz',
        PLZ: 1653,
        Zusatzziffer: 2,
        Gemeindename: 'Crésuz',
        'BFS-Nr': 2130,
        Kantonskürzel: 'FR',
        E: 7.140933360351211,
        N: 46.618529290642186,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Echarlens',
        PLZ: 1646,
        Zusatzziffer: 0,
        Gemeindename: 'Echarlens',
        'BFS-Nr': 2131,
        Kantonskürzel: 'FR',
        E: 7.075605281688044,
        N: 46.64786669206776,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Grandvillard',
        PLZ: 1666,
        Zusatzziffer: 0,
        Gemeindename: 'Grandvillard',
        'BFS-Nr': 2134,
        Kantonskürzel: 'FR',
        E: 7.085667279854661,
        N: 46.53879682471692,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Gruyères',
        PLZ: 1663,
        Zusatzziffer: 0,
        Gemeindename: 'Gruyères',
        'BFS-Nr': 2135,
        Kantonskürzel: 'FR',
        E: 7.08336044901879,
        N: 46.58353513563508,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Pringy',
        PLZ: 1663,
        Zusatzziffer: 2,
        Gemeindename: 'Gruyères',
        'BFS-Nr': 2135,
        Kantonskürzel: 'FR',
        E: 7.072099083281917,
        N: 46.57854931709257,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Epagny',
        PLZ: 1663,
        Zusatzziffer: 3,
        Gemeindename: 'Gruyères',
        'BFS-Nr': 2135,
        Kantonskürzel: 'FR',
        E: 7.085493715357163,
        N: 46.588114182831056,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Moléson-sur-Gruyères',
        PLZ: 1663,
        Zusatzziffer: 4,
        Gemeindename: 'Gruyères',
        'BFS-Nr': 2135,
        Kantonskürzel: 'FR',
        E: 7.037337332980504,
        N: 46.561995802663915,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Hauteville',
        PLZ: 1648,
        Zusatzziffer: 0,
        Gemeindename: 'Hauteville',
        'BFS-Nr': 2137,
        Kantonskürzel: 'FR',
        E: 7.1032887425477425,
        N: 46.670947529406696,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Jaun',
        PLZ: 1656,
        Zusatzziffer: 0,
        Gemeindename: 'Jaun',
        'BFS-Nr': 2138,
        Kantonskürzel: 'FR',
        E: 7.274585988898255,
        N: 46.611387702759224,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Im Fang',
        PLZ: 1656,
        Zusatzziffer: 1,
        Gemeindename: 'Jaun',
        'BFS-Nr': 2138,
        Kantonskürzel: 'FR',
        E: 7.236272734069336,
        N: 46.59807652490698,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Marsens',
        PLZ: 1633,
        Zusatzziffer: 0,
        Gemeindename: 'Marsens',
        'BFS-Nr': 2140,
        Kantonskürzel: 'FR',
        E: 7.0618584836489156,
        N: 46.65576277384948,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Vuippens',
        PLZ: 1633,
        Zusatzziffer: 2,
        Gemeindename: 'Marsens',
        'BFS-Nr': 2140,
        Kantonskürzel: 'FR',
        E: 7.072938537170896,
        N: 46.658850150483836,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Morlon',
        PLZ: 1638,
        Zusatzziffer: 0,
        Gemeindename: 'Morlon',
        'BFS-Nr': 2143,
        Kantonskürzel: 'FR',
        E: 7.087767588401722,
        N: 46.62792855619669,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Le Pâquier-Montbarry',
        PLZ: 1661,
        Zusatzziffer: 0,
        Gemeindename: 'Le Pâquier (FR)',
        'BFS-Nr': 2145,
        Kantonskürzel: 'FR',
        E: 7.0548473330804296,
        N: 46.59442227331724,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Pont-la-Ville',
        PLZ: 1649,
        Zusatzziffer: 0,
        Gemeindename: 'Pont-la-Ville',
        'BFS-Nr': 2147,
        Kantonskürzel: 'FR',
        E: 7.111047300413366,
        N: 46.700103862772465,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Riaz',
        PLZ: 1632,
        Zusatzziffer: 0,
        Gemeindename: 'Riaz',
        'BFS-Nr': 2148,
        Kantonskürzel: 'FR',
        E: 7.061165922025977,
        N: 46.6415000291942,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'La Roche FR',
        PLZ: 1634,
        Zusatzziffer: 0,
        Gemeindename: 'La Roche',
        'BFS-Nr': 2149,
        Kantonskürzel: 'FR',
        E: 7.13795290490809,
        N: 46.696363475199895,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Sâles (Gruyère)',
        PLZ: 1625,
        Zusatzziffer: 0,
        Gemeindename: 'Sâles',
        'BFS-Nr': 2152,
        Kantonskürzel: 'FR',
        E: 6.973141939474025,
        N: 46.635613150157205,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Maules',
        PLZ: 1625,
        Zusatzziffer: 2,
        Gemeindename: 'Sâles',
        'BFS-Nr': 2152,
        Kantonskürzel: 'FR',
        E: 6.991303506730818,
        N: 46.638669751438464,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Romanens',
        PLZ: 1626,
        Zusatzziffer: 0,
        Gemeindename: 'Sâles',
        'BFS-Nr': 2152,
        Kantonskürzel: 'FR',
        E: 6.970578824950418,
        N: 46.65075712295928,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Treyfayes',
        PLZ: 1626,
        Zusatzziffer: 2,
        Gemeindename: 'Sâles',
        'BFS-Nr': 2152,
        Kantonskürzel: 'FR',
        E: 6.953206398118805,
        N: 46.65412283774284,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Rueyres-Treyfayes',
        PLZ: 1626,
        Zusatzziffer: 3,
        Gemeindename: 'Sâles',
        'BFS-Nr': 2152,
        Kantonskürzel: 'FR',
        E: 6.958790821454907,
        N: 46.64867041125002,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Sorens',
        PLZ: 1642,
        Zusatzziffer: 0,
        Gemeindename: 'Sorens',
        'BFS-Nr': 2153,
        Kantonskürzel: 'FR',
        E: 7.060290232701499,
        N: 46.668920128609955,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Vaulruz',
        PLZ: 1627,
        Zusatzziffer: 0,
        Gemeindename: 'Vaulruz',
        'BFS-Nr': 2155,
        Kantonskürzel: 'FR',
        E: 6.993159255634336,
        N: 46.62382013653585,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Vuadens',
        PLZ: 1628,
        Zusatzziffer: 0,
        Gemeindename: 'Vuadens',
        'BFS-Nr': 2160,
        Kantonskürzel: 'FR',
        E: 7.01914324558413,
        N: 46.616463473802526,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Estavannens',
        PLZ: 1665,
        Zusatzziffer: 0,
        Gemeindename: 'Bas-Intyamon',
        'BFS-Nr': 2162,
        Kantonskürzel: 'FR',
        E: 7.101700861780023,
        N: 46.56578599417715,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Villars-sous-Mont',
        PLZ: 1666,
        Zusatzziffer: 2,
        Gemeindename: 'Bas-Intyamon',
        'BFS-Nr': 2162,
        Kantonskürzel: 'FR',
        E: 7.06946730819669,
        N: 46.5399643385336,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Enney',
        PLZ: 1667,
        Zusatzziffer: 0,
        Gemeindename: 'Bas-Intyamon',
        'BFS-Nr': 2162,
        Kantonskürzel: 'FR',
        E: 7.083586112523932,
        N: 46.56626700149869,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Charmey (Gruyère)',
        PLZ: 1637,
        Zusatzziffer: 0,
        Gemeindename: 'Val-de-Charmey',
        'BFS-Nr': 2163,
        Kantonskürzel: 'FR',
        E: 7.162886074916027,
        N: 46.61926601024689,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Cerniat FR',
        PLZ: 1654,
        Zusatzziffer: 0,
        Gemeindename: 'Val-de-Charmey',
        'BFS-Nr': 2163,
        Kantonskürzel: 'FR',
        E: 7.156769163872674,
        N: 46.632642178161966,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Autigny',
        PLZ: 1742,
        Zusatzziffer: 0,
        Gemeindename: 'Autigny',
        'BFS-Nr': 2173,
        Kantonskürzel: 'FR',
        E: 7.02057704673258,
        N: 46.73649484407039,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Corjolens',
        PLZ: 1754,
        Zusatzziffer: 2,
        Gemeindename: 'Avry',
        'BFS-Nr': 2174,
        Kantonskürzel: 'FR',
        E: 7.045536186307422,
        N: 46.785635147825964,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Avry-sur-Matran',
        PLZ: 1754,
        Zusatzziffer: 4,
        Gemeindename: 'Avry',
        'BFS-Nr': 2174,
        Kantonskürzel: 'FR',
        E: 7.070666414469065,
        N: 46.78784539363009,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Belfaux',
        PLZ: 1782,
        Zusatzziffer: 0,
        Gemeindename: 'Belfaux',
        'BFS-Nr': 2175,
        Kantonskürzel: 'FR',
        E: 7.1069398256222795,
        N: 46.822402504066446,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Autafond',
        PLZ: 1782,
        Zusatzziffer: 2,
        Gemeindename: 'Belfaux',
        'BFS-Nr': 2175,
        Kantonskürzel: 'FR',
        E: 7.07786977079765,
        N: 46.813618149906766,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Chénens',
        PLZ: 1744,
        Zusatzziffer: 0,
        Gemeindename: 'Chénens',
        'BFS-Nr': 2177,
        Kantonskürzel: 'FR',
        E: 7.002460146316964,
        N: 46.73967095998777,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Corminboeuf',
        PLZ: 1720,
        Zusatzziffer: 0,
        Gemeindename: 'Corminboeuf',
        'BFS-Nr': 2183,
        Kantonskürzel: 'FR',
        E: 7.102325569336771,
        N: 46.808288013244166,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Chésopelloz',
        PLZ: 1720,
        Zusatzziffer: 2,
        Gemeindename: 'Corminboeuf',
        'BFS-Nr': 2183,
        Kantonskürzel: 'FR',
        E: 7.07822862597509,
        N: 46.80713085979804,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Cottens FR',
        PLZ: 1741,
        Zusatzziffer: 0,
        Gemeindename: 'Cottens (FR)',
        'BFS-Nr': 2186,
        Kantonskürzel: 'FR',
        E: 7.033002181115899,
        N: 46.75063061064679,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Ferpicloz',
        PLZ: 1724,
        Zusatzziffer: 3,
        Gemeindename: 'Ferpicloz',
        'BFS-Nr': 2194,
        Kantonskürzel: 'FR',
        E: 7.164546347654661,
        N: 46.74790096242371,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Fribourg',
        PLZ: 1700,
        Zusatzziffer: 0,
        Gemeindename: 'Fribourg',
        'BFS-Nr': 2196,
        Kantonskürzel: 'FR',
        E: 7.162795392425011,
        N: 46.806184687102416,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Bourguillon',
        PLZ: 1722,
        Zusatzziffer: 0,
        Gemeindename: 'Fribourg',
        'BFS-Nr': 2196,
        Kantonskürzel: 'FR',
        E: 7.17695695215557,
        N: 46.79999268225937,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Givisiez',
        PLZ: 1762,
        Zusatzziffer: 0,
        Gemeindename: 'Givisiez',
        'BFS-Nr': 2197,
        Kantonskürzel: 'FR',
        E: 7.126584889248,
        N: 46.81220554096257,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Granges-Paccot',
        PLZ: 1763,
        Zusatzziffer: 0,
        Gemeindename: 'Granges-Paccot',
        'BFS-Nr': 2198,
        Kantonskürzel: 'FR',
        E: 7.141505396911373,
        N: 46.82508934746587,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Grolley',
        PLZ: 1772,
        Zusatzziffer: 0,
        Gemeindename: 'Grolley',
        'BFS-Nr': 2200,
        Kantonskürzel: 'FR',
        E: 7.071152074708786,
        N: 46.833679464450675,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Marly',
        PLZ: 1723,
        Zusatzziffer: 0,
        Gemeindename: 'Marly',
        'BFS-Nr': 2206,
        Kantonskürzel: 'FR',
        E: 7.158922528765395,
        N: 46.77491016562074,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Matran',
        PLZ: 1753,
        Zusatzziffer: 0,
        Gemeindename: 'Matran',
        'BFS-Nr': 2208,
        Kantonskürzel: 'FR',
        E: 7.096297183502497,
        N: 46.7864408661945,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Neyruz FR',
        PLZ: 1740,
        Zusatzziffer: 0,
        Gemeindename: 'Neyruz (FR)',
        'BFS-Nr': 2211,
        Kantonskürzel: 'FR',
        E: 7.0652066674156995,
        N: 46.76789462053092,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Pierrafortscha',
        PLZ: 1723,
        Zusatzziffer: 4,
        Gemeindename: 'Pierrafortscha',
        'BFS-Nr': 2216,
        Kantonskürzel: 'FR',
        E: 7.185644875486939,
        N: 46.78696056625305,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Nierlet-les-Bois',
        PLZ: 1772,
        Zusatzziffer: 1,
        Gemeindename: 'Ponthaux',
        'BFS-Nr': 2217,
        Kantonskürzel: 'FR',
        E: 7.057848522960477,
        N: 46.81839099384594,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Ponthaux',
        PLZ: 1772,
        Zusatzziffer: 2,
        Gemeindename: 'Ponthaux',
        'BFS-Nr': 2217,
        Kantonskürzel: 'FR',
        E: 7.04010100910895,
        N: 46.81557722220601,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Le Mouret',
        PLZ: 1724,
        Zusatzziffer: 0,
        Gemeindename: 'Le Mouret',
        'BFS-Nr': 2220,
        Kantonskürzel: 'FR',
        E: 7.177776725393025,
        N: 46.75108202807035,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Essert FR',
        PLZ: 1724,
        Zusatzziffer: 2,
        Gemeindename: 'Le Mouret',
        'BFS-Nr': 2220,
        Kantonskürzel: 'FR',
        E: 7.1644885065902955,
        N: 46.73804038392258,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Montévraz',
        PLZ: 1724,
        Zusatzziffer: 4,
        Gemeindename: 'Le Mouret',
        'BFS-Nr': 2220,
        Kantonskürzel: 'FR',
        E: 7.175906225134545,
        N: 46.726298468486085,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Oberried FR',
        PLZ: 1724,
        Zusatzziffer: 5,
        Gemeindename: 'Le Mouret',
        'BFS-Nr': 2220,
        Kantonskürzel: 'FR',
        E: 7.188958914270053,
        N: 46.73554662857158,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Zénauva',
        PLZ: 1724,
        Zusatzziffer: 7,
        Gemeindename: 'Le Mouret',
        'BFS-Nr': 2220,
        Kantonskürzel: 'FR',
        E: 7.181991452303234,
        N: 46.73497826651779,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Bonnefontaine',
        PLZ: 1724,
        Zusatzziffer: 8,
        Gemeindename: 'Le Mouret',
        'BFS-Nr': 2220,
        Kantonskürzel: 'FR',
        E: 7.200493924261109,
        N: 46.74028442896074,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Treyvaux',
        PLZ: 1733,
        Zusatzziffer: 0,
        Gemeindename: 'Treyvaux',
        'BFS-Nr': 2226,
        Kantonskürzel: 'FR',
        E: 7.13756895758336,
        N: 46.72851462664219,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Villars-sur-Glâne',
        PLZ: 1752,
        Zusatzziffer: 0,
        Gemeindename: 'Villars-sur-Glâne',
        'BFS-Nr': 2228,
        Kantonskürzel: 'FR',
        E: 7.116042223287758,
        N: 46.791035279838184,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Villarsel-sur-Marly',
        PLZ: 1723,
        Zusatzziffer: 3,
        Gemeindename: 'Villarsel-sur-Marly',
        'BFS-Nr': 2230,
        Kantonskürzel: 'FR',
        E: 7.172995624894065,
        N: 46.76043601230534,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Posieux',
        PLZ: 1725,
        Zusatzziffer: 0,
        Gemeindename: 'Hauterive (FR)',
        'BFS-Nr': 2233,
        Kantonskürzel: 'FR',
        E: 7.0949660696811945,
        N: 46.762324713243686,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Ecuvillens',
        PLZ: 1730,
        Zusatzziffer: 0,
        Gemeindename: 'Hauterive (FR)',
        'BFS-Nr': 2233,
        Kantonskürzel: 'FR',
        E: 7.086861558013547,
        N: 46.75808351453421,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Lentigny',
        PLZ: 1745,
        Zusatzziffer: 0,
        Gemeindename: 'La Brillaz',
        'BFS-Nr': 2234,
        Kantonskürzel: 'FR',
        E: 7.003156949074643,
        N: 46.760076728851786,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Onnens FR',
        PLZ: 1756,
        Zusatzziffer: 0,
        Gemeindename: 'La Brillaz',
        'BFS-Nr': 2234,
        Kantonskürzel: 'FR',
        E: 7.039431893373597,
        N: 46.7758343715594,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Lovens',
        PLZ: 1756,
        Zusatzziffer: 2,
        Gemeindename: 'La Brillaz',
        'BFS-Nr': 2234,
        Kantonskürzel: 'FR',
        E: 7.020086012492299,
        N: 46.77287567496876,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Lossy',
        PLZ: 1782,
        Zusatzziffer: 3,
        Gemeindename: 'La Sonnaz',
        'BFS-Nr': 2235,
        Kantonskürzel: 'FR',
        E: 7.1111382902264655,
        N: 46.833095082320106,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'La Corbaz',
        PLZ: 1782,
        Zusatzziffer: 4,
        Gemeindename: 'La Sonnaz',
        'BFS-Nr': 2235,
        Kantonskürzel: 'FR',
        E: 7.114982636571673,
        N: 46.839245528070414,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Cormagens',
        PLZ: 1782,
        Zusatzziffer: 5,
        Gemeindename: 'La Sonnaz',
        'BFS-Nr': 2235,
        Kantonskürzel: 'FR',
        E: 7.135081658250184,
        N: 46.83440802004643,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Formangueires',
        PLZ: 1782,
        Zusatzziffer: 6,
        Gemeindename: 'La Sonnaz',
        'BFS-Nr': 2235,
        Kantonskürzel: 'FR',
        E: 7.1172903638972045,
        N: 46.82743658532425,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Villarlod',
        PLZ: 1695,
        Zusatzziffer: 0,
        Gemeindename: 'Gibloux',
        'BFS-Nr': 2236,
        Kantonskürzel: 'FR',
        E: 7.022772189447554,
        N: 46.705189892570296,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Rueyres-St-Laurent',
        PLZ: 1695,
        Zusatzziffer: 2,
        Gemeindename: 'Gibloux',
        'BFS-Nr': 2236,
        Kantonskürzel: 'FR',
        E: 7.032659896372008,
        N: 46.71746551283599,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Estavayer-le-Gibloux',
        PLZ: 1695,
        Zusatzziffer: 3,
        Gemeindename: 'Gibloux',
        'BFS-Nr': 2236,
        Kantonskürzel: 'FR',
        E: 7.025161520499773,
        N: 46.7219756584993,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Villarsel-le-Gibloux',
        PLZ: 1695,
        Zusatzziffer: 4,
        Gemeindename: 'Gibloux',
        'BFS-Nr': 2236,
        Kantonskürzel: 'FR',
        E: 7.0172633053423,
        N: 46.71408411036252,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Vuisternens-en-Ogoz',
        PLZ: 1696,
        Zusatzziffer: 0,
        Gemeindename: 'Gibloux',
        'BFS-Nr': 2236,
        Kantonskürzel: 'FR',
        E: 7.053095550374502,
        N: 46.70780217180018,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Farvagny-le-Grand',
        PLZ: 1726,
        Zusatzziffer: 0,
        Gemeindename: 'Gibloux',
        'BFS-Nr': 2236,
        Kantonskürzel: 'FR',
        E: 7.0661634392356305,
        N: 46.72190527046099,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Farvagny-le-Petit',
        PLZ: 1726,
        Zusatzziffer: 2,
        Gemeindename: 'Gibloux',
        'BFS-Nr': 2236,
        Kantonskürzel: 'FR',
        E: 7.0726903683041,
        N: 46.72848093017673,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Grenilles',
        PLZ: 1726,
        Zusatzziffer: 3,
        Gemeindename: 'Gibloux',
        'BFS-Nr': 2236,
        Kantonskürzel: 'FR',
        E: 7.047912327346287,
        N: 46.727478259994,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Posat',
        PLZ: 1726,
        Zusatzziffer: 4,
        Gemeindename: 'Gibloux',
        'BFS-Nr': 2236,
        Kantonskürzel: 'FR',
        E: 7.057796789533115,
        N: 46.73797820678484,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Corpataux',
        PLZ: 1727,
        Zusatzziffer: 0,
        Gemeindename: 'Gibloux',
        'BFS-Nr': 2236,
        Kantonskürzel: 'FR',
        E: 7.097888125739226,
        N: 46.74421622957449,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Magnedens',
        PLZ: 1727,
        Zusatzziffer: 2,
        Gemeindename: 'Gibloux',
        'BFS-Nr': 2236,
        Kantonskürzel: 'FR',
        E: 7.0812721317853455,
        N: 46.742074604686884,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Rossens FR',
        PLZ: 1728,
        Zusatzziffer: 0,
        Gemeindename: 'Gibloux',
        'BFS-Nr': 2236,
        Kantonskürzel: 'FR',
        E: 7.100736371083301,
        N: 46.719982822984406,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Prez-vers-Noréaz',
        PLZ: 1746,
        Zusatzziffer: 0,
        Gemeindename: 'Prez',
        'BFS-Nr': 2237,
        Kantonskürzel: 'FR',
        E: 7.015078437458142,
        N: 46.78552448675213,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Corserey',
        PLZ: 1747,
        Zusatzziffer: 0,
        Gemeindename: 'Prez',
        'BFS-Nr': 2237,
        Kantonskürzel: 'FR',
        E: 6.992911478114278,
        N: 46.773538702157076,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Noréaz',
        PLZ: 1757,
        Zusatzziffer: 0,
        Gemeindename: 'Prez',
        'BFS-Nr': 2237,
        Kantonskürzel: 'FR',
        E: 7.027715190381372,
        N: 46.802733945917545,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Senèdes',
        PLZ: 1724,
        Zusatzziffer: 6,
        Gemeindename: 'Bois-d\'Amont',
        'BFS-Nr': 2238,
        Kantonskürzel: 'FR',
        E: 7.142645677732334,
        N: 46.742396035490835,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Ependes FR',
        PLZ: 1731,
        Zusatzziffer: 0,
        Gemeindename: 'Bois-d\'Amont',
        'BFS-Nr': 2238,
        Kantonskürzel: 'FR',
        E: 7.146299525728298,
        N: 46.75451126135019,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Arconciel',
        PLZ: 1732,
        Zusatzziffer: 0,
        Gemeindename: 'Bois-d\'Amont',
        'BFS-Nr': 2238,
        Kantonskürzel: 'FR',
        E: 7.123039052951078,
        N: 46.74687140903969,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Courgevaux',
        PLZ: 1796,
        Zusatzziffer: 0,
        Gemeindename: 'Courgevaux',
        'BFS-Nr': 2250,
        Kantonskürzel: 'FR',
        E: 7.1120307806632646,
        N: 46.907333955427234,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Villarepos',
        PLZ: 1583,
        Zusatzziffer: 0,
        Gemeindename: 'Courtepin',
        'BFS-Nr': 2254,
        Kantonskürzel: 'FR',
        E: 7.072391356277005,
        N: 46.88357762823259,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Pensier',
        PLZ: 1783,
        Zusatzziffer: 0,
        Gemeindename: 'Courtepin',
        'BFS-Nr': 2254,
        Kantonskürzel: 'FR',
        E: 7.1352132244697115,
        N: 46.84425977046507,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Barberêche',
        PLZ: 1783,
        Zusatzziffer: 4,
        Gemeindename: 'Courtepin',
        'BFS-Nr': 2254,
        Kantonskürzel: 'FR',
        E: 7.161290892612909,
        N: 46.85666949136571,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Courtepin',
        PLZ: 1784,
        Zusatzziffer: 0,
        Gemeindename: 'Courtepin',
        'BFS-Nr': 2254,
        Kantonskürzel: 'FR',
        E: 7.126077302473586,
        N: 46.86369497092609,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Wallenried',
        PLZ: 1784,
        Zusatzziffer: 3,
        Gemeindename: 'Courtepin',
        'BFS-Nr': 2254,
        Kantonskürzel: 'FR',
        E: 7.1154393532739775,
        N: 46.87456020146565,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Courtaman',
        PLZ: 1791,
        Zusatzziffer: 0,
        Gemeindename: 'Courtepin',
        'BFS-Nr': 2254,
        Kantonskürzel: 'FR',
        E: 7.130393072143025,
        N: 46.870804004801705,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Cressier FR',
        PLZ: 1785,
        Zusatzziffer: 0,
        Gemeindename: 'Cressier (FR)',
        'BFS-Nr': 2257,
        Kantonskürzel: 'FR',
        E: 7.1402260788608745,
        N: 46.89817992577833,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Fräschels',
        PLZ: 3284,
        Zusatzziffer: 0,
        Gemeindename: 'Fräschels',
        'BFS-Nr': 2258,
        Kantonskürzel: 'FR',
        E: 7.211127039457949,
        N: 46.995400204943195,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Greng',
        PLZ: 3280,
        Zusatzziffer: 4,
        Gemeindename: 'Greng',
        'BFS-Nr': 2261,
        Kantonskürzel: 'FR',
        E: 7.091329057613893,
        N: 46.91375257122266,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Cordast',
        PLZ: 1792,
        Zusatzziffer: 0,
        Gemeindename: 'Gurmels',
        'BFS-Nr': 2262,
        Kantonskürzel: 'FR',
        E: 7.153182508001439,
        N: 46.87180203244083,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Guschelmuth',
        PLZ: 1792,
        Zusatzziffer: 2,
        Gemeindename: 'Gurmels',
        'BFS-Nr': 2262,
        Kantonskürzel: 'FR',
        E: 7.1354501939459825,
        N: 46.88058973110312,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Wallenbuch',
        PLZ: 3206,
        Zusatzziffer: 2,
        Gemeindename: 'Gurmels',
        'BFS-Nr': 2262,
        Kantonskürzel: 'FR',
        E: 7.224740933016484,
        N: 46.929641548621085,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Gurmels',
        PLZ: 3212,
        Zusatzziffer: 0,
        Gemeindename: 'Gurmels',
        'BFS-Nr': 2262,
        Kantonskürzel: 'FR',
        E: 7.17191004486233,
        N: 46.89273159937798,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Kleingurmels',
        PLZ: 3212,
        Zusatzziffer: 2,
        Gemeindename: 'Gurmels',
        'BFS-Nr': 2262,
        Kantonskürzel: 'FR',
        E: 7.1861303548725015,
        N: 46.8852948035059,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Liebistorf',
        PLZ: 3213,
        Zusatzziffer: 0,
        Gemeindename: 'Gurmels',
        'BFS-Nr': 2262,
        Kantonskürzel: 'FR',
        E: 7.194327147241701,
        N: 46.908422330885415,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Kerzers',
        PLZ: 3210,
        Zusatzziffer: 0,
        Gemeindename: 'Kerzers',
        'BFS-Nr': 2265,
        Kantonskürzel: 'FR',
        E: 7.196383123377338,
        N: 46.97614661563753,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Kleinbösingen',
        PLZ: 3213,
        Zusatzziffer: 2,
        Gemeindename: 'Kleinbösingen',
        'BFS-Nr': 2266,
        Kantonskürzel: 'FR',
        E: 7.205129383224126,
        N: 46.894250146938205,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Meyriez',
        PLZ: 3280,
        Zusatzziffer: 2,
        Gemeindename: 'Meyriez',
        'BFS-Nr': 2271,
        Kantonskürzel: 'FR',
        E: 7.109092019800207,
        N: 46.924965564835375,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Misery',
        PLZ: 1721,
        Zusatzziffer: 1,
        Gemeindename: 'Misery-Courtion',
        'BFS-Nr': 2272,
        Kantonskürzel: 'FR',
        E: 7.069174422939671,
        N: 46.85135490216962,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Cormérod',
        PLZ: 1721,
        Zusatzziffer: 2,
        Gemeindename: 'Misery-Courtion',
        'BFS-Nr': 2272,
        Kantonskürzel: 'FR',
        E: 7.088550210706083,
        N: 46.86561142371358,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Courtion',
        PLZ: 1721,
        Zusatzziffer: 4,
        Gemeindename: 'Misery-Courtion',
        'BFS-Nr': 2272,
        Kantonskürzel: 'FR',
        E: 7.0693372243930375,
        N: 46.85779653950122,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Cournillens',
        PLZ: 1721,
        Zusatzziffer: 5,
        Gemeindename: 'Misery-Courtion',
        'BFS-Nr': 2272,
        Kantonskürzel: 'FR',
        E: 7.101822218572852,
        N: 46.857362972003884,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Muntelier',
        PLZ: 3286,
        Zusatzziffer: 0,
        Gemeindename: 'Muntelier',
        'BFS-Nr': 2274,
        Kantonskürzel: 'FR',
        E: 7.124019439549017,
        N: 46.93542576825936,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Clavaleyres',
        PLZ: 1595,
        Zusatzziffer: 2,
        Gemeindename: 'Murten',
        'BFS-Nr': 2275,
        Kantonskürzel: 'FR',
        E: 7.091256086383113,
        N: 46.899457820914115,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Jeuss',
        PLZ: 1793,
        Zusatzziffer: 0,
        Gemeindename: 'Murten',
        'BFS-Nr': 2275,
        Kantonskürzel: 'FR',
        E: 7.164065286802711,
        N: 46.903324787189966,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Salvenach',
        PLZ: 1794,
        Zusatzziffer: 0,
        Gemeindename: 'Murten',
        'BFS-Nr': 2275,
        Kantonskürzel: 'FR',
        E: 7.150409021665595,
        N: 46.91254268126815,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Courlevon',
        PLZ: 1795,
        Zusatzziffer: 0,
        Gemeindename: 'Murten',
        'BFS-Nr': 2275,
        Kantonskürzel: 'FR',
        E: 7.111069098635409,
        N: 46.89120495885628,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Gempenach',
        PLZ: 3215,
        Zusatzziffer: 0,
        Gemeindename: 'Murten',
        'BFS-Nr': 2275,
        Kantonskürzel: 'FR',
        E: 7.197575050986864,
        N: 46.9407342919469,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Büchslen',
        PLZ: 3215,
        Zusatzziffer: 2,
        Gemeindename: 'Murten',
        'BFS-Nr': 2275,
        Kantonskürzel: 'FR',
        E: 7.1796580033328,
        N: 46.945196882641106,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Lurtigen',
        PLZ: 3215,
        Zusatzziffer: 3,
        Gemeindename: 'Murten',
        'BFS-Nr': 2275,
        Kantonskürzel: 'FR',
        E: 7.171721479438307,
        N: 46.92896896738658,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Murten',
        PLZ: 3280,
        Zusatzziffer: 0,
        Gemeindename: 'Murten',
        'BFS-Nr': 2275,
        Kantonskürzel: 'FR',
        E: 7.119109668067299,
        N: 46.92842126857147,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Galmiz',
        PLZ: 3285,
        Zusatzziffer: 0,
        Gemeindename: 'Murten',
        'BFS-Nr': 2275,
        Kantonskürzel: 'FR',
        E: 7.157852322806792,
        N: 46.94996030283689,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Ried b. Kerzers',
        PLZ: 3216,
        Zusatzziffer: 0,
        Gemeindename: 'Ried bei Kerzers',
        'BFS-Nr': 2276,
        Kantonskürzel: 'FR',
        E: 7.186141057351152,
        N: 46.9543510746628,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Agriswil',
        PLZ: 3216,
        Zusatzziffer: 2,
        Gemeindename: 'Ried bei Kerzers',
        'BFS-Nr': 2276,
        Kantonskürzel: 'FR',
        E: 7.197171161842822,
        N: 46.95421085803927,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Ulmiz',
        PLZ: 3214,
        Zusatzziffer: 0,
        Gemeindename: 'Ulmiz',
        'BFS-Nr': 2278,
        Kantonskürzel: 'FR',
        E: 7.200690954068558,
        N: 46.93258399357902,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Sugiez',
        PLZ: 1786,
        Zusatzziffer: 0,
        Gemeindename: 'Mont-Vully',
        'BFS-Nr': 2284,
        Kantonskürzel: 'FR',
        E: 7.11281165318637,
        N: 46.961888758066166,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Môtier (Vully)',
        PLZ: 1787,
        Zusatzziffer: 0,
        Gemeindename: 'Mont-Vully',
        'BFS-Nr': 2284,
        Kantonskürzel: 'FR',
        E: 7.082981863566911,
        N: 46.948458898126226,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Mur (Vully) FR',
        PLZ: 1787,
        Zusatzziffer: 1,
        Gemeindename: 'Mont-Vully',
        'BFS-Nr': 2284,
        Kantonskürzel: 'FR',
        E: 7.0633774688859114,
        N: 46.94454353259416,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Praz (Vully)',
        PLZ: 1788,
        Zusatzziffer: 0,
        Gemeindename: 'Mont-Vully',
        'BFS-Nr': 2284,
        Kantonskürzel: 'FR',
        E: 7.097282221513365,
        N: 46.95299745837782,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Lugnorre',
        PLZ: 1789,
        Zusatzziffer: 0,
        Gemeindename: 'Mont-Vully',
        'BFS-Nr': 2284,
        Kantonskürzel: 'FR',
        E: 7.07021453749761,
        N: 46.950324912358646,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Brünisried',
        PLZ: 1719,
        Zusatzziffer: 0,
        Gemeindename: 'Brünisried',
        'BFS-Nr': 2292,
        Kantonskürzel: 'FR',
        E: 7.279207526561364,
        N: 46.76027446115288,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Düdingen',
        PLZ: 3186,
        Zusatzziffer: 0,
        Gemeindename: 'Düdingen',
        'BFS-Nr': 2293,
        Kantonskürzel: 'FR',
        E: 7.1906667781941245,
        N: 46.84603604857306,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Giffers',
        PLZ: 1735,
        Zusatzziffer: 0,
        Gemeindename: 'Giffers',
        'BFS-Nr': 2294,
        Kantonskürzel: 'FR',
        E: 7.21040211902578,
        N: 46.761142309696424,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Bösingen',
        PLZ: 3178,
        Zusatzziffer: 0,
        Gemeindename: 'Bösingen',
        'BFS-Nr': 2295,
        Kantonskürzel: 'FR',
        E: 7.228379375436857,
        N: 46.89420051645175,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Heitenried',
        PLZ: 1714,
        Zusatzziffer: 0,
        Gemeindename: 'Heitenried',
        'BFS-Nr': 2296,
        Kantonskürzel: 'FR',
        E: 7.298664564347069,
        N: 46.829909445042546,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Plaffeien',
        PLZ: 1716,
        Zusatzziffer: 0,
        Gemeindename: 'Plaffeien',
        'BFS-Nr': 2299,
        Kantonskürzel: 'FR',
        E: 7.286055141223184,
        N: 46.742276171816826,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Oberschrot',
        PLZ: 1716,
        Zusatzziffer: 2,
        Gemeindename: 'Plaffeien',
        'BFS-Nr': 2299,
        Kantonskürzel: 'FR',
        E: 7.273818280115437,
        N: 46.739498173538884,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Schwarzsee',
        PLZ: 1716,
        Zusatzziffer: 3,
        Gemeindename: 'Plaffeien',
        'BFS-Nr': 2299,
        Kantonskürzel: 'FR',
        E: 7.291299732398086,
        N: 46.67049076841196,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Zumholz',
        PLZ: 1719,
        Zusatzziffer: 2,
        Gemeindename: 'Plaffeien',
        'BFS-Nr': 2299,
        Kantonskürzel: 'FR',
        E: 7.291017955888431,
        N: 46.75423562938174,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Plasselb',
        PLZ: 1737,
        Zusatzziffer: 0,
        Gemeindename: 'Plasselb',
        'BFS-Nr': 2300,
        Kantonskürzel: 'FR',
        E: 7.251942735993891,
        N: 46.73550965403241,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Rechthalten',
        PLZ: 1718,
        Zusatzziffer: 0,
        Gemeindename: 'Rechthalten',
        'BFS-Nr': 2301,
        Kantonskürzel: 'FR',
        E: 7.2408093545092145,
        N: 46.768330074156175,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'St. Silvester',
        PLZ: 1736,
        Zusatzziffer: 0,
        Gemeindename: 'St. Silvester',
        'BFS-Nr': 2303,
        Kantonskürzel: 'FR',
        E: 7.224643680411764,
        N: 46.74107739799306,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'St. Ursen',
        PLZ: 1717,
        Zusatzziffer: 0,
        Gemeindename: 'St. Ursen',
        'BFS-Nr': 2304,
        Kantonskürzel: 'FR',
        E: 7.221258568066896,
        N: 46.79127110375441,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Schmitten FR',
        PLZ: 3185,
        Zusatzziffer: 0,
        Gemeindename: 'Schmitten (FR)',
        'BFS-Nr': 2305,
        Kantonskürzel: 'FR',
        E: 7.2494918294744615,
        N: 46.8563358832448,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Tafers',
        PLZ: 1712,
        Zusatzziffer: 0,
        Gemeindename: 'Tafers',
        'BFS-Nr': 2306,
        Kantonskürzel: 'FR',
        E: 7.217607776435563,
        N: 46.815422127124116,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'St. Antoni',
        PLZ: 1713,
        Zusatzziffer: 0,
        Gemeindename: 'Tafers',
        'BFS-Nr': 2306,
        Kantonskürzel: 'FR',
        E: 7.260732316492704,
        N: 46.8222506439864,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Alterswil FR',
        PLZ: 1715,
        Zusatzziffer: 0,
        Gemeindename: 'Tafers',
        'BFS-Nr': 2306,
        Kantonskürzel: 'FR',
        E: 7.258818012232352,
        N: 46.79556618446954,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Tentlingen',
        PLZ: 1734,
        Zusatzziffer: 0,
        Gemeindename: 'Tentlingen',
        'BFS-Nr': 2307,
        Kantonskürzel: 'FR',
        E: 7.197690529329193,
        N: 46.77042665454588,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Ueberstorf',
        PLZ: 3182,
        Zusatzziffer: 0,
        Gemeindename: 'Ueberstorf',
        'BFS-Nr': 2308,
        Kantonskürzel: 'FR',
        E: 7.31434092873176,
        N: 46.86546330251754,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Flamatt',
        PLZ: 3175,
        Zusatzziffer: 0,
        Gemeindename: 'Wünnewil-Flamatt',
        'BFS-Nr': 2309,
        Kantonskürzel: 'FR',
        E: 7.310035269201682,
        N: 46.89036329934236,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Wünnewil',
        PLZ: 3184,
        Zusatzziffer: 0,
        Gemeindename: 'Wünnewil-Flamatt',
        'BFS-Nr': 2309,
        Kantonskürzel: 'FR',
        E: 7.275229604836734,
        N: 46.87459870053762,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Attalens',
        PLZ: 1616,
        Zusatzziffer: 0,
        Gemeindename: 'Attalens',
        'BFS-Nr': 2321,
        Kantonskürzel: 'FR',
        E: 6.8492212995060875,
        N: 46.510846230728625,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Tatroz',
        PLZ: 1617,
        Zusatzziffer: 2,
        Gemeindename: 'Attalens',
        'BFS-Nr': 2321,
        Kantonskürzel: 'FR',
        E: 6.869067045697452,
        N: 46.526165416005185,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Bossonnens',
        PLZ: 1615,
        Zusatzziffer: 0,
        Gemeindename: 'Bossonnens',
        'BFS-Nr': 2323,
        Kantonskürzel: 'FR',
        E: 6.850066413575109,
        N: 46.521803990800045,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Châtel-St-Denis',
        PLZ: 1618,
        Zusatzziffer: 0,
        Gemeindename: 'Châtel-Saint-Denis',
        'BFS-Nr': 2325,
        Kantonskürzel: 'FR',
        E: 6.901071812593163,
        N: 46.528800162737646,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Les Paccots',
        PLZ: 1619,
        Zusatzziffer: 0,
        Gemeindename: 'Châtel-Saint-Denis',
        'BFS-Nr': 2325,
        Kantonskürzel: 'FR',
        E: 6.944099399809023,
        N: 46.522708953260015,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Granges (Veveyse)',
        PLZ: 1614,
        Zusatzziffer: 0,
        Gemeindename: 'Granges (Veveyse)',
        'BFS-Nr': 2328,
        Kantonskürzel: 'FR',
        E: 6.8312485672213406,
        N: 46.52466494943169,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Remaufens',
        PLZ: 1617,
        Zusatzziffer: 0,
        Gemeindename: 'Remaufens',
        'BFS-Nr': 2333,
        Kantonskürzel: 'FR',
        E: 6.878652515328479,
        N: 46.527646263061186,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'St-Martin FR',
        PLZ: 1609,
        Zusatzziffer: 0,
        Gemeindename: 'Saint-Martin (FR)',
        'BFS-Nr': 2335,
        Kantonskürzel: 'FR',
        E: 6.869391627967727,
        N: 46.57589817051778,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Besencens',
        PLZ: 1609,
        Zusatzziffer: 2,
        Gemeindename: 'Saint-Martin (FR)',
        'BFS-Nr': 2335,
        Kantonskürzel: 'FR',
        E: 6.869690828265548,
        N: 46.58731787580109,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Fiaugères',
        PLZ: 1609,
        Zusatzziffer: 3,
        Gemeindename: 'Saint-Martin (FR)',
        'BFS-Nr': 2335,
        Kantonskürzel: 'FR',
        E: 6.891219476911926,
        N: 46.58622264646245,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Semsales',
        PLZ: 1623,
        Zusatzziffer: 0,
        Gemeindename: 'Semsales',
        'BFS-Nr': 2336,
        Kantonskürzel: 'FR',
        E: 6.927713851910756,
        N: 46.572886611485416,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Porsel',
        PLZ: 1699,
        Zusatzziffer: 0,
        Gemeindename: 'Le Flon',
        'BFS-Nr': 2337,
        Kantonskürzel: 'FR',
        E: 6.865981305935991,
        N: 46.599764482737825,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Pont (Veveyse)',
        PLZ: 1699,
        Zusatzziffer: 2,
        Gemeindename: 'Le Flon',
        'BFS-Nr': 2337,
        Kantonskürzel: 'FR',
        E: 6.853658209192716,
        N: 46.589262760125315,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Bouloz',
        PLZ: 1699,
        Zusatzziffer: 3,
        Gemeindename: 'Le Flon',
        'BFS-Nr': 2337,
        Kantonskürzel: 'FR',
        E: 6.8838005587930935,
        N: 46.611604134287894,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Le Crêt-près-Semsales',
        PLZ: 1611,
        Zusatzziffer: 0,
        Gemeindename: 'La Verrerie',
        'BFS-Nr': 2338,
        Kantonskürzel: 'FR',
        E: 6.910090852951701,
        N: 46.60893899149365,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'La Verrerie',
        PLZ: 1624,
        Zusatzziffer: 0,
        Gemeindename: 'La Verrerie',
        'BFS-Nr': 2338,
        Kantonskürzel: 'FR',
        E: 6.921401030188855,
        N: 46.58937116932934,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Grattavache',
        PLZ: 1624,
        Zusatzziffer: 2,
        Gemeindename: 'La Verrerie',
        'BFS-Nr': 2338,
        Kantonskürzel: 'FR',
        E: 6.919290363311655,
        N: 46.595024516037185,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Progens',
        PLZ: 1624,
        Zusatzziffer: 3,
        Gemeindename: 'La Verrerie',
        'BFS-Nr': 2338,
        Kantonskürzel: 'FR',
        E: 6.940931484822873,
        N: 46.59539483759418,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Progens',
        PLZ: 1624,
        Zusatzziffer: 3,
        Gemeindename: 'La Verrerie',
        'BFS-Nr': 2338,
        Kantonskürzel: 'FR',
        E: 6.9102598865226454,
        N: 46.58346506040361,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Egerkingen',
        PLZ: 4622,
        Zusatzziffer: 0,
        Gemeindename: 'Egerkingen',
        'BFS-Nr': 2401,
        Kantonskürzel: 'SO',
        E: 7.796297075551116,
        N: 47.32619560752585,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Härkingen',
        PLZ: 4624,
        Zusatzziffer: 0,
        Gemeindename: 'Härkingen',
        'BFS-Nr': 2402,
        Kantonskürzel: 'SO',
        E: 7.823842173105594,
        N: 47.306442750997505,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Kestenholz',
        PLZ: 4703,
        Zusatzziffer: 0,
        Gemeindename: 'Kestenholz',
        'BFS-Nr': 2403,
        Kantonskürzel: 'SO',
        E: 7.754089650827698,
        N: 47.27620178609597,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Neuendorf',
        PLZ: 4623,
        Zusatzziffer: 0,
        Gemeindename: 'Neuendorf',
        'BFS-Nr': 2404,
        Kantonskürzel: 'SO',
        E: 7.79666401339499,
        N: 47.301543288649974,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Niederbuchsiten',
        PLZ: 4626,
        Zusatzziffer: 0,
        Gemeindename: 'Niederbuchsiten',
        'BFS-Nr': 2405,
        Kantonskürzel: 'SO',
        E: 7.772376865914448,
        N: 47.294808068988694,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Oberbuchsiten',
        PLZ: 4625,
        Zusatzziffer: 0,
        Gemeindename: 'Oberbuchsiten',
        'BFS-Nr': 2406,
        Kantonskürzel: 'SO',
        E: 7.772790234077351,
        N: 47.310300619318106,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Oensingen',
        PLZ: 4702,
        Zusatzziffer: 0,
        Gemeindename: 'Oensingen',
        'BFS-Nr': 2407,
        Kantonskürzel: 'SO',
        E: 7.723819210622502,
        N: 47.28729968526529,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Wolfwil',
        PLZ: 4628,
        Zusatzziffer: 0,
        Gemeindename: 'Wolfwil',
        'BFS-Nr': 2408,
        Kantonskürzel: 'SO',
        E: 7.79344343591581,
        N: 47.2706605279219,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Aedermannsdorf',
        PLZ: 4714,
        Zusatzziffer: 0,
        Gemeindename: 'Aedermannsdorf',
        'BFS-Nr': 2421,
        Kantonskürzel: 'SO',
        E: 7.608267274843601,
        N: 47.30360544774974,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Balsthal',
        PLZ: 4710,
        Zusatzziffer: 0,
        Gemeindename: 'Balsthal',
        'BFS-Nr': 2422,
        Kantonskürzel: 'SO',
        E: 7.698198091865203,
        N: 47.31193361260734,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Herbetswil',
        PLZ: 4715,
        Zusatzziffer: 0,
        Gemeindename: 'Herbetswil',
        'BFS-Nr': 2424,
        Kantonskürzel: 'SO',
        E: 7.588277917524576,
        N: 47.29401110118346,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Holderbank SO',
        PLZ: 4718,
        Zusatzziffer: 0,
        Gemeindename: 'Holderbank (SO)',
        'BFS-Nr': 2425,
        Kantonskürzel: 'SO',
        E: 7.755925843706565,
        N: 47.33187031063507,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Laupersdorf',
        PLZ: 4712,
        Zusatzziffer: 0,
        Gemeindename: 'Laupersdorf',
        'BFS-Nr': 2426,
        Kantonskürzel: 'SO',
        E: 7.647457503107374,
        N: 47.31523625830742,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Matzendorf',
        PLZ: 4713,
        Zusatzziffer: 0,
        Gemeindename: 'Matzendorf',
        'BFS-Nr': 2427,
        Kantonskürzel: 'SO',
        E: 7.625778648931267,
        N: 47.30499732298549,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Mümliswil',
        PLZ: 4717,
        Zusatzziffer: 0,
        Gemeindename: 'Mümliswil-Ramiswil',
        'BFS-Nr': 2428,
        Kantonskürzel: 'SO',
        E: 7.695125870319548,
        N: 47.34117797426455,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Ramiswil',
        PLZ: 4719,
        Zusatzziffer: 0,
        Gemeindename: 'Mümliswil-Ramiswil',
        'BFS-Nr': 2428,
        Kantonskürzel: 'SO',
        E: 7.65970983798786,
        N: 47.34689632130113,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Welschenrohr',
        PLZ: 4716,
        Zusatzziffer: 0,
        Gemeindename: 'Welschenrohr-Gänsbrunnen',
        'BFS-Nr': 2430,
        Kantonskürzel: 'SO',
        E: 7.518093126802251,
        N: 47.279935051230446,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Gänsbrunnen',
        PLZ: 4716,
        Zusatzziffer: 1,
        Gemeindename: 'Welschenrohr-Gänsbrunnen',
        'BFS-Nr': 2430,
        Kantonskürzel: 'SO',
        E: 7.470961200514102,
        N: 47.263912757445915,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Biezwil',
        PLZ: 4585,
        Zusatzziffer: 0,
        Gemeindename: 'Biezwil',
        'BFS-Nr': 2445,
        Kantonskürzel: 'SO',
        E: 7.4154734554278185,
        N: 47.11654180032879,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Lüterkofen',
        PLZ: 4571,
        Zusatzziffer: 1,
        Gemeindename: 'Lüterkofen-Ichertswil',
        'BFS-Nr': 2455,
        Kantonskürzel: 'SO',
        E: 7.509603487371107,
        N: 47.16529608713495,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Ichertswil',
        PLZ: 4571,
        Zusatzziffer: 2,
        Gemeindename: 'Lüterkofen-Ichertswil',
        'BFS-Nr': 2455,
        Kantonskürzel: 'SO',
        E: 7.4958191061897725,
        N: 47.16066664091715,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Lüterswil',
        PLZ: 4584,
        Zusatzziffer: 2,
        Gemeindename: 'Lüterswil-Gächliwil',
        'BFS-Nr': 2456,
        Kantonskürzel: 'SO',
        E: 7.436708325479069,
        N: 47.11968360402499,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Gächliwil',
        PLZ: 4584,
        Zusatzziffer: 3,
        Gemeindename: 'Lüterswil-Gächliwil',
        'BFS-Nr': 2456,
        Kantonskürzel: 'SO',
        E: 7.447139566581389,
        N: 47.129264443820205,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Messen',
        PLZ: 3254,
        Zusatzziffer: 0,
        Gemeindename: 'Messen',
        'BFS-Nr': 2457,
        Kantonskürzel: 'SO',
        E: 7.448716074807956,
        N: 47.09148744628277,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Balm b. Messen',
        PLZ: 3254,
        Zusatzziffer: 2,
        Gemeindename: 'Messen',
        'BFS-Nr': 2457,
        Kantonskürzel: 'SO',
        E: 7.431546790667318,
        N: 47.10370982241305,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Brunnenthal',
        PLZ: 3307,
        Zusatzziffer: 0,
        Gemeindename: 'Messen',
        'BFS-Nr': 2457,
        Kantonskürzel: 'SO',
        E: 7.469006057822978,
        N: 47.08645905840389,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Oberramsern',
        PLZ: 4588,
        Zusatzziffer: 2,
        Gemeindename: 'Messen',
        'BFS-Nr': 2457,
        Kantonskürzel: 'SO',
        E: 7.465395567371642,
        N: 47.11372730331302,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Schnottwil',
        PLZ: 3253,
        Zusatzziffer: 0,
        Gemeindename: 'Schnottwil',
        'BFS-Nr': 2461,
        Kantonskürzel: 'SO',
        E: 7.397124323546915,
        N: 47.11174999213478,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Unterramsern',
        PLZ: 4588,
        Zusatzziffer: 0,
        Gemeindename: 'Unterramsern',
        'BFS-Nr': 2463,
        Kantonskürzel: 'SO',
        E: 7.482666734084113,
        N: 47.11774042242749,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Nennigkofen',
        PLZ: 4574,
        Zusatzziffer: 0,
        Gemeindename: 'Lüsslingen-Nennigkofen',
        'BFS-Nr': 2464,
        Kantonskürzel: 'SO',
        E: 7.489728733951951,
        N: 47.187114990710704,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Lüsslingen',
        PLZ: 4574,
        Zusatzziffer: 1,
        Gemeindename: 'Lüsslingen-Nennigkofen',
        'BFS-Nr': 2464,
        Kantonskürzel: 'SO',
        E: 7.500616689429671,
        N: 47.18796960601955,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Tscheppach',
        PLZ: 4576,
        Zusatzziffer: 0,
        Gemeindename: 'Buchegg',
        'BFS-Nr': 2465,
        Kantonskürzel: 'SO',
        E: 7.48271230106385,
        N: 47.14868026417201,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Hessigkofen',
        PLZ: 4577,
        Zusatzziffer: 0,
        Gemeindename: 'Buchegg',
        'BFS-Nr': 2465,
        Kantonskürzel: 'SO',
        E: 7.459808855044866,
        N: 47.13819092803915,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Bibern SO',
        PLZ: 4578,
        Zusatzziffer: 0,
        Gemeindename: 'Buchegg',
        'BFS-Nr': 2465,
        Kantonskürzel: 'SO',
        E: 7.4535396142097685,
        N: 47.14815986442197,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Gossliwil',
        PLZ: 4579,
        Zusatzziffer: 0,
        Gemeindename: 'Buchegg',
        'BFS-Nr': 2465,
        Kantonskürzel: 'SO',
        E: 7.431513932782362,
        N: 47.13657931815707,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Küttigkofen',
        PLZ: 4581,
        Zusatzziffer: 0,
        Gemeindename: 'Buchegg',
        'BFS-Nr': 2465,
        Kantonskürzel: 'SO',
        E: 7.513379055029564,
        N: 47.15144679460385,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Brügglen',
        PLZ: 4582,
        Zusatzziffer: 0,
        Gemeindename: 'Buchegg',
        'BFS-Nr': 2465,
        Kantonskürzel: 'SO',
        E: 7.4955408095126534,
        N: 47.14374161811097,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Mühledorf SO',
        PLZ: 4583,
        Zusatzziffer: 0,
        Gemeindename: 'Buchegg',
        'BFS-Nr': 2465,
        Kantonskürzel: 'SO',
        E: 7.47834123681428,
        N: 47.133908615237196,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Aetigkofen',
        PLZ: 4583,
        Zusatzziffer: 2,
        Gemeindename: 'Buchegg',
        'BFS-Nr': 2465,
        Kantonskürzel: 'SO',
        E: 7.466683389647051,
        N: 47.12321926604243,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Kyburg-Buchegg',
        PLZ: 4586,
        Zusatzziffer: 0,
        Gemeindename: 'Buchegg',
        'BFS-Nr': 2465,
        Kantonskürzel: 'SO',
        E: 7.512875601500134,
        N: 47.14137374174773,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Aetingen',
        PLZ: 4587,
        Zusatzziffer: 0,
        Gemeindename: 'Buchegg',
        'BFS-Nr': 2465,
        Kantonskürzel: 'SO',
        E: 7.507750613039571,
        N: 47.13196180402078,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Brittern',
        PLZ: 4588,
        Zusatzziffer: 1,
        Gemeindename: 'Buchegg',
        'BFS-Nr': 2465,
        Kantonskürzel: 'SO',
        E: 7.494776155063006,
        N: 47.1209436131755,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Bättwil',
        PLZ: 4112,
        Zusatzziffer: 1,
        Gemeindename: 'Bättwil',
        'BFS-Nr': 2471,
        Kantonskürzel: 'SO',
        E: 7.509967346528962,
        N: 47.49192470857189,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Büren SO',
        PLZ: 4413,
        Zusatzziffer: 0,
        Gemeindename: 'Büren (SO)',
        'BFS-Nr': 2472,
        Kantonskürzel: 'SO',
        E: 7.670593468012855,
        N: 47.44701710822687,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Dornach',
        PLZ: 4143,
        Zusatzziffer: 0,
        Gemeindename: 'Dornach',
        'BFS-Nr': 2473,
        Kantonskürzel: 'SO',
        E: 7.61668150891676,
        N: 47.478680368501024,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Gempen',
        PLZ: 4145,
        Zusatzziffer: 0,
        Gemeindename: 'Gempen',
        'BFS-Nr': 2474,
        Kantonskürzel: 'SO',
        E: 7.655658523619366,
        N: 47.475883884904306,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Hochwald',
        PLZ: 4146,
        Zusatzziffer: 0,
        Gemeindename: 'Hochwald',
        'BFS-Nr': 2475,
        Kantonskürzel: 'SO',
        E: 7.641027882713548,
        N: 47.4561308447225,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Flüh',
        PLZ: 4112,
        Zusatzziffer: 2,
        Gemeindename: 'Hofstetten-Flüh',
        'BFS-Nr': 2476,
        Kantonskürzel: 'SO',
        E: 7.498619585216355,
        N: 47.48351439549773,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Hofstetten SO',
        PLZ: 4114,
        Zusatzziffer: 0,
        Gemeindename: 'Hofstetten-Flüh',
        'BFS-Nr': 2476,
        Kantonskürzel: 'SO',
        E: 7.512363473791688,
        N: 47.4772816197209,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Mariastein',
        PLZ: 4115,
        Zusatzziffer: 0,
        Gemeindename: 'Metzerlen-Mariastein',
        'BFS-Nr': 2477,
        Kantonskürzel: 'SO',
        E: 7.4918786338192565,
        N: 47.476301354192536,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Metzerlen',
        PLZ: 4116,
        Zusatzziffer: 0,
        Gemeindename: 'Metzerlen-Mariastein',
        'BFS-Nr': 2477,
        Kantonskürzel: 'SO',
        E: 7.465929915042383,
        N: 47.46569680627432,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Nuglar',
        PLZ: 4412,
        Zusatzziffer: 0,
        Gemeindename: 'Nuglar-St. Pantaleon',
        'BFS-Nr': 2478,
        Kantonskürzel: 'SO',
        E: 7.6915709831791235,
        N: 47.46999400558233,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'St. Pantaleon',
        PLZ: 4421,
        Zusatzziffer: 0,
        Gemeindename: 'Nuglar-St. Pantaleon',
        'BFS-Nr': 2478,
        Kantonskürzel: 'SO',
        E: 7.690827728483004,
        N: 47.45964090963616,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Rodersdorf',
        PLZ: 4118,
        Zusatzziffer: 0,
        Gemeindename: 'Rodersdorf',
        'BFS-Nr': 2479,
        Kantonskürzel: 'SO',
        E: 7.456108762993546,
        N: 47.481157897009886,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Seewen SO',
        PLZ: 4206,
        Zusatzziffer: 0,
        Gemeindename: 'Seewen',
        'BFS-Nr': 2480,
        Kantonskürzel: 'SO',
        E: 7.660308122909247,
        N: 47.434629376624606,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Witterswil',
        PLZ: 4108,
        Zusatzziffer: 0,
        Gemeindename: 'Witterswil',
        'BFS-Nr': 2481,
        Kantonskürzel: 'SO',
        E: 7.528366730776671,
        N: 47.487461915551975,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Hauenstein',
        PLZ: 4633,
        Zusatzziffer: 0,
        Gemeindename: 'Hauenstein-Ifenthal',
        'BFS-Nr': 2491,
        Kantonskürzel: 'SO',
        E: 7.871330135727291,
        N: 47.37775486914992,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Kienberg',
        PLZ: 4468,
        Zusatzziffer: 0,
        Gemeindename: 'Kienberg',
        'BFS-Nr': 2492,
        Kantonskürzel: 'SO',
        E: 7.967763522650118,
        N: 47.43886744144428,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Lostorf',
        PLZ: 4654,
        Zusatzziffer: 0,
        Gemeindename: 'Lostorf',
        'BFS-Nr': 2493,
        Kantonskürzel: 'SO',
        E: 7.95170538584694,
        N: 47.383068784992545,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Niedergösgen',
        PLZ: 5013,
        Zusatzziffer: 0,
        Gemeindename: 'Niedergösgen',
        'BFS-Nr': 2495,
        Kantonskürzel: 'SO',
        E: 7.988126936479201,
        N: 47.37741733217601,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Obergösgen',
        PLZ: 4653,
        Zusatzziffer: 0,
        Gemeindename: 'Obergösgen',
        'BFS-Nr': 2497,
        Kantonskürzel: 'SO',
        E: 7.955836829637237,
        N: 47.36247263320103,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Stüsslingen',
        PLZ: 4655,
        Zusatzziffer: 0,
        Gemeindename: 'Stüsslingen',
        'BFS-Nr': 2499,
        Kantonskürzel: 'SO',
        E: 7.974276915480596,
        N: 47.39427802340245,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Rohr b. Olten',
        PLZ: 4655,
        Zusatzziffer: 2,
        Gemeindename: 'Stüsslingen',
        'BFS-Nr': 2499,
        Kantonskürzel: 'SO',
        E: 7.956269976044763,
        N: 47.41060533061872,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Trimbach',
        PLZ: 4632,
        Zusatzziffer: 0,
        Gemeindename: 'Trimbach',
        'BFS-Nr': 2500,
        Kantonskürzel: 'SO',
        E: 7.895941195086928,
        N: 47.3674386240857,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Winznau',
        PLZ: 4652,
        Zusatzziffer: 0,
        Gemeindename: 'Winznau',
        'BFS-Nr': 2501,
        Kantonskürzel: 'SO',
        E: 7.930110269914189,
        N: 47.36355834666422,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Wisen SO',
        PLZ: 4634,
        Zusatzziffer: 0,
        Gemeindename: 'Wisen (SO)',
        'BFS-Nr': 2502,
        Kantonskürzel: 'SO',
        E: 7.8873303678029325,
        N: 47.39290215112462,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Erlinsbach SO',
        PLZ: 5015,
        Zusatzziffer: 0,
        Gemeindename: 'Erlinsbach (SO)',
        'BFS-Nr': 2503,
        Kantonskürzel: 'SO',
        E: 8.000548307748778,
        N: 47.40068604893173,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Aeschi SO',
        PLZ: 4556,
        Zusatzziffer: 0,
        Gemeindename: 'Aeschi (SO)',
        'BFS-Nr': 2511,
        Kantonskürzel: 'SO',
        E: 7.66089695847235,
        N: 47.18151656521642,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Burgäschi',
        PLZ: 4556,
        Zusatzziffer: 3,
        Gemeindename: 'Aeschi (SO)',
        'BFS-Nr': 2511,
        Kantonskürzel: 'SO',
        E: 7.671085154747628,
        N: 47.172710265377155,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Steinhof SO',
        PLZ: 4556,
        Zusatzziffer: 4,
        Gemeindename: 'Aeschi (SO)',
        'BFS-Nr': 2511,
        Kantonskürzel: 'SO',
        E: 7.6863317839206085,
        N: 47.15915361959083,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Biberist',
        PLZ: 4562,
        Zusatzziffer: 0,
        Gemeindename: 'Biberist',
        'BFS-Nr': 2513,
        Kantonskürzel: 'SO',
        E: 7.552292204185866,
        N: 47.18319359563419,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Bolken',
        PLZ: 4556,
        Zusatzziffer: 2,
        Gemeindename: 'Bolken',
        'BFS-Nr': 2514,
        Kantonskürzel: 'SO',
        E: 7.6663066596324825,
        N: 47.19129744688232,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Deitingen',
        PLZ: 4543,
        Zusatzziffer: 0,
        Gemeindename: 'Deitingen',
        'BFS-Nr': 2516,
        Kantonskürzel: 'SO',
        E: 7.626549670191431,
        N: 47.2163764562395,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Derendingen',
        PLZ: 4552,
        Zusatzziffer: 0,
        Gemeindename: 'Derendingen',
        'BFS-Nr': 2517,
        Kantonskürzel: 'SO',
        E: 7.586544185693632,
        N: 47.19476647225747,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Etziken',
        PLZ: 4554,
        Zusatzziffer: 0,
        Gemeindename: 'Etziken',
        'BFS-Nr': 2518,
        Kantonskürzel: 'SO',
        E: 7.647335113097721,
        N: 47.18937989876029,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Gerlafingen',
        PLZ: 4563,
        Zusatzziffer: 0,
        Gemeindename: 'Gerlafingen',
        'BFS-Nr': 2519,
        Kantonskürzel: 'SO',
        E: 7.571666327700666,
        N: 47.17051122417577,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Halten',
        PLZ: 4566,
        Zusatzziffer: 2,
        Gemeindename: 'Halten',
        'BFS-Nr': 2520,
        Kantonskürzel: 'SO',
        E: 7.608298567530413,
        N: 47.169825565423004,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Horriwil',
        PLZ: 4557,
        Zusatzziffer: 0,
        Gemeindename: 'Horriwil',
        'BFS-Nr': 2523,
        Kantonskürzel: 'SO',
        E: 7.622862720686456,
        N: 47.180438575310696,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Hüniken',
        PLZ: 4554,
        Zusatzziffer: 2,
        Gemeindename: 'Hüniken',
        'BFS-Nr': 2524,
        Kantonskürzel: 'SO',
        E: 7.635783154730595,
        N: 47.18306529706879,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Kriegstetten',
        PLZ: 4566,
        Zusatzziffer: 0,
        Gemeindename: 'Kriegstetten',
        'BFS-Nr': 2525,
        Kantonskürzel: 'SO',
        E: 7.594615546110406,
        N: 47.17430596871883,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Lohn-Ammannsegg',
        PLZ: 4573,
        Zusatzziffer: 0,
        Gemeindename: 'Lohn-Ammannsegg',
        'BFS-Nr': 2526,
        Kantonskürzel: 'SO',
        E: 7.527411367637412,
        N: 47.173265288643115,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Luterbach',
        PLZ: 4542,
        Zusatzziffer: 0,
        Gemeindename: 'Luterbach',
        'BFS-Nr': 2527,
        Kantonskürzel: 'SO',
        E: 7.5813692698376824,
        N: 47.21356405645555,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Obergerlafingen',
        PLZ: 4564,
        Zusatzziffer: 0,
        Gemeindename: 'Obergerlafingen',
        'BFS-Nr': 2528,
        Kantonskürzel: 'SO',
        E: 7.584126614693865,
        N: 47.16098784629211,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Oekingen',
        PLZ: 4566,
        Zusatzziffer: 3,
        Gemeindename: 'Oekingen',
        'BFS-Nr': 2529,
        Kantonskürzel: 'SO',
        E: 7.606145787052679,
        N: 47.18129372539712,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Recherswil',
        PLZ: 4565,
        Zusatzziffer: 0,
        Gemeindename: 'Recherswil',
        'BFS-Nr': 2530,
        Kantonskürzel: 'SO',
        E: 7.6000975774392785,
        N: 47.16045287154654,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Subingen',
        PLZ: 4553,
        Zusatzziffer: 0,
        Gemeindename: 'Subingen',
        'BFS-Nr': 2532,
        Kantonskürzel: 'SO',
        E: 7.622779150647907,
        N: 47.19665805979735,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Zuchwil',
        PLZ: 4528,
        Zusatzziffer: 0,
        Gemeindename: 'Zuchwil',
        'BFS-Nr': 2534,
        Kantonskürzel: 'SO',
        E: 7.562529283104628,
        N: 47.20440237967105,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Hersiwil',
        PLZ: 4558,
        Zusatzziffer: 0,
        Gemeindename: 'Drei Höfe',
        'BFS-Nr': 2535,
        Kantonskürzel: 'SO',
        E: 7.6347692495332655,
        N: 47.1657558675692,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Heinrichswil',
        PLZ: 4558,
        Zusatzziffer: 2,
        Gemeindename: 'Drei Höfe',
        'BFS-Nr': 2535,
        Kantonskürzel: 'SO',
        E: 7.634275675117344,
        N: 47.15661882501295,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Winistorf',
        PLZ: 4558,
        Zusatzziffer: 3,
        Gemeindename: 'Drei Höfe',
        'BFS-Nr': 2535,
        Kantonskürzel: 'SO',
        E: 7.650537949325098,
        N: 47.15264668887617,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Balmberg',
        PLZ: 4524,
        Zusatzziffer: 2,
        Gemeindename: 'Balm bei Günsberg',
        'BFS-Nr': 2541,
        Kantonskürzel: 'SO',
        E: 7.551725743301835,
        N: 47.26999016374785,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Oberbalmberg',
        PLZ: 4524,
        Zusatzziffer: 3,
        Gemeindename: 'Balm bei Günsberg',
        'BFS-Nr': 2541,
        Kantonskürzel: 'SO',
        E: 7.5393107476592744,
        N: 47.26439216017489,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Balm b. Günsberg',
        PLZ: 4525,
        Zusatzziffer: 0,
        Gemeindename: 'Balm bei Günsberg',
        'BFS-Nr': 2541,
        Kantonskürzel: 'SO',
        E: 7.554071229228513,
        N: 47.25347933929094,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Bellach',
        PLZ: 4512,
        Zusatzziffer: 0,
        Gemeindename: 'Bellach',
        'BFS-Nr': 2542,
        Kantonskürzel: 'SO',
        E: 7.50098414410151,
        N: 47.2121407888905,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Bettlach',
        PLZ: 2544,
        Zusatzziffer: 0,
        Gemeindename: 'Bettlach',
        'BFS-Nr': 2543,
        Kantonskürzel: 'SO',
        E: 7.42970945751733,
        N: 47.2033938522557,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Feldbrunnen',
        PLZ: 4532,
        Zusatzziffer: 0,
        Gemeindename: 'Feldbrunnen-St. Niklaus',
        'BFS-Nr': 2544,
        Kantonskürzel: 'SO',
        E: 7.55280382691536,
        N: 47.22124128194957,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Flumenthal',
        PLZ: 4534,
        Zusatzziffer: 0,
        Gemeindename: 'Flumenthal',
        'BFS-Nr': 2545,
        Kantonskürzel: 'SO',
        E: 7.601530851696723,
        N: 47.23550343602723,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Grenchen',
        PLZ: 2540,
        Zusatzziffer: 0,
        Gemeindename: 'Grenchen',
        'BFS-Nr': 2546,
        Kantonskürzel: 'SO',
        E: 7.394721019989022,
        N: 47.19281434377215,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Günsberg',
        PLZ: 4524,
        Zusatzziffer: 0,
        Gemeindename: 'Günsberg',
        'BFS-Nr': 2547,
        Kantonskürzel: 'SO',
        E: 7.575945870153987,
        N: 47.25984825616821,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Hubersdorf',
        PLZ: 4535,
        Zusatzziffer: 0,
        Gemeindename: 'Hubersdorf',
        'BFS-Nr': 2548,
        Kantonskürzel: 'SO',
        E: 7.588778994348915,
        N: 47.24478309342452,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Kammersrohr',
        PLZ: 4535,
        Zusatzziffer: 2,
        Gemeindename: 'Kammersrohr',
        'BFS-Nr': 2549,
        Kantonskürzel: 'SO',
        E: 7.593203976547397,
        N: 47.25295723254994,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Langendorf',
        PLZ: 4513,
        Zusatzziffer: 0,
        Gemeindename: 'Langendorf',
        'BFS-Nr': 2550,
        Kantonskürzel: 'SO',
        E: 7.514550726964569,
        N: 47.221745181576175,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Lommiswil',
        PLZ: 4514,
        Zusatzziffer: 0,
        Gemeindename: 'Lommiswil',
        'BFS-Nr': 2551,
        Kantonskürzel: 'SO',
        E: 7.4636405202453275,
        N: 47.22244105190212,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Oberdorf SO',
        PLZ: 4515,
        Zusatzziffer: 0,
        Gemeindename: 'Oberdorf (SO)',
        'BFS-Nr': 2553,
        Kantonskürzel: 'SO',
        E: 7.502121390833773,
        N: 47.22774836254265,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Weissenstein b. Solothurn',
        PLZ: 4515,
        Zusatzziffer: 1,
        Gemeindename: 'Oberdorf (SO)',
        'BFS-Nr': 2553,
        Kantonskürzel: 'SO',
        E: 7.505983345937719,
        N: 47.25101845906098,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Niederwil SO',
        PLZ: 4523,
        Zusatzziffer: 0,
        Gemeindename: 'Riedholz',
        'BFS-Nr': 2554,
        Kantonskürzel: 'SO',
        E: 7.5699757619226835,
        N: 47.24936790316078,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Riedholz',
        PLZ: 4533,
        Zusatzziffer: 0,
        Gemeindename: 'Riedholz',
        'BFS-Nr': 2554,
        Kantonskürzel: 'SO',
        E: 7.566358872208891,
        N: 47.23379738745674,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Rüttenen',
        PLZ: 4522,
        Zusatzziffer: 0,
        Gemeindename: 'Rüttenen',
        'BFS-Nr': 2555,
        Kantonskürzel: 'SO',
        E: 7.530112453194684,
        N: 47.230465621444246,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Selzach',
        PLZ: 2545,
        Zusatzziffer: 0,
        Gemeindename: 'Selzach',
        'BFS-Nr': 2556,
        Kantonskürzel: 'SO',
        E: 7.453931115449455,
        N: 47.20607439165216,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Boningen',
        PLZ: 4618,
        Zusatzziffer: 0,
        Gemeindename: 'Boningen',
        'BFS-Nr': 2571,
        Kantonskürzel: 'SO',
        E: 7.856483646909357,
        N: 47.30808487332486,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Däniken SO',
        PLZ: 4658,
        Zusatzziffer: 0,
        Gemeindename: 'Däniken',
        'BFS-Nr': 2572,
        Kantonskürzel: 'SO',
        E: 7.982450840918766,
        N: 47.3552507682447,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Dulliken',
        PLZ: 4657,
        Zusatzziffer: 0,
        Gemeindename: 'Dulliken',
        'BFS-Nr': 2573,
        Kantonskürzel: 'SO',
        E: 7.9462469689500415,
        N: 47.347869971903535,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Wöschnau',
        PLZ: 5012,
        Zusatzziffer: 3,
        Gemeindename: 'Eppenberg-Wöschnau',
        'BFS-Nr': 2574,
        Kantonskürzel: 'SO',
        E: 8.02510955673538,
        N: 47.383520260622966,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Eppenberg',
        PLZ: 5012,
        Zusatzziffer: 4,
        Gemeindename: 'Eppenberg-Wöschnau',
        'BFS-Nr': 2574,
        Kantonskürzel: 'SO',
        E: 8.023635285954608,
        N: 47.37694944481511,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Fulenbach',
        PLZ: 4629,
        Zusatzziffer: 0,
        Gemeindename: 'Fulenbach',
        'BFS-Nr': 2575,
        Kantonskürzel: 'SO',
        E: 7.831352088540428,
        N: 47.277265055830746,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Gretzenbach',
        PLZ: 5014,
        Zusatzziffer: 0,
        Gemeindename: 'Gretzenbach',
        'BFS-Nr': 2576,
        Kantonskürzel: 'SO',
        E: 7.995936018823447,
        N: 47.361737639338756,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Gunzgen',
        PLZ: 4617,
        Zusatzziffer: 0,
        Gemeindename: 'Gunzgen',
        'BFS-Nr': 2578,
        Kantonskürzel: 'SO',
        E: 7.833230209208016,
        N: 47.31570990779996,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Hägendorf',
        PLZ: 4614,
        Zusatzziffer: 0,
        Gemeindename: 'Hägendorf',
        'BFS-Nr': 2579,
        Kantonskürzel: 'SO',
        E: 7.836697088020383,
        N: 47.3379137105608,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Allerheiligenberg',
        PLZ: 4615,
        Zusatzziffer: 0,
        Gemeindename: 'Hägendorf',
        'BFS-Nr': 2579,
        Kantonskürzel: 'SO',
        E: 7.814875342375677,
        N: 47.35253093689586,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Kappel SO',
        PLZ: 4616,
        Zusatzziffer: 0,
        Gemeindename: 'Kappel (SO)',
        'BFS-Nr': 2580,
        Kantonskürzel: 'SO',
        E: 7.8544746756600095,
        N: 47.322503816716704,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Olten',
        PLZ: 4600,
        Zusatzziffer: 0,
        Gemeindename: 'Olten',
        'BFS-Nr': 2581,
        Kantonskürzel: 'SO',
        E: 7.898623254589295,
        N: 47.35095126851565,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Rickenbach SO',
        PLZ: 4613,
        Zusatzziffer: 0,
        Gemeindename: 'Rickenbach (SO)',
        'BFS-Nr': 2582,
        Kantonskürzel: 'SO',
        E: 7.8546975866886335,
        N: 47.34239763057718,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Schönenwerd',
        PLZ: 5012,
        Zusatzziffer: 0,
        Gemeindename: 'Schönenwerd',
        'BFS-Nr': 2583,
        Kantonskürzel: 'SO',
        E: 8.010919881293042,
        N: 47.36955257993829,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Starrkirch-Wil',
        PLZ: 4656,
        Zusatzziffer: 0,
        Gemeindename: 'Starrkirch-Wil',
        'BFS-Nr': 2584,
        Kantonskürzel: 'SO',
        E: 7.929461334451165,
        N: 47.35260902920337,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Walterswil SO',
        PLZ: 5746,
        Zusatzziffer: 0,
        Gemeindename: 'Walterswil (SO)',
        'BFS-Nr': 2585,
        Kantonskürzel: 'SO',
        E: 7.9716555189071805,
        N: 47.328060617868054,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Wangen b. Olten',
        PLZ: 4612,
        Zusatzziffer: 0,
        Gemeindename: 'Wangen bei Olten',
        'BFS-Nr': 2586,
        Kantonskürzel: 'SO',
        E: 7.877376740945794,
        N: 47.34266994510035,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Solothurn',
        PLZ: 4500,
        Zusatzziffer: 0,
        Gemeindename: 'Solothurn',
        'BFS-Nr': 2601,
        Kantonskürzel: 'SO',
        E: 7.539730129000775,
        N: 47.20842622149813,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Bärschwil',
        PLZ: 4252,
        Zusatzziffer: 0,
        Gemeindename: 'Bärschwil',
        'BFS-Nr': 2611,
        Kantonskürzel: 'SO',
        E: 7.473087271589357,
        N: 47.38340104689255,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Beinwil SO',
        PLZ: 4229,
        Zusatzziffer: 0,
        Gemeindename: 'Beinwil (SO)',
        'BFS-Nr': 2612,
        Kantonskürzel: 'SO',
        E: 7.58745367383927,
        N: 47.36208704904361,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Breitenbach',
        PLZ: 4226,
        Zusatzziffer: 0,
        Gemeindename: 'Breitenbach',
        'BFS-Nr': 2613,
        Kantonskürzel: 'SO',
        E: 7.550965072025541,
        N: 47.4031541801511,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Büsserach',
        PLZ: 4227,
        Zusatzziffer: 0,
        Gemeindename: 'Büsserach',
        'BFS-Nr': 2614,
        Kantonskürzel: 'SO',
        E: 7.546776326488438,
        N: 47.389899017719415,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Erschwil',
        PLZ: 4228,
        Zusatzziffer: 0,
        Gemeindename: 'Erschwil',
        'BFS-Nr': 2615,
        Kantonskürzel: 'SO',
        E: 7.537197054683178,
        N: 47.37302936661651,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Fehren',
        PLZ: 4232,
        Zusatzziffer: 0,
        Gemeindename: 'Fehren',
        'BFS-Nr': 2616,
        Kantonskürzel: 'SO',
        E: 7.5769902654266765,
        N: 47.39652527351335,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Grindel',
        PLZ: 4247,
        Zusatzziffer: 0,
        Gemeindename: 'Grindel',
        'BFS-Nr': 2617,
        Kantonskürzel: 'SO',
        E: 7.501375271878777,
        N: 47.38136563172928,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Himmelried',
        PLZ: 4204,
        Zusatzziffer: 0,
        Gemeindename: 'Himmelried',
        'BFS-Nr': 2618,
        Kantonskürzel: 'SO',
        E: 7.598507301840952,
        N: 47.422155897081495,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Kleinlützel',
        PLZ: 4245,
        Zusatzziffer: 0,
        Gemeindename: 'Kleinlützel',
        'BFS-Nr': 2619,
        Kantonskürzel: 'SO',
        E: 7.417971487439651,
        N: 47.42426439353583,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Meltingen',
        PLZ: 4233,
        Zusatzziffer: 0,
        Gemeindename: 'Meltingen',
        'BFS-Nr': 2620,
        Kantonskürzel: 'SO',
        E: 7.588109820689691,
        N: 47.38646905270415,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Nunningen',
        PLZ: 4208,
        Zusatzziffer: 0,
        Gemeindename: 'Nunningen',
        'BFS-Nr': 2621,
        Kantonskürzel: 'SO',
        E: 7.619250934102281,
        N: 47.39339796044525,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Zullwil',
        PLZ: 4234,
        Zusatzziffer: 0,
        Gemeindename: 'Zullwil',
        'BFS-Nr': 2622,
        Kantonskürzel: 'SO',
        E: 7.599841400813107,
        N: 47.39115247591701,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Basel',
        PLZ: 4001,
        Zusatzziffer: 0,
        Gemeindename: 'Basel',
        'BFS-Nr': 2701,
        Kantonskürzel: 'BS',
        E: 7.588870113343706,
        N: 47.55637068489486,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Basel',
        PLZ: 4031,
        Zusatzziffer: 0,
        Gemeindename: 'Basel',
        'BFS-Nr': 2701,
        Kantonskürzel: 'BS',
        E: 7.582833473894854,
        N: 47.56196605555984,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Basel',
        PLZ: 4031,
        Zusatzziffer: 0,
        Gemeindename: 'Basel',
        'BFS-Nr': 2701,
        Kantonskürzel: 'BS',
        E: 7.576346251909282,
        N: 47.562486243105496,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Basel',
        PLZ: 4051,
        Zusatzziffer: 0,
        Gemeindename: 'Basel',
        'BFS-Nr': 2701,
        Kantonskürzel: 'BS',
        E: 7.586697633712084,
        N: 47.554190537244246,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Basel',
        PLZ: 4052,
        Zusatzziffer: 0,
        Gemeindename: 'Basel',
        'BFS-Nr': 2701,
        Kantonskürzel: 'BS',
        E: 7.596174402480728,
        N: 47.52247160085576,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Basel',
        PLZ: 4052,
        Zusatzziffer: 0,
        Gemeindename: 'Basel',
        'BFS-Nr': 2701,
        Kantonskürzel: 'BS',
        E: 7.609107209580731,
        N: 47.54502701755553,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Basel',
        PLZ: 4053,
        Zusatzziffer: 0,
        Gemeindename: 'Basel',
        'BFS-Nr': 2701,
        Kantonskürzel: 'BS',
        E: 7.601012683002598,
        N: 47.53833137100621,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Basel',
        PLZ: 4054,
        Zusatzziffer: 0,
        Gemeindename: 'Basel',
        'BFS-Nr': 2701,
        Kantonskürzel: 'BS',
        E: 7.568940143971525,
        N: 47.55049380824938,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Basel',
        PLZ: 4055,
        Zusatzziffer: 0,
        Gemeindename: 'Basel',
        'BFS-Nr': 2701,
        Kantonskürzel: 'BS',
        E: 7.563961744031856,
        N: 47.56372775378096,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Basel',
        PLZ: 4056,
        Zusatzziffer: 0,
        Gemeindename: 'Basel',
        'BFS-Nr': 2701,
        Kantonskürzel: 'BS',
        E: 7.5735336282100585,
        N: 47.56951486372504,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Basel',
        PLZ: 4057,
        Zusatzziffer: 0,
        Gemeindename: 'Basel',
        'BFS-Nr': 2701,
        Kantonskürzel: 'BS',
        E: 7.599656338067886,
        N: 47.57607136035068,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Basel',
        PLZ: 4058,
        Zusatzziffer: 0,
        Gemeindename: 'Basel',
        'BFS-Nr': 2701,
        Kantonskürzel: 'BS',
        E: 7.613483901142764,
        N: 47.56771366451022,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Basel',
        PLZ: 4059,
        Zusatzziffer: 0,
        Gemeindename: 'Basel',
        'BFS-Nr': 2701,
        Kantonskürzel: 'BS',
        E: 7.593411728698013,
        N: 47.53160364257617,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Bettingen',
        PLZ: 4126,
        Zusatzziffer: 0,
        Gemeindename: 'Bettingen',
        'BFS-Nr': 2702,
        Kantonskürzel: 'BS',
        E: 7.6649692026268665,
        N: 47.57096074487957,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Riehen',
        PLZ: 4125,
        Zusatzziffer: 0,
        Gemeindename: 'Riehen',
        'BFS-Nr': 2703,
        Kantonskürzel: 'BS',
        E: 7.649218137671261,
        N: 47.58471114896281,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Aesch BL',
        PLZ: 4147,
        Zusatzziffer: 0,
        Gemeindename: 'Aesch (BL)',
        'BFS-Nr': 2761,
        Kantonskürzel: 'BL',
        E: 7.596577758556619,
        N: 47.46685205427124,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Allschwil',
        PLZ: 4123,
        Zusatzziffer: 0,
        Gemeindename: 'Allschwil',
        'BFS-Nr': 2762,
        Kantonskürzel: 'BL',
        E: 7.535641462998725,
        N: 47.550620945832755,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Arlesheim',
        PLZ: 4144,
        Zusatzziffer: 0,
        Gemeindename: 'Arlesheim',
        'BFS-Nr': 2763,
        Kantonskürzel: 'BL',
        E: 7.626761740150684,
        N: 47.49508231367093,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Biel-Benken BL',
        PLZ: 4105,
        Zusatzziffer: 0,
        Gemeindename: 'Biel-Benken',
        'BFS-Nr': 2764,
        Kantonskürzel: 'BL',
        E: 7.519286263971317,
        N: 47.50357107152688,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Bruderholz',
        PLZ: 4101,
        Zusatzziffer: 0,
        Gemeindename: 'Binningen',
        'BFS-Nr': 2765,
        Kantonskürzel: 'BL',
        E: 7.582091789821619,
        N: 47.52857714968418,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Binningen',
        PLZ: 4102,
        Zusatzziffer: 0,
        Gemeindename: 'Binningen',
        'BFS-Nr': 2765,
        Kantonskürzel: 'BL',
        E: 7.577510759408908,
        N: 47.5391581535978,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Birsfelden',
        PLZ: 4127,
        Zusatzziffer: 0,
        Gemeindename: 'Birsfelden',
        'BFS-Nr': 2766,
        Kantonskürzel: 'BL',
        E: 7.625557759220216,
        N: 47.555447725174254,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Bottmingen',
        PLZ: 4103,
        Zusatzziffer: 0,
        Gemeindename: 'Bottmingen',
        'BFS-Nr': 2767,
        Kantonskürzel: 'BL',
        E: 7.574453419546123,
        N: 47.5257576530981,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Ettingen',
        PLZ: 4107,
        Zusatzziffer: 0,
        Gemeindename: 'Ettingen',
        'BFS-Nr': 2768,
        Kantonskürzel: 'BL',
        E: 7.541140750534698,
        N: 47.480392897471226,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Münchenstein',
        PLZ: 4142,
        Zusatzziffer: 0,
        Gemeindename: 'Münchenstein',
        'BFS-Nr': 2769,
        Kantonskürzel: 'BL',
        E: 7.6215552027864355,
        N: 47.51368936361954,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Muttenz',
        PLZ: 4132,
        Zusatzziffer: 0,
        Gemeindename: 'Muttenz',
        'BFS-Nr': 2770,
        Kantonskürzel: 'BL',
        E: 7.654465313913493,
        N: 47.52099882926945,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Oberwil BL',
        PLZ: 4104,
        Zusatzziffer: 0,
        Gemeindename: 'Oberwil (BL)',
        'BFS-Nr': 2771,
        Kantonskürzel: 'BL',
        E: 7.554009430473809,
        N: 47.51368881069738,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Pfeffingen',
        PLZ: 4148,
        Zusatzziffer: 0,
        Gemeindename: 'Pfeffingen',
        'BFS-Nr': 2772,
        Kantonskürzel: 'BL',
        E: 7.58894109513398,
        N: 47.459481900122555,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Reinach BL',
        PLZ: 4153,
        Zusatzziffer: 0,
        Gemeindename: 'Reinach (BL)',
        'BFS-Nr': 2773,
        Kantonskürzel: 'BL',
        E: 7.590496496968148,
        N: 47.49265265778774,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Schönenbuch',
        PLZ: 4124,
        Zusatzziffer: 0,
        Gemeindename: 'Schönenbuch',
        'BFS-Nr': 2774,
        Kantonskürzel: 'BL',
        E: 7.50588975842835,
        N: 47.5348391789757,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Therwil',
        PLZ: 4106,
        Zusatzziffer: 0,
        Gemeindename: 'Therwil',
        'BFS-Nr': 2775,
        Kantonskürzel: 'BL',
        E: 7.553658001057792,
        N: 47.497230179002415,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Blauen',
        PLZ: 4223,
        Zusatzziffer: 0,
        Gemeindename: 'Blauen',
        'BFS-Nr': 2781,
        Kantonskürzel: 'BL',
        E: 7.5181034928341095,
        N: 47.45076301256308,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Brislach',
        PLZ: 4225,
        Zusatzziffer: 0,
        Gemeindename: 'Brislach',
        'BFS-Nr': 2782,
        Kantonskürzel: 'BL',
        E: 7.543649192705194,
        N: 47.418054896663655,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Burg im Leimental',
        PLZ: 4117,
        Zusatzziffer: 0,
        Gemeindename: 'Burg im Leimental',
        'BFS-Nr': 2783,
        Kantonskürzel: 'BL',
        E: 7.4394950299998,
        N: 47.45607024809806,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Dittingen',
        PLZ: 4243,
        Zusatzziffer: 0,
        Gemeindename: 'Dittingen',
        'BFS-Nr': 2784,
        Kantonskürzel: 'BL',
        E: 7.496297572492436,
        N: 47.44214040083346,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Duggingen',
        PLZ: 4202,
        Zusatzziffer: 0,
        Gemeindename: 'Duggingen',
        'BFS-Nr': 2785,
        Kantonskürzel: 'BL',
        E: 7.606372771928876,
        N: 47.452301733755306,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Grellingen',
        PLZ: 4203,
        Zusatzziffer: 0,
        Gemeindename: 'Grellingen',
        'BFS-Nr': 2786,
        Kantonskürzel: 'BL',
        E: 7.587594495610833,
        N: 47.441671893072005,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Laufen',
        PLZ: 4242,
        Zusatzziffer: 0,
        Gemeindename: 'Laufen',
        'BFS-Nr': 2787,
        Kantonskürzel: 'BL',
        E: 7.498428335198225,
        N: 47.42111498269406,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Liesberg',
        PLZ: 4253,
        Zusatzziffer: 0,
        Gemeindename: 'Liesberg',
        'BFS-Nr': 2788,
        Kantonskürzel: 'BL',
        E: 7.42177825570078,
        N: 47.396962523969876,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Liesberg Dorf',
        PLZ: 4254,
        Zusatzziffer: 0,
        Gemeindename: 'Liesberg',
        'BFS-Nr': 2788,
        Kantonskürzel: 'BL',
        E: 7.429214623760897,
        N: 47.40437085598599,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Nenzlingen',
        PLZ: 4224,
        Zusatzziffer: 0,
        Gemeindename: 'Nenzlingen',
        'BFS-Nr': 2789,
        Kantonskürzel: 'BL',
        E: 7.563705183654132,
        N: 47.44807862495942,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Roggenburg',
        PLZ: 2814,
        Zusatzziffer: 0,
        Gemeindename: 'Roggenburg',
        'BFS-Nr': 2790,
        Kantonskürzel: 'BL',
        E: 7.341370045891763,
        N: 47.43304566745117,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Röschenz',
        PLZ: 4244,
        Zusatzziffer: 0,
        Gemeindename: 'Röschenz',
        'BFS-Nr': 2791,
        Kantonskürzel: 'BL',
        E: 7.47234125401743,
        N: 47.427797784130604,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Wahlen b. Laufen',
        PLZ: 4246,
        Zusatzziffer: 0,
        Gemeindename: 'Wahlen',
        'BFS-Nr': 2792,
        Kantonskürzel: 'BL',
        E: 7.516121723455267,
        N: 47.4024381250759,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Zwingen',
        PLZ: 4222,
        Zusatzziffer: 0,
        Gemeindename: 'Zwingen',
        'BFS-Nr': 2793,
        Kantonskürzel: 'BL',
        E: 7.529679782585629,
        N: 47.437451350959556,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Arisdorf',
        PLZ: 4422,
        Zusatzziffer: 0,
        Gemeindename: 'Arisdorf',
        'BFS-Nr': 2821,
        Kantonskürzel: 'BL',
        E: 7.766884692152538,
        N: 47.51077489401165,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Augst BL',
        PLZ: 4302,
        Zusatzziffer: 0,
        Gemeindename: 'Augst',
        'BFS-Nr': 2822,
        Kantonskürzel: 'BL',
        E: 7.719991739928825,
        N: 47.53247753587151,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Bubendorf',
        PLZ: 4416,
        Zusatzziffer: 0,
        Gemeindename: 'Bubendorf',
        'BFS-Nr': 2823,
        Kantonskürzel: 'BL',
        E: 7.734645500280203,
        N: 47.44892043713861,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Frenkendorf',
        PLZ: 4402,
        Zusatzziffer: 0,
        Gemeindename: 'Frenkendorf',
        'BFS-Nr': 2824,
        Kantonskürzel: 'BL',
        E: 7.706743964125709,
        N: 47.501853913380245,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Füllinsdorf',
        PLZ: 4414,
        Zusatzziffer: 0,
        Gemeindename: 'Füllinsdorf',
        'BFS-Nr': 2825,
        Kantonskürzel: 'BL',
        E: 7.7287269684371465,
        N: 47.50756063121848,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Giebenach',
        PLZ: 4304,
        Zusatzziffer: 0,
        Gemeindename: 'Giebenach',
        'BFS-Nr': 2826,
        Kantonskürzel: 'BL',
        E: 7.743283502935662,
        N: 47.523494744663296,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Hersberg',
        PLZ: 4423,
        Zusatzziffer: 0,
        Gemeindename: 'Hersberg',
        'BFS-Nr': 2827,
        Kantonskürzel: 'BL',
        E: 7.78197905258427,
        N: 47.491396959092675,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Lausen',
        PLZ: 4415,
        Zusatzziffer: 0,
        Gemeindename: 'Lausen',
        'BFS-Nr': 2828,
        Kantonskürzel: 'BL',
        E: 7.759559085286316,
        N: 47.47033386094739,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Liestal',
        PLZ: 4410,
        Zusatzziffer: 0,
        Gemeindename: 'Liestal',
        'BFS-Nr': 2829,
        Kantonskürzel: 'BL',
        E: 7.734837044272838,
        N: 47.4847952025951,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Lupsingen',
        PLZ: 4419,
        Zusatzziffer: 0,
        Gemeindename: 'Lupsingen',
        'BFS-Nr': 2830,
        Kantonskürzel: 'BL',
        E: 7.69402608165102,
        N: 47.44580065085959,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Pratteln',
        PLZ: 4133,
        Zusatzziffer: 0,
        Gemeindename: 'Pratteln',
        'BFS-Nr': 2831,
        Kantonskürzel: 'BL',
        E: 7.6880761609829475,
        N: 47.5156671479948,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Ramlinsburg',
        PLZ: 4433,
        Zusatzziffer: 0,
        Gemeindename: 'Ramlinsburg',
        'BFS-Nr': 2832,
        Kantonskürzel: 'BL',
        E: 7.769565169609722,
        N: 47.44913500065285,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Seltisberg',
        PLZ: 4411,
        Zusatzziffer: 0,
        Gemeindename: 'Seltisberg',
        'BFS-Nr': 2833,
        Kantonskürzel: 'BL',
        E: 7.715215110766593,
        N: 47.45913644167224,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Ziefen',
        PLZ: 4417,
        Zusatzziffer: 0,
        Gemeindename: 'Ziefen',
        'BFS-Nr': 2834,
        Kantonskürzel: 'BL',
        E: 7.70530257608472,
        N: 47.42924293162259,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Anwil',
        PLZ: 4469,
        Zusatzziffer: 0,
        Gemeindename: 'Anwil',
        'BFS-Nr': 2841,
        Kantonskürzel: 'BL',
        E: 7.939884737447228,
        N: 47.451359380780524,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Böckten',
        PLZ: 4461,
        Zusatzziffer: 0,
        Gemeindename: 'Böckten',
        'BFS-Nr': 2842,
        Kantonskürzel: 'BL',
        E: 7.832644904987675,
        N: 47.46539831538596,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Buckten',
        PLZ: 4446,
        Zusatzziffer: 0,
        Gemeindename: 'Buckten',
        'BFS-Nr': 2843,
        Kantonskürzel: 'BL',
        E: 7.844657205503039,
        N: 47.41020353788593,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Buus',
        PLZ: 4463,
        Zusatzziffer: 0,
        Gemeindename: 'Buus',
        'BFS-Nr': 2844,
        Kantonskürzel: 'BL',
        E: 7.866240568423815,
        N: 47.50587017812506,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Diepflingen',
        PLZ: 4442,
        Zusatzziffer: 0,
        Gemeindename: 'Diepflingen',
        'BFS-Nr': 2845,
        Kantonskürzel: 'BL',
        E: 7.836413350719449,
        N: 47.44443483683113,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Gelterkinden',
        PLZ: 4460,
        Zusatzziffer: 0,
        Gemeindename: 'Gelterkinden',
        'BFS-Nr': 2846,
        Kantonskürzel: 'BL',
        E: 7.858803366158925,
        N: 47.46423285796233,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Häfelfingen',
        PLZ: 4445,
        Zusatzziffer: 0,
        Gemeindename: 'Häfelfingen',
        'BFS-Nr': 2847,
        Kantonskürzel: 'BL',
        E: 7.866042094794459,
        N: 47.41503418831654,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Hemmiken',
        PLZ: 4465,
        Zusatzziffer: 0,
        Gemeindename: 'Hemmiken',
        'BFS-Nr': 2848,
        Kantonskürzel: 'BL',
        E: 7.892945978308315,
        N: 47.48840397894801,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Itingen',
        PLZ: 4452,
        Zusatzziffer: 0,
        Gemeindename: 'Itingen',
        'BFS-Nr': 2849,
        Kantonskürzel: 'BL',
        E: 7.782383377110943,
        N: 47.465584563876135,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Känerkinden',
        PLZ: 4447,
        Zusatzziffer: 0,
        Gemeindename: 'Känerkinden',
        'BFS-Nr': 2850,
        Kantonskürzel: 'BL',
        E: 7.833744153376466,
        N: 47.41471333764632,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Kilchberg BL',
        PLZ: 4496,
        Zusatzziffer: 0,
        Gemeindename: 'Kilchberg (BL)',
        'BFS-Nr': 2851,
        Kantonskürzel: 'BL',
        E: 7.8989715567828,
        N: 47.425850947012584,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Läufelfingen',
        PLZ: 4448,
        Zusatzziffer: 0,
        Gemeindename: 'Läufelfingen',
        'BFS-Nr': 2852,
        Kantonskürzel: 'BL',
        E: 7.859505150703256,
        N: 47.39793333305456,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Maisprach',
        PLZ: 4464,
        Zusatzziffer: 0,
        Gemeindename: 'Maisprach',
        'BFS-Nr': 2853,
        Kantonskürzel: 'BL',
        E: 7.843981198043401,
        N: 47.524928333433266,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Nusshof',
        PLZ: 4453,
        Zusatzziffer: 0,
        Gemeindename: 'Nusshof',
        'BFS-Nr': 2854,
        Kantonskürzel: 'BL',
        E: 7.804052003317728,
        N: 47.49154973513198,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Oltingen',
        PLZ: 4494,
        Zusatzziffer: 0,
        Gemeindename: 'Oltingen',
        'BFS-Nr': 2855,
        Kantonskürzel: 'BL',
        E: 7.933773352036215,
        N: 47.43311734034831,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Ormalingen',
        PLZ: 4466,
        Zusatzziffer: 0,
        Gemeindename: 'Ormalingen',
        'BFS-Nr': 2856,
        Kantonskürzel: 'BL',
        E: 7.874077394501319,
        N: 47.46937895936574,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Rickenbach BL',
        PLZ: 4462,
        Zusatzziffer: 0,
        Gemeindename: 'Rickenbach (BL)',
        'BFS-Nr': 2857,
        Kantonskürzel: 'BL',
        E: 7.850511539386286,
        N: 47.48471996993386,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Rothenfluh',
        PLZ: 4467,
        Zusatzziffer: 0,
        Gemeindename: 'Rothenfluh',
        'BFS-Nr': 2858,
        Kantonskürzel: 'BL',
        E: 7.914393311542638,
        N: 47.46195012346367,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Rümlingen',
        PLZ: 4444,
        Zusatzziffer: 0,
        Gemeindename: 'Rümlingen',
        'BFS-Nr': 2859,
        Kantonskürzel: 'BL',
        E: 7.8534913849105505,
        N: 47.425766569508255,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Rünenberg',
        PLZ: 4497,
        Zusatzziffer: 0,
        Gemeindename: 'Rünenberg',
        'BFS-Nr': 2860,
        Kantonskürzel: 'BL',
        E: 7.8871878141135054,
        N: 47.43300823868636,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Sissach',
        PLZ: 4450,
        Zusatzziffer: 0,
        Gemeindename: 'Sissach',
        'BFS-Nr': 2861,
        Kantonskürzel: 'BL',
        E: 7.815969449157543,
        N: 47.470755464585,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Tecknau',
        PLZ: 4492,
        Zusatzziffer: 0,
        Gemeindename: 'Tecknau',
        'BFS-Nr': 2862,
        Kantonskürzel: 'BL',
        E: 7.887409439112948,
        N: 47.445892864007654,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Tenniken',
        PLZ: 4456,
        Zusatzziffer: 0,
        Gemeindename: 'Tenniken',
        'BFS-Nr': 2863,
        Kantonskürzel: 'BL',
        E: 7.816405820848666,
        N: 47.436527854465815,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Thürnen',
        PLZ: 4441,
        Zusatzziffer: 0,
        Gemeindename: 'Thürnen',
        'BFS-Nr': 2864,
        Kantonskürzel: 'BL',
        E: 7.827855268557114,
        N: 47.4547865271382,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Wenslingen',
        PLZ: 4493,
        Zusatzziffer: 0,
        Gemeindename: 'Wenslingen',
        'BFS-Nr': 2865,
        Kantonskürzel: 'BL',
        E: 7.909527000568207,
        N: 47.43996213375178,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Wintersingen',
        PLZ: 4451,
        Zusatzziffer: 0,
        Gemeindename: 'Wintersingen',
        'BFS-Nr': 2866,
        Kantonskürzel: 'BL',
        E: 7.825954666428532,
        N: 47.49253593373747,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Wittinsburg',
        PLZ: 4443,
        Zusatzziffer: 0,
        Gemeindename: 'Wittinsburg',
        'BFS-Nr': 2867,
        Kantonskürzel: 'BL',
        E: 7.839281394545653,
        N: 47.427255530291525,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Zeglingen',
        PLZ: 4495,
        Zusatzziffer: 0,
        Gemeindename: 'Zeglingen',
        'BFS-Nr': 2868,
        Kantonskürzel: 'BL',
        E: 7.904712151242405,
        N: 47.41684418666053,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Zunzgen',
        PLZ: 4455,
        Zusatzziffer: 0,
        Gemeindename: 'Zunzgen',
        'BFS-Nr': 2869,
        Kantonskürzel: 'BL',
        E: 7.802706968333333,
        N: 47.4492006537145,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Arboldswil',
        PLZ: 4424,
        Zusatzziffer: 0,
        Gemeindename: 'Arboldswil',
        'BFS-Nr': 2881,
        Kantonskürzel: 'BL',
        E: 7.7186027105621955,
        N: 47.41662559649289,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Bennwil',
        PLZ: 4431,
        Zusatzziffer: 0,
        Gemeindename: 'Bennwil',
        'BFS-Nr': 2882,
        Kantonskürzel: 'BL',
        E: 7.781152216383496,
        N: 47.40073507869624,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Bretzwil',
        PLZ: 4207,
        Zusatzziffer: 0,
        Gemeindename: 'Bretzwil',
        'BFS-Nr': 2883,
        Kantonskürzel: 'BL',
        E: 7.648835502346156,
        N: 47.39952600150013,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Diegten',
        PLZ: 4457,
        Zusatzziffer: 0,
        Gemeindename: 'Diegten',
        'BFS-Nr': 2884,
        Kantonskürzel: 'BL',
        E: 7.812870525886726,
        N: 47.41455602804055,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Eptingen',
        PLZ: 4458,
        Zusatzziffer: 0,
        Gemeindename: 'Eptingen',
        'BFS-Nr': 2885,
        Kantonskürzel: 'BL',
        E: 7.823733994937671,
        N: 47.38610888310278,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Hölstein',
        PLZ: 4434,
        Zusatzziffer: 0,
        Gemeindename: 'Hölstein',
        'BFS-Nr': 2886,
        Kantonskürzel: 'BL',
        E: 7.775622030571913,
        N: 47.42707546329261,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Lampenberg',
        PLZ: 4432,
        Zusatzziffer: 0,
        Gemeindename: 'Lampenberg',
        'BFS-Nr': 2887,
        Kantonskürzel: 'BL',
        E: 7.754166165345647,
        N: 47.42612355519864,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Langenbruck',
        PLZ: 4438,
        Zusatzziffer: 0,
        Gemeindename: 'Langenbruck',
        'BFS-Nr': 2888,
        Kantonskürzel: 'BL',
        E: 7.767491480955991,
        N: 47.34889426029655,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Lauwil',
        PLZ: 4426,
        Zusatzziffer: 0,
        Gemeindename: 'Lauwil',
        'BFS-Nr': 2889,
        Kantonskürzel: 'BL',
        E: 7.675400620785453,
        N: 47.387736997452365,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Liedertswil',
        PLZ: 4436,
        Zusatzziffer: 2,
        Gemeindename: 'Liedertswil',
        'BFS-Nr': 2890,
        Kantonskürzel: 'BL',
        E: 7.720666248437504,
        N: 47.390518453110055,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Niederdorf',
        PLZ: 4435,
        Zusatzziffer: 0,
        Gemeindename: 'Niederdorf',
        'BFS-Nr': 2891,
        Kantonskürzel: 'BL',
        E: 7.749580766011817,
        N: 47.40945327411642,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Oberdorf BL',
        PLZ: 4436,
        Zusatzziffer: 0,
        Gemeindename: 'Oberdorf (BL)',
        'BFS-Nr': 2892,
        Kantonskürzel: 'BL',
        E: 7.753843036559463,
        N: 47.39128341277502,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Reigoldswil',
        PLZ: 4418,
        Zusatzziffer: 0,
        Gemeindename: 'Reigoldswil',
        'BFS-Nr': 2893,
        Kantonskürzel: 'BL',
        E: 7.68666671092082,
        N: 47.40040380149326,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Titterten',
        PLZ: 4425,
        Zusatzziffer: 0,
        Gemeindename: 'Titterten',
        'BFS-Nr': 2894,
        Kantonskürzel: 'BL',
        E: 7.719533620598961,
        N: 47.403903756790704,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Waldenburg',
        PLZ: 4437,
        Zusatzziffer: 0,
        Gemeindename: 'Waldenburg',
        'BFS-Nr': 2895,
        Kantonskürzel: 'BL',
        E: 7.748811254449349,
        N: 47.38289732580177,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Gächlingen',
        PLZ: 8214,
        Zusatzziffer: 0,
        Gemeindename: 'Gächlingen',
        'BFS-Nr': 2901,
        Kantonskürzel: 'SH',
        E: 8.499914739841,
        N: 47.70223961102723,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Gächlingen',
        PLZ: 8214,
        Zusatzziffer: 0,
        Gemeindename: 'Gächlingen',
        'BFS-Nr': 2901,
        Kantonskürzel: 'SH',
        E: 8.510387388155989,
        N: 47.7351129126336,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Löhningen',
        PLZ: 8224,
        Zusatzziffer: 0,
        Gemeindename: 'Löhningen',
        'BFS-Nr': 2903,
        Kantonskürzel: 'SH',
        E: 8.553064840247584,
        N: 47.69912853977566,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Neunkirch',
        PLZ: 8213,
        Zusatzziffer: 0,
        Gemeindename: 'Neunkirch',
        'BFS-Nr': 2904,
        Kantonskürzel: 'SH',
        E: 8.498689224812633,
        N: 47.69058141558467,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Büttenhardt',
        PLZ: 8236,
        Zusatzziffer: 0,
        Gemeindename: 'Büttenhardt',
        'BFS-Nr': 2914,
        Kantonskürzel: 'SH',
        E: 8.652982183272087,
        N: 47.75602666928816,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Dörflingen',
        PLZ: 8239,
        Zusatzziffer: 0,
        Gemeindename: 'Dörflingen',
        'BFS-Nr': 2915,
        Kantonskürzel: 'SH',
        E: 8.72284975667164,
        N: 47.70616146124532,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Lohn SH',
        PLZ: 8235,
        Zusatzziffer: 0,
        Gemeindename: 'Lohn (SH)',
        'BFS-Nr': 2917,
        Kantonskürzel: 'SH',
        E: 8.671226011560446,
        N: 47.756404599381966,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Stetten SH',
        PLZ: 8234,
        Zusatzziffer: 0,
        Gemeindename: 'Stetten (SH)',
        'BFS-Nr': 2919,
        Kantonskürzel: 'SH',
        E: 8.663118865631521,
        N: 47.74019275137458,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Opfertshofen SH',
        PLZ: 8236,
        Zusatzziffer: 2,
        Gemeindename: 'Thayngen',
        'BFS-Nr': 2920,
        Kantonskürzel: 'SH',
        E: 8.662608403269388,
        N: 47.777343479946815,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Thayngen',
        PLZ: 8240,
        Zusatzziffer: 0,
        Gemeindename: 'Thayngen',
        'BFS-Nr': 2920,
        Kantonskürzel: 'SH',
        E: 8.711194668363992,
        N: 47.747441047833014,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Barzheim',
        PLZ: 8241,
        Zusatzziffer: 0,
        Gemeindename: 'Thayngen',
        'BFS-Nr': 2920,
        Kantonskürzel: 'SH',
        E: 8.721166707726539,
        N: 47.760675898722,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Bibern SH',
        PLZ: 8242,
        Zusatzziffer: 0,
        Gemeindename: 'Thayngen',
        'BFS-Nr': 2920,
        Kantonskürzel: 'SH',
        E: 8.67729716563837,
        N: 47.77263578454034,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Hofen SH',
        PLZ: 8242,
        Zusatzziffer: 1,
        Gemeindename: 'Thayngen',
        'BFS-Nr': 2920,
        Kantonskürzel: 'SH',
        E: 8.676873205131708,
        N: 47.78274105824539,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Altdorf SH',
        PLZ: 8243,
        Zusatzziffer: 0,
        Gemeindename: 'Thayngen',
        'BFS-Nr': 2920,
        Kantonskürzel: 'SH',
        E: 8.658575871778845,
        N: 47.78409678066163,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Bargen SH',
        PLZ: 8233,
        Zusatzziffer: 0,
        Gemeindename: 'Bargen (SH)',
        'BFS-Nr': 2931,
        Kantonskürzel: 'SH',
        E: 8.610245613254218,
        N: 47.79191161127814,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Beringen',
        PLZ: 8222,
        Zusatzziffer: 0,
        Gemeindename: 'Beringen',
        'BFS-Nr': 2932,
        Kantonskürzel: 'SH',
        E: 8.572611624934394,
        N: 47.699178668182974,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Guntmadingen',
        PLZ: 8223,
        Zusatzziffer: 0,
        Gemeindename: 'Beringen',
        'BFS-Nr': 2932,
        Kantonskürzel: 'SH',
        E: 8.557250708561561,
        N: 47.686015147377134,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Buchberg',
        PLZ: 8454,
        Zusatzziffer: 0,
        Gemeindename: 'Buchberg',
        'BFS-Nr': 2933,
        Kantonskürzel: 'SH',
        E: 8.562847812627616,
        N: 47.57278001913014,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Merishausen',
        PLZ: 8232,
        Zusatzziffer: 0,
        Gemeindename: 'Merishausen',
        'BFS-Nr': 2936,
        Kantonskürzel: 'SH',
        E: 8.607066926262922,
        N: 47.760087838359176,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Neuhausen am Rheinfall',
        PLZ: 8212,
        Zusatzziffer: 0,
        Gemeindename: 'Neuhausen am Rheinfall',
        'BFS-Nr': 2937,
        Kantonskürzel: 'SH',
        E: 8.617446571830547,
        N: 47.68182622587651,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Rüdlingen',
        PLZ: 8455,
        Zusatzziffer: 0,
        Gemeindename: 'Rüdlingen',
        'BFS-Nr': 2938,
        Kantonskürzel: 'SH',
        E: 8.572229025722118,
        N: 47.57917928752981,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Schaffhausen',
        PLZ: 8200,
        Zusatzziffer: 0,
        Gemeindename: 'Schaffhausen',
        'BFS-Nr': 2939,
        Kantonskürzel: 'SH',
        E: 8.625944327597136,
        N: 47.71766273098231,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Schaffhausen',
        PLZ: 8203,
        Zusatzziffer: 0,
        Gemeindename: 'Schaffhausen',
        'BFS-Nr': 2939,
        Kantonskürzel: 'SH',
        E: 8.66205519343421,
        N: 47.6997598969335,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Schaffhausen',
        PLZ: 8207,
        Zusatzziffer: 0,
        Gemeindename: 'Schaffhausen',
        'BFS-Nr': 2939,
        Kantonskürzel: 'SH',
        E: 8.672768788265897,
        N: 47.72346967760935,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Schaffhausen',
        PLZ: 8208,
        Zusatzziffer: 0,
        Gemeindename: 'Schaffhausen',
        'BFS-Nr': 2939,
        Kantonskürzel: 'SH',
        E: 8.635900474439833,
        N: 47.71189902286902,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Hemmental',
        PLZ: 8231,
        Zusatzziffer: 0,
        Gemeindename: 'Schaffhausen',
        'BFS-Nr': 2939,
        Kantonskürzel: 'SH',
        E: 8.579115266112112,
        N: 47.735326832417826,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Beggingen',
        PLZ: 8228,
        Zusatzziffer: 0,
        Gemeindename: 'Beggingen',
        'BFS-Nr': 2951,
        Kantonskürzel: 'SH',
        E: 8.533659241969888,
        N: 47.76762415523137,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Schleitheim',
        PLZ: 8226,
        Zusatzziffer: 0,
        Gemeindename: 'Schleitheim',
        'BFS-Nr': 2952,
        Kantonskürzel: 'SH',
        E: 8.488614291114507,
        N: 47.74962633306975,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Siblingen',
        PLZ: 8225,
        Zusatzziffer: 0,
        Gemeindename: 'Siblingen',
        'BFS-Nr': 2953,
        Kantonskürzel: 'SH',
        E: 8.519391058790344,
        N: 47.71418720629928,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Buch SH',
        PLZ: 8263,
        Zusatzziffer: 0,
        Gemeindename: 'Buch (SH)',
        'BFS-Nr': 2961,
        Kantonskürzel: 'SH',
        E: 8.783015003804268,
        N: 47.71710039603235,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Hemishofen',
        PLZ: 8261,
        Zusatzziffer: 0,
        Gemeindename: 'Hemishofen',
        'BFS-Nr': 2962,
        Kantonskürzel: 'SH',
        E: 8.8320704874562,
        N: 47.67687162399041,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Ramsen',
        PLZ: 8262,
        Zusatzziffer: 0,
        Gemeindename: 'Ramsen',
        'BFS-Nr': 2963,
        Kantonskürzel: 'SH',
        E: 8.813003041693037,
        N: 47.706928806275414,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Stein am Rhein',
        PLZ: 8260,
        Zusatzziffer: 0,
        Gemeindename: 'Stein am Rhein',
        'BFS-Nr': 2964,
        Kantonskürzel: 'SH',
        E: 8.860172420275655,
        N: 47.658935719557284,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Hallau',
        PLZ: 8215,
        Zusatzziffer: 0,
        Gemeindename: 'Hallau',
        'BFS-Nr': 2971,
        Kantonskürzel: 'SH',
        E: 8.462171251437317,
        N: 47.698414815703565,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Oberhallau',
        PLZ: 8216,
        Zusatzziffer: 0,
        Gemeindename: 'Oberhallau',
        'BFS-Nr': 2972,
        Kantonskürzel: 'SH',
        E: 8.47852194976199,
        N: 47.70488198264856,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Trasadingen',
        PLZ: 8219,
        Zusatzziffer: 0,
        Gemeindename: 'Trasadingen',
        'BFS-Nr': 2973,
        Kantonskürzel: 'SH',
        E: 8.43067620221272,
        N: 47.667936847353786,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Wilchingen',
        PLZ: 8217,
        Zusatzziffer: 0,
        Gemeindename: 'Wilchingen',
        'BFS-Nr': 2974,
        Kantonskürzel: 'SH',
        E: 8.519372868197038,
        N: 47.655502485572846,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Wilchingen',
        PLZ: 8217,
        Zusatzziffer: 0,
        Gemeindename: 'Wilchingen',
        'BFS-Nr': 2974,
        Kantonskürzel: 'SH',
        E: 8.46466743912962,
        N: 47.66923791360452,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Osterfingen',
        PLZ: 8218,
        Zusatzziffer: 0,
        Gemeindename: 'Wilchingen',
        'BFS-Nr': 2974,
        Kantonskürzel: 'SH',
        E: 8.491104201092346,
        N: 47.66307504176219,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Herisau',
        PLZ: 9100,
        Zusatzziffer: 0,
        Gemeindename: 'Herisau',
        'BFS-Nr': 3001,
        Kantonskürzel: 'AR',
        E: 9.279451691019037,
        N: 47.38592569730449,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Schachen b. Herisau',
        PLZ: 9112,
        Zusatzziffer: 0,
        Gemeindename: 'Herisau',
        'BFS-Nr': 3001,
        Kantonskürzel: 'AR',
        E: 9.243146227843573,
        N: 47.385091257335986,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Hundwil',
        PLZ: 9064,
        Zusatzziffer: 0,
        Gemeindename: 'Hundwil',
        'BFS-Nr': 3002,
        Kantonskürzel: 'AR',
        E: 9.320539709061876,
        N: 47.36564558625275,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Schönengrund',
        PLZ: 9105,
        Zusatzziffer: 0,
        Gemeindename: 'Schönengrund',
        'BFS-Nr': 3003,
        Kantonskürzel: 'AR',
        E: 9.226361464778448,
        N: 47.32578436455803,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Schwellbrunn',
        PLZ: 9103,
        Zusatzziffer: 0,
        Gemeindename: 'Schwellbrunn',
        'BFS-Nr': 3004,
        Kantonskürzel: 'AR',
        E: 9.24926780474281,
        N: 47.352259249283414,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Stein AR',
        PLZ: 9063,
        Zusatzziffer: 0,
        Gemeindename: 'Stein (AR)',
        'BFS-Nr': 3005,
        Kantonskürzel: 'AR',
        E: 9.34399890460838,
        N: 47.37511129920863,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Urnäsch',
        PLZ: 9107,
        Zusatzziffer: 0,
        Gemeindename: 'Urnäsch',
        'BFS-Nr': 3006,
        Kantonskürzel: 'AR',
        E: 9.28293694355312,
        N: 47.31704324769014,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Waldstatt',
        PLZ: 9104,
        Zusatzziffer: 0,
        Gemeindename: 'Waldstatt',
        'BFS-Nr': 3007,
        Kantonskürzel: 'AR',
        E: 9.283506051426595,
        N: 47.35647794683162,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Bühler',
        PLZ: 9055,
        Zusatzziffer: 0,
        Gemeindename: 'Bühler',
        'BFS-Nr': 3021,
        Kantonskürzel: 'AR',
        E: 9.425841920347851,
        N: 47.37339398841754,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Gais',
        PLZ: 9056,
        Zusatzziffer: 0,
        Gemeindename: 'Gais',
        'BFS-Nr': 3022,
        Kantonskürzel: 'AR',
        E: 9.455029331585484,
        N: 47.362275057514864,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Speicherschwendi',
        PLZ: 9037,
        Zusatzziffer: 0,
        Gemeindename: 'Speicher',
        'BFS-Nr': 3023,
        Kantonskürzel: 'AR',
        E: 9.436636400039884,
        N: 47.42517476798464,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Speicher',
        PLZ: 9042,
        Zusatzziffer: 0,
        Gemeindename: 'Speicher',
        'BFS-Nr': 3023,
        Kantonskürzel: 'AR',
        E: 9.442710395538475,
        N: 47.41318472487846,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Niederteufen',
        PLZ: 9052,
        Zusatzziffer: 0,
        Gemeindename: 'Teufen (AR)',
        'BFS-Nr': 3024,
        Kantonskürzel: 'AR',
        E: 9.367348250241708,
        N: 47.392249842799146,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Teufen AR',
        PLZ: 9053,
        Zusatzziffer: 0,
        Gemeindename: 'Teufen (AR)',
        'BFS-Nr': 3024,
        Kantonskürzel: 'AR',
        E: 9.38757553105238,
        N: 47.38986175090979,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Lustmühle',
        PLZ: 9062,
        Zusatzziffer: 0,
        Gemeindename: 'Teufen (AR)',
        'BFS-Nr': 3024,
        Kantonskürzel: 'AR',
        E: 9.359179769015343,
        N: 47.40029024964599,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Trogen',
        PLZ: 9043,
        Zusatzziffer: 0,
        Gemeindename: 'Trogen',
        'BFS-Nr': 3025,
        Kantonskürzel: 'AR',
        E: 9.465423036427048,
        N: 47.408306479331834,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Grub AR',
        PLZ: 9035,
        Zusatzziffer: 0,
        Gemeindename: 'Grub (AR)',
        'BFS-Nr': 3031,
        Kantonskürzel: 'AR',
        E: 9.509522929898669,
        N: 47.44844912193842,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Heiden',
        PLZ: 9410,
        Zusatzziffer: 0,
        Gemeindename: 'Heiden',
        'BFS-Nr': 3032,
        Kantonskürzel: 'AR',
        E: 9.53506624262168,
        N: 47.44511656482135,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Wienacht-Tobel',
        PLZ: 9405,
        Zusatzziffer: 0,
        Gemeindename: 'Lutzenberg',
        'BFS-Nr': 3033,
        Kantonskürzel: 'AR',
        E: 9.539165545361891,
        N: 47.46547751465468,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Lutzenberg',
        PLZ: 9426,
        Zusatzziffer: 0,
        Gemeindename: 'Lutzenberg',
        'BFS-Nr': 3033,
        Kantonskürzel: 'AR',
        E: 9.583624632065801,
        N: 47.4595906708567,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Rehetobel',
        PLZ: 9038,
        Zusatzziffer: 0,
        Gemeindename: 'Rehetobel',
        'BFS-Nr': 3034,
        Kantonskürzel: 'AR',
        E: 9.482502270572782,
        N: 47.42623637816181,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Reute AR',
        PLZ: 9411,
        Zusatzziffer: 0,
        Gemeindename: 'Reute (AR)',
        'BFS-Nr': 3035,
        Kantonskürzel: 'AR',
        E: 9.575386987213793,
        N: 47.42008796029071,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Schachen b. Reute',
        PLZ: 9411,
        Zusatzziffer: 1,
        Gemeindename: 'Reute (AR)',
        'BFS-Nr': 3035,
        Kantonskürzel: 'AR',
        E: 9.560247550219588,
        N: 47.42702176516289,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Wald AR',
        PLZ: 9044,
        Zusatzziffer: 0,
        Gemeindename: 'Wald (AR)',
        'BFS-Nr': 3036,
        Kantonskürzel: 'AR',
        E: 9.489073151072818,
        N: 47.41550455560157,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Walzenhausen',
        PLZ: 9428,
        Zusatzziffer: 0,
        Gemeindename: 'Walzenhausen',
        'BFS-Nr': 3037,
        Kantonskürzel: 'AR',
        E: 9.601169092597049,
        N: 47.45152787625997,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Wolfhalden',
        PLZ: 9427,
        Zusatzziffer: 0,
        Gemeindename: 'Wolfhalden',
        'BFS-Nr': 3038,
        Kantonskürzel: 'AR',
        E: 9.550719045799452,
        N: 47.45404240700694,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Appenzell',
        PLZ: 9050,
        Zusatzziffer: 0,
        Gemeindename: 'Appenzell',
        'BFS-Nr': 3101,
        Kantonskürzel: 'AI',
        E: 9.410087891843503,
        N: 47.330898708395964,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Appenzell Meistersrüte',
        PLZ: 9050,
        Zusatzziffer: 5,
        Gemeindename: 'Appenzell',
        'BFS-Nr': 3101,
        Kantonskürzel: 'AI',
        E: 9.429907677846701,
        N: 47.345005679162746,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Gonten',
        PLZ: 9108,
        Zusatzziffer: 0,
        Gemeindename: 'Gonten',
        'BFS-Nr': 3102,
        Kantonskürzel: 'AI',
        E: 9.346644208325323,
        N: 47.328271975034326,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Gontenbad',
        PLZ: 9108,
        Zusatzziffer: 2,
        Gemeindename: 'Gonten',
        'BFS-Nr': 3102,
        Kantonskürzel: 'AI',
        E: 9.372437912670305,
        N: 47.33161066458039,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Jakobsbad',
        PLZ: 9108,
        Zusatzziffer: 3,
        Gemeindename: 'Gonten',
        'BFS-Nr': 3102,
        Kantonskürzel: 'AI',
        E: 9.328245597826138,
        N: 47.32010886417834,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Appenzell Enggenhütten',
        PLZ: 9050,
        Zusatzziffer: 3,
        Gemeindename: 'Schlatt-Haslen',
        'BFS-Nr': 3104,
        Kantonskürzel: 'AI',
        E: 9.362306435490408,
        N: 47.353054584949554,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Appenzell Schlatt',
        PLZ: 9050,
        Zusatzziffer: 7,
        Gemeindename: 'Schlatt-Haslen',
        'BFS-Nr': 3104,
        Kantonskürzel: 'AI',
        E: 9.395959106132194,
        N: 47.35642857375616,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Haslen AI',
        PLZ: 9054,
        Zusatzziffer: 0,
        Gemeindename: 'Schlatt-Haslen',
        'BFS-Nr': 3104,
        Kantonskürzel: 'AI',
        E: 9.366958528861257,
        N: 47.36963734012242,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Oberegg',
        PLZ: 9413,
        Zusatzziffer: 0,
        Gemeindename: 'Oberegg',
        'BFS-Nr': 3111,
        Kantonskürzel: 'AI',
        E: 9.583253573055186,
        N: 47.423443362484676,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Oberegg',
        PLZ: 9413,
        Zusatzziffer: 0,
        Gemeindename: 'Oberegg',
        'BFS-Nr': 3111,
        Kantonskürzel: 'AI',
        E: 9.553477730351718,
        N: 47.421603525823265,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Büriswilen',
        PLZ: 9442,
        Zusatzziffer: 2,
        Gemeindename: 'Oberegg',
        'BFS-Nr': 3111,
        Kantonskürzel: 'AI',
        E: 9.610119440888335,
        N: 47.43428463054459,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Appenzell Eggerstanden',
        PLZ: 9050,
        Zusatzziffer: 2,
        Gemeindename: 'Schwende-Rüte',
        'BFS-Nr': 3112,
        Kantonskürzel: 'AI',
        E: 9.467557191567257,
        N: 47.33090429695778,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Appenzell Steinegg',
        PLZ: 9050,
        Zusatzziffer: 6,
        Gemeindename: 'Schwende-Rüte',
        'BFS-Nr': 3112,
        Kantonskürzel: 'AI',
        E: 9.43053968100929,
        N: 47.32051675390167,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Weissbad',
        PLZ: 9057,
        Zusatzziffer: 0,
        Gemeindename: 'Schwende-Rüte',
        'BFS-Nr': 3112,
        Kantonskürzel: 'AI',
        E: 9.435841876106483,
        N: 47.31055642617949,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Schwende',
        PLZ: 9057,
        Zusatzziffer: 2,
        Gemeindename: 'Schwende-Rüte',
        'BFS-Nr': 3112,
        Kantonskürzel: 'AI',
        E: 9.43420831531056,
        N: 47.3007349336977,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Wasserauen',
        PLZ: 9057,
        Zusatzziffer: 3,
        Gemeindename: 'Schwende-Rüte',
        'BFS-Nr': 3112,
        Kantonskürzel: 'AI',
        E: 9.42833759942511,
        N: 47.28526746618124,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Brülisau',
        PLZ: 9058,
        Zusatzziffer: 0,
        Gemeindename: 'Schwende-Rüte',
        'BFS-Nr': 3112,
        Kantonskürzel: 'AI',
        E: 9.45639863103018,
        N: 47.29737443701988,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Lömmenschwil',
        PLZ: 9308,
        Zusatzziffer: 0,
        Gemeindename: 'Häggenschwil',
        'BFS-Nr': 3201,
        Kantonskürzel: 'SG',
        E: 9.354106581463437,
        N: 47.49658779263809,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Häggenschwil',
        PLZ: 9312,
        Zusatzziffer: 0,
        Gemeindename: 'Häggenschwil',
        'BFS-Nr': 3201,
        Kantonskürzel: 'SG',
        E: 9.343854252514822,
        N: 47.49423437336003,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Muolen',
        PLZ: 9313,
        Zusatzziffer: 0,
        Gemeindename: 'Muolen',
        'BFS-Nr': 3202,
        Kantonskürzel: 'SG',
        E: 9.323787431395068,
        N: 47.52141395771278,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'St. Gallen',
        PLZ: 9000,
        Zusatzziffer: 0,
        Gemeindename: 'St. Gallen',
        'BFS-Nr': 3203,
        Kantonskürzel: 'SG',
        E: 9.362229413507695,
        N: 47.42363487883572,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'St. Gallen',
        PLZ: 9008,
        Zusatzziffer: 0,
        Gemeindename: 'St. Gallen',
        'BFS-Nr': 3203,
        Kantonskürzel: 'SG',
        E: 9.391851107484579,
        N: 47.44181557229332,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'St. Gallen',
        PLZ: 9010,
        Zusatzziffer: 0,
        Gemeindename: 'St. Gallen',
        'BFS-Nr': 3203,
        Kantonskürzel: 'SG',
        E: 9.37171024782361,
        N: 47.44223918002585,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'St. Gallen',
        PLZ: 9011,
        Zusatzziffer: 0,
        Gemeindename: 'St. Gallen',
        'BFS-Nr': 3203,
        Kantonskürzel: 'SG',
        E: 9.399316304187936,
        N: 47.41476307732838,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'St. Gallen',
        PLZ: 9012,
        Zusatzziffer: 0,
        Gemeindename: 'St. Gallen',
        'BFS-Nr': 3203,
        Kantonskürzel: 'SG',
        E: 9.360818719400768,
        N: 47.41028434194868,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'St. Gallen',
        PLZ: 9014,
        Zusatzziffer: 0,
        Gemeindename: 'St. Gallen',
        'BFS-Nr': 3203,
        Kantonskürzel: 'SG',
        E: 9.335477413627848,
        N: 47.40925795467224,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'St. Gallen',
        PLZ: 9015,
        Zusatzziffer: 0,
        Gemeindename: 'St. Gallen',
        'BFS-Nr': 3203,
        Kantonskürzel: 'SG',
        E: 9.310157852718444,
        N: 47.405746188135154,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'St. Gallen',
        PLZ: 9016,
        Zusatzziffer: 0,
        Gemeindename: 'St. Gallen',
        'BFS-Nr': 3203,
        Kantonskürzel: 'SG',
        E: 9.414065451995727,
        N: 47.44150235634556,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Wittenbach',
        PLZ: 9300,
        Zusatzziffer: 0,
        Gemeindename: 'Wittenbach',
        'BFS-Nr': 3204,
        Kantonskürzel: 'SG',
        E: 9.379114458152808,
        N: 47.4633503264606,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Berg SG',
        PLZ: 9305,
        Zusatzziffer: 0,
        Gemeindename: 'Berg (SG)',
        'BFS-Nr': 3211,
        Kantonskürzel: 'SG',
        E: 9.40781390385026,
        N: 47.48656706184253,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Eggersriet',
        PLZ: 9034,
        Zusatzziffer: 0,
        Gemeindename: 'Eggersriet',
        'BFS-Nr': 3212,
        Kantonskürzel: 'SG',
        E: 9.471835195920711,
        N: 47.442356230007604,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Grub SG',
        PLZ: 9036,
        Zusatzziffer: 0,
        Gemeindename: 'Eggersriet',
        'BFS-Nr': 3212,
        Kantonskürzel: 'SG',
        E: 9.512724846746504,
        N: 47.45202638263008,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Goldach',
        PLZ: 9403,
        Zusatzziffer: 0,
        Gemeindename: 'Goldach',
        'BFS-Nr': 3213,
        Kantonskürzel: 'SG',
        E: 9.464790704694314,
        N: 47.473364091201105,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Mörschwil',
        PLZ: 9402,
        Zusatzziffer: 0,
        Gemeindename: 'Mörschwil',
        'BFS-Nr': 3214,
        Kantonskürzel: 'SG',
        E: 9.424458195497028,
        N: 47.46817850069179,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Rorschach',
        PLZ: 9400,
        Zusatzziffer: 0,
        Gemeindename: 'Rorschach',
        'BFS-Nr': 3215,
        Kantonskürzel: 'SG',
        E: 9.49135211937201,
        N: 47.47445967418841,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Rorschacherberg',
        PLZ: 9404,
        Zusatzziffer: 0,
        Gemeindename: 'Rorschacherberg',
        'BFS-Nr': 3216,
        Kantonskürzel: 'SG',
        E: 9.502127444817242,
        N: 47.46628088967783,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Steinach',
        PLZ: 9323,
        Zusatzziffer: 0,
        Gemeindename: 'Steinach',
        'BFS-Nr': 3217,
        Kantonskürzel: 'SG',
        E: 9.44022892377825,
        N: 47.502637098863275,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Tübach',
        PLZ: 9327,
        Zusatzziffer: 0,
        Gemeindename: 'Tübach',
        'BFS-Nr': 3218,
        Kantonskürzel: 'SG',
        E: 9.453790787869417,
        N: 47.48474291858882,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Untereggen',
        PLZ: 9033,
        Zusatzziffer: 0,
        Gemeindename: 'Untereggen',
        'BFS-Nr': 3219,
        Kantonskürzel: 'SG',
        E: 9.452835798238063,
        N: 47.4549240794919,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Au SG',
        PLZ: 9434,
        Zusatzziffer: 0,
        Gemeindename: 'Au (SG)',
        'BFS-Nr': 3231,
        Kantonskürzel: 'SG',
        E: 9.63378250215058,
        N: 47.43341360743322,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Heerbrugg',
        PLZ: 9435,
        Zusatzziffer: 0,
        Gemeindename: 'Au (SG)',
        'BFS-Nr': 3231,
        Kantonskürzel: 'SG',
        E: 9.626882818450293,
        N: 47.412862940170584,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Balgach',
        PLZ: 9436,
        Zusatzziffer: 0,
        Gemeindename: 'Balgach',
        'BFS-Nr': 3232,
        Kantonskürzel: 'SG',
        E: 9.606310605527618,
        N: 47.40737324495131,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Reute AR',
        PLZ: 9411,
        Zusatzziffer: 0,
        Gemeindename: 'Berneck',
        'BFS-Nr': 3233,
        Kantonskürzel: 'SG',
        E: 9.594265094451481,
        N: 47.4202639721608,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Berneck',
        PLZ: 9442,
        Zusatzziffer: 0,
        Gemeindename: 'Berneck',
        'BFS-Nr': 3233,
        Kantonskürzel: 'SG',
        E: 9.609395504895048,
        N: 47.42456435384888,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Diepoldsau',
        PLZ: 9444,
        Zusatzziffer: 0,
        Gemeindename: 'Diepoldsau',
        'BFS-Nr': 3234,
        Kantonskürzel: 'SG',
        E: 9.657629110018572,
        N: 47.38540320222254,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Rheineck',
        PLZ: 9424,
        Zusatzziffer: 0,
        Gemeindename: 'Rheineck',
        'BFS-Nr': 3235,
        Kantonskürzel: 'SG',
        E: 9.590040969029083,
        N: 47.465799582264985,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'St. Margrethen SG',
        PLZ: 9430,
        Zusatzziffer: 0,
        Gemeindename: 'St. Margrethen',
        'BFS-Nr': 3236,
        Kantonskürzel: 'SG',
        E: 9.62590374829524,
        N: 47.453942580449485,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Staad SG',
        PLZ: 9422,
        Zusatzziffer: 0,
        Gemeindename: 'Thal',
        'BFS-Nr': 3237,
        Kantonskürzel: 'SG',
        E: 9.542224631484782,
        N: 47.480582238027694,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Altenrhein',
        PLZ: 9423,
        Zusatzziffer: 0,
        Gemeindename: 'Thal',
        'BFS-Nr': 3237,
        Kantonskürzel: 'SG',
        E: 9.551472310960577,
        N: 47.49089255048704,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Thal',
        PLZ: 9425,
        Zusatzziffer: 0,
        Gemeindename: 'Thal',
        'BFS-Nr': 3237,
        Kantonskürzel: 'SG',
        E: 9.569448639134338,
        N: 47.46503525892179,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Widnau',
        PLZ: 9443,
        Zusatzziffer: 0,
        Gemeindename: 'Widnau',
        'BFS-Nr': 3238,
        Kantonskürzel: 'SG',
        E: 9.639645589034705,
        N: 47.40413508919252,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Altstätten SG',
        PLZ: 9450,
        Zusatzziffer: 0,
        Gemeindename: 'Altstätten',
        'BFS-Nr': 3251,
        Kantonskürzel: 'SG',
        E: 9.541477723712235,
        N: 47.378025422685496,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Lüchingen',
        PLZ: 9450,
        Zusatzziffer: 2,
        Gemeindename: 'Altstätten',
        'BFS-Nr': 3251,
        Kantonskürzel: 'SG',
        E: 9.552987171685185,
        N: 47.38749642344185,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Hinterforst',
        PLZ: 9452,
        Zusatzziffer: 0,
        Gemeindename: 'Altstätten',
        'BFS-Nr': 3251,
        Kantonskürzel: 'SG',
        E: 9.530969104682077,
        N: 47.35926910410465,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Lienz',
        PLZ: 9464,
        Zusatzziffer: 2,
        Gemeindename: 'Altstätten',
        'BFS-Nr': 3251,
        Kantonskürzel: 'SG',
        E: 9.516135881725416,
        N: 47.27845983370227,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Eichberg',
        PLZ: 9453,
        Zusatzziffer: 0,
        Gemeindename: 'Eichberg',
        'BFS-Nr': 3252,
        Kantonskürzel: 'SG',
        E: 9.525326401970228,
        N: 47.34655596295751,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Marbach SG',
        PLZ: 9437,
        Zusatzziffer: 0,
        Gemeindename: 'Marbach (SG)',
        'BFS-Nr': 3253,
        Kantonskürzel: 'SG',
        E: 9.567526869638053,
        N: 47.391728058801654,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Kriessern',
        PLZ: 9451,
        Zusatzziffer: 0,
        Gemeindename: 'Oberriet (SG)',
        'BFS-Nr': 3254,
        Kantonskürzel: 'SG',
        E: 9.606844040400167,
        N: 47.36544143619309,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Montlingen',
        PLZ: 9462,
        Zusatzziffer: 0,
        Gemeindename: 'Oberriet (SG)',
        'BFS-Nr': 3254,
        Kantonskürzel: 'SG',
        E: 9.590510857960554,
        N: 47.33487132297337,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Oberriet SG',
        PLZ: 9463,
        Zusatzziffer: 0,
        Gemeindename: 'Oberriet (SG)',
        'BFS-Nr': 3254,
        Kantonskürzel: 'SG',
        E: 9.570876253532482,
        N: 47.32609154224632,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Rebstein',
        PLZ: 9445,
        Zusatzziffer: 0,
        Gemeindename: 'Rebstein',
        'BFS-Nr': 3255,
        Kantonskürzel: 'SG',
        E: 9.584152908151735,
        N: 47.399498212611576,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Rüthi (Rheintal)',
        PLZ: 9464,
        Zusatzziffer: 0,
        Gemeindename: 'Rüthi (SG)',
        'BFS-Nr': 3256,
        Kantonskürzel: 'SG',
        E: 9.538641949676672,
        N: 47.29463708218253,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Buchs SG',
        PLZ: 9470,
        Zusatzziffer: 0,
        Gemeindename: 'Buchs (SG)',
        'BFS-Nr': 3271,
        Kantonskürzel: 'SG',
        E: 9.467979432334522,
        N: 47.165071975901796,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Gams',
        PLZ: 9473,
        Zusatzziffer: 0,
        Gemeindename: 'Gams',
        'BFS-Nr': 3272,
        Kantonskürzel: 'SG',
        E: 9.440358587068795,
        N: 47.203754469600256,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Werdenberg',
        PLZ: 9470,
        Zusatzziffer: 5,
        Gemeindename: 'Grabs',
        'BFS-Nr': 3273,
        Kantonskürzel: 'SG',
        E: 9.461361641561624,
        N: 47.16858524608932,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Grabs',
        PLZ: 9472,
        Zusatzziffer: 0,
        Gemeindename: 'Grabs',
        'BFS-Nr': 3273,
        Kantonskürzel: 'SG',
        E: 9.447085273278537,
        N: 47.18113361320224,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Grabserberg',
        PLZ: 9472,
        Zusatzziffer: 2,
        Gemeindename: 'Grabs',
        'BFS-Nr': 3273,
        Kantonskürzel: 'SG',
        E: 9.425253709501435,
        N: 47.18156285495435,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Salez',
        PLZ: 9465,
        Zusatzziffer: 0,
        Gemeindename: 'Sennwald',
        'BFS-Nr': 3274,
        Kantonskürzel: 'SG',
        E: 9.502596343811154,
        N: 47.23749079194739,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Sennwald',
        PLZ: 9466,
        Zusatzziffer: 0,
        Gemeindename: 'Sennwald',
        'BFS-Nr': 3274,
        Kantonskürzel: 'SG',
        E: 9.507912509963626,
        N: 47.265617268029075,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Frümsen',
        PLZ: 9467,
        Zusatzziffer: 0,
        Gemeindename: 'Sennwald',
        'BFS-Nr': 3274,
        Kantonskürzel: 'SG',
        E: 9.472545289563447,
        N: 47.244605312995276,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Sax',
        PLZ: 9468,
        Zusatzziffer: 0,
        Gemeindename: 'Sennwald',
        'BFS-Nr': 3274,
        Kantonskürzel: 'SG',
        E: 9.459256559056177,
        N: 47.232078578680586,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Haag (Rheintal)',
        PLZ: 9469,
        Zusatzziffer: 0,
        Gemeindename: 'Sennwald',
        'BFS-Nr': 3274,
        Kantonskürzel: 'SG',
        E: 9.492053649116235,
        N: 47.21216404493497,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Sevelen',
        PLZ: 9475,
        Zusatzziffer: 0,
        Gemeindename: 'Sevelen',
        'BFS-Nr': 3275,
        Kantonskürzel: 'SG',
        E: 9.486082549109204,
        N: 47.12149413175542,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Weite',
        PLZ: 9476,
        Zusatzziffer: 0,
        Gemeindename: 'Wartau',
        'BFS-Nr': 3276,
        Kantonskürzel: 'SG',
        E: 9.496972549563989,
        N: 47.09249493889945,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Fontnas',
        PLZ: 9476,
        Zusatzziffer: 2,
        Gemeindename: 'Wartau',
        'BFS-Nr': 3276,
        Kantonskürzel: 'SG',
        E: 9.489381622622128,
        N: 47.09023191576126,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Trübbach',
        PLZ: 9477,
        Zusatzziffer: 0,
        Gemeindename: 'Wartau',
        'BFS-Nr': 3276,
        Kantonskürzel: 'SG',
        E: 9.47861333001629,
        N: 47.07081665015197,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Azmoos',
        PLZ: 9478,
        Zusatzziffer: 0,
        Gemeindename: 'Wartau',
        'BFS-Nr': 3276,
        Kantonskürzel: 'SG',
        E: 9.475824592196318,
        N: 47.0801387596105,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Oberschan',
        PLZ: 9479,
        Zusatzziffer: 0,
        Gemeindename: 'Wartau',
        'BFS-Nr': 3276,
        Kantonskürzel: 'SG',
        E: 9.474243746481811,
        N: 47.098843235423956,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Malans SG',
        PLZ: 9479,
        Zusatzziffer: 1,
        Gemeindename: 'Wartau',
        'BFS-Nr': 3276,
        Kantonskürzel: 'SG',
        E: 9.477215074833424,
        N: 47.09118442376871,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Gretschins',
        PLZ: 9479,
        Zusatzziffer: 2,
        Gemeindename: 'Wartau',
        'BFS-Nr': 3276,
        Kantonskürzel: 'SG',
        E: 9.486257531414983,
        N: 47.09721604508798,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Bad Ragaz',
        PLZ: 7310,
        Zusatzziffer: 0,
        Gemeindename: 'Bad Ragaz',
        'BFS-Nr': 3291,
        Kantonskürzel: 'SG',
        E: 9.500957651735884,
        N: 47.004015252062466,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Flums',
        PLZ: 8890,
        Zusatzziffer: 0,
        Gemeindename: 'Flums',
        'BFS-Nr': 3292,
        Kantonskürzel: 'SG',
        E: 9.344703494190611,
        N: 47.090813593476284,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Flums Hochwiese',
        PLZ: 8893,
        Zusatzziffer: 0,
        Gemeindename: 'Flums',
        'BFS-Nr': 3292,
        Kantonskürzel: 'SG',
        E: 9.370741055370296,
        N: 47.083813595555085,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Flumserberg Saxli',
        PLZ: 8894,
        Zusatzziffer: 0,
        Gemeindename: 'Flums',
        'BFS-Nr': 3292,
        Kantonskürzel: 'SG',
        E: 9.350034645651657,
        N: 47.07869729611998,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Flumserberg Portels',
        PLZ: 8895,
        Zusatzziffer: 0,
        Gemeindename: 'Flums',
        'BFS-Nr': 3292,
        Kantonskürzel: 'SG',
        E: 9.335450902983094,
        N: 47.08330667227624,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Flumserberg Bergheim',
        PLZ: 8896,
        Zusatzziffer: 0,
        Gemeindename: 'Flums',
        'BFS-Nr': 3292,
        Kantonskürzel: 'SG',
        E: 9.30578681747942,
        N: 47.09765652772271,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Flumserberg Tannenheim',
        PLZ: 8897,
        Zusatzziffer: 0,
        Gemeindename: 'Flums',
        'BFS-Nr': 3292,
        Kantonskürzel: 'SG',
        E: 9.30400440530224,
        N: 47.08924032072956,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Flumserberg Tannenbodenalp',
        PLZ: 8898,
        Zusatzziffer: 0,
        Gemeindename: 'Flums',
        'BFS-Nr': 3292,
        Kantonskürzel: 'SG',
        E: 9.2842641036332,
        N: 47.09365441975392,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Schwendi im Weisstannental',
        PLZ: 7325,
        Zusatzziffer: 0,
        Gemeindename: 'Mels',
        'BFS-Nr': 3293,
        Kantonskürzel: 'SG',
        E: 9.363505106184903,
        N: 47.00877663484439,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Weisstannen',
        PLZ: 7326,
        Zusatzziffer: 0,
        Gemeindename: 'Mels',
        'BFS-Nr': 3293,
        Kantonskürzel: 'SG',
        E: 9.345596312023021,
        N: 46.991819241776916,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Mädris-Vermol',
        PLZ: 8886,
        Zusatzziffer: 0,
        Gemeindename: 'Mels',
        'BFS-Nr': 3293,
        Kantonskürzel: 'SG',
        E: 9.392793364267241,
        N: 47.050999373082455,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Mels',
        PLZ: 8887,
        Zusatzziffer: 0,
        Gemeindename: 'Mels',
        'BFS-Nr': 3293,
        Kantonskürzel: 'SG',
        E: 9.422496253211582,
        N: 47.0465267814761,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Heiligkreuz (Mels)',
        PLZ: 8888,
        Zusatzziffer: 0,
        Gemeindename: 'Mels',
        'BFS-Nr': 3293,
        Kantonskürzel: 'SG',
        E: 9.410408318980675,
        N: 47.058814024970424,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Plons',
        PLZ: 8889,
        Zusatzziffer: 0,
        Gemeindename: 'Mels',
        'BFS-Nr': 3293,
        Kantonskürzel: 'SG',
        E: 9.399960108719752,
        N: 47.056996251376866,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Pfäfers',
        PLZ: 7312,
        Zusatzziffer: 0,
        Gemeindename: 'Pfäfers',
        'BFS-Nr': 3294,
        Kantonskürzel: 'SG',
        E: 9.498407692710174,
        N: 46.988375765269886,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'St. Margrethenberg',
        PLZ: 7313,
        Zusatzziffer: 0,
        Gemeindename: 'Pfäfers',
        'BFS-Nr': 3294,
        Kantonskürzel: 'SG',
        E: 9.510755069147812,
        N: 46.97913872682365,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Vadura',
        PLZ: 7314,
        Zusatzziffer: 0,
        Gemeindename: 'Pfäfers',
        'BFS-Nr': 3294,
        Kantonskürzel: 'SG',
        E: 9.484234818686815,
        N: 46.95807855921542,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Vättis',
        PLZ: 7315,
        Zusatzziffer: 0,
        Gemeindename: 'Pfäfers',
        'BFS-Nr': 3294,
        Kantonskürzel: 'SG',
        E: 9.441316321705175,
        N: 46.91052034480162,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Valens',
        PLZ: 7317,
        Zusatzziffer: 0,
        Gemeindename: 'Pfäfers',
        'BFS-Nr': 3294,
        Kantonskürzel: 'SG',
        E: 9.48068348778003,
        N: 46.96860142406297,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Vasön',
        PLZ: 7317,
        Zusatzziffer: 3,
        Gemeindename: 'Pfäfers',
        'BFS-Nr': 3294,
        Kantonskürzel: 'SG',
        E: 9.4776940503798,
        N: 46.95260505830854,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Murg',
        PLZ: 8877,
        Zusatzziffer: 0,
        Gemeindename: 'Quarten',
        'BFS-Nr': 3295,
        Kantonskürzel: 'SG',
        E: 9.215973923945333,
        N: 47.11287595653485,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Quinten',
        PLZ: 8878,
        Zusatzziffer: 0,
        Gemeindename: 'Quarten',
        'BFS-Nr': 3295,
        Kantonskürzel: 'SG',
        E: 9.215933831739196,
        N: 47.129202209545554,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Unterterzen',
        PLZ: 8882,
        Zusatzziffer: 0,
        Gemeindename: 'Quarten',
        'BFS-Nr': 3295,
        Kantonskürzel: 'SG',
        E: 9.255427324652697,
        N: 47.11408519531988,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Quarten',
        PLZ: 8883,
        Zusatzziffer: 0,
        Gemeindename: 'Quarten',
        'BFS-Nr': 3295,
        Kantonskürzel: 'SG',
        E: 9.241598058891494,
        N: 47.109156804554125,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Oberterzen',
        PLZ: 8884,
        Zusatzziffer: 0,
        Gemeindename: 'Quarten',
        'BFS-Nr': 3295,
        Kantonskürzel: 'SG',
        E: 9.255640579509448,
        N: 47.102808486353965,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Mols',
        PLZ: 8885,
        Zusatzziffer: 0,
        Gemeindename: 'Quarten',
        'BFS-Nr': 3295,
        Kantonskürzel: 'SG',
        E: 9.28125630708726,
        N: 47.11225234932806,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Sargans',
        PLZ: 7320,
        Zusatzziffer: 0,
        Gemeindename: 'Sargans',
        'BFS-Nr': 3296,
        Kantonskürzel: 'SG',
        E: 9.435127315272306,
        N: 47.05033684903835,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Wangs',
        PLZ: 7323,
        Zusatzziffer: 0,
        Gemeindename: 'Vilters-Wangs',
        'BFS-Nr': 3297,
        Kantonskürzel: 'SG',
        E: 9.432149289329391,
        N: 47.0307215039746,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Wangs',
        PLZ: 7323,
        Zusatzziffer: 0,
        Gemeindename: 'Vilters-Wangs',
        'BFS-Nr': 3297,
        Kantonskürzel: 'SG',
        E: 9.474858587205917,
        N: 47.031202502621596,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Vilters',
        PLZ: 7324,
        Zusatzziffer: 0,
        Gemeindename: 'Vilters-Wangs',
        'BFS-Nr': 3297,
        Kantonskürzel: 'SG',
        E: 9.44827409916633,
        N: 47.023119231965985,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Walenstadt',
        PLZ: 8880,
        Zusatzziffer: 0,
        Gemeindename: 'Walenstadt',
        'BFS-Nr': 3298,
        Kantonskürzel: 'SG',
        E: 9.314830882227334,
        N: 47.12412841314137,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Walenstadtberg',
        PLZ: 8881,
        Zusatzziffer: 1,
        Gemeindename: 'Walenstadt',
        'BFS-Nr': 3298,
        Kantonskürzel: 'SG',
        E: 9.286964545480417,
        N: 47.13614123341476,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Tscherlach',
        PLZ: 8881,
        Zusatzziffer: 6,
        Gemeindename: 'Walenstadt',
        'BFS-Nr': 3298,
        Kantonskürzel: 'SG',
        E: 9.333497724372686,
        N: 47.118467462637696,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Berschis',
        PLZ: 8892,
        Zusatzziffer: 0,
        Gemeindename: 'Walenstadt',
        'BFS-Nr': 3298,
        Kantonskürzel: 'SG',
        E: 9.34693722063211,
        N: 47.105182486665676,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Amden',
        PLZ: 8873,
        Zusatzziffer: 0,
        Gemeindename: 'Amden',
        'BFS-Nr': 3311,
        Kantonskürzel: 'SG',
        E: 9.145783626817531,
        N: 47.14960034385512,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Benken SG',
        PLZ: 8717,
        Zusatzziffer: 0,
        Gemeindename: 'Benken (SG)',
        'BFS-Nr': 3312,
        Kantonskürzel: 'SG',
        E: 9.005676303863984,
        N: 47.19913406582387,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Kaltbrunn',
        PLZ: 8722,
        Zusatzziffer: 0,
        Gemeindename: 'Kaltbrunn',
        'BFS-Nr': 3313,
        Kantonskürzel: 'SG',
        E: 9.024535761433789,
        N: 47.21394543896846,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Schänis',
        PLZ: 8718,
        Zusatzziffer: 0,
        Gemeindename: 'Schänis',
        'BFS-Nr': 3315,
        Kantonskürzel: 'SG',
        E: 9.045409062824561,
        N: 47.15991738989821,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Rufi',
        PLZ: 8723,
        Zusatzziffer: 0,
        Gemeindename: 'Schänis',
        'BFS-Nr': 3315,
        Kantonskürzel: 'SG',
        E: 9.049483768096882,
        N: 47.17999416069781,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Maseltrangen',
        PLZ: 8723,
        Zusatzziffer: 2,
        Gemeindename: 'Schänis',
        'BFS-Nr': 3315,
        Kantonskürzel: 'SG',
        E: 9.04950989856159,
        N: 47.19183731094396,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Ziegelbrücke',
        PLZ: 8866,
        Zusatzziffer: 0,
        Gemeindename: 'Schänis',
        'BFS-Nr': 3315,
        Kantonskürzel: 'SG',
        E: 9.060264567125285,
        N: 47.13627762696593,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Weesen',
        PLZ: 8872,
        Zusatzziffer: 0,
        Gemeindename: 'Weesen',
        'BFS-Nr': 3316,
        Kantonskürzel: 'SG',
        E: 9.096226017876305,
        N: 47.13531434093685,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Schmerikon',
        PLZ: 8716,
        Zusatzziffer: 0,
        Gemeindename: 'Schmerikon',
        'BFS-Nr': 3338,
        Kantonskürzel: 'SG',
        E: 8.940920249005021,
        N: 47.22674006676042,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Uznach',
        PLZ: 8730,
        Zusatzziffer: 0,
        Gemeindename: 'Uznach',
        'BFS-Nr': 3339,
        Kantonskürzel: 'SG',
        E: 8.986325139626183,
        N: 47.22548265178091,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Rapperswil SG',
        PLZ: 8640,
        Zusatzziffer: 0,
        Gemeindename: 'Rapperswil-Jona',
        'BFS-Nr': 3340,
        Kantonskürzel: 'SG',
        E: 8.816600144106133,
        N: 47.22759614585802,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Jona',
        PLZ: 8645,
        Zusatzziffer: 0,
        Gemeindename: 'Rapperswil-Jona',
        'BFS-Nr': 3340,
        Kantonskürzel: 'SG',
        E: 8.836495621742094,
        N: 47.22909099662506,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Wagen',
        PLZ: 8646,
        Zusatzziffer: 0,
        Gemeindename: 'Rapperswil-Jona',
        'BFS-Nr': 3340,
        Kantonskürzel: 'SG',
        E: 8.886038559102513,
        N: 47.230944937861054,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Bollingen',
        PLZ: 8715,
        Zusatzziffer: 0,
        Gemeindename: 'Rapperswil-Jona',
        'BFS-Nr': 3340,
        Kantonskürzel: 'SG',
        E: 8.894310653849052,
        N: 47.219389711969335,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Ernetschwil',
        PLZ: 8725,
        Zusatzziffer: 0,
        Gemeindename: 'Gommiswald',
        'BFS-Nr': 3341,
        Kantonskürzel: 'SG',
        E: 9.002498032267107,
        N: 47.23723552449093,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Gebertingen',
        PLZ: 8725,
        Zusatzziffer: 2,
        Gemeindename: 'Gommiswald',
        'BFS-Nr': 3341,
        Kantonskürzel: 'SG',
        E: 9.0066174614038,
        N: 47.25207995415145,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Gommiswald',
        PLZ: 8737,
        Zusatzziffer: 0,
        Gemeindename: 'Gommiswald',
        'BFS-Nr': 3341,
        Kantonskürzel: 'SG',
        E: 9.022665599924144,
        N: 47.23118113793701,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Uetliburg SG',
        PLZ: 8738,
        Zusatzziffer: 0,
        Gemeindename: 'Gommiswald',
        'BFS-Nr': 3341,
        Kantonskürzel: 'SG',
        E: 9.033352853986585,
        N: 47.23711872617732,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Rieden SG',
        PLZ: 8739,
        Zusatzziffer: 0,
        Gemeindename: 'Gommiswald',
        'BFS-Nr': 3341,
        Kantonskürzel: 'SG',
        E: 9.050555417077609,
        N: 47.2198502301249,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Goldingen',
        PLZ: 8638,
        Zusatzziffer: 0,
        Gemeindename: 'Eschenbach (SG)',
        'BFS-Nr': 3342,
        Kantonskürzel: 'SG',
        E: 8.96693434622185,
        N: 47.26278961317893,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Walde SG',
        PLZ: 8727,
        Zusatzziffer: 0,
        Gemeindename: 'Eschenbach (SG)',
        'BFS-Nr': 3342,
        Kantonskürzel: 'SG',
        E: 9.010632686977985,
        N: 47.27028085739901,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Neuhaus SG',
        PLZ: 8732,
        Zusatzziffer: 0,
        Gemeindename: 'Eschenbach (SG)',
        'BFS-Nr': 3342,
        Kantonskürzel: 'SG',
        E: 8.94718398532512,
        N: 47.24234994921861,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Eschenbach SG',
        PLZ: 8733,
        Zusatzziffer: 0,
        Gemeindename: 'Eschenbach (SG)',
        'BFS-Nr': 3342,
        Kantonskürzel: 'SG',
        E: 8.919891195443542,
        N: 47.23958841536021,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Ermenswil',
        PLZ: 8734,
        Zusatzziffer: 0,
        Gemeindename: 'Eschenbach (SG)',
        'BFS-Nr': 3342,
        Kantonskürzel: 'SG',
        E: 8.884103472240565,
        N: 47.244940975148104,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'St. Gallenkappel',
        PLZ: 8735,
        Zusatzziffer: 0,
        Gemeindename: 'Eschenbach (SG)',
        'BFS-Nr': 3342,
        Kantonskürzel: 'SG',
        E: 8.968900002471091,
        N: 47.24540097005503,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Rüeterswil',
        PLZ: 8735,
        Zusatzziffer: 2,
        Gemeindename: 'Eschenbach (SG)',
        'BFS-Nr': 3342,
        Kantonskürzel: 'SG',
        E: 8.989405794842654,
        N: 47.259066001011476,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Ebnat-Kappel',
        PLZ: 9642,
        Zusatzziffer: 0,
        Gemeindename: 'Ebnat-Kappel',
        'BFS-Nr': 3352,
        Kantonskürzel: 'SG',
        E: 9.120548660735105,
        N: 47.26406307347905,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Alt St. Johann',
        PLZ: 9656,
        Zusatzziffer: 0,
        Gemeindename: 'Wildhaus-Alt St. Johann',
        'BFS-Nr': 3359,
        Kantonskürzel: 'SG',
        E: 9.286033086802506,
        N: 47.19425396818094,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Unterwasser',
        PLZ: 9657,
        Zusatzziffer: 0,
        Gemeindename: 'Wildhaus-Alt St. Johann',
        'BFS-Nr': 3359,
        Kantonskürzel: 'SG',
        E: 9.308641958031066,
        N: 47.19708772084905,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Wildhaus',
        PLZ: 9658,
        Zusatzziffer: 0,
        Gemeindename: 'Wildhaus-Alt St. Johann',
        'BFS-Nr': 3359,
        Kantonskürzel: 'SG',
        E: 9.350739549648765,
        N: 47.20374611753531,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Krummenau',
        PLZ: 9643,
        Zusatzziffer: 0,
        Gemeindename: 'Nesslau',
        'BFS-Nr': 3360,
        Kantonskürzel: 'SG',
        E: 9.173297361495317,
        N: 47.2468482026995,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Nesslau',
        PLZ: 9650,
        Zusatzziffer: 0,
        Gemeindename: 'Nesslau',
        'BFS-Nr': 3360,
        Kantonskürzel: 'SG',
        E: 9.200146539184109,
        N: 47.22391577178812,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Ennetbühl',
        PLZ: 9651,
        Zusatzziffer: 0,
        Gemeindename: 'Nesslau',
        'BFS-Nr': 3360,
        Kantonskürzel: 'SG',
        E: 9.211850106582164,
        N: 47.2408464273095,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Neu St. Johann',
        PLZ: 9652,
        Zusatzziffer: 0,
        Gemeindename: 'Nesslau',
        'BFS-Nr': 3360,
        Kantonskürzel: 'SG',
        E: 9.193843308383173,
        N: 47.22910446420894,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Stein SG',
        PLZ: 9655,
        Zusatzziffer: 0,
        Gemeindename: 'Nesslau',
        'BFS-Nr': 3360,
        Kantonskürzel: 'SG',
        E: 9.228142488909663,
        N: 47.199421981011405,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Hemberg',
        PLZ: 9633,
        Zusatzziffer: 0,
        Gemeindename: 'Hemberg',
        'BFS-Nr': 3372,
        Kantonskürzel: 'SG',
        E: 9.175915932909833,
        N: 47.30065343757946,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Bächli (Hemberg)',
        PLZ: 9633,
        Zusatzziffer: 2,
        Gemeindename: 'Hemberg',
        'BFS-Nr': 3372,
        Kantonskürzel: 'SG',
        E: 9.194967053240138,
        N: 47.305452435759825,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Lichtensteig',
        PLZ: 9620,
        Zusatzziffer: 0,
        Gemeindename: 'Lichtensteig',
        'BFS-Nr': 3374,
        Kantonskürzel: 'SG',
        E: 9.088876964204237,
        N: 47.3225089041132,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Oberhelfenschwil',
        PLZ: 9621,
        Zusatzziffer: 0,
        Gemeindename: 'Oberhelfenschwil',
        'BFS-Nr': 3375,
        Kantonskürzel: 'SG',
        E: 9.112060867402054,
        N: 47.356185735872764,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Hoffeld',
        PLZ: 9114,
        Zusatzziffer: 0,
        Gemeindename: 'Neckertal',
        'BFS-Nr': 3378,
        Kantonskürzel: 'SG',
        E: 9.173392673591145,
        N: 47.36215348940711,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Dicken',
        PLZ: 9115,
        Zusatzziffer: 0,
        Gemeindename: 'Neckertal',
        'BFS-Nr': 3378,
        Kantonskürzel: 'SG',
        E: 9.190465242766294,
        N: 47.33708158862483,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Mogelsberg',
        PLZ: 9122,
        Zusatzziffer: 0,
        Gemeindename: 'Neckertal',
        'BFS-Nr': 3378,
        Kantonskürzel: 'SG',
        E: 9.135862354339304,
        N: 47.362173367535476,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Ebersol',
        PLZ: 9122,
        Zusatzziffer: 2,
        Gemeindename: 'Neckertal',
        'BFS-Nr': 3378,
        Kantonskürzel: 'SG',
        E: 9.151289544715397,
        N: 47.35020121963181,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Nassen',
        PLZ: 9123,
        Zusatzziffer: 0,
        Gemeindename: 'Neckertal',
        'BFS-Nr': 3378,
        Kantonskürzel: 'SG',
        E: 9.136051570088442,
        N: 47.372964577548736,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Brunnadern',
        PLZ: 9125,
        Zusatzziffer: 0,
        Gemeindename: 'Neckertal',
        'BFS-Nr': 3378,
        Kantonskürzel: 'SG',
        E: 9.131532806232146,
        N: 47.33455314780833,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Necker',
        PLZ: 9126,
        Zusatzziffer: 0,
        Gemeindename: 'Neckertal',
        'BFS-Nr': 3378,
        Kantonskürzel: 'SG',
        E: 9.130206532594245,
        N: 47.34807495352761,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'St. Peterzell',
        PLZ: 9127,
        Zusatzziffer: 0,
        Gemeindename: 'Neckertal',
        'BFS-Nr': 3378,
        Kantonskürzel: 'SG',
        E: 9.173886707822373,
        N: 47.31803339493388,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Ricken SG',
        PLZ: 8726,
        Zusatzziffer: 0,
        Gemeindename: 'Wattwil',
        'BFS-Nr': 3379,
        Kantonskürzel: 'SG',
        E: 9.046359396716669,
        N: 47.26521158252928,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Krinau',
        PLZ: 9622,
        Zusatzziffer: 0,
        Gemeindename: 'Wattwil',
        'BFS-Nr': 3379,
        Kantonskürzel: 'SG',
        E: 9.052415581311664,
        N: 47.31727055296855,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Wattwil',
        PLZ: 9630,
        Zusatzziffer: 0,
        Gemeindename: 'Wattwil',
        'BFS-Nr': 3379,
        Kantonskürzel: 'SG',
        E: 9.088564584785214,
        N: 47.30430398042045,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Ulisbach',
        PLZ: 9631,
        Zusatzziffer: 0,
        Gemeindename: 'Wattwil',
        'BFS-Nr': 3379,
        Kantonskürzel: 'SG',
        E: 9.100927225673445,
        N: 47.28624463170603,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Wil SG',
        PLZ: 9500,
        Zusatzziffer: 0,
        Gemeindename: 'Kirchberg (SG)',
        'BFS-Nr': 3392,
        Kantonskürzel: 'SG',
        E: 9.053634210277187,
        N: 47.4410441992157,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Kirchberg SG',
        PLZ: 9533,
        Zusatzziffer: 0,
        Gemeindename: 'Kirchberg (SG)',
        'BFS-Nr': 3392,
        Kantonskürzel: 'SG',
        E: 9.0406316712174,
        N: 47.41180526516729,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Dietschwil',
        PLZ: 9533,
        Zusatzziffer: 1,
        Gemeindename: 'Kirchberg (SG)',
        'BFS-Nr': 3392,
        Kantonskürzel: 'SG',
        E: 9.017332688042098,
        N: 47.42074537038621,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Gähwil',
        PLZ: 9534,
        Zusatzziffer: 0,
        Gemeindename: 'Kirchberg (SG)',
        'BFS-Nr': 3392,
        Kantonskürzel: 'SG',
        E: 9.001969312982373,
        N: 47.39829172513736,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Bazenheid',
        PLZ: 9602,
        Zusatzziffer: 0,
        Gemeindename: 'Kirchberg (SG)',
        'BFS-Nr': 3392,
        Kantonskürzel: 'SG',
        E: 9.0686237760746,
        N: 47.40977888357997,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Müselbach',
        PLZ: 9602,
        Zusatzziffer: 2,
        Gemeindename: 'Kirchberg (SG)',
        'BFS-Nr': 3392,
        Kantonskürzel: 'SG',
        E: 9.041452390554657,
        N: 47.39057144152439,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Lütisburg Station',
        PLZ: 9601,
        Zusatzziffer: 0,
        Gemeindename: 'Lütisburg',
        'BFS-Nr': 3393,
        Kantonskürzel: 'SG',
        E: 9.071133161451208,
        N: 47.384887232950256,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Lütisburg',
        PLZ: 9604,
        Zusatzziffer: 0,
        Gemeindename: 'Lütisburg',
        'BFS-Nr': 3393,
        Kantonskürzel: 'SG',
        E: 9.07947822105808,
        N: 47.395040941820604,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Unterrindal',
        PLZ: 9604,
        Zusatzziffer: 2,
        Gemeindename: 'Lütisburg',
        'BFS-Nr': 3393,
        Kantonskürzel: 'SG',
        E: 9.090115045065783,
        N: 47.40907963446797,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Mosnang',
        PLZ: 9607,
        Zusatzziffer: 0,
        Gemeindename: 'Mosnang',
        'BFS-Nr': 3394,
        Kantonskürzel: 'SG',
        E: 9.039778413439818,
        N: 47.36246727143122,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Dreien',
        PLZ: 9612,
        Zusatzziffer: 0,
        Gemeindename: 'Mosnang',
        'BFS-Nr': 3394,
        Kantonskürzel: 'SG',
        E: 9.016855967626192,
        N: 47.371843072316786,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Mühlrüti',
        PLZ: 9613,
        Zusatzziffer: 0,
        Gemeindename: 'Mosnang',
        'BFS-Nr': 3394,
        Kantonskürzel: 'SG',
        E: 8.985872632175566,
        N: 47.370639460557385,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Libingen',
        PLZ: 9614,
        Zusatzziffer: 0,
        Gemeindename: 'Mosnang',
        'BFS-Nr': 3394,
        Kantonskürzel: 'SG',
        E: 9.022302910473233,
        N: 47.32889191791521,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Bütschwil',
        PLZ: 9606,
        Zusatzziffer: 0,
        Gemeindename: 'Bütschwil-Ganterschwil',
        'BFS-Nr': 3395,
        Kantonskürzel: 'SG',
        E: 9.068487107933281,
        N: 47.358978860144894,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Ganterschwil',
        PLZ: 9608,
        Zusatzziffer: 0,
        Gemeindename: 'Bütschwil-Ganterschwil',
        'BFS-Nr': 3395,
        Kantonskürzel: 'SG',
        E: 9.090218266949195,
        N: 47.38111084732734,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Dietfurt',
        PLZ: 9615,
        Zusatzziffer: 0,
        Gemeindename: 'Bütschwil-Ganterschwil',
        'BFS-Nr': 3395,
        Kantonskürzel: 'SG',
        E: 9.078896195734174,
        N: 47.346902439440754,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Degersheim',
        PLZ: 9113,
        Zusatzziffer: 0,
        Gemeindename: 'Degersheim',
        'BFS-Nr': 3401,
        Kantonskürzel: 'SG',
        E: 9.196920394554397,
        N: 47.37338763783697,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Wolfertswil',
        PLZ: 9116,
        Zusatzziffer: 0,
        Gemeindename: 'Degersheim',
        'BFS-Nr': 3401,
        Kantonskürzel: 'SG',
        E: 9.18359480823237,
        N: 47.39453893222774,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Flawil',
        PLZ: 9230,
        Zusatzziffer: 0,
        Gemeindename: 'Flawil',
        'BFS-Nr': 3402,
        Kantonskürzel: 'SG',
        E: 9.183742059354644,
        N: 47.41696411118511,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Egg (Flawil)',
        PLZ: 9231,
        Zusatzziffer: 0,
        Gemeindename: 'Flawil',
        'BFS-Nr': 3402,
        Kantonskürzel: 'SG',
        E: 9.226390970206584,
        N: 47.391665906469015,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Jonschwil',
        PLZ: 9243,
        Zusatzziffer: 0,
        Gemeindename: 'Jonschwil',
        'BFS-Nr': 3405,
        Kantonskürzel: 'SG',
        E: 9.088287180551246,
        N: 47.42409919843579,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Rickenbach b. Wil',
        PLZ: 9532,
        Zusatzziffer: 0,
        Gemeindename: 'Jonschwil',
        'BFS-Nr': 3405,
        Kantonskürzel: 'SG',
        E: 9.063074571766128,
        N: 47.43456219539324,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Schwarzenbach SG',
        PLZ: 9536,
        Zusatzziffer: 0,
        Gemeindename: 'Jonschwil',
        'BFS-Nr': 3405,
        Kantonskürzel: 'SG',
        E: 9.076346982974085,
        N: 47.44110147987402,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Oberrindal',
        PLZ: 9604,
        Zusatzziffer: 1,
        Gemeindename: 'Jonschwil',
        'BFS-Nr': 3405,
        Kantonskürzel: 'SG',
        E: 9.11898062059084,
        N: 47.40785392526217,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Niederglatt SG',
        PLZ: 9240,
        Zusatzziffer: 2,
        Gemeindename: 'Oberuzwil',
        'BFS-Nr': 3407,
        Kantonskürzel: 'SG',
        E: 9.17159177100938,
        N: 47.43101656436875,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Oberuzwil',
        PLZ: 9242,
        Zusatzziffer: 0,
        Gemeindename: 'Oberuzwil',
        'BFS-Nr': 3407,
        Kantonskürzel: 'SG',
        E: 9.120602500421226,
        N: 47.4311744737072,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Bichwil',
        PLZ: 9248,
        Zusatzziffer: 0,
        Gemeindename: 'Oberuzwil',
        'BFS-Nr': 3407,
        Kantonskürzel: 'SG',
        E: 9.136890343496367,
        N: 47.41892660748802,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Uzwil',
        PLZ: 9240,
        Zusatzziffer: 0,
        Gemeindename: 'Uzwil',
        'BFS-Nr': 3408,
        Kantonskürzel: 'SG',
        E: 9.131231117241331,
        N: 47.43667159120411,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Niederuzwil',
        PLZ: 9244,
        Zusatzziffer: 0,
        Gemeindename: 'Uzwil',
        'BFS-Nr': 3408,
        Kantonskürzel: 'SG',
        E: 9.142263421341841,
        N: 47.444638065022176,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Henau',
        PLZ: 9247,
        Zusatzziffer: 0,
        Gemeindename: 'Uzwil',
        'BFS-Nr': 3408,
        Kantonskürzel: 'SG',
        E: 9.119477840803661,
        N: 47.45210338721118,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Algetshausen',
        PLZ: 9249,
        Zusatzziffer: 0,
        Gemeindename: 'Uzwil',
        'BFS-Nr': 3408,
        Kantonskürzel: 'SG',
        E: 9.107078477167647,
        N: 47.447768865352735,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Niederstetten',
        PLZ: 9249,
        Zusatzziffer: 1,
        Gemeindename: 'Uzwil',
        'BFS-Nr': 3408,
        Kantonskürzel: 'SG',
        E: 9.083305639112403,
        N: 47.45129351125515,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Oberstetten',
        PLZ: 9249,
        Zusatzziffer: 2,
        Gemeindename: 'Uzwil',
        'BFS-Nr': 3408,
        Kantonskürzel: 'SG',
        E: 9.089333536442775,
        N: 47.44854154381028,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Niederbüren',
        PLZ: 9246,
        Zusatzziffer: 0,
        Gemeindename: 'Niederbüren',
        'BFS-Nr': 3422,
        Kantonskürzel: 'SG',
        E: 9.207785559371032,
        N: 47.46509056114881,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Lenggenwil',
        PLZ: 9525,
        Zusatzziffer: 0,
        Gemeindename: 'Niederhelfenschwil',
        'BFS-Nr': 3423,
        Kantonskürzel: 'SG',
        E: 9.149527180686372,
        N: 47.47523027589469,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Zuckenriet',
        PLZ: 9526,
        Zusatzziffer: 0,
        Gemeindename: 'Niederhelfenschwil',
        'BFS-Nr': 3423,
        Kantonskürzel: 'SG',
        E: 9.162652940107456,
        N: 47.48433282102506,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Niederhelfenschwil',
        PLZ: 9527,
        Zusatzziffer: 0,
        Gemeindename: 'Niederhelfenschwil',
        'BFS-Nr': 3423,
        Kantonskürzel: 'SG',
        E: 9.187100802176463,
        N: 47.476162572946706,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Niederwil SG',
        PLZ: 9203,
        Zusatzziffer: 0,
        Gemeindename: 'Oberbüren',
        'BFS-Nr': 3424,
        Kantonskürzel: 'SG',
        E: 9.200862784710695,
        N: 47.439321867454126,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Oberbüren',
        PLZ: 9245,
        Zusatzziffer: 0,
        Gemeindename: 'Oberbüren',
        'BFS-Nr': 3424,
        Kantonskürzel: 'SG',
        E: 9.161507991407152,
        N: 47.451160020019216,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Sonnental',
        PLZ: 9245,
        Zusatzziffer: 2,
        Gemeindename: 'Oberbüren',
        'BFS-Nr': 3424,
        Kantonskürzel: 'SG',
        E: 9.13654183237476,
        N: 47.46017674400054,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Züberwangen',
        PLZ: 9523,
        Zusatzziffer: 0,
        Gemeindename: 'Zuzwil (SG)',
        'BFS-Nr': 3426,
        Kantonskürzel: 'SG',
        E: 9.083843065947391,
        N: 47.465981338649684,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Zuzwil SG',
        PLZ: 9524,
        Zusatzziffer: 0,
        Gemeindename: 'Zuzwil (SG)',
        'BFS-Nr': 3426,
        Kantonskürzel: 'SG',
        E: 9.110172169402526,
        N: 47.47568440505718,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Wil SG',
        PLZ: 9500,
        Zusatzziffer: 0,
        Gemeindename: 'Wil (SG)',
        'BFS-Nr': 3427,
        Kantonskürzel: 'SG',
        E: 9.04942974528324,
        N: 47.46645523769582,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Rossrüti',
        PLZ: 9512,
        Zusatzziffer: 0,
        Gemeindename: 'Wil (SG)',
        'BFS-Nr': 3427,
        Kantonskürzel: 'SG',
        E: 9.060639422584643,
        N: 47.47413466123779,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Bronschhofen',
        PLZ: 9552,
        Zusatzziffer: 0,
        Gemeindename: 'Wil (SG)',
        'BFS-Nr': 3427,
        Kantonskürzel: 'SG',
        E: 9.034271896954323,
        N: 47.475844015746205,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Andwil SG',
        PLZ: 9204,
        Zusatzziffer: 0,
        Gemeindename: 'Andwil (SG)',
        'BFS-Nr': 3441,
        Kantonskürzel: 'SG',
        E: 9.269875970581152,
        N: 47.43939373176166,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Abtwil SG',
        PLZ: 9030,
        Zusatzziffer: 0,
        Gemeindename: 'Gaiserwald',
        'BFS-Nr': 3442,
        Kantonskürzel: 'SG',
        E: 9.324262540562959,
        N: 47.42171301952085,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'St. Josefen',
        PLZ: 9030,
        Zusatzziffer: 3,
        Gemeindename: 'Gaiserwald',
        'BFS-Nr': 3442,
        Kantonskürzel: 'SG',
        E: 9.336119995651869,
        N: 47.42468752347112,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Engelburg',
        PLZ: 9032,
        Zusatzziffer: 0,
        Gemeindename: 'Gaiserwald',
        'BFS-Nr': 3442,
        Kantonskürzel: 'SG',
        E: 9.341228663314789,
        N: 47.44373074955352,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Gossau SG',
        PLZ: 9200,
        Zusatzziffer: 0,
        Gemeindename: 'Gossau (SG)',
        'BFS-Nr': 3443,
        Kantonskürzel: 'SG',
        E: 9.249618545835101,
        N: 47.415378292541504,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Arnegg',
        PLZ: 9212,
        Zusatzziffer: 0,
        Gemeindename: 'Gossau (SG)',
        'BFS-Nr': 3443,
        Kantonskürzel: 'SG',
        E: 9.252039547908739,
        N: 47.441998110402935,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Waldkirch',
        PLZ: 9205,
        Zusatzziffer: 0,
        Gemeindename: 'Waldkirch',
        'BFS-Nr': 3444,
        Kantonskürzel: 'SG',
        E: 9.284957237907584,
        N: 47.46953654137728,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Bernhardzell',
        PLZ: 9304,
        Zusatzziffer: 0,
        Gemeindename: 'Waldkirch',
        'BFS-Nr': 3444,
        Kantonskürzel: 'SG',
        E: 9.33655672266365,
        N: 47.47377973656936,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Valbella',
        PLZ: 7077,
        Zusatzziffer: 0,
        Gemeindename: 'Vaz/Obervaz',
        'BFS-Nr': 3506,
        Kantonskürzel: 'GR',
        E: 9.553707018755059,
        N: 46.747506951738444,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Lenzerheide/Lai',
        PLZ: 7078,
        Zusatzziffer: 0,
        Gemeindename: 'Vaz/Obervaz',
        'BFS-Nr': 3506,
        Kantonskürzel: 'GR',
        E: 9.555737447769935,
        N: 46.72970320801972,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Vaz/Obervaz',
        PLZ: 7082,
        Zusatzziffer: 0,
        Gemeindename: 'Vaz/Obervaz',
        'BFS-Nr': 3506,
        Kantonskürzel: 'GR',
        E: 9.531557687074768,
        N: 46.69593103123983,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Tiefencastel',
        PLZ: 7450,
        Zusatzziffer: 0,
        Gemeindename: 'Vaz/Obervaz',
        'BFS-Nr': 3506,
        Kantonskürzel: 'GR',
        E: 9.514527712664473,
        N: 46.68196724167768,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Lantsch/Lenz',
        PLZ: 7083,
        Zusatzziffer: 0,
        Gemeindename: 'Lantsch/Lenz',
        'BFS-Nr': 3513,
        Kantonskürzel: 'GR',
        E: 9.562409142635774,
        N: 46.683096167304825,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Schmitten (Albula)',
        PLZ: 7493,
        Zusatzziffer: 0,
        Gemeindename: 'Schmitten (GR)',
        'BFS-Nr': 3514,
        Kantonskürzel: 'GR',
        E: 9.677294710008942,
        N: 46.68669192819091,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Brienz/Brinzauls GR',
        PLZ: 7084,
        Zusatzziffer: 0,
        Gemeindename: 'Albula/Alvra',
        'BFS-Nr': 3542,
        Kantonskürzel: 'GR',
        E: 9.594880941543737,
        N: 46.66859802255963,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Tiefencastel',
        PLZ: 7450,
        Zusatzziffer: 0,
        Gemeindename: 'Albula/Alvra',
        'BFS-Nr': 3542,
        Kantonskürzel: 'GR',
        E: 9.575008762806405,
        N: 46.66160678087307,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Alvaschein',
        PLZ: 7451,
        Zusatzziffer: 0,
        Gemeindename: 'Albula/Alvra',
        'BFS-Nr': 3542,
        Kantonskürzel: 'GR',
        E: 9.552333520488977,
        N: 46.674481958891604,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Mon',
        PLZ: 7458,
        Zusatzziffer: 0,
        Gemeindename: 'Albula/Alvra',
        'BFS-Nr': 3542,
        Kantonskürzel: 'GR',
        E: 9.565010584980127,
        N: 46.650405893329996,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Stierva',
        PLZ: 7459,
        Zusatzziffer: 0,
        Gemeindename: 'Albula/Alvra',
        'BFS-Nr': 3542,
        Kantonskürzel: 'GR',
        E: 9.541322641030865,
        N: 46.66413806809061,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Surava',
        PLZ: 7472,
        Zusatzziffer: 0,
        Gemeindename: 'Albula/Alvra',
        'BFS-Nr': 3542,
        Kantonskürzel: 'GR',
        E: 9.611667108983672,
        N: 46.66534518092677,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Alvaneu Bad',
        PLZ: 7473,
        Zusatzziffer: 0,
        Gemeindename: 'Albula/Alvra',
        'BFS-Nr': 3542,
        Kantonskürzel: 'GR',
        E: 9.646987555462392,
        N: 46.66885766440414,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Alvaneu Dorf',
        PLZ: 7492,
        Zusatzziffer: 0,
        Gemeindename: 'Albula/Alvra',
        'BFS-Nr': 3542,
        Kantonskürzel: 'GR',
        E: 9.64552582769122,
        N: 46.67954608684407,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Cunter',
        PLZ: 7452,
        Zusatzziffer: 0,
        Gemeindename: 'Surses',
        'BFS-Nr': 3543,
        Kantonskürzel: 'GR',
        E: 9.59573046065128,
        N: 46.609008446594906,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Tinizong',
        PLZ: 7453,
        Zusatzziffer: 0,
        Gemeindename: 'Surses',
        'BFS-Nr': 3543,
        Kantonskürzel: 'GR',
        E: 9.619449745255158,
        N: 46.58360292378715,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Rona',
        PLZ: 7454,
        Zusatzziffer: 0,
        Gemeindename: 'Surses',
        'BFS-Nr': 3543,
        Kantonskürzel: 'GR',
        E: 9.62241081691167,
        N: 46.561270869977044,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Mulegns',
        PLZ: 7455,
        Zusatzziffer: 0,
        Gemeindename: 'Surses',
        'BFS-Nr': 3543,
        Kantonskürzel: 'GR',
        E: 9.621413878355698,
        N: 46.525088511894175,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Sur',
        PLZ: 7456,
        Zusatzziffer: 0,
        Gemeindename: 'Surses',
        'BFS-Nr': 3543,
        Kantonskürzel: 'GR',
        E: 9.631551355253624,
        N: 46.52346224245458,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Marmorera',
        PLZ: 7456,
        Zusatzziffer: 2,
        Gemeindename: 'Surses',
        'BFS-Nr': 3543,
        Kantonskürzel: 'GR',
        E: 9.643223981625136,
        N: 46.497199054619394,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Bivio',
        PLZ: 7457,
        Zusatzziffer: 0,
        Gemeindename: 'Surses',
        'BFS-Nr': 3543,
        Kantonskürzel: 'GR',
        E: 9.650554268190215,
        N: 46.46808536541147,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Savognin',
        PLZ: 7460,
        Zusatzziffer: 0,
        Gemeindename: 'Surses',
        'BFS-Nr': 3543,
        Kantonskürzel: 'GR',
        E: 9.602658433221059,
        N: 46.59563418923419,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Salouf',
        PLZ: 7462,
        Zusatzziffer: 0,
        Gemeindename: 'Surses',
        'BFS-Nr': 3543,
        Kantonskürzel: 'GR',
        E: 9.5757199767463,
        N: 46.62457261827363,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Riom',
        PLZ: 7463,
        Zusatzziffer: 0,
        Gemeindename: 'Surses',
        'BFS-Nr': 3543,
        Kantonskürzel: 'GR',
        E: 9.593051371912368,
        N: 46.63328872856852,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Riom',
        PLZ: 7463,
        Zusatzziffer: 0,
        Gemeindename: 'Surses',
        'BFS-Nr': 3543,
        Kantonskürzel: 'GR',
        E: 9.582812742012408,
        N: 46.60766580112366,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Parsonz',
        PLZ: 7464,
        Zusatzziffer: 0,
        Gemeindename: 'Surses',
        'BFS-Nr': 3543,
        Kantonskürzel: 'GR',
        E: 9.569627604543783,
        N: 46.6087390248603,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Filisur',
        PLZ: 7477,
        Zusatzziffer: 0,
        Gemeindename: 'Bergün Filisur',
        'BFS-Nr': 3544,
        Kantonskürzel: 'GR',
        E: 9.688678462100361,
        N: 46.6743120397338,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Bergün/Bravuogn',
        PLZ: 7482,
        Zusatzziffer: 0,
        Gemeindename: 'Bergün Filisur',
        'BFS-Nr': 3544,
        Kantonskürzel: 'GR',
        E: 9.744909534804085,
        N: 46.6291892820205,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Preda',
        PLZ: 7482,
        Zusatzziffer: 1,
        Gemeindename: 'Bergün Filisur',
        'BFS-Nr': 3544,
        Kantonskürzel: 'GR',
        E: 9.77715918868583,
        N: 46.590408097064795,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Stugl/Stuls',
        PLZ: 7482,
        Zusatzziffer: 2,
        Gemeindename: 'Bergün Filisur',
        'BFS-Nr': 3544,
        Kantonskürzel: 'GR',
        E: 9.732897093169521,
        N: 46.650000668337746,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Latsch',
        PLZ: 7484,
        Zusatzziffer: 0,
        Gemeindename: 'Bergün Filisur',
        'BFS-Nr': 3544,
        Kantonskürzel: 'GR',
        E: 9.7534126755521,
        N: 46.63520708111022,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Brusio',
        PLZ: 7743,
        Zusatzziffer: 0,
        Gemeindename: 'Brusio',
        'BFS-Nr': 3551,
        Kantonskürzel: 'GR',
        E: 10.124330833989942,
        N: 46.258537393842374,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Campocologno',
        PLZ: 7744,
        Zusatzziffer: 0,
        Gemeindename: 'Brusio',
        'BFS-Nr': 3551,
        Kantonskürzel: 'GR',
        E: 10.141885651361605,
        N: 46.23331574212598,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Viano',
        PLZ: 7747,
        Zusatzziffer: 0,
        Gemeindename: 'Brusio',
        'BFS-Nr': 3551,
        Kantonskürzel: 'GR',
        E: 10.139831692623684,
        N: 46.25372230256232,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Campascio',
        PLZ: 7748,
        Zusatzziffer: 0,
        Gemeindename: 'Brusio',
        'BFS-Nr': 3551,
        Kantonskürzel: 'GR',
        E: 10.129823222173918,
        N: 46.2452286402286,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Ospizio Bernina',
        PLZ: 7710,
        Zusatzziffer: 0,
        Gemeindename: 'Poschiavo',
        'BFS-Nr': 3561,
        Kantonskürzel: 'GR',
        E: 10.02197938123735,
        N: 46.41065826474109,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Alp Grüm',
        PLZ: 7710,
        Zusatzziffer: 2,
        Gemeindename: 'Poschiavo',
        'BFS-Nr': 3561,
        Kantonskürzel: 'GR',
        E: 10.032858303908373,
        N: 46.3747659418509,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'S. Carlo (Poschiavo)',
        PLZ: 7741,
        Zusatzziffer: 0,
        Gemeindename: 'Poschiavo',
        'BFS-Nr': 3561,
        Kantonskürzel: 'GR',
        E: 10.064275220414983,
        N: 46.34377072311453,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Poschiavo',
        PLZ: 7742,
        Zusatzziffer: 0,
        Gemeindename: 'Poschiavo',
        'BFS-Nr': 3561,
        Kantonskürzel: 'GR',
        E: 10.06200467083416,
        N: 46.32553464880987,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Sfazù',
        PLZ: 7742,
        Zusatzziffer: 2,
        Gemeindename: 'Poschiavo',
        'BFS-Nr': 3561,
        Kantonskürzel: 'GR',
        E: 10.081061848880568,
        N: 46.389023450377834,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'La Rösa',
        PLZ: 7742,
        Zusatzziffer: 3,
        Gemeindename: 'Poschiavo',
        'BFS-Nr': 3561,
        Kantonskürzel: 'GR',
        E: 10.068832474351428,
        N: 46.401299764265055,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Miralago',
        PLZ: 7743,
        Zusatzziffer: 2,
        Gemeindename: 'Poschiavo',
        'BFS-Nr': 3561,
        Kantonskürzel: 'GR',
        E: 10.100715279687996,
        N: 46.27307397999865,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Li Curt',
        PLZ: 7745,
        Zusatzziffer: 0,
        Gemeindename: 'Poschiavo',
        'BFS-Nr': 3561,
        Kantonskürzel: 'GR',
        E: 10.06268008424028,
        N: 46.31069948897207,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Le Prese',
        PLZ: 7746,
        Zusatzziffer: 0,
        Gemeindename: 'Poschiavo',
        'BFS-Nr': 3561,
        Kantonskürzel: 'GR',
        E: 10.080528910948223,
        N: 46.293171407848696,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Falera',
        PLZ: 7153,
        Zusatzziffer: 0,
        Gemeindename: 'Falera',
        'BFS-Nr': 3572,
        Kantonskürzel: 'GR',
        E: 9.231613345206192,
        N: 46.8002675214873,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Laax GR',
        PLZ: 7031,
        Zusatzziffer: 0,
        Gemeindename: 'Laax',
        'BFS-Nr': 3575,
        Kantonskürzel: 'GR',
        E: 9.25357588265258,
        N: 46.80411734809405,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Laax GR 2',
        PLZ: 7032,
        Zusatzziffer: 0,
        Gemeindename: 'Laax',
        'BFS-Nr': 3575,
        Kantonskürzel: 'GR',
        E: 9.264102759673245,
        N: 46.82198668770516,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Sagogn',
        PLZ: 7152,
        Zusatzziffer: 0,
        Gemeindename: 'Sagogn',
        'BFS-Nr': 3581,
        Kantonskürzel: 'GR',
        E: 9.254014947917275,
        N: 46.789019747806236,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Schluein',
        PLZ: 7151,
        Zusatzziffer: 0,
        Gemeindename: 'Schluein',
        'BFS-Nr': 3582,
        Kantonskürzel: 'GR',
        E: 9.228317309161797,
        N: 46.78881933551103,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'St. Martin (Lugnez)',
        PLZ: 7116,
        Zusatzziffer: 2,
        Gemeindename: 'Vals',
        'BFS-Nr': 3603,
        Kantonskürzel: 'GR',
        E: 9.183597414636708,
        N: 46.67368677268177,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Vals',
        PLZ: 7132,
        Zusatzziffer: 0,
        Gemeindename: 'Vals',
        'BFS-Nr': 3603,
        Kantonskürzel: 'GR',
        E: 9.178048274603608,
        N: 46.61854808664683,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Peiden',
        PLZ: 7110,
        Zusatzziffer: 0,
        Gemeindename: 'Lumnezia',
        'BFS-Nr': 3618,
        Kantonskürzel: 'GR',
        E: 9.196033745715319,
        N: 46.72002315959633,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Camuns',
        PLZ: 7113,
        Zusatzziffer: 0,
        Gemeindename: 'Lumnezia',
        'BFS-Nr': 3618,
        Kantonskürzel: 'GR',
        E: 9.197353840271301,
        N: 46.703589759682096,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Uors (Lumnezia)',
        PLZ: 7114,
        Zusatzziffer: 0,
        Gemeindename: 'Lumnezia',
        'BFS-Nr': 3618,
        Kantonskürzel: 'GR',
        E: 9.183134736358461,
        N: 46.70126409163514,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Surcasti',
        PLZ: 7115,
        Zusatzziffer: 0,
        Gemeindename: 'Lumnezia',
        'BFS-Nr': 3618,
        Kantonskürzel: 'GR',
        E: 9.176842941827417,
        N: 46.69678620743835,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Tersnaus',
        PLZ: 7116,
        Zusatzziffer: 0,
        Gemeindename: 'Lumnezia',
        'BFS-Nr': 3618,
        Kantonskürzel: 'GR',
        E: 9.184756218688682,
        N: 46.6931789472179,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Cumbel',
        PLZ: 7142,
        Zusatzziffer: 0,
        Gemeindename: 'Lumnezia',
        'BFS-Nr': 3618,
        Kantonskürzel: 'GR',
        E: 9.19194185384111,
        N: 46.72603806435813,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Morissen',
        PLZ: 7143,
        Zusatzziffer: 0,
        Gemeindename: 'Lumnezia',
        'BFS-Nr': 3618,
        Kantonskürzel: 'GR',
        E: 9.180781021030617,
        N: 46.729854592769854,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Vella',
        PLZ: 7144,
        Zusatzziffer: 0,
        Gemeindename: 'Lumnezia',
        'BFS-Nr': 3618,
        Kantonskürzel: 'GR',
        E: 9.173590945620962,
        N: 46.717905489029874,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Degen',
        PLZ: 7145,
        Zusatzziffer: 0,
        Gemeindename: 'Lumnezia',
        'BFS-Nr': 3618,
        Kantonskürzel: 'GR',
        E: 9.168002394928191,
        N: 46.70589057580174,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Vattiz',
        PLZ: 7146,
        Zusatzziffer: 0,
        Gemeindename: 'Lumnezia',
        'BFS-Nr': 3618,
        Kantonskürzel: 'GR',
        E: 9.160191375979089,
        N: 46.70667897614131,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Vignogn',
        PLZ: 7147,
        Zusatzziffer: 0,
        Gemeindename: 'Lumnezia',
        'BFS-Nr': 3618,
        Kantonskürzel: 'GR',
        E: 9.156459760886934,
        N: 46.699283720038586,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Lumbrein',
        PLZ: 7148,
        Zusatzziffer: 0,
        Gemeindename: 'Lumnezia',
        'BFS-Nr': 3618,
        Kantonskürzel: 'GR',
        E: 9.13594968702177,
        N: 46.68348803840778,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Vrin',
        PLZ: 7149,
        Zusatzziffer: 0,
        Gemeindename: 'Lumnezia',
        'BFS-Nr': 3618,
        Kantonskürzel: 'GR',
        E: 9.099298944915247,
        N: 46.65515254718433,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Pitasch',
        PLZ: 7111,
        Zusatzziffer: 0,
        Gemeindename: 'Ilanz/Glion',
        'BFS-Nr': 3619,
        Kantonskürzel: 'GR',
        E: 9.216911689936154,
        N: 46.7308442897976,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Duvin',
        PLZ: 7112,
        Zusatzziffer: 0,
        Gemeindename: 'Ilanz/Glion',
        'BFS-Nr': 3619,
        Kantonskürzel: 'GR',
        E: 9.21126493903897,
        N: 46.71519152768517,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Castrisch',
        PLZ: 7126,
        Zusatzziffer: 0,
        Gemeindename: 'Ilanz/Glion',
        'BFS-Nr': 3619,
        Kantonskürzel: 'GR',
        E: 9.23148779578021,
        N: 46.77707076675698,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Sevgein',
        PLZ: 7127,
        Zusatzziffer: 0,
        Gemeindename: 'Ilanz/Glion',
        'BFS-Nr': 3619,
        Kantonskürzel: 'GR',
        E: 9.22131724333545,
        N: 46.765341152979694,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Riein',
        PLZ: 7128,
        Zusatzziffer: 0,
        Gemeindename: 'Ilanz/Glion',
        'BFS-Nr': 3619,
        Kantonskürzel: 'GR',
        E: 9.233119983102055,
        N: 46.744313288206804,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Ilanz',
        PLZ: 7130,
        Zusatzziffer: 0,
        Gemeindename: 'Ilanz/Glion',
        'BFS-Nr': 3619,
        Kantonskürzel: 'GR',
        E: 9.203161143030206,
        N: 46.773235409881565,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Schnaus',
        PLZ: 7130,
        Zusatzziffer: 2,
        Gemeindename: 'Ilanz/Glion',
        'BFS-Nr': 3619,
        Kantonskürzel: 'GR',
        E: 9.180610022934761,
        N: 46.777104932508486,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Schnaus',
        PLZ: 7130,
        Zusatzziffer: 2,
        Gemeindename: 'Ilanz/Glion',
        'BFS-Nr': 3619,
        Kantonskürzel: 'GR',
        E: 9.181220608770943,
        N: 46.822519422729314,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Luven',
        PLZ: 7141,
        Zusatzziffer: 0,
        Gemeindename: 'Ilanz/Glion',
        'BFS-Nr': 3619,
        Kantonskürzel: 'GR',
        E: 9.198860784667833,
        N: 46.762396174505504,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Ruschein',
        PLZ: 7154,
        Zusatzziffer: 0,
        Gemeindename: 'Ilanz/Glion',
        'BFS-Nr': 3619,
        Kantonskürzel: 'GR',
        E: 9.191836554805679,
        N: 46.78511192670052,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Ladir',
        PLZ: 7155,
        Zusatzziffer: 0,
        Gemeindename: 'Ilanz/Glion',
        'BFS-Nr': 3619,
        Kantonskürzel: 'GR',
        E: 9.174898003352048,
        N: 46.839043500939134,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Ladir',
        PLZ: 7155,
        Zusatzziffer: 0,
        Gemeindename: 'Ilanz/Glion',
        'BFS-Nr': 3619,
        Kantonskürzel: 'GR',
        E: 9.204601933663657,
        N: 46.78958789716067,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Rueun',
        PLZ: 7156,
        Zusatzziffer: 0,
        Gemeindename: 'Ilanz/Glion',
        'BFS-Nr': 3619,
        Kantonskürzel: 'GR',
        E: 9.152023806572439,
        N: 46.77849261281895,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Pigniu',
        PLZ: 7156,
        Zusatzziffer: 1,
        Gemeindename: 'Ilanz/Glion',
        'BFS-Nr': 3619,
        Kantonskürzel: 'GR',
        E: 9.116800325705924,
        N: 46.80885838254552,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Siat',
        PLZ: 7157,
        Zusatzziffer: 0,
        Gemeindename: 'Ilanz/Glion',
        'BFS-Nr': 3619,
        Kantonskürzel: 'GR',
        E: 9.163487540687873,
        N: 46.790890993506714,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Fürstenaubruck',
        PLZ: 7413,
        Zusatzziffer: 0,
        Gemeindename: 'Fürstenau',
        'BFS-Nr': 3633,
        Kantonskürzel: 'GR',
        E: 9.45032422606826,
        N: 46.71382691508536,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Fürstenau',
        PLZ: 7414,
        Zusatzziffer: 0,
        Gemeindename: 'Fürstenau',
        'BFS-Nr': 3633,
        Kantonskürzel: 'GR',
        E: 9.448719078499161,
        N: 46.7204374766683,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Rothenbrunnen',
        PLZ: 7405,
        Zusatzziffer: 0,
        Gemeindename: 'Rothenbrunnen',
        'BFS-Nr': 3637,
        Kantonskürzel: 'GR',
        E: 9.426804062885388,
        N: 46.76956455683319,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Scharans',
        PLZ: 7412,
        Zusatzziffer: 0,
        Gemeindename: 'Scharans',
        'BFS-Nr': 3638,
        Kantonskürzel: 'GR',
        E: 9.459437768979091,
        N: 46.71800416140005,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Sils im Domleschg',
        PLZ: 7411,
        Zusatzziffer: 0,
        Gemeindename: 'Sils im Domleschg',
        'BFS-Nr': 3640,
        Kantonskürzel: 'GR',
        E: 9.454479947657468,
        N: 46.699844090915704,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Cazis',
        PLZ: 7408,
        Zusatzziffer: 0,
        Gemeindename: 'Cazis',
        'BFS-Nr': 3661,
        Kantonskürzel: 'GR',
        E: 9.42971168640213,
        N: 46.721168813182636,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Summaprada',
        PLZ: 7421,
        Zusatzziffer: 0,
        Gemeindename: 'Cazis',
        'BFS-Nr': 3661,
        Kantonskürzel: 'GR',
        E: 9.433612500069495,
        N: 46.71411563482164,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Tartar',
        PLZ: 7422,
        Zusatzziffer: 0,
        Gemeindename: 'Cazis',
        'BFS-Nr': 3661,
        Kantonskürzel: 'GR',
        E: 9.418206226498064,
        N: 46.71873197948496,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Sarn',
        PLZ: 7423,
        Zusatzziffer: 0,
        Gemeindename: 'Cazis',
        'BFS-Nr': 3661,
        Kantonskürzel: 'GR',
        E: 9.409879054444795,
        N: 46.719668643293716,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Portein',
        PLZ: 7423,
        Zusatzziffer: 2,
        Gemeindename: 'Cazis',
        'BFS-Nr': 3661,
        Kantonskürzel: 'GR',
        E: 9.407058126243813,
        N: 46.71202931027444,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Präz',
        PLZ: 7424,
        Zusatzziffer: 0,
        Gemeindename: 'Cazis',
        'BFS-Nr': 3661,
        Kantonskürzel: 'GR',
        E: 9.405986108398954,
        N: 46.74177074656354,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Dalin',
        PLZ: 7424,
        Zusatzziffer: 1,
        Gemeindename: 'Cazis',
        'BFS-Nr': 3661,
        Kantonskürzel: 'GR',
        E: 9.404341625129716,
        N: 46.73510959888954,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Flerden',
        PLZ: 7426,
        Zusatzziffer: 0,
        Gemeindename: 'Flerden',
        'BFS-Nr': 3662,
        Kantonskürzel: 'GR',
        E: 9.405185307246395,
        N: 46.703709273055,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Flerden',
        PLZ: 7426,
        Zusatzziffer: 0,
        Gemeindename: 'Flerden',
        'BFS-Nr': 3662,
        Kantonskürzel: 'GR',
        E: 9.39513221545324,
        N: 46.67475041339227,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Masein',
        PLZ: 7425,
        Zusatzziffer: 0,
        Gemeindename: 'Masein',
        'BFS-Nr': 3663,
        Kantonskürzel: 'GR',
        E: 9.426832680212518,
        N: 46.7049878221745,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Thusis',
        PLZ: 7430,
        Zusatzziffer: 0,
        Gemeindename: 'Thusis',
        'BFS-Nr': 3668,
        Kantonskürzel: 'GR',
        E: 9.438788481441522,
        N: 46.694382768791726,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Mutten',
        PLZ: 7431,
        Zusatzziffer: 1,
        Gemeindename: 'Thusis',
        'BFS-Nr': 3668,
        Kantonskürzel: 'GR',
        E: 9.499904431670451,
        N: 46.67932487391321,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Obermutten',
        PLZ: 7431,
        Zusatzziffer: 2,
        Gemeindename: 'Thusis',
        'BFS-Nr': 3668,
        Kantonskürzel: 'GR',
        E: 9.483515959627212,
        N: 46.67195098408374,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Tschappina',
        PLZ: 7428,
        Zusatzziffer: 0,
        Gemeindename: 'Tschappina',
        'BFS-Nr': 3669,
        Kantonskürzel: 'GR',
        E: 9.372219455723682,
        N: 46.686707935273716,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Urmein',
        PLZ: 7427,
        Zusatzziffer: 0,
        Gemeindename: 'Urmein',
        'BFS-Nr': 3670,
        Kantonskürzel: 'GR',
        E: 9.40073792234169,
        N: 46.6917808398405,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Versam',
        PLZ: 7104,
        Zusatzziffer: 0,
        Gemeindename: 'Safiental',
        'BFS-Nr': 3672,
        Kantonskürzel: 'GR',
        E: 9.33635578959582,
        N: 46.794249015767804,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Tenna',
        PLZ: 7106,
        Zusatzziffer: 0,
        Gemeindename: 'Safiental',
        'BFS-Nr': 3672,
        Kantonskürzel: 'GR',
        E: 9.336181356553046,
        N: 46.74747549161327,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Safien Platz',
        PLZ: 7107,
        Zusatzziffer: 0,
        Gemeindename: 'Safiental',
        'BFS-Nr': 3672,
        Kantonskürzel: 'GR',
        E: 9.315839847165892,
        N: 46.682268451026374,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Thalkirch',
        PLZ: 7109,
        Zusatzziffer: 0,
        Gemeindename: 'Safiental',
        'BFS-Nr': 3672,
        Kantonskürzel: 'GR',
        E: 9.280281746928864,
        N: 46.63784975524308,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Valendas',
        PLZ: 7122,
        Zusatzziffer: 0,
        Gemeindename: 'Safiental',
        'BFS-Nr': 3672,
        Kantonskürzel: 'GR',
        E: 9.281998756133644,
        N: 46.788480387294086,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Feldis/Veulden',
        PLZ: 7404,
        Zusatzziffer: 0,
        Gemeindename: 'Domleschg',
        'BFS-Nr': 3673,
        Kantonskürzel: 'GR',
        E: 9.432638128886282,
        N: 46.79260530203758,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Trans',
        PLZ: 7407,
        Zusatzziffer: 0,
        Gemeindename: 'Domleschg',
        'BFS-Nr': 3673,
        Kantonskürzel: 'GR',
        E: 9.463615792717368,
        N: 46.76393397738292,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Rodels',
        PLZ: 7415,
        Zusatzziffer: 0,
        Gemeindename: 'Domleschg',
        'BFS-Nr': 3673,
        Kantonskürzel: 'GR',
        E: 9.44509603568948,
        N: 46.73543954096583,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Pratval',
        PLZ: 7415,
        Zusatzziffer: 2,
        Gemeindename: 'Domleschg',
        'BFS-Nr': 3673,
        Kantonskürzel: 'GR',
        E: 9.44579716883088,
        N: 46.73181414786733,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Almens',
        PLZ: 7416,
        Zusatzziffer: 0,
        Gemeindename: 'Domleschg',
        'BFS-Nr': 3673,
        Kantonskürzel: 'GR',
        E: 9.459165363428243,
        N: 46.73806634129287,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Paspels',
        PLZ: 7417,
        Zusatzziffer: 0,
        Gemeindename: 'Domleschg',
        'BFS-Nr': 3673,
        Kantonskürzel: 'GR',
        E: 9.444559705472962,
        N: 46.7504182247581,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Tumegl/Tomils',
        PLZ: 7418,
        Zusatzziffer: 0,
        Gemeindename: 'Domleschg',
        'BFS-Nr': 3673,
        Kantonskürzel: 'GR',
        E: 9.439943635187403,
        N: 46.76129937138572,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Scheid',
        PLZ: 7419,
        Zusatzziffer: 0,
        Gemeindename: 'Domleschg',
        'BFS-Nr': 3673,
        Kantonskürzel: 'GR',
        E: 9.451045362269065,
        N: 46.778047357325235,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Avers',
        PLZ: 7447,
        Zusatzziffer: 0,
        Gemeindename: 'Avers',
        'BFS-Nr': 3681,
        Kantonskürzel: 'GR',
        E: 9.513941989977209,
        N: 46.47315706739906,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Juf',
        PLZ: 7448,
        Zusatzziffer: 0,
        Gemeindename: 'Avers',
        'BFS-Nr': 3681,
        Kantonskürzel: 'GR',
        E: 9.579869470350445,
        N: 46.445309708900346,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Sufers',
        PLZ: 7434,
        Zusatzziffer: 0,
        Gemeindename: 'Sufers',
        'BFS-Nr': 3695,
        Kantonskürzel: 'GR',
        E: 9.36700623745846,
        N: 46.572199027543554,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Andeer',
        PLZ: 7440,
        Zusatzziffer: 0,
        Gemeindename: 'Andeer',
        'BFS-Nr': 3701,
        Kantonskürzel: 'GR',
        E: 9.424795023354223,
        N: 46.60167104695359,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Clugin',
        PLZ: 7442,
        Zusatzziffer: 0,
        Gemeindename: 'Andeer',
        'BFS-Nr': 3701,
        Kantonskürzel: 'GR',
        E: 9.425309117520102,
        N: 46.616299021937266,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Pignia',
        PLZ: 7443,
        Zusatzziffer: 0,
        Gemeindename: 'Andeer',
        'BFS-Nr': 3701,
        Kantonskürzel: 'GR',
        E: 9.441222632124278,
        N: 46.61356583207667,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Rongellen',
        PLZ: 7430,
        Zusatzziffer: 2,
        Gemeindename: 'Rongellen',
        'BFS-Nr': 3711,
        Kantonskürzel: 'GR',
        E: 9.443320936379815,
        N: 46.67352815011563,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Zillis',
        PLZ: 7432,
        Zusatzziffer: 0,
        Gemeindename: 'Zillis-Reischen',
        'BFS-Nr': 3712,
        Kantonskürzel: 'GR',
        E: 9.443287378063715,
        N: 46.63563952706989,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Ausserferrera',
        PLZ: 7444,
        Zusatzziffer: 0,
        Gemeindename: 'Ferrera',
        'BFS-Nr': 3713,
        Kantonskürzel: 'GR',
        E: 9.441637511307356,
        N: 46.55732613621869,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Innerferrera',
        PLZ: 7445,
        Zusatzziffer: 0,
        Gemeindename: 'Ferrera',
        'BFS-Nr': 3713,
        Kantonskürzel: 'GR',
        E: 9.455533011841203,
        N: 46.48240920395572,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Innerferrera',
        PLZ: 7445,
        Zusatzziffer: 0,
        Gemeindename: 'Ferrera',
        'BFS-Nr': 3713,
        Kantonskürzel: 'GR',
        E: 9.436739869476233,
        N: 46.522219291060324,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Splügen',
        PLZ: 7435,
        Zusatzziffer: 0,
        Gemeindename: 'Rheinwald',
        'BFS-Nr': 3714,
        Kantonskürzel: 'GR',
        E: 9.323938706226192,
        N: 46.55408112313424,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Medels im Rheinwald',
        PLZ: 7436,
        Zusatzziffer: 0,
        Gemeindename: 'Rheinwald',
        'BFS-Nr': 3714,
        Kantonskürzel: 'GR',
        E: 9.293683911412076,
        N: 46.54630613994429,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Nufenen',
        PLZ: 7437,
        Zusatzziffer: 0,
        Gemeindename: 'Rheinwald',
        'BFS-Nr': 3714,
        Kantonskürzel: 'GR',
        E: 9.245440686616812,
        N: 46.540755297048314,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Hinterrhein',
        PLZ: 7438,
        Zusatzziffer: 0,
        Gemeindename: 'Rheinwald',
        'BFS-Nr': 3714,
        Kantonskürzel: 'GR',
        E: 9.19703977841701,
        N: 46.532048348614325,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Donat',
        PLZ: 7433,
        Zusatzziffer: 0,
        Gemeindename: 'Muntogna da Schons',
        'BFS-Nr': 3715,
        Kantonskürzel: 'GR',
        E: 9.430350502746078,
        N: 46.62882051112475,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Lohn GR',
        PLZ: 7433,
        Zusatzziffer: 3,
        Gemeindename: 'Muntogna da Schons',
        'BFS-Nr': 3715,
        Kantonskürzel: 'GR',
        E: 9.426859685858597,
        N: 46.650836148259295,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Mathon',
        PLZ: 7433,
        Zusatzziffer: 4,
        Gemeindename: 'Muntogna da Schons',
        'BFS-Nr': 3715,
        Kantonskürzel: 'GR',
        E: 9.415630548260921,
        N: 46.636196306716116,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Wergenstein',
        PLZ: 7433,
        Zusatzziffer: 5,
        Gemeindename: 'Muntogna da Schons',
        'BFS-Nr': 3715,
        Kantonskürzel: 'GR',
        E: 9.404705436227031,
        N: 46.62565542010616,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Bonaduz',
        PLZ: 7402,
        Zusatzziffer: 0,
        Gemeindename: 'Bonaduz',
        'BFS-Nr': 3721,
        Kantonskürzel: 'GR',
        E: 9.402439991747565,
        N: 46.811837035494186,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Domat/Ems',
        PLZ: 7013,
        Zusatzziffer: 0,
        Gemeindename: 'Domat/Ems',
        'BFS-Nr': 3722,
        Kantonskürzel: 'GR',
        E: 9.451354465751747,
        N: 46.83613594942912,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Rhäzüns',
        PLZ: 7403,
        Zusatzziffer: 0,
        Gemeindename: 'Rhäzüns',
        'BFS-Nr': 3723,
        Kantonskürzel: 'GR',
        E: 9.397000297840027,
        N: 46.79870007054345,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Felsberg',
        PLZ: 7012,
        Zusatzziffer: 0,
        Gemeindename: 'Felsberg',
        'BFS-Nr': 3731,
        Kantonskürzel: 'GR',
        E: 9.47058268816321,
        N: 46.84542794832556,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Flims Dorf',
        PLZ: 7017,
        Zusatzziffer: 0,
        Gemeindename: 'Flims',
        'BFS-Nr': 3732,
        Kantonskürzel: 'GR',
        E: 9.284319621688,
        N: 46.8376508664783,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Flims Waldhaus',
        PLZ: 7018,
        Zusatzziffer: 0,
        Gemeindename: 'Flims',
        'BFS-Nr': 3732,
        Kantonskürzel: 'GR',
        E: 9.29146177070685,
        N: 46.8291994483295,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Fidaz',
        PLZ: 7019,
        Zusatzziffer: 0,
        Gemeindename: 'Flims',
        'BFS-Nr': 3732,
        Kantonskürzel: 'GR',
        E: 9.309341660300904,
        N: 46.840786533957626,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Tamins',
        PLZ: 7015,
        Zusatzziffer: 0,
        Gemeindename: 'Tamins',
        'BFS-Nr': 3733,
        Kantonskürzel: 'GR',
        E: 9.407984258140296,
        N: 46.82921898930762,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Trin',
        PLZ: 7014,
        Zusatzziffer: 0,
        Gemeindename: 'Trin',
        'BFS-Nr': 3734,
        Kantonskürzel: 'GR',
        E: 9.363619957288709,
        N: 46.83043434320046,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Trin Mulin',
        PLZ: 7016,
        Zusatzziffer: 0,
        Gemeindename: 'Trin',
        'BFS-Nr': 3734,
        Kantonskürzel: 'GR',
        E: 9.33635454166175,
        N: 46.83123258677348,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Brail',
        PLZ: 7527,
        Zusatzziffer: 0,
        Gemeindename: 'Zernez',
        'BFS-Nr': 3746,
        Kantonskürzel: 'GR',
        E: 10.036522903505652,
        N: 46.65638155974896,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Zernez',
        PLZ: 7530,
        Zusatzziffer: 0,
        Gemeindename: 'Zernez',
        'BFS-Nr': 3746,
        Kantonskürzel: 'GR',
        E: 10.095238060122242,
        N: 46.69927390993397,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Susch',
        PLZ: 7542,
        Zusatzziffer: 0,
        Gemeindename: 'Zernez',
        'BFS-Nr': 3746,
        Kantonskürzel: 'GR',
        E: 10.077047461867009,
        N: 46.749113822883416,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Lavin',
        PLZ: 7543,
        Zusatzziffer: 0,
        Gemeindename: 'Zernez',
        'BFS-Nr': 3746,
        Kantonskürzel: 'GR',
        E: 10.113239893470533,
        N: 46.77015633552379,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Samnaun-Compatsch',
        PLZ: 7562,
        Zusatzziffer: 0,
        Gemeindename: 'Samnaun',
        'BFS-Nr': 3752,
        Kantonskürzel: 'GR',
        E: 10.400228335646238,
        N: 46.961253682414664,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Samnaun Dorf',
        PLZ: 7563,
        Zusatzziffer: 0,
        Gemeindename: 'Samnaun',
        'BFS-Nr': 3752,
        Kantonskürzel: 'GR',
        E: 10.36144939317227,
        N: 46.941168314004,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Guarda',
        PLZ: 7545,
        Zusatzziffer: 0,
        Gemeindename: 'Scuol',
        'BFS-Nr': 3762,
        Kantonskürzel: 'GR',
        E: 10.152113848288003,
        N: 46.77512176740142,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Ardez',
        PLZ: 7546,
        Zusatzziffer: 0,
        Gemeindename: 'Scuol',
        'BFS-Nr': 3762,
        Kantonskürzel: 'GR',
        E: 10.202042231745917,
        N: 46.77545273488475,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Scuol',
        PLZ: 7550,
        Zusatzziffer: 0,
        Gemeindename: 'Scuol',
        'BFS-Nr': 3762,
        Kantonskürzel: 'GR',
        E: 10.304523341963685,
        N: 46.79390116104304,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Ftan',
        PLZ: 7551,
        Zusatzziffer: 0,
        Gemeindename: 'Scuol',
        'BFS-Nr': 3762,
        Kantonskürzel: 'GR',
        E: 10.246137404124859,
        N: 46.79611065446124,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Vulpera',
        PLZ: 7552,
        Zusatzziffer: 0,
        Gemeindename: 'Scuol',
        'BFS-Nr': 3762,
        Kantonskürzel: 'GR',
        E: 10.286204795898135,
        N: 46.787243287595075,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Tarasp',
        PLZ: 7553,
        Zusatzziffer: 0,
        Gemeindename: 'Scuol',
        'BFS-Nr': 3762,
        Kantonskürzel: 'GR',
        E: 10.264709828718013,
        N: 46.77574360543999,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Sent',
        PLZ: 7554,
        Zusatzziffer: 0,
        Gemeindename: 'Scuol',
        'BFS-Nr': 3762,
        Kantonskürzel: 'GR',
        E: 10.336803012386275,
        N: 46.81584857319978,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Ramosch',
        PLZ: 7556,
        Zusatzziffer: 0,
        Gemeindename: 'Valsot',
        'BFS-Nr': 3764,
        Kantonskürzel: 'GR',
        E: 10.244561187005027,
        N: 46.9012151206686,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Ramosch',
        PLZ: 7556,
        Zusatzziffer: 0,
        Gemeindename: 'Valsot',
        'BFS-Nr': 3764,
        Kantonskürzel: 'GR',
        E: 10.382249723100388,
        N: 46.834303007572636,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Vnà',
        PLZ: 7557,
        Zusatzziffer: 0,
        Gemeindename: 'Valsot',
        'BFS-Nr': 3764,
        Kantonskürzel: 'GR',
        E: 10.363524841125363,
        N: 46.84305811213374,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Strada',
        PLZ: 7558,
        Zusatzziffer: 0,
        Gemeindename: 'Valsot',
        'BFS-Nr': 3764,
        Kantonskürzel: 'GR',
        E: 10.434964080873758,
        N: 46.86260626861026,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Tschlin',
        PLZ: 7559,
        Zusatzziffer: 0,
        Gemeindename: 'Valsot',
        'BFS-Nr': 3764,
        Kantonskürzel: 'GR',
        E: 10.422907949739555,
        N: 46.870857472195695,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Martina',
        PLZ: 7560,
        Zusatzziffer: 0,
        Gemeindename: 'Valsot',
        'BFS-Nr': 3764,
        Kantonskürzel: 'GR',
        E: 10.463543700515107,
        N: 46.884345562552916,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Bever',
        PLZ: 7502,
        Zusatzziffer: 0,
        Gemeindename: 'Bever',
        'BFS-Nr': 3781,
        Kantonskürzel: 'GR',
        E: 9.88927159945962,
        N: 46.55235049509746,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Bever',
        PLZ: 7502,
        Zusatzziffer: 0,
        Gemeindename: 'Bever',
        'BFS-Nr': 3781,
        Kantonskürzel: 'GR',
        E: 9.729523165466842,
        N: 46.5285655946488,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Celerina/Schlarigna',
        PLZ: 7505,
        Zusatzziffer: 0,
        Gemeindename: 'Celerina/Schlarigna',
        'BFS-Nr': 3782,
        Kantonskürzel: 'GR',
        E: 9.86263028498326,
        N: 46.51227782936343,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Madulain',
        PLZ: 7523,
        Zusatzziffer: 0,
        Gemeindename: 'Madulain',
        'BFS-Nr': 3783,
        Kantonskürzel: 'GR',
        E: 9.936914304122734,
        N: 46.58549176795034,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Madulain',
        PLZ: 7523,
        Zusatzziffer: 0,
        Gemeindename: 'Madulain',
        'BFS-Nr': 3783,
        Kantonskürzel: 'GR',
        E: 9.998295664214314,
        N: 46.49724614323881,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Pontresina',
        PLZ: 7504,
        Zusatzziffer: 0,
        Gemeindename: 'Pontresina',
        'BFS-Nr': 3784,
        Kantonskürzel: 'GR',
        E: 9.90247473487033,
        N: 46.49350224505089,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'La Punt Chamues-ch',
        PLZ: 7522,
        Zusatzziffer: 0,
        Gemeindename: 'La Punt Chamues-ch',
        'BFS-Nr': 3785,
        Kantonskürzel: 'GR',
        E: 9.928683611466699,
        N: 46.577541480429105,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Samedan',
        PLZ: 7503,
        Zusatzziffer: 0,
        Gemeindename: 'Samedan',
        'BFS-Nr': 3786,
        Kantonskürzel: 'GR',
        E: 9.870554894169318,
        N: 46.533898129554935,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'St. Moritz',
        PLZ: 7500,
        Zusatzziffer: 0,
        Gemeindename: 'St. Moritz',
        'BFS-Nr': 3787,
        Kantonskürzel: 'GR',
        E: 9.839985337298728,
        N: 46.49841188520117,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'S-chanf',
        PLZ: 7525,
        Zusatzziffer: 0,
        Gemeindename: 'S-chanf',
        'BFS-Nr': 3788,
        Kantonskürzel: 'GR',
        E: 9.985068420622047,
        N: 46.613887249594015,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Cinuos-chel',
        PLZ: 7526,
        Zusatzziffer: 0,
        Gemeindename: 'S-chanf',
        'BFS-Nr': 3788,
        Kantonskürzel: 'GR',
        E: 10.023145844221185,
        N: 46.64157955474017,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Chapella',
        PLZ: 7526,
        Zusatzziffer: 1,
        Gemeindename: 'S-chanf',
        'BFS-Nr': 3788,
        Kantonskürzel: 'GR',
        E: 10.01048866604564,
        N: 46.633249484757314,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Sils/Segl Maria',
        PLZ: 7514,
        Zusatzziffer: 0,
        Gemeindename: 'Sils im Engadin/Segl',
        'BFS-Nr': 3789,
        Kantonskürzel: 'GR',
        E: 9.763662324057414,
        N: 46.429019954608165,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Fex',
        PLZ: 7514,
        Zusatzziffer: 2,
        Gemeindename: 'Sils im Engadin/Segl',
        'BFS-Nr': 3789,
        Kantonskürzel: 'GR',
        E: 9.760862529083132,
        N: 46.418974265426854,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Sils/Segl Baselgia',
        PLZ: 7515,
        Zusatzziffer: 0,
        Gemeindename: 'Sils im Engadin/Segl',
        'BFS-Nr': 3789,
        Kantonskürzel: 'GR',
        E: 9.75508505538917,
        N: 46.434692470907926,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Plaun da Lej',
        PLZ: 7517,
        Zusatzziffer: 0,
        Gemeindename: 'Sils im Engadin/Segl',
        'BFS-Nr': 3789,
        Kantonskürzel: 'GR',
        E: 9.724474514732494,
        N: 46.42182478352467,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Champfèr',
        PLZ: 7512,
        Zusatzziffer: 0,
        Gemeindename: 'Silvaplana',
        'BFS-Nr': 3790,
        Kantonskürzel: 'GR',
        E: 9.81048783096587,
        N: 46.47813089276229,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Silvaplana',
        PLZ: 7513,
        Zusatzziffer: 0,
        Gemeindename: 'Silvaplana',
        'BFS-Nr': 3790,
        Kantonskürzel: 'GR',
        E: 9.795161924530857,
        N: 46.45944062753783,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Silvaplana-Surlej',
        PLZ: 7513,
        Zusatzziffer: 2,
        Gemeindename: 'Silvaplana',
        'BFS-Nr': 3790,
        Kantonskürzel: 'GR',
        E: 9.8139763951171,
        N: 46.45944108248705,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Zuoz',
        PLZ: 7524,
        Zusatzziffer: 0,
        Gemeindename: 'Zuoz',
        'BFS-Nr': 3791,
        Kantonskürzel: 'GR',
        E: 9.956313339361152,
        N: 46.60138646519043,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Zuoz',
        PLZ: 7524,
        Zusatzziffer: 0,
        Gemeindename: 'Zuoz',
        'BFS-Nr': 3791,
        Kantonskürzel: 'GR',
        E: 9.886658206320025,
        N: 46.61164182982894,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Maloja',
        PLZ: 7516,
        Zusatzziffer: 0,
        Gemeindename: 'Bregaglia',
        'BFS-Nr': 3792,
        Kantonskürzel: 'GR',
        E: 9.696147630194279,
        N: 46.40235955465698,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Casaccia',
        PLZ: 7602,
        Zusatzziffer: 0,
        Gemeindename: 'Bregaglia',
        'BFS-Nr': 3792,
        Kantonskürzel: 'GR',
        E: 9.665848595358025,
        N: 46.39091769995954,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Vicosoprano',
        PLZ: 7603,
        Zusatzziffer: 0,
        Gemeindename: 'Bregaglia',
        'BFS-Nr': 3792,
        Kantonskürzel: 'GR',
        E: 9.622188404686527,
        N: 46.350867935865544,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Borgonovo',
        PLZ: 7604,
        Zusatzziffer: 0,
        Gemeindename: 'Bregaglia',
        'BFS-Nr': 3792,
        Kantonskürzel: 'GR',
        E: 9.60386496204543,
        N: 46.34783262722452,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Stampa',
        PLZ: 7605,
        Zusatzziffer: 0,
        Gemeindename: 'Bregaglia',
        'BFS-Nr': 3792,
        Kantonskürzel: 'GR',
        E: 9.59064788178799,
        N: 46.34340904580393,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Promontogno',
        PLZ: 7606,
        Zusatzziffer: 0,
        Gemeindename: 'Bregaglia',
        'BFS-Nr': 3792,
        Kantonskürzel: 'GR',
        E: 9.55829558855705,
        N: 46.33751714597992,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Bondo',
        PLZ: 7606,
        Zusatzziffer: 1,
        Gemeindename: 'Bregaglia',
        'BFS-Nr': 3792,
        Kantonskürzel: 'GR',
        E: 9.554365528414891,
        N: 46.33437325409853,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Castasegna',
        PLZ: 7608,
        Zusatzziffer: 0,
        Gemeindename: 'Bregaglia',
        'BFS-Nr': 3792,
        Kantonskürzel: 'GR',
        E: 9.51618018601029,
        N: 46.33391441398864,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Soglio',
        PLZ: 7610,
        Zusatzziffer: 0,
        Gemeindename: 'Bregaglia',
        'BFS-Nr': 3792,
        Kantonskürzel: 'GR',
        E: 9.539256555961222,
        N: 46.34109021365299,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Buseno',
        PLZ: 6542,
        Zusatzziffer: 0,
        Gemeindename: 'Buseno',
        'BFS-Nr': 3804,
        Kantonskürzel: 'GR',
        E: 9.107344858312565,
        N: 46.27275912249046,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Castaneda',
        PLZ: 6540,
        Zusatzziffer: 0,
        Gemeindename: 'Castaneda',
        'BFS-Nr': 3805,
        Kantonskürzel: 'GR',
        E: 9.140497235878994,
        N: 46.25700735647225,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Rossa',
        PLZ: 6548,
        Zusatzziffer: 0,
        Gemeindename: 'Rossa',
        'BFS-Nr': 3808,
        Kantonskürzel: 'GR',
        E: 9.127096906526479,
        N: 46.37282886406153,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Augio',
        PLZ: 6548,
        Zusatzziffer: 1,
        Gemeindename: 'Rossa',
        'BFS-Nr': 3808,
        Kantonskürzel: 'GR',
        E: 9.12671009048805,
        N: 46.36470191071707,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Sta. Domenica',
        PLZ: 6548,
        Zusatzziffer: 2,
        Gemeindename: 'Rossa',
        'BFS-Nr': 3808,
        Kantonskürzel: 'GR',
        E: 9.122845750653754,
        N: 46.351822801640125,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Sta. Maria in Calanca',
        PLZ: 6541,
        Zusatzziffer: 0,
        Gemeindename: 'Santa Maria in Calanca',
        'BFS-Nr': 3810,
        Kantonskürzel: 'GR',
        E: 9.144958794511027,
        N: 46.26301322082196,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Lostallo',
        PLZ: 6558,
        Zusatzziffer: 0,
        Gemeindename: 'Lostallo',
        'BFS-Nr': 3821,
        Kantonskürzel: 'GR',
        E: 9.194435336028919,
        N: 46.31260034159053,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Mesocco',
        PLZ: 6563,
        Zusatzziffer: 0,
        Gemeindename: 'Mesocco',
        'BFS-Nr': 3822,
        Kantonskürzel: 'GR',
        E: 9.230758252491245,
        N: 46.39278033915167,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'S. Bernardino',
        PLZ: 6565,
        Zusatzziffer: 0,
        Gemeindename: 'Mesocco',
        'BFS-Nr': 3822,
        Kantonskürzel: 'GR',
        E: 9.192387471770187,
        N: 46.46283264219293,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Soazza',
        PLZ: 6562,
        Zusatzziffer: 0,
        Gemeindename: 'Soazza',
        'BFS-Nr': 3823,
        Kantonskürzel: 'GR',
        E: 9.222924597474144,
        N: 46.36691327698995,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Cama',
        PLZ: 6557,
        Zusatzziffer: 0,
        Gemeindename: 'Cama',
        'BFS-Nr': 3831,
        Kantonskürzel: 'GR',
        E: 9.173172862006505,
        N: 46.27211821516478,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Grono',
        PLZ: 6537,
        Zusatzziffer: 0,
        Gemeindename: 'Grono',
        'BFS-Nr': 3832,
        Kantonskürzel: 'GR',
        E: 9.144999240787877,
        N: 46.24754590749502,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Verdabbio',
        PLZ: 6538,
        Zusatzziffer: 0,
        Gemeindename: 'Grono',
        'BFS-Nr': 3832,
        Kantonskürzel: 'GR',
        E: 9.157854762320383,
        N: 46.268216587645654,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Verdabbio',
        PLZ: 6538,
        Zusatzziffer: 0,
        Gemeindename: 'Grono',
        'BFS-Nr': 3832,
        Kantonskürzel: 'GR',
        E: 9.23422883318792,
        N: 46.26442284624786,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Leggia',
        PLZ: 6556,
        Zusatzziffer: 0,
        Gemeindename: 'Grono',
        'BFS-Nr': 3832,
        Kantonskürzel: 'GR',
        E: 9.164463557866263,
        N: 46.260947102788634,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Roveredo GR',
        PLZ: 6535,
        Zusatzziffer: 0,
        Gemeindename: 'Roveredo (GR)',
        'BFS-Nr': 3834,
        Kantonskürzel: 'GR',
        E: 9.120003006531215,
        N: 46.23310249523047,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Laura',
        PLZ: 6549,
        Zusatzziffer: 0,
        Gemeindename: 'Roveredo (GR)',
        'BFS-Nr': 3834,
        Kantonskürzel: 'GR',
        E: 9.105185370377322,
        N: 46.213347750386745,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'S. Vittore',
        PLZ: 6534,
        Zusatzziffer: 0,
        Gemeindename: 'San Vittore',
        'BFS-Nr': 3835,
        Kantonskürzel: 'GR',
        E: 9.162610925347636,
        N: 46.18621933642523,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'S. Vittore',
        PLZ: 6534,
        Zusatzziffer: 0,
        Gemeindename: 'San Vittore',
        'BFS-Nr': 3835,
        Kantonskürzel: 'GR',
        E: 9.110124647357114,
        N: 46.23842782168884,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Arvigo',
        PLZ: 6543,
        Zusatzziffer: 0,
        Gemeindename: 'Calanca',
        'BFS-Nr': 3837,
        Kantonskürzel: 'GR',
        E: 9.113391169575102,
        N: 46.30225069190649,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Braggio',
        PLZ: 6544,
        Zusatzziffer: 0,
        Gemeindename: 'Calanca',
        'BFS-Nr': 3837,
        Kantonskürzel: 'GR',
        E: 9.123535050785593,
        N: 46.30326680130374,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Selma',
        PLZ: 6545,
        Zusatzziffer: 0,
        Gemeindename: 'Calanca',
        'BFS-Nr': 3837,
        Kantonskürzel: 'GR',
        E: 9.121669808026144,
        N: 46.31924627241436,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Cauco',
        PLZ: 6546,
        Zusatzziffer: 0,
        Gemeindename: 'Calanca',
        'BFS-Nr': 3837,
        Kantonskürzel: 'GR',
        E: 9.12134115847307,
        N: 46.33506406866638,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Tschierv',
        PLZ: 7532,
        Zusatzziffer: 0,
        Gemeindename: 'Val Müstair',
        'BFS-Nr': 3847,
        Kantonskürzel: 'GR',
        E: 10.340760387800264,
        N: 46.62445550651699,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Fuldera',
        PLZ: 7533,
        Zusatzziffer: 0,
        Gemeindename: 'Val Müstair',
        'BFS-Nr': 3847,
        Kantonskürzel: 'GR',
        E: 10.371404029092169,
        N: 46.60864013445815,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Lü',
        PLZ: 7534,
        Zusatzziffer: 0,
        Gemeindename: 'Val Müstair',
        'BFS-Nr': 3847,
        Kantonskürzel: 'GR',
        E: 10.369174166130472,
        N: 46.622443369042195,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Valchava',
        PLZ: 7535,
        Zusatzziffer: 0,
        Gemeindename: 'Val Müstair',
        'BFS-Nr': 3847,
        Kantonskürzel: 'GR',
        E: 10.408568958033278,
        N: 46.60019423557342,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Sta. Maria Val Müstair',
        PLZ: 7536,
        Zusatzziffer: 0,
        Gemeindename: 'Val Müstair',
        'BFS-Nr': 3847,
        Kantonskürzel: 'GR',
        E: 10.422838532516616,
        N: 46.6013301486474,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Müstair',
        PLZ: 7537,
        Zusatzziffer: 0,
        Gemeindename: 'Val Müstair',
        'BFS-Nr': 3847,
        Kantonskürzel: 'GR',
        E: 10.445205632132339,
        N: 46.62171782204736,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Davos Dorf',
        PLZ: 7260,
        Zusatzziffer: 0,
        Gemeindename: 'Davos',
        'BFS-Nr': 3851,
        Kantonskürzel: 'GR',
        E: 9.839822948699043,
        N: 46.80907392650913,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Davos Wolfgang',
        PLZ: 7265,
        Zusatzziffer: 0,
        Gemeindename: 'Davos',
        'BFS-Nr': 3851,
        Kantonskürzel: 'GR',
        E: 9.854593024128345,
        N: 46.83201534807431,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Davos Platz',
        PLZ: 7270,
        Zusatzziffer: 0,
        Gemeindename: 'Davos',
        'BFS-Nr': 3851,
        Kantonskürzel: 'GR',
        E: 9.8221368256754,
        N: 46.7932792416927,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Davos Clavadel',
        PLZ: 7272,
        Zusatzziffer: 0,
        Gemeindename: 'Davos',
        'BFS-Nr': 3851,
        Kantonskürzel: 'GR',
        E: 9.81625393533818,
        N: 46.76712512841199,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Davos Frauenkirch',
        PLZ: 7276,
        Zusatzziffer: 0,
        Gemeindename: 'Davos',
        'BFS-Nr': 3851,
        Kantonskürzel: 'GR',
        E: 9.798670834631054,
        N: 46.768770603131706,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Davos Glaris',
        PLZ: 7277,
        Zusatzziffer: 0,
        Gemeindename: 'Davos',
        'BFS-Nr': 3851,
        Kantonskürzel: 'GR',
        E: 9.776589770640685,
        N: 46.741012700949994,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Davos Monstein',
        PLZ: 7278,
        Zusatzziffer: 0,
        Gemeindename: 'Davos',
        'BFS-Nr': 3851,
        Kantonskürzel: 'GR',
        E: 9.770946455917564,
        N: 46.71199017920756,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Davos Wiesen',
        PLZ: 7494,
        Zusatzziffer: 0,
        Gemeindename: 'Davos',
        'BFS-Nr': 3851,
        Kantonskürzel: 'GR',
        E: 9.714508017359899,
        N: 46.70372429448485,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Fideris',
        PLZ: 7235,
        Zusatzziffer: 0,
        Gemeindename: 'Fideris',
        'BFS-Nr': 3861,
        Kantonskürzel: 'GR',
        E: 9.740345300742565,
        N: 46.91400222754782,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Furna',
        PLZ: 7232,
        Zusatzziffer: 0,
        Gemeindename: 'Furna',
        'BFS-Nr': 3862,
        Kantonskürzel: 'GR',
        E: 9.678968216807393,
        N: 46.93695873382035,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Pragg-Jenaz',
        PLZ: 7231,
        Zusatzziffer: 0,
        Gemeindename: 'Jenaz',
        'BFS-Nr': 3863,
        Kantonskürzel: 'GR',
        E: 9.703532215006776,
        N: 46.938495349216375,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Jenaz',
        PLZ: 7233,
        Zusatzziffer: 0,
        Gemeindename: 'Jenaz',
        'BFS-Nr': 3863,
        Kantonskürzel: 'GR',
        E: 9.716706265589613,
        N: 46.926435659740946,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Saas im Prättigau',
        PLZ: 7247,
        Zusatzziffer: 0,
        Gemeindename: 'Klosters',
        'BFS-Nr': 3871,
        Kantonskürzel: 'GR',
        E: 9.8053100478841,
        N: 46.91086517336067,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Serneus',
        PLZ: 7249,
        Zusatzziffer: 0,
        Gemeindename: 'Klosters',
        'BFS-Nr': 3871,
        Kantonskürzel: 'GR',
        E: 9.840231869611868,
        N: 46.89068565667186,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Klosters',
        PLZ: 7250,
        Zusatzziffer: 0,
        Gemeindename: 'Klosters',
        'BFS-Nr': 3871,
        Kantonskürzel: 'GR',
        E: 9.88210213459575,
        N: 46.8703635568374,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Klosters Dorf',
        PLZ: 7252,
        Zusatzziffer: 0,
        Gemeindename: 'Klosters',
        'BFS-Nr': 3871,
        Kantonskürzel: 'GR',
        E: 9.875142302548065,
        N: 46.88362087560375,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Conters im Prättigau',
        PLZ: 7241,
        Zusatzziffer: 0,
        Gemeindename: 'Conters im Prättigau',
        'BFS-Nr': 3881,
        Kantonskürzel: 'GR',
        E: 9.7990435599311,
        N: 46.90321856047657,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Küblis',
        PLZ: 7240,
        Zusatzziffer: 0,
        Gemeindename: 'Küblis',
        'BFS-Nr': 3882,
        Kantonskürzel: 'GR',
        E: 9.777042388419474,
        N: 46.914226159619396,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Buchen im Prättigau',
        PLZ: 7223,
        Zusatzziffer: 0,
        Gemeindename: 'Luzein',
        'BFS-Nr': 3891,
        Kantonskürzel: 'GR',
        E: 9.722751817279013,
        N: 46.939613325835204,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Putz',
        PLZ: 7224,
        Zusatzziffer: 0,
        Gemeindename: 'Luzein',
        'BFS-Nr': 3891,
        Kantonskürzel: 'GR',
        E: 9.742374410355128,
        N: 46.92611190906532,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Luzein',
        PLZ: 7242,
        Zusatzziffer: 0,
        Gemeindename: 'Luzein',
        'BFS-Nr': 3891,
        Kantonskürzel: 'GR',
        E: 9.762851334364294,
        N: 46.920012838658735,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Pany',
        PLZ: 7243,
        Zusatzziffer: 0,
        Gemeindename: 'Luzein',
        'BFS-Nr': 3891,
        Kantonskürzel: 'GR',
        E: 9.76932980855635,
        N: 46.930761647964005,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Gadenstätt',
        PLZ: 7244,
        Zusatzziffer: 0,
        Gemeindename: 'Luzein',
        'BFS-Nr': 3891,
        Kantonskürzel: 'GR',
        E: 9.793072169720748,
        N: 46.94446110236218,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Ascharina',
        PLZ: 7245,
        Zusatzziffer: 0,
        Gemeindename: 'Luzein',
        'BFS-Nr': 3891,
        Kantonskürzel: 'GR',
        E: 9.807084140397377,
        N: 46.95763169946251,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'St. Antönien',
        PLZ: 7246,
        Zusatzziffer: 0,
        Gemeindename: 'Luzein',
        'BFS-Nr': 3891,
        Kantonskürzel: 'GR',
        E: 9.814018926491418,
        N: 46.9694746230127,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Chur',
        PLZ: 7000,
        Zusatzziffer: 0,
        Gemeindename: 'Chur',
        'BFS-Nr': 3901,
        Kantonskürzel: 'GR',
        E: 9.53279983527006,
        N: 46.84817709160957,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Haldenstein',
        PLZ: 7023,
        Zusatzziffer: 0,
        Gemeindename: 'Chur',
        'BFS-Nr': 3901,
        Kantonskürzel: 'GR',
        E: 9.521942767055394,
        N: 46.879200086584255,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Maladers',
        PLZ: 7026,
        Zusatzziffer: 0,
        Gemeindename: 'Chur',
        'BFS-Nr': 3901,
        Kantonskürzel: 'GR',
        E: 9.560642993168035,
        N: 46.83669701315662,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Passugg',
        PLZ: 7062,
        Zusatzziffer: 0,
        Gemeindename: 'Churwalden',
        'BFS-Nr': 3911,
        Kantonskürzel: 'GR',
        E: 9.546975364594061,
        N: 46.830249561349085,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Malix',
        PLZ: 7074,
        Zusatzziffer: 0,
        Gemeindename: 'Churwalden',
        'BFS-Nr': 3911,
        Kantonskürzel: 'GR',
        E: 9.531007731699583,
        N: 46.81384998008302,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Churwalden',
        PLZ: 7075,
        Zusatzziffer: 0,
        Gemeindename: 'Churwalden',
        'BFS-Nr': 3911,
        Kantonskürzel: 'GR',
        E: 9.54447292799674,
        N: 46.78070500007767,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Parpan',
        PLZ: 7076,
        Zusatzziffer: 0,
        Gemeindename: 'Churwalden',
        'BFS-Nr': 3911,
        Kantonskürzel: 'GR',
        E: 9.557628615805138,
        N: 46.76106088243136,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Castiel',
        PLZ: 7027,
        Zusatzziffer: 0,
        Gemeindename: 'Arosa',
        'BFS-Nr': 3921,
        Kantonskürzel: 'GR',
        E: 9.601831183654589,
        N: 46.83845337621556,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Lüen',
        PLZ: 7027,
        Zusatzziffer: 1,
        Gemeindename: 'Arosa',
        'BFS-Nr': 3921,
        Kantonskürzel: 'GR',
        E: 9.613074351954092,
        N: 46.83289434092728,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Calfreisen',
        PLZ: 7027,
        Zusatzziffer: 3,
        Gemeindename: 'Arosa',
        'BFS-Nr': 3921,
        Kantonskürzel: 'GR',
        E: 9.595055059743,
        N: 46.84135176458199,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'St. Peter',
        PLZ: 7028,
        Zusatzziffer: 0,
        Gemeindename: 'Arosa',
        'BFS-Nr': 3921,
        Kantonskürzel: 'GR',
        E: 9.643875554178928,
        N: 46.83433106276666,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Pagig',
        PLZ: 7028,
        Zusatzziffer: 1,
        Gemeindename: 'Arosa',
        'BFS-Nr': 3921,
        Kantonskürzel: 'GR',
        E: 9.63669256331225,
        N: 46.837102907112225,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Peist',
        PLZ: 7029,
        Zusatzziffer: 0,
        Gemeindename: 'Arosa',
        'BFS-Nr': 3921,
        Kantonskürzel: 'GR',
        E: 9.673871698304103,
        N: 46.83299695807456,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Arosa',
        PLZ: 7050,
        Zusatzziffer: 0,
        Gemeindename: 'Arosa',
        'BFS-Nr': 3921,
        Kantonskürzel: 'GR',
        E: 9.675599161530497,
        N: 46.77729827776493,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Molinis',
        PLZ: 7056,
        Zusatzziffer: 0,
        Gemeindename: 'Arosa',
        'BFS-Nr': 3921,
        Kantonskürzel: 'GR',
        E: 9.655177650149842,
        N: 46.827287127993934,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Langwies',
        PLZ: 7057,
        Zusatzziffer: 0,
        Gemeindename: 'Arosa',
        'BFS-Nr': 3921,
        Kantonskürzel: 'GR',
        E: 9.713195396084664,
        N: 46.82041515571302,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Litzirüti',
        PLZ: 7058,
        Zusatzziffer: 0,
        Gemeindename: 'Arosa',
        'BFS-Nr': 3921,
        Kantonskürzel: 'GR',
        E: 9.702650648674535,
        N: 46.797992383113595,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Praden',
        PLZ: 7063,
        Zusatzziffer: 0,
        Gemeindename: 'Tschiertschen-Praden',
        'BFS-Nr': 3932,
        Kantonskürzel: 'GR',
        E: 9.581644934804933,
        N: 46.8250763390718,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Tschiertschen',
        PLZ: 7064,
        Zusatzziffer: 0,
        Gemeindename: 'Tschiertschen-Praden',
        'BFS-Nr': 3932,
        Kantonskürzel: 'GR',
        E: 9.606973036600458,
        N: 46.817853136278174,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Says',
        PLZ: 7202,
        Zusatzziffer: 0,
        Gemeindename: 'Trimmis',
        'BFS-Nr': 3945,
        Kantonskürzel: 'GR',
        E: 9.575168336871615,
        N: 46.903578440125145,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Trimmis',
        PLZ: 7203,
        Zusatzziffer: 0,
        Gemeindename: 'Trimmis',
        'BFS-Nr': 3945,
        Kantonskürzel: 'GR',
        E: 9.563937832877956,
        N: 46.89602558444964,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Untervaz',
        PLZ: 7204,
        Zusatzziffer: 0,
        Gemeindename: 'Untervaz',
        'BFS-Nr': 3946,
        Kantonskürzel: 'GR',
        E: 9.535084368258172,
        N: 46.92721863554332,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Zizers',
        PLZ: 7205,
        Zusatzziffer: 0,
        Gemeindename: 'Zizers',
        'BFS-Nr': 3947,
        Kantonskürzel: 'GR',
        E: 9.566025482788588,
        N: 46.93664704565707,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Fläsch',
        PLZ: 7306,
        Zusatzziffer: 0,
        Gemeindename: 'Fläsch',
        'BFS-Nr': 3951,
        Kantonskürzel: 'GR',
        E: 9.513896761389045,
        N: 47.025727824674036,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Jenins',
        PLZ: 7307,
        Zusatzziffer: 0,
        Gemeindename: 'Jenins',
        'BFS-Nr': 3952,
        Kantonskürzel: 'GR',
        E: 9.556026489583088,
        N: 47.00107053521757,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Maienfeld',
        PLZ: 7304,
        Zusatzziffer: 0,
        Gemeindename: 'Maienfeld',
        'BFS-Nr': 3953,
        Kantonskürzel: 'GR',
        E: 9.531480740081374,
        N: 47.00798954655765,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Malans GR',
        PLZ: 7208,
        Zusatzziffer: 0,
        Gemeindename: 'Malans',
        'BFS-Nr': 3954,
        Kantonskürzel: 'GR',
        E: 9.574330550951926,
        N: 46.98182662089853,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Igis',
        PLZ: 7206,
        Zusatzziffer: 0,
        Gemeindename: 'Landquart',
        'BFS-Nr': 3955,
        Kantonskürzel: 'GR',
        E: 9.571730767522743,
        N: 46.94527643519146,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Landquart',
        PLZ: 7302,
        Zusatzziffer: 0,
        Gemeindename: 'Landquart',
        'BFS-Nr': 3955,
        Kantonskürzel: 'GR',
        E: 9.558616106269687,
        N: 46.963569415242894,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Mastrils',
        PLZ: 7303,
        Zusatzziffer: 0,
        Gemeindename: 'Landquart',
        'BFS-Nr': 3955,
        Kantonskürzel: 'GR',
        E: 9.543969508287738,
        N: 46.96794861985525,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Valzeina',
        PLZ: 7213,
        Zusatzziffer: 0,
        Gemeindename: 'Grüsch',
        'BFS-Nr': 3961,
        Kantonskürzel: 'GR',
        E: 9.603172328086886,
        N: 46.95098819379005,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Grüsch',
        PLZ: 7214,
        Zusatzziffer: 0,
        Gemeindename: 'Grüsch',
        'BFS-Nr': 3961,
        Kantonskürzel: 'GR',
        E: 9.647034953807298,
        N: 46.982451805219824,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Fanas',
        PLZ: 7215,
        Zusatzziffer: 0,
        Gemeindename: 'Grüsch',
        'BFS-Nr': 3961,
        Kantonskürzel: 'GR',
        E: 9.66663334568443,
        N: 46.984456461879006,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Schiers',
        PLZ: 7220,
        Zusatzziffer: 0,
        Gemeindename: 'Schiers',
        'BFS-Nr': 3962,
        Kantonskürzel: 'GR',
        E: 9.770067705090314,
        N: 46.995319810894124,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Schiers',
        PLZ: 7220,
        Zusatzziffer: 0,
        Gemeindename: 'Schiers',
        'BFS-Nr': 3962,
        Kantonskürzel: 'GR',
        E: 9.688374572960496,
        N: 46.970220107247776,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Lunden',
        PLZ: 7222,
        Zusatzziffer: 0,
        Gemeindename: 'Schiers',
        'BFS-Nr': 3962,
        Kantonskürzel: 'GR',
        E: 9.711868848467107,
        N: 46.94829827373983,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Stels',
        PLZ: 7226,
        Zusatzziffer: 0,
        Gemeindename: 'Schiers',
        'BFS-Nr': 3962,
        Kantonskürzel: 'GR',
        E: 9.71578028809101,
        N: 46.96549628204442,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Stels',
        PLZ: 7226,
        Zusatzziffer: 0,
        Gemeindename: 'Schiers',
        'BFS-Nr': 3962,
        Kantonskürzel: 'GR',
        E: 9.798348579164086,
        N: 47.0103253520973,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Fajauna',
        PLZ: 7226,
        Zusatzziffer: 2,
        Gemeindename: 'Schiers',
        'BFS-Nr': 3962,
        Kantonskürzel: 'GR',
        E: 9.700645469236553,
        N: 46.969661880506116,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Schuders',
        PLZ: 7228,
        Zusatzziffer: 0,
        Gemeindename: 'Schiers',
        'BFS-Nr': 3962,
        Kantonskürzel: 'GR',
        E: 9.738421546300454,
        N: 46.991097322973424,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Pusserein',
        PLZ: 7228,
        Zusatzziffer: 1,
        Gemeindename: 'Schiers',
        'BFS-Nr': 3962,
        Kantonskürzel: 'GR',
        E: 9.710943086394552,
        N: 46.9835394890135,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Seewis Dorf',
        PLZ: 7212,
        Zusatzziffer: 0,
        Gemeindename: 'Seewis im Prättigau',
        'BFS-Nr': 3972,
        Kantonskürzel: 'GR',
        E: 9.639780556157344,
        N: 46.989709755299984,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Seewis-Pardisla',
        PLZ: 7212,
        Zusatzziffer: 1,
        Gemeindename: 'Seewis im Prättigau',
        'BFS-Nr': 3972,
        Kantonskürzel: 'GR',
        E: 9.631783787080932,
        N: 46.97755749377443,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Seewis-Schmitten',
        PLZ: 7212,
        Zusatzziffer: 2,
        Gemeindename: 'Seewis im Prättigau',
        'BFS-Nr': 3972,
        Kantonskürzel: 'GR',
        E: 9.640681501001445,
        N: 46.980360908360396,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Waltensburg/Vuorz',
        PLZ: 7158,
        Zusatzziffer: 0,
        Gemeindename: 'Breil/Brigels',
        'BFS-Nr': 3981,
        Kantonskürzel: 'GR',
        E: 9.117599611722438,
        N: 46.777114292554536,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Andiast',
        PLZ: 7159,
        Zusatzziffer: 0,
        Gemeindename: 'Breil/Brigels',
        'BFS-Nr': 3981,
        Kantonskürzel: 'GR',
        E: 9.11793641335161,
        N: 46.78701272595247,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Tavanasa',
        PLZ: 7162,
        Zusatzziffer: 0,
        Gemeindename: 'Breil/Brigels',
        'BFS-Nr': 3981,
        Kantonskürzel: 'GR',
        E: 9.0627163768955,
        N: 46.75481296481839,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Danis',
        PLZ: 7163,
        Zusatzziffer: 0,
        Gemeindename: 'Breil/Brigels',
        'BFS-Nr': 3981,
        Kantonskürzel: 'GR',
        E: 9.057203173506265,
        N: 46.75471879526169,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Dardin',
        PLZ: 7164,
        Zusatzziffer: 0,
        Gemeindename: 'Breil/Brigels',
        'BFS-Nr': 3981,
        Kantonskürzel: 'GR',
        E: 9.043755453828346,
        N: 46.75690926478085,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Breil/Brigels',
        PLZ: 7165,
        Zusatzziffer: 0,
        Gemeindename: 'Breil/Brigels',
        'BFS-Nr': 3981,
        Kantonskürzel: 'GR',
        E: 9.06146159545732,
        N: 46.76817180134502,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Disentis/Mustér',
        PLZ: 7180,
        Zusatzziffer: 0,
        Gemeindename: 'Disentis/Mustér',
        'BFS-Nr': 3982,
        Kantonskürzel: 'GR',
        E: 8.851466126965445,
        N: 46.703389350690635,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Cavardiras',
        PLZ: 7182,
        Zusatzziffer: 0,
        Gemeindename: 'Disentis/Mustér',
        'BFS-Nr': 3982,
        Kantonskürzel: 'GR',
        E: 8.884864242101493,
        N: 46.71052075255355,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Mumpé Medel',
        PLZ: 7183,
        Zusatzziffer: 0,
        Gemeindename: 'Disentis/Mustér',
        'BFS-Nr': 3982,
        Kantonskürzel: 'GR',
        E: 8.848076190991229,
        N: 46.68952215148371,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Segnas',
        PLZ: 7186,
        Zusatzziffer: 0,
        Gemeindename: 'Disentis/Mustér',
        'BFS-Nr': 3982,
        Kantonskürzel: 'GR',
        E: 8.828022813420423,
        N: 46.694941616144575,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Curaglia',
        PLZ: 7184,
        Zusatzziffer: 0,
        Gemeindename: 'Medel (Lucmagn)',
        'BFS-Nr': 3983,
        Kantonskürzel: 'GR',
        E: 8.85871221747488,
        N: 46.67357203373149,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Platta',
        PLZ: 7185,
        Zusatzziffer: 0,
        Gemeindename: 'Medel (Lucmagn)',
        'BFS-Nr': 3983,
        Kantonskürzel: 'GR',
        E: 8.854060819600374,
        N: 46.658248861605976,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Rabius',
        PLZ: 7172,
        Zusatzziffer: 0,
        Gemeindename: 'Sumvitg',
        'BFS-Nr': 3985,
        Kantonskürzel: 'GR',
        E: 8.958484752015814,
        N: 46.7345049985576,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Surrein',
        PLZ: 7173,
        Zusatzziffer: 0,
        Gemeindename: 'Sumvitg',
        'BFS-Nr': 3985,
        Kantonskürzel: 'GR',
        E: 8.949457197771443,
        N: 46.72269141826945,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'S. Benedetg',
        PLZ: 7174,
        Zusatzziffer: 0,
        Gemeindename: 'Sumvitg',
        'BFS-Nr': 3985,
        Kantonskürzel: 'GR',
        E: 8.939175401238332,
        N: 46.73471326656388,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Sumvitg',
        PLZ: 7175,
        Zusatzziffer: 0,
        Gemeindename: 'Sumvitg',
        'BFS-Nr': 3985,
        Kantonskürzel: 'GR',
        E: 8.939095028603756,
        N: 46.72818531373043,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Cumpadials',
        PLZ: 7176,
        Zusatzziffer: 0,
        Gemeindename: 'Sumvitg',
        'BFS-Nr': 3985,
        Kantonskürzel: 'GR',
        E: 8.92085470342764,
        N: 46.722756774438736,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Camischolas',
        PLZ: 7187,
        Zusatzziffer: 0,
        Gemeindename: 'Tujetsch',
        'BFS-Nr': 3986,
        Kantonskürzel: 'GR',
        E: 8.761172887791515,
        N: 46.68237253052586,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Sedrun',
        PLZ: 7188,
        Zusatzziffer: 0,
        Gemeindename: 'Tujetsch',
        'BFS-Nr': 3986,
        Kantonskürzel: 'GR',
        E: 8.775169150995112,
        N: 46.679615995951416,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Rueras',
        PLZ: 7189,
        Zusatzziffer: 0,
        Gemeindename: 'Tujetsch',
        'BFS-Nr': 3986,
        Kantonskürzel: 'GR',
        E: 8.750882254434563,
        N: 46.67456046399816,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Trun',
        PLZ: 7166,
        Zusatzziffer: 0,
        Gemeindename: 'Trun',
        'BFS-Nr': 3987,
        Kantonskürzel: 'GR',
        E: 8.984790128702453,
        N: 46.74196928820311,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Zignau',
        PLZ: 7167,
        Zusatzziffer: 0,
        Gemeindename: 'Trun',
        'BFS-Nr': 3987,
        Kantonskürzel: 'GR',
        E: 9.00621075406221,
        N: 46.74094618334855,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Schlans',
        PLZ: 7168,
        Zusatzziffer: 0,
        Gemeindename: 'Trun',
        'BFS-Nr': 3987,
        Kantonskürzel: 'GR',
        E: 9.015012842491934,
        N: 46.75319831275079,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Obersaxen',
        PLZ: 7134,
        Zusatzziffer: 0,
        Gemeindename: 'Obersaxen Mundaun',
        'BFS-Nr': 3988,
        Kantonskürzel: 'GR',
        E: 9.100229701547233,
        N: 46.74852927682207,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Flond',
        PLZ: 7137,
        Zusatzziffer: 0,
        Gemeindename: 'Obersaxen Mundaun',
        'BFS-Nr': 3988,
        Kantonskürzel: 'GR',
        E: 9.161022013568228,
        N: 46.76758860332831,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Surcuolm',
        PLZ: 7138,
        Zusatzziffer: 0,
        Gemeindename: 'Obersaxen Mundaun',
        'BFS-Nr': 3988,
        Kantonskürzel: 'GR',
        E: 9.144088634093457,
        N: 46.7578091367759,
        Sprache: 'rm',
    },
    {
        Ortschaftsname: 'Aarau',
        PLZ: 5000,
        Zusatzziffer: 0,
        Gemeindename: 'Aarau',
        'BFS-Nr': 4001,
        Kantonskürzel: 'AG',
        E: 8.048667760616965,
        N: 47.38901423157816,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Aarau',
        PLZ: 5004,
        Zusatzziffer: 0,
        Gemeindename: 'Aarau',
        'BFS-Nr': 4001,
        Kantonskürzel: 'AG',
        E: 8.060592030984289,
        N: 47.400921041532925,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Aarau Rohr',
        PLZ: 5032,
        Zusatzziffer: 0,
        Gemeindename: 'Aarau',
        'BFS-Nr': 4001,
        Kantonskürzel: 'AG',
        E: 8.085285394113608,
        N: 47.40200809245217,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Biberstein',
        PLZ: 5023,
        Zusatzziffer: 0,
        Gemeindename: 'Biberstein',
        'BFS-Nr': 4002,
        Kantonskürzel: 'AG',
        E: 8.081244803607762,
        N: 47.415955143611754,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Buchs AG',
        PLZ: 5033,
        Zusatzziffer: 0,
        Gemeindename: 'Buchs (AG)',
        'BFS-Nr': 4003,
        Kantonskürzel: 'AG',
        E: 8.07133990310647,
        N: 47.38921826615594,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Asp',
        PLZ: 5025,
        Zusatzziffer: 0,
        Gemeindename: 'Densbüren',
        'BFS-Nr': 4004,
        Kantonskürzel: 'AG',
        E: 8.04875805221814,
        N: 47.44287014866537,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Densbüren',
        PLZ: 5026,
        Zusatzziffer: 0,
        Gemeindename: 'Densbüren',
        'BFS-Nr': 4004,
        Kantonskürzel: 'AG',
        E: 8.05486323843423,
        N: 47.45285898883019,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Barmelweid',
        PLZ: 5017,
        Zusatzziffer: 0,
        Gemeindename: 'Erlinsbach (AG)',
        'BFS-Nr': 4005,
        Kantonskürzel: 'AG',
        E: 7.969941942960792,
        N: 47.42185778936376,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Erlinsbach',
        PLZ: 5018,
        Zusatzziffer: 0,
        Gemeindename: 'Erlinsbach (AG)',
        'BFS-Nr': 4005,
        Kantonskürzel: 'AG',
        E: 8.009675597968336,
        N: 47.40746809985258,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Gränichen',
        PLZ: 5722,
        Zusatzziffer: 0,
        Gemeindename: 'Gränichen',
        'BFS-Nr': 4006,
        Kantonskürzel: 'AG',
        E: 8.102126287370167,
        N: 47.356504891918824,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Hirschthal',
        PLZ: 5042,
        Zusatzziffer: 0,
        Gemeindename: 'Hirschthal',
        'BFS-Nr': 4007,
        Kantonskürzel: 'AG',
        E: 8.056392816249216,
        N: 47.31870720252326,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Rombach',
        PLZ: 5022,
        Zusatzziffer: 0,
        Gemeindename: 'Küttigen',
        'BFS-Nr': 4008,
        Kantonskürzel: 'AG',
        E: 8.046797981749277,
        N: 47.40544473520921,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Küttigen',
        PLZ: 5024,
        Zusatzziffer: 0,
        Gemeindename: 'Küttigen',
        'BFS-Nr': 4008,
        Kantonskürzel: 'AG',
        E: 8.047804175811564,
        N: 47.413030588899254,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Muhen',
        PLZ: 5037,
        Zusatzziffer: 0,
        Gemeindename: 'Muhen',
        'BFS-Nr': 4009,
        Kantonskürzel: 'AG',
        E: 8.051355935919275,
        N: 47.335029350351014,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Oberentfelden',
        PLZ: 5036,
        Zusatzziffer: 0,
        Gemeindename: 'Oberentfelden',
        'BFS-Nr': 4010,
        Kantonskürzel: 'AG',
        E: 8.03377378772822,
        N: 47.35800388951357,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Suhr',
        PLZ: 5034,
        Zusatzziffer: 0,
        Gemeindename: 'Suhr',
        'BFS-Nr': 4012,
        Kantonskürzel: 'AG',
        E: 8.076050236933959,
        N: 47.37319913559865,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Unterentfelden',
        PLZ: 5035,
        Zusatzziffer: 0,
        Gemeindename: 'Unterentfelden',
        'BFS-Nr': 4013,
        Kantonskürzel: 'AG',
        E: 8.038348881429336,
        N: 47.36968525075385,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Baden',
        PLZ: 5400,
        Zusatzziffer: 0,
        Gemeindename: 'Baden',
        'BFS-Nr': 4021,
        Kantonskürzel: 'AG',
        E: 8.308981470450894,
        N: 47.47262639905851,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Baden',
        PLZ: 5404,
        Zusatzziffer: 0,
        Gemeindename: 'Baden',
        'BFS-Nr': 4021,
        Kantonskürzel: 'AG',
        E: 8.278285489120819,
        N: 47.454506656538534,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Dättwil AG',
        PLZ: 5405,
        Zusatzziffer: 2,
        Gemeindename: 'Baden',
        'BFS-Nr': 4021,
        Kantonskürzel: 'AG',
        E: 8.285912550438717,
        N: 47.454448095239094,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Rütihof',
        PLZ: 5406,
        Zusatzziffer: 2,
        Gemeindename: 'Baden',
        'BFS-Nr': 4021,
        Kantonskürzel: 'AG',
        E: 8.268352153289332,
        N: 47.440087476529726,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Bellikon',
        PLZ: 5454,
        Zusatzziffer: 0,
        Gemeindename: 'Bellikon',
        'BFS-Nr': 4022,
        Kantonskürzel: 'AG',
        E: 8.345428543616844,
        N: 47.391204500467445,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Bergdietikon',
        PLZ: 8962,
        Zusatzziffer: 0,
        Gemeindename: 'Bergdietikon',
        'BFS-Nr': 4023,
        Kantonskürzel: 'AG',
        E: 8.380370150733269,
        N: 47.39075686461178,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Birmenstorf AG',
        PLZ: 5413,
        Zusatzziffer: 0,
        Gemeindename: 'Birmenstorf (AG)',
        'BFS-Nr': 4024,
        Kantonskürzel: 'AG',
        E: 8.248709815689026,
        N: 47.461868052120586,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Ennetbaden',
        PLZ: 5408,
        Zusatzziffer: 0,
        Gemeindename: 'Ennetbaden',
        'BFS-Nr': 4026,
        Kantonskürzel: 'AG',
        E: 8.323996845889873,
        N: 47.48055557364367,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Fislisbach',
        PLZ: 5442,
        Zusatzziffer: 0,
        Gemeindename: 'Fislisbach',
        'BFS-Nr': 4027,
        Kantonskürzel: 'AG',
        E: 8.29688020756547,
        N: 47.43904218816608,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Freienwil',
        PLZ: 5423,
        Zusatzziffer: 0,
        Gemeindename: 'Freienwil',
        'BFS-Nr': 4028,
        Kantonskürzel: 'AG',
        E: 8.326438854177049,
        N: 47.502635227346836,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Gebenstorf',
        PLZ: 5412,
        Zusatzziffer: 0,
        Gemeindename: 'Gebenstorf',
        'BFS-Nr': 4029,
        Kantonskürzel: 'AG',
        E: 8.240166177386115,
        N: 47.48065575806111,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Vogelsang AG',
        PLZ: 5412,
        Zusatzziffer: 1,
        Gemeindename: 'Gebenstorf',
        'BFS-Nr': 4029,
        Kantonskürzel: 'AG',
        E: 8.240103209383813,
        N: 47.4951134221311,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Killwangen',
        PLZ: 8956,
        Zusatzziffer: 0,
        Gemeindename: 'Killwangen',
        'BFS-Nr': 4030,
        Kantonskürzel: 'AG',
        E: 8.34871896150538,
        N: 47.43271019192202,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Künten',
        PLZ: 5444,
        Zusatzziffer: 0,
        Gemeindename: 'Künten',
        'BFS-Nr': 4031,
        Kantonskürzel: 'AG',
        E: 8.326894506542793,
        N: 47.39027901812604,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Mägenwil',
        PLZ: 5506,
        Zusatzziffer: 0,
        Gemeindename: 'Mägenwil',
        'BFS-Nr': 4032,
        Kantonskürzel: 'AG',
        E: 8.233670142532956,
        N: 47.40973188781006,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Mellingen',
        PLZ: 5507,
        Zusatzziffer: 0,
        Gemeindename: 'Mellingen',
        'BFS-Nr': 4033,
        Kantonskürzel: 'AG',
        E: 8.28140089741134,
        N: 47.41819940889915,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Neuenhof',
        PLZ: 5432,
        Zusatzziffer: 0,
        Gemeindename: 'Neuenhof',
        'BFS-Nr': 4034,
        Kantonskürzel: 'AG',
        E: 8.323675642906949,
        N: 47.44570935554622,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Niederrohrdorf',
        PLZ: 5443,
        Zusatzziffer: 0,
        Gemeindename: 'Niederrohrdorf',
        'BFS-Nr': 4035,
        Kantonskürzel: 'AG',
        E: 8.297717522904602,
        N: 47.42382200488397,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Oberrohrdorf',
        PLZ: 5452,
        Zusatzziffer: 0,
        Gemeindename: 'Oberrohrdorf',
        'BFS-Nr': 4037,
        Kantonskürzel: 'AG',
        E: 8.32198640688661,
        N: 47.422733915045605,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Nussbaumen AG',
        PLZ: 5415,
        Zusatzziffer: 0,
        Gemeindename: 'Obersiggenthal',
        'BFS-Nr': 4038,
        Kantonskürzel: 'AG',
        E: 8.289518476562604,
        N: 47.4891270402582,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Hertenstein AG',
        PLZ: 5415,
        Zusatzziffer: 1,
        Gemeindename: 'Obersiggenthal',
        'BFS-Nr': 4038,
        Kantonskürzel: 'AG',
        E: 8.312729817548732,
        N: 47.48750170880415,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Rieden AG',
        PLZ: 5415,
        Zusatzziffer: 2,
        Gemeindename: 'Obersiggenthal',
        'BFS-Nr': 4038,
        Kantonskürzel: 'AG',
        E: 8.304051327524338,
        N: 47.483964644448484,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Kirchdorf AG',
        PLZ: 5416,
        Zusatzziffer: 0,
        Gemeindename: 'Obersiggenthal',
        'BFS-Nr': 4038,
        Kantonskürzel: 'AG',
        E: 8.2740996856474,
        N: 47.496890051573494,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Remetschwil',
        PLZ: 5453,
        Zusatzziffer: 0,
        Gemeindename: 'Remetschwil',
        'BFS-Nr': 4039,
        Kantonskürzel: 'AG',
        E: 8.326704061465595,
        N: 47.408317718780744,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Spreitenbach',
        PLZ: 8957,
        Zusatzziffer: 0,
        Gemeindename: 'Spreitenbach',
        'BFS-Nr': 4040,
        Kantonskürzel: 'AG',
        E: 8.365600590056742,
        N: 47.416854059094376,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Stetten AG',
        PLZ: 5608,
        Zusatzziffer: 0,
        Gemeindename: 'Stetten (AG)',
        'BFS-Nr': 4041,
        Kantonskürzel: 'AG',
        E: 8.305947838785896,
        N: 47.3995745998586,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Turgi',
        PLZ: 5300,
        Zusatzziffer: 0,
        Gemeindename: 'Turgi',
        'BFS-Nr': 4042,
        Kantonskürzel: 'AG',
        E: 8.253695283885394,
        N: 47.49072826137482,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Siggenthal Station',
        PLZ: 5301,
        Zusatzziffer: 0,
        Gemeindename: 'Untersiggenthal',
        'BFS-Nr': 4044,
        Kantonskürzel: 'AG',
        E: 8.23796625374396,
        N: 47.51522516954077,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Untersiggenthal',
        PLZ: 5417,
        Zusatzziffer: 0,
        Gemeindename: 'Untersiggenthal',
        'BFS-Nr': 4044,
        Kantonskürzel: 'AG',
        E: 8.259889012057913,
        N: 47.50528989151746,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Wettingen',
        PLZ: 5430,
        Zusatzziffer: 0,
        Gemeindename: 'Wettingen',
        'BFS-Nr': 4045,
        Kantonskürzel: 'AG',
        E: 8.33046577587193,
        N: 47.46781163124184,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Wohlenschwil',
        PLZ: 5512,
        Zusatzziffer: 0,
        Gemeindename: 'Wohlenschwil',
        'BFS-Nr': 4046,
        Kantonskürzel: 'AG',
        E: 8.256708640719525,
        N: 47.41238658687534,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Würenlingen',
        PLZ: 5303,
        Zusatzziffer: 0,
        Gemeindename: 'Würenlingen',
        'BFS-Nr': 4047,
        Kantonskürzel: 'AG',
        E: 8.258632311972468,
        N: 47.53023543539085,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Würenlos',
        PLZ: 5436,
        Zusatzziffer: 0,
        Gemeindename: 'Würenlos',
        'BFS-Nr': 4048,
        Kantonskürzel: 'AG',
        E: 8.364873281481309,
        N: 47.442787316282,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Kloster Fahr',
        PLZ: 8109,
        Zusatzziffer: 0,
        Gemeindename: 'Würenlos',
        'BFS-Nr': 4048,
        Kantonskürzel: 'AG',
        E: 8.439065847735677,
        N: 47.40827565601708,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Ehrendingen',
        PLZ: 5420,
        Zusatzziffer: 0,
        Gemeindename: 'Ehrendingen',
        'BFS-Nr': 4049,
        Kantonskürzel: 'AG',
        E: 8.342137171802355,
        N: 47.49731557857811,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Arni AG',
        PLZ: 8905,
        Zusatzziffer: 3,
        Gemeindename: 'Arni (AG)',
        'BFS-Nr': 4061,
        Kantonskürzel: 'AG',
        E: 8.42030923996525,
        N: 47.31854677183242,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Berikon',
        PLZ: 8965,
        Zusatzziffer: 0,
        Gemeindename: 'Berikon',
        'BFS-Nr': 4062,
        Kantonskürzel: 'AG',
        E: 8.372989973577686,
        N: 47.34980445196718,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Bremgarten AG',
        PLZ: 5620,
        Zusatzziffer: 0,
        Gemeindename: 'Bremgarten (AG)',
        'BFS-Nr': 4063,
        Kantonskürzel: 'AG',
        E: 8.340249183615057,
        N: 47.35185760693883,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Hermetschwil-Staffeln',
        PLZ: 5626,
        Zusatzziffer: 0,
        Gemeindename: 'Bremgarten (AG)',
        'BFS-Nr': 4063,
        Kantonskürzel: 'AG',
        E: 8.341538048594813,
        N: 47.330305090716266,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Büttikon AG',
        PLZ: 5619,
        Zusatzziffer: 0,
        Gemeindename: 'Büttikon',
        'BFS-Nr': 4064,
        Kantonskürzel: 'AG',
        E: 8.26947109851006,
        N: 47.328559189550404,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Dottikon',
        PLZ: 5605,
        Zusatzziffer: 0,
        Gemeindename: 'Dottikon',
        'BFS-Nr': 4065,
        Kantonskürzel: 'AG',
        E: 8.241003011814197,
        N: 47.38366378516513,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Eggenwil',
        PLZ: 5445,
        Zusatzziffer: 0,
        Gemeindename: 'Eggenwil',
        'BFS-Nr': 4066,
        Kantonskürzel: 'AG',
        E: 8.33915843187933,
        N: 47.36716737008623,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Fischbach-Göslikon',
        PLZ: 5525,
        Zusatzziffer: 0,
        Gemeindename: 'Fischbach-Göslikon',
        'BFS-Nr': 4067,
        Kantonskürzel: 'AG',
        E: 8.311100253895166,
        N: 47.36911993865972,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Hägglingen',
        PLZ: 5607,
        Zusatzziffer: 0,
        Gemeindename: 'Hägglingen',
        'BFS-Nr': 4068,
        Kantonskürzel: 'AG',
        E: 8.254076353858885,
        N: 47.387520428712556,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Jonen',
        PLZ: 8916,
        Zusatzziffer: 0,
        Gemeindename: 'Jonen',
        'BFS-Nr': 4071,
        Kantonskürzel: 'AG',
        E: 8.395477921854784,
        N: 47.29580040862139,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Niederwil AG',
        PLZ: 5524,
        Zusatzziffer: 0,
        Gemeindename: 'Niederwil (AG)',
        'BFS-Nr': 4072,
        Kantonskürzel: 'AG',
        E: 8.294351915473245,
        N: 47.37874265737798,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Nesselnbach',
        PLZ: 5524,
        Zusatzziffer: 2,
        Gemeindename: 'Niederwil (AG)',
        'BFS-Nr': 4072,
        Kantonskürzel: 'AG',
        E: 8.29570550272332,
        N: 47.390365223722355,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Oberlunkhofen',
        PLZ: 8917,
        Zusatzziffer: 0,
        Gemeindename: 'Oberlunkhofen',
        'BFS-Nr': 4073,
        Kantonskürzel: 'AG',
        E: 8.38996633989805,
        N: 47.31240278720281,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Oberwil-Lieli',
        PLZ: 8966,
        Zusatzziffer: 0,
        Gemeindename: 'Oberwil-Lieli',
        'BFS-Nr': 4074,
        Kantonskürzel: 'AG',
        E: 8.385604554230742,
        N: 47.335718555575966,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Rudolfstetten',
        PLZ: 8964,
        Zusatzziffer: 0,
        Gemeindename: 'Rudolfstetten-Friedlisberg',
        'BFS-Nr': 4075,
        Kantonskürzel: 'AG',
        E: 8.37918618216318,
        N: 47.36829136414204,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Sarmenstorf',
        PLZ: 5614,
        Zusatzziffer: 0,
        Gemeindename: 'Sarmenstorf',
        'BFS-Nr': 4076,
        Kantonskürzel: 'AG',
        E: 8.251926898118318,
        N: 47.313347212080224,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Tägerig',
        PLZ: 5522,
        Zusatzziffer: 0,
        Gemeindename: 'Tägerig',
        'BFS-Nr': 4077,
        Kantonskürzel: 'AG',
        E: 8.281379343901449,
        N: 47.402430099343,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Uezwil',
        PLZ: 5619,
        Zusatzziffer: 2,
        Gemeindename: 'Uezwil',
        'BFS-Nr': 4078,
        Kantonskürzel: 'AG',
        E: 8.275508501790057,
        N: 47.31552187748691,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Unterlunkhofen',
        PLZ: 8918,
        Zusatzziffer: 0,
        Gemeindename: 'Unterlunkhofen',
        'BFS-Nr': 4079,
        Kantonskürzel: 'AG',
        E: 8.380813880569631,
        N: 47.32126972233873,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Villmergen',
        PLZ: 5612,
        Zusatzziffer: 0,
        Gemeindename: 'Villmergen',
        'BFS-Nr': 4080,
        Kantonskürzel: 'AG',
        E: 8.243859161870212,
        N: 47.34573341765583,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Hilfikon',
        PLZ: 5613,
        Zusatzziffer: 0,
        Gemeindename: 'Villmergen',
        'BFS-Nr': 4080,
        Kantonskürzel: 'AG',
        E: 8.250901159749027,
        N: 47.33323348064915,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Widen',
        PLZ: 8967,
        Zusatzziffer: 0,
        Gemeindename: 'Widen',
        'BFS-Nr': 4081,
        Kantonskürzel: 'AG',
        E: 8.35766836426026,
        N: 47.36767820915273,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Wohlen AG',
        PLZ: 5610,
        Zusatzziffer: 0,
        Gemeindename: 'Wohlen (AG)',
        'BFS-Nr': 4082,
        Kantonskürzel: 'AG',
        E: 8.278192139493799,
        N: 47.351195340409326,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Anglikon',
        PLZ: 5611,
        Zusatzziffer: 0,
        Gemeindename: 'Wohlen (AG)',
        'BFS-Nr': 4082,
        Kantonskürzel: 'AG',
        E: 8.262798883398121,
        N: 47.36536141118902,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Zufikon',
        PLZ: 5621,
        Zusatzziffer: 0,
        Gemeindename: 'Zufikon',
        'BFS-Nr': 4083,
        Kantonskürzel: 'AG',
        E: 8.360908045146695,
        N: 47.34452305593265,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Islisberg',
        PLZ: 8905,
        Zusatzziffer: 2,
        Gemeindename: 'Islisberg',
        'BFS-Nr': 4084,
        Kantonskürzel: 'AG',
        E: 8.439267199532804,
        N: 47.322526841399394,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Auenstein',
        PLZ: 5105,
        Zusatzziffer: 0,
        Gemeindename: 'Auenstein',
        'BFS-Nr': 4091,
        Kantonskürzel: 'AG',
        E: 8.140704947163327,
        N: 47.41599375711504,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Birr',
        PLZ: 5242,
        Zusatzziffer: 1,
        Gemeindename: 'Birr',
        'BFS-Nr': 4092,
        Kantonskürzel: 'AG',
        E: 8.201220856306582,
        N: 47.43688881835543,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Birrhard',
        PLZ: 5244,
        Zusatzziffer: 0,
        Gemeindename: 'Birrhard',
        'BFS-Nr': 4093,
        Kantonskürzel: 'AG',
        E: 8.245620455068186,
        N: 47.43417579417934,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Schinznach Bad',
        PLZ: 5116,
        Zusatzziffer: 0,
        Gemeindename: 'Brugg',
        'BFS-Nr': 4095,
        Kantonskürzel: 'AG',
        E: 8.168717132078998,
        N: 47.45384434216058,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Brugg AG',
        PLZ: 5200,
        Zusatzziffer: 0,
        Gemeindename: 'Brugg',
        'BFS-Nr': 4095,
        Kantonskürzel: 'AG',
        E: 8.206855727883811,
        N: 47.48510390470239,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Umiken',
        PLZ: 5222,
        Zusatzziffer: 0,
        Gemeindename: 'Brugg',
        'BFS-Nr': 4095,
        Kantonskürzel: 'AG',
        E: 8.189749220630521,
        N: 47.48204575895972,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Habsburg',
        PLZ: 5245,
        Zusatzziffer: 0,
        Gemeindename: 'Habsburg',
        'BFS-Nr': 4099,
        Kantonskürzel: 'AG',
        E: 8.185986808457306,
        N: 47.461426338701926,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Hausen AG',
        PLZ: 5212,
        Zusatzziffer: 0,
        Gemeindename: 'Hausen (AG)',
        'BFS-Nr': 4100,
        Kantonskürzel: 'AG',
        E: 8.211551221921365,
        N: 47.462100864960895,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Lupfig',
        PLZ: 5242,
        Zusatzziffer: 2,
        Gemeindename: 'Lupfig',
        'BFS-Nr': 4104,
        Kantonskürzel: 'AG',
        E: 8.206557423945954,
        N: 47.4394559009842,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Scherz',
        PLZ: 5246,
        Zusatzziffer: 0,
        Gemeindename: 'Lupfig',
        'BFS-Nr': 4104,
        Kantonskürzel: 'AG',
        E: 8.18419684841963,
        N: 47.446886833828664,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Mandach',
        PLZ: 5318,
        Zusatzziffer: 0,
        Gemeindename: 'Mandach',
        'BFS-Nr': 4105,
        Kantonskürzel: 'AG',
        E: 8.186152105927746,
        N: 47.54679990563589,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Mönthal',
        PLZ: 5237,
        Zusatzziffer: 0,
        Gemeindename: 'Mönthal',
        'BFS-Nr': 4106,
        Kantonskürzel: 'AG',
        E: 8.143331707257639,
        N: 47.51788471000419,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Mülligen',
        PLZ: 5243,
        Zusatzziffer: 0,
        Gemeindename: 'Mülligen',
        'BFS-Nr': 4107,
        Kantonskürzel: 'AG',
        E: 8.239424231763895,
        N: 47.45783389290502,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Remigen',
        PLZ: 5236,
        Zusatzziffer: 0,
        Gemeindename: 'Remigen',
        'BFS-Nr': 4110,
        Kantonskürzel: 'AG',
        E: 8.186918051662989,
        N: 47.516052847506145,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Riniken',
        PLZ: 5223,
        Zusatzziffer: 0,
        Gemeindename: 'Riniken',
        'BFS-Nr': 4111,
        Kantonskürzel: 'AG',
        E: 8.189964693785877,
        N: 47.49293628650045,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Rüfenach AG',
        PLZ: 5235,
        Zusatzziffer: 0,
        Gemeindename: 'Rüfenach',
        'BFS-Nr': 4112,
        Kantonskürzel: 'AG',
        E: 8.205058536699495,
        N: 47.50899804695212,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Thalheim AG',
        PLZ: 5112,
        Zusatzziffer: 0,
        Gemeindename: 'Thalheim (AG)',
        'BFS-Nr': 4117,
        Kantonskürzel: 'AG',
        E: 8.102820750677916,
        N: 47.435993578062984,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Veltheim AG',
        PLZ: 5106,
        Zusatzziffer: 0,
        Gemeindename: 'Veltheim (AG)',
        'BFS-Nr': 4120,
        Kantonskürzel: 'AG',
        E: 8.14731407663177,
        N: 47.43829492234106,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Stilli',
        PLZ: 5233,
        Zusatzziffer: 0,
        Gemeindename: 'Villigen',
        'BFS-Nr': 4121,
        Kantonskürzel: 'AG',
        E: 8.23149428632739,
        N: 47.51649183927331,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Villigen',
        PLZ: 5234,
        Zusatzziffer: 0,
        Gemeindename: 'Villigen',
        'BFS-Nr': 4121,
        Kantonskürzel: 'AG',
        E: 8.212994781045236,
        N: 47.52713130043819,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Villnachern',
        PLZ: 5213,
        Zusatzziffer: 0,
        Gemeindename: 'Villnachern',
        'BFS-Nr': 4122,
        Kantonskürzel: 'AG',
        E: 8.156566646063329,
        N: 47.46935481449758,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Windisch',
        PLZ: 5210,
        Zusatzziffer: 0,
        Gemeindename: 'Windisch',
        'BFS-Nr': 4123,
        Kantonskürzel: 'AG',
        E: 8.213338533864652,
        N: 47.47571374425734,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Bözberg',
        PLZ: 5225,
        Zusatzziffer: 1,
        Gemeindename: 'Bözberg',
        'BFS-Nr': 4124,
        Kantonskürzel: 'AG',
        E: 8.161412825244234,
        N: 47.49127256560385,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Schinznach Dorf',
        PLZ: 5107,
        Zusatzziffer: 0,
        Gemeindename: 'Schinznach',
        'BFS-Nr': 4125,
        Kantonskürzel: 'AG',
        E: 8.142555727593473,
        N: 47.4463749434959,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Oberflachs',
        PLZ: 5108,
        Zusatzziffer: 0,
        Gemeindename: 'Schinznach',
        'BFS-Nr': 4125,
        Kantonskürzel: 'AG',
        E: 8.130559364275136,
        N: 47.44088373281186,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Beinwil am See',
        PLZ: 5712,
        Zusatzziffer: 0,
        Gemeindename: 'Beinwil am See',
        'BFS-Nr': 4131,
        Kantonskürzel: 'AG',
        E: 8.208411287578402,
        N: 47.2649907221911,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Birrwil',
        PLZ: 5708,
        Zusatzziffer: 0,
        Gemeindename: 'Birrwil',
        'BFS-Nr': 4132,
        Kantonskürzel: 'AG',
        E: 8.197733077564294,
        N: 47.29015198678358,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Burg AG',
        PLZ: 5736,
        Zusatzziffer: 0,
        Gemeindename: 'Burg (AG)',
        'BFS-Nr': 4133,
        Kantonskürzel: 'AG',
        E: 8.177988247532358,
        N: 47.233893562023695,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Dürrenäsch',
        PLZ: 5724,
        Zusatzziffer: 0,
        Gemeindename: 'Dürrenäsch',
        'BFS-Nr': 4134,
        Kantonskürzel: 'AG',
        E: 8.15747432490808,
        N: 47.32131722233586,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Gontenschwil',
        PLZ: 5728,
        Zusatzziffer: 0,
        Gemeindename: 'Gontenschwil',
        'BFS-Nr': 4135,
        Kantonskürzel: 'AG',
        E: 8.145178789960822,
        N: 47.271772483336626,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Holziken',
        PLZ: 5043,
        Zusatzziffer: 0,
        Gemeindename: 'Holziken',
        'BFS-Nr': 4136,
        Kantonskürzel: 'AG',
        E: 8.034309025721127,
        N: 47.31951315237186,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Leimbach AG',
        PLZ: 5733,
        Zusatzziffer: 0,
        Gemeindename: 'Leimbach (AG)',
        'BFS-Nr': 4137,
        Kantonskürzel: 'AG',
        E: 8.170063459648773,
        N: 47.274869563829064,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Leutwil',
        PLZ: 5725,
        Zusatzziffer: 0,
        Gemeindename: 'Leutwil',
        'BFS-Nr': 4138,
        Kantonskürzel: 'AG',
        E: 8.17401463661694,
        N: 47.309475460179556,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Menziken',
        PLZ: 5737,
        Zusatzziffer: 0,
        Gemeindename: 'Menziken',
        'BFS-Nr': 4139,
        Kantonskürzel: 'AG',
        E: 8.186373578616314,
        N: 47.2395127213925,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Oberkulm',
        PLZ: 5727,
        Zusatzziffer: 0,
        Gemeindename: 'Oberkulm',
        'BFS-Nr': 4140,
        Kantonskürzel: 'AG',
        E: 8.124073350493546,
        N: 47.2989763693735,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Reinach AG',
        PLZ: 5734,
        Zusatzziffer: 0,
        Gemeindename: 'Reinach (AG)',
        'BFS-Nr': 4141,
        Kantonskürzel: 'AG',
        E: 8.188439048013898,
        N: 47.2562198985712,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Schlossrued',
        PLZ: 5044,
        Zusatzziffer: 0,
        Gemeindename: 'Schlossrued',
        'BFS-Nr': 4142,
        Kantonskürzel: 'AG',
        E: 8.089061567831983,
        N: 47.292732160340044,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Schmiedrued',
        PLZ: 5046,
        Zusatzziffer: 1,
        Gemeindename: 'Schmiedrued',
        'BFS-Nr': 4143,
        Kantonskürzel: 'AG',
        E: 8.108591882919722,
        N: 47.270980304295016,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Walde AG',
        PLZ: 5046,
        Zusatzziffer: 2,
        Gemeindename: 'Schmiedrued',
        'BFS-Nr': 4143,
        Kantonskürzel: 'AG',
        E: 8.112156888521463,
        N: 47.25879914070584,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Schöftland',
        PLZ: 5040,
        Zusatzziffer: 0,
        Gemeindename: 'Schöftland',
        'BFS-Nr': 4144,
        Kantonskürzel: 'AG',
        E: 8.050659835850148,
        N: 47.30480595963113,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Teufenthal AG',
        PLZ: 5723,
        Zusatzziffer: 0,
        Gemeindename: 'Teufenthal (AG)',
        'BFS-Nr': 4145,
        Kantonskürzel: 'AG',
        E: 8.118380060458358,
        N: 47.327812435323274,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Unterkulm',
        PLZ: 5726,
        Zusatzziffer: 0,
        Gemeindename: 'Unterkulm',
        'BFS-Nr': 4146,
        Kantonskürzel: 'AG',
        E: 8.115530428755383,
        N: 47.31050072675883,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Zetzwil',
        PLZ: 5732,
        Zusatzziffer: 0,
        Gemeindename: 'Zetzwil',
        'BFS-Nr': 4147,
        Kantonskürzel: 'AG',
        E: 8.150961865239582,
        N: 47.288243993945756,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Eiken',
        PLZ: 5074,
        Zusatzziffer: 0,
        Gemeindename: 'Eiken',
        'BFS-Nr': 4161,
        Kantonskürzel: 'AG',
        E: 7.9890138313911105,
        N: 47.53196448008205,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Frick',
        PLZ: 5070,
        Zusatzziffer: 0,
        Gemeindename: 'Frick',
        'BFS-Nr': 4163,
        Kantonskürzel: 'AG',
        E: 8.022433734143558,
        N: 47.504423026435006,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Gansingen',
        PLZ: 5272,
        Zusatzziffer: 0,
        Gemeindename: 'Gansingen',
        'BFS-Nr': 4164,
        Kantonskürzel: 'AG',
        E: 8.140018463125449,
        N: 47.54288926812418,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Gipf-Oberfrick',
        PLZ: 5073,
        Zusatzziffer: 0,
        Gemeindename: 'Gipf-Oberfrick',
        'BFS-Nr': 4165,
        Kantonskürzel: 'AG',
        E: 8.00339435570854,
        N: 47.497275995848966,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Herznach',
        PLZ: 5027,
        Zusatzziffer: 0,
        Gemeindename: 'Herznach',
        'BFS-Nr': 4166,
        Kantonskürzel: 'AG',
        E: 8.047513847969308,
        N: 47.471485715197716,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Kaisten',
        PLZ: 5082,
        Zusatzziffer: 0,
        Gemeindename: 'Kaisten',
        'BFS-Nr': 4169,
        Kantonskürzel: 'AG',
        E: 8.04589601973965,
        N: 47.540124042937606,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Ittenthal',
        PLZ: 5083,
        Zusatzziffer: 0,
        Gemeindename: 'Kaisten',
        'BFS-Nr': 4169,
        Kantonskürzel: 'AG',
        E: 8.058444103805522,
        N: 47.51755611420996,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Laufenburg',
        PLZ: 5080,
        Zusatzziffer: 0,
        Gemeindename: 'Laufenburg',
        'BFS-Nr': 4170,
        Kantonskürzel: 'AG',
        E: 8.061498194693455,
        N: 47.56198980779183,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Rheinsulz',
        PLZ: 5084,
        Zusatzziffer: 0,
        Gemeindename: 'Laufenburg',
        'BFS-Nr': 4170,
        Kantonskürzel: 'AG',
        E: 8.090022939271366,
        N: 47.55571265204428,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Sulz AG',
        PLZ: 5085,
        Zusatzziffer: 0,
        Gemeindename: 'Laufenburg',
        'BFS-Nr': 4170,
        Kantonskürzel: 'AG',
        E: 8.097566594944173,
        N: 47.53431356629067,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Münchwilen AG',
        PLZ: 4333,
        Zusatzziffer: 0,
        Gemeindename: 'Münchwilen (AG)',
        'BFS-Nr': 4172,
        Kantonskürzel: 'AG',
        E: 7.963330725293145,
        N: 47.53688298207888,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Oberhof',
        PLZ: 5062,
        Zusatzziffer: 0,
        Gemeindename: 'Oberhof',
        'BFS-Nr': 4173,
        Kantonskürzel: 'AG',
        E: 8.002737525542276,
        N: 47.448742101218606,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Oeschgen',
        PLZ: 5072,
        Zusatzziffer: 0,
        Gemeindename: 'Oeschgen',
        'BFS-Nr': 4175,
        Kantonskürzel: 'AG',
        E: 8.019002829724894,
        N: 47.52307328644683,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Schwaderloch',
        PLZ: 5326,
        Zusatzziffer: 0,
        Gemeindename: 'Schwaderloch',
        'BFS-Nr': 4176,
        Kantonskürzel: 'AG',
        E: 8.144454527982868,
        N: 47.58564890476332,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Sisseln AG',
        PLZ: 4334,
        Zusatzziffer: 0,
        Gemeindename: 'Sisseln',
        'BFS-Nr': 4177,
        Kantonskürzel: 'AG',
        E: 7.985353307893484,
        N: 47.55216329105581,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Ueken',
        PLZ: 5028,
        Zusatzziffer: 0,
        Gemeindename: 'Ueken',
        'BFS-Nr': 4179,
        Kantonskürzel: 'AG',
        E: 8.043505558542781,
        N: 47.48630030546968,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Wittnau',
        PLZ: 5064,
        Zusatzziffer: 0,
        Gemeindename: 'Wittnau',
        'BFS-Nr': 4181,
        Kantonskürzel: 'AG',
        E: 7.974821040744316,
        N: 47.48084096988206,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Wölflinswil',
        PLZ: 5063,
        Zusatzziffer: 0,
        Gemeindename: 'Wölflinswil',
        'BFS-Nr': 4182,
        Kantonskürzel: 'AG',
        E: 7.995651560844302,
        N: 47.46184557681718,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Zeihen',
        PLZ: 5079,
        Zusatzziffer: 0,
        Gemeindename: 'Zeihen',
        'BFS-Nr': 4183,
        Kantonskürzel: 'AG',
        E: 8.0876168549146,
        N: 47.47533871516407,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Oberhofen AG',
        PLZ: 5273,
        Zusatzziffer: 0,
        Gemeindename: 'Mettauertal',
        'BFS-Nr': 4184,
        Kantonskürzel: 'AG',
        E: 8.133360305490969,
        N: 47.55452713004124,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Mettau',
        PLZ: 5274,
        Zusatzziffer: 0,
        Gemeindename: 'Mettauertal',
        'BFS-Nr': 4184,
        Kantonskürzel: 'AG',
        E: 8.126201296501572,
        N: 47.56431631643989,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Etzgen',
        PLZ: 5275,
        Zusatzziffer: 0,
        Gemeindename: 'Mettauertal',
        'BFS-Nr': 4184,
        Kantonskürzel: 'AG',
        E: 8.10548880816992,
        N: 47.57339403447417,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Wil AG',
        PLZ: 5276,
        Zusatzziffer: 0,
        Gemeindename: 'Mettauertal',
        'BFS-Nr': 4184,
        Kantonskürzel: 'AG',
        E: 8.153038469591616,
        N: 47.56055009191399,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Hottwil',
        PLZ: 5277,
        Zusatzziffer: 0,
        Gemeindename: 'Mettauertal',
        'BFS-Nr': 4184,
        Kantonskürzel: 'AG',
        E: 8.161248646092117,
        N: 47.547844396889566,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Hornussen',
        PLZ: 5075,
        Zusatzziffer: 0,
        Gemeindename: 'Böztal',
        'BFS-Nr': 4185,
        Kantonskürzel: 'AG',
        E: 8.062964294923612,
        N: 47.49875904991701,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Bözen',
        PLZ: 5076,
        Zusatzziffer: 0,
        Gemeindename: 'Böztal',
        'BFS-Nr': 4185,
        Kantonskürzel: 'AG',
        E: 8.085269355593223,
        N: 47.49547840137686,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Elfingen',
        PLZ: 5077,
        Zusatzziffer: 0,
        Gemeindename: 'Böztal',
        'BFS-Nr': 4185,
        Kantonskürzel: 'AG',
        E: 8.098860314839524,
        N: 47.5068070651358,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Effingen',
        PLZ: 5078,
        Zusatzziffer: 0,
        Gemeindename: 'Böztal',
        'BFS-Nr': 4185,
        Kantonskürzel: 'AG',
        E: 8.103884318410621,
        N: 47.488825229441034,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Ammerswil AG',
        PLZ: 5600,
        Zusatzziffer: 3,
        Gemeindename: 'Ammerswil',
        'BFS-Nr': 4191,
        Kantonskürzel: 'AG',
        E: 8.209198868087327,
        N: 47.37132463068526,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Boniswil',
        PLZ: 5706,
        Zusatzziffer: 0,
        Gemeindename: 'Boniswil',
        'BFS-Nr': 4192,
        Kantonskürzel: 'AG',
        E: 8.184433869860433,
        N: 47.31519515856891,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Brunegg',
        PLZ: 5505,
        Zusatzziffer: 0,
        Gemeindename: 'Brunegg',
        'BFS-Nr': 4193,
        Kantonskürzel: 'AG',
        E: 8.216922443033104,
        N: 47.4189481887521,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Dintikon',
        PLZ: 5606,
        Zusatzziffer: 0,
        Gemeindename: 'Dintikon',
        'BFS-Nr': 4194,
        Kantonskürzel: 'AG',
        E: 8.226827887344351,
        N: 47.361860552743444,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Egliswil',
        PLZ: 5704,
        Zusatzziffer: 0,
        Gemeindename: 'Egliswil',
        'BFS-Nr': 4195,
        Kantonskürzel: 'AG',
        E: 8.185632875789462,
        N: 47.34974018002163,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Fahrwangen',
        PLZ: 5615,
        Zusatzziffer: 0,
        Gemeindename: 'Fahrwangen',
        'BFS-Nr': 4196,
        Kantonskürzel: 'AG',
        E: 8.242276584170943,
        N: 47.29484522241931,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Hallwil',
        PLZ: 5705,
        Zusatzziffer: 0,
        Gemeindename: 'Hallwil',
        'BFS-Nr': 4197,
        Kantonskürzel: 'AG',
        E: 8.175924624051376,
        N: 47.32868766887901,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Hendschiken',
        PLZ: 5604,
        Zusatzziffer: 0,
        Gemeindename: 'Hendschiken',
        'BFS-Nr': 4198,
        Kantonskürzel: 'AG',
        E: 8.216985886017518,
        N: 47.38592941022882,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Holderbank AG',
        PLZ: 5113,
        Zusatzziffer: 0,
        Gemeindename: 'Holderbank (AG)',
        'BFS-Nr': 4199,
        Kantonskürzel: 'AG',
        E: 8.170666918996881,
        N: 47.430938388799184,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Hunzenschwil',
        PLZ: 5502,
        Zusatzziffer: 0,
        Gemeindename: 'Hunzenschwil',
        'BFS-Nr': 4200,
        Kantonskürzel: 'AG',
        E: 8.12304367167965,
        N: 47.38400189508897,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Lenzburg',
        PLZ: 5600,
        Zusatzziffer: 0,
        Gemeindename: 'Lenzburg',
        'BFS-Nr': 4201,
        Kantonskürzel: 'AG',
        E: 8.179301759488173,
        N: 47.38807292315821,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Meisterschwanden',
        PLZ: 5616,
        Zusatzziffer: 0,
        Gemeindename: 'Meisterschwanden',
        'BFS-Nr': 4202,
        Kantonskürzel: 'AG',
        E: 8.234450743421144,
        N: 47.29513586293342,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Tennwil',
        PLZ: 5617,
        Zusatzziffer: 0,
        Gemeindename: 'Meisterschwanden',
        'BFS-Nr': 4202,
        Kantonskürzel: 'AG',
        E: 8.220873574988518,
        N: 47.306751041759384,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Wildegg',
        PLZ: 5103,
        Zusatzziffer: 0,
        Gemeindename: 'Möriken-Wildegg',
        'BFS-Nr': 4203,
        Kantonskürzel: 'AG',
        E: 8.16514493148852,
        N: 47.415879534704146,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Möriken AG',
        PLZ: 5103,
        Zusatzziffer: 2,
        Gemeindename: 'Möriken-Wildegg',
        'BFS-Nr': 4203,
        Kantonskürzel: 'AG',
        E: 8.190972224533558,
        N: 47.416473818495454,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Niederlenz',
        PLZ: 5702,
        Zusatzziffer: 0,
        Gemeindename: 'Niederlenz',
        'BFS-Nr': 4204,
        Kantonskürzel: 'AG',
        E: 8.17035576908491,
        N: 47.40269979685488,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Othmarsingen',
        PLZ: 5504,
        Zusatzziffer: 0,
        Gemeindename: 'Othmarsingen',
        'BFS-Nr': 4205,
        Kantonskürzel: 'AG',
        E: 8.210146733387171,
        N: 47.402113814219234,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Rupperswil',
        PLZ: 5102,
        Zusatzziffer: 0,
        Gemeindename: 'Rupperswil',
        'BFS-Nr': 4206,
        Kantonskürzel: 'AG',
        E: 8.134577042985786,
        N: 47.40276627424918,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Schafisheim',
        PLZ: 5503,
        Zusatzziffer: 0,
        Gemeindename: 'Schafisheim',
        'BFS-Nr': 4207,
        Kantonskürzel: 'AG',
        E: 8.142271340771174,
        N: 47.37818731878339,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Seengen',
        PLZ: 5707,
        Zusatzziffer: 0,
        Gemeindename: 'Seengen',
        'BFS-Nr': 4208,
        Kantonskürzel: 'AG',
        E: 8.203056471955414,
        N: 47.32639539328031,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Seon',
        PLZ: 5703,
        Zusatzziffer: 0,
        Gemeindename: 'Seon',
        'BFS-Nr': 4209,
        Kantonskürzel: 'AG',
        E: 8.156748986053818,
        N: 47.346188920433,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Staufen',
        PLZ: 5603,
        Zusatzziffer: 0,
        Gemeindename: 'Staufen',
        'BFS-Nr': 4210,
        Kantonskürzel: 'AG',
        E: 8.162874049098832,
        N: 47.38074694398728,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Abtwil AG',
        PLZ: 5646,
        Zusatzziffer: 0,
        Gemeindename: 'Abtwil',
        'BFS-Nr': 4221,
        Kantonskürzel: 'AG',
        E: 8.355054716646293,
        N: 47.1750021332656,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Aristau',
        PLZ: 5628,
        Zusatzziffer: 0,
        Gemeindename: 'Aristau',
        'BFS-Nr': 4222,
        Kantonskürzel: 'AG',
        E: 8.36096289013706,
        N: 47.28693886444564,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Auw',
        PLZ: 5644,
        Zusatzziffer: 0,
        Gemeindename: 'Auw',
        'BFS-Nr': 4223,
        Kantonskürzel: 'AG',
        E: 8.367053701828638,
        N: 47.2100917703786,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Beinwil (Freiamt)',
        PLZ: 5637,
        Zusatzziffer: 0,
        Gemeindename: 'Beinwil (Freiamt)',
        'BFS-Nr': 4224,
        Kantonskürzel: 'AG',
        E: 8.346439852870356,
        N: 47.230237259911895,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Besenbüren',
        PLZ: 5627,
        Zusatzziffer: 0,
        Gemeindename: 'Besenbüren',
        'BFS-Nr': 4226,
        Kantonskürzel: 'AG',
        E: 8.344054708110148,
        N: 47.31197794925375,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Bettwil',
        PLZ: 5618,
        Zusatzziffer: 0,
        Gemeindename: 'Bettwil',
        'BFS-Nr': 4227,
        Kantonskürzel: 'AG',
        E: 8.266529016256435,
        N: 47.29065579424548,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Boswil',
        PLZ: 5623,
        Zusatzziffer: 0,
        Gemeindename: 'Boswil',
        'BFS-Nr': 4228,
        Kantonskürzel: 'AG',
        E: 8.308824072980928,
        N: 47.303812771555734,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Bünzen',
        PLZ: 5624,
        Zusatzziffer: 0,
        Gemeindename: 'Bünzen',
        'BFS-Nr': 4229,
        Kantonskürzel: 'AG',
        E: 8.326619408034299,
        N: 47.309508621724056,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Waldhäusern AG',
        PLZ: 5624,
        Zusatzziffer: 2,
        Gemeindename: 'Bünzen',
        'BFS-Nr': 4229,
        Kantonskürzel: 'AG',
        E: 8.315166492403186,
        N: 47.32687415233385,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Buttwil',
        PLZ: 5632,
        Zusatzziffer: 0,
        Gemeindename: 'Buttwil',
        'BFS-Nr': 4230,
        Kantonskürzel: 'AG',
        E: 8.313805832870214,
        N: 47.2695334421581,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Dietwil',
        PLZ: 6042,
        Zusatzziffer: 0,
        Gemeindename: 'Dietwil',
        'BFS-Nr': 4231,
        Kantonskürzel: 'AG',
        E: 8.392971262904757,
        N: 47.147645067654324,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Geltwil',
        PLZ: 5637,
        Zusatzziffer: 2,
        Gemeindename: 'Geltwil',
        'BFS-Nr': 4232,
        Kantonskürzel: 'AG',
        E: 8.325986636342638,
        N: 47.24900562550425,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Kallern',
        PLZ: 5625,
        Zusatzziffer: 0,
        Gemeindename: 'Kallern',
        'BFS-Nr': 4233,
        Kantonskürzel: 'AG',
        E: 8.293308783092897,
        N: 47.313169500407255,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Merenschwand',
        PLZ: 5634,
        Zusatzziffer: 0,
        Gemeindename: 'Merenschwand',
        'BFS-Nr': 4234,
        Kantonskürzel: 'AG',
        E: 8.375762671581079,
        N: 47.26024147342946,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Benzenschwil',
        PLZ: 5636,
        Zusatzziffer: 0,
        Gemeindename: 'Merenschwand',
        'BFS-Nr': 4234,
        Kantonskürzel: 'AG',
        E: 8.363740992988106,
        N: 47.2464972269048,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Mühlau',
        PLZ: 5642,
        Zusatzziffer: 0,
        Gemeindename: 'Mühlau',
        'BFS-Nr': 4235,
        Kantonskürzel: 'AG',
        E: 8.390367690041312,
        N: 47.229960873210345,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Muri AG',
        PLZ: 5630,
        Zusatzziffer: 0,
        Gemeindename: 'Muri (AG)',
        'BFS-Nr': 4236,
        Kantonskürzel: 'AG',
        E: 8.338683389330196,
        N: 47.27233010799649,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Oberrüti',
        PLZ: 5647,
        Zusatzziffer: 0,
        Gemeindename: 'Oberrüti',
        'BFS-Nr': 4237,
        Kantonskürzel: 'AG',
        E: 8.396097857661227,
        N: 47.165664982928774,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Rottenschwil',
        PLZ: 8919,
        Zusatzziffer: 0,
        Gemeindename: 'Rottenschwil',
        'BFS-Nr': 4238,
        Kantonskürzel: 'AG',
        E: 8.36594686933129,
        N: 47.31442727285185,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Sins',
        PLZ: 5643,
        Zusatzziffer: 0,
        Gemeindename: 'Sins',
        'BFS-Nr': 4239,
        Kantonskürzel: 'AG',
        E: 8.346412352860403,
        N: 47.18723987269192,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Sins',
        PLZ: 5643,
        Zusatzziffer: 0,
        Gemeindename: 'Sins',
        'BFS-Nr': 4239,
        Kantonskürzel: 'AG',
        E: 8.395449551623106,
        N: 47.19463267767934,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Alikon',
        PLZ: 5643,
        Zusatzziffer: 2,
        Gemeindename: 'Sins',
        'BFS-Nr': 4239,
        Kantonskürzel: 'AG',
        E: 8.35976076195761,
        N: 47.19962531313028,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Meienberg',
        PLZ: 5643,
        Zusatzziffer: 3,
        Gemeindename: 'Sins',
        'BFS-Nr': 4239,
        Kantonskürzel: 'AG',
        E: 8.376244242462805,
        N: 47.19781785475612,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Aettenschwil',
        PLZ: 5645,
        Zusatzziffer: 0,
        Gemeindename: 'Sins',
        'BFS-Nr': 4239,
        Kantonskürzel: 'AG',
        E: 8.370037264263683,
        N: 47.18625121056938,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Fenkrieden',
        PLZ: 5645,
        Zusatzziffer: 2,
        Gemeindename: 'Sins',
        'BFS-Nr': 4239,
        Kantonskürzel: 'AG',
        E: 8.369741671349987,
        N: 47.16120179656907,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Waltenschwil',
        PLZ: 5622,
        Zusatzziffer: 0,
        Gemeindename: 'Waltenschwil',
        'BFS-Nr': 4240,
        Kantonskürzel: 'AG',
        E: 8.302093061911151,
        N: 47.33388708666461,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Hellikon',
        PLZ: 4316,
        Zusatzziffer: 0,
        Gemeindename: 'Hellikon',
        'BFS-Nr': 4251,
        Kantonskürzel: 'AG',
        E: 7.923151056274891,
        N: 47.50768269409659,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Kaiseraugst',
        PLZ: 4303,
        Zusatzziffer: 0,
        Gemeindename: 'Kaiseraugst',
        'BFS-Nr': 4252,
        Kantonskürzel: 'AG',
        E: 7.724933013424502,
        N: 47.539606680864026,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Magden',
        PLZ: 4312,
        Zusatzziffer: 0,
        Gemeindename: 'Magden',
        'BFS-Nr': 4253,
        Kantonskürzel: 'AG',
        E: 7.810885828195471,
        N: 47.53092419778373,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Möhlin',
        PLZ: 4313,
        Zusatzziffer: 0,
        Gemeindename: 'Möhlin',
        'BFS-Nr': 4254,
        Kantonskürzel: 'AG',
        E: 7.843404634262754,
        N: 47.56172136465373,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Mumpf',
        PLZ: 4322,
        Zusatzziffer: 0,
        Gemeindename: 'Mumpf',
        'BFS-Nr': 4255,
        Kantonskürzel: 'AG',
        E: 7.922779523714227,
        N: 47.54575358994543,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Obermumpf',
        PLZ: 4324,
        Zusatzziffer: 0,
        Gemeindename: 'Obermumpf',
        'BFS-Nr': 4256,
        Kantonskürzel: 'AG',
        E: 7.93355744296002,
        N: 47.53031020975719,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Olsberg',
        PLZ: 4305,
        Zusatzziffer: 0,
        Gemeindename: 'Olsberg',
        'BFS-Nr': 4257,
        Kantonskürzel: 'AG',
        E: 7.7833664336002295,
        N: 47.52085234623146,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Rheinfelden',
        PLZ: 4310,
        Zusatzziffer: 0,
        Gemeindename: 'Rheinfelden',
        'BFS-Nr': 4258,
        Kantonskürzel: 'AG',
        E: 7.792735752154904,
        N: 47.55415159597879,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Schupfart',
        PLZ: 4325,
        Zusatzziffer: 0,
        Gemeindename: 'Schupfart',
        'BFS-Nr': 4259,
        Kantonskürzel: 'AG',
        E: 7.9666264979358035,
        N: 47.51332341622507,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Stein AG',
        PLZ: 4332,
        Zusatzziffer: 0,
        Gemeindename: 'Stein (AG)',
        'BFS-Nr': 4260,
        Kantonskürzel: 'AG',
        E: 7.951358144676237,
        N: 47.54341483152194,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Wallbach',
        PLZ: 4323,
        Zusatzziffer: 0,
        Gemeindename: 'Wallbach',
        'BFS-Nr': 4261,
        Kantonskürzel: 'AG',
        E: 7.900412435413291,
        N: 47.56260260466957,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Wegenstetten',
        PLZ: 4317,
        Zusatzziffer: 0,
        Gemeindename: 'Wegenstetten',
        'BFS-Nr': 4262,
        Kantonskürzel: 'AG',
        E: 7.935330389431703,
        N: 47.49870444437942,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Zeiningen',
        PLZ: 4314,
        Zusatzziffer: 0,
        Gemeindename: 'Zeiningen',
        'BFS-Nr': 4263,
        Kantonskürzel: 'AG',
        E: 7.869955580152021,
        N: 47.542120213444846,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Zuzgen',
        PLZ: 4315,
        Zusatzziffer: 0,
        Gemeindename: 'Zuzgen',
        'BFS-Nr': 4264,
        Kantonskürzel: 'AG',
        E: 7.897047376676883,
        N: 47.52464180507376,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Aarburg',
        PLZ: 4663,
        Zusatzziffer: 0,
        Gemeindename: 'Aarburg',
        'BFS-Nr': 4271,
        Kantonskürzel: 'AG',
        E: 7.899275287858974,
        N: 47.32076462647689,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Bottenwil',
        PLZ: 4814,
        Zusatzziffer: 0,
        Gemeindename: 'Bottenwil',
        'BFS-Nr': 4273,
        Kantonskürzel: 'AG',
        E: 8.0050111442056,
        N: 47.28474758511171,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Brittnau',
        PLZ: 4805,
        Zusatzziffer: 0,
        Gemeindename: 'Brittnau',
        'BFS-Nr': 4274,
        Kantonskürzel: 'AG',
        E: 7.9483293778903,
        N: 47.258914943289355,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Kirchleerau',
        PLZ: 5054,
        Zusatzziffer: 1,
        Gemeindename: 'Kirchleerau',
        'BFS-Nr': 4275,
        Kantonskürzel: 'AG',
        E: 8.068249287705221,
        N: 47.276391364463095,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Kölliken',
        PLZ: 5742,
        Zusatzziffer: 0,
        Gemeindename: 'Kölliken',
        'BFS-Nr': 4276,
        Kantonskürzel: 'AG',
        E: 8.029380717644955,
        N: 47.33800511290128,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Moosleerau',
        PLZ: 5054,
        Zusatzziffer: 2,
        Gemeindename: 'Moosleerau',
        'BFS-Nr': 4277,
        Kantonskürzel: 'AG',
        E: 8.064579351029579,
        N: 47.26910483217632,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Murgenthal',
        PLZ: 4853,
        Zusatzziffer: 0,
        Gemeindename: 'Murgenthal',
        'BFS-Nr': 4279,
        Kantonskürzel: 'AG',
        E: 7.832270138592409,
        N: 47.26542146699334,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Riken AG',
        PLZ: 4853,
        Zusatzziffer: 2,
        Gemeindename: 'Murgenthal',
        'BFS-Nr': 4279,
        Kantonskürzel: 'AG',
        E: 7.849574423955969,
        N: 47.2762257822507,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Glashütten',
        PLZ: 4856,
        Zusatzziffer: 0,
        Gemeindename: 'Murgenthal',
        'BFS-Nr': 4279,
        Kantonskürzel: 'AG',
        E: 7.845495272047804,
        N: 47.25866392962019,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Oftringen',
        PLZ: 4665,
        Zusatzziffer: 0,
        Gemeindename: 'Oftringen',
        'BFS-Nr': 4280,
        Kantonskürzel: 'AG',
        E: 7.930490134813141,
        N: 47.31033022220715,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Attelwil',
        PLZ: 5056,
        Zusatzziffer: 0,
        Gemeindename: 'Reitnau',
        'BFS-Nr': 4281,
        Kantonskürzel: 'AG',
        E: 8.044699712908239,
        N: 47.26223256047417,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Reitnau',
        PLZ: 5057,
        Zusatzziffer: 0,
        Gemeindename: 'Reitnau',
        'BFS-Nr': 4281,
        Kantonskürzel: 'AG',
        E: 8.048901553116288,
        N: 47.25102315372965,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Rothrist',
        PLZ: 4852,
        Zusatzziffer: 0,
        Gemeindename: 'Rothrist',
        'BFS-Nr': 4282,
        Kantonskürzel: 'AG',
        E: 7.878109901688755,
        N: 47.30619795055111,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Safenwil',
        PLZ: 5745,
        Zusatzziffer: 0,
        Gemeindename: 'Safenwil',
        'BFS-Nr': 4283,
        Kantonskürzel: 'AG',
        E: 7.982150013897108,
        N: 47.31757204827981,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Staffelbach',
        PLZ: 5053,
        Zusatzziffer: 0,
        Gemeindename: 'Staffelbach',
        'BFS-Nr': 4284,
        Kantonskürzel: 'AG',
        E: 8.042337479755766,
        N: 47.28372517716673,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Wittwil',
        PLZ: 5053,
        Zusatzziffer: 2,
        Gemeindename: 'Staffelbach',
        'BFS-Nr': 4284,
        Kantonskürzel: 'AG',
        E: 8.039599848155461,
        N: 47.290549572471264,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Strengelbach',
        PLZ: 4802,
        Zusatzziffer: 0,
        Gemeindename: 'Strengelbach',
        'BFS-Nr': 4285,
        Kantonskürzel: 'AG',
        E: 7.927548288648148,
        N: 47.27989482388408,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Uerkheim',
        PLZ: 4813,
        Zusatzziffer: 0,
        Gemeindename: 'Uerkheim',
        'BFS-Nr': 4286,
        Kantonskürzel: 'AG',
        E: 8.024873095283542,
        N: 47.306173381452645,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Vordemwald',
        PLZ: 4803,
        Zusatzziffer: 0,
        Gemeindename: 'Vordemwald',
        'BFS-Nr': 4287,
        Kantonskürzel: 'AG',
        E: 7.896133548188046,
        N: 47.27257872935918,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Wiliberg',
        PLZ: 5058,
        Zusatzziffer: 0,
        Gemeindename: 'Wiliberg',
        'BFS-Nr': 4288,
        Kantonskürzel: 'AG',
        E: 8.022370446237018,
        N: 47.26762580624041,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Zofingen',
        PLZ: 4800,
        Zusatzziffer: 0,
        Gemeindename: 'Zofingen',
        'BFS-Nr': 4289,
        Kantonskürzel: 'AG',
        E: 7.945071277286622,
        N: 47.28881624817353,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Mühlethal',
        PLZ: 4812,
        Zusatzziffer: 0,
        Gemeindename: 'Zofingen',
        'BFS-Nr': 4289,
        Kantonskürzel: 'AG',
        E: 7.978116444373652,
        N: 47.30138566154691,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Kleindöttingen',
        PLZ: 5314,
        Zusatzziffer: 0,
        Gemeindename: 'Böttstein',
        'BFS-Nr': 4303,
        Kantonskürzel: 'AG',
        E: 8.246471029620977,
        N: 47.56916020361176,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Böttstein',
        PLZ: 5315,
        Zusatzziffer: 0,
        Gemeindename: 'Böttstein',
        'BFS-Nr': 4303,
        Kantonskürzel: 'AG',
        E: 8.223289886305004,
        N: 47.55553890912464,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Döttingen',
        PLZ: 5312,
        Zusatzziffer: 0,
        Gemeindename: 'Döttingen',
        'BFS-Nr': 4304,
        Kantonskürzel: 'AG',
        E: 8.258693336229824,
        N: 47.57006010393924,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Endingen',
        PLZ: 5304,
        Zusatzziffer: 0,
        Gemeindename: 'Endingen',
        'BFS-Nr': 4305,
        Kantonskürzel: 'AG',
        E: 8.289859120146092,
        N: 47.53670417496447,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Unterendingen',
        PLZ: 5305,
        Zusatzziffer: 0,
        Gemeindename: 'Endingen',
        'BFS-Nr': 4305,
        Kantonskürzel: 'AG',
        E: 8.291365862077527,
        N: 47.54755151081906,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Fisibach',
        PLZ: 5467,
        Zusatzziffer: 0,
        Gemeindename: 'Fisibach',
        'BFS-Nr': 4306,
        Kantonskürzel: 'AG',
        E: 8.407905069915193,
        N: 47.562130264026166,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Full-Reuenthal',
        PLZ: 5324,
        Zusatzziffer: 0,
        Gemeindename: 'Full-Reuenthal',
        'BFS-Nr': 4307,
        Kantonskürzel: 'AG',
        E: 8.202766962296568,
        N: 47.6087575277048,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Klingnau',
        PLZ: 5313,
        Zusatzziffer: 0,
        Gemeindename: 'Klingnau',
        'BFS-Nr': 4309,
        Kantonskürzel: 'AG',
        E: 8.249813803904914,
        N: 47.58087387526016,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Koblenz',
        PLZ: 5322,
        Zusatzziffer: 0,
        Gemeindename: 'Koblenz',
        'BFS-Nr': 4310,
        Kantonskürzel: 'AG',
        E: 8.238288219473944,
        N: 47.60785396152522,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Leibstadt',
        PLZ: 5325,
        Zusatzziffer: 0,
        Gemeindename: 'Leibstadt',
        'BFS-Nr': 4311,
        Kantonskürzel: 'AG',
        E: 8.180284430874705,
        N: 47.59169441343722,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Lengnau AG',
        PLZ: 5426,
        Zusatzziffer: 0,
        Gemeindename: 'Lengnau (AG)',
        'BFS-Nr': 4312,
        Kantonskürzel: 'AG',
        E: 8.329038879935476,
        N: 47.519996772227444,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Leuggern',
        PLZ: 5316,
        Zusatzziffer: 0,
        Gemeindename: 'Leuggern',
        'BFS-Nr': 4313,
        Kantonskürzel: 'AG',
        E: 8.21684592501964,
        N: 47.58001520603688,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Hettenschwil',
        PLZ: 5317,
        Zusatzziffer: 0,
        Gemeindename: 'Leuggern',
        'BFS-Nr': 4313,
        Kantonskürzel: 'AG',
        E: 8.196543116959019,
        N: 47.57575074492637,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Mellikon',
        PLZ: 5465,
        Zusatzziffer: 0,
        Gemeindename: 'Mellikon',
        'BFS-Nr': 4314,
        Kantonskürzel: 'AG',
        E: 8.347607175172572,
        N: 47.56709235909235,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Schneisingen',
        PLZ: 5425,
        Zusatzziffer: 0,
        Gemeindename: 'Schneisingen',
        'BFS-Nr': 4318,
        Kantonskürzel: 'AG',
        E: 8.366631225203687,
        N: 47.52032812497907,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Siglistorf',
        PLZ: 5462,
        Zusatzziffer: 0,
        Gemeindename: 'Siglistorf',
        'BFS-Nr': 4319,
        Kantonskürzel: 'AG',
        E: 8.380668091941509,
        N: 47.54580745980969,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Tegerfelden',
        PLZ: 5306,
        Zusatzziffer: 0,
        Gemeindename: 'Tegerfelden',
        'BFS-Nr': 4320,
        Kantonskürzel: 'AG',
        E: 8.289672148168927,
        N: 47.557023666773304,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Rietheim',
        PLZ: 5323,
        Zusatzziffer: 0,
        Gemeindename: 'Zurzach',
        'BFS-Nr': 4324,
        Kantonskürzel: 'AG',
        E: 8.280672126337247,
        N: 47.60204260328571,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Bad Zurzach',
        PLZ: 5330,
        Zusatzziffer: 0,
        Gemeindename: 'Zurzach',
        'BFS-Nr': 4324,
        Kantonskürzel: 'AG',
        E: 8.295302673657428,
        N: 47.58208209298062,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Rekingen AG',
        PLZ: 5332,
        Zusatzziffer: 0,
        Gemeindename: 'Zurzach',
        'BFS-Nr': 4324,
        Kantonskürzel: 'AG',
        E: 8.327509202853026,
        N: 47.569936236903274,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Baldingen',
        PLZ: 5333,
        Zusatzziffer: 0,
        Gemeindename: 'Zurzach',
        'BFS-Nr': 4324,
        Kantonskürzel: 'AG',
        E: 8.320807813618918,
        N: 47.55572929205394,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Böbikon',
        PLZ: 5334,
        Zusatzziffer: 0,
        Gemeindename: 'Zurzach',
        'BFS-Nr': 4324,
        Kantonskürzel: 'AG',
        E: 8.333066905433553,
        N: 47.55343304330981,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Wislikofen',
        PLZ: 5463,
        Zusatzziffer: 0,
        Gemeindename: 'Zurzach',
        'BFS-Nr': 4324,
        Kantonskürzel: 'AG',
        E: 8.362765897045215,
        N: 47.55746576983615,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Rümikon AG',
        PLZ: 5464,
        Zusatzziffer: 0,
        Gemeindename: 'Zurzach',
        'BFS-Nr': 4324,
        Kantonskürzel: 'AG',
        E: 8.377447441969993,
        N: 47.56372578823547,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Kaiserstuhl AG',
        PLZ: 5466,
        Zusatzziffer: 0,
        Gemeindename: 'Zurzach',
        'BFS-Nr': 4324,
        Kantonskürzel: 'AG',
        E: 8.420168539546756,
        N: 47.56726350561507,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Arbon',
        PLZ: 9320,
        Zusatzziffer: 0,
        Gemeindename: 'Arbon',
        'BFS-Nr': 4401,
        Kantonskürzel: 'TG',
        E: 9.436893293919633,
        N: 47.515233968607646,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Frasnacht',
        PLZ: 9320,
        Zusatzziffer: 2,
        Gemeindename: 'Arbon',
        'BFS-Nr': 4401,
        Kantonskürzel: 'TG',
        E: 9.405803138562792,
        N: 47.527325998914996,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Stachen',
        PLZ: 9320,
        Zusatzziffer: 3,
        Gemeindename: 'Arbon',
        'BFS-Nr': 4401,
        Kantonskürzel: 'TG',
        E: 9.409917261320103,
        N: 47.50666607639438,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Dozwil',
        PLZ: 8582,
        Zusatzziffer: 0,
        Gemeindename: 'Dozwil',
        'BFS-Nr': 4406,
        Kantonskürzel: 'TG',
        E: 9.320000156083006,
        N: 47.576045297667314,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Steinebrunn',
        PLZ: 9314,
        Zusatzziffer: 0,
        Gemeindename: 'Egnach',
        'BFS-Nr': 4411,
        Kantonskürzel: 'TG',
        E: 9.34336295568951,
        N: 47.53431354735908,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Neukirch (Egnach)',
        PLZ: 9315,
        Zusatzziffer: 0,
        Gemeindename: 'Egnach',
        'BFS-Nr': 4411,
        Kantonskürzel: 'TG',
        E: 9.369496339868693,
        N: 47.52870010063038,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Winden',
        PLZ: 9315,
        Zusatzziffer: 2,
        Gemeindename: 'Egnach',
        'BFS-Nr': 4411,
        Kantonskürzel: 'TG',
        E: 9.35670800497006,
        N: 47.50603370110302,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Egnach',
        PLZ: 9322,
        Zusatzziffer: 0,
        Gemeindename: 'Egnach',
        'BFS-Nr': 4411,
        Kantonskürzel: 'TG',
        E: 9.382844375901975,
        N: 47.544287254450026,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Hefenhofen',
        PLZ: 8580,
        Zusatzziffer: 7,
        Gemeindename: 'Hefenhofen',
        'BFS-Nr': 4416,
        Kantonskürzel: 'TG',
        E: 9.298945481504932,
        N: 47.56478579635065,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Horn',
        PLZ: 9326,
        Zusatzziffer: 0,
        Gemeindename: 'Horn',
        'BFS-Nr': 4421,
        Kantonskürzel: 'TG',
        E: 9.463107378409346,
        N: 47.493839719646935,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Kesswil',
        PLZ: 8593,
        Zusatzziffer: 0,
        Gemeindename: 'Kesswil',
        'BFS-Nr': 4426,
        Kantonskürzel: 'TG',
        E: 9.31681614268233,
        N: 47.59339455919304,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Freidorf TG',
        PLZ: 9306,
        Zusatzziffer: 0,
        Gemeindename: 'Roggwil (TG)',
        'BFS-Nr': 4431,
        Kantonskürzel: 'TG',
        E: 9.397297275540373,
        N: 47.48629744242055,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Roggwil TG',
        PLZ: 9325,
        Zusatzziffer: 0,
        Gemeindename: 'Roggwil (TG)',
        'BFS-Nr': 4431,
        Kantonskürzel: 'TG',
        E: 9.396321214344471,
        N: 47.500174451599996,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Romanshorn',
        PLZ: 8590,
        Zusatzziffer: 0,
        Gemeindename: 'Romanshorn',
        'BFS-Nr': 4436,
        Kantonskürzel: 'TG',
        E: 9.369414030317662,
        N: 47.56486901227895,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Salmsach',
        PLZ: 8599,
        Zusatzziffer: 0,
        Gemeindename: 'Salmsach',
        'BFS-Nr': 4441,
        Kantonskürzel: 'TG',
        E: 9.374814390724376,
        N: 47.55568063309691,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Sommeri',
        PLZ: 8580,
        Zusatzziffer: 3,
        Gemeindename: 'Sommeri',
        'BFS-Nr': 4446,
        Kantonskürzel: 'TG',
        E: 9.290137403966376,
        N: 47.5671918662772,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Uttwil',
        PLZ: 8592,
        Zusatzziffer: 0,
        Gemeindename: 'Uttwil',
        'BFS-Nr': 4451,
        Kantonskürzel: 'TG',
        E: 9.34101513735606,
        N: 47.58358393388047,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Amriswil',
        PLZ: 8580,
        Zusatzziffer: 0,
        Gemeindename: 'Amriswil',
        'BFS-Nr': 4461,
        Kantonskürzel: 'TG',
        E: 9.29748922168339,
        N: 47.54497589535935,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Hagenwil b. Amriswil',
        PLZ: 8580,
        Zusatzziffer: 5,
        Gemeindename: 'Amriswil',
        'BFS-Nr': 4461,
        Kantonskürzel: 'TG',
        E: 9.307144771358113,
        N: 47.52903812972204,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Biessenhofen',
        PLZ: 8580,
        Zusatzziffer: 8,
        Gemeindename: 'Amriswil',
        'BFS-Nr': 4461,
        Kantonskürzel: 'TG',
        E: 9.260400007448624,
        N: 47.54651768946617,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Schocherswil',
        PLZ: 8581,
        Zusatzziffer: 0,
        Gemeindename: 'Amriswil',
        'BFS-Nr': 4461,
        Kantonskürzel: 'TG',
        E: 9.266319985119562,
        N: 47.53695161573004,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Oberaach',
        PLZ: 8587,
        Zusatzziffer: 0,
        Gemeindename: 'Amriswil',
        'BFS-Nr': 4461,
        Kantonskürzel: 'TG',
        E: 9.266938263430266,
        N: 47.558954948927806,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Bischofszell',
        PLZ: 9220,
        Zusatzziffer: 0,
        Gemeindename: 'Bischofszell',
        'BFS-Nr': 4471,
        Kantonskürzel: 'TG',
        E: 9.238937813693683,
        N: 47.49401261811148,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Schweizersholz',
        PLZ: 9223,
        Zusatzziffer: 0,
        Gemeindename: 'Bischofszell',
        'BFS-Nr': 4471,
        Kantonskürzel: 'TG',
        E: 9.200882869441202,
        N: 47.5016668140228,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Halden',
        PLZ: 9223,
        Zusatzziffer: 1,
        Gemeindename: 'Bischofszell',
        'BFS-Nr': 4471,
        Kantonskürzel: 'TG',
        E: 9.209378987599997,
        N: 47.50426206553012,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Erlen',
        PLZ: 8586,
        Zusatzziffer: 0,
        Gemeindename: 'Erlen',
        'BFS-Nr': 4476,
        Kantonskürzel: 'TG',
        E: 9.23446314589178,
        N: 47.54804404675361,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Kümmertshausen',
        PLZ: 8586,
        Zusatzziffer: 3,
        Gemeindename: 'Erlen',
        'BFS-Nr': 4476,
        Kantonskürzel: 'TG',
        E: 9.244438541442204,
        N: 47.56535110861271,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Riedt b. Erlen',
        PLZ: 8586,
        Zusatzziffer: 5,
        Gemeindename: 'Erlen',
        'BFS-Nr': 4476,
        Kantonskürzel: 'TG',
        E: 9.216154802402015,
        N: 47.545596204613744,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Buchackern',
        PLZ: 8586,
        Zusatzziffer: 7,
        Gemeindename: 'Erlen',
        'BFS-Nr': 4476,
        Kantonskürzel: 'TG',
        E: 9.232541365507938,
        N: 47.53859754524568,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Engishofen',
        PLZ: 8586,
        Zusatzziffer: 8,
        Gemeindename: 'Erlen',
        'BFS-Nr': 4476,
        Kantonskürzel: 'TG',
        E: 9.249505141955499,
        N: 47.556441736250854,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Ennetaach',
        PLZ: 8586,
        Zusatzziffer: 9,
        Gemeindename: 'Erlen',
        'BFS-Nr': 4476,
        Kantonskürzel: 'TG',
        E: 9.215948213571384,
        N: 47.549165745082924,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Hauptwil',
        PLZ: 9213,
        Zusatzziffer: 0,
        Gemeindename: 'Hauptwil-Gottshaus',
        'BFS-Nr': 4486,
        Kantonskürzel: 'TG',
        E: 9.253116988091923,
        N: 47.48062580658546,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Wilen (Gottshaus)',
        PLZ: 9225,
        Zusatzziffer: 0,
        Gemeindename: 'Hauptwil-Gottshaus',
        'BFS-Nr': 4486,
        Kantonskürzel: 'TG',
        E: 9.283023781357517,
        N: 47.493827113128745,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'St. Pelagiberg',
        PLZ: 9225,
        Zusatzziffer: 1,
        Gemeindename: 'Hauptwil-Gottshaus',
        'BFS-Nr': 4486,
        Kantonskürzel: 'TG',
        E: 9.30250180577112,
        N: 47.48915626749582,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Heldswil',
        PLZ: 9216,
        Zusatzziffer: 0,
        Gemeindename: 'Hohentannen',
        'BFS-Nr': 4495,
        Kantonskürzel: 'TG',
        E: 9.221105477138586,
        N: 47.53005121079519,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Hohentannen',
        PLZ: 9216,
        Zusatzziffer: 2,
        Gemeindename: 'Hohentannen',
        'BFS-Nr': 4495,
        Kantonskürzel: 'TG',
        E: 9.223662086012327,
        N: 47.509912125111846,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Kradolf',
        PLZ: 9214,
        Zusatzziffer: 2,
        Gemeindename: 'Kradolf-Schönenberg',
        'BFS-Nr': 4501,
        Kantonskürzel: 'TG',
        E: 9.201326744160742,
        N: 47.525143691283574,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Schönenberg an der Thur',
        PLZ: 9215,
        Zusatzziffer: 0,
        Gemeindename: 'Kradolf-Schönenberg',
        'BFS-Nr': 4501,
        Kantonskürzel: 'TG',
        E: 9.198786036658124,
        N: 47.52132707306943,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Buhwil',
        PLZ: 9215,
        Zusatzziffer: 2,
        Gemeindename: 'Kradolf-Schönenberg',
        'BFS-Nr': 4501,
        Kantonskürzel: 'TG',
        E: 9.166237835930835,
        N: 47.52712876130681,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Neukirch an der Thur',
        PLZ: 9217,
        Zusatzziffer: 0,
        Gemeindename: 'Kradolf-Schönenberg',
        'BFS-Nr': 4501,
        Kantonskürzel: 'TG',
        E: 9.170899828921053,
        N: 47.510407002026426,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Sulgen',
        PLZ: 8583,
        Zusatzziffer: 0,
        Gemeindename: 'Sulgen',
        'BFS-Nr': 4506,
        Kantonskürzel: 'TG',
        E: 9.183827841275576,
        N: 47.543051977150206,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Götighofen',
        PLZ: 8583,
        Zusatzziffer: 1,
        Gemeindename: 'Sulgen',
        'BFS-Nr': 4506,
        Kantonskürzel: 'TG',
        E: 9.215947272527874,
        N: 47.53511087464307,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Donzhausen',
        PLZ: 8583,
        Zusatzziffer: 2,
        Gemeindename: 'Sulgen',
        'BFS-Nr': 4506,
        Kantonskürzel: 'TG',
        E: 9.196173170058259,
        N: 47.55418872922863,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Zihlschlacht',
        PLZ: 8588,
        Zusatzziffer: 0,
        Gemeindename: 'Zihlschlacht-Sitterdorf',
        'BFS-Nr': 4511,
        Kantonskürzel: 'TG',
        E: 9.257726529888883,
        N: 47.52126474160961,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Sitterdorf',
        PLZ: 8589,
        Zusatzziffer: 0,
        Gemeindename: 'Zihlschlacht-Sitterdorf',
        'BFS-Nr': 4511,
        Kantonskürzel: 'TG',
        E: 9.248422280940808,
        N: 47.504803189292446,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Basadingen',
        PLZ: 8254,
        Zusatzziffer: 0,
        Gemeindename: 'Basadingen-Schlattingen',
        'BFS-Nr': 4536,
        Kantonskürzel: 'TG',
        E: 8.743397635316333,
        N: 47.67161675706732,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Schlattingen',
        PLZ: 8255,
        Zusatzziffer: 0,
        Gemeindename: 'Basadingen-Schlattingen',
        'BFS-Nr': 4536,
        Kantonskürzel: 'TG',
        E: 8.770237543088706,
        N: 47.66686235948442,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Diessenhofen',
        PLZ: 8253,
        Zusatzziffer: 0,
        Gemeindename: 'Diessenhofen',
        'BFS-Nr': 4545,
        Kantonskürzel: 'TG',
        E: 8.748711047138821,
        N: 47.690016560754934,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Willisdorf',
        PLZ: 8253,
        Zusatzziffer: 2,
        Gemeindename: 'Diessenhofen',
        'BFS-Nr': 4545,
        Kantonskürzel: 'TG',
        E: 8.738161592538278,
        N: 47.67860385210421,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Schlatt TG',
        PLZ: 8252,
        Zusatzziffer: 0,
        Gemeindename: 'Schlatt (TG)',
        'BFS-Nr': 4546,
        Kantonskürzel: 'TG',
        E: 8.702829602932093,
        N: 47.66157027484184,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Aadorf',
        PLZ: 8355,
        Zusatzziffer: 0,
        Gemeindename: 'Aadorf',
        'BFS-Nr': 4551,
        Kantonskürzel: 'TG',
        E: 8.89769823294554,
        N: 47.49135943297217,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Ettenhausen TG',
        PLZ: 8356,
        Zusatzziffer: 0,
        Gemeindename: 'Aadorf',
        'BFS-Nr': 4551,
        Kantonskürzel: 'TG',
        E: 8.898302903342772,
        N: 47.47838544285783,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Guntershausen b. Aadorf',
        PLZ: 8357,
        Zusatzziffer: 0,
        Gemeindename: 'Aadorf',
        'BFS-Nr': 4551,
        Kantonskürzel: 'TG',
        E: 8.916864917262643,
        N: 47.474261206922385,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Häuslenen',
        PLZ: 8522,
        Zusatzziffer: 0,
        Gemeindename: 'Aadorf',
        'BFS-Nr': 4551,
        Kantonskürzel: 'TG',
        E: 8.90335210074304,
        N: 47.52689732944613,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Aawangen',
        PLZ: 8522,
        Zusatzziffer: 2,
        Gemeindename: 'Aadorf',
        'BFS-Nr': 4551,
        Kantonskürzel: 'TG',
        E: 8.89993613013691,
        N: 47.51218929270313,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Wittenwil',
        PLZ: 9547,
        Zusatzziffer: 0,
        Gemeindename: 'Aadorf',
        'BFS-Nr': 4551,
        Kantonskürzel: 'TG',
        E: 8.925309967164852,
        N: 47.50435382634346,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Felben-Wellhausen',
        PLZ: 8552,
        Zusatzziffer: 0,
        Gemeindename: 'Felben-Wellhausen',
        'BFS-Nr': 4561,
        Kantonskürzel: 'TG',
        E: 8.94265061464982,
        N: 47.57690914614743,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Frauenfeld',
        PLZ: 8500,
        Zusatzziffer: 0,
        Gemeindename: 'Frauenfeld',
        'BFS-Nr': 4566,
        Kantonskürzel: 'TG',
        E: 8.898261869970607,
        N: 47.55599473880441,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Gerlikon',
        PLZ: 8500,
        Zusatzziffer: 4,
        Gemeindename: 'Frauenfeld',
        'BFS-Nr': 4566,
        Kantonskürzel: 'TG',
        E: 8.87843192020212,
        N: 47.53702680755049,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Islikon',
        PLZ: 8546,
        Zusatzziffer: 0,
        Gemeindename: 'Gachnang',
        'BFS-Nr': 4571,
        Kantonskürzel: 'TG',
        E: 8.845665446442544,
        N: 47.54775813415775,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Kefikon TG',
        PLZ: 8546,
        Zusatzziffer: 2,
        Gemeindename: 'Gachnang',
        'BFS-Nr': 4571,
        Kantonskürzel: 'TG',
        E: 8.833718680605156,
        N: 47.55030714159183,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Gachnang',
        PLZ: 8547,
        Zusatzziffer: 0,
        Gemeindename: 'Gachnang',
        'BFS-Nr': 4571,
        Kantonskürzel: 'TG',
        E: 8.851107442117177,
        N: 47.537850417079326,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Harenwilen',
        PLZ: 8553,
        Zusatzziffer: 2,
        Gemeindename: 'Hüttlingen',
        'BFS-Nr': 4590,
        Kantonskürzel: 'TG',
        E: 9.000447092749368,
        N: 47.5711073183183,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Mettendorf TG',
        PLZ: 8553,
        Zusatzziffer: 3,
        Gemeindename: 'Hüttlingen',
        'BFS-Nr': 4590,
        Kantonskürzel: 'TG',
        E: 8.969834045397848,
        N: 47.577398784777436,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Hüttlingen',
        PLZ: 8553,
        Zusatzziffer: 4,
        Gemeindename: 'Hüttlingen',
        'BFS-Nr': 4590,
        Kantonskürzel: 'TG',
        E: 8.981241751129845,
        N: 47.57668321803132,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Eschikofen',
        PLZ: 8553,
        Zusatzziffer: 5,
        Gemeindename: 'Hüttlingen',
        'BFS-Nr': 4590,
        Kantonskürzel: 'TG',
        E: 9.004782624085932,
        N: 47.57979108367084,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Matzingen',
        PLZ: 9548,
        Zusatzziffer: 0,
        Gemeindename: 'Matzingen',
        'BFS-Nr': 4591,
        Kantonskürzel: 'TG',
        E: 8.932561020057708,
        N: 47.52010955658052,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Niederneunforn',
        PLZ: 8525,
        Zusatzziffer: 0,
        Gemeindename: 'Neunforn',
        'BFS-Nr': 4601,
        Kantonskürzel: 'TG',
        E: 8.778055840536913,
        N: 47.59522303937581,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Oberneunforn',
        PLZ: 8526,
        Zusatzziffer: 0,
        Gemeindename: 'Neunforn',
        'BFS-Nr': 4601,
        Kantonskürzel: 'TG',
        E: 8.766802150731355,
        N: 47.605946516554866,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Stettfurt',
        PLZ: 9507,
        Zusatzziffer: 0,
        Gemeindename: 'Stettfurt',
        'BFS-Nr': 4606,
        Kantonskürzel: 'TG',
        E: 8.95395838860172,
        N: 47.524571913613435,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Thundorf',
        PLZ: 8512,
        Zusatzziffer: 0,
        Gemeindename: 'Thundorf',
        'BFS-Nr': 4611,
        Kantonskürzel: 'TG',
        E: 8.963467904204261,
        N: 47.54570476954909,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Lustdorf',
        PLZ: 8512,
        Zusatzziffer: 2,
        Gemeindename: 'Thundorf',
        'BFS-Nr': 4611,
        Kantonskürzel: 'TG',
        E: 8.986293366947848,
        N: 47.55042326884312,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Wetzikon TG',
        PLZ: 8512,
        Zusatzziffer: 3,
        Gemeindename: 'Thundorf',
        'BFS-Nr': 4611,
        Kantonskürzel: 'TG',
        E: 9.001143768447049,
        N: 47.537904747307664,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Uesslingen',
        PLZ: 8524,
        Zusatzziffer: 0,
        Gemeindename: 'Uesslingen-Buch',
        'BFS-Nr': 4616,
        Kantonskürzel: 'TG',
        E: 8.836638467844411,
        N: 47.5804690497972,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Buch b. Frauenfeld',
        PLZ: 8524,
        Zusatzziffer: 2,
        Gemeindename: 'Uesslingen-Buch',
        'BFS-Nr': 4616,
        Kantonskürzel: 'TG',
        E: 8.837887909984168,
        N: 47.599431495143484,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Warth',
        PLZ: 8532,
        Zusatzziffer: 0,
        Gemeindename: 'Warth-Weiningen',
        'BFS-Nr': 4621,
        Kantonskürzel: 'TG',
        E: 8.87378130172603,
        N: 47.5842163249162,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Weiningen TG',
        PLZ: 8532,
        Zusatzziffer: 2,
        Gemeindename: 'Warth-Weiningen',
        'BFS-Nr': 4621,
        Kantonskürzel: 'TG',
        E: 8.891254819507433,
        N: 47.588648729324944,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Altnau',
        PLZ: 8595,
        Zusatzziffer: 0,
        Gemeindename: 'Altnau',
        'BFS-Nr': 4641,
        Kantonskürzel: 'TG',
        E: 9.257678768719039,
        N: 47.60802656156299,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Bottighofen',
        PLZ: 8598,
        Zusatzziffer: 0,
        Gemeindename: 'Bottighofen',
        'BFS-Nr': 4643,
        Kantonskürzel: 'TG',
        E: 9.21330005117827,
        N: 47.640799298993684,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Ermatingen',
        PLZ: 8272,
        Zusatzziffer: 0,
        Gemeindename: 'Ermatingen',
        'BFS-Nr': 4646,
        Kantonskürzel: 'TG',
        E: 9.080437384090779,
        N: 47.669867780714476,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Triboltingen',
        PLZ: 8273,
        Zusatzziffer: 0,
        Gemeindename: 'Ermatingen',
        'BFS-Nr': 4646,
        Kantonskürzel: 'TG',
        E: 9.10780252703643,
        N: 47.66198945820662,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Gottlieben',
        PLZ: 8274,
        Zusatzziffer: 2,
        Gemeindename: 'Gottlieben',
        'BFS-Nr': 4651,
        Kantonskürzel: 'TG',
        E: 9.133590564065065,
        N: 47.66380352082843,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Güttingen',
        PLZ: 8594,
        Zusatzziffer: 0,
        Gemeindename: 'Güttingen',
        'BFS-Nr': 4656,
        Kantonskürzel: 'TG',
        E: 9.286361341844545,
        N: 47.602627471207725,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Hugelshofen',
        PLZ: 8565,
        Zusatzziffer: 0,
        Gemeindename: 'Kemmental',
        'BFS-Nr': 4666,
        Kantonskürzel: 'TG',
        E: 9.115091564761084,
        N: 47.59875600144734,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Neuwilen',
        PLZ: 8566,
        Zusatzziffer: 0,
        Gemeindename: 'Kemmental',
        'BFS-Nr': 4666,
        Kantonskürzel: 'TG',
        E: 9.134282505143926,
        N: 47.619984964549694,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Dotnacht',
        PLZ: 8566,
        Zusatzziffer: 2,
        Gemeindename: 'Kemmental',
        'BFS-Nr': 4666,
        Kantonskürzel: 'TG',
        E: 9.141181295726408,
        N: 47.60020578137128,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Ellighausen',
        PLZ: 8566,
        Zusatzziffer: 4,
        Gemeindename: 'Kemmental',
        'BFS-Nr': 4666,
        Kantonskürzel: 'TG',
        E: 9.135622443677551,
        N: 47.613642605150986,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Lippoldswilen',
        PLZ: 8566,
        Zusatzziffer: 5,
        Gemeindename: 'Kemmental',
        'BFS-Nr': 4666,
        Kantonskürzel: 'TG',
        E: 9.111409685404897,
        N: 47.61088043604375,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Siegershausen',
        PLZ: 8573,
        Zusatzziffer: 0,
        Gemeindename: 'Kemmental',
        'BFS-Nr': 4666,
        Kantonskürzel: 'TG',
        E: 9.168460315328904,
        N: 47.611288899125675,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Alterswilen',
        PLZ: 8573,
        Zusatzziffer: 2,
        Gemeindename: 'Kemmental',
        'BFS-Nr': 4666,
        Kantonskürzel: 'TG',
        E: 9.154979114269226,
        N: 47.60964702755147,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Altishausen',
        PLZ: 8573,
        Zusatzziffer: 3,
        Gemeindename: 'Kemmental',
        'BFS-Nr': 4666,
        Kantonskürzel: 'TG',
        E: 9.170725459651388,
        N: 47.60126013276639,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Kreuzlingen',
        PLZ: 8280,
        Zusatzziffer: 0,
        Gemeindename: 'Kreuzlingen',
        'BFS-Nr': 4671,
        Kantonskürzel: 'TG',
        E: 9.173202396172773,
        N: 47.64467769015092,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Langrickenbach',
        PLZ: 8585,
        Zusatzziffer: 4,
        Gemeindename: 'Langrickenbach',
        'BFS-Nr': 4681,
        Kantonskürzel: 'TG',
        E: 9.24734290138479,
        N: 47.58327922441947,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Zuben',
        PLZ: 8585,
        Zusatzziffer: 5,
        Gemeindename: 'Langrickenbach',
        'BFS-Nr': 4681,
        Kantonskürzel: 'TG',
        E: 9.236604134610156,
        N: 47.60670257312282,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Schönenbaumgarten',
        PLZ: 8585,
        Zusatzziffer: 7,
        Gemeindename: 'Langrickenbach',
        'BFS-Nr': 4681,
        Kantonskürzel: 'TG',
        E: 9.230800837733169,
        N: 47.614400433275634,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Herrenhof',
        PLZ: 8585,
        Zusatzziffer: 8,
        Gemeindename: 'Langrickenbach',
        'BFS-Nr': 4681,
        Kantonskürzel: 'TG',
        E: 9.243456613344625,
        N: 47.59787615099063,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Illighausen',
        PLZ: 8574,
        Zusatzziffer: 1,
        Gemeindename: 'Lengwil',
        'BFS-Nr': 4683,
        Kantonskürzel: 'TG',
        E: 9.206866435727296,
        N: 47.60372249651893,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Oberhofen TG',
        PLZ: 8574,
        Zusatzziffer: 2,
        Gemeindename: 'Lengwil',
        'BFS-Nr': 4683,
        Kantonskürzel: 'TG',
        E: 9.194918960922715,
        N: 47.61867344672595,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Lengwil',
        PLZ: 8574,
        Zusatzziffer: 3,
        Gemeindename: 'Lengwil',
        'BFS-Nr': 4683,
        Kantonskürzel: 'TG',
        E: 9.190032486643426,
        N: 47.62586496304939,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Lengwil',
        PLZ: 8574,
        Zusatzziffer: 3,
        Gemeindename: 'Lengwil',
        'BFS-Nr': 4683,
        Kantonskürzel: 'TG',
        E: 9.20696646468395,
        N: 47.615484364724864,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Dettighofen (Lengwil)',
        PLZ: 8574,
        Zusatzziffer: 4,
        Gemeindename: 'Lengwil',
        'BFS-Nr': 4683,
        Kantonskürzel: 'TG',
        E: 9.20057406272806,
        N: 47.620675120055175,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Scherzingen',
        PLZ: 8596,
        Zusatzziffer: 0,
        Gemeindename: 'Münsterlingen',
        'BFS-Nr': 4691,
        Kantonskürzel: 'TG',
        E: 9.22317887283868,
        N: 47.63198793785019,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Münsterlingen',
        PLZ: 8596,
        Zusatzziffer: 1,
        Gemeindename: 'Münsterlingen',
        'BFS-Nr': 4691,
        Kantonskürzel: 'TG',
        E: 9.235000139790131,
        N: 47.63143725575124,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Landschlacht',
        PLZ: 8597,
        Zusatzziffer: 0,
        Gemeindename: 'Münsterlingen',
        'BFS-Nr': 4691,
        Kantonskürzel: 'TG',
        E: 9.24461971949314,
        N: 47.62560650549746,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Tägerwilen',
        PLZ: 8274,
        Zusatzziffer: 0,
        Gemeindename: 'Tägerwilen',
        'BFS-Nr': 4696,
        Kantonskürzel: 'TG',
        E: 9.136657021169784,
        N: 47.65487140105523,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Hefenhausen',
        PLZ: 8564,
        Zusatzziffer: 0,
        Gemeindename: 'Wäldi',
        'BFS-Nr': 4701,
        Kantonskürzel: 'TG',
        E: 9.064241032081986,
        N: 47.61595903950303,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Engwilen',
        PLZ: 8564,
        Zusatzziffer: 2,
        Gemeindename: 'Wäldi',
        'BFS-Nr': 4701,
        Kantonskürzel: 'TG',
        E: 9.095614197706723,
        N: 47.61713531191881,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Sonterswil',
        PLZ: 8564,
        Zusatzziffer: 3,
        Gemeindename: 'Wäldi',
        'BFS-Nr': 4701,
        Kantonskürzel: 'TG',
        E: 9.078124368125223,
        N: 47.62087798799726,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Wäldi',
        PLZ: 8564,
        Zusatzziffer: 4,
        Gemeindename: 'Wäldi',
        'BFS-Nr': 4701,
        Kantonskürzel: 'TG',
        E: 9.094201176271469,
        N: 47.634096659211025,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Lipperswil',
        PLZ: 8564,
        Zusatzziffer: 5,
        Gemeindename: 'Wäldi',
        'BFS-Nr': 4701,
        Kantonskürzel: 'TG',
        E: 9.0531100385979,
        N: 47.61706117279914,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Hattenhausen',
        PLZ: 8564,
        Zusatzziffer: 7,
        Gemeindename: 'Wäldi',
        'BFS-Nr': 4701,
        Kantonskürzel: 'TG',
        E: 9.066688441774016,
        N: 47.62369141376523,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Gunterswilen',
        PLZ: 8564,
        Zusatzziffer: 8,
        Gemeindename: 'Wäldi',
        'BFS-Nr': 4701,
        Kantonskürzel: 'TG',
        E: 9.076516003497627,
        N: 47.63559677705925,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Affeltrangen',
        PLZ: 9556,
        Zusatzziffer: 0,
        Gemeindename: 'Affeltrangen',
        'BFS-Nr': 4711,
        Kantonskürzel: 'TG',
        E: 9.030027789151738,
        N: 47.52598926112114,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Zezikon',
        PLZ: 9556,
        Zusatzziffer: 2,
        Gemeindename: 'Affeltrangen',
        'BFS-Nr': 4711,
        Kantonskürzel: 'TG',
        E: 9.021006021339527,
        N: 47.534839081038484,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Märwil',
        PLZ: 9562,
        Zusatzziffer: 0,
        Gemeindename: 'Affeltrangen',
        'BFS-Nr': 4711,
        Kantonskürzel: 'TG',
        E: 9.074497013711252,
        N: 47.53244507217561,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Buch b. Märwil',
        PLZ: 9562,
        Zusatzziffer: 2,
        Gemeindename: 'Affeltrangen',
        'BFS-Nr': 4711,
        Kantonskürzel: 'TG',
        E: 9.057793651259054,
        N: 47.53765711107678,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Bettwiesen',
        PLZ: 9553,
        Zusatzziffer: 0,
        Gemeindename: 'Bettwiesen',
        'BFS-Nr': 4716,
        Kantonskürzel: 'TG',
        E: 9.026730718478497,
        N: 47.49399844559707,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Balterswil',
        PLZ: 8362,
        Zusatzziffer: 0,
        Gemeindename: 'Bichelsee-Balterswil',
        'BFS-Nr': 4721,
        Kantonskürzel: 'TG',
        E: 8.937620453422324,
        N: 47.45315791185668,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Bichelsee',
        PLZ: 8363,
        Zusatzziffer: 0,
        Gemeindename: 'Bichelsee-Balterswil',
        'BFS-Nr': 4721,
        Kantonskürzel: 'TG',
        E: 8.92132135078989,
        N: 47.4510312470221,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Braunau',
        PLZ: 9502,
        Zusatzziffer: 0,
        Gemeindename: 'Braunau',
        'BFS-Nr': 4723,
        Kantonskürzel: 'TG',
        E: 9.07122120761076,
        N: 47.50368803699617,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Eschlikon TG',
        PLZ: 8360,
        Zusatzziffer: 0,
        Gemeindename: 'Eschlikon',
        'BFS-Nr': 4724,
        Kantonskürzel: 'TG',
        E: 8.964229959685836,
        N: 47.465568625553985,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Wallenwil',
        PLZ: 8360,
        Zusatzziffer: 2,
        Gemeindename: 'Eschlikon',
        'BFS-Nr': 4724,
        Kantonskürzel: 'TG',
        E: 8.955456388878678,
        N: 47.45467070837589,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Dussnang',
        PLZ: 8374,
        Zusatzziffer: 0,
        Gemeindename: 'Fischingen',
        'BFS-Nr': 4726,
        Kantonskürzel: 'TG',
        E: 8.962380912296117,
        N: 47.43026299389102,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Oberwangen TG',
        PLZ: 8374,
        Zusatzziffer: 2,
        Gemeindename: 'Fischingen',
        'BFS-Nr': 4726,
        Kantonskürzel: 'TG',
        E: 8.973265560464762,
        N: 47.42984338668543,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Fischingen',
        PLZ: 8376,
        Zusatzziffer: 0,
        Gemeindename: 'Fischingen',
        'BFS-Nr': 4726,
        Kantonskürzel: 'TG',
        E: 8.968245907620446,
        N: 47.41241159864051,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Au TG',
        PLZ: 8376,
        Zusatzziffer: 2,
        Gemeindename: 'Fischingen',
        'BFS-Nr': 4726,
        Kantonskürzel: 'TG',
        E: 8.953601130812823,
        N: 47.39774029633615,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Lommis',
        PLZ: 9506,
        Zusatzziffer: 0,
        Gemeindename: 'Lommis',
        'BFS-Nr': 4741,
        Kantonskürzel: 'TG',
        E: 8.997950947699378,
        N: 47.51697631092459,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Weingarten-Kalthäusern',
        PLZ: 9508,
        Zusatzziffer: 0,
        Gemeindename: 'Lommis',
        'BFS-Nr': 4741,
        Kantonskürzel: 'TG',
        E: 8.978968452333453,
        N: 47.52316143413059,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Münchwilen TG',
        PLZ: 9542,
        Zusatzziffer: 0,
        Gemeindename: 'Münchwilen (TG)',
        'BFS-Nr': 4746,
        Kantonskürzel: 'TG',
        E: 8.999036690074583,
        N: 47.47972204399947,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'St. Margarethen TG',
        PLZ: 9543,
        Zusatzziffer: 0,
        Gemeindename: 'Münchwilen (TG)',
        'BFS-Nr': 4746,
        Kantonskürzel: 'TG',
        E: 9.001149255954726,
        N: 47.486511102363735,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Rickenbach b. Wil',
        PLZ: 9532,
        Zusatzziffer: 0,
        Gemeindename: 'Rickenbach (TG)',
        'BFS-Nr': 4751,
        Kantonskürzel: 'TG',
        E: 9.052142727567908,
        N: 47.448726577346115,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Schönholzerswilen',
        PLZ: 8577,
        Zusatzziffer: 0,
        Gemeindename: 'Schönholzerswilen',
        'BFS-Nr': 4756,
        Kantonskürzel: 'TG',
        E: 9.14091978780629,
        N: 47.51699252217091,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Sirnach',
        PLZ: 8370,
        Zusatzziffer: 0,
        Gemeindename: 'Sirnach',
        'BFS-Nr': 4761,
        Kantonskürzel: 'TG',
        E: 8.997506656925943,
        N: 47.46203075166267,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Busswil TG',
        PLZ: 8371,
        Zusatzziffer: 0,
        Gemeindename: 'Sirnach',
        'BFS-Nr': 4761,
        Kantonskürzel: 'TG',
        E: 9.015758515544167,
        N: 47.45338351874146,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Wiezikon b. Sirnach',
        PLZ: 8372,
        Zusatzziffer: 0,
        Gemeindename: 'Sirnach',
        'BFS-Nr': 4761,
        Kantonskürzel: 'TG',
        E: 8.98654514111016,
        N: 47.45037356272141,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Littenheid',
        PLZ: 9573,
        Zusatzziffer: 0,
        Gemeindename: 'Sirnach',
        'BFS-Nr': 4761,
        Kantonskürzel: 'TG',
        E: 9.00873882645117,
        N: 47.44073032219142,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Tägerschen',
        PLZ: 9554,
        Zusatzziffer: 0,
        Gemeindename: 'Tobel-Tägerschen',
        'BFS-Nr': 4776,
        Kantonskürzel: 'TG',
        E: 9.02950180025349,
        N: 47.50694582393814,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Tobel',
        PLZ: 9555,
        Zusatzziffer: 0,
        Gemeindename: 'Tobel-Tägerschen',
        'BFS-Nr': 4776,
        Kantonskürzel: 'TG',
        E: 9.036509701165302,
        N: 47.51480509039014,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Wängi',
        PLZ: 9545,
        Zusatzziffer: 0,
        Gemeindename: 'Wängi',
        'BFS-Nr': 4781,
        Kantonskürzel: 'TG',
        E: 8.954423930411119,
        N: 47.50081235214886,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Tuttwil',
        PLZ: 9546,
        Zusatzziffer: 0,
        Gemeindename: 'Wängi',
        'BFS-Nr': 4781,
        Kantonskürzel: 'TG',
        E: 8.938396336652705,
        N: 47.483268252236584,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Wilen b. Wil',
        PLZ: 9535,
        Zusatzziffer: 0,
        Gemeindename: 'Wilen (TG)',
        'BFS-Nr': 4786,
        Kantonskürzel: 'TG',
        E: 9.034366051307973,
        N: 47.45157671779675,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Wuppenau',
        PLZ: 9514,
        Zusatzziffer: 0,
        Gemeindename: 'Wuppenau',
        'BFS-Nr': 4791,
        Kantonskürzel: 'TG',
        E: 9.103464970575764,
        N: 47.495303129861874,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Hosenruck',
        PLZ: 9515,
        Zusatzziffer: 0,
        Gemeindename: 'Wuppenau',
        'BFS-Nr': 4791,
        Kantonskürzel: 'TG',
        E: 9.122518195081,
        N: 47.49035376974706,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Berlingen',
        PLZ: 8267,
        Zusatzziffer: 0,
        Gemeindename: 'Berlingen',
        'BFS-Nr': 4801,
        Kantonskürzel: 'TG',
        E: 9.016329954737065,
        N: 47.674463745180134,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Eschenz',
        PLZ: 8264,
        Zusatzziffer: 0,
        Gemeindename: 'Eschenz',
        'BFS-Nr': 4806,
        Kantonskürzel: 'TG',
        E: 8.875013679030276,
        N: 47.646547034324264,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Lanzenneunforn',
        PLZ: 8506,
        Zusatzziffer: 0,
        Gemeindename: 'Herdern',
        'BFS-Nr': 4811,
        Kantonskürzel: 'TG',
        E: 8.937919598385436,
        N: 47.62329906642247,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Herdern',
        PLZ: 8535,
        Zusatzziffer: 0,
        Gemeindename: 'Herdern',
        'BFS-Nr': 4811,
        Kantonskürzel: 'TG',
        E: 8.909545310134,
        N: 47.6048102690946,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Hörhausen',
        PLZ: 8507,
        Zusatzziffer: 0,
        Gemeindename: 'Homburg',
        'BFS-Nr': 4816,
        Kantonskürzel: 'TG',
        E: 8.969876094902135,
        N: 47.63214074184606,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Homburg',
        PLZ: 8508,
        Zusatzziffer: 0,
        Gemeindename: 'Homburg',
        'BFS-Nr': 4816,
        Kantonskürzel: 'TG',
        E: 9.008433223797539,
        N: 47.63514100022467,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Hüttwilen',
        PLZ: 8536,
        Zusatzziffer: 0,
        Gemeindename: 'Hüttwilen',
        'BFS-Nr': 4821,
        Kantonskürzel: 'TG',
        E: 8.873221286901549,
        N: 47.608908471403986,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Nussbaumen TG',
        PLZ: 8537,
        Zusatzziffer: 0,
        Gemeindename: 'Hüttwilen',
        'BFS-Nr': 4821,
        Kantonskürzel: 'TG',
        E: 8.829533059235704,
        N: 47.627084219296535,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Uerschhausen',
        PLZ: 8537,
        Zusatzziffer: 2,
        Gemeindename: 'Hüttwilen',
        'BFS-Nr': 4821,
        Kantonskürzel: 'TG',
        E: 8.817739116474101,
        N: 47.609684039089935,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Mammern',
        PLZ: 8265,
        Zusatzziffer: 0,
        Gemeindename: 'Mammern',
        'BFS-Nr': 4826,
        Kantonskürzel: 'TG',
        E: 8.916934746606067,
        N: 47.64582254933177,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Müllheim Dorf',
        PLZ: 8555,
        Zusatzziffer: 0,
        Gemeindename: 'Müllheim',
        'BFS-Nr': 4831,
        Kantonskürzel: 'TG',
        E: 9.010453574735648,
        N: 47.603168325055584,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Pfyn',
        PLZ: 8505,
        Zusatzziffer: 0,
        Gemeindename: 'Pfyn',
        'BFS-Nr': 4841,
        Kantonskürzel: 'TG',
        E: 8.961350447118946,
        N: 47.5943756675395,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Dettighofen',
        PLZ: 8505,
        Zusatzziffer: 2,
        Gemeindename: 'Pfyn',
        'BFS-Nr': 4841,
        Kantonskürzel: 'TG',
        E: 8.949232922249726,
        N: 47.61674349048514,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Raperswilen',
        PLZ: 8558,
        Zusatzziffer: 0,
        Gemeindename: 'Raperswilen',
        'BFS-Nr': 4846,
        Kantonskürzel: 'TG',
        E: 9.042383130365128,
        N: 47.63224859206152,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Mannenbach-Salenstein',
        PLZ: 8268,
        Zusatzziffer: 0,
        Gemeindename: 'Salenstein',
        'BFS-Nr': 4851,
        Kantonskürzel: 'TG',
        E: 9.052818736821678,
        N: 47.673653271680315,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Salenstein',
        PLZ: 8268,
        Zusatzziffer: 2,
        Gemeindename: 'Salenstein',
        'BFS-Nr': 4851,
        Kantonskürzel: 'TG',
        E: 9.058663333874675,
        N: 47.66892505155855,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Fruthwilen',
        PLZ: 8269,
        Zusatzziffer: 0,
        Gemeindename: 'Salenstein',
        'BFS-Nr': 4851,
        Kantonskürzel: 'TG',
        E: 9.066362836556873,
        N: 47.660821763475205,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Steckborn',
        PLZ: 8266,
        Zusatzziffer: 0,
        Gemeindename: 'Steckborn',
        'BFS-Nr': 4864,
        Kantonskürzel: 'TG',
        E: 8.983403285234761,
        N: 47.66674691512679,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Kaltenbach',
        PLZ: 8259,
        Zusatzziffer: 0,
        Gemeindename: 'Wagenhausen',
        'BFS-Nr': 4871,
        Kantonskürzel: 'TG',
        E: 8.842772881050118,
        N: 47.65311939258672,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Etzwilen',
        PLZ: 8259,
        Zusatzziffer: 2,
        Gemeindename: 'Wagenhausen',
        'BFS-Nr': 4871,
        Kantonskürzel: 'TG',
        E: 8.81821917985859,
        N: 47.66124123126186,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Rheinklingen',
        PLZ: 8259,
        Zusatzziffer: 3,
        Gemeindename: 'Wagenhausen',
        'BFS-Nr': 4871,
        Kantonskürzel: 'TG',
        E: 8.807340517527816,
        N: 47.675032658623515,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Wagenhausen',
        PLZ: 8259,
        Zusatzziffer: 4,
        Gemeindename: 'Wagenhausen',
        'BFS-Nr': 4871,
        Kantonskürzel: 'TG',
        E: 8.848168779157906,
        N: 47.66164728615381,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Amlikon-Bissegg',
        PLZ: 8514,
        Zusatzziffer: 0,
        Gemeindename: 'Amlikon-Bissegg',
        'BFS-Nr': 4881,
        Kantonskürzel: 'TG',
        E: 9.048579405290678,
        N: 47.56591680954591,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Berg TG',
        PLZ: 8572,
        Zusatzziffer: 0,
        Gemeindename: 'Berg (TG)',
        'BFS-Nr': 4891,
        Kantonskürzel: 'TG',
        E: 9.196925671796281,
        N: 47.59740584966701,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Berg TG',
        PLZ: 8572,
        Zusatzziffer: 0,
        Gemeindename: 'Berg (TG)',
        'BFS-Nr': 4891,
        Kantonskürzel: 'TG',
        E: 9.165227303185421,
        N: 47.57816196368055,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Andhausen',
        PLZ: 8572,
        Zusatzziffer: 2,
        Gemeindename: 'Berg (TG)',
        'BFS-Nr': 4891,
        Kantonskürzel: 'TG',
        E: 9.179675804844672,
        N: 47.57801321761557,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Graltshausen',
        PLZ: 8572,
        Zusatzziffer: 3,
        Gemeindename: 'Berg (TG)',
        'BFS-Nr': 4891,
        Kantonskürzel: 'TG',
        E: 9.182378332231453,
        N: 47.59587583316972,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Guntershausen b. Berg',
        PLZ: 8572,
        Zusatzziffer: 4,
        Gemeindename: 'Berg (TG)',
        'BFS-Nr': 4891,
        Kantonskürzel: 'TG',
        E: 9.188584622904994,
        N: 47.57220182064546,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Mauren TG',
        PLZ: 8576,
        Zusatzziffer: 0,
        Gemeindename: 'Berg (TG)',
        'BFS-Nr': 4891,
        Kantonskürzel: 'TG',
        E: 9.151774698029678,
        N: 47.566534128748835,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Mattwil',
        PLZ: 8585,
        Zusatzziffer: 1,
        Gemeindename: 'Birwinken',
        'BFS-Nr': 4901,
        Kantonskürzel: 'TG',
        E: 9.206371405775391,
        N: 47.57767063945402,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Happerswil',
        PLZ: 8585,
        Zusatzziffer: 2,
        Gemeindename: 'Birwinken',
        'BFS-Nr': 4901,
        Kantonskürzel: 'TG',
        E: 9.222779303879152,
        N: 47.57780257256844,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Birwinken',
        PLZ: 8585,
        Zusatzziffer: 9,
        Gemeindename: 'Birwinken',
        'BFS-Nr': 4901,
        Kantonskürzel: 'TG',
        E: 9.197325671310471,
        N: 47.58244587681413,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Klarsreuti',
        PLZ: 8585,
        Zusatzziffer: 10,
        Gemeindename: 'Birwinken',
        'BFS-Nr': 4901,
        Kantonskürzel: 'TG',
        E: 9.213107850698043,
        N: 47.58772491303195,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Andwil TG',
        PLZ: 8586,
        Zusatzziffer: 2,
        Gemeindename: 'Birwinken',
        'BFS-Nr': 4901,
        Kantonskürzel: 'TG',
        E: 9.215760923428395,
        N: 47.56573043991069,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Buch b. Kümmertshausen',
        PLZ: 8586,
        Zusatzziffer: 10,
        Gemeindename: 'Birwinken',
        'BFS-Nr': 4901,
        Kantonskürzel: 'TG',
        E: 9.235046224131791,
        N: 47.571071712556865,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Bürglen TG',
        PLZ: 8575,
        Zusatzziffer: 0,
        Gemeindename: 'Bürglen (TG)',
        'BFS-Nr': 4911,
        Kantonskürzel: 'TG',
        E: 9.154211128216037,
        N: 47.547215582822005,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Istighofen',
        PLZ: 8575,
        Zusatzziffer: 1,
        Gemeindename: 'Bürglen (TG)',
        'BFS-Nr': 4911,
        Kantonskürzel: 'TG',
        E: 9.148595409537435,
        N: 47.540959601586586,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Leimbach TG',
        PLZ: 8584,
        Zusatzziffer: 0,
        Gemeindename: 'Bürglen (TG)',
        'BFS-Nr': 4911,
        Kantonskürzel: 'TG',
        E: 9.187964021086458,
        N: 47.56109336989759,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Opfershofen TG',
        PLZ: 8584,
        Zusatzziffer: 1,
        Gemeindename: 'Bürglen (TG)',
        'BFS-Nr': 4911,
        Kantonskürzel: 'TG',
        E: 9.171823639321456,
        N: 47.560424210712654,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Stehrenberg',
        PLZ: 9503,
        Zusatzziffer: 0,
        Gemeindename: 'Bussnang',
        'BFS-Nr': 4921,
        Kantonskürzel: 'TG',
        E: 9.088407456822807,
        N: 47.523224019682225,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Lanterswil',
        PLZ: 9503,
        Zusatzziffer: 2,
        Gemeindename: 'Bussnang',
        'BFS-Nr': 4921,
        Kantonskürzel: 'TG',
        E: 9.096543848112773,
        N: 47.520773561262644,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Friltschen',
        PLZ: 9504,
        Zusatzziffer: 0,
        Gemeindename: 'Bussnang',
        'BFS-Nr': 4921,
        Kantonskürzel: 'TG',
        E: 9.0852014977165,
        N: 47.53862602347549,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Mettlen',
        PLZ: 9517,
        Zusatzziffer: 0,
        Gemeindename: 'Bussnang',
        'BFS-Nr': 4921,
        Kantonskürzel: 'TG',
        E: 9.120333051264968,
        N: 47.53293950191317,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Bussnang',
        PLZ: 9565,
        Zusatzziffer: 0,
        Gemeindename: 'Bussnang',
        'BFS-Nr': 4921,
        Kantonskürzel: 'TG',
        E: 9.08194516781135,
        N: 47.557829251403625,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Oberbussnang',
        PLZ: 9565,
        Zusatzziffer: 2,
        Gemeindename: 'Bussnang',
        'BFS-Nr': 4921,
        Kantonskürzel: 'TG',
        E: 9.087191201372516,
        N: 47.548263436002074,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Oppikon',
        PLZ: 9565,
        Zusatzziffer: 3,
        Gemeindename: 'Bussnang',
        'BFS-Nr': 4921,
        Kantonskürzel: 'TG',
        E: 9.061001394911381,
        N: 47.55071108477447,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Schmidshof',
        PLZ: 9565,
        Zusatzziffer: 4,
        Gemeindename: 'Bussnang',
        'BFS-Nr': 4921,
        Kantonskürzel: 'TG',
        E: 9.054501162348519,
        N: 47.545808976480366,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Rothenhausen',
        PLZ: 9565,
        Zusatzziffer: 5,
        Gemeindename: 'Bussnang',
        'BFS-Nr': 4921,
        Kantonskürzel: 'TG',
        E: 9.102830459168729,
        N: 47.549309264146956,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Märstetten',
        PLZ: 8560,
        Zusatzziffer: 0,
        Gemeindename: 'Märstetten',
        'BFS-Nr': 4941,
        Kantonskürzel: 'TG',
        E: 9.069275600920465,
        N: 47.59392402550366,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Ottoberg',
        PLZ: 8561,
        Zusatzziffer: 0,
        Gemeindename: 'Märstetten',
        'BFS-Nr': 4941,
        Kantonskürzel: 'TG',
        E: 9.085508031967453,
        N: 47.58733345266248,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Weinfelden',
        PLZ: 8570,
        Zusatzziffer: 0,
        Gemeindename: 'Weinfelden',
        'BFS-Nr': 4946,
        Kantonskürzel: 'TG',
        E: 9.111270745411433,
        N: 47.56806940159633,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Müllheim-Wigoltingen',
        PLZ: 8554,
        Zusatzziffer: 0,
        Gemeindename: 'Wigoltingen',
        'BFS-Nr': 4951,
        Kantonskürzel: 'TG',
        E: 9.015549472292543,
        N: 47.58813503053181,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Bonau',
        PLZ: 8554,
        Zusatzziffer: 3,
        Gemeindename: 'Wigoltingen',
        'BFS-Nr': 4951,
        Kantonskürzel: 'TG',
        E: 9.04435606801704,
        N: 47.582611433022265,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Wigoltingen',
        PLZ: 8556,
        Zusatzziffer: 0,
        Gemeindename: 'Wigoltingen',
        'BFS-Nr': 4951,
        Kantonskürzel: 'TG',
        E: 9.030963683746409,
        N: 47.595734329387206,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Engwang',
        PLZ: 8556,
        Zusatzziffer: 2,
        Gemeindename: 'Wigoltingen',
        'BFS-Nr': 4951,
        Kantonskürzel: 'TG',
        E: 9.052742830994115,
        N: 47.599989956130486,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Illhart',
        PLZ: 8556,
        Zusatzziffer: 3,
        Gemeindename: 'Wigoltingen',
        'BFS-Nr': 4951,
        Kantonskürzel: 'TG',
        E: 9.038178056442755,
        N: 47.61800318386395,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Lamperswil TG',
        PLZ: 8556,
        Zusatzziffer: 4,
        Gemeindename: 'Wigoltingen',
        'BFS-Nr': 4951,
        Kantonskürzel: 'TG',
        E: 9.038792003868505,
        N: 47.61185251088478,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Wagerswil',
        PLZ: 8564,
        Zusatzziffer: 6,
        Gemeindename: 'Wigoltingen',
        'BFS-Nr': 4951,
        Kantonskürzel: 'TG',
        E: 9.0642406166881,
        N: 47.607811975881305,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Arbedo',
        PLZ: 6517,
        Zusatzziffer: 0,
        Gemeindename: 'Arbedo-Castione',
        'BFS-Nr': 5001,
        Kantonskürzel: 'TI',
        E: 9.046483322941453,
        N: 46.21378463014271,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Castione',
        PLZ: 6532,
        Zusatzziffer: 0,
        Gemeindename: 'Arbedo-Castione',
        'BFS-Nr': 5001,
        Kantonskürzel: 'TI',
        E: 9.04740607788615,
        N: 46.22719721188734,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Bellinzona',
        PLZ: 6500,
        Zusatzziffer: 0,
        Gemeindename: 'Bellinzona',
        'BFS-Nr': 5002,
        Kantonskürzel: 'TI',
        E: 9.035526644871934,
        N: 46.19698977791154,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Bellinzona',
        PLZ: 6503,
        Zusatzziffer: 0,
        Gemeindename: 'Bellinzona',
        'BFS-Nr': 5002,
        Kantonskürzel: 'TI',
        E: 9.007086991873864,
        N: 46.208597075207415,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Giubiasco',
        PLZ: 6512,
        Zusatzziffer: 0,
        Gemeindename: 'Bellinzona',
        'BFS-Nr': 5002,
        Kantonskürzel: 'TI',
        E: 9.003398058251495,
        N: 46.1739379792302,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Monte Carasso',
        PLZ: 6513,
        Zusatzziffer: 0,
        Gemeindename: 'Bellinzona',
        'BFS-Nr': 5002,
        Kantonskürzel: 'TI',
        E: 8.997078156166623,
        N: 46.18708949720143,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Sementina',
        PLZ: 6514,
        Zusatzziffer: 0,
        Gemeindename: 'Bellinzona',
        'BFS-Nr': 5002,
        Kantonskürzel: 'TI',
        E: 8.986780908965658,
        N: 46.18305292519581,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Gudo',
        PLZ: 6515,
        Zusatzziffer: 0,
        Gemeindename: 'Bellinzona',
        'BFS-Nr': 5002,
        Kantonskürzel: 'TI',
        E: 8.951059307209748,
        N: 46.17534700071845,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Gorduno',
        PLZ: 6518,
        Zusatzziffer: 0,
        Gemeindename: 'Bellinzona',
        'BFS-Nr': 5002,
        Kantonskürzel: 'TI',
        E: 9.032401849156258,
        N: 46.21685903574508,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Preonzo',
        PLZ: 6523,
        Zusatzziffer: 0,
        Gemeindename: 'Bellinzona',
        'BFS-Nr': 5002,
        Kantonskürzel: 'TI',
        E: 9.002014996004304,
        N: 46.26349561769784,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Moleno',
        PLZ: 6524,
        Zusatzziffer: 0,
        Gemeindename: 'Bellinzona',
        'BFS-Nr': 5002,
        Kantonskürzel: 'TI',
        E: 8.9934635841617,
        N: 46.26859709399601,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Gnosca',
        PLZ: 6525,
        Zusatzziffer: 0,
        Gemeindename: 'Bellinzona',
        'BFS-Nr': 5002,
        Kantonskürzel: 'TI',
        E: 9.020463028392728,
        N: 46.2339000213862,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Camorino',
        PLZ: 6528,
        Zusatzziffer: 0,
        Gemeindename: 'Bellinzona',
        'BFS-Nr': 5002,
        Kantonskürzel: 'TI',
        E: 9.006395484115924,
        N: 46.163661623377216,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Pianezzo',
        PLZ: 6582,
        Zusatzziffer: 0,
        Gemeindename: 'Bellinzona',
        'BFS-Nr': 5002,
        Kantonskürzel: 'TI',
        E: 9.029751382736986,
        N: 46.16964625185345,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'S. Antonio (Val Morobbia)',
        PLZ: 6583,
        Zusatzziffer: 0,
        Gemeindename: 'Bellinzona',
        'BFS-Nr': 5002,
        Kantonskürzel: 'TI',
        E: 9.061327940714415,
        N: 46.16924359628216,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Carena',
        PLZ: 6584,
        Zusatzziffer: 0,
        Gemeindename: 'Bellinzona',
        'BFS-Nr': 5002,
        Kantonskürzel: 'TI',
        E: 9.085264932171674,
        N: 46.16879073072494,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Claro',
        PLZ: 6702,
        Zusatzziffer: 0,
        Gemeindename: 'Bellinzona',
        'BFS-Nr': 5002,
        Kantonskürzel: 'TI',
        E: 9.024175393329816,
        N: 46.253426227777126,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Cadenazzo',
        PLZ: 6593,
        Zusatzziffer: 0,
        Gemeindename: 'Cadenazzo',
        'BFS-Nr': 5003,
        Kantonskürzel: 'TI',
        E: 8.952044307703376,
        N: 46.14902057875978,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Robasacco',
        PLZ: 6599,
        Zusatzziffer: 0,
        Gemeindename: 'Cadenazzo',
        'BFS-Nr': 5003,
        Kantonskürzel: 'TI',
        E: 8.940581589715425,
        N: 46.14270357969352,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Isone',
        PLZ: 6810,
        Zusatzziffer: 0,
        Gemeindename: 'Isone',
        'BFS-Nr': 5009,
        Kantonskürzel: 'TI',
        E: 8.985251202399663,
        N: 46.12891353179505,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Lumino',
        PLZ: 6533,
        Zusatzziffer: 0,
        Gemeindename: 'Lumino',
        'BFS-Nr': 5010,
        Kantonskürzel: 'TI',
        E: 9.06598903984082,
        N: 46.23038035903418,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'S. Antonino',
        PLZ: 6592,
        Zusatzziffer: 0,
        Gemeindename: 'Sant\'Antonino',
        'BFS-Nr': 5017,
        Kantonskürzel: 'TI',
        E: 8.978690475702646,
        N: 46.15342160095455,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Dongio',
        PLZ: 6715,
        Zusatzziffer: 0,
        Gemeindename: 'Acquarossa',
        'BFS-Nr': 5048,
        Kantonskürzel: 'TI',
        E: 8.955370923357705,
        N: 46.43702086631229,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Acquarossa',
        PLZ: 6716,
        Zusatzziffer: 0,
        Gemeindename: 'Acquarossa',
        'BFS-Nr': 5048,
        Kantonskürzel: 'TI',
        E: 8.937067934788956,
        N: 46.4559345026929,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Leontica',
        PLZ: 6716,
        Zusatzziffer: 2,
        Gemeindename: 'Acquarossa',
        'BFS-Nr': 5048,
        Kantonskürzel: 'TI',
        E: 8.922445684405547,
        N: 46.45921354231142,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Lottigna',
        PLZ: 6716,
        Zusatzziffer: 3,
        Gemeindename: 'Acquarossa',
        'BFS-Nr': 5048,
        Kantonskürzel: 'TI',
        E: 8.943256682927348,
        N: 46.469672578814524,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Motto (Blenio)',
        PLZ: 6721,
        Zusatzziffer: 2,
        Gemeindename: 'Acquarossa',
        'BFS-Nr': 5048,
        Kantonskürzel: 'TI',
        E: 8.97128614913067,
        N: 46.42870645422291,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Corzoneso',
        PLZ: 6722,
        Zusatzziffer: 0,
        Gemeindename: 'Acquarossa',
        'BFS-Nr': 5048,
        Kantonskürzel: 'TI',
        E: 8.93423789471718,
        N: 46.44609411483294,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Prugiasco',
        PLZ: 6723,
        Zusatzziffer: 0,
        Gemeindename: 'Acquarossa',
        'BFS-Nr': 5048,
        Kantonskürzel: 'TI',
        E: 8.931809529320553,
        N: 46.4615356854235,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Castro',
        PLZ: 6723,
        Zusatzziffer: 2,
        Gemeindename: 'Acquarossa',
        'BFS-Nr': 5048,
        Kantonskürzel: 'TI',
        E: 8.927150468865497,
        N: 46.471550575756524,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Marolta',
        PLZ: 6723,
        Zusatzziffer: 3,
        Gemeindename: 'Acquarossa',
        'BFS-Nr': 5048,
        Kantonskürzel: 'TI',
        E: 8.920834308643768,
        N: 46.47894331455787,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Ponto Valentino',
        PLZ: 6724,
        Zusatzziffer: 0,
        Gemeindename: 'Acquarossa',
        'BFS-Nr': 5048,
        Kantonskürzel: 'TI',
        E: 8.935487158781076,
        N: 46.483023932188786,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Largario',
        PLZ: 6724,
        Zusatzziffer: 2,
        Gemeindename: 'Acquarossa',
        'BFS-Nr': 5048,
        Kantonskürzel: 'TI',
        E: 8.941287689914473,
        N: 46.494466373041746,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Dangio',
        PLZ: 6717,
        Zusatzziffer: 0,
        Gemeindename: 'Blenio',
        'BFS-Nr': 5049,
        Kantonskürzel: 'TI',
        E: 8.952990972434163,
        N: 46.494181381200335,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Torre',
        PLZ: 6717,
        Zusatzziffer: 1,
        Gemeindename: 'Blenio',
        'BFS-Nr': 5049,
        Kantonskürzel: 'TI',
        E: 8.953889738628847,
        N: 46.4872657753461,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Olivone',
        PLZ: 6718,
        Zusatzziffer: 0,
        Gemeindename: 'Blenio',
        'BFS-Nr': 5049,
        Kantonskürzel: 'TI',
        E: 8.941477769213451,
        N: 46.52973267582938,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Camperio',
        PLZ: 6718,
        Zusatzziffer: 1,
        Gemeindename: 'Blenio',
        'BFS-Nr': 5049,
        Kantonskürzel: 'TI',
        E: 8.906753682111269,
        N: 46.52456073262494,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Aquila',
        PLZ: 6719,
        Zusatzziffer: 0,
        Gemeindename: 'Blenio',
        'BFS-Nr': 5049,
        Kantonskürzel: 'TI',
        E: 9.01000436398699,
        N: 46.58184181969585,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Aquila',
        PLZ: 6719,
        Zusatzziffer: 0,
        Gemeindename: 'Blenio',
        'BFS-Nr': 5049,
        Kantonskürzel: 'TI',
        E: 8.948216175612293,
        N: 46.503897812682176,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Campo (Blenio)',
        PLZ: 6720,
        Zusatzziffer: 0,
        Gemeindename: 'Blenio',
        'BFS-Nr': 5049,
        Kantonskürzel: 'TI',
        E: 8.936555540717332,
        N: 46.55570845280367,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Ghirone',
        PLZ: 6720,
        Zusatzziffer: 2,
        Gemeindename: 'Blenio',
        'BFS-Nr': 5049,
        Kantonskürzel: 'TI',
        E: 8.944750441417556,
        N: 46.56076943504084,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Malvaglia',
        PLZ: 6713,
        Zusatzziffer: 0,
        Gemeindename: 'Serravalle',
        'BFS-Nr': 5050,
        Kantonskürzel: 'TI',
        E: 8.98356740064677,
        N: 46.403637316392576,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Semione',
        PLZ: 6714,
        Zusatzziffer: 0,
        Gemeindename: 'Serravalle',
        'BFS-Nr': 5050,
        Kantonskürzel: 'TI',
        E: 8.971717461291538,
        N: 46.40771039227527,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Ludiano',
        PLZ: 6721,
        Zusatzziffer: 0,
        Gemeindename: 'Serravalle',
        'BFS-Nr': 5050,
        Kantonskürzel: 'TI',
        E: 8.969310097663834,
        N: 46.41999643532533,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Airolo',
        PLZ: 6780,
        Zusatzziffer: 0,
        Gemeindename: 'Airolo',
        'BFS-Nr': 5061,
        Kantonskürzel: 'TI',
        E: 8.609216703177065,
        N: 46.52910380139051,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Madrano',
        PLZ: 6780,
        Zusatzziffer: 2,
        Gemeindename: 'Airolo',
        'BFS-Nr': 5061,
        Kantonskürzel: 'TI',
        E: 8.62959636932561,
        N: 46.527146297326155,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Villa Bedretto',
        PLZ: 6781,
        Zusatzziffer: 1,
        Gemeindename: 'Bedretto',
        'BFS-Nr': 5063,
        Kantonskürzel: 'TI',
        E: 8.525214301922292,
        N: 46.509976591167174,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Bedretto',
        PLZ: 6781,
        Zusatzziffer: 4,
        Gemeindename: 'Bedretto',
        'BFS-Nr': 5063,
        Kantonskürzel: 'TI',
        E: 8.511692296753424,
        N: 46.50610371711352,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Bodio TI',
        PLZ: 6743,
        Zusatzziffer: 0,
        Gemeindename: 'Bodio',
        'BFS-Nr': 5064,
        Kantonskürzel: 'TI',
        E: 8.91179709309504,
        N: 46.37907495488275,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Dalpe',
        PLZ: 6774,
        Zusatzziffer: 0,
        Gemeindename: 'Dalpe',
        'BFS-Nr': 5071,
        Kantonskürzel: 'TI',
        E: 8.775088647091815,
        N: 46.473016469209426,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Lavorgo',
        PLZ: 6746,
        Zusatzziffer: 0,
        Gemeindename: 'Faido',
        'BFS-Nr': 5072,
        Kantonskürzel: 'TI',
        E: 8.840889584261701,
        N: 46.441740586356126,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Calonico',
        PLZ: 6746,
        Zusatzziffer: 1,
        Gemeindename: 'Faido',
        'BFS-Nr': 5072,
        Kantonskürzel: 'TI',
        E: 8.841127066949593,
        N: 46.450732184539106,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Nivo',
        PLZ: 6746,
        Zusatzziffer: 2,
        Gemeindename: 'Faido',
        'BFS-Nr': 5072,
        Kantonskürzel: 'TI',
        E: 8.842309469982338,
        N: 46.436164801529756,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Chironico',
        PLZ: 6747,
        Zusatzziffer: 0,
        Gemeindename: 'Faido',
        'BFS-Nr': 5072,
        Kantonskürzel: 'TI',
        E: 8.84381507440122,
        N: 46.42309899791355,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Anzonico',
        PLZ: 6748,
        Zusatzziffer: 0,
        Gemeindename: 'Faido',
        'BFS-Nr': 5072,
        Kantonskürzel: 'TI',
        E: 8.8627778335313,
        N: 46.43160853693149,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Sobrio',
        PLZ: 6749,
        Zusatzziffer: 0,
        Gemeindename: 'Faido',
        'BFS-Nr': 5072,
        Kantonskürzel: 'TI',
        E: 8.901668353760808,
        N: 46.399971792153806,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Cavagnago',
        PLZ: 6749,
        Zusatzziffer: 1,
        Gemeindename: 'Faido',
        'BFS-Nr': 5072,
        Kantonskürzel: 'TI',
        E: 8.88129103092049,
        N: 46.4135069485264,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Faido',
        PLZ: 6760,
        Zusatzziffer: 0,
        Gemeindename: 'Faido',
        'BFS-Nr': 5072,
        Kantonskürzel: 'TI',
        E: 8.797847643091195,
        N: 46.478805356702146,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Molare',
        PLZ: 6760,
        Zusatzziffer: 1,
        Gemeindename: 'Faido',
        'BFS-Nr': 5072,
        Kantonskürzel: 'TI',
        E: 8.830342346689585,
        N: 46.48614240354303,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Calpiogna',
        PLZ: 6760,
        Zusatzziffer: 2,
        Gemeindename: 'Faido',
        'BFS-Nr': 5072,
        Kantonskürzel: 'TI',
        E: 8.80540736423923,
        N: 46.486948142773514,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Campello',
        PLZ: 6760,
        Zusatzziffer: 3,
        Gemeindename: 'Faido',
        'BFS-Nr': 5072,
        Kantonskürzel: 'TI',
        E: 8.818638991817568,
        N: 46.486908928211925,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Carì',
        PLZ: 6760,
        Zusatzziffer: 4,
        Gemeindename: 'Faido',
        'BFS-Nr': 5072,
        Kantonskürzel: 'TI',
        E: 8.821490480263932,
        N: 46.494174380302134,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Rossura',
        PLZ: 6760,
        Zusatzziffer: 5,
        Gemeindename: 'Faido',
        'BFS-Nr': 5072,
        Kantonskürzel: 'TI',
        E: 8.82505515811374,
        N: 46.47535346791667,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Osco',
        PLZ: 6763,
        Zusatzziffer: 0,
        Gemeindename: 'Faido',
        'BFS-Nr': 5072,
        Kantonskürzel: 'TI',
        E: 8.781184915542417,
        N: 46.493452598631045,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Mairengo',
        PLZ: 6763,
        Zusatzziffer: 1,
        Gemeindename: 'Faido',
        'BFS-Nr': 5072,
        Kantonskürzel: 'TI',
        E: 8.787776732641229,
        N: 46.487597764086,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Chiggiogna',
        PLZ: 6764,
        Zusatzziffer: 0,
        Gemeindename: 'Faido',
        'BFS-Nr': 5072,
        Kantonskürzel: 'TI',
        E: 8.822346372076876,
        N: 46.467579574293225,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Giornico',
        PLZ: 6745,
        Zusatzziffer: 0,
        Gemeindename: 'Giornico',
        'BFS-Nr': 5073,
        Kantonskürzel: 'TI',
        E: 8.875042946162022,
        N: 46.39980724554578,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Personico',
        PLZ: 6744,
        Zusatzziffer: 0,
        Gemeindename: 'Personico',
        'BFS-Nr': 5076,
        Kantonskürzel: 'TI',
        E: 8.919402408853266,
        N: 46.37044926776556,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Pollegio',
        PLZ: 6742,
        Zusatzziffer: 0,
        Gemeindename: 'Pollegio',
        'BFS-Nr': 5077,
        Kantonskürzel: 'TI',
        E: 8.941482065882647,
        N: 46.365421026349196,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Rodi-Fiesso',
        PLZ: 6772,
        Zusatzziffer: 0,
        Gemeindename: 'Prato (Leventina)',
        'BFS-Nr': 5078,
        Kantonskürzel: 'TI',
        E: 8.736090881724873,
        N: 46.491888166698885,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Prato (Leventina)',
        PLZ: 6773,
        Zusatzziffer: 0,
        Gemeindename: 'Prato (Leventina)',
        'BFS-Nr': 5078,
        Kantonskürzel: 'TI',
        E: 8.756553498007134,
        N: 46.48282632454772,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Ambrì',
        PLZ: 6775,
        Zusatzziffer: 0,
        Gemeindename: 'Quinto',
        'BFS-Nr': 5079,
        Kantonskürzel: 'TI',
        E: 8.695041801939292,
        N: 46.50912677834657,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Piotta',
        PLZ: 6776,
        Zusatzziffer: 0,
        Gemeindename: 'Quinto',
        'BFS-Nr': 5079,
        Kantonskürzel: 'TI',
        E: 8.671231696873184,
        N: 46.514021611492524,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Quinto',
        PLZ: 6777,
        Zusatzziffer: 0,
        Gemeindename: 'Quinto',
        'BFS-Nr': 5079,
        Kantonskürzel: 'TI',
        E: 8.711391586522481,
        N: 46.51099217081296,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Varenzo',
        PLZ: 6777,
        Zusatzziffer: 1,
        Gemeindename: 'Quinto',
        'BFS-Nr': 5079,
        Kantonskürzel: 'TI',
        E: 8.724347007368404,
        N: 46.50307641924373,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Ascona',
        PLZ: 6612,
        Zusatzziffer: 0,
        Gemeindename: 'Ascona',
        'BFS-Nr': 5091,
        Kantonskürzel: 'TI',
        E: 8.767949628964413,
        N: 46.15493370771035,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Brione sopra Minusio',
        PLZ: 6645,
        Zusatzziffer: 0,
        Gemeindename: 'Brione sopra Minusio',
        'BFS-Nr': 5096,
        Kantonskürzel: 'TI',
        E: 8.814466587308377,
        N: 46.183023778577635,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Brissago',
        PLZ: 6614,
        Zusatzziffer: 0,
        Gemeindename: 'Brissago',
        'BFS-Nr': 5097,
        Kantonskürzel: 'TI',
        E: 8.710822713493195,
        N: 46.11862131347223,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Isole di Brissago',
        PLZ: 6614,
        Zusatzziffer: 2,
        Gemeindename: 'Brissago',
        'BFS-Nr': 5097,
        Kantonskürzel: 'TI',
        E: 8.73520182578435,
        N: 46.13226357451826,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Gordola',
        PLZ: 6596,
        Zusatzziffer: 0,
        Gemeindename: 'Gordola',
        'BFS-Nr': 5108,
        Kantonskürzel: 'TI',
        E: 8.865256030066272,
        N: 46.17921912258844,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Riazzino',
        PLZ: 6595,
        Zusatzziffer: 0,
        Gemeindename: 'Lavertezzo',
        'BFS-Nr': 5112,
        Kantonskürzel: 'TI',
        E: 8.888018904602975,
        N: 46.17834971305667,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Locarno',
        PLZ: 6600,
        Zusatzziffer: 0,
        Gemeindename: 'Locarno',
        'BFS-Nr': 5113,
        Kantonskürzel: 'TI',
        E: 8.805017245806203,
        N: 46.16025356713298,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Locarno',
        PLZ: 6600,
        Zusatzziffer: 0,
        Gemeindename: 'Locarno',
        'BFS-Nr': 5113,
        Kantonskürzel: 'TI',
        E: 8.781813585354604,
        N: 46.19082887229135,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Solduno',
        PLZ: 6600,
        Zusatzziffer: 4,
        Gemeindename: 'Locarno',
        'BFS-Nr': 5113,
        Kantonskürzel: 'TI',
        E: 8.778689486191054,
        N: 46.171048538719276,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Locarno',
        PLZ: 6605,
        Zusatzziffer: 0,
        Gemeindename: 'Locarno',
        'BFS-Nr': 5113,
        Kantonskürzel: 'TI',
        E: 8.777799825711222,
        N: 46.185066563015425,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Losone',
        PLZ: 6616,
        Zusatzziffer: 0,
        Gemeindename: 'Losone',
        'BFS-Nr': 5115,
        Kantonskürzel: 'TI',
        E: 8.759675127293068,
        N: 46.16934155640677,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Arcegno',
        PLZ: 6618,
        Zusatzziffer: 0,
        Gemeindename: 'Losone',
        'BFS-Nr': 5115,
        Kantonskürzel: 'TI',
        E: 8.742847549194884,
        N: 46.16239962828717,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Mergoscia',
        PLZ: 6647,
        Zusatzziffer: 0,
        Gemeindename: 'Mergoscia',
        'BFS-Nr': 5117,
        Kantonskürzel: 'TI',
        E: 8.848231783159855,
        N: 46.210659024352665,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Minusio',
        PLZ: 6648,
        Zusatzziffer: 0,
        Gemeindename: 'Minusio',
        'BFS-Nr': 5118,
        Kantonskürzel: 'TI',
        E: 8.813030987379875,
        N: 46.17672012805742,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Muralto',
        PLZ: 6600,
        Zusatzziffer: 2,
        Gemeindename: 'Muralto',
        'BFS-Nr': 5120,
        Kantonskürzel: 'TI',
        E: 8.805271857981886,
        N: 46.172692486712585,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Orselina',
        PLZ: 6644,
        Zusatzziffer: 0,
        Gemeindename: 'Orselina',
        'BFS-Nr': 5121,
        Kantonskürzel: 'TI',
        E: 8.800197358197693,
        N: 46.18010235262061,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Porto Ronco',
        PLZ: 6613,
        Zusatzziffer: 0,
        Gemeindename: 'Ronco sopra Ascona',
        'BFS-Nr': 5125,
        Kantonskürzel: 'TI',
        E: 8.726263402469632,
        N: 46.1402908681521,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Ronco sopra Ascona',
        PLZ: 6622,
        Zusatzziffer: 0,
        Gemeindename: 'Ronco sopra Ascona',
        'BFS-Nr': 5125,
        Kantonskürzel: 'TI',
        E: 8.726471760303943,
        N: 46.14409082790642,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Tenero',
        PLZ: 6598,
        Zusatzziffer: 0,
        Gemeindename: 'Tenero-Contra',
        'BFS-Nr': 5131,
        Kantonskürzel: 'TI',
        E: 8.848489862119164,
        N: 46.18125743988235,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Contra',
        PLZ: 6646,
        Zusatzziffer: 0,
        Gemeindename: 'Tenero-Contra',
        'BFS-Nr': 5131,
        Kantonskürzel: 'TI',
        E: 8.84024025040967,
        N: 46.18848872111908,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Mosogno',
        PLZ: 6611,
        Zusatzziffer: 13,
        Gemeindename: 'Onsernone',
        'BFS-Nr': 5136,
        Kantonskürzel: 'TI',
        E: 8.639082179478324,
        N: 46.19883289374469,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Gresso',
        PLZ: 6611,
        Zusatzziffer: 16,
        Gemeindename: 'Onsernone',
        'BFS-Nr': 5136,
        Kantonskürzel: 'TI',
        E: 8.616025136795804,
        N: 46.2249399235427,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Crana',
        PLZ: 6611,
        Zusatzziffer: 17,
        Gemeindename: 'Onsernone',
        'BFS-Nr': 5136,
        Kantonskürzel: 'TI',
        E: 8.611831326915983,
        N: 46.20353971021533,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Loco',
        PLZ: 6661,
        Zusatzziffer: 0,
        Gemeindename: 'Onsernone',
        'BFS-Nr': 5136,
        Kantonskürzel: 'TI',
        E: 8.671835627241549,
        N: 46.20245238665996,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Auressio',
        PLZ: 6661,
        Zusatzziffer: 2,
        Gemeindename: 'Onsernone',
        'BFS-Nr': 5136,
        Kantonskürzel: 'TI',
        E: 8.684483538168516,
        N: 46.20159121197452,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Berzona',
        PLZ: 6661,
        Zusatzziffer: 3,
        Gemeindename: 'Onsernone',
        'BFS-Nr': 5136,
        Kantonskürzel: 'TI',
        E: 8.662244181947344,
        N: 46.204794008572144,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Russo',
        PLZ: 6662,
        Zusatzziffer: 0,
        Gemeindename: 'Onsernone',
        'BFS-Nr': 5136,
        Kantonskürzel: 'TI',
        E: 8.62224569891745,
        N: 46.202127704426765,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Comologno',
        PLZ: 6663,
        Zusatzziffer: 0,
        Gemeindename: 'Onsernone',
        'BFS-Nr': 5136,
        Kantonskürzel: 'TI',
        E: 8.57685120288962,
        N: 46.203645227577695,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Spruga',
        PLZ: 6663,
        Zusatzziffer: 2,
        Gemeindename: 'Onsernone',
        'BFS-Nr': 5136,
        Kantonskürzel: 'TI',
        E: 8.568631066847919,
        N: 46.20117379374649,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Vergeletto',
        PLZ: 6664,
        Zusatzziffer: 0,
        Gemeindename: 'Onsernone',
        'BFS-Nr': 5136,
        Kantonskürzel: 'TI',
        E: 8.602230016254202,
        N: 46.22692914928863,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Cugnasco',
        PLZ: 6516,
        Zusatzziffer: 0,
        Gemeindename: 'Cugnasco-Gerra',
        'BFS-Nr': 5138,
        Kantonskürzel: 'TI',
        E: 8.91749871818584,
        N: 46.17418892706802,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Agarone',
        PLZ: 6597,
        Zusatzziffer: 0,
        Gemeindename: 'Cugnasco-Gerra',
        'BFS-Nr': 5138,
        Kantonskürzel: 'TI',
        E: 8.90585751513676,
        N: 46.17843861213426,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Agno',
        PLZ: 6982,
        Zusatzziffer: 0,
        Gemeindename: 'Agno',
        'BFS-Nr': 5141,
        Kantonskürzel: 'TI',
        E: 8.897706145886302,
        N: 45.99766144876942,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Cassina d\'Agno',
        PLZ: 6990,
        Zusatzziffer: 0,
        Gemeindename: 'Agno',
        'BFS-Nr': 5141,
        Kantonskürzel: 'TI',
        E: 8.893055569519559,
        N: 45.99117241197874,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Aranno',
        PLZ: 6994,
        Zusatzziffer: 0,
        Gemeindename: 'Aranno',
        'BFS-Nr': 5143,
        Kantonskürzel: 'TI',
        E: 8.873080156850834,
        N: 46.01790880076462,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Arogno',
        PLZ: 6822,
        Zusatzziffer: 0,
        Gemeindename: 'Arogno',
        'BFS-Nr': 5144,
        Kantonskürzel: 'TI',
        E: 8.988567500353467,
        N: 45.959587595302935,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Pugerna',
        PLZ: 6823,
        Zusatzziffer: 0,
        Gemeindename: 'Arogno',
        'BFS-Nr': 5144,
        Kantonskürzel: 'TI',
        E: 8.978763578873776,
        N: 45.97879808837881,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Astano',
        PLZ: 6999,
        Zusatzziffer: 0,
        Gemeindename: 'Astano',
        'BFS-Nr': 5146,
        Kantonskürzel: 'TI',
        E: 8.816226897192205,
        N: 46.01269991726358,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Bedano',
        PLZ: 6930,
        Zusatzziffer: 0,
        Gemeindename: 'Bedano',
        'BFS-Nr': 5148,
        Kantonskürzel: 'TI',
        E: 8.919360090682176,
        N: 46.05135605962671,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Bedigliora',
        PLZ: 6981,
        Zusatzziffer: 11,
        Gemeindename: 'Bedigliora',
        'BFS-Nr': 5149,
        Kantonskürzel: 'TI',
        E: 8.842428267445053,
        N: 46.00255893341802,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Bedigliora',
        PLZ: 6981,
        Zusatzziffer: 11,
        Gemeindename: 'Bedigliora',
        'BFS-Nr': 5149,
        Kantonskürzel: 'TI',
        E: 8.82867019763616,
        N: 46.02174296441272,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Banco',
        PLZ: 6981,
        Zusatzziffer: 16,
        Gemeindename: 'Bedigliora',
        'BFS-Nr': 5149,
        Kantonskürzel: 'TI',
        E: 8.843291554188417,
        N: 46.01055668046681,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Beride di Bedigliora',
        PLZ: 6981,
        Zusatzziffer: 17,
        Gemeindename: 'Bedigliora',
        'BFS-Nr': 5149,
        Kantonskürzel: 'TI',
        E: 8.833038249288315,
        N: 46.002088308135654,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Bioggio',
        PLZ: 6934,
        Zusatzziffer: 0,
        Gemeindename: 'Bioggio',
        'BFS-Nr': 5151,
        Kantonskürzel: 'TI',
        E: 8.908674137871728,
        N: 46.01446590939831,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Bosco Luganese',
        PLZ: 6935,
        Zusatzziffer: 0,
        Gemeindename: 'Bioggio',
        'BFS-Nr': 5151,
        Kantonskürzel: 'TI',
        E: 8.908884604277194,
        N: 46.0263809660364,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Cimo',
        PLZ: 6992,
        Zusatzziffer: 1,
        Gemeindename: 'Bioggio',
        'BFS-Nr': 5151,
        Kantonskürzel: 'TI',
        E: 8.889841160107423,
        N: 46.00211736341793,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Iseo',
        PLZ: 6993,
        Zusatzziffer: 0,
        Gemeindename: 'Bioggio',
        'BFS-Nr': 5151,
        Kantonskürzel: 'TI',
        E: 8.882283237308535,
        N: 46.00462763929545,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Bissone',
        PLZ: 6816,
        Zusatzziffer: 0,
        Gemeindename: 'Bissone',
        'BFS-Nr': 5154,
        Kantonskürzel: 'TI',
        E: 8.964355679704687,
        N: 45.951286804307216,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Brusino Arsizio',
        PLZ: 6827,
        Zusatzziffer: 0,
        Gemeindename: 'Brusino Arsizio',
        'BFS-Nr': 5160,
        Kantonskürzel: 'TI',
        E: 8.937731239599916,
        N: 45.92884232087363,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Serpiano',
        PLZ: 6867,
        Zusatzziffer: 0,
        Gemeindename: 'Brusino Arsizio',
        'BFS-Nr': 5160,
        Kantonskürzel: 'TI',
        E: 8.928919512965344,
        N: 45.911831994645205,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Cademario',
        PLZ: 6936,
        Zusatzziffer: 0,
        Gemeindename: 'Cademario',
        'BFS-Nr': 5161,
        Kantonskürzel: 'TI',
        E: 8.892348739138312,
        N: 46.02270541430658,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Cadempino',
        PLZ: 6814,
        Zusatzziffer: 3,
        Gemeindename: 'Cadempino',
        'BFS-Nr': 5162,
        Kantonskürzel: 'TI',
        E: 8.934516353981111,
        N: 46.03373214759384,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Canobbio',
        PLZ: 6952,
        Zusatzziffer: 0,
        Gemeindename: 'Canobbio',
        'BFS-Nr': 5167,
        Kantonskürzel: 'TI',
        E: 8.968644557286353,
        N: 46.03365828787607,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Caslano',
        PLZ: 6987,
        Zusatzziffer: 0,
        Gemeindename: 'Caslano',
        'BFS-Nr': 5171,
        Kantonskürzel: 'TI',
        E: 8.883458075294463,
        N: 45.97195418412666,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Comano',
        PLZ: 6949,
        Zusatzziffer: 0,
        Gemeindename: 'Comano',
        'BFS-Nr': 5176,
        Kantonskürzel: 'TI',
        E: 8.955061070611796,
        N: 46.03598982219133,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Cureglia',
        PLZ: 6944,
        Zusatzziffer: 0,
        Gemeindename: 'Cureglia',
        'BFS-Nr': 5180,
        Kantonskürzel: 'TI',
        E: 8.944538439470577,
        N: 46.03704672900873,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Bombinasco',
        PLZ: 6981,
        Zusatzziffer: 15,
        Gemeindename: 'Curio',
        'BFS-Nr': 5181,
        Kantonskürzel: 'TI',
        E: 8.833426976606102,
        N: 46.01404858092222,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Curio',
        PLZ: 6986,
        Zusatzziffer: 3,
        Gemeindename: 'Curio',
        'BFS-Nr': 5181,
        Kantonskürzel: 'TI',
        E: 8.862969838314484,
        N: 46.001528820890734,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Grancia',
        PLZ: 6916,
        Zusatzziffer: 0,
        Gemeindename: 'Grancia',
        'BFS-Nr': 5186,
        Kantonskürzel: 'TI',
        E: 8.928871313385098,
        N: 45.96698653003652,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Gravesano',
        PLZ: 6929,
        Zusatzziffer: 0,
        Gemeindename: 'Gravesano',
        'BFS-Nr': 5187,
        Kantonskürzel: 'TI',
        E: 8.916687752500549,
        N: 46.04274160805792,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Lamone',
        PLZ: 6814,
        Zusatzziffer: 2,
        Gemeindename: 'Lamone',
        'BFS-Nr': 5189,
        Kantonskürzel: 'TI',
        E: 8.931263107703833,
        N: 46.04435261594592,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Lugano',
        PLZ: 6900,
        Zusatzziffer: 0,
        Gemeindename: 'Lugano',
        'BFS-Nr': 5192,
        Kantonskürzel: 'TI',
        E: 8.946559808668052,
        N: 46.00540441379396,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Pazzallo',
        PLZ: 6912,
        Zusatzziffer: 0,
        Gemeindename: 'Lugano',
        'BFS-Nr': 5192,
        Kantonskürzel: 'TI',
        E: 8.943063155183419,
        N: 45.984706830839954,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Carabbia',
        PLZ: 6913,
        Zusatzziffer: 0,
        Gemeindename: 'Lugano',
        'BFS-Nr': 5192,
        Kantonskürzel: 'TI',
        E: 8.936210436777861,
        N: 45.97252478296359,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Carona',
        PLZ: 6914,
        Zusatzziffer: 0,
        Gemeindename: 'Lugano',
        'BFS-Nr': 5192,
        Kantonskürzel: 'TI',
        E: 8.935411013113592,
        N: 45.95775852727349,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Pambio-Noranco',
        PLZ: 6915,
        Zusatzziffer: 0,
        Gemeindename: 'Lugano',
        'BFS-Nr': 5192,
        Kantonskürzel: 'TI',
        E: 8.931027416622786,
        N: 45.986474083921955,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Barbengo',
        PLZ: 6917,
        Zusatzziffer: 0,
        Gemeindename: 'Lugano',
        'BFS-Nr': 5192,
        Kantonskürzel: 'TI',
        E: 8.913615549992981,
        N: 45.95825215433344,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Figino',
        PLZ: 6918,
        Zusatzziffer: 0,
        Gemeindename: 'Lugano',
        'BFS-Nr': 5192,
        Kantonskürzel: 'TI',
        E: 8.905945436426379,
        N: 45.95069063691606,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Breganzona',
        PLZ: 6932,
        Zusatzziffer: 0,
        Gemeindename: 'Lugano',
        'BFS-Nr': 5192,
        Kantonskürzel: 'TI',
        E: 8.926164395168518,
        N: 46.00853704512712,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Insone',
        PLZ: 6951,
        Zusatzziffer: 8,
        Gemeindename: 'Lugano',
        'BFS-Nr': 5192,
        Kantonskürzel: 'TI',
        E: 9.029661157382723,
        N: 46.08606622081454,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Scareglia',
        PLZ: 6951,
        Zusatzziffer: 17,
        Gemeindename: 'Lugano',
        'BFS-Nr': 5192,
        Kantonskürzel: 'TI',
        E: 9.035748377047987,
        N: 46.090201630550176,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Colla',
        PLZ: 6951,
        Zusatzziffer: 18,
        Gemeindename: 'Lugano',
        'BFS-Nr': 5192,
        Kantonskürzel: 'TI',
        E: 9.0542438887893,
        N: 46.09211677914276,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Bogno',
        PLZ: 6951,
        Zusatzziffer: 19,
        Gemeindename: 'Lugano',
        'BFS-Nr': 5192,
        Kantonskürzel: 'TI',
        E: 9.062639645371751,
        N: 46.08943928607173,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Cozzo',
        PLZ: 6951,
        Zusatzziffer: 20,
        Gemeindename: 'Lugano',
        'BFS-Nr': 5192,
        Kantonskürzel: 'TI',
        E: 9.061983593576056,
        N: 46.0971728876051,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Signôra',
        PLZ: 6951,
        Zusatzziffer: 23,
        Gemeindename: 'Lugano',
        'BFS-Nr': 5192,
        Kantonskürzel: 'TI',
        E: 9.046571874076767,
        N: 46.09238024358035,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Maglio di Colla',
        PLZ: 6959,
        Zusatzziffer: 0,
        Gemeindename: 'Lugano',
        'BFS-Nr': 5192,
        Kantonskürzel: 'TI',
        E: 9.048732515857862,
        N: 46.08863107784108,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Certara',
        PLZ: 6959,
        Zusatzziffer: 2,
        Gemeindename: 'Lugano',
        'BFS-Nr': 5192,
        Kantonskürzel: 'TI',
        E: 9.058850126267016,
        N: 46.08514985956926,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Curtina',
        PLZ: 6959,
        Zusatzziffer: 3,
        Gemeindename: 'Lugano',
        'BFS-Nr': 5192,
        Kantonskürzel: 'TI',
        E: 9.023659388603155,
        N: 46.08174331054357,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Cimadera',
        PLZ: 6959,
        Zusatzziffer: 4,
        Gemeindename: 'Lugano',
        'BFS-Nr': 5192,
        Kantonskürzel: 'TI',
        E: 9.045861490005558,
        N: 46.07885488270386,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Piandera Paese',
        PLZ: 6959,
        Zusatzziffer: 5,
        Gemeindename: 'Lugano',
        'BFS-Nr': 5192,
        Kantonskürzel: 'TI',
        E: 9.039844902995798,
        N: 46.082818855867146,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Piandera Paese',
        PLZ: 6959,
        Zusatzziffer: 5,
        Gemeindename: 'Lugano',
        'BFS-Nr': 5192,
        Kantonskürzel: 'TI',
        E: 9.03162714779163,
        N: 46.07932261027779,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Viganello',
        PLZ: 6962,
        Zusatzziffer: 0,
        Gemeindename: 'Lugano',
        'BFS-Nr': 5192,
        Kantonskürzel: 'TI',
        E: 8.967120034847161,
        N: 46.01103651806222,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Pregassona',
        PLZ: 6963,
        Zusatzziffer: 0,
        Gemeindename: 'Lugano',
        'BFS-Nr': 5192,
        Kantonskürzel: 'TI',
        E: 8.971504306641197,
        N: 46.021660659924564,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Cureggia',
        PLZ: 6963,
        Zusatzziffer: 2,
        Gemeindename: 'Lugano',
        'BFS-Nr': 5192,
        Kantonskürzel: 'TI',
        E: 8.984532180214686,
        N: 46.02077700842372,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Davesco-Soragno',
        PLZ: 6964,
        Zusatzziffer: 0,
        Gemeindename: 'Lugano',
        'BFS-Nr': 5192,
        Kantonskürzel: 'TI',
        E: 8.980413554446816,
        N: 46.03524827806401,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Cadro',
        PLZ: 6965,
        Zusatzziffer: 0,
        Gemeindename: 'Lugano',
        'BFS-Nr': 5192,
        Kantonskürzel: 'TI',
        E: 8.989849983920722,
        N: 46.04469280559492,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Villa Luganese',
        PLZ: 6966,
        Zusatzziffer: 0,
        Gemeindename: 'Lugano',
        'BFS-Nr': 5192,
        Kantonskürzel: 'TI',
        E: 8.996492338849855,
        N: 46.054062056035434,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Dino',
        PLZ: 6967,
        Zusatzziffer: 0,
        Gemeindename: 'Lugano',
        'BFS-Nr': 5192,
        Kantonskürzel: 'TI',
        E: 8.984243463877881,
        N: 46.053129786754035,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Sonvico',
        PLZ: 6968,
        Zusatzziffer: 0,
        Gemeindename: 'Lugano',
        'BFS-Nr': 5192,
        Kantonskürzel: 'TI',
        E: 8.990737110134864,
        N: 46.05786915678791,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Aldesago',
        PLZ: 6974,
        Zusatzziffer: 0,
        Gemeindename: 'Lugano',
        'BFS-Nr': 5192,
        Kantonskürzel: 'TI',
        E: 8.978884133607808,
        N: 46.008733849300214,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Castagnola',
        PLZ: 6976,
        Zusatzziffer: 0,
        Gemeindename: 'Lugano',
        'BFS-Nr': 5192,
        Kantonskürzel: 'TI',
        E: 8.977978649406642,
        N: 46.00231482877844,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Ruvigliana',
        PLZ: 6977,
        Zusatzziffer: 0,
        Gemeindename: 'Lugano',
        'BFS-Nr': 5192,
        Kantonskürzel: 'TI',
        E: 8.976079358104682,
        N: 46.004062807765806,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Gandria',
        PLZ: 6978,
        Zusatzziffer: 0,
        Gemeindename: 'Lugano',
        'BFS-Nr': 5192,
        Kantonskürzel: 'TI',
        E: 9.002406655149825,
        N: 46.005426179436185,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Brè sopra Lugano',
        PLZ: 6979,
        Zusatzziffer: 0,
        Gemeindename: 'Lugano',
        'BFS-Nr': 5192,
        Kantonskürzel: 'TI',
        E: 8.998379885630852,
        N: 46.01289935242552,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Magliaso',
        PLZ: 6983,
        Zusatzziffer: 0,
        Gemeindename: 'Magliaso',
        'BFS-Nr': 5193,
        Kantonskürzel: 'TI',
        E: 8.884558404068786,
        N: 45.981739064453954,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Manno',
        PLZ: 6928,
        Zusatzziffer: 0,
        Gemeindename: 'Manno',
        'BFS-Nr': 5194,
        Kantonskürzel: 'TI',
        E: 8.918672498044364,
        N: 46.03445273549831,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Massagno',
        PLZ: 6900,
        Zusatzziffer: 5,
        Gemeindename: 'Massagno',
        'BFS-Nr': 5196,
        Kantonskürzel: 'TI',
        E: 8.943177946637347,
        N: 46.012712906221196,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Melide',
        PLZ: 6815,
        Zusatzziffer: 0,
        Gemeindename: 'Melide',
        'BFS-Nr': 5198,
        Kantonskürzel: 'TI',
        E: 8.949071177374101,
        N: 45.95474818313674,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Mezzovico',
        PLZ: 6805,
        Zusatzziffer: 0,
        Gemeindename: 'Mezzovico-Vira',
        'BFS-Nr': 5199,
        Kantonskürzel: 'TI',
        E: 8.873224436225986,
        N: 46.08631068378253,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Mezzovico',
        PLZ: 6805,
        Zusatzziffer: 0,
        Gemeindename: 'Mezzovico-Vira',
        'BFS-Nr': 5199,
        Kantonskürzel: 'TI',
        E: 8.914969167710344,
        N: 46.08976459009939,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Miglieglia',
        PLZ: 6986,
        Zusatzziffer: 2,
        Gemeindename: 'Miglieglia',
        'BFS-Nr': 5200,
        Kantonskürzel: 'TI',
        E: 8.857293563670925,
        N: 46.02540935720357,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Morcote',
        PLZ: 6922,
        Zusatzziffer: 0,
        Gemeindename: 'Morcote',
        'BFS-Nr': 5203,
        Kantonskürzel: 'TI',
        E: 8.914261962164197,
        N: 45.92315375746325,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Muzzano',
        PLZ: 6933,
        Zusatzziffer: 0,
        Gemeindename: 'Muzzano',
        'BFS-Nr': 5205,
        Kantonskürzel: 'TI',
        E: 8.92231994664285,
        N: 45.99836021415244,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Neggio',
        PLZ: 6991,
        Zusatzziffer: 0,
        Gemeindename: 'Neggio',
        'BFS-Nr': 5206,
        Kantonskürzel: 'TI',
        E: 8.879414968810451,
        N: 45.98722095736472,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Novaggio',
        PLZ: 6986,
        Zusatzziffer: 0,
        Gemeindename: 'Novaggio',
        'BFS-Nr': 5207,
        Kantonskürzel: 'TI',
        E: 8.855959886572265,
        N: 46.01020144806685,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Origlio',
        PLZ: 6945,
        Zusatzziffer: 0,
        Gemeindename: 'Origlio',
        'BFS-Nr': 5208,
        Kantonskürzel: 'TI',
        E: 8.94444338767428,
        N: 46.054301338141926,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Paradiso',
        PLZ: 6900,
        Zusatzziffer: 9,
        Gemeindename: 'Paradiso',
        'BFS-Nr': 5210,
        Kantonskürzel: 'TI',
        E: 8.946391473619752,
        N: 45.989213980614736,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Ponte Capriasca',
        PLZ: 6946,
        Zusatzziffer: 0,
        Gemeindename: 'Ponte Capriasca',
        'BFS-Nr': 5212,
        Kantonskürzel: 'TI',
        E: 9.065809220770307,
        N: 46.12408266694155,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Ponte Capriasca',
        PLZ: 6946,
        Zusatzziffer: 0,
        Gemeindename: 'Ponte Capriasca',
        'BFS-Nr': 5212,
        Kantonskürzel: 'TI',
        E: 8.949387363265066,
        N: 46.06208913281764,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Porza',
        PLZ: 6948,
        Zusatzziffer: 0,
        Gemeindename: 'Porza',
        'BFS-Nr': 5214,
        Kantonskürzel: 'TI',
        E: 8.954543375718188,
        N: 46.02728481956968,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Pura',
        PLZ: 6984,
        Zusatzziffer: 0,
        Gemeindename: 'Pura',
        'BFS-Nr': 5216,
        Kantonskürzel: 'TI',
        E: 8.868835536333002,
        N: 45.9860495114981,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Savosa',
        PLZ: 6942,
        Zusatzziffer: 0,
        Gemeindename: 'Savosa',
        'BFS-Nr': 5221,
        Kantonskürzel: 'TI',
        E: 8.951453324713032,
        N: 46.023771067756165,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Sorengo',
        PLZ: 6924,
        Zusatzziffer: 0,
        Gemeindename: 'Sorengo',
        'BFS-Nr': 5225,
        Kantonskürzel: 'TI',
        E: 8.93479036498829,
        N: 46.00034221755415,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Taverne',
        PLZ: 6807,
        Zusatzziffer: 0,
        Gemeindename: 'Capriasca',
        'BFS-Nr': 5226,
        Kantonskürzel: 'TI',
        E: 8.935263271483757,
        N: 46.07254430134813,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Vaglio',
        PLZ: 6947,
        Zusatzziffer: 0,
        Gemeindename: 'Capriasca',
        'BFS-Nr': 5226,
        Kantonskürzel: 'TI',
        E: 8.95659803796053,
        N: 46.061789001014226,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Tesserete',
        PLZ: 6950,
        Zusatzziffer: 0,
        Gemeindename: 'Capriasca',
        'BFS-Nr': 5226,
        Kantonskürzel: 'TI',
        E: 8.965360376952095,
        N: 46.06763441488413,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Lugaggia',
        PLZ: 6953,
        Zusatzziffer: 0,
        Gemeindename: 'Capriasca',
        'BFS-Nr': 5226,
        Kantonskürzel: 'TI',
        E: 8.971942039184924,
        N: 46.06239391397656,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Sala Capriasca',
        PLZ: 6954,
        Zusatzziffer: 0,
        Gemeindename: 'Capriasca',
        'BFS-Nr': 5226,
        Kantonskürzel: 'TI',
        E: 8.95681661026447,
        N: 46.06530197539652,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Bigorio',
        PLZ: 6954,
        Zusatzziffer: 1,
        Gemeindename: 'Capriasca',
        'BFS-Nr': 5226,
        Kantonskürzel: 'TI',
        E: 8.957865035962183,
        N: 46.070510978381606,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Cagiallo',
        PLZ: 6955,
        Zusatzziffer: 0,
        Gemeindename: 'Capriasca',
        'BFS-Nr': 5226,
        Kantonskürzel: 'TI',
        E: 8.97318013133337,
        N: 46.06634634597871,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Oggio',
        PLZ: 6955,
        Zusatzziffer: 1,
        Gemeindename: 'Capriasca',
        'BFS-Nr': 5226,
        Kantonskürzel: 'TI',
        E: 8.985988327635248,
        N: 46.071286161442906,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Lopagno',
        PLZ: 6956,
        Zusatzziffer: 0,
        Gemeindename: 'Capriasca',
        'BFS-Nr': 5226,
        Kantonskürzel: 'TI',
        E: 8.976876763642167,
        N: 46.068811099128425,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Roveredo TI',
        PLZ: 6957,
        Zusatzziffer: 0,
        Gemeindename: 'Capriasca',
        'BFS-Nr': 5226,
        Kantonskürzel: 'TI',
        E: 8.983301860649972,
        N: 46.07372203171655,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Bidogno',
        PLZ: 6958,
        Zusatzziffer: 0,
        Gemeindename: 'Capriasca',
        'BFS-Nr': 5226,
        Kantonskürzel: 'TI',
        E: 8.999737377825877,
        N: 46.078387851336934,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Corticiasca',
        PLZ: 6958,
        Zusatzziffer: 2,
        Gemeindename: 'Capriasca',
        'BFS-Nr': 5226,
        Kantonskürzel: 'TI',
        E: 9.013612530650503,
        N: 46.07957854008105,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Corticiasca',
        PLZ: 6958,
        Zusatzziffer: 2,
        Gemeindename: 'Capriasca',
        'BFS-Nr': 5226,
        Kantonskürzel: 'TI',
        E: 9.016394582759487,
        N: 46.087014248200624,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Odogno',
        PLZ: 6960,
        Zusatzziffer: 0,
        Gemeindename: 'Capriasca',
        'BFS-Nr': 5226,
        Kantonskürzel: 'TI',
        E: 8.969716271049888,
        N: 46.082311181725046,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Taverne',
        PLZ: 6807,
        Zusatzziffer: 0,
        Gemeindename: 'Torricella-Taverne',
        'BFS-Nr': 5227,
        Kantonskürzel: 'TI',
        E: 8.934397823545439,
        N: 46.06673454707854,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Torricella',
        PLZ: 6808,
        Zusatzziffer: 0,
        Gemeindename: 'Torricella-Taverne',
        'BFS-Nr': 5227,
        Kantonskürzel: 'TI',
        E: 8.922200857608889,
        N: 46.066749015617454,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Vernate',
        PLZ: 6992,
        Zusatzziffer: 0,
        Gemeindename: 'Vernate',
        'BFS-Nr': 5230,
        Kantonskürzel: 'TI',
        E: 8.886735788749144,
        N: 45.99477563297588,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Vezia',
        PLZ: 6943,
        Zusatzziffer: 0,
        Gemeindename: 'Vezia',
        'BFS-Nr': 5231,
        Kantonskürzel: 'TI',
        E: 8.937022289500952,
        N: 46.02347220799274,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Vico Morcote',
        PLZ: 6921,
        Zusatzziffer: 0,
        Gemeindename: 'Vico Morcote',
        'BFS-Nr': 5233,
        Kantonskürzel: 'TI',
        E: 8.922439146903155,
        N: 45.930366029926105,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Carabietta',
        PLZ: 6919,
        Zusatzziffer: 0,
        Gemeindename: 'Collina d\'Oro',
        'BFS-Nr': 5236,
        Kantonskürzel: 'TI',
        E: 8.900397919296877,
        N: 45.96845199862321,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Gentilino',
        PLZ: 6925,
        Zusatzziffer: 0,
        Gemeindename: 'Collina d\'Oro',
        'BFS-Nr': 5236,
        Kantonskürzel: 'TI',
        E: 8.93279480072811,
        N: 45.99103318485847,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Montagnola',
        PLZ: 6926,
        Zusatzziffer: 0,
        Gemeindename: 'Collina d\'Oro',
        'BFS-Nr': 5236,
        Kantonskürzel: 'TI',
        E: 8.917193149510355,
        N: 45.98214619000436,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Agra',
        PLZ: 6927,
        Zusatzziffer: 0,
        Gemeindename: 'Collina d\'Oro',
        'BFS-Nr': 5236,
        Kantonskürzel: 'TI',
        E: 8.911212628474232,
        N: 45.96803488118435,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Breno',
        PLZ: 6937,
        Zusatzziffer: 0,
        Gemeindename: 'Alto Malcantone',
        'BFS-Nr': 5237,
        Kantonskürzel: 'TI',
        E: 8.870350968165093,
        N: 46.03311593553301,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Vezio',
        PLZ: 6938,
        Zusatzziffer: 0,
        Gemeindename: 'Alto Malcantone',
        'BFS-Nr': 5237,
        Kantonskürzel: 'TI',
        E: 8.88240046719605,
        N: 46.045963918752655,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Fescoggia',
        PLZ: 6938,
        Zusatzziffer: 1,
        Gemeindename: 'Alto Malcantone',
        'BFS-Nr': 5237,
        Kantonskürzel: 'TI',
        E: 8.877086035138781,
        N: 46.04049342702068,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Mugena',
        PLZ: 6939,
        Zusatzziffer: 1,
        Gemeindename: 'Alto Malcantone',
        'BFS-Nr': 5237,
        Kantonskürzel: 'TI',
        E: 8.890289332608944,
        N: 46.04873231907844,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Arosio',
        PLZ: 6939,
        Zusatzziffer: 2,
        Gemeindename: 'Alto Malcantone',
        'BFS-Nr': 5237,
        Kantonskürzel: 'TI',
        E: 8.900186172310756,
        N: 46.04777544105997,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Rivera',
        PLZ: 6802,
        Zusatzziffer: 0,
        Gemeindename: 'Monteceneri',
        'BFS-Nr': 5238,
        Kantonskürzel: 'TI',
        E: 8.925619307806905,
        N: 46.12352966540406,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Camignolo',
        PLZ: 6803,
        Zusatzziffer: 0,
        Gemeindename: 'Monteceneri',
        'BFS-Nr': 5238,
        Kantonskürzel: 'TI',
        E: 8.938335439804582,
        N: 46.10608850334823,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Bironico',
        PLZ: 6804,
        Zusatzziffer: 0,
        Gemeindename: 'Monteceneri',
        'BFS-Nr': 5238,
        Kantonskürzel: 'TI',
        E: 8.93293210351042,
        N: 46.113637840644806,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Sigirino',
        PLZ: 6806,
        Zusatzziffer: 0,
        Gemeindename: 'Monteceneri',
        'BFS-Nr': 5238,
        Kantonskürzel: 'TI',
        E: 8.914214959244337,
        N: 46.08054385138737,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Medeglia',
        PLZ: 6809,
        Zusatzziffer: 0,
        Gemeindename: 'Monteceneri',
        'BFS-Nr': 5238,
        Kantonskürzel: 'TI',
        E: 8.969383997932395,
        N: 46.11550642221074,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Castelrotto',
        PLZ: 6980,
        Zusatzziffer: 0,
        Gemeindename: 'Tresa',
        'BFS-Nr': 5239,
        Kantonskürzel: 'TI',
        E: 8.837650865449422,
        N: 45.99285219381793,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Ponte Tresa',
        PLZ: 6988,
        Zusatzziffer: 0,
        Gemeindename: 'Tresa',
        'BFS-Nr': 5239,
        Kantonskürzel: 'TI',
        E: 8.858345467802076,
        N: 45.96962134582536,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Purasca',
        PLZ: 6989,
        Zusatzziffer: 0,
        Gemeindename: 'Tresa',
        'BFS-Nr': 5239,
        Kantonskürzel: 'TI',
        E: 8.849730354328189,
        N: 45.980712925230804,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Madonna del Piano',
        PLZ: 6995,
        Zusatzziffer: 2,
        Gemeindename: 'Tresa',
        'BFS-Nr': 5239,
        Kantonskürzel: 'TI',
        E: 8.833795937616364,
        N: 45.98800647148176,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Sessa',
        PLZ: 6997,
        Zusatzziffer: 0,
        Gemeindename: 'Tresa',
        'BFS-Nr': 5239,
        Kantonskürzel: 'TI',
        E: 8.818794625361958,
        N: 45.998942601905426,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Monteggio',
        PLZ: 6998,
        Zusatzziffer: 1,
        Gemeindename: 'Tresa',
        'BFS-Nr': 5239,
        Kantonskürzel: 'TI',
        E: 8.813768962770421,
        N: 45.996057121226045,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Maroggia',
        PLZ: 6817,
        Zusatzziffer: 0,
        Gemeindename: 'Val Mara',
        'BFS-Nr': 5240,
        Kantonskürzel: 'TI',
        E: 8.968702009551311,
        N: 45.93562123302927,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Melano',
        PLZ: 6818,
        Zusatzziffer: 0,
        Gemeindename: 'Val Mara',
        'BFS-Nr': 5240,
        Kantonskürzel: 'TI',
        E: 8.98640684830274,
        N: 45.91907543535467,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Rovio',
        PLZ: 6821,
        Zusatzziffer: 0,
        Gemeindename: 'Val Mara',
        'BFS-Nr': 5240,
        Kantonskürzel: 'TI',
        E: 8.987192139320094,
        N: 45.93293668163606,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Capolago',
        PLZ: 6825,
        Zusatzziffer: 0,
        Gemeindename: 'Val Mara',
        'BFS-Nr': 5240,
        Kantonskürzel: 'TI',
        E: 9.018466328794243,
        N: 45.9279816580095,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Balerna',
        PLZ: 6828,
        Zusatzziffer: 0,
        Gemeindename: 'Balerna',
        'BFS-Nr': 5242,
        Kantonskürzel: 'TI',
        E: 9.008062709958324,
        N: 45.84957640853838,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Corteglia',
        PLZ: 6873,
        Zusatzziffer: 0,
        Gemeindename: 'Castel San Pietro',
        'BFS-Nr': 5249,
        Kantonskürzel: 'TI',
        E: 8.99375259351797,
        N: 45.861458354994575,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Castel San Pietro',
        PLZ: 6874,
        Zusatzziffer: 0,
        Gemeindename: 'Castel San Pietro',
        'BFS-Nr': 5249,
        Kantonskürzel: 'TI',
        E: 9.009861454769839,
        N: 45.85968612933301,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Monte',
        PLZ: 6875,
        Zusatzziffer: 0,
        Gemeindename: 'Castel San Pietro',
        'BFS-Nr': 5249,
        Kantonskürzel: 'TI',
        E: 9.028045410933307,
        N: 45.88166686536131,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Casima',
        PLZ: 6875,
        Zusatzziffer: 2,
        Gemeindename: 'Castel San Pietro',
        'BFS-Nr': 5249,
        Kantonskürzel: 'TI',
        E: 9.037114734076452,
        N: 45.890606469900895,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Campora',
        PLZ: 6875,
        Zusatzziffer: 3,
        Gemeindename: 'Castel San Pietro',
        'BFS-Nr': 5249,
        Kantonskürzel: 'TI',
        E: 9.023212110879149,
        N: 45.87485675088533,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Chiasso',
        PLZ: 6830,
        Zusatzziffer: 0,
        Gemeindename: 'Chiasso',
        'BFS-Nr': 5250,
        Kantonskürzel: 'TI',
        E: 9.026855751846654,
        N: 45.83487904953815,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Pedrinate',
        PLZ: 6832,
        Zusatzziffer: 0,
        Gemeindename: 'Chiasso',
        'BFS-Nr': 5250,
        Kantonskürzel: 'TI',
        E: 9.011043554782656,
        N: 45.82653911823656,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Seseglio',
        PLZ: 6832,
        Zusatzziffer: 1,
        Gemeindename: 'Chiasso',
        'BFS-Nr': 5250,
        Kantonskürzel: 'TI',
        E: 8.999289646550675,
        N: 45.82683996937213,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Coldrerio',
        PLZ: 6877,
        Zusatzziffer: 0,
        Gemeindename: 'Coldrerio',
        'BFS-Nr': 5251,
        Kantonskürzel: 'TI',
        E: 8.985875029845712,
        N: 45.85349883777824,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Capolago',
        PLZ: 6825,
        Zusatzziffer: 0,
        Gemeindename: 'Mendrisio',
        'BFS-Nr': 5254,
        Kantonskürzel: 'TI',
        E: 8.980797742883283,
        N: 45.9045195325199,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Mendrisio',
        PLZ: 6850,
        Zusatzziffer: 0,
        Gemeindename: 'Mendrisio',
        'BFS-Nr': 5254,
        Kantonskürzel: 'TI',
        E: 8.989202798699102,
        N: 45.87076408594072,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Genestrerio',
        PLZ: 6852,
        Zusatzziffer: 0,
        Gemeindename: 'Mendrisio',
        'BFS-Nr': 5254,
        Kantonskürzel: 'TI',
        E: 8.960491082540749,
        N: 45.855884187978056,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Ligornetto',
        PLZ: 6853,
        Zusatzziffer: 0,
        Gemeindename: 'Mendrisio',
        'BFS-Nr': 5254,
        Kantonskürzel: 'TI',
        E: 8.953365538523041,
        N: 45.86277462745,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Rancate',
        PLZ: 6862,
        Zusatzziffer: 0,
        Gemeindename: 'Mendrisio',
        'BFS-Nr': 5254,
        Kantonskürzel: 'TI',
        E: 8.969076949843577,
        N: 45.8713589188229,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Besazio',
        PLZ: 6863,
        Zusatzziffer: 0,
        Gemeindename: 'Mendrisio',
        'BFS-Nr': 5254,
        Kantonskürzel: 'TI',
        E: 8.951853484723316,
        N: 45.87322461874318,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Arzo',
        PLZ: 6864,
        Zusatzziffer: 0,
        Gemeindename: 'Mendrisio',
        'BFS-Nr': 5254,
        Kantonskürzel: 'TI',
        E: 8.941240920786347,
        N: 45.87611670438742,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Tremona',
        PLZ: 6865,
        Zusatzziffer: 0,
        Gemeindename: 'Mendrisio',
        'BFS-Nr': 5254,
        Kantonskürzel: 'TI',
        E: 8.958587553311673,
        N: 45.88088681775883,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Meride',
        PLZ: 6866,
        Zusatzziffer: 0,
        Gemeindename: 'Mendrisio',
        'BFS-Nr': 5254,
        Kantonskürzel: 'TI',
        E: 8.952637020818882,
        N: 45.89034366618726,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Salorino',
        PLZ: 6872,
        Zusatzziffer: 0,
        Gemeindename: 'Mendrisio',
        'BFS-Nr': 5254,
        Kantonskürzel: 'TI',
        E: 8.992586850224809,
        N: 45.87359660877381,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Somazzo',
        PLZ: 6872,
        Zusatzziffer: 1,
        Gemeindename: 'Mendrisio',
        'BFS-Nr': 5254,
        Kantonskürzel: 'TI',
        E: 8.992977123991693,
        N: 45.8781760235299,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Morbio Inferiore',
        PLZ: 6834,
        Zusatzziffer: 0,
        Gemeindename: 'Morbio Inferiore',
        'BFS-Nr': 5257,
        Kantonskürzel: 'TI',
        E: 9.016861049268458,
        N: 45.8536192203095,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Novazzano',
        PLZ: 6883,
        Zusatzziffer: 0,
        Gemeindename: 'Novazzano',
        'BFS-Nr': 5260,
        Kantonskürzel: 'TI',
        E: 8.981331232099713,
        N: 45.84157474041473,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Riva San Vitale',
        PLZ: 6826,
        Zusatzziffer: 0,
        Gemeindename: 'Riva San Vitale',
        'BFS-Nr': 5263,
        Kantonskürzel: 'TI',
        E: 8.969665751600763,
        N: 45.90682325838312,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'S. Pietro',
        PLZ: 6854,
        Zusatzziffer: 0,
        Gemeindename: 'Stabio',
        'BFS-Nr': 5266,
        Kantonskürzel: 'TI',
        E: 8.935725435809617,
        N: 45.859239570201424,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Stabio',
        PLZ: 6855,
        Zusatzziffer: 0,
        Gemeindename: 'Stabio',
        'BFS-Nr': 5266,
        Kantonskürzel: 'TI',
        E: 8.93854635315008,
        N: 45.8507278890987,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Vacallo',
        PLZ: 6833,
        Zusatzziffer: 0,
        Gemeindename: 'Vacallo',
        'BFS-Nr': 5268,
        Kantonskürzel: 'TI',
        E: 9.034951649856625,
        N: 45.84764847242736,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Morbio Superiore',
        PLZ: 6835,
        Zusatzziffer: 0,
        Gemeindename: 'Breggia',
        'BFS-Nr': 5269,
        Kantonskürzel: 'TI',
        E: 9.020729220593157,
        N: 45.861456897948514,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Caneggio',
        PLZ: 6837,
        Zusatzziffer: 0,
        Gemeindename: 'Breggia',
        'BFS-Nr': 5269,
        Kantonskürzel: 'TI',
        E: 9.031797711693192,
        N: 45.87260100221947,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Bruzella',
        PLZ: 6837,
        Zusatzziffer: 2,
        Gemeindename: 'Breggia',
        'BFS-Nr': 5269,
        Kantonskürzel: 'TI',
        E: 9.038039955925242,
        N: 45.882427900696264,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Muggio',
        PLZ: 6838,
        Zusatzziffer: 0,
        Gemeindename: 'Breggia',
        'BFS-Nr': 5269,
        Kantonskürzel: 'TI',
        E: 9.0431097926103,
        N: 45.90224784376214,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Cabbio',
        PLZ: 6838,
        Zusatzziffer: 2,
        Gemeindename: 'Breggia',
        'BFS-Nr': 5269,
        Kantonskürzel: 'TI',
        E: 9.046733098442123,
        N: 45.89792001324599,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Sagno',
        PLZ: 6839,
        Zusatzziffer: 0,
        Gemeindename: 'Breggia',
        'BFS-Nr': 5269,
        Kantonskürzel: 'TI',
        E: 9.041122504184523,
        N: 45.85711387344527,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Biasca',
        PLZ: 6710,
        Zusatzziffer: 0,
        Gemeindename: 'Biasca',
        'BFS-Nr': 5281,
        Kantonskürzel: 'TI',
        E: 8.972224403384713,
        N: 46.36011201439682,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Prosito',
        PLZ: 6526,
        Zusatzziffer: 0,
        Gemeindename: 'Riviera',
        'BFS-Nr': 5287,
        Kantonskürzel: 'TI',
        E: 8.98350748182635,
        N: 46.279618170099724,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Lodrino',
        PLZ: 6527,
        Zusatzziffer: 0,
        Gemeindename: 'Riviera',
        'BFS-Nr': 5287,
        Kantonskürzel: 'TI',
        E: 8.977840946412714,
        N: 46.301559052480954,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Osogna',
        PLZ: 6703,
        Zusatzziffer: 0,
        Gemeindename: 'Riviera',
        'BFS-Nr': 5287,
        Kantonskürzel: 'TI',
        E: 8.987127306914163,
        N: 46.313270739835595,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Cresciano',
        PLZ: 6705,
        Zusatzziffer: 0,
        Gemeindename: 'Riviera',
        'BFS-Nr': 5287,
        Kantonskürzel: 'TI',
        E: 9.004494725323442,
        N: 46.280780673072115,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Iragna',
        PLZ: 6707,
        Zusatzziffer: 0,
        Gemeindename: 'Riviera',
        'BFS-Nr': 5287,
        Kantonskürzel: 'TI',
        E: 8.966892470383309,
        N: 46.32993350922691,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Bosco/Gurin',
        PLZ: 6685,
        Zusatzziffer: 0,
        Gemeindename: 'Bosco/Gurin',
        'BFS-Nr': 5304,
        Kantonskürzel: 'TI',
        E: 8.491560630076776,
        N: 46.316754906347555,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Niva (Vallemaggia)',
        PLZ: 6683,
        Zusatzziffer: 2,
        Gemeindename: 'Campo (Vallemaggia)',
        'BFS-Nr': 5307,
        Kantonskürzel: 'TI',
        E: 8.531039997750474,
        N: 46.29114413427212,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Campo (Vallemaggia)',
        PLZ: 6684,
        Zusatzziffer: 0,
        Gemeindename: 'Campo (Vallemaggia)',
        'BFS-Nr': 5307,
        Kantonskürzel: 'TI',
        E: 8.496479612607379,
        N: 46.28901304325196,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Cimalmotto',
        PLZ: 6684,
        Zusatzziffer: 1,
        Gemeindename: 'Campo (Vallemaggia)',
        'BFS-Nr': 5307,
        Kantonskürzel: 'TI',
        E: 8.489028038764737,
        N: 46.2833631130748,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Cerentino',
        PLZ: 6683,
        Zusatzziffer: 0,
        Gemeindename: 'Cerentino',
        'BFS-Nr': 5309,
        Kantonskürzel: 'TI',
        E: 8.54763595941701,
        N: 46.30564673948652,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Cevio',
        PLZ: 6675,
        Zusatzziffer: 0,
        Gemeindename: 'Cevio',
        'BFS-Nr': 5310,
        Kantonskürzel: 'TI',
        E: 8.601752337127127,
        N: 46.31646225643645,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Bignasco',
        PLZ: 6676,
        Zusatzziffer: 0,
        Gemeindename: 'Cevio',
        'BFS-Nr': 5310,
        Kantonskürzel: 'TI',
        E: 8.609129655571284,
        N: 46.338550708394706,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Cavergno',
        PLZ: 6690,
        Zusatzziffer: 0,
        Gemeindename: 'Cevio',
        'BFS-Nr': 5310,
        Kantonskürzel: 'TI',
        E: 8.607167367955046,
        N: 46.34466776137544,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'S. Carlo (Val Bavona)',
        PLZ: 6690,
        Zusatzziffer: 1,
        Gemeindename: 'Cevio',
        'BFS-Nr': 5310,
        Kantonskürzel: 'TI',
        E: 8.527747241396838,
        N: 46.40879311469227,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Linescio',
        PLZ: 6682,
        Zusatzziffer: 0,
        Gemeindename: 'Linescio',
        'BFS-Nr': 5315,
        Kantonskürzel: 'TI',
        E: 8.583101159073042,
        N: 46.30816610786917,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Maggia',
        PLZ: 6673,
        Zusatzziffer: 0,
        Gemeindename: 'Maggia',
        'BFS-Nr': 5317,
        Kantonskürzel: 'TI',
        E: 8.70830210245811,
        N: 46.2467731568653,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Someo',
        PLZ: 6674,
        Zusatzziffer: 0,
        Gemeindename: 'Maggia',
        'BFS-Nr': 5317,
        Kantonskürzel: 'TI',
        E: 8.666057478094674,
        N: 46.28624645152321,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Riveo',
        PLZ: 6674,
        Zusatzziffer: 2,
        Gemeindename: 'Maggia',
        'BFS-Nr': 5317,
        Kantonskürzel: 'TI',
        E: 8.635667321306272,
        N: 46.29648128268535,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Aurigeno',
        PLZ: 6677,
        Zusatzziffer: 1,
        Gemeindename: 'Maggia',
        'BFS-Nr': 5317,
        Kantonskürzel: 'TI',
        E: 8.712651518171368,
        N: 46.23076115526933,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Moghegno',
        PLZ: 6677,
        Zusatzziffer: 2,
        Gemeindename: 'Maggia',
        'BFS-Nr': 5317,
        Kantonskürzel: 'TI',
        E: 8.705483317197068,
        N: 46.24007635985353,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Giumaglio',
        PLZ: 6678,
        Zusatzziffer: 0,
        Gemeindename: 'Maggia',
        'BFS-Nr': 5317,
        Kantonskürzel: 'TI',
        E: 8.682287253868314,
        N: 46.274203336192265,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Lodano',
        PLZ: 6678,
        Zusatzziffer: 2,
        Gemeindename: 'Maggia',
        'BFS-Nr': 5317,
        Kantonskürzel: 'TI',
        E: 8.684717794773233,
        N: 46.257592299440844,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Coglio',
        PLZ: 6678,
        Zusatzziffer: 3,
        Gemeindename: 'Maggia',
        'BFS-Nr': 5317,
        Kantonskürzel: 'TI',
        E: 8.684140802968319,
        N: 46.26905436106236,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Menzonio',
        PLZ: 6692,
        Zusatzziffer: 0,
        Gemeindename: 'Lavizzara',
        'BFS-Nr': 5323,
        Kantonskürzel: 'TI',
        E: 8.640933814667811,
        N: 46.361199815147174,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Brontallo',
        PLZ: 6692,
        Zusatzziffer: 1,
        Gemeindename: 'Lavizzara',
        'BFS-Nr': 5323,
        Kantonskürzel: 'TI',
        E: 8.628490331662835,
        N: 46.35553355058448,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Broglio',
        PLZ: 6693,
        Zusatzziffer: 0,
        Gemeindename: 'Lavizzara',
        'BFS-Nr': 5323,
        Kantonskürzel: 'TI',
        E: 8.661083863112886,
        N: 46.37698513178276,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Prato-Sornico',
        PLZ: 6694,
        Zusatzziffer: 0,
        Gemeindename: 'Lavizzara',
        'BFS-Nr': 5323,
        Kantonskürzel: 'TI',
        E: 8.654219342021646,
        N: 46.39649907670493,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Peccia',
        PLZ: 6695,
        Zusatzziffer: 0,
        Gemeindename: 'Lavizzara',
        'BFS-Nr': 5323,
        Kantonskürzel: 'TI',
        E: 8.648046392969167,
        N: 46.40724164165157,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Piano di Peccia',
        PLZ: 6695,
        Zusatzziffer: 1,
        Gemeindename: 'Lavizzara',
        'BFS-Nr': 5323,
        Kantonskürzel: 'TI',
        E: 8.60828024266148,
        N: 46.41381151061356,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Fusio',
        PLZ: 6696,
        Zusatzziffer: 0,
        Gemeindename: 'Lavizzara',
        'BFS-Nr': 5323,
        Kantonskürzel: 'TI',
        E: 8.660081318380312,
        N: 46.445177592729294,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Avegno',
        PLZ: 6670,
        Zusatzziffer: 0,
        Gemeindename: 'Avegno Gordevio',
        'BFS-Nr': 5324,
        Kantonskürzel: 'TI',
        E: 8.745910589580326,
        N: 46.20638284107934,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Gordevio',
        PLZ: 6672,
        Zusatzziffer: 0,
        Gemeindename: 'Avegno Gordevio',
        'BFS-Nr': 5324,
        Kantonskürzel: 'TI',
        E: 8.738186691407622,
        N: 46.225505113151705,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Medeglia',
        PLZ: 6809,
        Zusatzziffer: 0,
        Gemeindename: 'Comunanza Cadenazzo/Monteceneri',
        'BFS-Nr': 5391,
        Kantonskürzel: 'TI',
        E: 9.058531824766064,
        N: 46.14067644228474,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Tegna',
        PLZ: 6652,
        Zusatzziffer: 0,
        Gemeindename: 'Terre di Pedemonte',
        'BFS-Nr': 5396,
        Kantonskürzel: 'TI',
        E: 8.744327667269816,
        N: 46.18615788061427,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Verscio',
        PLZ: 6653,
        Zusatzziffer: 0,
        Gemeindename: 'Terre di Pedemonte',
        'BFS-Nr': 5396,
        Kantonskürzel: 'TI',
        E: 8.732067510609753,
        N: 46.184635873425414,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Cavigliano',
        PLZ: 6654,
        Zusatzziffer: 0,
        Gemeindename: 'Terre di Pedemonte',
        'BFS-Nr': 5396,
        Kantonskürzel: 'TI',
        E: 8.719051516587045,
        N: 46.18493873595037,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Intragna',
        PLZ: 6655,
        Zusatzziffer: 0,
        Gemeindename: 'Centovalli',
        'BFS-Nr': 5397,
        Kantonskürzel: 'TI',
        E: 8.700105648178033,
        N: 46.17735814379126,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Verdasio',
        PLZ: 6655,
        Zusatzziffer: 2,
        Gemeindename: 'Centovalli',
        'BFS-Nr': 5397,
        Kantonskürzel: 'TI',
        E: 8.636125436796164,
        N: 46.1647666248681,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Rasa',
        PLZ: 6655,
        Zusatzziffer: 3,
        Gemeindename: 'Centovalli',
        'BFS-Nr': 5397,
        Kantonskürzel: 'TI',
        E: 8.654575438104667,
        N: 46.15606265917694,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Golino',
        PLZ: 6656,
        Zusatzziffer: 0,
        Gemeindename: 'Centovalli',
        'BFS-Nr': 5397,
        Kantonskürzel: 'TI',
        E: 8.714099971323723,
        N: 46.17919049577238,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Palagnedra',
        PLZ: 6657,
        Zusatzziffer: 0,
        Gemeindename: 'Centovalli',
        'BFS-Nr': 5397,
        Kantonskürzel: 'TI',
        E: 8.632209484122688,
        N: 46.15416663462637,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Borgnone',
        PLZ: 6658,
        Zusatzziffer: 0,
        Gemeindename: 'Centovalli',
        'BFS-Nr': 5397,
        Kantonskürzel: 'TI',
        E: 8.616068121596422,
        N: 46.15994546165779,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Camedo',
        PLZ: 6659,
        Zusatzziffer: 0,
        Gemeindename: 'Centovalli',
        'BFS-Nr': 5397,
        Kantonskürzel: 'TI',
        E: 8.608517999216048,
        N: 46.155726480488546,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Moneto',
        PLZ: 6659,
        Zusatzziffer: 9,
        Gemeindename: 'Centovalli',
        'BFS-Nr': 5397,
        Kantonskürzel: 'TI',
        E: 8.620779515057933,
        N: 46.150493795860235,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Indemini',
        PLZ: 6571,
        Zusatzziffer: 0,
        Gemeindename: 'Gambarogno',
        'BFS-Nr': 5398,
        Kantonskürzel: 'TI',
        E: 8.826149367713937,
        N: 46.09445511823649,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Quartino',
        PLZ: 6572,
        Zusatzziffer: 0,
        Gemeindename: 'Gambarogno',
        'BFS-Nr': 5398,
        Kantonskürzel: 'TI',
        E: 8.895107891818922,
        N: 46.15278652910646,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Magadino',
        PLZ: 6573,
        Zusatzziffer: 0,
        Gemeindename: 'Gambarogno',
        'BFS-Nr': 5398,
        Kantonskürzel: 'TI',
        E: 8.856774777948651,
        N: 46.14761434959627,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Vira (Gambarogno)',
        PLZ: 6574,
        Zusatzziffer: 0,
        Gemeindename: 'Gambarogno',
        'BFS-Nr': 5398,
        Kantonskürzel: 'TI',
        E: 8.841021188905033,
        N: 46.144487965588496,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'S. Nazzaro',
        PLZ: 6575,
        Zusatzziffer: 0,
        Gemeindename: 'Gambarogno',
        'BFS-Nr': 5398,
        Kantonskürzel: 'TI',
        E: 8.803586432697726,
        N: 46.132219192084726,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Vairano',
        PLZ: 6575,
        Zusatzziffer: 1,
        Gemeindename: 'Gambarogno',
        'BFS-Nr': 5398,
        Kantonskürzel: 'TI',
        E: 8.810429591714847,
        N: 46.1320890831578,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Gerra (Gambarogno)',
        PLZ: 6576,
        Zusatzziffer: 0,
        Gemeindename: 'Gambarogno',
        'BFS-Nr': 5398,
        Kantonskürzel: 'TI',
        E: 8.785274406232038,
        N: 46.12306307938588,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Ranzo',
        PLZ: 6577,
        Zusatzziffer: 0,
        Gemeindename: 'Gambarogno',
        'BFS-Nr': 5398,
        Kantonskürzel: 'TI',
        E: 8.776457570237314,
        N: 46.11704764676601,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Caviano',
        PLZ: 6578,
        Zusatzziffer: 0,
        Gemeindename: 'Gambarogno',
        'BFS-Nr': 5398,
        Kantonskürzel: 'TI',
        E: 8.76636015547498,
        N: 46.10716783481724,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Piazzogna',
        PLZ: 6579,
        Zusatzziffer: 0,
        Gemeindename: 'Gambarogno',
        'BFS-Nr': 5398,
        Kantonskürzel: 'TI',
        E: 8.824928452175234,
        N: 46.13512149706229,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Contone',
        PLZ: 6594,
        Zusatzziffer: 0,
        Gemeindename: 'Gambarogno',
        'BFS-Nr': 5398,
        Kantonskürzel: 'TI',
        E: 8.92868109200676,
        N: 46.14806740587515,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Corippo',
        PLZ: 6631,
        Zusatzziffer: 0,
        Gemeindename: 'Verzasca',
        'BFS-Nr': 5399,
        Kantonskürzel: 'TI',
        E: 8.840927326244561,
        N: 46.2357659618382,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Vogorno',
        PLZ: 6632,
        Zusatzziffer: 0,
        Gemeindename: 'Verzasca',
        'BFS-Nr': 5399,
        Kantonskürzel: 'TI',
        E: 8.858640406584966,
        N: 46.222625591384194,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Lavertezzo',
        PLZ: 6633,
        Zusatzziffer: 0,
        Gemeindename: 'Verzasca',
        'BFS-Nr': 5399,
        Kantonskürzel: 'TI',
        E: 8.835991748712926,
        N: 46.25720836831403,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Brione (Verzasca)',
        PLZ: 6634,
        Zusatzziffer: 0,
        Gemeindename: 'Verzasca',
        'BFS-Nr': 5399,
        Kantonskürzel: 'TI',
        E: 8.790302638982265,
        N: 46.29676823767583,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Gerra (Verzasca)',
        PLZ: 6635,
        Zusatzziffer: 0,
        Gemeindename: 'Verzasca',
        'BFS-Nr': 5399,
        Kantonskürzel: 'TI',
        E: 8.798422685694703,
        N: 46.318907355620915,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Frasco',
        PLZ: 6636,
        Zusatzziffer: 0,
        Gemeindename: 'Verzasca',
        'BFS-Nr': 5399,
        Kantonskürzel: 'TI',
        E: 8.802104599462066,
        N: 46.340228921442076,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Sonogno',
        PLZ: 6637,
        Zusatzziffer: 0,
        Gemeindename: 'Verzasca',
        'BFS-Nr': 5399,
        Kantonskürzel: 'TI',
        E: 8.786155280291592,
        N: 46.34982582138733,
        Sprache: 'it',
    },
    {
        Ortschaftsname: 'Aigle',
        PLZ: 1860,
        Zusatzziffer: 0,
        Gemeindename: 'Aigle',
        'BFS-Nr': 5401,
        Kantonskürzel: 'VD',
        E: 6.967618108123619,
        N: 46.31728153456867,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Bex',
        PLZ: 1880,
        Zusatzziffer: 0,
        Gemeindename: 'Bex',
        'BFS-Nr': 5402,
        Kantonskürzel: 'VD',
        E: 7.012436832384353,
        N: 46.25121695089581,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Frenières-sur-Bex',
        PLZ: 1880,
        Zusatzziffer: 2,
        Gemeindename: 'Bex',
        'BFS-Nr': 5402,
        Kantonskürzel: 'VD',
        E: 7.071431883269558,
        N: 46.26150004175834,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Fenalet-sur-Bex',
        PLZ: 1880,
        Zusatzziffer: 3,
        Gemeindename: 'Bex',
        'BFS-Nr': 5402,
        Kantonskürzel: 'VD',
        E: 7.027759421752145,
        N: 46.27197851576935,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Les Plans-sur-Bex',
        PLZ: 1880,
        Zusatzziffer: 4,
        Gemeindename: 'Bex',
        'BFS-Nr': 5402,
        Kantonskürzel: 'VD',
        E: 7.093281166881865,
        N: 46.25684820619534,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Les Posses-sur-Bex',
        PLZ: 1880,
        Zusatzziffer: 5,
        Gemeindename: 'Bex',
        'BFS-Nr': 5402,
        Kantonskürzel: 'VD',
        E: 7.048021649458864,
        N: 46.270586014318106,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Chessel',
        PLZ: 1846,
        Zusatzziffer: 0,
        Gemeindename: 'Chessel',
        'BFS-Nr': 5403,
        Kantonskürzel: 'VD',
        E: 6.894260960726323,
        N: 46.34958710999354,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Corbeyrier',
        PLZ: 1856,
        Zusatzziffer: 0,
        Gemeindename: 'Corbeyrier',
        'BFS-Nr': 5404,
        Kantonskürzel: 'VD',
        E: 6.962466529983147,
        N: 46.35024522031608,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Gryon',
        PLZ: 1882,
        Zusatzziffer: 0,
        Gemeindename: 'Gryon',
        'BFS-Nr': 5405,
        Kantonskürzel: 'VD',
        E: 7.062829627900473,
        N: 46.2734890814626,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Lavey-Village',
        PLZ: 1892,
        Zusatzziffer: 0,
        Gemeindename: 'Lavey-Morcles',
        'BFS-Nr': 5406,
        Kantonskürzel: 'VD',
        E: 7.019225954646639,
        N: 46.219448799076375,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Lavey-les-Bains',
        PLZ: 1892,
        Zusatzziffer: 1,
        Gemeindename: 'Lavey-Morcles',
        'BFS-Nr': 5406,
        Kantonskürzel: 'VD',
        E: 7.018044792649443,
        N: 46.20454568313313,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Morcles',
        PLZ: 1892,
        Zusatzziffer: 2,
        Gemeindename: 'Lavey-Morcles',
        'BFS-Nr': 5406,
        Kantonskürzel: 'VD',
        E: 7.036551084117983,
        N: 46.20899341006069,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Leysin',
        PLZ: 1854,
        Zusatzziffer: 0,
        Gemeindename: 'Leysin',
        'BFS-Nr': 5407,
        Kantonskürzel: 'VD',
        E: 7.012534760879129,
        N: 46.3416366939267,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Noville',
        PLZ: 1845,
        Zusatzziffer: 0,
        Gemeindename: 'Noville',
        'BFS-Nr': 5408,
        Kantonskürzel: 'VD',
        E: 6.89859274263301,
        N: 46.3809185656027,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Ollon VD',
        PLZ: 1867,
        Zusatzziffer: 0,
        Gemeindename: 'Ollon',
        'BFS-Nr': 5409,
        Kantonskürzel: 'VD',
        E: 6.994512687105419,
        N: 46.29713319812285,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'St-Triphon',
        PLZ: 1867,
        Zusatzziffer: 2,
        Gemeindename: 'Ollon',
        'BFS-Nr': 5409,
        Kantonskürzel: 'VD',
        E: 6.9784711300022355,
        N: 46.292688499278036,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Panex',
        PLZ: 1867,
        Zusatzziffer: 3,
        Gemeindename: 'Ollon',
        'BFS-Nr': 5409,
        Kantonskürzel: 'VD',
        E: 7.014265153167878,
        N: 46.31122509388634,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Villars-sur-Ollon',
        PLZ: 1884,
        Zusatzziffer: 0,
        Gemeindename: 'Ollon',
        'BFS-Nr': 5409,
        Kantonskürzel: 'VD',
        E: 7.056396955915402,
        N: 46.29760515631227,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Arveyes',
        PLZ: 1884,
        Zusatzziffer: 2,
        Gemeindename: 'Ollon',
        'BFS-Nr': 5409,
        Kantonskürzel: 'VD',
        E: 7.062520941444413,
        N: 46.28882328846628,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Huémoz',
        PLZ: 1884,
        Zusatzziffer: 3,
        Gemeindename: 'Ollon',
        'BFS-Nr': 5409,
        Kantonskürzel: 'VD',
        E: 7.02706746795014,
        N: 46.2913440547328,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Chesières',
        PLZ: 1885,
        Zusatzziffer: 0,
        Gemeindename: 'Ollon',
        'BFS-Nr': 5409,
        Kantonskürzel: 'VD',
        E: 7.043512130657404,
        N: 46.301254419777244,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Les Mosses',
        PLZ: 1862,
        Zusatzziffer: 0,
        Gemeindename: 'Ormont-Dessous',
        'BFS-Nr': 5410,
        Kantonskürzel: 'VD',
        E: 7.101846167413826,
        N: 46.39806320417739,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'La Comballaz',
        PLZ: 1862,
        Zusatzziffer: 2,
        Gemeindename: 'Ormont-Dessous',
        'BFS-Nr': 5410,
        Kantonskürzel: 'VD',
        E: 7.0773323448180685,
        N: 46.37717874909243,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Le Sépey',
        PLZ: 1863,
        Zusatzziffer: 0,
        Gemeindename: 'Ormont-Dessous',
        'BFS-Nr': 5410,
        Kantonskürzel: 'VD',
        E: 7.051700375292807,
        N: 46.36176373126449,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'La Forclaz VD',
        PLZ: 1866,
        Zusatzziffer: 0,
        Gemeindename: 'Ormont-Dessous',
        'BFS-Nr': 5410,
        Kantonskürzel: 'VD',
        E: 7.068289801833898,
        N: 46.35154353761165,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Vers-l\'Eglise',
        PLZ: 1864,
        Zusatzziffer: 0,
        Gemeindename: 'Ormont-Dessus',
        'BFS-Nr': 5411,
        Kantonskürzel: 'VD',
        E: 7.1316649755665775,
        N: 46.35365970648242,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Les Diablerets',
        PLZ: 1865,
        Zusatzziffer: 0,
        Gemeindename: 'Ormont-Dessus',
        'BFS-Nr': 5411,
        Kantonskürzel: 'VD',
        E: 7.157162045051663,
        N: 46.34975183622354,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Rennaz',
        PLZ: 1847,
        Zusatzziffer: 0,
        Gemeindename: 'Rennaz',
        'BFS-Nr': 5412,
        Kantonskürzel: 'VD',
        E: 6.918628431062926,
        N: 46.37590331493515,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Roche VD',
        PLZ: 1852,
        Zusatzziffer: 0,
        Gemeindename: 'Roche (VD)',
        'BFS-Nr': 5413,
        Kantonskürzel: 'VD',
        E: 6.930430200063799,
        N: 46.361668526548144,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Villeneuve VD',
        PLZ: 1844,
        Zusatzziffer: 0,
        Gemeindename: 'Villeneuve (VD)',
        'BFS-Nr': 5414,
        Kantonskürzel: 'VD',
        E: 6.925179316236833,
        N: 46.39625627921399,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Yvorne',
        PLZ: 1853,
        Zusatzziffer: 0,
        Gemeindename: 'Yvorne',
        'BFS-Nr': 5415,
        Kantonskürzel: 'VD',
        E: 6.9608609909618435,
        N: 46.330897019559906,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Aubonne',
        PLZ: 1170,
        Zusatzziffer: 0,
        Gemeindename: 'Aubonne',
        'BFS-Nr': 5422,
        Kantonskürzel: 'VD',
        E: 6.392147415267419,
        N: 46.49671941989498,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Montherod',
        PLZ: 1174,
        Zusatzziffer: 0,
        Gemeindename: 'Aubonne',
        'BFS-Nr': 5422,
        Kantonskürzel: 'VD',
        E: 6.365783620335947,
        N: 46.498779376157046,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Pizy',
        PLZ: 1174,
        Zusatzziffer: 2,
        Gemeindename: 'Aubonne',
        'BFS-Nr': 5422,
        Kantonskürzel: 'VD',
        E: 6.3477515732709175,
        N: 46.49392988128952,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Ballens',
        PLZ: 1144,
        Zusatzziffer: 0,
        Gemeindename: 'Ballens',
        'BFS-Nr': 5423,
        Kantonskürzel: 'VD',
        E: 6.3740798439638375,
        N: 46.554690587938026,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Berolle',
        PLZ: 1149,
        Zusatzziffer: 0,
        Gemeindename: 'Berolle',
        'BFS-Nr': 5424,
        Kantonskürzel: 'VD',
        E: 6.336612068630657,
        N: 46.55822468429219,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Bière',
        PLZ: 1145,
        Zusatzziffer: 0,
        Gemeindename: 'Bière',
        'BFS-Nr': 5425,
        Kantonskürzel: 'VD',
        E: 6.333425626588881,
        N: 46.5381635918027,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Bougy-Villars',
        PLZ: 1172,
        Zusatzziffer: 0,
        Gemeindename: 'Bougy-Villars',
        'BFS-Nr': 5426,
        Kantonskürzel: 'VD',
        E: 6.354063424153514,
        N: 46.47894221569074,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Féchy',
        PLZ: 1173,
        Zusatzziffer: 0,
        Gemeindename: 'Féchy',
        'BFS-Nr': 5427,
        Kantonskürzel: 'VD',
        E: 6.369900213676352,
        N: 46.48117050517379,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Gimel',
        PLZ: 1188,
        Zusatzziffer: 0,
        Gemeindename: 'Gimel',
        'BFS-Nr': 5428,
        Kantonskürzel: 'VD',
        E: 6.309750862682947,
        N: 46.50814106486584,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Longirod',
        PLZ: 1261,
        Zusatzziffer: 35,
        Gemeindename: 'Longirod',
        'BFS-Nr': 5429,
        Kantonskürzel: 'VD',
        E: 6.258434168990332,
        N: 46.49480257535658,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Marchissy',
        PLZ: 1261,
        Zusatzziffer: 32,
        Gemeindename: 'Marchissy',
        'BFS-Nr': 5430,
        Kantonskürzel: 'VD',
        E: 6.247316457812166,
        N: 46.48722158440335,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Mollens VD',
        PLZ: 1146,
        Zusatzziffer: 0,
        Gemeindename: 'Mollens (VD)',
        'BFS-Nr': 5431,
        Kantonskürzel: 'VD',
        E: 6.363170872666193,
        N: 46.57790905321699,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'St-George',
        PLZ: 1188,
        Zusatzziffer: 2,
        Gemeindename: 'Saint-George',
        'BFS-Nr': 5434,
        Kantonskürzel: 'VD',
        E: 6.26253505864636,
        N: 46.51471320420317,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'St-Livres',
        PLZ: 1176,
        Zusatzziffer: 0,
        Gemeindename: 'Saint-Livres',
        'BFS-Nr': 5435,
        Kantonskürzel: 'VD',
        E: 6.386683355853268,
        N: 46.50736109114683,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'St-Oyens',
        PLZ: 1187,
        Zusatzziffer: 0,
        Gemeindename: 'Saint-Oyens',
        'BFS-Nr': 5436,
        Kantonskürzel: 'VD',
        E: 6.309108317939858,
        N: 46.498821627154896,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Saubraz',
        PLZ: 1189,
        Zusatzziffer: 0,
        Gemeindename: 'Saubraz',
        'BFS-Nr': 5437,
        Kantonskürzel: 'VD',
        E: 6.330347568955334,
        N: 46.514265498167745,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Avenches',
        PLZ: 1580,
        Zusatzziffer: 0,
        Gemeindename: 'Avenches',
        'BFS-Nr': 5451,
        Kantonskürzel: 'VD',
        E: 7.040606869945081,
        N: 46.880248350926664,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Oleyres',
        PLZ: 1580,
        Zusatzziffer: 2,
        Gemeindename: 'Avenches',
        'BFS-Nr': 5451,
        Kantonskürzel: 'VD',
        E: 7.038094191549378,
        N: 46.8532297429827,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Donatyre',
        PLZ: 1580,
        Zusatzziffer: 3,
        Gemeindename: 'Avenches',
        'BFS-Nr': 5451,
        Kantonskürzel: 'VD',
        E: 7.057986107959758,
        N: 46.87665426776402,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Cudrefin',
        PLZ: 1588,
        Zusatzziffer: 0,
        Gemeindename: 'Cudrefin',
        'BFS-Nr': 5456,
        Kantonskürzel: 'VD',
        E: 7.019627598204514,
        N: 46.95528216753253,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Faoug',
        PLZ: 1595,
        Zusatzziffer: 0,
        Gemeindename: 'Faoug',
        'BFS-Nr': 5458,
        Kantonskürzel: 'VD',
        E: 7.077399675110069,
        N: 46.908754627035314,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Villars-le-Grand',
        PLZ: 1584,
        Zusatzziffer: 0,
        Gemeindename: 'Vully-les-Lacs',
        'BFS-Nr': 5464,
        Kantonskürzel: 'VD',
        E: 6.989996803861851,
        N: 46.90550040146312,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Salavaux',
        PLZ: 1585,
        Zusatzziffer: 0,
        Gemeindename: 'Vully-les-Lacs',
        'BFS-Nr': 5464,
        Kantonskürzel: 'VD',
        E: 7.022933153865684,
        N: 46.916899964513114,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Bellerive VD',
        PLZ: 1585,
        Zusatzziffer: 2,
        Gemeindename: 'Vully-les-Lacs',
        'BFS-Nr': 5464,
        Kantonskürzel: 'VD',
        E: 7.023284162730551,
        N: 46.92461066585939,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Cotterd',
        PLZ: 1585,
        Zusatzziffer: 3,
        Gemeindename: 'Vully-les-Lacs',
        'BFS-Nr': 5464,
        Kantonskürzel: 'VD',
        E: 7.0257615881854925,
        N: 46.92160131024661,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Vallamand',
        PLZ: 1586,
        Zusatzziffer: 0,
        Gemeindename: 'Vully-les-Lacs',
        'BFS-Nr': 5464,
        Kantonskürzel: 'VD',
        E: 7.038677408012126,
        N: 46.93176203837587,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Montmagny',
        PLZ: 1587,
        Zusatzziffer: 0,
        Gemeindename: 'Vully-les-Lacs',
        'BFS-Nr': 5464,
        Kantonskürzel: 'VD',
        E: 7.00938587925615,
        N: 46.9267709128461,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Constantine',
        PLZ: 1587,
        Zusatzziffer: 1,
        Gemeindename: 'Vully-les-Lacs',
        'BFS-Nr': 5464,
        Kantonskürzel: 'VD',
        E: 7.012096787172144,
        N: 46.918807621700125,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Chabrey',
        PLZ: 1589,
        Zusatzziffer: 0,
        Gemeindename: 'Vully-les-Lacs',
        'BFS-Nr': 5464,
        Kantonskürzel: 'VD',
        E: 6.981421924050195,
        N: 46.92702541294073,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Mur (Vully) VD',
        PLZ: 1787,
        Zusatzziffer: 2,
        Gemeindename: 'Vully-les-Lacs',
        'BFS-Nr': 5464,
        Kantonskürzel: 'VD',
        E: 7.059970250862907,
        N: 46.94496413086314,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Bettens',
        PLZ: 1042,
        Zusatzziffer: 4,
        Gemeindename: 'Bettens',
        'BFS-Nr': 5471,
        Kantonskürzel: 'VD',
        E: 6.577339282375479,
        N: 46.62574198838093,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Bournens',
        PLZ: 1035,
        Zusatzziffer: 0,
        Gemeindename: 'Bournens',
        'BFS-Nr': 5472,
        Kantonskürzel: 'VD',
        E: 6.56376763768004,
        N: 46.60381147127098,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Boussens',
        PLZ: 1034,
        Zusatzziffer: 0,
        Gemeindename: 'Boussens',
        'BFS-Nr': 5473,
        Kantonskürzel: 'VD',
        E: 6.5827749163239115,
        N: 46.60374591713379,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'La Chaux (Cossonay)',
        PLZ: 1308,
        Zusatzziffer: 0,
        Gemeindename: 'La Chaux (Cossonay)',
        'BFS-Nr': 5474,
        Kantonskürzel: 'VD',
        E: 6.472897602708939,
        N: 46.61800590115866,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Chavannes-le-Veyron',
        PLZ: 1148,
        Zusatzziffer: 7,
        Gemeindename: 'Chavannes-le-Veyron',
        'BFS-Nr': 5475,
        Kantonskürzel: 'VD',
        E: 6.451340311866689,
        N: 46.60591163824979,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Chevilly',
        PLZ: 1316,
        Zusatzziffer: 0,
        Gemeindename: 'Chevilly',
        'BFS-Nr': 5476,
        Kantonskürzel: 'VD',
        E: 6.476963208899111,
        N: 46.64234754541369,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Cossonay-Ville',
        PLZ: 1304,
        Zusatzziffer: 0,
        Gemeindename: 'Cossonay',
        'BFS-Nr': 5477,
        Kantonskürzel: 'VD',
        E: 6.509436340591353,
        N: 46.614216153098816,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Allens',
        PLZ: 1304,
        Zusatzziffer: 3,
        Gemeindename: 'Cossonay',
        'BFS-Nr': 5477,
        Kantonskürzel: 'VD',
        E: 6.509810413782632,
        N: 46.60011632937013,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Cuarnens',
        PLZ: 1148,
        Zusatzziffer: 4,
        Gemeindename: 'Cuarnens',
        'BFS-Nr': 5479,
        Kantonskürzel: 'VD',
        E: 6.4383627277859805,
        N: 46.6252813597524,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Daillens',
        PLZ: 1306,
        Zusatzziffer: 0,
        Gemeindename: 'Daillens',
        'BFS-Nr': 5480,
        Kantonskürzel: 'VD',
        E: 6.548779762748585,
        N: 46.62049808726464,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Dizy',
        PLZ: 1304,
        Zusatzziffer: 2,
        Gemeindename: 'Dizy',
        'BFS-Nr': 5481,
        Kantonskürzel: 'VD',
        E: 6.49628133228335,
        N: 46.63559204965237,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Eclépens',
        PLZ: 1312,
        Zusatzziffer: 0,
        Gemeindename: 'Eclépens',
        'BFS-Nr': 5482,
        Kantonskürzel: 'VD',
        E: 6.525699488760172,
        N: 46.652473297882324,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Ferreyres',
        PLZ: 1313,
        Zusatzziffer: 0,
        Gemeindename: 'Ferreyres',
        'BFS-Nr': 5483,
        Kantonskürzel: 'VD',
        E: 6.485469186457913,
        N: 46.658378561196294,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Gollion',
        PLZ: 1124,
        Zusatzziffer: 0,
        Gemeindename: 'Gollion',
        'BFS-Nr': 5484,
        Kantonskürzel: 'VD',
        E: 6.508397736935717,
        N: 46.58524040908587,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Grancy',
        PLZ: 1117,
        Zusatzziffer: 0,
        Gemeindename: 'Grancy',
        'BFS-Nr': 5485,
        Kantonskürzel: 'VD',
        E: 6.4641613425320585,
        N: 46.59172198443532,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'L\'Isle',
        PLZ: 1148,
        Zusatzziffer: 0,
        Gemeindename: 'L\'Isle',
        'BFS-Nr': 5486,
        Kantonskürzel: 'VD',
        E: 6.413186379603166,
        N: 46.61789483414457,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Villars-Bozon',
        PLZ: 1148,
        Zusatzziffer: 3,
        Gemeindename: 'L\'Isle',
        'BFS-Nr': 5486,
        Kantonskürzel: 'VD',
        E: 6.407258030445664,
        N: 46.60655598641696,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'La Coudre',
        PLZ: 1148,
        Zusatzziffer: 9,
        Gemeindename: 'L\'Isle',
        'BFS-Nr': 5486,
        Kantonskürzel: 'VD',
        E: 6.402816454700967,
        N: 46.63906133148906,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Lussery-Villars',
        PLZ: 1307,
        Zusatzziffer: 0,
        Gemeindename: 'Lussery-Villars',
        'BFS-Nr': 5487,
        Kantonskürzel: 'VD',
        E: 6.525997132153527,
        N: 46.63290061627385,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Mauraz',
        PLZ: 1148,
        Zusatzziffer: 2,
        Gemeindename: 'Mauraz',
        'BFS-Nr': 5488,
        Kantonskürzel: 'VD',
        E: 6.422517690697587,
        N: 46.60524408309125,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Mex VD',
        PLZ: 1031,
        Zusatzziffer: 0,
        Gemeindename: 'Mex (VD)',
        'BFS-Nr': 5489,
        Kantonskürzel: 'VD',
        E: 6.5522859648285205,
        N: 46.57749798469106,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Moiry VD',
        PLZ: 1148,
        Zusatzziffer: 8,
        Gemeindename: 'Moiry',
        'BFS-Nr': 5490,
        Kantonskürzel: 'VD',
        E: 6.453647546013383,
        N: 46.64853545354674,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Mont-la-Ville',
        PLZ: 1148,
        Zusatzziffer: 5,
        Gemeindename: 'Mont-la-Ville',
        'BFS-Nr': 5491,
        Kantonskürzel: 'VD',
        E: 6.408887015696761,
        N: 46.64590119935128,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Montricher',
        PLZ: 1147,
        Zusatzziffer: 0,
        Gemeindename: 'Montricher',
        'BFS-Nr': 5492,
        Kantonskürzel: 'VD',
        E: 6.376921388278247,
        N: 46.60115132552307,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Orny',
        PLZ: 1317,
        Zusatzziffer: 0,
        Gemeindename: 'Orny',
        'BFS-Nr': 5493,
        Kantonskürzel: 'VD',
        E: 6.525591765330085,
        N: 46.66831302081415,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Penthalaz',
        PLZ: 1305,
        Zusatzziffer: 0,
        Gemeindename: 'Penthalaz',
        'BFS-Nr': 5495,
        Kantonskürzel: 'VD',
        E: 6.529311132123204,
        N: 46.61426354224973,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Penthaz',
        PLZ: 1303,
        Zusatzziffer: 0,
        Gemeindename: 'Penthaz',
        'BFS-Nr': 5496,
        Kantonskürzel: 'VD',
        E: 6.538843026606873,
        N: 46.60032175204984,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Pompaples',
        PLZ: 1318,
        Zusatzziffer: 0,
        Gemeindename: 'Pompaples',
        'BFS-Nr': 5497,
        Kantonskürzel: 'VD',
        E: 6.511229077314487,
        N: 46.666280149347486,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'La Sarraz',
        PLZ: 1315,
        Zusatzziffer: 0,
        Gemeindename: 'La Sarraz',
        'BFS-Nr': 5498,
        Kantonskürzel: 'VD',
        E: 6.514473254000137,
        N: 46.65953843783619,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Senarclens',
        PLZ: 1304,
        Zusatzziffer: 4,
        Gemeindename: 'Senarclens',
        'BFS-Nr': 5499,
        Kantonskürzel: 'VD',
        E: 6.488992623649115,
        N: 46.60080505540386,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Sullens',
        PLZ: 1036,
        Zusatzziffer: 0,
        Gemeindename: 'Sullens',
        'BFS-Nr': 5501,
        Kantonskürzel: 'VD',
        E: 6.56705097929716,
        N: 46.594298526517605,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Vufflens-la-Ville',
        PLZ: 1302,
        Zusatzziffer: 0,
        Gemeindename: 'Vufflens-la-Ville',
        'BFS-Nr': 5503,
        Kantonskürzel: 'VD',
        E: 6.5388769762941,
        N: 46.5769640602468,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Assens',
        PLZ: 1042,
        Zusatzziffer: 0,
        Gemeindename: 'Assens',
        'BFS-Nr': 5511,
        Kantonskürzel: 'VD',
        E: 6.622637836349773,
        N: 46.612560205560925,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Bioley-Orjulaz',
        PLZ: 1042,
        Zusatzziffer: 3,
        Gemeindename: 'Assens',
        'BFS-Nr': 5511,
        Kantonskürzel: 'VD',
        E: 6.597390123455131,
        N: 46.62184833599527,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Bercher',
        PLZ: 1038,
        Zusatzziffer: 0,
        Gemeindename: 'Bercher',
        'BFS-Nr': 5512,
        Kantonskürzel: 'VD',
        E: 6.708521981714881,
        N: 46.692155004445176,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Bottens',
        PLZ: 1041,
        Zusatzziffer: 33,
        Gemeindename: 'Bottens',
        'BFS-Nr': 5514,
        Kantonskürzel: 'VD',
        E: 6.659493143346604,
        N: 46.62057994873499,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Bretigny-sur-Morrens',
        PLZ: 1053,
        Zusatzziffer: 2,
        Gemeindename: 'Bretigny-sur-Morrens',
        'BFS-Nr': 5515,
        Kantonskürzel: 'VD',
        E: 6.642521978312031,
        N: 46.59778996892911,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Cugy VD',
        PLZ: 1053,
        Zusatzziffer: 0,
        Gemeindename: 'Cugy (VD)',
        'BFS-Nr': 5516,
        Kantonskürzel: 'VD',
        E: 6.641161117867429,
        N: 46.584244691469785,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Echallens',
        PLZ: 1040,
        Zusatzziffer: 0,
        Gemeindename: 'Echallens',
        'BFS-Nr': 5518,
        Kantonskürzel: 'VD',
        E: 6.634071133841577,
        N: 46.64077072186312,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Essertines-sur-Yverdon',
        PLZ: 1417,
        Zusatzziffer: 0,
        Gemeindename: 'Essertines-sur-Yverdon',
        'BFS-Nr': 5520,
        Kantonskürzel: 'VD',
        E: 6.637980053751581,
        N: 46.71835465216508,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Epautheyres',
        PLZ: 1417,
        Zusatzziffer: 2,
        Gemeindename: 'Essertines-sur-Yverdon',
        'BFS-Nr': 5520,
        Kantonskürzel: 'VD',
        E: 6.643197659706145,
        N: 46.739510742587186,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Etagnières',
        PLZ: 1037,
        Zusatzziffer: 0,
        Gemeindename: 'Etagnières',
        'BFS-Nr': 5521,
        Kantonskürzel: 'VD',
        E: 6.611946988769706,
        N: 46.60009590704349,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Fey',
        PLZ: 1044,
        Zusatzziffer: 0,
        Gemeindename: 'Fey',
        'BFS-Nr': 5522,
        Kantonskürzel: 'VD',
        E: 6.681244837165686,
        N: 46.676087973888535,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Froideville',
        PLZ: 1055,
        Zusatzziffer: 0,
        Gemeindename: 'Froideville',
        'BFS-Nr': 5523,
        Kantonskürzel: 'VD',
        E: 6.68074188934409,
        N: 46.601776089163316,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Morrens VD',
        PLZ: 1054,
        Zusatzziffer: 0,
        Gemeindename: 'Morrens (VD)',
        'BFS-Nr': 5527,
        Kantonskürzel: 'VD',
        E: 6.62663748821548,
        N: 46.59118780583415,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Oulens-sous-Echallens',
        PLZ: 1377,
        Zusatzziffer: 0,
        Gemeindename: 'Oulens-sous-Echallens',
        'BFS-Nr': 5529,
        Kantonskürzel: 'VD',
        E: 6.578322478166343,
        N: 46.64357561187086,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Pailly',
        PLZ: 1416,
        Zusatzziffer: 0,
        Gemeindename: 'Pailly',
        'BFS-Nr': 5530,
        Kantonskürzel: 'VD',
        E: 6.672463639324812,
        N: 46.69931417554038,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Penthéréaz',
        PLZ: 1375,
        Zusatzziffer: 0,
        Gemeindename: 'Penthéréaz',
        'BFS-Nr': 5531,
        Kantonskürzel: 'VD',
        E: 6.603534363648163,
        N: 46.681994262492296,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Poliez-Pittet',
        PLZ: 1041,
        Zusatzziffer: 32,
        Gemeindename: 'Poliez-Pittet',
        'BFS-Nr': 5533,
        Kantonskürzel: 'VD',
        E: 6.68250601026579,
        N: 46.62755999657034,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Rueyres',
        PLZ: 1046,
        Zusatzziffer: 0,
        Gemeindename: 'Rueyres',
        'BFS-Nr': 5534,
        Kantonskürzel: 'VD',
        E: 6.6918961390710745,
        N: 46.693449055022135,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'St-Barthélemy VD',
        PLZ: 1040,
        Zusatzziffer: 3,
        Gemeindename: 'Saint-Barthélemy (VD)',
        'BFS-Nr': 5535,
        Kantonskürzel: 'VD',
        E: 6.594747797252885,
        N: 46.63405473879089,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Villars-le-Terroir',
        PLZ: 1040,
        Zusatzziffer: 2,
        Gemeindename: 'Villars-le-Terroir',
        'BFS-Nr': 5537,
        Kantonskürzel: 'VD',
        E: 6.639055955667947,
        N: 46.65776087821219,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Vuarrens',
        PLZ: 1418,
        Zusatzziffer: 0,
        Gemeindename: 'Vuarrens',
        'BFS-Nr': 5539,
        Kantonskürzel: 'VD',
        E: 6.64824417588345,
        N: 46.68724176105717,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Dommartin',
        PLZ: 1041,
        Zusatzziffer: 21,
        Gemeindename: 'Montilliez',
        'BFS-Nr': 5540,
        Kantonskürzel: 'VD',
        E: 6.702723654814196,
        N: 46.65026765874446,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Naz',
        PLZ: 1041,
        Zusatzziffer: 26,
        Gemeindename: 'Montilliez',
        'BFS-Nr': 5540,
        Kantonskürzel: 'VD',
        E: 6.695394748070305,
        N: 46.65825805500925,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Poliez-le-Grand',
        PLZ: 1041,
        Zusatzziffer: 31,
        Gemeindename: 'Montilliez',
        'BFS-Nr': 5540,
        Kantonskürzel: 'VD',
        E: 6.663750400892924,
        N: 46.63873977575857,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Sugnens',
        PLZ: 1043,
        Zusatzziffer: 0,
        Gemeindename: 'Montilliez',
        'BFS-Nr': 5540,
        Kantonskürzel: 'VD',
        E: 6.670908632694332,
        N: 46.65545374852849,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Goumoens-la-Ville',
        PLZ: 1376,
        Zusatzziffer: 0,
        Gemeindename: 'Goumoëns',
        'BFS-Nr': 5541,
        Kantonskürzel: 'VD',
        E: 6.603084826733057,
        N: 46.658102761281704,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Eclagnens',
        PLZ: 1376,
        Zusatzziffer: 2,
        Gemeindename: 'Goumoëns',
        'BFS-Nr': 5541,
        Kantonskürzel: 'VD',
        E: 6.5914115696492726,
        N: 46.65148590762143,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Goumoens-le-Jux',
        PLZ: 1376,
        Zusatzziffer: 3,
        Gemeindename: 'Goumoëns',
        'BFS-Nr': 5541,
        Kantonskürzel: 'VD',
        E: 6.587481219461129,
        N: 46.665939099814146,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Bonvillars',
        PLZ: 1427,
        Zusatzziffer: 0,
        Gemeindename: 'Bonvillars',
        'BFS-Nr': 5551,
        Kantonskürzel: 'VD',
        E: 6.672409387024431,
        N: 46.83998552568698,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Les Rasses',
        PLZ: 1452,
        Zusatzziffer: 0,
        Gemeindename: 'Bullet',
        'BFS-Nr': 5552,
        Kantonskürzel: 'VD',
        E: 6.540170626347946,
        N: 46.829234868218,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Bullet',
        PLZ: 1453,
        Zusatzziffer: 0,
        Gemeindename: 'Bullet',
        'BFS-Nr': 5552,
        Kantonskürzel: 'VD',
        E: 6.554823907253395,
        N: 46.83048351618804,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Champagne',
        PLZ: 1424,
        Zusatzziffer: 0,
        Gemeindename: 'Champagne',
        'BFS-Nr': 5553,
        Kantonskürzel: 'VD',
        E: 6.658150008726031,
        N: 46.83049642112123,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Concise',
        PLZ: 1426,
        Zusatzziffer: 0,
        Gemeindename: 'Concise',
        'BFS-Nr': 5554,
        Kantonskürzel: 'VD',
        E: 6.7201748415646545,
        N: 46.84990815286729,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Corcelles-près-Concise',
        PLZ: 1426,
        Zusatzziffer: 1,
        Gemeindename: 'Corcelles-près-Concise',
        'BFS-Nr': 5555,
        Kantonskürzel: 'VD',
        E: 6.707498691636927,
        N: 46.84710317253369,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Fiez',
        PLZ: 1420,
        Zusatzziffer: 0,
        Gemeindename: 'Fiez',
        'BFS-Nr': 5556,
        Kantonskürzel: 'VD',
        E: 6.623613426791585,
        N: 46.82632374405788,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Fontaines-sur-Grandson',
        PLZ: 1421,
        Zusatzziffer: 0,
        Gemeindename: 'Fontaines-sur-Grandson',
        'BFS-Nr': 5557,
        Kantonskürzel: 'VD',
        E: 6.619319785759893,
        N: 46.83533414608756,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Giez',
        PLZ: 1429,
        Zusatzziffer: 0,
        Gemeindename: 'Giez',
        'BFS-Nr': 5559,
        Kantonskürzel: 'VD',
        E: 6.616413043841555,
        N: 46.80957595856203,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Grandevent',
        PLZ: 1421,
        Zusatzziffer: 2,
        Gemeindename: 'Grandevent',
        'BFS-Nr': 5560,
        Kantonskürzel: 'VD',
        E: 6.6063658664419505,
        N: 46.83845831563669,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Grandson',
        PLZ: 1422,
        Zusatzziffer: 0,
        Gemeindename: 'Grandson',
        'BFS-Nr': 5561,
        Kantonskürzel: 'VD',
        E: 6.64358750047391,
        N: 46.80832761320712,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Mauborget',
        PLZ: 1453,
        Zusatzziffer: 2,
        Gemeindename: 'Mauborget',
        'BFS-Nr': 5562,
        Kantonskürzel: 'VD',
        E: 6.616810730713964,
        N: 46.85576434366759,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Mutrux',
        PLZ: 1428,
        Zusatzziffer: 2,
        Gemeindename: 'Mutrux',
        'BFS-Nr': 5563,
        Kantonskürzel: 'VD',
        E: 6.727485322996016,
        N: 46.8831138520894,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Novalles',
        PLZ: 1431,
        Zusatzziffer: 2,
        Gemeindename: 'Novalles',
        'BFS-Nr': 5564,
        Kantonskürzel: 'VD',
        E: 6.595859596145269,
        N: 46.82841059821435,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Onnens VD',
        PLZ: 1425,
        Zusatzziffer: 0,
        Gemeindename: 'Onnens (VD)',
        'BFS-Nr': 5565,
        Kantonskürzel: 'VD',
        E: 6.686133880560038,
        N: 46.838530630456894,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Provence',
        PLZ: 1428,
        Zusatzziffer: 1,
        Gemeindename: 'Provence',
        'BFS-Nr': 5566,
        Kantonskürzel: 'VD',
        E: 6.726265372241401,
        N: 46.88999998444018,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Ste-Croix',
        PLZ: 1450,
        Zusatzziffer: 0,
        Gemeindename: 'Sainte-Croix',
        'BFS-Nr': 5568,
        Kantonskürzel: 'VD',
        E: 6.502974268913817,
        N: 46.823248245946736,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'La Sagne (Ste-Croix)',
        PLZ: 1450,
        Zusatzziffer: 2,
        Gemeindename: 'Sainte-Croix',
        'BFS-Nr': 5568,
        Kantonskürzel: 'VD',
        E: 6.49747640956343,
        N: 46.814861556435005,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Le Château-de-Ste-Croix',
        PLZ: 1450,
        Zusatzziffer: 3,
        Gemeindename: 'Sainte-Croix',
        'BFS-Nr': 5568,
        Kantonskürzel: 'VD',
        E: 6.534105277425332,
        N: 46.818234157204365,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'L\'Auberson',
        PLZ: 1454,
        Zusatzziffer: 0,
        Gemeindename: 'Sainte-Croix',
        'BFS-Nr': 5568,
        Kantonskürzel: 'VD',
        E: 6.463014298145715,
        N: 46.81801643155601,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'La Vraconnaz',
        PLZ: 1454,
        Zusatzziffer: 2,
        Gemeindename: 'Sainte-Croix',
        'BFS-Nr': 5568,
        Kantonskürzel: 'VD',
        E: 6.482598007662461,
        N: 46.84172158953782,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Villars-Burquin',
        PLZ: 1423,
        Zusatzziffer: 0,
        Gemeindename: 'Tévenon',
        'BFS-Nr': 5571,
        Kantonskürzel: 'VD',
        E: 6.625272351429598,
        N: 46.846633280624836,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Fontanezier',
        PLZ: 1423,
        Zusatzziffer: 2,
        Gemeindename: 'Tévenon',
        'BFS-Nr': 5571,
        Kantonskürzel: 'VD',
        E: 6.653370269564585,
        N: 46.853853522279,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Romairon',
        PLZ: 1423,
        Zusatzziffer: 3,
        Gemeindename: 'Tévenon',
        'BFS-Nr': 5571,
        Kantonskürzel: 'VD',
        E: 6.641940598314928,
        N: 46.85074835709256,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Vaugondry',
        PLZ: 1423,
        Zusatzziffer: 4,
        Gemeindename: 'Tévenon',
        'BFS-Nr': 5571,
        Kantonskürzel: 'VD',
        E: 6.636856319445832,
        N: 46.846431615244164,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Belmont-sur-Lausanne',
        PLZ: 1092,
        Zusatzziffer: 0,
        Gemeindename: 'Belmont-sur-Lausanne',
        'BFS-Nr': 5581,
        Kantonskürzel: 'VD',
        E: 6.680420543692541,
        N: 46.52011650513355,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Cheseaux-sur-Lausanne',
        PLZ: 1033,
        Zusatzziffer: 0,
        Gemeindename: 'Cheseaux-sur-Lausanne',
        'BFS-Nr': 5582,
        Kantonskürzel: 'VD',
        E: 6.605033984584373,
        N: 46.584875084536165,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Crissier',
        PLZ: 1023,
        Zusatzziffer: 0,
        Gemeindename: 'Crissier',
        'BFS-Nr': 5583,
        Kantonskürzel: 'VD',
        E: 6.578758801563157,
        N: 46.5561694321503,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Epalinges',
        PLZ: 1066,
        Zusatzziffer: 0,
        Gemeindename: 'Epalinges',
        'BFS-Nr': 5584,
        Kantonskürzel: 'VD',
        E: 6.673652602319469,
        N: 46.54595132676624,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Jouxtens-Mézery',
        PLZ: 1008,
        Zusatzziffer: 2,
        Gemeindename: 'Jouxtens-Mézery',
        'BFS-Nr': 5585,
        Kantonskürzel: 'VD',
        E: 6.597695685256937,
        N: 46.55280623616876,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Lausanne 25',
        PLZ: 1000,
        Zusatzziffer: 25,
        Gemeindename: 'Lausanne',
        'BFS-Nr': 5586,
        Kantonskürzel: 'VD',
        E: 6.683443539253286,
        N: 46.56223671140397,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Lausanne 26',
        PLZ: 1000,
        Zusatzziffer: 26,
        Gemeindename: 'Lausanne',
        'BFS-Nr': 5586,
        Kantonskürzel: 'VD',
        E: 6.696215827457423,
        N: 46.55648337576275,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Lausanne 27',
        PLZ: 1000,
        Zusatzziffer: 27,
        Gemeindename: 'Lausanne',
        'BFS-Nr': 5586,
        Kantonskürzel: 'VD',
        E: 6.681464584827575,
        N: 46.541742675143546,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Lausanne',
        PLZ: 1003,
        Zusatzziffer: 0,
        Gemeindename: 'Lausanne',
        'BFS-Nr': 5586,
        Kantonskürzel: 'VD',
        E: 6.630104680319968,
        N: 46.520004325199174,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Lausanne',
        PLZ: 1004,
        Zusatzziffer: 0,
        Gemeindename: 'Lausanne',
        'BFS-Nr': 5586,
        Kantonskürzel: 'VD',
        E: 6.618677635496144,
        N: 46.52848029958016,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Lausanne',
        PLZ: 1005,
        Zusatzziffer: 0,
        Gemeindename: 'Lausanne',
        'BFS-Nr': 5586,
        Kantonskürzel: 'VD',
        E: 6.642500088546282,
        N: 46.519858888648734,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Lausanne',
        PLZ: 1006,
        Zusatzziffer: 0,
        Gemeindename: 'Lausanne',
        'BFS-Nr': 5586,
        Kantonskürzel: 'VD',
        E: 6.637483846312789,
        N: 46.48565168357765,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Lausanne',
        PLZ: 1007,
        Zusatzziffer: 0,
        Gemeindename: 'Lausanne',
        'BFS-Nr': 5586,
        Kantonskürzel: 'VD',
        E: 6.609552186460221,
        N: 46.48984063323985,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Lausanne',
        PLZ: 1010,
        Zusatzziffer: 0,
        Gemeindename: 'Lausanne',
        'BFS-Nr': 5586,
        Kantonskürzel: 'VD',
        E: 6.6589203004653745,
        N: 46.53614302589882,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Lausanne',
        PLZ: 1011,
        Zusatzziffer: 0,
        Gemeindename: 'Lausanne',
        'BFS-Nr': 5586,
        Kantonskürzel: 'VD',
        E: 6.642775885775834,
        N: 46.52638207142071,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Lausanne',
        PLZ: 1012,
        Zusatzziffer: 0,
        Gemeindename: 'Lausanne',
        'BFS-Nr': 5586,
        Kantonskürzel: 'VD',
        E: 6.656932149576898,
        N: 46.52627321083718,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Lausanne',
        PLZ: 1015,
        Zusatzziffer: 0,
        Gemeindename: 'Lausanne',
        'BFS-Nr': 5586,
        Kantonskürzel: 'VD',
        E: 6.583028727835654,
        N: 46.517264764138496,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Lausanne',
        PLZ: 1018,
        Zusatzziffer: 0,
        Gemeindename: 'Lausanne',
        'BFS-Nr': 5586,
        Kantonskürzel: 'VD',
        E: 6.628631331184677,
        N: 46.53876301752992,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Le Mont-sur-Lausanne',
        PLZ: 1052,
        Zusatzziffer: 0,
        Gemeindename: 'Le Mont-sur-Lausanne',
        'BFS-Nr': 5587,
        Kantonskürzel: 'VD',
        E: 6.636118333231582,
        N: 46.55679959062843,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Paudex',
        PLZ: 1094,
        Zusatzziffer: 0,
        Gemeindename: 'Paudex',
        'BFS-Nr': 5588,
        Kantonskürzel: 'VD',
        E: 6.672349201540892,
        N: 46.505958539189805,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Prilly',
        PLZ: 1008,
        Zusatzziffer: 0,
        Gemeindename: 'Prilly',
        'BFS-Nr': 5589,
        Kantonskürzel: 'VD',
        E: 6.607760315753399,
        N: 46.53437454595488,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Pully',
        PLZ: 1009,
        Zusatzziffer: 0,
        Gemeindename: 'Pully',
        'BFS-Nr': 5590,
        Kantonskürzel: 'VD',
        E: 6.661971123280417,
        N: 46.509185250734056,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Les Monts-de-Pully',
        PLZ: 1068,
        Zusatzziffer: 0,
        Gemeindename: 'Pully',
        'BFS-Nr': 5590,
        Kantonskürzel: 'VD',
        E: 6.683372827594051,
        N: 46.53417175545359,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Renens VD',
        PLZ: 1020,
        Zusatzziffer: 0,
        Gemeindename: 'Renens (VD)',
        'BFS-Nr': 5591,
        Kantonskürzel: 'VD',
        E: 6.590677649359407,
        N: 46.536826675010445,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Romanel-sur-Lausanne',
        PLZ: 1032,
        Zusatzziffer: 0,
        Gemeindename: 'Romanel-sur-Lausanne',
        'BFS-Nr': 5592,
        Kantonskürzel: 'VD',
        E: 6.606144558694388,
        N: 46.56319399143231,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Chexbres',
        PLZ: 1071,
        Zusatzziffer: 0,
        Gemeindename: 'Chexbres',
        'BFS-Nr': 5601,
        Kantonskürzel: 'VD',
        E: 6.777522333209496,
        N: 46.482253175577625,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Forel (Lavaux)',
        PLZ: 1072,
        Zusatzziffer: 0,
        Gemeindename: 'Forel (Lavaux)',
        'BFS-Nr': 5604,
        Kantonskürzel: 'VD',
        E: 6.7698421201311465,
        N: 46.541162668577876,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'La Croix (Lutry)',
        PLZ: 1090,
        Zusatzziffer: 0,
        Gemeindename: 'Lutry',
        'BFS-Nr': 5606,
        Kantonskürzel: 'VD',
        E: 6.702699255525531,
        N: 46.51666374061145,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'La Conversion',
        PLZ: 1093,
        Zusatzziffer: 0,
        Gemeindename: 'Lutry',
        'BFS-Nr': 5606,
        Kantonskürzel: 'VD',
        E: 6.686219098730866,
        N: 46.514914874361565,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Lutry',
        PLZ: 1095,
        Zusatzziffer: 0,
        Gemeindename: 'Lutry',
        'BFS-Nr': 5606,
        Kantonskürzel: 'VD',
        E: 6.686387690728701,
        N: 46.502679140520144,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Puidoux',
        PLZ: 1070,
        Zusatzziffer: 0,
        Gemeindename: 'Puidoux',
        'BFS-Nr': 5607,
        Kantonskürzel: 'VD',
        E: 6.766130385273231,
        N: 46.49386437593245,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Rivaz',
        PLZ: 1071,
        Zusatzziffer: 1,
        Gemeindename: 'Rivaz',
        'BFS-Nr': 5609,
        Kantonskürzel: 'VD',
        E: 6.778516695841846,
        N: 46.47635639864878,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'St-Saphorin (Lavaux)',
        PLZ: 1071,
        Zusatzziffer: 2,
        Gemeindename: 'Saint-Saphorin (Lavaux)',
        'BFS-Nr': 5610,
        Kantonskürzel: 'VD',
        E: 6.796101109485536,
        N: 46.473414475389646,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Savigny',
        PLZ: 1073,
        Zusatzziffer: 0,
        Gemeindename: 'Savigny',
        'BFS-Nr': 5611,
        Kantonskürzel: 'VD',
        E: 6.733058093367193,
        N: 46.537590818141354,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Mollie-Margot',
        PLZ: 1073,
        Zusatzziffer: 2,
        Gemeindename: 'Savigny',
        'BFS-Nr': 5611,
        Kantonskürzel: 'VD',
        E: 6.75038454204825,
        N: 46.55681489238539,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Grandvaux',
        PLZ: 1091,
        Zusatzziffer: 0,
        Gemeindename: 'Bourg-en-Lavaux',
        'BFS-Nr': 5613,
        Kantonskürzel: 'VD',
        E: 6.716250538070151,
        N: 46.49370574028894,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Aran',
        PLZ: 1091,
        Zusatzziffer: 1,
        Gemeindename: 'Bourg-en-Lavaux',
        'BFS-Nr': 5613,
        Kantonskürzel: 'VD',
        E: 6.710337665412657,
        N: 46.49800783972963,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Chenaux',
        PLZ: 1091,
        Zusatzziffer: 3,
        Gemeindename: 'Bourg-en-Lavaux',
        'BFS-Nr': 5613,
        Kantonskürzel: 'VD',
        E: 6.728768283108007,
        N: 46.49476621937237,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Cully',
        PLZ: 1096,
        Zusatzziffer: 0,
        Gemeindename: 'Bourg-en-Lavaux',
        'BFS-Nr': 5613,
        Kantonskürzel: 'VD',
        E: 6.7294024446672775,
        N: 46.4887306575382,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Villette (Lavaux)',
        PLZ: 1096,
        Zusatzziffer: 2,
        Gemeindename: 'Bourg-en-Lavaux',
        'BFS-Nr': 5613,
        Kantonskürzel: 'VD',
        E: 6.710541748655343,
        N: 46.49371207768561,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Riex',
        PLZ: 1097,
        Zusatzziffer: 0,
        Gemeindename: 'Bourg-en-Lavaux',
        'BFS-Nr': 5613,
        Kantonskürzel: 'VD',
        E: 6.735688848690609,
        N: 46.493314544195414,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Epesses',
        PLZ: 1098,
        Zusatzziffer: 0,
        Gemeindename: 'Bourg-en-Lavaux',
        'BFS-Nr': 5613,
        Kantonskürzel: 'VD',
        E: 6.74819412655127,
        N: 46.491124217668826,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Aclens',
        PLZ: 1123,
        Zusatzziffer: 0,
        Gemeindename: 'Aclens',
        'BFS-Nr': 5621,
        Kantonskürzel: 'VD',
        E: 6.509843090640182,
        N: 46.567655451282,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Bremblens',
        PLZ: 1121,
        Zusatzziffer: 0,
        Gemeindename: 'Bremblens',
        'BFS-Nr': 5622,
        Kantonskürzel: 'VD',
        E: 6.5177066586130925,
        N: 46.546688986033665,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Buchillon',
        PLZ: 1164,
        Zusatzziffer: 0,
        Gemeindename: 'Buchillon',
        'BFS-Nr': 5623,
        Kantonskürzel: 'VD',
        E: 6.4212490096837636,
        N: 46.470165093503255,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Bussigny',
        PLZ: 1030,
        Zusatzziffer: 0,
        Gemeindename: 'Bussigny',
        'BFS-Nr': 5624,
        Kantonskürzel: 'VD',
        E: 6.5510605060479445,
        N: 46.55316478683512,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Chavannes-près-Renens',
        PLZ: 1022,
        Zusatzziffer: 0,
        Gemeindename: 'Chavannes-près-Renens',
        'BFS-Nr': 5627,
        Kantonskürzel: 'VD',
        E: 6.576338446081509,
        N: 46.535840143258845,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Chigny',
        PLZ: 1134,
        Zusatzziffer: 2,
        Gemeindename: 'Chigny',
        'BFS-Nr': 5628,
        Kantonskürzel: 'VD',
        E: 6.477036046156941,
        N: 46.52008758551559,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Clarmont',
        PLZ: 1127,
        Zusatzziffer: 0,
        Gemeindename: 'Clarmont',
        'BFS-Nr': 5629,
        Kantonskürzel: 'VD',
        E: 6.450289096058974,
        N: 46.54726268277513,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Denens',
        PLZ: 1135,
        Zusatzziffer: 0,
        Gemeindename: 'Denens',
        'BFS-Nr': 5631,
        Kantonskürzel: 'VD',
        E: 6.4568039618476165,
        N: 46.519001907581035,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Denges',
        PLZ: 1026,
        Zusatzziffer: 2,
        Gemeindename: 'Denges',
        'BFS-Nr': 5632,
        Kantonskürzel: 'VD',
        E: 6.538963804466332,
        N: 46.52337064599027,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Echandens',
        PLZ: 1026,
        Zusatzziffer: 3,
        Gemeindename: 'Echandens',
        'BFS-Nr': 5633,
        Kantonskürzel: 'VD',
        E: 6.542410554788482,
        N: 46.532815941026364,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Echichens',
        PLZ: 1112,
        Zusatzziffer: 0,
        Gemeindename: 'Echichens',
        'BFS-Nr': 5634,
        Kantonskürzel: 'VD',
        E: 6.498642496746171,
        N: 46.52601637692037,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'St-Saphorin-sur-Morges',
        PLZ: 1113,
        Zusatzziffer: 0,
        Gemeindename: 'Echichens',
        'BFS-Nr': 5634,
        Kantonskürzel: 'VD',
        E: 6.48755847092189,
        N: 46.54558377933343,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Colombier VD',
        PLZ: 1114,
        Zusatzziffer: 0,
        Gemeindename: 'Echichens',
        'BFS-Nr': 5634,
        Kantonskürzel: 'VD',
        E: 6.475534328148893,
        N: 46.556560635287504,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Monnaz',
        PLZ: 1125,
        Zusatzziffer: 0,
        Gemeindename: 'Echichens',
        'BFS-Nr': 5634,
        Kantonskürzel: 'VD',
        E: 6.478958009758358,
        N: 46.528489312237,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Ecublens VD',
        PLZ: 1024,
        Zusatzziffer: 0,
        Gemeindename: 'Ecublens (VD)',
        'BFS-Nr': 5635,
        Kantonskürzel: 'VD',
        E: 6.562013835368447,
        N: 46.526128326052365,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Etoy',
        PLZ: 1163,
        Zusatzziffer: 0,
        Gemeindename: 'Etoy',
        'BFS-Nr': 5636,
        Kantonskürzel: 'VD',
        E: 6.420576311330282,
        N: 46.48612950148505,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Lavigny',
        PLZ: 1175,
        Zusatzziffer: 0,
        Gemeindename: 'Lavigny',
        'BFS-Nr': 5637,
        Kantonskürzel: 'VD',
        E: 6.4046563983015545,
        N: 46.50053153827873,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Lonay',
        PLZ: 1027,
        Zusatzziffer: 0,
        Gemeindename: 'Lonay',
        'BFS-Nr': 5638,
        Kantonskürzel: 'VD',
        E: 6.520880751213883,
        N: 46.52647745635818,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Lully VD',
        PLZ: 1132,
        Zusatzziffer: 0,
        Gemeindename: 'Lully (VD)',
        'BFS-Nr': 5639,
        Kantonskürzel: 'VD',
        E: 6.465259088514285,
        N: 46.50589593757599,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Lussy-sur-Morges',
        PLZ: 1167,
        Zusatzziffer: 0,
        Gemeindename: 'Lussy-sur-Morges',
        'BFS-Nr': 5640,
        Kantonskürzel: 'VD',
        E: 6.4506318981954935,
        N: 46.5036771578231,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Morges',
        PLZ: 1110,
        Zusatzziffer: 0,
        Gemeindename: 'Morges',
        'BFS-Nr': 5642,
        Kantonskürzel: 'VD',
        E: 6.499991686202838,
        N: 46.51073176642811,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Préverenges',
        PLZ: 1028,
        Zusatzziffer: 0,
        Gemeindename: 'Préverenges',
        'BFS-Nr': 5643,
        Kantonskürzel: 'VD',
        E: 6.526944990710734,
        N: 46.51839753048603,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Romanel-sur-Morges',
        PLZ: 1122,
        Zusatzziffer: 0,
        Gemeindename: 'Romanel-sur-Morges',
        'BFS-Nr': 5645,
        Kantonskürzel: 'VD',
        E: 6.511647591810882,
        N: 46.55556444464723,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'St-Prex',
        PLZ: 1162,
        Zusatzziffer: 0,
        Gemeindename: 'Saint-Prex',
        'BFS-Nr': 5646,
        Kantonskürzel: 'VD',
        E: 6.458243347230496,
        N: 46.48133522261153,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'St-Sulpice VD',
        PLZ: 1025,
        Zusatzziffer: 0,
        Gemeindename: 'Saint-Sulpice (VD)',
        'BFS-Nr': 5648,
        Kantonskürzel: 'VD',
        E: 6.559956255555076,
        N: 46.50903543638193,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Tolochenaz',
        PLZ: 1131,
        Zusatzziffer: 0,
        Gemeindename: 'Tolochenaz',
        'BFS-Nr': 5649,
        Kantonskürzel: 'VD',
        E: 6.473872172832824,
        N: 46.50591603778724,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Vaux-sur-Morges',
        PLZ: 1126,
        Zusatzziffer: 0,
        Gemeindename: 'Vaux-sur-Morges',
        'BFS-Nr': 5650,
        Kantonskürzel: 'VD',
        E: 6.466329139688101,
        N: 46.536540704964494,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Villars-Ste-Croix',
        PLZ: 1029,
        Zusatzziffer: 0,
        Gemeindename: 'Villars-Sainte-Croix',
        'BFS-Nr': 5651,
        Kantonskürzel: 'VD',
        E: 6.560629680859009,
        N: 46.5664858467977,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Villars-sous-Yens',
        PLZ: 1168,
        Zusatzziffer: 0,
        Gemeindename: 'Villars-sous-Yens',
        'BFS-Nr': 5652,
        Kantonskürzel: 'VD',
        E: 6.428609260846742,
        N: 46.51039918063873,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Vufflens-le-Château',
        PLZ: 1134,
        Zusatzziffer: 0,
        Gemeindename: 'Vufflens-le-Château',
        'BFS-Nr': 5653,
        Kantonskürzel: 'VD',
        E: 6.470901960720033,
        N: 46.526815969210205,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Vullierens',
        PLZ: 1115,
        Zusatzziffer: 0,
        Gemeindename: 'Vullierens',
        'BFS-Nr': 5654,
        Kantonskürzel: 'VD',
        E: 6.481220226967014,
        N: 46.57418692232939,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Yens',
        PLZ: 1169,
        Zusatzziffer: 0,
        Gemeindename: 'Yens',
        'BFS-Nr': 5655,
        Kantonskürzel: 'VD',
        E: 6.4171570555568405,
        N: 46.51859778898384,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Cottens VD',
        PLZ: 1116,
        Zusatzziffer: 0,
        Gemeindename: 'Hautemorges',
        'BFS-Nr': 5656,
        Kantonskürzel: 'VD',
        E: 6.454462946431608,
        N: 46.57259186764514,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Reverolle',
        PLZ: 1128,
        Zusatzziffer: 0,
        Gemeindename: 'Hautemorges',
        'BFS-Nr': 5656,
        Kantonskürzel: 'VD',
        E: 6.4397069810234076,
        N: 46.542433837900326,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Bussy-Chardonney',
        PLZ: 1136,
        Zusatzziffer: 0,
        Gemeindename: 'Hautemorges',
        'BFS-Nr': 5656,
        Kantonskürzel: 'VD',
        E: 6.442639941187684,
        N: 46.52914372352663,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Sévery',
        PLZ: 1141,
        Zusatzziffer: 0,
        Gemeindename: 'Hautemorges',
        'BFS-Nr': 5656,
        Kantonskürzel: 'VD',
        E: 6.438016115007768,
        N: 46.57589734364371,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Pampigny',
        PLZ: 1142,
        Zusatzziffer: 0,
        Gemeindename: 'Hautemorges',
        'BFS-Nr': 5656,
        Kantonskürzel: 'VD',
        E: 6.428914830846109,
        N: 46.58164153105485,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Apples',
        PLZ: 1143,
        Zusatzziffer: 0,
        Gemeindename: 'Hautemorges',
        'BFS-Nr': 5656,
        Kantonskürzel: 'VD',
        E: 6.428621690150074,
        N: 46.552256463726586,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Boulens',
        PLZ: 1063,
        Zusatzziffer: 3,
        Gemeindename: 'Boulens',
        'BFS-Nr': 5661,
        Kantonskürzel: 'VD',
        E: 6.7190039087762905,
        N: 46.68187799815287,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Bussy-sur-Moudon',
        PLZ: 1514,
        Zusatzziffer: 0,
        Gemeindename: 'Bussy-sur-Moudon',
        'BFS-Nr': 5663,
        Kantonskürzel: 'VD',
        E: 6.811128645841802,
        N: 46.68674309407702,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Chavannes-sur-Moudon',
        PLZ: 1512,
        Zusatzziffer: 0,
        Gemeindename: 'Chavannes-sur-Moudon',
        'BFS-Nr': 5665,
        Kantonskürzel: 'VD',
        E: 6.808358768076883,
        N: 46.6571115853914,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Curtilles',
        PLZ: 1521,
        Zusatzziffer: 0,
        Gemeindename: 'Curtilles',
        'BFS-Nr': 5669,
        Kantonskürzel: 'VD',
        E: 6.847597397897828,
        N: 46.70267823063303,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Dompierre VD',
        PLZ: 1682,
        Zusatzziffer: 3,
        Gemeindename: 'Dompierre (VD)',
        'BFS-Nr': 5671,
        Kantonskürzel: 'VD',
        E: 6.882537005005172,
        N: 46.70841718630923,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Hermenches',
        PLZ: 1513,
        Zusatzziffer: 0,
        Gemeindename: 'Hermenches',
        'BFS-Nr': 5673,
        Kantonskürzel: 'VD',
        E: 6.76019977404349,
        N: 46.64190235865745,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Lovatens',
        PLZ: 1682,
        Zusatzziffer: 2,
        Gemeindename: 'Lovatens',
        'BFS-Nr': 5674,
        Kantonskürzel: 'VD',
        E: 6.865103116797509,
        N: 46.69336415793658,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Lucens',
        PLZ: 1522,
        Zusatzziffer: 0,
        Gemeindename: 'Lucens',
        'BFS-Nr': 5675,
        Kantonskürzel: 'VD',
        E: 6.837990938299612,
        N: 46.71032244876205,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Oulens-sur-Lucens',
        PLZ: 1522,
        Zusatzziffer: 9,
        Gemeindename: 'Lucens',
        'BFS-Nr': 5675,
        Kantonskürzel: 'VD',
        E: 6.808121435846655,
        N: 46.70440415701117,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Forel-sur-Lucens',
        PLZ: 1526,
        Zusatzziffer: 0,
        Gemeindename: 'Lucens',
        'BFS-Nr': 5675,
        Kantonskürzel: 'VD',
        E: 6.82298804363051,
        N: 46.72895093358568,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Cremin',
        PLZ: 1526,
        Zusatzziffer: 2,
        Gemeindename: 'Lucens',
        'BFS-Nr': 5675,
        Kantonskürzel: 'VD',
        E: 6.842500860674049,
        N: 46.72414528326439,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Brenles',
        PLZ: 1683,
        Zusatzziffer: 0,
        Gemeindename: 'Lucens',
        'BFS-Nr': 5675,
        Kantonskürzel: 'VD',
        E: 6.8541981891482395,
        N: 46.6711537854113,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Chesalles-sur-Moudon',
        PLZ: 1683,
        Zusatzziffer: 2,
        Gemeindename: 'Lucens',
        'BFS-Nr': 5675,
        Kantonskürzel: 'VD',
        E: 6.832938141053643,
        N: 46.67517671941409,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Sarzens',
        PLZ: 1683,
        Zusatzziffer: 3,
        Gemeindename: 'Lucens',
        'BFS-Nr': 5675,
        Kantonskürzel: 'VD',
        E: 6.850106578546883,
        N: 46.68227851333819,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Moudon',
        PLZ: 1510,
        Zusatzziffer: 0,
        Gemeindename: 'Moudon',
        'BFS-Nr': 5678,
        Kantonskürzel: 'VD',
        E: 6.799126693067762,
        N: 46.668156412803924,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Ogens',
        PLZ: 1045,
        Zusatzziffer: 0,
        Gemeindename: 'Ogens',
        'BFS-Nr': 5680,
        Kantonskürzel: 'VD',
        E: 6.722009773148149,
        N: 46.7110566332039,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Prévonloup',
        PLZ: 1682,
        Zusatzziffer: 0,
        Gemeindename: 'Prévonloup',
        'BFS-Nr': 5683,
        Kantonskürzel: 'VD',
        E: 6.8815335639190875,
        N: 46.6997804937438,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Rossenges',
        PLZ: 1513,
        Zusatzziffer: 2,
        Gemeindename: 'Rossenges',
        'BFS-Nr': 5684,
        Kantonskürzel: 'VD',
        E: 6.772959598113757,
        N: 46.653721447051154,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Syens',
        PLZ: 1510,
        Zusatzziffer: 2,
        Gemeindename: 'Syens',
        'BFS-Nr': 5688,
        Kantonskürzel: 'VD',
        E: 6.7787617432452985,
        N: 46.64603123263455,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Villars-le-Comte',
        PLZ: 1515,
        Zusatzziffer: 0,
        Gemeindename: 'Villars-le-Comte',
        'BFS-Nr': 5690,
        Kantonskürzel: 'VD',
        E: 6.79962023072744,
        N: 46.71064033885288,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Vucherens',
        PLZ: 1509,
        Zusatzziffer: 0,
        Gemeindename: 'Vucherens',
        'BFS-Nr': 5692,
        Kantonskürzel: 'VD',
        E: 6.773930578638616,
        N: 46.62253013860732,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Chapelle-sur-Moudon',
        PLZ: 1063,
        Zusatzziffer: 0,
        Gemeindename: 'Montanaire',
        'BFS-Nr': 5693,
        Kantonskürzel: 'VD',
        E: 6.734850473703314,
        N: 46.6699037362084,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Martherenges',
        PLZ: 1063,
        Zusatzziffer: 2,
        Gemeindename: 'Montanaire',
        'BFS-Nr': 5693,
        Kantonskürzel: 'VD',
        E: 6.7532286897627944,
        N: 46.66109079699987,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Peyres-Possens',
        PLZ: 1063,
        Zusatzziffer: 4,
        Gemeindename: 'Montanaire',
        'BFS-Nr': 5693,
        Kantonskürzel: 'VD',
        E: 6.706896268979456,
        N: 46.66333500627964,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Chanéaz',
        PLZ: 1409,
        Zusatzziffer: 0,
        Gemeindename: 'Montanaire',
        'BFS-Nr': 5693,
        Kantonskürzel: 'VD',
        E: 6.742744436526476,
        N: 46.72964857942199,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Thierrens',
        PLZ: 1410,
        Zusatzziffer: 0,
        Gemeindename: 'Montanaire',
        'BFS-Nr': 5693,
        Kantonskürzel: 'VD',
        E: 6.756227494770582,
        N: 46.70303266883462,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Correvon',
        PLZ: 1410,
        Zusatzziffer: 2,
        Gemeindename: 'Montanaire',
        'BFS-Nr': 5693,
        Kantonskürzel: 'VD',
        E: 6.737221081867349,
        N: 46.71588769586671,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Denezy',
        PLZ: 1410,
        Zusatzziffer: 3,
        Gemeindename: 'Montanaire',
        'BFS-Nr': 5693,
        Kantonskürzel: 'VD',
        E: 6.784724361781398,
        N: 46.719752962040864,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'St-Cierges',
        PLZ: 1410,
        Zusatzziffer: 5,
        Gemeindename: 'Montanaire',
        'BFS-Nr': 5693,
        Kantonskürzel: 'VD',
        E: 6.732434620435866,
        N: 46.68938316988406,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Neyruz-sur-Moudon',
        PLZ: 1515,
        Zusatzziffer: 1,
        Gemeindename: 'Montanaire',
        'BFS-Nr': 5693,
        Kantonskürzel: 'VD',
        E: 6.787191388780404,
        N: 46.6978282774454,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Arnex-sur-Nyon',
        PLZ: 1277,
        Zusatzziffer: 1,
        Gemeindename: 'Arnex-sur-Nyon',
        'BFS-Nr': 5701,
        Kantonskürzel: 'VD',
        E: 6.191473864439313,
        N: 46.37533284232947,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Arzier-Le Muids',
        PLZ: 1273,
        Zusatzziffer: 0,
        Gemeindename: 'Arzier-Le Muids',
        'BFS-Nr': 5702,
        Kantonskürzel: 'VD',
        E: 6.20735926871245,
        N: 46.46051916793938,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Bassins',
        PLZ: 1269,
        Zusatzziffer: 0,
        Gemeindename: 'Bassins',
        'BFS-Nr': 5703,
        Kantonskürzel: 'VD',
        E: 6.2315013901996625,
        N: 46.46213566313261,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Begnins',
        PLZ: 1268,
        Zusatzziffer: 0,
        Gemeindename: 'Begnins',
        'BFS-Nr': 5704,
        Kantonskürzel: 'VD',
        E: 6.25152475394418,
        N: 46.440792593444584,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Bogis-Bossey',
        PLZ: 1279,
        Zusatzziffer: 2,
        Gemeindename: 'Bogis-Bossey',
        'BFS-Nr': 5705,
        Kantonskürzel: 'VD',
        E: 6.166266059270292,
        N: 46.353829198745494,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Borex',
        PLZ: 1277,
        Zusatzziffer: 0,
        Gemeindename: 'Borex',
        'BFS-Nr': 5706,
        Kantonskürzel: 'VD',
        E: 6.177607821074762,
        N: 46.37869015470283,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Chavannes-de-Bogis',
        PLZ: 1279,
        Zusatzziffer: 0,
        Gemeindename: 'Chavannes-de-Bogis',
        'BFS-Nr': 5707,
        Kantonskürzel: 'VD',
        E: 6.162315850879493,
        N: 46.3443723019895,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Chavannes-des-Bois',
        PLZ: 1290,
        Zusatzziffer: 2,
        Gemeindename: 'Chavannes-des-Bois',
        'BFS-Nr': 5708,
        Kantonskürzel: 'VD',
        E: 6.133766413893034,
        N: 46.315973505516375,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Chéserex',
        PLZ: 1275,
        Zusatzziffer: 0,
        Gemeindename: 'Chéserex',
        'BFS-Nr': 5709,
        Kantonskürzel: 'VD',
        E: 6.175051931678808,
        N: 46.40072646804166,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Coinsins',
        PLZ: 1267,
        Zusatzziffer: 2,
        Gemeindename: 'Coinsins',
        'BFS-Nr': 5710,
        Kantonskürzel: 'VD',
        E: 6.239389800305042,
        N: 46.42361254533412,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Commugny',
        PLZ: 1291,
        Zusatzziffer: 0,
        Gemeindename: 'Commugny',
        'BFS-Nr': 5711,
        Kantonskürzel: 'VD',
        E: 6.180396317500046,
        N: 46.32017116876137,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Coppet',
        PLZ: 1296,
        Zusatzziffer: 0,
        Gemeindename: 'Coppet',
        'BFS-Nr': 5712,
        Kantonskürzel: 'VD',
        E: 6.193095613657732,
        N: 46.315876571838515,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Crans VD',
        PLZ: 1299,
        Zusatzziffer: 0,
        Gemeindename: 'Crans (VD)',
        'BFS-Nr': 5713,
        Kantonskürzel: 'VD',
        E: 6.206368099556037,
        N: 46.355900152777195,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Crassier',
        PLZ: 1263,
        Zusatzziffer: 0,
        Gemeindename: 'Crassier',
        'BFS-Nr': 5714,
        Kantonskürzel: 'VD',
        E: 6.163789355027043,
        N: 46.37427462304961,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Duillier',
        PLZ: 1266,
        Zusatzziffer: 0,
        Gemeindename: 'Duillier',
        'BFS-Nr': 5715,
        Kantonskürzel: 'VD',
        E: 6.2330398059002095,
        N: 46.40897013262043,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Eysins',
        PLZ: 1262,
        Zusatzziffer: 0,
        Gemeindename: 'Eysins',
        'BFS-Nr': 5716,
        Kantonskürzel: 'VD',
        E: 6.204703495960405,
        N: 46.37930796662885,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Founex',
        PLZ: 1297,
        Zusatzziffer: 0,
        Gemeindename: 'Founex',
        'BFS-Nr': 5717,
        Kantonskürzel: 'VD',
        E: 6.192731938409835,
        N: 46.33292639322101,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Genolier',
        PLZ: 1272,
        Zusatzziffer: 0,
        Gemeindename: 'Genolier',
        'BFS-Nr': 5718,
        Kantonskürzel: 'VD',
        E: 6.217926859319581,
        N: 46.434659061025975,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Gingins',
        PLZ: 1276,
        Zusatzziffer: 0,
        Gemeindename: 'Gingins',
        'BFS-Nr': 5719,
        Kantonskürzel: 'VD',
        E: 6.179701080264371,
        N: 46.409485315475024,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Givrins',
        PLZ: 1271,
        Zusatzziffer: 0,
        Gemeindename: 'Givrins',
        'BFS-Nr': 5720,
        Kantonskürzel: 'VD',
        E: 6.202121406548571,
        N: 46.429262926823625,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Gland',
        PLZ: 1196,
        Zusatzziffer: 0,
        Gemeindename: 'Gland',
        'BFS-Nr': 5721,
        Kantonskürzel: 'VD',
        E: 6.265217410846858,
        N: 46.42369574166649,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Grens',
        PLZ: 1274,
        Zusatzziffer: 3,
        Gemeindename: 'Grens',
        'BFS-Nr': 5722,
        Kantonskürzel: 'VD',
        E: 6.192073912430265,
        N: 46.39495064760147,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Mies',
        PLZ: 1295,
        Zusatzziffer: 1,
        Gemeindename: 'Mies',
        'BFS-Nr': 5723,
        Kantonskürzel: 'VD',
        E: 6.170929378778225,
        N: 46.30540209455971,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Nyon',
        PLZ: 1260,
        Zusatzziffer: 0,
        Gemeindename: 'Nyon',
        'BFS-Nr': 5724,
        Kantonskürzel: 'VD',
        E: 6.238007696509395,
        N: 46.38081668522332,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Prangins',
        PLZ: 1197,
        Zusatzziffer: 0,
        Gemeindename: 'Prangins',
        'BFS-Nr': 5725,
        Kantonskürzel: 'VD',
        E: 6.249859646178536,
        N: 46.3945290131721,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'La Rippe',
        PLZ: 1278,
        Zusatzziffer: 0,
        Gemeindename: 'La Rippe',
        'BFS-Nr': 5726,
        Kantonskürzel: 'VD',
        E: 6.150265160042033,
        N: 46.38168934255325,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'St-Cergue',
        PLZ: 1264,
        Zusatzziffer: 0,
        Gemeindename: 'Saint-Cergue',
        'BFS-Nr': 5727,
        Kantonskürzel: 'VD',
        E: 6.158796852282726,
        N: 46.44794740604952,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'La Cure',
        PLZ: 1265,
        Zusatzziffer: 0,
        Gemeindename: 'Saint-Cergue',
        'BFS-Nr': 5727,
        Kantonskürzel: 'VD',
        E: 6.075449919797972,
        N: 46.463683338246824,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Signy',
        PLZ: 1274,
        Zusatzziffer: 1,
        Gemeindename: 'Signy-Avenex',
        'BFS-Nr': 5728,
        Kantonskürzel: 'VD',
        E: 6.202099312638785,
        N: 46.39103259303026,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Tannay',
        PLZ: 1295,
        Zusatzziffer: 2,
        Gemeindename: 'Tannay',
        'BFS-Nr': 5729,
        Kantonskürzel: 'VD',
        E: 6.176231826986923,
        N: 46.30881885544907,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Trélex',
        PLZ: 1270,
        Zusatzziffer: 0,
        Gemeindename: 'Trélex',
        'BFS-Nr': 5730,
        Kantonskürzel: 'VD',
        E: 6.203947598107373,
        N: 46.41321734999385,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Le Vaud',
        PLZ: 1261,
        Zusatzziffer: 33,
        Gemeindename: 'Le Vaud',
        'BFS-Nr': 5731,
        Kantonskürzel: 'VD',
        E: 6.2345897141133495,
        N: 46.47729882352229,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Vich',
        PLZ: 1267,
        Zusatzziffer: 1,
        Gemeindename: 'Vich',
        'BFS-Nr': 5732,
        Kantonskürzel: 'VD',
        E: 6.249666484355362,
        N: 46.429332348921,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'L\'Abergement',
        PLZ: 1355,
        Zusatzziffer: 0,
        Gemeindename: 'L\'Abergement',
        'BFS-Nr': 5741,
        Kantonskürzel: 'VD',
        E: 6.489411675895427,
        N: 46.75438676978326,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Agiez',
        PLZ: 1352,
        Zusatzziffer: 0,
        Gemeindename: 'Agiez',
        'BFS-Nr': 5742,
        Kantonskürzel: 'VD',
        E: 6.50679751987618,
        N: 46.7182530762287,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Arnex-sur-Orbe',
        PLZ: 1321,
        Zusatzziffer: 0,
        Gemeindename: 'Arnex-sur-Orbe',
        'BFS-Nr': 5743,
        Kantonskürzel: 'VD',
        E: 6.51919553730667,
        N: 46.69324481932202,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Ballaigues',
        PLZ: 1338,
        Zusatzziffer: 0,
        Gemeindename: 'Ballaigues',
        'BFS-Nr': 5744,
        Kantonskürzel: 'VD',
        E: 6.414753435044086,
        N: 46.72924650780658,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Baulmes',
        PLZ: 1446,
        Zusatzziffer: 0,
        Gemeindename: 'Baulmes',
        'BFS-Nr': 5745,
        Kantonskürzel: 'VD',
        E: 6.518266849194946,
        N: 46.79093106467536,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Bavois',
        PLZ: 1372,
        Zusatzziffer: 0,
        Gemeindename: 'Bavois',
        'BFS-Nr': 5746,
        Kantonskürzel: 'VD',
        E: 6.565619801323251,
        N: 46.68535327657071,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Bofflens',
        PLZ: 1353,
        Zusatzziffer: 0,
        Gemeindename: 'Bofflens',
        'BFS-Nr': 5747,
        Kantonskürzel: 'VD',
        E: 6.498897652672038,
        N: 46.70309865283983,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Bretonnières',
        PLZ: 1329,
        Zusatzziffer: 0,
        Gemeindename: 'Bretonnières',
        'BFS-Nr': 5748,
        Kantonskürzel: 'VD',
        E: 6.475198382923545,
        N: 46.71212945154636,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Chavornay',
        PLZ: 1373,
        Zusatzziffer: 0,
        Gemeindename: 'Chavornay',
        'BFS-Nr': 5749,
        Kantonskürzel: 'VD',
        E: 6.57053574220182,
        N: 46.70143640041541,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Corcelles-sur-Chavornay',
        PLZ: 1374,
        Zusatzziffer: 0,
        Gemeindename: 'Chavornay',
        'BFS-Nr': 5749,
        Kantonskürzel: 'VD',
        E: 6.60004691820436,
        N: 46.70375158322965,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Essert-Pittet',
        PLZ: 1435,
        Zusatzziffer: 0,
        Gemeindename: 'Chavornay',
        'BFS-Nr': 5749,
        Kantonskürzel: 'VD',
        E: 6.583861630769351,
        N: 46.727784228845074,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Les Clées',
        PLZ: 1356,
        Zusatzziffer: 0,
        Gemeindename: 'Les Clées',
        'BFS-Nr': 5750,
        Kantonskürzel: 'VD',
        E: 6.462759159417622,
        N: 46.731541072777105,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'La Russille',
        PLZ: 1356,
        Zusatzziffer: 2,
        Gemeindename: 'Les Clées',
        'BFS-Nr': 5750,
        Kantonskürzel: 'VD',
        E: 6.47855070633468,
        N: 46.73602343427968,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Croy',
        PLZ: 1322,
        Zusatzziffer: 0,
        Gemeindename: 'Croy',
        'BFS-Nr': 5752,
        Kantonskürzel: 'VD',
        E: 6.476727026524506,
        N: 46.69432687896354,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Juriens',
        PLZ: 1326,
        Zusatzziffer: 0,
        Gemeindename: 'Juriens',
        'BFS-Nr': 5754,
        Kantonskürzel: 'VD',
        E: 6.448918823717644,
        N: 46.69129750385241,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Lignerolle',
        PLZ: 1357,
        Zusatzziffer: 0,
        Gemeindename: 'Lignerolle',
        'BFS-Nr': 5755,
        Kantonskürzel: 'VD',
        E: 6.456390320676636,
        N: 46.740098101274434,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Montcherand',
        PLZ: 1354,
        Zusatzziffer: 0,
        Gemeindename: 'Montcherand',
        'BFS-Nr': 5756,
        Kantonskürzel: 'VD',
        E: 6.510630548625879,
        N: 46.732659813172084,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Orbe',
        PLZ: 1350,
        Zusatzziffer: 0,
        Gemeindename: 'Orbe',
        'BFS-Nr': 5757,
        Kantonskürzel: 'VD',
        E: 6.5329880387085755,
        N: 46.72460188786452,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'La Praz',
        PLZ: 1148,
        Zusatzziffer: 6,
        Gemeindename: 'La Praz',
        'BFS-Nr': 5758,
        Kantonskürzel: 'VD',
        E: 6.427087807051279,
        N: 46.66772274570454,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Premier',
        PLZ: 1324,
        Zusatzziffer: 0,
        Gemeindename: 'Premier',
        'BFS-Nr': 5759,
        Kantonskürzel: 'VD',
        E: 6.445239782639779,
        N: 46.70446890199045,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Rances',
        PLZ: 1439,
        Zusatzziffer: 0,
        Gemeindename: 'Rances',
        'BFS-Nr': 5760,
        Kantonskürzel: 'VD',
        E: 6.530928939844625,
        N: 46.76119696054262,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Romainmôtier',
        PLZ: 1323,
        Zusatzziffer: 0,
        Gemeindename: 'Romainmôtier-Envy',
        'BFS-Nr': 5761,
        Kantonskürzel: 'VD',
        E: 6.4614475275413,
        N: 46.69349020371541,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Sergey',
        PLZ: 1355,
        Zusatzziffer: 2,
        Gemeindename: 'Sergey',
        'BFS-Nr': 5762,
        Kantonskürzel: 'VD',
        E: 6.4997172884034775,
        N: 46.75073913392183,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Valeyres-sous-Rances',
        PLZ: 1358,
        Zusatzziffer: 0,
        Gemeindename: 'Valeyres-sous-Rances',
        'BFS-Nr': 5763,
        Kantonskürzel: 'VD',
        E: 6.5193389472895635,
        N: 46.75192075318694,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Vallorbe',
        PLZ: 1337,
        Zusatzziffer: 0,
        Gemeindename: 'Vallorbe',
        'BFS-Nr': 5764,
        Kantonskürzel: 'VD',
        E: 6.380037928705141,
        N: 46.712902690035165,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Vaulion',
        PLZ: 1325,
        Zusatzziffer: 0,
        Gemeindename: 'Vaulion',
        'BFS-Nr': 5765,
        Kantonskürzel: 'VD',
        E: 6.390390888542182,
        N: 46.689224572698556,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Vuiteboeuf',
        PLZ: 1445,
        Zusatzziffer: 0,
        Gemeindename: 'Vuiteboeuf',
        'BFS-Nr': 5766,
        Kantonskürzel: 'VD',
        E: 6.5498618425674735,
        N: 46.8070790424925,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Corcelles-le-Jorat',
        PLZ: 1082,
        Zusatzziffer: 0,
        Gemeindename: 'Corcelles-le-Jorat',
        'BFS-Nr': 5785,
        Kantonskürzel: 'VD',
        E: 6.741519196722334,
        N: 46.6064819196153,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Maracon',
        PLZ: 1613,
        Zusatzziffer: 0,
        Gemeindename: 'Maracon',
        'BFS-Nr': 5790,
        Kantonskürzel: 'VD',
        E: 6.871015173491037,
        N: 46.54966957589982,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Montpreveyres',
        PLZ: 1081,
        Zusatzziffer: 0,
        Gemeindename: 'Montpreveyres',
        'BFS-Nr': 5792,
        Kantonskürzel: 'VD',
        E: 6.748185532493411,
        N: 46.584239438411615,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Ropraz',
        PLZ: 1088,
        Zusatzziffer: 0,
        Gemeindename: 'Ropraz',
        'BFS-Nr': 5798,
        Kantonskürzel: 'VD',
        E: 6.751667476664913,
        N: 46.61495678463019,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Servion',
        PLZ: 1077,
        Zusatzziffer: 0,
        Gemeindename: 'Servion',
        'BFS-Nr': 5799,
        Kantonskürzel: 'VD',
        E: 6.785343875652941,
        N: 46.578082912326565,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Les Cullayes',
        PLZ: 1080,
        Zusatzziffer: 0,
        Gemeindename: 'Servion',
        'BFS-Nr': 5799,
        Kantonskürzel: 'VD',
        E: 6.751457519993697,
        N: 46.5732219351106,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Vulliens',
        PLZ: 1085,
        Zusatzziffer: 0,
        Gemeindename: 'Vulliens',
        'BFS-Nr': 5803,
        Kantonskürzel: 'VD',
        E: 6.796690770434454,
        N: 46.62167001178017,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Montaubion-Chardonney',
        PLZ: 1041,
        Zusatzziffer: 22,
        Gemeindename: 'Jorat-Menthue',
        'BFS-Nr': 5804,
        Kantonskürzel: 'VD',
        E: 6.714327674025997,
        N: 46.64707596275731,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Villars-Tiercelin',
        PLZ: 1058,
        Zusatzziffer: 0,
        Gemeindename: 'Jorat-Menthue',
        'BFS-Nr': 5804,
        Kantonskürzel: 'VD',
        E: 6.704435509870496,
        N: 46.62699109496191,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Peney-le-Jorat',
        PLZ: 1059,
        Zusatzziffer: 0,
        Gemeindename: 'Jorat-Menthue',
        'BFS-Nr': 5804,
        Kantonskürzel: 'VD',
        E: 6.727597250985782,
        N: 46.63395689953683,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Villars-Mendraz',
        PLZ: 1061,
        Zusatzziffer: 0,
        Gemeindename: 'Jorat-Menthue',
        'BFS-Nr': 5804,
        Kantonskürzel: 'VD',
        E: 6.729372117801289,
        N: 46.64734150802138,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Sottens',
        PLZ: 1062,
        Zusatzziffer: 0,
        Gemeindename: 'Jorat-Menthue',
        'BFS-Nr': 5804,
        Kantonskürzel: 'VD',
        E: 6.742109765463203,
        N: 46.65548539560639,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Essertes',
        PLZ: 1078,
        Zusatzziffer: 0,
        Gemeindename: 'Oron',
        'BFS-Nr': 5805,
        Kantonskürzel: 'VD',
        E: 6.789137077362502,
        N: 46.5624393331088,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Palézieux',
        PLZ: 1607,
        Zusatzziffer: 0,
        Gemeindename: 'Oron',
        'BFS-Nr': 5805,
        Kantonskürzel: 'VD',
        E: 6.837782561514889,
        N: 46.542564961977185,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Palézieux-Village',
        PLZ: 1607,
        Zusatzziffer: 1,
        Gemeindename: 'Oron',
        'BFS-Nr': 5805,
        Kantonskürzel: 'VD',
        E: 6.830459260575538,
        N: 46.55669505994926,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Les Tavernes',
        PLZ: 1607,
        Zusatzziffer: 3,
        Gemeindename: 'Oron',
        'BFS-Nr': 5805,
        Kantonskürzel: 'VD',
        E: 6.810619535332795,
        N: 46.554836337184184,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Les Thioleyres',
        PLZ: 1607,
        Zusatzziffer: 4,
        Gemeindename: 'Oron',
        'BFS-Nr': 5805,
        Kantonskürzel: 'VD',
        E: 6.81195397363982,
        N: 46.53968080827498,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Oron-le-Châtel',
        PLZ: 1608,
        Zusatzziffer: 0,
        Gemeindename: 'Oron',
        'BFS-Nr': 5805,
        Kantonskürzel: 'VD',
        E: 6.8376342740376925,
        N: 46.57472724787077,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Bussigny-sur-Oron',
        PLZ: 1608,
        Zusatzziffer: 2,
        Gemeindename: 'Oron',
        'BFS-Nr': 5805,
        Kantonskürzel: 'VD',
        E: 6.8629169890600705,
        N: 46.56967571853706,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Chesalles-sur-Oron',
        PLZ: 1608,
        Zusatzziffer: 3,
        Gemeindename: 'Oron',
        'BFS-Nr': 5805,
        Kantonskürzel: 'VD',
        E: 6.8535857796195865,
        N: 46.576667251463185,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Oron-la-Ville',
        PLZ: 1610,
        Zusatzziffer: 0,
        Gemeindename: 'Oron',
        'BFS-Nr': 5805,
        Kantonskürzel: 'VD',
        E: 6.827282002764362,
        N: 46.57048413741958,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Châtillens',
        PLZ: 1610,
        Zusatzziffer: 1,
        Gemeindename: 'Oron',
        'BFS-Nr': 5805,
        Kantonskürzel: 'VD',
        E: 6.8169490240041934,
        N: 46.56787171420856,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Vuibroye',
        PLZ: 1610,
        Zusatzziffer: 2,
        Gemeindename: 'Oron',
        'BFS-Nr': 5805,
        Kantonskürzel: 'VD',
        E: 6.805388329660015,
        N: 46.57200114884409,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Ecoteaux',
        PLZ: 1612,
        Zusatzziffer: 0,
        Gemeindename: 'Oron',
        'BFS-Nr': 5805,
        Kantonskürzel: 'VD',
        E: 6.862315583600141,
        N: 46.54573851008749,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Ferlens VD',
        PLZ: 1076,
        Zusatzziffer: 0,
        Gemeindename: 'Jorat-Mézières',
        'BFS-Nr': 5806,
        Kantonskürzel: 'VD',
        E: 6.7856883043342195,
        N: 46.588971051104856,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Mézières VD',
        PLZ: 1083,
        Zusatzziffer: 0,
        Gemeindename: 'Jorat-Mézières',
        'BFS-Nr': 5806,
        Kantonskürzel: 'VD',
        E: 6.771217164595201,
        N: 46.59523305326477,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Carrouge VD',
        PLZ: 1084,
        Zusatzziffer: 0,
        Gemeindename: 'Jorat-Mézières',
        'BFS-Nr': 5806,
        Kantonskürzel: 'VD',
        E: 6.775235965880082,
        N: 46.60478365009318,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Champtauroz',
        PLZ: 1537,
        Zusatzziffer: 0,
        Gemeindename: 'Champtauroz',
        'BFS-Nr': 5812,
        Kantonskürzel: 'VD',
        E: 6.787779927123177,
        N: 46.76020202452777,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Chevroux',
        PLZ: 1545,
        Zusatzziffer: 0,
        Gemeindename: 'Chevroux',
        'BFS-Nr': 5813,
        Kantonskürzel: 'VD',
        E: 6.9072408448645435,
        N: 46.8875613917459,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Corcelles-près-Payerne',
        PLZ: 1562,
        Zusatzziffer: 0,
        Gemeindename: 'Corcelles-près-Payerne',
        'BFS-Nr': 5816,
        Kantonskürzel: 'VD',
        E: 6.96121215618023,
        N: 46.83163995644573,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Grandcour',
        PLZ: 1543,
        Zusatzziffer: 0,
        Gemeindename: 'Grandcour',
        'BFS-Nr': 5817,
        Kantonskürzel: 'VD',
        E: 6.930484386854582,
        N: 46.87190466301284,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Henniez',
        PLZ: 1525,
        Zusatzziffer: 0,
        Gemeindename: 'Henniez',
        'BFS-Nr': 5819,
        Kantonskürzel: 'VD',
        E: 6.884056163999359,
        N: 46.74138880660664,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Missy',
        PLZ: 1565,
        Zusatzziffer: 0,
        Gemeindename: 'Missy',
        'BFS-Nr': 5821,
        Kantonskürzel: 'VD',
        E: 6.9703996725051285,
        N: 46.88037917856707,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Payerne',
        PLZ: 1530,
        Zusatzziffer: 0,
        Gemeindename: 'Payerne',
        'BFS-Nr': 5822,
        Kantonskürzel: 'VD',
        E: 6.9377683629732285,
        N: 46.82073548737413,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Vers-chez-Perrin',
        PLZ: 1551,
        Zusatzziffer: 0,
        Gemeindename: 'Payerne',
        'BFS-Nr': 5822,
        Kantonskürzel: 'VD',
        E: 6.942666500235573,
        N: 46.79641840292431,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Trey',
        PLZ: 1552,
        Zusatzziffer: 0,
        Gemeindename: 'Trey',
        'BFS-Nr': 5827,
        Kantonskürzel: 'VD',
        E: 6.925910152773023,
        N: 46.770266121625625,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Treytorrens (Payerne)',
        PLZ: 1538,
        Zusatzziffer: 0,
        Gemeindename: 'Treytorrens (Payerne)',
        'BFS-Nr': 5828,
        Kantonskürzel: 'VD',
        E: 6.800867797363455,
        N: 46.771136728256096,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Sédeilles',
        PLZ: 1554,
        Zusatzziffer: 0,
        Gemeindename: 'Villarzel',
        'BFS-Nr': 5830,
        Kantonskürzel: 'VD',
        E: 6.932869123514254,
        N: 46.74939464924298,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Rossens VD',
        PLZ: 1554,
        Zusatzziffer: 2,
        Gemeindename: 'Villarzel',
        'BFS-Nr': 5830,
        Kantonskürzel: 'VD',
        E: 6.922380004014524,
        N: 46.735084709511604,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Villarzel',
        PLZ: 1555,
        Zusatzziffer: 0,
        Gemeindename: 'Villarzel',
        'BFS-Nr': 5830,
        Kantonskürzel: 'VD',
        E: 6.912689473689295,
        N: 46.74888867193896,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Granges-près-Marnand',
        PLZ: 1523,
        Zusatzziffer: 0,
        Gemeindename: 'Valbroye',
        'BFS-Nr': 5831,
        Kantonskürzel: 'VD',
        E: 6.889627368520508,
        N: 46.75994898643699,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Marnand',
        PLZ: 1524,
        Zusatzziffer: 0,
        Gemeindename: 'Valbroye',
        'BFS-Nr': 5831,
        Kantonskürzel: 'VD',
        E: 6.901122039806487,
        N: 46.75611240470168,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Seigneux',
        PLZ: 1525,
        Zusatzziffer: 2,
        Gemeindename: 'Valbroye',
        'BFS-Nr': 5831,
        Kantonskürzel: 'VD',
        E: 6.878532686097362,
        N: 46.72810378340457,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Sassel',
        PLZ: 1534,
        Zusatzziffer: 0,
        Gemeindename: 'Valbroye',
        'BFS-Nr': 5831,
        Kantonskürzel: 'VD',
        E: 6.8576526412949965,
        N: 46.77723914496372,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Combremont-le-Grand',
        PLZ: 1535,
        Zusatzziffer: 0,
        Gemeindename: 'Valbroye',
        'BFS-Nr': 5831,
        Kantonskürzel: 'VD',
        E: 6.818211291179247,
        N: 46.76192725803924,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Combremont-le-Petit',
        PLZ: 1536,
        Zusatzziffer: 0,
        Gemeindename: 'Valbroye',
        'BFS-Nr': 5831,
        Kantonskürzel: 'VD',
        E: 6.809187334473385,
        N: 46.74944586908187,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Villars-Bramard',
        PLZ: 1682,
        Zusatzziffer: 4,
        Gemeindename: 'Valbroye',
        'BFS-Nr': 5831,
        Kantonskürzel: 'VD',
        E: 6.898546582322073,
        N: 46.71839438028355,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Cerniaz VD',
        PLZ: 1682,
        Zusatzziffer: 5,
        Gemeindename: 'Valbroye',
        'BFS-Nr': 5831,
        Kantonskürzel: 'VD',
        E: 6.895993085893725,
        N: 46.72720562349762,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Château-d\'Oex',
        PLZ: 1660,
        Zusatzziffer: 0,
        Gemeindename: 'Château-d\'Oex',
        'BFS-Nr': 5841,
        Kantonskürzel: 'VD',
        E: 7.129430265900451,
        N: 46.47274682185626,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Les Moulins',
        PLZ: 1660,
        Zusatzziffer: 2,
        Gemeindename: 'Château-d\'Oex',
        'BFS-Nr': 5841,
        Kantonskürzel: 'VD',
        E: 7.106659236618919,
        N: 46.46018526361332,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'L\'Etivaz',
        PLZ: 1660,
        Zusatzziffer: 3,
        Gemeindename: 'Château-d\'Oex',
        'BFS-Nr': 5841,
        Kantonskürzel: 'VD',
        E: 7.147835770606828,
        N: 46.42457986591174,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'La Lécherette',
        PLZ: 1660,
        Zusatzziffer: 4,
        Gemeindename: 'Château-d\'Oex',
        'BFS-Nr': 5841,
        Kantonskürzel: 'VD',
        E: 7.106278731983703,
        N: 46.421303402710045,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Rossinière',
        PLZ: 1658,
        Zusatzziffer: 0,
        Gemeindename: 'Rossinière',
        'BFS-Nr': 5842,
        Kantonskürzel: 'VD',
        E: 7.0811729716953,
        N: 46.46635916998345,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'La Tine',
        PLZ: 1658,
        Zusatzziffer: 2,
        Gemeindename: 'Rossinière',
        'BFS-Nr': 5842,
        Kantonskürzel: 'VD',
        E: 7.049509808834476,
        N: 46.4688405730577,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Rougemont',
        PLZ: 1659,
        Zusatzziffer: 0,
        Gemeindename: 'Rougemont',
        'BFS-Nr': 5843,
        Kantonskürzel: 'VD',
        E: 7.206249159051167,
        N: 46.48755383797859,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Flendruz',
        PLZ: 1659,
        Zusatzziffer: 2,
        Gemeindename: 'Rougemont',
        'BFS-Nr': 5843,
        Kantonskürzel: 'VD',
        E: 7.182473924471437,
        N: 46.48348357486591,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Allaman',
        PLZ: 1165,
        Zusatzziffer: 0,
        Gemeindename: 'Allaman',
        'BFS-Nr': 5851,
        Kantonskürzel: 'VD',
        E: 6.395306004906811,
        N: 46.47084026378792,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Bursinel',
        PLZ: 1195,
        Zusatzziffer: 3,
        Gemeindename: 'Bursinel',
        'BFS-Nr': 5852,
        Kantonskürzel: 'VD',
        E: 6.304185247365561,
        N: 46.43835033919052,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Bursins',
        PLZ: 1183,
        Zusatzziffer: 0,
        Gemeindename: 'Bursins',
        'BFS-Nr': 5853,
        Kantonskürzel: 'VD',
        E: 6.289859079170312,
        N: 46.45310161723624,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Burtigny',
        PLZ: 1268,
        Zusatzziffer: 2,
        Gemeindename: 'Burtigny',
        'BFS-Nr': 5854,
        Kantonskürzel: 'VD',
        E: 6.259267692183358,
        N: 46.464912065790614,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Dully',
        PLZ: 1195,
        Zusatzziffer: 2,
        Gemeindename: 'Dully',
        'BFS-Nr': 5855,
        Kantonskürzel: 'VD',
        E: 6.294776992454275,
        N: 46.429364350287834,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Essertines-sur-Rolle',
        PLZ: 1186,
        Zusatzziffer: 0,
        Gemeindename: 'Essertines-sur-Rolle',
        'BFS-Nr': 5856,
        Kantonskürzel: 'VD',
        E: 6.317898286550734,
        N: 46.49314508832213,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Gilly',
        PLZ: 1182,
        Zusatzziffer: 0,
        Gemeindename: 'Gilly',
        'BFS-Nr': 5857,
        Kantonskürzel: 'VD',
        E: 6.300270816269476,
        N: 46.459222591049134,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Luins',
        PLZ: 1184,
        Zusatzziffer: 0,
        Gemeindename: 'Luins',
        'BFS-Nr': 5858,
        Kantonskürzel: 'VD',
        E: 6.272738165664107,
        N: 46.44643127151621,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Mont-sur-Rolle',
        PLZ: 1185,
        Zusatzziffer: 0,
        Gemeindename: 'Mont-sur-Rolle',
        'BFS-Nr': 5859,
        Kantonskürzel: 'VD',
        E: 6.336958049696008,
        N: 46.470937463258934,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Perroy',
        PLZ: 1166,
        Zusatzziffer: 0,
        Gemeindename: 'Perroy',
        'BFS-Nr': 5860,
        Kantonskürzel: 'VD',
        E: 6.3654060479946875,
        N: 46.467592529042825,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Rolle',
        PLZ: 1180,
        Zusatzziffer: 0,
        Gemeindename: 'Rolle',
        'BFS-Nr': 5861,
        Kantonskürzel: 'VD',
        E: 6.336176693739728,
        N: 46.45856526915113,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Tartegnin',
        PLZ: 1180,
        Zusatzziffer: 2,
        Gemeindename: 'Tartegnin',
        'BFS-Nr': 5862,
        Kantonskürzel: 'VD',
        E: 6.31506720188343,
        N: 46.46637885151973,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Vinzel',
        PLZ: 1184,
        Zusatzziffer: 1,
        Gemeindename: 'Vinzel',
        'BFS-Nr': 5863,
        Kantonskürzel: 'VD',
        E: 6.278879408137836,
        N: 46.44816118884842,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Le Pont',
        PLZ: 1342,
        Zusatzziffer: 0,
        Gemeindename: 'L\'Abbaye',
        'BFS-Nr': 5871,
        Kantonskürzel: 'VD',
        E: 6.331536478285278,
        N: 46.664592817844145,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'L\'Abbaye',
        PLZ: 1344,
        Zusatzziffer: 0,
        Gemeindename: 'L\'Abbaye',
        'BFS-Nr': 5871,
        Kantonskürzel: 'VD',
        E: 6.319602800011451,
        N: 46.65009657595969,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Les Bioux',
        PLZ: 1346,
        Zusatzziffer: 0,
        Gemeindename: 'L\'Abbaye',
        'BFS-Nr': 5871,
        Kantonskürzel: 'VD',
        E: 6.274294875974365,
        N: 46.622179949943586,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'L\'Orient',
        PLZ: 1341,
        Zusatzziffer: 0,
        Gemeindename: 'Le Chenit',
        'BFS-Nr': 5872,
        Kantonskürzel: 'VD',
        E: 6.238369136569592,
        N: 46.600942292473135,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Le Sentier',
        PLZ: 1347,
        Zusatzziffer: 0,
        Gemeindename: 'Le Chenit',
        'BFS-Nr': 5872,
        Kantonskürzel: 'VD',
        E: 6.231984038865399,
        N: 46.608316050905316,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Le Solliat',
        PLZ: 1347,
        Zusatzziffer: 1,
        Gemeindename: 'Le Chenit',
        'BFS-Nr': 5872,
        Kantonskürzel: 'VD',
        E: 6.234500777218393,
        N: 46.61975700524395,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Le Brassus',
        PLZ: 1348,
        Zusatzziffer: 0,
        Gemeindename: 'Le Chenit',
        'BFS-Nr': 5872,
        Kantonskürzel: 'VD',
        E: 6.210152396823774,
        N: 46.58188392416693,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Les Charbonnières',
        PLZ: 1343,
        Zusatzziffer: 0,
        Gemeindename: 'Le Lieu',
        'BFS-Nr': 5873,
        Kantonskürzel: 'VD',
        E: 6.313467027395845,
        N: 46.666756107770254,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Le Lieu',
        PLZ: 1345,
        Zusatzziffer: 0,
        Gemeindename: 'Le Lieu',
        'BFS-Nr': 5873,
        Kantonskürzel: 'VD',
        E: 6.282085776731286,
        N: 46.64744795378437,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Le Séchey',
        PLZ: 1345,
        Zusatzziffer: 1,
        Gemeindename: 'Le Lieu',
        'BFS-Nr': 5873,
        Kantonskürzel: 'VD',
        E: 6.299992633700318,
        N: 46.66013158888129,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Le Mont-Pèlerin',
        PLZ: 1801,
        Zusatzziffer: 0,
        Gemeindename: 'Chardonne',
        'BFS-Nr': 5882,
        Kantonskürzel: 'VD',
        E: 6.830194598580664,
        N: 46.48158488599242,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Chardonne',
        PLZ: 1803,
        Zusatzziffer: 0,
        Gemeindename: 'Chardonne',
        'BFS-Nr': 5882,
        Kantonskürzel: 'VD',
        E: 6.826986516688092,
        N: 46.476839826932896,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Corseaux',
        PLZ: 1802,
        Zusatzziffer: 0,
        Gemeindename: 'Corseaux',
        'BFS-Nr': 5883,
        Kantonskürzel: 'VD',
        E: 6.832808624866366,
        N: 46.47230892476768,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Corsier-sur-Vevey',
        PLZ: 1804,
        Zusatzziffer: 0,
        Gemeindename: 'Corsier-sur-Vevey',
        'BFS-Nr': 5884,
        Kantonskürzel: 'VD',
        E: 6.8416946530890375,
        N: 46.47019892647394,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Les Monts-de-Corsier',
        PLZ: 1808,
        Zusatzziffer: 0,
        Gemeindename: 'Corsier-sur-Vevey',
        'BFS-Nr': 5884,
        Kantonskürzel: 'VD',
        E: 6.876764288971368,
        N: 46.49949121319002,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Fenil-sur-Corsier',
        PLZ: 1809,
        Zusatzziffer: 0,
        Gemeindename: 'Corsier-sur-Vevey',
        'BFS-Nr': 5884,
        Kantonskürzel: 'VD',
        E: 6.866179642795736,
        N: 46.48361043852959,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Jongny',
        PLZ: 1805,
        Zusatzziffer: 0,
        Gemeindename: 'Jongny',
        'BFS-Nr': 5885,
        Kantonskürzel: 'VD',
        E: 6.842134864623355,
        N: 46.47790665281406,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Clarens',
        PLZ: 1815,
        Zusatzziffer: 0,
        Gemeindename: 'Montreux',
        'BFS-Nr': 5886,
        Kantonskürzel: 'VD',
        E: 6.8924828756749354,
        N: 46.44202711057052,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Chailly-Montreux',
        PLZ: 1816,
        Zusatzziffer: 0,
        Gemeindename: 'Montreux',
        'BFS-Nr': 5886,
        Kantonskürzel: 'VD',
        E: 6.891991489730312,
        N: 46.45441725403866,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Brent',
        PLZ: 1817,
        Zusatzziffer: 0,
        Gemeindename: 'Montreux',
        'BFS-Nr': 5886,
        Kantonskürzel: 'VD',
        E: 6.901438787898431,
        N: 46.45758831835141,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Montreux',
        PLZ: 1820,
        Zusatzziffer: 0,
        Gemeindename: 'Montreux',
        'BFS-Nr': 5886,
        Kantonskürzel: 'VD',
        E: 6.912799317644741,
        N: 46.430832395781565,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Territet',
        PLZ: 1820,
        Zusatzziffer: 5,
        Gemeindename: 'Montreux',
        'BFS-Nr': 5886,
        Kantonskürzel: 'VD',
        E: 6.923158085039046,
        N: 46.42679687331625,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Chernex',
        PLZ: 1822,
        Zusatzziffer: 0,
        Gemeindename: 'Montreux',
        'BFS-Nr': 5886,
        Kantonskürzel: 'VD',
        E: 6.913610559839311,
        N: 46.44258986281365,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Glion',
        PLZ: 1823,
        Zusatzziffer: 0,
        Gemeindename: 'Montreux',
        'BFS-Nr': 5886,
        Kantonskürzel: 'VD',
        E: 6.925623491472286,
        N: 46.4321250802476,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Caux',
        PLZ: 1824,
        Zusatzziffer: 0,
        Gemeindename: 'Montreux',
        'BFS-Nr': 5886,
        Kantonskürzel: 'VD',
        E: 6.9385943627615925,
        N: 46.43271390153757,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Chamby',
        PLZ: 1832,
        Zusatzziffer: 0,
        Gemeindename: 'Montreux',
        'BFS-Nr': 5886,
        Kantonskürzel: 'VD',
        E: 6.913516730670301,
        N: 46.44930162462911,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Villard-sur-Chamby',
        PLZ: 1832,
        Zusatzziffer: 2,
        Gemeindename: 'Montreux',
        'BFS-Nr': 5886,
        Kantonskürzel: 'VD',
        E: 6.93138277695342,
        N: 46.46366437235091,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Les Avants',
        PLZ: 1833,
        Zusatzziffer: 0,
        Gemeindename: 'Montreux',
        'BFS-Nr': 5886,
        Kantonskürzel: 'VD',
        E: 6.942820853894603,
        N: 46.45310329883473,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'La Tour-de-Peilz',
        PLZ: 1814,
        Zusatzziffer: 0,
        Gemeindename: 'La Tour-de-Peilz',
        'BFS-Nr': 5889,
        Kantonskürzel: 'VD',
        E: 6.857017230479475,
        N: 46.45360905669416,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Vevey',
        PLZ: 1800,
        Zusatzziffer: 0,
        Gemeindename: 'Vevey',
        'BFS-Nr': 5890,
        Kantonskürzel: 'VD',
        E: 6.848151480145751,
        N: 46.45906041325897,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Veytaux',
        PLZ: 1820,
        Zusatzziffer: 6,
        Gemeindename: 'Veytaux',
        'BFS-Nr': 5891,
        Kantonskürzel: 'VD',
        E: 6.928819622762824,
        N: 46.420817291482805,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'St-Légier-La Chiésaz',
        PLZ: 1806,
        Zusatzziffer: 0,
        Gemeindename: 'Blonay - Saint-Légier',
        'BFS-Nr': 5892,
        Kantonskürzel: 'VD',
        E: 6.88398900136044,
        N: 46.47136709754637,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Blonay',
        PLZ: 1807,
        Zusatzziffer: 0,
        Gemeindename: 'Blonay - Saint-Légier',
        'BFS-Nr': 5892,
        Kantonskürzel: 'VD',
        E: 6.894408189697142,
        N: 46.46585583516562,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Belmont-sur-Yverdon',
        PLZ: 1432,
        Zusatzziffer: 0,
        Gemeindename: 'Belmont-sur-Yverdon',
        'BFS-Nr': 5902,
        Kantonskürzel: 'VD',
        E: 6.623927703419498,
        N: 46.745527400009394,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Bioley-Magnoux',
        PLZ: 1407,
        Zusatzziffer: 4,
        Gemeindename: 'Bioley-Magnoux',
        'BFS-Nr': 5903,
        Kantonskürzel: 'VD',
        E: 6.711821747344666,
        N: 46.726884882977636,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Chamblon',
        PLZ: 1436,
        Zusatzziffer: 2,
        Gemeindename: 'Chamblon',
        'BFS-Nr': 5904,
        Kantonskürzel: 'VD',
        E: 6.606571141035739,
        N: 46.77854653404299,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Champvent',
        PLZ: 1443,
        Zusatzziffer: 0,
        Gemeindename: 'Champvent',
        'BFS-Nr': 5905,
        Kantonskürzel: 'VD',
        E: 6.575169350710954,
        N: 46.78348003373061,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Essert-sous-Champvent',
        PLZ: 1443,
        Zusatzziffer: 2,
        Gemeindename: 'Champvent',
        'BFS-Nr': 5905,
        Kantonskürzel: 'VD',
        E: 6.584630626055403,
        N: 46.7931848472172,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Villars-sous-Champvent',
        PLZ: 1443,
        Zusatzziffer: 3,
        Gemeindename: 'Champvent',
        'BFS-Nr': 5905,
        Kantonskürzel: 'VD',
        E: 6.583230324650328,
        N: 46.78635842527365,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Chavannes-le-Chêne',
        PLZ: 1464,
        Zusatzziffer: 0,
        Gemeindename: 'Chavannes-le-Chêne',
        'BFS-Nr': 5907,
        Kantonskürzel: 'VD',
        E: 6.777114062809941,
        N: 46.776857506239814,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Chêne-Pâquier',
        PLZ: 1464,
        Zusatzziffer: 2,
        Gemeindename: 'Chêne-Pâquier',
        'BFS-Nr': 5908,
        Kantonskürzel: 'VD',
        E: 6.768789761305213,
        N: 46.771335505166476,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Cheseaux-Noréaz',
        PLZ: 1400,
        Zusatzziffer: 5,
        Gemeindename: 'Cheseaux-Noréaz',
        'BFS-Nr': 5909,
        Kantonskürzel: 'VD',
        E: 6.676861323537754,
        N: 46.78309278926006,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Cronay',
        PLZ: 1406,
        Zusatzziffer: 0,
        Gemeindename: 'Cronay',
        'BFS-Nr': 5910,
        Kantonskürzel: 'VD',
        E: 6.696382784505356,
        N: 46.75714146398835,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Cuarny',
        PLZ: 1404,
        Zusatzziffer: 0,
        Gemeindename: 'Cuarny',
        'BFS-Nr': 5911,
        Kantonskürzel: 'VD',
        E: 6.6888152619094745,
        N: 46.77007989532826,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Démoret',
        PLZ: 1415,
        Zusatzziffer: 2,
        Gemeindename: 'Démoret',
        'BFS-Nr': 5912,
        Kantonskürzel: 'VD',
        E: 6.756370327313911,
        N: 46.74743820222795,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Donneloye',
        PLZ: 1407,
        Zusatzziffer: 0,
        Gemeindename: 'Donneloye',
        'BFS-Nr': 5913,
        Kantonskürzel: 'VD',
        E: 6.716143435872335,
        N: 46.7450683923067,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Gossens',
        PLZ: 1407,
        Zusatzziffer: 2,
        Gemeindename: 'Donneloye',
        'BFS-Nr': 5913,
        Kantonskürzel: 'VD',
        E: 6.70025270974374,
        N: 46.73931132610343,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Mézery-près-Donneloye',
        PLZ: 1407,
        Zusatzziffer: 3,
        Gemeindename: 'Donneloye',
        'BFS-Nr': 5913,
        Kantonskürzel: 'VD',
        E: 6.727429146299367,
        N: 46.74906917495018,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Prahins',
        PLZ: 1408,
        Zusatzziffer: 0,
        Gemeindename: 'Donneloye',
        'BFS-Nr': 5913,
        Kantonskürzel: 'VD',
        E: 6.7401764062152925,
        N: 46.735275800746685,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Ependes VD',
        PLZ: 1434,
        Zusatzziffer: 0,
        Gemeindename: 'Ependes (VD)',
        'BFS-Nr': 5914,
        Kantonskürzel: 'VD',
        E: 6.608487082752755,
        N: 46.7450763784909,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Mathod',
        PLZ: 1438,
        Zusatzziffer: 0,
        Gemeindename: 'Mathod',
        'BFS-Nr': 5919,
        Kantonskürzel: 'VD',
        E: 6.567301879671287,
        N: 46.76678467916181,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Molondin',
        PLZ: 1415,
        Zusatzziffer: 0,
        Gemeindename: 'Molondin',
        'BFS-Nr': 5921,
        Kantonskürzel: 'VD',
        E: 6.748554991902692,
        N: 46.75973757474461,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Montagny-près-Yverdon',
        PLZ: 1442,
        Zusatzziffer: 0,
        Gemeindename: 'Montagny-près-Yverdon',
        'BFS-Nr': 5922,
        Kantonskürzel: 'VD',
        E: 6.6122898833516315,
        N: 46.79300416353951,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Oppens',
        PLZ: 1047,
        Zusatzziffer: 0,
        Gemeindename: 'Oppens',
        'BFS-Nr': 5923,
        Kantonskürzel: 'VD',
        E: 6.692450926587544,
        N: 46.714106008144554,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Orges',
        PLZ: 1430,
        Zusatzziffer: 0,
        Gemeindename: 'Orges',
        'BFS-Nr': 5924,
        Kantonskürzel: 'VD',
        E: 6.58855474690293,
        N: 46.80801749286424,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Orzens',
        PLZ: 1413,
        Zusatzziffer: 0,
        Gemeindename: 'Orzens',
        'BFS-Nr': 5925,
        Kantonskürzel: 'VD',
        E: 6.683697281586001,
        N: 46.72138979097285,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Pomy',
        PLZ: 1405,
        Zusatzziffer: 0,
        Gemeindename: 'Pomy',
        'BFS-Nr': 5926,
        Kantonskürzel: 'VD',
        E: 6.669473239520766,
        N: 46.759318859191964,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Rovray',
        PLZ: 1463,
        Zusatzziffer: 0,
        Gemeindename: 'Rovray',
        'BFS-Nr': 5928,
        Kantonskürzel: 'VD',
        E: 6.765184463256414,
        N: 46.78449739754238,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Suchy',
        PLZ: 1433,
        Zusatzziffer: 0,
        Gemeindename: 'Suchy',
        'BFS-Nr': 5929,
        Kantonskürzel: 'VD',
        E: 6.598992652621594,
        N: 46.72246569294739,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Suscévaz',
        PLZ: 1437,
        Zusatzziffer: 0,
        Gemeindename: 'Suscévaz',
        'BFS-Nr': 5930,
        Kantonskürzel: 'VD',
        E: 6.577961201495245,
        N: 46.764234893155184,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Treycovagnes',
        PLZ: 1436,
        Zusatzziffer: 0,
        Gemeindename: 'Treycovagnes',
        'BFS-Nr': 5931,
        Kantonskürzel: 'VD',
        E: 6.609411450539035,
        N: 46.77412538342896,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Ursins',
        PLZ: 1412,
        Zusatzziffer: 2,
        Gemeindename: 'Ursins',
        'BFS-Nr': 5932,
        Kantonskürzel: 'VD',
        E: 6.668219249017883,
        N: 46.73533814422629,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Valeyres-sous-Montagny',
        PLZ: 1441,
        Zusatzziffer: 0,
        Gemeindename: 'Valeyres-sous-Montagny',
        'BFS-Nr': 5933,
        Kantonskürzel: 'VD',
        E: 6.608906678617229,
        N: 46.798940432120745,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Valeyres-sous-Ursins',
        PLZ: 1412,
        Zusatzziffer: 0,
        Gemeindename: 'Valeyres-sous-Ursins',
        'BFS-Nr': 5934,
        Kantonskürzel: 'VD',
        E: 6.655622992822554,
        N: 46.74629333199555,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Villars-Epeney',
        PLZ: 1404,
        Zusatzziffer: 2,
        Gemeindename: 'Villars-Epeney',
        'BFS-Nr': 5935,
        Kantonskürzel: 'VD',
        E: 6.696901578539942,
        N: 46.78171109434005,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Vugelles-La Mothe',
        PLZ: 1431,
        Zusatzziffer: 0,
        Gemeindename: 'Vugelles-La Mothe',
        'BFS-Nr': 5937,
        Kantonskürzel: 'VD',
        E: 6.578591555615837,
        N: 46.8240864340715,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Yverdon-les-Bains',
        PLZ: 1400,
        Zusatzziffer: 0,
        Gemeindename: 'Yverdon-les-Bains',
        'BFS-Nr': 5938,
        Kantonskürzel: 'VD',
        E: 6.640379842793872,
        N: 46.778864650433505,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Gressy',
        PLZ: 1432,
        Zusatzziffer: 2,
        Gemeindename: 'Yverdon-les-Bains',
        'BFS-Nr': 5938,
        Kantonskürzel: 'VD',
        E: 6.635505508855323,
        N: 46.74799218716234,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Yvonand',
        PLZ: 1462,
        Zusatzziffer: 0,
        Gemeindename: 'Yvonand',
        'BFS-Nr': 5939,
        Kantonskürzel: 'VD',
        E: 6.745091237603063,
        N: 46.79822649551775,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Brig',
        PLZ: 3900,
        Zusatzziffer: 0,
        Gemeindename: 'Brig-Glis',
        'BFS-Nr': 6002,
        Kantonskürzel: 'VS',
        E: 7.9895851169103596,
        N: 46.316597200499295,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Gamsen',
        PLZ: 3900,
        Zusatzziffer: 2,
        Gemeindename: 'Brig-Glis',
        'BFS-Nr': 6002,
        Kantonskürzel: 'VS',
        E: 7.954881745006182,
        N: 46.305208488639536,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Brigerbad',
        PLZ: 3900,
        Zusatzziffer: 3,
        Gemeindename: 'Brig-Glis',
        'BFS-Nr': 6002,
        Kantonskürzel: 'VS',
        E: 7.9186579327912945,
        N: 46.30008283722591,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Glis',
        PLZ: 3902,
        Zusatzziffer: 0,
        Gemeindename: 'Brig-Glis',
        'BFS-Nr': 6002,
        Kantonskürzel: 'VS',
        E: 7.978054044586396,
        N: 46.31075147936208,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Eggerberg',
        PLZ: 3939,
        Zusatzziffer: 0,
        Gemeindename: 'Eggerberg',
        'BFS-Nr': 6004,
        Kantonskürzel: 'VS',
        E: 7.876986227115338,
        N: 46.306600637856235,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Mund',
        PLZ: 3903,
        Zusatzziffer: 0,
        Gemeindename: 'Naters',
        'BFS-Nr': 6007,
        Kantonskürzel: 'VS',
        E: 7.9390678409659685,
        N: 46.31635503956285,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Birgisch',
        PLZ: 3903,
        Zusatzziffer: 1,
        Gemeindename: 'Naters',
        'BFS-Nr': 6007,
        Kantonskürzel: 'VS',
        E: 7.957704576956,
        N: 46.31873165865412,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Naters',
        PLZ: 3904,
        Zusatzziffer: 0,
        Gemeindename: 'Naters',
        'BFS-Nr': 6007,
        Kantonskürzel: 'VS',
        E: 7.9881493127117995,
        N: 46.32560930918244,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Blatten b. Naters',
        PLZ: 3914,
        Zusatzziffer: 0,
        Gemeindename: 'Naters',
        'BFS-Nr': 6007,
        Kantonskürzel: 'VS',
        E: 7.983359694473766,
        N: 46.35786388669255,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Belalp',
        PLZ: 3914,
        Zusatzziffer: 1,
        Gemeindename: 'Naters',
        'BFS-Nr': 6007,
        Kantonskürzel: 'VS',
        E: 7.97462158391509,
        N: 46.37174890300252,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Rothwald',
        PLZ: 3901,
        Zusatzziffer: 23,
        Gemeindename: 'Ried-Brig',
        'BFS-Nr': 6008,
        Kantonskürzel: 'VS',
        E: 8.040790791037287,
        N: 46.281010191250985,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Ried-Brig',
        PLZ: 3911,
        Zusatzziffer: 0,
        Gemeindename: 'Ried-Brig',
        'BFS-Nr': 6008,
        Kantonskürzel: 'VS',
        E: 8.017136416656498,
        N: 46.31406780581342,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Simplon Dorf',
        PLZ: 3907,
        Zusatzziffer: 0,
        Gemeindename: 'Simplon',
        'BFS-Nr': 6009,
        Kantonskürzel: 'VS',
        E: 8.056213895087811,
        N: 46.19588076857639,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Simplon Hospiz',
        PLZ: 3907,
        Zusatzziffer: 2,
        Gemeindename: 'Simplon',
        'BFS-Nr': 6009,
        Kantonskürzel: 'VS',
        E: 8.029105488541589,
        N: 46.24665429694013,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Gabi (Simplon)',
        PLZ: 3907,
        Zusatzziffer: 3,
        Gemeindename: 'Simplon',
        'BFS-Nr': 6009,
        Kantonskürzel: 'VS',
        E: 8.072879534727937,
        N: 46.18492057677115,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Termen',
        PLZ: 3912,
        Zusatzziffer: 0,
        Gemeindename: 'Termen',
        'BFS-Nr': 6010,
        Kantonskürzel: 'VS',
        E: 8.020915717940266,
        N: 46.32806330002086,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Rosswald',
        PLZ: 3913,
        Zusatzziffer: 0,
        Gemeindename: 'Termen',
        'BFS-Nr': 6010,
        Kantonskürzel: 'VS',
        E: 8.040529702741148,
        N: 46.304147672768494,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Gondo',
        PLZ: 3907,
        Zusatzziffer: 4,
        Gemeindename: 'Zwischbergen',
        'BFS-Nr': 6011,
        Kantonskürzel: 'VS',
        E: 8.140961121872069,
        N: 46.1957560376008,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Ardon',
        PLZ: 1957,
        Zusatzziffer: 0,
        Gemeindename: 'Ardon',
        'BFS-Nr': 6021,
        Kantonskürzel: 'VS',
        E: 7.252568086301518,
        N: 46.2104411488254,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Chamoson',
        PLZ: 1955,
        Zusatzziffer: 0,
        Gemeindename: 'Chamoson',
        'BFS-Nr': 6022,
        Kantonskürzel: 'VS',
        E: 7.225019028379226,
        N: 46.20210342962514,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Mayens-de-Chamoson',
        PLZ: 1955,
        Zusatzziffer: 1,
        Gemeindename: 'Chamoson',
        'BFS-Nr': 6022,
        Kantonskürzel: 'VS',
        E: 7.181582867165192,
        N: 46.20590067164422,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Les Vérines (Chamoson)',
        PLZ: 1955,
        Zusatzziffer: 2,
        Gemeindename: 'Chamoson',
        'BFS-Nr': 6022,
        Kantonskürzel: 'VS',
        E: 7.2033872547376605,
        N: 46.21050854043734,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Némiaz (Chamoson)',
        PLZ: 1955,
        Zusatzziffer: 3,
        Gemeindename: 'Chamoson',
        'BFS-Nr': 6022,
        Kantonskürzel: 'VS',
        E: 7.223501287693774,
        N: 46.21231891466564,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Grugnay (Chamoson)',
        PLZ: 1955,
        Zusatzziffer: 4,
        Gemeindename: 'Chamoson',
        'BFS-Nr': 6022,
        Kantonskürzel: 'VS',
        E: 7.215926644910232,
        N: 46.206729155346586,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'St-Pierre-de-Clages',
        PLZ: 1955,
        Zusatzziffer: 5,
        Gemeindename: 'Chamoson',
        'BFS-Nr': 6022,
        Kantonskürzel: 'VS',
        E: 7.237469707087747,
        N: 46.19224821195775,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Conthey',
        PLZ: 1964,
        Zusatzziffer: 0,
        Gemeindename: 'Conthey',
        'BFS-Nr': 6023,
        Kantonskürzel: 'VS',
        E: 7.304875135654945,
        N: 46.227350115259476,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'St-Séverin',
        PLZ: 1975,
        Zusatzziffer: 0,
        Gemeindename: 'Conthey',
        'BFS-Nr': 6023,
        Kantonskürzel: 'VS',
        E: 7.3028554366548155,
        N: 46.23872386436844,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Erde',
        PLZ: 1976,
        Zusatzziffer: 0,
        Gemeindename: 'Conthey',
        'BFS-Nr': 6023,
        Kantonskürzel: 'VS',
        E: 7.293331028095262,
        N: 46.239052115911235,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Aven',
        PLZ: 1976,
        Zusatzziffer: 1,
        Gemeindename: 'Conthey',
        'BFS-Nr': 6023,
        Kantonskürzel: 'VS',
        E: 7.27234112181462,
        N: 46.23648589947899,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Daillon',
        PLZ: 1976,
        Zusatzziffer: 2,
        Gemeindename: 'Conthey',
        'BFS-Nr': 6023,
        Kantonskürzel: 'VS',
        E: 7.306127229813592,
        N: 46.25611166239829,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Clèbes (Nendaz)',
        PLZ: 1993,
        Zusatzziffer: 2,
        Gemeindename: 'Nendaz',
        'BFS-Nr': 6024,
        Kantonskürzel: 'VS',
        E: 7.336477945561453,
        N: 46.18517923076206,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Aproz (Nendaz)',
        PLZ: 1994,
        Zusatzziffer: 2,
        Gemeindename: 'Nendaz',
        'BFS-Nr': 6024,
        Kantonskürzel: 'VS',
        E: 7.315958020818162,
        N: 46.20672881578374,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Basse-Nendaz',
        PLZ: 1996,
        Zusatzziffer: 0,
        Gemeindename: 'Nendaz',
        'BFS-Nr': 6024,
        Kantonskürzel: 'VS',
        E: 7.312678751095369,
        N: 46.189480135671126,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Fey (Nendaz)',
        PLZ: 1996,
        Zusatzziffer: 1,
        Gemeindename: 'Nendaz',
        'BFS-Nr': 6024,
        Kantonskürzel: 'VS',
        E: 7.268473167783405,
        N: 46.187364375310295,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Bieudron (Nendaz)',
        PLZ: 1996,
        Zusatzziffer: 2,
        Gemeindename: 'Nendaz',
        'BFS-Nr': 6024,
        Kantonskürzel: 'VS',
        E: 7.27784224501765,
        N: 46.194745296578695,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Condémines (Nendaz)',
        PLZ: 1996,
        Zusatzziffer: 3,
        Gemeindename: 'Nendaz',
        'BFS-Nr': 6024,
        Kantonskürzel: 'VS',
        E: 7.255823378737566,
        N: 46.176969361605046,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Saclentse',
        PLZ: 1996,
        Zusatzziffer: 4,
        Gemeindename: 'Nendaz',
        'BFS-Nr': 6024,
        Kantonskürzel: 'VS',
        E: 7.319744923587644,
        N: 46.17591816845199,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Baar (Nendaz)',
        PLZ: 1996,
        Zusatzziffer: 5,
        Gemeindename: 'Nendaz',
        'BFS-Nr': 6024,
        Kantonskürzel: 'VS',
        E: 7.329472002363617,
        N: 46.20470394062068,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Beuson (Nendaz)',
        PLZ: 1996,
        Zusatzziffer: 6,
        Gemeindename: 'Nendaz',
        'BFS-Nr': 6024,
        Kantonskürzel: 'VS',
        E: 7.323724292212815,
        N: 46.18143443059348,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Brignon (Nendaz)',
        PLZ: 1996,
        Zusatzziffer: 7,
        Gemeindename: 'Nendaz',
        'BFS-Nr': 6024,
        Kantonskürzel: 'VS',
        E: 7.327336309838794,
        N: 46.189075585285956,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Haute-Nendaz',
        PLZ: 1997,
        Zusatzziffer: 0,
        Gemeindename: 'Nendaz',
        'BFS-Nr': 6024,
        Kantonskürzel: 'VS',
        E: 7.294445612320387,
        N: 46.18332517759239,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Siviez (Nendaz)',
        PLZ: 1997,
        Zusatzziffer: 1,
        Gemeindename: 'Nendaz',
        'BFS-Nr': 6024,
        Kantonskürzel: 'VS',
        E: 7.316377094734438,
        N: 46.135765289129985,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Sornard (Nendaz)',
        PLZ: 1997,
        Zusatzziffer: 2,
        Gemeindename: 'Nendaz',
        'BFS-Nr': 6024,
        Kantonskürzel: 'VS',
        E: 7.3047423313973905,
        N: 46.18548094915986,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Vétroz',
        PLZ: 1963,
        Zusatzziffer: 0,
        Gemeindename: 'Vétroz',
        'BFS-Nr': 6025,
        Kantonskürzel: 'VS',
        E: 7.281692659108469,
        N: 46.22722708679913,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Bourg-St-Pierre',
        PLZ: 1946,
        Zusatzziffer: 0,
        Gemeindename: 'Bourg-Saint-Pierre',
        'BFS-Nr': 6032,
        Kantonskürzel: 'VS',
        E: 7.212554391210754,
        N: 45.949619836750344,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Liddes',
        PLZ: 1945,
        Zusatzziffer: 0,
        Gemeindename: 'Liddes',
        'BFS-Nr': 6033,
        Kantonskürzel: 'VS',
        E: 7.184741133853141,
        N: 45.99106451267925,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Fontaine Dessus (Liddes)',
        PLZ: 1945,
        Zusatzziffer: 2,
        Gemeindename: 'Liddes',
        'BFS-Nr': 6033,
        Kantonskürzel: 'VS',
        E: 7.174033997719403,
        N: 46.00573133140505,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Fontaine Dessous (Liddes)',
        PLZ: 1945,
        Zusatzziffer: 3,
        Gemeindename: 'Liddes',
        'BFS-Nr': 6033,
        Kantonskürzel: 'VS',
        E: 7.167993421763276,
        N: 46.00996941558346,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Dranse (Liddes)',
        PLZ: 1945,
        Zusatzziffer: 4,
        Gemeindename: 'Liddes',
        'BFS-Nr': 6033,
        Kantonskürzel: 'VS',
        E: 7.1804935738641005,
        N: 45.989755088005616,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Chandonne (Liddes)',
        PLZ: 1945,
        Zusatzziffer: 5,
        Gemeindename: 'Liddes',
        'BFS-Nr': 6033,
        Kantonskürzel: 'VS',
        E: 7.179724078813686,
        N: 46.00521340767971,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Rive Haute (Liddes)',
        PLZ: 1945,
        Zusatzziffer: 6,
        Gemeindename: 'Liddes',
        'BFS-Nr': 6033,
        Kantonskürzel: 'VS',
        E: 7.17113191267578,
        N: 46.006695138719614,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Fornex (Liddes)',
        PLZ: 1945,
        Zusatzziffer: 7,
        Gemeindename: 'Liddes',
        'BFS-Nr': 6033,
        Kantonskürzel: 'VS',
        E: 7.1630968850116625,
        N: 46.00890202907271,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Les Moulins VS (Liddes)',
        PLZ: 1945,
        Zusatzziffer: 8,
        Gemeindename: 'Liddes',
        'BFS-Nr': 6033,
        Kantonskürzel: 'VS',
        E: 7.168071910855633,
        N: 46.004338963694835,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Vichères (Liddes)',
        PLZ: 1945,
        Zusatzziffer: 9,
        Gemeindename: 'Liddes',
        'BFS-Nr': 6033,
        Kantonskürzel: 'VS',
        E: 7.1665875066155,
        N: 45.996487885180585,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Palasuit (Liddes)',
        PLZ: 1945,
        Zusatzziffer: 10,
        Gemeindename: 'Liddes',
        'BFS-Nr': 6033,
        Kantonskürzel: 'VS',
        E: 7.192498556584034,
        N: 45.98271281780468,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Chez Petit (Liddes)',
        PLZ: 1945,
        Zusatzziffer: 11,
        Gemeindename: 'Liddes',
        'BFS-Nr': 6033,
        Kantonskürzel: 'VS',
        E: 7.178138307351293,
        N: 45.98752833125666,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Petit Vichères (Liddes)',
        PLZ: 1945,
        Zusatzziffer: 12,
        Gemeindename: 'Liddes',
        'BFS-Nr': 6033,
        Kantonskürzel: 'VS',
        E: 7.162270034939605,
        N: 45.99080348425938,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Orsières',
        PLZ: 1937,
        Zusatzziffer: 0,
        Gemeindename: 'Orsières',
        'BFS-Nr': 6034,
        Kantonskürzel: 'VS',
        E: 7.146210437707941,
        N: 46.031092461391744,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Champex-Lac',
        PLZ: 1938,
        Zusatzziffer: 0,
        Gemeindename: 'Orsières',
        'BFS-Nr': 6034,
        Kantonskürzel: 'VS',
        E: 7.119148173828239,
        N: 46.02667508335323,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Praz-de-Fort',
        PLZ: 1943,
        Zusatzziffer: 0,
        Gemeindename: 'Orsières',
        'BFS-Nr': 6034,
        Kantonskürzel: 'VS',
        E: 7.125481227560095,
        N: 45.99001394326167,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'La Fouly VS',
        PLZ: 1944,
        Zusatzziffer: 0,
        Gemeindename: 'Orsières',
        'BFS-Nr': 6034,
        Kantonskürzel: 'VS',
        E: 7.097446198204443,
        N: 45.93199611849921,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Sembrancher',
        PLZ: 1933,
        Zusatzziffer: 0,
        Gemeindename: 'Sembrancher',
        'BFS-Nr': 6035,
        Kantonskürzel: 'VS',
        E: 7.1505448128022655,
        N: 46.07821788709354,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Chamoille (Sembrancher)',
        PLZ: 1933,
        Zusatzziffer: 3,
        Gemeindename: 'Sembrancher',
        'BFS-Nr': 6035,
        Kantonskürzel: 'VS',
        E: 7.152547918703028,
        N: 46.060961589826384,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'La Garde (Sembrancher)',
        PLZ: 1933,
        Zusatzziffer: 4,
        Gemeindename: 'Sembrancher',
        'BFS-Nr': 6035,
        Kantonskürzel: 'VS',
        E: 7.146475227402576,
        N: 46.06515751512425,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Chemin',
        PLZ: 1927,
        Zusatzziffer: 0,
        Gemeindename: 'Val de Bagnes',
        'BFS-Nr': 6037,
        Kantonskürzel: 'VS',
        E: 7.097238740555881,
        N: 46.089730278897655,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Vens (Sembrancher)',
        PLZ: 1933,
        Zusatzziffer: 2,
        Gemeindename: 'Val de Bagnes',
        'BFS-Nr': 6037,
        Kantonskürzel: 'VS',
        E: 7.126278990513543,
        N: 46.08841828662321,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Le Châble VS',
        PLZ: 1934,
        Zusatzziffer: 0,
        Gemeindename: 'Val de Bagnes',
        'BFS-Nr': 6037,
        Kantonskürzel: 'VS',
        E: 7.214645525457372,
        N: 46.07940545891346,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Bruson',
        PLZ: 1934,
        Zusatzziffer: 1,
        Gemeindename: 'Val de Bagnes',
        'BFS-Nr': 6037,
        Kantonskürzel: 'VS',
        E: 7.218426579049948,
        N: 46.065908510262936,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Verbier',
        PLZ: 1936,
        Zusatzziffer: 0,
        Gemeindename: 'Val de Bagnes',
        'BFS-Nr': 6037,
        Kantonskürzel: 'VS',
        E: 7.21955565904598,
        N: 46.094075571106075,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Vollèges',
        PLZ: 1941,
        Zusatzziffer: 0,
        Gemeindename: 'Val de Bagnes',
        'BFS-Nr': 6037,
        Kantonskürzel: 'VS',
        E: 7.168797207352025,
        N: 46.08737275112697,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Cries (Vollèges)',
        PLZ: 1941,
        Zusatzziffer: 2,
        Gemeindename: 'Val de Bagnes',
        'BFS-Nr': 6037,
        Kantonskürzel: 'VS',
        E: 7.177052106545876,
        N: 46.091385356935966,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Levron',
        PLZ: 1942,
        Zusatzziffer: 0,
        Gemeindename: 'Val de Bagnes',
        'BFS-Nr': 6037,
        Kantonskürzel: 'VS',
        E: 7.162036061135981,
        N: 46.09797853466951,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Versegères',
        PLZ: 1947,
        Zusatzziffer: 0,
        Gemeindename: 'Val de Bagnes',
        'BFS-Nr': 6037,
        Kantonskürzel: 'VS',
        E: 7.23392761552034,
        N: 46.06582658876111,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Champsec',
        PLZ: 1947,
        Zusatzziffer: 5,
        Gemeindename: 'Val de Bagnes',
        'BFS-Nr': 6037,
        Kantonskürzel: 'VS',
        E: 7.24375522628739,
        N: 46.056146151062464,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Lourtier',
        PLZ: 1948,
        Zusatzziffer: 0,
        Gemeindename: 'Val de Bagnes',
        'BFS-Nr': 6037,
        Kantonskürzel: 'VS',
        E: 7.2635626052758475,
        N: 46.05238483628717,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Fionnay',
        PLZ: 1948,
        Zusatzziffer: 1,
        Gemeindename: 'Val de Bagnes',
        'BFS-Nr': 6037,
        Kantonskürzel: 'VS',
        E: 7.309540559358413,
        N: 46.03230309528966,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Sarreyer',
        PLZ: 1948,
        Zusatzziffer: 2,
        Gemeindename: 'Val de Bagnes',
        'BFS-Nr': 6037,
        Kantonskürzel: 'VS',
        E: 7.249881462634921,
        N: 46.06189611083965,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Bellwald',
        PLZ: 3997,
        Zusatzziffer: 0,
        Gemeindename: 'Bellwald',
        'BFS-Nr': 6052,
        Kantonskürzel: 'VS',
        E: 8.160111829445835,
        N: 46.423638881133016,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Binn',
        PLZ: 3996,
        Zusatzziffer: 0,
        Gemeindename: 'Binn',
        'BFS-Nr': 6054,
        Kantonskürzel: 'VS',
        E: 8.183673103544466,
        N: 46.365190750593875,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Ernen',
        PLZ: 3995,
        Zusatzziffer: 0,
        Gemeindename: 'Ernen',
        'BFS-Nr': 6056,
        Kantonskürzel: 'VS',
        E: 8.14397104683918,
        N: 46.39886293768462,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Ausserbinn',
        PLZ: 3995,
        Zusatzziffer: 2,
        Gemeindename: 'Ernen',
        'BFS-Nr': 6056,
        Kantonskürzel: 'VS',
        E: 8.149206534719529,
        N: 46.38225718710618,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Mühlebach (Goms)',
        PLZ: 3995,
        Zusatzziffer: 3,
        Gemeindename: 'Ernen',
        'BFS-Nr': 6056,
        Kantonskürzel: 'VS',
        E: 8.15664183283064,
        N: 46.40829684054226,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Steinhaus',
        PLZ: 3995,
        Zusatzziffer: 4,
        Gemeindename: 'Ernen',
        'BFS-Nr': 6056,
        Kantonskürzel: 'VS',
        E: 8.17789670497378,
        N: 46.42183922600843,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Fiesch',
        PLZ: 3984,
        Zusatzziffer: 0,
        Gemeindename: 'Fiesch',
        'BFS-Nr': 6057,
        Kantonskürzel: 'VS',
        E: 8.13408029640711,
        N: 46.403684608010465,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Jungfraujoch',
        PLZ: 3801,
        Zusatzziffer: 33,
        Gemeindename: 'Fieschertal',
        'BFS-Nr': 6058,
        Kantonskürzel: 'VS',
        E: 7.983347716477719,
        N: 46.547422450482465,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Fieschertal',
        PLZ: 3984,
        Zusatzziffer: 2,
        Gemeindename: 'Fieschertal',
        'BFS-Nr': 6058,
        Kantonskürzel: 'VS',
        E: 8.143128535931984,
        N: 46.42442810137102,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Lax',
        PLZ: 3994,
        Zusatzziffer: 0,
        Gemeindename: 'Lax',
        'BFS-Nr': 6061,
        Kantonskürzel: 'VS',
        E: 8.11738200750304,
        N: 46.38958000983947,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Ulrichen',
        PLZ: 3988,
        Zusatzziffer: 0,
        Gemeindename: 'Obergoms',
        'BFS-Nr': 6076,
        Kantonskürzel: 'VS',
        E: 8.303466193785733,
        N: 46.50667163853181,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Obergesteln',
        PLZ: 3988,
        Zusatzziffer: 2,
        Gemeindename: 'Obergoms',
        'BFS-Nr': 6076,
        Kantonskürzel: 'VS',
        E: 8.324603903967008,
        N: 46.514823763050195,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Oberwald',
        PLZ: 3999,
        Zusatzziffer: 0,
        Gemeindename: 'Obergoms',
        'BFS-Nr': 6076,
        Kantonskürzel: 'VS',
        E: 8.348446195353008,
        N: 46.534582669967165,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Münster VS',
        PLZ: 3985,
        Zusatzziffer: 0,
        Gemeindename: 'Goms',
        'BFS-Nr': 6077,
        Kantonskürzel: 'VS',
        E: 8.266346251866391,
        N: 46.48938836902538,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Geschinen',
        PLZ: 3985,
        Zusatzziffer: 2,
        Gemeindename: 'Goms',
        'BFS-Nr': 6077,
        Kantonskürzel: 'VS',
        E: 8.282804007774127,
        N: 46.49568182173531,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Biel VS',
        PLZ: 3989,
        Zusatzziffer: 0,
        Gemeindename: 'Goms',
        'BFS-Nr': 6077,
        Kantonskürzel: 'VS',
        E: 8.217076611801785,
        N: 46.455783941284956,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Ritzingen',
        PLZ: 3989,
        Zusatzziffer: 2,
        Gemeindename: 'Goms',
        'BFS-Nr': 6077,
        Kantonskürzel: 'VS',
        E: 8.22338801619698,
        N: 46.4571210442254,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Selkingen',
        PLZ: 3989,
        Zusatzziffer: 3,
        Gemeindename: 'Goms',
        'BFS-Nr': 6077,
        Kantonskürzel: 'VS',
        E: 8.213629272297085,
        N: 46.454259564980575,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Niederwald',
        PLZ: 3989,
        Zusatzziffer: 4,
        Gemeindename: 'Goms',
        'BFS-Nr': 6077,
        Kantonskürzel: 'VS',
        E: 8.189193266412971,
        N: 46.43573120602267,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Blitzingen',
        PLZ: 3989,
        Zusatzziffer: 5,
        Gemeindename: 'Goms',
        'BFS-Nr': 6077,
        Kantonskürzel: 'VS',
        E: 8.202234305755528,
        N: 46.44138903378869,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Reckingen VS',
        PLZ: 3998,
        Zusatzziffer: 0,
        Gemeindename: 'Goms',
        'BFS-Nr': 6077,
        Kantonskürzel: 'VS',
        E: 8.2427202443981,
        N: 46.46956276227891,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Gluringen',
        PLZ: 3998,
        Zusatzziffer: 1,
        Gemeindename: 'Goms',
        'BFS-Nr': 6077,
        Kantonskürzel: 'VS',
        E: 8.232041425819009,
        N: 46.464282841609005,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Fortunau (Ayent)',
        PLZ: 1966,
        Zusatzziffer: 2,
        Gemeindename: 'Ayent',
        'BFS-Nr': 6082,
        Kantonskürzel: 'VS',
        E: 7.415952535411249,
        N: 46.285321704195425,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Luc (Ayent)',
        PLZ: 1966,
        Zusatzziffer: 3,
        Gemeindename: 'Ayent',
        'BFS-Nr': 6082,
        Kantonskürzel: 'VS',
        E: 7.418082700879362,
        N: 46.28280153967691,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'St-Romain (Ayent)',
        PLZ: 1966,
        Zusatzziffer: 4,
        Gemeindename: 'Ayent',
        'BFS-Nr': 6082,
        Kantonskürzel: 'VS',
        E: 7.41281318585804,
        N: 46.28262261706747,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Saxonne (Ayent)',
        PLZ: 1966,
        Zusatzziffer: 5,
        Gemeindename: 'Ayent',
        'BFS-Nr': 6082,
        Kantonskürzel: 'VS',
        E: 7.406914030883616,
        N: 46.28023175977324,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Villa (Ayent)',
        PLZ: 1966,
        Zusatzziffer: 6,
        Gemeindename: 'Ayent',
        'BFS-Nr': 6082,
        Kantonskürzel: 'VS',
        E: 7.41563064807125,
        N: 46.27589147979824,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'La Place (Ayent)',
        PLZ: 1966,
        Zusatzziffer: 7,
        Gemeindename: 'Ayent',
        'BFS-Nr': 6082,
        Kantonskürzel: 'VS',
        E: 7.412351364115114,
        N: 46.273978864150045,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Botyre (Ayent)',
        PLZ: 1966,
        Zusatzziffer: 8,
        Gemeindename: 'Ayent',
        'BFS-Nr': 6082,
        Kantonskürzel: 'VS',
        E: 7.4045329002152735,
        N: 46.27630414639494,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Blignou (Ayent)',
        PLZ: 1966,
        Zusatzziffer: 9,
        Gemeindename: 'Ayent',
        'BFS-Nr': 6082,
        Kantonskürzel: 'VS',
        E: 7.398727580133826,
        N: 46.27210958552028,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Argnou (Ayent)',
        PLZ: 1966,
        Zusatzziffer: 10,
        Gemeindename: 'Ayent',
        'BFS-Nr': 6082,
        Kantonskürzel: 'VS',
        E: 7.399568811492724,
        N: 46.26291723472667,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Signèse (Ayent)',
        PLZ: 1966,
        Zusatzziffer: 11,
        Gemeindename: 'Ayent',
        'BFS-Nr': 6082,
        Kantonskürzel: 'VS',
        E: 7.4011097458904285,
        N: 46.257458496134895,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Anzère',
        PLZ: 1972,
        Zusatzziffer: 0,
        Gemeindename: 'Ayent',
        'BFS-Nr': 6082,
        Kantonskürzel: 'VS',
        E: 7.396607870528842,
        N: 46.29618343485562,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Evolène',
        PLZ: 1983,
        Zusatzziffer: 0,
        Gemeindename: 'Evolène',
        'BFS-Nr': 6083,
        Kantonskürzel: 'VS',
        E: 7.4959923991696,
        N: 46.11305679987192,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Lanna',
        PLZ: 1983,
        Zusatzziffer: 2,
        Gemeindename: 'Evolène',
        'BFS-Nr': 6083,
        Kantonskürzel: 'VS',
        E: 7.478568879595649,
        N: 46.12088556928785,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Les Haudères',
        PLZ: 1984,
        Zusatzziffer: 0,
        Gemeindename: 'Evolène',
        'BFS-Nr': 6083,
        Kantonskürzel: 'VS',
        E: 7.508661823447762,
        N: 46.08442395770971,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'La Tour VS',
        PLZ: 1984,
        Zusatzziffer: 1,
        Gemeindename: 'Evolène',
        'BFS-Nr': 6083,
        Kantonskürzel: 'VS',
        E: 7.505717551954862,
        N: 46.097946315665084,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'La Sage',
        PLZ: 1985,
        Zusatzziffer: 0,
        Gemeindename: 'Evolène',
        'BFS-Nr': 6083,
        Kantonskürzel: 'VS',
        E: 7.514429606082198,
        N: 46.09883475042129,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'La Forclaz VS',
        PLZ: 1985,
        Zusatzziffer: 1,
        Gemeindename: 'Evolène',
        'BFS-Nr': 6083,
        Kantonskürzel: 'VS',
        E: 7.5175454350910735,
        N: 46.08597173904282,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Villa (Evolène)',
        PLZ: 1985,
        Zusatzziffer: 2,
        Gemeindename: 'Evolène',
        'BFS-Nr': 6083,
        Kantonskürzel: 'VS',
        E: 7.512424319095392,
        N: 46.10635938590262,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Arolla',
        PLZ: 1986,
        Zusatzziffer: 0,
        Gemeindename: 'Evolène',
        'BFS-Nr': 6083,
        Kantonskürzel: 'VS',
        E: 7.483231414915171,
        N: 46.02482793861217,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Euseigne',
        PLZ: 1982,
        Zusatzziffer: 0,
        Gemeindename: 'Hérémence',
        'BFS-Nr': 6084,
        Kantonskürzel: 'VS',
        E: 7.422313392371634,
        N: 46.171640893798205,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Hérémence',
        PLZ: 1987,
        Zusatzziffer: 0,
        Gemeindename: 'Hérémence',
        'BFS-Nr': 6084,
        Kantonskürzel: 'VS',
        E: 7.4045235263483615,
        N: 46.180538133568696,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'St-Martin VS',
        PLZ: 1969,
        Zusatzziffer: 0,
        Gemeindename: 'Saint-Martin (VS)',
        'BFS-Nr': 6087,
        Kantonskürzel: 'VS',
        E: 7.44377699015705,
        N: 46.172456189261226,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Liez (St-Martin)',
        PLZ: 1969,
        Zusatzziffer: 2,
        Gemeindename: 'Saint-Martin (VS)',
        'BFS-Nr': 6087,
        Kantonskürzel: 'VS',
        E: 7.4508964402342475,
        N: 46.16250929082272,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Trogne (St-Martin)',
        PLZ: 1969,
        Zusatzziffer: 3,
        Gemeindename: 'Saint-Martin (VS)',
        'BFS-Nr': 6087,
        Kantonskürzel: 'VS',
        E: 7.45158972392202,
        N: 46.167854159937725,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Suen (St-Martin)',
        PLZ: 1969,
        Zusatzziffer: 4,
        Gemeindename: 'Saint-Martin (VS)',
        'BFS-Nr': 6087,
        Kantonskürzel: 'VS',
        E: 7.436921290418413,
        N: 46.18145579464538,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Eison (St-Martin)',
        PLZ: 1969,
        Zusatzziffer: 5,
        Gemeindename: 'Saint-Martin (VS)',
        'BFS-Nr': 6087,
        Kantonskürzel: 'VS',
        E: 7.466755685444406,
        N: 46.15351936908285,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Vex',
        PLZ: 1981,
        Zusatzziffer: 0,
        Gemeindename: 'Vex',
        'BFS-Nr': 6089,
        Kantonskürzel: 'VS',
        E: 7.397356856847493,
        N: 46.21184118566394,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Thyon',
        PLZ: 1988,
        Zusatzziffer: 1,
        Gemeindename: 'Vex',
        'BFS-Nr': 6089,
        Kantonskürzel: 'VS',
        E: 7.373169211828387,
        N: 46.18148816049839,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Les Collons',
        PLZ: 1988,
        Zusatzziffer: 2,
        Gemeindename: 'Vex',
        'BFS-Nr': 6089,
        Kantonskürzel: 'VS',
        E: 7.386178045011673,
        N: 46.18513974905437,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Les Mayens-de-Sion',
        PLZ: 1992,
        Zusatzziffer: 1,
        Gemeindename: 'Vex',
        'BFS-Nr': 6089,
        Kantonskürzel: 'VS',
        E: 7.381197017031677,
        N: 46.20203126861733,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Vernamiège',
        PLZ: 1961,
        Zusatzziffer: 0,
        Gemeindename: 'Mont-Noble',
        'BFS-Nr': 6090,
        Kantonskürzel: 'VS',
        E: 7.437964329016589,
        N: 46.21015968405985,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Mase',
        PLZ: 1968,
        Zusatzziffer: 0,
        Gemeindename: 'Mont-Noble',
        'BFS-Nr': 6090,
        Kantonskürzel: 'VS',
        E: 7.431235828020041,
        N: 46.19489153958595,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Nax',
        PLZ: 1973,
        Zusatzziffer: 0,
        Gemeindename: 'Mont-Noble',
        'BFS-Nr': 6090,
        Kantonskürzel: 'VS',
        E: 7.427818790780008,
        N: 46.2285016297058,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Agarn',
        PLZ: 3951,
        Zusatzziffer: 0,
        Gemeindename: 'Agarn',
        'BFS-Nr': 6101,
        Kantonskürzel: 'VS',
        E: 7.663313713524741,
        N: 46.296663326994505,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Albinen',
        PLZ: 3955,
        Zusatzziffer: 0,
        Gemeindename: 'Albinen',
        'BFS-Nr': 6102,
        Kantonskürzel: 'VS',
        E: 7.63334870249599,
        N: 46.340379100624205,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Ergisch',
        PLZ: 3947,
        Zusatzziffer: 0,
        Gemeindename: 'Ergisch',
        'BFS-Nr': 6104,
        Kantonskürzel: 'VS',
        E: 7.713069860405987,
        N: 46.290975459792826,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Inden',
        PLZ: 3953,
        Zusatzziffer: 1,
        Gemeindename: 'Inden',
        'BFS-Nr': 6109,
        Kantonskürzel: 'VS',
        E: 7.618611538591244,
        N: 46.34384515833891,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Susten',
        PLZ: 3952,
        Zusatzziffer: 0,
        Gemeindename: 'Leuk',
        'BFS-Nr': 6110,
        Kantonskürzel: 'VS',
        E: 7.6430672866970735,
        N: 46.30833170011727,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Leuk Stadt',
        PLZ: 3953,
        Zusatzziffer: 0,
        Gemeindename: 'Leuk',
        'BFS-Nr': 6110,
        Kantonskürzel: 'VS',
        E: 7.6350814634788415,
        N: 46.317369139133405,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Erschmatt',
        PLZ: 3957,
        Zusatzziffer: 0,
        Gemeindename: 'Leuk',
        'BFS-Nr': 6110,
        Kantonskürzel: 'VS',
        E: 7.692662184119796,
        N: 46.32153670722972,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Leukerbad',
        PLZ: 3954,
        Zusatzziffer: 0,
        Gemeindename: 'Leukerbad',
        'BFS-Nr': 6111,
        Kantonskürzel: 'VS',
        E: 7.629260790700122,
        N: 46.37938722459076,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Oberems',
        PLZ: 3948,
        Zusatzziffer: 1,
        Gemeindename: 'Oberems',
        'BFS-Nr': 6112,
        Kantonskürzel: 'VS',
        E: 7.6949620925526485,
        N: 46.28147619930132,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Salgesch',
        PLZ: 3970,
        Zusatzziffer: 0,
        Gemeindename: 'Salgesch',
        'BFS-Nr': 6113,
        Kantonskürzel: 'VS',
        E: 7.570781431707039,
        N: 46.31173143034224,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Varen',
        PLZ: 3953,
        Zusatzziffer: 2,
        Gemeindename: 'Varen',
        'BFS-Nr': 6116,
        Kantonskürzel: 'VS',
        E: 7.607308998404165,
        N: 46.316526892497095,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Guttet-Feschel',
        PLZ: 3956,
        Zusatzziffer: 0,
        Gemeindename: 'Guttet-Feschel',
        'BFS-Nr': 6117,
        Kantonskürzel: 'VS',
        E: 7.665350846699238,
        N: 46.324917529761244,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Gampel',
        PLZ: 3945,
        Zusatzziffer: 0,
        Gemeindename: 'Gampel-Bratsch',
        'BFS-Nr': 6118,
        Kantonskürzel: 'VS',
        E: 7.741713117484328,
        N: 46.315730002221734,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Niedergampel',
        PLZ: 3945,
        Zusatzziffer: 1,
        Gemeindename: 'Gampel-Bratsch',
        'BFS-Nr': 6118,
        Kantonskürzel: 'VS',
        E: 7.714112309381215,
        N: 46.312213512663426,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Bratsch',
        PLZ: 3957,
        Zusatzziffer: 2,
        Gemeindename: 'Gampel-Bratsch',
        'BFS-Nr': 6118,
        Kantonskürzel: 'VS',
        E: 7.708997276401564,
        N: 46.32004530021047,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Turtmann',
        PLZ: 3946,
        Zusatzziffer: 0,
        Gemeindename: 'Turtmann-Unterems',
        'BFS-Nr': 6119,
        Kantonskürzel: 'VS',
        E: 7.702859822230383,
        N: 46.301216099193645,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Gruben',
        PLZ: 3946,
        Zusatzziffer: 2,
        Gemeindename: 'Turtmann-Unterems',
        'BFS-Nr': 6119,
        Kantonskürzel: 'VS',
        E: 7.705911138811711,
        N: 46.21074558651029,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Unterems',
        PLZ: 3948,
        Zusatzziffer: 0,
        Gemeindename: 'Turtmann-Unterems',
        'BFS-Nr': 6119,
        Kantonskürzel: 'VS',
        E: 7.696690811757482,
        N: 46.292017116851824,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Bovernier',
        PLZ: 1932,
        Zusatzziffer: 0,
        Gemeindename: 'Bovernier',
        'BFS-Nr': 6131,
        Kantonskürzel: 'VS',
        E: 7.087412805299337,
        N: 46.07996265073143,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Les Valettes (Bovernier)',
        PLZ: 1932,
        Zusatzziffer: 2,
        Gemeindename: 'Bovernier',
        'BFS-Nr': 6131,
        Kantonskürzel: 'VS',
        E: 7.074574053018097,
        N: 46.07565125514208,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Fully',
        PLZ: 1926,
        Zusatzziffer: 0,
        Gemeindename: 'Fully',
        'BFS-Nr': 6133,
        Kantonskürzel: 'VS',
        E: 7.113703645184003,
        N: 46.138221596804385,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Isérables',
        PLZ: 1914,
        Zusatzziffer: 0,
        Gemeindename: 'Isérables',
        'BFS-Nr': 6134,
        Kantonskürzel: 'VS',
        E: 7.245092402872128,
        N: 46.161497843679136,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Ovronnaz',
        PLZ: 1911,
        Zusatzziffer: 0,
        Gemeindename: 'Leytron',
        'BFS-Nr': 6135,
        Kantonskürzel: 'VS',
        E: 7.172186525495811,
        N: 46.19939911805511,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Leytron',
        PLZ: 1912,
        Zusatzziffer: 0,
        Gemeindename: 'Leytron',
        'BFS-Nr': 6135,
        Kantonskürzel: 'VS',
        E: 7.2146604012521225,
        N: 46.1878628417876,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Produit (Leytron)',
        PLZ: 1912,
        Zusatzziffer: 2,
        Gemeindename: 'Leytron',
        'BFS-Nr': 6135,
        Kantonskürzel: 'VS',
        E: 7.19474983825247,
        N: 46.18654133564318,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Montagnon (Leytron)',
        PLZ: 1912,
        Zusatzziffer: 3,
        Gemeindename: 'Leytron',
        'BFS-Nr': 6135,
        Kantonskürzel: 'VS',
        E: 7.192906154818378,
        N: 46.190330029573396,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Dugny (Leytron)',
        PLZ: 1912,
        Zusatzziffer: 4,
        Gemeindename: 'Leytron',
        'BFS-Nr': 6135,
        Kantonskürzel: 'VS',
        E: 7.182926253956099,
        N: 46.18932705005605,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Charrat',
        PLZ: 1906,
        Zusatzziffer: 0,
        Gemeindename: 'Martigny',
        'BFS-Nr': 6136,
        Kantonskürzel: 'VS',
        E: 7.129801616572824,
        N: 46.123485899072314,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Martigny',
        PLZ: 1920,
        Zusatzziffer: 0,
        Gemeindename: 'Martigny',
        'BFS-Nr': 6136,
        Kantonskürzel: 'VS',
        E: 7.073760736530098,
        N: 46.100618640421395,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Martigny-Croix',
        PLZ: 1921,
        Zusatzziffer: 0,
        Gemeindename: 'Martigny-Combe',
        'BFS-Nr': 6137,
        Kantonskürzel: 'VS',
        E: 7.053963840468771,
        N: 46.086561621141115,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Ravoire',
        PLZ: 1928,
        Zusatzziffer: 0,
        Gemeindename: 'Martigny-Combe',
        'BFS-Nr': 6137,
        Kantonskürzel: 'VS',
        E: 7.046008021997069,
        N: 46.097160992197395,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Riddes',
        PLZ: 1908,
        Zusatzziffer: 0,
        Gemeindename: 'Riddes',
        'BFS-Nr': 6139,
        Kantonskürzel: 'VS',
        E: 7.220397364676841,
        N: 46.16866445379732,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Auddes-sur-Riddes',
        PLZ: 1914,
        Zusatzziffer: 2,
        Gemeindename: 'Riddes',
        'BFS-Nr': 6139,
        Kantonskürzel: 'VS',
        E: 7.244082421248077,
        N: 46.154421345115445,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'La Tzoumaz',
        PLZ: 1918,
        Zusatzziffer: 0,
        Gemeindename: 'Riddes',
        'BFS-Nr': 6139,
        Kantonskürzel: 'VS',
        E: 7.235581015338636,
        N: 46.1442506512889,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Saillon',
        PLZ: 1913,
        Zusatzziffer: 0,
        Gemeindename: 'Saillon',
        'BFS-Nr': 6140,
        Kantonskürzel: 'VS',
        E: 7.184742033903402,
        N: 46.171378959509966,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Saxon',
        PLZ: 1907,
        Zusatzziffer: 0,
        Gemeindename: 'Saxon',
        'BFS-Nr': 6141,
        Kantonskürzel: 'VS',
        E: 7.180756670424969,
        N: 46.1453358685022,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Trient',
        PLZ: 1929,
        Zusatzziffer: 0,
        Gemeindename: 'Trient',
        'BFS-Nr': 6142,
        Kantonskürzel: 'VS',
        E: 6.994749281258012,
        N: 46.056005434267945,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Champéry',
        PLZ: 1874,
        Zusatzziffer: 0,
        Gemeindename: 'Champéry',
        'BFS-Nr': 6151,
        Kantonskürzel: 'VS',
        E: 6.870265228961153,
        N: 46.17828605626536,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Collombey',
        PLZ: 1868,
        Zusatzziffer: 0,
        Gemeindename: 'Collombey-Muraz',
        'BFS-Nr': 6152,
        Kantonskürzel: 'VS',
        E: 6.934285230104127,
        N: 46.253149809266304,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Collombey',
        PLZ: 1868,
        Zusatzziffer: 0,
        Gemeindename: 'Collombey-Muraz',
        'BFS-Nr': 6152,
        Kantonskürzel: 'VS',
        E: 6.946357204847805,
        N: 46.26798982210981,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Muraz (Collombey)',
        PLZ: 1893,
        Zusatzziffer: 0,
        Gemeindename: 'Collombey-Muraz',
        'BFS-Nr': 6152,
        Kantonskürzel: 'VS',
        E: 6.925764110753536,
        N: 46.28020212105689,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Monthey',
        PLZ: 1870,
        Zusatzziffer: 0,
        Gemeindename: 'Monthey',
        'BFS-Nr': 6153,
        Kantonskürzel: 'VS',
        E: 6.815222333850122,
        N: 46.207043469702036,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Monthey',
        PLZ: 1870,
        Zusatzziffer: 0,
        Gemeindename: 'Monthey',
        'BFS-Nr': 6153,
        Kantonskürzel: 'VS',
        E: 6.946996349593051,
        N: 46.25269662181621,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Choëx',
        PLZ: 1871,
        Zusatzziffer: 0,
        Gemeindename: 'Monthey',
        'BFS-Nr': 6153,
        Kantonskürzel: 'VS',
        E: 6.960992504581979,
        N: 46.24358690901245,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Les Giettes',
        PLZ: 1871,
        Zusatzziffer: 2,
        Gemeindename: 'Monthey',
        'BFS-Nr': 6153,
        Kantonskürzel: 'VS',
        E: 6.962374520441982,
        N: 46.22497216321493,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Bouveret',
        PLZ: 1897,
        Zusatzziffer: 0,
        Gemeindename: 'Port-Valais',
        'BFS-Nr': 6154,
        Kantonskürzel: 'VS',
        E: 6.8511892723358105,
        N: 46.384737924208956,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Les Evouettes',
        PLZ: 1897,
        Zusatzziffer: 3,
        Gemeindename: 'Port-Valais',
        'BFS-Nr': 6154,
        Kantonskürzel: 'VS',
        E: 6.876993876210577,
        N: 46.362477845560754,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'St-Gingolph',
        PLZ: 1898,
        Zusatzziffer: 0,
        Gemeindename: 'Saint-Gingolph',
        'BFS-Nr': 6155,
        Kantonskürzel: 'VS',
        E: 6.8040149644688475,
        N: 46.392595930301226,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Troistorrents',
        PLZ: 1872,
        Zusatzziffer: 0,
        Gemeindename: 'Troistorrents',
        'BFS-Nr': 6156,
        Kantonskürzel: 'VS',
        E: 6.919014793731514,
        N: 46.22976021631569,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Morgins',
        PLZ: 1875,
        Zusatzziffer: 0,
        Gemeindename: 'Troistorrents',
        'BFS-Nr': 6156,
        Kantonskürzel: 'VS',
        E: 6.857478412142252,
        N: 46.237225862052156,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Val-d\'Illiez',
        PLZ: 1873,
        Zusatzziffer: 0,
        Gemeindename: 'Val-d\'Illiez',
        'BFS-Nr': 6157,
        Kantonskürzel: 'VS',
        E: 6.893094753540793,
        N: 46.204449871426306,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Champoussin',
        PLZ: 1873,
        Zusatzziffer: 2,
        Gemeindename: 'Val-d\'Illiez',
        'BFS-Nr': 6157,
        Kantonskürzel: 'VS',
        E: 6.864185158766531,
        N: 46.209307580846826,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Les Crosets',
        PLZ: 1873,
        Zusatzziffer: 3,
        Gemeindename: 'Val-d\'Illiez',
        'BFS-Nr': 6157,
        Kantonskürzel: 'VS',
        E: 6.839315437798484,
        N: 46.185542007738476,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Vionnaz',
        PLZ: 1895,
        Zusatzziffer: 0,
        Gemeindename: 'Vionnaz',
        'BFS-Nr': 6158,
        Kantonskürzel: 'VS',
        E: 6.899649505473624,
        N: 46.30898120098612,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Torgon',
        PLZ: 1899,
        Zusatzziffer: 0,
        Gemeindename: 'Vionnaz',
        'BFS-Nr': 6158,
        Kantonskürzel: 'VS',
        E: 6.8764319154909215,
        N: 46.31976216027535,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Vouvry',
        PLZ: 1896,
        Zusatzziffer: 0,
        Gemeindename: 'Vouvry',
        'BFS-Nr': 6159,
        Kantonskürzel: 'VS',
        E: 6.886023203442387,
        N: 46.33542907107,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Miex',
        PLZ: 1896,
        Zusatzziffer: 2,
        Gemeindename: 'Vouvry',
        'BFS-Nr': 6159,
        Kantonskürzel: 'VS',
        E: 6.863656833888342,
        N: 46.33845551166295,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Bister',
        PLZ: 3983,
        Zusatzziffer: 2,
        Gemeindename: 'Bister',
        'BFS-Nr': 6172,
        Kantonskürzel: 'VS',
        E: 8.066105962098892,
        N: 46.36187853246685,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Bitsch',
        PLZ: 3982,
        Zusatzziffer: 0,
        Gemeindename: 'Bitsch',
        'BFS-Nr': 6173,
        Kantonskürzel: 'VS',
        E: 8.017237045291322,
        N: 46.3360033347179,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Grengiols',
        PLZ: 3993,
        Zusatzziffer: 0,
        Gemeindename: 'Grengiols',
        'BFS-Nr': 6177,
        Kantonskürzel: 'VS',
        E: 8.093490650118937,
        N: 46.3717829703639,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Goppisberg',
        PLZ: 3983,
        Zusatzziffer: 4,
        Gemeindename: 'Riederalp',
        'BFS-Nr': 6181,
        Kantonskürzel: 'VS',
        E: 8.050528968198622,
        N: 46.371523410975996,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Greich',
        PLZ: 3983,
        Zusatzziffer: 5,
        Gemeindename: 'Riederalp',
        'BFS-Nr': 6181,
        Kantonskürzel: 'VS',
        E: 8.03941857934939,
        N: 46.36645648891943,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Ried-Mörel',
        PLZ: 3986,
        Zusatzziffer: 0,
        Gemeindename: 'Riederalp',
        'BFS-Nr': 6181,
        Kantonskürzel: 'VS',
        E: 8.033368351238794,
        N: 46.356593446224565,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Riederalp',
        PLZ: 3987,
        Zusatzziffer: 0,
        Gemeindename: 'Riederalp',
        'BFS-Nr': 6181,
        Kantonskürzel: 'VS',
        E: 8.027722836023205,
        N: 46.377572351399316,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Ausserberg',
        PLZ: 3938,
        Zusatzziffer: 0,
        Gemeindename: 'Ausserberg',
        'BFS-Nr': 6191,
        Kantonskürzel: 'VS',
        E: 7.851100174477476,
        N: 46.31433512133436,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Blatten (Lötschen)',
        PLZ: 3919,
        Zusatzziffer: 0,
        Gemeindename: 'Blatten',
        'BFS-Nr': 6192,
        Kantonskürzel: 'VS',
        E: 7.81938094132358,
        N: 46.42044016431107,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Bürchen',
        PLZ: 3935,
        Zusatzziffer: 0,
        Gemeindename: 'Bürchen',
        'BFS-Nr': 6193,
        Kantonskürzel: 'VS',
        E: 7.8164280339851215,
        N: 46.28229821571667,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Eischoll',
        PLZ: 3943,
        Zusatzziffer: 0,
        Gemeindename: 'Eischoll',
        'BFS-Nr': 6194,
        Kantonskürzel: 'VS',
        E: 7.779264456060321,
        N: 46.29478191024971,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Ferden',
        PLZ: 3916,
        Zusatzziffer: 0,
        Gemeindename: 'Ferden',
        'BFS-Nr': 6195,
        Kantonskürzel: 'VS',
        E: 7.759402114970393,
        N: 46.39393770097724,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Goppenstein',
        PLZ: 3917,
        Zusatzziffer: 2,
        Gemeindename: 'Ferden',
        'BFS-Nr': 6195,
        Kantonskürzel: 'VS',
        E: 7.754931770588264,
        N: 46.368000497733846,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Kippel',
        PLZ: 3917,
        Zusatzziffer: 0,
        Gemeindename: 'Kippel',
        'BFS-Nr': 6197,
        Kantonskürzel: 'VS',
        E: 7.773366612573313,
        N: 46.39898810400208,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Niedergesteln',
        PLZ: 3942,
        Zusatzziffer: 2,
        Gemeindename: 'Niedergesteln',
        'BFS-Nr': 6198,
        Kantonskürzel: 'VS',
        E: 7.781780669737697,
        N: 46.31288224097408,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Raron',
        PLZ: 3942,
        Zusatzziffer: 0,
        Gemeindename: 'Raron',
        'BFS-Nr': 6199,
        Kantonskürzel: 'VS',
        E: 7.800127578711266,
        N: 46.31136131434034,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'St. German',
        PLZ: 3942,
        Zusatzziffer: 3,
        Gemeindename: 'Raron',
        'BFS-Nr': 6199,
        Kantonskürzel: 'VS',
        E: 7.8209303682506395,
        N: 46.31228628653356,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Unterbäch VS',
        PLZ: 3944,
        Zusatzziffer: 0,
        Gemeindename: 'Unterbäch',
        'BFS-Nr': 6201,
        Kantonskürzel: 'VS',
        E: 7.800292920774198,
        N: 46.284876989019445,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Wiler (Lötschen)',
        PLZ: 3918,
        Zusatzziffer: 0,
        Gemeindename: 'Wiler (Lötschen)',
        'BFS-Nr': 6202,
        Kantonskürzel: 'VS',
        E: 7.783561471129173,
        N: 46.40420582224776,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Mörel',
        PLZ: 3983,
        Zusatzziffer: 0,
        Gemeindename: 'Mörel-Filet',
        'BFS-Nr': 6203,
        Kantonskürzel: 'VS',
        E: 8.0446302528179,
        N: 46.35597415570504,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Filet',
        PLZ: 3983,
        Zusatzziffer: 3,
        Gemeindename: 'Mörel-Filet',
        'BFS-Nr': 6203,
        Kantonskürzel: 'VS',
        E: 8.052511578539768,
        N: 46.36107886224234,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Steg VS',
        PLZ: 3940,
        Zusatzziffer: 0,
        Gemeindename: 'Steg-Hohtenn',
        'BFS-Nr': 6204,
        Kantonskürzel: 'VS',
        E: 7.7469713998413,
        N: 46.314970293338625,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Hohtenn',
        PLZ: 3949,
        Zusatzziffer: 0,
        Gemeindename: 'Steg-Hohtenn',
        'BFS-Nr': 6204,
        Kantonskürzel: 'VS',
        E: 7.756349049694174,
        N: 46.318650824112844,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Betten',
        PLZ: 3991,
        Zusatzziffer: 0,
        Gemeindename: 'Bettmeralp',
        'BFS-Nr': 6205,
        Kantonskürzel: 'VS',
        E: 8.069449382400128,
        N: 46.37680504136696,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Bettmeralp',
        PLZ: 3992,
        Zusatzziffer: 0,
        Gemeindename: 'Bettmeralp',
        'BFS-Nr': 6205,
        Kantonskürzel: 'VS',
        E: 8.062584053315772,
        N: 46.39010166377326,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Martisberg',
        PLZ: 3994,
        Zusatzziffer: 2,
        Gemeindename: 'Bettmeralp',
        'BFS-Nr': 6205,
        Kantonskürzel: 'VS',
        E: 8.099969900898893,
        N: 46.38571065192288,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Collonges',
        PLZ: 1903,
        Zusatzziffer: 0,
        Gemeindename: 'Collonges',
        'BFS-Nr': 6211,
        Kantonskürzel: 'VS',
        E: 7.033932316278164,
        N: 46.17162530827555,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Dorénaz',
        PLZ: 1905,
        Zusatzziffer: 0,
        Gemeindename: 'Dorénaz',
        'BFS-Nr': 6212,
        Kantonskürzel: 'VS',
        E: 7.041856659106228,
        N: 46.148926289348175,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Evionnaz',
        PLZ: 1902,
        Zusatzziffer: 0,
        Gemeindename: 'Evionnaz',
        'BFS-Nr': 6213,
        Kantonskürzel: 'VS',
        E: 7.02358267236199,
        N: 46.178468007938214,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Finhaut',
        PLZ: 1925,
        Zusatzziffer: 0,
        Gemeindename: 'Finhaut',
        'BFS-Nr': 6214,
        Kantonskürzel: 'VS',
        E: 6.974126422326267,
        N: 46.082413834367046,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Le Châtelard VS',
        PLZ: 1925,
        Zusatzziffer: 1,
        Gemeindename: 'Finhaut',
        'BFS-Nr': 6214,
        Kantonskürzel: 'VS',
        E: 6.956749684428879,
        N: 46.05799243738604,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Massongex',
        PLZ: 1869,
        Zusatzziffer: 0,
        Gemeindename: 'Massongex',
        'BFS-Nr': 6215,
        Kantonskürzel: 'VS',
        E: 6.989587773639975,
        N: 46.24263881488182,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'St-Maurice',
        PLZ: 1890,
        Zusatzziffer: 0,
        Gemeindename: 'Saint-Maurice',
        'BFS-Nr': 6217,
        Kantonskürzel: 'VS',
        E: 7.003067199679312,
        N: 46.217365325282806,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Mex VS',
        PLZ: 1890,
        Zusatzziffer: 1,
        Gemeindename: 'Saint-Maurice',
        'BFS-Nr': 6217,
        Kantonskürzel: 'VS',
        E: 6.999566125073558,
        N: 46.184981232340135,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Salvan',
        PLZ: 1922,
        Zusatzziffer: 0,
        Gemeindename: 'Salvan',
        'BFS-Nr': 6218,
        Kantonskürzel: 'VS',
        E: 7.020941556268409,
        N: 46.120629750611776,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Les Granges (Salvan)',
        PLZ: 1922,
        Zusatzziffer: 1,
        Gemeindename: 'Salvan',
        'BFS-Nr': 6218,
        Kantonskürzel: 'VS',
        E: 7.021887669587512,
        N: 46.12800488760555,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Les Marécottes',
        PLZ: 1923,
        Zusatzziffer: 0,
        Gemeindename: 'Salvan',
        'BFS-Nr': 6218,
        Kantonskürzel: 'VS',
        E: 7.009081458025569,
        N: 46.11252358637176,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Le Trétien',
        PLZ: 1923,
        Zusatzziffer: 2,
        Gemeindename: 'Salvan',
        'BFS-Nr': 6218,
        Kantonskürzel: 'VS',
        E: 6.99844880859351,
        N: 46.1010012370918,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Vernayaz',
        PLZ: 1904,
        Zusatzziffer: 0,
        Gemeindename: 'Vernayaz',
        'BFS-Nr': 6219,
        Kantonskürzel: 'VS',
        E: 7.038748683179195,
        N: 46.13431014110594,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Vérossaz',
        PLZ: 1891,
        Zusatzziffer: 11,
        Gemeindename: 'Vérossaz',
        'BFS-Nr': 6220,
        Kantonskürzel: 'VS',
        E: 6.988392425251994,
        N: 46.210533572208085,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Chalais',
        PLZ: 3966,
        Zusatzziffer: 0,
        Gemeindename: 'Chalais',
        'BFS-Nr': 6232,
        Kantonskürzel: 'VS',
        E: 7.504636446474167,
        N: 46.26522927144051,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Réchy',
        PLZ: 3966,
        Zusatzziffer: 1,
        Gemeindename: 'Chalais',
        'BFS-Nr': 6232,
        Kantonskürzel: 'VS',
        E: 7.497179300959652,
        N: 46.26173653448263,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Vercorin',
        PLZ: 3967,
        Zusatzziffer: 0,
        Gemeindename: 'Chalais',
        'BFS-Nr': 6232,
        Kantonskürzel: 'VS',
        E: 7.533137481743038,
        N: 46.25748806683922,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Chippis',
        PLZ: 3965,
        Zusatzziffer: 0,
        Gemeindename: 'Chippis',
        'BFS-Nr': 6235,
        Kantonskürzel: 'VS',
        E: 7.5402407502140685,
        N: 46.28056876851519,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Grône',
        PLZ: 3979,
        Zusatzziffer: 0,
        Gemeindename: 'Grône',
        'BFS-Nr': 6238,
        Kantonskürzel: 'VS',
        E: 7.454334163635748,
        N: 46.251695014314585,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Icogne',
        PLZ: 1977,
        Zusatzziffer: 0,
        Gemeindename: 'Icogne',
        'BFS-Nr': 6239,
        Kantonskürzel: 'VS',
        E: 7.438547224598805,
        N: 46.29072702340443,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Lens',
        PLZ: 1978,
        Zusatzziffer: 0,
        Gemeindename: 'Lens',
        'BFS-Nr': 6240,
        Kantonskürzel: 'VS',
        E: 7.445402632812291,
        N: 46.27894474482045,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Flanthey',
        PLZ: 3978,
        Zusatzziffer: 0,
        Gemeindename: 'Lens',
        'BFS-Nr': 6240,
        Kantonskürzel: 'VS',
        E: 7.4539260768318645,
        N: 46.2698399562725,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'St-Léonard',
        PLZ: 1958,
        Zusatzziffer: 0,
        Gemeindename: 'Saint-Léonard',
        'BFS-Nr': 6246,
        Kantonskürzel: 'VS',
        E: 7.419764623344002,
        N: 46.2558951997907,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Sierre',
        PLZ: 3960,
        Zusatzziffer: 0,
        Gemeindename: 'Sierre',
        'BFS-Nr': 6248,
        Kantonskürzel: 'VS',
        E: 7.536354241222192,
        N: 46.29361153305985,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Muraz (Sierre)',
        PLZ: 3960,
        Zusatzziffer: 5,
        Gemeindename: 'Sierre',
        'BFS-Nr': 6248,
        Kantonskürzel: 'VS',
        E: 7.529721560284513,
        N: 46.297521304004455,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Noës',
        PLZ: 3976,
        Zusatzziffer: 0,
        Gemeindename: 'Sierre',
        'BFS-Nr': 6248,
        Kantonskürzel: 'VS',
        E: 7.506543144239929,
        N: 46.280144894290835,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Granges VS',
        PLZ: 3977,
        Zusatzziffer: 0,
        Gemeindename: 'Sierre',
        'BFS-Nr': 6248,
        Kantonskürzel: 'VS',
        E: 7.464568926911881,
        N: 46.259452186222305,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Niouc',
        PLZ: 3960,
        Zusatzziffer: 4,
        Gemeindename: 'Anniviers',
        'BFS-Nr': 6252,
        Kantonskürzel: 'VS',
        E: 7.552123697265674,
        N: 46.2709109109686,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Vissoie',
        PLZ: 3961,
        Zusatzziffer: 21,
        Gemeindename: 'Anniviers',
        'BFS-Nr': 6252,
        Kantonskürzel: 'VS',
        E: 7.58510852545964,
        N: 46.21527434812249,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'St-Luc',
        PLZ: 3961,
        Zusatzziffer: 22,
        Gemeindename: 'Anniviers',
        'BFS-Nr': 6252,
        Kantonskürzel: 'VS',
        E: 7.597769855935957,
        N: 46.220169789162625,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Chandolin',
        PLZ: 3961,
        Zusatzziffer: 23,
        Gemeindename: 'Anniviers',
        'BFS-Nr': 6252,
        Kantonskürzel: 'VS',
        E: 7.589870314294379,
        N: 46.25247510357885,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Ayer',
        PLZ: 3961,
        Zusatzziffer: 24,
        Gemeindename: 'Anniviers',
        'BFS-Nr': 6252,
        Kantonskürzel: 'VS',
        E: 7.6028847301072355,
        N: 46.17883128755313,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Zinal',
        PLZ: 3961,
        Zusatzziffer: 25,
        Gemeindename: 'Anniviers',
        'BFS-Nr': 6252,
        Kantonskürzel: 'VS',
        E: 7.626590678506308,
        N: 46.135840074916906,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Grimentz',
        PLZ: 3961,
        Zusatzziffer: 26,
        Gemeindename: 'Anniviers',
        'BFS-Nr': 6252,
        Kantonskürzel: 'VS',
        E: 7.576039971007117,
        N: 46.17898796815283,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Mission',
        PLZ: 3961,
        Zusatzziffer: 27,
        Gemeindename: 'Anniviers',
        'BFS-Nr': 6252,
        Kantonskürzel: 'VS',
        E: 7.594660450103585,
        N: 46.192547319334764,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'St-Jean VS',
        PLZ: 3961,
        Zusatzziffer: 41,
        Gemeindename: 'Anniviers',
        'BFS-Nr': 6252,
        Kantonskürzel: 'VS',
        E: 7.584903275621777,
        N: 46.19642747423346,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Corin-de-la-Crête',
        PLZ: 3960,
        Zusatzziffer: 2,
        Gemeindename: 'Crans-Montana',
        'BFS-Nr': 6253,
        Kantonskürzel: 'VS',
        E: 7.50283076251081,
        N: 46.28452269849179,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Loc',
        PLZ: 3960,
        Zusatzziffer: 3,
        Gemeindename: 'Crans-Montana',
        'BFS-Nr': 6253,
        Kantonskürzel: 'VS',
        E: 7.511143698207816,
        N: 46.29347900834189,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Crans-Montana',
        PLZ: 3963,
        Zusatzziffer: 0,
        Gemeindename: 'Crans-Montana',
        'BFS-Nr': 6253,
        Kantonskürzel: 'VS',
        E: 7.4816840654176895,
        N: 46.312724588858025,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Montana',
        PLZ: 3963,
        Zusatzziffer: 3,
        Gemeindename: 'Crans-Montana',
        'BFS-Nr': 6253,
        Kantonskürzel: 'VS',
        E: 7.489975029410551,
        N: 46.29740476819647,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Aminona',
        PLZ: 3963,
        Zusatzziffer: 4,
        Gemeindename: 'Crans-Montana',
        'BFS-Nr': 6253,
        Kantonskürzel: 'VS',
        E: 7.525688621949673,
        N: 46.33259278197487,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Chermignon',
        PLZ: 3971,
        Zusatzziffer: 0,
        Gemeindename: 'Crans-Montana',
        'BFS-Nr': 6253,
        Kantonskürzel: 'VS',
        E: 7.47474968125803,
        N: 46.28683573855481,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Chermignon-d\'en-Bas',
        PLZ: 3971,
        Zusatzziffer: 1,
        Gemeindename: 'Crans-Montana',
        'BFS-Nr': 6253,
        Kantonskürzel: 'VS',
        E: 7.482486927324828,
        N: 46.281325248999295,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Ollon VS',
        PLZ: 3971,
        Zusatzziffer: 2,
        Gemeindename: 'Crans-Montana',
        'BFS-Nr': 6253,
        Kantonskürzel: 'VS',
        E: 7.477438577383105,
        N: 46.27371218369514,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Mollens VS',
        PLZ: 3974,
        Zusatzziffer: 0,
        Gemeindename: 'Crans-Montana',
        'BFS-Nr': 6253,
        Kantonskürzel: 'VS',
        E: 7.520808918106462,
        N: 46.31641613403653,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Randogne',
        PLZ: 3975,
        Zusatzziffer: 0,
        Gemeindename: 'Crans-Montana',
        'BFS-Nr': 6253,
        Kantonskürzel: 'VS',
        E: 7.510795370064384,
        N: 46.31299966723151,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Champzabé',
        PLZ: 3976,
        Zusatzziffer: 2,
        Gemeindename: 'Crans-Montana',
        'BFS-Nr': 6253,
        Kantonskürzel: 'VS',
        E: 7.498270701595305,
        N: 46.27921410919743,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Veyras',
        PLZ: 3968,
        Zusatzziffer: 0,
        Gemeindename: 'Noble-Contrée',
        'BFS-Nr': 6254,
        Kantonskürzel: 'VS',
        E: 7.537032508249072,
        N: 46.30244701153277,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Miège',
        PLZ: 3972,
        Zusatzziffer: 0,
        Gemeindename: 'Noble-Contrée',
        'BFS-Nr': 6254,
        Kantonskürzel: 'VS',
        E: 7.547221323582261,
        N: 46.31213413527323,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Venthône',
        PLZ: 3973,
        Zusatzziffer: 0,
        Gemeindename: 'Noble-Contrée',
        'BFS-Nr': 6254,
        Kantonskürzel: 'VS',
        E: 7.531992201550575,
        N: 46.30774565357256,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Arbaz',
        PLZ: 1974,
        Zusatzziffer: 0,
        Gemeindename: 'Arbaz',
        'BFS-Nr': 6261,
        Kantonskürzel: 'VS',
        E: 7.383492449368395,
        N: 46.272265128567504,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Grimisuat',
        PLZ: 1971,
        Zusatzziffer: 0,
        Gemeindename: 'Grimisuat',
        'BFS-Nr': 6263,
        Kantonskürzel: 'VS',
        E: 7.385608034587634,
        N: 46.25991250191265,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Champlan (Grimisuat)',
        PLZ: 1971,
        Zusatzziffer: 2,
        Gemeindename: 'Grimisuat',
        'BFS-Nr': 6263,
        Kantonskürzel: 'VS',
        E: 7.374040337635154,
        N: 46.24691959673391,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Savièse',
        PLZ: 1965,
        Zusatzziffer: 0,
        Gemeindename: 'Savièse',
        'BFS-Nr': 6265,
        Kantonskürzel: 'VS',
        E: 7.349772612262194,
        N: 46.250772145202895,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Sion',
        PLZ: 1950,
        Zusatzziffer: 0,
        Gemeindename: 'Sion',
        'BFS-Nr': 6266,
        Kantonskürzel: 'VS',
        E: 7.361994270734104,
        N: 46.233426125102106,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Uvrier',
        PLZ: 1958,
        Zusatzziffer: 2,
        Gemeindename: 'Sion',
        'BFS-Nr': 6266,
        Kantonskürzel: 'VS',
        E: 7.412451825705523,
        N: 46.250753088118174,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Pont-de-la-Morge (Sion)',
        PLZ: 1962,
        Zusatzziffer: 0,
        Gemeindename: 'Sion',
        'BFS-Nr': 6266,
        Kantonskürzel: 'VS',
        E: 7.317123099967439,
        N: 46.22411991657043,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Bramois',
        PLZ: 1967,
        Zusatzziffer: 0,
        Gemeindename: 'Sion',
        'BFS-Nr': 6266,
        Kantonskürzel: 'VS',
        E: 7.403950886634168,
        N: 46.2307537103581,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Salins',
        PLZ: 1991,
        Zusatzziffer: 0,
        Gemeindename: 'Sion',
        'BFS-Nr': 6266,
        Kantonskürzel: 'VS',
        E: 7.357153924314344,
        N: 46.210600232096674,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Arvillard (Salins)',
        PLZ: 1991,
        Zusatzziffer: 2,
        Gemeindename: 'Sion',
        'BFS-Nr': 6266,
        Kantonskürzel: 'VS',
        E: 7.345930020287275,
        N: 46.210997048896594,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Pravidondaz (Salins)',
        PLZ: 1991,
        Zusatzziffer: 3,
        Gemeindename: 'Sion',
        'BFS-Nr': 6266,
        Kantonskürzel: 'VS',
        E: 7.354252873472855,
        N: 46.211948946012086,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Turin (Salins)',
        PLZ: 1991,
        Zusatzziffer: 4,
        Gemeindename: 'Sion',
        'BFS-Nr': 6266,
        Kantonskürzel: 'VS',
        E: 7.354082630706126,
        N: 46.215506926435395,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Misériez (Salins)',
        PLZ: 1991,
        Zusatzziffer: 5,
        Gemeindename: 'Sion',
        'BFS-Nr': 6266,
        Kantonskürzel: 'VS',
        E: 7.345483451105422,
        N: 46.20714533946851,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Les Agettes',
        PLZ: 1992,
        Zusatzziffer: 0,
        Gemeindename: 'Sion',
        'BFS-Nr': 6266,
        Kantonskürzel: 'VS',
        E: 7.3791065044316895,
        N: 46.20818489697178,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'La Vernaz (Les Agettes)',
        PLZ: 1992,
        Zusatzziffer: 2,
        Gemeindename: 'Sion',
        'BFS-Nr': 6266,
        Kantonskürzel: 'VS',
        E: 7.371644021098981,
        N: 46.21041140836868,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Crête-à-l\'Oeil (Les Agettes)',
        PLZ: 1992,
        Zusatzziffer: 3,
        Gemeindename: 'Sion',
        'BFS-Nr': 6266,
        Kantonskürzel: 'VS',
        E: 7.370318380621426,
        N: 46.211326399527664,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Veysonnaz',
        PLZ: 1993,
        Zusatzziffer: 0,
        Gemeindename: 'Veysonnaz',
        'BFS-Nr': 6267,
        Kantonskürzel: 'VS',
        E: 7.33602387813207,
        N: 46.196065302684126,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Baltschieder',
        PLZ: 3937,
        Zusatzziffer: 0,
        Gemeindename: 'Baltschieder',
        'BFS-Nr': 6281,
        Kantonskürzel: 'VS',
        E: 7.881133662269449,
        N: 46.37593645951681,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Baltschieder',
        PLZ: 3937,
        Zusatzziffer: 0,
        Gemeindename: 'Baltschieder',
        'BFS-Nr': 6281,
        Kantonskürzel: 'VS',
        E: 7.864849657454506,
        N: 46.309447005326405,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Eisten',
        PLZ: 3922,
        Zusatzziffer: 2,
        Gemeindename: 'Eisten',
        'BFS-Nr': 6282,
        Kantonskürzel: 'VS',
        E: 7.893316238028276,
        N: 46.20043729760482,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Kalpetran',
        PLZ: 3922,
        Zusatzziffer: 1,
        Gemeindename: 'Embd',
        'BFS-Nr': 6283,
        Kantonskürzel: 'VS',
        E: 7.837919673092075,
        N: 46.21417810663681,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Embd',
        PLZ: 3926,
        Zusatzziffer: 0,
        Gemeindename: 'Embd',
        'BFS-Nr': 6283,
        Kantonskürzel: 'VS',
        E: 7.828290710921618,
        N: 46.21498772049198,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Grächen',
        PLZ: 3925,
        Zusatzziffer: 0,
        Gemeindename: 'Grächen',
        'BFS-Nr': 6285,
        Kantonskürzel: 'VS',
        E: 7.8384302989024075,
        N: 46.19526981458621,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Lalden',
        PLZ: 3931,
        Zusatzziffer: 1,
        Gemeindename: 'Lalden',
        'BFS-Nr': 6286,
        Kantonskürzel: 'VS',
        E: 7.904554773660758,
        N: 46.299024219002895,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Randa',
        PLZ: 3928,
        Zusatzziffer: 0,
        Gemeindename: 'Randa',
        'BFS-Nr': 6287,
        Kantonskürzel: 'VS',
        E: 7.784353759299935,
        N: 46.10115186542124,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Saas-Almagell',
        PLZ: 3905,
        Zusatzziffer: 0,
        Gemeindename: 'Saas-Almagell',
        'BFS-Nr': 6288,
        Kantonskürzel: 'VS',
        E: 7.959007694947645,
        N: 46.09372090614614,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Saas-Balen',
        PLZ: 3908,
        Zusatzziffer: 0,
        Gemeindename: 'Saas-Balen',
        'BFS-Nr': 6289,
        Kantonskürzel: 'VS',
        E: 7.927514425972606,
        N: 46.15417396119757,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Saas-Fee',
        PLZ: 3906,
        Zusatzziffer: 0,
        Gemeindename: 'Saas-Fee',
        'BFS-Nr': 6290,
        Kantonskürzel: 'VS',
        E: 7.924743754376784,
        N: 46.107189237629285,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Saas-Grund',
        PLZ: 3910,
        Zusatzziffer: 0,
        Gemeindename: 'Saas-Grund',
        'BFS-Nr': 6291,
        Kantonskürzel: 'VS',
        E: 7.936774249177611,
        N: 46.124045160518286,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'St. Niklaus VS',
        PLZ: 3924,
        Zusatzziffer: 0,
        Gemeindename: 'St. Niklaus',
        'BFS-Nr': 6292,
        Kantonskürzel: 'VS',
        E: 7.803094808254255,
        N: 46.17774484783004,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Herbriggen',
        PLZ: 3927,
        Zusatzziffer: 0,
        Gemeindename: 'St. Niklaus',
        'BFS-Nr': 6292,
        Kantonskürzel: 'VS',
        E: 7.792348738489756,
        N: 46.13541984639024,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Stalden VS',
        PLZ: 3922,
        Zusatzziffer: 0,
        Gemeindename: 'Stalden (VS)',
        'BFS-Nr': 6293,
        Kantonskürzel: 'VS',
        E: 7.8712100952703565,
        N: 46.234452159122114,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Staldenried',
        PLZ: 3933,
        Zusatzziffer: 0,
        Gemeindename: 'Staldenried',
        'BFS-Nr': 6294,
        Kantonskürzel: 'VS',
        E: 7.887666364082242,
        N: 46.22936072195691,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Täsch',
        PLZ: 3929,
        Zusatzziffer: 0,
        Gemeindename: 'Täsch',
        'BFS-Nr': 6295,
        Kantonskürzel: 'VS',
        E: 7.778250136009598,
        N: 46.06707994042137,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Törbel',
        PLZ: 3923,
        Zusatzziffer: 0,
        Gemeindename: 'Törbel',
        'BFS-Nr': 6296,
        Kantonskürzel: 'VS',
        E: 7.852639380745757,
        N: 46.236881741273265,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Visp',
        PLZ: 3930,
        Zusatzziffer: 0,
        Gemeindename: 'Visp',
        'BFS-Nr': 6297,
        Kantonskürzel: 'VS',
        E: 7.88118186519991,
        N: 46.29039599350188,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Eyholz',
        PLZ: 3930,
        Zusatzziffer: 2,
        Gemeindename: 'Visp',
        'BFS-Nr': 6297,
        Kantonskürzel: 'VS',
        E: 7.909022462351266,
        N: 46.29421641024678,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Visperterminen',
        PLZ: 3932,
        Zusatzziffer: 0,
        Gemeindename: 'Visperterminen',
        'BFS-Nr': 6298,
        Kantonskürzel: 'VS',
        E: 7.900793818262789,
        N: 46.25810236257733,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Zeneggen',
        PLZ: 3934,
        Zusatzziffer: 0,
        Gemeindename: 'Zeneggen',
        'BFS-Nr': 6299,
        Kantonskürzel: 'VS',
        E: 7.866809984444686,
        N: 46.27300779450084,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Zermatt',
        PLZ: 3920,
        Zusatzziffer: 0,
        Gemeindename: 'Zermatt',
        'BFS-Nr': 6300,
        Kantonskürzel: 'VS',
        E: 7.746746618727868,
        N: 46.01951639686414,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Areuse',
        PLZ: 2015,
        Zusatzziffer: 0,
        Gemeindename: 'Boudry',
        'BFS-Nr': 6404,
        Kantonskürzel: 'NE',
        E: 6.856789352338133,
        N: 46.95370431074801,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Boudry',
        PLZ: 2017,
        Zusatzziffer: 0,
        Gemeindename: 'Boudry',
        'BFS-Nr': 6404,
        Kantonskürzel: 'NE',
        E: 6.837102849577027,
        N: 46.9514220994199,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Cortaillod',
        PLZ: 2016,
        Zusatzziffer: 0,
        Gemeindename: 'Cortaillod',
        'BFS-Nr': 6408,
        Kantonskürzel: 'NE',
        E: 6.846326435333638,
        N: 46.94293092751713,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Rochefort',
        PLZ: 2019,
        Zusatzziffer: 0,
        Gemeindename: 'Rochefort',
        'BFS-Nr': 6413,
        Kantonskürzel: 'NE',
        E: 6.809946374084252,
        N: 46.97767568092768,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Chambrelien',
        PLZ: 2019,
        Zusatzziffer: 2,
        Gemeindename: 'Rochefort',
        'BFS-Nr': 6413,
        Kantonskürzel: 'NE',
        E: 6.812346528161686,
        N: 46.96734960699619,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Montezillon',
        PLZ: 2037,
        Zusatzziffer: 2,
        Gemeindename: 'Rochefort',
        'BFS-Nr': 6413,
        Kantonskürzel: 'NE',
        E: 6.837155924200875,
        N: 46.9866257552725,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Champ-du-Moulin',
        PLZ: 2149,
        Zusatzziffer: 0,
        Gemeindename: 'Rochefort',
        'BFS-Nr': 6413,
        Kantonskürzel: 'NE',
        E: 6.774616365004458,
        N: 46.95989575998343,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Fretereules',
        PLZ: 2149,
        Zusatzziffer: 2,
        Gemeindename: 'Rochefort',
        'BFS-Nr': 6413,
        Kantonskürzel: 'NE',
        E: 6.766165199211868,
        N: 46.96184400075461,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Brot-Dessous',
        PLZ: 2149,
        Zusatzziffer: 3,
        Gemeindename: 'Rochefort',
        'BFS-Nr': 6413,
        Kantonskürzel: 'NE',
        E: 6.747224753566692,
        N: 46.955701995127,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Auvernier',
        PLZ: 2012,
        Zusatzziffer: 0,
        Gemeindename: 'Milvignes',
        'BFS-Nr': 6416,
        Kantonskürzel: 'NE',
        E: 6.8772365568116545,
        N: 46.97778107757657,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Colombier NE',
        PLZ: 2013,
        Zusatzziffer: 0,
        Gemeindename: 'Milvignes',
        'BFS-Nr': 6416,
        Kantonskürzel: 'NE',
        E: 6.862026751351321,
        N: 46.966345485570734,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Bôle',
        PLZ: 2014,
        Zusatzziffer: 0,
        Gemeindename: 'Milvignes',
        'BFS-Nr': 6416,
        Kantonskürzel: 'NE',
        E: 6.840085874897392,
        N: 46.96617362807175,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Bevaix',
        PLZ: 2022,
        Zusatzziffer: 0,
        Gemeindename: 'La Grande Béroche',
        'BFS-Nr': 6417,
        Kantonskürzel: 'NE',
        E: 6.8156305434250575,
        N: 46.93081206225285,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Gorgier',
        PLZ: 2023,
        Zusatzziffer: 0,
        Gemeindename: 'La Grande Béroche',
        'BFS-Nr': 6417,
        Kantonskürzel: 'NE',
        E: 6.78052829908529,
        N: 46.90316477152239,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'St-Aubin-Sauges',
        PLZ: 2024,
        Zusatzziffer: 0,
        Gemeindename: 'La Grande Béroche',
        'BFS-Nr': 6417,
        Kantonskürzel: 'NE',
        E: 6.773619731859829,
        N: 46.895575899749524,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Chez-le-Bart',
        PLZ: 2025,
        Zusatzziffer: 0,
        Gemeindename: 'La Grande Béroche',
        'BFS-Nr': 6417,
        Kantonskürzel: 'NE',
        E: 6.7858190918434085,
        N: 46.90123359634231,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Montalchez',
        PLZ: 2027,
        Zusatzziffer: 2,
        Gemeindename: 'La Grande Béroche',
        'BFS-Nr': 6417,
        Kantonskürzel: 'NE',
        E: 6.745160955987346,
        N: 46.89744715406812,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Fresens',
        PLZ: 2027,
        Zusatzziffer: 3,
        Gemeindename: 'La Grande Béroche',
        'BFS-Nr': 6417,
        Kantonskürzel: 'NE',
        E: 6.745919449400579,
        N: 46.88863362125067,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Vaumarcus',
        PLZ: 2028,
        Zusatzziffer: 0,
        Gemeindename: 'La Grande Béroche',
        'BFS-Nr': 6417,
        Kantonskürzel: 'NE',
        E: 6.755118170879989,
        N: 46.87866125886799,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'La Chaux-de-Fonds',
        PLZ: 2300,
        Zusatzziffer: 0,
        Gemeindename: 'La Chaux-de-Fonds',
        'BFS-Nr': 6421,
        Kantonskürzel: 'NE',
        E: 6.833091498877364,
        N: 47.10412513412177,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'La Cibourg',
        PLZ: 2300,
        Zusatzziffer: 8,
        Gemeindename: 'La Chaux-de-Fonds',
        'BFS-Nr': 6421,
        Kantonskürzel: 'NE',
        E: 6.87583677504266,
        N: 47.11652301191079,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Le Crêt-du-Locle',
        PLZ: 2322,
        Zusatzziffer: 0,
        Gemeindename: 'La Chaux-de-Fonds',
        'BFS-Nr': 6421,
        Kantonskürzel: 'NE',
        E: 6.784502526822367,
        N: 47.07726576074009,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'La Cibourg',
        PLZ: 2333,
        Zusatzziffer: 2,
        Gemeindename: 'La Ferrière',
        'BFS-Nr': 435,
        Kantonskürzel: 'BE',
        E: 6.889950730651166,
        N: 47.12523149830889,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'La Cibourg',
        PLZ: 2616,
        Zusatzziffer: 2,
        Gemeindename: 'Renan (BE)',
        'BFS-Nr': 441,
        Kantonskürzel: 'BE',
        E: 6.893268598685557,
        N: 47.118307229373485,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Les Planchettes',
        PLZ: 2325,
        Zusatzziffer: 0,
        Gemeindename: 'Les Planchettes',
        'BFS-Nr': 6422,
        Kantonskürzel: 'NE',
        E: 6.770941264341842,
        N: 47.1062439405962,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'La Sagne NE',
        PLZ: 2314,
        Zusatzziffer: 0,
        Gemeindename: 'La Sagne',
        'BFS-Nr': 6423,
        Kantonskürzel: 'NE',
        E: 6.799659384729483,
        N: 47.03937351467349,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'La Brévine',
        PLZ: 2406,
        Zusatzziffer: 0,
        Gemeindename: 'La Brévine',
        'BFS-Nr': 6432,
        Kantonskürzel: 'NE',
        E: 6.607601204115739,
        N: 46.97982674900652,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Les Taillères',
        PLZ: 2406,
        Zusatzziffer: 2,
        Gemeindename: 'La Brévine',
        'BFS-Nr': 6432,
        Kantonskürzel: 'NE',
        E: 6.576150518103901,
        N: 46.9706479658018,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'La Châtagne',
        PLZ: 2406,
        Zusatzziffer: 3,
        Gemeindename: 'La Brévine',
        'BFS-Nr': 6432,
        Kantonskürzel: 'NE',
        E: 6.641798558379648,
        N: 46.98727557954173,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Le Brouillet',
        PLZ: 2406,
        Zusatzziffer: 4,
        Gemeindename: 'La Brévine',
        'BFS-Nr': 6432,
        Kantonskürzel: 'NE',
        E: 6.534768451709043,
        N: 46.950452224802596,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Brot-Plamboz',
        PLZ: 2318,
        Zusatzziffer: 0,
        Gemeindename: 'Brot-Plamboz',
        'BFS-Nr': 6433,
        Kantonskürzel: 'NE',
        E: 6.745999442640737,
        N: 46.97922482756201,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Le Cerneux-Péquignot',
        PLZ: 2414,
        Zusatzziffer: 0,
        Gemeindename: 'Le Cerneux-Péquignot',
        'BFS-Nr': 6434,
        Kantonskürzel: 'NE',
        E: 6.66499687377727,
        N: 47.016941121424566,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'La Chaux-du-Milieu',
        PLZ: 2405,
        Zusatzziffer: 0,
        Gemeindename: 'La Chaux-du-Milieu',
        'BFS-Nr': 6435,
        Kantonskürzel: 'NE',
        E: 6.7019602566584515,
        N: 47.01355496466809,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Le Locle',
        PLZ: 2400,
        Zusatzziffer: 0,
        Gemeindename: 'Le Locle',
        'BFS-Nr': 6436,
        Kantonskürzel: 'NE',
        E: 6.749502061696968,
        N: 47.058153665713554,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Le Prévoux',
        PLZ: 2400,
        Zusatzziffer: 2,
        Gemeindename: 'Le Locle',
        'BFS-Nr': 6436,
        Kantonskürzel: 'NE',
        E: 6.702029898411897,
        N: 47.03790468204061,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Les Brenets',
        PLZ: 2416,
        Zusatzziffer: 0,
        Gemeindename: 'Le Locle',
        'BFS-Nr': 6436,
        Kantonskürzel: 'NE',
        E: 6.705492703718813,
        N: 47.06758821754893,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Les Ponts-de-Martel',
        PLZ: 2316,
        Zusatzziffer: 0,
        Gemeindename: 'Les Ponts-de-Martel',
        'BFS-Nr': 6437,
        Kantonskürzel: 'NE',
        E: 6.73159300216057,
        N: 46.999745184434005,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Petit-Martel',
        PLZ: 2316,
        Zusatzziffer: 2,
        Gemeindename: 'Les Ponts-de-Martel',
        'BFS-Nr': 6437,
        Kantonskürzel: 'NE',
        E: 6.751830076857996,
        N: 47.010545292132704,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Cornaux NE',
        PLZ: 2087,
        Zusatzziffer: 0,
        Gemeindename: 'Cornaux',
        'BFS-Nr': 6451,
        Kantonskürzel: 'NE',
        E: 7.020990580441512,
        N: 47.03794065192663,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Cressier NE',
        PLZ: 2088,
        Zusatzziffer: 0,
        Gemeindename: 'Cressier (NE)',
        'BFS-Nr': 6452,
        Kantonskürzel: 'NE',
        E: 7.035685165438432,
        N: 47.050984599988865,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Enges',
        PLZ: 2073,
        Zusatzziffer: 0,
        Gemeindename: 'Enges',
        'BFS-Nr': 6453,
        Kantonskürzel: 'NE',
        E: 7.012308607764392,
        N: 47.05646446566596,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Hauterive NE',
        PLZ: 2068,
        Zusatzziffer: 0,
        Gemeindename: 'Hauterive (NE)',
        'BFS-Nr': 6454,
        Kantonskürzel: 'NE',
        E: 6.971680995615762,
        N: 47.01359518083029,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Le Landeron',
        PLZ: 2525,
        Zusatzziffer: 0,
        Gemeindename: 'Le Landeron',
        'BFS-Nr': 6455,
        Kantonskürzel: 'NE',
        E: 7.065008238063487,
        N: 47.05364598476162,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Lignières',
        PLZ: 2523,
        Zusatzziffer: 0,
        Gemeindename: 'Lignières',
        'BFS-Nr': 6456,
        Kantonskürzel: 'NE',
        E: 7.065398697080611,
        N: 47.08077843731001,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Neuchâtel',
        PLZ: 2000,
        Zusatzziffer: 0,
        Gemeindename: 'Neuchâtel',
        'BFS-Nr': 6458,
        Kantonskürzel: 'NE',
        E: 6.926355820731355,
        N: 46.99204504135111,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Peseux',
        PLZ: 2034,
        Zusatzziffer: 0,
        Gemeindename: 'Neuchâtel',
        'BFS-Nr': 6458,
        Kantonskürzel: 'NE',
        E: 6.888644590250457,
        N: 46.9873062325755,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Corcelles NE',
        PLZ: 2035,
        Zusatzziffer: 0,
        Gemeindename: 'Neuchâtel',
        'BFS-Nr': 6458,
        Kantonskürzel: 'NE',
        E: 6.876882330734246,
        N: 46.98560403560769,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Cormondrèche',
        PLZ: 2036,
        Zusatzziffer: 0,
        Gemeindename: 'Neuchâtel',
        'BFS-Nr': 6458,
        Kantonskürzel: 'NE',
        E: 6.869734466807548,
        N: 46.98060296363547,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Valangin',
        PLZ: 2042,
        Zusatzziffer: 0,
        Gemeindename: 'Neuchâtel',
        'BFS-Nr': 6458,
        Kantonskürzel: 'NE',
        E: 6.9070609579516615,
        N: 47.01616430092844,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Chaumont',
        PLZ: 2067,
        Zusatzziffer: 0,
        Gemeindename: 'Neuchâtel',
        'BFS-Nr': 6458,
        Kantonskürzel: 'NE',
        E: 6.956490855691593,
        N: 47.02839612214464,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'St-Blaise',
        PLZ: 2072,
        Zusatzziffer: 0,
        Gemeindename: 'Saint-Blaise',
        'BFS-Nr': 6459,
        Kantonskürzel: 'NE',
        E: 6.986882187100492,
        N: 47.01342491007282,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Marin-Epagnier',
        PLZ: 2074,
        Zusatzziffer: 0,
        Gemeindename: 'La Tène',
        'BFS-Nr': 6461,
        Kantonskürzel: 'NE',
        E: 7.001169593228425,
        N: 47.009267717056304,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Thielle',
        PLZ: 2075,
        Zusatzziffer: 1,
        Gemeindename: 'La Tène',
        'BFS-Nr': 6461,
        Kantonskürzel: 'NE',
        E: 7.029667334222878,
        N: 47.021172162576036,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Wavre',
        PLZ: 2075,
        Zusatzziffer: 2,
        Gemeindename: 'La Tène',
        'BFS-Nr': 6461,
        Kantonskürzel: 'NE',
        E: 7.022723569124159,
        N: 47.02709261208209,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Montmollin',
        PLZ: 2037,
        Zusatzziffer: 0,
        Gemeindename: 'Val-de-Ruz',
        'BFS-Nr': 6487,
        Kantonskürzel: 'NE',
        E: 6.846902255039954,
        N: 46.99306177646468,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Boudevilliers',
        PLZ: 2043,
        Zusatzziffer: 0,
        Gemeindename: 'Val-de-Ruz',
        'BFS-Nr': 6487,
        Kantonskürzel: 'NE',
        E: 6.889987927179504,
        N: 47.028494442050444,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Fontaines NE',
        PLZ: 2046,
        Zusatzziffer: 0,
        Gemeindename: 'Val-de-Ruz',
        'BFS-Nr': 6487,
        Kantonskürzel: 'NE',
        E: 6.900967406225793,
        N: 47.04370612025811,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Fontainemelon',
        PLZ: 2052,
        Zusatzziffer: 0,
        Gemeindename: 'Val-de-Ruz',
        'BFS-Nr': 6487,
        Kantonskürzel: 'NE',
        E: 6.887373157996488,
        N: 47.05573809103602,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'La Vue-des-Alpes',
        PLZ: 2052,
        Zusatzziffer: 2,
        Gemeindename: 'Val-de-Ruz',
        'BFS-Nr': 6487,
        Kantonskürzel: 'NE',
        E: 6.869655559649039,
        N: 47.072858955356246,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Cernier',
        PLZ: 2053,
        Zusatzziffer: 0,
        Gemeindename: 'Val-de-Ruz',
        'BFS-Nr': 6487,
        Kantonskürzel: 'NE',
        E: 6.904561202810044,
        N: 47.06145647634551,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Chézard-St-Martin',
        PLZ: 2054,
        Zusatzziffer: 0,
        Gemeindename: 'Val-de-Ruz',
        'BFS-Nr': 6487,
        Kantonskürzel: 'NE',
        E: 6.933175682401243,
        N: 47.06648500093366,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Les Vieux-Prés',
        PLZ: 2054,
        Zusatzziffer: 2,
        Gemeindename: 'Val-de-Ruz',
        'BFS-Nr': 6487,
        Kantonskürzel: 'NE',
        E: 6.936452344577276,
        N: 47.0816067460657,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Dombresson',
        PLZ: 2056,
        Zusatzziffer: 0,
        Gemeindename: 'Val-de-Ruz',
        'BFS-Nr': 6487,
        Kantonskürzel: 'NE',
        E: 6.960566200282867,
        N: 47.07278479111374,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Villiers',
        PLZ: 2057,
        Zusatzziffer: 0,
        Gemeindename: 'Val-de-Ruz',
        'BFS-Nr': 6487,
        Kantonskürzel: 'NE',
        E: 6.971559400610356,
        N: 47.07465795092898,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Le Pâquier NE',
        PLZ: 2058,
        Zusatzziffer: 0,
        Gemeindename: 'Val-de-Ruz',
        'BFS-Nr': 6487,
        Kantonskürzel: 'NE',
        E: 6.986810696243151,
        N: 47.098351087402676,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Vilars NE',
        PLZ: 2063,
        Zusatzziffer: 0,
        Gemeindename: 'Val-de-Ruz',
        'BFS-Nr': 6487,
        Kantonskürzel: 'NE',
        E: 6.927209073242388,
        N: 47.0318531080027,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Engollon',
        PLZ: 2063,
        Zusatzziffer: 2,
        Gemeindename: 'Val-de-Ruz',
        'BFS-Nr': 6487,
        Kantonskürzel: 'NE',
        E: 6.921352944910493,
        N: 47.03801542557807,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Fenin',
        PLZ: 2063,
        Zusatzziffer: 3,
        Gemeindename: 'Val-de-Ruz',
        'BFS-Nr': 6487,
        Kantonskürzel: 'NE',
        E: 6.922901526289012,
        N: 47.0240658890955,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Saules',
        PLZ: 2063,
        Zusatzziffer: 4,
        Gemeindename: 'Val-de-Ruz',
        'BFS-Nr': 6487,
        Kantonskürzel: 'NE',
        E: 6.939018435225475,
        N: 47.03838041684588,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Savagnier',
        PLZ: 2065,
        Zusatzziffer: 0,
        Gemeindename: 'Val-de-Ruz',
        'BFS-Nr': 6487,
        Kantonskürzel: 'NE',
        E: 6.953389344368498,
        N: 47.047375047858736,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Les Geneveys-sur-Coffrane',
        PLZ: 2206,
        Zusatzziffer: 0,
        Gemeindename: 'Val-de-Ruz',
        'BFS-Nr': 6487,
        Kantonskürzel: 'NE',
        E: 6.846607710474101,
        N: 47.01276720357482,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Coffrane',
        PLZ: 2207,
        Zusatzziffer: 0,
        Gemeindename: 'Val-de-Ruz',
        'BFS-Nr': 6487,
        Kantonskürzel: 'NE',
        E: 6.862596358846015,
        N: 47.01024766896023,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Les Hauts-Geneveys',
        PLZ: 2208,
        Zusatzziffer: 0,
        Gemeindename: 'Val-de-Ruz',
        'BFS-Nr': 6487,
        Kantonskürzel: 'NE',
        E: 6.873628261147986,
        N: 47.04837429988661,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'La Côte-aux-Fées',
        PLZ: 2117,
        Zusatzziffer: 0,
        Gemeindename: 'La Côte-aux-Fées',
        'BFS-Nr': 6504,
        Kantonskürzel: 'NE',
        E: 6.489548597174979,
        N: 46.866969311561554,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Les Verrières',
        PLZ: 2126,
        Zusatzziffer: 0,
        Gemeindename: 'Les Verrières',
        'BFS-Nr': 6511,
        Kantonskürzel: 'NE',
        E: 6.478999503894747,
        N: 46.905074668237916,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Noiraigue',
        PLZ: 2103,
        Zusatzziffer: 0,
        Gemeindename: 'Val-de-Travers',
        'BFS-Nr': 6512,
        Kantonskürzel: 'NE',
        E: 6.722967061707644,
        N: 46.95605554577179,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Travers',
        PLZ: 2105,
        Zusatzziffer: 0,
        Gemeindename: 'Val-de-Travers',
        'BFS-Nr': 6512,
        Kantonskürzel: 'NE',
        E: 6.674701881164737,
        N: 46.94087475642971,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Couvet',
        PLZ: 2108,
        Zusatzziffer: 0,
        Gemeindename: 'Val-de-Travers',
        'BFS-Nr': 6512,
        Kantonskürzel: 'NE',
        E: 6.632553337961208,
        N: 46.925002013906806,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Môtiers NE',
        PLZ: 2112,
        Zusatzziffer: 0,
        Gemeindename: 'Val-de-Travers',
        'BFS-Nr': 6512,
        Kantonskürzel: 'NE',
        E: 6.611545970122608,
        N: 46.9119034057072,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Boveresse',
        PLZ: 2113,
        Zusatzziffer: 0,
        Gemeindename: 'Val-de-Travers',
        'BFS-Nr': 6512,
        Kantonskürzel: 'NE',
        E: 6.600723918105412,
        N: 46.91643085672277,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Fleurier',
        PLZ: 2114,
        Zusatzziffer: 0,
        Gemeindename: 'Val-de-Travers',
        'BFS-Nr': 6512,
        Kantonskürzel: 'NE',
        E: 6.58184606354764,
        N: 46.901340515408684,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Buttes',
        PLZ: 2115,
        Zusatzziffer: 0,
        Gemeindename: 'Val-de-Travers',
        'BFS-Nr': 6512,
        Kantonskürzel: 'NE',
        E: 6.5520747660689125,
        N: 46.88954239329343,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Mont-de-Buttes',
        PLZ: 2116,
        Zusatzziffer: 0,
        Gemeindename: 'Val-de-Travers',
        'BFS-Nr': 6512,
        Kantonskürzel: 'NE',
        E: 6.509184624842392,
        N: 46.87723472031467,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'St-Sulpice NE',
        PLZ: 2123,
        Zusatzziffer: 0,
        Gemeindename: 'Val-de-Travers',
        'BFS-Nr': 6512,
        Kantonskürzel: 'NE',
        E: 6.562132250127077,
        N: 46.91109271465624,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Les Sagnettes',
        PLZ: 2124,
        Zusatzziffer: 0,
        Gemeindename: 'Val-de-Travers',
        'BFS-Nr': 6512,
        Kantonskürzel: 'NE',
        E: 6.598673089626151,
        N: 46.93913219135872,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Les Bayards',
        PLZ: 2127,
        Zusatzziffer: 0,
        Gemeindename: 'Val-de-Travers',
        'BFS-Nr': 6512,
        Kantonskürzel: 'NE',
        E: 6.520135531983882,
        N: 46.91683861376518,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Aire-la-Ville',
        PLZ: 1288,
        Zusatzziffer: 0,
        Gemeindename: 'Aire-la-Ville',
        'BFS-Nr': 6601,
        Kantonskürzel: 'GE',
        E: 6.043391790815784,
        N: 46.190836678046296,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Anières',
        PLZ: 1247,
        Zusatzziffer: 0,
        Gemeindename: 'Anières',
        'BFS-Nr': 6602,
        Kantonskürzel: 'GE',
        E: 6.2229046641382775,
        N: 46.2762209494312,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Avully',
        PLZ: 1237,
        Zusatzziffer: 0,
        Gemeindename: 'Avully',
        'BFS-Nr': 6603,
        Kantonskürzel: 'GE',
        E: 5.9999549020316945,
        N: 46.16946844205166,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Athenaz (Avusy)',
        PLZ: 1285,
        Zusatzziffer: 0,
        Gemeindename: 'Avusy',
        'BFS-Nr': 6604,
        Kantonskürzel: 'GE',
        E: 6.007261230642001,
        N: 46.15468669252625,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'La Croix-de-Rozon',
        PLZ: 1257,
        Zusatzziffer: 0,
        Gemeindename: 'Bardonnex',
        'BFS-Nr': 6605,
        Kantonskürzel: 'GE',
        E: 6.136397574163895,
        N: 46.1452051972968,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Bellevue',
        PLZ: 1293,
        Zusatzziffer: 0,
        Gemeindename: 'Bellevue',
        'BFS-Nr': 6606,
        Kantonskürzel: 'GE',
        E: 6.153296100433153,
        N: 46.25376841195622,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Bernex',
        PLZ: 1233,
        Zusatzziffer: 0,
        Gemeindename: 'Bernex',
        'BFS-Nr': 6607,
        Kantonskürzel: 'GE',
        E: 6.069660358993669,
        N: 46.173708396702324,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Carouge GE',
        PLZ: 1227,
        Zusatzziffer: 0,
        Gemeindename: 'Carouge (GE)',
        'BFS-Nr': 6608,
        Kantonskürzel: 'GE',
        E: 6.139548921001113,
        N: 46.18498444272909,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Cartigny',
        PLZ: 1236,
        Zusatzziffer: 0,
        Gemeindename: 'Cartigny',
        'BFS-Nr': 6609,
        Kantonskürzel: 'GE',
        E: 6.0171659839046,
        N: 46.17380356084019,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Céligny',
        PLZ: 1298,
        Zusatzziffer: 0,
        Gemeindename: 'Céligny',
        'BFS-Nr': 6610,
        Kantonskürzel: 'GE',
        E: 6.175357688965923,
        N: 46.34775086189472,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Céligny',
        PLZ: 1298,
        Zusatzziffer: 0,
        Gemeindename: 'Céligny',
        'BFS-Nr': 6610,
        Kantonskürzel: 'GE',
        E: 6.195091402069503,
        N: 46.350243493898994,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Chancy',
        PLZ: 1284,
        Zusatzziffer: 0,
        Gemeindename: 'Chancy',
        'BFS-Nr': 6611,
        Kantonskürzel: 'GE',
        E: 5.971343350672475,
        N: 46.14969234949853,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Chêne-Bougeries',
        PLZ: 1224,
        Zusatzziffer: 0,
        Gemeindename: 'Chêne-Bougeries',
        'BFS-Nr': 6612,
        Kantonskürzel: 'GE',
        E: 6.188610741790914,
        N: 46.1977453897447,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Conches',
        PLZ: 1231,
        Zusatzziffer: 0,
        Gemeindename: 'Chêne-Bougeries',
        'BFS-Nr': 6612,
        Kantonskürzel: 'GE',
        E: 6.176606515062948,
        N: 46.1843854127378,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Chêne-Bourg',
        PLZ: 1225,
        Zusatzziffer: 0,
        Gemeindename: 'Chêne-Bourg',
        'BFS-Nr': 6613,
        Kantonskürzel: 'GE',
        E: 6.191914852265288,
        N: 46.19372665847312,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Choulex',
        PLZ: 1244,
        Zusatzziffer: 0,
        Gemeindename: 'Choulex',
        'BFS-Nr': 6614,
        Kantonskürzel: 'GE',
        E: 6.226591095327677,
        N: 46.22812771216722,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Collex',
        PLZ: 1239,
        Zusatzziffer: 0,
        Gemeindename: 'Collex-Bossy',
        'BFS-Nr': 6615,
        Kantonskürzel: 'GE',
        E: 6.120818291192712,
        N: 46.270705513747195,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Vésenaz',
        PLZ: 1222,
        Zusatzziffer: 0,
        Gemeindename: 'Collonge-Bellerive',
        'BFS-Nr': 6616,
        Kantonskürzel: 'GE',
        E: 6.199668029226098,
        N: 46.23754317890777,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Collonge-Bellerive',
        PLZ: 1245,
        Zusatzziffer: 0,
        Gemeindename: 'Collonge-Bellerive',
        'BFS-Nr': 6616,
        Kantonskürzel: 'GE',
        E: 6.204034162733675,
        N: 46.25367873597992,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Cologny',
        PLZ: 1223,
        Zusatzziffer: 0,
        Gemeindename: 'Cologny',
        'BFS-Nr': 6617,
        Kantonskürzel: 'GE',
        E: 6.181208657691199,
        N: 46.21625929072952,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Confignon',
        PLZ: 1232,
        Zusatzziffer: 0,
        Gemeindename: 'Confignon',
        'BFS-Nr': 6618,
        Kantonskürzel: 'GE',
        E: 6.084483885222576,
        N: 46.17397280183826,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Corsier GE',
        PLZ: 1246,
        Zusatzziffer: 0,
        Gemeindename: 'Corsier (GE)',
        'BFS-Nr': 6619,
        Kantonskürzel: 'GE',
        E: 6.226212082383404,
        N: 46.26249121156178,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'La Plaine',
        PLZ: 1283,
        Zusatzziffer: 0,
        Gemeindename: 'Dardagny',
        'BFS-Nr': 6620,
        Kantonskürzel: 'GE',
        E: 5.999767876825216,
        N: 46.178763512566675,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Dardagny',
        PLZ: 1283,
        Zusatzziffer: 1,
        Gemeindename: 'Dardagny',
        'BFS-Nr': 6620,
        Kantonskürzel: 'GE',
        E: 5.995317201405164,
        N: 46.19480713485023,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Genève',
        PLZ: 1201,
        Zusatzziffer: 0,
        Gemeindename: 'Genève',
        'BFS-Nr': 6621,
        Kantonskürzel: 'GE',
        E: 6.1466557892893094,
        N: 46.21026704673743,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Genève',
        PLZ: 1202,
        Zusatzziffer: 0,
        Gemeindename: 'Genève',
        'BFS-Nr': 6621,
        Kantonskürzel: 'GE',
        E: 6.148863533439881,
        N: 46.221461825724454,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Genève',
        PLZ: 1203,
        Zusatzziffer: 0,
        Gemeindename: 'Genève',
        'BFS-Nr': 6621,
        Kantonskürzel: 'GE',
        E: 6.123611323388353,
        N: 46.20858287555155,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Genève',
        PLZ: 1204,
        Zusatzziffer: 0,
        Gemeindename: 'Genève',
        'BFS-Nr': 6621,
        Kantonskürzel: 'GE',
        E: 6.144968053206059,
        N: 46.20242095486308,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Genève',
        PLZ: 1205,
        Zusatzziffer: 0,
        Gemeindename: 'Genève',
        'BFS-Nr': 6621,
        Kantonskürzel: 'GE',
        E: 6.143626574201527,
        N: 46.195236442793,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Genève',
        PLZ: 1206,
        Zusatzziffer: 0,
        Gemeindename: 'Genève',
        'BFS-Nr': 6621,
        Kantonskürzel: 'GE',
        E: 6.158811138647352,
        N: 46.18716015564269,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Genève',
        PLZ: 1207,
        Zusatzziffer: 0,
        Gemeindename: 'Genève',
        'BFS-Nr': 6621,
        Kantonskürzel: 'GE',
        E: 6.161604516965235,
        N: 46.207265026490994,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Genève',
        PLZ: 1208,
        Zusatzziffer: 0,
        Gemeindename: 'Genève',
        'BFS-Nr': 6621,
        Kantonskürzel: 'GE',
        E: 6.16588871280472,
        N: 46.19939431641167,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Genève',
        PLZ: 1209,
        Zusatzziffer: 0,
        Gemeindename: 'Genève',
        'BFS-Nr': 6621,
        Kantonskürzel: 'GE',
        E: 6.124968056115557,
        N: 46.22186061069509,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Les Acacias',
        PLZ: 1227,
        Zusatzziffer: 4,
        Gemeindename: 'Genève',
        'BFS-Nr': 6621,
        Kantonskürzel: 'GE',
        E: 6.133148094123065,
        N: 46.1934969850963,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Genthod',
        PLZ: 1294,
        Zusatzziffer: 0,
        Gemeindename: 'Genthod',
        'BFS-Nr': 6622,
        Kantonskürzel: 'GE',
        E: 6.157561613510195,
        N: 46.26477168391933,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Genève',
        PLZ: 1215,
        Zusatzziffer: 0,
        Gemeindename: 'Le Grand-Saconnex',
        'BFS-Nr': 6623,
        Kantonskürzel: 'GE',
        E: 6.106857756676451,
        N: 46.235189843667605,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Le Grand-Saconnex',
        PLZ: 1218,
        Zusatzziffer: 0,
        Gemeindename: 'Le Grand-Saconnex',
        'BFS-Nr': 6623,
        Kantonskürzel: 'GE',
        E: 6.124000575196861,
        N: 46.23243391906411,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Gy',
        PLZ: 1251,
        Zusatzziffer: 0,
        Gemeindename: 'Gy',
        'BFS-Nr': 6624,
        Kantonskürzel: 'GE',
        E: 6.257596038233397,
        N: 46.25247237331644,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Hermance',
        PLZ: 1248,
        Zusatzziffer: 0,
        Gemeindename: 'Hermance',
        'BFS-Nr': 6625,
        Kantonskürzel: 'GE',
        E: 6.244453013237223,
        N: 46.301388943419425,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Jussy',
        PLZ: 1254,
        Zusatzziffer: 0,
        Gemeindename: 'Jussy',
        'BFS-Nr': 6626,
        Kantonskürzel: 'GE',
        E: 6.2660265901462,
        N: 46.23539011342385,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Laconnex',
        PLZ: 1287,
        Zusatzziffer: 0,
        Gemeindename: 'Laconnex',
        'BFS-Nr': 6627,
        Kantonskürzel: 'GE',
        E: 6.034670755144155,
        N: 46.154740457090405,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Grand-Lancy',
        PLZ: 1212,
        Zusatzziffer: 0,
        Gemeindename: 'Lancy',
        'BFS-Nr': 6628,
        Kantonskürzel: 'GE',
        E: 6.120960543844054,
        N: 46.18236469547561,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Petit-Lancy',
        PLZ: 1213,
        Zusatzziffer: 0,
        Gemeindename: 'Lancy',
        'BFS-Nr': 6628,
        Kantonskürzel: 'GE',
        E: 6.11724784672731,
        N: 46.18908208152802,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Meinier',
        PLZ: 1252,
        Zusatzziffer: 0,
        Gemeindename: 'Meinier',
        'BFS-Nr': 6629,
        Kantonskürzel: 'GE',
        E: 6.234226276318482,
        N: 46.24655880265358,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Cointrin',
        PLZ: 1216,
        Zusatzziffer: 0,
        Gemeindename: 'Meyrin',
        'BFS-Nr': 6630,
        Kantonskürzel: 'GE',
        E: 6.1055217050210056,
        N: 46.22444562290391,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Meyrin',
        PLZ: 1217,
        Zusatzziffer: 0,
        Gemeindename: 'Meyrin',
        'BFS-Nr': 6630,
        Kantonskürzel: 'GE',
        E: 6.070661675591116,
        N: 46.22826745179406,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Onex',
        PLZ: 1213,
        Zusatzziffer: 3,
        Gemeindename: 'Onex',
        'BFS-Nr': 6631,
        Kantonskürzel: 'GE',
        E: 6.100958304483036,
        N: 46.18206118611807,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Perly',
        PLZ: 1258,
        Zusatzziffer: 0,
        Gemeindename: 'Perly-Certoux',
        'BFS-Nr': 6632,
        Kantonskürzel: 'GE',
        E: 6.087689170917644,
        N: 46.155993377811285,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'PlanService-les-Ouates',
        PLZ: 1228,
        Zusatzziffer: 0,
        Gemeindename: 'PlanService-les-Ouates',
        'BFS-Nr': 6633,
        Kantonskürzel: 'GE',
        E: 6.11585914397746,
        N: 46.168061573755125,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Chambésy',
        PLZ: 1292,
        Zusatzziffer: 0,
        Gemeindename: 'Pregny-Chambésy',
        'BFS-Nr': 6634,
        Kantonskürzel: 'GE',
        E: 6.146931845121319,
        N: 46.24069958897081,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Presinge',
        PLZ: 1243,
        Zusatzziffer: 0,
        Gemeindename: 'Presinge',
        'BFS-Nr': 6635,
        Kantonskürzel: 'GE',
        E: 6.255053063923055,
        N: 46.21894167517077,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Puplinge',
        PLZ: 1241,
        Zusatzziffer: 0,
        Gemeindename: 'Puplinge',
        'BFS-Nr': 6636,
        Kantonskürzel: 'GE',
        E: 6.232415891399487,
        N: 46.20988745516153,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Russin',
        PLZ: 1281,
        Zusatzziffer: 0,
        Gemeindename: 'Russin',
        'BFS-Nr': 6637,
        Kantonskürzel: 'GE',
        E: 6.014072201756577,
        N: 46.18753040206896,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Satigny',
        PLZ: 1242,
        Zusatzziffer: 0,
        Gemeindename: 'Satigny',
        'BFS-Nr': 6638,
        Kantonskürzel: 'GE',
        E: 6.03141589621667,
        N: 46.21823078109354,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Soral',
        PLZ: 1286,
        Zusatzziffer: 0,
        Gemeindename: 'Soral',
        'BFS-Nr': 6639,
        Kantonskürzel: 'GE',
        E: 6.0430533839385685,
        N: 46.14479365659224,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Thônex',
        PLZ: 1226,
        Zusatzziffer: 0,
        Gemeindename: 'Thônex',
        'BFS-Nr': 6640,
        Kantonskürzel: 'GE',
        E: 6.198675910262119,
        N: 46.188270854951995,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Troinex',
        PLZ: 1256,
        Zusatzziffer: 0,
        Gemeindename: 'Troinex',
        'BFS-Nr': 6641,
        Kantonskürzel: 'GE',
        E: 6.148517414303377,
        N: 46.1611520017722,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Vandoeuvres',
        PLZ: 1253,
        Zusatzziffer: 0,
        Gemeindename: 'Vandoeuvres',
        'BFS-Nr': 6642,
        Kantonskürzel: 'GE',
        E: 6.2025655411278215,
        N: 46.22120533923856,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Vernier',
        PLZ: 1214,
        Zusatzziffer: 0,
        Gemeindename: 'Vernier',
        'BFS-Nr': 6643,
        Kantonskürzel: 'GE',
        E: 6.0865340303455975,
        N: 46.21528817965728,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Le Lignon',
        PLZ: 1219,
        Zusatzziffer: 0,
        Gemeindename: 'Vernier',
        'BFS-Nr': 6643,
        Kantonskürzel: 'GE',
        E: 6.0955000020391585,
        N: 46.20423658722336,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Aïre',
        PLZ: 1219,
        Zusatzziffer: 10,
        Gemeindename: 'Vernier',
        'BFS-Nr': 6643,
        Kantonskürzel: 'GE',
        E: 6.098349941914588,
        N: 46.19992729782162,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Châtelaine',
        PLZ: 1219,
        Zusatzziffer: 12,
        Gemeindename: 'Vernier',
        'BFS-Nr': 6643,
        Kantonskürzel: 'GE',
        E: 6.110518730720843,
        N: 46.21206682386804,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Les Avanchets',
        PLZ: 1220,
        Zusatzziffer: 0,
        Gemeindename: 'Vernier',
        'BFS-Nr': 6643,
        Kantonskürzel: 'GE',
        E: 6.10894451229884,
        N: 46.22083360663908,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Versoix',
        PLZ: 1290,
        Zusatzziffer: 0,
        Gemeindename: 'Versoix',
        'BFS-Nr': 6644,
        Kantonskürzel: 'GE',
        E: 6.165301469254077,
        N: 46.28190598711997,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Vessy',
        PLZ: 1234,
        Zusatzziffer: 0,
        Gemeindename: 'Veyrier',
        'BFS-Nr': 6645,
        Kantonskürzel: 'GE',
        E: 6.154693879938098,
        N: 46.17231370511626,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Veyrier',
        PLZ: 1255,
        Zusatzziffer: 0,
        Gemeindename: 'Veyrier',
        'BFS-Nr': 6645,
        Kantonskürzel: 'GE',
        E: 6.186075896042856,
        N: 46.166799394330056,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Boécourt',
        PLZ: 2856,
        Zusatzziffer: 0,
        Gemeindename: 'Boécourt',
        'BFS-Nr': 6702,
        Kantonskürzel: 'JU',
        E: 7.215560609120385,
        N: 47.34898413387936,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Montavon',
        PLZ: 2857,
        Zusatzziffer: 0,
        Gemeindename: 'Boécourt',
        'BFS-Nr': 6702,
        Kantonskürzel: 'JU',
        E: 7.235974314481685,
        N: 47.373421959151,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Bourrignon',
        PLZ: 2803,
        Zusatzziffer: 0,
        Gemeindename: 'Bourrignon',
        'BFS-Nr': 6703,
        Kantonskürzel: 'JU',
        E: 7.243355669425016,
        N: 47.397873518862234,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Châtillon JU',
        PLZ: 2843,
        Zusatzziffer: 0,
        Gemeindename: 'Châtillon (JU)',
        'BFS-Nr': 6704,
        Kantonskürzel: 'JU',
        E: 7.344841420017821,
        N: 47.32639564544007,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Courchapoix',
        PLZ: 2825,
        Zusatzziffer: 0,
        Gemeindename: 'Courchapoix',
        'BFS-Nr': 6706,
        Kantonskürzel: 'JU',
        E: 7.451434045592019,
        N: 47.34734122063928,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Courrendlin',
        PLZ: 2830,
        Zusatzziffer: 0,
        Gemeindename: 'Courrendlin',
        'BFS-Nr': 6708,
        Kantonskürzel: 'JU',
        E: 7.379267941852372,
        N: 47.338358629311706,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Vellerat',
        PLZ: 2830,
        Zusatzziffer: 2,
        Gemeindename: 'Courrendlin',
        'BFS-Nr': 6708,
        Kantonskürzel: 'JU',
        E: 7.369965601746369,
        N: 47.320310033731815,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Rebeuvelier',
        PLZ: 2832,
        Zusatzziffer: 0,
        Gemeindename: 'Courrendlin',
        'BFS-Nr': 6708,
        Kantonskürzel: 'JU',
        E: 7.409375199928265,
        N: 47.324767919110144,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Courroux',
        PLZ: 2822,
        Zusatzziffer: 0,
        Gemeindename: 'Courroux',
        'BFS-Nr': 6709,
        Kantonskürzel: 'JU',
        E: 7.375115195017095,
        N: 47.36221564664432,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Courcelon',
        PLZ: 2823,
        Zusatzziffer: 0,
        Gemeindename: 'Courroux',
        'BFS-Nr': 6709,
        Kantonskürzel: 'JU',
        E: 7.39667577711979,
        N: 47.36193088255652,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Courtételle',
        PLZ: 2852,
        Zusatzziffer: 0,
        Gemeindename: 'Courtételle',
        'BFS-Nr': 6710,
        Kantonskürzel: 'JU',
        E: 7.315917183996275,
        N: 47.34041243768331,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Delémont',
        PLZ: 2800,
        Zusatzziffer: 0,
        Gemeindename: 'Delémont',
        'BFS-Nr': 6711,
        Kantonskürzel: 'JU',
        E: 7.343637117508529,
        N: 47.36469720079255,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Develier',
        PLZ: 2802,
        Zusatzziffer: 0,
        Gemeindename: 'Develier',
        'BFS-Nr': 6712,
        Kantonskürzel: 'JU',
        E: 7.29128174915686,
        N: 47.35471570629266,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Ederswiler',
        PLZ: 2813,
        Zusatzziffer: 0,
        Gemeindename: 'Ederswiler',
        'BFS-Nr': 6713,
        Kantonskürzel: 'JU',
        E: 7.335569975669457,
        N: 47.426698347837664,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Mervelier',
        PLZ: 2827,
        Zusatzziffer: 0,
        Gemeindename: 'Mervelier',
        'BFS-Nr': 6715,
        Kantonskürzel: 'JU',
        E: 7.503727721925823,
        N: 47.34425010125599,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Mettembert',
        PLZ: 2806,
        Zusatzziffer: 0,
        Gemeindename: 'Mettembert',
        'BFS-Nr': 6716,
        Kantonskürzel: 'JU',
        E: 7.319467758447999,
        N: 47.39789231409255,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Movelier',
        PLZ: 2812,
        Zusatzziffer: 0,
        Gemeindename: 'Movelier',
        'BFS-Nr': 6718,
        Kantonskürzel: 'JU',
        E: 7.316351739852451,
        N: 47.41007069760785,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Pleigne',
        PLZ: 2807,
        Zusatzziffer: 0,
        Gemeindename: 'Pleigne',
        'BFS-Nr': 6719,
        Kantonskürzel: 'JU',
        E: 7.294114789382651,
        N: 47.40763285916331,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Lucelle',
        PLZ: 2807,
        Zusatzziffer: 1,
        Gemeindename: 'Pleigne',
        'BFS-Nr': 6719,
        Kantonskürzel: 'JU',
        E: 7.244741717549298,
        N: 47.42015973155405,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Rossemaison',
        PLZ: 2842,
        Zusatzziffer: 0,
        Gemeindename: 'Rossemaison',
        'BFS-Nr': 6721,
        Kantonskürzel: 'JU',
        E: 7.34274870487232,
        N: 47.34579519414779,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Saulcy',
        PLZ: 2873,
        Zusatzziffer: 0,
        Gemeindename: 'Saulcy',
        'BFS-Nr': 6722,
        Kantonskürzel: 'JU',
        E: 7.154196117067848,
        N: 47.30225216846655,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Soyhières',
        PLZ: 2805,
        Zusatzziffer: 0,
        Gemeindename: 'Soyhières',
        'BFS-Nr': 6724,
        Kantonskürzel: 'JU',
        E: 7.3694687784840855,
        N: 47.391569869506434,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Courfaivre',
        PLZ: 2853,
        Zusatzziffer: 0,
        Gemeindename: 'Haute-Sorne',
        'BFS-Nr': 6729,
        Kantonskürzel: 'JU',
        E: 7.2831906537662805,
        N: 47.334089897433955,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Bassecourt',
        PLZ: 2854,
        Zusatzziffer: 0,
        Gemeindename: 'Haute-Sorne',
        'BFS-Nr': 6729,
        Kantonskürzel: 'JU',
        E: 7.244548704175263,
        N: 47.336259794280004,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Glovelier',
        PLZ: 2855,
        Zusatzziffer: 0,
        Gemeindename: 'Haute-Sorne',
        'BFS-Nr': 6729,
        Kantonskürzel: 'JU',
        E: 7.199807358555989,
        N: 47.33161532186605,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Undervelier',
        PLZ: 2863,
        Zusatzziffer: 0,
        Gemeindename: 'Haute-Sorne',
        'BFS-Nr': 6729,
        Kantonskürzel: 'JU',
        E: 7.224497320220406,
        N: 47.30357433879062,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Soulce',
        PLZ: 2864,
        Zusatzziffer: 0,
        Gemeindename: 'Haute-Sorne',
        'BFS-Nr': 6729,
        Kantonskürzel: 'JU',
        E: 7.2706668160087125,
        N: 47.305250376780144,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Vicques',
        PLZ: 2824,
        Zusatzziffer: 0,
        Gemeindename: 'Val Terbi',
        'BFS-Nr': 6730,
        Kantonskürzel: 'JU',
        E: 7.413132955473078,
        N: 47.34716788452722,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Corban',
        PLZ: 2826,
        Zusatzziffer: 0,
        Gemeindename: 'Val Terbi',
        'BFS-Nr': 6730,
        Kantonskürzel: 'JU',
        E: 7.4780482453410295,
        N: 47.34809900070371,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Montsevelier',
        PLZ: 2828,
        Zusatzziffer: 0,
        Gemeindename: 'Val Terbi',
        'BFS-Nr': 6730,
        Kantonskürzel: 'JU',
        E: 7.510356716387254,
        N: 47.35912440421226,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Vermes',
        PLZ: 2829,
        Zusatzziffer: 0,
        Gemeindename: 'Val Terbi',
        'BFS-Nr': 6730,
        Kantonskürzel: 'JU',
        E: 7.47805345187227,
        N: 47.32977358185158,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Le Bémont JU',
        PLZ: 2360,
        Zusatzziffer: 0,
        Gemeindename: 'Le Bémont (JU)',
        'BFS-Nr': 6741,
        Kantonskürzel: 'JU',
        E: 7.014141008109299,
        N: 47.26322269383826,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Les Bois',
        PLZ: 2336,
        Zusatzziffer: 0,
        Gemeindename: 'Les Bois',
        'BFS-Nr': 6742,
        Kantonskürzel: 'JU',
        E: 6.905130749147119,
        N: 47.17660125835869,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Les Breuleux',
        PLZ: 2345,
        Zusatzziffer: 0,
        Gemeindename: 'Les Breuleux',
        'BFS-Nr': 6743,
        Kantonskürzel: 'JU',
        E: 7.003539481222807,
        N: 47.212635310331684,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'La Chaux-des-Breuleux',
        PLZ: 2345,
        Zusatzziffer: 3,
        Gemeindename: 'La Chaux-des-Breuleux',
        'BFS-Nr': 6744,
        Kantonskürzel: 'JU',
        E: 7.027469070941448,
        N: 47.2227036007061,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Les Enfers',
        PLZ: 2363,
        Zusatzziffer: 0,
        Gemeindename: 'Les Enfers',
        'BFS-Nr': 6745,
        Kantonskürzel: 'JU',
        E: 7.044251614107399,
        N: 47.288017098472636,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Les Genevez JU',
        PLZ: 2714,
        Zusatzziffer: 0,
        Gemeindename: 'Les Genevez (JU)',
        'BFS-Nr': 6748,
        Kantonskürzel: 'JU',
        E: 7.130366483323472,
        N: 47.25953835357055,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Le Prédame',
        PLZ: 2714,
        Zusatzziffer: 2,
        Gemeindename: 'Les Genevez (JU)',
        'BFS-Nr': 6748,
        Kantonskürzel: 'JU',
        E: 7.1070347623314785,
        N: 47.25750558567884,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Lajoux JU',
        PLZ: 2718,
        Zusatzziffer: 0,
        Gemeindename: 'Lajoux (JU)',
        'BFS-Nr': 6750,
        Kantonskürzel: 'JU',
        E: 7.139376269866916,
        N: 47.279215855850495,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Fornet-Dessus',
        PLZ: 2718,
        Zusatzziffer: 2,
        Gemeindename: 'Lajoux (JU)',
        'BFS-Nr': 6750,
        Kantonskürzel: 'JU',
        E: 7.1589884552006,
        N: 47.279101996867084,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Montfaucon',
        PLZ: 2362,
        Zusatzziffer: 0,
        Gemeindename: 'Montfaucon',
        'BFS-Nr': 6751,
        Kantonskürzel: 'JU',
        E: 7.051073491829011,
        N: 47.282656403323706,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Montfavergier',
        PLZ: 2362,
        Zusatzziffer: 2,
        Gemeindename: 'Montfaucon',
        'BFS-Nr': 6751,
        Kantonskürzel: 'JU',
        E: 7.089784839063442,
        N: 47.30957929082749,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Les Emibois',
        PLZ: 2338,
        Zusatzziffer: 0,
        Gemeindename: 'Muriaux',
        'BFS-Nr': 6753,
        Kantonskürzel: 'JU',
        E: 6.985928264555792,
        N: 47.232214277567465,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Muriaux',
        PLZ: 2338,
        Zusatzziffer: 2,
        Gemeindename: 'Muriaux',
        'BFS-Nr': 6753,
        Kantonskürzel: 'JU',
        E: 6.97829273446233,
        N: 47.24608394464996,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Le Cerneux-Veusil',
        PLZ: 2345,
        Zusatzziffer: 2,
        Gemeindename: 'Muriaux',
        'BFS-Nr': 6753,
        Kantonskürzel: 'JU',
        E: 6.962689508378615,
        N: 47.183822906928455,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Le Noirmont',
        PLZ: 2340,
        Zusatzziffer: 0,
        Gemeindename: 'Le Noirmont',
        'BFS-Nr': 6754,
        Kantonskürzel: 'JU',
        E: 6.95751669716731,
        N: 47.22646564782677,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Saignelégier',
        PLZ: 2350,
        Zusatzziffer: 0,
        Gemeindename: 'Saignelégier',
        'BFS-Nr': 6757,
        Kantonskürzel: 'JU',
        E: 6.995682601134839,
        N: 47.25573403865833,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Les Pommerats',
        PLZ: 2353,
        Zusatzziffer: 0,
        Gemeindename: 'Saignelégier',
        'BFS-Nr': 6757,
        Kantonskürzel: 'JU',
        E: 6.986920207599156,
        N: 47.271603573056,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Goumois',
        PLZ: 2354,
        Zusatzziffer: 0,
        Gemeindename: 'Saignelégier',
        'BFS-Nr': 6757,
        Kantonskürzel: 'JU',
        E: 6.952570594985191,
        N: 47.261389064405925,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'St-Brais',
        PLZ: 2364,
        Zusatzziffer: 0,
        Gemeindename: 'Saint-Brais',
        'BFS-Nr': 6758,
        Kantonskürzel: 'JU',
        E: 7.112479040301928,
        N: 47.3050266382989,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'St-Ursanne',
        PLZ: 2882,
        Zusatzziffer: 0,
        Gemeindename: 'Saint-Brais',
        'BFS-Nr': 6758,
        Kantonskürzel: 'JU',
        E: 7.111904018917308,
        N: 47.32059872285906,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Soubey',
        PLZ: 2887,
        Zusatzziffer: 0,
        Gemeindename: 'Soubey',
        'BFS-Nr': 6759,
        Kantonskürzel: 'JU',
        E: 7.048514255753543,
        N: 47.30956138886818,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Alle',
        PLZ: 2942,
        Zusatzziffer: 0,
        Gemeindename: 'Alle',
        'BFS-Nr': 6771,
        Kantonskürzel: 'JU',
        E: 7.128727316607906,
        N: 47.42725992711788,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Beurnevésin',
        PLZ: 2935,
        Zusatzziffer: 0,
        Gemeindename: 'Beurnevésin',
        'BFS-Nr': 6773,
        Kantonskürzel: 'JU',
        E: 7.1371704000328915,
        N: 47.49352687493887,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Boncourt',
        PLZ: 2926,
        Zusatzziffer: 0,
        Gemeindename: 'Boncourt',
        'BFS-Nr': 6774,
        Kantonskürzel: 'JU',
        E: 7.01322291098763,
        N: 47.4964344181208,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Bonfol',
        PLZ: 2944,
        Zusatzziffer: 0,
        Gemeindename: 'Bonfol',
        'BFS-Nr': 6775,
        Kantonskürzel: 'JU',
        E: 7.147485966545556,
        N: 47.47952799830399,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Bure',
        PLZ: 2915,
        Zusatzziffer: 0,
        Gemeindename: 'Bure',
        'BFS-Nr': 6778,
        Kantonskürzel: 'JU',
        E: 7.006006426177857,
        N: 47.440817226732065,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Coeuve',
        PLZ: 2932,
        Zusatzziffer: 0,
        Gemeindename: 'Coeuve',
        'BFS-Nr': 6781,
        Kantonskürzel: 'JU',
        E: 7.096215612517213,
        N: 47.45453339800806,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Cornol',
        PLZ: 2952,
        Zusatzziffer: 0,
        Gemeindename: 'Cornol',
        'BFS-Nr': 6782,
        Kantonskürzel: 'JU',
        E: 7.163826424497274,
        N: 47.405904793707954,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Courchavon',
        PLZ: 2922,
        Zusatzziffer: 0,
        Gemeindename: 'Courchavon',
        'BFS-Nr': 6783,
        Kantonskürzel: 'JU',
        E: 7.054918122214483,
        N: 47.440929665713355,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Courgenay',
        PLZ: 2950,
        Zusatzziffer: 0,
        Gemeindename: 'Courgenay',
        'BFS-Nr': 6784,
        Kantonskürzel: 'JU',
        E: 7.126216252707597,
        N: 47.40308435762176,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Courtemautruy',
        PLZ: 2950,
        Zusatzziffer: 2,
        Gemeindename: 'Courgenay',
        'BFS-Nr': 6784,
        Kantonskürzel: 'JU',
        E: 7.138720687196809,
        N: 47.395747549489435,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Courtedoux',
        PLZ: 2905,
        Zusatzziffer: 0,
        Gemeindename: 'Courtedoux',
        'BFS-Nr': 6785,
        Kantonskürzel: 'JU',
        E: 7.042542434216465,
        N: 47.40870546863922,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Damphreux',
        PLZ: 2933,
        Zusatzziffer: 2,
        Gemeindename: 'Damphreux',
        'BFS-Nr': 6787,
        Kantonskürzel: 'JU',
        E: 7.102402941641683,
        N: 47.47685305066094,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Fahy',
        PLZ: 2916,
        Zusatzziffer: 0,
        Gemeindename: 'Fahy',
        'BFS-Nr': 6789,
        Kantonskürzel: 'JU',
        E: 6.9492035665047425,
        N: 47.417888033925266,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Fontenais',
        PLZ: 2902,
        Zusatzziffer: 0,
        Gemeindename: 'Fontenais',
        'BFS-Nr': 6790,
        Kantonskürzel: 'JU',
        E: 7.081091172234214,
        N: 47.40298396928059,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Villars-sur-Fontenais',
        PLZ: 2903,
        Zusatzziffer: 0,
        Gemeindename: 'Fontenais',
        'BFS-Nr': 6790,
        Kantonskürzel: 'JU',
        E: 7.0807674782443755,
        N: 47.38785955051177,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Bressaucourt',
        PLZ: 2904,
        Zusatzziffer: 0,
        Gemeindename: 'Fontenais',
        'BFS-Nr': 6790,
        Kantonskürzel: 'JU',
        E: 7.036910884998154,
        N: 47.38768330708634,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Grandfontaine',
        PLZ: 2908,
        Zusatzziffer: 0,
        Gemeindename: 'Grandfontaine',
        'BFS-Nr': 6792,
        Kantonskürzel: 'JU',
        E: 6.939734143000999,
        N: 47.39173442356937,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Lugnez',
        PLZ: 2933,
        Zusatzziffer: 3,
        Gemeindename: 'Lugnez',
        'BFS-Nr': 6793,
        Kantonskürzel: 'JU',
        E: 7.097583023128529,
        N: 47.483289899031355,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Porrentruy',
        PLZ: 2900,
        Zusatzziffer: 0,
        Gemeindename: 'Porrentruy',
        'BFS-Nr': 6800,
        Kantonskürzel: 'JU',
        E: 7.076829393136043,
        N: 47.41589644345257,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Vendlincourt',
        PLZ: 2943,
        Zusatzziffer: 0,
        Gemeindename: 'Vendlincourt',
        'BFS-Nr': 6806,
        Kantonskürzel: 'JU',
        E: 7.146347783110028,
        N: 47.45106202891688,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Courtemaîche',
        PLZ: 2923,
        Zusatzziffer: 0,
        Gemeindename: 'Basse-Allaine',
        'BFS-Nr': 6807,
        Kantonskürzel: 'JU',
        E: 7.045591920818905,
        N: 47.45869498719052,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Montignez',
        PLZ: 2924,
        Zusatzziffer: 0,
        Gemeindename: 'Basse-Allaine',
        'BFS-Nr': 6807,
        Kantonskürzel: 'JU',
        E: 7.05929159154095,
        N: 47.48703343808432,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Buix',
        PLZ: 2925,
        Zusatzziffer: 0,
        Gemeindename: 'Basse-Allaine',
        'BFS-Nr': 6807,
        Kantonskürzel: 'JU',
        E: 7.028405447665326,
        N: 47.4794074120847,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'St-Ursanne',
        PLZ: 2882,
        Zusatzziffer: 0,
        Gemeindename: 'Clos du Doubs',
        'BFS-Nr': 6808,
        Kantonskürzel: 'JU',
        E: 7.1535904760521465,
        N: 47.36466787377351,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Montmelon',
        PLZ: 2883,
        Zusatzziffer: 0,
        Gemeindename: 'Clos du Doubs',
        'BFS-Nr': 6808,
        Kantonskürzel: 'JU',
        E: 7.18127725450903,
        N: 47.354501445110124,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Montenol',
        PLZ: 2884,
        Zusatzziffer: 0,
        Gemeindename: 'Clos du Doubs',
        'BFS-Nr': 6808,
        Kantonskürzel: 'JU',
        E: 7.151944518330188,
        N: 47.35286633578944,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Epauvillers',
        PLZ: 2885,
        Zusatzziffer: 0,
        Gemeindename: 'Clos du Doubs',
        'BFS-Nr': 6808,
        Kantonskürzel: 'JU',
        E: 7.115581003849413,
        N: 47.33518927887487,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Epiquerez',
        PLZ: 2886,
        Zusatzziffer: 0,
        Gemeindename: 'Clos du Doubs',
        'BFS-Nr': 6808,
        Kantonskürzel: 'JU',
        E: 7.057910978410388,
        N: 47.328656862906335,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Seleute',
        PLZ: 2888,
        Zusatzziffer: 0,
        Gemeindename: 'Clos du Doubs',
        'BFS-Nr': 6808,
        Kantonskürzel: 'JU',
        E: 7.111432819746247,
        N: 47.366067566133935,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Ocourt',
        PLZ: 2889,
        Zusatzziffer: 0,
        Gemeindename: 'Clos du Doubs',
        'BFS-Nr': 6808,
        Kantonskürzel: 'JU',
        E: 7.077922057487893,
        N: 47.351923354694414,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Chevenez',
        PLZ: 2906,
        Zusatzziffer: 0,
        Gemeindename: 'Haute-Ajoie',
        'BFS-Nr': 6809,
        Kantonskürzel: 'JU',
        E: 7.001274802964321,
        N: 47.39176259311166,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Rocourt',
        PLZ: 2907,
        Zusatzziffer: 0,
        Gemeindename: 'Haute-Ajoie',
        'BFS-Nr': 6809,
        Kantonskürzel: 'JU',
        E: 6.9563864816232,
        N: 47.38926550362676,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Réclère',
        PLZ: 2912,
        Zusatzziffer: 2,
        Gemeindename: 'Haute-Ajoie',
        'BFS-Nr': 6809,
        Kantonskürzel: 'JU',
        E: 6.923643731444939,
        N: 47.37565460827057,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Roche-d\'Or',
        PLZ: 2912,
        Zusatzziffer: 3,
        Gemeindename: 'Haute-Ajoie',
        'BFS-Nr': 6809,
        Kantonskürzel: 'JU',
        E: 6.954013289174863,
        N: 47.36720572370498,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Damvant',
        PLZ: 2914,
        Zusatzziffer: 0,
        Gemeindename: 'Haute-Ajoie',
        'BFS-Nr': 6809,
        Kantonskürzel: 'JU',
        E: 6.897077366743106,
        N: 47.37258688442143,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Miécourt',
        PLZ: 2946,
        Zusatzziffer: 0,
        Gemeindename: 'La Baroche',
        'BFS-Nr': 6810,
        Kantonskürzel: 'JU',
        E: 7.176427433937339,
        N: 47.426069166897356,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Charmoille',
        PLZ: 2947,
        Zusatzziffer: 0,
        Gemeindename: 'La Baroche',
        'BFS-Nr': 6810,
        Kantonskürzel: 'JU',
        E: 7.20439873056064,
        N: 47.42348834444298,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Fregiécourt',
        PLZ: 2953,
        Zusatzziffer: 2,
        Gemeindename: 'La Baroche',
        'BFS-Nr': 6810,
        Kantonskürzel: 'JU',
        E: 7.197942527925788,
        N: 47.41168648005722,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Pleujouse',
        PLZ: 2953,
        Zusatzziffer: 3,
        Gemeindename: 'La Baroche',
        'BFS-Nr': 6810,
        Kantonskürzel: 'JU',
        E: 7.210998808116126,
        N: 47.41187460398528,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Asuel',
        PLZ: 2954,
        Zusatzziffer: 0,
        Gemeindename: 'La Baroche',
        'BFS-Nr': 6810,
        Kantonskürzel: 'JU',
        E: 7.211151149569308,
        N: 47.40197349669311,
        Sprache: 'fr',
    },
    {
        Ortschaftsname: 'Vaduz',
        PLZ: 9490,
        Zusatzziffer: 0,
        Gemeindename: 'Vaduz',
        'BFS-Nr': 7001,
        Kantonskürzel: '',
        E: 9.52257934773574,
        N: 47.136252898379574,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Triesen',
        PLZ: 9495,
        Zusatzziffer: 0,
        Gemeindename: 'Triesen',
        'BFS-Nr': 7002,
        Kantonskürzel: '',
        E: 9.530340725403049,
        N: 47.106001352407425,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Balzers',
        PLZ: 9496,
        Zusatzziffer: 0,
        Gemeindename: 'Balzers',
        'BFS-Nr': 7003,
        Kantonskürzel: '',
        E: 9.502347484238989,
        N: 47.06527492879466,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Triesenberg',
        PLZ: 9497,
        Zusatzziffer: 0,
        Gemeindename: 'Triesenberg',
        'BFS-Nr': 7004,
        Kantonskürzel: '',
        E: 9.543957719271463,
        N: 47.11790272128858,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Schaan',
        PLZ: 9494,
        Zusatzziffer: 0,
        Gemeindename: 'Schaan',
        'BFS-Nr': 7005,
        Kantonskürzel: '',
        E: 9.506298646660415,
        N: 47.1731134184683,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Planken',
        PLZ: 9498,
        Zusatzziffer: 0,
        Gemeindename: 'Planken',
        'BFS-Nr': 7006,
        Kantonskürzel: '',
        E: 9.54577301585781,
        N: 47.18513492531911,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Nendeln',
        PLZ: 9485,
        Zusatzziffer: 0,
        Gemeindename: 'Eschen',
        'BFS-Nr': 7007,
        Kantonskürzel: '',
        E: 9.544033688573524,
        N: 47.19836122968354,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Eschen',
        PLZ: 9492,
        Zusatzziffer: 0,
        Gemeindename: 'Eschen',
        'BFS-Nr': 7007,
        Kantonskürzel: '',
        E: 9.524218680309875,
        N: 47.212046863010286,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Schaanwald',
        PLZ: 9486,
        Zusatzziffer: 0,
        Gemeindename: 'Mauren',
        'BFS-Nr': 7008,
        Kantonskürzel: '',
        E: 9.561856564803879,
        N: 47.213181789286516,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Mauren FL',
        PLZ: 9493,
        Zusatzziffer: 0,
        Gemeindename: 'Mauren',
        'BFS-Nr': 7008,
        Kantonskürzel: '',
        E: 9.543808486292788,
        N: 47.22028381723761,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Gamprin-Bendern',
        PLZ: 9487,
        Zusatzziffer: 0,
        Gemeindename: 'Gamprin',
        'BFS-Nr': 7009,
        Kantonskürzel: '',
        E: 9.506075274342836,
        N: 47.21515608877409,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Ruggell',
        PLZ: 9491,
        Zusatzziffer: 0,
        Gemeindename: 'Ruggell',
        'BFS-Nr': 7010,
        Kantonskürzel: '',
        E: 9.528830277668794,
        N: 47.24100025808611,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Schellenberg',
        PLZ: 9488,
        Zusatzziffer: 0,
        Gemeindename: 'Schellenberg',
        'BFS-Nr': 7011,
        Kantonskürzel: '',
        E: 9.54600684065143,
        N: 47.23136529420569,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Thunersee',
        PLZ: 9999,
        Zusatzziffer: 1,
        Gemeindename: 'Thunersee',
        'BFS-Nr': 9073,
        Kantonskürzel: 'BE',
        E: 7.715524563539287,
        N: 46.688033279726746,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Brienzersee',
        PLZ: 9999,
        Zusatzziffer: 2,
        Gemeindename: 'Brienzersee',
        'BFS-Nr': 9089,
        Kantonskürzel: 'BE',
        E: 7.9740967049736815,
        N: 46.72850583845078,
        Sprache: 'de',
    },
    {
        Ortschaftsname: 'Bielersee',
        PLZ: 9999,
        Zusatzziffer: 0,
        Gemeindename: 'Bielersee (BE)',
        'BFS-Nr': 9149,
        Kantonskürzel: 'BE',
        E: 7.178690820471294,
        N: 47.087236205115026,
        Sprache: 'de',
    },
];

// export const educationOption = [
//     { label: 'Universität', id: 'Universität' },
//     { label: 'Fachhochschule', id: 'Fachhochschule' },
//     { label: 'Lehre', id: 'Lehre' },
//     { label: 'Anderes', id: 'Anderes' },
// ];
