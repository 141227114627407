import {get, set} from 'lodash';
import {Step, StepsType} from '../core/stepper/Stepper.types';
import {StepperConfigType} from '../components/Stepper/types/StepperPropType';

export const getStepsForLayout = (stepsDef: StepsType) => {
    const counterPerProcess = {};
    const defineCurrentProcess = (akku: {
        [p: string]: Array<StepperConfigType>
    }, process: StepsType, parent?: Array<StepperConfigType>) => {
        Object.keys(process).forEach((key) => {
            const step: Step = process[key];

            if (!akku[step.process]) akku[step.process] = [];
            if (!counterPerProcess[step.process]) counterPerProcess[step.process] = 0;
            const currentProcessItem = akku[step.process];
            const simpleStep: StepperConfigType = {
                name: step.stepperTitle,
                label: key,
                position: counterPerProcess[step.process],
            };

            counterPerProcess[step.process] += 1;

            (parent || currentProcessItem).push(simpleStep);
            if (step.children) {
                simpleStep.children = [];
                defineCurrentProcess(akku, step.children, simpleStep.children);
            }
        });

        return akku;
    };

    const currentStepsByFlow = defineCurrentProcess({}, stepsDef);

    return currentStepsByFlow;
};

export const flattenSteps = (steps: StepsType) => {
    const flat = (akku, stepNode) => {
        Object.keys(stepNode)?.forEach((key) => {
            const step = stepNode[key];

            akku[key] = step;
            if (step.children) {
                flat(akku, step.children);
            }
        });

        return akku;
    };

    return flat({}, steps);
};

export const sectionOfSteps = (originStepsRepresentation: StepsType, presentSteps: Array<string>) => {
    const traverse = (akku, stepNode, stepNodeKey:string|undefined = undefined) => {
        Object.keys(stepNode).forEach((key) => {
            const step = stepNode[key];
            const objectPath = stepNodeKey ? `${stepNodeKey}.${key}` : key;

            if (presentSteps.includes(key)) {
                set(akku, objectPath, step);

                if (step.children) {
                    const newPath = `${objectPath}.children`;

                    traverse(akku, get(akku, newPath), newPath);
                }
            } else if (stepNodeKey) delete get(akku, stepNodeKey)[key];
        });

        return akku;
    };

    return traverse({}, originStepsRepresentation);
};

export const flattenViewSteps = (steps: Array<StepperConfigType>) => {
    const flat = (akku, stepLevel) => {
        stepLevel?.forEach((step) => {
            akku.push(step);
            if (step?.children) {
                flat(akku, step.children);
            }
        });

        return akku;
    };

    return flat([], steps);
};
