import {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import { useTableSelection } from './useTableSelection';
import { getDomIndex } from '../../../utils';
import { useDraggable } from '../../../hooks/useDraggable';
import { collectColumns, getSortingFromColumns, collectExpandedRows } from '../utils/utils';

export const useTableConfig = ({
   footerColumns,
    paging: pagingSource,
    columns: columnsSource,
    sorting: sortingSource,
    filtering: filteringSource,
    defaultExpandAllRows,
    expandedRowKeys,
    orderColumns,
    expandedColumn,
    table,
    dataSource,
    data,
    rowSelection,
}) => {
    // Expanded Row Keys
    const [expandedRows, setExpandedRows] = useState(expandedRowKeys || []);

    const expandOnClick = (key) => {
        setExpandedRows((old) => {
            if (old.includes(key)) return old.filter((item) => item !== key);

            return [...old, key];
        });
    };

    useEffect(() => {
        if (expandedRowKeys) setExpandedRows(expandedRowKeys);
    }, [expandedRowKeys]);
    useEffect(() => {
        if (defaultExpandAllRows) setExpandedRows(collectExpandedRows(dataSource));
    }, [dataSource]);

    // Table Selection
    const { selectionConfig } = useTableSelection(rowSelection);

    // Columns
    const [columns, setColumns] = useState(columnsSource);
    const fixedLastColumn = useMemo(() => columnsSource
        .filter(({ titleType }) => titleType === 'columns'), []);
    const changeColumns = (values) => {
        setColumns((arr) => arr.map((item) => (item.titleType
            ? item : { ...item, hidden: !values.includes(item.key) })));
    };
    const resetColumns = () => {
        setColumns(collectColumns(
            columnsSource, data, expandedColumn, expandOnClick, orderColumns, selectionConfig,
        ));

        return columnsSource?.filter((i) => !i.hidden)?.map((i) => i.key);
    };

    useEffect(() => {
        setColumns(collectColumns(
            columnsSource, data, expandedColumn, expandOnClick, orderColumns, selectionConfig,
        ));
    }, [columnsSource, orderColumns, selectionConfig.selectedRowKeys]);

    // Drag&Drop
    const dragEnd = (fromIndex, toIndex) => {
        const columnsCopy = columns.slice();
        const columnsVisible = columns.filter(({ hidden }) => !hidden).slice();
        const itemVisibleFrom = columnsVisible[fromIndex];
        const indexFrom = columnsCopy.findIndex(({ key }) => key === itemVisibleFrom.key);
        const itemFrom = columnsCopy[indexFrom];
        const itemVisibleTo = columnsVisible[toIndex];
        const indexTo = columnsCopy.findIndex(({ key }) => key === itemVisibleTo.key);

        if (itemFrom.dragDisabled) return;

        columnsCopy.splice(indexFrom, 1);
        columnsCopy.splice(indexTo, 0, itemFrom);
        setColumns(columnsCopy);
    };
    const last = columns.length - 1;
    const isLastDisabled = useCallback(
        (target) => getDomIndex(target) === last && columns[last].dragDisabled, [columns],
    );
    const isFirstDisabled = useCallback(
        (target) => getDomIndex(target) === 0 && columns[0].dragDisabled, [columns],
    );
    const isDisabled = useCallback(
        (target) => isLastDisabled(target) || isFirstDisabled(target), [columns],
    );
    const onMouseDown = useCallback((!orderColumns ? () => {} : useDraggable(
        dragEnd,
        table.current,
        '.Table',
        ['th', 'td'],
        ['th'],
        isDisabled,
        ['th'],
    )), [orderColumns, table.current, isDisabled]);

    // Paging
    const [paging, setPaging] = useState(pagingSource || {});
    const [pagingTotal, setPagingTotal] = useState(data.length);
    const changePagination = (currentPage) => {
        setPaging((obj) => ({ ...obj, currentPage }));
    };

    useEffect(() => {
        if (pagingSource) setPaging({ currentPage: 1, ...pagingSource });
    }, [pagingSource]);

    useEffect(() => {
        if (pagingSource && pagingSource.total) setPagingTotal(pagingSource.total);
    }, [pagingSource]);

    // Filtering
    const [filtering, setFiltering] = useState(filteringSource || []);
    const changeFiltering = useCallback((filter) => {
        const newFiltering = filtering.filter(({ key }) => key !== filter.key);

        if (pagingSource) changePagination(1);
        if (filter.value.length) setFiltering([...newFiltering, filter]);
        else setFiltering(newFiltering);
    }, [filtering, pagingSource, changePagination]);

    // Sorting
    const [sorting, setSorting] = useState(sortingSource || {});

    useEffect(() => {
        if (!sorting.key) setSorting(getSortingFromColumns(columnsSource));
    }, [columnsSource]);

    return {
        paging,
        pagingTotal,
        columns,
        footerColumns,
        fixedLastColumn,
        sorting,
        filtering,
        expandedRows,
        expandOnClick,
        changeColumns,
        resetColumns,
        changeSorting: setSorting,
        changeFiltering,
        changePagination,
        changePaginationTotal: setPagingTotal,
        onMouseDown,
            };
};
