import { PostActivationKeyData } from 'additiv-services/types/services/clientService';
import {
    getClientServiceUrl, getClientServiceMemberUrl, getClientServiceHeader, getCustomUrl,
} from '../../setup';
import Service from './service';
import { APIVersion } from '../../types/main';

class ClientService extends Service {
    static postContact(data: any) {
        return this.postRequest(`${getClientServiceMemberUrl()}/contacts`, data, {
            isSecure: true, axiosSetup: { headers: getClientServiceHeader() },
        });
    }

    static getContact(contactId: number) {
        return this.getRequest(`${getClientServiceMemberUrl()}/contacts/${contactId}/details`, {
            isSecure: true, axiosSetup: { headers: getClientServiceHeader() },
        });
    }

    static updateContact(contactId: number, data: any) {
        return this.putRequest(`${getClientServiceMemberUrl()}/contacts/${contactId}/details`, data, {
            isSecure: true, axiosSetup: { headers: getClientServiceHeader() },
        });
    }

    static patchContact(contactId: number, data: any) {
        return this.patchRequest(`${getClientServiceMemberUrl()}/contacts/${contactId}/details`, data, {
            isSecure: true, axiosSetup: { headers: getClientServiceHeader() },
        });
    }

    static postActivationKey(contactId: number, data: PostActivationKeyData) {
        return this.postRequest(`${getClientServiceMemberUrl(APIVersion.V11)}/contacts/${contactId}/activationkey/send`, data, {
            isSecure: true, axiosSetup: { headers: getClientServiceHeader() },
        });
    }

    static getSchema(key: string) {
        return this.getRequest(`${getClientServiceUrl()}/schemas/${key}`, {
            isSecure: true, axiosSetup: { headers: getClientServiceHeader() }, cache: true,
        });
    }

    static getSchemaFields(key: string) {
        return this.getRequest(`${getClientServiceUrl()}/schemas/${key}/fields-metadata`, {
            isSecure: true, axiosSetup: { headers: getClientServiceHeader() }, cache: true,
        });
    }

    static getSchemasLists(language: string) {
        return this.getRequest(`${getClientServiceUrl(APIVersion.V11)}/schemas/lists/items?language=${language}`, {
            isSecure: true, axiosSetup: { headers: getClientServiceHeader() }, cache: true,
        });
    }

    static getSchemaListByKey(key: string) {
        return this.getRequest(`${getClientServiceUrl(APIVersion.V11)}/schemas/lists/${key}/items`, {
            isSecure: true, axiosSetup: { headers: getClientServiceHeader() },
        });
    }
}

export default ClientService;
