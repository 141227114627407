import { useQuery } from '@tanstack/react-query';
import ServerError from '../../errors/ServerError';
import SM from '../../services/ServiceManager';

export const useGetInvestmentApp = ({
    containerOrApplicationId, language, isContainer, isEnabled = true,
}) => {
    const result = useQuery({
        queryKey: ['getInvestmentApplicationOverview', containerOrApplicationId, language, isContainer],
        enabled: !!containerOrApplicationId && !!language && typeof isContainer === 'boolean' && isEnabled,
        retry: false,
        gcTime: 30, // seconds
        queryFn: async () => {
            const { data } = await SM.customInvestmentService(
                'getInvestmentApplicationOverview',
                [{
                    containerOrApplicationId, language, isContainer,
                }],
            );

            return isContainer ? data : (data && [data]);
        },
    });

    return {
        ...result,
        error: result.error ? new ServerError(result.error) : result.error,
    };
};
