import { useContext } from 'react';

import PlanWizardLayoutContext from './context';

const usePlanWizardLayout = () => {
    const context = useContext(PlanWizardLayoutContext);

    if (context === undefined) {
        throw new Error('usePlanWizardLayout can only be used inside PlanWizardLayoutContext');
    }

    return context;
};

export default usePlanWizardLayout;
