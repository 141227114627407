import { FC } from 'react';
import { Logo } from 'components/Logo';

type NavigationItem = {
    title: string,
    link: string,
    active: boolean,
    hasChanges?: boolean,
};
type notificationsDataItem = {
    id: number,
    subject?: string,
    text?: string,
    date?: string,
};
type ProfileInfo = {
    name?: string,
    image?: string,
    email?: string,
    withAlert?: boolean,
    onLogout: void,
    notifications: {
        data?: notificationsDataItem[],
        onReadNotification: void,
        emptyMessage?: string,
        errorMessage?: string,
    },
};
type Button = string | { type: string, label: string };

export type Header = {
    link?: string,
    content?: FC,
    clientName?: string,
    showClientTitle?: boolean,
    dfsClientId?: string,
    showTopNavigation?: boolean,
    contentProps?: { clientName?: string },
    leftButton?: Button,
    onLeftButtonClick?: void,
    rightButton?: Button,
    onRightButtonClick?: void,
    navigation?: NavigationItem[],
    navigationTablet?: FC,
    profileInfo?: ProfileInfo,
    quickAccessMenuComponent?: FC,
    containerClassName?: string,
    headComponent?: FC,
};

export enum HeaderActionType {
    init = 'init',
    change = 'change',
    clear = 'clear'
}

export type HeaderPayload = {
    type: HeaderActionType.init | HeaderActionType.clear | HeaderActionType.change,
    payload: Header
}

export type HeaderAction = HeaderPayload;

export const defaultHeaderState: Header = {
    content: Logo,
    leftButton: undefined,
    rightButton: undefined,
};
