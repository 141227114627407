import React from 'react';
import './LabaledValue.css';

const LabeledValue = ({ label, children }) => (
    <div>
        <dt>{label}</dt>
        <dd>{children}</dd>
    </div>
);

export default LabeledValue;
