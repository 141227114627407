import React, { useCallback, useMemo, useState } from 'react';
import './InvestmentApplicationHeader.css';
import {
    Button, Icon, notification, Modal, Loader,
} from 'ui-library';
import { ICON_TYPE_REMOVE } from 'ui-library/components/Icon';
import { useTranslation } from 'react-i18next';
import { BUTTON_TYPE_SECONDARY } from 'ui-library/components/Button/constants';
import Card from 'components/Card/Card';
import ServiceManager from 'services/ServiceManager';
import { STATUS } from 'components/InvestmentApplicationsActionButton/InvestmentApplicationsActionButton';
import {useParams} from 'react-router-dom';
import {isNil} from 'lodash/fp';
import {useActions} from 'components/InvestmentApplicationsActionButton/hooks/useActions';
import { useClientApplicationOverview } from '../../context';
import {useLayout} from '../../../../../../prodivers/layout';
import {getClientId} from '../../../../../../utils';

type InvestmentApplicationHeaderProps = {
    containerId?: string;
  /*  applicationId?: string; */
}

const InvestmentApplicationHeader: React.FC<InvestmentApplicationHeaderProps> = ({ containerId}) => {
    const {dfsClientId} = useParams();
    const clientId = getClientId(dfsClientId);
    const { t } = useTranslation();
    const [inProgress, setInProgress] = useState(false);
    const {
        appsData,
        getInvestmentApplications,
    } = useClientApplicationOverview();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { setLoadingOverlayActive } = useLayout();

    const allApplicationsCanceled = useMemo(() => appsData?.every(app => app.rawStatus === STATUS.canceled), [appsData]);
    const anyApplicationSubmitted = useMemo(() => appsData?.some(app => app.rawStatus === STATUS.submitted), [appsData]);
    const anyInProgress = useMemo(() => appsData?.some(app => app.rawStatus === STATUS.in_progress), [appsData]);
    const {continueApplication} = useActions();
    const hideLoaders = () => {
        setInProgress(false);
        setLoadingOverlayActive(false);
    };

    const cancelInvestmentApplication = useCallback(async () => {
        try {
            setInProgress(true);
            await ServiceManager.customInvestmentService(/* applicationId ? 'cancelApplication' : */ 'cancelApplicationsByContainerId', [{/* applicationId, */ containerId }]);
            // here to handle additional changes after cancellation
            notification.open({ content: t('dashboard.overviewClientApplications.canceledSuccessfully'), type: 'success' });
        } catch (err) {
            notification.open({ content: t('dashboard.overviewClientApplications.cancelError'), type: 'error' });
        } finally {
            setInProgress(false);
            await getInvestmentApplications();
        }
    }, [containerId/* , applicationId */]);

    const onCancelClick = useCallback(() => {
        /* eslint-disable-next-line */
        /* @ts-ignore */
        Modal.confirm({
            title: t('dashboard.overviewClientApplications.deleteAllApplications'),
            content: t('dashboard.overviewClientApplications.confirmMessage'),
            okText: inProgress ? <Loader /> : t('dashboard.overviewClientApplications.confirmOk'),
            cancelText: t('dashboard.overviewClientApplications.confirmCancel'),
            onOk: cancelInvestmentApplication,
        });
    }, [cancelInvestmentApplication]);

    const onContinueApplication = useCallback(async () => {
        try {
            setInProgress(true);
            const investmentApplicationToResume = appsData?.find((investmentApplication) => {
                const agentInformation = investmentApplication?.investmentDescription?.agentInformation;

                return isNil(agentInformation) || isNil(agentInformation.issueCommission) || isNil(agentInformation.pbNumber);
            });

            await continueApplication({
                contactId: clientId,
                hideLoaders,
                investmentApplicationId: investmentApplicationToResume?.applicationId || appsData?.[0]?.applicationId,
            });
        } catch (err: any) {
            notification.open({content: err?.message, type: 'error'});
            hideLoaders();
        }
    }, [clientId, appsData]);

    if (allApplicationsCanceled || anyApplicationSubmitted) {
        return <></>;
    }

    return (
        <Card>
            <div className="InvestmentApplicationHeader__container">
                <div className="InvestmentApplicationHeader__actionButtons">
                    <Button onClick={onContinueApplication} type={BUTTON_TYPE_SECONDARY} >
                        {inProgress ? <Loader /> : (
                            <>
                                {t('dashboard.overviewClientApplications.createNewPlan')}
                            </>
                        )}
                    </Button>
                    {
                        anyInProgress && (
                            <Button onClick={onCancelClick} type={BUTTON_TYPE_SECONDARY} >
                                {inProgress ? <Loader /> : (
                                    <>
                                        {/* eslint-disable-next-line */}
                                        {/* @ts-ignore */}
                                        <Icon size={24} type={ICON_TYPE_REMOVE} />
                                        {t('dashboard.overviewClientApplications.deleteAllApplications')}
                                    </>
                                )}
                            </Button>
                        )
                    }

                </div>
            </div>
        </Card>
    );
};

export default InvestmentApplicationHeader;

InvestmentApplicationHeader.defaultProps = {
    containerId: undefined,
    /*  applicationId: undefined, */
};
