import React from 'react';
import PropTypes from 'prop-types';
import FooterCell from './FooterCell';
import { createUniqueKey } from '../../../utils';

function Footer(props) {
    const { columns, footer } = props;

    if (!footer) return null;

    return (
        <tfoot>
            <tr role="row">
                {columns.map((column, index) => (
                    <FooterCell
                        column={column}
                        data={footer}
                        item={footer[column.key]}
                        key={createUniqueKey(index, column)}
                    />
                ))}
            </tr>
        </tfoot>
    );
}

Footer.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.string,
        title: PropTypes.string,
        width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        minWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        hidden: PropTypes.bool,
        sortable: PropTypes.bool,
        className: PropTypes.string,
    })),
    footer: PropTypes.shape({
        [PropTypes.string]: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
            PropTypes.func,
        ]),
    }),
};

Footer.defaultProps = {
    columns: [],
    footer: null,
};

export default Footer;
