import moment from 'moment';
import React, { useMemo } from 'react';
import {Button, Table, Title} from 'ui-library';
import {useFormatting} from 'locale';
import {useOptionList} from 'hooks/useOptionList';
import {OPTION_LIST} from 'constants/constants';
import { isEmpty } from 'lodash';
import { dateFormatZurich } from 'utils/datetime';
import {
    nextReallocationColumnDefs,
} from 'pages/ClientOverview/pages/Portfolios/pages/SinglePortfolioView/pages/Strategy/columnDefs';
import RiskScale from '../../../../components/RiskScale/RiskScale';
import {InvestmentStrategyAllocations} from '../../../../components/InvestmentStrategyAllocations';

import './Strategy.css';

const BasicStrategy = ({
    onChangePlan,
    allocations,
    portfolioProducts,
    t,
    modelPortfolioDescription,
    riskCategories,
    strategy,
    getFormattedXAxisFormat,
    isPortfolioChangeable,
    ongoingPlan,
    riskName,
    withdrawalData,
}) => {
    const { getFormattedCurrency, getFormattedDate } = useFormatting();
    const {
        lists: [optionWithdrawalPeriodicity],
    } = useOptionList([OPTION_LIST.WithdrawalPeriodicity]);

    const frequency = useMemo(() => optionWithdrawalPeriodicity?.find(f => f.id === withdrawalData?.frequency)?.label, [withdrawalData, optionWithdrawalPeriodicity]);

    const reallocationColumns = useMemo(() => nextReallocationColumnDefs(t, getFormattedDate), [t, getFormattedDate]);

    return (
        <>
            <div className="col-xs-12 col-lg-6">
                <div className="fi-verticals">
                    <dl className="Strategy__Summary">
                        <div>
                            <dt className="Strategy__Summary__Key">{t('portfolioDetail.strategy.chosenInvestmentRisk')}</dt>
                            <dd className="Strategy__Summary__Value">
                                <div>{riskName}</div>
                            </dd>
                        </div>
                        <div>
                            <dt className="Strategy__Summary__Key">{t('portfolioDetail.strategy.chosenInvestmentStrategy')}</dt>
                            <dd className="Strategy__Summary__Value">
                                {
                                    portfolioProducts.map((product) => (
                                        <>
                                            <div>{product?.name}</div>
                                            {!!product?.factsheetUrl && (
                                                <div>
                                                    <a
                                                        href={product?.factsheetUrl}
                                                        target="_blank"
                                                        className="button"
                                                        rel="noreferrer"
                                                    >
                                                        {t('portfolio.createNewPortfolio.strategy.factsheetUrl')}
                                                    </a>
                                                </div>
                                            )}
                                        </>
                                    ))
                                }

                            </dd>
                        </div>
                    </dl>
                    <div>
                        <Title type={3}>
                            {t('portfolioDetail.strategy.modelPortfolioDetails')}
                        </Title>
                        <p>
                            {modelPortfolioDescription}
                        </p>
                    </div>
                    <div>
                        <dt className="Strategy__Summary__Key">{t('portfolio.createNewPortfolio.risk')}</dt>
                        <dd className="Strategy__Summary__Value">
                            <div>
                                <RiskScale scales={riskCategories} activeIndex={strategy}/>
                            </div>
                        </dd>
                    </div>
                    {withdrawalData?.bankIban && (
                        <dl className="Strategy__Summary withdrawal">
                            <div>
                                <Title type={3}>
                                    {t('portfolio.createNewPortfolio.withdrawalPlan')}
                                </Title>
                            </div>
                            {(withdrawalData?.bankName || withdrawalData?.bankIban) && (
                                <div className="bankInfo">
                                    <dt className="Strategy__Summary__Key">{t('portfolio.createNewPortfolio.bankInfo')}</dt>
                                    <dd className="Strategy__Summary__Value">{withdrawalData?.bankName}</dd>
                                    <dd className="Strategy__Summary__Value">{withdrawalData?.bankIban}</dd>
                                </div>
                            )}
                            <div>
                                <dt className="Strategy__Summary__Key">{t('3b.withdrawalPlan.amount')}</dt>
                                <dd className="Strategy__Summary__Value">

                                    {`CHF ${getFormattedCurrency(withdrawalData?.withdrawalAmount, {
                                        maximumFractionDigits: 2,
                                        minimumFractionDigits: 2,
                                    })}`}
                                </dd>
                            </div>
                            <div>
                                <dt className="Strategy__Summary__Key">{t('3b.withdrawalPlan.periodicity')}</dt>
                                <dd className="Strategy__Summary__Value">{frequency}</dd>
                            </div>
                            {withdrawalData.startOfWithdrawal && (
                                <div>
                                    <dt className="Strategy__Summary__Key">{t('3b.withdrawalPlan.withdrawalsStart')}</dt>
                                    <dd className="Strategy__Summary__Value">{moment(withdrawalData.startOfWithdrawal).format('MM/YYYY')}</dd>
                                </div>
                            )}
                            {withdrawalData.accountHolder && (
                                <div>
                                    <dt className="Strategy__Summary__Key">{t('3b.withdrawalPlan.accountHolder')}</dt>
                                    <dd className="Strategy__Summary__Value">{withdrawalData.accountHolder}</dd>
                                </div>
                            )}
                            {withdrawalData.zipCode && (
                                <div>
                                    <dt className="Strategy__Summary__Key">{t('portfolio.transferPlanModal.zip')}</dt>
                                    <dd className="Strategy__Summary__Value">{withdrawalData.zipCode}</dd>
                                </div>
                            )}
                            {withdrawalData.city && (
                                <div>
                                    <dt className="Strategy__Summary__Key">{t('portfolio.transferPlanModal.city')}</dt>
                                    <dd className="Strategy__Summary__Value">{withdrawalData.city}</dd>
                                </div>
                            )}
                            {!isEmpty(withdrawalData.targetPortfolio) && (
                                <div>
                                    <dt className="Strategy__Summary__Key">{t('3b.withdrawalPlan.targetPortfolio')}</dt>
                                    <dd className="Strategy__Summary__Value">{withdrawalData.targetPortfolio.name}</dd>
                                </div>
                            )}
                            {withdrawalData.nextTransfer && (
                                <div>
                                    <dt className="Strategy__Summary__Key">{t('portfolio.transferPlans.nextTransfer')}</dt>
                                    <dd className="Strategy__Summary__Value">{moment(withdrawalData.nextTransfer).format(dateFormatZurich)}</dd>
                                </div>
                            )}
                        </dl>
                    )}
                </div>
            </div>
            <div className="col-xs-12 col-lg-6">
                <div className="fi-verticals Strategy__Description">
                    <div className="Strategy__Chart">
                        <InvestmentStrategyAllocations
                            allocations={allocations}
                            getFormattedXAxisFormat={getFormattedXAxisFormat}
                        />
                    </div>
                    {isPortfolioChangeable && (
                        <>
                            <div>
                                <Title type={3}>
                                    {t('portfolioDetail.strategy.changeStrategyTitle')}
                                </Title>
                                <p>
                                    {t('portfolioDetail.strategy.changeStrategyText')}
                                </p>
                                <div className="button-group">
                                    <Button
                                        data-cy="changePlanBtn"
                                        disabled={ongoingPlan}
                                        onClick={onChangePlan}
                                    >
                                        {t('portfolioDetail.strategy.changePlanBtn')}
                                    </Button>
                                </div>
                            </div>
                        </>
                    )}
                    {!!withdrawalData?.nextReallocations?.length && (
                        <div>
                            <dt className="Strategy__Summary__Key">{t('portfolio.createNewPortfolio.nextReallocation')}</dt>
                            <Table columns={reallocationColumns} data={withdrawalData.nextReallocations} />
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default BasicStrategy;
