import { useContext } from 'react';
import { QualityCheckContext } from './context';

export const useSelector = (callback) => {
    const { getValue } = useContext(QualityCheckContext);

    if (getValue === undefined) {
        throw new Error('useSelector can only be used inside QualityCheckProvider');
    }

    return getValue(callback);
};
