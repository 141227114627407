import React from 'react';

import './Card.css';
import {Link} from 'react-router-dom';

function Card(props) {
    const {
        titleTagName,
        className,
        title,
        titleLink,
        headerActions,
        children,
        ...rest
    } = props;
    const TitleTagName = titleTagName || 'h2';

    return (
        <div className={`Card${className ? ` ${className}` : ''}`} {...rest}>
            {title
                && (
                    <div className="Card__Header">
                        <TitleTagName className="Card__Header__Title">
                            {titleLink ? <Link to={titleLink}>{title}</Link> : <>{title}</>}
                        </TitleTagName>
                        {(headerActions && <div className="Card__Header__Actions">{headerActions}</div>)}
                    </div>
                )}
            <div className="Card__Content">
                {children}
            </div>
        </div>
    );
}

export default Card;
