export const DEFAULT_PAGE_SIZE = 3;

export const START_PAGINATION_PAGE = 1;

export const columns = (t) => [
    {
        key: 'applicationId',
        title: t('dashboard.overviewClientApplications.applicationId'),
    },
    {
        key: 'createdDate',
        title: t('dashboard.agentCockpit.createdDate'),
    },
    {
        key: 'validTo',
        title: t('dashboard.agentCockpit.validTo'),
    },
    {
        key: 'advisor',
        title: t('dashboard.overviewClientApplications.advisor'),
    },
    {
        key: 'createdBy',
        title: t('dashboard.overviewClientApplications.createdBy'),
    },
    /* {
        key: 'modifiedBy',
        title: t('dashboard.overviewClientApplications.lastModifiedBy'),
    }, */
    {
        key: 'status',
        title: t('dashboard.agentCockpit.status'),
    },
    {
        key: 'submitted',
        title: t('dashboard.agentCockpit.submitted'),
    },
    {
        key: 'submittedBy',
        title: t('dashboard.overviewClientApplications.submittedBy'),
    },
];
