import {useContact} from 'domain/ClientService/hooks/useContact';
import {convertDate} from 'pages/ClientOnboardingFlow/pages/NewsPlan/NewPersonalInformationPage/helpers/convertDate';
import {validateDateOfBirth, validatePhoneNumber, validateRadio} from 'validation/validation';
import React, {
    useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import SM from 'services/ServiceManager';
import {Modal} from 'ui-library';
import {getClientId} from 'utils';
import {useNavigate} from 'react-router-dom';
import {getModel} from './models/getModel';

import 'ui-library/components/Button/Button.css';
import 'ui-library/components/DatePicker/DatePicker.css';
import 'ui-library/components/Label/Label.css';
import 'ui-library/components/TextInput/TextInput.css';
import {getValidationMessage} from '../../../../../../../../validation/messages';
import {getUpperAgeLimitPerProduct} from '../../utils';
import './PersonalDetails.css';
import {DEFAULT_NUMBER_OF_LOADED_ITEMS} from './constants/const';

function usePersonalDetails({
    newPortfolio, dfsClientId, language, setPageErrors, t, getFormattedDate, isJA = false,
}) {
    const navigate = useNavigate();
    const retiredRef = useRef(null);
    const [data, setData] = useState({});

    const [isLoading, setLoading] = useState(true);
    const [isLoadingPersonalData, setLoadingPersonalData] = useState(false);

    const userClientAgeExact = useMemo(() => parseFloat(global.sessionStorage.getItem('userClientAgeExact') || 0), []);

    const [personalDetails, setPersonalDetails] = useState({});
    const [occupationListOptions, setOccupationListOptions] = useState([]);
    const [error, setError] = useState(null);
    const [errorData, setErrorData] = useState({});
    const [saveContactDetailsInProgress, setSaveContactDetailsInProgress] = useState(false);
    const [searchInProgress, setSearchInProgress] = useState(false);
    const [hasMoreOccupations, setHasMoreOccupations] = useState(false);

    const productSettings = useMemo(() => (newPortfolio?.product?.settings), [newPortfolio?.product?.settings]);
    const showEmploymentContinuationCheck = useMemo(() => userClientAgeExact >= getUpperAgeLimitPerProduct(data?.gender, productSettings?.productKey),
        [userClientAgeExact, data, productSettings]);
    const clientId = getClientId(dfsClientId);

    const getOccupations = useCallback(async (params, appendResult) => {
        if (data?.gender && language) {
            const requestData = {
                language,
                gender: data?.gender,
                ...params,
            };
            const {data: occupations} = await SM.customService('getOccupationsLookup', [requestData]);

            const optionItems = occupations?.map(({id, name, disabled}) => ({id, label: name, disabled}));

            if (appendResult) {
                setOccupationListOptions(prev => [...prev, ...optionItems]);
            } else {
                setOccupationListOptions(optionItems);
            }
            setHasMoreOccupations(occupations.length >= DEFAULT_NUMBER_OF_LOADED_ITEMS);
        }
    }, [language, data?.gender]);

    const handleSearchOccupations = useCallback(async (value) => {
        try {
            setSearchInProgress(true);
            const payloadData = {
                skip: 0,
                name: value,
            };

            await getOccupations(payloadData, false);
        } finally {
            setSearchInProgress(false);
        }
    }, [getOccupations]);

    const loadMoreItems = useCallback(async (page, searchValue) => {
        if (hasMoreOccupations && !searchInProgress) {
            const payloadData = {
                skip: page * DEFAULT_NUMBER_OF_LOADED_ITEMS,
            };

            if (searchValue) {
                payloadData.name = searchValue;
            }

            await getOccupations(payloadData, true);
        }
    }, [getOccupations, hasMoreOccupations, searchInProgress]);

    useEffect(() => {
        const params = {page: 0};

        if (data?.occupation) {
            params.selectedId = data?.occupation;
        }
        if (data) (async () => getOccupations(params, false))();
    }, [getOccupations, data?.occupation]);

    useEffect(() => {
        /* Is portfolio selected? */
        if (newPortfolio?.product?.name === undefined || newPortfolio?.product?.name === null) {
            setError({ message: t('onboardingFlow.personalPage.noNewPortfolioSelected') });
        } else {
            setError(null);
        }
    }, [newPortfolio]);

    const { getContact } = useContact({ contactId: clientId });

    const cachedAdditionalData = useMemo(() => (newPortfolio?.applicationData?.investmentDescription?.personalDetails), [newPortfolio?.applicationData?.investmentDescription?.personalDetails]);

    useEffect(() => {
        /* Get user data */
        if (clientId) {
            setLoading(true);
            getContact({
                contactId: clientId,
            }).then((contactDataForm) => {
                setPersonalDetails(contactDataForm);
                // setErrorData(errorModelEditablePersonalData(getModel(contactDataForm, newPortfolio)));
            }).catch((err) => {
                setPageErrors(err.message);
            }).finally(() => {
                setLoading(false);
            });
        }
    }, [clientId]);

    useEffect(() => {
        if (Object.keys(personalDetails).length) setData(getModel(personalDetails, !isJA && cachedAdditionalData, getFormattedDate));
    }, [cachedAdditionalData, personalDetails, isJA]);

    const editPersonalData = () => {
        navigate(`/client/${dfsClientId}/details`);
    };

    const onDateClick = (date) => {
        const formatDateValue = convertDate(date);

        setData({ ...data, dateOfBirth: `${formatDateValue}` });
        setErrorData({
            ...errorData,
            dateOfBirth: validateDateOfBirth(formatDateValue),
        });
    };

    const modalConfirmationContent = useMemo(() => (
        <div className="modal-content">
            <div>{t('onboardingFlow.personalPage.retiredButStillEmployedConfirmationContent1')}</div>
            <div>{t('onboardingFlow.personalPage.retiredButStillEmployedConfirmationContent2')}</div>
            <div>{t('onboardingFlow.personalPage.retiredButStillEmployedConfirmationContent3')}</div>
        </div>
    ), [t]);

    const onRetiredButStillEmployedChange = useCallback((e) => {
        if (e.target?.checked) {
            Modal.confirm({
                triggerCancelOnClose: true,
                title: t('onboardingFlow.personalPage.retiredButStillEmployedConfirmationTitle'),
                content: modalConfirmationContent,
                okText: t('onboardingFlow.personalPage.retiredButStillEmployedConfirmationOk'),
                width: 450,
                className: 'employment-continuation-confirmation-modal',
                onOk: () => {
                    setData({
                        ...data,
                        employmentContinuation: true,
                        employmentContinuationDate: new Date(),
                    });
                    setErrorData({
                        ...errorData,
                        employmentContinuation: null,
                    });
                },
                onCancel: () => {
                    retiredRef.current?.click();
                },
                cancelText: t('onboardingFlow.personalPage.retiredButStillEmployedConfirmationCancel'),
            });
        } else {
            setData({
                ...data,
                employmentContinuation: false,
                employmentContinuationDate: new Date(),
            });
            setErrorData({
                ...errorData,
                employmentContinuation: getValidationMessage().mandatoryError,
            });
        }
    }, [data]);

    const passData = (value, key) => {
        setErrorData({ ...errorData, [key]: validateRadio(value) });

        const newData = { ...data };

        newData[key] = value;

        if (key === 'destinedLife' && value) {
            newData.member = false;
        } else if (key === 'member' && value) {
            newData.destinedLife = false;
        }

        setData(newData);
    };

    const changeMobileMask = (value, key, symbol) => {
        if (value !== undefined) {
            if ((value === '+' && data[key] !== '') || (data[key] === '+' && value === '')) {
                setData({ ...data, [key]: '' });
            } else if ((value).indexOf(symbol) !== -1) {
                setData({ ...data, [key]: value });
            } else {
                setData({ ...data, [key]: symbol.concat(value) });
            }
        }
        setErrorData({
            ...errorData,
            [key]: validatePhoneNumber(value),
        });
    };

    return {
        data,
        productSettings,
        showEmploymentContinuationCheck,
        setErrorData,
        setSaveContactDetailsInProgress,
        clientId,
        isLoading,
        isLoadingPersonalData,
        saveContactDetailsInProgress,
        error,
        editPersonalData,
        errorData,
        setData,
        onDateClick,
        passData,
        changeMobileMask,
        retiredRef,
        onRetiredButStillEmployedChange,
        occupationListOptions,
        handleSearchOccupations,
        searchInProgress,
        loadMoreItems,
        hasMoreOccupations,

    };
}

export default usePersonalDetails;
