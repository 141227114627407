import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
    getPortfolioStatus,
    PENDING_ORDERS,
} from 'constants/portfolioStatuses';
import { usePortfolio } from 'domain/Portfolio';
import Preloader from 'components/Preloader';
import PropTypes from 'prop-types';
import PageNotFoundContent from 'components/PageNotFound/PageNotFoundContent';
import { getClientId } from 'utils';
import PageVariantWrapper from 'components/PageVariantWrapper';
import {useParams} from 'react-router-dom';
import { PageVariant } from '../../../../../ui-library';

const withActionAccess = (Component) => {
    const Action = () => {
        const { portfolio: { data, isLoading, error } } = usePortfolio();
        const { t } = useTranslation();
        const status = getPortfolioStatus({
            portfolioStatusId: data?.portfolioStatusId,
            isBreached: data?.isBreached,
            isOrderBookBlocked: data?.hasPendingOrders,
        });

        const noActions = useMemo(() => [
            PENDING_ORDERS].includes(status), [status]);

        const pageNotFound = useMemo(() => [].includes(status), [status]);

        if (data?.portfolioStatusId && pageNotFound) {
            return (
                <Preloader isLoading={isLoading} error={error}>
                    <PageNotFoundContent />
                </Preloader>
            );
        }

        if (data?.portfolioStatusId && noActions) {
            return (
                <Preloader isLoading={isLoading} error={error}>
                    <PageVariantWrapper className="page-not-found">
                        <PageVariant
                            type="error"
                            title={t('error.title')}
                            message={t('portfolio.actionNotAvailable')}
                        />
                    </PageVariantWrapper>
                </Preloader>
            );
        }

        return <Component status={status} />;
    };

    return Action;
};

export default withActionAccess;
