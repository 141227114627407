import React, {
    useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import {Link, NavLink as RouterLink} from 'react-router-dom';
import { createUniqueKey, getClassNames } from '../../utils';
import { getFullWidth } from '../../utils/dom';
import HeaderNavigationDropdown from './HeaderNavigationDropdown';

function HeaderNavigation({ labelMore, navigation }) {
    const headerNavigationRef = useRef();
    const childrenWidth = useRef();
    const [itemsHidden, setItemsHidden] = useState(-1);
    const length = useMemo(() => navigation?.length, [navigation]);
    const calculateChildrenWidth = useCallback(() => {
        let acc = 0;

        childrenWidth.current = Object.values(headerNavigationRef.current?.childNodes || {})
            .map((item) => {
                acc += getFullWidth(item, true);

                return acc;
            });
    }, [headerNavigationRef]);
    const handleResize = useCallback(() => {
        const width = headerNavigationRef.current.offsetWidth;
        const idxFirstInDropdown = childrenWidth.current.findIndex((item) => item > width);

        setItemsHidden(idxFirstInDropdown === -1 ? -1 : length - idxFirstInDropdown);
    }, [headerNavigationRef.current, childrenWidth, setItemsHidden]);
    const linkClassName = ({ active, hasChanges }) => getClassNames(
        'Navigation__link',
        { 'Navigation__link-active': active },
        { Navigation__link_changed: hasChanges },
    );


    const renderLink = ({
        title, link, active, name, hasChanges, ...rest
    }, index) => {

        return rest.to ? (
            <a key={title} href={rest.to.pathname} data-cy={name} target={rest.target} className={linkClassName({
                active,
                hasChanges
            })}>{title}</a>
        ):(
            <RouterLink
                key={title}
                to={link}
                data-cy={name}
                className={linkClassName({
                    active,
                    hasChanges
                })}
                {...rest}
            >
                {title}
            </RouterLink>
        );
    };

    const renderDropdown = ({
        title, active, hasChanges, submenu,
    }, index) => (
        <div className={`${linkClassName({ active, hasChanges })} hasDropdown`} key={createUniqueKey(index, title)}>
            <HeaderNavigationDropdown
                navigation={submenu}
                renderLink={renderLink}
                labelMore={title}
                title={title}
                key={title}
            />
        </div>
    );

    const renderMenuItem = (item, index) => {
        if (itemsHidden > 0 && item?.submenu) {
            return item?.submenu.map(renderLink);
        }

        return (
            item?.submenu
                ? renderDropdown(item, index)
                : renderLink(item, index)
        );
    };

    const renderNavigationDropdown = useMemo(() => {
        if (itemsHidden < 0) return null;

        const navSubIndex = navigation.findIndex((nav) => nav.submenu);
        const hasSubnav = navSubIndex !== -1;

        const initSlice = hasSubnav ? navigation.slice(0, navSubIndex) : [];
        const endSlice = hasSubnav ? navigation.slice(navSubIndex + 1, navigation.length) : [];
        const navList = hasSubnav
            ? [...initSlice, ...navigation[navSubIndex]?.submenu, ...endSlice]
            : navigation;

        return (
            <HeaderNavigationDropdown
                navigation={navList.slice(length - itemsHidden - 1)}
                renderLink={renderLink}
                labelMore={labelMore}
                key={labelMore}
            />
        );
    }, [itemsHidden, navigation, length, labelMore]);

    useEffect(() => {
        calculateChildrenWidth();
        handleResize();
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div className="Header__navigation" ref={headerNavigationRef}>
            {navigation.slice(0, length - itemsHidden - 1).map(renderMenuItem)}
            {renderNavigationDropdown}
        </div>
    );
}

HeaderNavigation.propTypes = {
    navigation: PropTypes.arrayOf(PropTypes.shape({
        link: PropTypes.string,
        title: PropTypes.string,
        active: PropTypes.bool,
        hasChanges: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
        submenu: PropTypes.arrayOf(PropTypes.shape({
            link: PropTypes.string,
            title: PropTypes.string,
            active: PropTypes.bool,
            hasChanges: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
        })),
    })),
    labelMore: PropTypes.string.isRequired,
};

HeaderNavigation.defaultProps = {
    navigation: [],
};

export default HeaderNavigation;
