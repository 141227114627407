import React from 'react';
import {
    Route, Routes, Navigate, useParams,
} from 'react-router-dom';
import { getClientId } from 'utils';
import {ConnectionsManager} from 'components/Connections/components';
import ClientConnections from './ClientConnections';
import {
    NewConnectionsAddress, NewConnectionsCustomer, NewConnectionsRelationship, NewConnectionsSummary,
} from './components/NewConnections';
import {ContactGroupDetails} from './components/ContactGroupDetails/ContactGroupDetails';

const Router = () => {
    const {
        dfsClientId,
    } = useParams();
    const clientId = getClientId(dfsClientId);

    return (
        <ConnectionsManager clientId={clientId}>
            <Routes>
                <Route path="" element={<ClientConnections/>} />
                <Route path=":groupId/details" element={<ContactGroupDetails/>} />
                <Route path="new-connection/summary" element={<NewConnectionsSummary/>} />
                <Route path="new-connection/relationship" element={<NewConnectionsRelationship/>} />
                <Route path="new-connection/address" element={<NewConnectionsAddress/>} />
                <Route path="new-connection/customers" element={<NewConnectionsCustomer/>} />
                <Route
                    path="*"
                    element={<Navigate to="/404" replace />}
                />
            </Routes>
        </ConnectionsManager>
    );
};

Router.displayName = 'Router';

export default Router;
