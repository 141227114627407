import Service from 'additiv-services';

const handlerRequestCanceling = (callback) => (error) => {
    if (typeof callback === 'function') {
        if (!Service.isCanceled(error)) {
            callback(error);
        }
    } else {
        console.error('callback should be function');
    }
};

export default handlerRequestCanceling;
