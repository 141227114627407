import React, {useCallback, useMemo, useState} from 'react';
import { useParams } from 'react-router-dom';
import {
    Title, Modal,
    Button, ProjectionChart, Loader,
} from 'ui-library';

import {
    STATUS,
} from 'components/InvestmentApplicationsActionButton/InvestmentApplicationsActionButton';
import { isEmpty } from 'lodash';
import { useFormatting } from 'locale';
import {
    ConsultationBox,
} from 'pages/ClientOverview/pages/Portfolios/pages/CreateNewPortfolio/pages/GoalAndPortfolioSetup/components/ConsultationBox';
import {isThreeBProduct} from 'hooks/isThreeBProduct';
import { useOverview } from './hooks/useOverview';
import {useCreatePortfolio} from '../../context';
import {THREEBTYPES} from 'constants/constants';

import './Overview.css';

function AgentInformation() {
    const [showChart, setShowChart] = useState(null);
    const { dfsClientId } = useParams();
    const {
        t,
        strategy,
        showYearlyPayments,
        modelPortfolio,
        transferAmount,
        yearlyAmount,
        chartData,
        chartDataInProgress,
        projectionOptions,
        currency,
        pbValue,
        productDesc,
        onEdit,
        onDelete,
        startedApplications,
        onAddNewProduct,
        loadingAppsByContainer,
        getPlannedInvestment,
        isProofOfAssetsRequired,
    } = useOverview({
        dfsClientId,
        selectedAppId: showChart,
    });
    const {newPortfolio } = useCreatePortfolio();
    const isThreeB = isThreeBProduct(newPortfolio);

    const chart = useMemo(() => (
        showChart && (
            <Modal
                title={t('portfolio.createNewPortfolio.calculationExample')}
                visible={showChart}
                onOk={() => setShowChart(null)}
                width={600}
                cancelText={null}
            >
                <div>
                    {chartDataInProgress ? <Loader />
                        : (
                            <ProjectionChart
                                data={chartData}
                                yTitle={currency}
                                height={400}
                                {...projectionOptions}
                            />
                        )}
                </div>
            </Modal>
        )
    ), [chartData, currency, projectionOptions, showChart, chartDataInProgress]);

    const onChart = (appId) => {
        setShowChart(appId);
    };

    return (
        <div className="new-portfolio agent-info">
            <Title type={1}>
                {productDesc}
            </Title>
            <Title type={2}>{t('portfolio.createNewPortfolio.overview.portfolioOverview')}</Title>
            <div className="OverviewList">
                <div className="OverviewList__Header">
                    {!isThreeB ? (
                        <p className="OverviewList__Header__Title">
                            {productDesc}
                            {' '}
                            -
                            {' '}
                            {t('portfolio.createNewPortfolio.overview.strategy')}
                            {' '}
                            {strategy}
                        </p>
                    ) : <div/>}
                    {isThreeB ? <Button data-cy="chart" type="secondary" size="small" onClick={onAddNewProduct}>{t('interaction.addNewProduct')}</Button>
                        : (
                            <p>
                                {t('portfolio.createNewPortfolio.overview.PB')}
                                {': '}
                                {pbValue}
                            </p>
                        )}
                </div>
                <>
                    {loadingAppsByContainer ? <Loader/> : startedApplications?.map(app => (
                        <div className="OverviewList__Details" key={app?.investmentApplicationId ?? app?.applicationData?.investmentApplicationId}>
                            <dl className="indicators">
                                <div>
                                    <dt><strong>{t('portfolio.createNewPortfolio.overview.productName')}</strong></dt>
                                    <dd>{app.productName ?? app.product?.description}</dd>
                                </div>
                                <div>
                                    <dt><strong>{t('portfolio.createNewPortfolio.overview.investmentStrategy')}</strong></dt>
                                    <dd>{app.modelPortfolio?.Name ?? app.strategy?.toString()}</dd>
                                </div>
                                {isThreeB && (
                                    <>
                                        <div>
                                            <dt><strong>{t('portfolio.createNewPortfolio.overview.plannedInvestment')}</strong></dt>
                                            <dd>
                                                {getPlannedInvestment(app)}
                                            </dd>
                                        </div>
                                        <div>
                                            <dt><strong>{t('portfolio.createNewPortfolio.overview.withdrawalPlan')}</strong></dt>
                                            <dd>{t(app.hasWithdrawalPlan ?? !isEmpty(app.applicationData?.investmentDescription?.withdrawal) ? 'clientDashboard.yes' : 'clientDashboard.no')}</dd>
                                        </div>
                                    </>
                                )}
                                {!isThreeB && (
                                    <div>
                                        <dt><strong>{t('portfolio.createNewPortfolio.overview.transfer')}</strong></dt>
                                        <dd>{transferAmount}</dd>
                                    </div>
                                )}
                                {showYearlyPayments ? (
                                    <div>
                                        <dt><strong>{t('portfolio.createNewPortfolio.overview.yearlyAmount')}</strong></dt>
                                        <dd>{yearlyAmount}</dd>
                                    </div>
                                ) : <></>}
                            </dl>
                            {(app.status ?? app.applicationData?.status) !== STATUS.canceled && (
                                <div className="button-group">
                                    {(app?.product?.externalId !== THREEBTYPES.pk && app?.productExternalId !== THREEBTYPES.pk) && (
                                        <Button data-cy="chart" type="secondary" size="small" onClick={() => onChart(app.investmentApplicationId ?? app.applicationData.investmentApplicationId)} disabled={!chartData}>Chart</Button>
                                    )}
                                    <Button data-cy="edit" type="secondary" size="small" onClick={() => onEdit(app.investmentApplicationId ?? app.applicationData.investmentApplicationId)}>{t('interaction.edit')}</Button>
                                    <Button data-cy="delete" type="secondary" size="small" onClick={() => onDelete(app.investmentApplicationId ?? app.applicationData.investmentApplicationId)}>{t('clientDashboard.delete')}</Button>
                                </div>
                            )}
                        </div>
                    ))}
                    {isProofOfAssetsRequired && <ConsultationBox showButton={false} message="3b.performance.proofOfAssetsWarning" dfsClientId={dfsClientId}/> }
                </>
            </div>
            {chart}
        </div>
    );
}

export default AgentInformation;
