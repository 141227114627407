import {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import {useNavigate} from 'react-router-dom';
import moment from 'moment';
import {useTranslation} from 'react-i18next';
import {AdditionalData, InvestorProfileHook} from '../types/InvestorProfileHook';
import {useCreatePortfolio} from '../../../context';
import {isThreeBProduct, useThreeBProductType} from '../../../../../../../../../hooks/isThreeBProduct';
import {useContact} from '../../../../../../../../../domain/ClientService';
import {genarateDFSClientId, getClientId} from '../../../../../../../../../utils';
import SM from '../../../../../../../../../services/ServiceManager';
import {adaptModels, adaptStrategies} from '../../../../../adapters/adaptModelPortfolio';

export const useInvestorProfile = ({dfsClientId}): InvestorProfileHook => {
    const {i18n: {language}} = useTranslation();
    const navigate = useNavigate();
    const clientId = getClientId(dfsClientId);

    const {
        newPortfolio, saveNewPortfolio, layout: { setData: setLayoutData, setPageErrors},
    } = useCreatePortfolio();

    const [childClientName, setChildClientName] = useState('');
    const [isUsersLoading, setIsUsersLoading] = useState(true);

    const [targetDFSClientId, setTargetDFSClientId] = useState('');
    const [targetClientName, setTargetClientName] = useState(newPortfolio?.mainOwner?.name);
    const [clientAge, setClientAge] = useState(newPortfolio?.mainOwner?.age || newPortfolio?.userClientAge);
    const productDescription = useMemo(() => newPortfolio?.product?.description, [newPortfolio?.product?.description]);

    const isThreeB = isThreeBProduct(newPortfolio);

    const {
        isJA, isPk,
    } = useThreeBProductType(newPortfolio);

    const { getContact, error: contactError } = useContact({ contactId: clientId });

    const onBack = useCallback(() => {
        navigate(`/client/${dfsClientId}/portfolios/new-portfolio/personal-details`);
    }, []);

    const navigateForward = useCallback(() => {
        let navURL = `/client/${dfsClientId}/portfolios/new-portfolio/strategy-selection`;

        if (isPk) {
            navURL = `/client/${dfsClientId}/portfolios/new-portfolio/fees-overview`;
        }
        navigate(navURL);
    }, [isPk, navigate, dfsClientId]);

    const saveAdditionalData = useCallback(async ({clientId: mClientId, clientRiskToAccept, response}: AdditionalData) => {
        if (mClientId === clientRiskToAccept) {
            let additionalData: any = {recommendedStrategy: response};

            if (isPk) {
                const params = {language};

                const responseMP = await SM.portfolioManagement(
                    'getModelPortfolios',
                    [
                        newPortfolio?.applicationData?.investmentDescription?.productId,
                        params,
                    ],
                );

                const model = adaptModels(responseMP.data)[0];
                const riskCategory = adaptStrategies(responseMP.data)[0];

                const payload = {
                    strategyId: riskCategory?.Id,
                    investmentHorizon: 4,
                    investmentApplicationId: newPortfolio?.applicationData?.investmentApplicationId,
                    modelPortfolioId: model.Id,
                    withdrawalOption: false,
                };

                additionalData = {
                    ...additionalData,
                    riskCategory,
                    modelPortfolio: model,
                };

                await saveNewPortfolio({
                    data: payload,
                    method: 'saveStrategyData',
                    additionalData,
                });
            } else {
                await saveNewPortfolio({
                    additionalData,
                });
            }
        }
        if (isPk && newPortfolio?.applicationData?.investmentApplicationId) {
            await SM.customInvestmentService('saveProductData', [{investmentApplicationId: newPortfolio?.applicationData?.investmentApplicationId}]);
        }
    }, [language]);

    useEffect(() => {
        setLayoutData({
            stepNavBarActive: isThreeB ? 5 : 3,
            onBack,
        });
    }, [onBack, isThreeB]);

    useEffect(() => {
        if (isJA && newPortfolio?.connectionGroupId) {
            (async () => {
                setIsUsersLoading(true);
                let response;

                try {
                    response = await SM.customClientService('getContactGroupDetails', [{ groupId: newPortfolio.connectionGroupId }]);
                } catch (e) {
                    console.log('Issue with getting data from API: contactgroupdetails');
                }
                const users:Array<any> = [];

                Object.entries(response?.data?.owners || {}).sort((a, b) => +a - +b).forEach(([key, value]) => {
                    users.push({
                        id: key,
                        name: value,
                    });
                });

                setChildClientName(users?.[1]?.name ?? '');

                if (users?.[0]?.id !== clientId) {
                    const contactDataForm = await getContact({
                        contactId: users?.[0]?.id,
                    });

                    const jaUserClientAge = moment(moment().format('YYYY-MM-DD')).diff(moment(contactDataForm?.details?.personalInformation?.dateOfBirth), 'years', true);

                    setClientAge(`${jaUserClientAge}`);
                    setTargetDFSClientId(genarateDFSClientId(users?.[0]?.id));
                    setTargetClientName(users?.[0]?.name);
                }

                setIsUsersLoading(false);
            })();
        } else {
            setTargetDFSClientId(dfsClientId);
            setIsUsersLoading(false);
        }
    }, [isJA, newPortfolio?.connectionGroupId]);

    return {
        isJointAccount: isJA,
        isPk,
        setLayoutData,
        setPageErrors,
        isThreeB,
        clientAge,
        onBack,
        isUsersLoading,
        productDescription,
        saveAdditionalData,
        childClientName,
        navigateForward,
        targetClientName,
        targetDFSClientId,
    };
};
