import React from 'react';
import PropTypes from 'prop-types';
import '../Footer.css';
import withTheme from '../../../hocs/withTheme';

/** Use the footer as meta navigation containing all navigation
items of the website and additionally any social links. */

function FooterLarge({ children }) {
    return (
        <footer className="Footer">
            <div className="container">
                {children}
            </div>
        </footer>
    );
}

FooterLarge.propTypes = {
    /** @ignore */
    children: PropTypes.node,
};

FooterLarge.defaultProps = {
    children: null,
};

export default withTheme(FooterLarge);
