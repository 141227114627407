import React, { Fragment } from 'react';

function DefaultContent() {
    return (
        <Fragment>
            DFS.BackOffice
            <br />
            Copyright ©
            {' '}
            {new Date().getFullYear()}
            {' '}
            additiv AG. All rights reserved.
        </Fragment>
    );
}

export default DefaultContent;
