import { createSelector } from 'reselect/lib';
import { get } from 'lodash/fp';

export const authSelector = (state) => (state.auth);

export const memberProfileSelector = createSelector(
    authSelector,
    (auth) => (auth.memberProfile),
);

export const isAuthenticatedSelector = createSelector(
    authSelector,
    (auth) => (auth.isAuthenticated === true),
);

export const jwtSelector = createSelector(
    authSelector,
    (auth) => (auth.jwt),
);

export const memberIdSelector = createSelector(
    memberProfileSelector,
    (profile) => (
        get('Id', profile)
    ),
);
