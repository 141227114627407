import React from 'react';
import PropTypes from 'prop-types';
import withTheme from '../../../hocs/withTheme';
import '../Skeleton.css';

function SkeletonLine({
    width, height, className, ...props
}) {
    return (
        <span
            className={`Skeleton line ${className}`}
            style={{ width, height }}
            {...props}
        />
    );
}

SkeletonLine.propTypes = {
    width: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    height: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    className: PropTypes.string,
};

SkeletonLine.defaultProps = {
    width: '100%',
    height: 15,
    className: '',
};
export default withTheme(SkeletonLine);
