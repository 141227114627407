import {
    forbiddenNationalities, GENDER, PRODUCTS, switzLandCountryId, UPPER_AGE_LIMIT,
} from 'constants/constants';
import { TMainClientData } from '../../datasource/CreatePortfolio/CreatePortfolioManager.types';

export const getUpperAgeLimit = gender => (gender === GENDER.male ? UPPER_AGE_LIMIT.male : UPPER_AGE_LIMIT.female);
export const taxValidation = (taxes) => String(taxes?.hasUsResidence) === 'false' && String(taxes?.classedUsPerson) === 'false' && String(taxes?.isUsCitizenOrSubjectToUsTax) === 'false' && String(taxes?.isUsPerson) === 'false';

export const getRestrictedClientMessage = ({ data, selectedProductType = undefined } : {data?: TMainClientData, selectedProductType?: string}) => {
    if (data?.countryOfResidence !== switzLandCountryId) {
        return 'portfolio.createNewPortfolio.produntOnlyForSwitzland';
    }
    if (forbiddenNationalities.includes(data?.nationality)) {
        return 'portfolio.createNewPortfolio.nationalityNotAllowed';
    }
    if ((data?.age < 17 || data?.age >= getUpperAgeLimit(data?.gender)) && selectedProductType && selectedProductType !== PRODUCTS.bbb) {
        return 'portfolio.createNewPortfolio.productIsNotAccaptableForThisAge';
    }
    if (!taxValidation(data?.taxData)) { // explicit check is needed because there are some props with string value
        return 'clientDashboard.unableToOpenNewPortfolioBody';
    }

    return undefined;
};
