import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { getClassNames } from '../../../utils';

const TabHeader = forwardRef((props, ref) => {
    const {
        tab,
        isActive,
        disabled,
        onClick,
    } = props;

    const className = getClassNames(
        'Tab',
        { 'Tab-active': isActive },
        { 'Tab-disabled': disabled },
    );

    return (
        <div
            ref={ref}
            role="tab"
            tabIndex={disabled ? -1 : 0}
            aria-disabled="false"
            aria-selected={isActive}
            className={className}
            onClick={onClick}
            onKeyPress={onClick}
        >
            {tab}
        </div>
    );
});

TabHeader.propTypes = {
    tab: PropTypes.string,
    isActive: PropTypes.bool,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
};

TabHeader.defaultProps = {
    tab: null,
    isActive: false,
    disabled: false,
    onClick: () => {},
};

export default TabHeader;
