import { getServicesUrl } from '../../setup';
import Service from './service';

class Optimization extends Service {
    static optimizationGoalFormula(options) {
        return Service.postRequest(`${getServicesUrl()}/optimization/goal/formula`, options, { isSecure: true });
    }
}

export default Optimization;
