const fileToString = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (readerError) => reject(readerError);
});

export const adaptPictureFile = async (files) => {
    try {
        const file = files?.[0];
        const fileString = await fileToString(file);
        const splits = fileString.split(';base64,');
        const extension = file.name.split('.').pop();

        return {
            ImageContentBase64: splits[1],
            size: file.size,
            extension,
        };
    } catch (error) {
        throw error;
    }
};
