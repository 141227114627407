import { useCallback, useReducer } from 'react';
import ServerError from 'errors/ServerError';
import HandlerError from 'errors/HandlerError';
import handlerRequestCanceling from 'utils/handlerRequestCanceling';
import SM from 'services/ServiceManager';
import {isPlainObject, isString} from 'lodash';
import {cloneDeep, isNil} from 'lodash/fp';

const initialState = {
    data: null,
    error: null,
    isLoading: false,
    dataUpdateContact: null,
    errorUpdateContact: null,
    isLoadingUpdateContact: false,
};

const modifyRequestPayload = (data) => Object.keys(data).forEach(key => {
    if (isPlainObject(data[key])) {
        modifyRequestPayload(data[key]);
    }
    if (data[key] === '' || isNil(data[key])) {
        delete data[key];
    }

    if (isString(data[key])) {
        data[key] = data[key].trim();
    }
});

const createRequestPayload = (data) => {
    const detailsCopy = cloneDeep(data.details);

    modifyRequestPayload(detailsCopy);

    return {...data, details: detailsCopy};
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'setData':
            return { ...state, data: action.payload };
        case 'setError':
            return { ...state, error: action.payload };
        case 'setIsLoading':
            return { ...state, isLoading: action.payload };
        case 'setUpdateContactData':
            return { ...state, dataUpdateContact: action.payload };
        case 'setUpdateContactError':
            return { ...state, errorUpdateContact: action.payload };
        case 'setUpdateContactIsLoading':
            return { ...state, isLoadingUpdateContact: action.payload };
        default:
            return state;
    }
};

export const useContact = (options) => {
    const contactIdOption = options?.contactId;
    const [state, dispatch] = useReducer(reducer, initialState);

    // Callbacks
    const getContact = useCallback(async ({ contactId = contactIdOption } = {}) => {
        dispatch({ type: 'setError', payload: null });
        dispatch({ type: 'setIsLoading', payload: true });

        try {
            const response = await SM.clientService('getContact', [contactId]);

            dispatch({ type: 'setData', payload: response?.data });
            dispatch({ type: 'setIsLoading', payload: false });

            return response?.data;
        } catch (err) {
            handlerRequestCanceling(
                HandlerError({
                    setError: (val) => dispatch({ type: 'setError', payload: val }),
                    setLoading: (val) => dispatch({ type: 'setIsLoading', payload: val }),
                }),
            )(err);
            throw err.type !== undefined ? err : new ServerError(err);
        }
    }, [contactIdOption]);
    const updateContact = useCallback(async ({
        contactId = contactIdOption, params,
    } = {}) => {
        dispatch({ type: 'setUpdateContactError', payload: null });
        dispatch({ type: 'setUpdateContactIsLoading', payload: true });

        try {
            const response = await SM.customClientService('saveClientDetails', [{contactId, data: createRequestPayload(params)}]);

            dispatch({ type: 'setUpdateContactData', payload: response?.data });
            dispatch({ type: 'setUpdateContactIsLoading', payload: false });

            return response?.data;
        } catch (err) {
            handlerRequestCanceling(
                HandlerError({
                    setError: (val) => dispatch({ type: 'setUpdateContactError', payload: val }),
                    setLoading: (val) => dispatch({ type: 'setUpdateContactIsLoading', payload: val }),
                }),
            )(err);
            throw err.type !== undefined ? err : new ServerError(err);
        }
    }, [contactIdOption]);

    return {
        data: state.data,
        error: state.error,
        isLoading: state.isLoading,
        getContact,
        dataUpdateContact: state.dataUpdateContact,
        errorUpdateContact: state.errorUpdateContact,
        isLoadingUpdateContact: state.isLoadingUpdateContact,
        updateContact,
    };
};
