import PropTypes from 'prop-types';
import React from 'react';
import {Checkbox, renderers} from 'ui-library';

import './CellCheckboxedLinked.css';

const {CellLinked} = renderers;

function CellCheckboxedLinked({value}) {
    return (
        <span className="table-cell_checkboxed">
            <Checkbox label="" value={value}/>
            <CellLinked value={value} link={`/client/${value}`}/>
        </span>
    );
}

CellCheckboxedLinked.propTypes = {
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
};

CellCheckboxedLinked.defaultProps = {
    value: null,
};

export default CellCheckboxedLinked;
