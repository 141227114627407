import React, {Fragment, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {Loader} from '../../../../ui-library';
import './CellDocLibraryLinkDownload.css';

const CellDocLibraryLinkDownload = ({
    options, onDownload, onDelete, deleteInProgress, downloadInProgress, productName,
    documentMetadataName, documentFileNames,
}) => {
    const {t} = useTranslation();
    const button = (id, text, callback, inProgress, confirmationContent) => (
        <button
            type="button"
            disabled={inProgress}
            className="table-cell_linked cell-pdf-view"
            onClick={() => callback(id, confirmationContent)}
        >
            {/* <img width="18" height="18" src={downloadIcon} alt="" /> */}
            {inProgress ? <Loader/> : t(text).toLowerCase()}
        </button>
    );

    const downloadOptions = useMemo(() => options.map((item) => (
        <Fragment key={item.id}>
            <span>{item.text.toUpperCase()}</span>
            {button(item.id, 'research.downloadDocument', onDownload, downloadInProgress[item.id], documentFileNames[item.text])}
            {button(item.id, 'clientDashboard.delete', onDelete, deleteInProgress?.[item.id], t('advisoryDashboard.documents.deleteContent', {lang: item.text.toUpperCase(), doc: documentMetadataName, product: productName}))}
        </Fragment>
    )), [options, downloadInProgress, deleteInProgress, onDelete, onDownload]);

    return <div className="CellDocLibraryLinkDownload">{downloadOptions}</div>;
};

export default CellDocLibraryLinkDownload;
