import React, {useCallback, useEffect, useState} from 'react';
import { string } from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Button } from 'ui-library';

import Card from '../../../../../../../../../../components/Card/Card';
import ServiceManager from '../../../../../../../../../../services/ServiceManager';
import Preloader from '../../../../../../../../../../components/Preloader';
import {downloadDmsDocument} from '../../../../../../../../../../utils/downloadDmsDocument';
import './Qr.css';
import EmptyContent from '../../../../../../../../../../components/EmptyContent';

const Qr = ({ portfolioTitle, portfolioId}) => {
    const { t } = useTranslation();
    const [letter, setLetter] = useState(null);
    const [inProgress, setInProgress] = useState(false);
    const [error, setError] = useState(null);

    const getTheLetter = useCallback(async () => {
        try {
            setInProgress(true);
            const {data} = await ServiceManager.customDocumentsService('getOpeningLetter', [{portfolioId}]);

            setLetter(data?.pdf);
        } catch (err) {
            setError(err);
        } finally {
            setInProgress(false);
        }
    }, [portfolioId]);

    const onDownloadButtonClick = useCallback(() => {
        downloadDmsDocument({file: letter, mimeType: 'application/pdf', originalFilename: `${t('client.portfolios.deposit.documentName')}_${portfolioId}.pdf`}, {saveFile: true});
    }, [letter, portfolioId]);

    useEffect(() => {
        (async () => getTheLetter())();
    }, [getTheLetter]);

    return (
        <Card title={portfolioTitle}>
            <Preloader isLoading={inProgress} >
                <EmptyContent data={letter} text={t('clientDashboard.portfolioEdit.noDataFound')}>
                    <div className="qr-container">
                        <div className="fi-verticals">
                            <iframe
                                title="opening-letter"
                                src={`data:application/pdf;base64,${letter}#pagemode=none&navpanes=0&toolbar=0`}

                            />
                            <Button onClick={onDownloadButtonClick} type="primary">{t('client.portfolios.deposit.qrButton')}</Button>
                        </div>
                    </div>
                </EmptyContent>
            </Preloader>
        </Card>
    );
};

Qr.defaultProps = {
    portfolioTitle: '',
};

Qr.propTypes = {
    portfolioId: string.isRequired,
    portfolioTitle: string,
};

export default Qr;
