import { useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import SM from '../services/ServiceManager';
import handlerRequestCanceling from '../utils/handlerRequestCanceling';
import HandlerError from '../errors/HandlerError';

const reducer = (prevState, state) => ({
    ...prevState,
    ...state,
});

const initialState = {
    data: null,
    isLoading: true,
    error: null,
};

export const useSchemaFields = (schemaFiled) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const { i18n: { language }, t } = useTranslation();

    const loadData = async (filed) => {
        try {
            const { data } = await SM.clientService('getSchemaFields', [filed]);

            data?.fields?.forEach((field) => { field.label = t(`dynamicCRM.${field.key}`); });

            dispatch({
                isLoading: false,
                data,
            });
        } catch (err) {
            handlerRequestCanceling(
                HandlerError({
                    setError: (val) => dispatch({ error: val, isLoading: false }),
                }),
            )(err);
        }
    };

    useEffect(() => {
        loadData(schemaFiled);
    }, [schemaFiled, language]);

    return state;
};
