import React, {useCallback, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {withMainLayout} from 'hocs';
import Preloader from 'components/Preloader';
import NewWrapper from 'components/AtomicStructure/layouts/NewWrapper';
import ButtonRadioElement from 'components/AtomicStructure/atoms/ButtonRadioElement';
import Error from 'components/AtomicStructure/atoms/Error';
import {getUserRegisterId} from 'pages/ClientOnboardingFlow/helpers/userRegisterId';
import {IsAllDataNull} from 'validation/validation';
import {confirmationPageLink, uploadDocumentsPageLink} from 'pages/ClientOnboardingFlow/helpers/links';
import SM from 'services/ServiceManager';
import {sendModel} from './models/sendModel';
import ButtonsBlockRow from '../../../../../components/ButtonsBlockRow';
import {Infobox, Modal, Title} from '../../../../../ui-library';

import './QuestionPage.css';

const QuestionPage = () => {
    const navigate = useNavigate();
    const {i18n, t} = useTranslation();

    const portalCustomer = [
        {label: i18n.t('clientDashboard.yes'), id: true},
        {label: i18n.t('clientDashboard.no'), id: false},
    ];

    const userRegisterId = getUserRegisterId();

    const [isLoading, setLoading] = useState(true);
    const [isSending, setSending] = useState(false);
    const [data, setData] = useState({ customer: true});
    const [errorPage, setErrorPage] = useState();
    const [errorData, setErrorData] = useState({});
    const [disableButton, setDisableButton] = useState(true);

    const goBack = () => {
        navigate(uploadDocumentsPageLink);
    };

    const saveDataAndNavigate = useCallback(async () => {
        try {
            setSending(true);
            await SM.clientService('patchContact', [userRegisterId, sendModel(data)]);
            navigate(confirmationPageLink);
        } catch (err) {
            setErrorPage(err.response.data.message);
        } finally {
            setSending(false);
        }
    }, [data, userRegisterId]);

    const onContinue = useCallback(() => {
        if (IsAllDataNull(errorData) === false) {
            return;
        }
        if (data?.customer) {
            saveDataAndNavigate();
        } else {
            Modal.confirm({
                title: t('onboardingFlow.questionPage.printDocsPopup.title'),
                content: t('onboardingFlow.questionPage.printDocsPopup.desc'),
                okText: t('onboardingFlow.questionPage.printDocsPopup.confirm'),
                cancelText: t('onboardingFlow.questionPage.printDocsPopup.cancel'),
                onCancel: () => {},
                onOk: saveDataAndNavigate,
            });
        }
    }, [saveDataAndNavigate, errorData, data?.customer]);

    useEffect(() => {
        setLoading(false);
    }, []);

    useEffect(() => {
        if (data.customer === true) {
            passData('communicationPortal', 'communicationPortal');
        } else {
            passData(false, 'communicationPortal');
        }
    }, [data.customer]);

    useEffect(() => {
        if (Object.keys(data).includes('customer')) {
            setDisableButton(false);
        } else {
            setDisableButton(true);
        }
    }, [data]);

    const passData = (value, key) => {
        const newTodos = {...data};

        newTodos[key] = value;

        setData(newTodos);
    };

    return (
        <NewWrapper
            stepNavBarActive={2}
            headTitle={t('onboardingFlow.customerOnboarding.title')}
        >
            <div className="newStyle  QuestionPage">
                <div className="newStyleContent">
                    <div className="container">
                        <Preloader isLoading={isLoading} error={null}>
                            <Title type={2}>
                                {t('onboardingFlow.step.quarter')}
                            </Title>
                            <div className="form">
                                <div>
                                    <ButtonRadioElement
                                        label={t('onboardingFlow.questionPage.customerPortalQuestion')}
                                        items={portalCustomer}
                                        value={data?.customer}
                                        nameData="customer"
                                        passData={passData}
                                        error={errorData?.customer}
                                    />
                                </div>
                            </div>
                            {data?.customer && (
                                <Infobox className="infobox">
                                    <Title type={4}>
                                        {t('onboardingFlow.questionPage.paperless.title')}
                                    </Title>
                                    {t('onboardingFlow.questionPage.paperless.desc')}
                                </Infobox>
                            )}
                        </Preloader>
                    </div>
                    <div className="JourneyFooter">
                        <div className="container">
                            {errorPage && <Error>{errorPage}</Error>}
                            <ButtonsBlockRow
                                additionalButton={{
                                    text: t('onboardingFlow.documetsPage.back'),
                                    onClick: () => goBack(),
                                }}
                                primaryButton={{
                                    text: t('onboardingFlow.clientsDataPage.continue'),
                                    onClick: () => onContinue(),
                                    disabled: disableButton,
                                    loading: isSending,
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </NewWrapper>
    );
};

export default withMainLayout(QuestionPage);
