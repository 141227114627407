import React from 'react';
import PropTypes from 'prop-types';
import RangeSlider from 'components/RangeSlider';

const SliderTableRenderer = ({
    value, id, disabled, onChange,
}) => {
    if (value === undefined) return null;

    return (
        <div className="SliderTableRenderer-Cell">
            <RangeSlider
                disabled={disabled}
                data-cy="security-allocation"
                value={value}
                min={10}
                max={90}
                step={1}
                suffix="%"
                unit={false}
                withInput
                onChange={(val) => onChange({id, value: parseInt(val, 10)})}
            />
        </div>
    );
};

SliderTableRenderer.propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.number,
    id: PropTypes.number.isRequired,
    disabled: PropTypes.bool,
};

SliderTableRenderer.defaultProps = {
    value: 0,
    disabled: false,
    onChange: () => {},
};

export default SliderTableRenderer;
