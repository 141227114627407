import { PRODUCTS, strategyTypes } from 'constants/constants';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Modal, Title } from 'ui-library';
import { getClassNames } from 'utils';

const StrategyList = ({
    strategies,
    selectedStrategyId,
    setSelectedStrategyId,
    recommendedStrategy,
    products,
    cachedSelectStrategy,
    selectedProductKey,
    disabled,
}) => {
    const { t } = useTranslation();

    useEffect(() => {
        const strategyId = cachedSelectStrategy?.strategyId || recommendedStrategy?.Id;

        setSelectedStrategyId(strategyId);
    }, [recommendedStrategy, cachedSelectStrategy?.strategyId]);

    const onStrategyClick = useCallback((strategyId) => () => {
        const recStrategy = strategies.find(item => item.Id === recommendedStrategy?.Id);
        const recommendedProduct = products.sort((a, b) => b.RiskScore - a.RiskScore)
            .filter(item => recStrategy?.Products.includes(item.Id))[0];

        const currentlySelectedStrategy = strategies.find(item => item.Id === selectedStrategyId);
        const currentlySelectedProduct = products.sort((a, b) => b.RiskScore - a.RiskScore)
            .filter(item => currentlySelectedStrategy.Products.includes(item.Id))[0];

        const selectedStrategy = strategies.find(item => item.Id === strategyId);
        const selectedProductByScore = products.sort((a, b) => b.RiskScore - a.RiskScore)
            .filter(item => selectedStrategy.Products.includes(item.Id))[0];

        if (selectedStrategy?.Type === strategyTypes.INDIVIDUAL) {
            setSelectedStrategyId(strategyId);
        } else {
            const isNewlySelectedRiskHigher = selectedProductByScore.RiskScore > recommendedProduct.RiskScore;
            const isNewlySelectedRiskLower = selectedProductByScore.RiskScore < recommendedProduct.RiskScore;

            if (isNewlySelectedRiskHigher) {
                if (selectedProductKey?.toLowerCase() === PRODUCTS.fzp) {
                    Modal.confirm({
                        title: t('portfolio.disclaimer'),
                        content: t('portfolio.createNewPortfolio.strategy.fzpHigherRiskNotAllowed'),
                        cancelText: false,
                    });
                } else {
                    Modal.confirm({
                        title: t('portfolio.disclaimer'),
                        content: t('portfolio.createNewPortfolio.strategy.disclaimerText.high'),
                        okText: t('clientDashboard.confirm'),
                        onOk: async () => {
                            setSelectedStrategyId(strategyId);
                        },
                        cancelText: false,
                    });
                }

                return;
            }
            if (isNewlySelectedRiskLower) {
                Modal.confirm({
                    title: t('portfolio.disclaimer'),
                    content: t('portfolio.createNewPortfolio.strategy.disclaimerText.low'),
                    okText: t('clientDashboard.confirm'),
                    onOk: async () => {
                        setSelectedStrategyId(strategyId);
                    },
                    cancelText: false,
                });

                return;
            }
            setSelectedStrategyId(strategyId);
        }
    }, [strategies, recommendedStrategy, selectedStrategyId, selectedProductKey, products]);

    return (
        <div className="fi-strategy-selection">
            <Title type={2}>{t('portfolio.createNewPortfolio.strategy')}</Title>
            <div className="new-portfolio-content">
                {strategies.map((strategyItem) => (
                    <Button
                        disabled={disabled}
                        key={strategyItem.Id}
                        type={selectedStrategyId === strategyItem.Id ? 'primary' : 'secondary'}
                        size="small"
                        onClick={onStrategyClick(strategyItem.Id)}
                        className={getClassNames(
                            'buttonRadioElement',
                            selectedStrategyId === strategyItem.Id ? 'buttonRadioElementNotSelected' : '',
                            recommendedStrategy.Id === strategyItem.Id ? 'buttonRadioRecommended' : '',
                        )}
                    >
                        {strategyItem?.Name}
                        {' '}
                        {recommendedStrategy.Id === strategyItem.Id
                            && (
                                <span>
                                    (
                                    {t('portfolio.createNewPortfolio.strategy.recommended')}
                                    )
                                </span>
                            )}
                    </Button>
                ))}
            </div>
        </div>
    );
};

StrategyList.propTypes = {
    strategies: PropTypes.arrayOf(PropTypes.shape({
        [PropTypes.string]: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
            PropTypes.shape,
            PropTypes.bool,
            PropTypes.func,
        ]),
    })),
    selectedStrategyId: PropTypes.number,
    setSelectedStrategyId: PropTypes.func,
    recommendedStrategy: PropTypes.shape({
        Id: PropTypes.number,
    }),
    products: PropTypes.arrayOf(PropTypes.shape({
        [PropTypes.string]: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
            PropTypes.shape,
            PropTypes.bool,
            PropTypes.func,
        ]),
    })),
};

StrategyList.defaultProps = {
    strategies: [],
    selectedStrategyId: undefined,
    setSelectedStrategyId: () => {},
    recommendedStrategy: {},
    products: [],

};

export default StrategyList;
