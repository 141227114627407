/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';
import { Icon } from 'ui-library';
import { ICON_PROXY_CONNECTION, ICON_JA_CONNECTION } from 'ui-library/components/Icon';

import './ClientSummary.css';

type ClientSummaryProps = {
    id: string;
    name: string;
    isJa: boolean;
}

export const ClientSummary = ({id, name, isJa = false}: ClientSummaryProps) => (
    <div className="clientSummary">
        {/* @ts-ignore */}
        <Icon type={isJa ? ICON_JA_CONNECTION : ICON_PROXY_CONNECTION} size={24} />
        <span className="client_id">{id}</span>
        <p className="clientSummaryContent">{name}</p>
    </div>
);
