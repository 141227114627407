import React from 'react';
import Notification from 'rc-notification';
import CloseIcon from './core/CloseIcon';
import { getClassNames } from '../../utils';
import './Notification.css';

const prefix = 'Notification';
const defaultDuration = 5;
const defaultPlacement = 'topRight';
const notificationInstance = {};

const getNotificationInstance = (args, callback) => {
    const { placement = defaultPlacement } = args;
    const notificationClass = `${prefix}-${placement}`;
    const cacheInstance = notificationInstance[notificationClass];

    if (cacheInstance) {
        Promise.resolve(cacheInstance).then((instance) => {
            callback({ instance });
        });

        return;
    }

    notificationInstance[notificationClass] = new Promise((resolve) => {
        Notification.newInstance(
            {
                prefixCls: prefix,
                className: notificationClass,
                style: {},
                closeIcon: <CloseIcon />,
            },
            (notification) => {
                resolve(notification);
                callback({ instance: notification });
            },
        );
    });
};
const getRCNotificationProps = (args, prefixCls) => {
    const {
        duration = defaultDuration,
        type,
        content,
        onClose,
        onClick,
        key,
        className,
    } = args;

    return {
        content,
        duration,
        closable: true,
        onClose,
        onClick,
        key,
        style: {},
        props: { role: 'alert' },
        className: getClassNames(className, {
            [`${prefixCls || prefix}-${type}`]: !!type,
        }),
    };
};

const open = (args) => {
    getNotificationInstance(args, ({ prefixCls, instance }) => {
        instance.notice(getRCNotificationProps(args, prefixCls));
    });
};

const NotificationAPI = {
    open,
    close: (key) => {
        Object.keys(notificationInstance).forEach((cacheKey) => Promise
            .resolve(notificationInstance[cacheKey]).then((instance) => {
                instance.removeNotice(key);
            }));
    },
    destroy: () => {
        Object.keys(notificationInstance).forEach((cacheKey) => {
            Promise.resolve(notificationInstance[cacheKey]).then((instance) => {
                instance.destroy();
            });
            delete notificationInstance[cacheKey];
        });
    },
};

export default NotificationAPI;
