import {useCallback, useMemo} from 'react';
import {SELECTED_INVESTMENT_APP} from '../../../../../constants';

export const NEW_PORTFOLIO = 'new-portfolio';
export const NEW_PORTFOLIO_MODIFIED = 'new-portfolio-modified';

const getItem = (key, defaultValue) => {
    try {
        return JSON.parse(sessionStorage.getItem(key)) || defaultValue;
    } catch {
        return {};
    }
};

const setItem = (key, data) => {
    sessionStorage.setItem(key, JSON.stringify(data));
};

const removeItem = (key) => {
    sessionStorage.removeItem(key);
};

export const useNewPortfolioData = (investmentApplicationId) => {
    const newPortfolio = useMemo(() => getItem(NEW_PORTFOLIO, {})?.[investmentApplicationId] ?? {}, [investmentApplicationId]);
    const saveNewPortfolio = useCallback((data) => {
        setItem(NEW_PORTFOLIO, {...getItem(NEW_PORTFOLIO, {}), [data?.applicationData?.investmentApplicationId]: data});
    }, [newPortfolio]);
    const clearNewPortfolio = useCallback((applicationId, callback) => {
        const allApps = getItem(NEW_PORTFOLIO, {});
        const allAppIds = Object.keys(allApps)?.filter(f => f !== 'undefined');

        if (!applicationId || (allAppIds.length === 1 && allAppIds.includes(applicationId))) {
            removeItem(NEW_PORTFOLIO);
            removeItem('create-portfolio/finalisation/data');
            removeItem('investor-profile/data');
            removeItem('create-portfolio/quality-check/data');
            removeItem(SELECTED_INVESTMENT_APP);
            if (callback) {
                callback(false);
            }
        } else {
            delete allApps[applicationId];
            const selectedAppId = JSON.parse(getItem(SELECTED_INVESTMENT_APP, undefined));

            setItem(NEW_PORTFOLIO, allApps);

            if (selectedAppId === investmentApplicationId) {
                setItem(SELECTED_INVESTMENT_APP, undefined);
            }

            if (callback) {
                callback(true);
            }
        }
    }, []);

    const modifiedPositions = useMemo(() => getItem(NEW_PORTFOLIO_MODIFIED, null), []);
    const saveModifiedPositions = useCallback((data) => {
        setItem(NEW_PORTFOLIO_MODIFIED, data);
    }, []);
    const clearModifiedPositions = useCallback(() => {
        removeItem(NEW_PORTFOLIO_MODIFIED);
    }, []);

    return {
        newPortfolio,
        saveNewPortfolio,
        clearNewPortfolio,
        modifiedPositions,
        saveModifiedPositions,
        clearModifiedPositions,
    };
};
