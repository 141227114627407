import {GENDER, UPPER_AGE_LIMIT} from 'constants/constants';
import {UPPER_AGE_LIMIT_PER_PRODUCT} from './constants';

export const getUpperAgeLimit = gender => (gender === GENDER.male ? UPPER_AGE_LIMIT.male : UPPER_AGE_LIMIT.female);
export const getUpperAgeLimitPerProduct = (gender, product) => (UPPER_AGE_LIMIT_PER_PRODUCT[product]?.[gender]);
export const PAYMENT_PERIODS = {
    yearly: {
        id: 3,
        label: 'portfolio.createNewPortfolio.yearly',
        value: 1,
    },
    semiYearly: {
        id: 2,
        label: 'portfolio.createNewPortfolio.semiYearly',
        value: 2,
    },
    quarterly: {
        id: 1,
        label: 'portfolio.createNewPortfolio.quarterly',
        value: 4,
    },
    monthly: {
        id: 0,
        label: 'portfolio.createNewPortfolio.monthly',
        value: 12,
    },
};

export const getPaymentPeriodValue = (id) => (Object.values(PAYMENT_PERIODS).find(f => f.id === parseInt(id, 10))?.value || NaN);

export const calculatePlannedInvestment = (initialInvestment, periodicPayment, periodicity) => initialInvestment + (periodicPayment * getPaymentPeriodValue(periodicity));
