import React, { useEffect, useState } from 'react';
import { Infobox, Loader } from 'ui-library';
import PropTypes from 'prop-types';
import {useNavigate, useParams} from 'react-router-dom';
import ServiceManager from '../../../services/ServiceManager';
import { genarateDFSClientId } from '../../../utils';
import handlerRequestCanceling from '../../../utils/handlerRequestCanceling';
import HandlerError from '../../../errors/HandlerError';

export const ClientLanding = () => {
    const navigate = useNavigate();
    const { externalClientId } = useParams();
    const [error, setError] = useState(null);
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        if (externalClientId) {
            (async () => {
                setLoading(true);
                try {
                    const response = await ServiceManager.customClientService('getContactByExternalId', [{contactExternalId: externalClientId}]);
                    const clientId = response?.data?.contactId;

                    const dfsClientId = genarateDFSClientId(clientId);

                    navigate(`/client/${dfsClientId}`);
                } catch (err) {
                    if (err.response.status === 404) {
                        navigate('/onboardingflow/newPersonalInformation', { state: {externalClientId}});
                    }
                    handlerRequestCanceling(
                        HandlerError({
                            setError,
                            setLoading,
                        }),
                    )(err);
                } finally {
                    setLoading(false);
                }
            })();
        }
    }, [externalClientId]);

    return isLoading ? (<Loader />) : (
        error && <Infobox error>{error}</Infobox>
    );
};
