import generateSinglePicker from './generateSinglePicker';


function generatePicker(generateConfig) {
    // =========================== Picker ===========================
    const {
        DatePicker, WeekPicker, MonthPicker, YearPicker, TimePicker, QuarterPicker,
    } = generateSinglePicker(generateConfig);

    const MergedDatePicker = DatePicker;

    MergedDatePicker.WeekPicker = WeekPicker;
    MergedDatePicker.MonthPicker = MonthPicker;
    MergedDatePicker.YearPicker = YearPicker;
    MergedDatePicker.TimePicker = TimePicker;
    MergedDatePicker.QuarterPicker = QuarterPicker;

    return MergedDatePicker;
}
export default generatePicker;
