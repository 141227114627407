import React from 'react';
import {useTranslation} from 'react-i18next';

import './styles/NavbarTab.css';

const NavbarTab = (props) => {
    const {
        i18n,
        t
    } = useTranslation();

    const NavbarData = {
        onboarding: [
            {
                number: '1',
                name: t('onboardingFlow.step.second')
            },
            {
                number: '2',
                name: t('onboardingFlow.step.third')
            },
            {
                number: '3',
                name: t('onboardingFlow.step.quarter')
            },
        ],
        newConnections: [
            {
                number: '1',
                name: t('connections.createNew.firstStep')
            },
            {
                number: '2',
                name: t('connections.createNew.secondStep')
            },
            {
                number: '3',
                name: t('connections.createNew.thirdStep')
            },
        ],
        newJaConnections: [
            {
                number: '1',
                name: t('connections.createNew.firstStep')
            },
            {
                number: '2',
                name: t('connections.createNewJa.secondStep')
            },
            {
                number: '3',
                name: t('connections.createNew.thirdStep')
            },
        ],
    };

    const dataNewPlanSteps = [
        {
            number: '1',
            name: ''
        },
        {
            number: '2',
            name: ''
        },
        {
            number: '3',
            name: ''
        },
        {
            number: '4',
            name: ''
        },
        {
            number: '5',
            name: ''
        },
        {
            number: '6',
            name: ''
        },
        {
            number: '7',
            name: ''
        },
        {
            number: '8',
            name: ''
        },
    ];

    const {dataKey = 'onboarding'} = props;

    const data = NavbarData[dataKey];

    return (
        <div className="NavbarTabWrapper">
            <div className="container">
                <ul className="NavbarTab">
                    {!props.newPlanSteps && data.map((values, index) => (
                        <li key={index} className={index === props.stepNavBarActive ? 'active' : null}>
                            {index === props.stepNavBarActive ? <span className="dot"/> : null}
                            <span>
                                {values.number}
                                &nbsp;
                                <span>{values.name}</span>
                            </span>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default NavbarTab;
