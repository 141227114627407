/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, {useEffect, useMemo, useState} from 'react';
import { useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {genarateDFSClientId, getClientId} from 'utils';
import Icon, { ICON_EDIT_OUTLINE } from 'ui-library/components/Icon';
import {Button, Table} from 'ui-library';
import { useOptionList } from 'hooks/useOptionList';

import {RemoveProxyConnectionModal, useConnections} from 'components/Connections/components';
import { VALUE_TYPES, ValueWithLinks } from 'components/Overview/Overview.types';
import {adaptProxies} from 'components/Connections/adapters/adaptProxies';
import OverviewItem from 'components/Overview/OverviewItem';
import OverviewPanel from 'components/Overview/OverviewPanel';
import Card from 'components/Card/Card';
import Preloader from 'components/Preloader';

import {useClientLayout} from '../../../../hooks/useClientLayout';

import {
    OPTION_LIST,
    contactGroupDocumentsColumns,
    contactGroupHistoryColumns,
    proxyColumns,
} from '../../../../../../constants/constants';
import {UploadContactGroupDocumentModal} from './UploadContactGroupDocumentModal';
import NewConnectionModal from '../NewConnectionModal/NewConnectionModal';
import {useFormatting} from '../../../../../../locale';
import './ContactGroupDetails.css';
import { NewAddressModal } from '../NewConnections';

export type UrlParams = {
    groupId: string;
    dfsClientId: string;
}

export const ContactGroupDetails = () => {
    const {t} = useTranslation();
    const params = useParams<UrlParams>();

    const [editAddressModalOpen, setEditAddressModalOpen] = useState<boolean>(false);
    const [showUploadDocumentsModal, setShowUploadDocumentsModal] = useState<boolean>(false);
    const { isLoading: isOptionsLoading} = useOptionList([OPTION_LIST.country, OPTION_LIST.language]);

    const {
        getContactGroupDetails,
        contactGroupDetails,
        isLoading,
        error,
        setSelectedProxyConnection,
        onAddConnection,
        getContactGroupDocuments,
        contactGroupDocuments,
        contactGroupDocumentsInProgress,
        contactGroupDocumentsError,
        contactGroupHistoryLoading,
        contactGroupHistoryError,
        contactGroupHistory,
        getContactGroupHistory,
        updateContactGroupAddress,
        getProxyAdditionStatus,
        allowProxyAddition,
    } = useConnections();

    const {getFormattedDate} = useFormatting();

    useClientLayout();

    useEffect(() => {
        (async () => params.groupId && getContactGroupDetails(parseInt(params.groupId, 10)))();
    }, [getContactGroupDetails, params]);

    useEffect(() => {
        (async () => params.groupId && getContactGroupDocuments(parseInt(params.groupId, 10)))();
    }, [getContactGroupDocuments, params]);

    useEffect(() => {
        (async () => params.groupId && getContactGroupHistory(parseInt(params.groupId, 10)))();
    }, [getContactGroupHistory, params]);

    useEffect(() => {
        (async () => getProxyAdditionStatus(params.groupId))();
    }, [getProxyAdditionStatus, params.groupId]);

    const {dfsClientId} = params;
    const clientId: number | undefined = useMemo(() => getClientId(dfsClientId), [dfsClientId]);

    const owners = contactGroupDetails?.owners;

    // Generates an array of objects {label: string, linkTo: string}[]
    const ownersWithLinksToProfile: ValueWithLinks[] = useMemo(() => Object.keys(owners || [])?.map(key => ({label: owners?.[key], linkTo: `/client/${genarateDFSClientId(key)}/dashboard/`})), [owners, genarateDFSClientId]);

    const canDeleteProxies = useMemo(() => {
        if (owners?.[clientId as number] && allowProxyAddition) {
            return true;
        }

        return false;
    }, [owners, clientId, allowProxyAddition]);

    const proxyTableData = useMemo(() => adaptProxies(contactGroupDetails?.proxies ?? [], setSelectedProxyConnection, canDeleteProxies), [contactGroupDetails?.proxies, canDeleteProxies]);

    return (
        <div className="container">
            <div className="fi-verticals">
                <Preloader isLoading={isLoading || isOptionsLoading} error={error}>
                    <Card>
                        <OverviewPanel>
                            <OverviewItem title={t('contactGroupDetails.groupId')} type={VALUE_TYPES.TEXT} value={contactGroupDetails?.externalId} />
                            <OverviewItem title={t('contactGroupDetails.groupName')} type={VALUE_TYPES.TEXT} value={t(`connections.groupName.${contactGroupDetails?.groupName?.toLowerCase()}`).toString()} />
                            <OverviewItem title={t('contactGroupDetails.owners')} type={VALUE_TYPES.TEXT} value={ownersWithLinksToProfile || []} />
                            {contactGroupDetails?.groupName === 'JA' && (
                                <NewAddressModal
                                    onSubmit={async (data) => {
                                        setEditAddressModalOpen(false);

                                        if (params.groupId) {
                                            await updateContactGroupAddress(params.groupId, {
                                                language: data.mailRecipientLanguage,
                                                addLine1: data.mailRecipientAddressLine1,
                                                addLine2: data.mailRecipientAddressLine2,
                                                street: data.mailRecipientAddressLine3,
                                                zipCity: data.mailRecipientAddressLine4,
                                                city: data.mailRecipientAddressLine5,
                                                country: data.mailRecipientAddressCountry,
                                            }, getContactGroupDetails);
                                        }
                                    }}
                                    outsideClickHappened={editAddressModalOpen}
                                    setEditAddressModalOpen={setEditAddressModalOpen}
                                    initData={{
                                        mailRecipientLanguage: contactGroupDetails?.contactGroupAddress?.language,
                                        mailRecipientAddressLine1: contactGroupDetails?.contactGroupAddress?.addLine1,
                                        mailRecipientAddressLine2: contactGroupDetails?.contactGroupAddress?.addLine2,
                                        mailRecipientAddressLine3: contactGroupDetails?.contactGroupAddress?.street,
                                        mailRecipientAddressLine4: contactGroupDetails?.contactGroupAddress?.zipCity,
                                        mailRecipientAddressLine5: contactGroupDetails?.contactGroupAddress?.city,
                                        mailRecipientAddressCountry: contactGroupDetails?.contactGroupAddress?.country,
                                    }}
                                    customRenderComponent={(
                                        <div className="change-address">
                                            <OverviewItem title={t('MailingAddress.button.mailingAddress')} type={VALUE_TYPES.TEXT} value={`${contactGroupDetails?.contactGroupAddress?.street} ${contactGroupDetails?.contactGroupAddress?.zipCity} ${contactGroupDetails?.contactGroupAddress?.city}`} />
                                            <div className="address-edit">
                                                <Button type="link" onClick={() => setEditAddressModalOpen(true)}>
                                                    {/* @ts-ignore */}
                                                    <Icon size={24} type={ICON_EDIT_OUTLINE} />
                                                </Button>
                                            </div>
                                        </div>
                                    )}
                                />
                            )}
                        </OverviewPanel>
                    </Card>

                    <Card
                        title={t('contactGroupDetails.proxyList')}
                        headerActions={(
                            <button
                                data-cy="onAddNewConnection"
                                type="button"
                                className="button button-small button-primary"
                                onClick={onAddConnection}
                                disabled={!allowProxyAddition}
                            >
                                {t('contactGroupDetails.addNewConnection')}
                            </button>
                        )}
                    >
                        <Table
                            data={proxyTableData}
                            columns={proxyColumns(t)}
                            headerControls={{
                                selectControlsLabel: {
                                    selectAll: t('headerControls.selectAll'),
                                    reset: t('headerControls.reset'),
                                },
                            }}
                        />
                    </Card>
                </Preloader>

                <Preloader isLoading={contactGroupDocumentsInProgress} error={contactGroupDocumentsError}>
                    <Card
                        title={t('contactGroupDetails.documentsList')}
                        headerActions={(
                            <button
                                data-cy="onAddNewDocument"
                                type="button"
                                className="button button-small button-link"
                                onClick={() => setShowUploadDocumentsModal(true)}
                            >
                                {t('contactGroupDetails.addNewDocument')}
                            </button>
                        )}
                    >
                        <Table
                            data={contactGroupDocuments || []}
                            columns={contactGroupDocumentsColumns(t, getFormattedDate)}
                            headerControls={{
                                selectControlsLabel: {
                                    selectAll: t('headerControls.selectAll'),
                                    reset: t('headerControls.reset'),
                                },
                            }}
                        />
                    </Card>
                </Preloader>

                <Preloader isLoading={contactGroupHistoryLoading} error={contactGroupHistoryError}>
                    <Card
                        title={t('contactGroupDetails.contactGroupHistory')}
                    >
                        <Table
                            data={contactGroupHistory || []}
                            columns={contactGroupHistoryColumns(t, getFormattedDate)}
                            headerControls={{
                                selectControlsLabel: {
                                    selectAll: t('headerControls.selectAll'),
                                    reset: t('headerControls.reset'),
                                },
                            }}
                        />
                    </Card>
                </Preloader>

                {showUploadDocumentsModal && <UploadContactGroupDocumentModal show={showUploadDocumentsModal} onClose={() => setShowUploadDocumentsModal(false)} />}
            </div>

            <NewConnectionModal allowProxyAddition={allowProxyAddition} />
            <RemoveProxyConnectionModal />
        </div>
    );
};
