import {withMainLayout} from 'hocs';
import React from 'react';
import PageNotFoundContent from './PageNotFoundContent';
import './PageNotFound.css';

const PageNotFound = () => (
    <PageNotFoundContent/>
);

export default withMainLayout(PageNotFound);
