import React from 'react';
import PropTypes from 'prop-types';
import { getClassNames } from '../../../utils';

function CellWithMessageTitle({ title, recipients, isRead }) {
    const className = getClassNames(
        'table-cell_with_message',
        { 'table-cell_with_message-new': !isRead },
    );

    return (
        <div className={className}>
            <b>{recipients}</b>
            <p data-isread={isRead}>{title}</p>
        </div>
    );
}

CellWithMessageTitle.propTypes = {
    isRead: PropTypes.bool,
    title: PropTypes.string,
    recipients: PropTypes.string,
};

CellWithMessageTitle.defaultProps = {
    isRead: false,
    title: null,
    recipients: null,
};

export default CellWithMessageTitle;
