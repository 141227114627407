import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { getTimeTopPosition, isLabelHidden } from './utils';

const TimeSlotWrapper = ({
    children, value, locale,
}) => {
    const [now, setNow] = useState(new Date());
    const top = useMemo(() => getTimeTopPosition(now), [now]);
    const isLabel = children.props.children && children.props.children.type === 'span';
    const hideLabel = isLabel && isLabelHidden(now, value);

    useEffect(() => {
        const timer = window.setInterval(() => {
            const newNow = new Date();

            setNow(newNow);
            // eslint-disable-next-line no-undef
            document.dispatchEvent(new CustomEvent('time-change', { detail: newNow }));
        }, 30000);

        return () => { window.clearInterval(timer); };
    }, []);

    if (hideLabel) {
        return (
            <>
                <div className="current-time" style={{ top }}>
                    {now.toLocaleString(locale, { hour: 'numeric', minute: 'numeric', hour12: true })}
                </div>
                <span className="current-time-dot" style={{ top }} />
            </>
        );
    }

    return children;
};

TimeSlotWrapper.propTypes = {
    top: PropTypes.string,
    now: PropTypes.instanceOf(Date),
    value: PropTypes.instanceOf(Date),
    children: PropTypes.shape({
        props: PropTypes.shape({
            children: PropTypes.node,
        }),
    }),
    locale: PropTypes.string.isRequired,
};

TimeSlotWrapper.defaultProps = {
    title: '',
    event: {},
};

export default TimeSlotWrapper;
