import {
    ApplicationDetailsActionTypes,
    ApplicationStatus,
    DocTypes,
    TApplicationDetailsManagerState,
} from 'datasource/useApplicationDetails/ApplicationDetailsManager.types';
import { Documents } from 'components/ApplicationDetails/AdvisorySection/AdvisorySection.types';
import { DocStatus } from 'components/ApplicationDetails/DocBox/DocBox.types';
import { initialState } from './constants';

export const reducer = (
    state: TApplicationDetailsManagerState = initialState(),
    action,
) => {
    switch (action.type) {
        case ApplicationDetailsActionTypes.SET_APPLICATIONS:
            return {
                ...state,
                applicationsData: action.payload,
            };
        case ApplicationDetailsActionTypes.SUBMIT_APPLICATION:
            return {
                ...state,
                applicationsData: state.applicationsData.map((application) => {
                    if (application.applicationId === action.payload.applicationId) {
                        return {
                            ...application,
                            status: action.payload.status,
                            submitted: action.payload.submitted,
                            submittedBy: action.payload.submittedBy,
                            canBeDeleted: false,
                        };
                    }

                    return application;
                }),
            };
        case ApplicationDetailsActionTypes.UPDATE_APPLICATION_STATUS:
            return {
                ...state,
                applicationsData: state.applicationsData.map((application) => {
                    if (application.applicationId === action.payload.applicationId) {
                        return {
                            ...application,
                            status: action.payload.status,
                            canBeDeleted: false,
                            documents: application?.documents?.reduce((akku: Documents, doc) => {
                                if (
                                    action.payload.status === ApplicationStatus.canceled
                                    && doc.type !== DocTypes.GENERATE
                                    && doc.status !== DocStatus.ReadyToSign
                                ) akku.push(doc);

                                return akku;
                            }, []),
                        };
                    }

                    return application;
                }),
            };
        case ApplicationDetailsActionTypes.ADD_GENERATED_DOCS_FOR_APPLICATION:
            return {
                ...state,
                applicationsData: state.applicationsData.map((application) => {
                    if (application.applicationId === action.payload.applicationId) {
                        return {
                            ...application,
                            documents: action.payload.documents,
                        };
                    }

                    return application;
                }),
            };

        case ApplicationDetailsActionTypes.UPDATE_GENERATE_APPLICATION_DOC:
            return {
                ...state,
                applicationsData: state.applicationsData.map((application) => {
                    if (application.applicationId === action.payload.applicationId) {
                        const documents = application.documents?.map((doc) => {
                            if (doc.type === DocTypes.GENERATE) {
                                return {
                                    ...doc,
                                    status: action.payload.status,
                                };
                            }

                            return doc;
                        });

                        return {
                            ...application,
                            documents,
                        };
                    }

                    return application;
                }),
            };
        case ApplicationDetailsActionTypes.UPDATE_APPLICATION_DOC:
            return {
                ...state,
                applicationsData: state.applicationsData.map((application) => {
                    const documents = application.documents?.map((doc) => {
                        if (doc.documentId === action.payload.documentId) {
                            return {
                                ...doc,
                                status: DocStatus.ReadyToSubmit,
                            };
                        }

                        return doc;
                    });

                    return {
                        ...application,
                        documents,
                    };
                }),
            };
        case ApplicationDetailsActionTypes.UPDATE_APPLICATION_DOC_WITH_TRANSFER:
            return {
                ...state,
                applicationsData: state.applicationsData.map((application) => {
                    const documents = application.documents?.map((doc) => {
                        if (doc.documentId === action.payload.documentId) {
                            return {
                                ...doc,
                                status: action.payload.status,
                                isSent: true,
                            };
                        }

                        return doc;
                    });

                    return {
                        ...application,
                        documents,
                    };
                }),
            };
        case ApplicationDetailsActionTypes.SET_BASIC_AGREEMENT_DOCS:
            return {
                ...state,
                basicAgreementDocuments: action.payload,
            };
        case ApplicationDetailsActionTypes.UPDATE_BASIC_AGREEMENT_DOC:
            return {
                ...state,
                basicAgreementDocuments: state.basicAgreementDocuments.map((doc) => {
                    if (doc.documentId === action.payload.documentId) {
                        return {
                            ...doc,
                            status: DocStatus.ReadyToSubmit,
                        };
                    }

                    return doc;
                }),
            };
        case ApplicationDetailsActionTypes.SET_PROOF_OF_ASSETS_DOCS:
            return {
                ...state,
                proofOfAssetDocuments: action.payload,
            };
        case ApplicationDetailsActionTypes.UPDATE_PROOF_OF_ASSETS_DOC:
            return {
                ...state,
                proofOfAssetDocuments: state.proofOfAssetDocuments.map((doc) => {
                    if (doc.originOfAsset === action.payload.originOfAsset && doc.contactId === action.payload.contactId) {
                        return {
                            ...doc,
                            documentId: action.payload.documentId,
                            status: DocStatus.ReadyToSubmit,
                        };
                    }

                    return doc;
                }),
            };
        default:
            return state;
    }
};
