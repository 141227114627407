import React, { useState } from 'react';
import { useSecure } from 'libs/additiv-react-oidc-client';
import { useIsUserActive } from './hooks/useIsUserActive';
import { useSessionExpiring } from './hooks/useSessionExpiring';
import { ModalLogout } from './components/ModalLogout';
import {useAuth} from '../hooks/useAuth';

export const AutoLogout = () => {
    const { signinSilent } = useSecure();
    const {logout} = useAuth();
    const [showModal, setShowModal] = useState(false);
    const isUserActive = useIsUserActive(globalThis.SetupConfig.USER_INACTIVE_TIME);

    const renewSession = () => {
        signinSilent();
    };

    useSessionExpiring(() => {
        if (showModal) return;
        if (isUserActive) {
            renewSession();

            return;
        }
        setShowModal(true);
    }, [showModal, isUserActive]);

    return (
        <ModalLogout
            dialogCountdown={globalThis.SetupConfig.SESSION_TIMEOUT_WARNING_DIALOG_COUNTDOWN}
            visible={showModal}
            onContinue={() => {
                setShowModal(false);
                renewSession();
            }}
            onTimeOver={() => {
                setShowModal(false);
                logout();
            }}
        />
    );
};
