export const autocomplete = (countryNumber, postCodeSwitzland, switzLandCountryId, shippingPLZ) => {
    const index = postCodeSwitzland.findIndex((value) => value.PLZ == shippingPLZ);

    if (countryNumber == switzLandCountryId) {
        if (index !== -1) {
            return index;
        }
    }

    return false;
};
