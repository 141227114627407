import { SetupData, APIVersion, BearerFunction, ServiceTypes } from './types/main';

let setupData: SetupData = {
    withBearerApplicationAccessToken: false,
    bearerAccessToken: undefined,
    bearerApplicationAccessToken: undefined,
    bearerFIdentityAccessToken: undefined,
    headers: {},
    isVersionIncluded: false,
    GATEWAY_API_URL: '',
    SERVICES_URL_SUFFIX: '',
    DMS_URL_SUFFIX: '',
    CUSTOM_URL_SUFFIX: '',
    DMS_URL: undefined,
    SERVICES_URL: undefined,
    CUSTOM_URL: undefined,
    ENGAGEMENT_CONTENT_URL: undefined,
    ENGAGEMENT_CONTENT_URL_SUFFIX: '',
    FIDENTITY_URL: undefined,
    FIDENTITY_URL_SUFFIX: '',
    CLIENT_SERVICE_URL: undefined,
    CLIENT_SERVICE_URL_SUFFIX: '',
    CLIENT_SERVICE_HEADERS: {},
    CLIENT_SERVICE_MEMBER_URL_SUFFIX: ''
};

const ServiceTypeToActionMap: Record<string, BearerFunction> = {
    [ServiceTypes.FIDENTITY]: () => {
        return getBearerFIdentityAccessToken();
    },
    [ServiceTypes.DFS]: (isSecure: boolean) => {
        return isSecure
            ? getBearerAccessToken()
            : (getBearerApplicationAccessToken() || getBearerAccessToken());
    },
    [ServiceTypes.DMS]: (isSecure: boolean) => {
        return isSecure
            ? getBearerAccessToken()
            : (getBearerApplicationAccessToken() || getBearerAccessToken());
    },
    [ServiceTypes.APP]: () => (getBearerApplicationAccessToken())
}

const getAuthorizationHeader = (type: ServiceTypes, isSecure: boolean):Record<string, string> => {
    const Authorization = getBearerAccessTokenByType(type, isSecure);

    return Authorization === undefined ? {} : { Authorization };
}

export const setup = (data: Partial<SetupData>): void => {
    setupData = {
        ...setupData,
        ...data
    };

};

const getAdditionalHeaders = (): Record<string, string> => (
    setupData.headers
);

export const getBearerAccessToken = ():string|undefined => (
    setupData.bearerAccessToken
);

export const getBearerAccessTokenByType = (type: ServiceTypes, isSecure: boolean):string|undefined => {
    return ServiceTypeToActionMap[type](isSecure);
};

export const getBearerApplicationAccessToken = ():string|undefined => (
    setupData.bearerApplicationAccessToken
);

export const getBearerFIdentityAccessToken = ():string|undefined => (
    setupData.bearerFIdentityAccessToken
);

export const getServicesUrl = (version: APIVersion = APIVersion.V10):string => {
    if (setupData.SERVICES_URL !== undefined) {
        return setupData.isVersionIncluded
            ? `${setupData.SERVICES_URL}`
            : `${setupData.SERVICES_URL}/${version}`;
    }

    return `${setupData.GATEWAY_API_URL}${setupData.SERVICES_URL_SUFFIX}/${version}`;
};

export const getHeaders = (type: ServiceTypes, isSecure: boolean):Record<string, string> => {

    return {
        'Content-Type': 'application/json',
        ...getAdditionalHeaders(),
        ...getAuthorizationHeader(type, isSecure)
    };
}

export const withBearerApplicationAccessToken = ():boolean => (
    setupData.withBearerApplicationAccessToken
);

export const getDmsUrl = (version:APIVersion = APIVersion.V10):string => {
    if (setupData.DMS_URL !== undefined) {
        return setupData.isVersionIncluded
            ? `${setupData.DMS_URL}`
            : `${setupData.DMS_URL}/${version}`;
    }

    return `${setupData.GATEWAY_API_URL}${setupData.DMS_URL_SUFFIX}/${version}`;
};

export const getCustomUrl = ():string => {
    if (setupData.CUSTOM_URL !== undefined) {
        return `${setupData.CUSTOM_URL}`;
    }

    return `${setupData.GATEWAY_API_URL}${setupData.CUSTOM_URL_SUFFIX}`;
};

export const getEngagementContentUrl = (version:APIVersion = APIVersion.V10):string => {
    if (setupData.ENGAGEMENT_CONTENT_URL !== undefined) {
        return `${setupData.ENGAGEMENT_CONTENT_URL}/${version}`;
    }

    return `${setupData.GATEWAY_API_URL}${setupData.ENGAGEMENT_CONTENT_URL_SUFFIX}/${version}`;
};

export const getFIdentityUrl = (version:APIVersion = APIVersion.V1):string => {
    if (setupData.FIDENTITY_URL !== undefined) {
        return `${setupData.FIDENTITY_URL}/${version}`;
    }

    return `${setupData.GATEWAY_API_URL}${setupData.FIDENTITY_URL_SUFFIX}/${version}`;
};

export const getClientServiceUrl = (version:APIVersion = APIVersion.V10):string => {
    if (setupData.CLIENT_SERVICE_URL !== undefined) {
        return `${setupData.CLIENT_SERVICE_URL}/${version}`;
    }

    return `${setupData.GATEWAY_API_URL}${setupData.CLIENT_SERVICE_URL_SUFFIX}/${version}`;
};

export const getClientServiceHeader = ():Record<string, string> => {
    return setupData.CLIENT_SERVICE_HEADERS;
};

export const getClientServiceMemberUrl = (version:APIVersion = APIVersion.V10):string => {
    if (setupData.CLIENT_SERVICE_URL !== undefined) {
        return `${setupData.CLIENT_SERVICE_URL}/${version}`;
    }

    return `${setupData.GATEWAY_API_URL}${setupData.CLIENT_SERVICE_MEMBER_URL_SUFFIX}/${version}`;
};
