import PropTypes from 'prop-types';
import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {Infobox} from '../../ui-library';

export const InfoErrorList = ({errors}) => {
    if (!errors) return (<></>);
    const {t} = useTranslation();
    const errorList = useMemo(() => {
        if (Array.isArray(errors)) {
            return errors;
        }
        if (typeof errors !== 'string') {
            if (errors?.message) {
                return [errors?.message];
            }

            return [t('advisoryDashboard.errorText')];
        }

        return [errors];
    }, [errors]);

    return (
        <div className="InfoErrorList">
            {
                errorList.map((error) => (
                    <Infobox error>
                        {error}
                    </Infobox>
                ))
            }
        </div>
    );
};

InfoErrorList.propTypes = {
    errors: PropTypes.arrayOf(
        PropTypes.string || PropTypes.element,
    ),
};

InfoErrorList.defaultProps = {
    errors: [],
};
