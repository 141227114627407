import React, {useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {DEFAULT_PAGE_SIZE} from '../../../../../../../Dashboard/compnents/OverviewApplications/constants/const';
import {Table} from '../../../../../../../../../../ui-library';
import {getTransactionColumns} from '../../constants';
import useFormatting from '../../../../../../../../../../locale/useFormatting';
import {useTransactions} from '../../../../../../../../../../domain/Portfolio';
import Preloader from '../../../../../../../../../../components/Preloader';
import '../../styles/styles.css';
import '../../../../styles/Table-ext.css';

const TransactionsTable = ({portfolioId, clientId}) => {
    const {t} = useTranslation();
    const {getFormattedDate, getFormattedNumber} = useFormatting();
    const [currentPage, setCurrentPage] = useState(1);
    const {
        data, dataRaw: { TotalNumberOfResults}, isLoading, getTransactions,
    } = useTransactions({
        portfolioId,
        clientId,
    });

    useEffect(() => {
        getTransactions({optionsParams: {Page: currentPage, PageSize: DEFAULT_PAGE_SIZE, SecurityType: 'All'}});
    }, [currentPage]);

    const columns = useMemo(() => getTransactionColumns({getFormattedDate, getFormattedNumber, t}),
        [getFormattedDate, getFormattedNumber, t]);

    return (
        <Preloader isLoading={isLoading}>
            <Table
                className="transactions-table"
                columns={columns}
                data={data}
                paging={{
                    total: TotalNumberOfResults || 0,
                    pageSize: DEFAULT_PAGE_SIZE,
                    currentPage,
                }}
                onChange={(paging) => setCurrentPage(paging?.currentPage)}
            />
        </Preloader>
    );
};

export default TransactionsTable;
