import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { orderBy } from 'lodash/fp';
import OutsideClickHandler from 'react-outside-click-handler';
import SelectButton from '../../../SelectInput/SelectButton';
import { MultiSelectMiniForm } from '../../../SelectInput';
import './Filter.css';
import Popover from '../../../Popover';

const Filter = (props) => {
    const {
        filters, selectedKeys, setSelectedKeys, searchHidden, headerControls, onReset,
    } = props;
    const filterOptions = orderBy('label', 'asc', filters);
    const [opened, setOpened] = useState(false);
    const onChange = useCallback((value) => {
        setSelectedKeys(value);
        setOpened(false);
    }, [setSelectedKeys]);
    const closeFilter = () => setOpened(false);
    const onOutsideClick = (e) => {
        if (!e.target.closest('.select__dropdown')) setOpened(false);
    };
    const content = (
        <OutsideClickHandler onOutsideClick={onOutsideClick} display="block">
            <MultiSelectMiniForm
                value={selectedKeys}
                opened={opened}
                options={filterOptions}
                searchHidden={searchHidden}
                onReset={onReset}
                onSelect={onChange}
                headerControls={headerControls}
            />
        </OutsideClickHandler>
    );

    useEffect(() => {
        window.addEventListener('resize', closeFilter);
        window.addEventListener('touchmove', closeFilter);

        return () => {
            window.removeEventListener('resize', closeFilter);
            window.removeEventListener('touchmove', closeFilter);
        };
    }, []);

    return (
        <div className="ColumnFilter select select__multi-mini">
            <Popover
                title={content}
                overlayClassName="Table-filters"
                placement="bottomRight"
                trigger="click"
                visible={opened}
            >
                <SelectButton
                    className={opened ? 'opened' : ''}
                    onClick={() => setOpened((bool) => !bool)}
                    ariaControlName="multiselectFilter-dropdown"
                    boxed
                    mini
                />
            </Popover>
        </div>
    );
};

Filter.propTypes = {
    filters: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number]),
    })),
    selectedKeys: PropTypes.arrayOf(PropTypes.string),
    searchHidden: PropTypes.bool,
    onReset: PropTypes.func,
    setSelectedKeys: PropTypes.func,
    headerControls: PropTypes.shape({
        selectControlsLabel: PropTypes.shape({
            selectAll: PropTypes.string,
            reset: PropTypes.string,
        }),
        sortByLabel: PropTypes.string,
    }).isRequired,
};

Filter.defaultProps = {
    filters: [],
    selectedKeys: [],
    searchHidden: false,
    onReset: () => {},
    setSelectedKeys: () => {},
};

export default Filter;
