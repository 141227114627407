import React, {useMemo} from 'react';
import {Route, Routes} from 'react-router-dom';
import {StepsType} from './Stepper.types';
import {StepperManager} from './StepperManager';
import {flattenSteps} from '../../utils/stepDefintion';

type StepperProviderProps = {
    startStep: string;
    items: StepsType;
    layout?: React.ComponentType<any>;
    withCache: boolean;
    completeURL?: string;
    backURL?: string;
}

const DefaultLayout = ({children}: { children: React.ReactNode }) => (<>{children}</>);

export const Stepper = ({
    withCache, items, layout, startStep, completeURL, backURL,
}: StepperProviderProps) => {
    const Layout = useMemo(() => layout || DefaultLayout, [layout]);
    const flatSteps = useMemo(() => flattenSteps(items), [items]);

    return (
        <StepperManager
            withCache={withCache}
            items={items}
            startStep={startStep}
            completeURL={completeURL}
            backURL={backURL}
        >
            <Layout>
                <Routes>
                    {Object.keys(flatSteps).map((key) => {
                        const route = flatSteps[key];
                        const Component = route.component;

                        return (
                            <Route
                                path={route.url}
                                key={key}
                                element={<Component {...{datasource: route.datasource}}/>}
                            />
                        );
                    })}
                </Routes>
            </Layout>
        </StepperManager>
    );
};

Stepper.defaultProps = {
    layout: DefaultLayout,
    completeURL: '/',
    backURL: '/',
};
