import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ServiceManager from 'services/ServiceManager';
import { useFormatting } from 'locale';
import ServerError from 'errors/ServerError';
import AdapterError from 'errors/AdapterError';
import handlerRequestCanceling from 'utils/handlerRequestCanceling';
import HandlerError from 'errors/HandlerError';
import { genarateDFSClientId } from 'utils';
import {useNavigate} from 'react-router-dom';
import { getDocumentsPortfolios } from '../utils';
import { adaptPortfolios } from '../../Portfolios/adapters/adaptPortfolios';
import { portfolioActions } from '../../../constants';

export const usePortfolios = ({ clientId }) => {
    const [error, setError] = useState(null);
    const [isLoadingPortfolios, setLoadingPortfolios] = useState(false);
    const { i18n, t } = useTranslation();
    const { getFormattedCurrency } = useFormatting();
    const navigate = useNavigate();

    const getPortfolios = useCallback(async ({ documents }) => {
        setError(null);
        setLoadingPortfolios(true);

        try {
            const params = { language: i18n.language };
            const { data: allPortfolios } = await ServiceManager.portfolioManagement('getAllPortfolios', [clientId, params]);

            const filteredPortfolios = getDocumentsPortfolios({
                portfolios: allPortfolios, documents,
            });
            const portfoliosOverview = await Promise.all(filteredPortfolios.Portfolios
                .map(async ({ Id }) => ({
                    Id,
                    ...(await ServiceManager.portfolioManagement('getPortfolioOverview', [Id, clientId, params])).data,
                })));

            const dfsId = genarateDFSClientId(clientId);

            try {
                setLoadingPortfolios(false);

                return adaptPortfolios({
                    data: filteredPortfolios,
                    dfsId,
                    portfolioActions,
                    t,
                    getFormattedCurrency,
                    portfoliosOverview,
                    navigate,
                });
            } catch (err) {
                throw new AdapterError(err);
            }
        } catch (err) {
            handlerRequestCanceling(
                HandlerError({
                    setError,
                    setLoadingPortfolios,
                }),
            )(err);
        }
    }, [clientId, i18n.language, t, getFormattedCurrency]);

    return {
        getPortfolios, isLoadingPortfolios, error,
    };
};
