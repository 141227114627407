export const getMatches = (pred) => (theObject) => {
    let result = null;

    if (theObject instanceof Array) {
        for (let i = 0; i < theObject.length; i++) {
            result = getMatches(pred)(theObject[i]);
            if (result) {
                break;
            }
        }
    } else {
        if (pred(theObject)) {
            return theObject;
        }
        for (const prop in theObject) {
            if (theObject[prop] instanceof Object || theObject[prop] instanceof Array) {
                result = getMatches(pred)(theObject[prop]);
                if (result) {
                    break;
                }
            }
        }
    }

    return result;
};
