import React, {
    useCallback, useEffect, useRef, useState,
} from 'react';
import {useTranslation} from 'react-i18next';
import {
    Button, DatePicker, Infobox, Loader, Select,
} from 'ui-library';
import Input from 'components/AtomicStructure/atoms/Input';
import ServiceManager from 'services/ServiceManager';
import SelectElement from 'components/AtomicStructure/atoms/SelectElement';
import {compareDates} from 'utils/datetime';
import Error from 'components/AtomicStructure/atoms/Error';
import {useNavigate} from 'react-router-dom';
import {
    validateFile,
} from '../../../../../../ClientOnboardingFlow/pages/NewsPlan/UploadDocumentsPage/validation/validation';
import {
    convertDate,
} from '../../../../../../ClientOnboardingFlow/pages/NewsPlan/NewPersonalInformationPage/helpers/convertDate';
import {sendModel} from '../models/IndividualForm/sendModel';
import {errorModel} from '../models/IndividualForm/errorModel';
import {useOptionList} from '../../../../../../../hooks/useOptionList';
import {
    IsAllDataNull,
    validateInputString,
    validatePhoneNumber,
    validateSelect,
} from '../../../../../../../validation/validation';
import './IndividualForm.css';
import {OPTION_LIST} from '../../../../../../../constants/constants';

const getBase64 = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
});

export const IndividualForm = (props) => {
    const {
        individualDocumentCategoryList, dfsClientId, portfolioId, onChangeBrokerList, loadedData,
    } = props;

    if (props?.individualList === undefined || props?.individualList === null) return <></>;

    const {t} = useTranslation();
    const navigate = useNavigate();

    const documentUploadInProgressRef = useRef({});
    const errorUploadDocumentsRef = useRef({});
    const filesUploadedRef = useRef({});
    const [data, setData] = useState({});
    const [isDataSent, setDataSent] = useState(false);
    const [errorData, setErrorData] = useState({});
    const [errorPage, setErrorPage] = useState(null);
    const [documentUploadInProgress, setDocumentUploadInProgress] = useState({});
    const [filesUploaded, setFilesUploaded] = useState({});
    const [errorUploadDocuments, setErrorUploadDocuments] = useState({});

    useEffect(() => {
        if (data?.brokerId) {
            onChangeBrokerList(data.brokerId);
        }
    }, [data?.brokerId]);

    useEffect(() => {
        if (individualDocumentCategoryList.length) {
            setData({...data, showSubmitButton: false});
        }
    }, [individualDocumentCategoryList]);

    useEffect(() => {
        if (isDataSent
            && individualDocumentCategoryList.length
            && !individualDocumentCategoryList.find((item) => (item.mandatory && !filesUploaded[item.id]))
        ) {
            showSubmitOrder();
        }
    }, [individualDocumentCategoryList, isDataSent, filesUploaded]);

    useEffect(() => {
        setData(loadedData);
    }, [loadedData]);

    const sendData = async () => {
        try {
            const errors = errorModel(data);

            setErrorData(errors);
            if (IsAllDataNull(errors) === false) {
                return;
            }

            setDataSent(false);
            const response = await ServiceManager.caseManagementService('postIndividualCreate', [sendModel(data)]);

            const orderId = response?.data?.orderId;

            if (orderId) {
                setData({...data, disableButton: true, orderId});
                setDataSent(true);
            } else {
                setErrorPage(t('caseManagment.errorOccured'));
            }
        } catch (err) {
            setErrorPage(err?.message || err);
        }
    };

    const handleUploadDocumentError = (error, categoryId) => {
        errorUploadDocumentsRef.current = {...errorUploadDocumentsRef.current, [categoryId]: error?.message};
        setErrorUploadDocuments(errorUploadDocumentsRef.current);
    };

    const handleUploadDocumentInProgress = (isUploading, categoryId) => {
        documentUploadInProgressRef.current = {...documentUploadInProgressRef.current, [categoryId]: isUploading};
        setDocumentUploadInProgress(documentUploadInProgressRef.current);
    };

    const onFileChange = useCallback(async (e, documentCategoryId) => {
        try {
            const fileData = e.target.files;

            handleUploadDocumentInProgress(true, documentCategoryId);

            if (validateFile(fileData) !== null) {
                setErrorPage(validateFile(fileData));
            }

            const fileContent = await getBase64(fileData[0]);
            const formData = {
                fileName: String(fileData[0].name),
                file: fileContent.split(',')[1],
                orderId: data.orderId,
                category: documentCategoryId,
            };

            await ServiceManager.caseManagementService('postIndividualDocumentUpload', [formData]);

            handleUploadDocumentError(null, documentCategoryId);
            filesUploadedRef.current = {...filesUploadedRef.current, [documentCategoryId]: String(fileData[0].name)};
            setFilesUploaded(filesUploadedRef.current);
        } catch (err) {
            handleUploadDocumentError(err, documentCategoryId);
        } finally {
            handleUploadDocumentInProgress(false, documentCategoryId);
        }
    }, [filesUploadedRef, data.orderId]);

    const showSubmitOrder = () => {
        setData({...data, showSubmitButton: true});
    };

    const submitOrder = async () => {
        setData({...data, isLoadingSubmitButton: true});
        try {
            await ServiceManager.caseManagementService('postSubmitOrder', [{orderId: data.orderId}]);

            navigate(`/client/${dfsClientId}/portfolios/${portfolioId}/case-management/success`);
        } catch (error) {
            setErrorPage(String(error?.message || error));
        }
        setData({...data, isLoadingSubmitButton: false});
    };

    const changeMobileMask = (value, key, symbol) => {
        setErrorData({
            ...errorData,
            [key]: validatePhoneNumber(value),
        });
        if (value !== undefined) {
            if ((value).indexOf(symbol) === -1) {
                setData({...data, [key]: symbol.concat(value)});
            } else {
                setData({...data, [key]: value});
            }
        }
    };

    const onDateClick = (date) => {
        const formatDateValue = convertDate(date);

        setData({...data, dateOfBirth: `${formatDateValue}`, hideDatePickerValue: false});
    };

    const {
        lists: [optionLanguage, optionCountry],
    } = useOptionList([OPTION_LIST.language, OPTION_LIST.country]);

    return (
        <>
            <Select
                label={t('position.orderType')}
                onChange={props.onChange}
                options={props?.individualList.map(item => ({label: item?.name, value: item?.id}))}
            />

            {props?.brokerList.length > 0
                && (
                    <Select
                        label={t('clientDashboard.documents.brokerList')}
                        onChange={(val) => {
                            setData({...data, brokerId: val});
                        }}
                        disabled={!!loadedData?.brokerId}
                        value={data?.brokerId}
                        options={props?.brokerList.map(item => ({label: item?.name, value: item?.id}))}
                    />
                )}

            {props?.individualCategoryList.length > 0
                && (
                    <Select
                        label={t('clientDashboard.documents.category')}
                        onChange={(val) => {
                            props.onChangeCategoryList(val);
                            setData({...data, categoryId: val});
                        }}
                        options={props?.individualCategoryList.map(item => ({label: item?.name, value: item?.id}))}
                    />
                )}

            {props.renderIndividualForm
                && (
                    <>
                        <div className="desktop-col">
                            <Input
                                label={t('interaction.sorting.message')}
                                value={data?.message}
                                onChange={(e) => {
                                    setData({...data, message: e?.target?.value});
                                }}
                                onBlur={(e) => {
                                    setErrorData({
                                        ...errorData,
                                        message: validateInputString(e?.target?.value),
                                    });
                                }}
                                error={errorData?.message}
                                required
                            />

                            <Input
                                label={t('caseManagment.planNumber')}
                                value={data?.planNumber}
                                onChange={(e) => {
                                    setData({...data, planNumber: e?.target?.value});
                                }}
                                onBlur={(e) => {
                                    setErrorData({
                                        ...errorData,
                                        planNumber: validateInputString(e?.target?.value),
                                    });
                                }}
                                error={errorData?.planNumber}
                                disabled={!!loadedData?.planNumber}
                                required
                            />

                            <Input
                                label={t('onboardingFlow.personalPage.firstNames')}
                                value={data?.firstName}
                                disabled={!!loadedData?.firstName}
                                onChange={(e) => {
                                    setData({...data, firstName: e?.target?.value});
                                }}
                                onBlur={(e) => {
                                    setErrorData({
                                        ...errorData,
                                        firstName: validateInputString(e?.target?.value),
                                    });
                                }}
                                error={errorData?.firstName}
                                required
                            />

                            <Input
                                label={t('onboardingFlow.personalPage.surname')}
                                value={data?.lastName}
                                disabled={!!loadedData?.lastName}
                                onChange={(e) => {
                                    setData({...data, lastName: e?.target?.value});
                                }}
                                onBlur={(e) => {
                                    setErrorData({
                                        ...errorData,
                                        lastName: validateInputString(e?.target?.value),
                                    });
                                }}
                                error={errorData?.lastName}
                                required
                            />

                            <Input
                                label={t('caseManagment.customerNumber')}
                                value={data?.customerNumber}
                                disabled={!!loadedData?.customerNumber}
                                onChange={(e) => {
                                    setData({...data, customerNumber: e?.target?.value});
                                }}
                                onBlur={(e) => {
                                    setErrorData({
                                        ...errorData,
                                        customerNumber: validateInputString(e?.target?.value),
                                    });
                                }}
                                error={errorData?.customerNumber}
                                required
                            />

                            <SelectElement
                                label={t('onboardingFlow.personalPage.language')}
                                options={optionLanguage}
                                disabled={!!loadedData?.language}
                                onChange={(val) => {
                                    setData({...data, language: `${val}`});
                                    setErrorData({
                                        ...errorData,
                                        language: validateSelect(`${val}`),
                                    });
                                }}
                                value={data?.language}
                                error={errorData?.language}
                                required
                            />

                            <DatePicker
                                label={t('onboardingFlow.personalPage.dateOfBirth')}
                                value={data?.dateOfBirth}
                                disabled={!!loadedData?.dateOfBirth}
                                onChange={onDateClick}
                                disabledDate={(current) => current
                                    && !compareDates(
                                        new Date(), current,
                                    )}
                                format="DD.MM.YYYY"
                            />

                            <Input
                                label={t('onboardingFlow.personalPage.strret')}
                                value={data?.street}
                                disabled={!!loadedData?.street}
                                onChange={(e) => {
                                    setData({...data, street: e?.target?.value});
                                }}
                            />

                            <Input
                                label={t('caseManagment.additionalAddress1')}
                                value={data?.additionalAddress1}
                                disabled={!!loadedData?.additionalAddress1}
                                onChange={(e) => {
                                    setData({...data, additionalAddress1: e?.target?.value});
                                }}
                            />

                            <Input
                                label={t('caseManagment.additionalAddress2')}
                                value={data?.additionalAddress2}
                                disabled={!!loadedData?.additionalAddress2}
                                onChange={(e) => {
                                    setData({...data, additionalAddress2: e?.target?.value});
                                }}
                            />

                            <Input
                                label={t('onboardingFlow.personalPage.plz')}
                                value={data?.postalCode}
                                disabled={!!loadedData?.postalCode}
                                onChange={(e) => {
                                    setData({...data, postalCode: e?.target?.value});
                                }}
                            />

                            <Input
                                label={t('payments.city')}
                                value={data?.city}
                                disabled={!!loadedData?.city}
                                onChange={(e) => {
                                    setData({...data, city: e?.target?.value});
                                }}
                            />

                            <SelectElement
                                label={t('onboardingFlow.personalPage.countryOfResidence')}
                                options={optionCountry}
                                value={data?.countryOfNationality}
                                disabled={!!loadedData?.countryOfNationality}
                                onChange={(val) => {
                                    setData({...data, countryOfNationality: `${val}`});
                                }}
                            />

                            <Input
                                label={t('advisoryDashboard.profile.contacts.phone')}
                                value={data?.phone}
                                error={errorData?.phone}
                                disabled={!!loadedData?.phone}
                                onChange={(e) => {
                                    changeMobileMask(e?.target?.value, 'phone', '+');
                                }}
                            />

                            <Input
                                label={t('onboardingFlow.personalPage.mobileNumber')}
                                value={data?.mobile}
                                error={errorData?.mobile}
                                disabled={!!loadedData?.mobile}
                                onChange={(e) => {
                                    changeMobileMask(e?.target?.value, 'mobile', '+');
                                }}
                            />

                            <Input
                                label={t('onboardingFlow.personalPage.email')}
                                value={data?.email}
                                disabled={!!loadedData?.email}
                                onChange={(e) => {
                                    setData({...data, email: `${e?.target?.value}`});
                                }}
                            />
                        </div>

                        {/* {errorPage && <Error className="mb-20">{errorPage}</Error>} */}

                        <div className="button-group">

                            <Button
                                className="dynamic-button"
                                type="secondary"
                                onClick={sendData}
                                block
                            >
                                {t('messages.send')}
                            </Button>
                        </div>
                        <div className="desktop-col">
                            {data.disableButton && (
                                individualDocumentCategoryList.length > 0
                                && props.documentCategoryList.map(documentCategory => (
                                    <div className="individual-group" key={documentCategory.id}>
                                        <div>
                                            <label className={`Form-control-label ${documentCategory.mandatory ? 'required' : ''}`}>
                                                {documentCategory.text}
                                            </label>
                                        </div>
                                        <div>
                                            <div className="individual-button-group">

                                                <label htmlFor={`FileSelect_${documentCategory.id}`}>
                                                    &nbsp;
                                                    {(documentUploadInProgress[documentCategory.id])
                                                        ? <Loader small/> : t('advisoryDashboard.documents.selectFile')}
                                                </label>
                                                <input
                                                    disabled={documentUploadInProgress[documentCategory.id]}
                                                    onClick={(e) => {
                                                        e.target.value = null;
                                                    }}
                                                    onChange={(e) => onFileChange(e, documentCategory.id)}
                                                    type="file"
                                                    id={`FileSelect_${documentCategory.id}`}
                                                    accept="application/pdf"
                                                    className="fileButton"
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            {errorUploadDocuments[documentCategory.id]?.length && (
                                                <Error>
                                                    {errorUploadDocuments[documentCategory.id]}
                                                </Error>
                                            )}
                                            {filesUploaded[documentCategory.id] && (
                                                <label>
                                                    {filesUploaded[documentCategory.id]}
                                                </label>
                                            )}
                                        </div>
                                    </div>
                                )))}
                        </div>

                        {errorPage?.length && (
                            <Infobox error>
                                {errorPage}
                            </Infobox>
                        )}

                        <div>
                            {data.showSubmitButton && (
                                <Button
                                    className="dynamic-button"
                                    type="secondary"
                                    onClick={submitOrder}
                                    block
                                    loading={data.isLoadingSubmitButton}
                                >
                                    {t('clientDashboard.portfolioEdit.submit')}
                                </Button>
                            )}
                        </div>
                    </>
                )}
        </>
    );
};
