import React from 'react';
import PropTypes from 'prop-types';
import ChartItem from './ChartItem';
import { adaptDataPieChart, buildDataArray, getSum } from './utils';
import withTheme from '../../../hocs/withTheme';
import 'highcharts/css/highcharts.css';
import './PieChart.css';

function PieChart({
    data, numberFormat, percentFormat, disableLegend, hideValues, chartSize
}) {
    return (
        <div className={`PieChart`}>
            <ChartItem
                sum={getSum(adaptDataPieChart(data, 8))}
                data={buildDataArray(adaptDataPieChart(data, 8))}
                numberFormat={numberFormat}
                percentFormat={percentFormat}
                disableLegend={disableLegend}
                hideValues={hideValues}
                chartSize={chartSize}
            />
        </div>
    );
}

PieChart.propTypes = {
    /** Data to be shown on chart */
    data: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.number.isRequired,
    })),
    numberFormat: PropTypes.func,
    percentFormat: PropTypes.func,
    disableLegend: PropTypes.bool,
    hideValues: PropTypes.bool,
    chartSize: PropTypes.number
};

PieChart.defaultProps = {
    data: [],
    numberFormat: (num) => (num.toLocaleString('en-US', { minimumFractionDigits: 2 })),
    percentFormat: (num) => `${(num.toLocaleString('en-US', { maximumFractionDigits: 2 }))}`,
    disableLegend: false,
    hideValues: false,
    tooltip: false,
};

export default withTheme(PieChart);
