import PropTypes from 'prop-types';
import React from 'react';

const CellPerformanceWithValue = (getFormattedNumber) => ({
    performance,
    value
}) => (
    <div className="table-cell_with-subtext">
        <p className={+performance >= 0 ? 'table-cell_positive' : 'table-cell_negative'}>
            {getFormattedNumber(performance)}
            %
        </p>
        <span>{value}</span>
    </div>
);

CellPerformanceWithValue.propTypes = {
    performance: PropTypes.number,
    value: PropTypes.string,
};

CellPerformanceWithValue.defaultProps = {
    performance: null,
    value: null,
};

export default CellPerformanceWithValue;
