import React from 'react';
import PropTypes from 'prop-types';

function DashboardColumn({
    widgets, widgetsObject,
}) {
    return (
        <>
            {widgets.map((widget) => (
                <div key={widget.id}>
                    {widgetsObject()[widget.type]}
                </div>
            ))}
        </>
    );
}

DashboardColumn.propTypes = {
    widgets: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            type: PropTypes.string,
        }),
    ),
    widgetsObject: PropTypes.func,
};

DashboardColumn.defaultProps = {
    widgets: [],
    widgetsObject: () => {},
};

export default DashboardColumn;
