import {
    ApolloClient,
    ApolloLink,
    ApolloProvider,
    InMemoryCache,
    HttpLink,
    from,
} from '@apollo/client';
import React, {useMemo} from 'react';
import { useTranslation } from 'react-i18next';
import { useSecure } from 'libs/additiv-react-oidc-client';
import { getBearerAccessToken } from 'additiv-services/setup';
import { adaptQueryParams } from './utils';

const URI = `${import.meta.env.VITE_APP_GATEWAY_API_URL || window.location.origin}`;
const httpLink = new HttpLink({ uri: `${URI}${globalThis.AppConfig.GRAPHQL_URL_SUFFIX}` });
const authMiddlewareInit = () => new ApolloLink((operation, forward) => {
    const jwt = getBearerAccessToken();

    if (jwt) {
        const {
            path = globalThis.AppConfig.GRAPHQL_URL_SUFFIX,
            queryParams = {},
        } = operation.getContext();

        operation.setContext(({ headers = {} }) => ({
            headers: { ...headers, Authorization: jwt },
            uri: `${URI}${path}?${operation.operationName}${adaptQueryParams(queryParams)}`,
        }));
    }

    return forward(operation);
});
const languageMiddlewareInit = (language) => new ApolloLink((operation, forward) => {
    operation.setContext(({ headers = {} }) => ({
        headers: { ...headers, 'accept-language': language },
    }));

    return forward(operation);
});

const initClient = (language) => {
    const authMiddleware = authMiddlewareInit();
    const languageMiddleware = languageMiddlewareInit(language);

    return new ApolloClient({
        defaultOptions: {
            watchQuery: {
                fetchPolicy: 'no-cache',
                errorPolicy: 'ignore',
            },
            query: {
                fetchPolicy: 'no-cache',
                errorPolicy: 'all',
            },
        },
        cache: new InMemoryCache(),
        link: from([
            authMiddleware,
            languageMiddleware,
            httpLink,
        ]),
    });
};

export const ApolloClientProvider = (props) => {
    const { children } = props;
    const { i18n: { language } } = useTranslation();
    const client = useMemo(() => (initClient(language)), [language]);

    return (
        <ApolloProvider client={client}>
            {children}
        </ApolloProvider>
    );
};
