import { createContext } from 'react';

const initialContext = {
    clientId: undefined,
    // logic from useQuestionnaire hook
    questionnaire: {
        data: { Groups: [{ Steps: [{ Questions: [{}] }] }] },
        error: null,
        isLoading: false,
        dataPost: null,
        errorPost: null,
        isLoadingPost: false,
    },
};

export const InvestorProfileContext = createContext(initialContext);
