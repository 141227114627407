import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import ApplicationDetails from 'widgets/ApplicationDetails';
import { ClientApplicationOverviewProvider } from 'pages/ClientOverview/pages/ClientApplicationOverview/context';
import { useTranslation } from 'react-i18next';
import { use3aVBApplicationDetails, use3bApplicationDetails } from 'datasource/useApplicationDetails/strategy/product';
import { EXTERNAL_PRODUCTS } from 'constants/constants';
import Preloader from 'components/Preloader';
import { ApplicationDetailsManager } from 'datasource/useApplicationDetails/ApplicationDetailsManager';
import { useGetInvestmentApp } from 'hooks/rest/useGetInvestmentApp';

const ApplicationDetailsPage = () => {
    const { i18n: { language } } = useTranslation();
    const { dfsClientId, containerId, applicationId } = useParams();

    const {
        data: investmentAppsData,
        isLoading: isInvestmentApplicationsLoading,
    } = useGetInvestmentApp({
        containerOrApplicationId: containerId || applicationId, language, isContainer: !!containerId,
    });

    const is3b = useMemo(() => {
        const externalId = investmentAppsData?.[0]?.productExternalId.toLowerCase();

        return ![EXTERNAL_PRODUCTS.aaa, EXTERNAL_PRODUCTS.fz, EXTERNAL_PRODUCTS.fzp].includes(externalId);
    }, [investmentAppsData?.[0]?.productExternalId]);

    return (
        <Preloader isLoading={isInvestmentApplicationsLoading}>
            <ClientApplicationOverviewProvider {...{ dfsClientId, containerId, applicationId }}>
                <ApplicationDetailsManager
                    useDataProvider={is3b ? use3bApplicationDetails : use3aVBApplicationDetails}
                >
                    <div className="container">
                        <div className="fi-verticals">
                            <ApplicationDetails />
                        </div>
                    </div>
                </ApplicationDetailsManager>
            </ClientApplicationOverviewProvider>
        </Preloader>
    );
};

export default ApplicationDetailsPage;
