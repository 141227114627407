import AdapterError from 'errors/AdapterError';
import HandlerError from 'errors/HandlerError';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ServiceManager from 'services/ServiceManager';
import handlerRequestCanceling from 'utils/handlerRequestCanceling';
import {adaptModels, adaptStrategies} from '../adapters/adaptModelPortfolio';

export const useModelList = ({ productId }) => {
    const [error, setError] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const [models, setModels] = useState([]);
    const [riskCategories, setRiskCategories] = useState([]);

    const { i18n } = useTranslation();

    useEffect(() => {
        if (productId) {
            setLoading(true);
            const params = { language: i18n.language };

            ServiceManager.portfolioManagement('getModelPortfolios', [productId, params])
                .then((response) => {
                    try {
                        setModels(adaptModels(response.data));
                        setRiskCategories(adaptStrategies(response.data));
                        setLoading(false);
                    } catch (err) {
                        throw new AdapterError(err);
                    }
                })
                .catch(handlerRequestCanceling(
                    HandlerError({ setError, setLoading }),
                ));
        }
    }, [productId, i18n.language]);

    return {
        products: models, strategies: riskCategories, isLoading, error,
    };
};
