import React from 'react';
import PropTypes from 'prop-types';
import { createUniqueKey, getClassNames, transformOptions } from '../../../utils';
import { optionsList } from '../../../types';
import Loader from '../../Loader';

function OptionList({
    name, options, clicked, selected, searchInProgress,
}) {
    if (searchInProgress) {
        return <Loader/>;
    }

    return transformOptions(options).map(({
        label, value, disabled, ...rest
    }, index) => {
        const className = getClassNames('select__option',
            { 'select__option-active': selected === value },
            { 'select__option-disabled': disabled });

        return (
            <div
                {...rest}
                id={`${name}-${index}`}
                className={className}
                aria-selected={selected === value ? true : undefined}
                role="option"
                tabIndex={0}
                key={createUniqueKey(index, label)}
                onClick={() => !disabled && clicked(value)}
                onKeyPress={() => !disabled && clicked(value)}
            >
                <div className="checkbox-wrapper">{label}</div>
            </div>
        );
    });
}

OptionList.propTypes = {
    name: PropTypes.string,
    options: optionsList,
    clicked: PropTypes.func,
    selected: PropTypes.string,
};

OptionList.defaultProps = {
    name: 'option',
    options: null,
    selected: null,
    clicked: () => {},
};

export default OptionList;
