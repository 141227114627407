import { useEffect, useState } from 'react';

export const useDetectFlexWrap = (ref) => {
    const [wrapped, setWrapped] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            if (ref.current && ref.current.children) {
                setWrapped([...ref.current.children].some((el) => el.offsetTop > 0));
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return { wrapped };
};
