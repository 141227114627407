import React from 'react';
import PropTypes from 'prop-types';
import { PARAGRAPH_TYPE_PRIMARY, PARAGRAPH_TYPES } from './constants';
import { getClassNames } from '../../utils';
import './Paragraph.css';
import withTheme from '../../hocs/withTheme';

/** The paragraphs are used for regular content text */

function Paragraph({
    type, children, className, ...props
}) {
    return (
        <p {...props} className={getClassNames(className, 'Paragraph', `Paragraph_${type}`)}>
            {children}
        </p>
    );
}

Paragraph.propTypes = {
    /** The type of text: primary, secondary, title */
    type: PropTypes.oneOf(PARAGRAPH_TYPES),
    /** @ignore */
    children: PropTypes.node,
    /** @ignore */
    className: PropTypes.string,
};

Paragraph.defaultProps = {
    type: PARAGRAPH_TYPE_PRIMARY,
    children: null,
    className: null,
};

export default withTheme(Paragraph);
