import { useContext } from 'react';

import ClientApplicationOverviewContext from './context';

const useClientApplicationOverview = () => {
    const context = useContext(ClientApplicationOverviewContext);

    if (context === undefined) {
        throw new Error('useClientApplicationOverview can only be used inside ClientDashboardProvider');
    }

    return context;
};

export default useClientApplicationOverview;
