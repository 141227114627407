import React from 'react';
import PropTypes from 'prop-types';
import { getClassNames } from '../../utils';
import Icon, { ICON_TYPE_WARNING, ICON_TYPE_INFOBOX, ICON_TYPE_CHECK_FILLED } from '../Icon';
import './Infobox.css';
import withTheme from '../../hocs/withTheme';

const iconByType = {
    accent: ICON_TYPE_INFOBOX,
    error: ICON_TYPE_WARNING,
    success: ICON_TYPE_CHECK_FILLED,
};

function Infobox({
    accent, error, success, warning, children, className, showIcon = true, icon,
}) {
    const infoboxClassName = getClassNames(
        'Info-box',
        className,
        { 'info-box_accent': accent },
        { 'info-box_error': error },
        { 'info-box_success': success },
        { 'info-box_warning': warning },
    );
    const iconType = [
        { type: accent, icon: iconByType.accent },
        { type: error, icon: iconByType.error },
        { type: success, icon: iconByType.success },
    ].filter(({ type }) => type).pop()?.icon;

    return (
        <div
            className={infoboxClassName}
            role="article"
        >
            {showIcon && <span className="info-box__icon">
            <Icon type={icon || iconType || ICON_TYPE_INFOBOX} />
            </span>}
            <div className="info-box__content">{children}</div>
        </div>
    );
}

Infobox.propTypes = {
    /** Whether the input has accent theme */
    accent: PropTypes.bool,
    /** Whether the input has errored status */
    error: PropTypes.bool,
    /** Whether the input has success status */
    success: PropTypes.bool,
    /** @ignore */
    children: PropTypes.node,
    /** @ignore */
    className: PropTypes.string,
};

Infobox.defaultProps = {
    accent: false,
    error: false,
    success: false,
    children: null,
    className: null,
};

export default withTheme(Infobox);
