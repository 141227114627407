import common from './common';
import Service from './service';
import { getServicesUrl } from '../../setup';
import { APIVersion } from '../../types/main';
import { getQueryStringByObject } from '../../utils/queryUtils';
import Security from './security';

class CommonContent extends Service {
    static async getNews(assetType, language = 'en', params) {
        const queryString = getQueryStringByObject(params);

        await Security.generateBearerApplicationAccessTokenIfNeeded();

        return new Promise(((resolve, reject) => {
            if (assetType === undefined) {
                common.getNewsSettings().then((newsSettings) => {
                    const newsAssetTypeId = newsSettings.Value;

                    Service.getRequest(`${getServicesUrl()}/common/assets?language=${language}&assetType=${newsAssetTypeId}${queryString && `&${queryString}`}`)
                        .then((response) => {
                            resolve(response);
                        })
                        .catch((reason) => {
                            reject(reason);
                        });
                }).catch((reason) => {
                    reject(reason);
                });
            } else {
                Service.getRequest(`${getServicesUrl()}/common/assets?language=${language}&assetType=${assetType}${queryString && `&${queryString}`}`)
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((reason) => {
                        reject(reason);
                    });
            }
        }));
    }

    static generateTemplates(data) {
        return Service.postRequest(
            `${getServicesUrl(APIVersion.V11)}/templates/generateTemplate`, data, { isSecure: true },
        );
    }

    static getAllDatasets() {
        return Service.getRequest(
            `${getServicesUrl()}/rendertemplates/datasets/all`, { isSecure: true },
        );
    }

    static async getResourceSets(language = 'en', config = undefined) {
        await Security.generateBearerApplicationAccessTokenIfNeeded();

        return Service.getRequest(
            `${getServicesUrl()}/resourcesets?language=${language}`,
            config,
        );
    }

    static async getResourceSetsByName(name, language = 'en') {
        await Security.generateBearerApplicationAccessTokenIfNeeded();

        return Service.getRequest(
            `${getServicesUrl()}/resourcesets/${name}?language=${language}`,
        );
    }

    static getRenderTemplate(id) {
        return Service.getRequest(
            `${getServicesUrl()}/rendertemplates/${id}`, { isSecure: true },
        );
    }

    static portPreviewTemplate(data) {
        return Service.postRequest(
            `${getServicesUrl(APIVersion.V21)}/templates/preview`, data, { isSecure: true },
        );
    }
}

export default CommonContent;
