export const adapt = ({data, getFormattedNumber}) => ({
    id: data?.id,
    planId: data?.planId,
    clientId: data?.clientId,
    lpzPlanId: data?.lpzPlanId,
    lpzClientId: data?.lpzClientId,
    vestedBenefitsAge50: getFormattedNumber(data?.vestedBenefitsAge50),
    vestedBenefits: getFormattedNumber(data?.vestedBenefits),
    vestedBenefitsUponMarriage: getFormattedNumber(data?.vestedBenefitsUponMarriage),
    wefEarlyWithdrawal: getFormattedNumber(data?.wefEarlyWithdrawal),
    repaymentWEFEarlyWithdrawal: getFormattedNumber(data?.repaymentWEFEarlyWithdrawal),
    vestedBenefitsDivorce: getFormattedNumber(data?.vestedBenefitsDivorce),
    purchaseIntoPensionFund3years: getFormattedNumber(data?.purchaseIntoPensionFund3years),
    retirementAssetsBVG: getFormattedNumber(data?.retirementAssetsBVG),
    vestedBenefitsAge50LPP: getFormattedNumber(data?.vestedBenefitsAge50LPP),
    vestedBenefitsUponMarriageLPP: getFormattedNumber(data?.vestedBenefitsUponMarriageLPP),
    wefEarlyWithdrawalLPP: getFormattedNumber(data?.wefEarlyWithdrawalLPP),
    repaymentWEFEarlyWithdrawalLPP: getFormattedNumber(data?.repaymentWEFEarlyWithdrawalLPP),
    vestedBenefitsDivorceLPP: getFormattedNumber(data?.vestedBenefitsDivorceLPP),
});
