import React from 'react';
import { useTranslation } from 'react-i18next';
import { Loader } from 'ui-library';
import { formatDate } from 'utils/datetime';
import { formatCurrency, NA } from 'utils/formatting';
import { WithDrawalPlanSummaryProps } from './types';

const WithDrawalPlanSummary:React.FC<WithDrawalPlanSummaryProps> = ({
    display, planCalculationLoading, isAzpD, productExternalId, withdrawalPlanData,
}) => {
    const { t } = useTranslation();

    if (display) {
        return (
            <div className="form withdrawal-summary">
                {planCalculationLoading ? (
                    <div className="withdrawal-loading">
                        <Loader />
                    </div>
                ) : (
                    <>
                        <div className="summary-item">
                            <span className="summary-item__label">{t('3b.withdrawalPlan.typeLabel')}</span>
                            <span className="summary-item__value">{productExternalId}</span>
                        </div>
                        {isAzpD && (
                            <div className="summary-item">
                                <span className="summary-item__label">{t('3b.withdrawalPlan.termOfDeferralPhase')}</span>
                                <span className="summary-item__value">{ withdrawalPlanData?.deferralPhaseDuration && withdrawalPlanData?.deferralPhaseDuration >= 0 ? `${withdrawalPlanData?.deferralPhaseDuration} ${t('3b.withdrawalPlan.monthsLabel')}` : NA}</span>
                            </div>
                        )}
                        <div className="summary-item">
                            <span className="summary-item__label">{t('3b.withdrawalPlan.accountAmount')}</span>
                            <span className="summary-item__value">{formatCurrency(withdrawalPlanData?.amountAccount || 0, 'CHF')}</span>
                        </div>
                        <div className="summary-item">
                            <span className="summary-item__label">{t('3b.withdrawalPlan.amountDeposit')}</span>
                            <span className="summary-item__value">{formatCurrency(withdrawalPlanData?.amountDeposit || 0, 'CHF')}</span>
                        </div>
                        <div className="summary-item">
                            <span className="summary-item__label">{t('3b.withdrawalPlan.withdrawalAmount')}</span>
                            <span className="summary-item__value">{formatCurrency(withdrawalPlanData?.withdrawalAmount ? `${withdrawalPlanData?.withdrawalAmount.toFixed(1)}0` : 0, 'CHF')}</span>
                        </div>
                        <div className="summary-item">
                            <span className="summary-item__label">{t('3b.withdrawalPlan.plannedEndDate')}</span>
                            <span className="summary-item__value">{withdrawalPlanData?.plannedEndDate ? formatDate(withdrawalPlanData?.plannedEndDate, 'MM.YYYY') : NA}</span>
                        </div>
                        <p className="summary-warning">
                            {t('3b.withdrawalPlan.summaryWarning')}
                        </p>
                    </>
                )}
            </div>
        );
    }

    return (
        <div className="form withdrawal-summary">
            <div className="summary-item">
                <span className="summary-item__label">{t('3b.withdrawalPlan.typeLabel')}</span>
                <span className="summary-item__value">{productExternalId}</span>
            </div>
            {isAzpD && (
                <div className="summary-item">
                    <span className="summary-item__label">{t('3b.withdrawalPlan.termOfDeferralPhase')}</span>
                    <span className="summary-item__value">{NA}</span>
                </div>
            )}
            <div className="summary-item">
                <span className="summary-item__label">{t('3b.withdrawalPlan.accountAmount')}</span>
                <span className="summary-item__value">{formatCurrency(0, 'CHF')}</span>
            </div>
            <div className="summary-item">
                <span className="summary-item__label">{t('3b.withdrawalPlan.amountDeposit')}</span>
                <span className="summary-item__value">{formatCurrency(0, 'CHF')}</span>
            </div>
            <div className="summary-item">
                <span className="summary-item__label">{t('3b.withdrawalPlan.withdrawalAmount')}</span>
                <span className="summary-item__value">{formatCurrency(0, 'CHF')}</span>
            </div>
            <div className="summary-item">
                <span className="summary-item__label">{t('3b.withdrawalPlan.plannedEndDate')}</span>
                <span className="summary-item__value">{NA}</span>
            </div>
            <p className="summary-warning">
                {t('3b.withdrawalPlan.summaryWarning')}
            </p>
        </div>
    );
};

export default React.memo(WithDrawalPlanSummary);
