import {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import {useNavigate} from 'react-router-dom';
import {AdditionalData, InvestorProfileHook} from '../types/InvestorProfileHook';
import {useChangePlan} from '../../../../ChangeStrategy/context';
import {genarateDFSClientId} from '../../../../../../../../../utils';

export const useInvestorProfileChangePlan = ({dfsClientId}): InvestorProfileHook => {
    const navigate = useNavigate();
    const [jaUsers, setJaUsers] = useState<Array<any>>([]);
    const {
        riskProfile: { setData: setRiskProfile },
        layout: { setData: setLayoutData, setPageErrors, data: { clientAge, clientName }},
        productDescription,
        portfolioId,
        jointAccountDetails,
        mainOwnerDetails,
        isJointAccount,
        isThreeBProduct,
        portfolioOwners,
    } = useChangePlan();

    const baseUrl = useMemo(() => `/client/${dfsClientId}/portfolios/${portfolioId}/change-strategy`, [dfsClientId, portfolioId]);
    const mainOwnerId = useMemo(() => mainOwnerDetails?.clientId, [mainOwnerDetails]);

    const saveAdditionalData = useCallback(async ({response, answers}: AdditionalData) => {
        setRiskProfile({
            recommendedStrategy: response,
            answers,
        });
    }, []);

    const onBack = useCallback(() => navigate(-1), [navigate]);

    const navigateForward = useCallback(() => navigate(`${baseUrl}/strategy-selection`), [navigate, baseUrl]);

    useEffect(() => {
        const users = portfolioOwners?.map(item => ({
            id: item.id,
            name: item.name,
            dfsClientId: genarateDFSClientId(item.id),
            isValid: true,
        }));

        setJaUsers(users);
    }, [portfolioOwners]);

    useEffect(() => {
        setLayoutData({
            stepNavBarActive: isThreeBProduct ? 3 : 2,
            onBack,
        });
    }, [onBack, isThreeBProduct]);

    return {
        setLayoutData,
        setPageErrors,
        clientAge,
        clientName,
        saveAdditionalData,
        onBack,
        navigateForward,
        productDescription,
        isJointAccount,
        isThreeB: isThreeBProduct,
        jaUsers,
        setJaUsers,
        mainOwnerId,
        targetDFSClientId: genarateDFSClientId(mainOwnerId),
        targetClientName: portfolioOwners.find(owner => owner.id === mainOwnerId)?.name,
        childClientName: portfolioOwners.find(owner => owner.id !== mainOwnerId)?.name,

    };
};
