// eslint-disable-next-line no-useless-escape
export const removeExtension = (filename) => filename.replace(/\.[^\/.]+$/, '');

export const getBase64FromFile = (file:File) => new Promise<string>((resolve, reject) => {
    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
});

export const getFileFromBase64 = async (base64: RequestInfo | URL, name: string) => {
    const base64Response = await fetch(base64);
    const blob = await base64Response.blob();

    return new File([blob], name);
};
