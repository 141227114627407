import {
    useMemo, useCallback, useState, useEffect,
} from 'react';
import {
    HubConnectionState,
    HubConnectionBuilder,
    HubConnection,
} from '@microsoft/signalr';

export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS';
export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const READ_NOTIFICATION = 'READ_NOTIFICATION';

/**
 * Hook for managing connection
 * @param {Object} config
 * @param {String} config.url
 * @param {String} config.accessToken
 * @param {Number} config.debugLevel
 * @param {Boolean} config.startWhenReady
 * @param {number} config.automaticReconnectTimeout
 * @param {Function} config.onUnauthorized
 * @returns {{stop: (function(): void)|*, connectionState: unknown, start: (function(): Promise<void>)|*, setup: setup, error: unknown, notifications: *, connection: HubConnection }}
 */

export const useConnection = (config) => {
    const [error, setError] = useState(undefined);
    const [connectionState, setConnectionState] = useState(HubConnectionState.Disconnected);
    const [connection, setConnection] = useState();
    const [settings, setSettings] = useState(config);
    let timeoutId;

    const init = useCallback(() => {
        try {
            setError(undefined);

            const conn = new HubConnectionBuilder()
                .withUrl(settings.url, {
                    accessTokenFactory: () => settings.accessToken,
                })
                .configureLogging(settings.debugLevel)
                .withAutomaticReconnect({
                    nextRetryDelayInMilliseconds: (retryContext) => (settings.automaticReconnectTimeout),
                })
                .build();

            return conn;
        } catch (err) {
            console.log('init error', err);
            setError(error);
        }

        return false;
    }, [setError, settings]);

    const onUnauthorized = useCallback((receivedError) => {
        if (receivedError.statusCode === 401 && typeof settings.onUnauthorized === 'function') {
            settings.onUnauthorized();

            return true;
        }

        return false;
    }, [settings.onUnauthorized]);

    const start = useCallback(async (con) => (
        new Promise((resolve) => {
            if (con.state !== HubConnectionState.Connected) {
                try {
                    (async function () {
                        if (con.state !== HubConnectionState.Connecting) {
                            await con.start();
                        }

                        await con.startPromise;
                        setConnectionState(con.state);
                        resolve(con);
                    }());
                } catch (err) {
                    const isUnauthorized = onUnauthorized(error);

                    if (!isUnauthorized) {
                        setConnectionState(HubConnectionState.Reconnecting);
                        timeoutId = setTimeout(() => start(con), settings.automaticReconnectTimeout);
                    }
                }
            } else {
                setConnectionState(con.state);
                resolve(con.state);
            }
        })
    ), [setError, setConnectionState, onUnauthorized]);

    const stop = useCallback(() => {
        clearTimeout(timeoutId);
        if (connection) {
            connection.off();
        }
    }, [connection]);

    const initAndStart = useCallback(async () => {
        try {
            stop();
            const conn = init();

            setConnection(await start(conn));
            setError(undefined);
        } catch (err) {
            setError(err);
        }
    }, [setError, settings]);

    const setup = (configs) => {
        setSettings({ ...settings, ...configs });
    };

    useEffect(() => {
        if (settings.url && settings.accessToken) initAndStart();
    }, [settings]);

    return useMemo(() => ({
        connection,
        connectionState,
        setup,
        stop,
        error,
        onUnauthorized,
    }), [
        connection,
        connectionState,
        setup,
        stop,
        error,
        onUnauthorized,
    ]);
};
