import React from 'react';
import PropTypes from 'prop-types';
import uid from '../../../utils/uid';
import ImplicitFileInput from './core/ImplicitFileInput';
import './Upload.css';

const Upload = ({ children, id, ...props }) => {
    const uIdUpload = id || uid('file-input');
    const labelClick = (e) => {
        if (e.target !== e.currentTarget) e.currentTarget.click();
    };


    return (
        <div className="Upload">
            <div>
                <label
                    htmlFor={uIdUpload}
                    onClick={labelClick}
                >
                    {children}
                </label>
            </div>
            <ImplicitFileInput
                {...props}
                id={uIdUpload}
            />
        </div>
    );
};

Upload.propTypes = {
    children: PropTypes.node,
    id: PropTypes.string,
};
Upload.defaultProps = {
    children: null,
    id: null,
};
export default Upload;
