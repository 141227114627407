import React from 'react';
import './SearchBar.css';

function SearchBar(props) {
    return (
        <div className="SearchBarWrapper">
            {props.children}
        </div>
    );
}

export default SearchBar;
