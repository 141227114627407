export const sendModel = (data) => (
    {
        brokerId: String(data.brokerId),
        categoryId: String(data.categoryId),
        message: String(data.message),
        planNumber: String(data.planNumber),
        firstName: String(data.firstName),
        lastName: String(data.lastName),
        customerNumber: String(data.customerNumber),
        langugae: String(data.language),
        dateOfBirth: data.dateOfBirth,
        street: data.street,
        additionalAddress1: data.additionalAddress1,
        additionalAddress2: data.additionalAddress2,
        postalCode: data.postalCode,
        city: data.city,
        countryOfNationality: data.countryOfNationality,
        mobile: data.mobile,
        email: data.email,
    });
