export const getFontSize = (elem) => (
    parseInt(window.getComputedStyle(elem).getPropertyValue('font-size'), 10)
);
export const getInitialsByName = (name = '', withSecondName = false, separator = ' ') => {
    if (typeof name === 'string' && name[0]) {
        const [firstName = '', secondName = ''] = name.split(separator);

        if (withSecondName) {
            return (
                `${firstName[0]}${secondName[0]}`
            );
        }

        return firstName[0];
    }


    return '';
};
