import React, {
    useCallback, useEffect, useState,
} from 'react';
import {useTranslation} from 'react-i18next';
import {Title} from '../../../../../../../../ui-library';
import Preloader from '../../../../../../../../components/Preloader';
import {DocumentItem} from './components/DocumentItem';
import {DocumentType} from './hooks/useAdvisoryDocumentBase';

import './AdvisoryDocument.css';
import {UploadAdvisoryDocumentModal} from './components/UploadAdvisoryDocumentModal';
import Error from '../../../../../../../../components/AtomicStructure/atoms/Error';

export const AdvisoryDocument = ({useData}) => {
    const {t} = useTranslation();
    const {
        isLoading, document, setDocument, error, setError, contactGroupId,
    } = useData();

    const [showModal, setShowModal] = useState(false);

    const onModalClose = useCallback(async (advisoryDocument: DocumentType | null) => {
        setShowModal(false);
        if (advisoryDocument) {
            setError(null);
            setDocument(advisoryDocument);
        }
    }, [setDocument]);

    return (
        <div className="new-portfolio newStyle">
            <Preloader isLoading={isLoading}>
                <div className="advisory-document-container">
                    <Title type={1}>
                        {t('advisoryDocument.step.title')}
                    </Title>
                    {error && <Error className="no-docs-error">{error}</Error>}
                    <DocumentItem document={document} />
                    <button type="button" onClick={() => setShowModal(true)} className="button">{t('advisoryDocument.step.uploadDocButton')}</button>
                </div>
            </Preloader>
            {showModal && <UploadAdvisoryDocumentModal show={showModal} onClose={onModalClose} contactGroupId={contactGroupId}/>}
        </div>
    );
};
