import React, { useEffect, useState } from 'react';
import SM from 'services/ServiceManager';
import { useTranslation } from 'react-i18next';
import { getValFromHTML } from '../utils/getvalfromhtml';

export const useOptionsDocLibrary = () => {
    const [option, setOption] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const { t, i18n } = useTranslation();

    const adaptOptions = (obj) => [...new Set(obj?.map(({ id, name }) => ({ label: getValFromHTML(name, i18n), id: String(id) })))];

    useEffect(() => {
        (async () => {
            try {
                setIsLoading(true);
                const documentCategoriesListResponse = await SM.documentService('getDocumentCategoriesList');

                setOption({
                    documentCategoriesList: adaptOptions(documentCategoriesListResponse?.data),
                });
            } catch (err) {
                setOption({
                    documentCategoriesList: [],
                });
            } finally {
                setIsLoading(false);
            }
        })();
    }, [i18n.language]);

    return {...option, isLoading};
};
