import React, { useEffect, useMemo, useState } from 'react';
import '../../../../styles/Table-ext.css';
import { Table } from 'ui-library';
import { useTranslation } from 'react-i18next';
import { useFormatting } from 'locale';
import { portfolioSelector, usePortfolioSelector } from 'domain/Portfolio';
import { columns } from './columns';
import { adapt } from './adaptor';
import { useSinglePortfolio } from '../../../../context';

const InventoryTable = () => {
    const {t} = useTranslation();
    const [data, setData] = useState([]);

    const { getFormattedNumber, getFormattedDate } = useFormatting();

    const { portfolioDetail } = useSinglePortfolio();

    const positionsColumns = useMemo(() => (
        columns({ t, currency: portfolioDetail?.currency })
    ), [portfolioDetail?.currency]);

    const {
        dataRaw: portfolio,
    } = usePortfolioSelector(
        portfolioSelector,
    );

    useEffect(() => {
        if (portfolio?.Positions?.length) setData(adapt({data: portfolio, getFormattedNumber, getFormattedDate}));
    }, [portfolio]);

    return (
        <Table
            className="Table--ext"
            columns={positionsColumns}
            data={data}
            paging={{
                pageSize: 5,
                total: data?.length ?? 0,
            }}
        />
    );
};

export default InventoryTable;
