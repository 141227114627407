import React from 'react';
import PropTypes from 'prop-types';
import Icon, { ICON_TYPES } from '../Icon';
import { getClassNames } from '../../utils';
import withTheme from '../../hocs/withTheme';

function ExtraItem({
    icon, isActive, onClick, children,
}) {
    const className = getClassNames(
        'Tabs-extra__item',
        { 'Tabs-extra__item_active': isActive },
    );

    return (
        <div className={className}>
            {children || <Icon type={icon} onClick={onClick} />}
        </div>
    );
}

ExtraItem.propTypes = {
    isActive: PropTypes.bool,
    onClick: PropTypes.func,
    icon: PropTypes.oneOf(ICON_TYPES),
    children: PropTypes.node,
};

ExtraItem.defaultProps = {
    isActive: false,
    onClick: () => {},
    icon: null,
    children: null,
};

export default withTheme(ExtraItem);
