import Service from './service';
import { getServicesUrl } from '../../setup';
import { getQueryStringByObject } from '../../utils/queryUtils';
import { APIVersion } from '../../types/main';

class Product extends Service {
    
    static getAccounts(contactId: number, params: any) {
        const queryString = getQueryStringByObject(params);

        return Service.getRequest(`${getServicesUrl(APIVersion.V11)}/contacts/${contactId}/accounts?${queryString}`, { isSecure: true });
    }

    static getProductsBalances(contactId: number, params: any) {
        const queryString = getQueryStringByObject(params);

        return Service.getRequest(`${getServicesUrl()}/contacts/${contactId}/products/balances?${queryString}`, { isSecure: true });
    }
}

export default Product;
