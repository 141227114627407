import {
    useCallback, useEffect, useReducer,
} from 'react';
import {CHANGE_STRATEGY_CACHE_KEY} from '../constants';

const initialState = {
    clientName: '', clientAge: undefined, risk: '',
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'setData':
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

/**
 * @data: { clientName, clientAge, }
 * @returns {{data: {}, setData: (value: (((prevState: {}) => {}) | {})) => void, updateData: ((function(*): void)|*)}}
 */
export const useStrategy = ({portfolioId}) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const setData = useCallback((lData) => {
        const prevStrategyData = JSON.parse(global.sessionStorage.getItem(`${CHANGE_STRATEGY_CACHE_KEY}_${portfolioId || ''}`));

        global.sessionStorage.setItem(`${CHANGE_STRATEGY_CACHE_KEY}_${portfolioId || ''}`, JSON.stringify({...prevStrategyData, ...lData}));
        dispatch({ type: 'setData', payload: lData });
    }, [portfolioId]);

    const removeData = useCallback(() => {
        global.sessionStorage.removeItem(`${CHANGE_STRATEGY_CACHE_KEY}_${portfolioId || ''}`);
        dispatch({ type: 'setData', payload: {} });
    }, [portfolioId]);

    useEffect(() => {
        const cachedNewStrategyData = JSON.parse(global.sessionStorage.getItem(`${CHANGE_STRATEGY_CACHE_KEY}_${portfolioId || ''}`));

        if (cachedNewStrategyData) {
            dispatch({type: 'setData', payload: cachedNewStrategyData});
        }
    }, [dispatch, portfolioId]);

    return {
        data: state,
        setData,
        removeData,
    };
};
