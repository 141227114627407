import moment from 'moment';
import React, {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { date, object, string } from 'yup';
import {
    Button,
    Icon,
    Loader,
    Modal, notification, NumberInput, DatePicker,
} from 'ui-library';
import {useSinglePortfolio} from 'pages/ClientOverview/pages/Portfolios/pages/SinglePortfolioView/context';
import { BUTTON_TYPE_PRIMARY } from 'ui-library/components/Button/constants';
import { FEE_TYPES } from 'pages/ClientOverview/pages/Portfolios/pages/CreateNewPortfolio/constants';
import { ICON_TYPE_DELETE } from 'ui-library/components/Icon';
import ServiceManager from 'services/ServiceManager';
import { useYupValidationResolver } from '../../../../../../../../hooks/useYupValidationResolver';

import './CustodyFeeModal.css';

import {
    addDaysToDate,
    compareDates, dateFormatZurich, formatDateWithoutLocalConversion, getNextQuarterEndDate, isQuarterEndDate,

} from '../../../../../../../../utils/datetime';

const CustodyFeeModal = ({
    handleClose,
    showModal,
    feeData,
    onSave,
    defaultCustodyFee,
    onDeleteCb,
    showDeleteButton = false,
}) => {
    const {t} = useTranslation();
    const [custodyFee, setCustodyFee] = useState(feeData?.fee ?? defaultCustodyFee);
    const [discountValue, setDiscountValue] = useState(feeData?.discountValue || 0);
    const [inProgress, setInProgress] = useState(false);
    const schema = useMemo(() => object().shape({
        startDate: date().required(t('validation.mandatoryField')).nullable(),
        expiryDate: date().nullable(),
    }), [t]);

    const {investmentAppId} = useSinglePortfolio();

    useEffect(() => {
        if (!discountValue) {
            setDiscountValue(feeData?.discountValue || 0);
        }
    }, [feeData?.discountValue]);

    const resolver = useYupValidationResolver(schema);

    const defaultValues = {
        startDate: feeData?.startDate || moment.utc(),
        expiryDate: feeData?.expiryDate || null,
    };

    useEffect(() => {
        reset(defaultValues);
        setCustodyFee(feeData?.fee ?? defaultCustodyFee);
    }, [feeData]);

    const {
        handleSubmit, formState: {errors, isDirty}, control, watch, reset,
    } = useForm({
        resolver,
        defaultValues,
    });

    const data = watch();

    const onClose = () => {
        reset(defaultValues);
        setCustodyFee(feeData?.fee ?? defaultCustodyFee);
        setDiscountValue(feeData?.discountValue || 0);
        handleClose();
    };
    const saveCustodyChanges = async () => {
        try {
            setInProgress(true);
            const finalData = {
                fee: parseFloat(custodyFee, 10),
                discountValue: parseFloat(discountValue, 10),
                startDate: formatDateWithoutLocalConversion(data?.startDate, moment.defaultFormatUtc),
                expiryDate: data?.expiryDate ? formatDateWithoutLocalConversion(data?.expiryDate, moment.defaultFormatUtc) : null,
                feeType: FEE_TYPES.custody,
            };

            // Here to implement API Integration or other way how this will be integrated
            await onSave(FEE_TYPES.custody, finalData);

            handleClose();
        } finally {
            setInProgress(false);
        }
    };

    const onDelete = useCallback(async () => {
        try {
            setInProgress(true);
            await ServiceManager.customInvestmentService('deleteFee', [{
                investmentApplicationId: investmentAppId,
                feeType: FEE_TYPES.custody,
            }]);

            if (onDeleteCb) {
                onDeleteCb({
                    fee: defaultCustodyFee,
                    feeType: FEE_TYPES.custody,
                    startDate: moment(),
                    discountValue: null,
                });
            }

            onClose();

            notification.open({content: t('feeModal.feeDeletedSuccessfully'), type: 'success'});
        } catch (err) {
            notification.open({content: t('general.somethingWentWrong'), type: 'error'});
        } finally {
            setInProgress(false);
        }
    }, [onDeleteCb]);

    const confirmDeletion = useCallback(() => {
        Modal.confirm({
            title: t('feeModal.deleteDiscountConfirmation.title'),
            content: t('feeModal.deleteDiscountConfirmation.content'),
            onOk: onDelete,
        });
    }, [onDelete]);

    const renderFooter = useMemo(() => (
        <div className="CustodyFee__ButtonWrapper">
            <Button disabled={inProgress} onClick={confirmDeletion} type="danger" className={(!feeData?.discountValue || !showDeleteButton) ? 'hidden' : ''}>
                <Icon type={ICON_TYPE_DELETE} />
                {t('custodyFeeModal.buttons.deleteDiscount')}
            </Button>
            <Button disabled={inProgress} onClick={onClose}>
                {t('custodyFeeModal.buttons.cancel')}
            </Button>
            <Button type={BUTTON_TYPE_PRIMARY} disabled={(!isDirty && !discountValue) || inProgress} onClick={handleSubmit(() => saveCustodyChanges())} >
                {inProgress ? <Loader /> : t('custodyFeeModal.buttons.save')}
            </Button>
        </div>
    ), [inProgress, confirmDeletion, feeData?.discountValue, showDeleteButton, onClose, isDirty, discountValue, handleSubmit, saveCustodyChanges]);

    const handleDiscountChange = (e) => {
        const formattedValue = e;

        const tempCustodyFee = Math.max(defaultCustodyFee * (1 - formattedValue / 100), 0);

        // Update the value in the form state
        setCustodyFee(tempCustodyFee?.toFixed(3).replace(/\.?0*$/, ''));
        setDiscountValue(formattedValue);
    };

    return (
        <Modal
            title={t('custodyFeeModal.labels.title')}
            okText="Save"
            width={740}
            visible={showModal}
            onCancel={onClose}
            footer={renderFooter}
        >
            <form>
                <div className="CustodyFee__FieldWrapper">
                    <p>{t('custodyFeeModal.labels.regularFee')}</p>
                    <p>
                        {defaultCustodyFee}
                        %
                    </p>
                </div>
                <div className="CustodyFee__FieldWrapper">
                    <p>{t('custodyFeeModal.labels.percentageFee')}</p>
                    <NumberInput
                        onChange={handleDiscountChange}
                        value={discountValue}
                        validation={{
                            maxValue: 100,
                            minValue: 0,
                        }}
                    />
                </div>
                <div className="CustodyFee__FieldWrapper">
                    <p>{t('custodyFeeModal.labels.startDate')}</p>
                    <Controller
                        control={control}
                        name="startDate"
                        render={
                            ({field}) => (
                                <DatePicker
                                    {...field}
                                    value={data?.startDate && formatDateWithoutLocalConversion(data?.startDate, dateFormatZurich)}
                                    format={dateFormatZurich}
                                    data-cy="startDate"
                                    error={errors?.startDate}
                                    disabledDate={(current) => current && (compareDates(current, data.expiryDate) || moment(current) < moment(addDaysToDate(new Date(), -1)))}
                                    required
                                />
                            )
                        }
                    />
                </div>
                <div className="CustodyFee__FieldWrapper">
                    <div className="CustodyFee__LabelWrapper">
                        <p>{t('custodyFeeModal.labels.expiryDate')}</p>
                        <span>{t('custodyFeeModal.labels.quarterDatesInfo')}</span>
                    </div>
                    <Controller
                        control={control}
                        name="expiryDate"
                        render={
                            ({field}) => (
                                <DatePicker
                                    {...field}
                                    value={data?.expiryDate && formatDateWithoutLocalConversion(data?.expiryDate, dateFormatZurich)}
                                    format={dateFormatZurich}
                                    data-cy="expiryDate"
                                    errors={errors?.expiryDate}
                                    disabledDate={(current) => current && (compareDates(data.startDate, current) || isQuarterEndDate(current))}
                                    required
                                />
                            )
                        }
                    />
                </div>
                <div className="CustodyFee__FieldWrapper">
                    <p>{t('custodyFeeModal.labels.newFee')}</p>
                    <p>
                        <b>
                            {custodyFee}
                            %
                        </b>
                    </p>
                </div>
            </form>
        </Modal>
    );
};

export default CustodyFeeModal;
