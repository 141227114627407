import React from 'react';
import {FEE_TYPES} from 'pages/ClientOverview/pages/Portfolios/pages/CreateNewPortfolio/constants';
import {PRODUCTS} from '../../../../../../../../constants/constants';

export const SHADOW_ACCOUNT_FORM_FIELDS = [
    {
        label: 'shadowAccount.saldo.benefit',
        key: 'pensionAssets',
        type: 'fz',
        fullWidth: true,
    },
    {
        label: 'shadowAccount.saldo.pensionAssets',
        key: 'pensionAssets',
        type: '3a',
        fullWidth: true,
    },
    {
        label: 'shadowAccount.saldo.assetsFromBvg',
        key: 'assetFromBvg',
        type: 'fz',
        fullWidth: true,
    },
    {
        label: 'shadowAccount.saldo.assetsAtAgeOf50',
        key: 'assetsAtAgeOf50',
        type: 'fz',
        fullWidth: true,
    },
    {
        label: 'shadowAccount.saldo.assetsAtMarriage',
        key: 'assetsAtMarriage',
        type: 'fz',
    },
    {
        label: 'shadowAccount.saldo.assetsAtMarriageBvg',
        key: 'assetsAtMarriageBvg',
        type: 'fz',
    },
    {
        label: 'shadowAccount.saldo.wefEarlyWithdrawal',
        key: 'wefEarlyWithdrawal',
    },
    {
        label: 'shadowAccount.saldo.wefEarlyWithdrawalBvg',
        key: 'wefEarlyWithdrawalBvg',
        type: 'fz',
    },
    {
        label: 'shadowAccount.saldo.returnOnEarlyWithdrawal',
        key: 'returnOnEarlyWithdrawal',
    },
    {
        label: 'shadowAccount.saldo.returnOnEarlyWithdrawalBvg',
        key: 'returnOnEarlyWithdrawalBvg',
        type: 'fz',
    },
    {
        label: 'shadowAccount.saldo.transferPensionAssets',
        key: 'transferPensionAssets',
    },
    {
        label: 'shadowAccount.saldo.transferPensionAssetsBvg',
        key: 'transferPensionAssetsBvg',
        type: 'fz',
    },
    {
        label: 'shadowAccount.saldo.purchasePensionFunds',
        key: 'purchasePensionFunds',
        fullWidth: true,
    }];

export const getKudiNotesColumns = ({
    productKey, t, getFormattedDate, getFormattedNumber, currency,
}) => {
    const columns = [
        {
            key: 'type',
            title: t('shadowAccount.notes.type'),
        },
        {
            key: 'code',
            title: t('shadowAccount.notes.code'),
        },
        {
            key: 'text',
            title: t('shadowAccount.notes.text'),
        },
        {
            key: 'amount',
            title: (
                <>
                    {t('shadowAccount.notes.value')}
                    <div className="currency">
                        {t('portfolio.details.column.in')}
                    </div>
                </>),
            render: (val) => getFormattedNumber(val, {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
            }),
        },
        {
            key: 'valueDate',
            title: t('shadowAccount.notes.date'),
            render: (val) => getFormattedDate(val),
        },
    ];

    if ([PRODUCTS.fz, PRODUCTS.fzp].includes(productKey)) {
        columns.splice(4, 0, {
            key: 'amountBvg',
            title: (
                <>
                    {t('shadowAccount.notes.valueBvc')}
                    <div className="currency">
                        {t('portfolio.details.column.in')}
                    </div>
                </>),
            render: (val) => getFormattedNumber(val, {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
            }),
        });
    }

    return columns;
};

export const getShadowAccountPledgeColumns = ({getFormattedDate, getFormattedNumber, t }) => [
    {
        key: 'pledgeName',
        title: t('shadowAccount.pledge.reference'),
        width: 200,
        minWidth: 200,
    },
    {
        key: 'pledgeWef1',
        title: t('shadowAccount.pledge.wef1'),
        width: 200,
        minWidth: 200,
    },
    {
        key: 'pledgeWef2',
        title: t('shadowAccount.pledge.wef2'),
        width: 200,
        minWidth: 200,
    },
    {
        key: 'pledgeWef3',
        title: t('shadowAccount.pledge.wef3'),
        width: 200,
        minWidth: 200,
    },
    {
        key: 'pledgeWef4',
        title: t('shadowAccount.pledge.wef4'),
        width: 200,
        minWidth: 200,
    },
    {
        key: 'pledgeWefAmount',
        title: (
            <>
                {t('shadowAccount.pledge.wefAmount')}
                <div className="currency">
                    {t('portfolio.details.column.in')}
                </div>
            </>),
        render: (val) => getFormattedNumber(val, {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
        }),
        width: 150,
        minWidth: 150,
    },
    {
        key: 'pledgeSetupDate',
        title: t('shadowAccount.pledge.setupDate'),
        render: (val) => getFormattedDate(val),
        width: 150,
        minWidth: 150,
    },
];

export const getTransactionColumns = ({ getFormattedNumber, t }) => [
    {
        key: 'Name',
        title: t('shadowAccount.transactions.code'),
        width: 200,
        minWidth: 200,
    },
    {
        key: 'BookingType',
        title: t('shadowAccount.transactions.type'),
        width: 200,
        minWidth: 200,
    },
    {
        key: 'Text',
        title: t('shadowAccount.transactions.text'),
        width: 200,
        minWidth: 200,
    },
    {
        key: 'Number',
        title: t('shadowAccount.transactions.quantity'),
        width: 150,
        minWidth: 150,
    },
    {
        key: 'Price',
        title: t('shadowAccount.transactions.price'),
        width: 150,
        minWidth: 150,
    },
    {
        key: 'Value',
        title: (
            <>
                {t('shadowAccount.transactions.value')}
                <div className="currency">
                    {t('portfolio.details.column.in')}
                </div>
            </>),
        width: 200,
        minWidth: 200,
    },
    {
        key: 'ExecutionDate',
        title: t('shadowAccount.transactions.date'),
        width: 150,
        minWidth: 150,
    },
    {
        key: 'Total',
        title: (
            <>
                {t('shadowAccount.transactions.total')}
                <div className="currency">
                    {t('portfolio.details.column.in')}
                </div>
            </>),
        width: 200,
        minWidth: 200,
    },
];

export const DEPOSIT_MODAL_TYPE = {
    pbNumber: 'PbNumberAndAdvisor',
    generalAgency: 'GeneralAgency',
    commission: 'IssueCommission',
    custodyFee: 'CustodyFee',
    amFee: 'AMFee',
};

export const FEE_MODAL_TYPE = {
    [FEE_TYPES.custody]: DEPOSIT_MODAL_TYPE.custodyFee,
    [FEE_TYPES.asset]: DEPOSIT_MODAL_TYPE.amFee,
};

export const CHANGE_HISTORY_TYPE = {
    pbNumber: 1,
    generalAgency: 2,
    commission: 3,
};

export const getPbNumberChangeHistoryColumns = (t, getFormattedDate) => [
    {
        title: t('planDetails.changeHistory.name'),
        key: 'modifiedByName',
    },
    {
        title: t('planDetails.changeHistory.date'),
        key: 'modifiedDate',
        render: (val) => getFormattedDate(val),
    },
    {
        title: t('planDetails.changeHistory.prevPBValue'),
        key: 'oldPbNumber',
    },
    {
        title: t('planDetails.changeHistory.newPBValue'),
        key: 'newPbNumber',
    },
];

export const getGeneralAgencyChangeHistoryColumns = (t, getFormattedDate) => [
    {
        title: t('planDetails.changeHistory.name'),
        key: 'modifiedByName',
    },
    {
        title: t('planDetails.changeHistory.date'),
        key: 'modifiedDate',
        render: (val) => getFormattedDate(val),
    },
    {
        title: t('planDetails.changeHistory.prevGeneralAgencyValue'),
        key: 'oldAgencyName',
    },
    {
        title: t('planDetails.changeHistory.newGeneralAgencyValue'),
        key: 'newAgencyName',
    },
];

export const getCommissionChangeHistoryColumns = (t, getFormattedDate, isFee) => [
    {
        title: t('planDetails.changeHistory.name'),
        key: 'createdByName',
    },
    {
        title: t('planDetails.changeHistory.date'),
        key: 'createDate',
        render: (val) => getFormattedDate(val),
    },
    {
        title: t(`planDetails.changeHistory.${isFee ? 'oldFee' : 'prevCommissionValue'}`),
        key: 'oldFeeValue',
        render: (val) => (`${val ?? '-'}%`),
    },
    {
        title: t(`planDetails.changeHistory.${isFee ? 'newFee' : 'newCommissionValue'}`),
        key: 'newFeeValue',
        render: (val) => (`${val ?? '-'}%`),
    },
];
