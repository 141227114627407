import {NOT_IMPLEMENTED} from 'constants/portfolioStatuses';

export const adaptRemoveLinkPortfolio = (data) => {
    if (data === null || data === undefined) return null;

    return data.map(object => {
        if (object.Status.value === NOT_IMPLEMENTED) {
            object.Name.disabled = true;
        }

        return object;
    });
};
