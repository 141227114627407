import React, {useState} from 'react';
import PropTypes from 'prop-types';
import ClientDashboardContext from './context';
import {useCancelAllRequests} from '../../../../../hooks/useCancelAllRequests';
import {useClientLayout} from '../../../hooks/useClientLayout';
import {useClientProfile} from '../../../../../prodivers/clientProfile';
import {getClientId} from '../../../../../utils';
import Preloader from '../../../../../components/Preloader';
import {START_PAGINATION_PAGE} from '../compnents/OverviewApplications/constants/const';

const ClientDashboardProvider = ({dfsClientId, children}) => {
    const [appsPageNumber, setAppsPageNumber] = useState(START_PAGINATION_PAGE);
    const [containerPageNumber, setContainerPageNumber] = useState(START_PAGINATION_PAGE);

    useClientLayout();

    // Client Provider
    const {
        clientName, data: clientData,
    } = useClientProfile();
    const clientId = getClientId(dfsClientId);

    const { isOverviewLoading, overviewError} = useClientProfile();

    const state = {
        clientName,
        clientData,
        clientId,
        dfsClientId,
        setAppsPageNumber,
        appsPageNumber,
        containerPageNumber,
        setContainerPageNumber,
    };

    return (
        <ClientDashboardContext.Provider
            value={{ ...state }}
        >
            <Preloader isLoading={false} error={overviewError}>
                {children}
            </Preloader>
        </ClientDashboardContext.Provider>
    );
};

ClientDashboardProvider.propTypes = {
    dfsClientId: PropTypes.string.isRequired,
    children: PropTypes.node,
};

ClientDashboardProvider.defaultProps = {
    children: null,
};

export default ClientDashboardProvider;
