export const transformFromEmptyNumberToNull = (_: string|number, val: number) => (val === Number(val) ? val : null);

type NumericObject = { [key: string]: number };

export const getIntegerObjectValues = (data): NumericObject => {
    if (typeof data === 'object') {
        const result: NumericObject = {};

        Object.keys(data).forEach(key => {
            if (key !== 'strategyListItemId') {
                result[key] = data[key] * 100;
            } else {
                result[key] = data[key];
            }
        });

        return result;
    }

    return data;
};
