import React from 'react';
import {
    Route, Routes, useLocation, Navigate,
} from 'react-router-dom';
import Layout from 'components/Layout';
import {withSecure} from 'libs/additiv-react-oidc-client';
import withInitLocation from 'hocs/withInitLocation';
import { withMainLayout } from '../hocs';
import { AdvisoryDashboardRouter } from './AdvisoryDashboard';
import { ClientOnboardingFlowRouter } from './ClientOnboardingFlow';
import ClientOverview from './ClientOverview';
import Clients from './Clients';
import { PageNotFound } from '../components/PageNotFound';
import { ManagerProfileProvider } from '../prodivers/managerProfile';
import EditProfile from './EditProfile';
import DocLibraryPage from './DocLibraryPage/DocLibraryPage';
import { Router as ClientLandingRouter } from './ClientLanding';
import InfraTest from './Infra/Infra';
import Reports from './Reports/Reports';

const AdvisoryDashboardRouterSecure = withSecure(AdvisoryDashboardRouter);
const PageNotFoundSecure = withSecure(PageNotFound);
const ClientsWrapped = withSecure(withMainLayout(Clients));
const EditProfileWrapped = withSecure(withMainLayout(EditProfile));
const ReportsWrapped = withSecure(withMainLayout(Reports));
const InfraTestWrapped = withSecure(withMainLayout(InfraTest));
const DocLibraryPageWrapped = withSecure(DocLibraryPage);
const ClientOverviewRouter = withSecure(ClientOverview);
const ClientLandingWrapper = withSecure(ClientLandingRouter);

const Router = () => {
    const { pathname } = useLocation();

    return (
        <ManagerProfileProvider options={{ pathname }}>
            <Layout >
                <Routes>
                    <Route
                        path="dashboard/*"
                        element={<AdvisoryDashboardRouterSecure/>}
                    />
                    <Route
                        path="onboardingflow/*"
                        element={<ClientOnboardingFlowRouter/>}
                    />
                    <Route
                        path="client/:dfsClientId/*"
                        element={<ClientOverviewRouter/>}
                    />
                    <Route path="clients" element={<ClientsWrapped/>} />
                    <Route path="reports" element={<ReportsWrapped/>} />
                    <Route
                        path="edit-profile"
                        element={<EditProfileWrapped/>}
                    />
                    <Route
                        path="clientlanding/*"
                        element={<ClientLandingWrapper />}
                    />
                    <Route path="infra-test" element={<InfraTestWrapped/>} />

                    <Route
                        path=""
                        element={<Navigate to="dashboard" replace />}
                    />
                    <Route
                        path="login"
                        element={<Navigate to="/dashboard" replace />}
                    />
                    <Route
                        path="auth"
                        element={<Navigate to="dashboard" replace />}
                    />
                    <Route path="documents/" element={<DocLibraryPageWrapped/>} />

                    <Route path="404" element={<PageNotFoundSecure/>} />
                    <Route
                        path="*"
                        element={<Navigate to="/404" replace />}
                    />
                </Routes>
            </Layout>
        </ManagerProfileProvider>
    );
};

Router.displayName = 'Router';

export default withInitLocation(Router);
