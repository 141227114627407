export const SORT_DIRECTION_ASC = 'asc';
export const SORT_DIRECTION_ASCEND = 'ascend';
export const SORT_DIRECTION_DESC = 'desc';
export const SORT_DIRECTION_DESCEND = 'descend';
export const SORT_DIRECTION_DESCENDING = 'descending';
export const SORT_DIRECTION_ASCENDING = 'ascending';

export const SORT_DIRECTIONS = [
    SORT_DIRECTION_ASC,
    SORT_DIRECTION_ASCEND,
    SORT_DIRECTION_DESC,
    SORT_DIRECTION_DESCEND,
    SORT_DIRECTION_DESCENDING,
    SORT_DIRECTION_ASCENDING,
];

export const SORT_ORDER = [
    false,
    SORT_DIRECTION_ASC,
    SORT_DIRECTION_DESC,
];

export const SORT_ORDER_MINI = [
    SORT_DIRECTION_ASC,
    SORT_DIRECTION_DESC,
];

export const defaultPageSize = 10;
