import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Menu from './Menu';
import Popover from '../Popover';
import './Dropdown.css';
import withTheme from '../../hocs/withTheme';
import { getClassNames } from '../../utils';

function Dropdown({
    title, children, placement, visible: visibleProp, onVisibleChange,
    overlayClassName, ...rest
}) {
    const [visible, setVisible] = useState(visibleProp);
    const onChange = (show) => {
        setVisible(show);
        onVisibleChange(show);
    };

    const menu = <Menu {...rest} className="dropdown">{children}</Menu>;


    useEffect(() => {
        setVisible(visibleProp);
    }, [visibleProp]);

    return (
        <button className={getClassNames('Dropdown-container', { 'Dropdown-container__opened': visible })}>
            <Popover
                placement={placement}
                content={menu}
                trigger={['click']}
                overlayClassName={`dropdown-inner ${overlayClassName}`}
                visible={visible}
                onVisibleChange={onChange}
            >
                {title}
            </Popover>
        </button>
    );
}

Dropdown.propTypes = {
    /** The title text */
    title: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.string,
        PropTypes.element,
    ]),
    /** The placement of dropdown */
    placement: PropTypes.string,
    /** Whether the dropdown is visible */
    visible: PropTypes.bool,
    /** @ignore */
    overlayClassName: PropTypes.string,
    /** @ignore */
    children: PropTypes.node,
    /** @ignore */
    onVisibleChange: PropTypes.func,
};

Dropdown.defaultProps = {
    title: null,
    visible: false,
    overlayClassName: '',
    children: null,
    placement: 'bottomLeft',
    onVisibleChange: () => {
    },
};

export default withTheme(Dropdown);
