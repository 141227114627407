import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {PageVariant} from 'ui-library';
import PageVariantWrapper from '../PageVariantWrapper';
import './ErrorBoundary.css';

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            errorInfo: null
        };
    }

    backToDashboard = () => {
        const {navigate} = this.props;

        navigate('/dashboard');
        window.location.reload();
    };

    componentDidCatch(error, errorInfo) {
        this.setState({
            error,
            errorInfo
        });
    }

    render() {
        const {
            state: {
                error,
                errorInfo
            },
            props: {t},
        } = this;

        if (errorInfo) {
            return (
                <PageVariantWrapper className="ErrorBoundary">
                    <PageVariant
                        type="error"
                        title={t('advisoryDashboard.error')}
                        message={t('advisoryDashboard.errorText')}
                        actionButtonLabel={t('common.backToDashboard')}
                        onActionButtonClick={this.backToDashboard}
                    />
                </PageVariantWrapper>
            );
        }

        return this.props.children;
    }
}

export default withTranslation()(ErrorBoundary);
