import React from 'react';
import PropTypes from 'prop-types';
import withTheme from '../../hocs/withTheme';
import './TabbedNavigation.css';

function TabbedNavigation({ children }) {
    return (
        <div className="Tabbed-navigation">
            {children}
        </div>
    );
}

TabbedNavigation.propTypes = {
    /** @ignore */
    children: PropTypes.node,
};

TabbedNavigation.defaultProps = {
    children: null,
};

export default withTheme(TabbedNavigation);
