import React from 'react';
import Preloader from 'components/Preloader';
import { useClientApplicationOverview } from '../../context';
import InvestmentApplicationItem from '../InvestmentApplicationItem/InvestmentApplicationItem';

function InvestmentApplicationProducts() {
    const {
        appsData: data,
        isAppsLoading: isLoading,
    } = useClientApplicationOverview();

    return (
        <div>
            <Preloader isLoading={isLoading} >
                {data?.map((product) => (
                    <InvestmentApplicationItem
                        productData={product}
                        key={product?.applicationId}
                    />
                ))}
            </Preloader>
        </div>
    );
}

export default InvestmentApplicationProducts;
