import { createContext } from 'react';

const initialContext = {
    contactId: undefined,
    // logic from use useContact
    clarityWidget: {
        data: null,
        error: null,
        isLoading: false,
        dataUpdateContact: null,
        errorUpdateContact: null,
        isLoadingUpdateContact: false,
    },
    // logic from use useSchema
    schema: {
        data: null,
        error: null,
        isLoading: false,
    },
};

const ClientServiceContext = createContext(initialContext);

export default ClientServiceContext;
