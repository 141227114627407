import ServiceManager from './ServiceManager';

export const APPLICATION_SCOPE = 1;
export const NOTIFICATION_SCOPE = 2;

export const generateAccessToken = (params = {
    type: APPLICATION_SCOPE,
    access_Token: undefined,
}) => (
    new Promise((resolve, reject) => {
        ServiceManager.Security(
            'generateAccessToken',
            [{ payload: { ...params } }],
        ).then((data) => {
            resolve(data);
        }).catch((e) => {
            reject(e);
        });
    })
);
