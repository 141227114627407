import Highcharts from 'highcharts';

Highcharts.SVGRenderer.prototype.symbols.goal = (x, y, w, h) => [
    'M', x + w * 0.5, y, 'C', x + w * 0.224, y, x, y + h * 0.224, x, y + h * 0.5,
    'C', x, y + h * 0.776, x + w * 0.224, y + h * 1.0, x + w * 0.5, y + h * 1.0,
    'C', x + w * 0.776, y + h * 1.0, x + w * 1.0, y + h * 0.776, x + w * 1.0, y + h * 0.5,
    'C', x + w * 1.0, y + h * 0.224, x + w * 0.776, y, x + w * 0.5, y, 'Z',
    'M', x + w * 0.625, y + h * 0.081,
    'C', x + w * 0.766, y + h * 0.123, x + w * 0.877, y + h * 0.234, x + w * 0.919, y + h * 0.375,
    'L', x + w * 0.716, y + h * 0.375, 'C', x + w * 0.694, y + h * 0.337, x + w * 0.663, y + h * 0.306, x + w * 0.625, y + h * 0.284,
    'L', x + w * 0.625, y + h * 0.081, 'Z', 'M', x + w * 0.68700000625, y + h * 0.5,
    'C', x + w * 0.68700000625, y + h * 0.60400000375, x + w * 0.60300000375, y + h * 0.68700000625, x + w * 0.5, y + h * 0.68700000625,
    'C', x + w * 0.39699999625, y + h * 0.68700000625, x + w * 0.31299999375, y + h * 0.60400000375, x + w * 0.31299999375, y + h * 0.5,
    'C', x + w * 0.31299999375, y + h * 0.39599999625, x + w * 0.39699999625, y + h * 0.31299999375, x + w * 0.5, y + h * 0.31299999375,
    'C', x + w * 0.60300000375, y + h * 0.31299999375, x + w * 0.68700000625, y + h * 0.39699999625, x + w * 0.68700000625, y + h * 0.5,
    'Z', 'M', x + w * 0.43799999375, y + h * 0.067000000625,
    'C', x + w * 0.45799999375, y + h * 0.06400000125, x + w * 0.47899999375, y + h * 0.06300000125, x + w * 0.49999999375, y + h * 0.06300000125,
    'C', x + w * 0.52099999375, y + h * 0.06300000125, x + w * 0.54199999375, y + h * 0.06400000125, x + w * 0.56299999375, y + h * 0.067000000625,
    'L', x + w * 0.56299999375, y + h * 0.25799999375,
    'C', x + w * 0.54299999375, y + h * 0.25299999375, x + w * 0.52199999375, y + h * 0.249999994375, x + w * 0.49999999375, y + h * 0.249999994375,
    'C', x + w * 0.47799999375, y + h * 0.249999994375, x + w * 0.45799999375, y + h * 0.25299999375, x + w * 0.43799999375, y + h * 0.25799999375,
    'L', x + w * 0.43799999375, y + h * 0.067000000625, 'L', x + w * 0.43799999375, y + h * 0.067000000625, 'Z', 'M', x + w * 0.375, y + h * 0.081,
    'L', x + w * 0.375, y + h * 0.284, 'C', x + w * 0.337, y + h * 0.306, x + w * 0.306, y + h * 0.337, x + w * 0.284, y + h * 0.375,
    'L', x + w * 0.081, y + h * 0.375, 'C', x + w * 0.123, y + h * 0.234, x + w * 0.234, y + h * 0.123, x + w * 0.375, y + h * 0.081,
    'Z', 'M', x + w * 0.06300000125, y + h * 0.50000000625,
    'C', x + w * 0.06300000125, y + h * 0.47900000625, x + w * 0.06500000125, y + h * 0.45800000625, x + w * 0.068000000625, y + h * 0.43700000625,
    'L', x + w * 0.25799999375, y + h * 0.43700000625,
    'C', x + w * 0.25299999375, y + h * 0.45700000625, x + w * 0.249999994375, y + h * 0.47800000625, x + w * 0.249999994375, y + h * 0.50000000625,
    'C', x + w * 0.249999994375, y + h * 0.52200000625, x + w * 0.25299999375, y + h * 0.54300000625, x + w * 0.25799999375, y + h * 0.56200000625,
    'L', x + w * 0.068000000625, y + h * 0.56200000625,
    'C', x + w * 0.06500000125, y + h * 0.54200000625, x + w * 0.06300000125, y + h * 0.52100000625, x + w * 0.06300000125, y + h * 0.50000000625,
    'Z', 'M', x + w * 0.375, y + h * 0.919, 'C', x + w * 0.234, y + h * 0.877, x + w * 0.123, y + h * 0.766, x + w * 0.081, y + h * 0.625,
    'L', x + w * 0.284, y + h * 0.625, 'C', x + w * 0.306, y + h * 0.663, x + w * 0.337, y + h * 0.694, x + w * 0.375, y + h * 0.716,
    'L', x + w * 0.375, y + h * 0.919, 'Z', 'M', x + w * 0.56299999375, y + h * 0.93299998125,
    'C', x + w * 0.54199999375, y + h * 0.93599998125, x + w * 0.52099999375, y + h * 0.93799998125, x + w * 0.49999999375, y + h * 0.93799998125,
    'C', x + w * 0.47899999375, y + h * 0.93799998125, x + w * 0.45799999375, y + h * 0.93599998125, x + w * 0.43799999375, y + h * 0.93299998125,
    'L', x + w * 0.43799999375, y + h * 0.74199998125,
    'C', x + w * 0.45799999375, y + h * 0.74699998125, x + w * 0.47799999375, y + h * 0.74999998125, x + w * 0.49999999375, y + h * 0.74999998125,
    'C', x + w * 0.52199999375, y + h * 0.74999998125, x + w * 0.54299999375, y + h * 0.74699998125, x + w * 0.56299999375, y + h * 0.74199998125,
    'L', x + w * 0.56299999375, y + h * 0.93299998125,
    'Z', 'M', x + w * 0.625, y + h * 0.919,
    'L', x + w * 0.625, y + h * 0.716,
    'C', x + w * 0.663, y + h * 0.694, x + w * 0.694, y + h * 0.663, x + w * 0.716, y + h * 0.625,
    'L', x + w * 0.919, y + h * 0.625, 'C', x + w * 0.877, y + h * 0.766, x + w * 0.766, y + h * 0.877, x + w * 0.625, y + h * 0.919,
    'Z', 'M', x + w * 0.74199998125, y + h * 0.56200000625,
    'C', x + w * 0.74699998125, y + h * 0.54300000625, x + w * 0.74999998125, y + h * 0.52200000625, x + w * 0.74999998125, y + h * 0.50000000625,
    'C', x + w * 0.74999998125, y + h * 0.47800000625, x + w * 0.74699998125, y + h * 0.45700000625, x + w * 0.74199998125, y + h * 0.43700000625,
    'L', x + w * 0.931999975, y + h * 0.43700000625, 'C', x + w * 0.934999975, y + h * 0.45800000625, x + w * 0.936999975, y + h * 0.47900000625, x + w * 0.936999975, y + h * 0.50000000625,
    'C', x + w * 0.936999975, y + h * 0.52100000625, x + w * 0.934999975, y + h * 0.54200000625, x + w * 0.932999975, y + h * 0.56200000625, 'L', x + w * 0.74199998125, y + h * 0.56200000625,
    'Z',
];

if (Highcharts.VMLRenderer) {
    Highcharts.VMLRenderer.prototype.symbols.goal = Highcharts.SVGRenderer.prototype.symbols.goal;
}

export default Highcharts;
