import LogoEmblem from 'clientRequirements/images/main_logo-emblem.svg';

import './styles/HeaderNavbar.css';
import Logo from 'clientRequirements/images/main_logo.svg';
import React from 'react';

const HeaderNavbar = (props) => (
    <>
        <div className="headerNavBar headerNavBar--top">
            <div className={'container headerNavBarInner'}>
                <div>
                    <h1 className="headTitle">{props.headTitle}</h1>
                </div>
                <div>
                    {/*<LanguageSwitcher />*/}
                    <img className="mainLogo" src={Logo} alt="Zurich Versicherungen"/>
                    <img className="mainLogo-sm" src={LogoEmblem} alt="Zurich Versicherungen"/>
                </div>
            </div>
        </div>
    </>
);

export default HeaderNavbar;
