/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, {useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {Icon, Loader} from 'ui-library';
import {ICON_DOCS_OUTLINE, ICON_DOWNLOAD} from 'ui-library/components/Icon';
import {DocumentType, useAdvisoryDocumentBase} from '../hooks/useAdvisoryDocumentBase';
import {useFormatting} from '../../../../../../../../../locale';
import {AdvisorySystem} from './UploadAdvisoryDocumentModal';

import './DocumentItem.css';

type DocumentItemProps = {
    document: DocumentType | null
}

export const DocumentItem = ({document}: DocumentItemProps) => {
    const { t } = useTranslation();
    const {getFormattedDate} = useFormatting();
    const {downloadDocument, downloadDocumentInProgress} = useAdvisoryDocumentBase();

    const advisorySystem = useMemo(() => {
        const itemKey = Object.keys(AdvisorySystem).find(key => AdvisorySystem[key] === document?.advisorySystem);

        return itemKey;
    }, [document]);

    if (!document) {
        return null;
    }

    return (
        <div className="advisory-document-item">
            {/* @ts-ignore */}
            <Icon type={ICON_DOCS_OUTLINE} size={40} />
            <div>
                <div>{document?.fileName || '<documentName>.pdf'}</div>
                <div className="details">
                    <p>{t('advisoryDocument.date')}</p>
                    <p>{getFormattedDate(document?.advisoryDate)}</p>
                    <p>{t('advisoryDocument.number')}</p>
                    <p>{document?.advisoryNumber}</p>
                    <p>{t('advisoryDocument.system')}</p>
                    <p>{advisorySystem}</p>
                </div>
            </div>
            {/* @ts-ignore */}
            <div>{downloadDocumentInProgress ? <Loader/> : <Icon type={ICON_DOWNLOAD} size={24} onClick={() => downloadDocument(document.documentId)} className="download"/>}</div>
        </div>
    );
};
