import React from 'react';
import {useTranslation} from 'react-i18next';
import {Select} from 'ui-library';
import './SwitchForm.css';

export const SwitchForm = (props) => {
    const {t} = useTranslation();

    if (props?.listsData === undefined || props?.listsData === null) return <></>;

    return (
        <>
            <Select
                label={t('caseManagment.cases')}
                onChange={props.onChange}
                options={props?.listsData.map(item => ({label: item?.label, value: item?.id}))}
            />
        </>
    );
};
