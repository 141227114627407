export const cashFlowsColumns = (t, currency) => [
    {
        key: 'KeyValue',
        title: '',
        width: '25%',
    },
    {
        key: 'CurrentMonth',
        title: `${t('portfolios.cashFlowsColumns.currentMonth')}${currency ? ` (${currency})` : ''}`,
        width: '25%',
        className: 'text-right',
    },
    {
        key: 'YTD',
        title: `${t('portfolios.cashFlowsColumns.YTD')}${currency ? ` (${currency})` : ''}`,
        width: '25%',
        className: 'text-right',
    },
    {
        key: 'SinceInception',
        title: `${t('portfolios.cashFlowsColumns.sinceInception')}${currency ? ` (${currency})` : ''}`,
        width: '25%',
        className: 'text-right',
    },
];

export const CHART_DISPLAY_OPTION = {
    percentage: 'Value',
    amount: 'Amount',
};

export const NEW_RETURN_DATE = '01.01.2023';
export const NEW_3B_RETURN_DATE = '01.04.2024';
