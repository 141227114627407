import React from 'react';
import PropTypes from 'prop-types';
import CellWithIcon from './CellWithIcon';

const CellWithButtonIcon = ({ type, onClickIcon, size }) => {
    const cellWithIcon = CellWithIcon(type, size);

    return (
        <div className="table-cell_with-button-icon">
            <button type="button" onClick={onClickIcon}>
                {cellWithIcon}
            </button>
        </div>
    );
};

CellWithButtonIcon.propTypes = {
    type: PropTypes.string.isRequired,
    size: PropTypes.number,
    onClickIcon: PropTypes.string.isRequired,
};

CellWithButtonIcon.defaultProps = {
    size: 20,
};

export default CellWithButtonIcon;
