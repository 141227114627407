/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useCallback, useState } from 'react';
import { Button, Icon } from 'ui-library';

import { ICON_DOCS_OUTLINE, ICON_DOWNLOAD } from 'ui-library/components/Icon';
import './DocItem.css';
import { Loader } from '../../../../ui-library';

type DocItemProps = {
    name?: string;
    date: string;
    id?: number,
    onDownload?: (documentId: number) => Promise<void>
}

export const DocItem = ({
    name = '<documentname>.pdf', date, onDownload, id,
}: DocItemProps) => {
    const [downloadInProgress, setDownloadInProgress] = useState<boolean>(false);

    const handleDownload = useCallback(async () => {
        try {
            setDownloadInProgress(true);
            if (onDownload) {
                await onDownload(id!);
            }
        } finally {
            setDownloadInProgress(false);
        }
    }, [onDownload, id]);

    return (
        <div className="doc-item">
            {/* @ts-ignore */}
            <Icon type={ICON_DOCS_OUTLINE} size={24}/>
            <div className="doc-details">
                <span className="doc-name">{name}</span>
                <span className="doc-date">{date}</span>
            </div>
            <Button disabled={downloadInProgress} type="link" size="small" onClick={handleDownload}>
                {/* @ts-ignore */}
                {downloadInProgress ? <Loader /> : <Icon type={ICON_DOWNLOAD} size={24}/>}
            </Button>
        </div>
    );
};

export default DocItem;
