import { getServicesUrl } from '../../setup';
import Service from './service';

class Goals extends Service {
    static getTemplates(language, productId) {
        const queryString = productId !== undefined ? `&productId=${productId}` : '';

        return Service.getRequest(`${getServicesUrl()}/goals/templates?language=${language}${queryString}`, { isSecure: true });
    }

    static getTemplatePicture(templateId) {
        return Service.getRequest(`${getServicesUrl()}/goals/templates/${templateId}/picture`, { isSecure: true });
    }

    /**
     * Create goal and portfolios
     * @param {contactId} number.
     * @param {data} json
     * data json:
     * {
     *   CurrencyId: 1,
     *   GoalAttributes: {
     *      Name: 'Goal name',
     *      TargetAmount: 100000,
     *      Status: 1,
     *      GoalType: 1,
     *      GoalTemplateId: 1,
     *   },
     *   PortfolioAttributes: {
     *      ModelPortfolioId: 1,
     *      Name: 'Portfolio name',
     *      ProductId: 1,
     *      RebalancingType: 'Automatic',
     *      InitialInvestmentAmount: 60000,
     *   },
     * }
     */
    static createGoalAndPortfolios(contactId, data) {
        return Service.postRequest(
            `${getServicesUrl()}/contacts/${contactId}/goalportfolios`, data, { isSecure: true }
        );
    }

    static getContactGoals(contactId, language, currencyId) {
        let getParams = `?language=${language}`;
        if (currencyId) getParams += `&currencyId=${currencyId}`;

        return Service.getRequest(`${getServicesUrl()}/contacts/${contactId}/goals${getParams}`, { isSecure: true });
    }

    static saveGoal(contactId, language, data) {
        const languageGet = language && `?language=${language}`;

        return Service.postRequest(
            `${getServicesUrl()}/contacts/${contactId}/goals${languageGet}`, data, { isSecure: true }
        );
    }

    static patchGoal(contactId, goalId, language, data) {
        const languageGet = language && `?language=${language}`;

        return Service.patchRequest(
            `${getServicesUrl()}/contacts/${contactId}/goals/${goalId}${languageGet}`, data, { isSecure: true }
        );
    }
}

export default Goals;
