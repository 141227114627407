import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Button, Icon, Loader, Modal, notification, Title,
} from 'ui-library';
import { ICON_TYPE_BIN, ICON_TYPE_CLOSE } from 'ui-library/components/Icon';
import { useNavigate, useParams } from 'react-router-dom';
import { useApplicationDetails } from '../../../datasource/useApplicationDetails';
import './ApplicationDetailsHeader.css';

const ApplicationDetailsHeader = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [showUploadModal, setShowUploadModal] = useState(false);
    const { dfsClientId } = useParams();
    const backURL = useMemo(() => (`/client/${dfsClientId}`), [dfsClientId]);
    const [isCancelInProgress, setCancelInProgress] = useState(false);

    const {
        cancelAllApplications,
        isShoppingCardCancellable,
    } = useApplicationDetails();

    const handleCancelInvestmentApplication = useCallback(async () => {
        let updateApplications = () => {
        };

        try {
            setCancelInProgress(true);
            updateApplications = await cancelAllApplications();

            notification.open({
                content: t('dashboard.overviewClientApplications.canceledSuccessfully'),
                type: 'success',
            });
        } catch (err) {
            notification.open({
                content: t('dashboard.overviewClientApplications.cancelError'),
                type: 'error',
            });
        } finally {
            setShowUploadModal(false);
            setCancelInProgress(false);
            await updateApplications();
        }
    }, []);

    return (
        <>
            <div className="application-details-header">
                <div>
                    <Button
                        type="link"
                        onClick={() => {
                            navigate(backURL);
                        }}
                    >
                        <Icon size={16} className="mr-10" type="arrow-left"/>
                        {t('applicationDetailsHeader.backToOverview')}
                    </Button>
                </div>

                <div>
                    <Title type="1" className="application-details-header--title font-normal">
                        {t('applicationDetailsHeader.applications')}
                    </Title>
                </div>

                {isShoppingCardCancellable && (
                    <div className="flex flex-end">
                        <Button
                            className="application-details-header--delete-btn"
                            type="link"
                            onClick={() => setShowUploadModal(true)}
                        >
                            <Icon size={16} className="mr-10" type={ICON_TYPE_BIN}/>
                            {t('applicationDetailsHeader.Delete')}
                        </Button>
                    </div>
                )}
            </div>
            {showUploadModal && (
                <Modal
                    title=""
                    visible
                    okText={isCancelInProgress ? <Loader/> : 'Delete'}
                    cancelText="Cancel"
                    onOk={handleCancelInvestmentApplication}
                    okButtonProps={{ disabled: false }}
                    cancelButtonProps={{ disabled: false }}
                    onCancel={() => setShowUploadModal(false)}
                    width={512}
                    className="delete-application-modal"
                    borderRadius={24}
                    closable
                    closeIcon={<Icon type={ICON_TYPE_CLOSE} size={16}/>}
                >
                    <Title type={2} className="font-normal mb-32">
                        {t('applicationDetailsHeader.DeleteApplication')}
                    </Title>
                    <Title type={3} className="font-normal">
                        {t('applicationDetailsHeader.DeleteApplication.Confirmation')}
                    </Title>
                </Modal>
            )}
        </>
    );
};

export default ApplicationDetailsHeader;
