import Service from './service';
import {
    getServicesUrl
} from '../../setup';
import { APIVersion } from '../../types/main';

class Messages extends Service {
    static MESSAGE_NOT_ALLOWED = 'MessageNotAllowed';

    static SUCCESS = 'Success';

    static getMemberInboxMessages({
        pageNumber = 1,
        pageSize = 50,
        memberId,
        isRead,
        ...rest
    }) {
        return Service.postRequest(`${getServicesUrl(APIVersion.V21)}/members/${memberId}/messages/inbox`,
            {
                PageNumber: pageNumber,
                PageSize: pageSize,
                IsRead: isRead,
                ...rest
            },
            {
                isSecure: true
            });
    }

    static getClientInboxMessages({
        pageNumber = 1,
        pageSize = 50,
        contactId,
        memberId,
        isRead,
        ...rest
    }) {
        const senderIds = memberId !== undefined ? { SenderIds: [memberId] } : {};

        return Service.postRequest(`${getServicesUrl(APIVersion.V21)}/contacts/${contactId}/messages/inbox`,
            {
                PageNumber: pageNumber,
                PageSize: pageSize,
                IsRead: isRead,
                ...senderIds,
                ...rest
            },
            {
                isSecure: true
            });
    }

    static getClientSentMessages({ pageNumber = 1, pageSize = 50, ...rest }, contactId, memberId) {
        const recipientIds = memberId !== undefined ? { RecipientIds: [memberId] } : {};

        return Service.postRequest(`${getServicesUrl(APIVersion.V21)}/contacts/${contactId}/messages/sent`, {
            PageNumber: pageNumber,
            PageSize: pageSize,
            ...recipientIds,
            ...rest
        },
        {
            isSecure: true
        });
    }

    static getMemberSentMessages({ pageNumber = 1, pageSize = 50, ...rest }, memberId) {
        return Service.postRequest(`${getServicesUrl(APIVersion.V21)}/members/${memberId}/messages/sent`, {
            PageNumber: pageNumber,
            PageSize: pageSize,
            ...rest
        },
        {
            isSecure: true
        });
    }

    static getNumberOfUnreadMessages(contactId) {
        return Service.getRequest(`${getServicesUrl()}/persons/${contactId}/messages/unread/count`, { isSecure: true });
    }

    static readMessage(id, contactId) {
        return Service.patchRequest(`${getServicesUrl(APIVersion.V11)}/persons/${contactId}/messages/${id}`, { IsRead: true }, { isSecure: true });
    }

    static deleteMessage(id, contactId) {
        return Service.deleteRequest(`${getServicesUrl()}/persons/${contactId}/messages/${id}`, { isSecure: true });
    }

    static sendMessage({
        contactId, contactReason, subject, message, memberId, dtoAttachments
    }) {
        return Service.postRequest(`${getServicesUrl(APIVersion.V11)}/persons/${contactId}/messages`, {
            ContactMessageSourceId: 1,
            ContactMessageSubtypeId: contactReason,
            Subject: subject,
            Text: message,
            RecipientId: memberId,
            Attachments: dtoAttachments.map((item) => ({
                FileName: item.OriginalFilename,
                Content: item.File
            }))
        }, { isSecure: true });
    }

    static sendMessageFromContact({
        contactId, contactReason, subject, message, dtoAttachments
    }) {
        return Service.postRequest(`${getServicesUrl(APIVersion.V20)}/contacts/${contactId}/messages/send`, {
            SubtypeId: contactReason,
            Subject: subject,
            Text: message,
            Attachments: dtoAttachments.map((item) => ({
                FileName: item.OriginalFilename,
                Content: item.File
            }))
        }, { isSecure: true });
    }

    static sendMessageFromContactToMember({
        contactId, contactReason, subject, message, memberId, dtoAttachments
    }) {
        return Service.postRequest(`${getServicesUrl(APIVersion.V20)}/contacts/${contactId}/members/${memberId}/messages/send`, {
            SubtypeId: contactReason,
            Subject: subject,
            Text: message,
            Attachments: dtoAttachments.map((item) => ({
                FileName: item.OriginalFilename,
                Content: item.File
            }))
        }, { isSecure: true });
    }

    static sendMessageFromMember({
        contactReason, subject, message, memberId, dtoAttachments, recipientIds
    }) {
        return Service.postRequest(`${getServicesUrl(APIVersion.V20)}/members/${memberId}/messages/send`, {
            RecipientIds: recipientIds,
            SubtypeId: contactReason,
            Subject: subject,
            Text: message,
            Attachments: dtoAttachments.map((item) => ({
                FileName: item.OriginalFilename,
                Content: item.File
            }))
        }, { isSecure: true });
    }

    static replyMessage({
        personId, subject: Subject, message, messageId, dtoAttachments
    }) {
        return Service.postRequest(`${getServicesUrl(APIVersion.V20)}/persons/${personId}/messages/${messageId}/reply`, {
            Subject,
            Text: message,
            Attachments: dtoAttachments.map((item) => ({
                FileName: item.OriginalFilename,
                Content: item.File
            }))
        }, { isSecure: true });
    }

    static getContactThreads(contactId, {
        ThreadId,
        ...rest
    }) {
        return Service.postRequest(`${getServicesUrl(APIVersion.V22)}/contacts/${contactId}/messages/threads`, {
            ThreadId,
            ...rest
        }, { isSecure: true });
    }

    static getMemberThreads(memberId, {
        ThreadId,
        ...rest
    }) {
        return Service.postRequest(`${getServicesUrl(APIVersion.V22)}/members/${memberId}/hierarchy/messages/threads`, {
            ThreadId,
            ...rest
        }, { isSecure: true });
    }

    static getMessageTypes(language) {
        return Service.getRequest(`${getServicesUrl(APIVersion.V20)}/message-types?language=${language}`, { isSecure: true });
    }

    static getMessageAttachment(personId, messageId, attachmentId) {
        return Service.getRequest(`${getServicesUrl(APIVersion.V20)}/persons/${personId}/messages/${messageId}/attachments/${attachmentId}`, { isSecure: true });
    }

    static getMessageContent(id, contactId) {
        return Service.getRequest(`${getServicesUrl(APIVersion.V20)}/persons/${contactId}/messages/${id}`, { isSecure: true });
    }
}

export default Messages;
