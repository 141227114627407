import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import HeaderButton from './HeaderButton';
import HeaderNavigation from './HeaderNavigation';
import { buttonConfig } from './types';
import { useWindowDimensions } from '../../hooks/useWindowDimensions';
import ProfileMenu from '../ProfileMenu';
import Navigation, { NavigationLink } from '../Navigation';
import { ICON_TYPE_MENU, ICON_TYPE_LOGIN } from '../Icon/constants';
import { createUniqueKey, getClassNames } from '../../utils';
import withTheme from '../../hocs/withTheme';
import {
    HEADER_TYPES,
    HEADER_STANDARD_TYPE,
    HEADER_STACKED_TYPE,
    HEADER_SIDE_BAR_TYPE,
    HEADER_RIGHT_BAR_TYPE,
} from './constants';

import './Header.css';

/** Use the header according to the current context of the displayed page. */

function Header({
    logo, title, content, link, children, profileInfo, quickAccessMenu,
    navigation, navigationTablet, navigationOpen, closeNavigation,
    leftButton, rightButton, onLeftButtonClick, onRightButtonClick,
    labelMore, type,
}) {
    const { isDesktop, isTablet, isMobile } = useWindowDimensions();
    const [leftButtonElement, rightButtonElement] = children;
    const className = getClassNames('Header', type, { 'header_with-navigation': navigation.length > 0 });
    const headerLogo = useMemo(() => (logo ? <img src={logo} alt={title} /> : content),
        [logo, title, content]);
    const headerLogoLink = useMemo(() => {
        let headerLogoLinkComponent = headerLogo;
        const LinkComponent = link;

        headerLogoLinkComponent = (typeof link) === 'string' ? <Link to={link} title={title}>{headerLogo}</Link> : headerLogoLinkComponent;
        headerLogoLinkComponent = (typeof link) === 'function' ? <LinkComponent>{headerLogo}</LinkComponent> : headerLogoLinkComponent;

        return headerLogoLinkComponent;
    }, [headerLogo, link, title]);
    const headerAvatarMenu = useMemo(() => (<ProfileMenu {...profileInfo} />), [profileInfo]);
    const navigationMobile = useMemo(() => (
        navigationTablet || (
            <Navigation
                isOpen={navigationOpen}
                onClose={closeNavigation}
            >
                {navigation.map(({ title, link, ...rest }, index) => (
                    <NavigationLink
                        key={createUniqueKey(index, title)}
                        to={link}
                        {...rest}
                    >
                        {title}
                    </NavigationLink>
                ))}
            </Navigation>
        )
    ), [navigationTablet, navigationOpen, closeNavigation, navigation]);
    const headerLeft = useMemo(() => (
        leftButtonElement || (
            <HeaderButton
                type={leftButton.type}
                ariaLabel={leftButton.ariaLabel}
                className="Left-button"
                onClick={onLeftButtonClick}
            >
                {leftButton.label}
            </HeaderButton>
        )
    ), [leftButtonElement, leftButton, onLeftButtonClick]);

    const headerRight = useMemo(() => (
        rightButtonElement || (
            <HeaderButton type={rightButton.type} className="right-button" onClick={onRightButtonClick}>
                {rightButton.label}
            </HeaderButton>
        )
    ), [rightButtonElement, rightButton, onRightButtonClick]);

    const renderHeaderLeft = useMemo(() => {
        if (type === HEADER_SIDE_BAR_TYPE) {
            return (
                <div className="Header__left">
                    {headerLeft}
                </div>
            );
        }

        return (
            <div className="Header__left">
                {(isDesktop && navigation.length > 0) || type === HEADER_STACKED_TYPE
                    ? (<div className="Header__logo">{headerLogoLink}</div>)
                    : headerLeft}
            </div>
        );
    }, [isDesktop, headerLeft, headerLogoLink, navigation, type]);

    const renderHeaderCenter = useMemo(() => {
        if (type === HEADER_SIDE_BAR_TYPE) {
            return (
                <div className="Header__center">
                    <div className="Header__logo">{headerLogoLink}</div>
                </div>
            );
        }

        return (
            (
                <div className="Header__center">
                    {isDesktop && navigation.length > 0
                        ? <HeaderNavigation labelMore={labelMore} navigation={navigation} />
                        : (<div className="Header__logo">{headerLogoLink}</div>)}
                </div>
            )
        );
    }, [isDesktop, navigation, labelMore, headerLogoLink, type]);

    const renderHeaderRight = useMemo(() => (
        <div className="Header__right">
            {quickAccessMenu}
            {navigation.length > 0 ? headerAvatarMenu : headerRight}
        </div>
    ), [quickAccessMenu, navigation, headerAvatarMenu, headerRight]);

    const renderStackedHeader = useMemo(() => {
        if (type === HEADER_STACKED_TYPE) {
            return (
                <div className="Header__bottom">
                    {isDesktop && navigation.length > 0 ? renderHeaderCenter : headerLeft}
                    {renderHeaderRight}
                </div>
            );
        }

        return (
            <>
                {renderHeaderCenter}
                {renderHeaderRight}
            </>
        );
    }, [renderHeaderCenter, renderHeaderRight, type, headerLeft, isDesktop, navigation]);

    const renderMobileNavigation = useMemo(() => (
        isTablet || isMobile ? navigationMobile : null
    ), [isTablet, isMobile, navigationMobile]);

    return (
        <>
            <header className={className}>
                <div className="container">
                    {renderHeaderLeft}
                    {renderStackedHeader}
                </div>
            </header>
            {renderMobileNavigation}
        </>
    );
}

Header.propTypes = {
    type: PropTypes.oneOf(HEADER_TYPES),
    /** Source for the Logo */
    logo: PropTypes.node,
    /** Alternative text for logo image and title attribute for the link */
    title: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string,
    ]),
    /** Logo link */
    link: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    /** Header navigation */
    navigation: PropTypes.arrayOf(PropTypes.shape({
        link: PropTypes.string,
        title: PropTypes.string,
        active: PropTypes.bool,
        hasChanges: PropTypes.bool,
    })),
    /** Header profile props */
    profileInfo: PropTypes.shape({
        image: PropTypes.string,
        notifications: PropTypes.oneOfType([
            PropTypes.array,
            PropTypes.object,
        ]),
        name: PropTypes.string,
        email: PropTypes.string,
        editText: PropTypes.string,
        logoutText: PropTypes.string,
        withAlert: PropTypes.bool,
        onEdit: PropTypes.func,
        onLogout: PropTypes.func,
    }),
    /** Navigation menu for mobile version */
    navigationTablet: PropTypes.node,
    /** Navigation menu isOpen */
    navigationOpen: PropTypes.bool,
    /** @ignore */
    children: PropTypes.node,
    /** Default Icon and Label for left button */
    leftButton: buttonConfig,
    /** Default Icon and Label for right button */
    rightButton: buttonConfig,
    /** The callback function that is triggered when the close button is clicked */
    closeNavigation: PropTypes.func,
    /** Handler for onClick to the left button ('menu' for default header) */
    onLeftButtonClick: PropTypes.func,
    /** Handler for onClick to the right button ('login' for default header) */
    onRightButtonClick: PropTypes.func,
    /** Aria label more */
    labelMore: PropTypes.string,
    /** @ignore */
    content: PropTypes.node,
    /** @ignore */
    quickAccessMenu: PropTypes.node,
};

Header.defaultProps = {
    type: HEADER_STANDARD_TYPE,
    logo: null,
    title: 'Advisory',
    link: '/',
    navigation: [],
    profileInfo: {
        image: '',
        notifications: [],
        name: undefined,
        email: undefined,
        editText: 'Edit profile',
        logoutText: 'Logout',
        withAlert: false,
        onEdit: () => { },
        onLogout: () => { },
    },
    navigationTablet: undefined,
    navigationOpen: false,
    children: [],
    leftButton: {
        type: ICON_TYPE_MENU,
        label: null,
        ariaLabel: 'Open menu',
    },
    rightButton: {
        type: ICON_TYPE_LOGIN,
        label: null,
    },
    closeNavigation: () => { },
    onLeftButtonClick: () => { },
    onRightButtonClick: () => { },
    content: null,
    quickAccessMenu: null,
    labelMore: 'More',
};

export {
    HEADER_TYPES,
    HEADER_STANDARD_TYPE,
    HEADER_STACKED_TYPE,
    HEADER_SIDE_BAR_TYPE,
    HEADER_RIGHT_BAR_TYPE,
};

export default withTheme(Header);
