import React from 'react';
import PropTypes from 'prop-types';
import RadioButton from '../../RadioButton';
import Checkbox from '../../Checkbox';

const Selection = (props) => (data, { key }) => {
    const { type, selectedRowKeys, onChange } = props;
    const checked = selectedRowKeys.includes(key);
    const Select = type === 'checkbox' ? Checkbox : RadioButton;

    return (
        <div className="row-selection">
            <Select
                label=""
                checked={checked}
                onChange={() => onChange(key)}
            />
        </div>
    );
};

Selection.propTypes = {
    type: PropTypes.string.isRequired,
    selectedRowKeys: PropTypes.arrayOf(PropTypes.string),
    onChange: PropTypes.func,
};

Selection.defaultProps = {
    selectedRowKeys: [],
    onChange: () => {},
};

export default Selection;
