export const adaptDocLibrary = (data, downloadDocument, downloadInProgress, onDelete, deleteInProgress) => (data || [])
    .map((item) => {
        const {
            documentMetadataName,
            productName,
            translations,
            documentFileNames,
        } = item;

        return {
            documentMetadataName,
            productName,
            linkDownload: {
                options: Object.keys(translations).map(key => ({
                    text: key, id: translations[key],
                })),
                productName,
                documentMetadataName,
                documentFileNames,
                onDownload: downloadDocument,
                downloadInProgress,
                onDelete,
                deleteInProgress,
            },
        };
    });
