import React, {useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {isEmpty} from 'lodash';
import Card from '../../../../components/Card/Card';
import {useManagerProfile} from '../../../../prodivers/managerProfile';
import Preloader from '../../../../components/Preloader';
import EmptyContent from '../../../../components/EmptyContent';
import {CustomTable} from '../../../../components/CustomTable';
import {DEFAULT_PAGE_SIZE} from '../BusinessCase/constants/const';
import {
    containersColumns,
} from '../../../ClientOverview/pages/Dashboard/compnents/OverviewApplications/constants/const';

const BusinessContainers = () => {
    const {t} = useTranslation();
    const tableColumns = containersColumns(t);
    const {
        containersLoading,
        containersError,
        containersData,
        containersTotal: totalPages,
        investmentContainerPageNumber,
        setInvestmentContainerPageNumber,
    } = useManagerProfile();

    const onChangePage = useCallback((paging) => {
        setInvestmentContainerPageNumber(paging);
    }, [setInvestmentContainerPageNumber]);

    if (isEmpty(containersData)) {
        return null;
    }

    return (
        <Card title={t('dashboard.agentCockpit.overviewClientContainers')}>
            <Preloader isLoading={containersLoading} error={containersError}>
                <EmptyContent data={containersData} text="Not Found">
                    <CustomTable
                        columns={tableColumns}
                        data={containersData}
                        onChange={onChangePage}
                        paging={{
                            pageSize: DEFAULT_PAGE_SIZE,
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            pageNumber: investmentContainerPageNumber,
                            totalPages,
                        }}
                    />
                </EmptyContent>
            </Preloader>
        </Card>
    );
};

export default BusinessContainers;
