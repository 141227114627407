import React, {useEffect, useState} from 'react';
import CallbackContainer from '../containers/Callback.container';
import SilentContainer from '../containers/Silent.container';
import PostlogoutContainer from '../containers/Postlogout.container';
import {getPath} from '../utils';

interface OidcRoutesProps {
    children: React.ReactChild | React.ReactChild[] | null,
    callbackComponentOverride?: React.ReactElement | string | null,
    postLogoutComponentOverride?: React.ReactElement | string | null,
    silentComponentOverride?: React.ReactElement | string | null,
    onRedirect?: (path: string) => void,
    routes: {
        callbackPath?: string,
        silentPath?: string,
        postLogoutPath?: string,
    }
}

interface RedirectEvent extends PopStateEvent {
    detail?: { urlPath?: string }
}

const OidcRoutes = ({
                        children = null,
                        callbackComponentOverride = null,
                        silentComponentOverride = null,
                        postLogoutComponentOverride = null,
                        routes,
                        onRedirect,
                    }: OidcRoutesProps) => {
    const [path, setPath] = useState(window.location.pathname);

    const setNewPath = (event: RedirectEvent) => {
        const {urlPath} = event?.detail || {}

        if (urlPath) {

            setPath(urlPath);
            if (typeof onRedirect === 'function') onRedirect(urlPath);
        }
    };

    useEffect(() => {
        window.addEventListener('popstate', setNewPath, false);

        return () => window.removeEventListener('popstate', setNewPath, false);
    }, []);

    switch (path) {
        case getPath(routes?.callbackPath):
            return (
                <CallbackContainer>
                    {callbackComponentOverride}
                </CallbackContainer>
            );
        case getPath(routes?.silentPath):
            return (
                <SilentContainer>
                    {silentComponentOverride}
                </SilentContainer>
            );
        case getPath(routes?.postLogoutPath):
            return (
                <PostlogoutContainer>
                    {postLogoutComponentOverride}
                </PostlogoutContainer>
            );
        default:
            return <>{children}</>;
    }
};

export default OidcRoutes;
