import {CUSTOM_SERVICE_LAYER_SERVICES, getCustomServiceLayerBaseUrl} from 'services/constants';
import Service from './service';
import { getServicesUrl } from '../../setup';
import { APIVersion } from '../../types/main';
import { getQueryStringByObject } from '../../utils/queryUtils';

class PortfolioManagement extends Service {
    static #baseCustomContactServiceUrl = getCustomServiceLayerBaseUrl(CUSTOM_SERVICE_LAYER_SERVICES.CONTACT);

    constructor() {
        super();

        this.user = null;
    }

    static getInvestmentOverview(contactId, params, version = APIVersion.V10) {
        const queryString = getQueryStringByObject(params);

        return Service.getRequest(
            `${getServicesUrl(version)}/contacts/${contactId}/investment-overview?${queryString}`,
            { isSecure: true },
        );
    }

    static getAllPortfolios(contactId, params) {
        const queryString = getQueryStringByObject(params);

        return Service.getRequest(
            `${getServicesUrl()}/contacts/${contactId}/portfolios?${queryString}`,
            { isSecure: true },
        );
    }

    static getJointPortfolios(contactId, params) {
        const queryString = getQueryStringByObject(params);

        return Service.getRequest(
            `${this.#baseCustomContactServiceUrl}/${contactId}/portfolios/my-accounts?${queryString}`,
            { isSecure: true },
        );
    }

    static getPOAPortfolios(contactId, params) {
        const queryString = getQueryStringByObject(params);

        return Service.getRequest(
            `${this.#baseCustomContactServiceUrl}/${contactId}/portfolios/power-of-attorney?${queryString}`,
            { isSecure: true },
        );
    }

    static getPAPortfolios(contactId, params) {
        const queryString = getQueryStringByObject(params);

        return Service.getRequest(
            `${this.#baseCustomContactServiceUrl}/${contactId}/portfolios/parental-authority?${queryString}`,
            { isSecure: true },
        );
    }

    static getHistoricalPerformance(contactId, params) {
        const queryString = getQueryStringByObject(params);

        return Service.getRequest(
            `${getServicesUrl()}/contacts/${contactId}/investment-overview/performance?${queryString}`,
            { isSecure: true },
        );
    }

    static getPortfolios(contactId, params) {
        const queryString = getQueryStringByObject(params);

        return Service.getRequest(
            `${getServicesUrl()}/contacts/${contactId}/portfolios?${queryString}`,
            { isSecure: true },
        );
    }

    static getPortfolioDetails(portfolioId, contactId, params) {
        const queryString = getQueryStringByObject(params);

        return Service.getRequest(
            `${getServicesUrl()}/contacts/${contactId}/portfolios/${portfolioId}?${queryString}`,
            { isSecure: true },
        );
    }

    static getPortfolioOverview(portfolioId, contactId, params) {
        const queryString = getQueryStringByObject(params);

        return Service.getRequest(
            `${getServicesUrl()}/contacts/${contactId}/portfolios/${portfolioId}/overview?${queryString}`,
            { isSecure: true },
        );
    }

    static patchPortfolioDetails(portfolioId, contactId, options) {
        return Service.patchRequest(
            `${getServicesUrl()}/contacts/${contactId}/portfolios/${portfolioId}`,
            options,
            { isSecure: true },
        );
    }

    static getInvestmentOverviewPerformance(contactId, params) {
        const queryString = getQueryStringByObject(params);

        return Service.getRequest(
            `${getServicesUrl()}/contacts/${contactId}/investment-overview/performance?${queryString}`,
            { isSecure: true },
        );
    }

    static getPortfolioDetailsPerformance(
        portfolioId,
        contactId,
        params,
        requestOptions,
    ) {
        const queryString = getQueryStringByObject(params);

        return Service.getRequest(
            `${getServicesUrl()}/contacts/${contactId}/portfolios/${portfolioId}/performance?${queryString}`,
            {
                ...(requestOptions || {}),
                isSecure: true,
            },
        );
    }

    static getModelPortfolio(portfolioId, params) {
        const queryString = getQueryStringByObject(params);

        return Service.getRequest(
            `${getServicesUrl(
                APIVersion.V12,
            )}/model-portfolios/${portfolioId}?${queryString}`,
            { isSecure: true, cache: true },
        );
    }

    static getModelPortfolios(productId, params) {
        const queryString = getQueryStringByObject(params);

        return Service.getRequest(
            `${getServicesUrl()}/products/${productId}/model-portfolios?${queryString}`,
            { isSecure: true, cache: true },
        );
    }

    static getModelPortfolioDetails(memberId, modelPortfolioId, params) {
        const queryString = getQueryStringByObject(params);

        return Service.getRequest(
            `${getServicesUrl(
                APIVersion.V12,
            )}/members/${memberId}/model-portfolios/${modelPortfolioId}?${queryString}`,
            { isSecure: true },
        );
    }

    static getPortfolioTrnsactions(contactId, portfolioId, filter) {
        return Service.postRequest(
            `${getServicesUrl(
                APIVersion.V11,
            )}/contacts/${contactId}/portfolios/${portfolioId}/transactions`,
            filter,
            { isSecure: true },
        );
    }

    static getPortfolioAccounts(contactId, portfolioId, params) {
        const queryString = getQueryStringByObject(params);

        return Service.getRequest(
            `${getServicesUrl()}/contacts/${contactId}/portfolios/${portfolioId}/accounts?${queryString}`,
            { isSecure: true },
        );
    }

    static postPortfolioOrder(contactId, portfolioId, options) {
        return Service.postRequest(
            `${getServicesUrl()}/contacts/${contactId}/portfolios/${portfolioId}/order`,
            options,
            { isSecure: true },
        );
    }

    static getPortfolioOrderBook(contactId, portfolioId, params) {
        const queryString = getQueryStringByObject(params);

        return Service.getRequest(
            `${getServicesUrl()}/contacts/${contactId}/portfolios/${portfolioId}/orderbook?${queryString}`,
            { isSecure: true },
        );
    }

    static postPortfolioOrderBook(contactId, portfolioId, options) {
        return Service.postRequest(
            `${getServicesUrl()}/contacts/${contactId}/portfolios/${portfolioId}/orderbook`,
            options,
            { isSecure: true },
        );
    }

    static getPortfolioChanges(contactId, portfolioId, params) {
        const queryString = getQueryStringByObject(params);

        return Service.getRequest(
            `${getServicesUrl(
                APIVersion.V12,
            )}/contacts/${contactId}/portfolios/${portfolioId}/changes?${queryString}`,
            { isSecure: true },
        );
    }

    static getPortfolioProposals(contactId, params) {
        const queryString = getQueryStringByObject(params);

        return Service.getRequest(
            `${getServicesUrl()}/contacts/${contactId}/portfolioproposals?${queryString}`,
            { isSecure: true },
        );
    }

    static postPortfolioProposal(contactId, language, options, params) {
        const queryString = getQueryStringByObject(params);

        return Service.postRequest(
            `${getServicesUrl(
                APIVersion.V12,
            )}/contacts/${contactId}/portfolioproposals?language=${language}${
                queryString && `&${queryString}`
            }`,
            options,
            { isSecure: true },
        );
    }

    static getPortfolioProposal(contactId, portfolioProposalId, params) {
        const queryString = getQueryStringByObject(params);

        return Service.getRequest(
            `${getServicesUrl(
                APIVersion.V11,
            )}/contacts/${contactId}/portfolioproposals/${portfolioProposalId}?${queryString}`,
            { isSecure: true },
        );
    }

    static patchPortfolioProposal(contactId, portfolioProposalId, options) {
        return Service.patchRequest(
            `${getServicesUrl(
                APIVersion.V11,
            )}/contacts/${contactId}/portfolioproposals/${portfolioProposalId}`,
            options,
            { isSecure: true },
        );
    }

    static patchTradingCard(contactId, portfolioId, options) {
        return Service.patchRequest(
            `${getServicesUrl()}/contacts/${contactId}/portfolios/${portfolioId}/tradingcart`,
            options,
            { isSecure: true },
        );
    }

    static postPortfolioOrderbookSimulate(contactId, params, options) {
        const queryString = getQueryStringByObject(params);

        return Service.postRequest(
            `${getServicesUrl(APIVersion.V11)}/contacts/${contactId}/portfolios/orderbook/simulate?${queryString}`,
            options,
            { isSecure: true },
        );
    }

    static postPortfolioRebalance(contactId, portfolioId, options) {
        return Service.postRequest(
            `${getServicesUrl()}/contacts/${contactId}/portfolios/${portfolioId}/rebalance`,
            options,
            { isSecure: true },
        );
    }

    static postTradingCartOrder(contactId, portfolioId) {
        return Service.postRequest(
            `${getServicesUrl()}/contacts/${contactId}/portfolios/${portfolioId}/tradingcart/order`,
            {},
            { isSecure: true },
        );
    }

    static postTradingCartTarget(contactId, portfolioId) {
        return Service.postRequest(
            `${getServicesUrl()}/contacts/${contactId}/portfolios/${portfolioId}/tradingcart/target`,
            {},
            { isSecure: true },
        );
    }

    static deleteTradingCartOrder(contactId, portfolioId) {
        return Service.deleteRequest(
            `${getServicesUrl()}/contacts/${contactId}/portfolios/${portfolioId}/tradingcart`,
            { isSecure: true },
        );
    }

    static sellPortfolio(contactId, portfolioId, options) {
        const queryString = getQueryStringByObject(options);

        return Service.postRequest(
            `${getServicesUrl()}/contacts/${contactId}/portfolios/${portfolioId}/sell?${queryString}`,
            {},
            { isSecure: true },
        );
    }

    static tradingCardSimulate(contactId, portfolioId, params, options) {
        const queryString = getQueryStringByObject(params);

        return Service.postRequest(
            `${getServicesUrl()}/contacts/${contactId}/portfolios/${portfolioId}/tradingcart/order/simulate?${queryString}`,
            options,
            { isSecure: true },
        );
    }

    static getPortfolioPositions(portfolioId, contactId, params) {
        const queryString = getQueryStringByObject(params);

        return Service.getRequest(
            `${getServicesUrl()}/contacts/${contactId}/portfolios/${portfolioId}/overview?${queryString}`,
            { isSecure: true },
        );
    }

    static getTradingCard(portfolioId, contactId, params) {
        const queryString = getQueryStringByObject(params);

        return Service.getRequest(
            `${getServicesUrl()}/contacts/${contactId}/portfolios/${portfolioId}/tradingcart?${queryString}`,
            { isSecure: true },
        );
    }

    static createPortfolio(contactId, params, options) {
        const queryString = getQueryStringByObject(params);

        return Service.postRequest(
            `${getServicesUrl(
                APIVersion.V11,
            )}/contacts/${contactId}/portfolios?${queryString}`,
            options,
            { isSecure: true },
        );
    }

    static getPortfolioSecurityPerformance(
        portfolioId,
        contactId,
        securityId,
        params,
    ) {
        const queryString = getQueryStringByObject(params);

        return Service.getRequest(
            `${getServicesUrl()}/contacts/${contactId}/portfolios/${portfolioId}/securities/${securityId}/performance?${queryString}`,
            { isSecure: true },
        );
    }

    static getPortfolioSecurityMonetaryPerformance(
        portfolioId,
        contactId,
        securityId,
        params,
    ) {
        const queryString = getQueryStringByObject(params);

        return Service.getRequest(
            `${getServicesUrl()}/contacts/${contactId}/portfolios/${portfolioId}/securities/${securityId}/monetary-performance?${queryString}`,
            { isSecure: true },
        );
    }

    static getPortfolioPerformanceAttribution(portfolioId, contactId, options) {
        return Service.postRequest(
            `${getServicesUrl()}/contacts/${contactId}/portfolios/${portfolioId}/performance-attribution-cumulative`,
            options,
            { isSecure: true },
        );
    }

    static getPortfolioAllocation(contactId, params) {
        const queryString = getQueryStringByObject(params);

        return Service.getRequest(
            `${getServicesUrl()}/contacts/${contactId}/allocations?${queryString}`,
            { isSecure: true },
        );
    }

    static getContactsPortfolioAllocation(contactId, portfolioId, params) {
        const queryString = getQueryStringByObject(params);

        return Service.getRequest(
            `${getServicesUrl()}/contacts/${contactId}/portfolios/${portfolioId}/allocations?${queryString}`,
            { isSecure: true },
        );
    }

    static getModelPortfolioAllocation(modelPortfolioId, params) {
        const queryString = getQueryStringByObject(params);

        return Service.getRequest(
            `${getServicesUrl()}/model-portfolios/${modelPortfolioId}/allocations?${queryString}`,
            { isSecure: true },
        );
    }

    static getPortfolioProposalsAllocations(contactId, portfolioProposalId, params) {
        const queryString = getQueryStringByObject(params);

        return Service.getRequest(
            `${getServicesUrl()}/contacts/${contactId}/portfolioproposals/${portfolioProposalId}/allocations?${queryString}`,
            { isSecure: true },
        );
    }
}

export default PortfolioManagement;
