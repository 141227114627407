import {
    CellAdaptedDate,
    CellLink,
    CellPDFViewIcon,
    CellRemoveConnection,
    CellStatus,
    CellUrgency,
    CellWithSubtextAndTooltip,
} from 'components/renderers';
import {
    SORT_DIRECTION_ASC, sorterByDate, sorterNumber, sorterStatusColor, sorterString,
} from 'utils/sorting';
import CellHolders from 'components/renderers/CellHolders/CellHolders';
import { renderers } from '../ui-library';
import { getDateFormat, getDateTimeFormat } from '../utils/datetime';
import { AccessPolicy, ContactHistoryAction } from '../components/Connections/types';
import { dateTimeFormats } from '../locale';

const {
    CellWithSubtext, CellWithIcon, CellWithButtonIcon, CellTooltiped, CellWithCheckboxLinked,
} = renderers;

export const MAX_HANDHELD_VIEWPORT_SIZE = 768;
export const MOBILE_VIEWPORT_MIN_WIDTH = 540;
export const TABLET_VIEWPORT_MIN_WIDTH = 768;
export const MAX_TWO_COLUMN_GRID_WIDTH = 992;

export const CHANGE_STRATEGY = 'change-strategy';
export const CHANGE_MODEL = 'change-model';
export const CREATE_PORTFOLIO = 'create-portfolio';

export const CURRENT_PAGE = 1;
export const PAGE_SIZE = 8;
export const TOTAL_PAGES = 10;

export const PRODUCT_OFFERS_ID = 1;

const getAccessPolicy = (value, t) => {
    switch (value) {
        case AccessPolicy.Owner:
            return t('contactGroupDetails.owner');
        case AccessPolicy.PowerOfAttorney:
            return t('contactGroupDetails.poa');
        case AccessPolicy.BeneficialOwner:
            return t('contactGroupDetails.benificialOwner');
        case AccessPolicy.Representative:
            return t('contactGroupDetails.pa');
        default: return 'N/A';
    }
};

const getContactHistoryAction = (value, t) => {
    switch (value) {
        case ContactHistoryAction.Added:
            return t('contactGroupDetails.proxyAdded');
        case ContactHistoryAction.Deleted:
            return t('contactGroupDetails.proxyDeleted');
        default:
            return 'N/A';
    }
};

export const documentsColumns = (t, getFormattedDate) => [
    {
        key: 'Icon',
        render: CellWithButtonIcon,
        width: 20,
    },
    {
        key: 'Name',
        title: t('advisoryDashboard.documents.name'),
        render: CellTooltiped,
        sortable: true,
        sorter: sorterString('Name.value'),
    },
    {

        key: 'Date',
        title: t('advisoryDashboard.documents.date'),
        width: 90,
        minWidth: 90,
        className: 'text-right table-cell__date',
        render: CellAdaptedDate(t, getFormattedDate),
        sortable: true,
        sorter: sorterByDate('Date'),
    },
];

export const documentsSorting = { key: 'Date', direction: 'desc' };

export const overviewSelectOptions = [
    'Month', 'Year',
];

export const portfoliosColumns = (t, getFormattedNumber) => [
    {
        key: 'Name',
        title: t('portfoliosColumns.name'),
        sortable: true,
        sorter: sorterString('Name.label'),
        render: CellLink,
    },
    {
        key: 'AccountHolder',
        title: t('portfoliosColumns.holder'),
        sorter: sorterString('AccountHolder'),
        render: CellHolders,
        width: 300,
    },
    {
        key: 'Product',
        title: t('portfoliosColumns.product'),
        sorter: sorterString('Product'),
    },
    {
        key: 'Profile',
        title: t('portfoliosColumns.profile'),
        sorter: sorterString('Profile'),
    },
    {
        key: 'Status',
        title: t('portfolios.columns.status'),
        sorter: sorterStatusColor('Status'),
        sortable: true,
        render: CellStatus,
    },
    {
        key: 'ExternalId',
        title: t('portfoliosColumns.externalId'),
        hidden: true,
    },
    {
        key: 'Value',
        className: 'text-right',
        title: t('portfoliosColumns.value'),
        sorter: sorterNumber('Value'),
    },
    // {
    //     key: 'Performance',
    //     title: t('portfoliosColumns.preformanceSI'),
    //     className: 'text-right',
    //     width: 153,
    //     render: CellPercent(getFormattedNumber),
    //     sorter: sorterNumber('Performance'),
    // },
    {
        title: '',
        className: 'text-right table-cell__actions',
        key: 'Actions',
        // render: CellWithMoreActions,
        render: () => {},
        width: 30,
        minWidth: 30,
        titleType: 'columns',
        fixed: 'right',
        headerRenderer: CellWithIcon,
    },
];

export const connectionHistoryColumns = (t, getFormattedDate) => [
    {
        key: 'changeDate',
        title: t('connectionHistory.changeDate'),
        render: item => getFormattedDate(item),
    },
    {
        key: 'changeBy',
        title: t('connectionHistory.initiatedBy'),
    },
    {
        key: 'connectionType',
        title: t('connectionHistory.connectionType'),
    },
    {
        key: 'connectionName',
        title: t('connectionHistory.connection'),
    },
    {
        key: 'action',
        title: t('connectionHistory.action'),
    },
];

export const contactGroupDocumentsColumns = (t, formatDate) => [
    {
        key: 'name',
        title: t('contactGroupDocuments.documentName'),
        sorter: sorterString('name'),
        sortable: true,
    },
    {
        key: 'createDate',
        title: t('contactGroupDocuments.date'),
        sorter: sorterString('createDate'),
        sortable: true,
        render: (value) => formatDate(value, {
            hour: dateTimeFormats.TWO_DIGIT,
            minute: dateTimeFormats.TWO_DIGIT,
        }),
    },
    {
        key: 'nameCol',
        title: '',
        render: CellPDFViewIcon,
    },
];

export const contactGroupHistoryColumns = (t, formatDate) => [
    {
        key: 'changeDate',
        title: t('contactGroupHistory.date'),
        sorter: sorterString('changeDate'),
        render: (value) => formatDate(value, {
            hour: dateTimeFormats.TWO_DIGIT,
            minute: dateTimeFormats.TWO_DIGIT,
        }),
        sortable: true,
    },
    {
        key: 'member',
        title: t('contactGroupHistory.member'),
        sorter: sorterString('member'),
        sortable: true,
    },
    {
        key: 'accessPolicyType',
        title: t('contactGroupHistory.accessPolicy'),
        sorter: sorterString('accessPolicyType'),
        render: (value) => getAccessPolicy(value, t),
        sortable: true,
    },
    {
        key: 'contacts',
        title: t('contactGroupHistory.contacts'),
        sorter: sorterString('contacts'),
        sortable: true,
    },
    {
        key: 'action',
        title: t('contactGroupHistory.action'),
        sorter: sorterString('action'),
        render: (value) => getContactHistoryAction(value, t),
        sortable: true,
    },
];

export const proxyColumns = (t) => [

    {
        key: 'proxyName',
        title: t('contactGroupDetails.proxyName'),
        sorter: sorterString('proxyName'),
        sortable: true,
    },
    {
        key: 'accessPolicy',
        title: t('contactGroupDetails.accessPolicy'),
        sorter: sorterString('accessPolicy'),
        render: (value) => getAccessPolicy(value, t),
        sortable: true,
    },
    {
        key: 'relationshipDescription',
        title: t('contactGroupDetails.relationshipDescription'),
        sortable: false,
    },
    {
        title: '',
        key: 'actionCol',
        render: (props) => CellRemoveConnection(props),
        titleType: 'columns',
        fixed: 'right',
        headerRenderer: CellWithIcon,
    },
];

export const LIQUIDITY_TYPE = 'Liquidity';

export const PIE = 'pie';
export const PIE_SA = 'pieSa';
export const BAR = 'bar';
export const LIST = 'list';
export const LINE = 'line';
export const SUSTAINABILITY = 'sustainability';
export const RISKRETURN = 'riskReturn';
export const BANDWIDTH = 'bandwidth';
export const PROJECTION = 'projection';

export const allocationColumns = (t) => [
    {
        key: 'name',
        title: t('allocationColumns.name'),
        sortable: true,
        sorter: sorterString('name'),
        defaultSortOrder: 'descend',
        minWidth: 140,
    },
    {
        key: 'value',
        title: t('allocationColumns.value'),
        width: 200,
        minWidth: 150,
        sortable: true,
        className: 'text-right',
        sorter: sorterNumber('value'),
    },
    {
        key: 'percent',
        title: t('allocationColumns.allocation'),
        width: 150,
        sortable: true,
        className: 'text-right',
        sorter: sorterNumber('percent'),
    },
];

export const investmentAllocation = (title, t) => [
    {
        key: 'name',
        title,
        width: '65%',
        className: 'allocation-name',
    },
    {
        key: 'allocation',
        title: t('allocationColumns.allocation'),
        sortable: true,
        sorter: sorterNumber('allocation'),
        className: 'text-right',
    },
];

const TASK_URGENCY_ORDER = ['Low', 'Medium', 'High'];

export const tasks = (t, formatDate, full, urgencyFilters, isMiniTasks = true) => [
    {
        key: 'Subject',
        title: t('advisoryDashboard.tasks.tableTitle'),
        sortable: true,
        sorter: isMiniTasks ? sorterString('Subject.title') : sorterString('Subject.subtitle'),
        render: CellWithSubtextAndTooltip,
    },
    {
        key: 'type',
        title: t('advisoryDashboard.tasks.tableType'),
        sortable: true,
        filterable: true,
        sorter: sorterString('type'),
        className: 'text-right',
        width: 150,
    },
    {
        key: 'date',
        title: t('advisoryDashboard.tasks.tableDueDate'),
        sortable: true,
        sorter: sorterByDate('date'),
        className: 'text-right table-cell_date',
        width: full ? 150 : 90,
        render: CellAdaptedDate(t, formatDate),
    },
    {
        key: 'urgency',
        title: t('advisoryDashboard.tasks.tableUrgency'),
        render: CellUrgency,
        filterable: true,
        filters: urgencyFilters,
        onFilter: (value, row) => row.urgency.text === value,
        sortable: true,
        sorter: (data, { direction }) => data.sort((a, b) => {
            if (direction === SORT_DIRECTION_ASC) {
                return TASK_URGENCY_ORDER.indexOf(b?.urgency?.type)
                    - TASK_URGENCY_ORDER.indexOf(a?.urgency?.type);
            }

            return TASK_URGENCY_ORDER.indexOf(a?.urgency?.type)
            - TASK_URGENCY_ORDER.indexOf(b?.urgency?.type);
        }),
        className: 'text-right',
        width: full ? 150 : 70,
    },
];

export const tasksSorting = { key: 'date', direction: 'asc' };

export const projectionOptional = (t, yTitle, yAxisFormat, tooltipFormat) => ({
    yTitle,
    yAxisFormat,
    tooltipFormat,
    topLabel: t('clientDashboard.portfolio.veryStrongStock'),
    bottomLabel: t('clientDashboard.portfolio.veryWeakStock'),
    middleLabel: t('clientDashboard.portfolio.expectedValue'),
    middleTopLabel: t('clientDashboard.portfolio.strongStock'),
    middleBottomLabel: t('clientDashboard.portfolio.weakStock'),
});

export const lineChartLabels = (t) => ({
    months: t('months').split('_'),
    shortMonths: t('shortMonths').split('_'),
});

export const datePickerLocale = (t, locale) => ({
    lang: {
        locale,
        placeholder: 'Select date',
        rangePlaceholder: ['Start date', 'End date'],
        today: t('calendar.today'),
        now: t('calendar.now'),
        backToToday: t('calendar.backToToday'),
        ok: t('calendar.ok'),
        cancel: t('calendar.cancel'),
        clear: t('calendar.clear'),
        month: t('calendar.month'),
        year: t('calendar.year'),
        timeSelect: t('calendar.selectTime'),
        dateSelect: t('calendar.selectDate'),
        monthSelect: t('calendar.chooseMonth'),
        yearSelect: t('calendar.chooseYear'),
        decadeSelect: t('calendar.chooseDecade'),
        yearFormat: 'YYYY',
        dateFormat: getDateFormat(locale),
        dayFormat: 'D',
        dateTimeFormat: getDateTimeFormat(locale),
        monthFormat: 'MMMM',
        monthBeforeYear: true,
        previousMonth: t('calendar.prevMonth'),
        // 'Previous month (PageUp)',
        nextMonth: t('calendar.nextMonth'),
        // 'Next month (PageDown)',
        previousYear: t('calendar.lastYear'),
        // 'Last year (Control + left)',
        nextYear: t('calendar.nextYear'),
        // 'Next year (Control + right)',
        previousDecade: t('calendar.lastDecade'),
        // 'Last decade',
        nextDecade: t('calendar.nextDecade'),
        // 'Next decade',
        previousCentury: t('calendar.lastCentury'),
        // 'Last century',
        nextCentury: t('calendar.nextCentury'),
        // 'Next century',
    },
    timePickerLocale: {
        placeholder: t('calendar.selectTime'),
        // 'Select time',
    },
    dateFormat: getDateFormat(locale),
    dateTimeFormat: getDateTimeFormat(locale),
    weekFormat: 'YYYY-wo',
    monthFormat: 'MM-YYYY',
});

// TODO: check translation when Profile component will be uncommented
//  in src\components\SideNavigation\SideNavigation.jsx
export const profileColumns = (t) => [
    {
        key: 'Information',
        render: CellWithSubtext(t),
        width: 'calc(100% - 10px)',
    },
    {
        key: 'Icon',
        width: 10,
        render: CellWithIcon,
    },
];

// TODO: unused code below, to be deleted after approval
export const getProspectColumns = (t) => ([
    {
        key: 'internalId',
        title: t('clients.columns.internalId'),
        sorter: sorterString('internalId.label'),
        render: CellWithCheckboxLinked,
    },
    {
        key: 'name',
        title: t('clients.columns.name'),
        sortable: true,
        sorter: sorterString('name'),
    },
    {
        key: 'type',
        title: t('clients.columns.type'),
        sorter: sorterString('type'),
    },
    {
        key: 'country',
        title: t('clients.columns.country'),
        filterable: true,
        sorter: sorterString('country'),
    },
    {
        key: 'rm',
        title: t('clients.columns.rm'),
        filterable: true,
        sorter: sorterString('rm'),
    },
]);

export const IBAN = 'IBAN';
export const INV_ALLOC_DATA_KEYS = {
    withLookThroughtData: 'withLookThroughtData',
    withoutLookThroughtData: 'withoutLookThroughtData',
};

export const PRODUCTS = {
    aaa: '3a',
    fz: 'fz',
    fzp: 'fzp',
    bbb: '3b',
};

export const EXTERNAL_PRODUCTS = {
    aaa: '3a',
    fz: 'fz',
    fzp: 'fz premium',
    bbb: '3b',
};

export const EXTERNAL_PRODUCTS_CAPITALIZED = {
    aaa: '3A',
    fz: 'FZ',
    fzp: 'FZ Premium',
    bbb: '3B',
};

export const THREEBTYPES = {
    zivv: 'ZIVV',
    zifd: 'ZIFD',
    zic: 'ZIC',
    azpNeuSB: 'AZPI',
    azpNeu: 'AZPD',
    azpAlt: 'AZP alt',
    pk: 'PK',
};

export const STRATEGIES = {
    interest: 1,
    income: 2,
    balanced: 3,
    growth: 4,
    capital: 5,
    individual: 6,
};
export const KYC = {
    client: 843,
    thirdParty: 844,
    swissNationality: 73,
};

export const ACCESS_POLICY = {
    contactEdit: 'ContactEdit',
    EnableEditKyc: 'EnableEditKyc',
};

export const RISK_INDICATOR_CONFIG = [
    {
        limit: 15,
        value: 0,
        name: 'low',
    },
    {
        limit: 30,
        value: 1,
        name: 'lowToMedium',
    },
    {
        limit: 50,
        value: 2,
        name: 'medium',
    },
    {
        limit: 75,
        value: 3,
        name: 'mediumToHigh',
    },
    {
        limit: 100,
        value: 4,
        name: 'high',
    },
];

export const strategyTypes = {
    STANDARD: 'Standard',
    INDIVIDUAL: 'Individual',
};

export const NUMBER_REGEX = /^[0-9]*$/;
export const NUMBER_AND_LETTERS_REGEX = /^[a-zA-Z0-9]*$/;

export const OPTION_LIST = {
    country: 'country',
    gender: 'gender',
    maritalStatus: 'maritalStatus',
    language: 'language',
    salutation: 'salutation',
    WithdrawalPeriodicity: 'WithdrawalPeriodicity',
    degreeOfEducation: 'degreeOfEducation',
    pepStatus: 'pepStatus',
    inSwitzerlandSince: 'inSwitzerlandSince',
    plannedLengthOfStay: 'plannedLengthOfStay',
    professionalSituation: 'professionalSituation',
    originOfAssets: 'originOfAssets',
    industry2: 'industry2',
    beneficialOwner: 'beneficialOwner',
    preferenceStocks: 'preferenceStocks',
    preferenceExclusion: 'preferenceExclusion',
    exclusionSubstitute: 'substituteExclusion',
    preferenceInclusion: 'preferenceInclusion',
    fatfGreylist: 'fatfGreylist',
};

export const DEFAULT_APP_CURRENCY = 'CHF';
export const MAX_FILE_SIZE = 30; // in MB

export const INITIAL_START_DATE_FOR_CLIENT_REPORT_DATEPICKER = '2023-01-01';

export const languages = {
    de: 0,
    en: 1,
    it: 2,
    fr: 3,
};

export enum PRODUCT_VALUES_FOR_3A_VB_ENUM {
    '3A' = 1,
    VB = 2,
    VB_PREMIUM = 14,
}

export const PRODUCT_VALUES_FOR_3B = [20, 21, 22, 23, 24, 25];
export const PRODUCT_VALUES_FOR_3A_VB = [PRODUCT_VALUES_FOR_3A_VB_ENUM?.['3A'], PRODUCT_VALUES_FOR_3A_VB_ENUM?.VB, PRODUCT_VALUES_FOR_3A_VB_ENUM?.VB_PREMIUM];
export const EMAIL_VALIDATION_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
export const APPLICATION_MAX_FILE_SIZE = 30 * 1024 * 1024;
export const switzLandCountryId = 73;
export const forbiddenNationalities = [220, 155, 121, 70]; // USA, Myanmar, Iran, N. Korea
export const STARTBIRTHDAY = '01.01.1982';
export const separator = {
    disabled: true,
    id: 0,
    label: '---------------',
    listKey: 'country',
};
export const GENDER = {
    male: 301,
    female: 302,
};
export const UPPER_AGE_LIMIT = {
    male: 70,
    female: 69,
};
export const migrationYearOf3b = '2024';
export const allocationColors = {
    bonds: '#06294C',
    stocks: '#0F67BF',
    liquidity: '#093E73',
    realEstate: '#DAD2BD',
    rawMaterial: '#DDE4E3',
    tiedAssets: '#91C8F2',
    other: '#ECEEEF',
};
export const APPLICATION_CONTAINER_ID = 'application_container_id';
export const QUALITY_CHECK_DATA = 'create-portfolio/quality-check/data';

export const CATEGORY_LIST_ITEM_ID_FOR_LIFE_INSURANCE = 1302;
export const INSTITUTE_LIST_ITEM_ID_FOR_LIFE_INSURANCE = 1402;
