import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import withTheme from '../../hocs/withTheme';
import Icon from '../Icon/Icon';
import { getClassNames } from '../../utils';
import { createPagingArray } from './utils';
import './Pagination.css';

const LEFT_PAGE = 'LEFT';
const RIGHT_PAGE = 'RIGHT';

const Pagination = (props) => {
    const {
        currentPage, onChange, total, step, pageSize, className
    } = props;
    const totalPages = useMemo(() => createPagingArray(total, pageSize).length, [total, pageSize]);
    const totalNumbers = useMemo(() => step * 2 + 3, [step]);
    const fetchPageNumbers = () => {
        const range = (from, to, stepNum = 1) => {
            const res = [];
            let i = from;

            while (i <= to) {
                res.push(i);

                i += stepNum;
            }

            return res;
        };
        const totalBlocks = totalNumbers + 2;

        if (totalPages > totalBlocks) {
            let pages = [];

            const leftBound = currentPage - step;
            const rightBound = currentPage + step;
            const beforeLastPage = totalPages - 1;

            const startPage = leftBound > 2 ? leftBound : 2;
            const endPage = rightBound < beforeLastPage ? rightBound : beforeLastPage;

            pages = range(startPage, endPage);

            const pagesCount = pages.length;
            const singleSpillOffset = totalNumbers - pagesCount - 1;

            const leftSpill = startPage > 2;
            const rightSpill = endPage < beforeLastPage;

            if (leftSpill && !rightSpill) {
                const extraPages = range(startPage - singleSpillOffset, startPage - 1);

                pages = [LEFT_PAGE, ...extraPages, ...pages];
            } else if (!leftSpill && rightSpill) {
                const extraPages = range(endPage + 1, endPage + singleSpillOffset);

                pages = [...pages, ...extraPages, RIGHT_PAGE];
            } else if (leftSpill && rightSpill) {
                pages = [LEFT_PAGE, ...pages, RIGHT_PAGE];
            }

            return [1, ...pages, totalPages];
        }

        return range(1, totalPages);
    };
    const pageNumbers = fetchPageNumbers();

    const classNames = useMemo(()=>
        getClassNames(
            'Pagination',
            className,
        )
    , [className]);

    return (
        <ul className={classNames}>
            <li className="pagination-prev">
                <button
                    type="button"
                    onClick={() => onChange(currentPage - 1)}
                    disabled={currentPage === 1}
                >
                    <Icon type="prev" />
                </button>
            </li>
            {pageNumbers.map((page) => {
                if (page === LEFT_PAGE) {
                    return (
                        <li className="pagination-item pagination-others" key={page}>
                            <button
                                type="button"
                                onClick={() => onChange(Math.max(currentPage - totalNumbers, 1))}
                            >
                                ...
                            </button>
                        </li>
                    );
                }

                if (page === RIGHT_PAGE) {
                    return (
                        <li key={page} className="pagination-item pagination-others">
                            <button
                                type="button"
                                onClick={() => onChange(
                                    Math.min(currentPage + totalNumbers, totalPages),
                                )}
                            >
                                ...
                            </button>
                        </li>
                    );
                }
                const className = getClassNames(
                    'pagination-item',
                    { 'pagination-item-active': currentPage === page },
                );

                return (
                    <li className={className} key={`page-${page}`}>
                        <button
                            type="button"
                            onClick={() => onChange(page)}
                        >
                            {page}
                        </button>
                    </li>
                );
            })}
            <li className="pagination-next">
                <button
                    type="button"
                    onClick={() => onChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                >
                    <Icon type="next" />
                </button>
            </li>
        </ul>
    );
};

Pagination.propTypes = {
    total: PropTypes.number,
    currentPage: PropTypes.number,
    pageSize: PropTypes.number,
    step: PropTypes.number,
    onChange: PropTypes.func,
};

Pagination.defaultProps = {
    total: 0,
    currentPage: 1,
    pageSize: 10,
    step: 1,
    onChange: () => { },
};

export default withTheme(Pagination);
