import {useQuery} from '@tanstack/react-query';
import ServerError from '../../errors/ServerError';
import SM from '../../services/ServiceManager';

export const usePostRenderGeneralDocuments = ({
    containerId, isEnabled = true,
}) => {
    const result = useQuery({
        queryKey: ['renderGeneralDocuments', containerId],
        enabled: !!containerId && isEnabled,
        retry: false,
        gcTime: 0, // seconds
        queryFn: async () => {
            const {data} = await SM.customInvestmentService(
                'renderGeneralDocuments',
                [{
                    containerId,
                }],
            );

            return data;
        },
    });

    return {
        ...result,
        error: result.error ? new ServerError(result.error) : result.error,
    };
};
