import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import ServiceManager from 'services/ServiceManager';
import { notification } from 'ui-library';
import { adaptModels, adaptStrategies } from './helpers';

export const useModelPortfolioList = ({ productId, enabled }) => {
    const { t, i18n } = useTranslation();

    const getModelPortfolios = async () => {
        const params = { language: i18n.language };

        try {
            return await ServiceManager.portfolioManagement('getModelPortfolios', [productId, params]);
        } catch (error: any) {
            notification.open({ content: `${t('contactGroups.somethingWentWrong')} ${error.message}`, type: 'error' });

            return null;
        }
    };

    const {
        data, error, isLoading, isFetching, refetch,
    } = useQuery({
        queryKey: ['getModelPortfolios', productId],
        enabled,
        retry: false,
        gcTime: 30,
        queryFn: getModelPortfolios,
    });

    return {
        products: data?.data && adaptModels(data?.data), strategies: data?.data && adaptStrategies(data?.data), isLoading: isLoading || isFetching, error, refetch,
    };
};
