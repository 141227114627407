import PropTypes from 'prop-types';
import React from 'react';
import {renderers} from 'ui-library';

const {
    CellLinked,
    CellTooltiped
} = renderers;

function CellSwitch(props) {
    // eslint-disable-next-line react/destructuring-assignment
    if (props?.label === undefined) {
        return (<CellTooltiped value={props?.value || props} maxLength={props?.maxLength || 12}/>);
    }

    return (
        <span className="table-cell_checkboxed">
            {CellLinked({
                // eslint-disable-next-line react/destructuring-assignment
                value: props.label,
                // eslint-disable-next-line react/destructuring-assignment
                link: props.link,
                // eslint-disable-next-line react/destructuring-assignment
                maxLength: props.maxLength,
            })}
        </span>
    );
}

CellSwitch.propTypes = {
    label: PropTypes.string,
    link: PropTypes.string,
    maxLength: PropTypes.number,
    value: PropTypes.number || PropTypes.string,
};

CellSwitch.defaultProps = {
    label: undefined,
    link: '',
    maxLength: undefined,
    value: '',
};

export default CellSwitch;
