import React from 'react';
import './RiskScale.css';
import PropTypes from 'prop-types';

const RiskScale = ({
    scales,
    activeIndex,
}) => (
    <div className="RiskScale">
        {
            (scales).map(({text}, index) => (
                <div className="RiskScale__Item" key={index}>
                    <div>
                        {(index === 0 || index === 2 || index === 4) && <p className="RiskScale__Item__Text">{text}</p>}
                    </div>
                    <div className={`rect rect-${index}${activeIndex === index ? ' active' : ''}`}/>
                </div>
            ))
        }
    </div>
);

RiskScale.propTypes = {
    scales: PropTypes.arrayOf(PropTypes.shape({text: PropTypes.string.isRequired})),
    activeIndex: PropTypes.number,
};

RiskScale.defaultProps = {
    scales: [
        {text: 'lower'},
        {text: 'low'},
        {text: 'middle'},
        {text: 'higher'},
        {text: 'high'},
    ],
    activeIndex: undefined,
};

export default RiskScale;
