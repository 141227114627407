import React, {
    useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { number } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { downloadDmsDocument } from 'utils/downloadDmsDocument';
import SM from 'services/ServiceManager';
import AdapterError from 'errors/AdapterError';
import Preloader from 'components/Preloader';
import { Checkbox, Loader, Modal } from 'ui-library';
import { useFileExist } from 'hooks/useFileCheck';
import { APPLICATION_MAX_FILE_SIZE } from 'constants/constants';
import { genarateDFSClientId, getClassNames } from '../../utils';
import { DOCUMENT_SIGN_STATUS } from '../../pages/ClientOverview/pages/Dashboard/utils';
import { STATUS } from '../InvestmentApplicationsActionButton/InvestmentApplicationsActionButton';
import './CellAction.css';
import notification from '../../ui-library/components/Notification';
import { getBase64FromFile } from '../../utils/file';

export const CellAction = ({
    id,
    contactId,
    investmentApplicationId,
    getDocuments,
    isSent,
    signStatus,
    applicationStatus,
    hasUnsignedDocuments,
    updateData,
}) => {
    const [eSignInProgress, setESignInProgress] = useState(false);
    const [manualSignInProgress, setManualSignInProgress] = useState(false);
    const [acceptSendDocInProgress, setSendDocInProgress] = useState(false);
    const [downloadInProgress, setDownloadInProgress] = useState(false);
    const [isSentCheckValue, setSentCheckValue] = useState(isSent);
    const [error, setError] = useState(null);
    const inputFileRef = useRef();
    const isSigned = useMemo(() => (signStatus === DOCUMENT_SIGN_STATUS.signed), [signStatus]);
    const isAppCompletedCanceledOrSubmitted = useMemo(() => (applicationStatus === STATUS.completed || applicationStatus === STATUS.canceled || applicationStatus === STATUS.submitted), [applicationStatus]);
    const isDocumentRequireSend = useMemo(() => (typeof isSent === 'boolean'), [isSent]);
    const {
        checkIfFileSizeExceed,
        FileSizeErrorComponent,
    } = useFileExist(APPLICATION_MAX_FILE_SIZE);
    const { t } = useTranslation();

    useEffect(() => {
        if (!hasUnsignedDocuments && STATUS.ready_for_sign === applicationStatus) {
            updateData(investmentApplicationId, { status: STATUS.ready_for_submit });
        }
    }, [hasUnsignedDocuments, applicationStatus]);

    const handleESign = useCallback(async (e) => {
        try {
            setESignInProgress(true);
            const response = await SM.customDocumentsService('getESignUrl', [{
                documentId: id,
                returnUrl: `${window.location.origin}/client/${genarateDFSClientId(contactId)}/dashboard`,
            }]);

            window.open(response.data?.signingUrl, '_self');
        } catch (err) {
            setError(err);
        } finally {
            setESignInProgress(false);
        }
    }, [id, contactId]);

    const handleDocumentUpload = useCallback(async (e) => {
        try {
            setManualSignInProgress(true);
            const file = e.target.files[0];

            if (!file.type.includes('pdf')) {
                notification.open({
                    content: t('validation.wrongFormat'),
                    type: 'error',
                });

                return;
            }
            if (checkIfFileSizeExceed(file.size)) return;
            const fileForUpload = await getBase64FromFile(file);

            await SM.customDocumentsService('manualDocumentSign', [{
                documentId: id,
                file: fileForUpload.split(',')[1],
            }]);
            await getDocuments();
        } catch (err) {
            setError(err);
        } finally {
            setManualSignInProgress(false);
        }
    }, [id, contactId, getDocuments, t]);

    const handleManualSignDocumentClick = useCallback(() => {
        inputFileRef.current.click();
    }, [id, contactId, inputFileRef]);

    const onAcceptDocumentSend = useCallback(async () => {
        setSendDocInProgress(true);
        try {
            await SM.customDocumentsService('postDocumentSentFlag', [{ documentId: id }]);
            setSentCheckValue(true);
        } catch (err) {
            setError(err);
        } finally {
            await getDocuments();
            setSendDocInProgress(false);
        }
    }, [t, getDocuments]);

    const onCancelDocumentSend = useCallback(() => {
        setSentCheckValue(false);
    }, []);

    const tryToAcceptDocSend = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();

        if (isSent || isSentCheckValue) return;

        Modal.confirm({
            title: t('documentSign.modal.documentIsNotSent.title'),
            content: t('documentSign.modal.documentIsNotSent.description'),
            okText: t('documentSign.modal.documentIsNotSent.ok'),
            cancelText: t('documentSign.modal.documentIsNotSent.cancel'),
            onCancel: onCancelDocumentSend,
            onOk: onAcceptDocumentSend,
        });
    }, [onCancelDocumentSend, onAcceptDocumentSend]);

    const downloadDocument = useCallback(async () => {
        try {
            setDownloadInProgress(true);
            const response = await SM.documents('getDocumentById', [id]);

            if (response?.data) {
                const { retrieveFileAsName } = response.data;

                const { newBlob } = downloadDmsDocument(
                    response.data,
                    {
                        name: retrieveFileAsName,
                        saveFile: true,
                    },
                );
                const url = URL.createObjectURL(newBlob);

                global.open(url, '_blank');
            }
        } catch (err) {
            setError(err);
            throw new AdapterError(err);
        } finally {
            setDownloadInProgress(false);
        }
    }, [id]);

    const disableActions = eSignInProgress || manualSignInProgress || downloadInProgress;

    return (
        <Preloader error={error} isLoading={false}>
            <div className="button-group button-group--CellAction">
                <button
                    disabled={disableActions}
                    onClick={handleESign}
                    type="button"
                    className={getClassNames('button', 'TableButton', { 'TableButton--esign': !eSignInProgress }, { invisible: isSigned || isAppCompletedCanceledOrSubmitted })}
                >
                    {eSignInProgress && <Loader/>}
                </button>
                <button
                    disabled={disableActions}
                    onClick={downloadDocument}
                    type="button"
                    className={`button TableButton ${downloadInProgress ? '' : 'TableButton--pdf'}`}
                >
                    {downloadInProgress
                        && <Loader/>}
                </button>
                {
                    isDocumentRequireSend
                        ? (
                            // eslint-disable-next-line
                            <div onClick={tryToAcceptDocSend}>
                                <Checkbox
                                    checked={isSentCheckValue}
                                    label={t('dashboard.agentCockpit.sent')}
                                    disabled={isSent || isSentCheckValue}
                                />
                            </div>
                        )
                        : (
                            <button
                                disabled={disableActions}
                                onClick={handleManualSignDocumentClick}
                                type="button"
                                className={`button TableButton pdf-up ${getClassNames({ 'TableButton--pdf': !manualSignInProgress }, { invisible: isSigned || isAppCompletedCanceledOrSubmitted })}`}
                            >
                                {manualSignInProgress
                                    && <Loader/>}
                            </button>
                        )
                }
                <input
                    ref={inputFileRef}
                    type="file"
                    onChange={handleDocumentUpload}
                    className="hide-manual-sign-input"
                    accept="application/pdf"
                />
            </div>
            <FileSizeErrorComponent/>
        </Preloader>
    );
};

CellAction.propTypes = {
    id: number.isRequired,
};
