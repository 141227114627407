import accounting from 'accounting';

export const formatNumber = (value, precision = 2) => accounting
    .formatMoney(value, '', precision, ',', '.', '%v');

export const formatNumberRounding = (value) => {
    if (Math.abs(value) >= 1000000) {
        return `${formatNumber(value / 1000000, 1)}M`;
    }

    if (Math.abs(value) >= 1000) {
        return `${formatNumber(value / 1000, 1)}K`;
    }

    return formatNumber(value, 1);
};

export const getColorClass = (value, className) => {
    if (value > 0) {
        return `${className} ${className}_positive`;
    }
    if (value < 0) {
        return `${className} ${className}_negative`;
    }

    return `${className}`;
};

export const DOCUMENT_SIGN_STATUS = {
    outOfScope: 1,
    notSigned: 2,
    esignInProgress: 3,
    signed: 4,
};

export const DOCUMENT_SIGN_STATUS_TEXT = {
    [DOCUMENT_SIGN_STATUS.outOfScope]: 'dashboard.overviewClientApplications.documentStatus.outOfScope',
    [DOCUMENT_SIGN_STATUS.notSigned]: 'dashboard.overviewClientApplications.documentStatus.notSigned',
    [DOCUMENT_SIGN_STATUS.esignInProgress]: 'dashboard.overviewClientApplications.documentStatus.eSignInProgress',
    [DOCUMENT_SIGN_STATUS.signed]: 'dashboard.overviewClientApplications.documentStatus.signed',
};
