import { useLayoutEffect, useMemo, useState } from 'react';

const calculateSizes = (elements) => {
    const root = elements[0] && elements[0].current.parentElement;
    const rootBounds = root.getBoundingClientRect();

    return elements.map(({ current }) => {
        if (!current) return {};
        const bounds = current ? current.getBoundingClientRect() : {};
        const left = bounds.left - rootBounds.left + root.scrollLeft;

        return {
            width: bounds.width,
            left,
        };
    });
};

export const useUnderlineSlider = (refs, activeIdx) => {
    const [sizes, setSizes] = useState([]);
    const styles = useMemo(() => {
        const size = sizes[activeIdx] || { width: 0, left: 0 };

        return {
            width: size.width,
            transform: `translate3d(${size.left}px, 0px, 0px)`,
        };
    }, [sizes, activeIdx]);
    const changeSizes = () => {
        setSizes(calculateSizes(refs));
    };

    useLayoutEffect(() => {
        changeSizes();
    }, [activeIdx]);

    useLayoutEffect(() => {
        window.addEventListener('scroll', changeSizes);

        return () => window.removeEventListener('scroll', changeSizes);
    }, []);

    return { styles, changeSizes };
};
