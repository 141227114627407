import { getServicesUrl } from '../../setup';
import Service from './service';
import { APIVersion } from '../../types/main';

class Compliance extends Service {
    static filterQuestionnaires(data) {
        return Service.postRequest(
            `${getServicesUrl(APIVersion.V20)}/questionnaires`,
            data,
            { isSecure: true }
        );
    }

    /**
     * Get Questionnaire by ContactId and ProductId
     * Warning: This version is deprecated - Please use the v2.0 version
     * @param {contactId} number.
     * @param {productId} number.
     * @param {language} string.
     */
    static getQuestionnaireByContactAndProduct(contactId, productId, language = 'en') {
        return Service.postRequest(
            `${getServicesUrl()}/contacts/${contactId}/questionnaires?productid=${productId}&empty=true&iscomplete=true&language=${language}`,
            { isSecure: true }
        );
    }

    static searchQuestionnairesByContact(contactId, data) {
        return Service.postRequest(
            `${getServicesUrl(APIVersion.V11)}/contacts/${contactId}/questionnaires/search`,
            data,
            { isSecure: true }
        );
    }

    static postAnswers(contactId, data) {
        return Service.postRequest(
            `${getServicesUrl()}/contacts/${contactId}/questionnaires`,
            data,
            { isSecure: true }
        );
    }

    static calculateRisk(contactId, productId, acceptRistk, groupkey = '', completequestionnaire = true, language = 'en') {
        return Service.getRequest(
            `${getServicesUrl()}/contacts/${contactId}/calculaterisk?productid=${productId}&groupkey=${groupkey}&completequestionnaire=${completequestionnaire}&riskaccepted=${acceptRistk}&language=${language}`,
            { isSecure: true }
        );
    }

    static acceptRisk(contactId) {
        return Service.postRequest(
            `${getServicesUrl()}/contacts/${contactId}/acceptrisk`,
            {},
            { isSecure: true }
        );
    }

    static setChosenRisk(contactId, chosenRiskId) {
        return Service.postRequest(
            `${getServicesUrl()}/contacts/${contactId}/setchosenrisk/${chosenRiskId}`, {}, { isSecure: true }
        );
    }

    static chooseRisk(contactId) {
        return Service.getRequest(
            `${getServicesUrl()}/contacts/${contactId}/setchosenrisk`,
            { isSecure: true }
        );
    }

    static getAllActiveQuestionnaires(data) {
        return Service.postRequest(
            `${getServicesUrl(APIVersion.V20)}/questionnaires`,
            data,
            { isSecure: true }
        );
    }

    static getClientRiskCategory(contactId, language) {
        return Service.getRequest(
            `${getServicesUrl()}/contacts/${contactId}/riskcategories?language=${language}`,
            { isSecure: true }
        );
    }
}

export default Compliance;
