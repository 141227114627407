import React from 'react';
import {User} from 'oidc-client';
import {AuthenticationContext} from './authentication/context/AuthenticationContext'

const isRequireAuthentication = (user: User | null): boolean => (
    !user || (user && user.expired)
);

const useAuthenticationContext = () => {
    const context = React.useContext(AuthenticationContext);

    if (!context) {
        throw new Error('useReactOidc must be used within a AuthenticationContext');
    }

    return context
}

export const useUserManager = () => {
    const context = useAuthenticationContext();


    return context.userManager;
};


export const useSecure = () => {
    const {
        login, logout, oidcUser, events, isLoggingOut, isLoading, signinSilent
    } = useAuthenticationContext();


    return {
        userId: oidcUser?.profile?.mid as string,
        token: `${oidcUser?.token_type} ${oidcUser?.access_token}`,
        login,
        logout,
        signinSilent,
        oidcUser,
        events,
        isLoggingOut,
        isLoading,
    };
};

export const useSecureUser = () => {
    const context = useAuthenticationContext();

    if (!context) {
        throw new Error('useSecureUser must be used within a AuthenticationContext');
    }

    return context.oidcUser;
};


interface SecureChildren {
    children: React.ReactChild | React.ReactChild[] | null;
}

export const Secure = ({children}: SecureChildren) => {
    const userManager = useUserManager();
    const {logout} = useSecure();

    const checkUser = async () => {
        const oidcUser = await userManager.getUser();

        if (isRequireAuthentication(oidcUser)) {
            logout();
        }
    };

    React.useEffect(() => {
        checkUser();
    }, []);

    return <>{children}</>;
};

export const withSecure = <P extends object>(Component: React.ComponentType<P>) => (props: P) => {
    const secure = useSecure();

    return (
        <Secure>
            <Component {...secure} {...props} />
        </Secure>
    )
}
