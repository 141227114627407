import Service from 'additiv-services/services/core/service';
import { getServicesUrl } from 'additiv-services/setup';
import { getQueryStringByObject } from 'additiv-services/utils/queryUtils';

class Member extends Service {
    static getMembersProfile(memberId, params) {
        const queryString = getQueryStringByObject(params);

        return Service.getRequest(
            `${getServicesUrl()}/members/${memberId}/profile?${queryString}`,
            { isSecure: true },
        );
    }
}

export default Member;
