export const navigate = {
    PREVIOUS: 'PREV',
    NEXT: 'NEXT',
    TODAY: 'TODAY',
    DATE: 'DATE',
};

export const COLORS = [
    'primary', // blue
    'purple',
    'orange',
    'green',
    'turquoise',
    'yellow',
];
