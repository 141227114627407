import PropTypes from 'prop-types';

export const datePickerLocalePropTypes = PropTypes.shape({
    lang: PropTypes.shape({
        locale: PropTypes.string,
        placeholder: PropTypes.string,
        rangePlaceholder: PropTypes.arrayOf(PropTypes.string),
        today: PropTypes.string,
        now: PropTypes.string,
        backToToday: PropTypes.string,
        ok: PropTypes.string,
        clear: PropTypes.string,
        month: PropTypes.string,
        year: PropTypes.string,
        timeSelect: PropTypes.string,
        dateSelect: PropTypes.string,
        monthSelect: PropTypes.string,
        yearSelect: PropTypes.string,
        decadeSelect: PropTypes.string,
        yearFormat: PropTypes.string,
        dateFormat: PropTypes.string,
        dayFormat: PropTypes.string,
        dateTimeFormat: PropTypes.string,
        monthFormat: PropTypes.string,
        monthBeforeYear: PropTypes.bool,
        previousMonth: PropTypes.string,
        nextMonth: PropTypes.string,
        previousYear: PropTypes.string,
        nextYear: PropTypes.string,
        previousDecade: PropTypes.string,
        nextDecade: PropTypes.string,
        previousCentury: PropTypes.string,
        nextCentury: PropTypes.string,
    }),
    timePickerLocale: PropTypes.shape({
        placeholder: PropTypes.string,
    }),
    dateFormat: PropTypes.string,
    dateTimeFormat: PropTypes.string,
    weekFormat: PropTypes.string,
    monthFormat: PropTypes.string,
});
