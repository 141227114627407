import { isThreeBProduct, useThreeBProductType } from 'hooks/isThreeBProduct';
import { isNil } from 'lodash/fp';
import {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import ReactDOMServer from 'react-dom/server';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { formatDate } from 'utils/datetime';
import {
    findMinAndMax,
    newAdaptLinearChartData,
    newAdaptProjectionData,
} from '../../../../../../../adaptors/adaptProjection';
import {
    useQuestionnaire as useInvestorProfileQuestionnaire,
} from '../../../../../../../components/InvestorProfileBase/hooks/useQuestionnaire';
import ProjectionTooltip from '../../../../../../../components/ProjectionTooltip';
import { PRODUCTS } from '../../../../../../../constants/constants';
import { projectionOptional } from '../../../../../../../constants/goalCreation';
import { useContact } from '../../../../../../../domain/ClientService';
import { useProductsList } from '../../../../../../../hooks/useProducts';
import { useFormatting } from '../../../../../../../locale';
import ServiceManager from '../../../../../../../services/ServiceManager';
import { genarateDFSClientId } from '../../../../../../../utils';
import { formatNumberRounding } from '../../../../../../../utils/formatting';
import { SELECETED_PRODUCT_TYPE, SELECTED_INVESTMENT_APP } from '../../../../../constants';
import { adaptModels, adaptStrategies } from '../../../adapters/adaptModelPortfolio';
import useCommonAllocationDataProvider from '../../../context/useCommonAllocationDataProvider.';
import { useProductAllocation } from '../../../hooks/useProductAllocation';
import { FEE_TYPES } from '../constants';
import getInvestmentProjection from '../pages/GoalAndPortfolioSetup/services/getInvestmentProjection';

import { PAYMENT_PERIODS } from '../utils';

const STEP_NAME = {
    productOffer: 1,
    personalDetails: 2,
    investorProfile: 3,
    strategy: 4,
    productConfiguration: 5,
    agentInformation: 6,
    overview: 7,
    qualityCheck: 8,
    finalization: 9,
    fees: 10,
    KYC_FORM: 11,
    KYC_DOC_UPLOAD: 12,
    withdrawal: 13,
};

const pkStepDef = {
    [STEP_NAME.productOffer]: {
        condition: [
        ],
    },
    [STEP_NAME.personalDetails]: {
        condition: [
            STEP_NAME.productOffer,
        ],
    },
    [STEP_NAME.investorProfile]: {
        condition: [
            STEP_NAME.productOffer,
            STEP_NAME.personalDetails,
        ],
    },
    [STEP_NAME.strategy]: {
        condition: [
            STEP_NAME.productOffer,
            STEP_NAME.personalDetails,
            STEP_NAME.investorProfile,
        ],
    },
    [STEP_NAME.fees]: {
        condition: [
            STEP_NAME.productOffer,
            STEP_NAME.personalDetails,
            STEP_NAME.investorProfile,
            STEP_NAME.strategy,
        ],
    },
    [STEP_NAME.overview]: {
        condition: [
            STEP_NAME.productOffer,
            STEP_NAME.personalDetails,
            STEP_NAME.investorProfile,
            STEP_NAME.strategy,
            STEP_NAME.fees,
        ],
    },
    [STEP_NAME.qualityCheck]: {
        condition: [
            STEP_NAME.productOffer,
            STEP_NAME.personalDetails,
            STEP_NAME.investorProfile,
            STEP_NAME.strategy,
            STEP_NAME.fees,
        ],
    },
    [STEP_NAME.finalization]: {
        condition: [
            STEP_NAME.productOffer,
            STEP_NAME.personalDetails,
            STEP_NAME.investorProfile,
            STEP_NAME.strategy,
            STEP_NAME.fees,
            STEP_NAME.qualityCheck,
        ],
    },
};

const generalStepStateDef = {
    [STEP_NAME.productOffer]: {
        condition: [
        ],
    },
    [STEP_NAME.personalDetails]: {
        condition: [
            STEP_NAME.productOffer,
        ],
    },
    [STEP_NAME.investorProfile]: {
        condition: [
            STEP_NAME.productOffer,
            STEP_NAME.personalDetails,
        ],
    },
    [STEP_NAME.strategy]: {
        condition: [
            STEP_NAME.productOffer,
            STEP_NAME.personalDetails,
            STEP_NAME.investorProfile,
        ],
    },
    [STEP_NAME.productConfiguration]: {
        condition: [
            STEP_NAME.productOffer,
            STEP_NAME.personalDetails,
            STEP_NAME.investorProfile,
            STEP_NAME.strategy,
        ],
    },
    [STEP_NAME.agentInformation]: {
        condition: [
            STEP_NAME.productOffer,
            STEP_NAME.personalDetails,
            STEP_NAME.investorProfile,
            STEP_NAME.strategy,
            STEP_NAME.productConfiguration,
        ],
    },
    [STEP_NAME.overview]: {
        condition: [
            STEP_NAME.productOffer,
            STEP_NAME.personalDetails,
            STEP_NAME.investorProfile,
            STEP_NAME.strategy,
            STEP_NAME.productConfiguration,
            STEP_NAME.agentInformation,
        ],
    },
    [STEP_NAME.qualityCheck]: {
        condition: [
            STEP_NAME.productOffer,
            STEP_NAME.personalDetails,
            STEP_NAME.investorProfile,
            STEP_NAME.strategy,
            STEP_NAME.productConfiguration,
            STEP_NAME.agentInformation,
        ],
    },
    [STEP_NAME.finalization]: {
        condition: [
            STEP_NAME.productOffer,
            STEP_NAME.personalDetails,
            STEP_NAME.investorProfile,
            STEP_NAME.strategy,
            STEP_NAME.productConfiguration,
            STEP_NAME.agentInformation,
        ],
    },
    [STEP_NAME.withdrawal]: {
        condition: [
            STEP_NAME.productOffer,
            STEP_NAME.personalDetails,
            STEP_NAME.investorProfile,
            STEP_NAME.strategy,
        ],
    },
    [STEP_NAME.fees]: {
        condition: [
            STEP_NAME.productOffer,
            STEP_NAME.personalDetails,
            STEP_NAME.investorProfile,
            STEP_NAME.strategy,
            STEP_NAME.productConfiguration,
            STEP_NAME.withdrawal,
        ],
    },
    [STEP_NAME.KYC_FORM]: {
        condition: [
            STEP_NAME.productOffer,
            STEP_NAME.personalDetails,
            STEP_NAME.investorProfile,
            STEP_NAME.strategy,
            STEP_NAME.productConfiguration,
            STEP_NAME.withdrawal,
            STEP_NAME.fees,
        ],
    },
    [STEP_NAME.KYC_DOC_UPLOAD]: {
        condition: [
            STEP_NAME.productOffer,
            STEP_NAME.personalDetails,
            STEP_NAME.investorProfile,
            STEP_NAME.strategy,
            STEP_NAME.productConfiguration,
            STEP_NAME.withdrawal,
            STEP_NAME.fees,
            STEP_NAME.KYC_DOC_UPLOAD,
        ],
    },
};

export const useResume = ({
    clientId,
    setNewPortfolio,
    setLayoutData,
    saveDataToLocalStorage,
    newPortfolio,
    investmentApplicationId,
}) => {
    const [steps, setSteps] = useState();
    const [isResumeBlocked, setResumeBlocked] = useState(true);
    const [resumeStep, setResumeStep] = useState();
    const [isResumeInProgress, setResumeInProgress] = useState(false);
    const { i18n: { language }, t } = useTranslation();
    const { getContact } = useContact({ contactId: clientId });
    const { getFormattedNumber, getFormattedCurrency } = useFormatting();
    const dfsClientId = useMemo(() => genarateDFSClientId(clientId), [clientId]);
    const { getQuestionnaire: getInvestorProfileQuestionnaire } = useInvestorProfileQuestionnaire({ clientId });
    const [selectedStrategy, setSelectedStrategy] = useState(null);
    const {
        products,
        getProducts,
    } = useProductsList();
    const { setSelectedProductType } = useCommonAllocationDataProvider();

    const {
        isAzp, isPk, isZiv,
    } = useThreeBProductType(newPortfolio);
    const isThreeB = isThreeBProduct(newPortfolio);
    const { riskScore } = useProductAllocation({ productId: newPortfolio?.applicationData?.investmentDescription?.selectStrategy?.modelPortfolioId });
    const { setSetupWithdrawalPlan } = useCommonAllocationDataProvider();
    const navigate = useNavigate();

    const init = useCallback(async () => {
        const { data: planAppData } = await ServiceManager.customInvestmentService('getInvestmentApplication', [{ investmentApplicationId }]);

        const {
            selectStrategy,
            isJointAccount,
            contactGroupId,
            productId,
        } = planAppData?.investmentDescription;

        setSelectedStrategy(selectStrategy);
        const productList = await getProducts();
        const selectedProduct = productList?.find(({ id }) => (productId === id));
        const resumeObject = {
            ...newPortfolio,
            applicationData: {
                ...newPortfolio.applicationData,
                ...planAppData,
            },
            isJointAccount,
            connectionGroupId: contactGroupId,
            product: selectedProduct,
        };

        setSelectedProductType(resumeObject?.product?.settings?.productKey?.toLowerCase());
        global.sessionStorage.setItem(SELECTED_INVESTMENT_APP, resumeObject.applicationData.investmentApplicationId);
        global.sessionStorage.setItem(SELECETED_PRODUCT_TYPE, selectedProduct?.externalId || '');

        return resumeObject;
    }, [investmentApplicationId]);

    // define resume step
    const defineStep = useCallback(async () => {
        const {
            selectStrategy,
            agentInformation,
            productConfiguration,
            finalizeQuestion,
            withdrawal,
            paymentInstruction,
            fees,
            kycDone,
            idDocUploaded,
            isJointAccount,
            contactGroupId,
        } = newPortfolio?.applicationData?.investmentDescription;
        const resumeObject = newPortfolio;

        const contactDataForm = await getContact({ contactId: clientId });
        const questionnaireInvestorProfileData = await getInvestorProfileQuestionnaire();

        let response;
        let jaQuestionnaireInvestorProfileData;
        let isJAQuestionnaireCompletedAndNotExpired;
        const users = [];

        if (isJointAccount) {
            try {
                response = await ServiceManager.customClientService('getContactGroupDetails', [{ groupId: contactGroupId }]);
            } catch (e) {
                console.log('Issue with getting data from API: contactgroupdetails');
            }

            Object.entries(response?.data?.owners || {}).sort((a, b) => +a - +b).forEach(([key, value]) => {
                users.push({
                    id: key,
                    name: value,
                    dfsClientId: genarateDFSClientId(key),
                    isValid: true, // by default is valid, but after validation is done, it will be set to false if there are errors
                });
            });

            jaQuestionnaireInvestorProfileData = await getInvestorProfileQuestionnaire({ mClientId: users?.[0].id });
        }
        const { data: questionnaireQualityCheckData } = await ServiceManager.customInvestmentService('getQuestionnaireAnswers', [{ id: investmentApplicationId }]);

        const selectedProductSettings = products?.find(({ id }) => (newPortfolio?.applicationData?.investmentDescription?.productId === id))?.settings;
        const personalInformation = contactDataForm?.details?.personalInformation;
        const appPersonalInformation = newPortfolio?.applicationData?.investmentDescription?.personalDetails;

        if (isThreeB) {
            setSetupWithdrawalPlan(selectStrategy?.withdrawalOption || isAzp);
        }

        if (
            isNil(appPersonalInformation)
            || isNil(personalInformation)
            || (isNil(appPersonalInformation?.affiliatedToPensionFund) && selectedProductSettings?.isDependantOnAffiliatedWithPensionFund)
            || isNil(appPersonalInformation?.destinataryVita)
            || isNil(appPersonalInformation?.memberBevSwitzerland)
            || isNil(appPersonalInformation?.occupation)
            || isNil(appPersonalInformation?.zurichEmployee)
            || isNil(personalInformation?.affiliatedPensionFund)
            || isNil(personalInformation?.occupation)
            || isNil(personalInformation?.member)
            || isNil(personalInformation?.destinedLife)
            || isNil(personalInformation?.zurichMA)
        ) {
            return { resumeStep: STEP_NAME.personalDetails, resumeObject };
        }

        const isQuestionnaireCompletedAndNotExpired = questionnaireInvestorProfileData?.IsComplete
            && ((new Date(questionnaireInvestorProfileData?.ExpiryDate)) - (new Date()) > 0);

        if (isJointAccount) {
            isJAQuestionnaireCompletedAndNotExpired = jaQuestionnaireInvestorProfileData?.IsComplete
            && ((new Date(jaQuestionnaireInvestorProfileData?.ExpiryDate)) - (new Date()) > 0);
        }

        const strategyCheck = isNil(selectStrategy)
            || isNil(selectStrategy.investmentHorizon)
            || isNil(selectStrategy.strategyId)
            || isNil(selectStrategy.modelPortfolioId);

        if (
            !isQuestionnaireCompletedAndNotExpired
            || (isJointAccount && !isJAQuestionnaireCompletedAndNotExpired)
            || (isPk && strategyCheck)
        ) {
            return { resumeStep: STEP_NAME.investorProfile, resumeObject };
        }
        if (
            !isPk && strategyCheck
        ) {
            return { resumeStep: STEP_NAME.strategy, resumeObject };
        }
        if (
            !isPk
            && isThreeB && (selectStrategy?.withdrawalOption || isAzp)
            && (
                isNil(withdrawal) || isNil(isAzp ? withdrawal.investmentAmount : withdrawal.withdrawalAmount) || isNil(withdrawal.periodicity) || isNil(withdrawal.startDate)
                || isNil(paymentInstruction) || isNil(paymentInstruction.bankName) || isNil(paymentInstruction.city) || isNil(paymentInstruction.firstName) || isNil(paymentInstruction.lastName) || isNil(paymentInstruction.iban) || isNil(paymentInstruction.zipCity)
            )
        ) {
            return { resumeStep: STEP_NAME.withdrawal, resumeObject };
        }
        if (
            !isPk
            && (
                isNil(productConfiguration)
             || (!isThreeB && (selectedProductSettings.showYearlyPayments
                 ? (isNil(productConfiguration.yearlyPayments) || productConfiguration.yearlyPayments === 0)
                 : (isNil(productConfiguration.initialInvestment) || productConfiguration.initialInvestment === 0)))
             || (isThreeB && (isNil(isAzp ? productConfiguration.initialInvestment : productConfiguration.periodicalPaymentAmount)))
            )
        ) {
            return { resumeStep: STEP_NAME.productConfiguration, resumeObject };
        }
        if (!isThreeB && (isNil(agentInformation) || isNil(agentInformation.issueCommission) || isNil(agentInformation.pbNumber))) {
            return { resumeStep: STEP_NAME.agentInformation, resumeObject };
        }
        if (isThreeB && (isNil(agentInformation) || isNil(agentInformation.issueCommission) || isNil(agentInformation.pbNumber) || (isThreeB && !isPk && !fees?.some?.(s => s.feeType === FEE_TYPES.custody)))) {
            return { resumeStep: STEP_NAME.fees, resumeObject };
        }
        if (isThreeB && !isPk && !kycDone) {
            return { resumeStep: STEP_NAME.KYC_FORM, resumeObject };
        }
        if (isThreeB && !isPk && !idDocUploaded) {
            return { resumeStep: STEP_NAME.KYC_DOC_UPLOAD, resumeObject };
        }
        if (!isThreeB && !questionnaireQualityCheckData) {
            return { resumeStep: STEP_NAME.qualityCheck, resumeObject };
        }
        if (isNil(finalizeQuestion) || isNil(finalizeQuestion.documentLanguage) || isNil(finalizeQuestion.isConsultationConducted) || isNil(finalizeQuestion.transactionByExternalReferrer)) {
            return { resumeStep: STEP_NAME.finalization, resumeObject };
        }

        if (isNil(finalizeQuestion) || isNil(finalizeQuestion.documentLanguage) || isNil(finalizeQuestion.isConsultationConducted) || isNil(finalizeQuestion.transactionByExternalReferrer)) {
            return { resumeStep: STEP_NAME.finalization, resumeObject };
        }

        return { resumeStep: STEP_NAME.finalization };
    }, [investmentApplicationId, newPortfolio]);

    // step 1
    const initProductSeletionData = useCallback(async ({ resumeObject }) => {
        const contactDataForm = await getContact({ contactId: clientId });

        const userClientAge = (Number(formatDate(new Date(), 'YYYY')) - Number(formatDate(new Date(contactDataForm?.details?.personalInformation?.dateOfBirth), 'YYYY')));
        const userClientName = (`${contactDataForm?.details?.personalInformation?.firstName} ${contactDataForm?.details?.personalInformation?.lastName}`);

        setLayoutData({
            clientName: userClientName,
            clientAge: userClientAge,
        });

        const newResumeObject = {
            ...resumeObject,
            userClientName,
            userClientAge,
        };

        return newResumeObject;
    }, [clientId]);

    // step 2
    const initPesonalDetailsData = useCallback(async () => {

    }, []);

    useEffect(() => {}, [riskScore]);

    // step 3
    const initInvestorProfileData = useCallback(async ({ resumeObject }) => {
        try {
            const productId = products.find((product) => (product?.settings?.productKey === PRODUCTS.aaa))?.id;

            const { data } = await ServiceManager.compliance('calculateRisk', [
                clientId, productId,
                false, '', true, language,
            ]);

            const newResumeObject = {
                ...resumeObject,
                recommendedStrategy: data,
            };

            return newResumeObject;
        } catch (e) {
            console.log('error on resume(step 3)', e);
        }

        return resumeObject;
    }, [newPortfolio, language, products]);

    // step 4
    const initStrategySelectionData = useCallback(async ({ resumeObject }) => {
        const params = { language };
        const productId = resumeObject?.applicationData?.investmentDescription?.productId;
        const modelPortfolioId = resumeObject?.applicationData?.investmentDescription?.selectStrategy?.modelPortfolioId;
        const strategyId = resumeObject?.applicationData?.investmentDescription?.selectStrategy?.strategyId;

        const response = await ServiceManager.portfolioManagement('getModelPortfolios', [productId, params]);
        const strategies = adaptStrategies(response.data);
        const strategy = strategies.find(item => item.Id === strategyId);

        const models = adaptModels(response.data);

        const modelPortfolio = models.find(({ Id }) => (Id === modelPortfolioId));

        const newResumeObject = {
            ...resumeObject,
            strategy,
            riskScore,
            modelPortfolio,
        };

        return newResumeObject;
    }, [newPortfolio, language, riskScore]);

    // step 5
    const initGoalAndPortfolioSetupData = useCallback(async ({ resumeObject }) => {
        let oneTimeInvestment;
        let getYearlyContribution;

        if (isThreeB) {
            const productSettings = (newPortfolio?.product?.settings);
            const getPaymentPeriodValue = (id) => (Object.values(PAYMENT_PERIODS).find(f => f.id === parseInt(id, 10))?.value || 0);
            const periodicPayment = (newPortfolio?.applicationData?.investmentDescription?.productConfiguration?.periodicalPaymentAmount || productSettings.defaultPeriodicPayment);
            const paymentPeriod = (newPortfolio?.applicationData?.investmentDescription?.productConfiguration?.periodicity || productSettings.defaultPaymentFrequency);

            oneTimeInvestment = newPortfolio?.applicationData?.investmentDescription?.productConfiguration?.initialInvestment || productSettings.defaultOneTimeInvestment;
            getYearlyContribution = () => (periodicPayment * getPaymentPeriodValue(paymentPeriod));
        }
        const modelPortfolioId = resumeObject?.applicationData?.investmentDescription?.selectStrategy?.modelPortfolioId;
        const { productConfiguration } = resumeObject?.applicationData.investmentDescription;
        const projectionYears = resumeObject?.userClientAge ? (65 - resumeObject?.userClientAge) : null;
        const response = await ServiceManager.portfolioManagement(
            'getModelPortfolio',
            [modelPortfolioId, { language }],
        );

        const modelPortfolio = response.data;
        const currency = modelPortfolio.BaseCurrency;

        const performance = {
            language,
            InstrumentAllocations: modelPortfolio.Positions.map(
                ({ Allocation, Security: { Id } }) => ({
                    Allocation,
                    InstrumentId: Id,
                }),
            ),
            InitialInvestmentAmount: isThreeB ? oneTimeInvestment : parseInt(productConfiguration.initialInvestment, 10),
            YearlyContributionAmount: isThreeB ? getYearlyContribution() : parseInt(productConfiguration.yearlyPayments, 10),
            CurrencyId: modelPortfolio.BaseCurrency.Id,
            PortfolioId: modelPortfolioId,
            projectionYears: projectionYears < 1 ? 0 : projectionYears,
        };

        if (selectedStrategy?.individualPortfolio) {
            performance.individualPortfolio = selectedStrategy?.individualPortfolio;
        }

        const { data: chartGraphData } = await getInvestmentProjection(performance);

        let chart;

        if (isAzp) {
            chart = newAdaptLinearChartData([
                {
                    Values: [...chartGraphData[0]?.values], ProbabilityPercentage: chartGraphData[0]?.stdDevFactor, SeriesType: 'InstrumentSet', Name: 'Lowest',
                },
                {
                    Values: [...chartGraphData[1]?.values], ProbabilityPercentage: chartGraphData[1]?.stdDevFactor, SeriesType: 'InstrumentSet', Name: 'Neutral',
                },
                {
                    Values: [...chartGraphData[2]?.values], ProbabilityPercentage: chartGraphData[2]?.stdDevFactor, SeriesType: 'InstrumentSet', Name: 'Highest',
                },
            ]);
        } else {
            const AdditionalValueArray1 = chartGraphData[1]?.values.map((k, i) => ({
                ...k, AdditionalValue: chartGraphData[0]?.values[i]?.value, value: k?.value, date: k?.date,
            }));
            const AdditionalValueArray2 = chartGraphData[2]?.values.map((k, i) => ({
                ...k, AdditionalValue: chartGraphData[1]?.values[i]?.value, value: k?.value, date: k?.date,
            }));

            chart = newAdaptProjectionData([
                {
                    Values: [...AdditionalValueArray1],
                    ProbabilityPercentage: chartGraphData[1]?.stdDevFactor,
                    SeriesType: 'InstrumentSet',
                },
                {
                    Values: [...AdditionalValueArray1],
                    ProbabilityPercentage: chartGraphData[1]?.stdDevFactor,
                    SeriesType: 'InstrumentSet',
                },
                {
                    Values: [...AdditionalValueArray2],
                    ProbabilityPercentage: chartGraphData[2]?.stdDevFactor,
                    SeriesType: 'InstrumentSet',
                },
            ]);
        }

        const yAxisFormat = ((num) => formatNumberRounding(num, getFormattedNumber));
        const tooltipFormat = ({ point, series }) => ReactDOMServer
            .renderToString(ProjectionTooltip({
                point, series, currency, t, getFormattedCurrency,
            }));
        const optional = {
            ...projectionOptional(t, currency, yAxisFormat, tooltipFormat),
            ...(findMinAndMax([chart])),
        };

        const newResumeObject = {
            ...resumeObject,
            riskScore,
            isFullTransfer: productConfiguration.isFullTransfer,
            goals: {
                InstrumentAllocations: modelPortfolio.Positions.map(
                    ({ Allocation, Security: { Id } }) => ({
                        Allocation,
                        InstrumentId: Id,
                    }),
                ),
                InitialInvestmentAmount: productConfiguration.initialInvestment,
                YearlyContributionAmount: productConfiguration.yearlyPayments,
                CurrencyId: currency.Id,
                PortfolioId: modelPortfolioId,
                projectionYears: projectionYears < 1 ? 0 : projectionYears,
            },
            prevPensionScheme: {
                nameOfPensionScheme: productConfiguration.pensionScheme,
                addressSupplement: productConfiguration.addressSuffix,
                streetAndNumber: productConfiguration.street,
                postcodeAndCity: productConfiguration.zip,
                policeNumber: productConfiguration.policyNo,
                bankAccountNumber: productConfiguration.bankAccountNo,
            },
            chartOptions: optional,
            currency,
        };

        return newResumeObject;
    }, [newPortfolio, selectedStrategy, language, isAzp, isThreeB, riskScore]);

    // step 6
    const initAgentInformationData = useCallback(async () => {

    }, [newPortfolio]);

    // step 7
    const initOverviewData = useCallback(async () => {

    }, [newPortfolio]);

    // step 8
    const initQualityCheckData = useCallback(async () => {

    }, [newPortfolio]);

    // step 9
    const initFinalizationData = useCallback(async () => {

    }, [newPortfolio]);

    const initFeesData = useCallback(async () => {

    }, [newPortfolio]);
    const initKYCFormData = useCallback(async () => {

    }, [newPortfolio]);
    const initKYCDocUploadData = useCallback(async () => {

    }, [newPortfolio]);
    const initWithdrawalData = useCallback(async () => {

    }, [newPortfolio]);

    useEffect(() => {
        const stepStateDef = { ...(isPk ? pkStepDef : generalStepStateDef) };

        if (stepStateDef[STEP_NAME.productOffer]) {
            stepStateDef[STEP_NAME.productOffer].action = initProductSeletionData;
            stepStateDef[STEP_NAME.productOffer].url = `/client/${dfsClientId}/portfolios/new-portfolio`;
        }
        if (stepStateDef[STEP_NAME.personalDetails]) {
            stepStateDef[STEP_NAME.personalDetails].action = initPesonalDetailsData;
            stepStateDef[STEP_NAME.personalDetails].url = `/client/${dfsClientId}/portfolios/new-portfolio/personal-details`;
        }
        if (stepStateDef[STEP_NAME.investorProfile]) {
            stepStateDef[STEP_NAME.investorProfile].action = initInvestorProfileData;
            stepStateDef[STEP_NAME.investorProfile].url = `/client/${dfsClientId}/portfolios/new-portfolio/investor-profile`;
        }
        if (stepStateDef[STEP_NAME.strategy]) {
            stepStateDef[STEP_NAME.strategy].action = initStrategySelectionData;
            stepStateDef[STEP_NAME.strategy].url = `/client/${dfsClientId}/portfolios/new-portfolio/strategy-selection`;
        }
        if (stepStateDef[STEP_NAME.withdrawal]) {
            stepStateDef[STEP_NAME.withdrawal].action = initWithdrawalData;
            stepStateDef[STEP_NAME.withdrawal].url = `/client/${dfsClientId}/portfolios/new-portfolio/withdrawal-plan`;
        }
        if (stepStateDef[STEP_NAME.productConfiguration]) {
            stepStateDef[STEP_NAME.productConfiguration].action = initGoalAndPortfolioSetupData;
            stepStateDef[STEP_NAME.productConfiguration].url = `/client/${dfsClientId}/portfolios/new-portfolio/goal-setup`;
        }
        if (stepStateDef[STEP_NAME.agentInformation]) {
            stepStateDef[STEP_NAME.agentInformation].action = initAgentInformationData;
            stepStateDef[STEP_NAME.agentInformation].url = `/client/${dfsClientId}/portfolios/new-portfolio/agent-information`;
        }
        if (stepStateDef[STEP_NAME.overview]) {
            stepStateDef[STEP_NAME.overview].action = initOverviewData;
            stepStateDef[STEP_NAME.overview].url = `/client/${dfsClientId}/portfolios/new-portfolio/overview`;
        }
        if (stepStateDef[STEP_NAME.qualityCheck]) {
            stepStateDef[STEP_NAME.qualityCheck].action = initQualityCheckData;
            stepStateDef[STEP_NAME.qualityCheck].url = `/client/${dfsClientId}/portfolios/new-portfolio/quality-check`;
        }
        if (stepStateDef[STEP_NAME.finalization]) {
            stepStateDef[STEP_NAME.finalization].action = initFinalizationData;
            stepStateDef[STEP_NAME.finalization].url = `/client/${dfsClientId}/portfolios/new-portfolio/opening`;
        }
        if (stepStateDef[STEP_NAME.fees]) {
            stepStateDef[STEP_NAME.fees].action = initFeesData;
            stepStateDef[STEP_NAME.fees].url = `/client/${dfsClientId}/portfolios/new-portfolio/fees-overview`;
        }
        if (stepStateDef[STEP_NAME.KYC_FORM]) {
            stepStateDef[STEP_NAME.KYC_FORM].action = initKYCFormData;
            stepStateDef[STEP_NAME.KYC_FORM].url = `/client/${dfsClientId}/portfolios/new-portfolio/kyc`;
        }
        if (stepStateDef[STEP_NAME.KYC_DOC_UPLOAD]) {
            stepStateDef[STEP_NAME.KYC_DOC_UPLOAD].action = initKYCDocUploadData;
            stepStateDef[STEP_NAME.KYC_DOC_UPLOAD].url = `/client/${dfsClientId}/portfolios/new-portfolio/upload-id`;
        }

        setSteps(stepStateDef);
    }, [
        initProductSeletionData,
        initPesonalDetailsData,
        initInvestorProfileData,
        initStrategySelectionData,
        initGoalAndPortfolioSetupData,
        initAgentInformationData,
        initOverviewData,
        initQualityCheckData,
        initFeesData,
        initKYCFormData,
        initKYCDocUploadData,
        initWithdrawalData,
        isPk,
    ]);

    const stepByStepResume = useCallback(async () => {
        if (newPortfolio?.applicationData?.investmentDescription && resumeStep && !isResumeBlocked) {
            let mixedResumeObject = {
                ...newPortfolio,
            };

            const executeActionForStep = async (stepId) => {
                const resumeObjectFromStep = await steps[stepId].action({ resumeObject: mixedResumeObject });

                mixedResumeObject = {
                    ...mixedResumeObject,
                    ...resumeObjectFromStep,
                };

                return mixedResumeObject;
            };

            await Promise.all(
                steps[resumeStep].condition.map(async (stepId) => executeActionForStep(stepId)),
            );

            setNewPortfolio(mixedResumeObject);
            saveDataToLocalStorage(mixedResumeObject);
            console.log('RESUME STATE', mixedResumeObject, steps[resumeStep]);
            setResumeInProgress(false);
            setResumeBlocked(true);
            navigate(steps[resumeStep].url);

            return resumeStep;
        }

        return undefined;
    }, [newPortfolio?.applicationData?.investmentDescription, resumeStep, isResumeBlocked, steps]);

    useEffect(() => {
        if (newPortfolio.applicationData?.investmentDescription && resumeStep && !isResumeBlocked) {
            (async () => {
                await stepByStepResume();
            })();
        }
    }, [newPortfolio?.applicationData?.investmentDescription, resumeStep, isResumeBlocked, stepByStepResume]);

    useEffect(() => {
        if (newPortfolio?.applicationData?.investmentDescription && !resumeStep && !isResumeBlocked) {
            (async () => {
                const {
                    resumeStep: resumeStepKey,
                } = await defineStep();

                setResumeStep(resumeStepKey);
            })();
        }
    }, [newPortfolio?.applicationData?.investmentDescription, resumeStep, isResumeBlocked]);

    const resume = useCallback(async () => {
        if (investmentApplicationId && newPortfolio) {
            setResumeBlocked(false);
            setResumeInProgress(true);
            const resumeObject = await init();

            setNewPortfolio(resumeObject);
            saveDataToLocalStorage(resumeObject);
        }

        return undefined;
    }, [investmentApplicationId, newPortfolio]);

    return {
        resume,
        isResumeInProgress,
    };
};
