import React from 'react';
import PropTypes from 'prop-types';
import { getClassNames } from '../../utils';
import './Loader.css';
import withTheme from '../../hocs/withTheme';

function Loader({ large, light, negative }) {
    const className = getClassNames(
        'Loader',
        { 'Loader-large': large },
        { 'Loader-light': light },
        { 'Loader-negative': negative },
    );

    return (
        <div className="Loader-wrapper">
            <div className={className}>
                <div className="Loader-circle" />
            </div>
        </div>
    );
}

Loader.propTypes = {
    /** The size of loader */
    large: PropTypes.bool,
    /** The color of loader */
    light: PropTypes.bool,
    /** The color of loader */
    negative: PropTypes.bool,
};

Loader.defaultProps = {
    large: false,
    light: false,
    negative: false,
};

export default withTheme(Loader);
