import Modal from './Modal';
import confirm from './core/confirm';
import {
    modalWarn,
    withConfirm, withError, withInfo, withSuccess,
} from './types';
import { confirmProps, defaultConfig } from './constants';

Modal.info = (props) => confirm(
    withInfo({ ...defaultConfig, ...props }),
);
Modal.success = (props) => confirm(
    withSuccess({ ...defaultConfig, ...props }),
);
Modal.error = (props) => confirm(
    withError({ ...defaultConfig, ...props }),
);
Modal.confirm = (props) => confirm(
    withConfirm({ ...confirmProps, ...defaultConfig, ...props }),
);
Modal.warning = modalWarn;
Modal.warn = modalWarn;


export default Modal;
