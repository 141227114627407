import React, {useEffect} from 'react';
import {authenticator} from '../authenticator';

type PostlogoutContainerProps = {
    children?: React.ReactChild | React.ReactChild[] | null;
}
const PostlogoutContainer = ({children = null}: PostlogoutContainerProps) => {
    const userManager = authenticator.getUserManager();

    useEffect(() => {
        userManager?.signinRedirect();
    }, []);

    return (
        <>
            {children}
        </>
    );
};

export default PostlogoutContainer;
