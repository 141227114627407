import React from 'react';
import {
    ChangeVBorVBPPlanModalContent,
} from 'pages/ClientOverview/pages/Portfolios/pages/SinglePortfolioView/pages/Strategy/componets/ChangeVBorVBPPlanModalContent';
import {BUTTON_TYPE_SECONDARY} from 'ui-library/components/Button/constants';
import {ACTIVE} from 'constants/portfolioStatuses';
import {
    EXTERNAL_PRODUCTS, forbiddenNationalities, PRODUCTS, STRATEGIES, switzLandCountryId,
} from 'constants/constants';
import ServiceManager from '../../../services/ServiceManager';
import {Modal} from '../../../ui-library';
import Error from '../../../components/AtomicStructure/atoms/Error';
import {getUpperAgeLimit} from '../pages/Portfolios/pages/CreateNewPortfolio/utils';

const planStatus = {
    accepted: 'accepted',
};

const showModal = (t, action, error) => {
    const content = (
        <div>
            <span>{t(`clientDashboard.unableTo${action}Body`)}</span>
            {error && <Error>{error}</Error>}
        </div>
    );

    Modal.info({
        title: t(`clientDashboard.unableTo${action}Title`),
        content,
    });
};

const showVBorVBPModal = ({
    t, isVBP, isVB, path, navigate,
}) => {
    let modal;

    function closeModal() {
        if (modal?.destroy) {
            modal.destroy();
        }
    }
    modal = Modal.info({
        title: t('clientDashboard.changeVBPlan.title'),
        content: <ChangeVBorVBPPlanModalContent isVBP={isVBP} isVB={isVB} path={path} navigate={navigate} onNavigate={closeModal}/>,
        okText: t('clientDashboard.cancel'),
        okType: BUTTON_TYPE_SECONDARY,
        width: 680,
    });
};

export const taxValidation = (taxes) => String(taxes?.hasUsResidence) === 'false' && String(taxes?.classedUsPerson) === 'false' && String(taxes?.isUsCitizenOrSubjectToUsTax) === 'false' && String(taxes?.isUsPerson) === 'false';

export const getRestrictedClientMessage = ({data, t, selectedProductType = undefined}) => {
    if (data?.countryOfResidence !== switzLandCountryId) {
        return t('portfolio.createNewPortfolio.produntOnlyForSwitzland');
    }
    if (forbiddenNationalities.includes(data?.nationality)) {
        return t('portfolio.createNewPortfolio.nationalityNotAllowed');
    }
    if ((data?.userClientAge < 17 || data?.userClientAge >= getUpperAgeLimit(data?.gender)) && selectedProductType && selectedProductType !== PRODUCTS.bbb) {
        return t('portfolio.createNewPortfolio.productIsNotAccaptableForThisAge');
    }
    if (!taxValidation(data?.taxData)) { // explicit check is needed because there are some props with string value
        return t('clientDashboard.unableToOpenNewPortfolioBody');
    }

    return undefined;
};

export const modifyPlan = async ({
    path, action, id, t, status, navigate, productExternalId, amount,
}) => {
    const executeActivePlan = () => {
        if (productExternalId && (productExternalId.toLowerCase() === EXTERNAL_PRODUCTS.fz || productExternalId.toLowerCase() === EXTERNAL_PRODUCTS.fzp)) {
            showVBorVBPModal({
                t,
                isVBP: amount >= 200000,
                isVB: true,
                path,
                navigate,
            });
        } else {
            navigate(path);
        }
    };

    try {
        if (status === ACTIVE) {
            executeActivePlan();
        } else {
            const {data} = await ServiceManager.planService('getPlanStatus', [{planId: id}]);

            if (data === planStatus.accepted) {
                executeActivePlan();
            } else {
                showModal(t, action);
            }
        }
    } catch (err) {
        showModal(t, action, err.response?.data);
    }
};

export const findStrategyErrorMessage = ({
    is3bProduct, isZic, isAzp, selectedStrategyId, setStrategySelectedError,
}) => {
    if (is3bProduct && isZic) {
        if (selectedStrategyId === STRATEGIES.income || selectedStrategyId === STRATEGIES.capital) {
            setStrategySelectedError({
                message:
                selectedStrategyId === STRATEGIES.capital ? 'portfolio.createNewPortfolio.selectStrategy.zicStrategyCapitalWarn'
                    : 'portfolio.createNewPortfolio.selectStrategy.zicStrategyWarn',
                isError: false,
            });
        } else if (selectedStrategyId === STRATEGIES.interest) {
            setStrategySelectedError({message: 'portfolio.createNewPortfolio.selectStrategy.zicStrategyError', isError: true});
        } else {
            setStrategySelectedError(false);
        }
    } else if (is3bProduct && isAzp && selectedStrategyId === STRATEGIES.interest) {
        setStrategySelectedError({message: 'portfolio.createNewPortfolio.selectStrategy.strategySelectedError', isError: true});
    } else {
        setStrategySelectedError(false);
    }
};
