import { getCustomUrl } from '../../setup';
import Service from './service';
import { getQueryStringByObject } from '../../utils/queryUtils';

class Rss extends Service {
    static rssFeed(language, params) {
        const queryString = getQueryStringByObject(params);

        return this.getRequest(`${getCustomUrl()}/rss/${language}?${queryString}`, { isSecure: true });
    }
}

export default Rss;
