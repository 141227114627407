import React from 'react';
import PropTypes from 'prop-types';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from './WrappedHighcharts';
import Legend from './Legend';
import { buildAdditionalCss, buildLegend, getPieChartOptions } from './utils';

function ChartItem(props) {
    const {
        data, singleAllocation, sum, numberFormat, percentFormat, disableLegend, index, hideValues, chartSize
    } = props;
    const css = buildAdditionalCss(data, index);

    return (
        <div
            style={css}
            className={`highcharts-pie ${singleAllocation ? 'highcharts-pie_single-allocation' : ''}`}
        >
            <HighchartsReact
                containerProps={{ className: 'highcharts-react-container' }}
                highcharts={Highcharts}
                options={getPieChartOptions(data, sum, singleAllocation, percentFormat,chartSize)}
            />
            {!disableLegend && (
                <Legend
                    legend={buildLegend(data, sum, singleAllocation, percentFormat)}
                    numberFormat={numberFormat}
                    singleAllocation={singleAllocation}
                    hideValues={hideValues}
                />
            )}
        </div>
    );
}

ChartItem.propTypes = {
    /** Data to be shown on chart */
    data: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        y: PropTypes.number.isRequired,
    })),
    /** Single allocation view */
    singleAllocation: PropTypes.bool,
    /** Sum of values */
    sum: PropTypes.number,
    numberFormat: PropTypes.func,
    percentFormat: PropTypes.func,
    disableLegend: PropTypes.bool,
    index: PropTypes.number,
    hideValues: PropTypes.bool,
    chartSize: PropTypes.number
};

ChartItem.defaultProps = {
    data: [],
    singleAllocation: false,
    sum: 0,
    numberFormat: (num) => (num.toLocaleString('en-US', { minimumFractionDigits: 2 })),
    percentFormat: (num) => `${(num.toLocaleString('en-US', { maximumFractionDigits: 2 }))}`,
    disableLegend: false,
    index: null,
    hideValues: false,
};

export default ChartItem;
