import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { getClientId } from 'utils';
import { clearClient } from 'prodivers/clientProfile';
import { useMainLayout } from 'hooks/useMainLayout';
import {useLocation, useParams} from 'react-router-dom';

const mainLayout = (
    WrappedComponent,
) => {
    const MainLayout = (props) => {
        const { dfsClientId } = useParams();

        // Use the useLocation hook to get the current location object
        const { pathname } = useLocation();
        const clientId = getClientId(dfsClientId);

        useEffect(() => clearClient(), []);
        useMainLayout({ pathname });

        return (
            <WrappedComponent
                dfsClientId={dfsClientId}
                clientId={clientId}
                {...props}
            />
        );
    };

    return MainLayout;
};

export default mainLayout;
