import { useTranslation } from 'react-i18next';
import {useCallback, useEffect, useState} from 'react';
import ServiceManager from 'services/ServiceManager';
import { useFormatting } from '../locale';

export const useGetInvestmentApplicationOverview = (adapter, isDataAccessible, containerId, applicationId) => {
    const [isLoading, setIsLoading] = useState(true);
    const [rawData, setRawData] = useState(null);
    const [tableData, setTableData] = useState(null);
    const [error, setError] = useState(null);

    const { t, i18n: { language } } = useTranslation();

    const { getFormattedDate } = useFormatting();

    const updateApplicationData = useCallback((appId, data) => {
        const newData = rawData.map(i => {
            if (i.investmentApplicationId === appId) {
                return {...i, ...data};
            }

            return i;
        });

        setRawData(newData);
    }, [rawData]);

    const getInvestmentApplications = useCallback(async () => {
        setIsLoading(true);
        try {
            const {
                data,
            } = await ServiceManager.customInvestmentService('getInvestmentApplicationOverview', [{
                language, containerOrApplicationId: applicationId || containerId, isContainer: !!containerId,
            }]);

            setRawData(applicationId ? [data] : data);
        } catch (err) {
            setError(err);
        } finally {
            setIsLoading(false);
        }
    }, [language, containerId, applicationId]);

    useEffect(() => {
        (async () => getInvestmentApplications())();
    }, [getInvestmentApplications]);

    useEffect(() => {
        if (rawData) {
            const data = adapter({
                data: rawData,
                t,
                updateData: updateApplicationData,
                getFormattedDate,
                isDataAccessible,
                isContainerCase: !!containerId,
            });

            setTableData(data);
        }
    }, [rawData, isDataAccessible, updateApplicationData, adapter, getFormattedDate]);

    return {
        isLoading, error, data: tableData, getInvestmentApplications,
    };
};
