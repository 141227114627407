import React, {useCallback, useState} from 'react';
import {Button, notification, TextInput} from '../../ui-library';

import './Infra.css';
import ServiceManager from '../../services/ServiceManager';

const InfraTest = () => {
    const [timeout, setTimeout] = useState('');
    const [inProgress, setInProgress] = useState(false);

    const countDown = useCallback(() => {
        let countDownVal = parseInt(timeout, 10);
        const interval = setInterval(() => {
            if (countDownVal > 0) {
                countDownVal -= 1;
                setTimeout(countDownVal || '');
            } else {
                clearInterval(interval);
            }
        }, 1000);
    }, [timeout]);

    const onTestButtonClick = useCallback(async () => {
        try {
            if (!timeout) {
                return;
            }
            setInProgress(true);
            countDown();
            await ServiceManager.customCommonService('infraTest', [{timeout}]);
        } catch (err) {
            notification.open({content: err.message, type: 'error'});
        } finally {
            setInProgress(false);
        }
    }, [timeout, countDown]);

    return (
        <div className="infra">
            <TextInput disabled={inProgress} value={timeout} onChange={(e) => { setTimeout(e.target.value); }} label="Timeout seconds:" type="number"/>
            <Button disabled={inProgress} onClick={onTestButtonClick}>Test</Button>
        </div>
    );
};

export default InfraTest;
