import { roundAllocations } from 'utils';

export const getAllocations = (data) => {
    const roundedAllocations = data?.length ? roundAllocations(data, 1000, 1000) : [];
    let allocations = [];

    allocations = roundedAllocations.map(({ id, value }) => ({
        Allocation: Number(value),
        InstrumentId: id,
        Notes: [],
        IsNotAdvised: false,
    })).filter(({ Allocation }) => Allocation !== 0);

    return allocations;
};

export const adaptProjection = ({
    data, CurrencyId, ProjectionYears, InitialInvestmentAmount, MonthlyContributionAmount,
} = {}) => {
    const roundedAllocation = data?.length ? roundAllocations(data, 1000, 1000) : [];
    const InstrumentAllocations = roundedAllocation.map(({ id, value }) => ({
        Allocation: Number(value),
        InstrumentId: id,
    })).filter(({ Allocation }) => Allocation !== 0);

    return {
        CurrencyId,
        ProjectionYears,
        InstrumentAllocations,
        InitialInvestmentAmount,
        MonthlyContributionAmount,
    };
};
