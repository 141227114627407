export const DEFAULT_PAGE_SIZE = 5;
export const ITEM_ON_PAGE = 3;
export const START_PAGINATION_PAGE = 1;

export const BUSINESS_TRANSACTIONS_STATUS = {
    new: 'new',
    inProgress: 'inProgress',
    forSignature: 'forSignature',
    submitted: 'submitted',
    rejected: 'rejected',
    accepted: 'accepted',
    error: 'error',
};
