import { defaultBarChartOptions } from './constants';

export const buildAdditionalCss = (data) => (data?.[0]?.data || []).reduce(
    (acc, { color }, idx) => ({ ...acc, [`--bar-chart-color-${idx}`]: color }), {},
);

export const getBarChartOptions = (
    max, data, labels, height, labelFormat, barHeight,
) => {
    const baseLength = 15;
    const baseBarHeight = barHeight || 80;
    const baseCategoryLineHeight = 44;

    return {
        ...defaultBarChartOptions,
        chart: {
            ...defaultBarChartOptions.chart,
            height,
            events: {
                render() {
                    const { categories } = this.xAxis[0];
                    const maxLines = Math.max(...categories
                        .map((item) => Math.ceil(item.length / baseLength)) || 1);

                    const plotHeight = categories.length * baseBarHeight
                        + baseCategoryLineHeight * maxLines;
                    const chartHeight = plotHeight + (this.chartHeight - this.plotHeight);

                    if (!height && chartHeight !== this.chartHeight) {
                        this.setSize(null, chartHeight, false);
                    }
                },
            },
        },
        xAxis: {
            ...defaultBarChartOptions.xAxis,
            categories: labels,
            labels: {
                ...defaultBarChartOptions.xAxis.labels,
                formatter({ pos, value }) {
                    return `<div class="bar-chart__label">${labelFormat(value, data[0].data[pos])}</div>`;
                },
            },
        },
        yAxis: {
            ...defaultBarChartOptions.yAxis,
            endOnTick: false,
            max,
        },
        series: [...data],
    };
};
