import React from 'react';
import PropTypes from 'prop-types';
import ConfirmationPage from 'components/ConfirmationPage';
import { useConfirmationLayout } from './hooks/useConfirmationLayout';

const Confirmation = () => {
    useConfirmationLayout();

    return (
        <ConfirmationPage />
    );
};

export default Confirmation;
