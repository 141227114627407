import {useSecure} from 'libs/additiv-react-oidc-client';
import {useCallback} from 'react';
import {INIT_LOCATION} from '../../../constants/sessionStorageKeys';

export const useAuth = () => {
    const {logout: signOut} = useSecure();

    const logout = useCallback(() => {
        global.sessionStorage.removeItem(INIT_LOCATION);
        signOut();
    }, [signOut]);

    return {logout};
};
