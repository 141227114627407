class StoreManager {
    // eslint-disable-next-line class-methods-use-this
    save() {
        throw new Error('save should be implemented');
    }

    // eslint-disable-next-line class-methods-use-this
    load() {
        throw new Error('load should be implemented');
    }
}

export default StoreManager;
