import { getServicesUrl } from '../../setup';
import Service from './service';

class Trading extends Service {
    static postCurrenciesExchangerates(CurrencyExchangeRateRequests) {
        return Service.postRequest(`${getServicesUrl()}/currencies/exchangerates`, { CurrencyExchangeRateRequests }, { isSecure: true });
    }
}

export default Trading;
