import React, { useEffect, useState } from 'react';
import {useTranslation} from 'react-i18next';
import {useLocation, useNavigate} from 'react-router-dom';
import { capitalize } from 'lodash';
import { RadioButtonGroup } from 'ui-library';

import { connectClientsSchema } from 'pages/Clients/provider/ClientsSchemaProvider';
import Card from 'components/Card/Card';
import Preloader from 'components/Preloader/Preloader';
import NewWrapper from 'components/AtomicStructure/layouts/NewWrapper';
import ButtonsBlockRow from 'components/ButtonsBlockRow/ButtonsBlockRow';
import Error from 'components/AtomicStructure/atoms/Error';
import { useConnections } from 'components/Connections/components';
import { Address, AddressType } from 'components/Connections/types';
import { useOptionList } from 'hooks/useOptionList';

import {useClientLayout} from '../../../../hooks/useClientLayout';
import {
    CONNECTIONS_SESSION_KEY, MailingAddress, NewConnectionsSessionManagerAPI,
} from './constants';
import './NewConnections.css';
import NewAddressModal from './NewAddressModal';
import {OPTION_LIST} from '../../../../../../constants/constants';

const NewConnectionsAddress = () => {
    useClientLayout();
    const {t} = useTranslation();

    const navigate = useNavigate();
    const location = useLocation();

    const {
        loadContactAddresses, isAddressesLoading, addresses, addressError,
    } = useConnections();
    const {
        lists: [optionCountry, optionLanguage], isLoading: isOptionsLoading,
    } = useOptionList([OPTION_LIST.country, OPTION_LIST.language]);
    const [selectedAddress, setSelectedAddress] = useState<string | null>(null);
    const [addressesLocal, setAddressesLocal] = useState<Address[]>([]);

    const goToAction = () => {
        const preservedState = JSON.parse(global.sessionStorage.getItem(CONNECTIONS_SESSION_KEY) || '{}') as NewConnectionsSessionManagerAPI;

        const address = addressesLocal[Number(selectedAddress)];
        const country = optionCountry?.find(c => c.id === address.country);

        const mailingAddress: MailingAddress = {
            language: address.language,
            addLine1: address.addressLine1 || '',
            addLine2: address.addressLine2 || '',
            street: address.streetNo || '',
            zipCity: address.zipCode || '',
            city: address.city || '',
            country: country?.id ? +country.id : 0,
        };

        global.sessionStorage.setItem(CONNECTIONS_SESSION_KEY, JSON.stringify({
            ...preservedState,
            mailingAddress,
        }));

        navigate(location.pathname.replace('/address', '/summary'));
    };

    useEffect(() => {
        let newConnection: string | NewConnectionsSessionManagerAPI | null = global.sessionStorage.getItem(CONNECTIONS_SESSION_KEY);

        if (newConnection) {
            newConnection = JSON.parse(newConnection) as NewConnectionsSessionManagerAPI;

            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const addressIds = [...newConnection?.clients?.map(c => c.id) || [], newConnection.contactId!];

            loadContactAddresses(addressIds);
        }
    }, []);

    useEffect(() => {
        const allAddresses = [...addresses || []];
        let newConnection: string | NewConnectionsSessionManagerAPI | null = global.sessionStorage.getItem(CONNECTIONS_SESSION_KEY);

        if (newConnection) {
            newConnection = JSON.parse(newConnection) as NewConnectionsSessionManagerAPI;
            allAddresses.push(...newConnection.newlyCreatedAddresses || []);
        }

        setAddressesLocal(allAddresses);
    }, [addresses]);

    const createNewMailingAddress = (data: any) => {
        const adr: Address = {
            contactName: '',
            language: data.mailRecipientLanguage,
            country: data.mailRecipientAddressCountry,
            addressLine1: data.mailRecipientAddressLine1 || '',
            addressLine2: data.mailRecipientAddressLine2 || '',
            streetNo: data.mailRecipientAddressLine3 || '',
            zipCode: data.mailRecipientAddressLine4 || '',
            city: data.mailRecipientAddressLine5 || '',
            addressType: AddressType.Mailing,
        };

        setAddressesLocal((prev) => {
            const newAddresses = [...prev, adr];

            setSelectedAddress(String(newAddresses.length - 1));

            return newAddresses;
        });

        const preservedState = JSON.parse(global.sessionStorage.getItem(CONNECTIONS_SESSION_KEY) || '{}') as NewConnectionsSessionManagerAPI;

        preservedState.newlyCreatedAddresses = [...preservedState.newlyCreatedAddresses || [], adr];

        global.sessionStorage.setItem(CONNECTIONS_SESSION_KEY, JSON.stringify(preservedState));
    };

    const optionLabel = (address) => (
        <div>
            <div>{address?.streetNo}</div>
            <div>{`${address?.zipCode} ${address.city}`}</div>
        </div>
    );

    const labelDescription = (address) => <div>{t(address.addressType === AddressType.Mailing ? 'connections.createNew.mailingAddress' : 'connections.createNew.domicileAddress', {contact: address.contactName})}</div>;

    return (
        <div className="container PoaConnections JaAddress">
            <div className="fi-verticals">
                <Card
                    title={t('connections.createNewJa.title')}
                >
                    <NewWrapper
                        HeaderNavbarTurnOff
                        stepNavBarActive={1}
                        dataKey="newJaConnections"
                    >
                        <div className="poa_content">
                            <p className="text-center relationshipLabel">
                                {t('connections.createNew.addressLabel')}
                            </p>
                            <Preloader isLoading={isAddressesLoading || isOptionsLoading}>
                                <div className="poa_inner">
                                    <RadioButtonGroup
                                        className="radioButtons"
                                        value={selectedAddress}
                                        options={
                                            addressesLocal.map((adr: Address, index) => ({
                                                label: optionLabel(adr),
                                                value: index.toString(),
                                                description: labelDescription(adr),
                                            }))
                                        }
                                        onChange={(e) => setSelectedAddress(e.target.value)}
                                    />
                                </div>
                                <div>
                                    <NewAddressModal onSubmit={createNewMailingAddress} />
                                </div>
                            </Preloader>
                            <Preloader isLoading={false}>
                                <div className="container">
                                    {addressError && <Error>{addressError}</Error>}

                                    <ButtonsBlockRow
                                        additionalButton={{
                                            text: t('onboardingFlow.documetsPage.back'),
                                            onClick: () => navigate(-1),
                                        }}
                                        primaryButton={{
                                            text: t('onboardingFlow.clientsDataPage.continue'),
                                            onClick: () => goToAction(),
                                            disabled: !selectedAddress,
                                        }}
                                    />
                                </div>
                            </Preloader>
                        </div>
                    </NewWrapper>
                </Card>
            </div>
        </div>
    );
};

export default connectClientsSchema(NewConnectionsAddress);
