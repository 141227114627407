import React, {
    useEffect, useState, useCallback, useMemo,
} from 'react';
import {withMainLayout} from 'hocs';
import Preloader from 'components/Preloader';
import EmptyContent from 'components/EmptyContent';
import {useTranslation} from 'react-i18next';
import SM from 'services/ServiceManager';
import Card from 'components/Card/Card';
import SelectElement from 'components/AtomicStructure/atoms/SelectElement';
import {downloadDmsDocument} from 'utils/downloadDmsDocument';
import {CHANGE_MODEL, CHANGE_STRATEGY} from 'constants/constants';
import {
    Table, SearchBar, Modal, notification,
} from '../../ui-library';
import {getColumns} from './table/table';
import {DEFAULT_PAGE_SIZE, START_PAGINATION_PAGE} from './constants/const';
import {adaptDocLibrary} from './utils/adaptDocLibrary';
import {useOptionsDocLibrary} from './hooks/useOptionsDocLibrary';
import {TableFilter, TableFilterItem} from '../../components/TableFilter';
import {getLocaleString} from '../../locale';
import {useManagerProfile} from '../../prodivers/managerProfile';

import UploadDocumentModal from './components/UploadDocumentModal';
import './DocLibraryPage.css';
// import {MODIFY} from 'pages/ClientOverview/pages/Portfolios/constants';

const DocLibraryPage = () => {
    const {t, i18n: {language}} = useTranslation();

    const [docError, setDocError] = useState(null);
    const [fullData, setFullData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [downloadInProgress, setDownloadInProgress] = useState({});
    const [deleteInProgress, setDeleteInProgress] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [documentCategoryId, setDocumentCategoryId] = useState(null);
    const [productList, setProductList] = useState([]);
    const [productId, setProductId] = useState(null);
    const [currentPagination, setCurrentPagination] = useState(START_PAGINATION_PAGE);
    const [showModal, setShowModal] = useState(false);
    const {hasAccessPolicy} = useManagerProfile();

    const defaultItem = {id: '', label: t('clientDashboard.documents.all')};

    const {documentCategoriesList, isLoading: isDocOptionsLoading} = useOptionsDocLibrary();
    const getProductsList = useCallback(async () => {
        const {data} = await SM.customCommonService('getProductsConfiguration', [{language}]);

        setProductList(data?.productOffers?.[0]?.products?.map(item => ({id: String(item.id), label: getLocaleString(item.name, language)})));
    }, [language]);

    const getDocumentLists = useCallback(async () => {
        try {
            setIsLoading(true);

            const {data} = await SM.customDocumentsService('getGeneralDocuments', [{language}]);

            setFullData(data);
        } catch (err) {
            setDocError(err);
        } finally {
            setIsLoading(false);
        }
    }, [language]);

    // const documentCategories = useMemo(() => {
    //     if (!documentCategoriesList) return [defaultItem];
    //
    //     return [defaultItem, ...documentCategoriesList];
    // }, [documentCategoriesList]);

    const productOptions = useMemo(() => {
        if (!productList) return [defaultItem];

        return [defaultItem, ...productList];
    }, [productList]);

    const deleteDocument = useCallback(async (documentId) => {
        try {
            setDeleteInProgress(true);
            await SM.customDocumentsService('deleteDocumentById', [documentId]);
            getDocumentLists();

            notification.open({content: t('advisoryDashboard.documents.documentDeletedSuccess'), type: 'success'});
        } catch (err) {
            console.error(err);
            notification.open({content: t('general.somethingWentWrong'), type: 'error'});
        } finally {
            setDeleteInProgress(false);
        }
    }, [t, getDocumentLists]);

    const deleteDocumentConfirmation = useCallback((documentId, content) => {
        Modal.confirm({
            title: t('advisoryDashboard.documents.deleteTitle'),
            content,
            okText: t('clientDashboard.yes'),
            onOk: async () => deleteDocument(documentId),
            cancelText: t('clientDashboard.no'),
            className: 'close-client-modal',
        });
    }, []);

    const downloadDocument = useCallback(async (documentId, fileName) => {
        try {
            setDownloadInProgress((prev) => ({...prev, [documentId]: true}));
            const {data} = await SM.documents('getDocumentById', [documentId]);

            const {newBlob} = downloadDmsDocument(
                data,
                {
                    name: fileName,
                    saveFile: true,
                },
            );
            const url = URL.createObjectURL(newBlob);

            global.open(url, '_blank');
        } catch (err) {
            console.log(err);
        } finally {
            setDownloadInProgress((prev) => ({...prev, [documentId]: false}));
        }
    }, []);

    useEffect(() => {
        (async () => Promise.all([getDocumentLists(), getProductsList()]))();
    }, [getProductsList, getDocumentLists]);

    const onChangePage = useCallback((paging) => {
        setCurrentPagination(paging?.currentPage);
    }, []);

    const filteredData = useMemo(() => fullData
        .filter(f => !searchTerm || f.documentMetadataName.toLowerCase().includes(searchTerm.toLowerCase()))
        .filter(f => !documentCategoryId || parseInt(f.documentCategoryId, 10) === parseInt(documentCategoryId, 10))
        .filter(f => !productId || parseInt(f.productId, 10) === parseInt(productId, 10)), [searchTerm, documentCategoryId, productId, fullData]);

    const tableData = useMemo(() => adaptDocLibrary(filteredData, downloadDocument, downloadInProgress, deleteDocumentConfirmation, deleteInProgress), [filteredData, downloadDocument, downloadInProgress, deleteDocumentConfirmation, deleteInProgress]);

    return (
        <div className="container">
            <Card
                title={t('advisoryDashboard.documents.title')}
                headerActions={hasAccessPolicy('UploadGeneralDocuments') ? (
                    <button
                        type="button"
                        className="button-link"
                        onClick={() => setShowModal(true)}
                    >
                        {t('advisoryDashboard.documents.uploadDocument')}
                    </button>
                ) : undefined}
            >
                <TableFilter>
                    {/* <TableFilterItem> */}
                    {/*    <SelectElement */}
                    {/*        placeholder={t('clientDashboard.documents.category')} */}
                    {/*        options={documentCategories} */}
                    {/*        value={documentCategoryId} */}
                    {/*        onChange={setDocumentCategoryId} */}
                    {/*    /> */}
                    {/* </TableFilterItem> */}
                    <TableFilterItem>
                        <SelectElement
                            placeholder={t('advisoryDashboard.documents.product')}
                            options={productOptions}
                            value={productId}
                            onChange={setProductId}
                        />
                    </TableFilterItem>
                    <TableFilterItem>
                        <SearchBar
                            onSearch={(value) => setSearchTerm(value)}
                            value={searchTerm}
                            className="search-wrapper"
                            placeholder={t('clientDashboard.documents.search')}
                        />
                    </TableFilterItem>
                </TableFilter>
                <Preloader isLoading={isLoading} error={docError}>
                    <EmptyContent data={filteredData} text={t('advisoryDashboard.documents.noDocuments')}>
                        <Table
                            columns={getColumns(t)}
                            data={tableData}
                            paging={{
                                total: filteredData.length,
                                pageSize: DEFAULT_PAGE_SIZE,
                                currentPage: currentPagination,
                            }}
                            onChange={onChangePage}
                        />
                    </EmptyContent>
                </Preloader>
            </Card>
            {showModal && (
                <UploadDocumentModal
                    onClose={() => setShowModal(false)}
                    documentCategories={documentCategoriesList}
                    productsList={productList}
                    getDocumentLists={getDocumentLists}
                />
            )}
        </div>
    );
};

export default withMainLayout(DocLibraryPage);
