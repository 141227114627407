import Service from './service';
import { getServicesUrl } from '../../setup';
import { getQueryStringByObject } from '../../utils/queryUtils';
import { APIVersion } from '../../types/main';

class ContactInteraction extends Service {
    static getInteractionTopics(language) {
        // eslint-disable-next-line no-undef
        console.log('DEPRECATED use Definitions.postDefinitions instead')
        return Service.getRequest(
            `${getServicesUrl()}/interactiontopics?language=${language}`,
            { isSecure: true }
        );
    }

    static getInteractionTypes(language) {
        // eslint-disable-next-line no-undef
        console.log('DEPRECATED use Definitions.postDefinitions instead')
        return Service.getRequest(
            `${getServicesUrl()}/interactiontypes?language=${language}`,
            { isSecure: true }
        );
    }

    static getInteractions(contactId, data) {
        return Service.postRequest(
            `${getServicesUrl()}/contacts/${contactId}/interactions`, data, { isSecure: true }
        );
    }

    static getMemberInteractions(memberId, data, useV11 = false) {
        return Service.postRequest(
            `${getServicesUrl(useV11 ? APIVersion.V11 : APIVersion.V12)}/members/${memberId}/interactions`, data, { isSecure: true }
        );
    }

    static getJournals(interactionId) {
        return Service.getRequest(
            `${getServicesUrl()}/interactions/${interactionId}/journals`, { isSecure: true }
        );
    }

    static getJournalsForContact(contactId, params) {
        const queryString = getQueryStringByObject(params);

        return Service.getRequest(
            `${getServicesUrl(APIVersion.V11)}/contacts/${contactId}/journal?${queryString}`, { isSecure: true }
        );
    }

    static getJournalsForMember(memberId, data) {
        return Service.postRequest(
            `${getServicesUrl(APIVersion.V12)}/members/${memberId}/journals`, data, { isSecure: true }
        );
    }

    static patchInteractions(id, data) {
        return Service.patchRequest(
            `${getServicesUrl()}/interactions/${id}`, data, { isSecure: true }
        );
    }

    static postInteractions(data) {
        return Service.postRequest(
            `${getServicesUrl()}/interactions`, data, { isSecure: true }
        );
    }

    static patchJournals(contactId, journalId, data) {
        return Service.patchRequest(
            `${getServicesUrl()}/contacts/${contactId}/journals/${journalId}`, data, { isSecure: true }
        );
    }

    static searchJournalsByContact(contactId, data) {
        return Service.postRequest(
            `${getServicesUrl(APIVersion.V11)}/contacts/${contactId}/journals/search`, data, { isSecure: true }
        );
    }

    static postJournal(contactId, data) {
        return Service.postRequest(
            `${getServicesUrl()}/contacts/${contactId}/journal`, data, { isSecure: true }
        );
    }

    static postClients(personId, language, data) {
        return Service.postRequest(
            `${getServicesUrl()}/persons/${personId}/clients?language=${language}`, data, { isSecure: true }
        );
    }

    static createJournals(contactId, data) {
        return Service.postRequest(
            `${getServicesUrl()}/contacts/${contactId}/journal`, data, { isSecure: true }
        );
    }

    static linkJournals(interactionId, data) {
        return Service.postRequest(
            `${getServicesUrl()}/interactions/${interactionId}/journals/link`, data, { isSecure: true }
        );
    }

    static getInteraction(interactionId) {
        return Service.getRequest(
            `${getServicesUrl()}/interactions/${interactionId}`, { isSecure: true }
        );
    }

    static getMemberClients(memberId, data) {
        return Service.postRequest(
            `${getServicesUrl()}/members/${memberId}/clients`, data, { isSecure: true }
        );
    }
}

export default ContactInteraction;
