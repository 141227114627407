import {useCallback, useEffect, useReducer} from 'react';
import {useTranslation} from 'react-i18next';
import {useSchema} from 'domain/ClientService';
import {OPTION_LIST, separator} from 'constants/constants';
import {
    SortableItemType,
    sortByAlphabet,
    sortList,
    toLowerKeys,
} from '../pages/ClientOnboardingFlow/pages/NewsPlan/NewPersonalInformationPage/helpers/sortList';
import {
    removeListLanguages,
} from '../pages/ClientOnboardingFlow/pages/NewsPlan/NewPersonalInformationPage/helpers/removeListLanguages';
import {
    additionalCountryList,
    additionalLanguageList,
} from '../pages/ClientOnboardingFlow/pages/NewsPlan/NewPersonalInformationPage/constants/options';
import type {ReducerActionType} from '../ViewModels/Redux';

type StateType = {
    lists: Array<Array<SortableItemType> | null>
    isLoading: boolean,
}

const initialState = {
    lists: [],
    isLoading: true,
} as StateType;

const reducer = (state :StateType = initialState, action: ReducerActionType): StateType => {
    switch (action.type) {
        case 'setData':
            return { ...state, lists: action.payload };
        case 'setLoading':
            return { ...state, isLoading: action.payload };
        default:
            return state;
    }
};

const defaultAdapters = {
    [OPTION_LIST.country]: (list) => {
        const orderedCountriesList = sortByAlphabet(list);

        return sortList([separator, ...(orderedCountriesList as [])], additionalCountryList);
    },
    [OPTION_LIST.gender]: (list) => toLowerKeys(list),
    [OPTION_LIST.maritalStatus]: (list) => (list),
    [OPTION_LIST.language]: (list) => toLowerKeys(removeListLanguages(sortList(list || [], additionalLanguageList))),
    [OPTION_LIST.salutation]: (list) => toLowerKeys(list),
    [OPTION_LIST.salutation]: (list) => toLowerKeys(list),
    [OPTION_LIST.originOfAssets]: (list) => list?.map((item) => ({...item, value: `${item.id}`})),
    [OPTION_LIST.beneficialOwner]: (list) => list?.map((item) => ({...item, value: `${item.id}`})),
    [OPTION_LIST.exclusionSubstitute]: (list) => list?.map((item) => ({...item, value: `${item.id}`})),
    [OPTION_LIST.preferenceExclusion]: (list) => list?.map((item) => ({...item, value: `${item.id}`})),
    [OPTION_LIST.preferenceStocks]: (list) => list?.map((item) => ({...item, value: `${item.id}`})),
    [OPTION_LIST.preferenceInclusion]: (list) => list?.map((item) => ({...item, value: `${item.id}`})),
    [OPTION_LIST.fatfGreylist]: (list) => list?.map((item) => ({...item, value: `${item.id}`})),
};

export const useOptionList = (listKeys = [
    OPTION_LIST.country, OPTION_LIST.gender, OPTION_LIST.maritalStatus, OPTION_LIST.language, OPTION_LIST.salutation,
], adapters = defaultAdapters) : StateType => {
    const { i18n: { language } } = useTranslation();

    const { getSchemaListByKey } = useSchema();
    const [state, dispatch] = useReducer(reducer, initialState);

    const getData = useCallback(async () => {
        try {
            dispatch({ type: 'setLoading', payload: true });

            const listsData = await Promise.all(listKeys.map((key) => getSchemaListByKey<Array<SortableItemType>>({key})));

            const adaptedData = listsData.map((listDataItem, index) => (adapters[listKeys[index]] ? adapters[listKeys[index]](listDataItem) : listDataItem));

            dispatch({ type: 'setData', payload: adaptedData });
        } catch (err) {
            console.error('Error to get schema lists', err);
        } finally {
            dispatch({ type: 'setLoading', payload: false });
        }
    }, [language]);

    useEffect(() => {
        getData();
    }, [language]);

    return state;
};
