import Service from 'additiv-services/services/core/service';
import { getQueryStringByObject } from 'additiv-services/utils/queryUtils';
import {CUSTOM_SERVICE_LAYER_SERVICES, getCustomServiceLayerBaseUrl} from '../constants';

class PlanService extends Service {
    static #baseServiceUrl = getCustomServiceLayerBaseUrl(CUSTOM_SERVICE_LAYER_SERVICES.PLAN);

    static getKudiNote({portfolioId, params}) {
        const queryString = getQueryStringByObject(params);

        return Service.getRequest(
            `${this.#baseServiceUrl}/${portfolioId}/kudiNote?${queryString}`,
            { isSecure: true },
        );
    }

    static updatePbNumber({
        portfolioId, pbNumber, vstNumber, agentId,
    }) {
        return Service.patchRequest(`${this.#baseServiceUrl}/${portfolioId}/pb-number`, {pbNumber, vstNumber, agentId}, {isSecure: true});
    }

    static getPlanDetails({portfolioId}) {
        return Service.getRequest(`${this.#baseServiceUrl}/${portfolioId}/details`, {isSecure: true});
    }

    static updateGeneralAgency({portfolioId, generalAgency}) {
        return Service.patchRequest(`${this.#baseServiceUrl}/${portfolioId}/generalAgency?agencyId=${generalAgency}`, null, {isSecure: true});
    }

    static getPbNumberChangeHistory({portfolioId, pageNumber, pageSize}) {
        const queryString = getQueryStringByObject({pageNumber, pageSize});

        return Service.getRequest(`${this.#baseServiceUrl}/${portfolioId}/pb-number-history?${queryString}`, {isSecure: true});
    }

    static updateIssueCommission({portfolioId, issueCommission}) {
        return Service.patchRequest(`${this.#baseServiceUrl}/${portfolioId}/provisioning-percentage?issueComission=${issueCommission}`, null, {isSecure: true});
    }

    static getIssueCommissionChangeHistory({ portfolioId, pageNumber, pageSize }) {
        const queryString = getQueryStringByObject({pageNumber, pageSize});

        return Service.getRequest(`${this.#baseServiceUrl}/${portfolioId}/provisioning-percentage?${queryString}`, {isSecure: true});
    }

    static getFeesChangeHistory({
        investmentApplicationId, feeType, pageNumber, pageSize,
    }) {
        const queryString = getQueryStringByObject({pageNumber, pageSize});

        return Service.getRequest(`${this.#baseServiceUrl}/${investmentApplicationId}/feeChangeHistory/${feeType}?${queryString}`, {isSecure: true});
    }

    static getGeneralAgencyChangeHistory({portfolioId, pageNumber, pageSize}) {
        const queryString = getQueryStringByObject({pageNumber, pageSize});

        return Service.getRequest(`${this.#baseServiceUrl}/${portfolioId}/general-agency-history?${queryString}`, {isSecure: true});
    }

    static getAgentAndAgencyInfo({portfolioId}) {
        return Service.getRequest(`${this.#baseServiceUrl}/agent-agency-information?portfolioId=${portfolioId}`, { isSecure: true });
    }

    static checkIfChangePlanInProgress({portfolioId}) {
        return Service.getRequest(`${this.#baseServiceUrl}/${portfolioId}/changePlan`, { isSecure: true });
    }

    static getAssetManagement(payload) {
        const queryString = getQueryStringByObject(payload);

        return Service.getRequest(`${this.#baseServiceUrl}/asset-management-fee?${queryString}`, { isSecure: true });
    }

    static getPlanNamesByContactGroupId({contactGroupId}) {
        return Service.getRequest(`${this.#baseServiceUrl}/${contactGroupId}/names`, { isSecure: true });
    }

    static getWithdrawalData({ portfolioId, language }) {
        return Service.getRequest(`${this.#baseServiceUrl}/${portfolioId}/withdrawal-data?language=${language}`, { isSecure: true });
    }

    static getPlanStatus({planId}) {
        return Service.getRequest(`${this.#baseServiceUrl}/${planId}/status`, {isSecure: true});
    }
}

export default PlanService;
