import ServiceManager from 'services/ServiceManager';
import { object, string, number } from 'yup';
import { useEffect, useState } from 'react';
import HandlerError from 'errors/HandlerError';
import handlerRequestCanceling from 'utils/handlerRequestCanceling';
import { useFormatting } from 'locale';
import { adapt } from './adaptKudi';

const kudiSchema = object({
    id: number().required().integer(),
    planId: number().required().integer(),
    clientId: number().required().integer(),
    lpzPlanId: string().required(),
    lpzClientId: number().required().integer(),
    vestedBenefitsAge50: number().required(),
    vestedBenefits: number().required(),
    vestedBenefitsUponMarriage: number().required(),
    wefEarlyWithdrawal: number().required(),
    repaymentWEFEarlyWithdrawal: number().required(),
    vestedBenefitsDivorce: number().required(),
    purchaseIntoPensionFund3years: number().required(),
    retirementAssetsBVG: number().required(),
    vestedBenefitsAge50LPP: number().required(),
    vestedBenefitsUponMarriageLPP: number().required(),
    wefEarlyWithdrawalLPP: number().required(),
    repaymentWEFEarlyWithdrawalLPP: number().required(),
    vestedBenefitsDivorceLPP: number().required(),
});

export const useKudi = ({portfolioId}) => {
    const [error, setError] = useState(null);
    const [isLoading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [isEmpty, setEmpty] = useState(false);
    const {getFormattedNumber} = useFormatting();

    useEffect(() => {
        if (portfolioId) {
            (async () => {
                try {
                    setLoading(true);
                    const response = await ServiceManager.customInvestmentService('getKudi', [{portfolioId}]);
                    /* const response = {
                        data: {
                            id: 7804, planId: 40860, clientId: 462373, lpzPlanId: '110004001', lpzClientId: 110004, vestedBenefitsAge50: 0.00, vestedBenefits: 213150.09, vestedBenefitsUponMarriage: 0.00, wefEarlyWithdrawal: 333.00, repaymentWEFEarlyWithdrawal: 333.00, vestedBenefitsDivorce: 333.00, purchaseIntoPensionFund3years: 333.00, retirementAssetsBVG: 0.00, vestedBenefitsAge50LPP: 333.00, vestedBenefitsUponMarriageLPP: 0.00, wefEarlyWithdrawalLPP: 333.00, repaymentWEFEarlyWithdrawalLPP: 333.00, vestedBenefitsDivorceLPP: 333.00,
                        },
                    }; */
                    const validatedData = await kudiSchema.validate(response.data);

                    const adaptedData = adapt({data: validatedData, getFormattedNumber});

                    setData(adaptedData);
                    setLoading(false);
                } catch (err) {
                    setEmpty(err?.response?.status === 404);
                    handlerRequestCanceling(
                        HandlerError({
                            setError,
                            setLoading,
                        }),
                    )(err);
                }
            })();
        }
    }, [portfolioId, getFormattedNumber]);

    return {
        isEmpty,
        isLoading,
        error,
        data,
    };
};
