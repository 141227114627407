/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import {arrayOf, func, number, oneOfType, shape, string,} from 'prop-types';
import React, {useEffect, useState} from 'react';
import Icon, {ICON_TYPE_MINUS, ICON_TYPE_PLUS} from '../../ui-library/components/Icon';
import './TableRow.css';

export const TableRow = ({
    rowData,
    columns
}) => {
    const [isShowCollapse, setIsShowCollapse] = useState(false);

    const isCollapsible = rowData?.collapse;

    const renderContent = (data) => (typeof data === 'function' ? data() : data);

    const CollapseComponent = isCollapsible && rowData.collapse;

    useEffect(() => {
        // this way is needed so collapse occur on forceCollapse change only
        if (rowData?.forceCollapse === true) {
            setIsShowCollapse(false);
        } else if (rowData?.forceCollapse === false) {
            setIsShowCollapse(true);
        }
    }, [rowData?.forceCollapse]);

    return (
        <>
            <tr className="row-level-0">
                {columns.map((col, idx) => {
                    const value = rowData[col.key];

                    return (
                        <td key={col.key} className="ExpandableCell">
                            <div className="ExpandableCell__Wrapper">
                                {isCollapsible && idx === 0 && (
                                    <button
                                        type="button"
                                        onClick={() => setIsShowCollapse(!isShowCollapse)}
                                        className="ExpandableCell__Button"
                                    >
                                        <Icon type={isShowCollapse ? ICON_TYPE_MINUS : ICON_TYPE_PLUS}/>
                                        <span className="sr-only">{isShowCollapse ? 'collapse' : 'expand'}</span>
                                    </button>
                                )}
                                <span>
                                    {renderContent(value)}
                                </span>
                            </div>
                        </td>
                    );
                })}
            </tr>
            {isCollapsible && isShowCollapse && (
                <CollapseComponent/>
            )}
        </>
    );
};

export const columnPropType = arrayOf(shape({
    key: string.isRequired,
    title: string.isRequired,
}));

export const rowPropType = shape({[string]: oneOfType([string, number, func])});

TableRow.propTypes = {
    columns: columnPropType.isRequired,
    rowData: rowPropType.isRequired,
};
