import React from 'react';
import PropTypes from 'prop-types';
import withTheme from '../../../hocs/withTheme';
import '../Skeleton.css';

function SkeletonCircle({
    size, className, ...props
}) {
    return (
        <span
            className={`Skeleton circle ${className}`}
            style={{ width: size, height: size }}
            {...props}
        />
    );
}

SkeletonCircle.propTypes = {
    size: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    className: PropTypes.string,


};

SkeletonCircle.defaultProps = {
    size: 70,
    className: '',
};
export default withTheme(SkeletonCircle);
