export default function getDistanceByPlacement(options) {
    const {
        placement,
        arrowEl,
        innerEl,
        arrowDistance,
    } = options;
    const arrowElRect = arrowEl.getBoundingClientRect();
    const innerElRect = innerEl.getBoundingClientRect();

    const leftOffset = arrowElRect.left - innerElRect.left;
    const rightOffset = innerElRect.right - arrowElRect.right;
    const topOffset = arrowElRect.top - innerElRect.top;
    const bottomOffset = innerElRect.bottom - arrowElRect.bottom;

    const placementMap = {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        topLeft: {
            style: 'left',
            offset: leftOffset > 0 && leftOffset < arrowDistance
                ? leftOffset - arrowDistance : undefined,
        },
        topRight: {
            style: 'left',
            offset: rightOffset > 0 && rightOffset < arrowDistance
                ? arrowDistance - rightOffset : undefined,
        },
        bottomLeft: {
            style: 'left',
            offset: leftOffset > 0 && leftOffset < arrowDistance
                ? leftOffset - arrowDistance : undefined,
        },
        bottomRight: {
            style: 'left',
            offset: rightOffset > 0 && rightOffset < arrowDistance
                ? arrowDistance - rightOffset : undefined,
        },
        rightTop: {
            style: 'top',
            offset: topOffset > 0 && topOffset < arrowDistance
                ? Math.floor(topOffset - arrowDistance) : undefined,
        },
        rightBottom: {
            style: 'top',
            offset: bottomOffset > 0 && bottomOffset < arrowDistance
                ? Math.ceil(arrowDistance - bottomOffset) : undefined,
        },
        leftTop: {
            style: 'top',
            offset: topOffset > 0 && topOffset < arrowDistance
                ? Math.floor(topOffset - arrowDistance) : undefined,
        },
        leftBottom: {
            style: 'top',
            offset: bottomOffset > 0 && bottomOffset < arrowDistance
                ? Math.ceil(arrowDistance - bottomOffset) : undefined,
        },
    };

    return placementMap[placement];
}
