import React, {
    useCallback, useEffect, useMemo,
} from 'react';
import {useTranslation} from 'react-i18next';
import useCommonAllocationDataProvider from 'pages/ClientOverview/pages/Portfolios/context/useCommonAllocationDataProvider.';
import {useNavigate, useParams} from 'react-router-dom';
import {isThreeBProduct, useThreeBProductType } from 'hooks/isThreeBProduct';
import {useCreatePortfolio} from '../../context';
import StrategySelection from '../../../../components/StrategySelection/StrategySelection';
import {useStrategySelection} from '../../../../hooks/useStrategySelection';
import {Modal} from '../../../../../../../../ui-library';

function StrategySelectionPage() {
    const navigate = useNavigate();
    const {dfsClientId} = useParams();
    const {t} = useTranslation();
    const {
        newPortfolio, saveNewPortfolio, layout: {setData: setLayoutData, setPageErrors },
    } = useCreatePortfolio();

    const is3bProduct = isThreeBProduct(newPortfolio);
    const {setSetupWithdrawalPlan} = useCommonAllocationDataProvider();
    const {
        isZivZicAzp, isZiv, isZic, isAzp, isZivZifd, isZifd,
    } = useThreeBProductType(newPortfolio);

    const cachedInvestmentDescription = useMemo(
        () => (newPortfolio?.applicationData?.investmentDescription),
        [newPortfolio?.applicationData?.investmentDescription],
    );

    const {
        investmentHorizon,
        selectedStrategyId,
        recommendedStrategy,
        selectedProductMarkId,
        setIndividualPortfolioData,
        onInvestmentHorizonChange,
        onProductMarkClick,
        onSetupWithdrawalPlanChange,
        setupWithdrawalPlan,
        strategies,
        strategy,
        setSelectedStrategyId,
        isIndividualPortfolioSelected,
        individualPortfolioData,
        isRebalance,
        setRebalance,
        selectedProduct,
        isNavigationDisabled,
        products,
        isModalListLoading,
        isAllocationsLoading,
        showChart,
        modelPortfolioDetails,
        setIndividualPortfolioValidationError,
        investmentHorizonError,
        setInvestmentHorizonError,
        productMarksOptions,
        allocations,
        factsheetUrl,
        riskScore,
        checkTheRisk,
        productKey,
        preferenceStock,
        onPreferenceStockChange,
        preferenceExclusion,
        onPreferenceExclusionChange,
        preferenceInclusion,
        onPreferenceInclusionChange,
        exclusionSubstitute,
        onExclusionSubstituteChange,
        strategySelectedError,
        isZifdExclInterestIncome,
    } = useStrategySelection({
        productId: cachedInvestmentDescription?.productId,
        recommendedStrategyScore: newPortfolio?.recommendedStrategy?.Score,
        cachedModelPortfolioId: cachedInvestmentDescription?.selectStrategy?.modelPortfolioId,
        cachedSetupWithdrawalPlan: cachedInvestmentDescription?.selectStrategy?.withdrawalOption,
        cachedPreferenceStock: newPortfolio?.preferenceStock,
        cachedPreferenceExclusion: newPortfolio?.preferenceExclusion,
        cachedPreferenceInclusion: newPortfolio?.preferenceInclusion,
        cachedExclusionSubstitute: newPortfolio?.exclusionSubstitute,
        cachedInvestmentHorizon: cachedInvestmentDescription?.selectStrategy?.investmentHorizon,
        setPageErrors,
        productSettings: newPortfolio?.product?.settings,
        is3bProduct,
        isZiv,
        isZic,
        isAzp,
        isZifd,
    });

    // Handlers/Callbacks
    const handleOnContinue = useCallback(async () => {
        const strategyId = selectedStrategyId || recommendedStrategy?.Id;

        const tempSetupWithdrawalPlan = isAzp || setupWithdrawalPlan;

        const payload = {
            strategyId,
            investmentApplicationId: newPortfolio?.applicationData?.investmentApplicationId,
            investmentHorizon: parseInt(investmentHorizon, 10),
            modelPortfolioId: selectedProductMarkId,
            withdrawalOption: is3bProduct ? tempSetupWithdrawalPlan : undefined,
            ...(isIndividualPortfolioSelected ? {
                individualPortfolio: individualPortfolioData.body.reduce((accu, item) => {
                    if (!item?.allocation?.disabled) {
                        accu.push({
                            securityId: item?.allocation?.id,
                            allocation: item?.allocation?.value / 100,
                        });
                    }

                    return accu;
                }, []),
                rebalancing: isRebalance,
            } : {

            }),
        };

        const additionalData = {
            strategy,
            modelPortfolio: selectedProduct,
            riskScore,
            setupWithdrawalPlan: tempSetupWithdrawalPlan,
            preferenceStock,
            preferenceExclusion,
            exclusionSubstitute,
            preferenceInclusion,
        };

        const result = await saveNewPortfolio({
            data: payload,
            method: 'saveStrategyData',
            additionalData,
        });

        if (is3bProduct) {
            setSetupWithdrawalPlan(tempSetupWithdrawalPlan);
        }

        if (!result) return;

        navigate(`/client/${dfsClientId}/portfolios/new-portfolio/${isAzp ? 'withdrawal-plan' : 'goal-setup'}`);
    }, [isRebalance,
        selectedProduct,
        individualPortfolioData,
        investmentHorizon,
        selectedStrategyId,
        recommendedStrategy?.Id,
        selectedProductMarkId,
        riskScore,
        setupWithdrawalPlan,
        preferenceStock,
        preferenceExclusion,
        exclusionSubstitute,
        preferenceInclusion,
        is3bProduct,
        isZiv,
        isAzp,
        isIndividualPortfolioSelected,
        newPortfolio.applicationData.investmentApplicationId]);

    const onContinue = useCallback(async () => {
        // since Investment Horizon questions is out of the questionnaire, validation needs to be done separately and manually
        if (!investmentHorizon) {
            setInvestmentHorizonError(t('validation.mandatoryField'));

            return;
        }

        const isTheRiskChanged = checkTheRisk(handleOnContinue);

        if (isTheRiskChanged) {
            Modal.confirm(isTheRiskChanged);

            return;
        }

        await handleOnContinue();
    }, [
        investmentHorizon,
        checkTheRisk,
        handleOnContinue,
    ]);

    const onBack = useCallback(() => {
        navigate(`/client/${dfsClientId}/portfolios/new-portfolio/investor-profile`);
    }, [dfsClientId]);

    useEffect(() => {
        setLayoutData({
            stepNavBarActive: is3bProduct ? 6 : 4,
            onBack,
            onContinue,
            disabled: isNavigationDisabled,
            saveInProgress: false,
        });
    }, [onBack, onContinue, isNavigationDisabled, is3bProduct]);

    return (
        <StrategySelection
            productDescription={newPortfolio?.product?.description}
            {...{
                investmentHorizon,
                investmentHorizonError,
                selectedProduct,
                recommendedStrategy,
                selectedStrategyId,
                setSelectedStrategyId,
                products,
                isIndividualPortfolioSelected,
                isModalListLoading,
                isAllocationsLoading,
                individualPortfolioData,
                isRebalance,
                setRebalance,
                modelPortfolioDetails,
                setIndividualPortfolioData,
                setIndividualPortfolioValidationError,
                selectedProductMarkId,
                productMarksOptions,
                showChart,
                allocations,
                factsheetUrl,
                onInvestmentHorizonChange,
                onProductMarkClick,
                strategies,
                cachedSelectStrategy: newPortfolio?.applicationData?.investmentDescription?.selectStrategy,
                productKey,
                onSetupWithdrawalPlanChange,
                setupWithdrawalPlan,
                is3bProduct,
                preferenceStock,
                onPreferenceStockChange,
                preferenceExclusion,
                onPreferenceExclusionChange,
                preferenceInclusion,
                onPreferenceInclusionChange,
                exclusionSubstitute,
                onExclusionSubstituteChange,
                isZivZicAzp,
                isZiv,
                isAzp,
                isZivZifd,
                strategySelectedError,
                isZic,
                isZifdExclInterestIncome,
            }}
        />
    );
}
export default StrategySelectionPage;
