import { useQueries, UseQueryResult } from '@tanstack/react-query';
import { AdvisoryListItemTypes } from 'components/common/common.types';
import { useSchema } from 'domain/ClientService';
import { useCallback } from 'react';

export enum AdvisoryOptionTypesEnum {
    CATEGORY = 'AdvisoryCategoryType',
    INSTITUTE = 'AdvisoryInstituteType',
    STRATEGY = 'AdvisoryStrategyType',
    REAL_STATE_PROPERTY = 'advisoryRealEstateType',
  }

export const useGetListItemsByKey = (keys) => {
    const { getSchemaListByKey } = useSchema();
    const getList = async (type: string) => {
        const result = await getSchemaListByKey<Array<AdvisoryListItemTypes>>({ key: type });

        return result;
    };

    const queries = useQueries({
        queries: keys?.map(key => ({
            queryKey: [key],
            queryFn: () => getList(key),
            retry: false,
            enabled: false,
        })),
    });

    const refetchAll = useCallback(() => {
        queries?.forEach(query => {
            if (query?.refetch) {
                query.refetch();
            }
        });
    }, [queries]);

    return {
        advisoryOptions: queries?.map((query: UseQueryResult<any, Error>, index) => ({
            key: keys[index],
            data: query?.data?.map(d => ({ value: d?.id, label: d?.label })),
            error: query.error,
            status: query.status,
        })),
        refetchAll,
    };
};
