import Service from './service';
import { getServicesUrl } from '../../setup';
import Security from './security';

class Currencies extends Service {
    static async getCurrencies(language) {
        await Security.generateBearerApplicationAccessTokenIfNeeded();

        return Service.getRequest(`${getServicesUrl()}/currencies/list/${language}`);
    }

    static getCurrencyById(currencyId, language = 'en') {
        return Service.getRequest(`${getServicesUrl()}/currencies/${currencyId}?language=${language}`, { isSecure: true });
    }

    static postCurrenciesExchangerates(CurrencyExchangeRateRequests) {
        return Service.postRequest(`${getServicesUrl()}/currencies/exchangerates/kpi/calculate`, { CurrencyExchangeRateRequests }, { isSecure: true });
    }
}

export default Currencies;
