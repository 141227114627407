import { useContext } from 'react';

import SinglePortfolioContext from './context';

const useSinglePortfolio = () => {
    const context = useContext(SinglePortfolioContext);

    if (context === undefined) {
        throw new Error('useSinglePortfolio can only be used inside SinglePortfolioProvider');
    }

    return context;
};

export default useSinglePortfolio;
